import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation, Outlet, } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, isValidPhoneNumber, getCountryCallingCode, formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
// import DateTime from 'react-datetime';
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import { getDataAction, getDataClearAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { addSearchResultGuestToStore, addOutsideGuestStatusClearAction } from '../../../../redux/actions/miscEngageActions';

import useDebounce from '../../../Misc/DebounceHook';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { isContactValid, validateEmail, guestTransactionStatusToStayLabel, formatDate, getGuestTypeKey,
  getGuestCategoryKeyByGuest, getSearchGuestLabelByGuest, getFullNameForDisplay, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';

import './style.css';

const yesterday = moment().subtract(1, 'day');

function NewMessage(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [searchTerm, setSearchTerm] = useState('+1');
  const [searchTermChanged, setSearchTermChanged] = useState(false);
  const [sendToNewNumberValid, setSendToNewNumberValid] = useState(false);
  const [sendToNewNumberError, setSendToNewNumberError] = useState(false);

  const searchTermRef = useRef(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - New Message - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    // clear previous results
    dispatch(getDataClearAction({
      op: 'get_new_message_search_result',
      params: {
        location__id: urlParams.locationidentifier,
      }
    }));
    // props.dispatch(clearSearchResultAction({
    //   params: {
    //     type: 'guest',
    //   },
    // }));
    // get staff
    dispatch(getDataAction({
      op: 'get_guest_list',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'staff',
      },
    }));
    // dispatch(getGuestListAction({
    //   params: {
    //     location_id: props.locationprops.location.location_id,
    //     type: 'staff',
    //   },
    // }));
  }, []);


  useEffect(() => {
    try {
      const pContactNumber = parsePhoneNumber(searchTerm);
      if (process.env.NODE_ENV !== 'production') {
        console.log('validateContact pContactNumber: ', pContactNumber);
      }
      if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact error');
        }
        setSendToNewNumberValid(false);
      } else {
        setSendToNewNumberValid(true);
      }
    } catch (e) {}
  }, [searchTerm]);


  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm != '+1') {
      dispatch(getDataAction({
        op: 'get_new_message_search_result',
        params: {
          location__id: urlParams.locationidentifier,
          keyword: debouncedSearchTerm,
          type: 'all',
        },
      }));
    }
  }, [debouncedSearchTerm]);


  // when new outside guest is created
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.engage.outsideGuests: ', props.engage.outsideGuests.addInProgress);
      console.log('props.engage.outsideGuests.addStatus: ', props.engage.outsideGuests.addStatus);
    }
    if (!props.engage.outsideGuests.addInProgress && props.engage.outsideGuests.addStatus &&
      props.engage.outsideGuests.addStatus.success && props.engage.outsideGuests.addStatus.newId
    ) {
      // clear the add outside guest status
      const params = {};
      params.location__id = urlParams.locationidentifier;
      dispatch(addOutsideGuestStatusClearAction({
        op: 'edit_outside_guest',
        params,
      }));
      if (process.env.NODE_ENV !== 'production') {
        console.log('navigating ', `/engage/locations/${urlParams.locationidentifier}/messages/outside-guests/${props.engage.outsideGuests.addStatus.newId}`);
      }
      // props.history.push(`/categories/outside-guests/${props.locationprops.outsideGuests.addStatus.newId}`);
      navigate(`/engage/locations/${urlParams.locationidentifier}/messages/outside-guests/${props.engage.outsideGuests.addStatus.newId}`);
      props.onCancelClick('skip');
    }
  }, [props.engage.outsideGuests.addInProgress]);


  const onCloseClick = (e) => {
    props.onCancelClick('');
  };

  const onContactChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onContactChange: ', value);
    }
    setSearchTerm(value);
    if (value) {
      // if (value.length === 1 && value !== '+') {
      //   setSearchTerm(`+${value}`);
      // } else {
      //   setSearchTerm(value);
      // }
      const pContactNumber = parsePhoneNumber(value);
      if (process.env.NODE_ENV !== 'production') {
        console.log('pContactNumber: ', pContactNumber);
      }
      setSendToNewNumberError(!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber));
    } else {
      // setSearchTerm('+');
      setSendToNewNumberError(true);
    }
    setSearchTermChanged(true);
  };
  const onSearchClearClick = (e) => {
    setSearchTerm('');
  };
  const onSearchTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchTerm('');
    }
  };
  const onSearchResultCloseClick = (e) => {
    setSearchTermChanged(false);
  };
  const onRowClick = (row) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('NewMessage onRowClick: ', row);
    }
    let category = 'outside-guests';
    if (row.transaction_type === 'CheckIn') category = 'guests';
    if (row.transaction_type === 'CheckOut') category = 'past-guests';
    if (row.transaction_type === 'Reservation' || row.transaction_type === 'Booking') category = 'upcoming-guests';
    if (row.transaction_type === 'Cancel') category = 'past-guests';
    if (row.is_staff) category = 'staff';
    if (row.source === 'webtext') category = 'webtext';
    if (row.source === 'bm') category = 'gbm';
    if (category) {
      dispatch(addSearchResultGuestToStore({
        params: {
          location__id: urlParams.locationidentifier,
          guest_type: category,
          guest: row,
        }
      }));
      if (process.env.NODE_ENV !== 'production') {
        console.log('navigate to ', `/engage/locations/${urlParams.locationidentifier}/messages/${category}/${row._id}`);
      }
      navigate(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${row._id}`);
      props.onCancelClick('skip');
    }
  };

  const sendMessageToNewClick = (e) => {
    try {
      const pContactNumber = parsePhoneNumber(searchTerm);
      if (process.env.NODE_ENV !== 'production') {
        console.log('NewMessage validateContact pContactNumber: ', pContactNumber);
      }
      if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact error');
        }
        return;
      }
    } catch (e) {}

    let foundInSearchResult = false;
    if (props.engage.newMessage && props.engage.newMessage.searchResults.contacts && props.engage.newMessage.searchResults.contacts.length) {
      foundInSearchResult = props.engage.newMessage.searchResults.contacts.find(el => el.contact === searchTerm);
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('NewMessage foundInSearchResult: ', foundInSearchResult);
    }
    if (foundInSearchResult) {
      // do not create a new one, move to the existing one
      const current = foundInSearchResult; // props.locationprops.searchResults.contacts[0];
      const category = getGuestCategoryKeyByGuest(current);
      if (process.env.NODE_ENV !== 'production') {
        console.log('NewMessage category: ', category);
      }
      if (category) {
        dispatch(addSearchResultGuestToStore({
          params: {
            location__id: urlParams.locationidentifier,
            guest_type: category,
            guest: current,
          }
        }));
        navigate(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${current._id}`);
        // props.history.push(`/categories/${category}/${current._id}`);
        props.onCancelClick('skip');
      }
    } else {
      // check in staff
      let foundInStaff = false;
      if (props.engage.staff.list && props.engage.staff.list.length) {
        foundInStaff = props.engage.staff.list.find((el) => {
          if (props.engage.staff.keyValue[el] && props.engage.staff.keyValue[el].contact === searchTerm) {
            return true;
          }
          return false;
        });
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('NewMessage foundInStaff: ', foundInStaff);
      }
      if (foundInStaff) {
        foundInStaff = props.engage.staff.keyValue[foundInStaff];
        // do not create a new one, move to the existing one
        const current = foundInStaff; // props.locationprops.searchResults.contacts[0];
        const category = getGuestCategoryKeyByGuest(current);
        if (category) {
          dispatch(addSearchResultGuestToStore({
            params: {
              location__id: urlParams.locationidentifier,
              guest_type: category,
              guest: current,
            }
          }));
          // props.history.push(`/categories/${category}/${current._id}`);
          navigate(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${current._id}`);
          props.onCancelClick('skip');
        }
      } else {
        // create a new outside guest
        const { categoryidentifier, guestidentifier } = urlParams;
        const params = {};
        params.mode = 'add';
        params.location__id = urlParams.locationidentifier;
        params.contact = searchTerm;
        dispatch(editDataAction({
          op: 'add_outside_guest',
          show_alert: true,
          params,
        }));
        // dispatch(editOutsideGuestAction({
        //   params: {
        //     mode: 'add',
        //     location_id: props.locationprops.location.location_id,
        //     contact: searchTerm,
        //   },
        // }));
      }
      // create a new outside guest
      // props.dispatch(editOutsideGuestAction({
      //   params: {
      //     mode: 'add',
      //     location_id: props.locationprops.location.location_id,
      //     contact: searchTerm,
      //   },
      // }));
    }
    // const outsideGuest = {
    //   _id: new Date().getTime(),
    //   contact: searchTerm,
    //   name: '',
    //   date_created: new Date(),
    //   created_from_search: true,
    // }
    // props.dispatch(addSearchResultGuestToStore({
    //   params: {
    //     guest_type: 'outside-guests',
    //     guest: outsideGuest,
    //   },
    // }));
    // props.history.push(`/categories/outside-guests/${outsideGuest._id}`);
    // props.onCancelClick();
  };

  const renderResultRow = (row) => {

    return (
      <div className="rslRw" key={`srr_${row._id}`} onClick={() => onRowClick(row)} id={`srr_${row._id}`}>
        <div className="rwIt">
          <div className="lt">
            {getSearchGuestLabelByGuest(row)}
          </div>
        </div>
        <div className="rwIt topRow">
          <div className="lt">
            {getFullNameForDisplay(row)}
          </div>
          <div className="rt">
            {row.contact}
          </div>
        </div>
        <div className="rwIt stayInfo">
          <div className="lt">
            {formatDate(row.check_in_date)} to {formatDate(row.check_out_date)}
          </div>
          <div className="rt">
            {
              row.check_in_date ? `Room # ${row.room_number}` : ''
            }
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="sdMdl addGst newMessage">
      <div className="ctnt">
        <div className="gPWrp">
          <h4 className="gPTle title">
            <span>
            New Message
            </span>
            {
              props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            }
          </h4>
          <h5 className="gPTle subTitle">
            Send message to anyone
          </h5>
          <div className="bc">
            <div className={'bcRow contactRow ' + (props.engage.newMessage && props.engage.newMessage.searchResults && props.engage.newMessage.searchResults.contacts && props.engage.newMessage.searchResults.contacts.length ? 'searchResultsOpen' : '')}>
              <div className="bxLt">
                <div className="gLbl">
                  Cell Number
                </div>
                <div className={'gIpt'}>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    displayInitialValueAsLocalNumber={false}
                    value={searchTerm}
                    // onCountryChange={onCountryChange}
                    ref={searchTermRef}
                    onKeyDown={onSearchTermKeyDown}
                    onChange={onContactChange}/>
                  {/*<input type="text" placeholder="Cell Number" />*/}
                </div>
                {
                  props.engage.newMessage && props.engage.newMessage.searchResults &&
                  props.engage.newMessage.searchResults.searchInProgress &&
                  <span className="srchInPrg"><i className="fa fa-spinner fa-spin" /></span>
                }
                {
                  sendToNewNumberValid &&
                  <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={`Send message to ${formatPhoneNumberIntl(searchTerm)}`}>
                    <span className="sendToNewNumber" onClick={sendMessageToNewClick}>
                      Send Message
                      {
                        props.engage.outsideGuests.addInProgress &&
                        <i className="fa fa-spinner fa-spin" />
                      }
                    </span>
                  </ToolTipWrapper>
                }
                {/*
                  searchTerm && searchTerm !== '+1' &&
                  <span className="clear" onClick={onSearchClearClick}><img src={closeIcon} /></span>
                */}
                {
                  sendToNewNumberError &&
                  <div className="gErr">
                    Invalid number
                  </div>
                }


              </div>
            </div>
            {
              searchTerm && searchTermChanged && props.engage.newMessage && props.engage.newMessage.searchResults.contacts && props.engage.newMessage.searchResults.contacts.length
              ?
                <div className="srchRCls">
                  <span onClick={onSearchResultCloseClick}>Close</span>
                </div>
              : null
            }
            {
              searchTerm && searchTermChanged && props.engage.newMessage && props.engage.newMessage.searchResults.contacts && props.engage.newMessage.searchResults.contacts.length
              ?
                <>
                  <div className="srchRWrp">
                    <div className="srchRInfo">
                      <p>Selecting a row will open the selected contact conversation screen.</p>
                    </div>
                    {
                      props.engage.newMessage.searchResults.contacts &&
                      props.engage.newMessage.searchResults.contacts.map((item) => {
                        return renderResultRow(item);
                      })
                    }
                  </div>

                </>
              : null
            }
            <div className="gLnBkFl" />
            <div className="bcRow nameRow">
              <div className="bxLt firstNameBox">
                <div className="gLbl">
                  Staff
                </div>
              </div>
            </div>
            <div className="bcRow staffRow">
              <div className="bxLt">
              {
                props.engage.staff.list.map((item) => {
                  if (props.engage.staff.keyValue[item]) {
                    const staff = props.engage.staff.keyValue[item];
                    if (!staff || staff.is_trial_entry) return null;
                    return (
                      <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={`Send message to ${staff.name || staff.contact}`}>
                        <div className="entry" onClick={() => onRowClick(staff)}>
                          <p className="name">{staff.name}</p>
                          <p className="number">{staff.contact}</p>
                        </div>
                      </ToolTipWrapper>
                    );
                  }
                  return null;
                })
              }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMessage;
