// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import { getDataAction } from '../../../../redux/actions/getDataActions';

import SingleBroadcastHistoryBox from './SingleBroadcastHistoryBox';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import BroadcastRunDetail from '../BroadcastRunDetail';

import { formatDate, formatDateTime, getSearchGuestTypeByTransactionType, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';

import './style.css';

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function BroadcastHistory(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();

  const [keyword, setKeyword] = useState('');
  const [grid, setGrid] = useState(true);
  const [filteredList, setFilteredList] = useState([]);

  const [selectedBroadcastRunId, setSelectedBroadcastRunId] = useState(null);
  const [showBroadcastRunDetail, setShowBroadcastRunDetail] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Broadcast - History - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { list, keyValue } = props.engage.broadcast.history;
    if (!props.engage.broadcast.history.getListInProgress) {
      setFilteredList(list);
    }
  }, [props.engage.broadcast.history]);

  useEffect(() => {
    const { history } = props.engage.broadcast;
    let tempResults = [];
    if (keyword && keyword.length > 2 && history && history.list && history.list.length) {
      history.list.forEach((item) => {
        const entry = history.keyValue[item];
        if (process.env.NODE_ENV !== 'production') {
          console.log('entry: ', entry);
        }
        if (!entry || !entry.broadcast_data || entry.is_removed) return null;
        const broadcastData = entry.broadcast_data;
        const reg = new RegExp(keyword, "i");
        if (
          (broadcastData.name && broadcastData.name.search(reg) > -1) ||
          (broadcastData.type && broadcastData.type.search(reg) > -1) ||
          (broadcastData.target && broadcastData.target.search(reg) > -1) ||
          (broadcastData.message && broadcastData.message.search(reg) > -1)
        ) {
          tempResults.push(item);
        }
      });
      // tempResults = props.broadcastprops.history.list.filter((item) => {
      //   const entry = props.broadcastprops.history.keyValue[item];
      //   console.log('entry: ', entry);
      //   if (!entry || !entry.broadcast_data || entry.is_removed) return false;
      //   const broadcastData = entry.broadcast_data;
      //   const reg = new RegExp(keyword, "i");
      //   if (
      //     (broadcastData.name && broadcastData.name.search(reg) > -1) ||
      //     (broadcastData.type && broadcastData.type.search(reg) > -1) ||
      //     (broadcastData.target && broadcastData.target.search(reg) > -1) ||
      //     (broadcastData.message && broadcastData.message.search(reg) > -1)
      //   ) {
      //     return true;
      //   }
      //   return false;
      // });
    } else {
      if (history && history.list && history.list.length) {
        history.list.forEach((item) => {
          const entry = history.keyValue[item];
          if (!entry || entry.is_removed) return null;
          tempResults.push(item);
        });
      }
    }
    setFilteredList(tempResults);
  }, [keyword]);

  useEffect(() => {
    const { broadcasthistoryidentifier } = urlParams;
    if (broadcasthistoryidentifier) {
      setSelectedBroadcastRunId(broadcasthistoryidentifier);
      setShowBroadcastRunDetail(true);
    }
  }, [urlParams.broadcasthistoryidentifier]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
  }

  useEffect(() => {
    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        containerId: 'broadcastWrapId',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
    dispatch(getDataAction({
      op: 'get_broadcasts_history',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, []);

  const onFilterTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
    }
  };

  const onBroadcastRunDetailCloseClick = (e) => {
    setShowBroadcastRunDetail(false);
    navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/history`);
  };

  const renderBroadcastRunDetailModal = () => {
    return (
      <Modal>
          <div id="cModelId" className="sdMdl bdcstSnglMdl">
            <div className="ctnt">
              <BroadcastRunDetail
                onCloseClick={onBroadcastRunDetailCloseClick}
                mode={selectedBroadcastRunId ? 'edit' : 'add'}
                broadcastRunId={selectedBroadcastRunId}
                locationidentifier={urlParams.locationidentifier}
                settings={props.settings}
                engage={props.engage}
                windowState={windowState}
              />
            </div>
          </div>
        </Modal>
    );
  }

  const renderListHeader = () => {
    return (
      <div className="broadcastBox header">
        <div className="partOne">
          <div className="type">
            Type
          </div>
          <div className="target">
            Target
          </div>
          <div className="name">
            Name
          </div>
        </div>
        <div className="partTwo">
          <div className="message">
            Message
          </div>
        </div>
        <div className="partThree">
          <div className="when">
            <i className="far fa-clock"></i>
            When
          </div>
          <div className="sentStats">
            Sent to
          </div>
          <div className="oMenu">
          </div>
        </div>
      </div>
    );
  }
  const renderContent = (history) => {
    return (
      <>
        <h4 className="gPageWrapOuterTitle title">
          History
        </h4>
        {
          filteredList && filteredList.length
          ?
            <div className="gPageWrap broadcastsHistory">
              <div className="searchBoxg gIpt">
                <input type="text" onChange={onChange} placeholder="Filter..." value={keyword} onKeyDown={onFilterTermKeyDown}/>
              </div>

              <div className="allWrap">
                { renderListHeader() }
                {
                  filteredList && filteredList.length
                  ?
                    filteredList.map((item) => {
                      if (history.keyValue[item] && !history.keyValue[item].is_removed) {
                        return <SingleBroadcastHistoryBox broadcastHistory={history.keyValue[item]} grid={grid}
                          key={`bhb_${item}`}
                          locationidentifier={urlParams.locationidentifier}
                        />
                      }
                      return null;
                    })
                  : null
                }
              </div>
            </div>
          :
          <div className="gPageWrap broadcastsHistory">
            <div className="allWrap">
              <p>No Data</p>
            </div>
          </div>
        }
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('BroadcastHistory props: ', props);
  }

  const { history } = props.engage.broadcast;
  if (!history) return null;

  return (
    <>
      {
        history.getListInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg">
          { renderContent(history) }
        </div>
      </div>
      { showBroadcastRunDetail && renderBroadcastRunDetailModal() }
    </>
  );
}

export default BroadcastHistory;
