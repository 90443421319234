import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Dashboard from '../../Engage/Dashboard';
import LocationHome from './LocationHome';
import Overview from '../Overview';
import TransactionEmails from '../TransactionEmails';
import EnableMarketing from '../EnableMarketing';

import { getProductAccess, isLocationAdminAndAbove, isGroupAdminAndAbove, getMarketingAccess } from '../../../helpers';

import { initMarketingAction } from '../../../redux/actions/initActions';
import { getDataAction } from '../../../redux/actions/getDataActions';

const gtconfig = require('../../../gtconfig');

function LocationWrapper(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('LocationWrapper urlParams: ', urlParams);
      console.log('LocationWrapperurlParams locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(initMarketingAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
    dispatch(getDataAction({
      op: 'get_location_settings',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'marketing',
      },
    }));
  }, [urlParams.locationidentifier]); // urlParams.locationidentifier

  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  if (!userState.user.user_info || !userState.user.user_info.locations || !userState.user.user_info.locations[locationidentifier]) return null;
  const locationInfo = { ...userState.user.user_info.locations[locationidentifier] };

  if (!locationState.locations[locationidentifier]) return null;
  const { marketing } = locationState.locations[locationidentifier];
  if (!marketing) return null;

  const { settings } = locationState.locations[locationidentifier];
  if (!settings) return null;

  const hasTransactionEmail = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_transaction_emails
    : false;
  // if (!hasTransactionEmail) return null;
  if (!hasTransactionEmail) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('calling Intercom');
    }
    // window.Intercom('shutdown');
    // window.Intercom("boot", {
    //   api_base: "https://api-iam.intercom.io",
    //   app_id: "mydf4dtr",
    //   name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //     email: userState.user.uid, // the email for your user
    //   // created_at: user.createdAt, // Signup date as a Unix timestamp
    // });
    return (
      <div className="mcIframeWrp">
        <iframe id="iframeId"
          title={gtconfig.APP_NAME_CONTACT}
          src="https://guesttouch.com/internal-transaction-guest-journey-email"
        >
        </iframe>
      </div>
    );
  } else {
    // window.Intercom('shutdown');
  }
  if (!hasTransactionEmail) return <EnableMarketing />

  const { hasAccessToMarketing } = getProductAccess(userState.user.user_info, locationidentifier);
  if (!hasAccessToMarketing) return null;


  // show loader while settings are being retrieved
  if (settings.getInProgress && settings.type_marketing) {
    return (
      <div className="gPnlLdng">
        <div className="inWrp">
          <i className="fa fa-spinner fa-spin" />
        </div>
      </div>
    );
  }

  // let hasAccessToMarketing = false;
  // if (userState.user.user_info && userState.user.user_info.profile) {
  //   const { profile } = userState.user.user_info;
  //   if (profile.locations && profile.locations[urlParams.locationidentifier]) {
  //     if (profile.locations[urlParams.locationidentifier].marketing &&
  //       profile.locations[urlParams.locationidentifier].access_level === 'GENERIC_USER'
  //     ) {
  //       hasAccessToMarketing = true;
  //     }
  //     if (isLocationAdminAndAbove(profile.locations[urlParams.locationidentifier].access_level)) {
  //       hasAccessToMarketing = true;
  //     }
  //   }
  //   if (isGroupAdminAndAbove(profile.access_level)) {
  //     hasAccessToMarketing = true;
  //   }
  // }
  //
  // if (!hasAccessToMarketing) return null;



  const { hasAccessToManageTransactionEmails } = getMarketingAccess(userState.user.user_info, urlParams.locationidentifier);
  const mrkAccess = { hasAccessToManageTransactionEmails };

  return (
    <div className="gCw">
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path="overview" element={<Overview locationInfo={locationInfo} marketing={marketing} mrkAccess={mrkAccess} {...props} />} />
          <Route path="transaction-emails/*" element={<TransactionEmails locationInfo={locationInfo} marketing={marketing} settings={settings} mrkAccess={mrkAccess} {...props} />} />
          <Route index element={<LocationHome mrkAccess={mrkAccess} {...props} />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </div>
  );
}

export default LocationWrapper;
