// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import Mousetrap from 'mousetrap';

import './style.css';


let templateList = [];
templateList.push({
  id: 1,
  name: 'Template 1',
});
templateList.push({
  id: 2,
  name: 'Template 2',
});
templateList.push({
  id: 3,
  name: 'Template 3',
});
templateList.push({
  id: 4,
  name: 'Template 4',
});

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}


function EmailBuilderTipsModal(props) {
  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - Alert - ${props.modalType}`,
    //   user_id: props.userprops.user.uid,
    // });
    Mousetrap.bind('esc', () => props.setModalVisible(false));
    return () => {
      Mousetrap.unbind('esc');
    };
  }, []);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCancelClick = (e) => {
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    props.onAffirmativeClick();
  };

  const renderModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="emailBuilderTipsModal" onClick={onModalClick}>
          <div className="contentWrap">
            <div className="content">
              <div className="title">
                {
                  props.title || 'Tips'
                }
              </div>
              <div className="body">
                <div className="rowOne">
                  {props.bodyRowOne}
                </div>
                <p className="rowInfo">Hotel logo is availble under "Uploads" section on extreme right. Drag and drop to use it.</p>
                <p className="rowInfo">Use variables to insert dynamic value in emails.</p>
                <p className="rowInfo">Use {"{{first_name}}"} to dynamically insert guest's first name before sendng the email.</p>
                {
                  props.bodyRowNote &&
                  <div className="rowNote">
                    <b>Note: </b>{props.bodyRowNote}
                  </div>
                }
                <div className="actions">
                  <button className="ok" onClick={onAffirmativeClick}>
                    { props.affirmativeButtonLabel || 'Close' }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const renderContent = () => {
    if (showModal) return renderModal();
    return null;
  };

  return renderContent();
}

export default EmailBuilderTipsModal;
