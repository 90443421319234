import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { Rnd } from "react-rnd";

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericSideModal from '../../../Misc/GenericSideModal';

import overviewIcon from '../../../../img/icons/feedback.svg';
import overviewIconActive from '../../../../img/icons/feedback-active.svg';
import uploadCSVIcon from '../../../../img/icons/feedback.svg';
import uploadCSVIconActive from '../../../../img/icons/feedback.svg';
import uploadHistoryIcon from '../../../../img/icons/feedback.svg';
import uploadHistoryIconActive from '../../../../img/icons/feedback.svg';

import './style.css';

function AddRgLocation(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showLocationModal, setShowLocationModal] = useState(false);

  const onLocationAddClick = (e) => {
    if (props.isReadOnly) return;
    setShowLocationModal(true);
  };
  const onLocationCancelClick = (value) => {
    setShowLocationModal(value);
  };


  const renderLocationModal = () => {
    const { locations } = responseGeneratorState;
    return (
      <GenericSideModal
        modalType="rgLocation"
        className="mtGen"
        data={{
          mode: 'add',
          opName: 'add',
          template: {}, // selectedQuery ? { ...selectedQuery } : {},
        }}
        extData={{
          title: 'Add Location' ,
        }}
        setModalVisible={onLocationCancelClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  };


  const { locations } = responseGeneratorState;

  return (
    <>
      <div><span onClick={onLocationAddClick}>Add</span> your first location to start generating responses</div>
      { showLocationModal && renderLocationModal() }
    </>
  );
}

export default AddRgLocation;
