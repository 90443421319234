import axios from 'axios';

import { auth } from '../../firebase';
import { sendEmailAlert } from '../../helpers';

const gtconfig = require('../../gtconfig');



/* ***************************  Start - Edit Guest Remove Entry ****************************** */

export function editGuestRemoveEntryAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'EDIT_GUEST_REMOVE_ENTRY',
      payload,
    });
  };
}

/* ***************************  End - Edit Guest Remove Entry  ****************************** */

/* ***************************  Start - Add search result to relevant guest type store ****************************** */

export function addSearchResultGuestToStore(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'ADD_SEARCH_RESULT_GUEST_TO_STORE',
      payload,
    });
  };
}

/* ***************************  End - Add search result to relevant guest type store ****************************** */


/* ***************************  Start - Clear Add outside guest status ****************************** */

export function addOutsideGuestStatusClearAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'ADD_OUTSIDE_GUEST_STATUS_CLEAR',
      payload,
    });
  };
}

/* ***************************  End - Add search result to relevant guest type store ****************************** */


/* ***************************  Start - Handle Act Pusher Message ****************************** */

export function handleEngageActPusherMessage(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'HANDLE_ENGAGE_ACT_MESSAGE',
      payload,
    });
  };
}

/* ***************************  End - Handle Act Pusher Message ****************************** */



/* ***************************  Start - Handle Pusher Message ****************************** */

export function handleEngagePusherMessage(payload) {
  return function somename(dispatch, getState) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage payload: ', payload);
    }
    // dispatch({
    //   type: 'HANDLE_ENGAGE_PUSHER_MESSAGE',
    //   payload,
    // });
    const { type, data, locationidentifier } = payload;

    const stateWrap = getState();
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage getState stateWrap: ', stateWrap);
    }
    const state = { ...stateWrap.locationReducer };
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage getState state: ', state);
    }
    let engage = {};
    if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
      engage = { ...state.locations[locationidentifier].engage };
    }
    const { guests, pastGuests, upcomingGuests, outsideGuests, staff, webtext } = engage;
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage getState engage: ', engage);
    }

    const origPayload = { ...payload };
    // If the guest / outside guest message is received and the guest / outside guest data is not available in store,
    // then fetch it and add it to the store
    if (type === 'guest-message-create' || type === 'outside-guest-message-create') {
      const { message: { location_id, guest_id, outside_guest_id } = {} } = data;

      if (type === 'guest-message-create' || type === 'outside-guest-message-create') {
        if (process.env.NODE_ENV !== 'production') {
          console.log('guest_id: ', guest_id);
          console.log('outside_guest_id: ', outside_guest_id);
        }
        let guestFoundIn = '';
        if (type === 'guest-message-create') {
          if (guests.keyValue[guest_id]) { guestFoundIn = 'guests'; }
          else if (pastGuests.keyValue[guest_id]) { guestFoundIn = 'past-guests'; }
          else if (upcomingGuests.keyValue[guest_id]) { guestFoundIn = 'upcoming-guests'; }
        }

        if (type === 'outside-guest-message-create') {
          if (outsideGuests.keyValue[outside_guest_id]) { guestFoundIn = 'outside-guests'; }
          else if (staff.keyValue[outside_guest_id]) { guestFoundIn = 'staff'; }
          else if (webtext.keyValue[outside_guest_id]) { guestFoundIn = 'webtext'; }
        }

        if (process.env.NODE_ENV !== 'production') {
          console.log('guestFoundIn : ', guestFoundIn);
        }
        // a guest who is not available in the store, get it from backend
        if (!guestFoundIn) {
          const { message } = data;
          const params = {
            location_id,
            guest_id,
            outside_guest_id,
          };
          if (process.env.NODE_ENV !== 'production') {
            console.log('params', params, message);
          }
          // //Get Verify ID Token for server auth
          auth.currentUser.getIdToken(false).then((idToken) => {
            const url = gtconfig.DATA_BACKEND_URL;
            const opdata = {};
            // opdata.params = params;
            opdata.params = {
              ...params,
              relogin_identifier: window.gtCId,
            };
            const postData = {
              appId: gtconfig.APP_ID,
              idToken,
              op: 'get_guest',
              opdata,
            };
            if (process.env.NODE_ENV !== 'production') {
              console.log('get_guest postData: ', postData);
            }
            const headers = {
              // "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
              'Content-type': 'application/json',
              'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
            };
            if (process.env.NODE_ENV !== 'production') {
              console.log('get_guest postData', postData);
            }
            // const axiosData = {
            //   gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.DATA_BACKEND_URL_GT_BASIC_KEY).toString(),
            // };
            const axiosData = {
              gtps: postData,
              M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
            };

            axios.post(url, axiosData, { headers }).then((response) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('get_guest response: ', response); // comment
              }
              const { status, data } = response.data;
              if (status === 'success' && data && (data.guest_data || data.outside_guest_data)) {
                let payload = data;
                if (process.env.NODE_ENV !== 'production') {
                  console.log('payload!!', payload, message);
                }
                if (data.guest_data) {
                  dispatch({
                    type: 'ADD_GUEST_TO_LIST',
                    payload: {
                      params: {
                        guest_data: data.guest_data,
                        locationidentifier,
                      },
                    },
                  });
                }
                if (data.outside_guest_data) {
                  dispatch({
                    type: 'ADD_GUEST_TO_LIST',
                    payload: {
                      params: {
                        outside_guest_data: data.outside_guest_data,
                        locationidentifier,
                      },
                    },
                  });
                }
                dispatch({
                  type: 'HANDLE_ENGAGE_MESSAGE',
                  payload:{
                    ...origPayload,
                    locationidentifier,
                  },
                });
                if (process.env.NODE_ENV !== 'production') {
                  console.log('get_guest success');
                }
              } else {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('get_guest fail');
                }
                sendEmailAlert({
                  app: gtconfig.APP_NAME,
                  subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - get_guest Error - ${auth.currentUser.uid}`,
                  message: JSON.stringify({postData, responseData: response.data }),
                  error: JSON.stringify(response.data),
                  // store: JSON.stringify(getState()),
                });
                // logAxiosError(postData, status, { store: JSON.stringify(getState()) });
              }
            })
              .catch((err) => { // axios error
                if (process.env.NODE_ENV !== 'production') {
                  console.log('get_guest err--------------------: ', err); // comment
                }
                sendEmailAlert({
                  app: gtconfig.APP_NAME,
                  subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - get_guest Error - axios - ${auth.currentUser.uid}`,
                  message: JSON.stringify({ url, postData }),
                  error: JSON.stringify({err , config: err.config }),
                  // store: JSON.stringify(getState()),
                });
              })
            })
          .catch((err) => {
            // Handle error
            if (process.env.NODE_ENV !== 'production') {
              console.log('get_guest token err: ', err); // comment
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - get_guest Error - firebase`,
              message: 'Firebase error',
              error: err.toString(),
              store: JSON.stringify(getState()),
            });
            // logAxiosError(payload, error);
          });
        } else { // if else guestFoundIn
          if (process.env.NODE_ENV !== 'production') {
            console.log('payload guestFoundIn ', guestFoundIn);
          }
          dispatch({
            type: 'HANDLE_ENGAGE_MESSAGE',
            payload:{
              ...payload,
              locationidentifier,
            },
          });
        } // end of guestFoundIn else
      }
    } else { // end of type === 'guest-message-create' || type === 'outside-guest-message-create'
      dispatch({
        type: 'HANDLE_ENGAGE_MESSAGE',
        payload:{
          ...payload,
          locationidentifier,
        },
      });
    }
  };
}

/* ***************************  End - Handle Pusher Message ****************************** */
