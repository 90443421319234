import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import GenericAlertModal from '../../../../Misc/GenericAlertModal';
import WCPreview from '../WCPreview';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, STRS } from '../../../../../helpers';

import avataarIcon from '../../../../../img/icons/avataar.svg';

import '../style.css';

const gtconfig = require('../../../../../gtconfig');

const positionOptions = [];
positionOptions.push({ label: LAUNCHER_POSITION_LABELS.BOTTOM_RIGHT, value: LAUNCHER_POSITIONS.BOTTOM_RIGHT });
positionOptions.push({ label: LAUNCHER_POSITION_LABELS.BOTTOM_LEFT, value: LAUNCHER_POSITIONS.BOTTOM_LEFT });

function ThemeOption(props) {
  const onThemeClick = (e) => { props.onThemeClick(props.theme); };
  let cStyle = {};
  cStyle['borderColor'] = props.selected ? props.color : '#FFFFFF';
  let innerStyle = {};
  innerStyle['backgroundColor'] = props.color;
  return (
    <div className={'themeOption ' + (props.selected ? 'selected' : '')} style={cStyle} onClick={onThemeClick}>
      <div className={'inner ' + (props.theme)} style={innerStyle} >
      </div>
    </div>
  );
}

function WCGeneral(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [uploadedPath, setUploadedPath] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadedPathError, setUploadedPathError] = useState('');
  const [uploadedPathChanged, setUploadedPathChanged] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState('');
  const [themeError, setThemeError] = useState('');
  const [themeChanged, setThemeChanged] = useState(false);

  const [assistantName, setAssistantName] = useState('');
  const [assistantNameError, setAssistantNameError] = useState('');
  const [assistantNameChanged, setAssistantNameChanged] = useState(false);

  const [promptMsg, setPromptMsg] = useState('');
  const [promptMsgError, setPromptMsgError] = useState('');
  const [promptMsgChanged, setPromptMsgChanged] = useState(false);

  const [welcomeMsg, setWelcomeMsg] = useState('');
  const [welcomeMsgError, setWelcomeMsgError] = useState('');
  const [welcomeMsgChanged, setWelcomeMsgChanged] = useState(false);

  const [hasHelpGuide, setHasHelpGuide] = useState(false);
  const [hasHelpGuideChanged, setHasHelpGuideChanged] = useState(false);

  const [launcherPositionOptions, setLauncherPositionOptions] = useState(positionOptions);
  const [launcherPositionValue, setLauncherPositionValue] = useState(
    positionOptions[0],
  );
  const [sideSpace, setSideSpace] = useState(20);
  const [bottomSpace, setBottomSpace] = useState(20);
  const [launcherPositionChanged, setLauncherPositionChanged] = useState(false);

  const [launcherPositionMobileOptions, setLauncherPositionMobileOptions] = useState(positionOptions);
  const [launcherPositionMobileValue, setLauncherPositionMobileValue] = useState(
    positionOptions[0],
  );
  const [sideSpaceMobile, setSideSpaceMobile] = useState(20);
  const [bottomSpaceMobile, setBottomSpaceMobile] = useState(20);
  const [launcherPositionMobileChanged, setLauncherPositionMobileChanged] = useState(false);

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - WebChat - General - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { webchat } } = props;
    if (webchat) {
      if (webchat.general) {
        setUploadedPath(webchat.general.avataar_logo_url || '');
        setAssistantName(webchat.general.assistant_name || '');
        setSelectedTheme(webchat.general.theme || '');
        setPromptMsg(webchat.general.prompt ? webchat.general.prompt.msg || '' : '');
        setWelcomeMsg(webchat.general.intro ? webchat.general.intro.title || '' : '');
        const { launcher_position } = webchat.general;
        if (launcher_position) {
          const { desktop, mobile } = launcher_position;
          if (desktop) {
            if (desktop.position) {
              setLauncherPositionValue({
                label: LAUNCHER_POSITION_KEY_TO_LABELS[desktop.position],
                value: desktop.position,
              });
            }
            if (desktop.side_space) setSideSpace(desktop.side_space);
            if (desktop.bottom_space) setBottomSpace(desktop.bottom_space);
          }
          if (mobile) {
            if (mobile.position) {
              setLauncherPositionMobileValue({
                label: LAUNCHER_POSITION_KEY_TO_LABELS[mobile.position],
                value: mobile.position,
              });
            }
            if (mobile.side_space) setSideSpaceMobile(mobile.side_space);
            if (mobile.bottom_space) setBottomSpaceMobile(mobile.bottom_space);
          }
        }
      }
      if (webchat.is_help_guide_enabled) {
      setHasHelpGuide(true);
    }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.settings.webchat.editInProgress &&
      props.settings.webchat.editStatus &&
      props.settings.webchat.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.webchat]);


  const onDrop = useCallback((acceptedFiles) => {

    // Do something with the files
    if (process.env.NODE_ENV !== 'production') {
      console.log('acceptedFiles: ',acceptedFiles);
      // console.log('rejectedFiles: ',rejectedFiles);
    }
    if (acceptedFiles.length===0) {
      // swal('Oops', 'The file is not valid.', 'error');
      return;
    }
    if (acceptedFiles.length === 1) {
      // setMode('dnd');
      // setManualInput('');
      acceptedFiles.forEach((file) => {

        if ( /\.(jpe?g|png)$/i.test(file.name) === false ) {
          setUploadError(`Image must be of PNG or JPG type.`);
          setShowUploadErrorModal(true);
          return false;
        }

        // setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            // console.log('fileAsBinaryString: ',fileAsBinaryString);
            console.log('e.target.result: ',e.target.result);
          }
          //Initiate the JavaScript Image object.
          var image = new Image();

          //Set the Base64 string return from FileReader as source.


          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (process.env.NODE_ENV !== 'production') {
              console.log('height: ', height);
              console.log('width: ', width);
            }
            if (height !== 1024 || width != 1024) {
              setUploadError(`Image must be 1024px X 1024px. Tried one is ${height}px X ${width}px`);
              setShowUploadErrorModal(true);
              return false;
            }
            uploadFile(file);
            return true;
          };
          image.src = e.target.result;
          if (fileAsBinaryString) {
            // uploadFile(file);
            if (fileAsBinaryString.indexOf(',') === -1) {
              // No commas found
              // swal('Oops', 'Not a CSV ( Comma Seperated File ).', 'warning');

              return;
            }

            // let pResult = parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // if (process.env.NODE_ENV !== 'production') {
            //   console.log('parseResult: ', parseResult);
            // }
            // setParseResult(pResult);
            // this.parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // this.setState(prevState => ({ dragAndDropActive: true }));

          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsDataURL(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

  const uploadFile = (file) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile: ', file);
    }
    setUploadedPath('');
    setUploadInProgress(true);

    let filename = file.name.replace(/\s/g,'');
    let fileExtension = 'jpg';
    let tArr = filename.split('.');
    if (tArr.length >= 2) {
      fileExtension = tArr[tArr.length-1];
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('fileExtension: ', fileExtension);
    }

    let postData = new FormData();
    postData.append('file', file, file.name);
    postData.append('name', filename);
    postData.append('new_name', `webchat_avataar_logo.${fileExtension}`);
    // postData.append('uid', props.userprops.user.uid);
    postData.append('location_id', props.settings.info.location_id);
    postData.append('identifier', filename);
    postData.append('source', 'webchat');
    let sizes = [];
    sizes.push({ width: 256, suffix: '256', size_identifier: 'resized' });
    postData.append('sizes', JSON.stringify(sizes));
    // let myhtl = {}, sizes = [];
    // sizes.push({ width: 400, suffix: 'll', size_identifier: 'size1' });
    // sizes.push({ width: 200, suffix: 'sll', size_identifier: 'size2' });
    // myhtl['sizes'] = sizes;
    // postData.append('myhtl', JSON.stringify(myhtl));

    // setForm(prevForm => ({
    //   ...prevForm,
    //   [`${name}UploadInProgress`]: true,
    // }));


    // //get data from database
    let url = gtconfig.IMG_UPLOAD_URL_WITH_RESIZE;
    const header = { 'gt-access-token' : "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa", 'Content-Type': 'multipart/form-data' };
    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   'x-api-key': "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa",
    // };
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile called api');
    }
    axios.post(url, postData, { headers: header }).then((response) => {
      // setUploadInProgress(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('uploadFile response: ', response['data']);
      }
      const { status } = response.data;
      const { data } = response.data;
      // if (status === 'success' && data && data.name && data.path) {
      if (status === 'success' && data && data.name && data.uploads && data.uploads.length) {
        const findResized = data.uploads.find(el => el.size_identifier === 'resized');
        if (findResized) {
          setUploadedPath(findResized.path);
          setFormChanged(true);
          setUploadedPathError('');
          setUploadedPathChanged(true);
        }
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [data.identifier]: data.name, //feedbackEmailHotelLogoUrl
        //   [`${data.identifier}Error`]: 'success',
        //   [`${data.identifier}UploadInProgress`]: false,
        // }));
      } else {
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [name]: '',
        //   [`${name}Error`]: 'Error is uploading.',
        //   [`${name}UploadInProgress`]: false,
        // }));
      }
      setUploadInProgress(false);
    })
      .catch((err) => {
        console.log('uploadFile err--------------------: ',err);
      });

  };
  const onShowUploadErrorCloseModal = (e) => {
    setShowUploadErrorModal(false);
    setUploadError('');
    // setUploadedPath('');
    const { webchat } = props.settings;
    if (webchat && webchat.general) {
      setUploadedPath(webchat.general.avataar_logo_url || '');
    } else {
      setUploadedPath('');
    }
    setUploadInProgress(false);
  };

  const onThemeClick = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onThemeClick: ', value);
    }
    setSelectedTheme(value);
    setThemeError('');
    setThemeChanged(true);
    setFormChanged(true);
  };

  const onAssistantNameChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 20) return;
    setAssistantName(value);
    // setAssistantNameError(value ? '' : 'error');
    setFormChanged(true);
    setAssistantNameChanged(true);
  };

  const onPromptMsgChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 30) return;
    setPromptMsg(value);
    setPromptMsgError(value ? '' : 'error');
    setFormChanged(true);
    setPromptMsgChanged(true);
  };

  const onWelcomeMsgChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 30) return;
    setWelcomeMsg(value);
    setWelcomeMsgError(value ? '' : 'error');
    setFormChanged(true);
    setWelcomeMsgChanged(true);
  };

  const onHasHelpGuideChanged = (e) => {
    const { name, checked } = e.target;
    setHasHelpGuide(checked);
    setHasHelpGuideChanged(true);
    setFormChanged(true);
  };

  const onLauncherPositionDesktopResetClick = (e) => {
    setLauncherPositionValue(positionOptions[0]);
    setSideSpace(20);
    setBottomSpace(20);
    setLauncherPositionChanged(true);
    setFormChanged(true);
  };

  const onLauncherPositionChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLauncherPositionChange: ', value);
    }
    setLauncherPositionValue(value);
    setLauncherPositionChanged(true);
    setFormChanged(true);
  };
  const onSideSpaceChange = (e) => {
    setSideSpace(e.target.value);
    setLauncherPositionChanged(true);
    setFormChanged(true);
  };
  const onBottomSpaceChange = (e) => {
    setBottomSpace(e.target.value);
    setLauncherPositionChanged(true);
    setFormChanged(true);
  };


  const onLauncherPositionMobileChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLauncherPositionMobileChange: ', value);
    }
    setLauncherPositionMobileValue(value);
    setLauncherPositionMobileChanged(true);
    setFormChanged(true);
  };
  const onLauncherPositionMobileResetClick = (e) => {
    setLauncherPositionMobileValue(positionOptions[0]);
    setSideSpaceMobile(20);
    setBottomSpaceMobile(20);
    setLauncherPositionMobileChanged(true);
    setFormChanged(true);
  };
  const onSideSpaceMobileChange = (e) => {
    setSideSpaceMobile(e.target.value);
    setLauncherPositionMobileChanged(true);
    setFormChanged(true);
  };
  const onBottomSpaceMobileChange = (e) => {
    setBottomSpaceMobile(e.target.value);
    setLauncherPositionMobileChanged(true);
    setFormChanged(true);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { webchat } = props.settings;
    if (webchat && webchat.general && webchat.general.editInProgress) return;

    let hasError = false;

    if (!uploadedPath) { setUploadedPathError('error'); hasError = true; }
    if (!selectedTheme) { setThemeError('error'); hasError = true; }
    if (!promptMsg) { setPromptMsgError('error'); hasError = true; }
    if (!welcomeMsg) { setWelcomeMsgError('error'); hasError = true; }

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-general',
      general: {},
    };
    if (uploadedPathChanged) params.general.avataar_logo_url = uploadedPath;
    if (assistantNameChanged) {
      params.general.assistant_name = assistantName;
    }
    if (themeChanged) params.theme = selectedTheme;
    if (promptMsgChanged) {
      params.general.prompt = { msg: promptMsg };
    }
    if (welcomeMsgChanged) {
      params.general.intro = { title: welcomeMsg };
    }
    if (hasHelpGuideChanged) {
      params.general.is_help_guide_enabled = hasHelpGuide;
    }
    if (launcherPositionChanged || launcherPositionMobileChanged) {
      params.general.launcher_position = {};
        params.general.launcher_position.desktop = {
          position: launcherPositionValue.value,
          side_space: sideSpace,
          bottom_space: bottomSpace,
        };
        params.general.launcher_position.mobile = {
          position: launcherPositionMobileValue.value,
          side_space: sideSpaceMobile,
          bottom_space: bottomSpaceMobile,
        };
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };

  const renderAvataarImg = () => {
    if (uploadedPath) return <img src={uploadedPath} />;
    if (uploadInProgress) return <span><i className="fa fa-spinner fa-spin" /></span>;
    return <img src={avataarIcon} />;
  };

  const renderAgentLogo = () => {
    return (
      <div className={'gBCtRw'}>
        <div className="lt">
          <p className="title">Display Avataar</p>
          <p className="desc">Set the avatar that you want to display.</p>
          <div {...getRootProps({className: 'dndBox'})}>
            <input {...getInputProps()} />
            <div className="profileImgBox">
              { renderAvataarImg() }
            </div>
            <div className="info">
              <div className="top">Drag your image here or <div className="gButton"><span>Click to Upload</span></div></div>
              <p className="supportInfo">
                Supported formats: PNG, JPG. Size must be 1024px X 1024px.
              </p>
            </div>
          </div>
          {
            uploadedPathError &&
            <p className="error">Avataar picture is required.</p>
          }
        </div>
      </div>
    );
  };


  const renderTheme = () => {
    return (
      <div className="gBCtRw themeWrap">
        <div className="lt">
          <p className="title">Theme</p>
          <p className="desc">Set the appearance of WebChat</p>
          <div className="themeSelector">
            <ThemeOption theme={'theme_1'} selected={selectedTheme === 'theme_1'} onThemeClick={onThemeClick} color="#e15700"/>
            <ThemeOption theme={'theme_2'} selected={selectedTheme === 'theme_2'} onThemeClick={onThemeClick} color="#003ae1"/>
            <ThemeOption theme={'theme_3'} selected={selectedTheme === 'theme_3'} onThemeClick={onThemeClick} color="#eb0c73"/>
            <ThemeOption theme={'theme_4'} selected={selectedTheme === 'theme_4'} onThemeClick={onThemeClick} color="#262f3b"/>
            <ThemeOption theme={'theme_5'} selected={selectedTheme === 'theme_5'} onThemeClick={onThemeClick} color="#CF6A7D"/>
          </div>
          {
            themeError &&
            <p className="error">Theme is required.</p>
          }
        </div>
      </div>
    );
  };

  const renderAssistantName = () => {
    return (
      <div className="gBCtRw astntNm">
        <div className="lt">
          <p className="title">Assistant name</p>
          <p className="desc">Set name for the webchat assistant.</p>
          <p className="maxLen">Max length: 20</p>
          <div className="gIpt">
            <input
              type="text"
              value={assistantName}
              onChange={onAssistantNameChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPromptMsg = () => {
    return (
      <div className="gBCtRw prmptMsg">
        <div className="lt">
          <p className="title">Welcome prompt message
            <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
              overlay="This is the message that prompts open next to the chat icon when customers visit your website"
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </p>
          <p className="desc">Set one prompt message across all pages of your website or set unique ones for each page.</p>
          <p className="maxLen">Max length: 30</p>
          <div className="gIpt">
            <input
              type="text"
              value={promptMsg}
              onChange={onPromptMsgChange}
              placeholder="Example: Hi there! Hi can I help you."
            />
          </div>
        </div>
      </div>
    );
  };

  const renderWelcomeMsg = () => {
    return (
      <div className="gBCtRw wlcmMsg">
        <div className="lt">
          <p className="title">Intro message
            <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
              overlay="This is the message that appears at the top of the chat window"
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </p>
          <p className="desc">Set one intro message across all pages of your website.</p>
          <p className="maxLen">Max length: 30</p>
          <div className="gIpt">
            <input
              type="text"
              value={welcomeMsg}
              onChange={onWelcomeMsgChange}
              placeholder="Example: Hi, we are here to help you."
            />
          </div>
        </div>
      </div>
    );
  };

  const renderHelpGuideOnOff = () => {
    return (
      <div className="gBCtRw hasTgl">
        <div className="lt">
          <p className="title">Show Help Guide</p>
          <p className="desc">{ hasHelpGuide ? "Hide help guide in webchat" : "Show help guide in webchat"}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="hasHelpGuide" checked={hasHelpGuide} onChange={onHasHelpGuideChanged}/><label htmlFor="hasHelpGuide">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLauncherPositionDesktop = () => {
    return (
      <>
        <div className="gBCtRw lnchrPos title">
          <div className="lt">
            <p className="title">Chat Launcher Position Desktop & Tablets
              <span className="reset" onClick={onLauncherPositionDesktopResetClick}>Reset</span>
            </p>
            <p className="desc">Choose where you want to place the WebChat launcher icon.</p>
            <div className="selctOpts">
              <Select options={launcherPositionOptions}
                value={launcherPositionValue}
                onChange={onLauncherPositionChange}
              />
            </div>
          </div>
        </div>
        <div className="gBCtRw lnchrPos values">
          <div className="lt">
            {
              launcherPositionValue.value === LAUNCHER_POSITIONS.BOTTOM_LEFT &&
              <p className="title">Space from Left</p>
            }
            {
              launcherPositionValue.value === LAUNCHER_POSITIONS.BOTTOM_RIGHT &&
              <p className="title">Space from Right</p>
            }
            <div className="gIpt">
              <input
                type="number"
                value={sideSpace}
                onChange={onSideSpaceChange}
              />
              px
            </div>
          </div>
          <div className="rt">
            <p className="title">Space from Bottom</p>
            <div className="gIpt">
              <input
                type="number"
                value={bottomSpace}
                onChange={onBottomSpaceChange}
              />
              px
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLauncherPositionMobile = () => {
    return (
      <>
        <div className="gBCtRw lnchrPos title">
          <div className="lt">
            <p className="title">Chat Launcher Position Mobile
              <span className="reset" onClick={onLauncherPositionMobileResetClick}>Reset</span>
            </p>
            <p className="desc">Choose where you want to place the WebChat launcher icon on mobile screens.</p>
            <div className="selctOpts">
              <Select options={launcherPositionMobileOptions}
                value={launcherPositionMobileValue}
                onChange={onLauncherPositionMobileChange}
              />
            </div>
          </div>
        </div>
        <div className="gBCtRw lnchrPos values">
          <div className="lt">
            {
              launcherPositionMobileValue.value === LAUNCHER_POSITIONS.BOTTOM_LEFT &&
              <p className="title">Space from Left</p>
            }
            {
              launcherPositionMobileValue.value === LAUNCHER_POSITIONS.BOTTOM_RIGHT &&
              <p className="title">Space from Right</p>
            }
            <div className="gIpt">
              <input
                type="number"
                value={sideSpaceMobile}
                onChange={onSideSpaceMobileChange}
              />
              px
            </div>
          </div>
          <div className="rt">
            <p className="title">Space from Bottom</p>
            <div className="gIpt">
              <input
                type="number"
                value={bottomSpaceMobile}
                onChange={onBottomSpaceMobileChange}
              />
              px
            </div>
          </div>
        </div>
      </>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('WCGeneral: ', props, ' # ', props);
  }

  const { webchat } = props.settings

  return (
    <div className="gPScrlWrp hDGen flHt wCBasic wCGen">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          General Customizations{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderAgentLogo() }
          { renderTheme() }
          { renderAssistantName() }
          { renderPromptMsg() }
          { renderWelcomeMsg() }
          { renderHelpGuideOnOff() }
          { renderLauncherPositionDesktop() }
          { renderLauncherPositionMobile() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  webchat && webchat.general && webchat.general.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
          { showUploadErrorModal &&
              <GenericAlertModal
                modalType="UploadError"
                title={'Upload Error'}
                bodyRowOne={uploadError}
                setModalVisible={onShowUploadErrorCloseModal}
                onAffirmativeClick={onShowUploadErrorCloseModal}
                affirmativeButtonLabel={'Close'}
                inProgress={false}
                showCancel={false}
              />
            }
        </div>
      </div>
      <div className="webChatPreviewWrap">
        <h4 className="gPageTitle title">
          Preview
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay="A display only preview of WebChat"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </h4>
        <div className="previewBox">
          <WCPreview
            screen={"home"}
            settings={props.settings}
            data={{
              theme: selectedTheme,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WCGeneral;
