import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import WCPreview from '../WCPreview';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, STRS } from '../../../../../helpers';

import '../style.css';


function CheckBoxRow(props) {
  const updateValue = () => {
    if (props.value) return; // do not uncheck
    props.onChange(!props.value);
  };
  const onLabelClick = (e) => { updateValue(); }
  const onChange = (e) => { updateValue(); };
  const onInputChange = (e) => {
    if (e.target.value > 0) {
      props.onInputChange(e.target.value);
    }
  };

  return (
    <div className={'chkBxRw ' + (props.value ? 'selected ' : '') + (props.nested ? 'nested' : '')}>
      <input className="checkbox" type="checkbox" name={props.name} onChange={onChange} value={props.value ? 'on' : 'off'} checked={props.value} />
      <div className="gLabel" onClick={onLabelClick}>
        {props.label}
        {
          props.toolTip &&
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay={props.toolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        }
        {
          props.hasTextInput &&
          <div className="gIpt">
            <input
              className="inputValue"
              type="number"
              value={props.inputValue}
              onChange={onInputChange}
            />
          </div>
        }
      </div>
    </div>
  );
}

function WCDisplay(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [desktopValues, setDesktopValues] = useState({ display: {}, trigger: {} });
  const [mobileValues, setMobileValues] = useState({ display: {}, trigger: {} });

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - WebChat - Display - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { webchat } } = props;
    if (webchat) {
      if (webchat.display) {
        let tempDesktopValues = {};
        tempDesktopValues = webchat.display.desktop || {};
        if (!tempDesktopValues.display) tempDesktopValues.display = {};
        if (!tempDesktopValues.trigger) tempDesktopValues.trigger = {};
        let tempMobileValues = {};
        tempMobileValues = webchat.display.mobile || {};
        if (!tempMobileValues.display) tempMobileValues.display = {};
        if (!tempMobileValues.trigger) tempMobileValues.trigger = {};

        setDesktopValues(tempDesktopValues);
        setMobileValues(tempMobileValues);
      }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.settings.webchat.editInProgress &&
      props.settings.webchat.editStatus &&
      props.settings.webchat.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.webchat]);


  /* Desktop */
  const onDisplayLauncherWithMessageChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      display: {
        'launcher-with-message': true,
      },
    });
    setFormChanged(true);
  };
  const onDisplayLauncherOnlyChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      display: {
        'launcher-only': true,
      },
    });
    setFormChanged(true);
  };
  const onDisplayLauncherWithMessageAndTriggerChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      display: {
        'launcher-with-message-and-trigger': true,
      },
    });
    setFormChanged(true);
  };

  const onTriggerExitIntentChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      trigger: {
        ...desktopValues.trigger,
        'exit-intent': true,
        // 'time-spent': false,
        // 'page-scrolled': false,
        'appear': '',
      },
    });
    setFormChanged(true);
  };
  const onTriggerTimeSpentChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      trigger: {
        ...desktopValues.trigger,
        'exit-intent': false,
        // 'time-spent': true,
        // 'time-spent-value': desktopValues.trigger['time-spent-value'] || '5',
        // 'page-scrolled': false,
        'appear': 'delay',
        'appear_value': '5',
      },
    });
    setFormChanged(true);
  };
  const onTriggerTimeSpentValueChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      trigger: {
        ...desktopValues.trigger,
        // 'time-spent-value': value,
        'appear': 'delay',
        'appear_value': value,
      },
    });
    setFormChanged(true);
  };
  const onTriggerPageScrolledChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      trigger: {
        ...desktopValues.trigger,
        'exit-intent': false,
        // 'time-spent': false,
        'appear': 'scroll',
        'appear_value': '20',
        // 'page-scrolled': true,
        // 'page-scrolled-value': desktopValues.trigger['page-scrolled-value'] || '20',
      },
    });
    setFormChanged(true);
  };
  const onTriggerPageScrolledValueChange = (value) => {
    setDesktopValues({
      ...desktopValues,
      trigger: {
        ...desktopValues.trigger,
        // 'page-scrolled-value': value,
        'appear': 'scroll',
        'appear_value': value,
      },
    });
    setFormChanged(true);
  };

  /* ************** Mobile *************** */

  const onMobileDisplayLauncherWithMessageChange = (value) => {
    setMobileValues({
      ...mobileValues,
      display: {
        'launcher-with-message': true,
      },
    });
    setFormChanged(true);
  };
  const onMobileDisplayLauncherOnlyChange = (value) => {
    setMobileValues({
      ...mobileValues,
      display: {
        'launcher-only': true,
      },
    });
    setFormChanged(true);
  };
  const onMobileDisplayLauncherWithMessageAndTriggerChange = (value) => {
    setMobileValues({
      ...mobileValues,
      display: {
        'launcher-with-message-and-trigger': true,
      },
    });
    setFormChanged(true);
  };

  const onMobileTriggerExitIntentChange = (value) => {
    setMobileValues({
      ...mobileValues,
      trigger: {
        ...mobileValues.trigger,
        'exit-intent': true,
        // 'time-spent': false,
        // 'page-scrolled': false,
        'appear': '',
      },
    });
    setFormChanged(true);
  };
  const onMobileTriggerTimeSpentChange = (value) => {
    setMobileValues({
      ...mobileValues,
      trigger: {
        ...mobileValues.trigger,
        'exit-intent': false,
        // 'time-spent': true,
        // 'time-spent-value': mobileValues.trigger['time-spent-value'] || '5',
        // 'page-scrolled': false,
        'appear': 'delay',
        'appear_value': '5',
      },
    });
    setFormChanged(true);
  };
  const onMobileTriggerTimeSpentValueChange = (value) => {
    setMobileValues({
      ...mobileValues,
      trigger: {
        ...mobileValues.trigger,
        // 'time-spent-value': value,
        'appear': 'delay',
        'appear_value': value,
      },
    });
    setFormChanged(true);
  };
  const onMobileTriggerPageScrolledChange = (value) => {
    setMobileValues({
      ...mobileValues,
      trigger: {
        ...mobileValues.trigger,
        'exit-intent': false,
        // 'time-spent': false,
        // 'page-scrolled': true,
        // 'page-scrolled-value': mobileValues.trigger['page-scrolled-value'] || '20',
        'appear': 'scroll',
        'appear_value': '20',
      },
    });
    setFormChanged(true);
  };
  const onMobileTriggerPageScrolledValueChange = (value) => {
    setMobileValues({
      ...mobileValues,
      trigger: {
        ...mobileValues.trigger,
        // 'page-scrolled-value': value,
        'appear': 'scroll',
        'appear_value': value,
      },
    });
    setFormChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { webchat } = props.settings;
    if (webchat && webchat.display && webchat.display.editInProgress) return;

    let hasError = false;

    // For desktop
    if (!desktopValues || !desktopValues.display ||
      (
        !desktopValues.display['launcher-with-message'] &&
        !desktopValues.display['launcher-only'] &&
        !desktopValues.display['launcher-with-message-and-trigger']
      )
    ) {
      hasError = true;
    }
    if (desktopValues.display['launcher-with-message-and-trigger'] &&
      (
        !desktopValues.trigger['exit-intent'] &&
        !desktopValues.trigger['appear'] // &&
        // !desktopValues.trigger['page-scrolled']
      )
    ) {
      hasError = true;
    }

    // For mobile
    if (!mobileValues || !mobileValues.display ||
      (
        !mobileValues.display['launcher-with-message'] &&
        !mobileValues.display['launcher-only'] &&
        !mobileValues.display['launcher-with-message-and-trigger']
      )
    ) {
      hasError = true;
    }
    if (mobileValues.display['launcher-with-message-and-trigger'] &&
      (
        !mobileValues.trigger['exit-intent'] &&
        !mobileValues.trigger['appear'] // &&
        // !mobileValues.trigger['page-scrolled']
      )
    ) {
      hasError = true;
    }

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-display',
      display: {},
    };
    params.display.desktop = {
      ...desktopValues,
    };
    params.display.mobile = {
      ...mobileValues,
    };

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };


  const renderDesktop = () => {
    return (
      <>
        <div className={'gBCtRw dsplyPref' + (desktopValues.display['launcher-with-message-and-trigger'] ? ' hasTrgr' : '')}>
          <div className="lt">
            <p className="title">Set display behavior on Desktop and Tablets</p>
            <p className="desc">Set your customers' experience when they visit the website.</p>
            <CheckBoxRow
            // value={choiceOneDesktop}
              value={desktopValues.display['launcher-with-message'] || false}
              onChange={onDisplayLauncherWithMessageChange}
              label="Pop open the welcome message as a prompt"
              name="displayLauncherWithMessage"
              toolTip="Pop open the welcome message as a prompt"
            />
            <CheckBoxRow
              // value={choiceTwoDesktop}
              value={desktopValues.display['launcher-only'] || false}
              onChange={onDisplayLauncherOnlyChange}
              label="Only show chat launcher"
              name="displayLauncherOnly"
              toolTip="Only show chat launcher with small icon"
            />
            <CheckBoxRow
              // value={choiceThreeDesktop}
              value={desktopValues.display['launcher-with-message-and-trigger'] || false}
              onChange={onDisplayLauncherWithMessageAndTriggerChange}
              label="Show the welcome message then pop open the chat widget when trigger is met"
              name="displayLauncherWithMessageAndTrigger"
              toolTip="Show the welcome message then pop open the chat widget when trigger is met"
            />
          </div>
        </div>
        {
          desktopValues.display['launcher-with-message-and-trigger'] &&
          <div className="gBCtRw dsplyPref trgrVals">
            <div className="lt">
              <p className="title">Trigger</p>
              <p className="desc">Set the display behaviour.</p>
              <CheckBoxRow
                value={desktopValues.trigger['exit-intent'] || false}
                onChange={onTriggerExitIntentChange}
                label="On exit intent"
                name="triggerExitIntent"
                nested={true}
              />
              <CheckBoxRow
                value={desktopValues.trigger['appear'] === 'delay'}
                onChange={onTriggerTimeSpentChange}
                label="Time on page in seconds"
                name="triggerTimeSpent"
                hasTextInput={true}
                inputValue={desktopValues.trigger['appear'] === 'delay' ? desktopValues.trigger['appear_value'] || '' : ''}
                onInputChange={onTriggerTimeSpentValueChange}
                nested={true}
              />
              <CheckBoxRow
                value={desktopValues.trigger['appear'] === 'scroll'}
                onChange={onTriggerPageScrolledChange}
                label="Percentage of page scrolled"
                name="triggerPageScrolled"
                hasTextInput={true}
                inputValue={desktopValues.trigger['appear'] === 'scroll' ? desktopValues.trigger['appear_value'] || '' : ''}
                onInputChange={onTriggerPageScrolledValueChange}
                nested={true}
              />
            </div>
          </div>
        }
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <div className={'gBCtRw dsplyPref' + (mobileValues.display['launcher-with-message-and-trigger'] ? ' hasTrgr' : '')}>
          <div className="lt">
            <p className="title">Set display behaviour on Mobile</p>
            <p className="desc">Set your customers' experience when they visit the website.</p>
            <CheckBoxRow
              value={mobileValues.display['launcher-with-message'] || false}
              onChange={onMobileDisplayLauncherWithMessageChange}
              label="Pop open the welcome message as a prompt"
              name="mobileDisplayLauncherWithMessage"
              toolTip="Pop open the welcome message as a prompt"
            />
            <CheckBoxRow
              value={mobileValues.display['launcher-only'] || false}
              onChange={onMobileDisplayLauncherOnlyChange}
              label="Only show chat launcher"
              name="mobileDisplayLauncher"
              toolTip="Only show chat launcher with small icon"
            />
            <CheckBoxRow
              value={mobileValues.display['launcher-with-message-and-trigger'] || false}
              onChange={onMobileDisplayLauncherWithMessageAndTriggerChange}
              label="Show the welcome message then pop open the chat widget when trigger is met"
              name="mobileDisplayLauncherWithMessageAndTrigger"
              toolTip="Show the welcome message then pop open the chat widget when trigger is met"
            />
          </div>
        </div>
        {
          mobileValues.display['launcher-with-message-and-trigger'] &&
          <div className="gBCtRw dsplyPref trgrVals">
            <div className="lt">
              <p className="title">Trigger</p>
              <p className="desc">Set the display behaviour.</p>
              <CheckBoxRow
                value={mobileValues.trigger['exit-intent'] || false}
                onChange={onMobileTriggerExitIntentChange}
                label="On exit intent"
                name="mobileTriggerExitIntent"
                nested={true}
              />
              <CheckBoxRow
                value={mobileValues.trigger['appear'] === 'delay'}
                onChange={onMobileTriggerTimeSpentChange}
                label="Time on page in seconds"
                name="mobileTriggerTimeSpent"
                hasTextInput={true}
                inputValue={mobileValues.trigger['appear'] === 'delay' ? mobileValues.trigger['appear_value'] || '' : ''}
                onInputChange={onMobileTriggerTimeSpentValueChange}
                nested={true}
              />
              <CheckBoxRow
                value={mobileValues.trigger['appear'] === 'scroll'}
                onChange={onMobileTriggerPageScrolledChange}
                label="Percentage of page scrolled"
                name="mobileTriggerPageScrolled"
                hasTextInput={true}
                inputValue={mobileValues.trigger['appear'] === 'scroll' ? mobileValues.trigger['appear_value'] || '' : ''}
                onInputChange={onMobileTriggerPageScrolledValueChange}
                nested={true}
              />
            </div>
          </div>
        }
      </>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('WCDisplay: ', props, ' # ', props);
  }

  const { webchat } = props.settings

  return (
    <div className="gPScrlWrp hDGen flHt wCBasic">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Display Preferences{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderDesktop() }
          { renderMobile() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  webchat && webchat.display && webchat.display.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
      <div className="webChatPreviewWrap">
        <h4 className="gPageTitle title">
          Preview
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay="A display only preview of WebChat"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </h4>
        <div className="previewBox">
          <WCPreview
            screen={"home"}
            settings={props.settings}
            data={{
              a: 1,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WCDisplay;
