// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import copy from 'copy-to-clipboard';

import { receiveSnack, removeSnack } from '../../../../../redux/actions/snacksActions';

// import { getReviewsDataAction } from '../../actions/reviewsActions';
import { renderOTALogo, formatDate, getReviewState, RS, mapReviewStateToString, OTA_SOURCE_TO_NAME_FORMAL_MAP } from '../../../../../helpers';

import moreIcon from '../../../../../img/icons/more.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import shareIcon from '../../../../../img/icons/share.svg';
import whatsappIcon from '../../../../../img/icons/whatsapp.png';
import gmailIcon from '../../../../../img/icons/gmail.svg';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import './style.css';

const gtconfig = require('../../../../../gtconfig');

function RecentReviewCard(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [showShareMenuPanel, setShowShareMenuPanel] = useState(false);
  const shareMenuPanelRef = useRef();
  useOnClickOutside(shareMenuPanelRef, useCallback(() => setShowShareMenuPanel(false)));

  const [showMoreMenuPanel, setShowMoreMenuPanel] = useState(false);
  const moreMenuPanelRef = useRef();
  useOnClickOutside(moreMenuPanelRef, useCallback(() => setShowMoreMenuPanel(false)));

  const onShareMenuClick = (e) => {
    setShowShareMenuPanel(showShareMenuPanel => !showShareMenuPanel);
  };
  const onShareOnWhatsappClick = (e) => {
    const review = props.data;
    let message = `*${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}*`;
    message = `${message}\n----------------------------`;
    message = `${message}\n${formatDate(review.review_date)}\n`;
    if (review.title) message = `${message}\n${review.title}`;
    if (review.comment) message = `${message}\n${review.comment}`;
    if (review.comment_negative) message = `${message}\n${review.comment_negative}`;
    message = `${message}\n----------------------------`;
    if (gtconfig.IS_GT) {
      message = `${message}\nPowered by ${gtconfig.APP_NAME_CONTACT}`;
    }
    let urlencodedtext = encodeURIComponent(message);
    let url = `https://wa.me/?text=${urlencodedtext}`;
    window.open(url, '_blank');
    setShowShareMenuPanel(false);
    // gtag('event', 'share', {
    //   'content_type': 'whatsapp',
    //   'item_id': review._id,
    // });
  };
  const onShareOnEmailClick = (e) => {
    const review = props.data;
    let subject = `${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}`;
    let message = `${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}`;
    message = `${message}%0D%0A----------------------------`;
    message = `${message}%0D%0A${formatDate(review.review_date)}%0D%0A`;
    if (review.title) message = `${message}%0D%0A${review.title}`;
    if (review.comment) message = `${message}%0D%0A${review.comment}`;
    if (review.comment_negative) message = `${message}%0D%0A${review.comment_negative}`;
    message = `${message}%0D%0A----------------------------`;
    if (gtconfig.IS_GT) {
      message = `${message}%0D%0APowered by ${gtconfig.APP_NAME_CONTACT} (https://guesttouch.com)`;
    }
    // let urlencodedtext = encodeURIComponent(message);
    // let url = `mailto:?subject=${subject}&body=${message}`;
    let url = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${message}`;
    window.open(url, '_blank');
    setShowShareMenuPanel(false);
    // gtag('event', 'share', {
    //   'content_type': 'email',
    //   'item_id': review._id,
    // });
  };

  const onMoreMenuClick = (e) => {
    setShowMoreMenuPanel(showMoreMenuPanel => !showMoreMenuPanel);
  };

  const onCopyClick = (e) => {
    const review = props.data;
    let message = `${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}`;
    message = `${message}\n`;
    message = `${message}\n${formatDate(review.review_date)}\n`;
    if (review.title) message = `${message}\n${review.title}`;
    if (review.comment) message = `${message}\n${review.comment}`;
    if (review.comment_negative) message = `${message}\n${review.comment_negative}`;
    copy(message);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    setShowMoreMenuPanel(false);
    // gtag('event', 'gt_click', {
    //   'event_category': `Review - Copy`,
    //   'event_label': `Copy`,
    //   'value': review._id,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onMoreClick = (e) => {
    props.onMoreClick(props.data);
  };
  const onRespondClick = (e) => {
    props.onRespondClick(props.data);
  };

  const renderActionButton = (review, reviewState) => {
    if (reviewState === RS.RS_WAITING_FOR_APPROVAL) {
      return <span className="noPadding"><Link to={`${location.pathname}?single_review=${review._id}`}>Approve</Link></span>;
    }
    if (reviewState === RS.RS_NEED_MORE_INFO) {
      return <span className="noPadding"><Link to={`${location.pathname}?single_review=${review._id}`}>Give Info</Link></span>;
    }
    if (reviewState === RS.RS_SELF_RESPONSE_REQUIRED) {
      return <span className="noPadding" onClick={onRespondClick}>Respond</span>;
      return <span className="noPadding"><Link to={`${location.pathname}?single_review=${review._id}`}>Respond</Link></span>;
    }
    return null;
  };

  const renderMoreButton = (review, reviewState) => {
    return <span className="noPadding" onClick={onMoreClick}>More</span>;
    return <span className="noPadding"><Link to={`${location.pathname}?single_review=${review._id}&showReview=1`}>More</Link></span>;
  };

  const renderShareMenu = () => {
    return (
      <div ref={shareMenuPanelRef} className={'dropdown menuBox shareMenu ' + (showShareMenuPanel ? 'open' : '')}>
        <button className="dropbtn" onClick={onShareMenuClick}>
          <span><img src={shareIcon} className="actionIcon" /></span>
        </button>
        <div className="dropdown-content">
          <div className="dcRow" onClick={onShareOnWhatsappClick}>
            <span><img src={whatsappIcon} />WhatsApp</span>
          </div>
          <div className="dcRow gmail" onClick={onShareOnEmailClick}>
            <span><img src={gmailIcon} />Gmail</span>
          </div>
        </div>
      </div>
    );
  }

  const renderMoreMenu = () => {
    return (
      <div ref={moreMenuPanelRef} className={'dropdown menuBox moreMenu ' + (showMoreMenuPanel ? 'open' : '')}>
        <button className="dropbtn" onClick={onMoreMenuClick}>
          <span><img src={moreIcon} className="actionIcon" /></span>
        </button>
        <div className="dropdown-content">
          {/*
            reviewState !== RS.RS_HAS_MANAGER_RESPONSE &&
            <div className="dcRow" onClick={onMoreMenuMarkAsRespondedClick}>
              <span>Mark as responded</span>
            </div>
          */}
          <div className="dcRow" onClick={onCopyClick}>
            <span>Copy</span>
          </div>
        </div>
      </div>
    );
  }

  const renderContent = (review) => {
    if (!review) return null;
    const reviewState = getReviewState(review, props.hasSelfResponse);
    if (process.env.NODE_ENV !== 'production') {
      console.log('RecentReviewCard review: ', review._id);
      console.log('RecentReviewCard reviewState: ', reviewState);
    }
    var starStyle = { "--rating": review.rating_out_of_5 };
    return (
      <div className="innerBox" id={`rr_${review._id}`}>
        <div className="headerBox">
          <div className="left">
            <div className="ota">
              { renderOTALogo(review.source) }
            </div>
            <div className="ratingBox">
              {
                review.author && review.author.name
                ?
                  <div className="author">
                    {review.author.name}
                  </div>
                : null
              }
              <div className="rating">
                <div className="stars">
                  <div className="reviewStars" style={starStyle}></div>
                </div>
                <div className="ratingValue">
                  {review.rating_out_of_5} / 5
                </div>
              </div>
              {
                review.review_date &&
                <div className="reviewDate">
                  {formatDate(review.review_date)}
                </div>
              }
            </div>
          </div>
          <div className="right">

            {
              props.showReviewState
              ?
                <>
                  {
                    reviewState !== RS.RS_HAS_MANAGER_RESPONSE
                    ?
                      <div className={`reviewState ${reviewState}`}>
                        <span>{mapReviewStateToString(reviewState)}</span>
                      </div>
                    : null
                  }
                </>
              : null
            }
            {/*<div className="share">
              <span><i className="fa fa-share-alt" /></span>
            </div>
            <div className="cMenu">
              <img src={moreIcon} />
            </div>*/}
            <div className="cMenu">
              { renderShareMenu() }
            </div>
            <div className="cMenu">
              { renderMoreMenu() }
            </div>
          </div>
        </div>
        <div className="commentBox">
          { review.title && <p className="title">{review.title}</p> }
          <p className="comment">{review.comment}</p>
        </div>
        {
          props.showActionCTA &&
          <div className="actionBox">
            <div className="buttonWrap">
              <div className={'gButton'}>
                { renderActionButton (review, reviewState) }
              </div>
            </div>
          </div>
        }
        {
          props.showMore &&
          <div className="moreBox">
            { renderMoreButton(review, reviewState) }
          </div>
        }
      </div>
    )
  };





  if (process.env.NODE_ENV !== 'production') {
    console.log('RecentReviewCard props: ', props);
  }

  return (
    <div className="gCrd recentReviewCard">
        { renderContent(props.data) }
    </div>
  );
}

export default RecentReviewCard;
