import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBar from '../Reputation/SideBar';
import Home from './Home';
import Locations from './Locations';
import LocationWrapper from './LocationWrapper';
import ToolTipWrapper from '../Misc/ToolTipWrapper';

import { ACCESS_LEVELS } from '../../helpers';

import './Engage.css';

const gtconfig = require('../../gtconfig');

function Engage() {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `Engage Messaging - ${gtconfig.APP_NAME_CONTACT}`;
  }, []);

  const onEnableEngageClick = (e) => {
    try { window.Intercom('showNewMessage', 'Enable engage for us'); } catch(e) {}
  };
  const onScheduleDemoClick = (e) => {
    try { window.Intercom('showArticle', 8738544); } catch(e) {}
  };

  const renderRightSideBar = () => {
    const { locations, locations_list } = userState.user.user_info;
    const filteredList = locations_list.filter((el) => {
      if (locations[el] && locations[el].unreadCount) return true;
      return false;
    });
    if (!filteredList.length || filteredList.length === 1) return null;
    return (
      <div className="rsb">
        <div className="lcUrLst">
          {
            filteredList.length
            ?
              filteredList
              .sort((a,b) => {
              if (locations[a].unreadCount && !locations[b].unreadCount) return -1;
              if (locations[b].unreadCount && !locations[a].unreadCount) return 1;
              if (locations[b].unreadCount && locations[a].unreadCount) return 0;
              return 0;
            }).map((item) => {
              const { name, name_short_code, sms_number, unreadCount } = locations[item];
              if (!name || !name.trim()) return null;
              return (
                <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={name}>
                  <Link to={`/engage/locations/${item}/messages`}>
                    <span>{name_short_code || name.trim().substring(0,1)}</span>
                    <div className="uc" />
                  </Link>
                </ToolTipWrapper>
              );
              // return <LocationRow key={`tb_l_${item}`} location={locations[item]} appInfo={appState.appInfo} userState={userState} onRowClick={onLocationRowClick} />
            })
            : null
          }
        </div>
      </div>
    );
  };


  const isReadOnly = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;


  let hasAnyEngage = false;
  if (userState.user.user_info.profile.locations && userState.user.user_info.locations) {
    const locIdList = Object.keys(userState.user.user_info.profile.locations);
    for (let i = 0; i < locIdList.length; i++) {
      if (userState.user.user_info.locations[locIdList[i]] && userState.user.user_info.locations[locIdList[i]].has_engage) {
        hasAnyEngage = true; break;
      }
    }
  }

    // if (!userState.user.user_info.profile.access_level || !userState.user.user_info.profile.locations)
  // userState.user.user_info.profile.locations
  if (!hasAnyEngage) {
    return (
      <div className="mb iframeBx">
        <iframe id="iframeId"
          title={gtconfig.APP_NAME_CONTACT}
          src="https://guesttouch.com/internal-guest-messaging"
        >
        </iframe>
        <div className="prdCtaBox">
          <button className="gBtn" onClick={onScheduleDemoClick}>Schedule Demo</button>
          <button className="gBtn" onClick={onEnableEngageClick}>Enable Engage</button>
        </div>
      </div>
    );
  }


  return (
    <div className="mb eg">
      <Routes>
        <Route element={<Outlet />}>
          <Route path="locations/*" element={<Outlet />}>
            <Route path=":locationidentifier/*" element={<SideBar isReadOnly={isReadOnly} />} />
            <Route index element={<SideBar isReadOnly={isReadOnly} />} />
          </Route>
          <Route index element={<SideBar isReadOnly={isReadOnly} />} />
        </Route>
      </Routes>
      <div className="mc">
        <Routes>
          <Route element={<Outlet />}>
            <Route path="locations" element={<Outlet />}>
              <Route path=":locationidentifier/*" element={<LocationWrapper isReadOnly={isReadOnly} />} />
              <Route index element={<Locations isReadOnly={isReadOnly} />} />
            </Route>
            <Route index element={<Locations isReadOnly={isReadOnly} />} />
          </Route>
        </Routes>
      </div>
      { renderRightSideBar() }
    </div>
  );
}

export default Engage;
