import {
  Routes,
  Route,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SideBarInner from '../SideBarInner';
import Info from './Info';
import ChangePassword from './ChangePassword';
import Notifications from './Notifications';

const gtconfig = require('../../../gtconfig');

function Profile() {
  const userState = useSelector((state) => state.userReducer);

  useEffect(() => {
    try {
      document.title = `Profile - ${gtconfig.APP_NAME_CONTACT}`;
      // window.Intercom('shutdown');
    } catch (e) {}
  }, []);

  return (
    <div className="mb st">
      <div className="mc">
        <SideBarInner />
        <div className="sc">
          <Routes>
            <Route path="info" element={<Info userInfo={userState.user.user_info} />} />
            <Route path="change-password" element={<ChangePassword userInfo={userState.user.user_info} />} />
            <Route path="notifications" element={<Notifications userInfo={userState.user.user_info} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Profile;
