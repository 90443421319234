import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import GenericSideModal from '../../../../../Misc/GenericSideModal';
import MessageBox from '../../../../../Misc/MessageBox';
import PreDefinedMessage from '../../../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';
import WCPreview from '../../WCPreview';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, STRS } from '../../../../../../helpers';

import '../../style.css';


function WCConfigureWhatsapp(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [starterMessage, setStarterMessage] = useState({
    message: '',
    messageId: '',
  });
  const [nbhStarterMessage, setNbhStarterMessage] = useState({
    message: '',
    messageId: '',
  });

  const [successTitle, setSuccessTitle] = useState('');
  const [successTitleError, setSuccessTitleError] = useState('');

  const [successDesc, setSuccessDesc] = useState('');
  const [successDescError, setSuccessDescError] = useState('');

  const [selectedAddedWhatsappTemplate, setSelectedAddedWhatsappTemplate] = useState(null);
  const [showAddWhatsappTemplateModal, setShowAddWhatsappTemplateModal] = useState(false);
  const [addWhatsappTemplateType, setAddWhatsappTemplateType] = useState(false);
  const addTemplateModalRef = useRef();

  const [someChange, setSomeChange] = useState(false);

  const setInitialValues = () => {
    const { settings: { webchat, engage } } = props;
    if (webchat) {
      if (webchat.channels && webchat.channels.whatsapp) {
        if (webchat.channels.whatsapp.starter_msg_id && engage && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
          const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === webchat.channels.whatsapp.starter_msg_id);
          if (process.env.NODE_ENV !== 'production') {
            console.log('WCConfigureWhatsapp foundMsg: ', foundMsg);
          }
          if (foundMsg) {
            setStarterMessage(starterMessage => ({
              ...starterMessage,
              message: foundMsg.msg,
              messageId: webchat.channels.whatsapp.starter_msg_id,
            }));
          }
        }
        if (webchat.channels.whatsapp.non_business_hours_starter_msg_id && engage && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
          const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === webchat.channels.whatsapp.non_business_hours_starter_msg_id);
          if (foundMsg) {
            setNbhStarterMessage(nbhStarterMessage => ({
              ...nbhStarterMessage,
              message: foundMsg.msg,
              messageId: webchat.channels.whatsapp.non_business_hours_starter_msg_id,
            }));
          }
        }
        setSuccessTitle(webchat.channels.whatsapp.success_title || '');
        setSuccessDesc(webchat.channels.whatsapp.success_desc || '');
      }
    }
    setSomeChange(false);
  };

  const clearAction = () => {
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'whatsapp',
    };
    dispatch(editDataClearAction({
      op: 'edit_webchat_settings',
      params,
    }));
  };

  useEffect(() => {
    setInitialValues();
    clearAction();
  }, []);

  useEffect(() => {
    if (!props.settings.webchat.editInProgress &&
      props.settings.webchat.editStatus &&
      props.settings.webchat.editStatus.success
    ) {
      setSomeChange(false);
    }
  }, [props.settings.webchat]);

  const onSuccessTitleChange = (value) => {
    setSuccessTitle(value);
    setSomeChange(true);
    if (!value) {
      setSuccessTitleError('error');
    } else {
      setSuccessTitleError('');
    }
    clearAction();
  };

  const onSuccessDescChange = (value) => {
    setSuccessDesc(value);
    setSomeChange(true);
    if (!value) {
      setSuccessDescError('error');
    } else {
      setSuccessDescError('');
    }
    clearAction();
  };

  const onStarterMessageChange = (messageId) => {
    const { engage } = props.settings;
    if (engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        setStarterMessage(starterMessage => ({
          ...starterMessage,
          messageId,
          message: foundMsg.msg,
        }));
      }
    }
    setSomeChange(true);
    clearAction();
  };

  const onNbhStarterMessageChange = (messageId) => {
    const { engage } = props.settings;
    if (engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        setNbhStarterMessage(nbhStarterMessage => ({
          ...nbhStarterMessage,
          messageId,
          message: foundMsg.msg,
        }));
      }
    }
    setSomeChange(true);
    clearAction();
  };

  const onAddNewWhatsappTemplateClick = (msgType) => {
    setSelectedAddedWhatsappTemplate(null);
    setAddWhatsappTemplateType('webchatWhatsappStarterMessage');
    setShowAddWhatsappTemplateModal(true);
  };
  const onAddNewNbHWhatsappTemplateClick = (msgType) => {
    setSelectedAddedWhatsappTemplate(null);
    setAddWhatsappTemplateType('webchatWhatsappNbhStarterMessage');
    setShowAddWhatsappTemplateModal(true);
  };
  const onAddWhatsappTemplateModalCloseClick = (pathParam) => {
    setShowAddWhatsappTemplateModal(false);
  };
  const onShowAddedWhatsappTemplateClick = (templateData) => {
    setShowAddWhatsappTemplateModal(true);
    setSelectedAddedWhatsappTemplate(templateData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (someChange) performSave();
  };

  const performSave = () => {
    const { webchat } = props.settings;
    if (webchat && webchat.channels && webchat.channels.whatsapp && webchat.channels.whatsapp.editInProgress) return;

    let hasError = false;

    setSuccessTitleError(successTitle ? '' : 'error');
    setSuccessDescError(successDesc ? '' : 'error');

    if (!starterMessage || !starterMessage.messageId ||
      !nbhStarterMessage || !nbhStarterMessage.messageId ||
      !successTitle || !successDesc
    ) return;

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'whatsapp',
      channel_data: {
        starter_msg_id: starterMessage.messageId,
        non_business_hours_starter_msg_id: nbhStarterMessage.messageId,
        success_title: successTitle,
        success_desc: successDesc,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
    setSomeChange(false);
  };


  const renderAddTemplateModal = () => {
    return (
      <GenericSideModal
        modalType="addWhatsappMessageTemplate"
        className="mtGen"
        data={{
          mode: selectedAddedWhatsappTemplate ? 'view' : '',
          messageType: addWhatsappTemplateType, // "webchatWhatsappStarterMessage",
          template: selectedAddedWhatsappTemplate ? { ...selectedAddedWhatsappTemplate } : {},
        }}
        extData={{
          title: 'Add Whatsapp Message Template',
          settings: props.settings
        }}
        setModalVisible={onAddWhatsappTemplateModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('WCConfigureWhatsapp: ', props, ' # ', props);
  }

  const { webchat } = props.settings

  return (
    <div className="gPScrlWrp hDGen flHt wCBasic">
      <div className="gPWrp hDGen">
        <div className="gPTle allChnls">
          <Link to={`/settings/locations/${urlParams.locationidentifier}/webchat/channels`}>All Channels</Link> > Whatsapp
          {props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          <PreDefinedMessage
            className="webchatWhatsappStarterMessage"
            type="webchatWhatsappStarterMessage"
            messageLabel="Conversation starter message"
            title="Conversation starter message"
            desc="Initial message that will be sent to initiate the conversation on WhatsApp."
            dropdownLabel="Select approved whatsapp template"
            hasMessage={true}
            messageId={starterMessage.messageId}
            message={starterMessage.message}
            sendAfter={null}
            onHasMessageChange={null}
            onMessageIdChange={onStarterMessageChange}
            onSendAfterChange={null}
            locationInfo={props.locationInfo}
            settings={props.settings}
            dynamicLabel={null}
            onAddNewTemplateClick={onAddNewWhatsappTemplateClick}
            onShowAddedTemplateClick={onShowAddedWhatsappTemplateClick}
          />
          <PreDefinedMessage
            className="webchatWhatsappStarterMessage"
            type="webchatWhatsappNbhStarterMessage"
            messageLabel="Conversation starter message for non business hours"
            title="Conversation starter message for non business hours"
            desc="Initial message that will be sent to initiate the conversation on WhatsApp."
            dropdownLabel="Select approved whatsapp template"
            hasMessage={true}
            messageId={nbhStarterMessage.messageId}
            message={nbhStarterMessage.message}
            sendAfter={null}
            onHasMessageChange={null}
            onMessageIdChange={onNbhStarterMessageChange}
            onSendAfterChange={null}
            locationInfo={props.locationInfo}
            settings={props.settings}
            dynamicLabel={null}
            onAddNewTemplateClick={onAddNewNbHWhatsappTemplateClick}
            onShowAddedTemplateClick={onShowAddedWhatsappTemplateClick}
          />
          <MessageBox
            title={"Success line 1"}
            subTitle={"Success message line 1, to be shown to the guest."}
            error={successTitleError}
            onTextChange={onSuccessTitleChange}
            message={successTitle}
            maxLength={60}
          />
          <MessageBox
            title={"Success line 2"}
            subTitle={"Success message line 2, to be shown to the guest."}
            error={successDescError}
            onTextChange={onSuccessDescChange}
            message={successDesc}
            maxLength={160}
          />
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  webchat && webchat.channels && webchat.channels.whatsapp && webchat.channels.whatsapp.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
        { showAddWhatsappTemplateModal && renderAddTemplateModal() }
      </div>
      <div className="webChatPreviewWrap">
        <h4 className="gPageTitle title">
          Preview
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay="A display only preview of WebChat"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </h4>
        <div className="previewBox">
          <WCPreview
            screen={"channels-whatsapp"}
            settings={props.settings}
            data={{
              a: 1,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WCConfigureWhatsapp;
