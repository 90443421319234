// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import Mousetrap from 'mousetrap';

// import { getEmailTemplatesAction } from '../../../actions/emailTemplateActions';
// import GuestListPanel from './GuestListPanel';

import { validateEmail } from '../../../../../../helpers';

import closeIcon from '../../../../../../img/icons/close.svg';

import './style.css';

const modalRoot = document.getElementById('modal-send-test-email');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SendTestEmailModal(props) {
  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - Alert - ${props.modalType}`,
    //   user_id: props.userprops.user.uid,
    // });
    if (props.to) setEmail(props.to);
    Mousetrap.bind('esc', () => props.setModalVisible(false));
    return () => {
      Mousetrap.unbind('esc');
    };
  }, []);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCloseClick = (e) => {
    props.setModalVisible(false);
  };
  const onCancelClick = (e) => {
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    if (props.inProgress) return;
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email');
      return;
    }
    props.onAffirmativeClick(email);
  };

  const onAffirmativeSecondaryClick = (e) => {
    props.onAffirmativeSecondaryClick();
  };

  const onEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
    if (!value) {
      setEmailError('Email is required');
    } else {
      setEmailError(validateEmail(value) ? '' : 'Invalid email');
    }
  };

  const renderModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="sendTestEmailModal" onClick={onModalClick}>
          <div className="contentWrap">
            <div className="content">
              <div className="title">
                {
                  props.title || 'Send test email'
                }
                <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
              </div>
              <div className="body">
                {
                  props.bodyRowOne &&
                  <div className="rowOne">
                    {props.bodyRowOne}
                  </div>
                }
                {
                  props.bodyRowNote &&
                  <div className="rowNote">
                    <b>Note: </b>{props.bodyRowNote}
                  </div>
                }
                <div className="emailInput">
                  <input type="email" value={email} onChange={onEmailChange} />
                </div>
                <div className="emailInputError">
                  {emailError || ''}
                </div>
                {
                  props.currentStatus && (props.currentStatus.success || props.currentStatus.error) &&
                  <div className="currentStatus">
                    {
                      props.currentStatus.error && <p className="error">{props.currentStatus.message || 'Error in sending'}</p>
                    }
                    {
                      props.currentStatus.success && <p className="success">Sent successfully</p>
                    }
                  </div>
                }
                <div className="actions">
                  {
                    props.showCancel &&
                    <button className="cancel" onClick={onCancelClick}>Cancel</button>
                  }
                  <button className="ok" onClick={onAffirmativeClick}>
                    { props.affirmativeButtonLabel || 'Confirm' }
                    { props.inProgress && <i className="fa fa-spinner fa-spin" /> }
                  </button>
                  {
                    props.onAffirmativeSecondaryClick &&
                    <button className="ok secondary" onClick={onAffirmativeSecondaryClick}>
                      { props.affirmativeSecondaryButtonLabel || 'Confirm' }
                      { props.inProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const renderContent = () => {
    if (showModal) return renderModal();
    return null;
  };

  return renderContent();
}

export default SendTestEmailModal;
