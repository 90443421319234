import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import ToolTipWrapper from '../../Misc/ToolTipWrapper';

import { onSideBarToggle, setLocationIdentifier, setGroupIdentifier } from '../../../redux/actions/appActions';

import collapseIcon from '../../../img/icons/collapse.svg';
import feedbacksIcon from '../../../img/icons/feedback.svg';
import feedbacksIconActive from '../../../img/icons/feedback-active.svg';
import templatesIcon from '../../../img/icons/settings/settings-templates.svg';
import templatesActiveIcon from '../../../img/icons/settings/settings-templates-active.svg';

import infoIcon from '../../../img/icons/info.png';
import infoActiveIcon from '../../../img/icons/info-active.png';
import passwordIcon from '../../../img/icons/password.png';
import passwordActiveIcon from '../../../img/icons/password-active.png';
import notificationsIcon from '../../../img/icons/settings/alerts.png';
import notificationsActiveIcon from '../../../img/icons/settings/alerts-active.png';


import roomsIcon from '../../../img/icons/settings/rooms.svg';
import roomsActiveIcon from '../../../img/icons/settings/rooms-active.svg';
import rateCodesIcon from '../../../img/icons/settings/rate-codes.svg';
import rateCodesActiveIcon from '../../../img/icons/settings/rate-codes-active.svg';

import hotelDetailsIcon from '../../../img/icons/settings/settings-hotel-details.svg';
import hotelDetailsActiveIcon from '../../../img/icons/settings/settings-hotel-details-active.svg';

import './SideBarInner.css';



/* Profile */
let PROFILE_MENU_OPTIONS_TITLE = 'Profile';
let PROFILE_MENU_OPTIONS = [];
PROFILE_MENU_OPTIONS.push({
  label: 'Info',
  desc: 'Edit profile here',
  id: 'info',
  icon: infoIcon,
  iconActive: infoActiveIcon,
  ttText: 'Info',
});
PROFILE_MENU_OPTIONS.push({
  label: 'Change Password',
  desc: 'Change password here',
  id: 'change-password',
  icon: passwordIcon,
  iconActive: passwordActiveIcon,
  ttText: 'Change Password',
});
PROFILE_MENU_OPTIONS.push({
  label: 'Notifications',
  desc: 'Manage notifications here',
  id: 'notifications',
  icon: notificationsIcon,
  iconActive: notificationsActiveIcon,
  ttText: 'Notifications',
});



/* Hotel Details */
let HOTEL_DETAILS_MENU_OPTIONS_TITLE = 'Hotel Details';
let HOTEL_DETAILS_MENU_OPTIONS = [];
HOTEL_DETAILS_MENU_OPTIONS.push({
  label: 'General Information',
  desc: 'Manage information about your hotel',
  id: 'general-information',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'General Information',
});
HOTEL_DETAILS_MENU_OPTIONS.push({
  label: 'Rooms',
  desc: 'Add & manage your rooms',
  id: 'rooms',
  icon: roomsIcon,
  iconActive: roomsActiveIcon,
  ttText: 'Rooms',
});
HOTEL_DETAILS_MENU_OPTIONS.push({
  label: 'Remote Locks',
  desc: 'Add & manage your remote locks',
  id: 'remote-locks',
  icon: roomsIcon,
  iconActive: roomsActiveIcon,
  ttText: 'Remote Locks',
});
HOTEL_DETAILS_MENU_OPTIONS.push({
  label: 'Rate Codes',
  desc: 'Manage your rate codes for email targeting',
  id: 'rate-codes',
  icon: rateCodesIcon,
  iconActive: rateCodesActiveIcon,
  ttText: 'Rate Codes',
});
HOTEL_DETAILS_MENU_OPTIONS.push({
  label: 'Booking Sources',
  desc: 'Manage your booking sources for email targeting',
  id: 'booking-sources',
  icon: roomsIcon,
  iconActive: roomsActiveIcon,
  ttText: 'Booking Sources',
});


/* Templates */
let TEMPLATES_MENU_OPTIONS_TITLE = 'Templates';
let TEMPLATES_MENU_OPTIONS = [];
TEMPLATES_MENU_OPTIONS.push({
  label: 'Message Templates',
  desc: 'Add & manage templates',
  id: 'message-templates',
  icon: templatesIcon,
  iconActive: templatesActiveIcon,
  ttText: 'Message Templates',
});
TEMPLATES_MENU_OPTIONS.push({
  label: 'WhatsApp Starter Templates',
  desc: 'Add & manage WhatsApp starter templates',
  id: 'whatsapp-starter-templates',
  icon: templatesIcon,
  iconActive: templatesActiveIcon,
  ttText: 'WhatsApp Starter Templates',
});


/* Journey Messages */
let JOURNEY_MESSAGES_MENU_OPTIONS_TITLE = 'Journey Messages';
let JOURNEY_MESSAGES_MENU_OPTIONS = [];
JOURNEY_MESSAGES_MENU_OPTIONS.push({
  label: 'Guest Journey SMS',
  desc: 'Set pre-defined guest journey messages for sms medium',
  id: 'sms',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Guest Journey SMS',
});
JOURNEY_MESSAGES_MENU_OPTIONS.push({
  label: 'Guest Journey Whatsapp',
  desc: 'Set pre-defined guest journey messages for WhatsApp medium',
  id: 'whatsapp',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Guest Journey WhatsApp',
});
JOURNEY_MESSAGES_MENU_OPTIONS.push({
  label: 'Guest Journey Email',
  desc: 'Set pre-defined guest journey messages for email medium',
  id: 'email',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Guest Journey Email',
});
JOURNEY_MESSAGES_MENU_OPTIONS.push({
  label: 'Rule based Journey Messages',
  desc: 'Set pre-defined guest journey messages based on rules',
  id: 'rule-based',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Rule based Journey Messages',
});



/* WebChat */
let WEBCHAT_MENU_OPTIONS_TITLE = 'WebChat';
let WEBCHAT_MENU_OPTIONS = [];
WEBCHAT_MENU_OPTIONS.push({
  label: 'General Customizations',
  desc: 'Change look, feel, and position',
  id: 'general',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'General Customizations',
});
WEBCHAT_MENU_OPTIONS.push({
  label: 'Display Preferences',
  desc: 'Change behavior & display',
  id: 'display',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Display Preferences',
});
WEBCHAT_MENU_OPTIONS.push({
  label: 'Help Guide',
  desc: 'Add help categories and answers',
  id: 'help-guide',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Help Guide',
});
WEBCHAT_MENU_OPTIONS.push({
  label: 'Messaging Channels',
  desc: 'Configure messaging channels',
  id: 'channels',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Messaging Channels',
});
WEBCHAT_MENU_OPTIONS.push({
  label: 'Rules',
  desc: 'Customize display behavior using specific rules',
  id: 'rules',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Rules',
});
WEBCHAT_MENU_OPTIONS.push({
  label: 'Deploy',
  desc: 'Add WebChat on your website',
  id: 'deploy',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Deploy',
});


/* Digital Guide */
let DIGITAL_GUIDE_MENU_OPTIONS_TITLE = 'Digital Guide';
let DIGITAL_GUIDE_MENU_OPTIONS = [];
// DIGITAL_GUIDE_MENU_OPTIONS.push({
//   label: 'General Customizations',
//   desc: 'Change look, feel, and position',
//   id: 'general',
//   icon: hotelDetailsIcon,
//   iconActive: hotelDetailsActiveIcon,
//   ttText: 'General Customizations',
// });
// DIGITAL_GUIDE_MENU_OPTIONS.push({
//   label: 'Display Preferences',
//   desc: 'Change behavior & display',
//   id: 'display',
//   icon: hotelDetailsIcon,
//   iconActive: hotelDetailsActiveIcon,
//   ttText: 'Display Preferences',
// });
// DIGITAL_GUIDE_MENU_OPTIONS.push({
//   label: 'Help Guide',
//   desc: 'Add help categories and answers',
//   id: 'help-guide',
//   icon: hotelDetailsIcon,
//   iconActive: hotelDetailsActiveIcon,
//   ttText: 'Help Guide',
// });
// DIGITAL_GUIDE_MENU_OPTIONS.push({
//   label: 'Messaging Channels',
//   desc: 'Configure messaging channels',
//   id: 'channels',
//   icon: hotelDetailsIcon,
//   iconActive: hotelDetailsActiveIcon,
//   ttText: 'Messaging Channels',
// });
DIGITAL_GUIDE_MENU_OPTIONS.push({
  label: 'Hotel Information',
  desc: 'Add / Edit hotel information sections',
  id: 'hotel-information',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Hotel Information',
});
DIGITAL_GUIDE_MENU_OPTIONS.push({
  label: 'Rooms',
  desc: 'Add / Edit room specific information',
  id: 'rooms',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Rooms',
});
// DIGITAL_GUIDE_MENU_OPTIONS.push({
//   label: 'Deploy',
//   desc: 'Add WebChat on your website',
//   id: 'deploy',
//   icon: hotelDetailsIcon,
//   iconActive: hotelDetailsActiveIcon,
//   ttText: 'Deploy',
// });


/* Reviews Widget */
let REVIEWS_WIDGET_MENU_OPTIONS_TITLE = 'Reviews Widget';
let REVIEWS_WIDGET_MENU_OPTIONS = [];
REVIEWS_WIDGET_MENU_OPTIONS.push({
  label: 'General',
  desc: 'Change number of reviews displayed, date format, etc',
  id: 'general',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'General',
});
REVIEWS_WIDGET_MENU_OPTIONS.push({
  label: 'Rules',
  desc: 'Customize display behavior using specific rules',
  id: 'rules',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Rules',
});

/* Response Templates */
let RESPONSE_TEMPLATES_MENU_OPTIONS_TITLE = 'Response Templates';
let RESPONSE_TEMPLATES_MENU_OPTIONS = [];
RESPONSE_TEMPLATES_MENU_OPTIONS.push({
  label: 'Templates',
  desc: 'Manage templates',
  id: 'templates',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Templates',
});
RESPONSE_TEMPLATES_MENU_OPTIONS.push({
  label: 'Categories',
  desc: 'Manage categories',
  id: 'categories',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Categories',
});

/* Prompt Templates */
let PROMPT_TEMPLATES_MENU_OPTIONS_TITLE = 'guestAI™';
let PROMPT_TEMPLATES_MENU_OPTIONS = [];
PROMPT_TEMPLATES_MENU_OPTIONS.push({
  label: 'Templates',
  desc: 'Create pre-written prompts to craft highly personalized and efficient responses',
  id: 'templates',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Templates',
});
PROMPT_TEMPLATES_MENU_OPTIONS.push({
  label: 'Categories',
  desc: 'Create and manage prompt categories',
  id: 'categories',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'Categories',
});
PROMPT_TEMPLATES_MENU_OPTIONS.push({
  label: 'guestAi™ Guest Messaging Model',
  desc: 'guestAi™ Guest Messaging Model',
  id: 'guest-messaging-model',
  icon: hotelDetailsIcon,
  iconActive: hotelDetailsActiveIcon,
  ttText: 'guestAi™ Guest Messaging Model',
});



const MenuEntry = ({ id, className, pathname, label, desc, link, linkState, icon, iconActive, ttText, menuOpen }) => {
  let active = pathname.indexOf(link) > -1;
  // if (link === '/reputation/locations') {
  //   if (pathname === '/reputation/locations' || pathname === '/reputation/locations/') { active = true; } else { active = false; }
  // }
  return (
    <li className={(className ? className : '') + (active ? ' active' : '')} key={id}>
      <ToolTipWrapper placement="right" overlayClassName={'sbTooltip ' + (menuOpen ? 'hide' : '')}
        overlay={ttText}
      >
      <Link to={link} state={linkState || {}}>
        <div className="lt">
          <img src={active ? iconActive : icon} />
        </div>
        <div className="rt">
          <p>{label}</p>
          <span>{desc}</span>
        </div>
      </Link>
      </ToolTipWrapper>
    </li>
  );
}

function SideBarInner(props) {
  const user = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [product, setProduct] = useState('');
  const [settingFor, setSettingFor] = useState('');

  const [screenBeforeSettings, setScreenBeforeSettings] = useState({});

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SideBarInner urlParams: ', urlParams);
      console.log('SideBarInner location: ', location);
    }
    const { pathname, state } = location;
    if (pathname.indexOf('/reputation') > -1) setProduct('reputation');
    if (pathname.indexOf('/engage') > -1) setProduct('engage');
    if (pathname.indexOf('/marketing') > -1) setProduct('marketing');
    if (pathname.indexOf('/profile') > -1) setSettingFor('profile');
    if (pathname.indexOf('/hotel-details') > -1) setSettingFor('hotel-details');
    if (pathname.indexOf('/templates') > -1) setSettingFor('templates');
    if (pathname.indexOf('/webchat') > -1) setSettingFor('webchat');
    if (pathname.indexOf('/digital-guide') > -1) setSettingFor('digital-guide');
    if (pathname.indexOf('/journey-messages') > -1) setSettingFor('journey-messages');
    if (pathname.indexOf('/response-templates') > -1) setSettingFor('response-templates');
    if (pathname.indexOf('/guestai-templates') > -1) setSettingFor('guestai-templates');
    if (pathname.indexOf('/reviews-widget') > -1) setSettingFor('reviews-widget');

    setScreenBeforeSettings(state || {});

  }, [urlParams, location]);

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  const onSideBarToggleClick = (e) => {
    dispatch(onSideBarToggle({ open: !appState.sideBarOpen }));
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('SideBarInner props: ', props);
    console.log('SideBarInner screenBeforeSettings: ', screenBeforeSettings);
  }

  if (!location) return null;
  const { pathname } = location;
  const { locationidentifier } = urlParams;

  let title = '';
  let menuOptions = [];

  if (settingFor === 'profile') {
    title = PROFILE_MENU_OPTIONS_TITLE;
    menuOptions = PROFILE_MENU_OPTIONS;
  }
  if (settingFor === 'hotel-details') {
    title = HOTEL_DETAILS_MENU_OPTIONS_TITLE;
    menuOptions = HOTEL_DETAILS_MENU_OPTIONS;
  }
  if (settingFor === 'templates') {
    title = TEMPLATES_MENU_OPTIONS_TITLE;
    menuOptions = TEMPLATES_MENU_OPTIONS;
  }
  if (settingFor === 'journey-messages') {
    title = JOURNEY_MESSAGES_MENU_OPTIONS_TITLE;
    menuOptions = JOURNEY_MESSAGES_MENU_OPTIONS;
  }
  if (settingFor === 'webchat') {
    title = WEBCHAT_MENU_OPTIONS_TITLE;
    menuOptions = WEBCHAT_MENU_OPTIONS;
  }
  if (settingFor === 'digital-guide') {
    title = DIGITAL_GUIDE_MENU_OPTIONS_TITLE;
    menuOptions = DIGITAL_GUIDE_MENU_OPTIONS;
  }
  if (settingFor === 'response-templates') {
    title = RESPONSE_TEMPLATES_MENU_OPTIONS_TITLE;
    menuOptions = RESPONSE_TEMPLATES_MENU_OPTIONS;
  }
  if (settingFor === 'guestai-templates') {
    title = PROMPT_TEMPLATES_MENU_OPTIONS_TITLE;
    menuOptions = PROMPT_TEMPLATES_MENU_OPTIONS;
  }
  if (settingFor === 'reviews-widget') {
    title = REVIEWS_WIDGET_MENU_OPTIONS_TITLE;
    menuOptions = REVIEWS_WIDGET_MENU_OPTIONS;
  }

  const { screenBeforeSettingsName, screenBeforeSettingsPath } = screenBeforeSettings;

  const hasScreenBeforeSettings = screenBeforeSettingsName && screenBeforeSettingsPath;


  if (settingFor === 'profile') {
    return (
      <div className={'sbi'}>
        <div className="fxd top">
          <div className="ttl">
            {title}
          </div>
          {
            hasScreenBeforeSettings &&
            <div className="backRow">
              Back to <Link to={screenBeforeSettingsPath}>{screenBeforeSettingsName}</Link>
            </div>
          }
        </div>
        <div className="mn">
          <ul className="menu" >
            {
              menuOptions.map((entry) => {
                if (settingFor === 'profile') {
                  return (
                    <MenuEntry
                      key={`ssb_me_${entry.id}`}
                      id={`ssb_me_${entry.id}`}
                      className={entry.className ? entry.className : ''}
                      label={entry.label} desc={entry.desc} pathname={pathname}
                      link={`/${settingFor}/${entry.id}`}
                      linkState={screenBeforeSettings}
                      icon={entry.icon} iconActive={entry.iconActive}
                      ttText={entry.ttText}
                    />
                  )
                }
                return null;
              })
            }
          </ul>
        </div>
      </div>
    );
  }

  const { settings } = locationState.locations && locationidentifier && locationState.locations[locationidentifier]
    ? locationState.locations[locationidentifier] : {};
  if (!settings || !settings.info) return null;

  return (
    <div className={'sbi'}>
      <div className="fxd top">
        <div className="ttl">
          {title}
        </div>

      </div>
      <div className="mn">
        <ul className="menu" >
          {
            menuOptions.map((entry) => {
              if (settingFor === 'profile') {
                return (
                  <MenuEntry
                    key={`ssb_me_${entry.id}`}
                    id={`ssb_me_${entry.id}`}
                    className={entry.className ? entry.className : ''}
                    label={entry.label} desc={entry.desc} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${settingFor}/${entry.id}`}
                    linkState={screenBeforeSettings}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                )
              }
              if (settingFor === 'templates' && entry.id === 'whatsapp-starter-templates') {
                if (props.locationInfo && props.locationInfo.has_engage_whatsapp) {
                  return (
                    <MenuEntry
                      key={`ssb_me_${entry.id}`}
                      id={`ssb_me_${entry.id}`}
                      className={entry.className ? entry.className : ''}
                      label={entry.label} desc={entry.desc} pathname={pathname}
                      link={`/settings/locations/${locationidentifier}/${settingFor}/${entry.id}`}
                      linkState={screenBeforeSettings}
                      icon={entry.icon} iconActive={entry.iconActive}
                      ttText={entry.ttText}
                    />
                  )
                }
                return null;
              }
              if (settingFor === 'hotel-details' && entry.id === 'remote-locks') {
                if (!settings.info.has_remote_lock) {
                  // return (
                  //   <MenuEntry
                  //     key={`ssb_me_${entry.id}`}
                  //     id={`ssb_me_${entry.id}`}
                  //     className={entry.className ? entry.className : ''}
                  //     label={entry.label} desc={entry.desc} pathname={pathname}
                  //     link={`/${settingFor}/${entry.id}`}
                  //     icon={entry.icon} iconActive={entry.iconActive}
                  //     ttText={entry.ttText}
                  //   />
                  // )
                  return null;
                }
                // return null;
              }
              if (settingFor === 'guestai-templates' && entry.id === 'guest-messaging-model') {
                if (!settings.info.has_guest_ai) {
                  return null;
                }
              }
              return (
                <MenuEntry
                  key={`ssb_me_${entry.id}`}
                  id={`ssb_me_${entry.id}`}
                  className={entry.className ? entry.className : ''}
                  label={entry.label} desc={entry.desc} pathname={pathname}
                  link={`/settings/locations/${locationidentifier}/${settingFor}/${entry.id}`}
                  linkState={screenBeforeSettings}
                  icon={entry.icon} iconActive={entry.iconActive}
                  ttText={entry.ttText}
                />
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default SideBarInner;
