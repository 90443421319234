// @flow

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch, Prompt } from 'react-router-dom';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import './style.css';

import emojiIcon from '../../../img/icons/emoji.svg';
import closeIcon from '../../../img/icons/close.svg';


function MessageBox(props) {
  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const emojiSelectorRef = useRef(null);
  const messageEl = useRef(null);

  const onTextChange = (e) => {
    props.onTextChange(e.target.value);
  };
  const onOpenEmojiSelectorClick = (e) => {
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      props.onTextChange(`${props.message.substring(0,messageEl.current.selectionStart)}${emoji.native}${props.message.substring(messageEl.current.selectionStart)}`);
    }
  }

  let maxLength = {};
  if (props.maxLength) maxLength.maxLength = props.maxLength;

  return (
    <div className="gBCtRw msgRw">
      <div className="lt">
        <p className="title">{props.title}</p>
        <p className="desc">{props.subTitle}</p>
        { props.maxLength && <p className="maxLen">Max length: {props.maxLength}</p> }
        <div className={'gIpt ' + (props.error ? 'error' : '')}>
          <textarea rows="4" placeholder="Message"
            onChange={onTextChange}
            placeholder="Answer..."
            value={props.message}
            ref={messageEl}
            { ...maxLength }
          />
          { props.error && <p className="error">Message is required</p> }
        </div>
        <div className="emojiRw">
          <div className={'emojiBox ' + (props.error ? 'messageError' : '')}>
            <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
              {
                emojiSelectorOpen
                ? <img src={closeIcon} />
                : <img src={emojiIcon} />
              }

            </div>
          </div>
        </div>
        { emojiSelectorOpen &&
          <div className="emojiBox" ref={emojiSelectorRef}>
            <Picker data={emojiData}
              onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default MessageBox;
