import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { getHighestAccessLevels, isLocationAdminAndAbove, hasAccessToUserManagment } from '../../../helpers';

// import SideBar from './SideBar';

import './SettingsHome.css';

function SettingsHome() {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let urlParams = useParams();

  const { locationidentifier } = urlParams;

  const LinkUsers = <Link to={`/settings/locations/${locationidentifier}/users`}>users</Link>
  const LinkAddNewUsers = <Link to={`/settings/locations/${locationidentifier}/users`}>add new users</Link>
  const LinkAssignLocations = <Link to={`/settings/locations/${locationidentifier}/users`}>assign locations</Link>

  const LinkHotelDetailsGeneric = <Link to={`/settings/locations/${locationidentifier}/hotel-details/general-information`}>general information</Link>
  const LinkHotelDetailsRooms = <Link to={`/settings/locations/${locationidentifier}/hotel-details/rooms`}>rooms</Link>
  const LinkHotelDetailsRateCodes = <Link to={`/settings/locations/${locationidentifier}/hotel-details/rate-codes`}>rate codes</Link>
  const LinkHotelDetailsBookingSources = <Link to={`/settings/locations/${locationidentifier}/hotel-details/booking-sources`}>booking sources</Link>

  const LinkReviewChannels = <Link to={`/settings/locations/${locationidentifier}/review-channels`}>Review Channels</Link>
  const LinkAlerts = <Link to={`/settings/locations/${locationidentifier}/alerts`}>Alerts</Link>
  const LinkResponseTemplates = <Link to={`/settings/locations/${locationidentifier}/response-templates/templates`}>Response Templates</Link>
  const LinkAutoResponses = <Link to={`/settings/locations/${locationidentifier}/response-approval`}>Response Approval</Link>
  const LinkReports = <Link to={`/settings/locations/${locationidentifier}/reports`}>Reports</Link>
  const LinkReviewsWidget = <Link to={`/settings/locations/${locationidentifier}/reviews-widget`}>Reviews Widget</Link>


  const LinkMessagingChannels = <Link to={`/settings/locations/${locationidentifier}/messaging-channels`}>Messaging Channels</Link>
  const LinkManageTemplates = <Link to={`/settings/locations/${locationidentifier}/templates`}>Templates</Link>
  const LinkGenericTemplates = <Link to={`/settings/locations/${locationidentifier}/generic-templates`}>Generic Templates</Link>
  const LinkWhatsappStarterTemplates = <Link to={`/settings/locations/${locationidentifier}/whatsapp-starter-templates`}>Whatsapp Starter Templates</Link>
  const LinkLabels = <Link to={`/settings/locations/${locationidentifier}/labels`}>Labels</Link>
  const LinkWebChat = <Link to={`/settings/locations/${locationidentifier}/webchat`}>WebChat</Link>

  const LinkJourneyMessages = <Link to={`/settings/locations/${locationidentifier}/journey-messages`}>Journey Messages</Link>
  const LinkJourneyMessagesSms = <Link to={`/settings/locations/${locationidentifier}/journey-messages/sms`}>Sms</Link>
  const LinkJourneyMessagesWhatsapp = <Link to={`/settings/locations/${locationidentifier}/journey-messages/whatsapp`}>Whatsapp</Link>
  const LinkJourneyMessagesEmail = <Link to={`/settings/locations/${locationidentifier}/journey-messages/email`}>Email</Link>
  const LinkJourneyMessagesRuleBased = <Link to={`/settings/locations/${locationidentifier}/journey-messages/rule-based`}>Rule Based</Link>

  const LinkGbm = <Link to={`/settings/locations/${locationidentifier}/google-business-messages`}>Google Business Messages</Link>

  const LinkTransactionEmails = <Link to={`/settings/locations/${locationidentifier}/transaction-emails`}>Transaction Emails</Link>
  const LinkSenderNames = <Link to={`/settings/locations/${locationidentifier}/sender-names`}>Sender Names</Link>
  const LinkSenderEmails = <Link to={`/settings/locations/${locationidentifier}/sender-emails`}>Sender Emails</Link>


  const { reputation_enabled, engage_enabled, marketing_enabled } = appState.appInfo;

  const { settings } = locationState.locations[locationidentifier];
  if (!settings || !settings.info) return null;

  const { accessLevel } = getHighestAccessLevels(userState.user.user_info, locationidentifier);

  return (
    <div className="sCw sh">
      <div className="hdr">
        <h4>All Settings</h4>
      </div>
      <div className="bd">
        <div className="grd">
          {
            hasAccessToUserManagment(accessLevel) &&
            <div className="gi">
              <h3>Account Management</h3>
              <p>Manage {LinkUsers}, {LinkAddNewUsers}, {LinkAssignLocations}, and more</p>
            </div>
          }
          <div className="gi">
            <h3>Hotel Details</h3>
            <p>Manage {LinkHotelDetailsGeneric} about the property, such as Check-In/Check-Out time, {LinkHotelDetailsRooms}, {LinkHotelDetailsRateCodes}, {LinkHotelDetailsBookingSources}, and more</p>
          </div>
          {
            reputation_enabled && settings.info.has_reputation &&
            <div className="gi">
              <h3>Reputation</h3>
              <p>Manage {LinkAlerts}, {LinkResponseTemplates},
              { settings.info.has_gt_response && <> {LinkAutoResponses},</> } configure {LinkReports}.</p>
            </div>
          }
          {
            engage_enabled && settings.info.has_engage &&
            <div className="gi">
              <h3>Engage</h3>
              <p>Manage {LinkMessagingChannels}, {LinkManageTemplates}, {LinkLabels}, {LinkWebChat}, {LinkJourneyMessages} and {LinkGbm}.</p>
            </div>
          }
          {
            marketing_enabled && settings.info.has_transaction_emails &&
            <div className="gi">
              <h3>Email Marketing</h3>
              <p>Manage {LinkTransactionEmails}, {LinkSenderNames} and {LinkSenderEmails} settings.</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default SettingsHome;
