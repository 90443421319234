import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';

import RouteLeavingGuard from '../../../Misc/RouteLeavingGuard';
import GenericAlertModal from '../../../Misc/GenericAlertModal';
import GBMChip from '../../../Misc/GBMChip';
import BusinessHours from '../../../Misc/BusinessHours';
import ToggleAndData from '../../../Misc/ToggleAndData';
import GenericSideModal from '../../../Misc/GenericSideModal';
import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { validateEmail, STRS } from '../../../../helpers';

import avataarIcon from '../../../../img/icons/avataar.svg';

import './style.css';

const gtconfig = require('../.././../../gtconfig');

const DEFAULT_AUTO_REPLY = 'Hi this is an automated message, We are currently off business hours for text messaging. You may receive a reply later or during our business hours. Please reach out to front desk or our staff if we can be any help. Thank you.';

const firstId = new Date().getTime();

function GBM(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const isGoogleVerifiedRef = useRef(null);

  const [isGoogleVerified, setIsGoogleVerified] = useState(false);
  const [uploadedPath, setUploadedPath] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadedPathError, setUploadedPathError] = useState('');
  const [uploadedPathChanged, setUploadedPathChanged] = useState(false);

  const [agentName, setAgentName] = useState('');
  const [agentNameError, setAgentNameError] = useState('');
  const [agentNameChanged, setAgentNameChanged] = useState(false);

  const [welcomeMsg, setWelcomeMsg] = useState('');
  const [welcomeMsgError, setWelcomeMsgError] = useState('');
  const [welcomeMsgChanged, setWelcomeMsgChanged] = useState(false);

  const [suggestedReplies, setSuggestedReplies] = useState({ [firstId]: {} });
  const [suggestedRepliesChanged, setSuggestedRepliesChanged] = useState(false);
  const [suggestedRepliesError, setSuggestedRepliesError] = useState('');

  const [businessHours, setBusinessHours] = useState({});
  const [businessHoursChanged, setBusinessHoursChanged] = useState(false);

  const [automatedMsgBusinessHours, setAutomatedMsgBusinessHours] = useState(false);
  const [automatedMsgBusinessHoursText, setAutomatedMsgBusinessHoursText] = useState('');

  const [automatedMsgNonBusinessHours, setAutomatedMsgNonBusinessHours] = useState(false);
  const [automatedMsgNonBusinessHoursText, setAutomatedMsgNonBusinessHoursText] = useState('');
  const [automatedMsgChanged, setAutomatedMsgChanged] = useState(false);

  const [formError, setFormError] = useState('');
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GBM urlParams: ', urlParams);
      console.log('GBM locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(getDataAction({
      op: 'get_gbm_settings',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);

  const setInitialValues = () => {
    const { settings: { gbm } } = props;
    if (gbm) {
      isGoogleVerifiedRef.current = gbm.is_google_verified || false;
      setIsGoogleVerified(gbm.is_google_verified || false);
      setUploadedPath(gbm.agent_logo_url || '');
      setAgentName(gbm.agent_name || '');
      setWelcomeMsg(gbm.welcome_message || '');
      setSuggestedReplies(gbm.suggested_replies && Object.keys(gbm.suggested_replies).length ? gbm.suggested_replies : { [firstId]: {} });
      setBusinessHours(gbm.business_hours || {});
      setAutomatedMsgBusinessHours(gbm.has_automated_msg_business_hours || false);
      setAutomatedMsgBusinessHoursText(gbm.automated_msg_business_hours || '');
      setAutomatedMsgNonBusinessHours(gbm.has_automated_msg_non_business_hours || false);
      setAutomatedMsgNonBusinessHoursText(gbm.automated_msg_non_business_hours || '');
      setFormChanged(false);
      setFormChanged(false);
    }
  };


  useEffect(() => {
    setInitialValues();
  }, [props.settings.gbm]);

  useEffect(() => {
    if (props.settings.gbm &&
      !props.settings.gbm.editInProgress &&
      props.settings.gbm.editStatus &&
      props.settings.gbm.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.gbm]);


  const onAgentNameChange = (e) => {
    const { name, value } = e.target;
    setAgentName(value);
    setAgentNameError(value ? '' : 'error');
    setFormChanged(true);
    setAgentNameChanged(true);
  };

  const onWelcomeMsgChange = (e) => {
    const { name, value } = e.target;
    setWelcomeMsg(value);
    setWelcomeMsgError(value ? '' : 'error');
    setFormChanged(true);
    setWelcomeMsgChanged(true);
  };

  const onBusinessHoursChange = (values) => {
    setBusinessHours(values);
    setFormChanged(true);
    setBusinessHoursChanged(true);
  };

  const onAutomatedMsgBusinessHoursChange = (value) => {
    setAutomatedMsgBusinessHours(value);
    setFormChanged(true);
    setAutomatedMsgChanged(true);
  };
  const onAutomatedMsgBusinessHoursTextChange = (value) => {
    setAutomatedMsgBusinessHoursText(value);
    setFormChanged(true);
    setAutomatedMsgChanged(true);
  };

  const onAutomatedMsgNonBusinessHoursChange = (value) => {
    setAutomatedMsgNonBusinessHours(value);
    setFormChanged(true);
    setAutomatedMsgChanged(true);
  };
  const onAutomatedMsgNonBusinessHoursTextChange = (value) => {
    setAutomatedMsgNonBusinessHoursText(value);
    setFormChanged(true);
    setAutomatedMsgChanged(true);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { gbm } = props.settings;
    if (gbm && gbm.editInProgress) return;

    let hasError = false;

    if (!uploadedPath) { setUploadedPathError('error'); hasError = true; }
    if (!agentName) { setAgentNameError('error'); hasError = true; }
    if (!welcomeMsg) { setWelcomeMsgError('error'); hasError = true; }

    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadedPath: ', uploadedPath);
      console.log('agentName: ', agentName);
      console.log('welcomeMsg: ', welcomeMsg);
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('businessHours: ', businessHours);
    }

    if (Object.keys(businessHours).length) {
      Object.keys(businessHours).forEach((day) => {
        if (!hasError && businessHours[day] && businessHours[day].isActive) {
          if (!businessHours[day].startTime || businessHours[day].startTime === 'select') {
            hasError = true;
          }
          if (businessHours[day].startTime !== '24 hours' && (!businessHours[day].endTime || businessHours[day].endTime === 'select')) {
            hasError = true;
          }
        }
      });
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('suggestedReplies: ', suggestedReplies);
    }
    setSuggestedRepliesError('');
    if (Object.keys(suggestedReplies).length) {
      Object.keys(suggestedReplies).forEach((id) => {
        if (!hasError && suggestedReplies[id] && suggestedReplies[id].isActive) {
          if (!suggestedReplies[id].title || !suggestedReplies[id].title.trim()) {
            hasError = true;
            // setSuggestedRepliesError('Empty question is not allowed. Add a suggested question or remove suggested question.');
          } else {
            // setSuggestedRepliesError('');
          }
          if (!suggestedReplies[id].shortTitle || !suggestedReplies[id].shortTitle.trim()) {
            hasError = true;
          }
          if (suggestedReplies[id].hasMessage && (!suggestedReplies[id].message || !suggestedReplies[id].message.trim())) {
            hasError = true;
          }
        } else {
          setSuggestedRepliesError('Empty question is not allowed. Add a suggested question or remove suggested question.');
        }
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log('suggestedReplies hasError: ', hasError);
      }
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('hasError: ', hasError);
    }
    if (hasError) setFormError('error');
    if (hasError) return;

    setFormError('');

    let finalSuggestedReplies = {};
    Object.keys(suggestedReplies).forEach((item) => {
      if (Object.keys(suggestedReplies[item]).length) {
        finalSuggestedReplies[item] = suggestedReplies[item];
        if (suggestedReplies[item].title.trim()) {
          finalSuggestedReplies[item].title = suggestedReplies[item].title.trim();
          finalSuggestedReplies[item].key = suggestedReplies[item].title.replace(/\s+/g, '-').toLowerCase();
        }
      }
    });

    const params = {
      location__id: urlParams.locationidentifier,
    };
    if (uploadedPathChanged) params.agent_logo_url = uploadedPath;
    if (welcomeMsgChanged) params.welcome_message = welcomeMsg;
    if (agentNameChanged) params.agent_name = agentName;
    if (suggestedRepliesChanged) params.suggested_replies = finalSuggestedReplies;
    if (businessHoursChanged) params.business_hours = businessHours;
    if (automatedMsgChanged) {
      params.has_automated_msg_business_hours = automatedMsgBusinessHours;
      params.automated_msg_business_hours = automatedMsgBusinessHoursText;
      params.has_automated_msg_non_business_hours = automatedMsgNonBusinessHours;
      params.automated_msg_non_business_hours = automatedMsgNonBusinessHoursText;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }

    dispatch(editDataAction({
      op: 'edit_gbm_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };


  const onDrop = useCallback((acceptedFiles) => {

    // Do something with the files
    if (process.env.NODE_ENV !== 'production') {
      console.log('acceptedFiles: ',acceptedFiles);
      // console.log('rejectedFiles: ',rejectedFiles);
    }
    if (acceptedFiles.length===0) {
      // swal('Oops', 'The file is not valid.', 'error');
      return;
    }
    if (isGoogleVerifiedRef.current) {
      return;
    }
    if (acceptedFiles.length === 1) {
      // setMode('dnd');
      // setManualInput('');
      acceptedFiles.forEach((file) => {

        if ( /\.(jpe?g|png)$/i.test(file.name) === false ) {
          setUploadError(`Image must be of PNG or JPG type.`);
          setShowUploadErrorModal(true);
          return false;
        }

        // setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            // console.log('fileAsBinaryString: ',fileAsBinaryString);
            console.log('e.target.result: ',e.target.result);
          }
          //Initiate the JavaScript Image object.
          var image = new Image();

          //Set the Base64 string return from FileReader as source.


          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (process.env.NODE_ENV !== 'production') {
              console.log('height: ', height);
              console.log('width: ', width);
            }
            if (height !== 1024 || width != 1024) {
              setUploadError(`Image must be 1024px X 1024px. Tried one is ${height}px X ${width}px`);
              setShowUploadErrorModal(true);
              return false;
            }
            uploadFile(file);
            return true;
          };
          image.src = e.target.result;
          if (fileAsBinaryString) {
            // uploadFile(file);
            if (fileAsBinaryString.indexOf(',') === -1) {
              // No commas found
              // swal('Oops', 'Not a CSV ( Comma Seperated File ).', 'warning');

              return;
            }

            // let pResult = parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // if (process.env.NODE_ENV !== 'production') {
            //   console.log('parseResult: ', parseResult);
            // }
            // setParseResult(pResult);
            // this.parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // this.setState(prevState => ({ dragAndDropActive: true }));

          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsDataURL(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

  const uploadFile = (file) => {
    if (isGoogleVerified) return;
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile: ', file);
    }
    setUploadedPath('');
    setUploadInProgress(true);

    let filename = file.name.replace(/\s/g,'');
    let fileExtension = 'jpg';
    let tArr = filename.split('.');
    if (tArr.length >= 2) {
      fileExtension = tArr[tArr.length-1];
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('fileExtension: ', fileExtension);
    }

    let postData = new FormData();
    postData.append('file', file, file.name);
    postData.append('name', filename);
    postData.append('new_name', `agent_logo.${fileExtension}`);
    // postData.append('uid', props.userprops.user.uid);
    postData.append('location_id', props.locationInfo.location_id);
    postData.append('identifier', filename);
    // let myhtl = {}, sizes = [];
    // sizes.push({ width: 400, suffix: 'll', size_identifier: 'size1' });
    // sizes.push({ width: 200, suffix: 'sll', size_identifier: 'size2' });
    // myhtl['sizes'] = sizes;
    // postData.append('myhtl', JSON.stringify(myhtl));

    // setForm(prevForm => ({
    //   ...prevForm,
    //   [`${name}UploadInProgress`]: true,
    // }));


    // //get data from database
    let url = gtconfig.IMG_UPLOAD_URL;
    const header = { 'gt-access-token' : "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa", 'Content-Type': 'multipart/form-data' };
    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   'x-api-key': "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa",
    // };
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile called api');
    }
    axios.post(url, postData, { headers: header }).then((response) => {
      // setUploadInProgress(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('uploadFile response: ', response['data']);
      }
      const { status } = response.data;
      const { data } = response.data;
      if (status === 'success' && data && data.name && data.path) {
        setUploadedPath(data.path);
        setFormChanged(true);
        setUploadedPathError('');
        setUploadedPathChanged(true);
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [data.identifier]: data.name, //feedbackEmailHotelLogoUrl
        //   [`${data.identifier}Error`]: 'success',
        //   [`${data.identifier}UploadInProgress`]: false,
        // }));
      } else {
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [name]: '',
        //   [`${name}Error`]: 'Error is uploading.',
        //   [`${name}UploadInProgress`]: false,
        // }));
      }
      setUploadInProgress(false);
    })
      .catch((err) => {
        console.log('uploadFile err--------------------: ',err);
      });

  };
  const onShowUploadErrorCloseModal = (e) => {
    setShowUploadErrorModal(false);
    setUploadError('');
    setUploadedPath('');
    setUploadInProgress(false);
  };

  const onSRActiveChange = (id, value) => {
    const temp = { ...suggestedReplies };
    temp[id].isActive = value;
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };
  const onSRTitleChange = (id, value) => {
    const temp = { ...suggestedReplies };
    temp[id].title = value.slice(0,35);
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };
  const onSRShortTitleChange = (id, value) => {
    const temp = { ...suggestedReplies };
    temp[id].shortTitle = value.slice(0,25);
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };
  const onSRHasMessageChange = (id, value) => {
    const temp = { ...suggestedReplies };
    temp[id].hasMessage = value;
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };
  const onSRMessageChange = (id, value) => {
    const temp = { ...suggestedReplies };
    temp[id].message = value;
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };
  const onSRAddClick = (e) => {
    const temp = { ...suggestedReplies };
    temp[new Date().getTime()] = {};
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };
  const onSRRemoveClick = (id) => {
    const temp = { ...suggestedReplies };
    delete temp[id];
    setSuggestedReplies(temp);
    setFormChanged(true);
    setSuggestedRepliesChanged(true);
  };


  const renderAvataarImg = () => {
    if (uploadedPath) return <img src={uploadedPath} />;
    if (uploadInProgress) return <span><i className="fa fa-spinner fa-spin" /></span>;
    return <img src={avataarIcon} />;
  };

  const renderAgentLogo = () => {
    return (
      <div className={'gBCtRw' + (isGoogleVerified ? ' disabled' : '')}>
        <div className="lt">
          <p className="title">Agent display picture</p>
          <p className="desc">Set an image to display in the conversation interface.</p>
          <p className="desc">Once agent becomes live, display picture cannot be changed!</p>
          <div {...getRootProps({className: 'dndBox'})}>
            <input {...getInputProps()} />
            <div className="profileImgBox">
              { renderAvataarImg() }
            </div>
            <div className="info">
              <div className="top">Drag your image here or <div className="gButton"><span>Click to Upload</span></div></div>
              <p className="supportInfo">
                Supported formats: PNG, JPG. Size must be 1024px X 1024px.
              </p>
              <p className="supportInfo">
                In conversations, logos display as 1024 px diameter circles. Make sure that your logo displays well as a circle.
              </p>
            </div>
          </div>
          {
            uploadedPathError &&
            <p className="error">Agent display picture is required.</p>
          }
          {
            isGoogleVerifiedRef.current &&
            <div className="error">
              Agent is already LIVE, display picture cannot be changed now! Please contact us for the same.
            </div>
          }
        </div>
      </div>
    );
  };

  const renderAgentName = () => {
    return (
      <div className="gBCtRw revLnk">
        <div className="lt">
          <p className="title">Agent display name</p>
          <p className="desc">This name will be displayed along with the display picture in the conversation interface.</p>
          <p className="desc">Once agent becomes live, agent name cannot be changed!.</p>
          <div className="gIpt">
            <input
              type="text"
              value={agentName}
              onChange={onAgentNameChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderWelcomeMsg = () => {
    return (
      <div className="gBCtRw revLnk">
        <div className="lt">
          <p className="title">Welcome message</p>
          <p className="desc">This message will be the first message of the conversation.</p>
          <p className="desc">Once updated, it may take few hours to reflect in the conversation interface.</p>
          <div className="gIpt">
            <input
              type="text"
              value={welcomeMsg}
              onChange={onWelcomeMsgChange}
            />
          </div>
          {
            welcomeMsgError &&
            <p className="gErr">Welcome message is required.</p>
          }
        </div>
      </div>
    );
  };

  const renderSuggestedReplies = () => {
    return (
      <div className="gBCtRw revLnk">
        <div className="lt">
          <p className="title">Agent conversation starters</p>
          <p className="desc">A list of suggestions, in the form of conversation chips for the user to engage with the agent.</p>
          <p className="desc mb20">Once updated, it may take few hours to reflect in the conversation interface.</p>
          {
            Object.keys(suggestedReplies).map((item) => {
              return (
                <GBMChip
                  key={`gbmchip_${item}`}
                  id={item}
                  data={suggestedReplies[item]}
                  onActiveChange={onSRActiveChange}
                  onTitleChange={onSRTitleChange}
                  onShortTitleChange={onSRShortTitleChange}
                  onHasMessageChange={onSRHasMessageChange}
                  onMessageChange={onSRMessageChange}
                  onRemoveClick={onSRRemoveClick}
                />
              );
            })
          }
          {
            suggestedRepliesError &&
            <p className="error">{suggestedRepliesError}</p>
          }
          <div className="addRow">
            <p onClick={onSRAddClick}>Add <i className="fa fa-plus-circle" /></p>
          </div>
        </div>
      </div>
    );
  };

  const renderBusinessHours = () => {
    return (
      <div className="gBCtRw businessHours">
        <div className="lt">
          <p className="title">Set business hours</p>
          <p className="desc">Hours during which staff can reply to incoming messages</p>
          <BusinessHours
            businessHours={businessHours}
            onBusinessHoursChange={onBusinessHoursChange}
          />
        </div>
      </div>
    );
  };

  const renderAutomatedMsgs = () => {
    const { locationInfo } = props;
    return (
      <>
        <ToggleAndData
          title="Automated message during business hours?"
          subTitle="Automatic message to be sent during the hours above."
          name={"automatedMsgBusinessHours"}
          hasTextArea={true}
          // textareaTitle="Sms message"
          toggleOn={automatedMsgBusinessHours}
          onToggleChange={onAutomatedMsgBusinessHoursChange}
          textValue={automatedMsgBusinessHoursText}
          onTextChange={onAutomatedMsgBusinessHoursTextChange}
          // error={automatedMsgNonBusinessHoursError}
        />
      </>
    );
  };

  const renderAutomatedMsgsNbh = () => {
    const { locationInfo } = props;
    return (
      <>
        <ToggleAndData
          title="Automated message during non business hours?"
          subTitle="What message should go to customer when they reach out to you during non-business hours."
          name={"automatedMsgNonBusinessHours"}
          hasTextArea={true}
          // textareaTitle="Sms message"
          toggleOn={automatedMsgNonBusinessHours}
          onToggleChange={onAutomatedMsgNonBusinessHoursChange}
          textValue={automatedMsgNonBusinessHoursText}
          onTextChange={onAutomatedMsgNonBusinessHoursTextChange}
          // error={automatedMsgNonBusinessHoursError}
        />
      </>
    );
  };



  if (process.env.NODE_ENV !== 'production') {
    console.log('GBM: ', props, ' # ', props);
  }

  if (!props.locationInfo.has_engage) return null;

  const { gbm } = props.settings;

  return (
    <>
    {
      gbm && gbm.getInProgress &&
      <div className="gPnlLdng">
        <i className="fa fa-spinner fa-spin" />
      </div>
    }
    <div className="gPScrlWrp hDGen flHt gbmStngs">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Manage Google Business Messages{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderAgentLogo() }
          { renderAgentName() }
          { renderWelcomeMsg() }
          { renderSuggestedReplies() }
          { renderBusinessHours() }
          { renderAutomatedMsgs() }
          { renderAutomatedMsgsNbh() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  gbm && gbm.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
          { showUploadErrorModal &&
            <GenericAlertModal
              modalType="UploadError"
              title={'Upload Error'}
              bodyRowOne={uploadError}
              setModalVisible={onShowUploadErrorCloseModal}
              onAffirmativeClick={onShowUploadErrorCloseModal}
              affirmativeButtonLabel={'Close'}
              inProgress={false}
              showCancel={false}
            />
          }
        </div>
      </div>
    </div>
    </>
  );
}

export default GBM;
