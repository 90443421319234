
import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Highlighter from "react-highlight-words";

import { DATE_DURATION_OPTIONS, formatDate, FEEDBACK_SOURCE_TO_LABEL } from '../../../../../helpers';

// import SentimentMentions from './SentimentMentions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import moreIcon from '../../../../../img/icons/more.svg';
import happyIcon from '../../../../../img/icons/happy.svg';
import sadIcon from '../../../../../img/icons/sad.svg';

import './style.css';

import 'antd/dist/antd.css';

const gtconfig = require('../../../../../gtconfig');



function SingleSent(props) {
  const [replyText, setReplyText] = useState('');
  const [showReplyTextBox, setShowReplyTextBox] = useState(false);
  const [showRepliedText, setShowRepliedText] = useState(false);
  const [showGuestInfo, setShowGuestInfo] = useState(false);

  const guestInfoRef = useRef();
  useOnClickOutside(guestInfoRef, useCallback(() => setShowGuestInfo(false)));

  const onReplyClick = (e) => {
    setShowReplyTextBox(true);
  };
  const onSendReplyClick = (e) => {

  };
  const onSendReplyLaterClick = (e) => {
    setShowReplyTextBox(false);
  };
  const onReplyTextChange = (e) => {
    const { name, value } = e.target;
    setReplyText(value);
  };
  const onShowRepliedTextClick = (e) => {
    setShowRepliedText(showRepliedText => !showRepliedText);
  };
  const onGuestInfoClick = (e) => {
    setShowGuestInfo(showGuestInfo => !showGuestInfo);
  }

  const renderReplyTextBox = (check_out_feedback_email) => {
    if (!showReplyTextBox) {
      return (
        <div className="submitRow">
          <div className="buttonWrap">
            <div className={'gButton'} onClick={onReplyClick}>
              <span>Reply</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="replyTextBox">
        <div className="vBar">
        </div>
        <div className="content">
          <textarea
            value={replyText}
            onChange={onReplyTextChange}
            placeholder="Enter reply..."
          />
          <div className="submitRow">
            <div className={'gButton ' + (!replyText ? 'inactive' : '')} onClick={onSendReplyClick}>
              <span>Send Reply</span>
            </div>
            <div className={'gButton '} onClick={onSendReplyLaterClick}>
              <span>Close</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRepliedTextBox = (check_out_feedback_email) => {
    return (
      <div className="repliedTextBox">
        <div className="vBar">
        </div>
        <div className="content">
          <div className="topRow">
            {/*<div className="yourReply">
              Your Reply
            </div>*/}
            <div className="showReply" onClick={onShowRepliedTextClick}>
              {`${showRepliedText ? 'Hide' : 'Show'} Reply`}
            </div>
          </div>
          {
            showRepliedText &&
            <div className="textRow">
              {check_out_feedback_email.reply_text}
            </div>
          }
        </div>
      </div>
    );
  }

  const renderGuestInfo = (guestInfo) => {
    return (
      <div ref={guestInfoRef} className={'dropdown guestInfo ' + (showGuestInfo ? 'open' : '')}>
        <div className="dropbtn" onClick={onGuestInfoClick}>
          <span>Guest Details<i className="fas fa-chevron-right"></i></span>
        </div>
        <div className="dropdown-content">
          <div className="sFGuestInfoBox">
            <div className="closeRow">
              <span onClick={onGuestInfoClick}>Close</span>
            </div>
            <div className="nameBox">
              <p className="name">{guestInfo.first_name}</p>
              <p className="visits"><span>Total visits:</span> 1</p>
            </div>
            <div className="contactBox">
              <p className="email">@ {guestInfo.email || '-'}</p>
              <p className="contact"><i className="fas fa-mobile-alt"></i> {guestInfo.contact || '-'}</p>
            </div>
            {
              guestInfo.check_in_date || guestInfo.check_out_date ?
              <div className="datesBox">
                <p className="checkInDate"><span>Check-in Date</span>{guestInfo.check_in_date ? formatDate(guestInfo.check_in_date) : ''}</p>
                <p className="checkOutDate"><span>Check-out Date</span>{guestInfo.check_out_date ? formatDate(guestInfo.check_out_date) : ''}</p>
              </div>
              : null
            }
          </div>
        </div>
      </div>
    );
  }

  let { first_name, last_name, name, email, contact,
    check_in_date, check_out_date,
    check_out_feedback_email, date_departure_message_sent,
    collection_source, date_created,
    by_user,
  } = props.data;
  if (process.env.NODE_ENV !== 'production') {
    console.log('Sent props.data: ', props.data);
  }
  if (!check_out_feedback_email) return null;
  if (check_out_feedback_email.check_out_feedback_email) {
    ({ check_out_feedback_email } = check_out_feedback_email);
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('Sent check_out_feedback_email: ', check_out_feedback_email);
  }

  if (!check_out_feedback_email) return null;
  let nameDisplay = first_name || name;
  if (nameDisplay) { nameDisplay = `${nameDisplay} ( ${email || contact || ''} )`; }
  else { nameDisplay = email || contact || ''; }
  const dt = check_out_feedback_email.date_email_sent_at || date_created;
  let source = 'Email';
  if (check_out_feedback_email.entry_id) source = 'Email';
  if (date_departure_message_sent) source = 'SMS';
  if (check_out_feedback_email.date_msg_sent_at || check_out_feedback_email.sent_msg_id) source = 'SMS';
  return (
    <div className="singleEntry" id={`fo_sf_${props.data._id}`}>
      <div className="left">
        {
          check_out_feedback_email.date_response_positive_clicked_at &&
          <img src={happyIcon} />
        }
        {
          check_out_feedback_email.date_response_negative_clicked_at &&
          <img src={sadIcon} />
        }
      </div>
      <div className="right">
        <div className="guestInfo">
          <div className="left">
            <div className="name">
              {nameDisplay}
            </div>
            <div className="extra">
              <div className="date">
                On {formatDate(dt)} via
              </div>
              <div className="source">
                {source}
              </div>
            </div>
            {
              check_out_feedback_email.clicked_google || check_out_feedback_email.clicked_tripadvisor ||
              check_out_feedback_email.clicked_holidaycheck ||
              check_out_feedback_email.clicked_booking
              ?
                <div className="directedTo">
                  Directed to
                  {
                    check_out_feedback_email.clicked_google
                    ? <> Google<img src={gtconfig.GOOGLE_LOGO} /></> : ''
                  }
                  {
                    check_out_feedback_email.clicked_tripadvisor
                    ? <> Tripadvisor<img src={gtconfig.TRIPADVISOR_LOGO} /></> : ''
                  }
                  {
                    check_out_feedback_email.clicked_holidaycheck
                    ? <> HolidayCheck<img src={gtconfig.HOLIDAYCHECK_LOGO} className="holidaycheck"/></> : ''
                  }
                  {
                    check_out_feedback_email.clicked_booking
                    ? <> Booking.com<img src={gtconfig.BOOKING_LOGO} className="booking"/></> : ''
                  }
                </div>
              : null
            }
            {
              collection_source &&
              <div className="collectionSource">
                {FEEDBACK_SOURCE_TO_LABEL[collection_source]}{`${by_user ? ' sent by ' + by_user : ''}`}
              </div>
            }
            {
              check_out_feedback_email.date_email_opened &&
              <div className="readOn">
                Opened on {formatDate(check_out_feedback_email.date_email_opened)}
              </div>
            }
          </div>
          <div className="right">
            { renderGuestInfo({first_name, last_name, name, email, contact, check_in_date, check_out_date }) }
          </div>
        </div>
        {
          check_out_feedback_email.feedback_text &&
          <div className="feedbackText">
            {
              props.filterKeyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.filterKeyword]}
                  autoEscape={true}
                  textToHighlight={check_out_feedback_email.feedback_text}
                />
              :
                check_out_feedback_email.feedback_text
            }
          </div>
        }
      </div>
    </div>
  );
}


export default SingleSent;
