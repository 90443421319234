
/* *************** Start - Edit Settings Review Channels **************** */

export const editFeedbackSettingsClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              editInProgress: false,
              editStatus: {},
            },
          },
        },
      },
    },
  };
}

export const editFeedbackSettings = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              editInProgress: true,
              editStatus: {},
            },
          },
        },
      },
    },
  };
}

export const editFeedbackSettingsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { feedback_settings } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              ...feedback_settings,
              editInProgress: false,
              editStatus: { success: true },
            },
          },
        },
      },
    },
  };
}

export const editFeedbackSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id } = params;
  if (!location__id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              editInProgress: false,
              editStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
}


/* *************** Start - Send Test Email Custom Feedback **************** */

export const sendTestEmailCustomFeedbackClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              sendTestEmailInProgress: false,
              sendTestEmailStatus: {},
            },
          },
        },
      },
    },
  };
}

export const sendTestEmailCustomFeedback = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              sendTestEmailInProgress: true,
              sendTestEmailStatus: {},
            },
          },
        },
      },
    },
  };
}

export const sendTestEmailCustomFeedbackSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              sendTestEmailInProgress: false,
              sendTestEmailStatus: { success: true },
            },
          },
        },
      },
    },
  };
}

export const sendTestEmailCustomFeedbackError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id } = params;
  if (!location__id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reputation: {
            ...state.locations[location__id].settings.reputation,
            feedback_settings: {
              ...state.locations[location__id].settings.reputation.feedback_settings,
              sendTestEmailInProgress: false,
              sendTestEmailStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
}
