// @flow

import React, { Component, useEffect, useState } from 'react';

import { OTA_SOURCE_TO_NAME_FORMAL_MAP } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';

import './style.css';

function RedirectToOta(props) {
  const onCloseClick = (e) => { props.onCancelClick(); };
  const onConfirmClick = (e) => {
    if (!props.otaUrl) return;
    props.onConfirmClick();
    window.open(props.otaUrl, '_blank')
  }

  const renderContent = () => {
    return (
      <div className="innerBox">
        <div className="header">
          <div className="title">
            You will be redirected to {OTA_SOURCE_TO_NAME_FORMAL_MAP[props.ota]}
          </div>
          {
            props.mode === 'modal' &&
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
          }
        </div>
        <div className="body">
          <div className="infoMsg">
            You will be redirected to {OTA_SOURCE_TO_NAME_FORMAL_MAP[props.ota]} to publish your response to this review. Click yes to continue or no to stay on this page.
          </div>
          <div className="note">
            <p><b>Note:</b> Your response from this page has been copied.</p>
          </div>
        </div>
        <div className="submitBox">
          <div className="buttonWrap">
            <button className={'gBtn cancel'} onClick={onCloseClick}>
              <span>Cancel</span>
            </button>
            <button className={'gBtn'} onClick={onConfirmClick}>
              <span>Yes, take me there</span>
            </button>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="gBoxWrap rtoBoxWrap">
        { renderContent() }
    </div>
  );
}

export default RedirectToOta;
