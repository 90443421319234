import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';

import BarGroupChart from '../../../Charts/BarGroupChart';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import TransactionEmailTypeStatusCard from '../../../Misc/TransactionEmailTypeStatusCard';
import EmailSendingStatDetailCard from '../../../Misc/EmailSendingStatDetailCard';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { initMarketingAction } from '../../../../redux/actions/initActions';

import { DATE_DURATION_OPTIONS_MAP } from '../../../../helpers';

import statusBookingActiveIcon from '../../../../img/icons/status-booking-active.svg';
import statusPreArrivalActiveIcon from '../../../../img/icons/status-pre-arrival-active.svg';
import statusCheckInActiveIcon from '../../../../img/icons/status-check-in-active.svg';
import statusCheckOutActiveIcon from '../../../../img/icons/status-check-out-active.svg';

import './style.css';
import 'antd/dist/antd.css';

const { RangePicker } = DatePicker;

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

function Stats(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [sendingChartEmailTypeOptions, setSendingChartEmailTypeOptions] = useState([]);
  const [sendingChartEmailTypeValue, setSendingChartEmailTypeValue] = useState(
    { label: 'All', value: 'all' }
  );

  const [chartSent, setChartSent] = useState(true);
  const [chartDelivered, setChartDelivered] = useState(true);
  const [chartOpen, setChartOpen] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Marketing - Transaction Emails - Analyze - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const getStatsData = (startDate, endDate) => {
    dispatch(getDataAction({
      op: 'get_transaction_email_stats',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      },
    }));
  };

  useEffect(() => {
    const tOptions = [];
      tOptions.push({ label: 'All', value: 'all' });
      tOptions.push({ label: 'Booking', value: 'booking' });
      tOptions.push({ label: 'Pre-Arrival', value: 'pre-arrival' });
      tOptions.push({ label: 'Check-In', value: 'check-in' });
      tOptions.push({ label: 'Check-Out', value: 'check-out' });
      setSendingChartEmailTypeOptions(tOptions);

      const { commonParams } = appState;
      let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
      let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
      let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
      setDateFilterStartDate(startDate);
      setDateFilterEndDate(endDate);
      setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
      getStatsData(dateFilterStartDate, dateFilterEndDate);

  }, []);

  // useEffect(() => {
  //   const { commonParams } = props.locationprops;
  //   let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
  //   let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
  //   let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
  //   setDateFilterStartDate(startDate);
  //   setDateFilterEndDate(endDate);
  //   setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
  //   getStatsData(dateFilterStartDate, dateFilterEndDate);
  // }, []);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
        },
      }));
      getStatsData(new Date(moment().subtract(period, 'days')), new Date());
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
        },
      }));
      getStatsData(new Date(moment().subtract(period, 'month')), new Date());
    }
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { transactionemailprops } = props;
    if (!transactionemailprops || transactionemailprops.getStatsInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      },
    }));
    getStatsData(dates[0], dates[1]);
  };

  const onSendingChartEmailTypeChange = (value) => {
    setSendingChartEmailTypeValue(value);
  };

  const onChartCheckBoxChange = (e) => {
    return;
    const { name, value } = e.target;
    if (name === 'chartSent') setChartSent(chartSent ? false : true);
    if (name === 'chartDelivered') setChartDelivered(chartDelivered ? false : true);
    if (name === 'chartOpen') setChartOpen(chartOpen ? false : true);
  };

  const onChartSentClick = () => {
    if (!chartDelivered && !chartOpen) return;
    setChartSent(chartSent ? false : true);
  };
  const onChartDeliveredClick = () => {
    if (!chartSent && !chartOpen) return;
    setChartDelivered(chartDelivered ? false : true);
  };
  const onChartOpenClick = () => {
    if (!chartSent && !chartDelivered) return;
    setChartOpen(chartOpen ? false : true)
  };

  const renderEmailTypeStatusRow = (stats) => {
    if (!stats || !stats.te_status) return null;
    return (
      <div className="emTypStBx">
        <TransactionEmailTypeStatusCard
          data={{
            label: 'Booking',
            icon: statusBookingActiveIcon,
            emailType: 'booking',
            activeCount: stats.te_status['booking'] ? stats.te_status['booking'].total_count || 0 : 0,
            ctaLabel: 'Manage',
            ctaLink: '/marketing/transaction-emails/configure/booking',
          }}
        />
        <TransactionEmailTypeStatusCard
          data={{
            label: 'Pre-Arrival',
            icon: statusPreArrivalActiveIcon,
            emailType: 'pre-arrival',
            activeCount: stats.te_status['pre-arrival'] ? stats.te_status['pre-arrival'].total_count || 0 : 0,
            ctaLabel: 'Manage',
            ctaLink: '/marketing/transaction-emails/configure/pre-arrival',
          }}
        />
        <TransactionEmailTypeStatusCard
          data={{
            label: 'Check-In',
            icon: statusCheckInActiveIcon,
            emailType: 'check-in',
            activeCount: stats.te_status['check-in'] ? stats.te_status['check-in'].total_count || 0 : 0,
            ctaLabel: 'Manage',
            ctaLink: '/marketing/transaction-emails/configure/check-in',
          }}
        />
        <TransactionEmailTypeStatusCard
          data={{
            label: 'Check-Out',
            icon: statusCheckOutActiveIcon,
            emailType: 'check-out',
            activeCount: stats.te_status['check-out'] ? stats.te_status['check-out'].total_count || 0 : 0,
            ctaLabel: 'Manage',
            ctaLink: '/marketing/transaction-emails/configure/check-out',
          }}
        />
      </div>
    );
  }

  const renderDateFilterRow = (statsObj) => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Sent Email Stats
          </div>
          <div className="typeSelector">
            <Select
              className="emailTypeSelect"
              options={sendingChartEmailTypeOptions}
              onChange={onSendingChartEmailTypeChange}
              value={sendingChartEmailTypeValue}
            />
          </div>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };


  const renderSendingChartBox = (stats) => {
    if (!sendingChartEmailTypeValue.value) return;
    let tWidth = windowState.window.windowWidth - 196 - 10 - 40 - 40;
    if (windowState.window.windowWidth < 900) tWidth = windowState.window.windowWidth - 10 - 40 - 40;
    if (tWidth > 1024) tWidth = 1024;
    if (!stats || !stats.trend_data_all) return null;
    let trendData = stats.trend_data_all;
    if (sendingChartEmailTypeValue.value !== 'all' && stats.trend_data_type) {
      trendData = stats.trend_data_type[sendingChartEmailTypeValue.value];
    }
    if (!trendData) return (
      <p>No Data</p>
    );
    let totalSent = stats.total_sent && stats.total_sent[sendingChartEmailTypeValue.value] ? stats.total_sent[sendingChartEmailTypeValue.value].count || 0 : 0;
    let totalDelivered = stats.total_delivered && stats.total_delivered[sendingChartEmailTypeValue.value] ? stats.total_delivered[sendingChartEmailTypeValue.value].count || 0 : 0;
    let totalOpen = stats.total_open && stats.total_open[sendingChartEmailTypeValue.value] ? stats.total_open[sendingChartEmailTypeValue.value].count || 0 : 0;
    return (
      <div className="ovrChrtBx">
        <div className="title">
          <div className="left">
            Emails Sent / Delivered / Opened
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={"Sending Stats"}
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </div>
          <div className="right">
            <div className="entry sent" onClick={onChartSentClick}>
              <input className="checkbox" type="checkbox" name="chartSent" onChange={onChartCheckBoxChange} value={chartSent ? 'on' : 'off'} checked={chartSent} />
              Sent
            </div>
            <div className="entry delivered" onClick={onChartDeliveredClick}>
              <input className="checkbox" type="checkbox" name="chartDelivered" onChange={onChartCheckBoxChange} value={chartDelivered ? 'on' : 'off'} checked={chartDelivered} />
              Delivered
            </div>
            <div className="entry open" onClick={onChartOpenClick}>
              <input className="checkbox" type="checkbox" name="chartOpen" onChange={onChartCheckBoxChange} value={chartOpen ? 'on' : 'off'} checked={chartOpen} />
              Opened
            </div>
          </div>
        </div>
        <p className="total">
          { chartSent && <span className="entry sent">{totalSent}</span> }
          { chartDelivered && <span className="entry delivered">{totalDelivered}</span> }
          { chartOpen && <span className="entry open">{totalOpen}</span> }
        </p>
        {
          totalSent
          ?
            <BarGroupChart
              className="noShadow"
              windowState={windowState}
              width={tWidth}
              // width={900}
              height={260}
              data={trendData}
              hideRating={false}
              showDaily={dateRangeLastMonthValue['value'] === 'last-7-days' || dateRangeLastMonthValue['value'] === 'last-15-days'}
              showOnlyDaily={dateRangeLastMonthValue['value'] === 'last-7-days' || dateRangeLastMonthValue['value'] === 'last-15-days'}
              showSent={chartSent}
              showDelivered={chartDelivered}
              showOpen={chartOpen}
            />
          : <p className="noData">No Data!</p>
        }
      </div>
    );
  };

  const renderDetailCardsBox = (stats) => {
    if (!stats || !stats.open_rate) return null;
    let deliveryRate = '';
    if (stats.delivery_rate && stats.delivery_rate[sendingChartEmailTypeValue.value]) {
      deliveryRate = stats.delivery_rate[sendingChartEmailTypeValue.value] || 0;
    }
    let openRate = '';
    if (stats.open_rate && stats.open_rate[sendingChartEmailTypeValue.value]) {
      openRate = stats.open_rate[sendingChartEmailTypeValue.value] || 0;
    }
    let uniqueOpens = '';
    if (stats.total_open && stats.total_open[sendingChartEmailTypeValue.value]) {
      uniqueOpens = stats.total_open[sendingChartEmailTypeValue.value].count || 0;
    }
    let totalOpens = '';
    if (stats.total_open_repeats && stats.total_open_repeats[sendingChartEmailTypeValue.value]) {
      totalOpens = stats.total_open_repeats[sendingChartEmailTypeValue.value].count || 0;
    }
    let openDesktop = '';
    if (stats.total_open_desktop && stats.total_open_desktop[sendingChartEmailTypeValue.value]) {
      openDesktop = stats.total_open_desktop[sendingChartEmailTypeValue.value].count || 0;
    }
    let openMobile = '';
    if (stats.total_open_mobile && stats.total_open_mobile[sendingChartEmailTypeValue.value]) {
      openMobile = stats.total_open_mobile[sendingChartEmailTypeValue.value].count || 0;
    }
    return (
      <div className="emDetsBx">
        <EmailSendingStatDetailCard
          cardType="detail"
          separator={false}
          data={{
            label: 'Delivery Rate',
            value: deliveryRate ? `${deliveryRate}%` : '-',
            labelToolTip: 'Delivery Rate',
          }}
        />
        <EmailSendingStatDetailCard
          cardType="detail"
          separator={true}
          data={{
            label: 'Open Rate',
            value: openRate ? `${openRate}%` : '-',
            labelToolTip: 'Open Rate',
            labelValue1: {
              label: 'Unique Opens',
              value: uniqueOpens || '-',
            },
            labelValue2: {
              label: 'Total Opens',
              value: totalOpens || '-',
            },
            labelValue3: {
              label: 'Desktop',
              value: uniqueOpens ? `${Number.parseFloat(openDesktop/uniqueOpens).toFixed()}%` : '-',
            },
            labelValue4: {
              label: 'Mobile',
              value: uniqueOpens ? `${Number.parseFloat(openMobile/uniqueOpens).toFixed()}%` : '-',
            },
          }}
        />
      </div>
    );
  };


  const { transactionEmails } = props.marketing;
  if (!transactionEmails) return null;

  const { stats } = transactionEmails;

  return (
    <>
      {
        transactionEmails.getStatsInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg">
          { renderEmailTypeStatusRow(stats) }
          { renderDateFilterRow() }
          { renderSendingChartBox(stats) }
          { renderDetailCardsBox(stats) }
        </div>
      </div>
    </>
  );
}

export default Stats;
