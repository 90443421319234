import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../Misc/OutsideClickHook';

import { ACCESS_LEVELS, validateEmail, RG_FEATURES_TO_LABELS,
  curUserHasAccessToOtherUserByAccessLevel, ACCESS_LEVELS_TO_LABEL, isPasswordStrong,
  getHighestAccessLevels, isOwnerAndAbove, isGroupAdminAndAbove, isLocationAdminAndAbove,
  isPartnerManagerAndAbove, isPartnerAdminAndAbove } from '../../../../helpers';

import emojiIcon from '../../../../img/icons/emoji.svg';
import closeIcon from '../../../../img/icons/close.svg';

import '../style.css';

const featureSetOptions = [];
let featureId = 'configure';
featureSetOptions.push({ label: RG_FEATURES_TO_LABELS[featureId], value: featureId });



function FeatureTab(props) {
  const onRemoveClick = (e) => {
    props.onRemoveClick(feature);
  }
  const { feature } = props;
  if (process.env.NODE_ENV !== 'production') {
    console.log('FeatureTab feature: ', feature);
  }
  return (
    <div className="fetrEntry">
      {feature.label || ''}
      <span onClick={onRemoveClick}><i className="fa fa-times" /></span>
    </div>
  );
}



function LocationRow(props) {
  const [locationData, setLocationData] = useState({});

  const [featureOptions, setFeatureOptions] = useState([]);
  const [featureValue, setFeatureValue] = useState(
    { label: 'Select feature', value: 'select-feature' },
  );
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [featuresChanged, setFeaturesChanged] = useState(false);
  const [selectedFeaturesError, setSelectedFeaturesError] = useState(false);


  useEffect(() => {
    const { locData } = props;
    // features
    let tempSelectedFeatures = [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('locData: ', locData);
    }
    if (locData && locData.features) {
      Object.keys(locData.features).forEach((featureId) => {
        tempSelectedFeatures.push({ label: RG_FEATURES_TO_LABELS[featureId] || featureId, value: featureId })
      });
    }
    setSelectedFeatures(tempSelectedFeatures);


    let tempFeatureSetOptions = [];
    tempFeatureSetOptions = [ ...featureSetOptions ];
    if (process.env.NODE_ENV !== 'production') {
      console.log('tempFeatureSetOptions: ', tempFeatureSetOptions);
      console.log('tempSelectedFeatures: ', tempSelectedFeatures);
    }
    tempFeatureSetOptions = tempFeatureSetOptions.filter((item) => {
      if (tempSelectedFeatures.find(el => el.value === item.value)) {
        return false;
      }
      return true;
    });
    setFeatureOptions([
      { label: 'Select feature', value: 'select-feature' },
      ...tempFeatureSetOptions,
      // { label: 'Wifi', value: '{{wifi}}' },
    ]);

  }, []);

  useEffect(() => {
    props.onChange(locationData, props.locationInfo._id, props.locationInfo.location_id);
  }, [locationData]);

  const onLocationRemoveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationRemoveClick');
    }
    setLocationData(null, props.locationInfo._id, props.locationInfo.location_id);
    props.onSomeChange(e);
  };

  const onFeatureRemoveClick = (feature) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFeatureRemoveClick feature: ', feature);
    }
    let tempSelectedFeatures = selectedFeatures.filter(el => el.value !== feature.value);
    setSelectedFeatures(tempSelectedFeatures);

    setLocationData({
      ...locationData,
      features: {
        ...tempSelectedFeatures,
      }
    });

    let tempFeatures = { ...locData.features };
    delete tempFeatures[feature.value];

    // update feature options
    let tempFeatureSetOptions = [];
    tempFeatureSetOptions = [ ...featureSetOptions ];
    if (process.env.NODE_ENV !== 'production') {
      console.log('tempFeatureSetOptions: ', tempFeatureSetOptions);
      console.log('tempSelectedFeatures: ', tempSelectedFeatures);
    }
    tempFeatureSetOptions = tempFeatureSetOptions.filter((item) => {
      if (tempSelectedFeatures.find(el => el.value === item.value)) {
        return false;
      }
      return true;
    });
    setFeatureOptions([
      { label: 'Select feature', value: 'select-feature' },
      ...tempFeatureSetOptions,
      // { label: 'Wifi', value: '{{wifi}}' },
    ]);
    props.onSomeChange();
  };

  const onFeatureSelectValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFeatureSelectValueChange value: ', value);
    }
    if (props.user.username === props.userState.user.uid) return;
    // setPersonalizeValue(value);
    if (value.value !== 'select-feature') {
      setSelectedFeatures(selectedFeatures => [...selectedFeatures, value]);
      setFeatureOptions(featureOptions => featureOptions.filter(el => el.value !== value.value));
      setFeatureValue({ label: 'Select feature', value: 'select-feature' });
      setSelectedFeaturesError(false);
    }
    setLocationData({
      ...locationData,
      features: locationData.features
      ?
        {
          ...locationData.features,
          [value.value]: true,
        }
      :
        {
          [value.value]: true,
        }
    })
    setFeaturesChanged(true);
    // if (Object.keys(props.addEditStatus).length) {
    //   props.onAddEditClear(props.template);
    // }
    props.onSomeChange();
  };

  const { locData, locName, locationInfo, curUserProfile, curUserProfileLocData, formError } = props;
  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow formError: ', formError);
    console.log('LocationRow LocationRow locData: ', locData);
    console.log('LocationRow LocationRow curUserProfileLocData: ', curUserProfileLocData);
  }

  return (
    <>
    <div className={'gBCtRw locRw rgLoc' + (formError[locationInfo._id] ? ' hasErr' : '')}>
      <div className="gRwBxLt">
        <div className={'gLbl mb0' + (props.user && props.user.profile && props.user.profile.rg_access_level !== ACCESS_LEVELS.RG_GENERIC_USER ? ' noBtmMrgn' : '')}>
          {locName}

          {
            props.user.username !== props.userState.user.uid &&
            <span className="remove" onClick={onLocationRemoveClick}>Remove Access to Location</span>
          }
        </div>
        {
          props.user && props.user.profile && props.user.profile.rg_access_level === ACCESS_LEVELS.RG_GENERIC_USER &&
          <div className="ftBx">
            <div className="curFetrBx">
              {
                selectedFeatures && Object.keys(selectedFeatures).length
                ?
                  Object.keys(selectedFeatures).map((feature) => {
                    return (
                      <FeatureTab feature={selectedFeatures[feature]} onRemoveClick={onFeatureRemoveClick} />
                    )
                  })
                : <span>Limited access to basic features</span>
              }
            </div>
            <div className="addFetrBx">
              <div className="gLbl">Add access to features</div>
              <Select
                className={'selectDropDown ' + (selectedFeaturesError ? 'selectError' : '')}
                options={featureOptions}
                value={featureValue}
                onChange={onFeatureSelectValueChange}
                // onMenuOpen={onFeatureMenuOpen}
              />
            </div>
          </div>
        }
      </div>
    </div>
    {
      formError[locationInfo._id] &&
      <div className="gBCtRw locErrRw">
        {formError[locationInfo._id]}
      </div>
    }
    </>
  );
}

function RgUserSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    password: '',
    repeatPassword: '',
  });
  const [localError, setLocalError] = useState('');


  const [locationOptions, setLocationOptions] = useState([]);
  const [locationValue, setLocationValue] = useState(
    { label: 'Select location', value: 'select-location' },
  );
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationsChanged, setLocationsChanged] = useState(false);
  const [selectedLocationsError, setSelectedLocationsError] = useState(false);

  const [someChange, setSomeChange] = useState(false);

  const [formError, setFormError] = useState({});
  const [noLocationError, setNoLocationError] = useState(false);

  const [allLocationsData, setAllLocationsData] = useState({});
  const [initialAllLocationsData, setInitialAllLocationsData] = useState({});

  const [sendEmailToUser, setSendEmailToUser] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - User - Single`,
        gt_user_id: userState.user.uid,
        // gt_location_id: urlParams.locationidentifier,
        // gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    if (props.type === 'edit') {
      if (props.user && props.user.editStatus && Object.keys(props.user.editStatus).length) {
        dispatch(editDataClearAction({
          op: 'edit_rg_user',
          params: {
            // location__id: urlParams.locationidentifier,
            mode: props.type,
            user_id: props.user ? props.user._id : undefined,
          },
        }));
      }
    }
    if (props.type === 'add') {
      if (props.users && props.users.addStatus && Object.keys(props.users.addStatus).length) {
        dispatch(editDataClearAction({
          op: 'edit_rg_user',
          params: {
            // location__id: urlParams.locationidentifier,
            mode: props.type,
          },
        }));
      }
    }
  };

  useEffect(() => {
    let tempLocations = [];
    if (userState.user && userState.user.user_info && userState.user.user_info.rg_locations &&
      Object.keys(userState.user.user_info.rg_locations).length &&
      userState.user.user_info.profile
    ) {
      Object.keys(userState.user.user_info.rg_locations).forEach((locMId) => {
          let locInfo = { ...userState.user.user_info.rg_locations[locMId] };
          tempLocations.push({ label: locInfo.name, value: locMId });
      });
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('tempLocations: ', tempLocations);
    }

    if (props.user && props.user._id) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('RgUserSingle props.user: ', props.user);
      }
      setAllLocationsData(props.user.profile.rg_locations ? { ...props.user.profile.rg_locations } : {});
      setForm(form => ({
        ...form,
        firstName: props.user.profile.first_name,
        lastName: props.user.profile.last_name,
        email: props.user.username,
        contact: props.user.contact,
      }));
      if (props.user.profile.rg_locations && Object.keys(props.user.profile.rg_locations).length) {
        let tempSelectedLocationOptions = [];
        Object.keys(props.user.profile.rg_locations).forEach((locMId) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('locMId: ', locMId);
          }
          let locInfo = {};
          if (userState.user && userState.user.user_info && userState.user.user_info.rg_locations && userState.user.user_info.rg_locations[locMId]) {
            locInfo = { ...userState.user.user_info.rg_locations[locMId] };
          }
          if (locInfo && locInfo._id) {
            tempSelectedLocationOptions.push({ label: locInfo.name, value: locInfo._id });
          }
        });
        setSelectedLocations(tempSelectedLocationOptions);
        if (process.env.NODE_ENV !== 'production') {
          console.log('tempSelectedLocationOptions: ', tempSelectedLocationOptions);
        }

        // let tempLocations = [];
        // if (userState.user && userState.user.user_info && userState.user.user_info.locations && Object.keys(userState.user.user_info.locations).length) {
        //   Object.keys(userState.user.user_info.locations).forEach((locMId) => {
        //     if (!tempSelectedLocationOptions.find(ll => ll.value === locMId)) {
        //       let locInfo = { ...userState.user.user_info.locations[locMId] };
        //       tempLocations.push({ label: locInfo.name, value: locMId })
        //     }
        //   });
        // }
        tempLocations = tempLocations.filter((el) => {
          if (tempSelectedLocationOptions.find(ll => ll.value === el.value)) {
            return false;
          }
          return true;
        });

        if (process.env.NODE_ENV !== 'production') {
          console.log('tempLocations: ', tempLocations);
        }
      }
      setLocationOptions([
        { label: 'Select location', value: 'select-location' },
        ...tempLocations,
      ]);
      // if (props.user.profile.rg_access_level) {
      //   if (props.user.profile.rg_locations && Object.keys(props.user.profile.rg_locations).length) {
      //     let tLocations = [];
      //     Object.keys(props.user.profile.rg_locations).forEach((el) => {
      //       if (process.env.NODE_ENV !== 'production') {
      //         console.log('RgUserSingle loc: ', el);
      //       }
      //       if (props.user.profile.rg_locations[el] && props.user.profile.rg_locations[el].location_id) {
      //         tLocations.push({
      //           label: props.user.profile.rg_locations[el].name,
      //           value: el,
      //         });
      //       }
      //     });
      //     if (process.env.NODE_ENV !== 'production') {
      //       console.log('RgUserSingle tLocations: ', tLocations);
      //     }
      //   }
      //
      // }
    } else {
      setLocationOptions([
        { label: 'Select location', value: 'select-location' },
        ...tempLocations,
      ]);
    }



    setSomeChange(false);
    clearAction();
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.user && props.user._id &&
        props.users && props.users.keyValue &&
        props.users.keyValue[props.user._id] &&
        !props.users.keyValue[props.user._id].editInProgress &&
        props.users.keyValue[props.user._id].editStatus &&
        props.users.keyValue[props.user._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.users &&
        !props.users.addInProgress &&
        props.users.addStatus &&
        props.users.addStatus.success
      ) {
        setForm({
          firstName: '',
          lastName: '',
          email: '',
          contact: '',
          password: '',
          repeatPassword: '',
        });
        setSelectedLocations([]);
        setSomeChange(false);
      }
    }
  }, [props.users]);


  const onChange = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
      [`${name}Error`]: value ? '' : 'error',
    }));
    if (name === 'email') {
      if (!validateEmail(value)) {
        let emailError = value ? '' : 'error';
        if (!emailError) emailError = validateEmail(value) ? '' : 'invalid';
        setForm(form => ({
          ...form,
          emailError,
        }));
      } else {
        if (props.users && props.users.keyValue && Object.keys(props.users.keyValue).length) {
          const foundInUsers = Object.keys(props.users.keyValue).find((el) => {
            if (props.users.keyValue[el] && props.users.keyValue[el].username === value) {
              return true;
            }
            return false;
          });
          if (process.env.NODE_ENV !== 'production') {
            console.log('foundInUsers: ', foundInUsers);
          }
          let emailError = '';
          if (foundInUsers) {
            emailError = 'in-use';
          }
          setLocalError(emailError === 'in-use' ? 'Email already in use!' : '');
          setForm(form => ({
            ...form,
            emailError,
          }));
        }
      }
    }
    if (name === 'password') {
      setForm(form => ({
        ...form,
        passwordError: !isPasswordStrong(value) ? 'weak' : '',
      }));
    }
    if (name === 'repeatPassword') {
      setForm(form => ({
        ...form,
        repeatPasswordError: value !== form.password ? 'mismatch' : '',
      }));
    }
    setSomeChange(true);
    clearAction();
  };


  const onLocationValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationValueChange value: ', value);
    }
    // setPersonalizeValue(value);
    if (value.value !== 'select-location') {
      setSelectedLocations(selectedLocations => [...selectedLocations, value]);
      setLocationOptions(locationOptions => locationOptions.filter(el => el.value !== value.value));
      setLocationValue({ label: 'Select location', value: 'select-location' });
      setSelectedLocationsError(false);
    }

    setLocationsChanged(true);
    // if (props.addEditStatus && Object.keys(props.addEditStatus).length) {
    //   props.onAddEditClear(props.template);
    // }
    // gtag('event', 'gt_click', {
    //   'event_category': 'Settings - Users',
    //   'event_label': 'Added Feature',
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onLocationDataChange = (locationData, locMId, locationId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationDataChange locMId: ', locMId);
      console.log('onLocationDataChange locationId: ', locationId);
      console.log('onLocationDataChange locationData: ', locationData);
      console.log('onLocationDataChange allLocationsData: ', allLocationsData);
    }
    let tempLocationData = { ...locationData };
    if (tempLocationData.reputation && !tempLocationData.reputation.access_level) delete tempLocationData.reputation;
    if (tempLocationData.engage && !tempLocationData.engage.access_level) delete tempLocationData.engage;
    if (tempLocationData.marketing && !tempLocationData.marketing.access_level) delete tempLocationData.marketing;
    if (locationData) {
      // let tempAllLocationsData = {
      //   ...allLocationsData,
      //   [locMId]: allLocationsData[locMId]
      //   ?
      //     {
      //       ...allLocationsData[locMId],
      //       ...locationData,
      //     }
      //   :
      //     {
      //       location_id: locationId,
      //       ...locationData,
      //     }
      // };
      // over-write it
      let tempAllLocationsData = {
        ...allLocationsData,
        [locMId]: allLocationsData[locMId]
        ?
          {
            ...tempLocationData,
          }
        :
          {
            // location_id: locationId,
            ...tempLocationData,
          }
      };
      setAllLocationsData(tempAllLocationsData);

      let fError = {};
      setFormError(fError);

      if (!Object.keys(tempAllLocationsData).length) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('RgUserSingle no locations assigned');
        }
        setNoLocationError(true);
      } else {
        setNoLocationError(false);
      }

    } else {
      // remove location
      let tempAllLocationsData = { ...allLocationsData };
      delete tempAllLocationsData[locMId];
      setAllLocationsData(tempAllLocationsData);

      let fError = {};
      setFormError(fError);

      if (!Object.keys(tempAllLocationsData).length) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('RgUserSingle no locations assigned');
        }
        setNoLocationError(true);
      } else {
        setNoLocationError(false);
      }

      // update selected locations
      let newSelectedLocations = selectedLocations.filter(el => el.value !== locMId);
      setSelectedLocations(newSelectedLocations);

      // update location select options
      let tempLocations = [];
      if (userState.user && userState.user.user_info && userState.user.user_info.rg_locations && Object.keys(userState.user.user_info.rg_locations).length) {
        Object.keys(userState.user.user_info.rg_locations).forEach((locMId) => {
          if (!newSelectedLocations.find(ll => ll.value === locMId)) {
            let locInfo = { ...userState.user.user_info.rg_locations[locMId] };
            tempLocations.push({ label: locInfo.name, value: locMId })
          }
        });
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('tempLocations: ', tempLocations);
      }
      setLocationOptions([
        { label: 'Select location', value: 'select-location' },
        ...tempLocations,
      ]);
      setSomeChange(true);
    }
    clearAction();
    // setSomeChange(true);
  };

  const onSomeChange = (e) => {
    setSomeChange(true);
  }

  const onAddEditClick = (e) => {
    let addEditInProgress = props.users.addInProgress
    if (props.type === 'edit') {
      if (props.user && props.user._id &&
        props.users && props.users.keyValue &&
        props.users.keyValue[props.user._id]
      ) {
        addEditInProgress = props.users.keyValue[props.user._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('RgUserSingle onAddEditClick allLocationsData: ', allLocationsData);
      console.log('RgUserSingle onAddEditClick form: ', form);
      console.log('RgUserSingle onAddEditClick someChange: ', someChange);
    }

    if (
      props.user.username === userState.user.user_info.uid ||
      (props.user && props.user.profile && props.user.profile.rg_access_level === userState.user.user_info.profile.rg_access_level)
    ) {
      return;
    }

    clearAction();
    setForm(form => ({
      ...form,
      firstNameError: form.firstName ? '' : 'error',
      lastNameError: form.lastName ? '' : 'error',
    }));
    let emailError = form.email ? '' : 'error';
    if (!emailError) emailError = validateEmail(form.email) ? '' : 'invalid';
    if (localError) emailError = 'in-use';
    setForm(form => ({
      ...form,
      emailError,
    }));
    if (props.type === 'add') {
      setForm(form => ({
        ...form,
        passwordError: form.password ? '' : 'error',
      }));
      setForm(form => ({
        ...form,
        repeatPasswordError: form.repeatPassword ? '' : 'error',
      }));
      if (form.password && form.repeatPassword && (form.password !== form.repeatPassword)) {
        setForm(form => ({
          ...form,
          // passwordError: 'error',
          repeatPasswordError: 'mismatch',
        }));
        return;
      }
      if (form.password && !isPasswordStrong(form.password)) {
        setForm(form => ({
          ...form,
          passwordError: 'weak',
        }));
        return;
      }
    }
    if (localError) return;

    let hlLocations = {};
    let fError = {};
    setFormError(fError);
    if (Object.keys(fError).length) return;

    if (!Object.keys(allLocationsData).length) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('RgUserSingle no locations assigned');
      }
      setNoLocationError(true);
      return;
    }
    setNoLocationError(false);

    // clear name
    Object.keys(allLocationsData).forEach((el) => {
      delete allLocationsData[el].name;
    });

    // return;

    if (someChange) {
      if (props.type === 'add') {
        if (form.firstName && form.lastName && form.email && form.password && form.repeatPassword) {
          const params = {
            mode: props.type,
            first_name: form.firstName,
            last_name: form.lastName,
            username: form.email,
            contact: form.contact,
            password: form.password,
            send_email_to_user: sendEmailToUser,
          };
          params.rg_locations = allLocationsData;
          if (process.env.NODE_ENV !== 'production') {
            console.log('RgUserSingle onAdd params: ', params);
          }
          dispatch(editDataAction({
            op: 'edit_rg_user',
            params
          }));
          setSomeChange(false);
        }
      }
      if (props.type === 'edit') {
        if (form.firstName) {
          const params = {
            mode: props.type,
            username: props.user.username,
            user_id: props.user._id,
            first_name: form.firstName,
            last_name: form.lastName,
          };
          // params.access_level = '';
          params.rg_locations = allLocationsData;
          if (process.env.NODE_ENV !== 'production') {
            console.log('RgUserSingle params: ', params);
          }
          dispatch(editDataAction({
            op: 'edit_rg_user',
            params,
          }));
          setSomeChange(false);
        }
      }
    }
  };

  const renderContent = () => {
    const { users } = props;

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add User`;
    if (props.type === 'edit') pageTitle = 'Edit User';
    if (props.title) pageTitle = props.title;

    const accessTypeEnabled = isGroupAdminAndAbove(userState.user.user_info.profile.access_level);

    let addEditInProgress = users.addInProgress
    if (props.type === 'edit') { addEditInProgress = props.user.editInProgress; }
    let addEditStatus = users.addStatus;
    if (props.type === 'edit') { addEditStatus = props.user.editStatus; }

    return (
      <div className="gPWrp usrSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw byTwRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                First Name
              </div>
              <div className={'gIpt ' + (form.firstNameError ? 'error' : '')}>
                <input type="text" value={form.firstName} onChange={onChange} name="firstName" placeholder="First Name" />
                { form.firstNameError && <p className="gErr">Required</p> }
              </div>
            </div>
            <div className="gRwBxRt">
              <div className="gLbl">
                Last Name
              </div>
              <div className={'gIpt ' + (form.lastNameError ? 'error' : '')}>
                <input type="text" value={form.lastName} onChange={onChange} name="lastName" placeholder="Last Name" />
                { form.lastNameError && <p className="gErr">Required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw byTwRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Email
              </div>
              <div className={'gIpt ' + (form.emailError ? 'error' : '')}>
                <input type="text" value={form.email} onChange={onChange} name="email" placeholder="Email" readOnly={props.type === 'edit'} />
                {form.emailError === 'error' ? <div className="gErr">Required</div> : null}
                {form.emailError === 'invalid' ? <div className="gErr">Invalid email</div> : null}
                {form.emailError === 'in-use' ? <div className="gErr">{localError}</div> : null}
              </div>
            </div>
            <div className="gRwBxRt">
              <div className="gLbl">
                Mobile
              </div>
              <div className={'gIpt ' + (form.contactError ? 'error' : '')}>
                <input type="text" value={form.contact} onChange={onChange} name="contact" placeholder="Mobile" readOnly={props.type === 'edit'} />
                { form.contactError && <p className="gErr">Mobile is required</p> }
              </div>
            </div>
          </div>
          {
            props.type === 'add' &&
            <div className="gBCtRw byTwRw">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Password
                </div>
                <div className={'gIpt ' + (form.passwordError ? 'error' : '')}>
                  <input type="password" value={form.password} onChange={onChange} name="password" placeholder="Password" readOnly={props.type === 'edit'} />
                  {form.passwordError === 'error' ? <div className="gErr">Required</div> : null}
                { form.passwordError === 'weak' && <div className="gErr">Minimum 8 characters required.</div> }
                </div>
              </div>
              <div className="gRwBxRt">
                <div className="gLbl">
                  Repeat Password
                </div>
                <div className={'gIpt ' + (form.repeatPasswordError ? 'error' : '')}>
                  <input type="password" value={form.repeatPassword} onChange={onChange} name="repeatPassword" placeholder="Repeat Password" readOnly={props.type === 'edit'} />
                  {form.repeatPasswordError === 'error' ? <div className="gErr">Required</div> : null}
                { form.repeatPasswordError === 'mismatch' && <div className="gErr">Password mismatch</div> }
                </div>
              </div>
            </div>
          }
          <>
            <div className="gBCtRw locLbl">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Locations
                </div>
              </div>
            </div>
            {
              selectedLocations && selectedLocations.length
              ?
                selectedLocations.map((locLabelValue) => {
                  if (userState && userState.user && userState.user.user_info && userState.user.user_info.rg_locations && userState.user.user_info.rg_locations[locLabelValue.value]) {
                    let locationInfo = { ...userState.user.user_info.rg_locations[locLabelValue.value] };
                    let curUserProfile = userState.user && userState.user.user_info && userState.user.user_info.profile
                      ? { ...userState.user.user_info.profile } : {};
                    let curUserProfileLocData = userState.user && userState.user.user_info && userState.user.user_info.profile &&
                      userState.user.user_info.profile.rg_locations && userState.user.user_info.profile.rg_locations[locLabelValue.value]
                      ? { ...userState.user.user_info.profile.rg_locations[locLabelValue.value] }
                      : {};
                    return (
                      <LocationRow
                        key={`usr_lr_${locLabelValue.value}`}
                        locData={
                          props.user && props.user._id
                          ? { ...props.user.profile.rg_locations[locLabelValue.value] }
                          : {}
                        }
                        type={props.type}
                        user={props.user}
                        locName={userState.user.user_info.rg_locations[locLabelValue.value].name}
                        curUserProfile={curUserProfile}
                        curUserProfileLocData={curUserProfileLocData} locationInfo={locationInfo}
                        onChange={onLocationDataChange}
                        formError={formError}
                        onSomeChange={onSomeChange}
                        userState={userState}
                        appInfo={appState.appInfo}
                      />
                    )
                  }
                  return null;
                })
              : null
            }
            <div className="gBCtRw byTwRw">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Add Location
                </div>
                <Select
                  className={'selectDropDown ' + (selectedLocationsError ? 'selectError' : '')}
                  options={locationOptions}
                  value={locationValue}
                  onChange={onLocationValueChange}
                  isDisabled={props.user.username === userState.user.user_info.uid}
                  // onMenuOpen={onFeatureMenuOpen}
                />
                {selectedLocationsError ? <div className="gErr">Atleast one location is required</div> : null}
              </div>
              <div className="gRwBxRt">
              </div>
            </div>
          </>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          {
            Object.keys(formError).length
            ?
              <div className="gBCtRw curSts">
                <span className="gErr">Please check errors above!</span>
              </div>
            : null
          }
          {
            noLocationError &&
            <div className="gBCtRw curSts">
              <span className="gErr">Atleast one location is required.</span>
            </div>
          }
          {
            props.user.username !== userState.user.user_info.uid &&
            <div className="gBCtRw btnRw">
              {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
                { props.type === 'add' && 'Add' }
                { props.type === 'edit' && 'Save Changes' }
                { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            </div>
          }
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RgUserSingle props: ', props);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('RgUserSingle allLocationsData: ', allLocationsData);
    console.log('RgUserSingle noLocationError: ', noLocationError);
    console.log('RgUserSingle selectedLocations: ', selectedLocations);
  }

  return renderContent();
}

export default RgUserSingle;
