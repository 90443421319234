import {
  Routes,
  Route,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import SideBarInner from '../SideBarInner';
import General from './General';
import Rooms from './Rooms';
import RemoteLocks from './RemoteLocks';
import RateCodes from './RateCodes';
import BookingSources from './BookingSources';

function HotelDetails(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Hotel Details - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  let has_remote_lock = locationState.locations && locationState.locations[urlParams.locationidentifier]
    && locationState.locations[urlParams.locationidentifier].settings
    && locationState.locations[urlParams.locationidentifier].settings.info
    ? locationState.locations[urlParams.locationidentifier].settings.info.has_remote_lock || false
    : false;

  const { accessLevel } = props;

  return (
    <>
      <SideBarInner />
      <div className="sc">
        <Routes>
          <Route path="general-information" element={<General {...props} accessLevel={accessLevel} />} />
          <Route path="rooms" element={<Rooms {...props} accessLevel={accessLevel} />} />
          { has_remote_lock && <Route path="remote-locks" element={<RemoteLocks {...props} accessLevel={accessLevel} />} /> }
          <Route path="rate-codes" element={<RateCodes {...props} accessLevel={accessLevel} />} />
          <Route path="booking-sources" element={<BookingSources {...props} accessLevel={accessLevel} />} />
        </Routes>
      </div>
    </>
  );
}

export default HotelDetails;
