import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import Notifications from '../Notifications';

import useOnClickOutside from '../Misc/OutsideClickHook';
import ToolTipWrapper from '../Misc/ToolTipWrapper';

import { logout } from '../../redux/actions/miscActions';
import { editDataAction, editDataClearAction } from '../../redux/actions/editDataActions';

import { getProductAccess, productIdToLabels, bookmarksContain } from '../../helpers';

import gtLogo from '../../img/gtlogo-white-small.png';
import profileIcon from '../../img/icons/profile-white.svg';
import settingsIcon from '../../img/icons/settings-white.svg';
import caretDownIcon from '../../img/icons/caret-down.svg';
import helpIcon from '../../img/icons/help-white.svg';
import notificationIcon from '../../img/icons/notification-white.svg';

import './TopBar.css';

const modalRoot = document.getElementById('modal-notifications');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function LocationRow(props) {
  const { name, sms_number, whatsapp_number, has_engage_sms, has_engage_whatsapp, unreadCount } = props.location;
  let displayNumber = sms_number ? formatPhoneNumberIntl(sms_number) : '';
  if (has_engage_whatsapp && !has_engage_sms && whatsapp_number) {
    displayNumber = formatPhoneNumberIntl(whatsapp_number);
  }
  const onRowClick = (e) => {
    props.onRowClick(props.location);
  };

  const { reputation_enabled, engage_enabled, marketing_enabled } = props.appInfo;
  const { userState } = props;

  if (
    (reputation_enabled && props.location.has_reputation) ||
    (engage_enabled && props.location.has_engage) ||
    (marketing_enabled && props.location.has_transaction_emails)
  ) {
    return (
      <div className="tbLcRw" onClick={onRowClick}>
        <div className="rwIt">
          {
            unreadCount &&
            <span className="engageUnread" />
          }
          {name}
        </div>
        {
          has_engage_sms &&
          <div className="rwIt">
            {displayNumber}
          </div>
        }
        {
          (userState.user.user_id === '994dfb9b1b674a6d' || userState.user.user_id === '6b1dbd9b232d485e') &&
          <div className="rwIt prds">
            { reputation_enabled && props.location.has_gt_response ? <span>Reputation</span> : null }
            { reputation_enabled && props.location.has_self_response ? <span>Self Response</span> : null }
            { reputation_enabled &&
              (!props.location.has_self_response && !props.location.has_self_response) ? <span>Rep thru Feedback</span> : null
            }
            { engage_enabled && props.location.has_engage ? <span>Engage</span> : null }
            { marketing_enabled && props.location.has_transaction_emails ? <span>Transaction Emails</span> : null }
          </div>
        }
      </div>
    );
  }
  return null;
}

function BookmarkItem(props) {
  const [bookmarkActive, setBookmarkActive] = useState(false);
  const onMouseEnter = (e) => {
    setBookmarkActive(true);
  };
  const onMouseLeave = (e) => {
    setBookmarkActive(false);
  };
  const onIconClick = (e) => {
    e.preventDefault();
    const { name, location_name, link, bookmarks } = props;
    const alreadyBookmarked = bookmarksContain(bookmarks, link);
    props.onItemClick(name, location_name, link, !alreadyBookmarked);
  };
  const { name, link, bookmarks } = props;
  const alreadyBookmarked = bookmarksContain(bookmarks, link);
  return (
    <span 
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onIconClick}
    >
      <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={alreadyBookmarked ? "Remove" : "Add"}>
      <i class={'fa-bookmark' + (bookmarkActive || alreadyBookmarked ? " fa-solid" : " fa-regular")}></i>
      </ToolTipWrapper>
    </span>
  );
}

function TopBar(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [product, setProduct] = useState('');
  // const [locationIdentifier, setLocationIdentifier] = useState(appState.locationIdentifier);

  const [showBookmarksMenuPanel, setShowBookmarksMenuPanel] = useState(false);
  const bookmarksMenuPanelRef = useRef();
  useOnClickOutside(bookmarksMenuPanelRef, useCallback(() => setShowBookmarksMenuPanel(false)));
 

  const [showReputationMenuPanel, setShowReputationMenuPanel] = useState(false);
  const reputationMenuPanelRef = useRef();
  useOnClickOutside(reputationMenuPanelRef, useCallback(() => setShowReputationMenuPanel(false)));

  const [showEngageMenuPanel, setShowEngageMenuPanel] = useState(false);
  const engageMenuPanelRef = useRef();
  useOnClickOutside(engageMenuPanelRef, useCallback(() => setShowEngageMenuPanel(false)));

  const [showMarketingMenuPanel, setShowMarketingMenuPanel] = useState(false);
  const marketingMenuPanelRef = useRef();
  useOnClickOutside(marketingMenuPanelRef, useCallback(() => setShowMarketingMenuPanel(false)));

  const [showLocationSelectorPanel, setShowLocationSelectorPanel] = useState(false);
  const locationSelectorRef = useRef();
  useOnClickOutside(locationSelectorRef, useCallback(() => setShowLocationSelectorPanel(false)));

  const [showProfilePanel, setShowProfilePanel] = useState(false);
  const profilePanelRef = useRef();
  useOnClickOutside(profilePanelRef, useCallback(() => setShowProfilePanel(false)));

  // notifications modal
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const notificationsModalRef = useRef();

  const [locationKeyword, setLocationKeyword] = useState('');
  const locationSelectorInputRef = useRef(null);


  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('TopBar urlParams: ', urlParams);
      console.log('TopBar location: ', location);
      console.log('TopBar locationidentifier: ', urlParams.locationidentifier);
    }
    const { pathname } = location;
    if (pathname.indexOf('/reputation') > -1 ||
      pathname.indexOf('/engage') > -1 ||
      pathname.indexOf('/marketing') > -1 ||
      pathname.indexOf('/settings') > -1
    ) {
      if (pathname.indexOf('/reputation') > -1) setProduct('reputation');
      if (pathname.indexOf('/engage') > -1) setProduct('engage');
      if (pathname.indexOf('/marketing') > -1) setProduct('marketing');
      if (pathname.indexOf('/settings') > -1) setProduct('settings');
    } else {
      setProduct('');
    }
    // if (pathname.indexOf('/engage/locations/') > -1) {
    //   let temp = pathname.split('/engage/locations/')[1];
    //   console.log('TopBar locationIdentifier temp: ', temp);
    //   if (temp.split('/').length) {
    //     setLocationIdentifier(temp.split('/')[0]);
    //   } else {
    //     setLocationIdentifier(temp);
    //   }
    // } else {
    //   setLocationIdentifier('');
    // }
  }, [urlParams, location]);


  const onBookmarksMenuClick = (e) => {
    e.preventDefault();
    let locationidentifier = '';
    locationidentifier = pathname.indexOf('/locations/') > -1 ? pathname.split('/locations/')[1] : '';
    locationidentifier = locationidentifier ? locationidentifier.split('/')[0] : '';
    // let groupidentifier = '';
    // groupidentifier = pathname.indexOf('/groups/') > -1 ? pathname.split('/groups/')[1] : '';
    // groupidentifier = groupidentifier ? groupidentifier.split('/')[0] : '';

    const { locations } = userState.user.user_info;

    if (process.env.NODE_ENV !== 'production') {
      console.log('TopBar onReputationMenuDashboardClick locations[locationidentifier]: ', locations[locationidentifier]);
    }

    let locationName = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].name : '';
    if (!locationName) {
      console.log('TopBar onReputationMenuDashboardClick locationName missing');
      return;
    }

    setShowBookmarksMenuPanel(showBookmarksMenuPanel => !showBookmarksMenuPanel);
    // dispatch(editDataAction({
    //   op: 'edit_user_bookmarks',
    //   params: {
    //     location__id: locationidentifier,
    //     bookmark: {
    //       link: `/reputation/locations/${locationidentifier}/dashboard`,
    //       name: locationName,
    //     },
    //   },
    // }));
  };
  const onBookmarkItemClick = (name, location_name, link, add) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onBookmarkItemClick name: ', name);
      console.log('onBookmarkItemClick link: ', link);
    }
    // let locationidentifier = '';
    // locationidentifier = pathname.indexOf('/locations/') > -1 ? pathname.split('/locations/')[1] : '';
    // locationidentifier = locationidentifier ? locationidentifier.split('/')[0] : '';
    // // let groupidentifier = '';
    // // groupidentifier = pathname.indexOf('/groups/') > -1 ? pathname.split('/groups/')[1] : '';
    // // groupidentifier = groupidentifier ? groupidentifier.split('/')[0] : '';

    // const { locations } = userState.user.user_info;

    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('TopBar onReputationMenuDashboardClick locations[locationidentifier]: ', locations[locationidentifier]);
    // }

    // let locationName = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].name : '';
    // if (!locationName) {
    //   console.log('TopBar onReputationMenuDashboardClick locationName missing');
    //   return;
    // }

    // setShowBookmarksMenuPanel(showBookmarksMenuPanel => !showBookmarksMenuPanel);
    dispatch(editDataAction({
      op: 'edit_user_bookmarks',
      params: {
        location__id: locationidentifier,
        bookmark: {
          link: link,
          name: name,
          location_name: location_name,
          add: add,
        },
      },
    }));
  };

  const onBookmarksMenuMouseEnter = (e) => {
    setShowBookmarksMenuPanel(true);
  };
  const onBookmarksMenuMouseLeave = (e) => {
    setShowBookmarksMenuPanel(false);
  };


  // reputation
  const onReputationMenuClick = (e) => {
    if (e.target.tagName === 'A') {
      setShowReputationMenuPanel(showReputationMenuPanel => !showReputationMenuPanel);
    }
  };
  const onReputationMenuMouseEnter = (e) => {
    setShowReputationMenuPanel(true);
  };
  const onReputationMenuMouseLeave = (e) => {
    setShowReputationMenuPanel(false);
  };

  // engage
  const onEngageMenuClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEngageMenuClick showEngageMenuPanel: ', e);
    }
    if (e.target.tagName === 'A') {
      setShowEngageMenuPanel(showEngageMenuPanel => !showEngageMenuPanel);
    }
  };
  const onEngageMenuMouseEnter = (e) => {
    setShowEngageMenuPanel(true);
  };
  const onEngageMenuMouseLeave = (e) => {
    setShowEngageMenuPanel(false);
  };

  // marketing
  const onMarketingMenuClick = (e) => {
    setShowMarketingMenuPanel(showMarketingMenuPanel => !showMarketingMenuPanel);
  };
  const onMarketingMenuMouseEnter = (e) => {
    setShowMarketingMenuPanel(true);
  };
  const onMarketingMenuMouseLeave = (e) => {
    setShowMarketingMenuPanel(false);
  };


  const onOpenHelpClick = (e) => {
    // window.FreshworksWidget('open');
    // window.FreshworksWidget('identify', 'ticketForm', {
    //   name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //   email: userState.user.user_info.uid,
    //   subject: `From ${locationName}`,
    // });
    // setTimeout(() => {
    //   window.Intercom("boot", {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "mydf4dtr",
    //     name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //       email: userState.user.uid, // the email for your user
    //     // created_at: user.createdAt, // Signup date as a Unix timestamp
    //   });
    // }, 500);
    
    try { window.Intercom('showSpace', 'help'); } catch(e) {}
    // window.Intercom('showNewMessage', 'Enable engage for us');
  }

  // location selector
  const onOpenLocationSelectorClick = (e) => {
    if (!userState.user.user_info) return null;
    const { locations, locations_list } = userState.user.user_info;
    if (locations_list && locations_list.length > 1) {
      setShowLocationSelectorPanel(showLocationSelectorPanel => !showLocationSelectorPanel);
      setTimeout(() => {
        if (locationSelectorInputRef && locationSelectorInputRef.current) {
          locationSelectorInputRef.current.focus();
        }
      }, 100);
    }
  };

  // profile dropdown
  const onProfileClick = (e) => {
    setShowProfilePanel(showProfilePanel => !showProfilePanel);
  };

  // notifications modal click
  const onNotificationsIconClick = (e) => {
    setShowNotificationsModal(true);
  };
  const onNotificationsModalCloseClick = (e) => {
    setShowNotificationsModal(false);
  };

  const onLocationKeywordChange = (e) => {
    setLocationKeyword(e.target.value);
  };
  const onLocationSelectorClearFilter = (e) => {
    setLocationKeyword('');
  };
  const onLocationKeywordKeyDown = (e) => {
    if (e.keyCode === 27) {
      setLocationKeyword('');
    }
  };

  const onLocationRowClick = (locInfo) => {
    setShowLocationSelectorPanel(false);
    if (product === 'engage') {
      if (locInfo.has_engage) {
        navigate(`/engage/locations/${locInfo._id}/messages`); return;
      }
      if (locInfo.has_transaction_emails) {
        navigate(`/marketing/locations/${locInfo._id}`); return;
      }
      navigate(`/reputation/locations/${locInfo._id}`); return;
    }
    if (product === 'reputation') {
      if (locInfo.has_gt_response || locInfo.has_self_response) {
        navigate(`/reputation/locations/${locInfo._id}`); return;
      }
      if (locInfo.has_engage) {
        navigate(`/engage/locations/${locInfo._id}/messages`); return;
      }
      if (locInfo.has_transaction_emails) {
        navigate(`/marketing/locations/${locInfo._id}`); return;
      }
    }
    if (product === 'marketing') {
      if (locInfo.has_transaction_emails) {
        navigate(`/marketing/locations/${locInfo._id}`); return;
      }
      if (locInfo.has_gt_response || locInfo.has_self_response) {
        navigate(`/reputation/locations/${locInfo._id}`); return;
      }
      if (locInfo.has_engage) {
        navigate(`/engage/locations/${locInfo._id}/messages`); return;
      }
    }
    if (product === 'settings') {
      if (locInfo.has_transaction_emails) {
        navigate(`/settings/locations/${locInfo._id}`); return;
      }
      if (locInfo.has_gt_response || locInfo.has_self_response) {
        navigate(`/settings/locations/${locInfo._id}`); return;
      }
      if (locInfo.has_engage) {
        navigate(`/settings/locations/${locInfo._id}/messages`); return;
      }
    }
  };

  const onLogoutClick = (e) => {
    dispatch(logout({}));
  };

  const renderNotificationsModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="gModal ntModal">
          <div className="content" ref={notificationsModalRef}>
            <div className="sideModal">
              <Notifications
                onCancelClick={onNotificationsModalCloseClick}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const { pathname } = location;
  if (!pathname) return null;
  // if (pathname.indexOf('/locations/') === -1) return null;

  let locationidentifier = '';
  locationidentifier = pathname.indexOf('/locations/') > -1 ? pathname.split('/locations/')[1] : '';
  locationidentifier = locationidentifier ? locationidentifier.split('/')[0] : '';

  let groupidentifier = '';
  groupidentifier = pathname.indexOf('/groups/') > -1 ? pathname.split('/groups/')[1] : '';
  groupidentifier = groupidentifier ? groupidentifier.split('/')[0] : '';

  // const { locationidentifier, groupIdentifier } = urlParams;
  // if (!locationidentifier) return null;
  if (!userState.user.user_info) return null;

  const { locations, locations_list } = userState.user.user_info;

  if (process.env.NODE_ENV !== 'production') {
    console.log('TopBar locations[locationidentifier]: ', locations[locationidentifier]);
  }

  const bookmarks = userState.user.user_info.profile && userState.user.user_info.profile.bookmarks
    ? userState.user.user_info.profile.bookmarks || [] : [];
  if (process.env.NODE_ENV !== 'production') {
    console.log('TopBar bookmarks: ', bookmarks);
  }

  let locationName = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].name : '';
  let smsNumber = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].sms_number : '';
  let smsNumberFormatted = smsNumber ? formatPhoneNumberIntl(smsNumber) : '';
  let whatsappNumber = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].whatsapp_number : '';
  let whatsappNumberFormatted = whatsappNumber ? formatPhoneNumberIntl(whatsappNumber) : '';

  let hasInstantInvite = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].has_instant_invite || false : false;

  let hasReputation = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].has_reputation : false;
  let hasEngage = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].has_engage : false;
  let hasMarketing = locationidentifier && locations[locationidentifier] ? locations[locationidentifier].has_transaction_emails : false;

  let hasAccessToReputation = false;
  let hasAccessToEngage = false;
  let hasAccessToMarketing = false;
  if (locationidentifier) {
    ({ hasAccessToReputation, hasAccessToEngage, hasAccessToMarketing  } = getProductAccess(userState.user.user_info, locationidentifier))
  }

  const reputationMenuActive = pathname.indexOf('/reputation') > -1 && pathname.indexOf('/settings') === -1 ? true : false;
  const engageMenuActive = pathname.indexOf('/engage') > -1 && pathname.indexOf('/settings') === -1 ? true : false;
  const marketingMenuActive = pathname.indexOf('/marketing') > -1 && pathname.indexOf('/settings') === -1 ? true : false;
  const responseGeneratorMenuActive = pathname.indexOf('/response-generator') > -1 && pathname.indexOf('/settings') === -1 ? true : false;

  const locationStateInfo = locationState.locations ? locationState.locations[locationidentifier] || {} : {};

  let reputationUnreadCount = 0, engageUnreadCount = 0, marketingUnreadCount = 0;
  if (locationStateInfo && locationStateInfo.engage) {
    if (locationStateInfo.engage.guests && locationStateInfo.engage.guests.unreadCount) engageUnreadCount++;
    if (locationStateInfo.engage.pastGuests && locationStateInfo.engage.pastGuests.unreadCount) engageUnreadCount++;
    if (locationStateInfo.engage.upcomingGuests && locationStateInfo.engage.upcomingGuests.unreadCount) engageUnreadCount++;
    if (locationStateInfo.engage.outsideGuests && locationStateInfo.engage.outsideGuests.unreadCount) engageUnreadCount++;
    if (locationStateInfo.engage.gbm && locationStateInfo.engage.gbm.unreadCount) engageUnreadCount++;
    if (locationStateInfo.engage.staff && locationStateInfo.engage.staff.unreadCount) engageUnreadCount++;
    if (locationStateInfo.engage.webtext && locationStateInfo.engage.webtext.unreadCount) engageUnreadCount++;
  }

  const { logo, name } = appState.appInfo;

  if (process.env.NODE_ENV !== 'production') {
    console.log('##TopBar groupidentifier: ', groupidentifier);
  }

  return (
    <div className="tb">
      <div className="l">
        <div className="logo">
          <img src={logo || gtLogo} />
          <Link to="#">{name}</Link>
        </div>
      </div>
      <div className="m">
        <div className="prd rp">
          <div ref={bookmarksMenuPanelRef} className={'dropdown ' + (showBookmarksMenuPanel ? 'open' : '')}
            onMouseEnter={onBookmarksMenuMouseEnter}
            onMouseLeave={onBookmarksMenuMouseLeave}
            onClick={onBookmarksMenuClick}
          >
            <div className="dropbtn">
              <i class="fa-regular fa-bookmark"></i>
            </div>
            <div className="dropdown-content">
              {
                bookmarks.map((itm) => {
                  let name = itm.name;
                  if (userState.user.user_info.locations_list && userState.user.user_info.locations_list.length) {
                    name = `${itm.location_name} - ${itm.name}`;
                  }
                  return (
                    <Link to={itm.link}>{name}
                    <BookmarkItem
                        // name={locations_list.length > 1 ? `Engage Messages - ${locationName}` : `Engage Messages`}
                        name={`Engage Messages`}
                        location_name={itm.location_name}
                        link={itm.link}
                        onItemClick={onBookmarkItemClick}
                        onlyRemove={true}
                        bookmarks={bookmarks}
                    />
                    </Link>
                  )
                })
              }
            </div>
          </div>
        </div>
        {
          props.src === 'IntgConfigure'
          ? null
          :
            <>
              {
                appState.appInfo.reputation_enabled &&
                <>
                  {
                    locationidentifier
                    ?
                      <div className="prd rp">
                        <div ref={reputationMenuPanelRef} className={'dropdown ' + (showReputationMenuPanel ? 'open' : '') + (reputationMenuActive ? ' mActive' : '')}
                          onMouseEnter={onReputationMenuMouseEnter}
                          onMouseLeave={onReputationMenuMouseLeave}
                          // onClick={onReputationMenuClick}
                        >
                          <div className="dropbtn">
                          {
                            hasReputation
                            ? <span>Reputation</span>
                            : <Link to={`/reputation/locations/${locationidentifier}?reputation-demo=1`}>Reputation</Link>
                          }
                          </div>
                          {
                            hasReputation && hasAccessToReputation &&
                            <div className="dropdown-content">
                              <Link to={`/reputation/locations/${locationidentifier}/dashboard`}>
                                Dashboard
                                <BookmarkItem
                                  // name={locations_list.length > 1 ? `Instant Invite - ${locationName}` : `Instant Invite`}
                                  name={`Reputation Dashboard`}
                                  location_name={locationName}
                                  link={`/reputation/locations/${locationidentifier}/dashboard`}
                                  onItemClick={onBookmarkItemClick}
                                  bookmarks={bookmarks}
                                />
                                {/*<span 
                                  onMouseEnter={onBookmarksMenuMouseEnter}
                                  onMouseLeave={onBookmarksMenuMouseLeave}
                                  onClick={onBookmarksMenuClick}
                                ><i class={'fa-bookmark' + (showBookmarksMenuPanel || bookmarksContain(bookmarks, `/reputation/locations/${locationidentifier}/dashboard`) ? " fa-solid" : " fa-regular")}></i></span>*/}
                              </Link>
                              {
                                hasInstantInvite &&
                                <Link to={`/reputation/locations/${locationidentifier}/instant-invite`} onClick={onReputationMenuClick}>
                                  Instant Invite
                                  <BookmarkItem
                                    // name={locations_list.length > 1 ? `Instant Invite - ${locationName}` : `Instant Invite`}
                                    name={`Instant Invite`}
                                    location_name={locationName}
                                    link={`/reputation/locations/${locationidentifier}/instant-invite`}
                                    onItemClick={onBookmarkItemClick}
                                    bookmarks={bookmarks}
                                  />
                                </Link>
                              }
                              <Link to={`/settings/locations/${locationidentifier}/reviews-widget`}>Reviews Widget</Link>
                            </div>
                          }
                        </div>
                      </div>
                    :
                      <div className="prd rp">
                        <div className={'dropdown ' + (reputationMenuActive ? ' mActive' : '')}>
                          <div className="dropbtn">
                            <Link to="/reputation">Reputation</Link>
                          </div>
                        </div>
                      </div>
                  }
                </>
              }
              {
                appState.appInfo.engage_enabled &&
                <>
                  {
                    locationidentifier
                    ?
                      <div className="prd eg">
                        <div ref={engageMenuPanelRef} className={'dropdown ' + (showEngageMenuPanel ? 'open' : '') + (engageMenuActive ? ' mActive' : '')}
                          onMouseEnter={onEngageMenuMouseEnter}
                          onMouseLeave={onEngageMenuMouseLeave}
                          // onClick={onEngageMenuClick}
                        >
                          <div className="dropbtn">
                          {
                            hasEngage
                            ?
                              <>
                                <span>Engage</span>
                                { hasAccessToEngage && engageUnreadCount ? <span className="unrCnt">{engageUnreadCount}</span> : null }
                              </>
                            : <Link to={`/engage/locations/${locationidentifier}?engage-demo=1`}>Engage</Link>
                          }
                          </div>
                          {
                            hasEngage && hasAccessToEngage &&
                            <div className="dropdown-content">
                              <Link to={`/engage/locations/${locationidentifier}/messages`} onClick={onEngageMenuClick}>
                                Messages
                                <BookmarkItem
                                    // name={locations_list.length > 1 ? `Engage Messages - ${locationName}` : `Engage Messages`}
                                    name={`Engage Messages`}
                                    location_name={locationName}
                                    link={`/engage/locations/${locationidentifier}/messages`}
                                    onItemClick={onBookmarkItemClick}
                                    bookmarks={bookmarks}
                                />
                              </Link>
                              {/* <Link to={`/engage/locations/${locationidentifier}/journey-messages`}>Journey Message Rules</Link> */}
                              <Link to={`/engage/locations/${locationidentifier}/broadcast`}>Broadcast</Link>
                              {/*<Link to={`/engage/locations/${locationidentifier}/guide`}>Digital Guide</Link>*/}
                            </div>
                          }
                        </div>
                      </div>
                    :
                      <div className="prd eg">
                        <div className={'dropdown ' + (engageMenuActive ? ' mActive' : '')}>
                          <div className="dropbtn">
                            <Link to="/engage">Engage</Link>
                          </div>
                        </div>
                      </div>
                  }
                </>
              }
              {
                appState.appInfo.marketing_enabled &&
                <>
                  {
                    locationidentifier
                    ?
                      <div className="prd mk">
                        <div ref={marketingMenuPanelRef} className={'dropdown ' + (showMarketingMenuPanel ? 'open' : '') + (marketingMenuActive ? ' mActive' : '')}
                          onMouseEnter={onMarketingMenuMouseEnter}
                          onMouseLeave={onMarketingMenuMouseLeave}
                          onClick={onMarketingMenuClick}
                        >
                          <div className="dropbtn">
                            {
                              hasMarketing
                              ? <span>Marketing</span>
                              : <Link to={`/marketing/locations/${locationidentifier}?marketing-demo=1`}>Marketing</Link>
                            }
                          </div>
                          {
                            hasMarketing && hasAccessToMarketing &&
                            <div className="dropdown-content">
                              <Link to={`/marketing/locations/${locationidentifier}/overview`}>Overview</Link>
                              <Link to={`/marketing/locations/${locationidentifier}/transaction-emails/configure`}>Transaction Emails</Link>
                              {/*<Link to={`/marketing/locations/${locationidentifier}/email-marketing`}>Email Marketing</Link>*/}
                            </div>
                          }
                        </div>
                      </div>
                    :
                      <div className="prd mk">
                        <div className={'dropdown ' + (marketingMenuActive ? ' mActive' : '')}>
                          <div className="dropbtn">
                            <Link to="/marketing">Marketing</Link>
                          </div>
                        </div>
                      </div>
                  }
                </>
              }
              {
                appState.appInfo.response_generator_enabled &&
                (userState.user.user_id === '994dfb9b1b674a6d' ||
                  userState.user.user_id === '6b1dbd9b232d485e' ||
                  (userState.user.user_info && userState.user.user_info.profile &&
                    (userState.user.user_info.profile.rg_access_level === 'RG_ADMIN' || userState.user.user_info.profile.rg_access_level === 'RG_GENERIC_USER')
                  )
                ) &&
                <>
                  <div className="prd mk">
                    <div className={'dropdown ' + (responseGeneratorMenuActive ? ' mActive' : '')}>
                      <div className="dropbtn">
                        <Link to="/response-generator">ReviewResponseAI</Link>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
        }
      </div>
      <div className="r">
        <div className="opt">
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Help"}>
            <img src={helpIcon} onClick={onOpenHelpClick} />
          </ToolTipWrapper>
          {/*<ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Notifications"}>
            <img src={notificationIcon} onClick={onNotificationsIconClick}/>
          </ToolTipWrapper>*/}
        </div>
        <div className="lOpt">
          {
            (product === 'reputation' || product === 'engage' || product === 'marketing' || product === 'settings') &&
            locationidentifier &&
            <div className="sep" />
          }
          {
            (!groupidentifier &&
              (product === 'reputation' || product === 'engage' || product === 'marketing' || product === 'settings') &&
              locationidentifier
            ) &&
            <div className="ls">

                <div className="hn" onClick={onOpenLocationSelectorClick}>
                  <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={locationName}>
                    <><span>{locationName}</span><br/></>
                  </ToolTipWrapper>
                  {
                    product === 'engage' &&
                    <>
                      {
                        appState.appInfo.engage_enabled && locations[locationidentifier] && locations[locationidentifier].has_engage_sms && smsNumber &&
                        <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Business Messaging Number"}>
                          <span className="engCnt">{smsNumberFormatted}</span>
                        </ToolTipWrapper>
                      }
                      {
                        appState.appInfo.engage_enabled && locations[locationidentifier] && locations[locationidentifier].has_engage_whatsapp && whatsappNumber &&
                        <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Whatsapp Messaging Number"}>
                          <span className="engCnt">{whatsappNumberFormatted}</span>
                        </ToolTipWrapper>
                      }
                    </>
                  }
                </div>
              <div className="lsIcon">

                <div ref={locationSelectorRef} className={'dropdown ' + (showLocationSelectorPanel ? 'open' : '')}>
                  <div className="dropbtn">
                    <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={locationName ? "Change Location" : 'Select Location'}>
                      <img src={caretDownIcon} onClick={onOpenLocationSelectorClick} />
                    </ToolTipWrapper>
                  </div>
                  <div className="dropdown-content">
                    <div className="filter">
                      <input type="text" placeholder="Filter" onChange={onLocationKeywordChange}
                        value={locationKeyword} ref={locationSelectorInputRef}
                        onKeyDown={onLocationKeywordKeyDown}
                      />
                      <span onClick={onLocationSelectorClearFilter}><i className="fa fa-times"></i></span>
                    </div>
                    {
                      locations_list.length
                      ? locations_list.sort((a,b) => {
                        if (locations[a].unreadCount && !locations[b].unreadCount) return -1;
                        if (locations[b].unreadCount && !locations[a].unreadCount) return 1;
                        if (locations[b].unreadCount && locations[a].unreadCount) return 0;
                        return 0;
                      }).map((item) => {
                        if (locationKeyword) {
                          const reg = new RegExp(locationKeyword, "i")
                          if (
                            (locations[item].name && locations[item].name.search(reg) > -1) ||
                            (locations[item].sms_number && locations[item].sms_number.search(reg) > -1) ||
                            (locations[item].whatsapp_number && locations[item].whatsapp_number.search(reg) > -1)
                          ) {
                            return <LocationRow key={`tb_l_${item}`} location={locations[item]} appInfo={appState.appInfo} userState={userState} onRowClick={onLocationRowClick} />
                          }
                          return null;
                        }
                        return <LocationRow key={`tb_l_${item}`} location={locations[item]} appInfo={appState.appInfo} userState={userState} onRowClick={onLocationRowClick} />
                      })
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {
            locationidentifier &&
            <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Settings"}>
              <Link to={`/settings/locations/${locationidentifier}`} className="setngs"
                state={{ screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: pathname }}
              ><img src={settingsIcon} /></Link>
            </ToolTipWrapper>
          }
        </div>
        <div className="prf">

          <div className="prfMenu">
            <div ref={profilePanelRef} className={'dropdown ' + (showProfilePanel ? 'open' : '')}
              onClick={onProfileClick}
            >
              <div className="dropbtn">
                <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Profile"}>
                  <img src={profileIcon} />
                </ToolTipWrapper>
              </div>
              {
                props.src === 'IntgConfigure'
                ?
                  <div className="dropdown-content">
                    <Link to={`/logout`} onClick={onLogoutClick}>Logout</Link>
                  </div>
                :
                  <div className="dropdown-content">
                    <Link to={`/profile/info`}
                      state={{ screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: pathname }}
                    >Profile</Link>
                    <Link to={`/profile/change-password`}
                      state={{ screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: pathname }}
                    >Change Password</Link>
                    <Link to={`/profile/notifications`}
                      state={{ screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: pathname }}
                    >Desktop Notifications</Link>
                    <Link to={`/logout`} onClick={onLogoutClick}>Logout</Link>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      { showNotificationsModal && renderNotificationsModal() }
    </div>
  );
}

export default TopBar;
