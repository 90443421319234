import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { Rnd } from "react-rnd";

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import SendTestEmailModal from '../Customize/SendTestEmailModal';

import overviewIcon from '../../../../../img/icons/feedback.svg';
import overviewIconActive from '../../../../../img/icons/feedback-active.svg';
import uploadCSVIcon from '../../../../../img/icons/feedback.svg';
import uploadCSVIconActive from '../../../../../img/icons/feedback.svg';
import uploadHistoryIcon from '../../../../../img/icons/feedback.svg';
import uploadHistoryIconActive from '../../../../../img/icons/feedback.svg';

import './style.css';

function Stage(props) {
  const { stage, locationidentifier, active, link } = props;
  return (
    <Link to={link}
      className={(active ? 'active' : '')}
    >
      <div className="lt">
        <img src={active ? stage.iconActive : stage.icon} />
      </div>
      <div className="rt">
        <p className="title">{stage.name}</p>
        <p className="desc">{stage.desc}</p>
      </div>
    </Link>
  );
}

function ListPanel(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [boxWidth, setBoxWidth] = useState(240);
  const [list, setList] = useState([]);
  const [customizeFeedbackList, setCustomizeFeedbackList] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState('default');

  const [testEmailError, setTestEmailError] = useState('');
  const [showSendTestEmailModal, setShowSendTestEmailModal] = useState(false);

  useEffect(() => {
    let listLocal = [];
    listLocal.push({ 'id': 'overview', 'name': 'Overview', 'desc': 'All Feedbacks', 'icon': overviewIcon, 'iconActive': overviewIconActive });
    listLocal.push({ 'id': 'run-campaign', 'name': 'Run Campaign', 'desc': 'Run a new campaign', 'icon': uploadCSVIcon, 'iconActive': uploadCSVIconActive });
    listLocal.push({ 'id': 'requests-sent', 'name': 'Requests Sent', 'desc': 'Requests sent for feedback', 'icon': uploadHistoryIcon, 'iconActive': uploadHistoryIconActive });
    listLocal.push({ 'id': 'campaign-history', 'name': 'Campaign History', 'desc': 'Campaign history', 'icon': uploadHistoryIcon, 'iconActive': uploadHistoryIconActive });
    listLocal.push({ 'id': 'customize-feedbacks', 'name': 'Customize Feedbacks', 'desc': 'Customize Feedbacks', 'icon': uploadHistoryIcon, 'iconActive': uploadHistoryIconActive });
    setList(listLocal);

    let customizeFeedbackListLocal = [];
    customizeFeedbackListLocal.push(
      {'id': 'type', 'name': 'Feedback Type', 'desc': 'Feedback Type', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    );
    // customizeFeedbackListLocal.push(
    //   {'id': 'hotel-details', 'name': 'Hotel Details', 'desc': 'Hotel Details', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    // );
    customizeFeedbackListLocal.push(
      {'id': 'customize-email', 'name': 'Customize Email', 'desc': 'Customize Email', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    );
    customizeFeedbackListLocal.push(
      {'id': 'feedback-positive', 'name': 'Feedback Positive', 'desc': 'Feedback Positive', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    );
    customizeFeedbackListLocal.push(
      {'id': 'feedback-negative', 'name': 'Feedback Negative', 'desc': 'Feedback Negative', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    );
    // customizeFeedbackListLocal.push(
    //   {'id': 'additional-questions', 'name': 'Additional Questions', 'desc': 'Additional Questions', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    // );
    customizeFeedbackListLocal.push(
      {'id': 'send-test-email', 'name': 'Send Test Email', 'desc': 'Send Test Email', 'icon': overviewIcon, 'iconActive': overviewIconActive},
    );
    setCustomizeFeedbackList(customizeFeedbackListLocal);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('location.pathname: ', location.pathname);
    }
    if (location.pathname.indexOf('/feedbacks/customize-feedbacks') > -1) {
      setSelectedMenu('customize-feedbacks');
    } else {
      setSelectedMenu('default');
    }
  }, [location.pathname]);

  const onSendTestEmailClick = (e) => {
    setTestEmailError('');
    const params = {
      location__id: urlParams.locationidentifier,
    };
    dispatch(editDataClearAction({
      op: 'send_test_email_custom_feedback',
      params,
    }));
    setShowSendTestEmailModal(true);
  };

  const onShowSendTestEmailModalCloseModal = (e) => {
    setShowSendTestEmailModal(false);
  };
  const onSendTestEmailModalConfirmClick = (email) => {
    const params = {
      location__id: urlParams.locationidentifier,
      to: email,
    };
    dispatch(editDataAction({
      op: 'send_test_email_custom_feedback',
      params,
    }));
  };

  const { locationidentifier, stage } = urlParams;
  const { has_custom_feedback } = props.locationInfo;
  const { reputation } = props.settings;
  let feedback_settings = reputation && reputation.feedback_settings ? { ...reputation.feedback_settings } : {};

  let sendTestEmailInProgress = feedback_settings.sendTestEmailInProgress || false;
  let sendTestEmailStatus = feedback_settings.sendTestEmailStatus || {};

  const { hasAccessToRunFeedbackCampaign } = props.repAccess;

  return (
    <>
      <Rnd
        className={'fdbMenuLst'}
        size={{width: boxWidth}}
        style={{}}
        disableDragging={true}
        enableResizing={{
          right: true,
        }}
        maxWidth={300}
        minWidth={180}
        onResize={(e, direction, ref, delta, position) => {
          setBoxWidth(ref.style.width);
        }}
      >
        <div className="rszIndc">
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
        </div>
        {
          selectedMenu === 'default'
          ?
            <>
              <div className="hdr">
                Direct Feedbacks
              </div>
              <div className="mnuLst">
                {
                  list.map((stage) => {
                    if (stage.id === 'customize-feedbacks' || stage.id === 'run-campaign') {
                      if (!hasAccessToRunFeedbackCampaign) return null;
                    }
                    let active = location.pathname.indexOf(`/reputation/locations/${locationidentifier}/feedbacks/${stage.id}`) > -1;
                    return (
                      <Stage key={`te_stg_${stage.id}`} stage={stage} locationidentifier={locationidentifier} active={active}
                        link={`/reputation/locations/${locationidentifier}/feedbacks/${stage.id}`}
                      />
                    )
                  })
                }
              </div>
            </>
          : null
        }
        {
          selectedMenu === 'customize-feedbacks' && hasAccessToRunFeedbackCampaign
          ?
            <>
              <div className="hdr bold">
                <Link to={`/reputation/locations/${locationidentifier}/feedbacks`}>Back</Link>
              </div>
              <div className="hdr bold">
                Customize Feedbacks
              </div>
              <div className="mnuLst">
                {
                  has_custom_feedback &&
                  customizeFeedbackList.map((stage) => {
                    let active = location.pathname.indexOf(`/reputation/locations/${locationidentifier}/feedbacks/customize-feedbacks/${stage.id}`) > -1;
                    if (stage.id === 'send-test-email') {
                      return (
                        <a
                          className='sendTestEmail'
                          onClick={onSendTestEmailClick}
                        >
                          <div className="lt">
                            <img src={active ? stage.iconActive : stage.icon} />
                          </div>
                          <div className="rt">
                            <p className="title">{stage.name}</p>
                            <p className="desc">{stage.desc}</p>
                          </div>
                        </a>
                      );
                    }
                    return (
                      <Stage key={`te_stg_${stage.id}`} stage={stage} locationidentifier={locationidentifier} active={active}
                        link={`/reputation/locations/${locationidentifier}/feedbacks/customize-feedbacks/${stage.id}`}
                      />
                    )
                  })
                }
              </div>
            </>
          : null
        }
      </Rnd>
      { showSendTestEmailModal &&
        <SendTestEmailModal
          modalType="templateSelector"
          title={`Send Test Email`}
          bodyRowOne=""
          bodyRowNote="Guest specific dynamic variables will be replaced in LIVE emails."
          setModalVisible={onShowSendTestEmailModalCloseModal}
          onAffirmativeClick={onSendTestEmailModalConfirmClick}
          inProgress={sendTestEmailInProgress}
          currentStatus={sendTestEmailStatus}
          showCancel={true}
          affirmativeButtonLabel={'Proceed'}
          disableOutsideClick={true}
          to={userState.user.user_info.uid}
        />
      }
    </>
  );
}

export default ListPanel;
