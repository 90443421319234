
/* *************** Start - Edit Settings Review Channels **************** */

export const editSettingsReviewChannelsClear = (state, action) => {
  const { params: { location__id, ota } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            review_channels: {
              [ota]: {
                editInProgress: false,
                editStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const editSettingsReviewChannels = (state, action) => {
  const { params: { location__id, ota } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            review_channels: {
              [ota]: {
                editInProgress: true,
                editStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const editSettingsReviewChannelsSuccess = (state, action) => {
  const { data, params: { location__id, ota } } = action.payload;
  if (!location__id) return state;
  const { location_info } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            otas: {
              ...location_info.otas,
            },
            review_channels: {
              [ota]: {
                editInProgress: false,
                editStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
}

export const editSettingsReviewChannelsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, ota } = params;
  if (!location__id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            review_channels: {
              [ota]: {
                editInProgress: false,
                editStatus: {
                  error: true,
                },
              },
            },
          },
        },
      },
    },
  };
}
