import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import validateColor from "validate-color";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import GenericAlertModal from '../../../../Misc/GenericAlertModal';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, 
  STRS, CSS_COLORS } from '../../../../../helpers';

// import avataarIcon from '../../../../../img/icons/avataar.svg';

import '../style.css';

function CheckBoxRow(props) {
  const updateValue = () => {
    // if (props.value) return; // do not uncheck
    props.onChange(!props.value);
  };
  const onLabelClick = (e) => { updateValue(); }
  const onChange = (e) => { updateValue(); };

  return (
    <div className={'chkBxRw ' + (props.value ? 'selected ' : '') + (props.nested ? 'nested' : '')}>
      <input className="checkbox" type="checkbox" name={props.name} onChange={onChange} value={props.value === props.dtVal ? 'on' : 'off'} checked={props.value === props.dtVal} />
      <div className="gLabel" onClick={onLabelClick}>
        {props.label}
        {
          props.toolTip &&
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay={props.toolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        }
      </div>
    </div>
  );
}

function RWGeneral(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [numberOfReviews, setNumberOfReviews] = useState(10);
  const [numberOfReviewsError, setNumberOfReviewsError] = useState('');

  const [dtFmt, setDtFmt] = useState('');

  const [colorBackground, setColorBackground] = useState('');
  const [colorBackgroundHex, setColorBackgroundHex] = useState('');
  const [colorBackgroundError, setColorBackgroundError] = useState('');

  const [colorFont, setColorFont] = useState('');
  const [colorFontHex, setColorFontHex] = useState('');
  const [colorFontError, setColorFontError] = useState('');

  const [formChanged, setFormChanged] = useState(false);

  const setInitialValues = () => {
    const { settings: { reviews_widget } } = props;
    if (reviews_widget) {
      if (reviews_widget.widget_id) {
        setNumberOfReviews(reviews_widget.reviews_widget_number_of_reviews || '');
        setDtFmt(reviews_widget.reviews_widget_date_format || '');
        setColorBackground(reviews_widget.reviews_widget_config ? reviews_widget.reviews_widget_config['--background-color-gtw-reviews-base'] || '#FFFFFF' : 'FFFFFF');
        setColorBackgroundHex(reviews_widget.reviews_widget_config ? reviews_widget.reviews_widget_config['--background-color-gtw-reviews-base'] || '#FFFFFF' : 'FFFFFF');
        setColorFont(reviews_widget.reviews_widget_config ? reviews_widget.reviews_widget_config['--color-gtw-reviews-darkest'] || '#000000' : '000000');
        setColorFontHex(reviews_widget.reviews_widget_config ? reviews_widget.reviews_widget_config['--color-gtw-reviews-darkest'] || '#000000' : '000000');
      }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.settings.reviews_widget.editInProgress &&
      props.settings.reviews_widget.editStatus &&
      props.settings.reviews_widget.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.reviews_widget]);

  const onNumberOfReviewsChange = (e) => {
    const { name, value } = e.target;
    setNumberOfReviews(value); setFormChanged(true); // clearAction();
    if (process.env.NODE_ENV !== 'production') {
      console.log('onNumberOfReviewsChange: ', Number.isInteger(parseInt(value)));
    }
    if (Number.isInteger(parseInt(value))) {
      setNumberOfReviewsError(value < 0 ? 'Invalid value' : '');
    } else {
      setNumberOfReviewsError('Required');
    }
  };

  const onDtFmt1Change = (e) => {
    setDtFmt('MM/DD/YYYY');
    setFormChanged(true);
  };
  const onDtFmt2Change = (e) => {
    setDtFmt('DD-MM-YYYY');
    setFormChanged(true);
  };

  const onBackgroundColorChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onBackgroundColorChange: ', value.toHexString());
    }
    setColorBackground(value.toHexString());
    setColorBackgroundHex(value.toHexString());
    setFormChanged(true);
  };
  const onBackgroundColorTextChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onBackgroundColorTextChange: ', e.target.value);
    }
    // if (!value) value = colorBackground;
    setColorBackgroundHex(e.target.value);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onBackgroundColorTextChange isColor: ', validateColor(e.target.value));
      console.log('onBackgroundColorTextChange toLowerCase: ', CSS_COLORS[e.target.value.toLowerCase()]);
    }
    if (e.target.value) {
      if (validateColor(e.target.value)) {
        setColorBackground(e.target.value);
        setColorBackgroundError('');
      } else {
        setColorBackgroundError('Required');
      }
      if (CSS_COLORS[e.target.value.toLowerCase()]) setColorBackground(CSS_COLORS[e.target.value.toLowerCase()]);
    } else {
      setColorBackgroundError('Required');
    }
    setFormChanged(true);
  };
  const onFontColorChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFontColorChange: ', value.toHexString());
    }
    setColorFont(value.toHexString());
    setFormChanged(true);
  };
  const onFontColorTextChange = (e) => {
    setColorFontHex(e.target.value);
    if (e.target.value) {
      if (validateColor(e.target.value)) {
        setColorFont(e.target.value);
        setColorFontError('');
      } else {
        setColorFontError('Required');
      }
      if (CSS_COLORS[e.target.value.toLowerCase()]) setColorFont(CSS_COLORS[e.target.value.toLowerCase()]);
    } else {
      setColorFontError('Required');
    }
    setFormChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { reviews_widget } = props.settings;
    if (reviews_widget && reviews_widget.editInProgress) return;
    if (!reviews_widget || !reviews_widget.widget_id) return;

    let hasError = false;

    if (Number.isInteger(parseInt(numberOfReviews))) {
      setNumberOfReviewsError(numberOfReviews < 0 ? 'Invalid value' : '');
      if (numberOfReviews < 0) hasError = true;
    } else {
      setNumberOfReviewsError('Required');
      hasError = true;
    }
    if (colorBackgroundError) hasError = true;
    if (colorFontError) hasError = true;

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
      widget_id: reviews_widget.widget_id,
    };
    params.number_of_reviews = parseInt(numberOfReviews);
    params.date_format = dtFmt;
    params.color_background = colorBackground;
    params.color_font = colorFont;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_reviews_widget_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };





  const renderNumberOfReviews = () => {
    return (
      <div className="gBCtRw astntNm">
        <div className="lt">
          <p className="title">Number of reviews</p>
          <p className="desc">Number of reviews that will be shown on web page (Recommended: 10).</p>
          <div className={'gIpt ' + (numberOfReviewsError ? 'error' : '')}>
            <input
              type="text"
              value={numberOfReviews}
              onChange={onNumberOfReviewsChange}
            />
            { numberOfReviewsError && <p className="gErr">{numberOfReviewsError}</p> }
          </div>
        </div>
      </div>
    );
  };

  const renderColorPickerBackground = () => {
    return (
      <div className="gBCtRw astntNm">
        <div className="lt">
          <p className="title">Background color</p>
          <p className="desc">Choose the color to be used as background for reviews widget.</p>
          <div className={'gIpt'}>
            <input
              type="text"
              value={colorBackgroundHex}
              onChange={onBackgroundColorTextChange}
            />
            { colorBackgroundError && <p className="gErr">{colorBackgroundError}</p> }
            <ColorPicker onChange={onBackgroundColorChange} value={colorBackground} />
          </div>
        </div>
      </div>
    );
  };

  const renderColorPickerFont = () => {
    return (
      <div className="gBCtRw astntNm">
        <div className="lt">
          <p className="title">Font color</p>
          <p className="desc">Choose the color to be used for font.</p>
          <div className={'gIpt'}>
            <input
              type="text"
              value={colorFontHex}
              onChange={onFontColorTextChange}
            />
            { colorFontError && <p className="gErr">{colorFontError}</p> }
            <ColorPicker onChange={onFontColorChange} value={colorFont} />
          </div>
        </div>
      </div>
    );
  };


  const renderDateFormat = () => {
    return (
      <>
        <div className={'gBCtRw dsplyPref'}>
          <div className="lt">
            <p className="title">Date Format</p>
            <p className="desc">Set the date format for review date shown in the reviews widget.</p>
            <CheckBoxRow
              value={dtFmt}
              onChange={onDtFmt1Change}
              dtVal="MM/DD/YYYY"
              label="MM/DD/YYYY (e.g. 01/30/2023)"
              name="displayLauncherWithMessage"
              toolTip="Pop open the welcome message as a prompt"
            />
            <CheckBoxRow
              value={dtFmt}
              onChange={onDtFmt2Change}
              dtVal="DD-MM-YYYY"
              label="DD-MM-YYYY (e.g. 30-01-2023)"
              name="displayLauncherWithMessage"
              toolTip="Pop open the welcome message as a prompt"
            />
          </div>
        </div>
      </>
    );
  };




  if (process.env.NODE_ENV !== 'production') {
    console.log('RWGeneral: ', props, ' # ', props);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('dtFmt: ', dtFmt);
  }

  const { reviews_widget } = props.settings

  return (
    <div className="gPScrlWrp hDGen flHt rWBasic wCGen">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          General Customizations{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderNumberOfReviews() }
          { renderDateFormat() }
          { renderColorPickerBackground() }
          { renderColorPickerFont() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  reviews_widget && reviews_widget.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default RWGeneral;
