import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import { LABEL_COLORS, LABEL_MAX_LENGTH } from '../../../../../helpers';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import '../style.css';

function ColorDot(props) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(props.isSelected);
  }, [props.isSelected]);
  const onColorDotClick = (e) => {
    setSelected(selected => !selected);
    props.onClick(props.color);
  };
  let cStyle = {};
  cStyle['borderColor'] = selected ? props.color : '#FFFFFF';
  let innerStyle = {};
  innerStyle['backgroundColor'] = props.color;
  return (
    <div className={'colorDot ' + (selected ? 'selected' : '')} style={cStyle} onClick={onColorDotClick}>
      <div className="inner" style={innerStyle} />
    </div>
  );
}

function LabelSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [label, setLabel] = useState('');
  const [color, setColor] = useState('');

  const [labelError, setLabelError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const labelRef = useRef(null);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Settings - Engage - Labels - Single - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (props.label && props.label._id) {
      setLabel(props.label.label);
      setColor(props.label.color);
    } else {
      setColor(LABEL_COLORS[0]);
    }
    setSomeChange(false);
    dispatch(editDataClearAction({
      op: 'edit_engage_guest_label',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        label_id: props.label._id,
      },
    }));
    setTimeout(() => {
      if (labelRef && labelRef.current) {
        labelRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.label && props.label._id &&
        props.settings.engage.labels && props.settings.engage.labels.keyValue &&
        props.settings.engage.labels.keyValue[props.label._id] &&
        !props.settings.engage.labels.keyValue[props.label._id].editInProgress &&
        props.settings.engage.labels.keyValue[props.label._id].editStatus &&
        props.settings.engage.labels.keyValue[props.label._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.settings.engage.labels &&
        !props.settings.engage.labels.addInProgress &&
        props.settings.engage.labels.addStatus &&
        props.settings.engage.labels.addStatus.success
      ) {
        setLabel('');
        setSomeChange(false);
      }
    }
  }, [props.settings.engage.labels]);

  const onLabelChange = (e) => {
    const { name, value } = e.target;
    dispatch(editDataClearAction({
      op: 'edit_engage_guest_label',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        label_id: props.label._id,
      },
    }));
    if (!value.length) { setLabel(''); setLabelError('error'); return; }
    let tValue = value;
    if (tValue.length > LABEL_MAX_LENGTH) tValue = tValue.slice(0, LABEL_MAX_LENGTH);
    setLabel(tValue);
    setLabelError('');
    setSomeChange(true);
  };
  const onLabelColorClick = (color) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLabelColorClick: ', color);
    }
    dispatch(editDataClearAction({
      op: 'edit_engage_guest_label',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        label_id: props.label._id,
      },
    }));
    setColor(color);
    setSomeChange(true);
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    let addEditInProgress = props.settings.engage.labels.addInProgress
    if (props.type === 'edit') {
      if (props.label && props.label._id &&
        props.settings.engage.labels && props.settings.engage.labels.keyValue &&
        props.settings.engage.labels.keyValue[props.label._id]
      ) {
        addEditInProgress = props.settings.engage.labels.keyValue[props.label._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    dispatch(editDataClearAction({
      op: 'edit_engage_guest_label',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        label_id: props.label._id,
      },
    }));
    setLabelError(label ? '' : 'error');
    if (someChange) {
      if (props.type === 'add') {
        if (label && color) {
          dispatch(editDataAction({
            op: 'edit_engage_guest_label',
            params: {
              location__id: urlParams.locationidentifier,
              mode: props.type,
              label,
              color,
            },
          }));
          // props.onAddEditConfirmClick({
          //   title,
          //   msg: message,
          // });
        }
      }
      if (props.type === 'edit') {
        if (label && color) {
          dispatch(editDataAction({
            op: 'edit_engage_guest_label',
            params: {
              location__id: urlParams.locationidentifier,
              mode: props.type,
              label_id: props.label._id,
              label,
              color,
            },
          }));
          // props.onAddEditConfirmClick({
          //   _id: props.label._id,
          //   title,
          //   msg: message,
          // });
        }
      }
    }
  };

  const renderContent = () => {
    const { locationidentifier } = urlParams;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.engage ||
      !settings.engage.labels || !settings.engage.labels.keyValue
    ) return null;
    const tData = settings.engage.labels.keyValue[props.label._id] || {};

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Label`;
    if (props.type === 'edit') pageTitle = 'Edit Label';
    if (props.title) pageTitle = props.title;



    let addEditInProgress = settings.engage.labels.addInProgress
    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    let addEditStatus = settings.engage.labels.addStatus;
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    return (
      <div className="gPWrp lblSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Label Name<span className="addOn">(Character Limit - 20)</span>
              </div>
              <div className={'gIpt ' + (labelError ? 'error' : '')}>
                <input type="text" value={label} onChange={onLabelChange} placeholder="Title" ref={labelRef} />
                { labelError && <p className="gErr">Label name is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw clrRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Label Color
              </div>
              <div className="labelColors">
                {
                  LABEL_COLORS.map((item) => {
                    return (
                      <ColorDot key={`cd_${item}`} color={item} isSelected={color === item} onClick={onLabelColorClick} />
                    );
                  })
                }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('LabelSingle props: ', props);
  }

  return renderContent();
}

export default LabelSingle;
