// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import IFrame from '../../../../Misc/IFrame';
// import './style.css';

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function ConnectReviewsWidgetModal(props) {
  const onCloseClick = (e) => {
    props.onCloseClick();
  };
  const onCTAClick = (e) => {
    props.onCtaClick();
  };
  return (
    <Modal>
        <div id="cModelId" className="sdMdl knowMoreModal">
          <div className="ctnt">
            <IFrame
              data={{
                src: 'https://guesttouch.com/internal-connect-pms/',
              }}
              onCancelClick={onCloseClick}
              onCtaClick={onCTAClick}
            />
          </div>
        </div>
      </Modal>
  );
}

export default ConnectReviewsWidgetModal;
