import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import GenericSideModal from '../../../../Misc/GenericSideModal';
import GenericAlertModal from '../../../../Misc/GenericAlertModal';
import WCQueryRow from './WCQueryRow';
import WebChatTopicsModal from './WebChatTopicsModal';
import WebChatManageTopicsModal from './WebChatManageTopicsModal';

import { getDataAction } from '../../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, STRS } from '../../../../../helpers';

import reorderIcon from '../../../../../img/icons/reorder.svg';

import '../style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SingleTopic(props) {
  const onTopicClick = (e) => { props.onTopicClick(props.data.topic_id); };
  const { _id, topic_id, title, icon, icon_selected, questions_count, is_hidden } = props.data;
  return (
    <div id={`hg_t_${_id}`} className={'entry ' + (props.selected ? 'selected ' : '') + (is_hidden ? 'hidden' : '')} onClick={onTopicClick}>
      <div className="left">
        <img src={icon} /> {title}
      </div>
      <div className="right">
        ({questions_count})
      </div>
    </div>
  );
}

function WCHelpGuide(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedTopicName, setSelectedTopicName] = useState('');
  const [selectedTopicCount, setSelectedTopicCount] = useState(0);
  const [selectedTopicIsHidden, setSelectedTopicIsHidden] = useState(false);
  const [selectedTopicToggleInProgress, setSelectedTopicToggleInProgress] = useState(false);

  const [showQueryModal, setShowQueryModal] = useState(false);
  const [queryModalType, setQueryModalType] = useState('');
  const [selectedQuery, setSelectedQuery] = useState({});

  const [showQueryDeleteModal, setShowQueryDeleteModal] = useState(false);

  const [showTopicsModal, setShowTopicsModal] = useState(false);
  const [showManageTopicsModal, setShowManageTopicsModal] = useState(false);

  const [toggleOn, setToggleOn] = useState(false)

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - WebChat - Help Guide - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    dispatch(getDataAction({
      op: 'get_webchat_helpguide',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('selectedTopic change: ', selectedTopic);
    }
    const { settings: { webchat } } = props;
    if (selectedTopic && webchat &&
      webchat.help_guide && webchat.help_guide.topics
    ) {
      const topicFound = webchat.help_guide.topics.find((item) => item.topic_id === selectedTopic);
      if (process.env.NODE_ENV !== 'production') {
        console.log('selectedTopic change topicFound: ', topicFound);
      }
      if (topicFound) {
        setSelectedTopicCount(topicFound.questions_count || 0);
        setSelectedTopicName(topicFound.title);
        setSelectedTopicIsHidden(topicFound.is_hidden);
        setToggleOn(!topicFound.is_hidden);
      }
    }
  }, [selectedTopic]);

  // hide delete modal
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.settings.webchat change');
    }
    const { settings: { webchat } } = props;
    if (selectedQuery && selectedQuery._id && webchat &&
      webchat.help_guide && webchat.help_guide.questions &&
      webchat.help_guide.questions.keyValue[selectedQuery._id] &&
      webchat.help_guide.questions.keyValue[selectedQuery._id] &&
      webchat.help_guide.questions.keyValue[selectedQuery._id].is_removed !== selectedQuery.is_removed
    ) {
      setShowQueryDeleteModal(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('props.settings.webchat selectedTopic: ', selectedTopic);
      }
      if (selectedTopic && webchat &&
        webchat.help_guide && webchat.help_guide.topics
      ) {
        const topicFound = webchat.help_guide.topics.find((item) => item.topic_id === selectedTopic);
        if (topicFound) {
          setSelectedTopicCount(topicFound.questions_count || 0);
        }
      }
    }
  }, [props.settings.webchat]);

  // update questions count of selected topic
  useEffect(() => {
    const { settings: { webchat } } = props;
    if (process.env.NODE_ENV !== 'production') {
      console.log('change addStatus: ', props.settings.webchat);
    }
    if (webchat &&
      webchat.help_guide && webchat.help_guide.questions &&
      !webchat.help_guide.questions.addInProgress &&
      webchat.help_guide.questions.addStatus &&
      webchat.help_guide.questions.addStatus.success
    ) {
      if (selectedTopic && webchat &&
        webchat.help_guide && webchat.help_guide.topics
      ) {
        const topicFound = webchat.help_guide.topics.find((item) => item.topic_id === selectedTopic);
        if (topicFound) {
          setSelectedTopicCount(topicFound.questions_count || 0);
        }
      }
    }
  }, [props.settings.webchat]);

  // update topic is hidden or not
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('change hidden addStatus: ', props.settings.webchat);
    }
    const { settings: { webchat } } = props;
    if (webchat &&
      webchat.help_guide && webchat.help_guide.topics
    ) {
      const topicFound = webchat.help_guide.topics.find((item) => item.topic_id === selectedTopic);
      if (process.env.NODE_ENV !== 'production') {
        console.log('change hidden addStatus topicFound: ', topicFound);
      }
      if (topicFound) {
        if (!topicFound.editInProgress) {
          if (topicFound.editStatus && topicFound.editStatus.success) {
            setSelectedTopicIsHidden(topicFound.is_hidden);
          }
          if (topicFound.editStatus && topicFound.editStatus.error) {
            setSelectedTopicIsHidden(topicFound.is_hidden);
            setToggleOn(!topicFound.is_hidden)
          }
          setSelectedTopicToggleInProgress(false);
        }
      }
    }
  }, [props.settings.webchat]);


  const onToggleChange = (e) => {
    const { webchat } = props.settings
    if (webchat && webchat.help_guide && webchat.help_guide.topics) {
      const topicFound = webchat.help_guide.topics.find((item) => item.topic_id === selectedTopic);
      if (topicFound && topicFound.editInProgress) return;
    }
    const { name, checked } = e.target;
    setToggleOn(checked);

    if (process.env.NODE_ENV !== 'production') {
      console.log('onToggleChange checked: ', checked, ' # ', selectedTopic);
    }
    if (!selectedTopic) return;
    setSelectedTopicToggleInProgress(true);
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'toggle-topic',
      topic_id: selectedTopic,
      toggleValue: !checked,
    };
    dispatch(editDataAction({
      op: 'edit_webchat_helpguide',
      show_alert: true,
      params,
    }));
  };

  const onTopicsModalCancelClick = (e) => { setShowTopicsModal(false); };

  const onTopicClick = (topicId) => { setSelectedTopic(topicId); };

  const onReOrderClick = (e) => {
    if (props.isReadOnly) return;
    onTopicsModalEditClear();
    setShowTopicsModal(true);
  };

  const onManageTopicsModalCancelClick = (e) => { setShowManageTopicsModal(false); };
  const onManageTopicsClick = (e) => {
    if (props.isReadOnly) return;
    onManageTopicsModalEditClear();
    setShowManageTopicsModal(true);
  };

  const onQueryAddClick = (e) => {
    if (props.isReadOnly) return;
    setSelectedQuery({});
    setQueryModalType('add');
    setShowQueryModal(true);
  };


  const onQueryRowEditClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('selectedQuery: ', cTemplate);
    }
    setSelectedQuery(cTemplate);
    setQueryModalType('edit');
    setShowQueryModal(true);
  };

  const onQueryRowAddClear = (templateData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQueryRowAddClear: ', templateData);
    }
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'add-question',
    };
    dispatch(editDataClearAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onQueryRowEditClear = (templateData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQueryRowEditClear: ', templateData);
    }
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-question',
      question_id: templateData._id,
    };
    dispatch(editDataClearAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onQueryRowDeleteClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQueryRowDeleteClick: ', cTemplate);
    }
    setSelectedQuery(cTemplate);
    setShowQueryModal(false);
    setShowQueryDeleteModal(true);
  };

  const onTopicsModalEditClear = (e) => {
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'reorder-topics',
    };
    dispatch(editDataClearAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onManageTopicsModalEditClear = (e) => {
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'manage-topics',
    };
    dispatch(editDataClearAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onQueryRowAddConfirmClick = (templateData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQueryRowAddConfirmClick: ', templateData);
    }
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'add-question',
      question_data: {
        topic_id: selectedTopic,
        question: templateData.title,
        answer: templateData.msg,
      },
    };
    dispatch(editDataAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onQueryRowEditConfirmClick = (templateData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQueryRowEditConfirmClick: ', templateData);
    }
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-question',
      question_id: templateData._id,
      question_data: {
        question: templateData.title,
        answer: templateData.msg,
      },
    };
    dispatch(editDataAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onQueryDeleteConfirmClick = (e) => {
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-question',
      question_id: selectedQuery._id,
      question_data: {
        is_removed: true,
        topic_id: selectedQuery.topic_id,
      },
    };
    dispatch(editDataAction({
      op: 'edit_webchat_helpguide',
      show_alert: true,
      alert_msg: 'Removed successfully',
      params,
    }));
  };

  const onQueryCancelClick = (value) => {
    setShowQueryModal(value);
  };

  const onTopicsModalConfirmClick = (topicParams) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTopicsModalConfirmClick: ', topicParams);
    }
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'reorder-topics',
      topics: topicParams,
    };
    dispatch(editDataAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onManageTopicsModalConfirmClick = (topicParams) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onManageTopicsModalConfirmClick: ', topicParams);
    }
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'manage-topics',
      topics: topicParams,
    };
    dispatch(editDataAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onRequestCategoryClick = (e) => {
    if (props.isReadOnly) return;
  };

  const renderQueryModal = () => {
    const { webchat } = props.settings;
    let templateData = selectedQuery && selectedQuery._id &&
      webchat && webchat.help_guide && webchat.help_guide.questions &&
      webchat.help_guide.questions.keyValue
      ? webchat.help_guide.questions.keyValue[selectedQuery._id] || {}
      : {};
    return (
      <GenericSideModal
        modalType="webChatQuery"
        className="mtGen"
        data={{
          mode: queryModalType,
          opName: queryModalType,
          template: templateData, // selectedQuery ? { ...selectedQuery } : {},
        }}
        extData={{
          title: queryModalType ? 'Edit Question' : 'Add Question' ,
          topicId: selectedTopic,
          settings: props.settings
        }}
        setModalVisible={onQueryCancelClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  };

  const renderTopicsModal = (webchat) => {
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <WebChatTopicsModal
              onCancelClick={onTopicsModalCancelClick}
              onEditConfirmClick={onTopicsModalConfirmClick}
              editInProgress={webchat && webchat.help_guide ? webchat.help_guide.topicsReorderInProgress : false}
              editStatus={webchat && webchat.help_guide ? webchat && webchat.help_guide.topicsReorderStatus || {} : {}}
              editClear={onTopicsModalEditClear}
              settings={props.settings}
              windowHeight={windowState.window.windowHeight}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderManageTopicsModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <WebChatManageTopicsModal
              onCancelClick={onManageTopicsModalCancelClick}
              onEditConfirmClick={onManageTopicsModalConfirmClick}
              editInProgress={webchat && webchat.help_guide ? webchat.help_guide.topicsManageInProgress : false}
              editStatus={webchat && webchat.help_guide ? webchat && webchat.help_guide.topicsManageStatus || {} : {}}
              editClear={onManageTopicsModalEditClear}
              settings={props.settings}
              windowHeight={windowState.window.windowHeight}
            />
          </div>
        </div>
      </Modal>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('WCHelpGuide: ', props, ' # ', props);
  }

  const { webchat } = props.settings
  let topicFound = null;
  if (webchat && webchat.help_guide && webchat.help_guide.topics) {
    topicFound = webchat.help_guide.topics.find((item) => item.topic_id === selectedTopic);
  }

  return (
    <>
    {
      webchat && webchat.help_guide && webchat.help_guide.getInProgress &&
      <div className="gPnlLdng">
        <i className="fa fa-spinner fa-spin" />
      </div>
    }
    <div className="gPScrlWrp hDGen flHt helpGuide">
      <div className="gPWrp">
        <div className="gPTle title">
          Help Guide{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          <div className="topicsBox">
            <div className="header">
              Topics
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Manage topics'}>
              <span onClick={onManageTopicsClick}>
                <i className="fa fa-solid fa-plus" />/<i className="fa fa-solid fa-minus" />
              </span>
              </ToolTipWrapper>
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Reorder topics'}>
                <span><img src={reorderIcon} onClick={onReOrderClick}/></span>
              </ToolTipWrapper>
            </div>
            <div className="list">
              {
                webchat && webchat.help_guide && webchat.help_guide.topics &&
                webchat.help_guide.topics.map((item) => {
                  if (!item || item.is_hidden) return null;
                  return <SingleTopic data={item} onTopicClick={onTopicClick}
                    selected={selectedTopic === item.topic_id}
                    key={`st_${item.topic_id}`}
                  />
                })
              }
            </div>
            <div className="add">
              <p onClick={onRequestCategoryClick}><span>+ Request Category</span></p>
            </div>
          </div>
          {
            selectedTopic ?
            <div className="queriesBox">
              <div className="header">
                <div className="left">
                  <div className="title">{selectedTopicName} - Questions ({selectedTopicCount})
                  </div>
                  {
                    selectedTopicIsHidden
                    ?
                      <div className="info">Topic is currently disabled. It will not be shown on WebChat interface on your website.
                      </div>
                    :
                      <div className="info">Topic is currently enabled. It will be shown on WebChat interface on your website.
                      </div>
                  }
                </div>
                <div className="right">
                  {
                    topicFound && topicFound.editInProgress &&
                    <span><i className="fa fa-spinner fa-spin" /></span>
                  }
                  {
                    !props.isReadOnly &&
                    <div className="enableDisable">
                      <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={toggleOn ? 'Disable topic' : 'Enable topic'}>
                        <div className="gToggleWrap">
                          <div className="gToggle">
                            <input type="checkbox" id="topicToggle" checked={toggleOn} onChange={onToggleChange}/><label htmlFor={"topicToggle"}>Toggle</label>
                          </div>
                        </div>
                      </ToolTipWrapper>
                    </div>
                  }
                </div>
              </div>
              <div className="list">
                <div className="addQuestion"><span onClick={onQueryAddClick}>+ Add Question</span></div>
                {
                  webchat && webchat.help_guide && webchat.help_guide.questions &&
                  webchat.help_guide.questions.list &&
                  webchat.help_guide.questions.keyValue &&
                  webchat.help_guide.questions.list.map((item, index) => {
                    if (!webchat.help_guide.questions.keyValue[item] ||
                      webchat.help_guide.questions.keyValue[item].topic_id !== selectedTopic ||
                      webchat.help_guide.questions.keyValue[item].is_removed
                    ) return null;
                    return <WCQueryRow template={webchat.help_guide.questions.keyValue[item]} onEditClick={onQueryRowEditClick} onDeleteClick={onQueryRowDeleteClick}
                      isLast={webchat.help_guide.questions.list.length === index+1}
                      isReadOnly={props.isReadOnly}
                    />
                  })
                }
              </div>
            </div>
            : <div className="queriesBox chooseTopic">
              <p>Choose a topic</p>
            </div>
          }
        </div>
      </div>
      { showQueryModal && renderQueryModal() }
      { showTopicsModal && renderTopicsModal(webchat) }
      { showManageTopicsModal && renderManageTopicsModal(webchat) }
      { showQueryDeleteModal &&
        <GenericAlertModal
          modalType="Delete Template"
          bodyRowOne={`Do you want to delete this question (${selectedQuery.question}) ? This action cannot be undone!`}
          bodyRowNote={'You’ll not be able to retrieve the contents later.'}
          keyName={selectedQuery.topic_id}
          setModalVisible={setShowQueryDeleteModal}
          onAffirmativeClick={onQueryDeleteConfirmClick}
          inProgress={webchat && webchat.help_guide && webchat.help_guide.questions && webchat.help_guide.questions.keyValue
            ? webchat.help_guide.questions.keyValue[selectedQuery._id].editInProgress : false}
          showCancel={true}
        />
      }
    </div>
    </>
  );
}

export default WCHelpGuide;
