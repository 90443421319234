// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import queryString from 'query-string';
import copy from 'copy-to-clipboard';
import Highlighter from "react-highlight-words";
import { Interweave } from 'interweave';
import Select from 'react-select';

// import { editReviewAction, editReviewClearAction, postResponseOnGoogleClearAction, postResponseOnGoogleAction,
//   updateReviewTranslationAction, updateReviewTranslationClearAction
// } from '../../../redux/actions/reviewsActions';
import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../../redux/actions/snacksActions';
import { renderOTALogo, formatDate, getReviewState, RS, mapReviewStateToString,
  OTA_SOURCE_TO_NAME_FORMAL_MAP, LANGUAGE_LIST_KEYS, ratingToInt,
  isLocationAdminAndAbove, getUserGAId } from '../../../../../helpers';

import moreIcon from '../../../../../img/icons/more-options.svg';
import shareIcon from '../../../../../img/icons/share.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import whatsappIcon from '../../../../../img/icons/whatsapp.png';
import gmailIcon from '../../../../../img/icons/gmail.svg';
import translateIcon from '../../../../../img/icons/translate.svg';

import ResponseTemplateSelector from '../ResponseTemplateSelector';
import RedirectToOta from '../RedirectToOta';
import RespondedOnOta from '../RespondedOnOta';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../Misc/GenericAlertModal';

import './style.css';

const gtconfig = require('../../../../../gtconfig');

const SKIP_TRANSLATE_LANG_CODE = 'en';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function ResponseTemplateButton(props) {
  const onButtonClick = (e) => {
    props.onClick(props.data);
  };
  return (
    <div className="rtButton" onClick={onButtonClick}>
      {props.data.title}
    </div>
  );
}

function SingleReview(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const windowState = useSelector((state) => state.windowReducer);
  let urlParams = useParams();

  const [review, setReview] = useState(null);
  const [reviewState, setReviewState] = useState(null);
  const [showResponse, setShowResponse] = useState(true);
  const [selfResponseText, setSelfResponseText] = useState('');
  const [selfResponseTextChanged, setSelfResponseTextChanged] = useState(false);
  const [selfResponseTextError, setSelfResponseTextError] = useState('');

  const [showEnTranslation, setShowEnTranslation] = useState(false);

  const [moreInfoText, setMoreInfoText] = useState('');
  const [moreInfoTextChanged, setMoreInfoTextChanged] = useState(false);
  const [moreInfoTextError, setMoreInfoTextError] = useState('');

  const [approveEditText, setApproveEditText] = useState('');
  const [approveEditTextChanged, setApproveEditTextChanged] = useState(false);

  const [showRedirectToOtaModal, setShowRedirectToOtaModal] = useState(false);
  const [otaResponseUrl, setOtaResponseUrl] = useState('');
  const redirectToOtaModalRef = useRef();
  // useOnClickOutside(redirectToOtaModalRef, useCallback(() => onRedirectToOtaModalCloseClick()));

  const [showRespondedOnOtaModal, setShowRespondedOnOtaModal] = useState(false);
  const respondedOnOtaModalRef = useRef();
  // useOnClickOutside(respondedOnOtaModalRef, useCallback(() => onRespondedOnOtaModalCloseClick()));

  const [showShareMenuPanel, setShowShareMenuPanel] = useState(false);
  const shareMenuPanelRef = useRef();
  useOnClickOutside(shareMenuPanelRef, useCallback(() => setShowShareMenuPanel(false)));

  const [showMoreMenuPanel, setShowMoreMenuPanel] = useState(false);
  const moreMenuPanelRef = useRef();
  useOnClickOutside(moreMenuPanelRef, useCallback(() => setShowMoreMenuPanel(false)));

  const [approveEditMode, setApproveEditMode] = useState(false);
  const [showApproveConfirmModal, setShowApproveConfirmModal] = useState(false);

  const [showPostOnGoogleConfirmModal, setShowPostOnGoogleConfirmModal] = useState(false);

  const [hasSelfResponse, setHasSelfResponse] = useState(false);
  const [hasGTResponse, setHasGTResponse] = useState(false);

  const [temporaryReviewState, setTemporaryReviewState] = useState('');

  const [translateFor, setTranslateFor] = useState('');
  // main review

  const [hasTranslate, setHasTranslate] = useState(false);

  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [langaugeSearchTerm, setLangaugeSearchTerm] = useState('');
  const [translateInProgress, setTranslateInProgress] = useState(false);

  const languageSelectorRef = useRef();
  useOnClickOutside(languageSelectorRef, useCallback(() => setLanguageSelectorOpen(false)));

  const [selectedTranslationLang, setSelectedTranslationLang] = useState('');

  const [showSetTranslationLangModal, setShowSetTranslationLangModal] = useState(false);
  const [showSetTranslationLangMRModal, setShowSetTranslationLangMRModal] = useState(false);

  const [showOriginal, setShowOriginal] = useState(true);


  // Management response
  const [languageSelectorOpenMR, setLanguageSelectorOpenMR] = useState(false);
  const [langaugeSearchTermMR, setLangaugeSearchTermMR] = useState('');

  const languageSelectorRefMR = useRef();
  useOnClickOutside(languageSelectorRefMR, useCallback(() => setLanguageSelectorOpenMR(false)));
  const [selectedTranslationLangMR, setSelectedTranslationLangMR] = useState('');
  const [showOriginalMR, setShowOriginalMR] = useState(true);

  // Prepared response
  const [languageSelectorOpenPR, setLanguageSelectorOpenPR] = useState(false);

  const languageSelectorRefPR = useRef();
  useOnClickOutside(languageSelectorRefPR, useCallback(() => setLanguageSelectorOpenPR(false)));
  const [selectedTranslationLangPR, setSelectedTranslationLangPR] = useState('');

  const [showAllTemplatesModal, setShowAllTemplatesModal] = useState(false);

  const [promptOptions, setPromptOptions] = useState([]);
  const [promptSelectValue, setPromptSelectValue] = useState(
    { 'label': 'Select Prompt', 'value': 'select-prompt' }
  );
  const [promptText, setPromptText] = useState('');
  const [promptTextChanged, setPromptTextChanged] = useState(false);

  const [promptError, setPromptError] = useState('');

  const [addFollowUp, setAddFollowUp] = useState(false);
  const [followUpText, setFollowUpText] = useState('');

  const [generateResponseCounter, setGenerateResponseCounter] = useState(0);
  const [isGeneratedResponseText, setIsGeneratedResponseText] = useState(false);
  const [showTakePromptTextChangePermission, setShowTakePromptTextChangePermission] = useState(false);

  const [showGuestInfo, setShowGuestInfo] = useState(false);

  const guestInfoRef = useRef();
  useOnClickOutside(guestInfoRef, useCallback(() => setShowGuestInfo(false)));


  const [showTimeline, setShowTimeline] = useState(false);

  // const { locationidentifier } = props.match.params; // not accessible here

  useEffect(() => {
    if (props.isGroup) {
      let tempOptions = [];
      if (props.locationInfo && props.locationInfo.settings && props.locationInfo.settings.prompt_templates &&
        props.locationInfo.settings.prompt_templates.list &&
        props.locationInfo.settings.prompt_templates.list.length &&
        props.locationInfo.settings.prompt_templates.keyValue
      ) {
        tempOptions.push({ 'label': 'Select Prompt', 'value': 'select-prompt' });
        props.locationInfo.settings.prompt_templates.list.forEach((item) => {
          if (props.locationInfo.settings.prompt_templates.keyValue[item]) {
            tempOptions.push({
              'label': props.locationInfo.settings.prompt_templates.keyValue[item].title,
              'value': item });
          }
          if (props.locationInfo.settings.prompt_templates.keyValue[item].is_default) {
            setPromptSelectValue({
              'label': props.locationInfo.settings.prompt_templates.keyValue[item].title,
              'value': item
            });
            setPromptText(props.locationInfo.settings.prompt_templates.keyValue[item].prompt);
          }
        });
      }
      setPromptOptions(tempOptions);
    } else {
      let tempOptions = [];
      if (props.settings && props.settings.reputation && props.settings.reputation.prompt_templates &&
        props.settings.reputation.prompt_templates.list &&
        props.settings.reputation.prompt_templates.list.length &&
        props.settings.reputation.prompt_templates.keyValue
      ) {
        tempOptions.push({ 'label': 'Select Prompt', 'value': 'select-prompt' });
        props.settings.reputation.prompt_templates.list.forEach((item) => {
          if (props.settings.reputation.prompt_templates.keyValue[item]) {
            tempOptions.push({
              'label': props.settings.reputation.prompt_templates.keyValue[item].title,
              'value': item });
          }
          if (props.settings.reputation.prompt_templates.keyValue[item].is_default) {
            setPromptSelectValue({
              'label': props.settings.reputation.prompt_templates.keyValue[item].title,
              'value': item
            });
            setPromptText(props.settings.reputation.prompt_templates.keyValue[item].prompt);
          }
        });
      }
      // tempOptions.push({ 'label': 'Select Prompt', 'value': 'select-prompt' });
      // tempOptions.push({ 'label': 'Default', 'value': 'default' });
      // tempOptions.push({ 'label': 'Special', 'value': 'special' });
      setPromptOptions(tempOptions);
    }
  }, []);

  useEffect(() => {
    setReview(null);
    const { locationId, reviewId } = props;
    const { has_self_response, has_gt_response, has_reputation_translate } = props.locationInfo;
    setHasSelfResponse(has_self_response);
    setHasGTResponse(has_gt_response);
    setHasTranslate(has_reputation_translate);
    setOtaResponseUrl('');
    // if (reviewId && locationId) {
    //   let reviewInfo = props.locationprops.locations.keyValue && props.locationprops.locations.keyValue[locationId] &&
    //     props.locationprops.locations.keyValue[locationId].reviews &&
    //     props.locationprops.locations.keyValue[locationId].reviews.keyValue
    //     ? props.locationprops.locations.keyValue[locationId].reviews.keyValue[reviewId]
    //     : null;
    //   setReview(reviewInfo);
    //   if (reviewInfo) {
    //     setReviewState(getReviewState(reviewInfo, tempHasSelfResponse));
    //     setSelfResponseText(reviewInfo.self_response || '');
    //   }
    // }
    setLanguageSelectorOpen(false);
    setLanguageSelectorOpenMR(false);
    setLangaugeSearchTerm('');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = props.reviewId;
      dispatch(editDataClearAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = props.reviewId;
      dispatch(editDataClearAction({
        op: 'update_review_translation',
        params
      }));
    }
    // props.dispatch(updateReviewTranslationClearAction({
    //   params,
    // }));
  }, [props.reviewId]);

  useEffect(() => {
    if (props.reviews.keyValue[props.reviewId]) setReview(props.reviews.keyValue[props.reviewId]);
  }, [props.reviews.keyValue[props.reviewId]]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleReview review changed: ', props.review);
    }
    setReview(props.review);
    if (props.review) {
      const { locationId, reviewId } = props;
      const { has_self_response, has_gt_response } = props.locationInfo;
      setReviewState(getReviewState(props.review, has_self_response));
      let tempSelfResponseText = props.review.self_response || '';
      if (props.review.generated_response) {
        tempSelfResponseText = props.review.generated_response;
        setIsGeneratedResponseText(true);
      } else {
        setIsGeneratedResponseText(false);
      }
      setSelfResponseText(tempSelfResponseText);
    }
  }, [props.review]);

  useEffect(() => {
    // let review = props.reviews.keyValue[props.reviewId] ? { ...props.reviews.keyValue[props.reviewId] } : {};
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleReview review changed useEffect: ', review);
    }
    // show redirect to ota model
    if (review && !review.editSaveAndRespondInProgress && review.editSaveAndRespondStatus && review.editSaveAndRespondStatus.success) {
      if (props.isGroup) {
        const params = {};
        params.group_id = props.groupId;
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'edit_group_review',
          params
        }));
      } else {
        const params = {};
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'edit_review',
          params
        }));
      }
      setShowRedirectToOtaModal(true);
    }
    // hide redirect to ota model and show responded on ota model
    if (review && !review.editRedirectToOtaInProgress && review.editRedirectToOtaStatus && review.editRedirectToOtaStatus.success) {
      if (props.isGroup) {
        const params = {};
        params.group_id = props.groupId;
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'edit_group_review',
          params
        }));
      } else {
        const params = {};
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'edit_review',
          params
        }));
      }
      setShowRedirectToOtaModal(false);
      setShowRespondedOnOtaModal(true);
    }
    // close responded on ota model
    if (review && !review.editRespondedOnOtaInProgress && review.editRespondedOnOtaStatus && review.editRespondedOnOtaStatus.success) {
      if (props.isGroup) {
        const params = {};
        params.group_id = props.groupId;
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'edit_group_review',
          params
        }));
      } else {
        const params = {};
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'edit_review',
          params
        }));
      }
      setShowRespondedOnOtaModal(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('spoof the review state');
      }
      setTemporaryReviewState(RS.RS_RESPONSE_POSTED);
    }
    // close post response on google
    if (review && !review.postResponseOnGoogleInProgress && review.postResponseOnGoogleStatus && review.postResponseOnGoogleStatus.success) {
      if (props.isGroup) {
        const params = {};
        params.group_id = props.groupId;
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'post_group_response_on_google',
          params
        }));
      } else {
        const params = {};
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'post_response_on_google',
          params
        }));
      }
      setShowPostOnGoogleConfirmModal(false);
    }
    // if translate is complete
    if (review && !review.translateInProgress && review.translateStatus && review.translateStatus.success) {
      if (selectedTranslationLang) {
        if (translateFor === 'review')  setShowOriginal(false);
        // if (translateFor === 'management_response')  setShowOriginalMR(false);
      }
      if (selectedTranslationLangMR) {
        // if (translateFor === 'review')  setShowOriginal(false);
        if (translateFor === 'management_response')  setShowOriginalMR(false);
      }
      if (selectedTranslationLangPR) {
        // if (translateFor === 'review')  setShowOriginal(false);
        if (translateFor === 'prepared_response') setSelfResponseText(review.self_response_translated);
      }
    }
    // if get response is complete
    if (review && !review.getResponseInProgress && review.getResponseStatus && review.getResponseStatus.success) {
      if (props.isGroup) {
        const params = {};
        params.group_id = props.groupId;
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'get_group_response',
          params
        }));
      } else {
        const params = {};
        params.location__id = props.locationId;
        params.review__id = review._id;
        dispatch(editDataClearAction({
          op: 'get_response',
          params
        }));
      }
      if (review.generated_response) {
        setSelfResponseText(review.generated_response);
      }
      setGenerateResponseCounter(generateResponseCounter+1);
    }
  }, [review]); // review
  // props.reviews.keyValue[props.reviewId]



  // useEffect(() => {
  //   if (props.data) {
  //     setReview(null);
  //     if (process.env.NODE_ENV !== 'production') {
  //       console.log('SingleReview props.data: ', props.data);
  //     }
  //     let reviewInfo = props.data;
  //     setReview(reviewInfo);
  //     if (reviewInfo) setReviewState(getReviewState(reviewInfo, props.hasSelfResponse));
  //   }
  // }, [props.data]);

  const getOtaResponseUrl = () => {
    let otaUrl = '';
    const { locationInfo } = props;
    if (!locationInfo) return;
    switch (review.source) {
      case 2:
        otaUrl = 'https://www.tripadvisor.com/RegistrationPage';
        if (locationInfo.owner_response_url_tripadvisor) otaUrl = locationInfo.owner_response_url_tripadvisor;
        break;
      case 3:
        otaUrl = 'https://admin.booking.com';
        if (locationInfo.owner_response_url_booking) otaUrl = locationInfo.owner_response_url_booking;
        break;
      case 4:
        otaUrl = 'https://www.expediapartnercentral.com';
        if (locationInfo.owner_response_url_expedia) otaUrl = locationInfo.owner_response_url_expedia;
        break;
      case 6:
        // over hotelscom url with expedia
        // url = 'https://hotels.com/';
        // if (selectedlocation.response_url.hotelscom) url = selectedlocation.response_url.hotelscom;
        otaUrl = 'https://www.expediapartnercentral.com';
        if (locationInfo.owner_response_url_expedia) otaUrl = locationInfo.owner_response_url_expedia;
        break;
      case 11:
        otaUrl = 'https://inbox.hostelworld.com/reviews/hw';
        if (locationInfo.owner_response_url_hostelworld) otaUrl = locationInfo.owner_response_url_hostelworld;
        break;
      case 14:
        otaUrl = '#';
        if (locationInfo.owner_response_url_agoda) otaUrl = locationInfo.owner_response_url_agoda;
        break;
      case 15:
        otaUrl = 'https://www.airbnb.com/users/reviews';
        if (locationInfo.owner_response_url_airbnb) otaUrl = locationInfo.owner_response_url_airbnb;
        break;
      case 16:
        otaUrl = 'https://admin.vrbo.com/haod';
        if (locationInfo.owner_response_url_vrbo) otaUrl = locationInfo.owner_response_url_vrbo;
        break;
      case 17:
        otaUrl = '#';
        if (locationInfo.owner_response_url_holidaycheck) otaUrl = locationInfo.owner_response_url_holidaycheck;
        break;
      case 19:
        otaUrl = 'https://guestcenter.opentable.com/login';
        if (locationInfo.owner_response_url_opentable) otaUrl = locationInfo.owner_response_url_opentable;
        break;
      default:
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('getOtaResponseUrl otaUrl: ', otaUrl);
    }
    setOtaResponseUrl(otaUrl);
  };

  const onRedirectToOtaModalCloseClick = () => {
    setShowRedirectToOtaModal(false);
  };

  const onRespondedOnOtaModalCloseClick = () => {
    setShowRespondedOnOtaModal(false);
  };

  const onHideResponse = (e) => {
    setShowResponse(showResponse => !showResponse);
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Show/Hide Response`,
        'event_label': `Show/Hide Response - ${review._id}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onShowEnTranslation = (e) => { setShowEnTranslation(showEnTranslation => !showEnTranslation); };

  const onSelfResponseTextChange = (e) => {
    const { name, value } = e.target;
    setSelfResponseText(value);
    setSelfResponseTextChanged(true);
    setIsGeneratedResponseText(false);
  };

  const onMoreInfoTextChange = (e) => {
    const { name, value } = e.target;
    setMoreInfoText(value);
    setMoreInfoTextChanged(true);
  };

  const onCloseClick = (e) => { props.onCancelClick(); };

  const onSaveClick = (e) => {
    if (!props.locationId) return;
    if (review.editSaveInProgress) return;
    if (review.translateInProgress) return;

    if (!selfResponseText) {
      setSelfResponseTextError('error');
      return;
    }
    setSelfResponseTextError('');

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (selfResponseTextChanged) {
        params.self_response = selfResponseText;
      }
      params.edit_type = 'save';
      dispatch(editDataAction({
        op: 'edit_group_review',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (selfResponseTextChanged) {
        params.self_response = selfResponseText;
      }
      params.edit_type = 'save';
      dispatch(editDataAction({
        op: 'edit_review',
        params
      }));
    }
  };

  const onSubmitToGtClick = (e) => {
    if (!props.locationId) return;
    if (review.editSaveInProgress) return;
    if (review.translateInProgress) return;

    if (!selfResponseText) {
      setSelfResponseTextError('error');
      return;
    }
    setSelfResponseTextError('');

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (selfResponseTextChanged) {
        params.self_response_submit_to_gt = selfResponseText;
      }
      params.edit_type = 'save-and-submit-to-gt';
      dispatch(editDataAction({
        op: 'edit_group_review',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (selfResponseTextChanged) {
        params.self_response_submit_to_gt = selfResponseText;
      }
      params.edit_type = 'save-and-submit-to-gt';
      dispatch(editDataAction({
        op: 'edit_review',
        params
      }));
    }
  };

  const onRespondOnOtaClick = (e) => {
    if (!props.locationId) return;
    if (review.translateInProgress) return;

    if (review.source === 5) { // google
      if (!selfResponseText) {
        setSelfResponseTextError('error');
        return;
      }
      setSelfResponseTextError('');
      setShowPostOnGoogleConfirmModal(true);
    } else {
      if (review.editSaveAndRespondInProgress) return;

      if (!selfResponseText) {
        setSelfResponseTextError('error');
        return;
      }
      setSelfResponseTextError('');

      copy(selfResponseText);

      getOtaResponseUrl();

      if (props.isGroup) {
        const params = {};
        params.group_id = props.groupId;
        params.location__id = props.locationId;
        params.review__id = review._id;
        params.self_response_and_respond = selfResponseText;
        params.edit_type = 'save-and-respond';
        dispatch(editDataAction({
          op: 'edit_group_review',
          params
        }));
      } else {
        const params = {};
        params.location__id = props.locationId;
        params.review__id = review._id;
        params.self_response_and_respond = selfResponseText;
        params.edit_type = 'save-and-respond';
        dispatch(editDataAction({
          op: 'edit_review',
          params
        }));
      }
    }
    setShowRedirectToOtaModal(true);
  };

  const onRedirectToOtaConfirmClick = (e) => {
    setTimeout(() => {
      setShowRedirectToOtaModal(false);
      setShowRespondedOnOtaModal(true);
    }, 50);
    if (!props.locationId) return;
    if (review.editRedirectToOtaInProgress) return;
    if (review.translateInProgress) return;

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.self_response_redirect_to_ota = selfResponseText;
      params.edit_type = 'redirected-to-ota';
      dispatch(editDataAction({
        op: 'edit_group_review',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.self_response_redirect_to_ota = selfResponseText;
      params.edit_type = 'redirected-to-ota';
      dispatch(editDataAction({
        op: 'edit_review',
        params
      }));
    }
  };

  const onRespondedOnOtaConfirmClick = (e) => {
    if (!props.locationId) return;
    if (review.editRespondedOnOtaInProgress) return;
    if (review.translateInProgress) return;

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.self_response_responded_on_ota = selfResponseText;
      params.edit_type = 'responded-on-ota';
      dispatch(editDataAction({
        op: 'edit_group_review',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.self_response_responded_on_ota = selfResponseText;
      params.edit_type = 'responded-on-ota';
      dispatch(editDataAction({
        op: 'edit_review',
        params
      }));
    }
  };

  const onShareMenuClick = (e) => {
    setShowShareMenuPanel(showShareMenuPanel => !showShareMenuPanel);
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Share`,
        'event_label': `Share - ${review._id}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onShareOnWhatsappClick = (e) => {
    let message = `*${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}*`;
    message = `${message}\n----------------------------`;
    message = `${message}\n${formatDate(review.review_date)}\n`;
    if (review.title) message = `${message}\n${review.title}`;
    if (review.comment) message = `${message}\n${review.comment}`;
    if (review.comment_negative) message = `${message}\n${review.comment_negative}`;
    message = `${message}\n----------------------------`;
    if (gtconfig.IS_GT) {
      message = `${message}\nPowered by ${gtconfig.APP_NAME_CONTACT}`;
    }
    let urlencodedtext = encodeURIComponent(message);
    let url = `https://wa.me/?text=${urlencodedtext}`;
    window.open(url, '_blank');
    setShowShareMenuPanel(false);
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Share Whatsapp`,
        'event_label': `Share Whatsapp - ${review._id}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onShareOnEmailClick = (e) => {
    let subject = `${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}`;
    let message = `${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}`;
    message = `${message}%0D%0A----------------------------`;
    message = `${message}%0D%0A${formatDate(review.review_date)}%0D%0A`;
    if (review.title) message = `${message}%0D%0A${review.title}`;
    if (review.comment) message = `${message}%0D%0A${review.comment}`;
    if (review.comment_negative) message = `${message}%0D%0A${review.comment_negative}`;
    message = `${message}%0D%0A----------------------------`;
    if (gtconfig.IS_GT) {
      message = `${message}%0D%0APowered by ${gtconfig.APP_NAME_CONTACT} (https://guesttouch.com)`;
    }
    // let urlencodedtext = encodeURIComponent(message);
    // let url = `mailto:?subject=${subject}&body=${message}`;
    let url = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${message}`;
    window.open(url, '_blank');
    setShowShareMenuPanel(false);
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Share Email`,
        'event_label': `Share Email - ${review._id}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onMoreMenuClick = (e) => {
    setShowMoreMenuPanel(showMoreMenuPanel => !showMoreMenuPanel);
  };

  const onMoreMenuMarkAsRespondedClick = (e) => {
    setShowRespondedOnOtaModal(true);
  };

  const onCopyClick = (e) => {
    let message = `${OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]} review - Rating ${review.rating}`;
    message = `${message}\n`;
    message = `${message}\n${formatDate(review.review_date)}\n`;
    if (review.title) message = `${message}\n${review.title}`;
    if (review.comment) message = `${message}\n${review.comment}`;
    if (review.comment_negative) message = `${message}\n${review.comment_negative}`;
    copy(message);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    setShowMoreMenuPanel(false);
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Copy`,
        'event_label': `Copy - ${review._id}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onShowTimelineClick = (e) => {
    setShowTimeline(showTimeline => !showTimeline);
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Timeline`,
        'event_label': `Share Timeline`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onResponseTemplateButtonClick = (responseTemplate) => {
    setSelfResponseText(responseTemplate.response);
  };

  const onApproveButtonClick = (e) => {
    if (!props.locationId) return;
    if (review.editApproveInProgress) return;
    if (review.translateInProgress) return;

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (approveEditTextChanged) {
        params.approved_edited_response = true;
        params.edited_response = approveEditText;
      }
      else { params.approved = true; }
      params.edit_type = 'approve';
      dispatch(editDataAction({
        op: 'edit_group_review',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (approveEditTextChanged) {
        params.approved_edited_response = true;
        params.edited_response = approveEditText;
      }
      else { params.approved = true; }
      params.edit_type = 'approve';
      dispatch(editDataAction({
        op: 'edit_review',
        params
      }));
    }
  };
  const onApproveEditButtonClick = (e) => {
    setApproveEditText(review.suggested_response);
    setApproveEditMode(true);
  };
  const onApproveEditTextChange = (e) => {
    const { name, value } = e.target;
    setApproveEditText(value);
    setApproveEditTextChanged(true);
  };

  const onApproveConfirmCloseModal = (value) => {
    setShowApproveConfirmModal(false);
  };
  const onApproveConfirmClick = (e) => {
    setShowApproveConfirmModal(false);
  };

  const onPostOnGoogleConfirmCloseModal = (value) => {
    setShowPostOnGoogleConfirmModal(false);
  };
  const onPostOnGoogleConfirmClick = (e) => {
    if (!selfResponseText) {
      setSelfResponseTextError('error');
      return;
    }
    setSelfResponseTextError('');
    if (review.translateInProgress) return;

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.self_response = selfResponseText;
      params.edit_type = 'pending-response';
      dispatch(editDataAction({
        op: 'post_group_response_on_google',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.self_response = selfResponseText;
      params.edit_type = 'pending-response';
      dispatch(editDataAction({
        op: 'post_response_on_google',
        params
      }));
    }
  }

  const onSendMoreInfoButtonClick = (e) => {
    if (!props.locationId) return;
    if (review.editMoreInfoInProgress) return;
    if (review.translateInProgress) return;

    if (!moreInfoText) {
      setMoreInfoTextError('error');
      return;
    }
    setMoreInfoTextError('');

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (moreInfoTextChanged) {
        params.more_info_response = moreInfoText;
      }
      params.edit_type = 'more-info';
      dispatch(editDataAction({
        op: 'edit_group_review',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      if (moreInfoTextChanged) {
        params.more_info_response = moreInfoText;
      }
      params.edit_type = 'more-info';
      dispatch(editDataAction({
        op: 'edit_review',
        params
      }));
    }
  };

  const onSetTranslationLangCloseModal = (value) => {
    setShowSetTranslationLangModal(false);
  };
  const onSetTranslationLangCancelClick = (e) => {
    setShowSetTranslationLangModal(false);
    setLanguageSelectorOpen(false);
    setLanguageSelectorOpenMR(false);
    setTranslateFor('review');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };
  const onSetTranslationLangClick = (e) => {
    setShowSetTranslationLangModal(false);
    setLanguageSelectorOpen(false);
    setLanguageSelectorOpenMR(false);
    setTranslateFor('review');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLang; // 'en';
      params.text = selfResponseText;
      params.translation_lang = selectedTranslationLang;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLang; // 'en';
      params.text = selfResponseText;
      params.translation_lang = selectedTranslationLang;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };

  const onSetTranslationLangMRCloseModal = (value) => {
    setShowSetTranslationLangMRModal(false);
  };
  const onSetTranslationLangMRCancelClick = (e) => {
    setShowSetTranslationLangMRModal(false);
    setLanguageSelectorOpen(false);
    setLanguageSelectorOpenMR(false);
    setTranslateFor('management_response');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLangMR; // 'en';
      params.text = review.manager_response; // lfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLangMR; // 'en';
      params.text = review.manager_response; // lfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };
  const onSetTranslationLangMRClick = (e) => {
    setShowSetTranslationLangMRModal(false);
    setLanguageSelectorOpen(false);
    setLanguageSelectorOpenMR(false);
    setTranslateFor('management_response');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLangMR; // 'en';
      params.text = review.manager_response; // lfResponseText;
      params.translation_lang = selectedTranslationLang;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = selectedTranslationLangMR; // 'en';
      params.text = review.manager_response; // lfResponseText;
      params.translation_lang = selectedTranslationLang;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };

  const onTranslateClick = (e) => {
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.srcLang = 'es';
      params.destLang = 'en';
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.srcLang = 'es';
      params.destLang = 'en';
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };

  /* main review */

  const onTranslateLanguageSelectorOpenClick = (e) => {
    if (review.translateInProgress) return;
    setLanguageSelectorOpen(languageSelectorOpen => !languageSelectorOpen);
  };

  const onLanguageSelectorInputChange = (e) => {
    const { name, value } = e.target;
    setLangaugeSearchTerm(value);
  };

  const onLanguageSelect = (lang) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect: ', lang);
    }
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect translationLang: ', translationLang);
    }
    setSelectedTranslationLang(lang);
    if (!translationLang) {
      setSelectedTranslationLang(lang);
      setShowSetTranslationLangModal(true);
      return;
    }
    setLanguageSelectorOpen(false);
    setTranslateFor('review');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = lang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = lang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }

    // if (messageText) {
    //   translateText(messageText, lang);
    //   // gtag('event', 'gt_click', {
    //   //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   //   'event_label': `Selected Language - ${lang}`,
    //   //   // 'value': 1,
    //   //   user_id: props.userprops.user.uid,
    //   // });
    // }
  };

  const onTranslateToTranslationLangClick = (e) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
      props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (!translationLang) return;
    setSelectedTranslationLang(translationLang);
    setTranslateFor('review');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = translationLang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'review'; // 'review', 'text', 'management_response'
      params.destLang = translationLang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };

  const onShowOriginalClick = (e) => {
    setShowOriginal(true);
  };

  /* end - main review */

  /* management response */

  const onTranslateLanguageSelectorOpenClickMR = (e) => {
    if (review.translateInProgress) return;
    setLanguageSelectorOpenMR(languageSelectorOpenMR => !languageSelectorOpenMR);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Open - Language Selector`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onLanguageSelectMR = (lang) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelectMR: ', lang);
    }
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect translationLang: ', translationLang);
    }
    setSelectedTranslationLangMR(lang);
    if (!translationLang) {
      setSelectedTranslationLangMR(lang);
      setShowSetTranslationLangMRModal(true);
      return;
    }
    setLanguageSelectorOpenMR(false);
    setTranslateFor('management_response');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = lang; // 'en';
      params.text = review.manager_response; //selfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = lang; // 'en';
      params.text = review.manager_response; //selfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }

    // props.dispatch(updateReviewTranslationAction({
    //   params,
    // }));
    // if (messageText) {
    //   translateText(messageText, lang);
    //   // gtag('event', 'gt_click', {
    //   //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   //   'event_label': `Selected Language - ${lang}`,
    //   //   // 'value': 1,
    //   //   user_id: props.userprops.user.uid,
    //   // });
    // }
  };

  const onTranslateToTranslationLangClickMR = (e) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
      props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (!translationLang) return;
    setSelectedTranslationLangMR(translationLang);
    setTranslateFor('management_response');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = translationLang; // 'en';
      params.text = review.manager_response; // selfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'management_response'; // 'review', 'text', 'management_response'
      params.destLang = translationLang; // 'en';
      params.text = review.manager_response; // selfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }
  };

  const onShowOriginalClickMR = (e) => {
    setShowOriginalMR(true);
  };

  /* end - management response */


  /* prepared response */

  const onTranslateLanguageSelectorOpenClickPR = (e) => {
    if (review.translateInProgress) return;
    if (!selfResponseText) return;
    setLanguageSelectorOpenPR(languageSelectorOpenPR => !languageSelectorOpenPR);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Open - Language Selector`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onLanguageSelectPR = (lang) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelectPR: ', lang);
    }
    if (!lang) return;
    setSelectedTranslationLangPR(lang);
    setLanguageSelectorOpenPR(false);
    setTranslateFor('prepared_response');
    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'text'; // 'review', 'text', 'management_response'
      params.destLang = lang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_group_review_translation',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.type = 'text'; // 'review', 'text', 'management_response'
      params.destLang = lang; // 'en';
      params.text = selfResponseText;
      dispatch(editDataAction({
        op: 'update_review_translation',
        params
      }));
    }

    // props.dispatch(updateReviewTranslationAction({
    //   params,
    // }));

    // if (messageText) {
    //   translateText(messageText, lang);
    //   // gtag('event', 'gt_click', {
    //   //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   //   'event_label': `Selected Language - ${lang}`,
    //   //   // 'value': 1,
    //   //   user_id: props.userprops.user.uid,
    //   // });
    // }
  };

  const onOpenAllTemplatesClick = (e) => {
    setShowAllTemplatesModal(true);
  };
  const onCloseAllTemplatesModalClick = (e) => {
    setShowAllTemplatesModal(false);
  };

  /* end - prepared response */


  const onGenerateResponseClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onGenerateResponseClick: ', review);
      console.log('onGenerateResponseClick generateResponseCounter: ', generateResponseCounter);
    }
    if (
      (!promptSelectValue.value || promptSelectValue.value === 'select-prompt') &&
      !promptText
    ) {
      setPromptError('error');
      return;
    }
    setPromptError('');
    if (review.getResponseInProgress) return;

    if (props.isGroup) {
      const params = {};
      params.group_id = props.groupId;
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.prompt_id = promptSelectValue.value;
      if (promptTextChanged) params.prompt_text = promptText;
      if (followUpText) params.follow_up_text = followUpText;
      dispatch(editDataAction({
        op: 'get_group_response',
        params
      }));
    } else {
      const params = {};
      params.location__id = props.locationId;
      params.review__id = review._id;
      params.prompt_id = promptSelectValue.value;
      if (props.locationInfo.has_reputation_translate) params.translate = true;
      if (promptTextChanged) params.prompt_text = promptText;
      if (followUpText) params.follow_up_text = followUpText;
      dispatch(editDataAction({
        op: 'get_response',
        params
      }));
    }
  };

  const onPromptOptionChange = (value) => {
    setPromptSelectValue(value);
    if (value.value === 'select-prompt') {
      setPromptText('');
    } else {
      setGenerateResponseCounter(0);
      if (props.isGroup) {
        if (props.locationInfo && props.locationInfo.settings && props.locationInfo.settings.prompt_templates &&
          props.locationInfo.settings.prompt_templates.keyValue &&
          props.locationInfo.settings.prompt_templates.keyValue[value.value]
        ) {
          setPromptText(props.locationInfo.settings.prompt_templates.keyValue[value.value].prompt);
          setPromptError('');
        }
      } else {
        if (props.settings && props.settings.reputation && props.settings.reputation.prompt_templates &&
          props.settings.reputation.prompt_templates.keyValue &&
          props.settings.reputation.prompt_templates.keyValue[value.value]
        ) {
          setPromptText(props.settings.reputation.prompt_templates.keyValue[value.value].prompt);
          setPromptError('');
        }
      }
    }
    setFollowUpText('');
    // if (value.value === 'default') {
    //   setPromptText('Respond to this guest\'s review for 86 Cannon Historic Inn, and keep following points in mind when responding 1. Avoid the use of formal words like, Dear, disappointed, thrilled, regards.');
    // }
    // if (value.value === 'special') {
    //   setPromptText('Respond to this guest\'s review for 86 Cannon Historic Inn, and keep following points in mind when responding 2. When applicable, offer how we\'d like to make it right for them. 3. understand the underlying root cause and share how we are using their feedback to resolve that problem in the future.');
    // }
    // analytics event reporting
    try {
      const locationName = props.locationInfo.name || '';
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Review - Prompt Change`,
        'event_label': `Prompt Change - ${review._id} - ${value.value}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(props.userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onPromptTextChange = (e) => {
    if (generateResponseCounter > 1 && addFollowUp && followUpText) {
      setShowTakePromptTextChangePermission(true);
      return;
    }
    const { name, value } = e.target;
    setPromptText(value);
    setPromptTextChanged(true);
    if (value) setPromptError('');
    if (addFollowUp) {
      setAddFollowUp(false);
      setGenerateResponseCounter(0);
    }
    setFollowUpText('');
  };
  const onShowTakePromptTextChangePermissionCloseModal = (e) => {
    setShowTakePromptTextChangePermission(false);
  };
  const onTakePromptTextChangePermissionClick = (e) => {
    setShowTakePromptTextChangePermission(false);
    setGenerateResponseCounter(0);
  };


  const onFollowUpClick = (e) => {
    setAddFollowUp(true);
  };
  const onFollowUpRemoveClick = (e) => {
    setAddFollowUp(false);
  };

  const onFollowUpTextChange = (e) => {
    const { name, value } = e.target;
    setFollowUpText(value);
  };

  const onGuestInfoClick = (e) => {
    setShowGuestInfo(showGuestInfo => !showGuestInfo);
  };

  const onMarkAsGuestMatchClick = (guestInfo) => {
    const params = {};
    params.location__id = props.locationId;
    params.review__id = review._id;
    params.guest_match_id = guestInfo._id;
    params.edit_type = 'save';
    dispatch(editDataAction({
      op: 'edit_review',
      params
    }));
  };

  const renderRedirectToOtaModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal redirectToOtaModal">
          <div className="content" ref={redirectToOtaModalRef}>
            <RedirectToOta ota={review.source} otaUrl={otaResponseUrl}
              onCancelClick={onRedirectToOtaModalCloseClick}
              onConfirmClick={onRedirectToOtaConfirmClick}
              mode="modal"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderRespondedOnOtaModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal respondedOnOtaModal">
          <div className="content" ref={respondedOnOtaModalRef}>
            <RespondedOnOta ota={review.source} otaUrl={''}
              onCancelClick={onRespondedOnOtaModalCloseClick}
              onConfirmClick={onRespondedOnOtaConfirmClick}
              inProgress={review.editRespondedOnOtaInProgress}
              mode="modal"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderManagerResponse = (selectedLangTranslationsMR) => {
    return (
      <div className="body responseBody">
        <div className="header">
          <div className="left">
            Your Response
          </div>
          <div className="right" onClick={onHideResponse}>
            {`${showResponse ? 'Hide ' : 'Show '}`} Response
          </div>
        </div>
        {
          showResponse &&
          <>
            {
              true &&
              <div className="content">
                { review.manager_response}
              </div>
            }
            {
              !showOriginalMR && selectedLangTranslationsMR &&
              <div className="content">
                { selectedLangTranslationsMR.manager_response}
              </div>
            }
          </>
        }
      { hasTranslate && renderTranslateRowMR(selectedLangTranslationsMR) }
      </div>
    );
  };

  const renderResponsePosted = () => {
    return (
      <div className="body responseRequiredBody">
        <div className="header">
          <div className="left">
            Posted Response
          </div>
        </div>
        {
          showResponse &&
          <div className="content">
            {
              hasSelfResponse
              ? review.self_response || review.suggested_response /* gtcheck - remove suggested_response */
              : null
            }
            {
              hasGTResponse
              ? review.suggested_response
              : null
            }
          </div>
        }
      </div>
    );
  };

  const renderApprovalPendingSuggestedResponse = () => {
    return (
      <div className="body responseRequiredBody">
        <div className="header">
          <div className="left">
            Approval Pending - Suggested Response
          </div>
        </div>
        {
          showResponse && !approveEditMode &&
          <div className="content">
            { review.suggested_response}
            {
              hasGTResponse && props.locationInfo.response_signature
              ? <span className="sig">{props.locationInfo.response_signature}</span>
              : null
            }
          </div>
        }
        {
          approveEditMode &&
          <div className="contentTextarea">
            <textarea
              value={approveEditText}
              onChange={onApproveEditTextChange}
              placeholder="Enter your response..."
              rows="5"
            />
          </div>
        }
        <div className="submitBox">
          <div className="buttonWrap">
            <button className={'gBtn'} onClick={onApproveButtonClick}>
              <span>
                {
                  approveEditMode ? 'Save and Approve' : 'Approve'
                }
                { review.editApproveInProgress && <span><i className="fa fa-spinner fa-spin" /></span> }
              </span>
            </button>
            {
              !approveEditMode &&
              <button className={'gBtn'} onClick={onApproveEditButtonClick}>
                <span>{approveEditMode ? 'Save and Approve' : 'Edit'}</span>
              </button>
            }
          </div>
        </div>
      </div>
    );
  };

  const renderApprovalReceivedResponse = () => {
    return (
      <div className="body responseRequiredBody">
        <div className="header">
          <div className="left">
            Approval Received
          </div>
        </div>
        <div className="content">
          { review.edited_response || review.suggested_response}
        </div>
      </div>
    );
  };

  const renderNeedMoreInfoForResponse = () => {
    return (
      <div className="body responseRequiredBody">
        <div className="header">
          <div className="left">
            More info required
          </div>
        </div>
        {
          showResponse &&
          <div className="content">
            { review.suggested_response}
            {
              review.more_info_msg
              ? <p className='mt30'>{review.more_info_msg}</p>
              : null
            }
            {
              hasGTResponse && props.locationInfo.response_signature
              ? <span className="sig">{props.locationInfo.response_signature}</span>
              : null
            }
          </div>
        }
        <div className="contentTextarea">
          <textarea
            value={moreInfoText}
            onChange={onMoreInfoTextChange}
            placeholder="Give more info…."
          />
        </div>
        {
          moreInfoTextError &&
          <div className="error">
            More info is required.
          </div>
        }
        <div className="submitBox">
          <div className="buttonWrap">
            <button className={'gBtn'} onClick={onSendMoreInfoButtonClick}>
              <span>Send Info { review.editMoreInfoInProgress && <span><i className="fa fa-spinner fa-spin" /></span> }</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderMoreInfoReceivedResponse = () => {
    return (
      <div className="body responseRequiredBody">
        <div className="header">
          <div className="left">
            Info Received
          </div>
        </div>
        <div className="content">
          { review.more_info_response }
        </div>
        <div className="subTitle">
          Need info request
        </div>
        <div className="content">
          { review.more_info_msg }
        </div>
      </div>
    );
  };

  const renderPreparedResponseTranslateIcon = () => {
    return (
      <div className="translateIcon">
        <div className={'dropdown ' + (languageSelectorOpenPR ? 'open' : '')} ref={languageSelectorRefPR}>
          <button className="dropbtn" onClick={onTranslateLanguageSelectorOpenClickPR}>
            <img src={translateIcon} />
          </button>
          <div className="dropdown-content languageSelector">
            <div className="search">
              <input type="text" onChange={onLanguageSelectorInputChange} value={langaugeSearchTerm} placeholder="Search" />
            </div>
            <div className="langListWrap">
              {
                !langaugeSearchTerm && review.lang &&
                <div className="recentlyUsedList">
                  <p onClick={() => onLanguageSelectPR(review.lang ? review.lang.split('-')[0] : '')}>
                    Author's Language - {review.lang ? LANGUAGE_LIST_KEYS[review.lang.split('-')[0]] : ''}
                  </p>

                </div>
              }
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedTitle">
                  Recently Used
                </div>
              }
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedList">
                  {
                    props.userState.user.user_info && props.userState.user.user_info.profile &&
                    props.userState.user.user_info.profile.recent_lang &&
                    Object.keys(props.userState.user.user_info.profile.recent_lang).length > 0
                    ?
                      Object.keys(props.userState.user.user_info.profile.recent_lang)
                        .sort((a,b) => props.userState.user.user_info.profile.recent_lang[b] - props.userState.user.user_info.profile.recent_lang[a])
                        .map((item) => {
                        const lang = LANGUAGE_LIST_KEYS[item];
                        return (
                          <p onClick={() => onLanguageSelectPR(item)}>{lang}</p>
                        )
                      })
                    : null
                  }
                </div>
              }
              <div className="allTitle">
                All
              </div>
              <div className="allList">
                {
                  Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
                    const lang = LANGUAGE_LIST_KEYS[item];
                    if (langaugeSearchTerm && langaugeSearchTerm.length > 1) {
                      const reg = new RegExp(langaugeSearchTerm, "i")
                      if (
                        (lang && lang.search(reg) > -1)
                      ) {
                        return (
                          <p onClick={() => onLanguageSelectPR(item)}>{lang}</p>
                        )
                      }
                      return null;
                    }
                    return (
                      <p onClick={() => onLanguageSelectPR(item)}>{lang}</p>
                    )
                  })
                }
              </div>
            </div>
            {
              !props.isGroup &&
              <div className="langSettings">
                <span><Link to={`/locations/${props.locationId}/settings/language`}>Language settings</Link></span>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  const renderPrepareResponse = (reviewStateLocal) => {
    if (props.isReadOnly) return null;

    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('renderPrepareResponse reviewStateLocal: ', reviewStateLocal);
    //   console.log('renderPrepareResponse props.repAccess: ', props.repAccess);
    // }

    const hasGeneratedResponse = props.locationInfo.has_generated_response;

    const { hasAccessToRespondToReviews } = props.repAccess || {};
    if (!hasAccessToRespondToReviews) return null;

    let maxResponseTemplatesToShow = 3;
    if (windowState.window.windowWidth < 1024) {
      maxResponseTemplatesToShow = 1;
    }
    if (hasGTResponse) {
      if (reviewStateLocal === RS.RS_RESPONSE_POSTED) {
        return renderResponsePosted();
      }
      if (reviewStateLocal === RS.RS_APPROVAL_RECEIVED) {
        return renderApprovalReceivedResponse();
      }
      if (reviewStateLocal === RS.RS_WAITING_FOR_APPROVAL) {
        return renderApprovalPendingSuggestedResponse();
      }
      if (reviewStateLocal === RS.RS_MORE_INFO_RECEIVED) {
        return renderMoreInfoReceivedResponse();
      }
      if (reviewStateLocal === RS.RS_NEED_MORE_INFO) {
        return renderNeedMoreInfoForResponse();
      }
    }

    if (hasSelfResponse) {
      if (reviewStateLocal === RS.RS_RESPONSE_POSTED) {
        return renderResponsePosted();
      }


      let responseBoxTitle = "Type your Response";
      responseBoxTitle = "Prepare your response" // nkcheck

      return (
        <div className="body responseRequiredBody">
          <div className="header">
            <div className="left">
              {responseBoxTitle}
            </div>
            {/*<div className="right">
              <div className="getResp" onClick={onGenerateResponseClick}>
                Generate
                {
                  review.getResponseInProgress &&
                  <span><i className="fa fa-spinner fa-spin" /></span>
                }
              </div>
            </div>*/}
          </div>
          {
            hasGeneratedResponse &&
            <div className="promptBox">
              <div className="tTl">
                Use guestAI™ to generate response
              </div>
              <div className="prptDrpDown">
                <div className="title">Choose Prompt or enter your own</div>
                <Select
                  className="prptSelect"
                  options={promptOptions}
                  onChange={onPromptOptionChange}
                  value={promptSelectValue}
                  isDisabled={review.getResponseInProgress}
                />
              </div>
              <div className="prptTextarea">
                <textarea
                  value={promptText}
                  onChange={onPromptTextChange}
                  placeholder="Enter the prompt"
                />
              </div>
              {
                generateResponseCounter > 0 &&
                <div className="folUpBox">
                  {
                    addFollowUp
                    ?
                      <>
                        <div className="folUpTextArea">
                          <textarea
                            value={followUpText}
                            onChange={onFollowUpTextChange}
                            placeholder="Enter the follow up prompt to ask AI to further improve the response"
                            readOnly={review.getResponseInProgress}
                          />
                        </div>
                        <div className="addRemFolUpAct" onClick={onFollowUpRemoveClick}>
                          Remove Followup Prompt
                        </div>
                      </>
                    :
                      <div className="addRemFolUpAct" onClick={onFollowUpClick}>
                        Add Followup prompt to ask AI to further improve the response
                      </div>
                  }
                </div>
              }
              <div className="gnrtBox">
                <div className="getResp" onClick={onGenerateResponseClick}>
                  {
                    generateResponseCounter > 0
                    ? <>Re-Generate Response</>
                    : <>Generate Response</>
                  }
                  {
                    review.getResponseInProgress &&
                    <span><i className="fa fa-spinner fa-spin" /></span>
                  }
                </div>
                {
                  promptError &&
                  <div className="gErr">Choose a prompt or enter your own</div>
                }
              </div>
            </div>
          }
          {
            isGeneratedResponseText &&
            <div className="cntTxtArTtl">
              Generated response
            </div>
          }
          <div className="contentTextarea">
            {
              review.getResponseInProgress &&
              <span><i className="fa fa-spinner fa-spin" /></span>
            }
            <textarea
              value={selfResponseText}
              onChange={onSelfResponseTextChange}
              placeholder={hasGeneratedResponse
                ? 'Generate response from above or type something or tap on any of the templates below….'
                : 'Type something or tap on any of the templates below….'
              }
              readOnly={review.getResponseInProgress}
            />
            <div className="actionIcons">
              { hasTranslate && renderPreparedResponseTranslateIcon() }
            </div>
          </div>
          <div className="templates">
            <div className="list">
              {
                props.isGroup &&
                props.locationInfo &&
                props.locationInfo.settings &&
                props.locationInfo.settings.response_templates &&
                props.locationInfo.settings.response_templates.list &&
                props.locationInfo.settings.response_templates.keyValue
                ?
                  props.locationInfo.settings.response_templates.list.slice(0,maxResponseTemplatesToShow).map((item) => {
                    if (props.locationInfo.settings.response_templates.keyValue[item]) {
                      return <ResponseTemplateButton
                        data={props.locationInfo.settings.response_templates.keyValue[item]}
                        onClick={onResponseTemplateButtonClick}
                      />
                    }
                    return null;
                  })
                : null
              }
              {
                !props.isGroup &&
                props.settings &&
                props.settings.reputation &&
                props.settings.reputation.response_templates &&
                props.settings.reputation.response_templates.list &&
                props.settings.reputation.response_templates.keyValue
                ?
                  props.settings.reputation.response_templates.list.slice(0,maxResponseTemplatesToShow).map((item) => {
                    if (props.settings.reputation.response_templates.keyValue[item]) {
                      return <ResponseTemplateButton
                        data={props.settings.reputation.response_templates.keyValue[item]}
                        onClick={onResponseTemplateButtonClick}
                      />
                    }
                    return null;
                  })
                : null
              }
            </div>
            {
              props.mode !== 'embed' && !props.isGroup &&
              <div className="all">
                <span onClick={onOpenAllTemplatesClick}>All Templates</span>
                <span>></span>
              </div>
            }
          </div>
          {
            selfResponseTextError &&
            <div className="error">
              Response is required.
            </div>
          }
          <div className="submitBox">
            <div className="buttonWrap">
              <button className={'gBtn ' + (!selfResponseText ? 'inactive' : '')} onClick={onSaveClick}>
                Save { review.editSaveInProgress && <span><i className="fa fa-spinner fa-spin" /></span> }
              </button>
              <button className={'gBtn ' + (!selfResponseText ? 'inactive' : '')} onClick={onRespondOnOtaClick}>
                Respond on {OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]}
                  { review.editSaveAndRespondInProgress && <span>( Saving... <i className="fa fa-spinner fa-spin" /> )</span> }

              </button>
              {
                props.locationInfo.has_self_and_gt_response && review.source !== 5 &&
                <button className={'gBtn ' + (!selfResponseText ? 'inactive' : '')} onClick={onSubmitToGtClick}>
                  Submit to GuestTouch for posting on {OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source]}
                    { review.editSaveAndSubmitToGtInProgress && <span>( Submitting... <i className="fa fa-spinner fa-spin" /> )</span> }

                </button>
              }
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  const renderResponseBox = (reviewStateLocal, selectedLangTranslationsMR) => {
    if (!hasSelfResponse && !hasGTResponse) return null;
    if (reviewStateLocal !== RS.RS_RESPONSE_POSTED &&
      reviewStateLocal !== RS.RS_APPROVAL_RECEIVED &&
      reviewStateLocal !== RS.RS_WAITING_FOR_APPROVAL &&
      reviewStateLocal !== RS.RS_MORE_INFO_RECEIVED &&
      reviewStateLocal !== RS.RS_NEED_MORE_INFO &&
      reviewStateLocal !== RS.RS_HAS_MANAGER_RESPONSE &&
      reviewStateLocal !== RS.RS_SELF_RESPONSE_REQUIRED
    ) {
      return null;
    }

    const { hasAccessToRespondToReviews } = props.repAccess || {};

    return (
      <div className="responseBox">
        {
          (review.has_manager_response || hasAccessToRespondToReviews) && !props.isReadOnly &&
          <div className="vBar"></div>
        }
        {
          review.has_manager_response
          ?
            renderManagerResponse(selectedLangTranslationsMR)
          :
            renderPrepareResponse(reviewStateLocal)
        }
      </div>
    );
  };

  const renderShareMenu = () => {
    return (
      <div ref={shareMenuPanelRef} className={'dropdown menuBox shareMenu ' + (showShareMenuPanel ? 'open' : '')}>
        <button className="dropbtn" onClick={onShareMenuClick}>
          <span><img src={shareIcon} className="actionIcon" /></span>
        </button>
        <div className="dropdown-content">
          <div className="dcRow" onClick={onShareOnWhatsappClick}>
            <span><img src={whatsappIcon} />WhatsApp</span>
          </div>
          <div className="dcRow gmail" onClick={onShareOnEmailClick}>
            <span><img src={gmailIcon} />Gmail</span>
          </div>
        </div>
      </div>
    );
  }

  const renderMoreMenu = () => {
    return (
      <div ref={moreMenuPanelRef} className={'dropdown menuBox moreMenu ' + (showMoreMenuPanel ? 'open' : '')}>
        <button className="dropbtn" onClick={onMoreMenuClick}>
          <span><img src={moreIcon} className="actionIcon" /></span>
        </button>
        <div className="dropdown-content">
          {/*
            reviewState !== RS.RS_HAS_MANAGER_RESPONSE &&
            <div className="dcRow" onClick={onMoreMenuMarkAsRespondedClick}>
              <span>Mark as responded</span>
            </div>
          */}
          <div className="dcRow" onClick={onCopyClick}>
            <span>Copy</span>
          </div>
          {
            props.locationInfo.has_self_response &&
            isLocationAdminAndAbove(props.accessLevel) &&
            <div className="dcRow" onClick={onShowTimelineClick}>
              <span>{ showTimeline ? 'Hide Timeline' : 'Show Timeline'}</span>
            </div>
          }
        </div>
      </div>
    );
  };

  const renderTranslateToLang = (translationLang, selectedLangTranslations) => {
    return null; // nkcheck
    if (!showOriginal) return null;
    if (translationLang) {
      if (translationLang === SKIP_TRANSLATE_LANG_CODE) return null;
      return (
        <div className="translateToLang" onClick={onTranslateToTranslationLangClick}>
          <span>
            { translateFor === 'review' && review.translateInProgress ? 'Translating' : 'Translate' }
            {
              translationLang && ` to ${LANGUAGE_LIST_KEYS[translateFor === 'review' && review.translateInProgress ? selectedTranslationLang : translationLang]}`
            }
          </span>
        </div>
      );
    }
    return null;
  };

  const renderLangDropdownCta = (translationLang, selectedLangTranslations) => {
    return (
      <div className="translateIcon">
        <div className={'dropdown ' + (languageSelectorOpen ? 'open' : '')} ref={languageSelectorRef}>
          <button className="dropbtn" onClick={onTranslateLanguageSelectorOpenClick}>
            {/*
              translateInProgress
              ? <i className="fa fa-spinner fa-spin"></i>
              : <i className="fas fa-language"></i>
            */}
            {
              !showOriginal
              ?
                <>
                  {
                    translateFor === 'review'
                    ?
                      <span className="translatedToLang">
                        {review.translateInProgress ? 'Translating' : 'Translated'}
                        {selectedLangTranslations && selectedLangTranslations.review_src_lang && selectedTranslationLang !== selectedLangTranslations.review_src_lang ? ` from ${LANGUAGE_LIST_KEYS[selectedLangTranslations.review_src_lang]}` : ''}
                        {` to `}
                        {LANGUAGE_LIST_KEYS[selectedTranslationLang]}
                      </span>
                    :
                      <span className="translatedToLang">
                        {'Translated'}
                        {selectedLangTranslations && selectedLangTranslations.review_src_lang && selectedTranslationLang !== selectedLangTranslations.review_src_lang ? ` from ${LANGUAGE_LIST_KEYS[selectedLangTranslations.review_src_lang]}` : ''}
                        {` to `}
                        {LANGUAGE_LIST_KEYS[selectedTranslationLang]}
                      </span>
                  }
                </>
              :
                <>
                  <span>
                    {
                      translationLang ?
                      <>
                        {
                          translationLang === SKIP_TRANSLATE_LANG_CODE
                          ? `${review.translateInProgress ? 'Translating' : 'Translate'}`
                          : `${review.translateInProgress ? 'Translating' : 'Translate'}`
                        }
                      </>
                      : 'Translate'
                    }
                  </span>
                </>
            }
          </button>
          <div className="dropdown-content languageSelector">
            <div className="search">
              <input type ="text" onChange={onLanguageSelectorInputChange} value={langaugeSearchTerm} placeholder="Search" />
            </div>
            <div className="langListWrap">
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedTitle">
                  Recently Used
                </div>
              }
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedList">
                  {
                    props.userState.user.user_info && props.userState.user.user_info.profile &&
                    props.userState.user.user_info.profile.recent_lang &&
                    Object.keys(props.userState.user.user_info.profile.recent_lang).length > 0
                    ?
                      Object.keys(props.userState.user.user_info.profile.recent_lang)
                        .sort((a,b) => props.userState.user.user_info.profile.recent_lang[b] - props.userState.user.user_info.profile.recent_lang[a])
                        .map((item) => {
                        const lang = LANGUAGE_LIST_KEYS[item];
                        return (
                          <p onClick={() => onLanguageSelect(item)}>{lang}</p>
                        )
                      })
                    : null
                  }
                </div>
              }
              <div className="allTitle">
                All
              </div>
              <div className="allList">
                {
                  Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
                    const lang = LANGUAGE_LIST_KEYS[item];
                    if (langaugeSearchTerm && langaugeSearchTerm.length > 1) {
                      const reg = new RegExp(langaugeSearchTerm, "i")
                      if (
                        (lang && lang.search(reg) > -1)
                      ) {
                        return (
                          <p onClick={() => onLanguageSelect(item)}>{lang}</p>
                        )
                      }
                      return null;
                    }
                    return (
                      <p onClick={() => onLanguageSelect(item)}>{lang}</p>
                    )
                  })
                }
              </div>
            </div>
            {
              !props.isGroup &&
              <div className="langSettings">
                <span><Link to={`/locations/${props.locationId}/settings/language`}>Language settings</Link></span>
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  const renderTranslateRow = (selectedLangTranslations) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    return (
      <div className="translateRow">
        <div className="translateIconDiv">
          <span><img src={translateIcon} /></span>
        </div>
        { renderTranslateToLang(translationLang, selectedLangTranslations) }
        {/*
          translationLang &&
          <div className="translateToLang" onClick={onTranslateToTranslationLangClick}>
            <span>
              Translate
              {
                translationLang && ` to ${LANGUAGE_LIST_KEYS[translationLang]}`
              }
            </span>
          </div>
        */}
        { renderLangDropdownCta(translationLang, selectedLangTranslations) }

        {/*
          !showOriginal &&
          <div className="viewOriginal" onClick={onShowOriginalClick}>
            Show Original
          </div>
        */}
      </div>
    );
  };


  const renderTranslateToLangMR = (translationLang, selectedLangTranslations) => {
    return null; // nkcheck
    if (!showOriginalMR) return null;
    if (translationLang === SKIP_TRANSLATE_LANG_CODE) return null;
    if (translationLang) {
      return (
        <div className="translateToLang" onClick={onTranslateToTranslationLangClickMR}>
          <span>
            { translateFor === 'management_response' && review.translateInProgress ? 'Translating' : 'Translate' }
            {
              translationLang && ` to ${LANGUAGE_LIST_KEYS[translateFor === 'management_response' && review.translateInProgress ? selectedTranslationLangMR : translationLang]}`
            }
          </span>
        </div>
      );
    }
    return null;
  };

  const renderLangDropdownCtaMR = (translationLang, selectedLangTranslations) => {
    return (
      <div className="translateIcon">
        <div className={'dropdown ' + (languageSelectorOpenMR ? 'open' : '')} ref={languageSelectorRefMR}>
          <button className="dropbtn" onClick={onTranslateLanguageSelectorOpenClickMR}>
            {/*
              translateInProgress
              ? <i className="fa fa-spinner fa-spin"></i>
              : <i className="fas fa-language"></i>
            */}
            {
              !showOriginalMR
              ?
                <>
                  {
                    translateFor === 'management_response'
                    ?
                      <span className="translatedToLang">
                        {review.translateInProgress ? 'Translating' : 'Translated'}
                        {selectedLangTranslations && selectedLangTranslations.manager_response_src_lang && selectedTranslationLangMR !== selectedLangTranslations.manager_response_src_lang ? ` from ${LANGUAGE_LIST_KEYS[selectedLangTranslations.manager_response_src_lang]}` : ''}
                        {` to `}
                        {LANGUAGE_LIST_KEYS[selectedTranslationLangMR]}
                      </span>
                    :
                      <span className="translatedToLang">
                        {'Translated'}
                        {selectedLangTranslations && selectedLangTranslations.manager_response_src_lang && selectedTranslationLangMR !== selectedLangTranslations.manager_response_src_lang ? ` from ${LANGUAGE_LIST_KEYS[selectedLangTranslations.manager_response_src_lang]}` : ''}
                        {` to `}
                        {LANGUAGE_LIST_KEYS[selectedTranslationLangMR]}
                      </span>
                  }
                </>
              :
                <>
                <span>
                  {
                    translationLang ?
                    <>
                      {
                        translationLang === SKIP_TRANSLATE_LANG_CODE
                        ? `${review.translateInProgress ? 'Translating' : 'Translate'}`
                        : `${review.translateInProgress ? 'Translating' : 'Translate'}`
                      }
                    </>
                    : 'Translate'
                  }
                </span>
                </>
            }
          </button>
          <div className="dropdown-content languageSelector">
            <div className="search">
              <input type ="text" onChange={onLanguageSelectorInputChange} value={langaugeSearchTerm} placeholder="Search" />
            </div>
            <div className="langListWrap">
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedTitle">
                  Recently Used
                </div>
              }
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedList">
                  {
                    props.userState.user.user_info && props.userState.user.user_info.profile &&
                    props.userState.user.user_info.profile.recent_lang &&
                    Object.keys(props.userState.user.user_info.profile.recent_lang).length > 0
                    ?
                      Object.keys(props.userState.user.user_info.profile.recent_lang)
                        .sort((a,b) => props.userState.user.user_info.profile.recent_lang[b] - props.userState.user.user_info.profile.recent_lang[a])
                        .map((item) => {
                        const lang = LANGUAGE_LIST_KEYS[item];
                        return (
                          <p onClick={() => onLanguageSelectMR(item)}>{lang}</p>
                        )
                      })
                    : null
                  }
                </div>
              }
              <div className="allTitle">
                All
              </div>
              <div className="allList">
                {
                  Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
                    const lang = LANGUAGE_LIST_KEYS[item];
                    if (langaugeSearchTerm && langaugeSearchTerm.length > 1) {
                      const reg = new RegExp(langaugeSearchTerm, "i")
                      if (
                        (lang && lang.search(reg) > -1)
                      ) {
                        return (
                          <p onClick={() => onLanguageSelectMR(item)}>{lang}</p>
                        )
                      }
                      return null;
                    }
                    return (
                      <p onClick={() => onLanguageSelectMR(item)}>{lang}</p>
                    )
                  })
                }
              </div>
            </div>
            <div className="langSettings">
              <span><Link to={`/locations/${props.locationId}/settings/language`}>Language settings</Link></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTranslateRowMR = (selectedLangTranslationsMR) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    return (
      <div className="translateRow">
        <div className="translateIconDiv">
          <span><img src={translateIcon} /></span>
        </div>
        { renderTranslateToLangMR(translationLang, selectedLangTranslationsMR) }
        {/*
          translationLang &&
          <div className="translateToLang" onClick={onTranslateToTranslationLangClick}>
            <span>
              Translate
              {
                translationLang && ` to ${LANGUAGE_LIST_KEYS[translationLang]}`
              }
            </span>
          </div>
        */}
        { renderLangDropdownCtaMR(translationLang, selectedLangTranslationsMR) }

        {/*
          !showOriginalMR &&
          <div className="viewOriginal" onClick={onShowOriginalClickMR}>
            Show Original
          </div>
        */}
      </div>
    );
  };

  const renderAllTemplatesModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="sdMdl allRespTmplsMdl">
          <div className="ctnt">
            <ResponseTemplateSelector
              locationId={props.locationId}
              reviewId={props.locationInfo.has_self_response}
              onCancelClick={onCloseAllTemplatesModalClick}
              settings={props.settings}
              mode="modal"
              windowState={windowState}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderGuestInfo = (guestList) => {
    return (
      <div ref={guestInfoRef} className={'dropdown guestMtch' + (showGuestInfo ? ' open' : '') + (review.guest_match_manual_id ? ' hasMatch' : '')}>
        <div className="dropbtn" onClick={onGuestInfoClick}>
          <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
            overlay={review.guest_match_manual_id ? 'Guest matched' : 'Click to see possible guest matches'}
            // disable={item._id === review.guest_match_manual_id}
          >
            <span className="guestMtch">{ review.guest_match_manual_id ? 'Matched' : <i class="fa-solid fa-info"></i> }</span>
          </ToolTipWrapper>
        </div>
        <div className="dropdown-content">
          <div className="gMInfoBox">
            <div className="closeRow">
              <span onClick={onGuestInfoClick}>Close</span>
            </div>
            { 
              review.editSaveInProgress &&
              <div className="gPnlLdng">
                <i className="fa fa-spinner fa-spin" />
              </div>
            }
            {
              guestList.map((item) => {
                return (
                  <div className={'gDBox' + (item._id === review.guest_match_manual_id ? ' matched' : '')}>
                    <div className="nameBox">
                      <p className="name">
                        {item.first_name} {item.last_name || ''}
                        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                          overlay={'Click to mark as a match'}
                          disable={item._id === review.guest_match_manual_id}
                        >
                          <span className="" onClick={() => onMarkAsGuestMatchClick(item)}><i class="fa-solid fa-check"></i></span>
                        </ToolTipWrapper>
                      </p>
                    </div>
                    <div className="dRow">
                      <p className="checkInDate"><span>Check-in Date</span>{formatDate(item.check_in_date)}</p>
                      <p className="checkOutDate"><span>Check-out Date</span>{formatDate(item.check_out_date)}</p>
                      <p><span>Room Number</span> {item.room_number || '-'}</p>
                      <p><span>Email</span> {item.email || '-'}</p>
                      <p><span>Contact</span> {item.contact || '-'}</p>
                      {/*<p><span>Reservation Id</span> {item.reservation_id || '-'}</p>*/}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }

  const renderContent = (review) => {
    if (!review) return null;
    const { translations } = review;
    const selectedLangTranslations = translations && translations[selectedTranslationLang] ? translations[selectedTranslationLang] : null;
    const selectedLangTranslationsMR = translations && translations[selectedTranslationLangMR] ? translations[selectedTranslationLangMR] : null;
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleReview selectedLangTranslations: ', selectedLangTranslations);
      console.log('SingleReview props.locationInfo: ', props.locationInfo);
    }
    let hasEnTranslation = false;
    // if (enTranslated && (enTranslated.comment || enTranslated.comment_negative)) {
    //   hasEnTranslation = true;
    // }
    if (!hasEnTranslation) {
      if (review.title && review.lang_title && review.lang_title !== 'en' && review.lang_title !== 'en-gb' && review.lang_title !== 'en-us') {
        hasEnTranslation = true;
      }
    }

    const { hasAccessToRespondToReviews } = props.repAccess;

    let reviewStateLocal = reviewState;
    if (temporaryReviewState) reviewStateLocal = temporaryReviewState;
    const { showReview } = queryString.parse(location.search);
    const { has_self_response, has_gt_response } = props.locationInfo;
    var starStyle = { "--rating": review.rating_out_of_5 };
    return (
      <div className="innerBox" id={`sr_${review._id}`}>
        {
          props.mode === 'embed' &&
          <div className="closeRow">
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            {
              props.showNextPrev &&
              <div className="nextPrevBox">
                <span className="entry prev">
                  <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                    overlay={'Previous'}
                  >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  </ToolTipWrapper>
                </span>
                <span className="entry next">
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                  overlay={'Next'}
                >
                <i className="far fa-arrow-alt-circle-right"></i>
                </ToolTipWrapper>
                </span>
              </div>
            }
          </div>
        }
        <div className="reviewStateBox">
          {
            (has_self_response || has_gt_response) &&
            !showReview &&
            reviewStateLocal !== RS.RS_HAS_MANAGER_RESPONSE
            ?
              <div className={`reviewState ${reviewStateLocal}`}>
                <span>{mapReviewStateToString(reviewStateLocal)}</span>
              </div>
            : null
          }
        </div>
        <div className="headerBox">
          <div className="left">
            <div className="ota">
              { renderOTALogo(review.source) }
            </div>
            <div className="ratingBox">
              {
                props.isGroup && review.location_name
                ?
                <div className="author locName">
                  {review.location_name}
                </div>
                : null
              }
              {
                review.author && review.author.name
                ?
                  <div className="author">
                    {
                      review.guest_match_result && review.guest_match_result.length
                      ?
                        <div className="guestMtch">
                          { renderGuestInfo(review.guest_match_result) }
                        </div>
                      : null
                    }
                    {review.author.name}
                  </div>
                : null
              }
              <div className="rating">
                <div className="stars">
                  <div className="reviewStars" style={starStyle}></div>
                </div>
                <div className="ratingValue">
                  {review.rating_out_of_5} / 5
                </div>
              </div>
              {
                review.review_date &&
                <div className="reviewDate">
                  {formatDate(review.review_date)}
                </div>
              }
              {
                review.source === 15 && review.airbnb_set_id && review.airbnb_set_name &&
                <div className="reviewDate rmName">
                  @ {review.airbnb_set_name}
                </div>
              }
            </div>
          </div>
          <div className="right">
            {/*
              review.review_date &&
              <div className="reviewDate">
                {formatDate(review.review_date)}
              </div>
            */}
            {
              (has_self_response || has_gt_response) &&
              !showReview &&
              reviewStateLocal !== RS.RS_HAS_MANAGER_RESPONSE
              ?
                <div className={`reviewState ${reviewStateLocal}`}>
                  <span>{mapReviewStateToString(reviewStateLocal)}</span>
                </div>
              : null
            }
            {/*<div className="share">
              <span><i className="fa fa-share-alt" /></span>
            </div>*/}
            <div className="cMenu">
              { renderShareMenu() }
            </div>
            <div className="cMenu">
              { renderMoreMenu() }
            </div>
          </div>
        </div>
        {
          true &&
          <div className="commentBox">
            {
              props.filterKeyword
              ?
                <>
                  {
                    review.title &&
                    <Highlighter
                      className="title"
                      highlightClassName="highlightClass"
                      searchWords={[props.filterKeyword]}
                      autoEscape={true}
                      textToHighlight={review.title}
                    />
                  }
                </>
              : review.title && <p className="title">{review.title}</p>
            }
            {
              props.filterKeyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.filterKeyword]}
                  autoEscape={true}
                  textToHighlight={review.comment}
                />
              : <p className="comment">
                  <>
                    { review.comment && review.source === 3 ? <i className="far fa-laugh" /> : null }
                    <>{review.comment}</>
                  </>
                </p>
            }
            {
              review.comment_negative
              ?
                <>
                  {
                    props.filterKeyword
                    ?
                      <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[props.filterKeyword]}
                        autoEscape={true}
                        textToHighlight={review.comment_negative}
                      />
                    : <p className="comment comment_negative">
                        <>
                          { review.comment_negative && review.source === 3 ? <i className="far fa-frown" /> : null }
                          <>{review.comment_negative}</>
                        </>
                      </p>
                  }
                </>
              : null
            }
          </div>
        }
        {
          !showOriginal && selectedLangTranslations &&
          <div className="commentBox">
            {
              selectedLangTranslations.title && <p className="title">{ <Interweave content={selectedLangTranslations.title} /> }</p>
            }
            {
              <p className="comment">
                <>
                  { selectedLangTranslations.comment && review.source === 3 ? <i className="far fa-laugh" /> : null }
                  <>{ <Interweave content={selectedLangTranslations.comment} /> }</>
                </>
              </p>
            }
            {
              selectedLangTranslations.comment_negative
              ?
                <>
                  <p className="comment comment_negative">
                    <>
                      { selectedLangTranslations.comment_negative && review.source === 3 ? <i className="far fa-frown" /> : null }
                      <>{ <Interweave content={selectedLangTranslations.comment_negative} />}</>
                    </>
                  </p>
                </>
              : null
            }
          </div>
        }
        { (review.title || review.comment || review.comment_negative) && hasTranslate &&
        renderTranslateRow(selectedLangTranslations) }
        {/*
          hasEnTranslation &&
          <div className="commentBox hasEnTranslation">
            <div className="showEnTranslateCta" onClick={onShowEnTranslation}>
              {`${showEnTranslation ? 'Hide ' : 'Show '}`} english translation
            </div>
          </div>
        */}
        {/*
          hasEnTranslation && showEnTranslation &&
          <div className="commentBox translated">
            <p className="title">{enTranslated.title}</p>
            {
              enTranslated.comment &&
              <p className="comment">
                <>
                  { enTranslated.comment && review.source === 3 ? <i className="far fa-laugh" /> : null }
                  <>{enTranslated.comment}</>
                </>
              </p>
            }
            {
              enTranslated.comment_negative &&
              <p className="comment comment_negative">
                <>
                  { enTranslated.comment_negative && review.source === 3 ? <i className="far fa-frown" /> : null }
                  <>{enTranslated.comment_negative}</>
                </>
              </p>
            }
          </div>
        */}
        {/*<p onClick={onTranslateClick}>Translate</p>*/}
        {
          !showReview
          ?
            <>
              { reviewStateLocal !== RS.RS_NOT_RESPONDABLE
                ? renderResponseBox(reviewStateLocal, selectedLangTranslationsMR)
                : null
              }
              {
                (hasSelfResponse || hasGTResponse) &&
                reviewStateLocal !== RS.RS_HAS_MANAGER_RESPONSE && props.mode === 'embed' &&
                hasAccessToRespondToReviews &&
                <ResponseTemplateSelector locationId={props.locationId} reviewId={review._id} mode="embed"
                  settings={props.settings}
                />
              }
              {
                showTimeline &&
                <div className="timeLnBx">
                  {
                    review.self_response_updated_by &&
                    <div className="tmEtry">
                      <div className="lbl">Response prepared by:</div>
                      <div className="prpBy">{review.self_response_updated_by}</div>
                      <div className="updAt">{formatDate(review.self_response_date)}</div>
                    </div>
                  }
                  {
                    review.self_response_posted_by &&
                    <div className="tmEtry">
                      <div className="lbl">Response posted by:</div>
                      <div className="prpBy">{review.self_response_posted_by}</div>
                      <div className="updAt">{formatDate(review.self_response_posted_date)}</div>
                    </div>
                  }
                </div>
              }
              { showRedirectToOtaModal && renderRedirectToOtaModal() }
              { showRespondedOnOtaModal && renderRespondedOnOtaModal() }
              { showApproveConfirmModal &&
                <GenericAlertModal
                  modalType="ApproveConfirm"
                  title={`Are you sure?`}
                  bodyRowOne={"This approved response will be posted on OTA."}
                  bodyRowNote=""
                  setModalVisible={onApproveConfirmCloseModal}
                  onAffirmativeClick={onApproveConfirmClick}
                  // inProgress={guest.editCheckOutInProgress}
                  // currentStatus={guest.checkOutStatus}
                  showCancel={true}
                />
              }
              { showPostOnGoogleConfirmModal &&
                <GenericAlertModal
                  modalType="ApproveConfirm"
                  title={`Are you sure?`}
                  bodyRowOne={"This response will be posted on Google."}
                  bodyRowNote=""
                  setModalVisible={onPostOnGoogleConfirmCloseModal}
                  onAffirmativeClick={onPostOnGoogleConfirmClick}
                  inProgress={review.postResponseOnGoogleInProgress}
                  // currentStatus={guest.checkOutStatus}
                  showCancel={true}
                />
              }
              { showSetTranslationLangModal &&
                <GenericAlertModal
                  modalType="ApproveConfirm"
                  title={`Do you want to set your translation langauge ?`}
                  bodyRowOne={"It can be updated later as well from Settings -> Language"}
                  bodyRowNote=""
                  setModalVisible={onSetTranslationLangCloseModal}
                  onAffirmativeClick={onSetTranslationLangClick}
                  onCancelClick={onSetTranslationLangCancelClick}
                  // inProgress={guest.editCheckOutInProgress}
                  // currentStatus={guest.checkOutStatus}
                  showCancel={true}
                />
              }
              { showSetTranslationLangMRModal &&
                <GenericAlertModal
                  modalType="ApproveConfirm"
                  title={`Do you want to set your translation langauge ?`}
                  bodyRowOne={"It can be updated later as well from Settings -> Language"}
                  bodyRowNote=""
                  setModalVisible={onSetTranslationLangMRCloseModal}
                  onAffirmativeClick={onSetTranslationLangMRClick}
                  onCancelClick={onSetTranslationLangMRCancelClick}
                  // inProgress={guest.editCheckOutInProgress}
                  // currentStatus={guest.checkOutStatus}
                  showCancel={true}
                />
              }
              { showTakePromptTextChangePermission &&
                <GenericAlertModal
                  modalType="CancelReservation"
                  bodyRowOne={`Follow up text will be cleared and AI will try to generate a new response based on the prompt ?`}
                  setModalVisible={onShowTakePromptTextChangePermissionCloseModal}
                  onAffirmativeClick={onTakePromptTextChangePermissionClick}
                  // inProgress={guest.cancelReservationInProgress}
                  // currentStatus={guest.editDisableCheckMsgStatus}
                  showCancel={true}
                />
              }
            </>
          : null
        }
        { showAllTemplatesModal && renderAllTemplatesModal() }
        {
          review.translateInProgress &&
          <div className="translateInProgress"><span><i className="fa fa-spinner fa-spin" /></span></div>
        }
      </div>
    );
  };





  if (process.env.NODE_ENV !== 'production') {
    // console.log('SingleReview locationId: ', props.locationId);
    console.log('SingleReview reviewId: ', props.reviewId);
    console.log('SingleReview reviewState: ', reviewState);
    console.log('SingleReview hasGTResponse: ', hasGTResponse);
    console.log('SingleReview hasSelfResponse: ', hasSelfResponse);
    console.log('SingleReview hasTranslate: ', hasTranslate);
    console.log('SingleReview selectedTranslationLang: ', selectedTranslationLang);
    console.log('SingleReview selectedTranslationLangMR: ', selectedTranslationLangMR);
    console.log('SingleReview showOriginal: ', showOriginal);
    console.log('SingleReview showOriginalMR: ', showOriginalMR);
    console.log('SingleReview translateFor: ', translateFor);
    console.log('SingleReview generateResponseCounter: ', generateResponseCounter);
  }

  return (
    <div className="gCrd reviewCard">
        { renderContent(review) }
    </div>
  );
}

export default SingleReview;
