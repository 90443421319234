// @flow

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';

import './style.css';

function ToggleAndData(props) {

  const [toggleOn, setToggleOn] = useState(false)
  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    setToggleOn(props.toggleOn)
    setTextValue(props.textValue);
  }, [props.toggleOn, props.textValue]);
  const onTextChange = (e) => {
    const { name, value } = e.target;
    setTextValue(value);
    props.onTextChange(value);
  };
  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    setToggleOn(checked);
    props.onToggleChange(checked);
  };

  return (
    <>
      <div className={'gBCtRw tglAndData hasTgl' + (props.toggleOn ? ' withValue' : '')}>
        <div className="lt">
          <p className="title">{props.title}</p>
          <p className="desc">{props.subTitle}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={props.name} checked={toggleOn} onChange={onToggleChange}/><label htmlFor={props.name}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
      {
        toggleOn && props.hasTextArea &&
        <div className="gBCtRw tglAndDataValue">
          <div className="lt">
            {
              toggleOn && props.textareaTitle &&
              <p className="title">{props.textareaTitle}</p>
            }
            {
              toggleOn &&
              <div className="gIpt">
                <textarea
                  placeholder="Enter..."
                  value={textValue}
                  onChange={onTextChange}
                />
              </div>
            }
            {
              props.error &&
              <div className="gErr">
                Message is required
              </div>
            }
          </div>
        </div>
      }
    </>
  );

}

export default ToggleAndData;
