import React, { Component } from 'react';
import { connect } from 'react-redux';

import { removeSnack } from '../../redux/actions/snacksActions';

import './Snack.css';
import IconCloseNotification from '../../img/icons/snack.png';

const stateMap = store => ({
  windowprops: store.windowprops,
});

type Action = { type: string; payload: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  message: string,
  index: number,
  snackId: number,
};

type State = {};

class Snack extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
    };
    (this: any).dismissSnack = this.dismissSnack.bind(this);
  }

  dismissSnack() {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dismissSnack - : ', this);
    }
    const { snackId } = this.props;
    this.props.dispatch(removeSnack({
      id: snackId,
    }));
  }

  render() {
    const { message, index, snackId } = this.props;
    const snackClass = 'show';
    const snackStyle = {
      bottom: 30 + (index * 60),
    };
    return (
      <div id="snackbar" className={snackClass} key={snackId} style={snackStyle}>
        { message }
        <div onClick={this.dismissSnack} onKeyPress={this.dismissSnack} role="button" tabIndex="0">
              <img
                src={IconCloseNotification}
                className="close-snack"
                alt="" />
        </div>

      </div>
    );
  }
}
export default connect(stateMap)(Snack);
