// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import ToolTipWrapper from '../ToolTipWrapper';

import { changeInValuePercent } from '../../../helpers';

import './style.css';

function NumberCard(props) {
  const onCtaClick = (e) => {
    props.onCtaClick();
  };

  const { label, value, compareNow, cmpValue, labelToolTip, ctaLabel, ctaLink, adClass, getInProgress } = props.data;

  return (
    <div className={'gCrd gNumberCard ' + (adClass ? adClass : '')}>
      <div className="top">
        <p>
          {label}
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay={labelToolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </p>
      </div>
      <div className="middle">
        {
          value ? <p>{value}</p> : <span>-</span>
        }
        { compareNow && !getInProgress && cmpValue ? <p className='cmpVal'>vs {cmpValue}</p> : null }
        { value && compareNow && !getInProgress && cmpValue ? <p className='cmpChng'>{changeInValuePercent(value, cmpValue)}</p> : null }
      </div>
      <div className="footer">
        {/*
          ctaLabel &&
          <p onClick={onCtaClick}>{ctaLabel}</p>
        */}
        {
          ctaLink && ctaLabel &&
          <Link to={ctaLink}>{ctaLabel}</Link>
        }
      </div>
    </div>
  );
}

export default NumberCard;
