import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../Misc/GenericSideModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericList from '../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { STRS } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';

import './style.css';

function LabelRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  return (
    <div className="gBCtRw msgTplRw" id={`mt_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {template.label}
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
              <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
            </>
          }
        </div>
      </div>
    </div>
  )
}

function Labels(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Labels - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  if (!props.locationInfo.has_engage) return null;

  const { settings: { engage } } = props;
  const { labels } = engage;

  return (
    <GenericList
      type="labels"
      title={`Labels${props.isReadOnly ? ` [${STRS.READ_ONLY_LABEL}]` : ''}`}
      location__id={urlParams.locationidentifier}
      generic_list={labels}
      settings={props.settings}
    />
  );
}

export default Labels;
