// import * as firebase from 'firebase/app';
// import 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyA2m5BPiSUfT4XPLIApN_MCEp3CrZUyMD8',
  authDomain: 'gtauth-41a19.firebaseapp.com',
  databaseURL: 'https://gtauth-41a19.firebaseio.com',
  projectId: 'gtauth-41a19',
  storageBucket: 'gtauth-41a19.appspot.com',
  messagingSenderId: '828817167840',
};

// if (!firebase.apps.length) {
  const app = initializeApp(firebaseConfig);
  // firebase.initializeApp(config);
// }

const auth = getAuth(app);

export {
  auth,
};
