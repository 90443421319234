import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Reviews from './Reviews';

function Location() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  return (
    <>
      <Outlet />
    </>
  );
}

export default Location;
