// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select'
import { Picker } from 'emoji-mart';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import dragIcon from '../../../../../../img/icons/drag.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import './style.css';

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `5px 0 ${grid}px 0`,
  border: '1px solid #e6e6e7',
  borderRadius: 5,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  width: 330
});

function SingleTopic(props) {
  const { topic_id, title, icon, icon_selected, questions_count } = props.data;
  return (
    <div className={'roEntry ' + (props.selected ? 'selected' : '')}>
      <div className="left">
        <img className="dragIcon" src={dragIcon} />
        <img src={icon} /> {title}
      </div>
      <div className="right">
        ({questions_count})
      </div>
    </div>
  );
}

function WebChatTopicsModal(props) {

  const [items, setItems] = useState(getItems(10));

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [someChange, setSomeChange] = useState(false);


  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const [pickerStyle, setPickerStyle] = useState({
    height: 0,
  });
  const emojiSelectorRef = useRef(null);
  // useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));

  const messageEl = useRef(null);
  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  useEffect(() => {
    // let page_title = `${props.locationprops.location.name} - Settings - Manage Templates`;
    // if (props.template && props.template._id) {
    //   page_title = `${page_title} - Edit - ${props.template.title || ''}`;
    // } else {
    //   page_title = `${page_title} - Add`;
    // }
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title,
    //   user_id: props.userprops.user.uid,
    // });
    // Mousetrap.bind('esc', () => props.onCancelClick(''));
    // return () => {
    //   Mousetrap.unbind('esc');
    // };
  }, []);

  useEffect(() => {
    const { webchat } = props.settings;
    if (webchat && webchat.help_guide && webchat.help_guide.topics) {
      setItems(webchat.help_guide.topics);
    }
  }, []);

  useEffect(() => {
    if (!props.editInProgress && props.editStatus && props.editStatus.success) {
      setSomeChange(false);
    }
  }, [props.editInProgress, props.editStatus]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tempItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(tempItems);
    setSomeChange(true);
    props.editClear();
  }


  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onEditClick = (e) => {
    if (props.editInProgress) return;
    if (someChange) {
      props.onEditConfirmClick(items);
      setSomeChange(false);
    }
  };

  const renderContent = () => {
    const { webchat } = props.settings;
    const cStyle= {};
    cStyle.maxHeight = props.windowHeight*0.9 - 200;

    return (
      <div className="sdMdl wCTpcDnd" ref={modalRef}>
        <div className="ctnt">
          <div className="gPWrp">
            <div className="gPTle title">
              Reorder Topics
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
            </div>
            <div className="gPSubTle title">
              Drag the topics to reorder.
            </div>
            <div className="gBCtnt">
              <div className="dndBox" style={cStyle}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {
                          items.map((item, index) => {
                            if (!item) return null;
                            return (
                              <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <SingleTopic data={item} />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {
                props.editStatus && (props.editStatus.success || props.editStatus.error) &&
                <div className="gBCtRw curSts">
                  {
                    props.editStatus.error && <span className="gErr">{props.editStatus.message || 'Error in updating'}</span>
                  }
                  {
                    props.editStatus.success && <span className="success">Updated successfully</span>
                  }
                </div>
              }
              <div className="gBCtRw btnRw">
                {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
                <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onEditClick} >
                  Save Changes
                  { props.editInProgress && <i className="fa fa-spinner fa-spin" /> }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('WebChatTopicsModal items: ', items);
  }

  return renderContent();

}

export default WebChatTopicsModal;
