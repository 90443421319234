import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import '../style.css';

function MessageTemplateRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  let cStyle = {};
  cStyle['backgroundColor'] = template.color;
  return (
    <div className="gBCtRw lblRw" id={`lbl_${template._id}`}>
      <div className="lt">
        <div className="clrBx" style={cStyle} />
        {
          props.keyword
          ?
            <Highlighter
              highlightClassName="highlightClass"
              searchWords={[props.keyword]}
              autoEscape={true}
              textToHighlight={template.label}
            />
          : template.label
        }
      </div>
      <div className="rt">
        {
          !props.nonEditable &&
          <>
          <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
            <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
          </ToolTipWrapper>
          <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
            <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
          </ToolTipWrapper>
          </>
        }
      </div>
    </div>
  )

}

export default MessageTemplateRow;
