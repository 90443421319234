import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../Misc/GenericSideModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericList from '../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';


function CompSet(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();


  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Comp Set - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  if (process.env.NODE_ENV !== 'production') {
    console.log('CompSet props: ', props);
  }

  const settings = locationState.locations && locationState.locations[urlParams.locationidentifier]
    ? locationState.locations[urlParams.locationidentifier].settings || {} : {};
  const comp_set_locations = settings && settings.reputation ? settings.reputation.comp_set_locations || {} : {};
  // const { settings: { reputation } } = props;
  // const { comp_set_locations } = reputation;

  return (
    <GenericList
      type="compSet"
      title="Comp Set"
      location__id={urlParams.locationidentifier}
      generic_list={comp_set_locations}
      settings={settings}
      googlePlaceId={props.locationInfo.google_place_id}
    />
  );
}

export default CompSet;
