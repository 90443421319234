// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import useOnClickOutside from '../../../Misc/OutsideClickHook';
import { editDataClearAction, editDataAction } from '../../../../redux/actions/editDataActions';

import { isPasswordStrong } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';

import './style.css';

function ResetRgPasswordModal(props) {
  const dispatch = useDispatch();
  let urlParams = useParams();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [sendEmailToUser, setSendEmailToUser] = useState(false);

  const [someChange, setSomeChange] = useState(false);


  const clearAction = () => {
    if (props.user && props.user.resetStatus && Object.keys(props.user.resetStatus).length) {
      const params = {
        user_id: props.user._id,
      };
      dispatch(editDataClearAction({
        op: 'edit_rg_user_reset_password',
        params
      }));
    }
  };

  useEffect(() => {
    clearAction();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
      setPasswordError(value ? '' : 'error');
    }
    if (name === 'repeatPassword') {
      setRepeatPassword(value);
      setRepeatPasswordError(value ? '' : 'error');
    }
    setSomeChange(true);
    clearAction();
  };

  const onSendEmailToUserChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendEmailToUserChange: ', name, ' # ', value);
    }
    setSendEmailToUser(sendEmailToUser => !sendEmailToUser);
    // setSomeChange(true);
  };

  const onSendEmailToUserLabelClick = (e) => {
    setSendEmailToUser(sendEmailToUser => !sendEmailToUser);
    // setSomeChange(true);
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onResetConfirmClick = (e) => {
    handleFormSubmit();
  };

  const handleFormSubmit = () => {
    if (props.user.resetInProgress) return;
    setPasswordError(password ? '' : 'error');
    setRepeatPasswordError(repeatPassword ? '' : 'error');
    if (password && !isPasswordStrong(password)) {
      setPasswordError('weak');
      return;
    }
    if (password && repeatPassword && (password !== repeatPassword)) {
      setPasswordError('error');
      setRepeatPasswordError('mismatch');
      return;
    }
    if (password && repeatPassword) {
      const params = {
        user_id: props.user._id,
        rg_user_id: props.user._id,
        username: props.user.username,
        password: password,
        send_email_to_user: sendEmailToUser,
      };
      dispatch(editDataAction({
        op: 'edit_rg_user_reset_password',
        params
      }));
      setSomeChange(false);
    }
  };

  const renderContent = () => {
    const cStyle= {};
    cStyle.maxHeight = props.windowHeight*0.9 - 200;
    return (
      <div className="sdMdl usrRstPas">
        <div className="ctnt">
          <div className="gPWrp">
            <div className="gPTle title">
              Reset Password for {props.user.username}
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
            </div>
            <div className="gBCtnt">
              <div className="gBCtRw byTwRw">
                <div className="gRwBxLt">
                  <div className="gLbl">
                    Password
                  </div>
                  <div className={'gIpt ' + (passwordError ? 'error' : '')}>
                    <input type="password" value={password} name="password" onChange={onChange} placeholder="Password" />
                    { passwordError === 'weak' && <p className="gErr">Minimum 8 characters required.</p> }
                  </div>
                </div>
                <div className="gRwBxRt">
                  <div className="gLbl">
                    Repeat Password
                  </div>
                  <div className={'gIpt ' + (repeatPasswordError ? 'error' : '')}>
                    <input type="password" value={repeatPassword} name="repeatPassword" onChange={onChange} placeholder="Repeat Password" />
                    { repeatPasswordError === 'mismatch' && <p className="gErr">Password mismatch</p> }
                  </div>
                </div>
              </div>
              <div className="gBCtRw chkBxRw">
                <div className="gRwBxLt">
                  <input type="checkbox" name="sendEmailToUser" onChange={onSendEmailToUserChange} value={sendEmailToUser ? 'on' : 'off'} checked={sendEmailToUser} />
                  <div className="gLbl" onClick={onSendEmailToUserLabelClick}>
                    Send email to user with login information.
                  </div>
                </div>
              </div>
              {
                props.user.resetStatus && (props.user.resetStatus.success || props.user.resetStatus.error) &&
                <div className="gBCtRw curSts">
                  {
                    props.user.resetStatus.error && <span className="gErr">{props.user.resetStatus.message || 'Error in updating'}</span>
                  }
                  {
                    props.user.resetStatus.success && <span className="success">Updated successfully</span>
                  }
                </div>
              }
              <div className="gBCtRw btnRw">
                {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
                <button type="submit" className={'gBtn ok' + (someChange ? '' : ' disabled ')} onClick={onResetConfirmClick} >
                  Reset Password
                  { props.user.resetInProgress && <i className="fa fa-spinner fa-spin" /> }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('ResetRgPasswordModal props: ', props);
  }

  return renderContent();

}

export default ResetRgPasswordModal;
