// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { Interweave } from 'interweave';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';

import { renderOTALogo, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP} from '../../../../../../helpers';
import { STRS, getUserGAId } from '../../../../../../helpers';

import './style.css';

const gtconfig = require('../../../../../../gtconfig');

function GenericHotelDataSingle(props) {
  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={props.onMEnter} onMouseLeave={props.onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          { props.desc && <label>{props.desc}</label> }
          <div className="gIpt">
            {
              props.type === "textarea"
              ?
                <textarea
                  className="gInput"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
              :
                <input
                  className="gInput"
                  type="text"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
            }
          </div>
          {
            props.error &&
            <span className="gErr">{props.error}</span>
          }
        </div>
      </div>
    </div>
  );
}

function FeedbackPositive(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const urlParams = useParams();
  const location = useLocation();

  const [positiveTitle, setPositiveTitle] = useState('');
  const [positiveTitleError, setPositiveTitleError] = useState('');
  const [highlightPositiveTitle, setHighlightPositiveTitle] = useState(false);

  const [positivePrimaryMessage, setPositivePrimaryMessage] = useState('');
  const [positivePrimaryMessageError, setPositivePrimaryMessageError] = useState('');
  const [highlightPositivePrimaryMessage, setHighlightPositivePrimaryMessage] = useState(false);

  const [tripadvisor, setTripadvisor] = useState(false);
  const [google, setGoogle] = useState(false);
  const [holidaycheck, setHolidayCheck] = useState(false);
  const [airbnb, setAirbnb] = useState(false);
  const [vrbo, setVrbo] = useState(false);

  const [hotelMessage, setHotelMessage] = useState('');
  const [hotelMessageError, setHotelMessageError] = useState('');
  const [highlightHotelMessage, setHighlightHotelMessage] = useState(false);

  const [aQEnabled, setAQEnabled] = useState(false);
  const [aQEnabledChanged, setAQEnabledChanged] = useState(false);

  const [otasEnabled, setOtasEnabled] = useState(false);
  const [otasEnabledChanged, setOtasEnabledChanged] = useState(false);

  const [someError, setSomeError] = useState(false);

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Positive - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'edit_feedback_settings',
      params,
    }));
  };

  useEffect(() => {
    clearAction();
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    if (reputation && reputation.feedback_settings) {
      const { feedback_settings } = reputation;
      if (feedback_settings) {
        if (!feedback_settings.editInProgress && feedback_settings.editStatus && feedback_settings.editStatus.success) {
          setUpdatedValues(updatedValues => ({
          }));
          setAQEnabledChanged(false);
          setOtasEnabledChanged(false);
          clearAction();
        }
      }
    }
  }, [props.settings])

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    const { reputation } = props.settings;
    if (!reputation || !reputation.feedback_settings) return null;
    const { feedback_settings } = reputation;

    setPositiveTitle(feedback_settings.positive_title || '');
    setPositivePrimaryMessage(feedback_settings.positive_primary_message || '');

    setTripadvisor(feedback_settings.otas && feedback_settings.otas.tripadvisor && feedback_settings.otas.tripadvisor.is_enabled ? true : false);
    setGoogle(feedback_settings.otas && feedback_settings.otas.google && feedback_settings.otas.google.is_enabled ? true : false);
    setHolidayCheck(feedback_settings.otas && feedback_settings.otas.holidaycheck && feedback_settings.otas.holidaycheck.is_enabled ? true : false);
    setAirbnb(feedback_settings.otas && feedback_settings.otas.airbnb && feedback_settings.otas.airbnb.is_enabled ? true : false);
    setVrbo(feedback_settings.otas && feedback_settings.otas.vrbo && feedback_settings.otas.vrbo.is_enabled ? true : false);

    setHotelMessage(feedback_settings.positive_hotel_message || '');

    setAQEnabled(feedback_settings.positive_questions_enabled || false);

    setOtasEnabled(feedback_settings.positive_otas_enabled || false);

    setFormChanged(false);
  };

  const onAQEnabledChange = (e) => {
    setAQEnabled(e.target.checked);
    setAQEnabledChanged(true);
    setFormChanged(true);
  };

  const onOtasEnabledChange = (e) => {
    setOtasEnabled(e.target.checked);
    setOtasEnabledChanged(true);
    setFormChanged(true);
  };

  const onPositiveTitleChange = (e) => {
    const { name, value } = e.target;
    setPositiveTitle(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      positive_title: value,
    }));
    // setPositiveTitleError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onPositiveTitleMouseEnter = (e) => {
    setHighlightPositiveTitle(true);
  };
  const onPositiveTitleMouseLeave = (e) => {
    setHighlightPositiveTitle(false);
  };

  const onPositivePrimaryMessageChange = (e) => {
    const { name, value } = e.target;
    setPositivePrimaryMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      positive_primary_message: value,
    }));
    // setPositiveTitleError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onPositivePrimaryMessageMouseEnter = (e) => {
    setHighlightPositivePrimaryMessage(true);
  };
  const onPositivePrimaryMessageMouseLeave = (e) => {
    setHighlightPositivePrimaryMessage(false);
  };

  const onTripadvisorChange = (e) => {
    const { name, checked } = e.target;
    setTripadvisor(checked);
    setFormChanged(true);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      tripadvisor: checked,
    }));
    setSomeError(!checked && !google && !holidaycheck && !airbnb && !vrbo ? true : false);
  };
  const onGoogleChange = (e) => {
    const { name, checked } = e.target;
    setGoogle(checked);
    setFormChanged(true);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      google: checked,
    }));
    setSomeError(!tripadvisor && !checked && !holidaycheck && !airbnb && !vrbo ? true : false);
  };
  const onHolidayCheckChange = (e) => {
    const { name, checked } = e.target;
    setHolidayCheck(checked);
    setFormChanged(true);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      holidaycheck: checked,
    }));
    setSomeError(!tripadvisor && !google && !checked && !airbnb && !vrbo ? true : false);
  };
  const onAirbnbChange = (e) => {
    const { name, checked } = e.target;
    setAirbnb(checked);
    setFormChanged(true);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      airbnb: checked,
    }));
    setSomeError(!tripadvisor && !google && !holidaycheck && !checked && !vrbo ? true : false);
  };
  const onVrboChange = (e) => {
    const { name, checked } = e.target;
    setVrbo(checked);
    setFormChanged(true);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      vrbo: checked,
    }));
    setSomeError(!tripadvisor && !google && !holidaycheck && !airbnb && !checked ? true : false);
  };

  const onHotelMessageChange = (e) => {
    const { name, value } = e.target;
    setHotelMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      positive_hotel_message: value,
    }));
    // setHotelMessageError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelMessageMouseEnter = (e) => {
    setHighlightHotelMessage(true);
  };
  const onHotelMessageMouseLeave = (e) => {
    setHighlightHotelMessage(false);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    // setPositiveTitleError(positiveTitle ? '' : 'Required');
    // if (!positiveTitle) hasError = true;

    if (!tripadvisor && !google && !holidaycheck && !airbnb && !vrbo) hasError = true;

    // setHotelMessageError(hotelMessage ? '' : 'Required');
    // if (!hotelMessage) hasError = true;

    if (!aQEnabled && !otasEnabled) return;

    if (hasError) return;

    const params = {};
    params.location__id = urlParams.locationidentifier;

    if ('positive_title' in updatedValues) params['positive_title'] = updatedValues.positive_title;
    if ('positive_primary_message' in updatedValues) params['positive_primary_message'] = updatedValues.positive_primary_message;

    if ('tripadvisor' in updatedValues) params['otas_tripadvisor'] = updatedValues.tripadvisor;
    if ('google' in updatedValues) params['otas_google'] = updatedValues.google;
    if ('holidaycheck' in updatedValues) params['otas_holidaycheck'] = updatedValues.holidaycheck;
    if ('airbnb' in updatedValues) params['otas_airbnb'] = updatedValues.airbnb;
    if ('vrbo' in updatedValues) params['otas_vrbo'] = updatedValues.vrbo;

    if ('positive_hotel_message' in updatedValues) params['positive_hotel_message'] = updatedValues.positive_hotel_message;

    if (aQEnabledChanged) params.positive_questions_enabled = aQEnabled;

    if (otasEnabledChanged) params.positive_otas_enabled = otasEnabled;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_feedback_settings',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
      show_alert_timeout: 2000,
      show_error_alert: true,
      error_alert_msg: 'Error in updating',
    }));
    setFormChanged(false);
  };

  const renderPositiveTitle = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="positiveTitle"
        title="Title"
        desc=""
        type="textarea"
        value={positiveTitle}
        onChange={onPositiveTitleChange}
        error={positiveTitleError}
        onMEnter={onPositiveTitleMouseEnter}
        onMLeave={onPositiveTitleMouseLeave}
      />
    );
  };

  const renderPositivePrimaryMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="positivePrimaryMessage"
        title="Primary Message"
        desc=""
        type="textarea"
        value={positivePrimaryMessage}
        onChange={onPositivePrimaryMessageChange}
        error={positiveTitleError}
        onMEnter={onPositivePrimaryMessageMouseEnter}
        onMLeave={onPositivePrimaryMessageMouseLeave}
      />
    );
  };

  const renderTripadvisor = () => {
    return (
      <div className="otaRow">
        <div className="left">
          <p className="title"><img src={gtconfig.TRIPADVISOR_LOGO} />Tripadvisor</p>
          <p className="info">some info</p>
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'tripadvisor'} checked={tripadvisor} onChange={onTripadvisorChange}/><label htmlFor={'tripadvisor'}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGoogle= () => {
    return (
      <div className="otaRow">
        <div className="left">
          <p className="title"><img src={gtconfig.GOOGLE_LOGO} />Google</p>
          <p className="info">some info</p>
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'google'} checked={google} onChange={onGoogleChange}/><label htmlFor={'google'}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHolidayCheck = () => {
    return (
      <div className="otaRow">
        <div className="left">
          <p className="title"><img src={gtconfig.HOLIDAYCHECK_LOGO} />HolidayCheck</p>
          <p className="info">some info</p>
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'holidaycheck'} checked={holidaycheck} onChange={onHolidayCheckChange}/><label htmlFor={'holidaycheck'}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAirbnb = () => {
    return (
      <div className="otaRow">
        <div className="left">
          <p className="title"><img src={gtconfig.AIRBNB_LOGO} />Airbnb</p>
          <p className="info">some info</p>
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'airbnb'} checked={airbnb} onChange={onAirbnbChange}/><label htmlFor={'airbnb'}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVrbo = () => {
    return (
      <div className="otaRow">
        <div className="left">
          <p className="title"><img src={gtconfig.VRBO_LOGO} />Vrbo</p>
          <p className="info">some info</p>
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'vrbo'} checked={vrbo} onChange={onVrboChange}/><label htmlFor={'vrbo'}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHotelMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelMessage"
        title="Secondary Message"
        desc=""
        type="textarea"
        value={hotelMessage}
        onChange={onHotelMessageChange}
        error={hotelMessageError}
        onMEnter={onHotelMessageMouseEnter}
        onMLeave={onHotelMessageMouseLeave}
      />
    );
  };

  const renderSave = (feedback_settings) => {
    if (props.isReadOnly) {
      return (
        <div className="gBCtRw rdOlyRw stndAln">
          <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
        </div>
      );
    }
    return (
      <div className="gSettingsRow gSaveButton">
        {
          someError && <p className="error">Atleast one selection is required!</p>
        }
        <button
          type="submit"
          className={'gBtn' + (formChanged ? '' : ' disabled')}
        >
          Save Changes
          { feedback_settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }
        </button>
      </div>
    );
  };

  const renderOtas = (feedback_settings) => {
    return (
      <>
        <form onSubmit={onSubmit}>
          { renderPositiveTitle(feedback_settings) }
          { renderAdditionalQuestionsEnabled(feedback_settings) }
          { renderOTAsEnabled(feedback_settings) }
          {/*
          { renderPositivePrimaryMessage(feedback_settings) }
          <p className="otasTitle">Select the OTAs you want to display for collecting reviews from satisfied guests</p>
          {
            feedback_settings && feedback_settings.otas && feedback_settings.otas.tripadvisor &&
            feedback_settings.otas.tripadvisor.is_available && renderTripadvisor()
          }
          {
            feedback_settings && feedback_settings.otas && feedback_settings.otas.google &&
            feedback_settings.otas.google.is_available && renderGoogle()
          }
          {
            feedback_settings && feedback_settings.otas && feedback_settings.otas.holidaycheck &&
            feedback_settings.otas.holidaycheck.is_available && renderHolidayCheck()
          }
          {
            feedback_settings && feedback_settings.otas && feedback_settings.otas.airbnb &&
            feedback_settings.otas.airbnb.is_available && renderAirbnb()
          }
          {
            feedback_settings && feedback_settings.otas && feedback_settings.otas.vrbo &&
            feedback_settings.otas.vrbo.is_available && renderVrbo()
          }
          { renderHotelMessage(feedback_settings) }
        */}
          { renderSave(feedback_settings) }
        </form>
      </>
    );
  };

  const renderAdditionalQuestionsEnabled = (feedback_settings) => {
    return (
      <div className="enabledRow">
        <div className="left">
          Show questions ?
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={`aq_enabled`} checked={aQEnabled} onChange={onAQEnabledChange}/><label htmlFor={`aq_enabled`}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOTAsEnabled = (feedback_settings) => {
    return (
      <div className="enabledRow">
        <div className="left">
          Show OTAs for collecting reviews ?
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={`otas_enabled`} checked={otasEnabled} onChange={onOtasEnabledChange}/><label htmlFor={`otas_enabled`}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPreview = (feedback_settings, engage_settings, location_info) => {
    return (
      <div className="emailPreviewWrap positiveFeedback">
        <p>Positive Feedback Screen Preview</p>
        <div className="emailPreviewBox">
          <div className="emailBodyBox">
            <div className="headerBox">
              { engage_settings.hotel_logo_url && <img src={engage_settings.hotel_logo_url} /> }
              <p>{engage_settings.hotel_name || ''}</p>
            </div>
            <div className="middleBox">
              <p className={'positiveTitle' + (highlightPositiveTitle ? ' highlight' : '')}><Interweave content={positiveTitle || feedback_settings.positive_title} /></p>
              <p>[Questions Or OTAs Or Questions followed by OTAs will appear here]</p>
              <p>[Select Questions Or OTAs tab to see the preview]</p>
              {/*<p className={'positivePrimaryMessage' + (highlightPositivePrimaryMessage ? ' highlight' : '')}><Interweave content={positivePrimaryMessage || feedback_settings.positive_primary_message} /></p>
              {
                feedback_settings.otas && Object.keys(feedback_settings.otas).length
                ? Object.keys(feedback_settings.otas).map((item) => {
                  if (item === 'tripadvisor' && !tripadvisor) return null;
                  if (item === 'google' && !google) return null;
                  if (item === 'holidaycheck' && !holidaycheck) return null;
                  if (item === 'airbnb' && !airbnb) return null;
                  if (item === 'vrbo' && !vrbo) return null;
                    return (
                      <div className="otaBox">
                        Complete with {renderOTALogo(OTA_NAME_TO_SOURCE_MAP[item])} {OTA_ID_TO_NAME_FORMAL_MAP[item]}
                      </div>
                    );
                  })
                : null
              }*/}
            </div>
            <div className="footerBox">
              <p className={'hotelMessage' + (highlightHotelMessage ? ' highlight' : '')}><Interweave content={hotelMessage || feedback_settings.positive_hotel_message} /></p>
              <p className="address">{engage_settings.hotel_address || ''}</p>
              <p className="contact">
                <span className="link">{engage_settings.hotel_number || ''}</span>
                <span className="link">{engage_settings.hotel_website || ''}</span>
              </p>
              {
                gtconfig.IS_GT &&
                <p className="poweredBy">
                  Powered by <span className="link">{gtconfig.APP_NAME_CONTACT}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = (feedback_settings, engage_settings, location_info) => {
    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="tabBox">
              <Link className="active" to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/feedback-positive`}>Feedback Positive</Link>
              <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/positive-questions`}>Questions</Link>
              <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/positive-otas`}>OTAs</Link>
            </div>
            {/*<div className="titleBox">
              <h4>Feedback Positive{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
            </div>*/}
          </div>
          <div className="contentBox">
            <div className="left">
              { renderOtas(feedback_settings) }
            </div>
            <div className="right">
              { renderPreview(feedback_settings, engage_settings, location_info) }
            </div>
          </div>
        </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('FeedbackPositive props: ', props);
  }

  const { locationInfo } = props;
  const { reputation, engage, info } = props.settings;
  if (!reputation) return null;
  const { feedback_settings } = reputation;

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbPosPg">
        { renderContent(feedback_settings, engage || {}, locationInfo) }
      </div>
    </div>
  );

}

export default FeedbackPositive;
