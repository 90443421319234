// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import queryString from 'query-string';
import copy from 'copy-to-clipboard';
import Highlighter from "react-highlight-words";
import Interweave from 'interweave';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../redux/actions/snacksActions';
import { formatDate, isGTUser } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import moreIcon from '../../../../img/icons/more.svg';
import editIcon from '../../../../img/icons/edit.svg';
import searchIcon from '../../../../img/icons/search.svg';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../Misc/GenericAlertModal';

import './style.css';


const modalRoot = document.getElementById('modal-root-rto');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function UserRow(props) {
  const onCheckBoxChange = (e) => {
    props.onCheckBoxChange(props.data, !props.isSelected);
  };
  const onRowClick = (e) => {
    props.onCheckBoxChange(props.data, !props.isSelected);
  };
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('UserRow: ', props.data._id, ' # ', props.isSelected);
  // }
  return (
    <div className="lRow" id={`g_l_${props.data_id}`} onClick={onRowClick}>
      <div className="checkBox">
        <input type="checkBox" onChange={onCheckBoxChange} checked={props.isSelected} />
      </div>
      <div className="right">
        <p className="name">{props.data.username}</p>
        <p className="address">{props.data.profile.first_name || ''} {props.data.profile.last_name || ''}</p>
      </div>
    </div>
  )
}

function ManageGroupSharing(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const groupState = useSelector((state) => state.groupReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');
  const [editGroupNameEnabled, setEditGroupNameEnabled] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const [filteredList, setFilteredList] = useState([]);
  const [filteredListMap, setfilteredListMap] = useState({});

  const [someChange, setSomeChange] = useState(false);

  const groupUpdated = useRef(false);

  const groupNameRef = useRef(null);

  const clearStatus = () => {
    const params = {};
    params.group_id = props.groupData._id;
    dispatch(editDataClearAction({
      op: 'share_group',
      params,
    }));
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('ManageGroupSharing useEffect props.groupData: ', props.groupData);
    }

    const params = {};
    params.group_id = props.groupData._id;
    dispatch(editDataClearAction({
      op: 'share_group',
      params,
      // show_alert: true,
      // alert_msg: 'Updated successfully',
    }));

    const { share_data } = props.groupData;
    const { users } = groupState.groups;
    if (users) {
      if (users.length) {
        setFilteredList(users.map(el => el));
        let tFilteredListMap = {};
        users.forEach((el) => {
          let isSelected = false;
          if (share_data && el.username && share_data[el.username] && share_data[el.username].is_shared) {
            isSelected = true;
          }
          tFilteredListMap[el._id] = { value: isSelected, data: el };
        });
        setfilteredListMap(tFilteredListMap);
      } else {
        setFilteredList([]);
      }
    }
  }, []);

  useEffect(() => {
    const { groups } = groupState;
    const groupId = props.groupData._id;
    if (groupId && groups && groups.keyValue && groups.keyValue[groupId] &&
      !groups.keyValue[groupId].editShareInProgress &&
      groups.keyValue[groupId].editShareStatus && groups.keyValue[groupId].editShareStatus.success) {
      setSomeChange(false);
      // const params = {};
      // params.group_id = props.groupData._id;
      // dispatch(editDataClearAction({
      //   op: 'share_group',
      //   params,
      //   // show_alert: true,
      //   // alert_msg: 'Updated successfully',
      // }));
    }
  }, [groupState.groups]);

  useEffect(() => {
    const { users } = groupState.groups;

    let userList = [];
    userList = users && users.length ? users : [];
    if (userList.length) {
      if (searchKeyword && searchKeyword.length > 2) {
        let tempList = [];
        userList.forEach((usr) => {
          if (new RegExp(searchKeyword, 'i').test(usr.username) ||
            new RegExp(searchKeyword, 'i').test(usr.username.first_name) ||
            new RegExp(searchKeyword, 'i').test(usr.username.last_name)
          ) {
            tempList.push(usr);
          }
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log('tempList: ', tempList);
        }
        setFilteredList(tempList);
      } else {
        setFilteredList(userList);
      }
    }
  }, [searchKeyword]);

  const onCloseClick = (e) => {
    props.onCancelClick(e);
  };

  const onKeywordChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchKeywordKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  const selectAllUsers = () => {
    let tFilteredListMap = {};
    filteredList.forEach((el) => {
      tFilteredListMap[el._id] = { value: true, data: el };
    });
    setfilteredListMap(tFilteredListMap);
  };
  const clearAllUsers = () => {
    let tFilteredListMap = {};
    setfilteredListMap(tFilteredListMap);
  };
  const onSelectAllChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectAllChange: ', name, ' # ', value);
      console.log('onSelectAllChange selectAll: ', selectAll);
    }
    if (!selectAll) {
      selectAllUsers();
    } else {
      clearAllUsers();
    }
    setSelectAll(selectAll => !selectAll);
    setSomeChange(true);
    clearStatus();
  };
  const onSelectAllLabelClick = (e) => {
    if (!selectAll) {
      selectAllUsers();
    } else {
      clearAllUsers();
    }
    setSelectAll(selectAll => !selectAll);
    setSomeChange(true);
    clearStatus();
  };

  const onUserCheckBoxChange = (userData, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onUserCheckBoxChange userId: ', userData, ' # value: ', value);
    }
    let tempMap = { ...filteredListMap };
    tempMap[userData._id] = { value, data: userData };
    setfilteredListMap(tempMap);
    setSomeChange(true);
    clearStatus();
  };


  const onCtaClick = (e) => {
    const { groups } = groupState;
    const { users } = groupState.groups;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick groupName: ', groupName);
      console.log('onCtaClick filteredListMap: ', filteredListMap);
    }
    let hasError = false;


    // for update
    if (!someChange) return;
    if (!props.groupData || !props.groupData._id) return;

    const groupId = props.groupData._id;
    if (groups.keyValue && groups.keyValue[groupId] && groups.keyValue[groupId].editShareInProgress) return;

    if (hasError) {
      return;
    }

    let userList = [];
    userList = Object.keys(filteredListMap).filter(el => filteredListMap[el]).map((el) => {
      return {
        ...filteredListMap[el],
        // _id: el,
        // name: locations[el].name,
      };
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick userList: ', userList);
    }
    const params = {};
    params.group_id = props.groupData._id;
    params.group_name = props.groupData.name;
    params.list = userList;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'share_group',
      params,
      // show_alert: true,
      // alert_msg: 'Updated successfully',
    }));
  };

  const renderHeaderBox = () => {
    return (
      <>
        <div className="left">
          {props.groupData.name}
        </div>
        <div className="right">
          <span onClick={onCloseClick}><img src={closeIcon} />Close</span>
        </div>
      </>
    );
  };

  const renderBodyBox = (groups) => {
    const { users } = groups;
    return (
      <>
        <div className="searchBox">
          <p>Search users</p>
          <div className="inputBox">
            <img src={searchIcon} />
            <input type="text" name="keyword" value={searchKeyword} onChange={onKeywordChange}
              placeholder="Search User"
              onKeyDown={onSearchKeywordKeyDown}
            />
          </div>
        </div>
        <div className="selAllBox">
          <input type="checkbox" name="selectAll" onChange={onSelectAllChange} value={selectAll ? 'on' : 'off'} checked={selectAll} />
          <div className="gLbl" onClick={onSelectAllLabelClick}>
            {selectAll ? 'Clear All' : 'Select All'}
          </div>
        </div>
        <div className="usersListBox">
          {
            filteredList.length
            ?
              filteredList.map((usr) => {
                return (
                  <UserRow data={usr} user={userState.user}
                    key={`gur_${usr._id}`}
                    onCheckBoxChange={onUserCheckBoxChange}
                    isSelected={filteredListMap[usr._id] ? filteredListMap[usr._id].value : false}
                  />
                )
              })
            : null
          }
        </div>
      </>
    );
  };


  const renderBottomBox = (groups) => {
    const groupId = props.groupData._id;
    let ctaLabel = 'Save Changes';
    let inProgress = groups.keyValue && groups.keyValue[groupId] ? groups.keyValue[groupId].editShareInProgress || false : false;
    let curStatus = '';
    if (groups.keyValue && groups.keyValue[groupId] && groups.keyValue[groupId].editShareStatus) {
      if (groups.keyValue[groupId].editShareStatus.success) curStatus = 'Updated successfully';
      if (groups.keyValue[groupId].editShareStatus.error) curStatus = 'Error in updating';
    }

    return (
      <>
        <div className="middle">
          {curStatus}
        </div>
        <div className="right">
          <span className={'cta' + (someChange ? '' : ' inactive')} onClick={onCtaClick}>
            { ctaLabel }
            { inProgress && <span className="inProgress"><i className="fa fa-spinner fa-spin" /></span> }
          </span>
        </div>
      </>
    );
  };

  const renderContent = () => {
    const { groups } = groupState;
    const cStyle = {};
    cStyle.height = windowState.window.windowHeight - 72 - 70;
    return (
      <div className="innerBox">
        <div className="headerBox">
          { renderHeaderBox() }
        </div>
        <div className="bodyBox" style={cStyle}>
          { renderBodyBox(groups) }
        </div>
        <div className="bottomBox">
          { renderBottomBox(groups) }
        </div>
      </div>
    );
  };





  if (process.env.NODE_ENV !== 'production') {
    console.log('ManageGroupSharing props: ', props);
    console.log('ManageGroupSharing filteredList: ', filteredList);
    console.log('ManageGroupSharing filteredListMap: ', filteredListMap);
  }

  return (
    <div className="gCard manageGroup shareGroup">
        { renderContent() }
    </div>
  );
}

export default ManageGroupSharing;
