import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';

import { ruleTypeToLabel, RULE_TYPES } from '../../../../../../helpers';

import editIcon from '../../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../../img/icons/delete.svg';

import '../style.css';

function RWRuleRow(props) {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    setToggleOn(props.template.is_enabled || false);
  }, []);

  useEffect(() => {
    if (props.template && !props.template.editInProgress && props.template.editStatus && props.template.editStatus.error) {
      setToggleOn(props.template.is_enabled || false);
    }
  }, [props.template]);

  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    if (props.template.editInProgress) return;
    props.onDeleteClick(props.template);
  };
  const onToggleChange = (e) => {
    if (props.template.editInProgress) return;
    const { name, checked } = e.target;
    setToggleOn(checked);
    props.onEnableDisableClick({
      ...props.template,
      is_enabled: checked,
    });
  };

  const { template, settings } = props;
  if (!template) return null;
  let locNames = '', locNamesList = [];
  try {
    if (template.locations && settings.reviews_widget && settings.reviews_widget.reviews_widget_all_locations) {
      template.locations.forEach((el) => {
        let found = settings.reviews_widget.reviews_widget_all_locations.find(x => x.location_id === el.location_id);
        if (found) locNamesList.push(found.name);
      });
    }
    locNames = locNamesList.length ? locNamesList.join(',') : '-';
  } catch (e) {}


  return (
    <div className="gBCtRw ruleRw" id={`rm_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.name}
              />
            : template.name
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable
            ?
            <>
              <div className="gToggleWrap">
                <div className="gToggle">
                  <input type="checkbox" id={template._id} checked={toggleOn} onChange={onToggleChange}/><label htmlFor={template._id}>Toggle</label>
                </div>
              </div>
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
                <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
              </ToolTipWrapper>
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
                <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
              </ToolTipWrapper>
            </>
            :
            <>
              { toggleOn && <span>Active</span> }
            </>
          }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          <p>Web Address: {template.url}</p>
          <p>Locations: {locNames}</p>
          <p>Number of reviews to show: {template.number_of_reviews}</p>
        </div>
      </div>
    </div>
  )

}

export default RWRuleRow;
