import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import { MSGTYPE_TO_LABEL } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';

import '../../style.css';

const roomAttrOptionsG = [];
roomAttrOptionsG.push({ label: 'Room Number', value: 'room-number' });
roomAttrOptionsG.push({ label: 'Room Type', value: 'room-type' });

const getRoomLabel = (room) => {
  if (!room || !room.attr) return '';
  if (room.attr['room-number']) {
    return `${room.label} - Room Number(${room.attr['room-number']})`;
  }
  if (room.attr['room-type']) {
    return `${room.label} - Room Type(${room.attr['room-type']})`;
  }
  return '';
};

function JMRuleSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState('');

  const [roomOptions, setRoomOptions] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [selectedRoomError, setSelectedRoomError] = useState('');

  const [currentStatus, setCurrentStatus] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const labelRef = useRef(null);

  useEffect(() => {
    const { rooms } = props.settings;
    if (process.env.NODE_ENV !== 'production') {
      console.log('rooms: ', rooms);
    }
    if (props.rule && props.rule._id) {
      setLabel(props.rule.label);
      if (rooms && rooms.list && rooms.list.length && rooms.keyValue) {
        const foundRoom = rooms.keyValue[props.rule.room_id];
        if (foundRoom) {
          setSelectedRoom({
            label: getRoomLabel(foundRoom), value: foundRoom._id,
          });
        }
      }
    }

    if (rooms && rooms.list && rooms.list.length) {
      let tOptions = [];
      rooms.list.forEach((el) => {
        if (rooms.keyValue && rooms.keyValue[el] && rooms.keyValue[el].attr) {
          tOptions.push({ label: getRoomLabel(rooms.keyValue[el]), value: el });
        }
      });
      setRoomOptions(tOptions);
    }
    setSomeChange(false);
  }, []);

  useEffect(() => {
    if (props.settings.engage.journey_rules) {
      if (props.mode === 'add') {
        if (process.env.NODE_ENV !== 'production') {
          console.log('JMRuleSingle props change: ', props.settings.engage.journey_rules);
        }
        if (!props.settings.engage.journey_rules.addInProgress &&
          props.settings.engage.journey_rules.addStatus &&
          props.settings.engage.journey_rules.addStatus.success
        ) {
          setLabel('');
          setSelectedRoom({});
          setSomeChange(false);
        }
      }
      if (props.mode === 'edit') {
        if (props.rule && props.rule._id &&
          props.settings.engage.journey_rules.keyValue &&
          props.settings.engage.journey_rules.keyValue[props.rule._id] &&
          !props.settings.engage.journey_rules.keyValue[props.rule._id].editInProgress &&
          props.settings.engage.journey_rules.keyValue[props.rule._id].editStatus &&
          props.settings.engage.journey_rules.keyValue[props.rule._id].editStatus.success
        ) {
          setSomeChange(false);
        }
      }
    }
  }, [props.settings.engage.journey_rules]);



  const onLabelChange = (e) => {
    const { name, value } = e.target;
    setLabel(value); setSomeChange(true);
    // props.onAddEditClear(props.template);
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.mode,
        rule_id: props.rule && props.rule._id ? props.rule._id : undefined,
      },
    }));
    if (!value) {
      setLabelError('error');
    } else {
      setLabelError('');
    }
  };

  const onSelectedRoomChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectedRoomChange: ', value);
    }
    setSelectedRoom(value);
    setSelectedRoomError('');
    setSomeChange(true);
  };


  const onCloseClick = (e) => {
    // dispatch(editDataClearAction({
    //   op: 'add_whatsapp_message_template',
    //   params: {
    //     location__id: urlParams.locationidentifier
    //   },
    // }));
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    // dispatch(editDataClearAction({
    //   op: 'add_whatsapp_message_template',
    //   params: {
    //     location__id: urlParams.locationidentifier
    //   },
    // }));
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    const { journey_rules } = props.settings.engage;
    if (journey_rules.addInProgress) return;
    if (props.mode === 'edit') {
      if (props.rule && props.rule._id) {
        if (journey_rules.keyValue && journey_rules.keyValue[props.rule._id] && journey_rules.keyValue[props.rule._id].editInProgress) return;
      }
    }

    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.mode,
        rule_id: props.rule && props.rule._id ? props.rule._id : undefined,
      },
    }));

    setLabelError(label ? '' : 'error');
    if (!selectedRoom.value) {
      setSelectedRoomError('Room is required.');
    } else {
      setSelectedRoomError('');
    }

    if (!label || !selectedRoom.value) return;

    if (someChange) {
      if (props.mode === 'add') {
        dispatch(editDataAction({
          op: 'edit_guest_journey_rule',
          show_error_alert: false,
          params: {
            location__id: urlParams.locationidentifier,
            mode: props.mode,
            label,
            room_id: selectedRoom.value,
          },
        }));
      }
      if (props.mode === 'edit') {
        dispatch(editDataAction({
          op: 'edit_guest_journey_rule',
          show_error_alert: false,
          params: {
            location__id: urlParams.locationidentifier,
            mode: props.mode,
            rule_id: props.rule._id,
            label,
            room_id: selectedRoom.value,
          },
        }));
      }

      // if (title && message) {
      //   const params = {
      //     title,
      //     msg: message,
      //   };
      //   if (props.messageType === 'preArrivalMessage') params.type = 'pre_arrival_msg';
      //   if (props.messageType === 'welcomeMessage') params.type = 'welcome_msg';
      //   if (props.messageType === 'repeatWelcomeMessage') params.type = 'repeat_welcome_msg';
      //   if (props.messageType === 'departureMessage') params.type = 'departure_msg';
      //   dispatch(editDataAction({
      //     op: 'add_whatsapp_message_template',
      //     show_error_alert: false,
      //     params: {
      //       location__id: urlParams.locationidentifier,
      //       ...params,
      //     },
      //   }));
      // }
    }
  };

  const renderStatus = () => {
    if (!currentStatus) return null;
    return (
      <div className="gBCtRw curSts">
        <p className="error">
          {
            currentStatus === 'error'
            ? 'Check above errors!'
            : currentStatus
          }
        </p>
      </div>
    );
  };

  const renderContent = () => {
    const { locationidentifier } = urlParams;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.engage) return null;


    let pageTitle = 'Edit Rule';
    if (props.mode === 'add') {
      pageTitle = `Add Rule`;
    }

    let addEditStatus = props.settings.engage.journey_rules ? props.settings.engage.journey_rules.addStatus || {} : {};
    if (props.mode === 'edit') addEditStatus = props.settings.engage.journey_rules && props.settings.engage.journey_rules.keyValue &&
      props.settings.engage.journey_rules.keyValue[props.rule._id] ? props.settings.engage.journey_rules.keyValue[props.rule._id].editStatus || {} : {};

    let addEditInProgress = props.settings.engage.journey_rules ? props.settings.engage.journey_rules.addInProgress || false : false;
    if (props.mode === 'edit') addEditInProgress = props.settings.engage.journey_rules && props.settings.engage.journey_rules.keyValue &&
      props.settings.engage.journey_rules.keyValue[props.rule._id] ? props.settings.engage.journey_rules.keyValue[props.rule._id].editInProgress || false : false;

    return (
      <div className="gPWrp">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gPSubTle">
          <p>Rule will be applied on guests whose room number / type matches with  this rule and below guest journey messages will be sent.</p>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Name
              </div>
              <div className={'gIpt ' + (labelError ? 'error' : '')}>
                <input type="text" value={label} onChange={onLabelChange} placeholder="Label" ref={labelRef}
                />
                { labelError && <p className="gErr">Label is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw">
            <div className="gRwBxLt personalizeBox">
              <label>Choose room</label>
              {/*<Select
                options={personalizeOptions}
                labelField="text"
                valueField="value"
                values={personalizeValues}
                onChange={onPersonalizeChange}
              />*/}
              <Select
                options={roomOptions}
                onChange={onSelectedRoomChange}
                value={selectedRoom}
              />
              { selectedRoomError && <p className="gErr">{selectedRoomError}</p> }
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <p className="gErr">{props.addEditStatus.message || 'Error!'}</p>
              }
              {
                addEditStatus.success && props.mode === 'edit' && <p className="success">Updated successfully</p>
              }
              {
                addEditStatus.success && props.mode === 'add' && <p className="success">Added successfully</p>
              }
            </div>
          }
          { renderStatus() }
          <div className="gBCtRw btnRw">
            {
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
                { props.mode === 'add' && 'Add' }
                { props.mode === 'edit' && 'Save Changes' }
                { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            }
            <button className="gBtn cancel" onClick={onCloseClick}>Close</button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('JMRuleSingle props: ', props);
  }

  return renderContent();
}

export default JMRuleSingle;
