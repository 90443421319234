import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import NotAllowed from '../components/NotAllowed';
import SignUp from '../components/SignUp';
import Login from '../components/Login';
import Logout from '../components/Logout';
import Wrapper from '../components/Wrapper';

const gtconfig = require('../gtconfig');

const routes = (
  <BrowserRouter>
    <Routes>
      <Route path="/not-allowed" element={<NotAllowed />} />
      {
        (gtconfig.APP_ID === 'gt-unified-rep-only-live' ||
        gtconfig.APP_ID === 'gt-unified-dev' ||
        gtconfig.APP_ID === 'gt-unified-app-test') &&
        <Route path="/signup" element={<SignUp />} />
      }
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/*" element={<Wrapper />} />
    </Routes>
</BrowserRouter>
);

export default routes;
