import { genericItemTypeToKeyName } from '../../helpers';

/***************************************** Get Webchat Settings ****************************/

export const getGbmSettings = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: state.locations[location__id].settings.gbm
          ?
            {
              ...state.locations[location__id].settings.gbm,
              getInProgress: true, getStatus: {},
            }
          : {
              getInProgress: true, getStatus: {},
            }
        },
      },
    },
  };
};

export const getGbmSettingsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { gbm_settings } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: {
            ...state.locations[location__id].settings.gbm,
            ...gbm_settings,
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getGbmSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: {
            ...state.locations[location__id].settings.gbm,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};



/* ********************** Start - edit gbm settings ******************* */

export const editGbmSettingsClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: state.locations[location__id].settings.gbm
          ? {
              ...state.locations[location__id].settings.gbm,
              editInProgress: false,
              editStatus: {},
            }
          : {
            editInProgress: false,
            editStatus: {},
            },
        },
      },
    },
  };
};

export const editGbmSettings = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: {
            ...state.locations[location__id].settings.gbm,
            editInProgress: true,
            editStatus: {},
          },
        },
      },
    },
  };
};

export const editGbmSettingsSuccess = (state, action) => {
  const { data, params } = action.payload;
  if (!params) return state;
  const { location__id, ...restParams } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: {
            ...state.locations[location__id].settings.gbm,
            ...restParams,
            editInProgress: false,
            editStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const editGbmSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          gbm: {
            ...state.locations[location__id].settings.gbm,
            editInProgress: false,
            editStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};

/* ********************** End - edit gbm settings ******************* */
