import React, {Component, useEffect, useRef, useState} from 'react';

import './style.css';

const tempSplit = [
    { 'ota': '5 Star', 'source': 2, 'count': 10, 'percent': '10', 'color': 'grey' },
    { 'ota': '4 Star', 'source': 2, 'count': 20, 'percent': '20', 'color': 'yellow' },
    { 'ota': '3 Star', 'source': 2, 'count': 30, 'percent': '30', 'color': 'orange' },
    { 'ota': '2 Star', 'source': 2, 'count': 30, 'percent': '30', 'color': 'blue' },
    { 'ota': '1 Star', 'source': 2, 'count': 10, 'percent': '10', 'color': 'lightgreen' },
];

function ItemBox(props) {
  const { star, count, percent, color } = props.data;
  const cStyle = {
    backgroundColor: color,
  }
  cStyle.backgroundColor = '#FFFFFF';
  if (props.selected) cStyle.backgroundColor = '#EEEEEE';
  return (
    <div className="itemBoxWrap" style={cStyle}
    >
      <div className="dot" style={{backgroundColor:color}}></div>
      <div className="ota">{star}</div>
      <div className="percent">{percent}%</div>
      <div className="count">{count}</div>
    </div>
  );
}

function AnswerRatingChart(props) {
  const { ratings, question } = props.data;
  return (
    <div className="ansRatChrt">
      { question && <div className="qns">{question}</div> }
      { ratings &&
        <div className="labelsWrap">
          {
            ratings.map((el) => {
              return <ItemBox data={el}
              // selected={el.source === selectedWedge || el.source === selectedLabel}
                // onClick={onLabelClick}
              />
            })
          }
        </div>
      }
    </div>
  );
}

export default AnswerRatingChart;
