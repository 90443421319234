// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import SentEmailPreviewModal from '../../../Misc/SentEmailPreviewModal';
import TransactionEmailTaskDetailModal from './TransactionEmailTaskDetailModal';
import SelectTransactionEmailModal from './SelectTransactionEmailModal';

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../Misc/OutsideClickHook';

import { ENGAGE_FEATURES, hasAccessToFeature } from '../../../../helpers';
import { formatDate, formatDateStrToStr, getSearchGuestTypeByTransactionType,
  TRANSACTION_EMAIL_RSRV_TYPE_TO_LABEL, TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP,
  COUNTRY_LIST_KEYS, DEVICE_TYPE_CODE_TO_LABEL, TRANSACTION_EMAIL_TYPE_TO_LABEL } from '../../../../helpers';

import moreIcon from '../../../../img/icons/more-horizontal.svg';

import './style.css';


const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SingleTransactionEmailHistoryBox(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  let location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const cMenuRef = useRef();
  useOnClickOutside(cMenuRef, useCallback(() => closeMenu()));

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [showTaskDetailModal, setShowTaskDetailModal] = useState(false);
  const [showSelectTransactionEmailModal, setShowSelectTransactionEmailModal] = useState(false);

  const [showResendModal, setShowResendModal] = useState(false);

  const [selectedTransactionEmailTemplate, setSelectedTransactionEmailTemplate] = useState({});
  const [selectedTransactionEmailTask, setSelectedTransactionEmailTask] = useState({});

  const [rowSelected, setRowSelected] = useState(false);

  useEffect(() => {
    const { history } = props.transactionEmails;
    if (history && history.keyValue && props.transactionEmailData &&
      props.transactionEmailData._id &&
      history.keyValue[props.transactionEmailData._id] &&
      !history.keyValue[props.transactionEmailData._id].resendInProgress &&
      history.keyValue[props.transactionEmailData._id].resendStatus &&
      history.keyValue[props.transactionEmailData._id].resendStatus.success
    ) {
      setShowResendModal(false);
    }
  }, [props.transactionEmails.reservations]);

  useEffect(() => {
    const { reservations } = props.transactionEmails;
    if (reservations && reservations.keyValue &&
      reservations.keyValue[props.reservationData._id] &&
      !reservations.keyValue[props.reservationData._id].sendCustomInProgress &&
      reservations.keyValue[props.reservationData._id].sendCustomStatus &&
      reservations.keyValue[props.reservationData._id].sendCustomStatus.success
    ) {
      props.onRowSendIsActive(false);
      setShowSelectTransactionEmailModal(false);
    }
  }, [props.transactionEmails.reservations]);

  useEffect(() => {
    setRowSelected(props.rowIsSelected);
  }, [props.rowIsSelected]);


  const closeMenu = (e) => {
    setMenuOpen(false);
  };
  const onOpenMenuClick = (e) => {
    setMenuOpen(true);
  };
  const onDetailClick = (e) => {
    setShowTaskDetailModal(true);
  };

  const onPreviewOpenClick = (data) => {
    setSelectedTransactionEmailTask(data);
    setShowEmailPreviewModal(true);
    // analytics reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Marketing - Transaction Emails - Reservations - Single - Preview - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onEmailPreviewCloseModal = (e) => {
    setShowEmailPreviewModal(false);
  };
  const onEmailPreviewConfirmClick = (data) => {
    setShowEmailPreviewModal(false);
  };

  const onTaskDetailModalCloseClick = (e) => {
    setShowTaskDetailModal(false);
  }

  const onSelectTransactionEmailCloseModal = (e) => {
    setShowSelectTransactionEmailModal(false);
  };
  const onSelectTransactionEmailConfirmClick = (transactionEmail) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectTransactionEmailConfirmClick transactionEmail: ', transactionEmail);
    }
    if (props.isReadOnly) return;
    if (props.reservationData.sendCustomInProgress) return false;
    if (transactionEmail && transactionEmail._id) {
      setSelectedTransactionEmailTemplate(transactionEmail);
      dispatch(editDataAction({
        op: 'send_custom_transaction_email',
        params: {
          location__id: urlParams.locationidentifier,
          transaction_email_id: transactionEmail._id,
          guest_id: props.reservationData._id,
        },
        show_alert: true,
        alert_msg: 'Sent successfully',
      }));
      // props.onRowSendIsActive(true);
    }
  };
  const onCustomEmailClick = (transactionEmail) => {
    setShowSelectTransactionEmailModal(true);

    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('onCustomEmailClick transactionEmail: ', transactionEmail);
    // }
    // if (props.isReadOnly) return;
    // if (props.reservationData.sendCustomInProgress) return false;
    // if (transactionEmail && transactionEmail._id) {
    //   setSelectedTransactionEmailTemplate(transactionEmail);
    //   dispatch(editDataAction({
    //     op: 'send_custom_transaction_email',
    //     params: {
    //       location__id: urlParams.locationidentifier,
    //       transaction_email_id: transactionEmail._id,
    //       guest_id: props.reservationData._id,
    //     },
    //     show_alert: true,
    //     alert_msg: 'Sent successfully',
    //   }));
    //   props.onRowSendIsActive(true);
    // }
  };

  const onResendClick = (e) => {
    if (props.isReadOnly) return;
    dispatch(editDataClearAction({
      op: 'resend_transaction_email',
      params: {
        location__id: urlParams.locationidentifier,
        transaction_email_task_id: props.reservationData._id,
      },
    }));
    setShowResendModal(true);
  };
  const onResendConfirmClick = (e) => {
    dispatch(editDataAction({
      op: 'resend_transaction_email',
      params: {
        location__id: urlParams.locationidentifier,
        transaction_email_task_id: props.reservationData._id,
      },
      show_alert: true,
      alert_msg: 'Sent successfully',
    }));
  };

  const onSelectRowChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectRowChange: ', value);
    }
    setRowSelected(rowSelected => !rowSelected);
    props.onRowSelect(props.reservationData);
    // if (!checkInNow) {
    //   setForm(form => ({
    //     ...form,
    //     roomNumberError: form.roomNumber ? '' : 'Room number is required.',
    //   }));
    // } else {
    //   setForm(form => ({
    //     ...form,
    //     roomNumberError: '',
    //   }));
    // }
    // setCheckInNow(checkInNow => !checkInNow);
    // if (value === 'off') {
    //   setCheckInDate(new Date());
    //   setCheckInTime(new Date());
    // }
    // setCheckInNowChanged(true);
    // setSomeChange(true);
    // clearCurrentStatus();
  };

  const getEmailStatus = (reservationData) => {
    let result = {};
    result.status = 'sent';
    result.statusLabel = 'Sent';
    const { tracking } = reservationData;
    if (tracking && tracking.date_email_status_bounce) {
      result.status = 'bounce';
      result.statusLabel = 'Bounced';
      return result;
    }
    if (reservationData.date_email_status_click_tz) {
      result.date = reservationData.date_email_status_click_tz;
      result.status = 'click';
      result.statusLabel = 'Clicked';
      if (tracking && tracking.event_data_click) {
        if (tracking.event_data_click.ip_country) result.ipCountry = tracking.event_data_click.ip_country;
        if (tracking.event_data_click.is_desktop) result.device = 'desktop';
        if (tracking.event_data_click.is_mobile) result.device = 'mobile';
        if (tracking.event_data_click.is_tablet) result.device = 'tablet';
      }
      return result;
    }
    if (reservationData.date_email_status_open_tz) {
      result.date = reservationData.date_email_status_open_tz;
      result.status = 'open';
      result.statusLabel = 'Opened';
      if (tracking && tracking.event_data_open) {
        if (tracking.event_data_open.ip_country) result.ipCountry = tracking.event_data_open.ip_country;
        if (tracking.event_data_open.is_desktop) result.device = 'desktop';
        if (tracking.event_data_open.is_mobile) result.device = 'mobile';
        if (tracking.event_data_open.is_tablet) result.device = 'tablet';
      }
      return result;
    }
    if (reservationData.date_email_status_delivery_tz) {
      result.date = reservationData.date_email_status_delivery_tz;
      result.status = 'delivery';
      result.statusLabel = 'Delivered';
      return result;
    }
    return result;
  }

  const renderTaskDetailModal = () => {
    const info = props.reservationData;
    return (
      <Modal>
        <div id="cModelId" className="cModal mtModal">
          <div className="content">
            <TransactionEmailTaskDetailModal onCancelClick={onTaskDetailModalCloseClick} reservationData={props.reservationData}
              type="taskDetail"
              locationMId={props.locationMId}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderOverflowMenu = () => {
    const { transaction_type } = props.reservationData;
    const { transaction_emails } = props.transactionEmails;
    return (
      <div className="oMenu">
        <div className="dropdown" ref={cMenuRef}>
          <button className="dropbtn" onClick={onOpenMenuClick} ><img src={moreIcon}/></button>
          <div className={'dropdown-content ' + (menuOpen ? 'open ' : '')}>
            <p onClick={onDetailClick}>Details</p>
            <p onClick={onCustomEmailClick}>Send email</p>
            {/*
              transaction_emails && transaction_emails.list && transaction_emails.keyValue &&
              transaction_emails.list.length
              ?
                transaction_emails.list.map((item) => {
                  if (transaction_emails.keyValue[item] &&
                    (
                      (props.reservationData.transaction_type === 'Reservation' &&
                        (transaction_emails.keyValue[item].type === 'booking' ||
                          transaction_emails.keyValue[item].type === 'pre-arrival' ||
                          transaction_emails.keyValue[item].type === 'custom'
                        )
                      ) ||
                      (props.reservationData.transaction_type === 'CheckIn' &&
                        (transaction_emails.keyValue[item].type === 'check-in' ||
                          transaction_emails.keyValue[item].type === 'custom'
                        )
                      ) ||
                      (props.reservationData.transaction_type === 'CheckOut' &&
                        (transaction_emails.keyValue[item].type === 'check-out' ||
                          transaction_emails.keyValue[item].type === 'custom'
                        )
                      )
                    )
                  ) {
                    let ln1 = 'Send';
                    ln1 = `${ln1} email`;
                    let ln2 = `Subject: ${transaction_emails.keyValue[item].subject}`;
                    return (
                      <p onClick={() => onCustomEmailClick(transaction_emails.keyValue[item])}>
                        <span>Send <b>{TRANSACTION_EMAIL_TYPE_TO_LABEL[transaction_emails.keyValue[item].type]}</b> email</span>
                        <span>{ln2}</span>
                        {
                          selectedTransactionEmailTemplate &&
                          selectedTransactionEmailTemplate._id === item &&
                          props.reservationData.sendCustomInProgress &&
                          <span>
                            <i className="fa fa-spinner fa-spin" />
                          </span>
                        }
                      </p>
                    );
                  }
                  return null;
                })
              : null
            */}
            {/*
              transaction_type === 'Reservation' &&
              <p onClick={onCustomEmailClick}>Send Some Custom Email</p>
            */}
            {/*<p onClick={onPreviewOpenClick}>Preview</p>
          { !props.isReadOnly && <p onClick={onResendClick}>Resend</p> }*/}
          </div>
        </div>
      </div>
    );
  };

  const renderEmailStatus = (reservationData) => {
    const emailStatus = getEmailStatus(reservationData);
    return (
      <div className={'sentStats ' + (emailStatus.status)}>
        <div className="line1">
          <div className="emailStatusIndicator"></div><span>{emailStatus.statusLabel}</span>
        </div>
        <div className="line2">
          { emailStatus.date && <div className="dateStr">{emailStatus.date}</div> }
        </div>
        <div className="line3">
          { emailStatus.ipCountry &&
            <ToolTipWrapper placement="left" overlayClassName={'sbTooltip'}
              overlay={COUNTRY_LIST_KEYS[emailStatus.ipCountry]}
            >
              <div className="ipCountry">{emailStatus.ipCountry}</div>
            </ToolTipWrapper>
          }
          { emailStatus.device &&
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={DEVICE_TYPE_CODE_TO_LABEL[emailStatus.device]}
            >
              <div className="device"><span><i className={`fas fa-${emailStatus.device}-alt`}></i></span></div>
            </ToolTipWrapper>
          }
        </div>
      </div>
    );
  }

  const renderContent = () => {
    const { reservationData } = props;
    const checkInDateStr = formatDateStrToStr(reservationData.check_in_date_local_str) || formatDate(reservationData.check_in_date);
    const checkOutDateStr = formatDateStrToStr(reservationData.check_out_date_local_str) || formatDate(reservationData.check_out_date);
    let customLabel = 'Default';
    if (reservationData && reservationData.data && reservationData.data.is_custom && reservationData.data.custom_for) {
      customLabel = `Custom - ${TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP[reservationData.data.custom_for]}`;
      if (reservationData.data.rate_code_info && reservationData.data.rate_code_info.label) {
        customLabel = `${customLabel} - ${reservationData.data.rate_code_info.label}`;
      }
      if (reservationData.data.custom_for === 'booking-source' && reservationData.data.custom_value && reservationData.data.custom_value.length) {
        customLabel = `${customLabel} - ${reservationData.data.custom_value.reduce((prev, cur, curIndex) => { if (curIndex === reservationData.data.custom_value.length-1) { return prev + cur.label; } else { return prev + cur.label  + ' / '; }}, '')}`;
      }
    }
    const transactionEmailBookingSentDateStr = reservationData.test_date_transaction_email_booking_processed ? formatDate(reservationData.test_date_transaction_email_booking_processed) : '';
    const transactionEmailPreArrivalSentDateStr = reservationData['test_date_transaction_email_pre-arrival_processed'] ? formatDate(reservationData['test_date_transaction_email_pre-arrival_processed']) : '';
    const transactionEmailCheckInSentDateStr = reservationData['test_date_transaction_email_check-in_processed'] ? formatDate(reservationData['test_date_transaction_email_check-in_processed']) : '';
    const transactionEmailCheckOutSentDateStr = reservationData['test_date_transaction_email_check-out_processed'] ? formatDate(reservationData['test_date_transaction_email_check-out_processed']) : '';

    return (
      <div className={'singleBox' + (rowSelected ? ' selected' : '')} id={`bh_${reservationData._id}`}>
        <div className="partOne">
          <div className="chkBx">
            <input type="checkbox" name="selectRow" onChange={onSelectRowChange} value={rowSelected ? 'on' : 'off'} checked={rowSelected} />
          </div>
          <div className={'type '+ (reservationData.transaction_type)}>
            <span>
              {TRANSACTION_EMAIL_RSRV_TYPE_TO_LABEL[reservationData.transaction_type]}
            </span>
          </div>
          <div className="name">
            {reservationData ? reservationData.first_name || '' : ''}
            {reservationData ? ` ${reservationData.last_name || ''}` : ''}
            <br/>
            <span className="email">{reservationData.email || ''}</span>
          </div>
          <div className="stay">
            {reservationData ? `${checkInDateStr} - ${checkOutDateStr}` : ''}
          </div>
        </div>
        {/*<div className="partTwo">
          <div className="emailTemplate">
            {transactionEmailData.sent_email ? transactionEmailData.sent_email.email_template_name || '' : ''}
          </div>
          <div className="custom">
            {customLabel}
          </div>
        </div>*/}
        <div className="partThree">
          <div className="trEmSnt">
            {/*
              transactionEmailBookingSentDateStr
              ? <div className="entry"><div className="typ">Booking</div><span>sent on {transactionEmailBookingSentDateStr}</span></div>
              : ''
            }
            {
              transactionEmailPreArrivalSentDateStr
              ? <div className="entry"><div className="typ">Pre-Arrival</div><span>sent on {transactionEmailPreArrivalSentDateStr}</span></div>
              : ''
            }
            {
              transactionEmailCheckInSentDateStr
              ? <div className="entry"><div className="typ">Check-In</div><span>sent on {transactionEmailCheckInSentDateStr}</span></div>
              : ''
            }
            {
              transactionEmailCheckOutSentDateStr
              ? <div className="entry"><div className="typ">Check-Out</div><span>sent on {transactionEmailCheckOutSentDateStr}</span></div>
              : ''
            */}
            {
              props.reservationData.transaction_email_tasks && props.reservationData.transaction_email_tasks.length
              ?
                props.reservationData.transaction_email_tasks.map((item) => {
                  if (item) {
                    let dateStr = item.date_email_sent_at ? formatDate(item.date_email_sent_at) : '';
                    if (!dateStr) dateStr = item.date_created ? formatDate(item.date_created) : '';
                    let tooltipStr = `Subject: ${item.sent_email && item.sent_email.subject ? item.sent_email.subject : ''}`;
                    if (!item.sent_email) {
                      tooltipStr = `Subject: ${item.data && item.data.subject ? item.data.subject : ''}`;
                    }
                    return (
                      <div className={'entry' + (item.new ? ' new' : '')} id={`g_tet_${item._id}`} key={`g_tet_${item._id}`}>
                        <ToolTipWrapper placement="left" overlayClassName={'sbTooltip maxWidth'}
                          overlay={tooltipStr}
                        >
                          <div className="typ">{TRANSACTION_EMAIL_TYPE_TO_LABEL[item.data.type]}</div>
                        </ToolTipWrapper>
                        <ToolTipWrapper placement="left" overlayClassName={'sbTooltip maxWidth'}
                          overlay={'Open Preview'}
                        >
                          <span onClick={() => onPreviewOpenClick(item)}><i className="fas fa-eye"></i></span>
                        </ToolTipWrapper>
                        <span>sent on {dateStr}</span>
                        {
                          item.manual
                          ? <span>(Manual)</span>
                          : <span>(Automated)</span>
                        }
                      </div>
                    );
                  }
                  return null;
                })
              : null
            }
          </div>
          <div className={'sentStats'}>
            { reservationData.booking_source || '' }
          </div>

          {
            renderOverflowMenu()
          }
        </div>
        { showEmailPreviewModal &&
          <SentEmailPreviewModal
            modalType="templateSelector"
            title="Email Preview"
            setModalVisible={onEmailPreviewCloseModal}
            onAffirmativeClick={onEmailPreviewConfirmClick}
            showCancel={true}
            affirmativeButtonLabel={'Close'}
            disableOutsideClick={true}
            // emailData={sentEmailData}
            // inProgress={getPreviewInProgress}
            transactionEmailIdentifier={selectedTransactionEmailTask._id}
            locationMId={props.locationMId}
            subject={selectedTransactionEmailTask.sent_email ? selectedTransactionEmailTask.sent_email.subject : ''}
          />
        }
        { showSelectTransactionEmailModal &&
          <SelectTransactionEmailModal
            modalType="templateSelector"
            title="Select Email"
            setModalVisible={onSelectTransactionEmailCloseModal}
            onAffirmativeClick={onSelectTransactionEmailConfirmClick}
            showCancel={true}
            affirmativeButtonLabel={'Send'}
            disableOutsideClick={true}
            // emailData={sentEmailData}
            inProgress={props.reservationData.sendCustomInProgress}
            transactionEmails={props.transactionEmails}
            reservationData={props.reservationData}
          />
        }
        {
          showTaskDetailModal &&
          renderTaskDetailModal()
        }
        { showResendModal &&
          <GenericAlertModal
            modalType="Resend Email"
            bodyRowOne={`Do you want to resend this ${reservationData.type} email ?`}
            bodyRowNote=""
            keyName={reservationData.label}
            setModalVisible={setShowResendModal}
            onAffirmativeClick={onResendConfirmClick}
            inProgress={reservationData.resendInProgress || false}
            showCancel={true}
          />
        }
      </div>
    );
  };

  // if (!hasAccessToFeature(props.userprops.user, ENGAGE_FEATURES.ADD_EDIT_GUESTS)) {
  //   // nkcheck - log this
  //   return <NoAccess />;
  // }

  return renderContent();
}

export default SingleTransactionEmailHistoryBox;
