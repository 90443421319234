// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import queryString from 'query-string';
import copy from 'copy-to-clipboard';
import Highlighter from "react-highlight-words";
import Interweave from 'interweave';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../redux/actions/snacksActions';
import { formatDate, isGTUser } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import moreIcon from '../../../../img/icons/more.svg';
import editIcon from '../../../../img/icons/edit.svg';
import searchIcon from '../../../../img/icons/search.svg';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../Misc/GenericAlertModal';

import './style.css';


const modalRoot = document.getElementById('modal-root-rto');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function LocationRow(props) {
  const onCheckBoxChange = (e) => {
    props.onCheckBoxChange(props.data._id, !props.isSelected);
  };
  const onRowClick = (e) => {
    props.onCheckBoxChange(props.data._id, !props.isSelected);
  };
  if (!props.data) return null;
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('LocationRow props: ', props);
  //   console.log('LocationRow: ', props.data._id, ' # ', props.isSelected);
  // }
  return (
    <div className="lRow" id={`g_l_${props.data_id}`} onClick={onRowClick}>
      <div className="checkBox">
        <input type="checkBox" onChange={onCheckBoxChange} checked={props.isSelected} />
      </div>
      <div className="left">
        {
          props.data.welcome_screen_image_url &&
          <img src={props.data.welcome_screen_image_url} />
        }
      </div>
      <div className="right">
        <p className="name">{props.data.name}
          {
            isGTUser(props.user) &&
            ` ${props.data.location_id} - ${props.data.serverid}`
          }
        </p>
        <p className="address">{props.data.address}</p>
        {
          props.partOfGroupsList && props.partOfGroupsList.length
          ?
            <div className="alreadyInList">Already in:
              {
                props.partOfGroupsList.map(item => <span>{item.name}</span>)
              }
            </div>
          : null
        }
      </div>
    </div>
  )
}

function ManageGroup(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const groupState = useSelector((state) => state.groupReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');
  const [editGroupNameEnabled, setEditGroupNameEnabled] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const [filteredList, setFilteredList] = useState([]);
  const [filteredListMap, setfilteredListMap] = useState({});
  const [locationListError, setLocationListError] = useState('');


  const [filteredListAddLocations, setFilteredListAddLocations] = useState([]);
  const [filteredListAddLocationsMap, setFilteredListAddLocationsMap] = useState({});
  const [addLocationsListError, setAddLocationsListError] = useState('');

  const [addLocationEnabled, setAddLocationEnabled] = useState(false);

  const [someChange, setSomeChange] = useState(false);
  const [someChangeAddLocations, setSomeChangeAddLocations] = useState(false);

  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const groupUpdated = useRef(false);

  const groupNameRef = useRef(null);

  const clearStatus = () => {
    const params = {};
    params.mode = props.mode;
    if (props.mode === 'edit') {
      params.group_id = props.groupData._id;
    }
    dispatch(editDataClearAction({
      op: 'edit_group',
      params,
    }));
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('ManageGroup useEffect props.mode: ', props.mode);
      console.log('ManageGroup useEffect props.groupData: ', props.groupData);
    }

    const params = {};
    params.mode = props.mode;
    if (props.mode === 'edit') {
      params.group_id = props.groupData._id;
    }
    dispatch(editDataClearAction({
      op: 'edit_group',
      params,
      // show_alert: true,
      // alert_msg: 'Updated successfully',
    }));


    const { locations } = userState.user.user_info;
    if (locations) {
      if (props.mode === 'add') {
        if (Object.keys(locations).length) {
          setFilteredList(Object.keys(locations).map(el => el));
        } else {
          setFilteredList([]);
        }
      }
      if (props.mode === 'edit') {
        let tempList = [];
        let tempListMap = {};
        if (props.groupData && props.groupData.list && props.groupData.list.length) {
          props.groupData.list.forEach((item) => {
            if (locations[item._id]) {
              tempList.push(item._id);
              tempListMap[item._id] = true;
            }
          });
        }
        setFilteredList(tempList);
        setfilteredListMap(tempListMap);
        setGroupName(props.groupData.name || '');
      }
    }
  }, []);

  useEffect(() => {
    const { groups } = groupState;
    if (props.mode === 'add' && groups && !groups.addInProgress && groups.addStatus && groups.addStatus.success) {
      setSomeChange(false);
      setSomeChangeAddLocations(false);
      const params = {};
      params.mode = props.mode;
      if (props.mode === 'edit') {
        params.group_id = props.groupData._id;
      }
      dispatch(editDataClearAction({
        op: 'edit_group',
        params,
        // show_alert: true,
        // alert_msg: 'Updated successfully',
      }));
      props.onCancelClick('add');
    }
    if (props.mode === 'edit' && props.groupData && groups && groups.keyValue &&
      groups.keyValue[props.groupData._id] && !groups.keyValue[props.groupData._id].editInProgress &&
      groups.keyValue[props.groupData._id].editStatus && groups.keyValue[props.groupData._id].editStatus.success
      && !groupUpdated.current
    ) {
      setSomeChange(false);
      setSomeChangeAddLocations(false);
      // const params = {};
      // params.mode = props.mode;
      // params.group_id = props.groupData._id;
      // dispatch(editDataClearAction({
      //   op: 'edit_group',
      //   params,
      //   // show_alert: true,
      //   // alert_msg: 'Updated successfully',
      // }));
      if (props.onGroupUpdateSuccess) {
        props.onGroupUpdateSuccess();
      }
      groupUpdated.current = true;
    }
    if (props.mode === 'edit' && props.groupData && groups && groups.keyValue &&
      groups.keyValue[props.groupData._id] && !groups.keyValue[props.groupData._id].removeInProgress &&
      groups.keyValue[props.groupData._id].is_removed
    ) {
      setSomeChange(false);
      setSomeChangeAddLocations(false);
      const params = {};
      params.mode = 'remove';
      params.group_id = props.groupData._id;
      dispatch(editDataClearAction({
        op: 'edit_group',
        params,
        // show_alert: true,
        // alert_msg: 'Updated successfully',
      }));
      props.onCancelClick();
    }
  }, [groupState.groups]);

  useEffect(() => {
    const { locations, locations_list } = userState.user.user_info;
    if (addLocationEnabled) {
      let tempList = [];
      if (locations && Object.keys(locations).length) {
        Object.keys(locations).forEach((item) => {
          if (props.groupData && props.groupData.list && props.groupData.list.length) {
            if (!props.groupData.list.find(el => el._id === item)) tempList.push(item);
          }
        });
        let fList = [];
        tempList.forEach((locationId) => {
          if (new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
            new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
            new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
            new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
          ) {
            fList.push(locationId);
          }
        });
        setFilteredListAddLocations(fList);
      }
    } else {
      let locationIdList = [];
      if (props.mode === 'add') {
        locationIdList = locations && Object.keys(locations).length ? Object.keys(locations).map(el => el) : [];
      }
      if (props.mode === 'edit') {
        locationIdList = props.groupData && props.groupData.list && props.groupData.list.length
        ? props.groupData.list.map(el => el._id) : [];
      }
      if (locationIdList.length) {
        if (searchKeyword && searchKeyword.length > 2) {
          let tempList = [];
          locationIdList.forEach((locationId) => {
            if (
              locations[locationId] &&
              (
                new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
                new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
                new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
                new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
              )
            ) {
              tempList.push(locationId);
            }
          });
          if (process.env.NODE_ENV !== 'production') {
            console.log('onLocationCheckBoxChange tempList: ', tempList);
          }
          setFilteredList(tempList);
        } else {
          setFilteredList(locationIdList);
        }
      }
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (addLocationEnabled) {
      let tempList = [];
      const { locations } = userState.user.user_info;
      if (locations && Object.keys(locations).length) {
        Object.keys(locations).forEach((item) => {
          if (props.groupData && props.groupData.list && props.groupData.list.length) {
            if (!props.groupData.list.find(el => el._id === item)) tempList.push(item);
          }
        });
        setFilteredListAddLocations(tempList);
      }
    } else {
      setFilteredListAddLocations([]);
    }
  }, [addLocationEnabled]);

  const onCloseClick = (e) => {
    props.onCancelClick(groupUpdated.current ? 'edit' : '');
  };

  const onGroupNameChange = (e) => {
    const { name, value } = e.target;
    setGroupNameError(value ? '' : 'error');
    setGroupName(value);
    setSomeChange(true);
    clearStatus();
  };
  const onEditGroupNameChanged = (e) => {
    setEditGroupNameEnabled(true);
    groupNameRef.current.focus();
  };

  const onKeywordChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchKeywordKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  const selectAllLocations = () => {
    let tFilteredListMap = {};
    filteredList.forEach((el) => {
      tFilteredListMap[el] = true;
    });
    setfilteredListMap(tFilteredListMap);
  };
  const clearAllLocations = () => {
    let tFilteredListMap = {};
    setfilteredListMap(tFilteredListMap);
  };
  const onSelectAllChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectAllChange: ', name, ' # ', value);
      console.log('onSelectAllChange selectAll: ', selectAll);
    }
    if (!selectAll) {
      selectAllLocations();
    } else {
      clearAllLocations();
    }
    setSelectAll(selectAll => !selectAll);
    setSomeChange(true);
    clearStatus();
  };
  const onSelectAllLabelClick = (e) => {
    if (!selectAll) {
      selectAllLocations();
    } else {
      clearAllLocations();
    }
    setSelectAll(selectAll => !selectAll);
    setSomeChange(true);
    clearStatus();
  };

  const onLocationCheckBoxChange = (locationId, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationCheckBoxChange locationId: ', locationId, ' # value: ', value);
    }
    if (addLocationEnabled) {
      let tempMap = { ...filteredListAddLocationsMap };
      tempMap[locationId] = value;
      setFilteredListAddLocationsMap(tempMap);
      if (!Object.keys(tempMap).filter(el => tempMap[el]).length) {
        setAddLocationsListError('Select atleast one location');
      } else {
        setAddLocationsListError('');
      }
      setSomeChangeAddLocations(true);
    } else {
      let tempMap = { ...filteredListMap };
      tempMap[locationId] = value;
      setfilteredListMap(tempMap);
      if (!Object.keys(tempMap).filter(el => tempMap[el]).length) {
        setLocationListError('Select atleast one location');
      } else {
        setLocationListError('');
      }
      setSomeChange(true);
      clearStatus();
    }
  };

  const onAddLocationClick = (e) => {
    setAddLocationEnabled(true);
  };
  const onAddLocationCancelClick = (e) => {
    setAddLocationEnabled(false);
  };

  const onDeletGroupClick = (e) => {
    setShowDeleteGroupModal(true);
  };
  const onDeleteGroupConfirmCloseModal = (e) => {
    setShowDeleteGroupModal(false);
  };
  const onDeleteGroupConfirmClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDeleteGroupConfirmClick : ', props.groupData);
    }
    const params = {};
    params.mode = 'remove';
    params.group_id = props.groupData._id;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_group',
      params,
      // show_alert: true,
      // alert_msg: 'Updated successfully',
    }));
  };

  const onCtaClick = (e) => {
    const { locations } = userState.user.user_info;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick groupName: ', groupName);
      console.log('onCtaClick filteredListMap: ', filteredListMap);
    }
    let hasError = false;

    // for add location screen
    if (addLocationEnabled) {
      if (!Object.keys(filteredListAddLocationsMap).filter(el => filteredListAddLocationsMap[el]).length) {
        hasError = true;
        setLocationListError('Select atleast one location');
      } else {
        setLocationListError('');
      }
      if (hasError) {
        return;
      }
      let tempList = Object.keys(filteredListAddLocationsMap).filter(el => filteredListAddLocationsMap[el]);
      setFilteredList([
        ...filteredList,
        ...tempList,
      ]);
      let tempListMap = { ...filteredListMap };
      tempList.forEach((item) => { tempListMap[item] = true; });
      setfilteredListMap(tempListMap);
      setAddLocationEnabled(false);
      setSomeChange(true);
      return;
    }

    // for update
    if (!someChange) return;
    if (props.mode === 'edit' && (!props.groupData || !props.groupData._id)) return;
    setGroupNameError(groupName ? '' : 'error');
    if (!groupName) hasError = true;
    if (props.mode === 'add') {
      if (!Object.keys(filteredListMap).filter(el => filteredListMap[el]).length) {
        hasError = true;
        setLocationListError('Select atleast one location');
      } else {
        setLocationListError('');
      }
    }
    if (props.mode === 'edit') {
      if (addLocationEnabled) {
        if (!Object.keys(filteredListAddLocationsMap).filter(el => filteredListAddLocationsMap[el]).length) {
          hasError = true;
          setLocationListError('Select atleast one location');
        } else {
          setLocationListError('');
        }
      } else {
        if (!Object.keys(filteredListMap).filter(el => filteredListMap[el]).length) {
          hasError = true;
          setLocationListError('Select atleast one location');
        } else {
          setLocationListError('');
        }
      }
    }
    if (hasError) {
      return;
    }
    let locationList = [];
    if (props.mode === 'add') {
      locationList = Object.keys(filteredListMap).filter(el => filteredListMap[el]).map((el) => {
        return {
          _id: el,
          name: locations[el].name,
        };
      });
    }
    if (props.mode === 'edit') {
      locationList = Object.keys(filteredListMap).filter(el => filteredListMap[el]).map((el) => {
        return {
          _id: el,
          name: locations[el].name,
        };
      });
      if (addLocationEnabled) {
        locationList = [
          ...locationList,
          ...Object.keys(filteredListAddLocationsMap).filter(el => filteredListMap[el]).map((el) => {
            return {
              _id: el,
              name: locations[el].name,
            };
          }),
        ];
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick locationList: ', locationList);
    }
    const params = {};
    params.mode = props.mode;
    if (props.mode === 'edit') {
      params.group_id = props.groupData._id;
    }
    params.name = groupName;
    params.list = locationList;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCtaClick params: ', params);
    }
    groupUpdated.current = false;
    dispatch(editDataAction({
      op: 'edit_group',
      params,
      // show_alert: true,
      // alert_msg: 'Updated successfully',
    }));
  };

  const renderHeaderBox = () => {
    return (
      <>
        <div className="left">
          <input className={(groupNameError ? 'groupNameError' : '')} type="text" name="groupName" value={groupName} onChange={onGroupNameChange} readOnly={!editGroupNameEnabled || addLocationEnabled}
            placeholder="New Group"
            ref={groupNameRef}
          />
          { !editGroupNameEnabled && !addLocationEnabled &&
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={"Edit group name"}
            >
              <img src={editIcon} onClick={onEditGroupNameChanged} />
            </ToolTipWrapper>

          }
        </div>
        <div className="right">
          <span onClick={onCloseClick}><img src={closeIcon} />Close</span>
        </div>
      </>
    );
  };

  const renderBodyBox = (groups) => {
    const { locations } = userState.user.user_info;
    return (
      <>
        <div className="searchBox">
          <p>Search locations</p>
          <div className="inputBox">
            <img src={searchIcon} />
            <input type="text" name="keyword" value={searchKeyword} onChange={onKeywordChange}
              placeholder="Search location"
              onKeyDown={onSearchKeywordKeyDown}
            />
          </div>
        </div>
        <div className="selAllBox">
          <input type="checkbox" name="selectAll" onChange={onSelectAllChange} value={selectAll ? 'on' : 'off'} checked={selectAll} />
          <div className="gLbl" onClick={onSelectAllLabelClick}>
            {selectAll ? 'Clear All' : 'Select All'}
          </div>
        </div>
        <div className="locationsListBox">
          {
            filteredList.length
            ?
              filteredList.map((locationId) => {
                let partOfGroupsList = groups && groups.list
                  ? groups.list.filter((groupId) => {
                      if (groups.keyValue[groupId] && groups.keyValue[groupId].list && groups.keyValue[groupId].list.find(item => item._id === locationId)) {
                        return true;
                      }
                      return false;
                    }).map((groupId) => {
                      return { _id: groupId, name: groups.keyValue[groupId].name };
                    })
                  : [];
                return (
                  <LocationRow data={locations[locationId]} user={userState.user}
                    key={`lr_${locationId}`}
                    onCheckBoxChange={onLocationCheckBoxChange}
                    isSelected={filteredListMap[locationId] || false}
                    partOfGroupsList={partOfGroupsList}
                  />
                )
              })
            : null
          }
        </div>
      </>
    );
  };

  const renderEditGroupBodyBox = (groups) => {
    const { locations } = userState.user.user_info;
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderEditGroupBodyBox locations: ', locations);
      console.log('renderEditGroupBodyBox filteredList: ', filteredList);
    }
    if (addLocationEnabled) return renderEditGroupAddLocationBodyBox();
    return (
      <>
        <div className="searchBox">
          <div className="top">
            <span className="title">Search locations</span>
            <div className="actions">
              <span className="add" onClick={onAddLocationClick}>Add Location</span>
              <span className="delete" onClick={onDeletGroupClick}>Delete Group</span>
            </div>
          </div>
          <div className="inputBox">
            <img src={searchIcon} />
            <input type="text" name="keyword" value={searchKeyword} onChange={onKeywordChange}
              placeholder="Search location"
            />
          </div>
        </div>
        <div className="locationsListBox">
          {
            filteredList.length
            ?
              filteredList.map((locationId) => {
                return (
                  <LocationRow data={locations[locationId]} user={userState.user}
                    key={`lr_${locationId}`}
                    onCheckBoxChange={onLocationCheckBoxChange}
                    isSelected={filteredListMap[locationId]}
                  />
                )
              })
            : null
          }
        </div>
      </>
    );
  };

  const renderEditGroupAddLocationBodyBox = () => {
    const { locations } = userState.user.user_info;
    return (
      <>
        <div className="searchBox">
          <p>Select locations to add
          </p>
          <div className="inputBox">
            <img src={searchIcon} />
            <input type="text" name="keyword" value={searchKeyword} onChange={onKeywordChange}
              placeholder="Search location"
            />
          </div>
        </div>
        <div className="locationsListBox">
          {
            filteredListAddLocations.length
            ?
              filteredListAddLocations.map((locationId) => {
                return (
                  <LocationRow data={locations[locationId]} user={userState.user}
                    key={`lr_${locationId}`}
                    onCheckBoxChange={onLocationCheckBoxChange}
                    isSelected={filteredListMap[locationId]}
                  />
                )
              })
            : null
          }
        </div>
      </>
    );
  };

  const renderBottomBox = (groups) => {
    let ctaLabel = 'Create Group';
    if (props.mode === 'edit') ctaLabel = 'Save Changes';
    let inProgress = false;
    if (props.mode === 'add' && groups) inProgress = groups.addInProgress || false;
    if (props.mode === 'edit' && groups && groups.keyValue && groups.keyValue[props.groupData._id]) {
      inProgress = groups.keyValue[props.groupData._id].editInProgress ||
        groups.keyValue[props.groupData._id].removeInProgress || false;
    }
    let curStatus = '';
    if (props.mode === 'add' && groups && groups.addStatus) {
      if (groups.addStatus.success) curStatus = 'Added successfully';
      if (groups.addStatus.error) curStatus = 'Error in adding';
    }
    if (props.mode === 'edit' && groups && groups.keyValue &&
      groups.keyValue[props.groupData._id] &&
      groups.keyValue[props.groupData._id].editStatus
    ) {
      if (groups.keyValue[props.groupData._id].editStatus.success) curStatus = 'Updated successfully';
      if (groups.keyValue[props.groupData._id].editStatus.error) curStatus = 'Error in updating';
    }

    return (
      <>
        {
          (groupNameError || locationListError)
          ?
            <div className="left">
              {
                groupNameError ? <span>Group name is required</span> : null
              }
              {
                locationListError ? <span>{locationListError}</span> : null
              }
            </div>
          : null
        }
        <div className="middle">
          {curStatus}
        </div>
        <div className="right">
          <span className={'cta' + (someChange ? '' : ' inactive')} onClick={onCtaClick}>
            { ctaLabel }
            { inProgress && <span className="inProgress"><i className="fa fa-spinner fa-spin" /></span> }
          </span>
        </div>
      </>
    );
  };

  const renderBottomAddLocationBox = (groups) => {
    let ctaLabel = 'Add to Group';
    return (
      <>
        <div className="left">
          {
            groupNameError ? <span>Group name is required</span> : null
          }
          {
            locationListError ? <span>{locationListError}</span> : null
          }
        </div>
        <div className="right">
          <span className="cta cancel" onClick={onAddLocationCancelClick}>
            Cancel
          </span>
          <span className={'cta' + (someChangeAddLocations ? '' : ' inactive')} onClick={onCtaClick}>
            { ctaLabel }
          </span>
        </div>
      </>
    );
  };

  const renderContent = () => {
    const { groups } = groupState;
    const cStyle = {};
    cStyle.height = windowState.window.windowHeight - 72 - 70;
    return (
      <div className="innerBox">
        {/*
          props.mode === 'embed' &&
          <div className="closeRow">
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
          </div>
        */}
        <div className="headerBox">
          { renderHeaderBox() }
        </div>
        <div className="bodyBox" style={cStyle}>
          { props.mode === 'add' && renderBodyBox(groups) }
          { props.mode === 'edit' && renderEditGroupBodyBox(groups) }
        </div>
        <div className="bottomBox">
          {
            addLocationEnabled
            ? renderBottomAddLocationBox(groups)
            : renderBottomBox(groups)
          }
        </div>
        { showDeleteGroupModal &&
          <GenericAlertModal
            modalType="ApproveConfirm"
            title={`Are you sure?`}
            bodyRowOne={`Do you want to delete the group (${props.groupData.name}) ? This action cannot be undone!`}
            bodyRowNote={'You’ll not be able to retrieve the contents later.'}
            setModalVisible={onDeleteGroupConfirmCloseModal}
            onAffirmativeClick={onDeleteGroupConfirmClick}
            inProgress={groups && groups.keyValue[props.groupData._id].removeInProgress}
            // currentStatus={guest.checkOutStatus}
            showCancel={true}
          />
        }
      </div>
    );
  };





  if (process.env.NODE_ENV !== 'production') {
    console.log('ManageGroup props: ', props);
    console.log('ManageGroup groupName: ', groupName);
    console.log('ManageGroup filteredList: ', filteredList);
    console.log('ManageGroup filteredListMap: ', filteredListMap);
    console.log('ManageGroup filteredListAddLocationsMap: ', filteredListAddLocationsMap);
  }

  return (
    <div className="gCard manageGroup">
        { renderContent() }
    </div>
  );
}

export default ManageGroup;
