import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation, Outlet, } from "react-router-dom";

import { getDataAction, getDataClearAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { addSearchResultGuestToStore, addOutsideGuestStatusClearAction } from '../../../../redux/actions/miscEngageActions';

import useDebounce from '../../../Misc/DebounceHook';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { guestTransactionStatusToStayLabel, formatDate, getGuestTypeKey, guestCategoryKeyByGuestType,
  getGuestCategoryKeyByGuest, getSearchGuestLabelByGuest, getFullNameForDisplay,
  formatMessageDate, guestLabelByGuestType, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';
import whatsappIcon from '../../../../img/icons/whatsapp.png';
import emailIcon from '../../../../img/icons/email.svg';
import gbmIcon from '../../../../img/icons/google-logo.png';
import smsIcon from '../../../../img/icons/sms.svg';
import currentIcon from '../../../../img/icons/current.svg';
import pastIcon from '../../../../img/icons/past.svg';
import upcomingIcon from '../../../../img/icons/upcoming.svg';
import staffIcon from '../../../../img/icons/staff.svg';
import webtextIcon from '../../../../img/icons/webchat.svg';
import leadsIcon from '../../../../img/icons/leads.svg';
import directIcon from '../../../../img/icons/direct-messaging.svg';

import './style.css';

const guestTypeIcon = {
  'guest': currentIcon,
  'past-guest': pastIcon,
  'upcoming-guest': upcomingIcon,
  'outside-guest': directIcon,
  'webtext': webtextIcon,
  'staff': staffIcon,
  'gbm': gbmIcon,
};

function LogRow(props) {
  const onRowClick = (e) => {
    let url = '/engage/';
    const { _id, guest_type, latest_received_message } = props.data;
    props.onRowClick(props.data);
  };

  const { _id, guest_type, latest_received_message } = props.data;
  let curIcon = guest_type ? guestTypeIcon[guest_type] : staffIcon;
  let curIconToolTip = guest_type ? guestLabelByGuestType[guest_type] : '';
  let displayName = '';
  if (guest_type === 'guest' || guest_type === 'past-guest' || guest_type === 'upcoming-guest') {
    displayName = props.data.first_name || '';
    if (props.data.last_name) displayName = `${displayName}, ${props.data.last_name}`;
  }
  if (guest_type === 'outside-guest' || guest_type === 'webtext' || guest_type === 'staff' || guest_type === 'gbm') {
    displayName = props.data.name || '';
    if (!displayName) displayName = props.data.contact;
  }

  <div className={'bcRow logRow'}>
    <div className="bxLt">
      <div className="gLbl">
        Cell Number
      </div>
      <div className={'gIpt'}>
      </div>
    </div>
  </div>

  return (
    <div className="bcRow logRow" onClick={onRowClick} id={`al_${props.data._id}`}>
      <div className="bxLt">
        <div className="tpRw">
          <div className="lt">
            <span className="dateReceived">On: { formatMessageDate(props.data.date_latest_received_message) }</span>
          </div>
          <div className="rt">
            {
              !props.data.latest_received_message_read &&
              <span className="unread">Unread</span>
            }
            {
              (!props.data.latest_medium || props.data.latest_medium === 'sms') &&
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Sms"}>
                <img src={smsIcon} />
              </ToolTipWrapper>
            }
            {
              props.data.latest_medium === 'whatsapp' &&
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"WhatsApp"}>
                <img src={whatsappIcon} />
              </ToolTipWrapper>
            }
            {
              props.data.latest_medium === 'email' &&
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Email"}>
                <img src={emailIcon} />
              </ToolTipWrapper>
            }
            {
              props.data.latest_medium === 'bm' &&
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={"Google Message"}>
                <img src={gbmIcon} />
              </ToolTipWrapper>
            }
          </div>
        </div>
        <p className="name">
          From: { displayName }
        </p>
        <p className="msg">
          { latest_received_message }
        </p>
      </div>
      <div className="bxRt">
        <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={curIconToolTip} disable={curIconToolTip ? false : true}>
          <img src={curIcon} />
        </ToolTipWrapper>
      </div>
    </div>
  );
}

function ReceivedMessages(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Received Messages - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('ReceivedMessages urlParams.locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(getDataAction({
      op: 'get_activity_log',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'engage',
      },
    }));
  }, []);

  const onCloseClick = (e) => {
    props.onCancelClick('');
  };

  const onActivityLogRowClick = (data) => {
    dispatch(addSearchResultGuestToStore({
      params: {
        location__id: urlParams.locationidentifier,
        guest_type: data.guest_type,
        guest: data,
      },
    }));
    // props.onCancelClick();
    // props.history.push(url);
    const category = guestCategoryKeyByGuestType[data.guest_type];
    if (process.env.NODE_ENV !== 'production') {
      console.log('navigate to ', `/engage/locations/${urlParams.locationidentifier}/messages/${category}/${data._id}`);
    }
    navigate(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${data._id}`);
    props.onCancelClick('skip');
  };

  const { activityLog } = props.engage;

  return (
    <div className="sdMdl addGst receivedMessages">
      <div className="ctnt">
        <div className="gPWrp">
          <h4 className="gPTle title">
            <span>
            Received Messages
            </span>
            {
              props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            }
          </h4>
          <h5 className="gPTle subTitle">
            Check the most recently received messages. Select any message to view the conversation.
          </h5>
          <div className="bc">
            {
              activityLog && activityLog.list && activityLog.list.length && activityLog.keyValue
              ?
                activityLog.list.map((item) => {
                  if (!activityLog.keyValue[item]) return null;
                  return (
                    <LogRow data={activityLog.keyValue[item]} onRowClick={onActivityLogRowClick} key={`al_key_${item}`}/>
                  );
                })
              : <>
                {
                  activityLog && activityLog.getInProgress
                  ? <i className="fa fa-spinner fa-spin" />
                  : <p>No Data</p>
                }
                </>
            }
          </div>
        </div>
      </div>
    </div>
  );

}

export default ReceivedMessages;
