import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation, Outlet, } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import moment from 'moment';
import Select from 'react-select';

import { getDataAction, getDataClearAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import useDebounce from '../../../Misc/DebounceHook';

import { isContactValid, validateEmail, guestTransactionStatusToStayLabel, formatDate, getGuestTypeKey,
  getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';

import './style.css';

const yesterday = moment().subtract(1, 'day');

function AddStaff(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [contact, setContact] = useState('+1');
  const contactRef = useRef(null);

  const [contactChanged, setContactChanged] = useState(false);
  const debouncedSearchTerm = useDebounce(contact, 500);
  const [contactSelectedFromSearchResult, setContactSelectedFromSearchResult] = useState(false);
  const [selectedFromSearchResultId, setSelectedFromSearchResultId] = useState(false);

  const [form, setForm] = useState({
    name: '',
  });

  const [someChange, setSomeChange] = useState(false);

  const [addButtonType, setAddButtonType] = useState('');


  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Add Staff - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('AddStaff props: ', props);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('AddStaff props.guest: ', props.guest);
      // console.log('AddStaff passedGuest: ', passedGuest);
      console.log('AddStaff props.mode: ', props.mode);
    }
    setForm(form => ({
      ...form,
      name: '',
    }));
    setSomeChange(false);
    clearCurrentStatus();
    setTimeout(() => {
      if (contactRef && contactRef.current) contactRef.current.focus();
    }, 100);

    const params = {};
    params.mode = props.mode;
    params.location__id = urlParams.locationidentifier;
    params.is_staff = true;
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.mode params: ', params);
    }
    dispatch(editDataClearAction({
      op: 'add_outside_guest',
      params,
    }));

    dispatch(getDataClearAction({
      op: 'get_guest_search_result',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'staff',
      }
    }));
  }, [props.mode]);

  // [debouncedSearchTerm]
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('debouncedSearchTerm: ', debouncedSearchTerm);
    }
    if (!contactSelectedFromSearchResult) {
      if (debouncedSearchTerm && debouncedSearchTerm != '+1') {
        if (props.mode === "edit") {
          const { guest } = props;
          if (guest && guest.contact && guest.contact.trim()) {
            let temp = `+${guest.contact.replace(/\D/g,'')}`;
            if (process.env.NODE_ENV !== 'production') {
              console.log('debouncedSearchTerm temp: ', temp);
            }
            if (temp !== contact) {
              dispatch(getDataAction({
                op: 'get_guest_search_result',
                params: {
                  location__id: urlParams.locationidentifier,
                  keyword: debouncedSearchTerm,
                  type: 'staff',
                }
              }));
            }
          }
        } else {
          dispatch(getDataAction({
            op: 'get_guest_search_result',
            params: {
              location__id: urlParams.locationidentifier,
              keyword: debouncedSearchTerm,
              type: 'staff',
            }
          }));
        }
      }
    }
  }, [debouncedSearchTerm]);


  // add successful - nkcheck - pending
  useEffect(() => {
    if (props.engage.staff &&
      !props.engage.staff.addInProgress && props.engage.staff.addStatus &&
      props.engage.staff.addStatus.success && props.engage.staff.addStatus.newId
    ) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('AddStaff addButtonType: ', addButtonType);
      }
      setContact('+1');
      setForm(form => ({
        ...form,
        name: '',
      }));
      setSomeChange(false);
      if (addButtonType === 'addAndMessage') {
        let category = 'staff';
        if (process.env.NODE_ENV !== 'production') {
          // console.log('pushing ', `/engage/locations/${urlParams.locationidentifier}/messages/${category}/${props.engage.staff.addStatus.newId}`);
        }
        props.onCancelClick(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${props.engage.staff.addStatus.newId}`);
        if (process.env.NODE_ENV !== 'production') {
          console.log('called onCancelClick');
        }
      }
    }
  }, [props.engage.staff]);


  const clearCurrentStatus = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('clearCurrentStatus: ', props);
    }
    if (props.engage.staff &&
      props.engage.staff.addStatus && Object.keys(props.engage.staff.addStatus).length) {
      const params = {};
      params.mode = props.mode;
      params.is_staff = true;
      if (process.env.NODE_ENV !== 'production') {
        console.log('clearCurrentStatus params: ', params);
      }
      dispatch(editDataClearAction({
        op: 'add_outside_guest',
        params,
      }));
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  };

  const validateContact = (value) => {
    try {
      if (value) {
        const pContactNumber = parsePhoneNumber(value);
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact pContactNumber: ', pContactNumber);
        }
        setContact(value);
        setForm(form => ({
          ...form,
          contactError: pContactNumber && isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber) ? '' : 'error',
        }));
      } else {
        setContact('');
        setForm(form => ({
          ...form,
          // contactError: 'error',
          contactError: '',
        }));
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('contact error: ', e);
      }
    }
  };

  const onContactChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onContactChange: ', value);
    }
    setContactSelectedFromSearchResult(false);
    setSelectedFromSearchResultId('');
    if (value) {
      validateContact(value);
      setSomeChange(true);
      setContactChanged(true);
      clearCurrentStatus();
    } else {
      setContact('');
    }
  };

  const onSearchResultCloseClick = (e) => {
    setContactChanged(false);
  };
  const onRowClick = (row) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowClick: ', row);
    }
    if (row && row._id) {
      setContactSelectedFromSearchResult(true);
      setSelectedFromSearchResultId(row._id);
      setContact(row.contact);
      validateContact(row.contact);
      setForm(form => ({
        ...form,
        name: row.name,
      }));
      setContactChanged(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
    }));
    if (name === 'name') {
      setForm(form => ({
        ...form,
        [`${name}Error`]: value ? '' : 'error',
      }));
    }
    setSomeChange(true);
    clearCurrentStatus();
  };

  const performAdd = (addType) => {
    if (!someChange) return;
    if (props.engage.staff && props.engage.staff.addInProgress) return;

    if (contactChanged) {
      validateContact(contact);
    }
    setForm(form => ({
      ...form,
      nameError: form.name ? '' : 'error',
    }));
    if (process.env.NODE_ENV !== 'production') {
      console.log('performAdd form: ', form);
    }

    if (contactChanged && contact) {
      try {
        const pContactNumber = parsePhoneNumber(contact);
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact pContactNumber: ', pContactNumber);
        }
        if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('validateContact error');
          }
          return;
        }
      } catch (e) {}
    }

    if (contact && form.contactError) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('some contact error');
      }
      return;
    }

    if (!form.name || form.nameError) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('some error');
      }
      return;
    }

    if (!contactChanged && contact === '+1') {
      setContact('');
    }

    const params = {};
    params.add_type = addType;
    params.mode = 'add';
    params.location__id = urlParams.locationidentifier;
    params.is_staff = true;
    params['name'] = form.name || '';
    params['contact'] = contact || '';
    if (!contactChanged && contact === '+1') {
      params['contact'] = '';
    }
    const pContactNumber = contact ? parsePhoneNumber(contact) : null;
    if (pContactNumber && pContactNumber.countryCallingCode) {
      params['country_code'] = `+${pContactNumber.countryCallingCode}`;
    }
    if (pContactNumber && pContactNumber.nationalNumber) {
      params['contact_number'] = pContactNumber.nationalNumber;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'add_outside_guest', // 'edit_guest',
      params,
    }));
  };

  const onAddClick = (e) => {
    setAddButtonType('add');
    performAdd('add');
  };

  const onAddAndMessageClick = (e) => {
    setAddButtonType('addAndMessage');
    performAdd('addAndMessage');
  };

  const onMessageClick = (e) => {
    if (selectedFromSearchResultId) {
      props.onCancelClick(`/engage/locations/${urlParams.locationidentifier}/messages/staff/${selectedFromSearchResultId}`);
    }
  };


  const renderResultRow = (row) => {

    return (
      <div className="rslRw" id={`rr_${row._id}`} onClick={() => onRowClick(row)}>
        <div className="rwIt">
          <div className="lt">
            {guestTransactionStatusToStayLabel[row.transaction_type]}
          </div>
        </div>
        <div className="rwIt topRow">
          <div className="lt">
            {row.name || row.first_name}
          </div>
          <div className="rt">
            {row.contact}
          </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('AddStaff form: ', form);
  }

  return (
    <div className="sdMdl addStf">
      <div className="ctnt">
        <div className="gPWrp">
          <h4 className="gPTle title">
            <span>
            Add Staff
            </span>
            {
              props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            }
          </h4>
          <div className="bc">
            <div className={'bcRow contactRow ' + (contact && contactChanged && props.engage.staff && props.engage.staff.searchResults && props.engage.staff.searchResults.contacts && props.engage.staff.searchResults.contacts.length ? 'searchResultsOpen' : '')}>
              <div className="bxLt">
                <div className="gLbl">
                  Cell Number
                </div>
                <div className={'gIpt ' + (form.contactError ? 'error' : '')}>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    displayInitialValueAsLocalNumber={false}
                    value={contact}
                    // onCountryChange={onCountryChange}
                    ref={contactRef}
                    onChange={onContactChange}/>
                  {/*<input type="text" placeholder="Cell Number" />*/}
                </div>
                {
                  props.engage.staff && props.engage.staff.searchResults &&
                  props.engage.staff.searchResults.searchInProgress &&
                  <span className="srchInPrg"><i className="fa fa-spinner fa-spin" /></span>
                }

                {
                  form.contactError &&
                  <div className="gErr">
                    <img src={requiredIcon} /> Invalid contact
                  </div>
                }

                <div className="gHelpMsg">
                  Choose country code and enter cell number
                </div>
              </div>
              <div className="bxRt">
              </div>
            </div>
            {
              contact && contactChanged && props.engage.staff && props.engage.staff.searchResults.contacts && props.engage.staff.searchResults.contacts.length
              ?
                <div className="srchRCls">
                  <span onClick={onSearchResultCloseClick}>Close</span>
                </div>
              : null
            }
            {
              contact && contactChanged && props.engage.staff && props.engage.staff.searchResults.contacts && props.engage.staff.searchResults.contacts.length
              ?
                <>
                  <div className="srchRWrp">
                    <div className="srchRInfo">
                      <p>Staff already exists</p>
                    </div>
                    {
                      props.engage.staff.searchResults.contacts &&
                      props.engage.staff.searchResults.contacts.map((item) => {
                        return renderResultRow(item);
                      })
                    }
                  </div>

                </>
              : null
            }
            <div className="bcRow nameRow">
              <div className="bxLt firstNameBox">
                <div className="gLbl">
                  Name
                </div>
                <div className={'gIpt ' + (form.nameError ? 'error' : '')}>
                  <input type="text" name="name" placeholder="Name" value={form.name} onChange={onChange} />
                </div>
              </div>
              <div className="bxRt">
              </div>
            </div>
            {
              props.engage.staff && props.engage.staff.addStatus &&
              (props.engage.staff.addStatus.success || props.engage.staff.addStatus.error) &&
              <div className="bcRow curSts">
                {
                  props.engage.staff.addStatus.error && <p className="error">{props.engage.staff.addStatus.message}</p>
                }
                {
                  props.engage.staff.addStatus.success && <p className="success">Added successfully</p>
                }
              </div>
            }
            <div className="bcRow buttonsRow">
              {
                contactSelectedFromSearchResult
                ?
                  <>
                    <button className={'gBtn addAndMessage ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onMessageClick}>
                      Message
                    </button>
                  </>
                :
                  <>
                    <button className={'gBtn add ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onAddClick}>
                      Add{ addButtonType === 'add' && props.engage.staff && props.engage.staff.addInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                    <button className={'gBtn addAndMessage ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onAddAndMessageClick}>
                      Add & Message { addButtonType === 'addAndMessage' && props.engage.staff && props.engage.staff.addInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  </>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStaff;
