// @flow

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';

import SentEmailPreviewModal from '../../../../Misc/SentEmailPreviewModal';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import { validateEmail, isContactValid } from '../../../../../helpers';
import { formatDate, formatDateStrToStr, TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP,
  TRANSACTION_EMAIL_TYPE_TO_LABEL, sendEmailAlert,
  DEVICE_TYPE_CODE_TO_LABEL, COUNTRY_LIST_KEYS } from '../../../../../helpers';

import useDebounce from '../../../../Misc/DebounceHook';

import closeIcon from '../../../../../img/icons/close.svg';
import requiredIcon from '../../../../../img/icons/required.svg';

import './style.css';

const gtconfig = require('../../../../../gtconfig');

function TransactionEmailTaskDetailModal(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  let location = useLocation();

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [getPreviewInProgress, setGetPreviewInProgress] = useState(false);
  const [sentEmailData, setSentEmailData] = useState({});

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Marketing - Transaction Emails - History - Single - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const onPreviewOpenClick = (e) => {
    setShowEmailPreviewModal(true);
  };

  const onEmailPreviewCloseModal = (e) => {
    setShowEmailPreviewModal(false);
  };
  const onEmailPreviewConfirmClick = (data) => {
    setShowEmailPreviewModal(false);
  };
  const onCloseClick = (e) => {
    props.onCancelClick();
  }


  const renderContent = () => {
    const info = props.taskDetail;
    if (process.env.NODE_ENV !== 'production') {
      console.log('info: ', info);
    }
    if (!info) return null;
    const checkInDateStr = formatDateStrToStr(info.guest_info.check_in_date_local_str) || formatDate(info.guest_info.check_in_date);
    const checkOutDateStr = formatDateStrToStr(info.guest_info.check_out_date_local_str) || formatDate(info.guest_info.check_out_date);
    let customLabel = 'Default';
    if (info.data.is_custom && info.data.custom_for) {
      customLabel = `Custom - ${TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP[info.data.custom_for]}`;
      if (info.data.rate_code_info && info.data.rate_code_info.label) {
        customLabel = `${customLabel} - ${info.data.rate_code_info.label}`;
      }
      if (info.data.custom_for === 'booking-source' && info.data.custom_value && info.data.custom_value.length) {
        customLabel = `${customLabel} - ${info.data.custom_value.reduce((prev, cur, curIndex) => { if (curIndex === info.data.custom_value.length-1) { return prev + cur.label; } else { return prev + cur.label  + ' / '; }}, '')}`;
      }
    }

    let device = '';
    if (info && info.tracking && info.tracking.event_data_open) {
      if (info.tracking.event_data_open.is_desktop) device = 'desktop';
      if (info.tracking.event_data_open.is_mobile) device = 'mobile';
      if (info.tracking.event_data_open.is_tablet) device = 'tablet';
    }

    return (
      <div className="sdMdl emailTaskDetail">
        <div className="ctnt">
          <div className="gPWrp">
            <div className="gPTle title">
              Details
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            </div>
            <div className="gBCtnt">
              <div className="gBCtRw title">
                <div className="gRwBxLt">
                  Recepient Details
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Name
                </div>
                <div className="gRwBxRt">
                  {info.guest_info ? info.guest_info.first_name || '' : ''}
                  {info.guest_info ? ` ${info.guest_info.last_name || ''}` : ''}
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Email
                </div>
                <div className="gRwBxRt">
                  {info.sent_email ? info.sent_email.to_email : ''}
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Stay Dates
                </div>
                <div className="gRwBxRt">
                  {checkInDateStr} - {checkOutDateStr}
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Room Number
                </div>
                <div className="gRwBxRt">
                  {info.guest_info ? info.guest_info.room_number || '' : ''}
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Room Type
                </div>
                <div className="gRwBxRt">
                  {info.guest_info ? info.guest_info.room_type || '' : ''}
                </div>
              </div>
              <div className="gBCtRw title mt30">
                <div className="gRwBxLt">
                  Email Details
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Type
                </div>
                <div className="gRwBxRt">
                  {TRANSACTION_EMAIL_TYPE_TO_LABEL[info.data.type]}
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Email Template
                </div>
                <div className="gRwBxRt">
                  {info.sent_email ? info.sent_email.email_template_name : ''}
                </div>
              </div>
              {
                info.data.type !== 'custom' &&
                <div className="gBCtRw kVRw">
                  <div className="gRwBxLt">
                    Default/Custom
                  </div>
                  <div className="gRwBxRt">
                    {customLabel}
                  </div>
                </div>
              }
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Email Subject
                </div>
                <div className="gRwBxRt">
                  {info.sent_email ? info.sent_email.subject : ''}
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Preview
                </div>
                <div className="gRwBxRt">
                  <span className="opnMdl" onClick={onPreviewOpenClick}>Open</span>
                </div>
              </div>
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Sent At
                </div>
                <div className="gRwBxRt">
                  {
                    info.date_email_sent_at_tz
                    ? info.date_email_sent_at_tz
                    : '-'
                  }
                </div>
              </div>
              {
                info.date_email_resent_at_tz &&
                <div className="gBCtRw kVRw">
                  <div className="gRwBxLt">
                    Re-Sent At
                  </div>
                  <div className="gRwBxRt">
                    {
                      info.date_email_resent_at_tz
                      ? info.date_email_resent_at_tz
                      : '-'
                    }
                  </div>
                </div>
              }
              <div className="gBCtRw kVRw">
                <div className="gRwBxLt">
                  Opened At
                </div>
                <div className="gRwBxRt">
                  {
                    info.date_email_status_open_tz
                    ? <p>{info.date_email_status_open_tz}</p>
                    : '-'
                  }
                  {
                    (
                      (info.tracking && info.tracking.event_data_open && info.tracking.event_data_open.ip_country) ||
                      device
                    )
                    ?
                      <p className="flexRow">
                        { info.tracking.event_data_open.ip_country &&
                          <ToolTipWrapper placement="left" overlayClassName={'sbTooltip'}
                            overlay={COUNTRY_LIST_KEYS[info.tracking.event_data_open.ip_country]}
                          >
                            <div className="ipCountry">{info.tracking.event_data_open.ip_country}</div>
                          </ToolTipWrapper>
                        }
                        { device &&
                          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
                            overlay={DEVICE_TYPE_CODE_TO_LABEL[device]}
                          >
                            <div className="device"><span><i className={`fas fa-${device}-alt`}></i></span></div>
                          </ToolTipWrapper>
                        }
                      </p>
                    : null
                  }
                </div>
              </div>
              {
                info.tracking && info.tracking.click
                ?
                  info.tracking.click.map((item) => {
                    if (item.data) {
                      let device = '';
                      if (item.data.is_desktop) device = 'desktop';
                      if (item.data.is_mobile) device = 'mobile';
                      if (item.data.is_tablet) device = 'tablet';
                      return (
                        <div className="gBCtRw kVRw">
                          <div className="gRwBxLt">
                            Link Clicked
                          </div>
                          <div className="gRwBxRt">
                            <p>{item.data.link}</p>
                            <p>{item.data.timestamp} UTC</p>
                            <p className="flexRow">
                              { item.data.ip_country &&
                                <ToolTipWrapper placement="left" overlayClassName={'sbTooltip'}
                                  overlay={COUNTRY_LIST_KEYS[item.data.ip_country]}
                                >
                                  <div className="ipCountry">{item.data.ip_country}</div>
                                </ToolTipWrapper>
                              }
                              { device &&
                                <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
                                  overlay={DEVICE_TYPE_CODE_TO_LABEL[device]}
                                >
                                  <div className="device"><span><i className={`fas fa-${device}-alt`}></i></span></div>
                                </ToolTipWrapper>
                              }
                            </p>
                          </div>
                        </div>
                      )
                    }
                  })
                : null
              }
            </div>
          </div>
          { showEmailPreviewModal &&
            <SentEmailPreviewModal
              modalType="templateSelector"
              title="Email Preview"
              bodyRowOne=""
              bodyRowNote=""
              setModalVisible={onEmailPreviewCloseModal}
              onAffirmativeClick={onEmailPreviewConfirmClick}
              inProgress={false}
              // currentStatus={guest.checkOutStatus}
              showCancel={true}
              affirmativeButtonLabel={'Close'}
              disableOutsideClick={true}
              // emailData={sentEmailData}
              // inProgress={getPreviewInProgress}
              transactionEmailIdentifier={props.taskDetail._id}
              locationMId={props.locationMId}
            />
          }
        </div>
      </div>
    );
  }

  // if (!props.match.params.transactionemailidentifier) {
  //   return null;
  // }

  // if (!hasAccessToFeature(props.userprops.user, ENGAGE_FEATURES.ADD_EDIT_GUESTS)) {
  //   // nkcheck - log this
  //   return <NoAccess />;
  // }

  return renderContent();
}

export default TransactionEmailTaskDetailModal;
