import React, {Component, useEffect, useRef, useState} from 'react';
import {withFauxDOM} from 'react-faux-dom'

import * as d3 from "d3";
import './style.css';

type Action = { type: string; payload: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
};
type State = {};

const MIN_BAR_WIDTH = 12;

function TimePeriodButton(props) {
  const onButtonClick = (e) => { props.onButtonClick(props.value); }
  return (
    <div className={'entry ' + (props.selected ? 'active' : '')} onClick={onButtonClick}>
      <span>{props.label}</span>
    </div>
  );
}

function BarGroupChart(props) {
  const svgRef = useRef();
  const [margin, setMargin] = useState({ top: 60, right: 60, bottom: 90, left: 60 });
  const [barWidth, setBarWidth] = useState(40);
  const [data, setData] = useState([]);
  const [canvasHeight, setCanvasHeight] = useState(360);
  const [canvasWidth, setCanvasWidth] = useState(360);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState('weekly');

  const [chartUpdating, setChartUpdating] = useState(false);


  useEffect(() => {
    setCanvasHeight(props.height);
    // setCanvasWidth(props.windowprops.window.windowWidth/2 - 100);
    setCanvasWidth(props.width - 120 - 20);
    setData([
      { period: 'jan', value: 2, rating: 4 },
      { period: 'feb', value: 14, rating: 5},
      { period: 'mar', value: 6, rating: 1 },
      { period: 'apr', value: 8, rating: 2 },
      { period: 'may', value: 10, rating: 5 },
    ]);
    drawChart();
  }, []);

  useEffect(() => {
    if (props.data) {
      // console.log('props.data changed: ', props.data);
      let tempCanvasWidth = props.width - 120 - 20;
      if (selectedTimePeriod === 'daily' && props.data.daily) {
        if (tempCanvasWidth/props.data.daily.length < MIN_BAR_WIDTH) {
          // change it to monthly
          if (tempCanvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
            setData(props.data.monthly);
            setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
            setSelectedTimePeriod('monthly');
          } else {
            // change it to weekly
            setData(props.data.weekly);
            setBarWidth((tempCanvasWidth/props.data.weekly.length)/2);
            setSelectedTimePeriod('weekly');
          }
        } else {
          setData(props.data.daily);
          setBarWidth((tempCanvasWidth/props.data.daily.length)/2);
        }
      }
      if (selectedTimePeriod === 'weekly' && props.data.weekly) {
        // change it to monthly
        if (tempCanvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
          setData(props.data.monthly);
          setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
          setSelectedTimePeriod('monthly');
        } else {
          // change it to weekly
          setData(props.data.weekly);
          setBarWidth((tempCanvasWidth/props.data.weekly.length)/2);
          setSelectedTimePeriod('weekly');
        }
      }
      if (selectedTimePeriod === 'monthly' && props.data.monthly) {
        setData(props.data.monthly);
        setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
      }
    }
    if (props.showOnlyDaily) {
      setSelectedTimePeriod('daily');
    }
  }, [props.data, props.showOnlyDaily]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('showSent: ', props.showSent);
      console.log('showDelivered: ', props.showDelivered);
      console.log('showOpen: ', props.showOpen);
    }
  }, [props.showSent, props.showDelivered, props.showOpen]);

  useEffect(() => {
    // console.log('data changed');
    setChartUpdating(true);
    updateChart();
  }, [data, props.showSent, props.showDelivered, props.showOpen, canvasWidth, canvasHeight]);

  useEffect(() => {
    setCanvasHeight(props.height);
    setCanvasWidth(props.width - 120 - 20);
    updateChart();
  }, [props.windowState.window.windowHeight]);


  const onTimePeriodButtonClick = (timePeriod) => {
    // console.log('onTimePeriodButtonClick: ', timePeriod);
    if (selectedTimePeriod === timePeriod) return;
    setSelectedTimePeriod(timePeriod);
    if (timePeriod === 'monthly') {
      if (props.data.monthly) {
        setData(props.data.monthly);
        setBarWidth((canvasWidth/props.data.monthly.length)/2);
        setChartUpdating(true);
      }
    }
    if (timePeriod === 'weekly') {
      // change it to monthly
      if (canvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
        setData(props.data.monthly);
        setBarWidth((canvasWidth/props.data.monthly.length)/2);
        setSelectedTimePeriod('monthly');
        setChartUpdating(true);
      } else {
        // change it to weekly
        setData(props.data.weekly);
        setBarWidth((canvasWidth/props.data.weekly.length)/2);
        setSelectedTimePeriod('weekly');
        setChartUpdating(true);
      }
    }
    if (timePeriod === 'daily') {
      if (canvasWidth/props.data.daily.length < MIN_BAR_WIDTH) {
        // change it to monthly
        if (canvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
          setData(props.data.monthly);
          setBarWidth((canvasWidth/props.data.monthly.length)/2);
          setSelectedTimePeriod('monthly');
          setChartUpdating(true);
        } else {
          // change it to weekly
          setData(props.data.weekly);
          setBarWidth((canvasWidth/props.data.weekly.length)/2);
          setSelectedTimePeriod('weekly');
          setChartUpdating(true);
        }
      } else {
        setData(props.data.daily);
        setBarWidth((canvasWidth/props.data.daily.length)/2);
        setChartUpdating(true);
      }
    }
  };

  // const x0 = d3.scaleOrdinal()
    // .rangeRoundBands([0, canvasWidth], .1);
  const x0 = d3.scaleBand()
    .rangeRound([0, canvasWidth])
    .padding(0.1);
  const x1 = d3.scaleBand();

  const y = d3.scaleLinear()
    .range([canvasHeight, 0]);

  const xAxis = d3.axisBottom()
    .scale(x0)
    .tickSize(0)

  const yAxis = d3.axisLeft()
      .scale(y);

  let colorTemp = [];
  if (props.showSent) colorTemp.push("#52ced5");
  if (props.showDelivered) colorTemp.push("#ffe662");
  if (props.showOpen) colorTemp.push("#6295ff");
  const color = d3.scaleOrdinal()
      .range(colorTemp);


  const drawChart = () => {
    // const margin = 30;

    const faux = props.connectFauxDOM('div', 'chart');

    const scale = 20;
    const svgCanvas = d3.select(faux)
      .append('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)

      // .style('border', '1px solid #EEE')


    // Add Y axis
    svgCanvas.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(yAxis);

    // Add X axis
    svgCanvas.append("g")
    .attr("class", "x axis")
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.bottom) + ")")
      .call(xAxis);

    svgCanvas.append("g")
      .attr('id', 'barChart');


    // Add g tag for text on top of bars
    svgCanvas.append("g")
      .attr('id', 'barText');

    // Add g tag for line chart
    svgCanvas.append("g")
      .attr('id', 'lineChart');

    // Define the div for the tooltip
    const div = d3.select("body").append("div")
      .attr("class", "barChartTooltip")
      .style("opacity", 0);

  }

  const updateChart = () => {
    // console.log('updateChart: ', (new Date()).getTime());

    setChartUpdating(true);
    d3.select('#chartLoader').style('display', 'block');
    // console.log('updateChart after setChartUpdating: ', (new Date()).getTime());


    const faux = props.connectFauxDOM('div', 'chart')

    // const svgCanvas = d3.select(svgRef.current)
    const svgCanvas = d3.select(faux)
      .select('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)

    // console.log('updateChart svgCanvas: ', (new Date()).getTime(), ' # ', svgCanvas);

    var categoriesNames = data.map(function(d) { return d.period; });
    // var rateNames = data[0].values.map(function(d) { return d.rate; });
    // var rateNames = ["sent", "delivered", "open"];
    var rateNames = [];
    if (props.showSent) rateNames.push("sent");
    if (props.showDelivered) rateNames.push("delivered");
    if (props.showOpen) rateNames.push("open");
    // if (data && data.length && data[0].values) {
    //   rateNames = data[0].values.map(function(d) { return d.rate; });
    // }
    x0.domain(categoriesNames);
    x1.domain(rateNames).range([0, x0.bandwidth()]).padding([0.02]);
    // y.domain([0, d3.max(data, function(categorie) { return d3.max(categorie.values, function(d) { return d.value; }); })]);
    y.domain([0, d3.max(data, function(categorie) {
      let tValues = [];
      if (props.showSent) tValues.push(categorie.values[0]);
      if (props.showDelivered) tValues.push(categorie.values[1]);
      if (props.showOpen) tValues.push(categorie.values[2]);
      return d3.max(tValues, function(d) { return d.value; });
    })]);

    svgCanvas
      .select('#barText')
      .selectAll('text')
      .remove();

    // clear barChart
    svgCanvas
      .select('#barChart')
      .selectAll('g')
      .remove();

    const entryOffset = (canvasWidth - barWidth * data.length)/(data.length*2);

      svgCanvas
        .select('#barChart')
        .append("g")
        .selectAll("g")
      // var slice = svgCanvas.append("g")
      // .attr('id', 'barChart')
        .data(data)
        .enter().append("g")
        .attr("class", "g")
        .attr("transform",function(d) { return "translate(" + x0(d.period) + ",0)"; })
        .selectAll("rect")
        // .data(function(d) { return d.values; })
        .data(function(d) { let v = [];
          if (props.showSent) v.push(d.values[0]);
          if (props.showDelivered) v.push(d.values[1]);
          if (props.showOpen) v.push(d.values[2]);
          return v;
        })
    .enter().append("rect")
        .attr("width", x1.bandwidth())
        .attr("x", function(d) { return x1(d.rate) + margin.left; })
        .style("fill", function(d) { return color(d.rate) })
        .attr("y", function(d) { return y(d.value) + margin.top; })
        .attr("height", function(d) { return canvasHeight - y(d.value); })
        // .on("mouseover", function(d) {
        //     d3.select(this).style("fill", d3.rgb(color(d.rate)).darker(2));
        // })
        // .on("mouseout", function(d) {
        //     d3.select(this).style("fill", color(d.rate));
        // });



      svgCanvas
        .select('#barText')
        .selectAll('text')
        .data(data).enter()
        .append("g")
        .attr("class", "g")
        .attr("transform",function(d) { return "translate(" + x0(d.period) + ",0)"; })
        .selectAll("text")
        // .data(function(d) { return d.values; })
        .data(function(d) { let v = [];
          if (props.showSent) v.push(d.values[0]);
          if (props.showDelivered) v.push(d.values[1]);
          if (props.showOpen) v.push(d.values[2]);
          return v;
        })
        .enter().append("text")
        // .attr('x', (datapoint, iteration) => scaleValueXAxis(iteration) + parseInt(entryOffset) + margin.left + barWidth/2 - 5)
        .attr("x", function(d) { return x1(d.rate) + margin.left + x1.bandwidth()/2 - 10; })
        // .attr('y', (datapoint) => (scaleValueYAxis(datapoint.value) + margin.top - 4))
        .attr("y", function(d) { return y(d.value) + margin.top - 5; })
        // .attr("transform", "rotate(-60)")
        // .attr("transform", "translate("  + (-40) + "," + (50) + "), rotate(-60)")
        .text((dataPoint) => dataPoint.value || '')

    // Remove Y axis and Y axis label
    svgCanvas
      .select('.y.axis')
      .remove();

    // Add y axis
    svgCanvas.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      // .attr("transform", "translate("  + (canvasWidth + margin.left) + "," + margin.top + ")")
      .call(yAxis);

    let xAxisTransform = "translate("  + (-40) + "," + (50) + "), rotate(-60)";
    if (selectedTimePeriod === 'weekly') {
      xAxisTransform = "translate("  + (-80) + "," + (45) + "), rotate(-25)";
    }
    if (selectedTimePeriod === 'monthly') {
      xAxisTransform = "translate("  + (-30) + "," + (20) + "), rotate(-30)";
    }
    // Add X axis
    svgCanvas.select(".x.axis") // change the x axis
      // .duration(750)
      // .transition()
      // .duration(750)
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.top) + ")")
      .call(xAxis)
      .selectAll("text")
      .attr("y", 10)
      .attr("x", -2)
      .attr("transform", "rotate(-60)")
      .attr("transform", xAxisTransform)
      .style("text-anchor", "start")
      .style("font-size", "10px")






    svgCanvas
      .selectAll('.legend')
      .remove();

    //Legend
  // var legend = svgCanvas.selectAll(".legend")
  //     // .data(data[0].values.map(function(d) { return d.rate; }.reverse())
  //     .data(["open", "delivered", "sent"].reverse())
  // .enter().append("g")
  //     .attr("class", "legend")
  //     // .attr("transform", function(d,i) { return "translate("+0+"," + i * 20 + ")"; })
  //     .attr("transform", function(d,i) { return "translate(-"+i * 80+"," + 0 + ")"; })
  //     .style("opacity","1");
  //
  // legend.append("rect")
  //     .attr("x", canvasWidth + margin.left - 1)
  //     .attr("width", 18)
  //     .attr("height", 18)
  //     .style("fill", function(d) { return color(d); });
  //
  // legend.append("text")
  //     .attr("x", canvasWidth + margin.left - 7)
  //     .attr("y", 9)
  //     .attr("dy", ".35em")
  //     .style("text-anchor", "end")
  //     .text(function(d) {return d; });
// // legend.transition().duration(500).delay(function(d,i){ return 1300 + 100 * i; }).style("opacity","1");



    // console.log('updateChart end: ', (new Date()).getTime());

    // d3.select("#loader_container")
    // .attr('class', 'hide')
    // hideLoader();

    props.animateFauxDOM(800)

    d3.select('#chartLoader').style('display', 'none');

    setTimeout(() => {
      setChartUpdating(false);
    }, 50);
  };


  // console.log('chartUpdating time: ', (new Date()).getTime());
  // console.log('chartUpdating: ', chartUpdating);

  const cStyle = {};
  cStyle['width'] = props.width;

  return (
    <div className={'barChartWrap ' + (props.className || '' )} style={cStyle}>
      {/*<svg id="loader_container" width="900" height="600"></svg>*/}
      <div id="chartLoader" className="chartLoader">
        <i className="fa fa-spinner fa-spin" />
      </div>
      {props.chart}
      <div className="bottomRow">
        <div className="timePeriodBox">
          {
            !props.showOnlyDaily &&
            <TimePeriodButton label={'Monthly'} value={'monthly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'monthly'} />
          }
          {
            (canvasWidth/props.data.weekly.length > MIN_BAR_WIDTH && !props.showOnlyDaily) &&
            <TimePeriodButton label={'Weekly'} value={'weekly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'weekly'} />
          }
          {
            props.showDaily && (canvasWidth/props.data.daily.length > MIN_BAR_WIDTH) &&
            <TimePeriodButton label={'Daily'} value={'daily'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'daily'} />
          }
        </div>
      </div>
    </div>
  )
}

export default withFauxDOM(BarGroupChart);
