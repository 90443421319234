import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import '../style.css';

const roomAttrOptionsG = [];
roomAttrOptionsG.push({ label: 'Room Number', value: 'room-number' });
roomAttrOptionsG.push({ label: 'Room Type', value: 'room-type' });

function RoomSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState('');

  const [attr, setAttr] = useState({});

  const [roomAttrOptions, setRoomAttrOptions] = useState([
    ...roomAttrOptionsG,
  ]);
  const [selectedRoomAttr, setSelectedRoomAttr] = useState({});
  const [selectedRoomAttrError, setSelectedRoomAttrError] = useState('');

  const [roomAttrValue, setRoomAttrValue] = useState('');
  const [roomAttrValueError, setRoomAttrValueError] = useState('');

  const [keycode, setKeycode] = useState('');

  const [remoteLockOptions, setRemoteLockOptions] = useState([]);
  const [selectedRemoteLock, setSelectedRemoteLock] = useState({});
  const [selectedRemoteLockError, setSelectedRemoteLockError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const labelRef = useRef(null);


  useEffect(() => {
    if (props.room && props.room._id) {
      setLabel(props.room.label);
      setAttr(props.room.attr);
      if ('room-number' in props.room.attr) {
        setSelectedRoomAttr({ label: 'Room Number', value: 'room-number' });
        setRoomAttrValue(props.room.attr['room-number']);
      }
      if ('room-type' in props.room.attr) {
        setSelectedRoomAttr({ label: 'Room Type', value: 'room-type' });
        setRoomAttrValue(props.room.attr['room-type']);
      }
      setKeycode(props.room.keycode);
      let selectedRemoteLockLabel = '';
      if (props.settings && props.settings.remote_locks && props.settings.remote_locks.keyValue &&
        props.settings.remote_locks.list
      ) {
        let tList = [];
        props.settings.remote_locks.list.forEach((item) => {
          if (props.settings.remote_locks.keyValue[item]) {
            tList.push({
              'label': props.settings.remote_locks.keyValue[item].attributes ? props.settings.remote_locks.keyValue[item].attributes.name || '' : '',
              'value': item,
            });
            if (item === props.room.remote_lock_id) {
              selectedRemoteLockLabel = props.settings.remote_locks.keyValue[item].attributes
                ? props.settings.remote_locks.keyValue[item].attributes.name || '' : '';
            }
          }
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log('tList: ', tList);
          console.log('RoomSingle selectedRemoteLockLabel: ', selectedRemoteLockLabel);
        }
        setRemoteLockOptions(tList);
        if (props.room.remote_lock_id && selectedRemoteLockLabel) {
          setSelectedRemoteLock({ label: selectedRemoteLockLabel, value: props.room.remote_lock_id });
        }
      }
    } else {
      if (props.settings && props.settings.remote_locks && props.settings.remote_locks.keyValue &&
        props.settings.remote_locks.list
      ) {
        let tList = [];
        props.settings.remote_locks.list.forEach((item) => {
          if (props.settings.remote_locks.keyValue[item]) {
            tList.push({
              'label': props.settings.remote_locks.keyValue[item].attributes ? props.settings.remote_locks.keyValue[item].attributes.name || '' : '',
              'value': item,
            });
          }
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log('RoomSingle add tList: ', tList);
        }
        setRemoteLockOptions(tList);
      }
    }
    setSomeChange(false);
    dispatch(editDataClearAction({
      op: 'edit_room',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        room_id: props.room._id,
      },
    }));
    setTimeout(() => {
      if (labelRef && labelRef.current) {
        labelRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.room && props.room._id &&
        props.settings.rooms && props.settings.rooms.keyValue &&
        props.settings.rooms.keyValue[props.room._id] &&
        !props.settings.rooms.keyValue[props.room._id].editInProgress &&
        props.settings.rooms.keyValue[props.room._id].editStatus &&
        props.settings.rooms.keyValue[props.room._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.settings.rooms &&
        !props.settings.rooms.addInProgress &&
        props.settings.rooms.addStatus &&
        props.settings.rooms.addStatus.success
      ) {
        setLabel('');
        setAttr({});
        setSelectedRoomAttr({});
        setRoomAttrValue('');
        setKeycode('');
        setSomeChange(false);
      }
    }
  }, [props.settings.rooms]);

  const onLabelChange = (e) => {
    const { name, value } = e.target;
    dispatch(editDataClearAction({
      op: 'edit_room',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        room_id: props.room._id,
      },
    }));
    setLabel(value);
    if (!value) {
      setLabelError('error');
    } else {
      setLabelError('');
    }
    setSomeChange(true);
  };
  const onSelectedRoomAttrChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectedRoomAttrChange: ', value);
    }
    let attrValue = '';
    if (attr['room-number']) attrValue = attr['room-number'];
    if (attr['room-type']) attrValue = attr['room-type'];
    setAttr({
      [value.value]: attrValue,
    });
    setSelectedRoomAttr(value);
    setSelectedRoomAttrError('');
    setSomeChange(true);
  };
  const onRoomAttrValueChange = (e) => {
    if (!selectedRoomAttr.value) {
      setRoomAttrValueError('Select room identifier first!');
      return;
    }
    const { name, value } = e.target;
    setRoomAttrValue(value);
    setAttr({
      [selectedRoomAttr.value]: value,
    });
    if (!value) {
      setRoomAttrValueError('error');
    } else {
      setRoomAttrValueError('');
    }
    setSomeChange(true);
  };
  const onKeycodeChange = (e) => {
    const { name, value } = e.target;
    setKeycode(value);
    setSomeChange(true);
  };
  const onSelectedRemoteLockChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectedRemoteLockChange: ', value);
    }
    setSelectedRemoteLock(value);
    setSelectedRemoteLockError('');
    setSomeChange(true);
  };


  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    let addEditInProgress = props.settings.rooms.addInProgress
    if (props.type === 'edit') {
      if (props.room && props.room._id &&
        props.settings.rooms && props.settings.rooms.keyValue &&
        props.settings.rooms.keyValue[props.room._id]
      ) {
        addEditInProgress = props.settings.rooms.keyValue[props.room._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    dispatch(editDataClearAction({
      op: 'edit_room',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        room_id: props.room._id,
      },
    }));
    setLabelError(label ? '' : 'error');
    if (!selectedRoomAttr.value) {
      setSelectedRoomAttrError('Room identifier is required.');
    } else {
      setSelectedRoomAttrError('');
    }
    setRoomAttrValueError(roomAttrValue ? '' : 'Value is required');
    if (!roomAttrValue) return;
    if (someChange) {
      if (props.type === 'add') {
        if (label && attr && Object.keys(attr).length) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            label,
            attr,
            keycode,
          };
          if (props.settings && props.settings.info && props.settings.info.has_remote_lock) {
            params.remote_lock_id = selectedRemoteLock.value || '';
          }
          dispatch(editDataAction({
            op: 'edit_room',
            params,
          }));
          // props.onAddEditConfirmClick({
          //   title,
          //   msg: message,
          // });
        }
      }
      if (props.type === 'edit') {
        if (label && attr && Object.keys(attr).length) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            room_id: props.room._id,
            label,
            attr,
            keycode,
          };
          if (props.settings && props.settings.info && props.settings.info.has_remote_lock) {
            params.remote_lock_id = selectedRemoteLock.value || '';
          }
          dispatch(editDataAction({
            op: 'edit_room',
            params,
          }));
        }
      }
    }
  };

  const renderContent = () => {
    const { locationidentifier } = urlParams;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings ||
      !settings.rooms || !settings.rooms.keyValue
    ) return null;
    const tData = settings.rooms.keyValue[props.room._id] || {};

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Room`;
    if (props.type === 'edit') pageTitle = 'Edit Room';
    if (props.title) pageTitle = props.title;



    let addEditInProgress = settings.rooms.addInProgress
    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    let addEditStatus = settings.rooms.addStatus;
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    return (
      <div className="gPWrp roomSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Label
              </div>
              <div className={'gIpt ' + (labelError ? 'error' : '')}>
                <input type="text" value={label} onChange={onLabelChange} placeholder="Title" ref={labelRef} />
                { labelError && <p className="gErr">Label name is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw attrRw">
            <div className="gRwBxLt">
              <label className="gLbl">Choose room identifier</label>
              <Select
                options={roomAttrOptions}
                onChange={onSelectedRoomAttrChange}
                value={selectedRoomAttr}
              />
              { selectedRoomAttrError && <p className="gErr">{selectedRoomAttrError}</p> }
            </div>
            <div className="gRwBxRt">
              <label className="gLbl">Value</label>
              <div className="gIpt">
                <input type="text" onChange={onRoomAttrValueChange} value={roomAttrValue} />
                { roomAttrValueError && <p className="gErr">{roomAttrValueError}</p> }
              </div>
            </div>
          </div>
          {
            props.settings && props.settings.info && props.settings.info.has_remote_lock &&
            <div className="gBCtRw attrRw remLckRw">
              <div className="gRwBxLt">
                <label className="gLbl">Assiged Remote Lock</label>
                <Select
                  options={remoteLockOptions}
                  onChange={onSelectedRemoteLockChange}
                  value={selectedRemoteLock}
                />
                { selectedRemoteLockError && <p className="gErr">{selectedRemoteLockError}</p> }
              </div>
            </div>
          }
          <div className="gBCtRw attrRw keycodeRw">
            <div className="gRwBxLt">
              <label className="gLbl">Keycode</label>
              <div className="gIpt">
                <input type="text" onChange={onKeycodeChange} value={keycode} />
              </div>
            </div>
          </div>
          <div className="gBCtRw helpText">
            <p>Use Room identifier and a value to create a room. Value will be used to match the room number / type recived from PMS. Case-insensitive match will be used.</p>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RoomSingle props: ', props);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('RoomSingle selectedRemoteLock: ', selectedRemoteLock);
  }

  return renderContent();
}

export default RoomSingle;
