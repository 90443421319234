import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import './style.css';

function RGLocationSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [title, setTitle] = useState('');

  const [titleError, setTitleError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  useEffect(() => {
    setSomeChange(false);
    clearAction();
  }, []);

  useEffect(() => {
    const { locations } = responseGeneratorState;
    if (props.type === 'add') {
      if (locations &&
        !locations.addInProgress &&
        locations.addStatus &&
        locations.addStatus.success
      ) {
        setTitle('');
        setSomeChange(false);
      }
    }
  }, [responseGeneratorState]);

  const clearAction = () => {
    const params = {};
    dispatch(editDataClearAction({
      op: 'add_rg_location',
      params,
    }));
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    clearAction();
    setTitle(value);
    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
    setSomeChange(true);
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    const { locations } = responseGeneratorState;
    let addEditInProgress = locations ? locations.addInProgress : false;
    if (addEditInProgress) return;

    clearAction();

    setTitleError(title ? '' : 'error');


    if (someChange) {
      if (props.type === 'add') {
        if (title) {
          const params = {
            name: title,
            mode: 'add',
          };
          dispatch(editDataAction({
            op: 'add_rg_location',
            params,
          }));
        }
        setSomeChange(false);
      }
    }
  };

  const renderContent = () => {
    const { locations } = responseGeneratorState;

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Location`;
    if (props.title) pageTitle = props.title;


    let addEditInProgress = locations ? locations.addInProgress : false;
    let addEditStatus = locations ? locations.addStatus || {} : {};

    if (process.env.NODE_ENV !== 'production') {
      console.log('RGLocationSingle addEditInProgress: ', addEditInProgress);
      console.log('RGLocationSingle addEditStatus: ', addEditStatus);
    }

    return (
      <div className="gPWrp roomSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Location Name
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Location Name" />
                { titleError && <p className="gErr">Required</p> }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RGLocationSingle props: ', props);
  }

  return renderContent();
}

export default RGLocationSingle;
