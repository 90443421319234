const initialState = {
  snacks_index: [],
  snacks: {},
};

const snackReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT': {
      return initialState;
    }
    case 'RECEIVED_NEW_SNACK': {
      let newState = Object.assign({}, state);
      const { snack } = action.payload;
      const { id } = snack;
      const { msg } = snack;

      const temp_snacks_index = newState.snacks_index.slice();
      if (temp_snacks_index.indexOf(id) === -1) {
        temp_snacks_index.push(id);
      }

      newState = {
        ...newState,
        snacks: {
          ...newState.snacks,
          [id]: msg,
        },
      };

      newState = {
        ...newState,
        snacks_index: temp_snacks_index,
      };

      return newState;
    }

    case 'REMOVE_SNACK': {
      let newState = Object.assign({}, state);
      const { id } = action.payload;

      let temp_snacks_index = newState.snacks_index.slice();
      temp_snacks_index = temp_snacks_index.filter((item, index) => item !== parseInt(id, 10));

      const temp_snacks = Object.assign({}, newState.snacks);
      const tt = {};
      Object.keys(temp_snacks).forEach((item, index) => {
        if (item !== id) {
          tt[item] = temp_snacks[item];
        }
      });
      newState = {
        ...newState,
        snacks: tt,
        snacks_index: temp_snacks_index,
      };

      return newState;
    }
    case 'REMOVE_ALL_SNACK': {
      let newState = Object.assign({}, state);
      newState = {
        ...newState,
        snacks_index: [],
      };

      return newState;
    }
    default:
  }
  return state;
}
export default snackReducer;
