// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';

import './style.css';


function Home(props) {
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    navigate(`/reputation/locations/${urlParams.locationidentifier}/feedbacks/overview`);
  }, []);

  return null;
}

export default Home;
