import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import ImagePreviewModal from '../../../../Misc/ImagePreviewModal';
import GenericSideModal from '../../../../Misc/GenericSideModal';

// import AddWhatsappTemplate from '../../../Settings/Templates/AddWhatsappTemplate';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
// import { MSGTYPE_STR_TO_MSGTYPE } from '../../../../helpers';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';
import smsIcon from '../../../../../img/icons/sms.svg';
import whatsappIcon from '../../../../../img/icons/whatsapp.png';
import emailIcon from '../../../../../img/icons/email.svg';

import '../../style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function JMRuleRow(props) {

  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };

  const onSmsEditClick = (e) => {
    props.onSmsEditClick(props.template);
  };

  const onWhatsappEditClick = (e) => {
    props.onWhatsappEditClick(props.template);
  };

  const onEmailEditClick = (e) => {
    props.onEmailEditClick(props.template);
  };

  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  if (!template) return null;
  return (
    <div className={'gBCtRw jmRlBsdRw' + (props.isLast ? ' noBorder ' : '')} id={`gjr_${props.template._id}`}>
      <div className="lt">
        <div className="title">
          {template.label}
        </div>
        <div className="desc">
          {
            props.room
            ? <span>{props.room.label}</span>
            : null
          }
          {
            props.room && props.room.attr && props.room.attr['room-number']
            ? <span>Room Number: {props.room.attr['room-number']}</span>
            : null
          }
          {
            props.room && props.room.attr && props.room.attr['room-type']
            ? <span>Room Type: {props.room.attr['room-type']}</span>
            : null
          }
        </div>
      </div>
      <div className="rt">
        {
          !props.isReadOnly &&
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay="Edit rule name and room number">
            <span><img className="edit" src={editIcon} onClick={onEditClick} /></span>
          </ToolTipWrapper>
        }
        {
          props.hasSms &&
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay="Set SMS journey messages">
            <span><img className="edit" src={smsIcon} onClick={onSmsEditClick} /></span>
          </ToolTipWrapper>
        }
        {
          props.hasEngageWhatsapp &&
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay="Set WhatsApp journey messages">
            <span><img className="edit" src={whatsappIcon} onClick={onWhatsappEditClick} /></span>
          </ToolTipWrapper>
        }
        {
          props.hasEngageEmail &&
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay="Set Email journey messages">
            <span><img className="edit" src={emailIcon} onClick={onEmailEditClick} /></span>
          </ToolTipWrapper>
        }
        {
          !props.isReadOnly &&
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay="Delete rule">
            <span><img className="delete" src={deleteIcon} onClick={onDeleteClick} /></span>
          </ToolTipWrapper>
        }
      </div>
    </div>
  );

}

export default JMRuleRow;
