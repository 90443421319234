import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import { RULE_TYPES, RULE_TYPE_LABELS, ruleTypeToLabel, validateUrl } from '../../../../../../helpers';

import emojiIcon from '../../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import '../style.css';

function InputBox(props) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('props.error: ', props.props);
  }
  const onTextChange = (e) => { props.onTextChange(e.target.value); };
  return (
    <div className="titleRow">
      <div className="itemBox">
        <div className="gLabel">
          {props.title}
        </div>
        {
          props.subTitle &&
          <div className="gSubLabel">
            {props.subTitle}
          </div>
        }
        <div className={'gInput ' + (props.error ? 'error' : '')}>
          <input type="text" value={props.value} onChange={onTextChange} placeholder={props.placeholder} />
          { props.error && <p className="error">{props.error}</p> }
        </div>
      </div>
    </div>
  );
}

function RWRuleSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  const [url, setUrl] = useState('');
  const [urlError, seturlError] = useState('');

  const [numberOfReviews, setNumberOfReviews] = useState(10);
  const [numberOfReviewsError, setNumberOfReviewsError] = useState('');

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationValue, setLocationValue] = useState(
    []
    // [{ label: 'Select', value: 'select' }]
  );

  const [someChange, setSomeChange] = useState(false);

  const clearAction = () => {
    if (props.type === 'edit') {
      dispatch(editDataClearAction({
        op: 'edit_reviews_widget_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
        },
      }));
    }
    if (props.type === 'add') {
      dispatch(editDataClearAction({
        op: 'edit_reviews_widget_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
  };

  useEffect(() => {
    const { reviews_widget } = props.settings;
    let allLocMap = {};
    if (reviews_widget && reviews_widget.reviews_widget_all_locations) {
      reviews_widget.reviews_widget_all_locations.forEach((item) => {
        allLocMap[item.location_id] = item;
      });
    }
    if (props.rule && props.rule._id) {
      setTitle(props.rule.name);
      setUrl(props.rule.url || '');
      setNumberOfReviews(props.rule.number_of_reviews);
      let lMap = {};
      if (props.rule.locations) {
        let lValue = [];
        props.rule.locations.forEach((item) => {
          lMap[item.location_id] = item;
          let name = allLocMap[item.location_id] ? allLocMap[item.location_id].name : '';
          if (userState.user.uid === 'neeraj.2197@gmail.com') { // nkcheck
            name = `${name} - ${item.location_id}`;
          }
          lValue.push({
            label: name,
            value: item.location_id,
          });
        });
        setLocationValue(lValue);
      }
      let lOptions = [];
      if (reviews_widget && reviews_widget.reviews_widget_all_locations) {
        reviews_widget.reviews_widget_all_locations.forEach((item) => {
          if (!lMap[item.location_id]) {
            let name = item.name;
            if (userState.user.uid === 'neeraj.2197@gmail.com') { // nkcheck
              name = `${name} - ${item.location_id}`;
            }
            lOptions.push({
              label: name,
              value: item.location_id,
            });
          }
        });
      }
      setLocationOptions(lOptions);
    } else {
      let lOptions = [];
      if (reviews_widget && reviews_widget.reviews_widget_all_locations) {
        reviews_widget.reviews_widget_all_locations.forEach((item) => {
          let name = item.name;
          if (userState.user.uid === 'neeraj.2197@gmail.com') { // nkcheck
            name = `${name} - ${item.location_id}`;
          }
          lOptions.push({
            label: name,
            value: item.location_id,
          });
        });
      }
      setLocationOptions(lOptions);
    }
    setSomeChange(false);
    clearAction();
  }, []);

  useEffect(() => {
    const { reviews_widget } = props.settings;
    if (props.type === 'edit') {
      if (props.rule && props.rule._id &&
        reviews_widget && reviews_widget.rules &&
        reviews_widget.rules.keyValue &&
        reviews_widget.rules.keyValue[props.rule._id] &&
        !reviews_widget.rules.keyValue[props.rule._id].editInProgress &&
        reviews_widget.rules.keyValue[props.rule._id].editStatus &&
        reviews_widget.rules.keyValue[props.rule._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (reviews_widget && reviews_widget.rules &&
        !reviews_widget.rules.addInProgress &&
        reviews_widget.rules.addStatus &&
        reviews_widget.rules.addStatus.success
      ) {
        setTitle('');
        // setLocationValue([{ label: 'Select', value: 'select' }]);
        setLocationValue([]);
        setUrl('');
        setSomeChange(false);
      }
    }
  }, [props.settings.reviews_widget]);

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true); clearAction();
    if (!value) {
      setTitleError('Name is required');
    } else {
      setTitleError('');
    }
  };

  const validateUrlValue = (value) => {
    if (!value) {
      seturlError('Url is required'); return false;
    } else {
      if (validateUrl(value)) {
        seturlError(''); return true;
      } else {
        seturlError('Invalid value'); return false;
      }
    }
  };

  const onUrlChange = (e) => {
    const { name, value } = e.target;
    setUrl(value); setSomeChange(true); clearAction()
    validateUrlValue(value);
  };

  const onNumberOfReviewsChange = (e) => {
    const { name, value } = e.target;
    setNumberOfReviews(value); setSomeChange(true); clearAction();
    if (process.env.NODE_ENV !== 'production') {
      console.log('onNumberOfReviewsChange: ', Number.isInteger(parseInt(value)));
    }
    if (Number.isInteger(parseInt(value))) {
      setNumberOfReviewsError(value < 0 ? 'Invalid value' : '');
    } else {
      setNumberOfReviewsError('Required');
    }
  };

  const onLocationChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationChange: ', value);
    }
    if (value && value.length === 1 && value[0].value === 'select') {
      return;
    }
    setLocationValue(value);
    setSomeChange(true);
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    const { reviews_widget } = props.settings;
    if (!reviews_widget || !reviews_widget.widget_id) return;

    let addEditInProgress = reviews_widget.rules.addInProgress
    if (props.type === 'edit') {
      if (props.rule && props.rule._id &&
        reviews_widget.rules && reviews_widget.rules.keyValue &&
        reviews_widget.rules.keyValue[props.rule._id]
      ) {
        addEditInProgress = reviews_widget.rules.keyValue[props.rule._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    clearAction();

    let hasError = false;
    if (!title) hasError = true;
    setTitleError(title ? '' : 'Name is required');

    if (!validateUrlValue(url)) hasError = true;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddEditClick numberOfReviews: ', numberOfReviews);
    }

    if (Number.isInteger(parseInt(numberOfReviews))) {
      setNumberOfReviewsError(numberOfReviews < 0 ? 'Invalid value' : '');
      if (numberOfReviews < 0) hasError = true;
    } else {
      setNumberOfReviewsError('Required');
      hasError = true;
    }
    // if (!Number.isInteger(numberOfReviews) || numberOfReviews < 0) {
    //   setNumberOfReviewsError('Required');
    //   hasError = true;
    // }

    if (hasError) return;

    if (someChange) {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        rule_data: {
          name: title,
          // locations: locationValue.map(el => { return { location_id: el.value }}),
          number_of_reviews: numberOfReviews,
          url,
        }
      }
      if (locationValue) {
        if (locationValue.length > 0) {
          if (locationValue.length === 1) {
            if (locationValue[0].value === 'select') {}
            else {
              params.rule_data.locations = locationValue.map(el => { return { location_id: el.value }});
            }
          } else {
            params.rule_data.locations = locationValue.map(el => { return { location_id: el.value }});
          }
        } else {
          params.rule_data.locations = [];
        }
      }
      if (props.type === 'add') {
        params.rule_data.is_enabled = true;
        params.rule_data.widget_id = reviews_widget.widget_id;
        dispatch(editDataAction({
          op: 'edit_reviews_widget_rule',
          params,
        }));
        setSomeChange(false);
      }
      if (props.type === 'edit') {
        params.rule_id = props.rule._id;
        dispatch(editDataAction({
          op: 'edit_reviews_widget_rule',
          params,
        }));
        setSomeChange(false);
      }
    }
  };

  const renderContent = () => {
    const { reviews_widget } = props.settings;

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Rule`;
    if (props.type === 'edit') pageTitle = 'Edit Rule';
    if (props.title) pageTitle = props.title;

    let addEditInProgress = reviews_widget && reviews_widget.rules ? reviews_widget.rules.addInProgress : false;
    let addEditStatus = reviews_widget && reviews_widget.rules ? reviews_widget.rules.addStatus || {} : {};
    if (props.type === 'edit') {
      if (props.rule && props.rule._id) {
        addEditInProgress = props.rule.editInProgress;
        addEditStatus = props.rule.editStatus;
      }
    }

    return (
      <div className="gPWrp ruleSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Name
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" />
                { titleError && <p className="gErr">Name is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw">
            <div className="gRwBxLt">
              <div className="gLbl">Locations</div>
              <div className="gSLbl">
                Choose locations whose reviews should be displayed on this specific web address.
              </div>
              <Select
                isMulti={true}
                options={locationOptions}
                value={locationValue}
                onChange={onLocationChange}
              />
            </div>
          </div>
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Web Address
              </div>
              <div className="gSLbl">
                Web address on which you want to apply this rule.
              </div>
              <div className={'gIpt ' + (urlError ? 'error' : '')}>
                <input type="text" value={url} onChange={onUrlChange} placeholder="Web Address" />
                { urlError && <p className="gErr">Web address is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Number of reviews
              </div>
              <div className="gSLbl">
                Number of reviews that will be shown on this specific web address (Recommended: 10).
              </div>
              <div className={'gIpt ' + (numberOfReviewsError ? 'error' : '')}>
                <input type="number" value={numberOfReviews} onChange={onNumberOfReviewsChange} placeholder="Number of reviews" />
                { numberOfReviewsError && <p className="gErr">{numberOfReviewsError}</p> }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RWRuleSingle props: ', props);
  }

  return renderContent();
}

export default RWRuleSingle;
