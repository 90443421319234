// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';

import closeIcon from '../../../../../img/icons/close.svg';
import { sendEmailAlert, TRANSACTION_EMAIL_TYPE_TO_LABEL } from '../../../../../helpers';

import './style.css';

const gtconfig = require('../../../../../gtconfig');

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}


function SelectTransactionEmailModal(props) {
  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  const [selectedTransactionEmailTemplate, setSelectedTransactionEmailTemplate] = useState({});

  const [selectError, setSelectError] = useState('');

  useEffect(() => {
  }, []);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCloseClick = (e) => {
    props.setModalVisible(false);
  };

  const onCancelClick = (e) => {
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    if (!selectedTransactionEmailTemplate || !selectedTransactionEmailTemplate._id) {
      setSelectError('error');
    } else {
      setSelectError('');
    }
    props.onAffirmativeClick(selectedTransactionEmailTemplate);
  };

  const onRowSelect = (data) => {
    setSelectError('');
    setSelectedTransactionEmailTemplate(data);
  };


  const renderModal = () => {

    const { transaction_emails } = props.transactionEmails;

    return (
      <Modal>
        <div id="cModelId" className="sentEmailPreviewModal" onClick={onModalClick}>
          <div className="content">
            <div className="sdMdl">
              <div className="ctnt">
                <div className="gPWrp selTrEml">
                  <div className="gPTle title">
                    {
                      props.title || 'Select'
                    }
                    <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
                  </div>
                  <div className="gPTle subTitle">
                    Choose an email to send
                  </div>
                  <div className="gBCtnt">

                  {
                    transaction_emails && transaction_emails.list && transaction_emails.keyValue &&
                    transaction_emails.list.length
                    ?
                      transaction_emails.list.map((item) => {
                        if (transaction_emails.keyValue[item] &&
                          (
                            (
                              props.reservationData &&
                              (
                                (props.reservationData.transaction_type === 'Reservation' &&
                                  (transaction_emails.keyValue[item].type === 'booking' ||
                                    transaction_emails.keyValue[item].type === 'pre-arrival' ||
                                    transaction_emails.keyValue[item].type === 'custom'
                                  )
                                ) ||
                                (props.reservationData.transaction_type === 'CheckIn' &&
                                  (transaction_emails.keyValue[item].type === 'check-in' ||
                                    transaction_emails.keyValue[item].type === 'custom'
                                  )
                                ) ||
                                (props.reservationData.transaction_type === 'CheckOut' &&
                                  (transaction_emails.keyValue[item].type === 'check-out' ||
                                    transaction_emails.keyValue[item].type === 'custom'
                                  )
                                )
                              )
                            ) ||
                            (
                              !props.reservationData && transaction_emails.keyValue[item].type === 'custom'
                            ) ||
                            (
                              props.selectedGuestsType &&
                              Object.keys(props.selectedGuestsType).length === 1 &&
                              (
                                (Object.keys(props.selectedGuestsType)[0] === 'Reservation' &&
                                  (transaction_emails.keyValue[item].type === 'booking' ||
                                    transaction_emails.keyValue[item].type === 'pre-arrival' ||
                                    transaction_emails.keyValue[item].type === 'custom'
                                  )
                                ) ||
                                (Object.keys(props.selectedGuestsType)[0] === 'CheckIn' &&
                                  (transaction_emails.keyValue[item].type === 'check-in' ||
                                    transaction_emails.keyValue[item].type === 'custom'
                                  )
                                ) ||
                                (Object.keys(props.selectedGuestsType)[0] === 'CheckOut' &&
                                  (transaction_emails.keyValue[item].type === 'check-out' ||
                                    transaction_emails.keyValue[item].type === 'custom'
                                  )
                                )
                              )
                            )
                          )
                        ) {
                          let ln2 = `Subject: ${transaction_emails.keyValue[item].subject}`;
                          return (
                            <div className={'trEntry ' + (selectedTransactionEmailTemplate._id === item ? ' selected' : '')}
                              onClick={() => onRowSelect(transaction_emails.keyValue[item])}
                              key={`sl_tr_em_${item}`}
                            >
                              <span><b>{TRANSACTION_EMAIL_TYPE_TO_LABEL[transaction_emails.keyValue[item].type]}</b></span>
                              <span>{ln2}</span>
                            </div>
                          );
                        }
                        return null;
                      })
                    : null
                  }
                  {
                    selectError &&
                    <div className="gBCtRw selErr">
                      <div className="gErr">
                        Selection required
                      </div>
                    </div>
                  }
                    <div className="gBCtRw btnRw">
                      <button className="gBtn ok" onClick={onAffirmativeClick}>
                        { props.affirmativeButtonLabel || 'Send' }
                        {
                          props.inProgress &&
                          <span>
                            <i className="fa fa-spinner fa-spin" />
                          </span>
                        }
                      </button>
                      <button className="gBtn cancel" onClick={onCloseClick}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const renderContent = () => {
    if (showModal) return renderModal();
    return null;
  };

  return renderContent();
}

export default SelectTransactionEmailModal;
