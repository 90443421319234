// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import { changeInValuePercent } from '../../../helpers';

import ToolTipWrapper from '../ToolTipWrapper';

import './style.css';

function MixedCard(props) {
  const onCtaClick = (e) => {
    props.onCtaClick();
  };

  const { label, value, compareNow, cmpValue, labelToolTip, ctaLabel, ctaLink, adClass, warningToolTip, getInProgress } = props.data;

  return (
    <div className={'gCrd gMixedCard ' + (adClass ? adClass : '')}>
      <div className="top">
        <p>
          {label}
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay={labelToolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </p>
      </div>
      <div className="middle">
        <div className="left">
          {
            value ? <p>{value}</p> : <span>-</span>
          }
          { compareNow && !getInProgress && cmpValue ? <p className='cmpVal'>vs {cmpValue}</p> : null }
          { value && compareNow && !getInProgress && cmpValue ? <p className='cmpChng'>{changeInValuePercent(value, cmpValue)}</p> : null }
          {
            warningToolTip &&
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={warningToolTip}
            >
              <i class="fa-solid fa-triangle-exclamation" />
            </ToolTipWrapper>
          }
        </div>
        <div className="right">
          <p></p>
        </div>
      </div>
      <div className="footer">
        {/*
          ctaLabel &&
          <p onClick={onCtaClick}>{ctaLabel}</p>
        */}
        {
          ctaLink && ctaLabel &&
          <Link to={ctaLink}>{ctaLabel}</Link>
        }
      </div>
    </div>
  );
}

export default MixedCard;
