import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { isGTUser, hasProductAccess } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';

import '../style.css';

function LocationRow(props) {
  const onConnectClick = (e) => {
    if (props.data.connectionInProgress) return;
    props.onConnectClick(props.data);
  }
  const onDisconnectClick = (e) => {
    if (props.data.disconnectionInProgress) return;
    props.onDisconnectClick(props.data);
  }
  return (
    <div className="lRow">
      <div className="left">
        {
          props.data.welcome_screen_image_url &&
          <img src={props.data.welcome_screen_image_url} />
        }
      </div>
      <div className="right">
        <p className="name">{props.data.name}
          {
            isGTUser(props.user) &&
            ` ${props.data.location_id} - ${props.data.serverid}`
          }
        </p>
        <p className="address">{props.data.address}</p>
        <div className="cntRw">
          {
            (props.data.remote_lock_connnected || props.data.connected) && !props.data.disconnected
            ? 
              <>
                <span>Connected with RemoteLock</span>
                <button className="gBtn warning" onClick={onDisconnectClick}>
                  Disconnect
                  {
                    props.data.disconnectionInProgress &&
                    <span><i className="fa fa-spinner fa-spin" /></span>
                  }
                </button>
              </>
            : null
          }
          {
            !props.data.remote_lock_connnected && !props.data.connected
            ?
            <button className="gBtn" onClick={onConnectClick}>
              Connect
              {
                props.data.connectionInProgress &&
                <span><i className="fa fa-spinner fa-spin" /></span>
              }
            </button>
            : null
          }
        </div>
      </div>
    </div>
  );
}

function RemoteLock(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const intgState = useSelector((state) => state.intgReducer);
  const snackState = useSelector((state) => state.snackReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const [somethingConnected, setSomethingConnected] = useState(false);
  const [somethingDisconnected, setSomethingDisconnected] = useState(false);
  const [connectedLocationMid, setConnectedLocationMid] = useState('');

  const getData = () => {
    dispatch(getDataAction({
      op: 'get_intg_configure_data',
      params: {
      },
    }));
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RemoteLock location: ', location);
    }
    dispatch(getDataClearAction({
      op: 'get_intg_configure_data',
      params: {}
    }));
    getData();
  }, []);

  useEffect(() => {
    if (intgState.intg_data && intgState.intg_data.locations && intgState.intg_data.locations_list) {
      let connectedFound = false;
      intgState.intg_data.locations_list.forEach((item) => {
        if (intgState.intg_data.locations[item] && intgState.intg_data.locations[item].connected) {
          connectedFound = true;
          setConnectedLocationMid(item);
        }
      });
      setSomethingConnected(connectedFound);
    }
  }, [intgState.intg_data])

  const onGetDataClick = (e) => {
    getData();
  };

  const onConnectClick = (data) => {
    if (somethingConnected) return;
    if (process.env.NODE_ENV !== 'production') {
      console.log('RemoteLock onConnectClick data: ', data);
    }
    const queryJson = queryString.parse(location.search);
    const { code } = queryJson;
    if (code && data && data._id) {
      dispatch(editDataAction({
        op: 'edit_intg_configure_data',
        params: {
          code,
          partner: 'remote-lock',
          location__id: data._id,
        },
      }));
    }
  };

  const onDisconnectClick = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RemoteLock onDisconnectClick data: ', data);
    }
    const queryJson = queryString.parse(location.search);
    // const { code } = queryJson;
    // if (code && data && data._id) {
      dispatch(editDataAction({
        op: 'edit_intg_configure_data',
        params: {
          disconnect: true,
          partner: 'remote-lock',
          location__id: data._id,
        },
      }));
    // }
    setSomethingDisconnected(true);
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('RemoteLock: ', props);
    console.log('RemoteLock intgState: ', intgState);
  }

  const { intg_data } = intgState;
  if (!intg_data) return null;

  const { locations_list, locations } = intg_data;

  if (process.env.NODE_ENV !== 'production') {
    console.log('RemoteLock locations_list: ', locations_list);
    console.log('RemoteLock locations: ', locations);
  }

  return (
    <div className="gPScrlWrp flHt allHme">
      <div className="gPWrp homeAllLocsPg">
        <div className="gPTle" onClick={onGetDataClick}>
          Choose the location to connect!
        </div>
        <div className="gBCtnt">
          {
            locations_list && locations && locations_list.length
            ?
              locations_list.map((locationId) => {
                if (locations[locationId]) {
                  return (
                    <LocationRow data={locations[locationId]}
                      key={`lr_${locationId}`}
                      user={userState.user} appInfo={appState.appInfo}
                      onConnectClick={onConnectClick}
                      onDisconnectClick={onDisconnectClick}
                      somethingConnected={somethingConnected}
                    />
                  )
                }
                return null;
              })
            : null
          }
        </div>
        {
          somethingConnected && !somethingDisconnected &&
          <div className="cntFollowUp">
            <p>Congratulations! Remote Lock is now connected. Please procees to Engage to retrieve and map locks.</p>
            <p><Link to={`/settings/locations/${connectedLocationMid}/hotel-details/remote-locks`}>Get Locks</Link></p>
          </div>
        }
      </div>
    </div>
  );

}

export default RemoteLock;
