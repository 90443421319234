
// import * as userProfileHelper from './userProfileHelper';

const initialState = {
};

const intgReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT': {
      return initialState;
    }

    case "GET_INTG_CONFIGURE_DATA_CLEAR": {
      return initialState;
    }

    case "GET_INTG_CONFIGURE_DATA": {
      return {
        ...state,
        getDataInProgress: true,
        getDataStatus: {},
      };
    }

    case "GET_INTG_CONFIGURE_DATA_SUCCESS": {
      const { data } = action.payload;
      const { intg_data } = data;
      return {
        ...state,
        intg_data,
        getDataInProgress: false,
        getDataStatus: {
          success: true,
        },
      };
    }
    case "GET_USER_INFO_ERROR": {
      const { error, errorMessage, params } = action.payload;
      return {
        ...state,
        getDataInProgress: false,
        getDataStatus: {
          error: true,
        },
        error,
        errorMessage,
      };
    }


    case "EDIT_INTG_CONFIGURE_DATA_CLEAR": {
      const { params: { code, location__id, partner, disconnect } } = action.payload;
      if (!location__id || !partner) return state;
      if (disconnect) {
        return {
          ...state,
          intg_data: {
            ...state.intg_data,
            locations: {
              ...state.intg_data.locations,
              [location__id]: {
                ...state.intg_data.locations[location__id],
                disconnectionInProgress: false,
                disconnectionStatus: {},
              }
            }
          },
        };
      }
      return {
        ...state,
        intg_data: {
          ...state.intg_data,
          locations: {
            ...state.intg_data.locations,
            [location__id]: {
              ...state.intg_data.locations[location__id],
              connectionInProgress: false,
              connectionStatus: {},
            }
          }
        },
      };
    }

    case "EDIT_INTG_CONFIGURE_DATA": {
      const { params: { code, location__id, partner, disconnect } } = action.payload;
      if (!location__id || !partner) return state;
      if (disconnect) {
        return {
          ...state,
          intg_data: {
            ...state.intg_data,
            locations: {
              ...state.intg_data.locations,
              [location__id]: {
                ...state.intg_data.locations[location__id],
                disconnectionInProgress: true,
                disconnectionStatus: {},
              }
            }
          },
        };
      }
      if (!code || !partner) return state;
      return {
        ...state,
        intg_data: {
          ...state.intg_data,
          locations: {
            ...state.intg_data.locations,
            [location__id]: {
              ...state.intg_data.locations[location__id],
              connectionInProgress: true,
              connectionStatus: {},
            }
          }
        },
      };
    }

    case "EDIT_INTG_CONFIGURE_DATA_SUCCESS": {
      const { data, params } = action.payload;
      const { code, location__id, partner, disconnect } = params;
      if (!location__id || !partner) return state;
      if (disconnect) {
        return {
          ...state,
          intg_data: {
            ...state.intg_data,
            locations: {
              ...state.intg_data.locations,
              [location__id]: {
                ...state.intg_data.locations[location__id],
                disconnected: true,
                disconnectionInProgress: false,
                disconnectionStatus: {
                  success: true,
                },
              }
            },
          },
        };
      }
      if (!code) return state;
      return {
        ...state,
        intg_data: {
          ...state.intg_data,
          locations: {
            ...state.intg_data.locations,
            [location__id]: {
              ...state.intg_data.locations[location__id],
              connected: true,
              connectionInProgress: false,
              connectionStatus: {
                success: true,
              },
            }
          },
        },
      };
    }

    case "EDIT_INTG_CONFIGURE_DATA_ERROR": {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { code, location__id, partner, disconnect } = params;
      if (!location__id || !partner) return state;
      if (disconnect) {
        return {
          ...state,
          intg_data: {
            ...state.intg_data,
            locations: {
              ...state.intg_data.locations,
              [location__id]: {
                ...state.intg_data.locations[location__id],
                disconnectionInProgress: false,
                disconnectionStatus: {
                  error: true,
                },
                error,
                errorMessage,
              }
            },
          },
        };
      }
      if (!code) return state;
      return {
        ...state,
        intg_data: {
          ...state.intg_data,
          locations: {
            ...state.intg_data.locations,
            [location__id]: {
              ...state.intg_data.locations[location__id],
              connectionInProgress: false,
              connectionStatus: {
                error: true,
              },
              error,
              errorMessage,
            }
          },
        },
      };
    }

    case "EDIT_USER_INFO_ERROR": {
      const { error, errorMessage, params } = action.payload;
      const { code, location__id, partner, disconnect } = params;
      if (!location__id || !partner) return state;
      if (disconnect) {
        return {
          ...state,
          intg_data: {
            ...state.intg_data,
            locations: {
              ...state.intg_data.locations,
              [location__id]: {
                ...state.intg_data.locations[location__id],
                disconnectionInProgress: false,
                disconnectionStatus: {
                  error: true,
                },
                error,
                errorMessage,
              }
            },
          },
        };
      }
      if (!code) return state;
      return {
        ...state,
        intg_data: {
          ...state.intg_data,
          locations: {
            ...state.intg_data.locations,
            [location__id]: {
              ...state.intg_data.locations[location__id],
              connectionInProgress: false,
              connectionStatus: {
                error: true,
              },
              error,
              errorMessage,
            }
          },
        },
      };
    }

  }
  return state;
};
export default intgReducer;
