import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

// import SideBar from './SideBar';

import './SettingsHome.css';

function SettingsReputationHome() {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();
  let urlParams = useParams();

  const [screenBeforeSettings, setScreenBeforeSettings] = useState({});

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { state } = location;
    setScreenBeforeSettings(state || {});
  }, [location]);

  const { locationidentifier } = urlParams;

  const LinkReviewChannels = <Link to={`/settings/locations/${locationidentifier}/review-channels`} state={screenBeforeSettings}>Manage Review Channels</Link>

  const LinkAlerts = <Link to={`/settings/locations/${locationidentifier}/alerts`} state={screenBeforeSettings}>Manage Alerts</Link>

  const LinkResponseTemplates = <Link to={`/settings/locations/${locationidentifier}/response-templates/templates`} state={screenBeforeSettings}>Response Templates</Link>

  const LinkAutoResponses = <Link to={`/settings/locations/${locationidentifier}/response-approval`} state={screenBeforeSettings}>Response Approval</Link>

  const LinkReports = <Link to={`/settings/locations/${locationidentifier}/reports`} state={screenBeforeSettings}>Reports</Link>

  const LinkReviewsWidget = <Link to={`/settings/locations/${locationidentifier}/reviews-widget`} state={screenBeforeSettings}>Reviews Widget</Link>

  const { settings } = locationState.locations[locationidentifier];
  if (!settings || !settings.info) return null;

  return (
    <div className="sCw sh">
      <div className="hdr">
        <h4>Reputation Settings</h4>
      </div>
      {
        locationidentifier &&
        <div className="bd">
          <div className="sbx">
            <ul>
              {/*<li>{LinkReviewChannels}</li>*/}
              <li>{LinkAlerts}</li>
              <li>{LinkResponseTemplates}</li>
              {
                settings.info.has_gt_response && <li>{LinkAutoResponses}</li>
              }
              <li>{LinkReports}</li>
              <li>{LinkReviewsWidget}</li>
            </ul>
          </div>
        </div>
      }
    </div>
  );
}

export default SettingsReputationHome;
