import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import resetIcon from '../../../../../img/icons/reset.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import { ACCESS_LEVELS_TO_LABEL, ACCESS_LEVELS, hasAccessToUserManagment,
curUserHasAccessToOtherUserByAccessLevel, formatDateTime } from '../../../../../helpers';

import '../style.css';

function UserRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onResetPasswordClick = (e) => {
    props.onResetPasswordClick(props.template);
  };
  const onDisableClick = (e) => {
    props.onDisableClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template, userState } = props;
  let cStyle = {};
  cStyle['backgroundColor'] = template.color;
  let accessLevelMap = {}, accessLevelList = [];
  if (template.profile && template.profile.locations && Object.keys(template.profile.locations).length) {
    Object.keys(template.profile.locations).forEach((item) => {
      let aL = template.profile.locations[item].access_level || '';
      if (aL) {
        if (!accessLevelMap[aL]) {
          accessLevelMap[aL] = aL;
          accessLevelList.push(ACCESS_LEVELS_TO_LABEL[aL]);
        }
      }
      if (template.profile.locations[item].reputation && template.profile.locations[item].reputation.access_level) {
        aL = template.profile.locations[item].reputation.access_level;
        if (!accessLevelMap[aL]) {
          accessLevelMap[aL] = aL;
          accessLevelList.push(ACCESS_LEVELS_TO_LABEL[aL]);
        }
      }
      if (template.profile.locations[item].engage && template.profile.locations[item].engage.access_level) {
        aL = template.profile.locations[item].engage.access_level;
        if (!accessLevelMap[aL]) {
          accessLevelMap[aL] = aL;
          accessLevelList.push(ACCESS_LEVELS_TO_LABEL[aL]);
        }
      }
      if (template.profile.locations[item].marketing && template.profile.locations[item].marketing.access_level) {
        aL = template.profile.locations[item].marketing.access_level;
        if (!accessLevelMap[aL]) {
          accessLevelMap[aL] = aL;
          accessLevelList.push(ACCESS_LEVELS_TO_LABEL[aL]);
        }
      }

    });
  }

  if (
    props.userState.user.user_info.uid !== template.username &&
    !curUserHasAccessToOtherUserByAccessLevel(props.userState.user.user_info.profile.access_level, template.profile.access_level)
  ) {
    return null;
  }

  return (
    <div className={'gBCtRw usrRw' + (template.is_disabled ? ' usrDsbled' : '')} id={`lbl_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            template.is_disabled &&
            <p className="dsbleUsr">[ User Deactivated ]</p>
          }
          <p className="usrNm">
            {
              template.profile.first_name
              ?
                <span>
                  {
                    props.keyword
                    ?
                      <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[props.keyword]}
                        autoEscape={true}
                        textToHighlight={template.profile.first_name}
                      />
                    : template.profile.first_name
                  }
                </span>
              : null
            }
            {
              template.profile.last_name
              ?
                <span>
                  {
                    props.keyword
                    ?
                      <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[props.keyword]}
                        autoEscape={true}
                        textToHighlight={template.profile.last_name}
                      />
                    : template.profile.last_name
                  }
                </span>
              : null
            }
          </p>
          <p>
            {
              props.keyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.keyword]}
                  autoEscape={true}
                  textToHighlight={template.username}
                />
              : template.username
            }
          </p>
          {
            curUserHasAccessToOtherUserByAccessLevel(props.userState.user.user_info.profile.access_level, template.profile.access_level) &&
            <p className="lstLgn">
              Last login at {formatDateTime(template.date_last_login)}
            </p>
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
              {
                (props.userState.user.user_info.uid === template.username ||
                template.profile.access_level !== userState.user.user_info.profile.access_level) &&
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
                  <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
                </ToolTipWrapper>
              }
              {
                props.userState.user.user_info.uid !== template.username &&
                template.profile.access_level !== userState.user.user_info.profile.access_level &&
                <>
                  <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Reset Password">
                    <span onClick={onResetPasswordClick}><img className="edit" src={resetIcon} /></span>
                  </ToolTipWrapper>
                  <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={template.is_disabled ? 'Activate' : 'Deactivate'}>
                    <span onClick={onDisableClick} className="enblDisbl"><i className="fa-solid fa-user-large-slash"></i></span>
                  </ToolTipWrapper>
                  <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
                    <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
                  </ToolTipWrapper>
                </>
              }
            </>
          }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          {
            template.profile.access_level
            ?
            <p>Access Level: {ACCESS_LEVELS_TO_LABEL[template.profile.access_level]}</p>
            :
            <>
              {/*
                template.profile && template.profile.locations && Object.keys(template.profile.locations).length
                ?
                  <p>Access Level: {accessLevelList.join(', ')}</p>
                : null
              */}
            </>
          }

          {
            template.profile && template.profile.locations && Object.keys(template.profile.locations).length
            ?
              <>
                <p className="usrLocTtl">Locations:</p>
                {
                  Object.keys(template.profile.locations).map((locMId) => {
                    let locInfo = userState.user && userState.user.user_info && userState.user.user_info.locations && userState.user.user_info.locations[locMId]
                      ? { ...userState.user.user_info.locations[locMId] } : null;
                    if (!locInfo) return;
                    return (
                      <div className="usrLocNm">
                        <span className="name">{locInfo.name}</span>
                        {
                          !template.profile.access_level &&
                          template.profile.locations &&
                          template.profile.locations[locMId] &&
                          <>
                            {
                              template.profile.locations[locMId].access_level
                              ?
                                <>
                                  {ACCESS_LEVELS_TO_LABEL[template.profile.locations[locMId].access_level]}
                                </>
                              :
                                <>
                                  {
                                    Object.keys(template.profile.locations[locMId]).map((el) => {
                                      if (el === 'reputation' && template.profile.locations[locMId]['reputation'].access_level) {
                                        return (
                                          <span>Reputation({ACCESS_LEVELS_TO_LABEL[template.profile.locations[locMId]['reputation'].access_level]})</span>
                                        );
                                      }
                                      if (el === 'engage' && template.profile.locations[locMId]['engage'].access_level) {
                                        return (
                                          <span>Engage({ACCESS_LEVELS_TO_LABEL[template.profile.locations[locMId]['engage'].access_level]})</span>
                                        );
                                      }
                                      if (el === 'marketing' && template.profile.locations[locMId]['marketing'].access_level) {
                                        return (
                                          <span>Marketing({ACCESS_LEVELS_TO_LABEL[template.profile.locations[locMId]['marketing'].access_level]})</span>
                                        );
                                      }
                                      return null;
                                    })
                                  }
                                </>
                            }
                          </>
                        }
                        {/*<div className="usrLocPrds">
                          {template.profile.locations[locMId].reputation ? <span>Reputation</span> : null}
                          {template.profile.locations[locMId].engage ? <span>Engage</span> : null}
                        </div>*/}
                      </div>
                    )
                  })
                }
              </>
            : <p>No Locations</p>
          }
        </div>
      </div>
    </div>
  )

}

export default UserRow;
