
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import ToolTipWrapper from '../../Misc/ToolTipWrapper';
import GenericAlertModal from '../../Misc/GenericAlertModal';

import ManageGroup from './ManageGroup';
import ManageGroupSharing from './ManageGroupSharing';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { isGTUser, isLocationAdminAndAbove, getUserGAId } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';
import editIcon from '../../../img/icons/edit.svg';
import deleteIcon from '../../../img/icons/delete.svg';
import shareIcon from '../../../img/icons/share.svg';

import './style.css';

const gtconfig = require('../../../gtconfig');

const DEFAULT_GROUP_NAME = "Default Group";

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function renderSplLoader() { return ( <span><i className="fa fa-circle-notch fa-spin" /></span> ) }

function GroupRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.data);
  };
  const onShareClick = (e) => {
    props.onShareClick(props.data);
  };
  const onRemoveClick = (e) => {
    props.onRemoveClick(props.data);
  };
  // if (props.data._id === 'default' && !props.data.numberOfLocations) return null;

  // let numLocations = 0;
  // if (!props.isDefault) {
  //   const { locations } = props.user.user_info;
  //   if (locations && props.data.list && props.data.list.length) {
  //     props.data.list.forEach((item) => {
  //       if (locations[item._id]) numLocations++;
  //     })
  //   }
  // }

  return (
    <div className="lRow">
      {
        props.sharedGroup &&
        <div className="shrGrp">
          Shared Group
        </div>
      }
      <div className="tp">
        <div className="left">
          <p className="name">
            {props.data.name}
            {
              props.isDefault &&
              <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
                overlay={'Default group for all your locations.'}
              >
                <i className="fas fa-info-circle" />
              </ToolTipWrapper>
            }
          </p>
          {
            props.getSummaryDataInProgress && renderSplLoader()
          }
          {
            !props.getSummaryDataInProgress &&
            <Link to={`/reputation/groups/${props.data._id}/dashboard`}><p className="viewData">View Data</p></Link>
          }
        </div>
        <div className="right">
          <div className="entry">
            <p className="title">Number Of Locations</p>
            {
              !props.isDefault
              ? <p className="value">{props.data.list ? props.data.list.length : '-'}</p>
              :
              <>
                {
                  props.getSummaryDataInProgress && renderSplLoader()
                }
                {
                  !props.getSummaryDataInProgress &&
                  <p className="value">{props.data.numberOfLocations || '-'}</p>
                }
              </>
            }
          </div>
          <div className="entry overallScore">
            <p className="title">
              Overall Score
              <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
                overlay={'Overall Score'}
              >
                <i className="fas fa-info-circle" />
              </ToolTipWrapper>
            </p>

            <p className="value">
              {
                props.getSummaryDataInProgress && renderSplLoader()
              }
              {
                props.data.summary && props.data.summary.current_ratings && props.data.summary.current_ratings.all && props.data.summary.current_ratings.all.overall
                ? props.data.summary.current_ratings.all.overall
                : '-'
              }
            </p>
          </div>
        </div>
        <div className="share">
          {
            props.sharedGroup
            ?
            <>
              <div className="sharedBy">
                <span>shared by</span>
                {
                  props.data.shared_by && Object.keys(props.data.shared_by).length
                  ?
                    Object.keys(props.data.shared_by).map((el) => {
                      return (
                        <span>{el}</span>
                      )
                    })
                  : null
                }
              </div>
            </>
            :
            <>
              {
                !props.isDefault &&
                <>
                  <div className="tp">
                    <ToolTipWrapper placement="top" overlayClassName={'sbTooltip'}
                      overlay={'Share group with your team'}
                    >
                      <span onClick={onShareClick}><img src={shareIcon} /></span>
                    </ToolTipWrapper>
                  </div>
                  <div className="bt">
                    {
                      props.data.share_data && Object.keys(props.data.share_data).length
                      ?
                        Object.keys(props.data.share_data).map((item) => {
                          if (props.data.share_data[item].is_shared) {
                            return (
                              <span>{item}</span>
                            )
                          }
                          return null;
                        })
                      : null
                    }
                  </div>
                </>
              }
            </>
          }
        </div>
        <div className="edit">
          {
            !props.sharedGroup
            ?
            <>
              {
                !props.isDefault &&
                <ToolTipWrapper placement="top" overlayClassName={'sbTooltip'}
                  overlay={'Edit'}
                >
                  <span onClick={onEditClick}><img src={editIcon} /></span>
                </ToolTipWrapper>
              }
            </>
            :
            <>
              {
                !props.isDefault &&
                <ToolTipWrapper placement="top" overlayClassName={'sbTooltip'}
                  overlay={'Remove'}
                >
                  <span onClick={onRemoveClick}><img src={deleteIcon} /></span>
                </ToolTipWrapper>
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}

function Groups(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const groupState = useSelector((state) => state.groupReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState({});
  const [manageGroupMode, setManageGroupMode] = useState('');
  const [showManageGroupModal, setShowManageGroupModal] = useState(false);

  const [showManageGroupSharingModal, setShowManageGroupSharingModal] = useState(false);

  const [showRemoveSharedGroupModal, setShowRemoveSharedGroupModal] = useState();
  const [selectedSharedGroupToRemove, setSelectedSharedGroupToRemove] = useState({});

  const getGroupsData = () => {
    dispatch(getDataAction({
      op: 'get_groups',
      params: {},
    }));
  };

  const getGroupsSummaryData = () => {
    const { groups, shared_groups } = groupState;

    let params = {};
    let groupIdList = groups.list || [];
    groupIdList = [ ...groupIdList, 'default' ]; // nkcheck
    params.group_id_list = groupIdList;
    let sharedGroupIdList = shared_groups.list || [];
    params.shared_group_id_list = sharedGroupIdList;
    dispatch(getDataAction({
      op: 'get_groups_summary_data',
      params,
    }));
  };

  // analytics reporting
  useEffect(() => {
    document.title = `Reputation - All Groups`;
    try {
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    getGroupsData();

  }, []);

  useEffect(() => {
    const { list, keyValue } = groupState.groups;
    if (list && list.length && keyValue) {
      if (searchKeyword && searchKeyword.length > 2) {
        const tList = list.filter((groupId) => {
          if (!keyValue[groupId]) return false;
          if (new RegExp(searchKeyword, 'i').test(keyValue[groupId].name)
          ) {
            return true;
          }
          return false;
        });
        setFilteredList(tList);
      } else {
        setFilteredList(list);
      }
    }
  }, [searchKeyword]);

  useEffect(() => {
    // const { groups } = groupState;
    // if (groups && !groups.getInProgress && groups.getStatus && groups.getStatus.success) {
    //   dispatch(getDataClearAction({
    //     op: 'get_groups',
    //     params: {},
    //   }));
    //   let params = {};
    //   let groupIdList = groups.list || [];
    //   groupIdList = [ ...groupIdList, 'default' ];
    //   params.group_id_list = groupIdList;
    //   dispatch(getDataAction({
    //     op: 'get_groups_summary_data',
    //     params,
    //   }));
    // }
    const { groups } = groupState;
    if (groups && !groups.getInProgress && groups.getStatus && groups.getStatus.success) {
      dispatch(getDataClearAction({
        op: 'get_groups',
        params: {},
      }));
      getGroupsSummaryData();
    }
  }, [groupState.groups]);

  useEffect(() => {
    const { shared_groups } = groupState;
    if (selectedSharedGroupToRemove && selectedSharedGroupToRemove._id &&
      shared_groups.keyValue && shared_groups.keyValue[selectedSharedGroupToRemove._id] &&
      !shared_groups.keyValue[selectedSharedGroupToRemove._id].removeInProgress &&
      shared_groups.keyValue[selectedSharedGroupToRemove._id].removeStatus &&
      shared_groups.keyValue[selectedSharedGroupToRemove._id].removeStatus.success) {
      setShowRemoveSharedGroupModal(false);
    }
  }, [groupState.shared_groups.keyValue]);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchClearClick = (e) => { setSearchKeyword(''); };
  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  const onAddGroup = (e) => {
    setSelectedGroup({});
    setManageGroupMode('add');
    setShowManageGroupModal(true);
    // document.body.style.overflow = 'hidden';
  };
  const onManageGroupCloseClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onManageGroupCloseClick e: ', e);
    }
    setShowManageGroupModal(false);
    if (e === 'add') getGroupsSummaryData();
    if (e === 'edit') getGroupsSummaryData();
    // document.body.style.overflow = 'auto';
  };

  const onGroupEditClick = (groupData) => {
    setSelectedGroup(groupData);
    setManageGroupMode('edit');
    setShowManageGroupModal(true);
    // document.body.style.overflow = 'hidden';
    // analytics event reporting
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Groups - Edit Group`,
        'event_label': `Edit Group - ${groupData.name || ''}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Groups`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onManageGroupSharingCloseClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onManageGroupSharingCloseClick e: ', e);
    }
    setShowManageGroupSharingModal(false);
  };

  const onGroupShareClick = (groupData) => {
    setSelectedGroup(groupData);
    setShowManageGroupSharingModal(true);
  };

  const onSharedGroupRemoveClick = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSharedGroupRemoveClick: ', data);
    }
    setSelectedSharedGroupToRemove(data);
    setShowRemoveSharedGroupModal(true);
  };
  const onRemoveSharedGroupCloseModal = (e) => {
    setShowRemoveSharedGroupModal(false);
  };
  const onRemoveSharedGroupConfirmClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveSharedGroupConfirmClick: ', selectedSharedGroupToRemove);
    }
    if (selectedSharedGroupToRemove && selectedSharedGroupToRemove._id && selectedSharedGroupToRemove.shared_by) {
      const { shared_groups } = groupState;
      if (shared_groups.keyValue && shared_groups.keyValue[selectedSharedGroupToRemove._id] &&
        shared_groups.keyValue[selectedSharedGroupToRemove._id].removeInProgress
      ) {
        return;
      }

      const params = {};
      params.group_id = selectedSharedGroupToRemove._id;
      params.shared_by = selectedSharedGroupToRemove.shared_by;
      if (process.env.NODE_ENV !== 'production') {
        console.log('onRemoveSharedGroupConfirmClick params: ', params);
      }
      dispatch(editDataAction({
        op: 'remove_shared_group',
        params,
        show_alert: true,
        alert_msg: 'Removed successfully',
      }));
    }
  };


  const renderManageGroupModal = () => {
    const { groups } = groupState;
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderManageGroupModal manageGroupMode: ', manageGroupMode);
      console.log('renderManageGroupModal selectedGroup: ', selectedGroup);
      console.log('renderManageGroupModal groups: ', groups);
    }
    if (manageGroupMode === 'edit' && (!groups || !groups.list || !groups.list.length)) return null;
    if (manageGroupMode === 'edit' && (!selectedGroup || !selectedGroup._id)) return null;
    // const { single_review } = queryString.parse(props.location.search);
    // if (!single_review) return null;
    // const locationId = getLocationIdFromUrl(props.location.pathname);
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('locationId: ', locationId);
    // }
    // if (!locationId) return null;
    // if (!props.locationprops.locations.keyValue[locationId] ||
    //   !props.locationprops.locations.keyValue[locationId].reviews ||
    //   !props.locationprops.locations.keyValue[locationId].reviews.keyValue
    // ) {
    //   return null;
    // }
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <div className={'sdMdl mngGrpMdl'}>
              <div className="ctnt">
                <ManageGroup
                  onCancelClick={onManageGroupCloseClick}
                  mode={manageGroupMode}
                  groupData={selectedGroup}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderManageGroupSharingModal = () => {
    const { groups } = groupState;
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderManageGroupSharingModal selectedGroup: ', selectedGroup);
      console.log('renderManageGroupSharingModal groups: ', groups);
    }
    if (!groups || !groups.users || !groups.users.length) return null;
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <div className={'sdMdl mngGrpMdl'}>
              <div className="ctnt">
                <ManageGroupSharing
                  onCancelClick={onManageGroupSharingCloseClick}
                  groupData={selectedGroup}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const renderDefaultGroup = (keyValue, getSummaryDataInProgress) => {
    const groupData = keyValue && keyValue['default'] ? keyValue['default'] : {};
    if (searchKeyword && !(new RegExp(searchKeyword, 'i').test(DEFAULT_GROUP_NAME))) return null;
    return (
      <GroupRow isDefault={true} name={DEFAULT_GROUP_NAME}
        data={{
          name: DEFAULT_GROUP_NAME,
          _id: 'default',
          numberOfLocations: groupData.summary && groupData.summary.locations ? groupData.summary.locations.length : 0,
          ...groupData,
        }}
        getSummaryDataInProgress={getSummaryDataInProgress}
      />
    );
  };

  const { list, keyValue, getSummaryDataInProgress } = groupState.groups;
  let accessLevel = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level ? userState.user.user_info.profile.access_level : '';

  const { shared_groups } = groupState;

  return (
    <>
      {
        groupState.groups.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      {/*
        groupState.groups.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      <div className="gPScrlWrp flHt mk">
        <div className="gPWrp allGrpsPg">
          <div className="gPTle add">
            Groups
            {
              isLocationAdminAndAbove(accessLevel) &&
              <span className="add" onClick={onAddGroup}><i className="fa fa-plus-circle" /></span>
            }
          </div>
          <div className="gBCtnt">
            <div className="gBCtRw">
              <div className="gRwBxLt">
                <div className="gIpt">
                  <input type="text" onChange={onSearchChange} placeholder="Search..." value={searchKeyword} onKeyDown={onSearchInputKeyDown}/>
                  {
                    searchKeyword &&
                    <img className="clear" src={closeIcon} onClick={onSearchClearClick} />
                  }
                </div>
              </div>
            </div>
            {
              searchKeyword && searchKeyword.length > 2
              ? <div className="gBCtRw">{filteredList.length} Found.</div>
              : null
            }
            { renderDefaultGroup(keyValue, getSummaryDataInProgress) }
            {
              list && list.length && keyValue
              ?
                list.map((groupId) => {
                  if (keyValue[groupId]) {
                    if (searchKeyword && searchKeyword.length > 2) {
                      if (new RegExp(searchKeyword, 'i').test(keyValue[groupId].name)
                      ) {
                        return (
                          <GroupRow data={keyValue[groupId]} user={userState.user} getSummaryDataInProgress={getSummaryDataInProgress}
                            key={`gr_${groupId}`}
                            onEditClick={onGroupEditClick}
                            onShareClick={onGroupShareClick}
                          />
                        )
                      }
                      return null;
                    }
                    return (
                      <GroupRow data={keyValue[groupId]} user={userState.user} getSummaryDataInProgress={getSummaryDataInProgress}
                        key={`gr_${groupId}`}
                        onEditClick={onGroupEditClick}
                        onShareClick={onGroupShareClick}
                      />
                    )
                  }
                  return null;
                })
              : null
            }
            {
              shared_groups.list && shared_groups.list.length && shared_groups.keyValue
              ?
                shared_groups.list.map((groupId) => {
                  if (shared_groups.keyValue[groupId] && !shared_groups.keyValue[groupId].is_removed) {
                    if (searchKeyword && searchKeyword.length > 2) {
                      if (new RegExp(searchKeyword, 'i').test(shared_groups.keyValue[groupId].name)
                      ) {
                        return (
                          <GroupRow data={shared_groups.keyValue[groupId]} user={userState.user} getSummaryDataInProgress={getSummaryDataInProgress}
                            key={`sgr_${groupId}`}
                            onRemoveClick={onSharedGroupRemoveClick}
                            sharedGroup={true}
                          />
                        )
                      }
                      return null;
                    }
                    return (
                      <GroupRow data={shared_groups.keyValue[groupId]} user={userState.user} getSummaryDataInProgress={getSummaryDataInProgress}
                        key={`sgr_${groupId}`}
                        onRemoveClick={onSharedGroupRemoveClick}
                        sharedGroup={true}
                      />
                    )
                  }
                  return null;
                })
              : null
            }
          </div>
        </div>
        { showManageGroupModal && renderManageGroupModal() }
        { showManageGroupSharingModal && renderManageGroupSharingModal() }
        { showRemoveSharedGroupModal &&
          <GenericAlertModal
            modalType="CheckOut"
            title={`Do you want to remove shared group (${selectedSharedGroupToRemove.name}) now?`}
            bodyRowOne="You will no longer have access to this shared group. Remove only in case of duplicates."
            bodyRowNote="Note: You cannot undo this action"
            setModalVisible={onRemoveSharedGroupCloseModal}
            affirmativeButtonLabel="Confirm"
            onAffirmativeClick={onRemoveSharedGroupConfirmClick}
            inProgress={selectedSharedGroupToRemove && selectedSharedGroupToRemove._id &&
              shared_groups.keyValue && shared_groups.keyValue[selectedSharedGroupToRemove._id]
              ? shared_groups.keyValue[selectedSharedGroupToRemove._id].removeInProgress
              : false
            }
            // currentStatus={guest.checkOutStatus}
            showCancel={true}
          />
        }
      </div>
    </>
  );
}

export default Groups;
