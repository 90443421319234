import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { isGTUser, hasProductAccess } from '../../helpers';

import closeIcon from '../../img/icons/close.svg';

import './style.css';

const gtconfig = require('../../gtconfig');

function LocationRow(props) {
  const { reputation_enabled, engage_enabled, marketing_enabled } = props.appInfo;
  const hasReputation = reputation_enabled && props.data.has_reputation && hasProductAccess(props.user, props.data._id, 'reputation');
  const hasEngage = engage_enabled && props.data.has_engage && hasProductAccess(props.user, props.data._id, 'engage');
  const hasMarketing = marketing_enabled && props.data.has_transaction_emails && hasProductAccess(props.user, props.data._id, 'marketing');
  if (
    hasReputation || hasEngage || hasMarketing
  ) {
    return (
      <div className="lRow">
        <div className="left">
          {
            props.data.welcome_screen_image_url &&
            <img src={props.data.welcome_screen_image_url} />
          }
        </div>
        <div className="right">
          <p className="name">{props.data.name}
            {
              isGTUser(props.user) &&
              ` ${props.data.location_id} - ${props.data.serverid}`
            }
          </p>
          <p className="address">{props.data.address}</p>
          <div className="prdLst">
            {
              hasReputation &&
              <Link to={`/reputation/locations/${props.data._id}/dashboard`}>
                Reputation
              </Link>
            }
            {
              hasEngage &&
              <Link to={`/engage/locations/${props.data._id}/messages`}>
                Engage
              </Link>
            }
            {
              hasMarketing &&
              <Link to={`/marketing/locations/${props.data._id}/overview`}>
                Marketing
              </Link>
            }
          </div>
        </div>
      </div>
    )
  }
  // return (<p>Null</p>);
  return null;
}

function Home(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const snackState = useSelector((state) => state.snackReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    document.title = `${gtconfig.APP_NAME_CONTACT}`;
    // window.Intercom('shutdown');
  }, []);

  useEffect(() => {
    let hasReputation = false, hasEngage = false, hasMarketing = false;
    if (userState.user.user_info && userState.user.user_info.locations &&
      Object.keys(userState.user.user_info.locations).length
    ) {
        Object.keys(userState.user.user_info.locations).forEach((locMId) => {
          const locInfo = userState.user.user_info.locations[locMId];
          if (!hasReputation) hasReputation = locInfo.has_reputation || false;
          if (!hasEngage) hasEngage = locInfo.has_engage || false;
        });
    }
  }, []);

  useEffect(() => {
    const { user_info } = userState.user;
    if (user_info) {
      const { locations_list, locations } = user_info;
      if (searchKeyword && searchKeyword.length > 2) {
        const tList = locations_list.filter((locationId) => {
          const { reputation_enabled, engage_enabled, marketing_enabled } = appState.appInfo;
          const hasReputation = reputation_enabled && locations[locationId].has_reputation && hasProductAccess(userState.user, locations[locationId]._id, 'reputation');
          const hasEngage = engage_enabled && locations[locationId].has_engage && hasProductAccess(userState.user, locations[locationId]._id, 'engage');
          const hasMarketing = marketing_enabled && locations[locationId].has_transaction_emails && hasProductAccess(userState.user, locations[locationId]._id, 'marketing');
          if (
            hasReputation || hasEngage || hasMarketing
          ) {
          if (new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
            new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
            new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
            new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
          ) {
            return true;
          }
          }
          return false;
        });
        setTimeout(() => {
          setFilteredList(tList);
        }, 100);
        // setFilteredList(tList);
      } else {
        setFilteredList(locations_list);
      }
    }
  }, [searchKeyword]);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchClearClick = (e) => { setSearchKeyword(''); };
  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Home: ', props);
  }

  const { user_info } = userState.user;
  if (!user_info) return null;

  const { locations_list, locations } = user_info;

  return (
    <div className="gPScrlWrp flHt allHme">
      <div className="gPWrp homeAllLocsPg">
        <div className="gPTle">
          Choose the product to start with!
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw hmeSrch">
            <div className="gRwBxLt">
              <div className="gIpt">
                <input type="text" onChange={onSearchChange} placeholder="Search..." value={searchKeyword} onKeyDown={onSearchInputKeyDown}/>
                {
                  searchKeyword &&
                  <img className="clear" src={closeIcon} onClick={onSearchClearClick} />
                }
              </div>
            </div>
          </div>
          {
            searchKeyword && searchKeyword.length > 2
            ? <div className="gBCtRw">{filteredList.length} Found.</div>
            : null
          }
          {/*
            locations_list && locations && locations_list.length
            ?
              locations_list.map((locationId) => {
                if (locations[locationId]) {
                  if (searchKeyword && searchKeyword.length > 2) {
                    if (new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
                      new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
                      new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
                      new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
                    ) {
                      return (
                        <LocationRow key={`lr_${locationId}`} data={locations[locationId]} user={userState.user} appInfo={appState.appInfo} />
                      )
                    }
                    return null;
                  }
                  return (
                    <LocationRow key={`lr_${locationId}`} data={locations[locationId]} user={userState.user} appInfo={appState.appInfo} />
                  )
                }
                return null;
              })
            : null
          */}
          {
            filteredList && filteredList.length
            ?
              filteredList.map((locationId) => {
                if (locations[locationId]) {
                  return (
                    <LocationRow key={`lr_${locationId}`} data={locations[locationId]} user={userState.user} appInfo={appState.appInfo} />
                  )
                }
                return null;
              })
            : null
          }
        </div>
      </div>
    </div>
  );

}

export default Home;
