import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

// import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import './style.css';


function Info(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showEditForm, setShowEditForm] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');

  const [someChange, setSomeChange] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      document.title = `Profile - Info`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { uid, contact } = props.userInfo;
    const { first_name, last_name } = props.userInfo.profile;
    setFirstName(first_name || '');
    setLastName(last_name || '');
    setEmail(uid || '');
    setContact(contact || '');
  };

  const clearAction = () => {
    const params = {};
    dispatch(editDataClearAction({
      op: 'edit_profile',
    }));
  };

  useEffect(() => {
    clearAction();
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.userInfo.editInProgress &&
      props.userInfo.editStatus &&
      props.userInfo.editStatus.success
    ) {
      setSomeChange(false);
    }
  }, [props.userInfo]);

  const onOpenEditFormClick = (e) => {
    setShowEditForm(true);
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'firstName') {
      setFirstName(value);
      setFirstNameError(value ? '' : 'error');
    }
    if (name === 'lastName') {
      setLastName(value);
      setLastNameError(value ? '' : 'error');
    }
    setSomeChange(true);
  };

  const onCancelClick = (e) => {
    setShowEditForm(false);
    setSomeChange(false);
    setInitialValues();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (someChange) performSave();
  };

  const performSave = () => {
    let hasError = false;

    if (!firstName) {
      hasError = true;
    }

    if (hasError) return;

    const params = {
      // location__id: urlParams.locationidentifier,
    };
    params['first_name'] = firstName;
    params['last_name'] = lastName;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_profile',
      show_alert: true,
      params,
    }));
    setSomeChange(false);
  };


  const renderProfileInfo = () => {
    const { uid, contact } = props.userInfo
    const { first_name, last_name } = props.userInfo.profile;
    return (
      <div className="gBCtRw">
        <div className="lt">
          <p className="title">{first_name} {last_name}</p>
          <p className="desc">{uid}</p>
          <p className="desc">{contact}</p>
        </div>
        <div className="rt">
        </div>
      </div>
    );
  };

  const renderProfileEdit = () => {
    const { uid, contact } = userState.user.user_info;
    return (
      <>
        <div className="gBCtRw prfEdtRw">
          <div className="lt">
            <div className="gLbl">
              First Name
            </div>
            <div className="gIpt">
              <input type="text" name="firstName" placeholder="First Name" value={firstName} onChange={onChange} />
            </div>
          </div>
          <div className="rt">
            <div className="gLbl">
              Last Name
            </div>
            <div className="gIpt">
              <input type="text" name="lastName" placeholder="Last Name" value={lastName} onChange={onChange} />
            </div>
          </div>
        </div>
        <div className="gBCtRw prfEdtRw">
          <div className="lt">
            <div className="gLbl">
              Email
            </div>
            <div className="gIpt">
              <input type="text" name="email" placeholder="Email" value={email} readOnly />
            </div>
          </div>
          <div className="rt">
            <div className="gLbl">
            Mobile
            </div>
            <div className="gIpt">
              <input type="text" name="contact" placeholder="Mobile" value={contact} readOnly />
            </div>
          </div>
        </div>
      </>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Info: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen prfInf">
        <div className="gPTle title">
          Profile Info
        </div>
        <div className="gBCtnt">
          { renderProfileInfo() }
          {
            !showEditForm &&
            <div className="gBCtRw btnRw prfEdtRw">
              <button type="submit" className={'gBtn ok '} onClick={onOpenEditFormClick} >
                Edit
              </button>
            </div>
          }
          {
            showEditForm && renderProfileEdit()
          }
          {
            showEditForm &&
            <div className="gBCtRw btnRw prfEdtSvRw">
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.userInfo.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
              <button type="submit" className={'gBtn cancel'} onClick={onCancelClick} >
                Cancel
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Info;
