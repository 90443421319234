import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import SideBar from '../Reputation/SideBar';
import Generate from './Generate';
import Overview from './Overview';
import History from './History';
import Configure from './Configure';
import RgUsers from './RgUsers';
import AddRgLocation from './Configure/AddRgLocation';


import { getDataAction } from '../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../redux/actions/editDataActions';

import { ACCESS_LEVELS, RG_SUBS, getResponseGeneratorAccess } from '../../helpers';

import './style.css';

const gtconfig = require('../../gtconfig');

function ResponseGenerator() {
  const userState = useSelector((state) => state.userReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [firstLocationAdded, setFirstLocationAdded] = useState(false);

  const getData = (startDate, endDate) => {
    dispatch(getDataAction({
      op: 'get_response_generator_locations',
      params: {},
    }));
  };

  useEffect(() => {
    document.title = `Review Response AI - ${gtconfig.APP_NAME_CONTACT}`;
    // window.Intercom('shutdown');
    // window.Intercom("boot", {
    //   api_base: "https://api-iam.intercom.io",
    //   app_id: "mydf4dtr",
    //   name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //     email: userState.user.uid, // the email for your user
    //   // created_at: user.createdAt, // Signup date as a Unix timestamp
    // });
    getData();
    if (process.env.NODE_ENV !== 'production') {
      console.log('ResponseGenerator location: ', location);
    }
    if (location.pathname === '/response-generator' || location.pathname === '/response-generator/') {
      navigate(`/response-generator/generate`);
    }
  }, []);

  useEffect(() => {
    const { locations } = responseGeneratorState;
    const hasRgLocation = locations && locations.list && locations.list.length === 1;
    if (hasRgLocation) {
      if (locations && !locations.addInProgress && locations.addStatus && locations.addStatus.success) {
        setFirstLocationAdded(true);
        const params = {};
        dispatch(editDataClearAction({
          op: 'add_rg_location',
          params,
        }));
      }
    }
  }, [responseGeneratorState.locations]);

  const onGenerateClick = (e) => {
    setFirstLocationAdded(false);
    navigate(`/response-generator/generate`);
  };
  const onConfigureClick = (e) => {
    const { locations } = responseGeneratorState;
    const firstRgLocation = locations && locations.list && locations.list.length ? locations.list[0] : '';
    if (firstRgLocation) {
      setFirstLocationAdded(false);
      navigate(`/response-generator/configure/${firstRgLocation}`);
    }
  };

  const onSubscribeNowClick = (e) => {
    try { window.Intercom('showNewMessage', `Subscribe Review Response AI`); } catch(e) {}
  };

  const isReadOnly = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;

  const hasRGAddLocationAccess = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN;

  if (!responseGeneratorState) return null;

  const { locations } = responseGeneratorState;
  const hasRgLocation = locations && locations.list && locations.list.length > 0;


  const { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure } = getResponseGeneratorAccess(userState.user.user_info, urlParams.rglocationidentifier);
  const rGAccess = { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure };

  const hasTrialNotice = userState.user.user_info && userState.user.user_info.profile &&
  userState.user.user_info.profile.rg_subscription === RG_SUBS.TRIAL;

  let trialNotice = 'Trial is expiring soon!';
  if (userState.user.user_info && userState.user.user_info.profile && userState.user.user_info.profile.rg_trial_end_date) {
    trialNotice = `Trial is expiring in ${moment(userState.user.user_info.profile.rg_trial_end_date).diff(moment(), 'days')} days!`
  }
  if (userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.rg_primary_user_id
  ) {
    trialNotice = `${trialNotice} Contact your administrator to subscribe!`
  }
  if (userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN
  ) {
    trialNotice = `${trialNotice} Subscribe Now!`
  }

  return (
    <div className={'mb rg' + (' sbClsd1')}>
      <Routes>
        <Route element={<Outlet />}>
          {/*<Route path="locations/*" element={<Outlet />}>
            <Route path=":locationidentifier/*" element={<SideBar isReadOnly={isReadOnly} />} />
            <Route index element={<SideBar isReadOnly={isReadOnly} />} />
          </Route>*/}
          <Route path="generate/*" element={<SideBar isReadOnly={isReadOnly} />} />
          <Route path="overview" element={<SideBar isReadOnly={isReadOnly} />} />
          <Route path="history" element={<SideBar isReadOnly={isReadOnly} />} />
          <Route path="configure/*" element={<SideBar isReadOnly={isReadOnly} />} />
          <Route path="users" element={<SideBar isReadOnly={isReadOnly} />} />
          <Route index element={<SideBar isReadOnly={isReadOnly} />} />
        </Route>
      </Routes>
      <div className={'mc' + (hasTrialNotice ? ' hasNtce' : '')}>
        {
          responseGeneratorState && responseGeneratorState.locations && responseGeneratorState.locations.getInProgress &&
          <div className="gPnlLdng">
            <i className="fa fa-spinner fa-spin" />
          </div>
        }
        {
          hasTrialNotice &&
          <div className="trlNtce"><span onClick={onSubscribeNowClick}>{trialNotice}</span></div>
        }
        {
          (!hasRgLocation || firstLocationAdded ||
            (location.pathname === '/response-generator' || location.pathname === '/response-generator/')
          )
          ?
            <div className="gPScrlWrp flHt mk">
              <div className="gPg rgGnrt onbrd">
                <div className="onbrdBx">
                  {
                    hasRGAddLocationAccess
                    ?
                      <>
                        {
                          !hasRgLocation && (responseGeneratorState.locations && !responseGeneratorState.locations.getInProgress) &&
                          <AddRgLocation />
                        }
                        {
                          firstLocationAdded &&
                          <div>Location Added successfully, <span onClick={onGenerateClick}>Generate</span> response or <span onClick={onConfigureClick}>Configure</span> the added location further</div>
                        }
                        {
                          (location.pathname === '/response-generator' || location.pathname === '/response-generator/') &&
                          <div><span onClick={onGenerateClick}>Generate</span> response or <span onClick={onConfigureClick}>Configure</span></div>
                        }
                      </>
                    :
                      <div>No locations added to your profile, contact your account administrator.</div>
                  }
                </div>
              </div>
            </div>
          :
        <Routes>
          <Route element={<Outlet />}>
            <Route path="generate/*" element={<Generate rGAccess={rGAccess} />}>
              <Route path=":generateidentifier/*" element={<Generate rGAccess={rGAccess} />} />
              <Route index element={<Generate rGAccess={rGAccess} />} />
            </Route>
            <Route path="overview" element={<Overview rGAccess={rGAccess} />} />
            <Route path="history" element={<History rGAccess={rGAccess} />} />
            {
              hasAccessToResponseGeneratorConfigure &&
              <Route path="configure/*" element={<Configure rGAccess={rGAccess} />} />
            }
            <Route path="users" element={<RgUsers rGAccess={rGAccess} />} />

            {/*}<Route path="locations" element={<Outlet />}>
              <Route path=":locationidentifier/*" element={<LocationWrapper isReadOnly={isReadOnly} />} />
              <Route index element={<Locations isReadOnly={isReadOnly} />} />
            </Route>
            <Route index element={<Locations isReadOnly={isReadOnly} />} />*/}
            </Route>
        </Routes>
      }
      </div>
    </div>
  );
}

export default ResponseGenerator;
