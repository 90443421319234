import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Dashboard from '../Dashboard';
import Messages from '../Messages';
import Broadcast from '../Broadcast';
import EngageUsage from '../EngageUsage';
import JourneyMessages from '../JourneyMessages';
import EnableEngage from '../EnableEngage';


import { initEngageAction, initSettingsAction } from '../../../redux/actions/initActions';
import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';

import { getProductAccess, getEngageAccess, ACCESS_LEVELS } from '../../../helpers';

const gtconfig = require('../../../gtconfig');

function LocationWrapper(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('LocationWrapper urlParams: ', urlParams);
      console.log('LocationWrapperurlParams locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(initSettingsAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
    dispatch(initEngageAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
    // get data only if the location is present in locations under user
    if (userState.user.user_info && userState.user.user_info.locations &&
      userState.user.user_info.locations[urlParams.locationidentifier]
    ) {
      dispatch(getDataAction({
        op: 'get_location_settings',
        params: {
          location__id: urlParams.locationidentifier,
          type: 'engage',
        },
      }));
      dispatch(getDataAction({
        op: 'get_unread_count',
        params: {
          location__id: urlParams.locationidentifier,
          type: 'all',
        },
      }));
    }
  }, [urlParams.locationidentifier]);

  useEffect(() => {
    if (locationState.locations[urlParams.locationidentifier]) {
      const { engage } = locationState.locations[locationidentifier];
      if (engage) {
        if (!engage.getUnreadCountInProgress && engage.getUnreadCountStatus && engage.getUnreadCountStatus.success) {
          dispatch(getDataAction({
            op: 'get_unread_count_for_user',
            params: {
              uid: userState.user.uid, // : 'neeraj.2197@gmail.com', // nkcheck
            },
          }));
          dispatch(getDataClearAction({
            op: 'get_unread_count',
            params: {
              location__id: urlParams.locationidentifier,
              type: 'all',
            },
          }));
        }
      }
    }
  }, [locationState.locations[urlParams.locationidentifier]])

  // useEffect(() => {
  //   const hasEngage = userState && userState.user && userState.user.user_info
  //   && userState.user.user_info.locations
  //   && userState.user.user_info.locations[urlParams.locationidentifier]
  //   ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage
  //   : false;
  //   if (!hasEngage) navigate(`${location.pathname}?demo=1`);
  // }, [userState.user.user_info.locations[urlParams.locationidentifier]]);

  const onEnableEngageClick = (e) => {
    // setTimeout(() => {
    //   window.Intercom("boot", {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "mydf4dtr",
    //     name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //       email: userState.user.uid, // the email for your user
    //     // created_at: user.createdAt, // Signup date as a Unix timestamp
    //   });
    // }, 500);
    
    // window.Intercom('showSpace', 'help');
    try { window.Intercom('showNewMessage', 'Enable engage for us'); } catch(e) {}
  };
  const onScheduleDemoClick = (e) => {
    try { window.Intercom('showArticle', 8738544); } catch(e) {}
  };

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  if (!locationState.locations[locationidentifier]) return null;
  const { engage } = locationState.locations[locationidentifier];
  if (!engage) return null;

  const { settings } = locationState.locations[locationidentifier];
  if (!settings) return null;

  if (!userState.user.user_info || !userState.user.user_info.locations ||
    !userState.user.user_info.locations[urlParams.locationidentifier]
  ) return null;




  // for location info
  // if (!settings.info) return null;

  if (userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('LocationWrapper loc: ', userState.user.user_info.locations[urlParams.locationidentifier]);
    }
  }

  const hasEngage = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage
    : false;
  // if (!hasEngage) return null;
  if (!hasEngage) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('calling Intercom');
    }
    // window.Intercom('shutdown');
    // setTimeout(() => {
    //   window.Intercom("boot", {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "mydf4dtr",
    //     name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //       email: userState.user.uid, // the email for your user
    //     // created_at: user.createdAt, // Signup date as a Unix timestamp
    //   });
    // }, 500);
    return (
      <>
    <div className="mcIframeWrp">
      <iframe id="iframeId"
        title={gtconfig.APP_NAME_CONTACT}
        src="https://guesttouch.com/internal-guest-messaging"
      >
      </iframe>
    </div>
    <div className="prdCtaBox">
      <button className="gBtn" onClick={onScheduleDemoClick}>Schedule Demo</button>
      <button className="gBtn" onClick={onEnableEngageClick}>Enable Engage</button>
    </div>
    </>
  );
} else {
  // window.Intercom('shutdown');
}
  if (!hasEngage) return <EnableEngage />

  // show loader while settings are being retrieved
  if (settings.getInProgress && settings.type_engage) {
    return (
      <div className="gPnlLdng">
        <div className="inWrp">
          <i className="fa fa-spinner fa-spin" />
        </div>
      </div>
    );
  }

  if (!settings.info) return null;

  const hasSms = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage_sms
    : false;
  const hasMms = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage_mms
    : false;
  const hasWhatsapp = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage_whatsapp
    : false;
  const hasEmail = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage_email
    : false;
  const hasWebApp = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage_webapp
    : false;
  const hasExpedia = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_engage_expedia
    : false;

  let commChannels = [];
  if (hasSms) commChannels.push('sms');
  if (hasWhatsapp) commChannels.push('whatsapp');
  if (hasEmail) commChannels.push('email');
  if (hasWebApp) commChannels.push('webapp');
  if (hasExpedia) commChannels.push('expedia');

  const { hasAccessToEngage } = getProductAccess(userState.user.user_info, urlParams.locationidentifier);
  if (!hasAccessToEngage) return null;

  const { hasAccessToWebChatReply, hasAccessToBroadcast } = getEngageAccess(userState.user.user_info, urlParams.locationidentifier);
  const engAccess = { hasAccessToWebChatReply, hasAccessToBroadcast };

  const { isReadOnly } = props;

  return (
    <div className="gCw">
      <Routes>
        <Route path="*" element={<Outlet />}>
          {/*<Route path="reviews" element={<Reviews />} />*/}
          <Route path="messages/*"
            element={
              <Messages hasSms={hasSms}
                hasWhatsapp={hasWhatsapp}
                hasEmail={hasEmail}
                hasWebApp={hasWebApp}
                hasMms={hasMms}
                commChannels={commChannels}
                locationInfo={settings.info} engage={engage} settings={settings}
                engAccess={engAccess}
                isReadOnly={isReadOnly}
              />
            }
          />
          {/*<Route path="journey-messages/*" element={<JourneyMessages isReadOnly={isReadOnly} />} />*/}
          { hasAccessToBroadcast && <Route path="broadcast/*" element={<Broadcast locationInfo={settings.info} engage={engage} settings={settings} isReadOnly={isReadOnly} />} />}
          <Route path="usage" element={<EngageUsage locationInfo={settings.info} engage={engage} settings={settings} commChannels={commChannels} isReadOnly={isReadOnly} />} />
          <Route index element={<Dashboard isReadOnly={isReadOnly} />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </div>
  );
}

export default LocationWrapper;
