import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericList from '../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';


function WhatsappTemplates(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Templates - Whatsapp - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  if (!props.locationInfo.has_engage) return null;

  const { settings: { engage } } = props;
  const { approved_whatsapp_templates, added_whatsapp_templates } = engage;

  return (
    <GenericList
      type="whatsappTemplates"
      title="WhatsApp Starter Templates"
      location__id={urlParams.locationidentifier}
      generic_list={approved_whatsapp_templates}
      added_whatsapp_templates={added_whatsapp_templates}
      settings={props.settings}
    />
  );
}

export default WhatsappTemplates;
