import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import RouteLeavingGuard from '../../../Misc/RouteLeavingGuard';
import BusinessHours from '../../../Misc/BusinessHours';
import ToggleAndData from '../../../Misc/ToggleAndData';
import GenericSideModal from '../../../Misc/GenericSideModal';
import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { validateEmail, STRS } from '../../../../helpers';

import './style.css';

const gtconfig = require('../../../../gtconfig');

const approveResponsesOptions = [];
approveResponsesOptions.push({
  id: 'no',
  label: 'No (Responses will be published directly)',
});
approveResponsesOptions.push({
  id: 'yes',
  label: 'Yes (All reviews will require your approval before being published)',
});
// approveResponsesOptions.push({
//   id: 'negative-only',
//   label: 'Negative Reviews Only (Reviews with 1 and 2 Stars will require your approval before being published)',
// });
approveResponsesOptions.push({
  id: 'custom',
  label: 'Custom (Reviews with certain rating such as 3 and below will require your approval before being published)',
});

const customRatingValueOptions = [];
customRatingValueOptions.push({
  id: '4-or-less',
  label: '4 or less',
});
customRatingValueOptions.push({
  id: '3-or-less',
  label: '3 or less',
});
customRatingValueOptions.push({
  id: '2-or-less',
  label: '2 or less',
});
customRatingValueOptions.push({
  id: '1-or-less',
  label: '1 only',
});


const autoApproveOptions = [];
autoApproveOptions.push({
  id: 'do-not-auto-approve',
  label: 'Do not auto approve',
});
autoApproveOptions.push({
  id: '24-hrs',
  label: '24 Hrs',
});
autoApproveOptions.push({
  id: '48-hrs',
  label: '48 Hrs',
});
autoApproveOptions.push({
  id: '72-hrs',
  label: '72 Hrs',
});


function CheckBoxRow(props) {
  const onCheckBoxChange = (e) => { props.onCheckBoxChange(props.id); };
  return (
    <div className={'entry ' + (props.value ? 'active' : '')}>
      <input type="checkbox" name={props.name} onChange={onCheckBoxChange} value={props.value ? 'on' : 'off'} checked={props.value} />
      <div className="label">
        <span className="name" onClick={onCheckBoxChange}>{props.label}</span>
      </div>
    </div>
  );
}


function AutoResponses(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [approveResponsesValue, setApproveResponsesValue] = useState({});
  const [customRatingValue, setCustomRatingValue] = useState({});
  const [customRatingValueError, setCustomRatingValueError] = useState('');
  const [autoApproveValue, setAutoApproveValue] = useState({});
  const [autoApproveValueError, setAutoApproveValueError] = useState('');

  const [alertEmail, setAlertEmail] = useState('');
  const [alertEmailError, setAlertEmailError] = useState('');

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Response Approval - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { locationInfo } = props;
    if (locationInfo) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('locationInfo: ', locationInfo);
      }
      if (locationInfo.response_needs_approval) {
        if (locationInfo.rating_threshold_for_approval) {
          setApproveResponsesValue({ 'custom': true });
        } else {
          setApproveResponsesValue({ yes: true });
        }
        // if (locationInfo.auto_approval_delay) {
        //   if (locationInfo.auto_approval_delay === '24-hrs') { setAutoApproveValue({ '24-hrs': true }); }
        //   if (locationInfo.auto_approval_delay === '48-hrs') { setAutoApproveValue({ '48-hrs': true }); }
        // }
      } else {
        setApproveResponsesValue({ no: true });
      }
      if (locationInfo.rating_threshold_for_approval) {
        if (locationInfo.rating_threshold_for_approval === '4-or-less') { setCustomRatingValue({ '4-or-less': true }); }
        if (locationInfo.rating_threshold_for_approval === '3-or-less') { setCustomRatingValue({ '3-or-less': true }); }
        if (locationInfo.rating_threshold_for_approval === '2-or-less') { setCustomRatingValue({ '2-or-less': true }); }
        if (locationInfo.rating_threshold_for_approval === '1-or-less') { setCustomRatingValue({ '1-or-less': true }); }
      }
      if (locationInfo.auto_approval_delay) {
        if (locationInfo.auto_approve_disabled) { setAutoApproveValue({ 'do-not-auto-approve': true }); }
        if (locationInfo.auto_approval_delay === '24-hrs') { setAutoApproveValue({ '24-hrs': true }); }
        if (locationInfo.auto_approval_delay === '48-hrs') { setAutoApproveValue({ '48-hrs': true }); }
        if (locationInfo.auto_approval_delay === '72-hrs') { setAutoApproveValue({ '72-hrs': true }); }
      } else {
        if (locationInfo.auto_approve_disabled) { setAutoApproveValue({ 'do-not-auto-approve': true }); }
      }
      // review_response_email
      if (locationInfo.review_response_email) {
        setAlertEmail(locationInfo.review_response_email);
      }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  // useEffect(() => {
  //   if (props.settings.reputation &&
  //     !props.settings.reputation.editInProgress &&
  //     props.settings.reputation.editStatus &&
  //     props.settings.reputation.editStatus.success
  //   ) {
  //     setFormChanged(false);
  //   }
  // }, [props.settings.reputation]);

  const onApproveResponseCheckBoxChange = (id) => {
    let cValue = approveResponsesValue[id] || false;
    if (cValue) {
      if (Object.keys(approveResponsesValue).filter(el => approveResponsesValue[el]).length === 1) {
        return; // one is required
      }
    }
    setApproveResponsesValue({
      // ...approveResponsesValue,
      [id]: !cValue,
    });
    setFormChanged(true);
  };
  const onCustomRatingValueCheckBoxChange = (id) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCustomRatingValueCheckBoxChange id: ', id);
    }
    let cValue = customRatingValue[id] || false;
    if (cValue) {
      if (Object.keys(customRatingValue).filter(el => customRatingValue[el]).length === 1) {
        return; // one is required
      }
    }
    setCustomRatingValue({
      [id]: !cValue,
    });
    setFormChanged(true);
  };
  const onAutoApproveCheckBoxChange = (id) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAutoApproveCheckBoxChange id: ', id);
    }
    let cValue = autoApproveValue[id] || false;
    if (cValue) {
      if (Object.keys(autoApproveValue).filter(el => autoApproveValue[el]).length === 1) {
        return; // one is required
      }
    }
    setAutoApproveValue({
      [id]: !cValue,
    });
    setFormChanged(true);
  };

  const onAlertEmailChange = (e) => {
    const { name, value } = e.target;
    setAlertEmail(value);
    if (!validateEmail(value)) {
      let emailError = value ? '' : 'error';
      if (!emailError) emailError = validateEmail(value) ? '' : 'invalid';
      setAlertEmailError(emailError);
    } else {
      setAlertEmailError('');
    }
    setFormChanged(true);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    const { locationInfo } = props;
    if (locationInfo && locationInfo.auto_responses &&
      locationInfo.auto_responses.editInProgress
    ) return;

    // check if auto approve delay is set or not
    if (approveResponsesValue['yes']) {
      setCustomRatingValueError('');
      // alert email check
      let emailError = alertEmailError ? '' : 'error';
      // if (!emailError) emailError = validateEmail(alertEmail) ? '' : 'invalid';
      if (!emailError) {
        if (alertEmail.split(',').length > 1) {
          let tArr = alertEmail.split(',');
          tArr.forEach((item) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('item: ', item);
            }
            if (!validateEmail(item)) emailError = 'invalid';
          });
        } else {
          emailError = validateEmail(alertEmail) ? '' : 'invalid';
        }
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('emailError:: ', emailError);
      }
      setAlertEmailError(emailError);
    }

    if (approveResponsesValue['custom']) {
      if (!customRatingValue['4-or-less'] &&
        !customRatingValue['3-or-less'] &&
        !customRatingValue['2-or-less'] &&
        !customRatingValue['1-or-less']
      ) {
        setCustomRatingValueError('error');
        return null;
      } else {
        setCustomRatingValueError('');
      }
      if (!autoApproveValue['24-hrs'] && !autoApproveValue['48-hrs'] && !autoApproveValue['72-hrs']) {
        setAutoApproveValueError('error');
        return null;
      } else {
        setAutoApproveValueError('');
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('alertEmailError: ', alertEmailError);
    }
    // alert email check
    let emailError = alertEmailError;
    // if (!emailError) emailError = validateEmail(alertEmail) ? '' : 'invalid';
    if (!emailError) {
      if (alertEmail.split(',').length > 1) {
        let tArr = alertEmail.split(',');
        tArr.forEach((item) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('item: ', item);
          }
          if (!validateEmail(item)) emailError = 'invalid';
        });
      } else {
        emailError = validateEmail(alertEmail) ? '' : 'invalid';
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('emailError: ', emailError);
    }
    setAlertEmailError(emailError);
    // return null;

    if (emailError) return null;

    if (hasError) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('autoApproveValue: ', autoApproveValue);
    }

    const params = {
      location__id: urlParams.locationidentifier,
    };
    if (approveResponsesValue['yes'] || approveResponsesValue['custom']) {
      params['response_needs_approval'] = true;
      if (approveResponsesValue['custom']) {
        if (customRatingValue['4-or-less']) params['rating_threshold_for_approval'] = '4-or-less';
        if (customRatingValue['3-or-less']) params['rating_threshold_for_approval'] = '3-or-less';
        if (customRatingValue['2-or-less']) params['rating_threshold_for_approval'] = '2-or-less';
        if (customRatingValue['1-or-less']) params['rating_threshold_for_approval'] = '1-or-less';
      } else {
        params['rating_threshold_for_approval'] = '';
      }
      if (autoApproveValue['do-not-auto-approve']) {
        params['auto_approve_disabled'] = true;
        params['auto_approval_delay'] = '';
      } else {
        if (autoApproveValue['24-hrs']) params['auto_approval_delay'] = '24-hrs';
        if (autoApproveValue['48-hrs']) params['auto_approval_delay'] = '48-hrs';
        if (autoApproveValue['72-hrs']) params['auto_approval_delay'] = '72-hrs';
        params['auto_approve_disabled'] = false;
      }
      // params['approval_alert'] = {};
      // params['approval_alert']['medium'] = {};
      // if (emailAlert) params['approval_alert']['medium']['email'] = true;
      // if (whatsappAlert) params['approval_alert']['medium']['whatsapp'] = true;
    } else {
      params['response_needs_approval'] = false;
    }
    // if (approveResponsesValue['yes']) {
    //   params['response_needs_info'] = true;
    //   params['need_info_alert'] = {};
    //   params['need_info_alert']['medium'] = {};
    //   if (emailAlertNeedInfo) params['need_info_alert']['medium']['email'] = true;
    // } else {
    //   params['response_needs_info'] = false;
    // }
    if (alertEmail) params['review_response_email'] = alertEmail;


    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    // params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_reputation_auto_responses',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };


  const renderApprovalSettingsBox = () => {
    return (
      <>
        <div className="gBCtRw hasTgl">
          <div className="lt">
            <p className="title">Response Approval Settings</p>
            <p className="desc">If you have opted for {gtconfig.APP_NAME_CONTACT} Managed Responses, you can set controls for reviewing and approving responses.</p>
          </div>
        </div>
        <div className={'gBCtRw alrtOpts'}>
          <div className="lt">
            <p className="title">
              Is approval required for suggested responses ?
            </p>
            <div className="alertOptions">
              {
                approveResponsesOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={approveResponsesValue[item.id] || false}
                    onCheckBoxChange={onApproveResponseCheckBoxChange}
                  />
                })
              }
            </div>
          </div>
        </div>
        {
          (approveResponsesValue['custom']) &&
          <div className={'gBCtRw alrtOpts'}>
            <div className="lt">
              <div className={'title ' + (customRatingValueError ? 'error' : '')}>
                Choose the rating for which approval is required. (Required)
              </div>
              <div className="alertOptions">
                {
                  customRatingValueOptions.map((item) => {
                    return <CheckBoxRow
                      id={item.id}
                      name={item.id}
                      label={item.label}
                      value={customRatingValue[item.id] || false}
                      onCheckBoxChange={onCustomRatingValueCheckBoxChange}
                    />
                  })
                }
              </div>
            </div>
          </div>
        }
        {
          (approveResponsesValue['yes'] || approveResponsesValue['custom']) &&
          <div className={'gBCtRw alrtOpts'}>
            <div className="lt">
              <p className={'title ' + (autoApproveValueError ? 'error' : '')}>
                If not approved, then response will be auto approved after how long ? (Required)
              </p>
              <div className="alertOptions">
                {
                  autoApproveOptions.map((item) => {
                    return <CheckBoxRow
                      id={item.id}
                      name={item.id}
                      label={item.label}
                      value={autoApproveValue[item.id] || false}
                      onCheckBoxChange={onAutoApproveCheckBoxChange}
                    />
                  })
                }
              </div>
            </div>
          </div>
        }
      </>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('AutoResponses: ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen setRepAlrt">
        <div className="gPTle title">
          Response Approval{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderApprovalSettingsBox() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
                <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                  Save Changes
                  {
                    props.locationInfo && props.locationInfo.auto_responses &&
                    props.locationInfo.auto_responses.editInProgress
                    && <i className="fa fa-spinner fa-spin" />
                  }
                </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default AutoResponses;
