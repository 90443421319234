// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { Interweave } from 'interweave';

import { auth } from '../../../../../firebase';
import { updateCurrentUserRecentLang } from '../../../../../redux/actions/miscActions';

import { renderOTALogo, formatDate, getReviewState, RS, mapReviewStateToString, OTA_SOURCE_TO_NAME_FORMAL_MAP,
  sendEmailAlert, LANGUAGE_LIST_KEYS } from '../../../../../helpers';

import moreIcon from '../../../../../img/icons/more.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import translateIcon from '../../../../../img/icons/translate.svg';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import './style.css';

const gtconfig = require('../../../../../gtconfig');


const filterOptions = [];
filterOptions.push({ label: 'Positive', value: 'positive' });
filterOptions.push({ label: 'Negative', value: 'negative' });
filterOptions.push({ label: 'All', value: 'all' });

function SingleRow(props) {
  const [showReview, setShowReview] = useState(false);
  const onShowReviewClick = (e) => {
    setShowReview(showReview => !showReview);
  }
  let reviewTitle = '', reviewText = '', reviewTextNegative = '', reviewDate = '', reviewRating = '', reviewSource = '', reviewAuthor = '';
  let reviewHasManagerResponse = false, reviewManagerResponse = '';
  let hotelName = '';
  if (props.data.review_id && props.reviewData && props.reviewData[props.data.review_id]) {
    reviewTitle = props.reviewData[props.data.review_id].title;
    reviewText = props.reviewData[props.data.review_id].comment;
    reviewTextNegative = props.reviewData[props.data.review_id].comment_negative;
    reviewDate = props.reviewData[props.data.review_id].review_date;
    reviewRating = props.reviewData[props.data.review_id].rating_out_of_5;
    reviewSource = props.reviewData[props.data.review_id].source;
    reviewAuthor = props.reviewData[props.data.review_id].author;
    reviewHasManagerResponse = props.reviewData[props.data.review_id].has_manager_response;
    reviewManagerResponse = props.reviewData[props.data.review_id].manager_response;
    hotelName = props.reviewData[props.data.review_id].hotel_name;
  }
  var starStyle = { "--rating": reviewRating };
  return (
    <div className={'singleRow ' + (props.rowType)}>
      <div className="icon">
      </div>
      <div className="text">
        {/*
          hotelName &&
          <div className="hName">{hotelName}</div>
        */}
        {
          props.translated
          ? <Interweave content={props.text} />
          : props.data.text
        }
        {
          reviewText && <span onClick={onShowReviewClick} className="showReview">{showReview ? 'Hide' : 'See'} review context</span>
        }
        {
          showReview &&
          <div className="reviewText">
            { hotelName && <p className="hName">{hotelName}</p> }
            {reviewDate && <p>{ renderOTALogo(reviewSource) } On {formatDate(reviewDate)}</p> }
            {reviewAuthor && <p>By {reviewAuthor}</p> }
            {reviewRating &&
              <div className="rating">
                <div className="ratingValue">
                  {reviewRating} / 5
                </div>
                <div className="stars">
                  <div className="reviewStars" style={starStyle}></div>
                </div>
              </div>
            }
            {reviewTitle && <p><b>{reviewTitle}</b></p> }
            {reviewText && <p>{reviewText}</p> }
            {reviewTextNegative && <p>{reviewTextNegative}</p> }
            {
              reviewHasManagerResponse &&
              <div className="mgrRsp">
                <p className="title">Your Response</p>
                {reviewManagerResponse && <p>{reviewManagerResponse}</p>}
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

function SentimentMentions(props) {
  const dispatch = useDispatch();
  let urlParams = useParams();

  const [keyword, setKeyword] = useState('');
  const [showPositive, setShowPositive] = useState(true);
  const [showNegative, setShowNegative] = useState(true);
  const [filterValue, setFilterValue] = useState(
    { label: 'All', value: 'all' }
  );

  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [langaugeSearchTerm, setLangaugeSearchTerm] = useState('');

  const languageSelectorRef = useRef();
  useOnClickOutside(languageSelectorRef, useCallback(() => setLanguageSelectorOpen(false)));

  const [selectedTranslationLang, setSelectedTranslationLang] = useState('');
  const [showOriginal, setShowOriginal] = useState(true);
  const [translateInProgress, setTranslateInProgress] = useState(false);

  const [translatedFeedbackText, setTranslatedFeedbackText] = useState([]);
  const [translatePositiveArray, setTranslatePositiveArray] = useState([]);
  const [translateNegativeArray, setTranslateNegativeArray] = useState([]);

  const { locationidentifier } = props;

  useEffect(() => {
    if (!props.default || props.default === 'all') {
      setShowPositive(true); setShowNegative(true);
      setFilterValue({ label: 'All', value: 'all'});
    }
    if (props.default === 'positive') {
      setShowPositive(true); setShowNegative(false);
      setFilterValue({ label: 'Positive', value: 'positive'});
    }
    if (props.default === 'negative') {
      setShowPositive(false); setShowNegative(true);
      setFilterValue({ label: 'Negative', value: 'negative'});
    }
  }, [props.default]);

  const onKeywordChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
    // if (value) {
    //   gtag('event', 'search', {
    //     'search_term': value,
    //   });
    //   gtag('event', 'gt_click', {
    //     'event_category': `Charts - Sentiments Mentions - Mention Details - Filter - Keyword`,
    //     'event_label': `Keyword`,
    //     'value': value,
    //     user_id: props.userprops.user.uid,
    //   });
    // }
  };
  const onKeywordKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
    }
  };

  const onPositiveClick = (e) => {
    setShowPositive(showPositive => !showPositive);
  };
  const onNegativeClick = (e) => {
    setShowNegative(showNegative => !showNegative);
  };

  const onFilterOptionChange = (value) => {
    const filterValue = value.value;
    setFilterValue(value);
    if (filterValue === 'all') {
      setShowPositive(true);
      setShowNegative(true);
    }
    if (filterValue === 'positive') {
      setShowPositive(true);
      setShowNegative(false);
    }
    if (filterValue === 'negative') {
      setShowPositive(false);
      setShowNegative(true);
    }
  };

  const onTranslateClick = (text) => {
    getTextTranslation({
      location__id: locationidentifier || '',
      group_id: urlParams.groupidentifier || '',
      textArray: [text],
      destLang: 'es',
    });
  };

  const getTextTranslation = (params) => {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.log('start getTextTranslation params: ', params);
        console.log('start getTextTranslation translatePositiveArray length: ', translatePositiveArray.length);
        console.log('start getTextTranslation translateNegativeArray length: ', translateNegativeArray.length);
      }
      auth.currentUser
        .getIdToken(false)
        .then((idToken) => {
          const url = gtconfig.DATA_BACKEND_URL;
          const opdata = {};
          // opdata.params = params;
          opdata.params = {
            ...params,
            relogin_identifier: window.gtCId,
          };
          const postData = {
            idToken,
            appId: gtconfig.APP_ID,
            op: 'get_text_translation',
            opdata,
          };
          if (process.env.NODE_ENV !== 'production') {
            console.log('getTextTranslation postData: ', postData);
          }

          const headers = {
            'Content-type': 'application/json',
            'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
          };
          const axiosData = {
            gtps: postData,
            M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
          };

          setTranslateInProgress(true);

          axios
            .post(url, axiosData, { headers })
            .then((response) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation response: ', response.data);
              }
              setTranslateInProgress(false);
              const { status } = response.data;
              const { data } = response.data;
              if (status === 'success' && data) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getTextTranslation data: ', data);
                }
                if (data.recent_lang) {
                  dispatch(updateCurrentUserRecentLang({
                    params: {
                      recent_lang: data.recent_lang,
                    },
                  }));
                }
                if (data.translated_text_array && data.translated_text_array.length) {
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('getTextTranslation translatePositiveArray length: ', params.positiveArray.length);
                    console.log('getTextTranslation translateNegativeArray length: ', params.negativeArray.length);
                  }
                  let i = 0;
                  if (params.positiveArray.length) {
                    setTranslatePositiveArray(data.translated_text_array.slice(0, params.positiveArray.length));
                    i = params.positiveArray.length;
                  }
                  if (params.negativeArray.length) {
                    if (process.env.NODE_ENV !== 'production') {
                      console.log('getTextTranslation data.translated_text_array.slice(i): ', data.translated_text_array.slice(i));
                    }
                    setTranslateNegativeArray(data.translated_text_array.slice(i));
                  }
                  // setTranslatedFeedbackText(data.translated_text_array);
                  setShowOriginal(false);
                }
              } else {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getTextTranslation error: ', response);
                }
                sendEmailAlert({
                  app: gtconfig.APP_NAME,
                  subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Sentiments Error - ${auth.currentUser.uid}`, // nkcheck
                  message: JSON.stringify({ postData, responseData: response.data }),
                  error: JSON.stringify(response.data),
                });
              }
            })
            .catch((err) => {
              // axios error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation err--------------------: ', err);
                console.log('getTextTranslation err.response --------------------: ', err.response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Sentiments Error - axios - ${auth.currentUser.uid}`, // nkcheck
                message: JSON.stringify({ url, postData }),
                error: JSON.stringify({ err , config: err.config }),
              });
            })
            .catch((err) => {
            // firebase error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation firebase err--------------------: ', err);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Sentiments Error - firebase`,
                message: 'Firebase error',
                error: err.toString(),
              });
            });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('getTextTranslation e: ', e);
      }
    }
  };

  /* translate row */

  const onTranslateLanguageSelectorOpenClick = (e) => {
    if (translateInProgress) return;
    setLanguageSelectorOpen(languageSelectorOpen => !languageSelectorOpen);
    setLangaugeSearchTerm('');
  };

  const onLanguageSelectorInputChange = (e) => {
    const { name, value } = e.target;
    setLangaugeSearchTerm(value);
  };

  const onLanguageSelect = (lang, details) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect: ', lang);
    }
    if (translateInProgress) return;

    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect translationLang: ', translationLang);
    }
    setSelectedTranslationLang(lang);
    setLanguageSelectorOpen(false);

    // build the array
    let translatePositiveArrayTemp = [];
    if (details && details.positive) {
      setTranslatePositiveArray(details.positive.map(el => el.text));
      translatePositiveArrayTemp = details.positive.map(el => el.text);
    }
    let translateNegativeArrayTemp = [];
    if (details && details.negative) {
      setTranslateNegativeArray(details.negative.map(el => el.text));
      translateNegativeArrayTemp = details.negative.map(el => el.text);
    }

    getTextTranslation({
      location__id: locationidentifier,
      group_id: urlParams.groupidentifier || '',
      textArray: [...translatePositiveArrayTemp, ...translateNegativeArrayTemp],
      destLang: lang,
      positiveArray: translatePositiveArrayTemp,
      negativeArray: translateNegativeArrayTemp,
    });

  };

  const onTranslateToTranslationLangClick = (details) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
      props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (!translationLang) return;
    setSelectedTranslationLang(translationLang);

    // build the array
    let translatePositiveArrayTemp = [];
    if (details && details.positive) {
      setTranslatePositiveArray(details.positive.map(el => el.text));
      translatePositiveArrayTemp = details.positive.map(el => el.text);
    }
    let translateNegativeArrayTemp = [];
    if (details && details.negative) {
      setTranslateNegativeArray(details.negative.map(el => el.text));
      translateNegativeArrayTemp = details.negative.map(el => el.text);
    }

    getTextTranslation({
      location__id: locationidentifier,
      group_id: urlParams.groupidentifier || '',
      textArray: [...translatePositiveArrayTemp, ...translateNegativeArrayTemp],
      destLang: translationLang,
      positiveArray: translatePositiveArrayTemp,
      negativeArray: translateNegativeArrayTemp,
    });

  };

  const onShowOriginalClick = (e) => {
    setShowOriginal(true);
  };

  /* end - translate row */

  const renderTranslateToLang = (translationLang, details) => {
    return null; //nkcheck
    if (!showOriginal) return null;
    if (translationLang) {
      return (
        <div className="translateToLang" onClick={() => onTranslateToTranslationLangClick(details)}>
          <span>
            { translateInProgress ? 'Translating' : 'Translate' }
            {
              translationLang && ` to ${LANGUAGE_LIST_KEYS[translateInProgress ? selectedTranslationLang : translationLang]}`
            }
          </span>
        </div>
      );
    }
    return null;
  };

  const renderLangDropdownCta = (translationLang, details) => {
    return (
      <div className="translateIcon">
        <div className={'dropdown ' + (languageSelectorOpen ? 'open' : '')} ref={languageSelectorRef}>
          <button className="dropbtn" onClick={onTranslateLanguageSelectorOpenClick}>
            {/*
              translateInProgress
              ? <i className="fa fa-spinner fa-spin"></i>
              : <i className="fas fa-language"></i>
            */}
            {
              !showOriginal
              ?
                <span className="translatedToLang">
                  {translateInProgress ? 'Translating' : 'Translated'}
                  {` to `}
                  {LANGUAGE_LIST_KEYS[selectedTranslationLang]}
                </span>
              :
                <>
                  <span>
                    {
                      translationLang ? `${translateInProgress ? 'Translating' : 'Translate'}` : `${translateInProgress ? 'Translating' : 'Translate'}`
                    }
                  </span>
                </>
            }
          </button>
          <div className="dropdown-content gLngSlctr">
            <div className="search">
              <input type ="text" onChange={onLanguageSelectorInputChange} value={langaugeSearchTerm} placeholder="Search" />
            </div>
            <div className="langListWrap">
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedTitle">
                  Recently Used
                </div>
              }
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedList">
                  {
                    props.userState.user.user_info && props.userState.user.user_info.profile &&
                    props.userState.user.user_info.profile.recent_lang &&
                    Object.keys(props.userState.user.user_info.profile.recent_lang).length > 0
                    ?
                      Object.keys(props.userState.user.user_info.profile.recent_lang)
                        .sort((a,b) => props.userState.user.user_info.profile.recent_lang[b] - props.userState.user.user_info.profile.recent_lang[a])
                        .map((item) => {
                        const lang = LANGUAGE_LIST_KEYS[item];
                        return (
                          <p onClick={() => onLanguageSelect(item, details)}>{lang}</p>
                        )
                      })
                    : null
                  }
                </div>
              }
              <div className="allTitle">
                All
              </div>
              <div className="allList">
                {
                  Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
                    const lang = LANGUAGE_LIST_KEYS[item];
                    if (langaugeSearchTerm && langaugeSearchTerm.length > 1) {
                      const reg = new RegExp(langaugeSearchTerm, "i")
                      if (
                        (lang && lang.search(reg) > -1)
                      ) {
                        return (
                          <p onClick={() => onLanguageSelect(item, details)}>{lang}</p>
                        )
                      }
                      return null;
                    }
                    return (
                      <p onClick={() => onLanguageSelect(item, details)}>{lang}</p>
                    )
                  })
                }
              </div>
            </div>
            <div className="langSettings">
              <span><Link to={`/locations/${props.locationidentifier}/settings/language`}>Language settings</Link></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTranslateRow = (details) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    return (
      <div className="translateRow">
        <div className="translateIconDiv">
          <span><img src={translateIcon} /></span>
        </div>
        { renderTranslateToLang(translationLang, details) }
        {/*
          translationLang &&
          <div className="translateToLang" onClick={onTranslateToTranslationLangClick}>
            <span>
              Translate
              {
                translationLang && ` to ${LANGUAGE_LIST_KEYS[translationLang]}`
              }
            </span>
          </div>
        */}
        { renderLangDropdownCta(translationLang, details) }

        {
          !showOriginal &&
          <div className="viewOriginal" onClick={onShowOriginalClick}>
            Show Original
          </div>
        }
      </div>
    );
  };

  const renderPositive = (details, reviewData) => {
    if (showOriginal) {
      if (!details || !details.positive) return null;
      return details.positive.map((item) => {
        if (keyword) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('keyword: ', item.text.search(new RegExp(keyword, "i")));
          }
          if (
            item.text.search(new RegExp(keyword, "i")) > -1 ||
            (reviewData[item.review_id] && reviewData[item.review_id].hotel_name && reviewData[item.review_id].hotel_name.search(new RegExp(keyword, "i")) > -1)
          ) {
            return <SingleRow data={item} reviewData={reviewData} rowType="positive" />
          }
          return null;
        }
        return <SingleRow data={item} reviewData={reviewData} rowType="positive" />
      });
    }
    if (!showOriginal) {
      if (!translatePositiveArray.length) return null;
      return translatePositiveArray.map((item) => {
        if (keyword) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('keyword: ', item.search(new RegExp(keyword, "i")));
          }
          if (item.text.search(new RegExp(keyword, "i")) > -1) {
            return <SingleRow data={item} reviewData={reviewData} text={item} translated={true} rowType="positive" />
          }
          return null;
        }
        return <SingleRow data={item} reviewData={reviewData} text={item} translated={true} rowType="positive" />
      });
    }
    return null;
  };
  const renderNegative = (details, reviewData) => {
    if (showOriginal) {
      if (!details || !details.negative) return null;
      return details.negative.map((item) => {
        if (keyword) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('keyword: ', item.text.search(new RegExp(keyword, "i")));
          }
          if (
            item.text.search(new RegExp(keyword, "i")) > -1 ||
            (reviewData[item.review_id] && reviewData[item.review_id].hotel_name && reviewData[item.review_id].hotel_name.search(new RegExp(keyword, "i")) > -1)
          ) {
            return <SingleRow data={item} reviewData={reviewData} rowType="negative" />
          }
          return null;
        }
        return <SingleRow data={item} reviewData={reviewData} rowType="negative" />
      });
    }
    if (!showOriginal) {
      if (!translateNegativeArray.length) return null;
      return translateNegativeArray.map((item) => {
        if (keyword) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('keyword: ', item.search(new RegExp(keyword, "i")));
          }
          if (item.text.search(new RegExp(keyword, "i")) > -1) {
            return <SingleRow data={item} reviewData={reviewData} text={item} translated={true} rowType="negative" />
          }
          return null;
        }
        return <SingleRow data={item} reviewData={reviewData} text={item} translated={true} rowType="negative" />
      });
    }
    return null;
  };

  const renderContent = () => {
    const { name, details } = props.data;
    if (!name || !details) return null;
    let reviewData = props.sentiments && props.sentiments.review_data ? props.sentiments.review_data : {};
    return (
      <div className="gPWrp sentMentBx">
        <div className="gPTle title">
          Mentions - {name}
          <span className="close" onClick={props.onCancelClick}><img src={closeIcon} />Close</span>
        </div>
        <div className="gBCtnt">
          <div className="innerBox">
            <div className="headerRow">
              {
                props.hasTranslate &&
                ((details.positive && details.positive.length) || (details.negative && details.negative.length)) &&
                renderTranslateRow(details)
              }
            </div>
            <div className="optionsRow">
              <div className="search">
                <div className="gIpt">
                  <input type="text"
                    placeholder="Search"
                    value={keyword}
                    onChange={onKeywordChange}
                  />
                </div>
              </div>
              <div className="filter">
                <Select
                  className="sentimentPositiveNegativeFilter"
                  options={filterOptions}
                  onChange={onFilterOptionChange}
                  value={filterValue}
                  onKeyDown={onKeywordKeyDown}
                />
              </div>
            </div>
            <div className="contentBox">
              { showPositive && renderPositive(details, reviewData) }
              { showNegative && renderNegative(details, reviewData) }
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    // console.log('SingleReview locationId: ', props.locationId);
    // console.log('SingleReview reviewId: ', props.reviewId);
    console.log('SentimentMentions props: ', props);
    console.log('SentimentMentions translatePositiveArray: ', translatePositiveArray);
    console.log('SentimentMentions translateNegativeArray: ', translateNegativeArray);
  }

  return renderContent();

  return (
    <div className="gBoxWrap sentimentMentionsWrap">
        { renderContent() }
    </div>
  );
}

export default SentimentMentions;
