import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import closeIcon from '../../../../../img/icons/close.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

function CompSetSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [locationDetails, setLocationDetails] = useState({});
  const [locationDetailsError, setLocationDetailsError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  
  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      let page_title = `Settings - Reputation - Comp Set - Single - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;    
    setSomeChange(false);

    clearData();

    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.template._id &&
        props.settings.reputation.comp_set_locations && props.settings.reputation.comp_set_locations.keyValue &&
        props.settings.reputation.comp_set_locations.keyValue[props.template._id] &&
        !props.settings.reputation.comp_set_locations.keyValue[props.template._id].editInProgress &&
        props.settings.reputation.comp_set_locations.keyValue[props.template._id].editStatus &&
        props.settings.reputation.comp_set_locations.keyValue[props.template._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.settings.reputation.comp_set_locations &&
        !props.settings.reputation.comp_set_locations.addInProgress &&
        props.settings.reputation.comp_set_locations.addStatus &&
        props.settings.reputation.comp_set_locations.addStatus.success
      ) {
        setLocationDetails({});
        setSomeChange(false);
      }
    }
  }, [props.settings.reputation.comp_set_locations]);


  const clearData = () => {
    const { templateType } = props;
    if (props.type === 'edit') {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
      };
      let op = 'edit_comp_set_location';
      params.feedback_text_template_id = props.template._id;
      dispatch(editDataClearAction({
        op,
        params,
      }));
    }

    if (props.type === 'add') {
      let op = 'edit_comp_set_location';
      dispatch(editDataClearAction({
        op,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
  };




  
  

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const autoCompleteChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('autoCompleteChange: ', value);
    }
    if (value.value && value.value.place_id === props.googlePlaceId) {
      setLocationDetailsError('New location cannot be same as your current location');
      return;
    }
    if (value.value && value.value.place_id &&
      props.settings && props.settings.reputation &&
      props.settings.reputation.comp_set_locations &&
      props.settings.reputation.comp_set_locations.keyValue &&
      Object.keys(props.settings.reputation.comp_set_locations.keyValue).length
    ) {
      let tFound = Object.keys(props.settings.reputation.comp_set_locations.keyValue).find((item) => {
        return !props.settings.reputation.comp_set_locations.keyValue[item].is_removed && 
          props.settings.reputation.comp_set_locations.keyValue[item].place_id === value.value.place_id;
      })
      if (tFound) {
        setLocationDetailsError('New location already exist in the comp set');
        return;
      }
    }
    if (value.value && value.value.place_id &&
      props.settings && props.settings.reputation &&
      props.settings.reputation.comp_set_locations &&
      props.settings.reputation.comp_set_locations.list &&
      props.settings.reputation.comp_set_locations.list.length
    ) {
      if (props.settings.info && props.settings.info.comp_set_max_locations) {
        if (props.settings.reputation.comp_set_locations.list.length >= props.settings.info.comp_set_max_locations) {
          setLocationDetailsError(`Maximum of ${props.settings.info.comp_set_max_locations} locations can be added!`);
          return;
        }
      } else {
        if (props.settings.reputation.comp_set_locations.list.length >= 5) {
          setLocationDetailsError('Maximum of 5 locations can be added!');
          return;
        }
      }
    }
    clearData();
    setLocationDetails(value);
    setLocationDetailsError('');
    setSomeChange(true);
  }

  const onAddEditClick = (e) => {
    if (props.addEditInProgress) return;
    clearData();
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddEditClick title: ', locationDetails);
    }
    if (locationDetailsError) return;
    setLocationDetailsError(Object.keys(locationDetails).length ? '' : 'Required');
    if (someChange) {
      if (Object.keys(locationDetails).length && locationDetails.value && locationDetails.value.place_id &&
          locationDetails.value.structured_formatting && locationDetails.value.structured_formatting.main_text
        ) {
        if (props.type === 'add') {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            location_details: locationDetails,
            name: locationDetails.value.structured_formatting.main_text,
            place_id: locationDetails.value.place_id,
          };
          let op = 'edit_comp_set_location';
          dispatch(editDataAction({
            op,
            params,
          }));
        }
        if (props.type === 'edit') {
          let op = 'edit_comp_set_location';
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            location_details: locationDetails,
            name: locationDetails.value.structured_formatting.main_text,
            place_id: locationDetails.value.place_id,
          };
          params.comp_set_location_id = props.template._id;
          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
    }
  };


  const renderContent = () => {
    const { locationidentifier } = urlParams;
    const { templateType } = props;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.reputation) return null;

    let tData = {};

    if (props.type === 'edit') {
      if (!settings.reputation.comp_set_locations || !settings.reputation.comp_set_locations.keyValue) return null;
      tData = settings.reputation.comp_set_locations.keyValue[props.template._id] || {};
    }

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Comp Set`;
    if (props.type === 'edit') pageTitle = 'Edit Comp Set';
    if (props.title) pageTitle = props.title;


    let addEditInProgress = false;
    let addEditStatus = {};

    addEditInProgress = settings.reputation.comp_set_locations ? settings.reputation.comp_set_locations.addInProgress : false;
    addEditStatus = settings.reputation.comp_set_locations ? settings.reputation.comp_set_locations.addStatus : {};
    
    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    if (process.env.NODE_ENV !== 'production') {
      console.log('CompSetSingle addEditStatus: ', addEditStatus);
    }


    return (
      <div className="gPWrp fttSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Name
              </div>
              <div className={'gIpt ' + (locationDetailsError ? 'error' : '')}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD7etzCX-2KP87VsqK6v_zfrZqr52IiP-s"
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ['us', 'ca', 'in'],
                    },
                    fields: ["address_components", "geometry", "icon", "name"],
                    types: ['lodging']
                    // establishment
                  }}
                  selectProps={{
                    locationDetails,
                    onChange: autoCompleteChange,
                  }}
                  debounce={500}
                />
                { locationDetailsError && <p className="gErr mt5">{locationDetailsError}</p> }
              </div>
            </div>
          </div>
          
          
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully, reviews data will be populated in next 48hrs.</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('CompSetSingle props: ', props);
  }

  return renderContent();
}

export default CompSetSingle;
