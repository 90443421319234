import { genericItemTypeToKeyName } from '../../helpers';


/***************************************** Get Ai Model Data ****************************/

export const getAiModelData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: state.locations[location__id].settings.ai_model
          ?
            {
              ...state.locations[location__id].settings.ai_model,
              getInProgress: true, getStatus: {},
            }
          : {
              getInProgress: true, getStatus: {},
            },
        },
      },
    },
  };
};

export const getAiModelDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { ai_model_topics, ai_model_data, ai_model_all_topics } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: {
            ...state.locations[location__id].settings.ai_model,
            topics: ai_model_topics || [],
            all_topics: ai_model_all_topics || [],
            data: {
              ...ai_model_data,
            },
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getAiModelDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: {
            ...state.locations[location__id].settings.ai_model,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};


/***************************************** Get Ai Model Result ****************************/

export const getAiModelResultClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: {
            ...state.locations[location__id].settings.ai_model,
            result: state.locations[location__id].settings.ai_model.result
            ? {
                getInProgress: false, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getAiModelResult = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: {
            ...state.locations[location__id].settings.ai_model,
            result: state.locations[location__id].settings.ai_model.result
            ? {
                ...state.locations[location__id].settings.ai_model.result,
                getInProgress: true, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getAiModelResultSuccess = (state, action) => {
  const { data, params: { location__id, messages } } = action.payload;
  if (!location__id || !messages) return state;
  const { generated_response, messageList } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: {
            ...state.locations[location__id].settings.ai_model,
            result: {
              ...state.locations[location__id].settings.ai_model.result,
              getInProgress: false,
              getStatus: {
                success: true,
              },
              generated_response,
              messages: messageList,
            },
          },
        },
      },
    },
  };
};

export const getAiModelResultError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, messages } = params;
  if (!location__id || !messages) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ai_model: {
            ...state.locations[location__id].settings.ai_model,
            result: {
              ...state.locations[location__id].settings.ai_model.result,
              getInProgress: false,
              getStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/* ********************** Start - edit ai model data ******************* */

export const editAiModelDataClear = (state, action) => {
  const { params: { location__id, op, data_id, topic_id } } = action.payload;
  if (!location__id || !op) return state;
  if (op === 'edit-data') {
    if (!data_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                keyValue: {
                  ...state.locations[location__id].settings.ai_model.data.keyValue,
                  [data_id]: {
                    ...state.locations[location__id].settings.ai_model.data.keyValue[data_id],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-data') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              topicsManageInProgress: false,
              topicsManageStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editAiModelData = (state, action) => {
  const { params: { location__id, op, data_id, topic_id } } = action.payload;
  if (!location__id || !op) return state;
  if (op === 'edit-data') {
    if (!data_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
                data: {
                ...state.locations[location__id].settings.ai_model.data,
                keyValue: {
                  ...state.locations[location__id].settings.ai_model.data.keyValue,
                  [data_id]: {
                    ...state.locations[location__id].settings.ai_model.data.keyValue[data_id],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-data') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              topicsManageInProgress: true,
              topicsManageStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editAiModelDataSuccess = (state, action) => {
  const { params: { location__id, op, data_id, question, answer, is_removed, topics, topic_id } } = action.payload;
  if (!location__id || !op) return state;
  const { data } = action.payload;
  if (op === 'edit-data') {
    if (!data_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                keyValue: {
                  ...state.locations[location__id].settings.ai_model.data.keyValue,
                  [data_id]: {
                    ...state.locations[location__id].settings.ai_model.data.keyValue[data_id],
                    question,
                    answer,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
              topics: state.locations[location__id].settings.ai_model.topics.map((item) => {
                if (is_removed && item._id === topic_id) {
                  if (item.questions_count) {
                    item.questions_count--;
                  } else {
                    item.questions_count = 0;
                  }
                }
                return item;
              }),
            },
          },
        },
      },
    };
  }
  if (op === 'add-data') {
    if (!data.new_data || !data.new_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                list: state.locations[location__id].settings.ai_model.data.list
                  ? [ data.new_data._id, ...state.locations[location__id].settings.ai_model.data.list]
                  : [ data.new_data._id ],
                keyValue: {
                  ...state.locations[location__id].settings.ai_model.data.keyValue,
                  [data.new_data._id]: {
                    ...data.new_data,
                  },
                },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
              topics: state.locations[location__id].settings.ai_model.topics.map((item) => {
                if (item._id === data.new_data.topic_id) {
                  if (!item.questions_count) item.questions_count = 0;
                  item.questions_count++;
                }
                return item;
              }),
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    if (!data.ai_model_topics) return state;
    const { ai_model_topics } = data;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              topics: ai_model_topics,
              topicsManageInProgress: false,
              topicsManageStatus: {
                success: true,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editAiModelDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, op, data_id, topic_id } = params;
  if (!location__id || !op) return state;
  if (op === 'edit-data' && data_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                keyValue: {
                  ...state.locations[location__id].settings.ai_model.data.keyValue,
                  [data_id]: {
                    ...state.locations[location__id].settings.ai_model.data.keyValue[data_id],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-data') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              data: {
                ...state.locations[location__id].settings.ai_model.data,
                addInProgress: false,
                addStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            ai_model: {
              ...state.locations[location__id].settings.ai_model,
              topicsManageInProgress: false,
              topicsManageStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    };
  }
  return state;
};

/* ********************** End - edit ai model data ******************* */
