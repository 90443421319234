import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { isGTUser, hasProductAccess } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';

import '../style.css';


function RemoteLockInitiate(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  let aLink = `https://connect.remotelock.com/oauth/authorize?client_id=d6e1825c20fa882a4d1c492e42e40edea5d8e41641b380bc2ae32c308943b970&redirect_uri=https%3A%2F%2Fapp.guesttouch.com%2Fintg-configure%2Fremote-lock&response_type=code`

  return (
    <div className="gPScrlWrp flHt allHme">
      <div className="gPWrp homeAllLocsPg">
        <div className="gPTle">
          Initiate Remote Lock Integration
        </div>
        <div className="gBCtnt">
          <a href={aLink}>Initiate</a>
        </div>
      </div>
    </div>
  );

}

export default RemoteLockInitiate;
