// @flow

import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import { mapReviewStateToString } from '../../../../../helpers';

import SingleTemplate from './SingleTemplate';

import './style.css';

import closeIcon from '../../../../../img/icons/close.svg';


const categoryList = [
  { '_id': 1, id: 'all', title: 'All' },
  { '_id': 2, id: 'positive', 'title': 'Positive' },
  { '_id': 3, id: 'negative', 'title': 'Negative' },
  { '_id': 4, id: 'support' ,'title': 'Support' },
  { '_id': 5, id: 'wifi', 'title': 'Wifi' },
];
const templateList = [
  { '_id': 1, 'title': '5 start basic', 'msg': 'Here is the response for 5 start basic, Here is the response for 5 start basic, Here is the response for 5 start basic, Here is the response for 5 start basic'},
  { '_id': 2, 'title': '5 start special', 'msg': 'Here is the response for 5 start basic, Here is the response for 5 start basic, Here is the response for 5 start basic'},
  { '_id': 3, 'title': '5 start location', 'msg': 'Here is the response for 5 start basic, Here is the response for 5 start basic, Here is the response for 5 start basic, Here is the response for 5 start basic'},
  { '_id': 4, 'title': '5 start beds', 'msg': 'Here is the response for 5 start basic'},
  { '_id': 5, 'title': '5 start wifi', 'msg': 'Here is the response for 5 start basic'},
  { '_id': 6, 'title': '5 start parking', 'msg': 'Here is the response for 5 start basic'},
  { '_id': 6, 'title': '5 start parking', 'msg': 'Here is the response for 5 start basic'},
  { '_id': 6, 'title': '5 start parking', 'msg': 'Here is the response for 5 start basic'},
  { '_id': 6, 'title': '5 start parking', 'msg': 'Here is the response for 5 start basic'},
  { '_id': 6, 'title': '5 start parking', 'msg': 'Here is the response for 5 start basic'},
];

function CategoryName(props) {
  const onCategoryClick = (e) => { props.onCategoryClick(props.data); };
  return (
    <div className={'categoryName ' + (props.selected ? 'active' : '')} onClick={onCategoryClick}>
      { props.data.name }
    </div>
  );
}

function ResponseTemplateSelector(props) {
  const [selctedCategory, setSelectedCategory] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    // if (props.mode === 'modal') {
    //   gtag('event', 'gt_click', {
    //     'event_category': `Response Templates Selector - Modal`,
    //     'event_label': `Modal`,
    //     'value': props.reviewId,
    //     user_id: props.userprops.user.uid,
    //   });
    // }
    // if (props.mode === 'embed') {
    //   gtag('event', 'gt_click', {
    //     'event_category': `Response Templates Selector - Embed`,
    //     'event_label': `Embed`,
    //     'value': props.reviewId,
    //     user_id: props.userprops.user.uid,
    //   });
    // }
  }, [props.reviewId]);

  // useEffect(() => {
  //   if (locationidentifier && props.userprops.user.locations && props.userprops.user.locations.keyValue &&
  //     props.userprops.user.locations.keyValue[locationidentifier]
  //   ) {
  //     props.dispatch(getDashboardDataAction({
  //       params: {
  //         location__id: locationidentifier,
  //       }
  //     }));
  //   }
  // }, []);

  const onCloseClick = (e) => { props.onCancelClick(); };

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
    setSelectedCategory('all');
    // if (value) {
    //   gtag('event', 'search', {
    //     'search_term': value,
    //   });
    //   gtag('event', 'gt_click', {
    //     'event_category': `Response Templates Selector - Search - Keyword`,
    //     'event_label': `Keyword`,
    //     'value': value,
    //     user_id: props.userprops.user.uid,
    //   });
    // }
  }

  const onCategoryClick = (category) => {
    setSelectedCategory(category._id);
  };

  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 13) {
    }
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  const renderContent = () => {
    if (!props.settings || !props.settings.reputation) return null;
    const { reputation } = props.settings;
    if (!reputation.response_template_categories) return null;
    if (!reputation.response_template_categories.list ||
      !reputation.response_template_categories.list.length
    ) {
      return (
        <div className="innerBox">
          <div className="responseTemplates">
            <div className="header">
              <div className="title">
                <p>No Response Templates Found</p>
                {
                  props.mode === 'embed' &&
                  <Link to={`/settings/locations/${props.locationId}/response-templates/templates`}>Add Response Templates</Link>
                }
              </div>
              {
                props.mode === 'modal' &&
                <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
              }
            </div>
            {
              props.mode === 'modal' &&
              <div className="centerBody">
                <Link to={`/settings/locations/${props.locationId}/response-templates/templates`}>Add Response Templates</Link>
              </div>
            }
          </div>
        </div>
      );
    }
    return (
      <div className="gPWrp respTmplsBx">
        <div className="gPTle title">
          Response Templates
          {
            props.mode === 'modal' &&
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
          }
        </div>
        <div className="gBCtnt">
          <div className="innerBox">
            <div className="responseTemplates">
              <div className="body">
                <div className="left">
                  <div className="categoryList">
                    {
                      reputation.response_template_categories &&
                      reputation.response_template_categories.list &&
                      reputation.response_template_categories.keyValue
                      ?
                        reputation.response_template_categories.list.map((item) => {
                          if (reputation.response_template_categories.keyValue[item]) {
                            return <CategoryName
                              key={`rts_c_${item}`}
                              data={reputation.response_template_categories.keyValue[item]}
                              selected={selctedCategory === item}
                              onCategoryClick={onCategoryClick}
                            />
                          }
                          return null;
                        })
                      : null
                    }
                  </div>
                </div>
                <div className="right">
                  <div className="search">
                    <input
                      type="text"
                      value={searchKeyword}
                      onChange={onSearchChange}
                      placeholder="Search Templates"
                      onKeyDown={onSearchInputKeyDown}
                    />
                  </div>
                  <div className="templateList">
                    {
                      reputation.response_templates &&
                      reputation.response_templates.list &&
                      reputation.response_templates.keyValue
                      ?
                        reputation.response_templates.list.map((item) => {
                          if (reputation.response_templates.keyValue[item]) {
                            if (searchKeyword) {
                              const template = reputation.response_templates.keyValue[item];
                              if ((template.title && template.title.indexOf(searchKeyword) > -1) ||
                                (template.response && template.response.indexOf(searchKeyword) > -1)
                              ) {
                                return <SingleTemplate
                                  key={`rts_t_${item}`}
                                  data={reputation.response_templates.keyValue[item]}
                                />
                              }
                              return null;
                            } else {
                              if (selctedCategory === 'all' ||
                                reputation.response_templates.keyValue[item].category_id === selctedCategory
                              ) {
                                return <SingleTemplate
                                  data={reputation.response_templates.keyValue[item]}
                                />
                              }
                            }
                          }
                          return null;
                        })
                      : null
                    }
                    {/*
                      templateList
                      .filter((template) => {
                        if (template.title.indexOf(searchKeyword) > -1 || template.msg.indexOf(searchKeyword) > -1) {
                          return true;
                        }
                        return false;
                      })
                      .map((item) => {
                        return <SingleTemplate data={item} />
                      })
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };


  const cStyle = {};
  if (props.mode === 'modal') {
    cStyle['maxHeight'] = props.windowState.window.windowHeight * 1; // 0.95
  }

  return (
    <div className="gBoxWrap rtsBoxWrap" style={cStyle}>
        { renderContent() }
    </div>
  );
}

export default ResponseTemplateSelector;
