import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { Rnd } from "react-rnd";

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import createNewIcon from '../../../../img/icons/create-new-icon.svg';
import broadcastIcon from '../../../../img/icons/active-broadcast-icon.svg';
import broadcastActiveIcon from '../../../../img/icons/active-broadcast-active-icon.svg';
import broadcastHistoryIcon from '../../../../img/icons/broadcast-history-icon.svg';
import broadcastHistoryActiveIcon from '../../../../img/icons/broadcast-history-active-icon.svg';

import './style.css';

function Stage(props) {
  const { stage, locationidentifier, active, link } = props;
  return (
    <Link to={link}
      className={(active ? 'active' : '')}
    >
      <div className="lt">
        <img src={active ? stage.iconActive : stage.icon} />
      </div>
      <div className="rt">
        <p className="title">{stage.name}</p>
        <p className="desc">{stage.desc}</p>
      </div>
    </Link>
  );
}

function ListPanel(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [boxWidth, setBoxWidth] = useState(240);
  const [list, setList] = useState([]);

  useEffect(() => {
    let listLocal = [];
    listLocal.push({ 'id': 'all', 'name': 'Scheduled Broadcasts', 'desc': 'See all your scheduled broadcasts', 'icon': broadcastIcon, 'iconActive': broadcastActiveIcon });
    listLocal.push({ 'id': 'history', 'name': 'History', 'desc': 'See broadcast sent history', 'icon': broadcastHistoryIcon, 'iconActive': broadcastHistoryActiveIcon });
    setList(listLocal);
  }, []);

  const { locationidentifier, stage } = urlParams;

  return (
    <>
      <Rnd
        className={'fdbMenuLst'}
        size={{width: boxWidth}}
        style={{}}
        disableDragging={true}
        enableResizing={{
          right: true,
        }}
        maxWidth={300}
        minWidth={180}
        onResize={(e, direction, ref, delta, position) => {
          setBoxWidth(ref.style.width);
        }}
      >
        <div className="rszIndc">
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
        </div>
        <>
          <div className="hdr">
            Broadcast
          </div>
          <div className="mnuLst">
            {
              list.map((stage) => {
                let active = location.pathname.indexOf(`/engage/locations/${locationidentifier}/broadcast/${stage.id}`) > -1;
                return (
                  <Stage key={`te_stg_${stage.id}`} stage={stage} locationidentifier={locationidentifier} active={active}
                    link={`/engage/locations/${locationidentifier}/broadcast/${stage.id}`}
                  />
                )
              })
            }
          </div>
        </>
      </Rnd>
    </>
  );
}

export default ListPanel;
