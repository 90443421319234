import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../../Misc/GenericSideModal';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import GenericList from '../../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { getDataAction } from '../../../../../redux/actions/getDataActions';

import { STRS } from '../../../../../helpers';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import './style.css';

function RWRules(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RW urlParams: ', urlParams);
      console.log('RW locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(getDataAction({
      op: 'get_reviews_widget_rules',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);


  const { settings: { reviews_widget } } = props;

  if (process.env.NODE_ENV !== 'production') {
    console.log('RWRules props: ', props);
  }

  return (
    <>
      {
        reviews_widget && reviews_widget.rules && reviews_widget.rules.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <GenericList
        className="wc_rules"
        type="rw_rules"
        title={`Rules${props.isReadOnly ? ` [${STRS.READ_ONLY_LABEL}]` : ''}`}
        sub_title="Customize behaviour using rules for specfic web addresses"
        location__id={urlParams.locationidentifier}
        generic_list={reviews_widget && reviews_widget.rules ? reviews_widget.rules : []}
        settings={props.settings}
      />
    </>
  );
}

export default RWRules;
