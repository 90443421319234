import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBarInner from '../../SideBarInner';
import WCGeneral from './WCGeneral';
import WCDisplay from './WCDisplay';
import WCHelpGuide from './WCHelpGuide';
import WCChatChannels from './WCChatChannels';
import WCChatChannelsRouter from './WCChatChannels/WCChatChannelsRouter';
import DGPages from './DGPages';
import DGRooms from './DGRooms';
import WCDeploy from './WCDeploy';

import { getDataAction } from '../../../../redux/actions/getDataActions';

function DigitalGuide(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Digital Guide - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    console.log('Digital Guide urlParams: ', urlParams);
    console.log('Digital Guide locationidentifier: ', urlParams.locationidentifier);
    dispatch(getDataAction({
      op: 'get_digital_guide_settings',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);

  if (!props.locationInfo.has_engage || !props.locationInfo.has_digital_guide) return null;

  const { digital_guide } = props.settings;

  return (
    <>
      {
        digital_guide && digital_guide.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <SideBarInner />
      <div className="sc">
        <Routes>
          <Route path="general" element={<WCGeneral { ...props } />} />
          <Route path="display" element={<WCDisplay { ...props } />} />
          <Route path="help-guide" element={<WCHelpGuide { ...props } />} />
          <Route path="channels/*" element={<WCChatChannelsRouter { ...props } />} />
          <Route path="hotel-information" element={<DGPages { ...props } />} />
          <Route path="rooms" element={<DGRooms { ...props } />} />
          <Route path="deploy" element={<WCDeploy { ...props } />} />
        </Routes>
      </div>
    </>
  );
}

export default DigitalGuide;
