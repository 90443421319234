// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../Misc/OutsideClickHook';

import { validateEmail, isContactValid } from '../../../../helpers';
import { formatDate, formatDateTime, getSearchGuestTypeByTransactionType, broadcastWeekDayToLabel, broadcastTargetToLabel,
  broadcastTargetDaysAheadToLabel, getUserGAId } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';
import moreIcon from '../../../../img/icons/more.svg';
import oneTimeIcon from '../../../../img/icons/broadcast-onetime.svg';
import recurringIcon from '../../../../img/icons/broadcast-recurring.svg';

import './style.css';

function SingleBroadcastBox(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();

  const [isEnabled, setIsEnabled] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const cMenuRef = useRef();
  useOnClickOutside(cMenuRef, useCallback(() => closeMenu()));

  const [sendInfo, setSendInfo] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [nextRun, setNextRun] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Broadcast - Single - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    setIsEnabled(props.broadcast.is_enabled);
  }, [props.broadcast.is_enabled]);

  useEffect(() => {
    const { broadcast } = props;
    let text = 'Send';
    if (broadcast.when === 'now') text = `${text} Now`;
    if (broadcast.when === 'later') text = `${text} at ${broadcast.when_schedule_time}`;
    if (broadcast.when_all_days) text = `${text} Everyday`;
    if (broadcast.when_days && Object.keys(broadcast.when_days).length) {
      // text = `${text} on ${Object.keys(broadcast.when_days).join(', ')}`;
      const numOfDays = Object.keys(broadcast.when_days).length;
      text = `${text} on `;
      Object.keys(broadcast.when_days).forEach((item, index) => {
        text = `${text} ${broadcastWeekDayToLabel[item]}${index < numOfDays-1 ? ',' : ''}`;
      });
    }
    setSendInfo(text);

    let tempNextRun = '';
    if (broadcast.when_schedule_time) {
      tempNextRun = moment(broadcast.when_schedule_time, 'HH:mm').format('hh:mm A');
    }
    if (broadcast.type === 'recurring') {
      if (broadcast.when_all_days) {
        if (moment.utc(broadcast.when_schedule_time_utc, 'HH:mm') > moment().utc()) {
          tempNextRun = `Today ${tempNextRun}`;
        } else {
          tempNextRun = `Tomorrow ${tempNextRun}`;
        }
      } else {
        if (broadcast.when_days && Object.keys(broadcast.when_days).length) {
          let findNextDay = false;
          if (broadcast.when_days[moment().format('dddd').toLowerCase()]) {
            if (moment.utc(broadcast.when_schedule_time_utc, 'HH:mm') > moment().utc()) {
              tempNextRun = `Today ${tempNextRun}`;
            } else {
              findNextDay = true;
            }
          } else {
            findNextDay = true;
          }
          if (findNextDay) {
            let i = 1, foundDay = false;
            while (i < 7 && !foundDay) {
              if (broadcast.when_days[moment().add(i, 'day').format('dddd').toLowerCase()]) {
                foundDay = true;
                tempNextRun = `${moment().add(i, 'day').format('ddd')} ${tempNextRun}`;
              }
              i++;
            }
          }
        }
      }
    }
    setNextRun(tempNextRun);

  }, [props.broadcast]);

  useEffect(() => {
    if (!props.broadcast.editInProgress && props.broadcast.editStatus && props.broadcast.editStatus.success) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('edit success');
      }
      setShowDeleteModal(false);
    }
    if (!props.broadcast.editInProgress && props.broadcast.editStatus && props.broadcast.editStatus.error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('edit failure');
      }
      setIsEnabled(!isEnabled);
    }
  }, [props.broadcast.editInProgress]);

  const closeMenu = (e) => {
    setMenuOpen(false);
  };
  const onOpenMenuClick = (e) => {
    setMenuOpen(true);
  };

  const onEditClick = (e) => {
    // navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/edit/${props.broadcast._id}`);
    props.onEditClick(props.broadcast);
  };
  const onDeleteClick = (e) => {
    setShowDeleteModal(true);
  };

  const onIsEnabledChange = (e) => {
    const { name, checked } = e.target;
    setIsEnabled(checked);
    dispatch(editDataAction({
      op: 'edit_broadcast',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'edit',
        is_enabled: checked,
        broadcast_id: props.broadcast._id,
      },
    }));
  };

  const onShowDeleteModal = (e) => {
    setShowDeleteModal(false);
  }
  const onDeleteConfirmClick = (e) => {
    dispatch(editDataAction({
      op: 'edit_broadcast',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'edit',
        is_removed: true,
        broadcast_id: props.broadcast._id,
      },
      show_alert: true,
      alert_msg: 'Deleted successfully',
    }));
  }

  const renderOverflowMenu = () => {
    return (
      <div className="oMenu">
        <div className="dropdown" ref={cMenuRef}>
          <button className="dropbtn" onClick={onOpenMenuClick} ><img src={moreIcon} /></button>
          <div className={'dropdown-content ' + (menuOpen ? 'open ' : '')}>
            <p onClick={onEditClick}>Edit</p>
            <p onClick={onDeleteClick}>Delete</p>
          </div>
        </div>
      </div>
    );
  }

  const renderContextMenu = () => {
    return (
      <div className="cMenu">
        <div className="edit" onClick={onEditClick}>
          <img src={editIcon} />
        </div>
        {
          !props.isReadOnly &&
          <div className="delete" onClick={onDeleteClick}>
            <img src={deleteIcon} />
          </div>
        }
      {/* renderOverflowMenu() */}
      </div>
    );
  };

  const renderContent = () => {
    const { broadcast } = props;
    const cStyle = {};
    // cStyle = {
    //   maxWidth: props.style.width - 200,
    // };
    let dayInfo = '';
    if (broadcast.type === 'recurring') {
      if (broadcast.when_all_days) {
        dayInfo = 'Everyday';
      } else {
        if (broadcast.when_days && Object.keys(broadcast.when_days).length) {
          Object.keys(broadcast.when_days).forEach((item) => {
            if (broadcast.when_days[item]) {
              if (dayInfo) { dayInfo = `${dayInfo}, ${broadcastWeekDayToLabel[item]}`; }
              else { dayInfo = `${broadcastWeekDayToLabel[item]}`; }
            }
          });
        }
      }
    }
    if (broadcast.type === 'one-time') {
      if (broadcast.when === 'later') {
        dayInfo = moment(broadcast.when_schedule_date, 'YYYY-MM-DD').format('Do MMM YYYY');
      }
    }

    return (
      <div className="broadcastBox" id={`bitem_${broadcast._id}`}>
        <div className="typeBox">
          <div className="type">
            <span>
              {
                broadcast.type === 'one-time' && <img src={oneTimeIcon} />
              }
              {
                broadcast.type === 'recurring' && <img src={recurringIcon} />
              }
              {broadcast.type}
            </span>
          </div>
        </div>

        <div className="whenBox">
          <div className="title">
            <span>
              Scheduled at:
            </span>
          </div>
          <div className="when">
            <p className="day">{dayInfo} - {moment(broadcast.when_schedule_time, 'HH:mm').format('hh:mm A')}</p>
          </div>
        </div>

        {/*<div className="top">
          <div className="type">
            <span>
              {
                broadcast.type === 'one-time' && <img src={oneTimeIcon} />
              }
              {
                broadcast.type === 'recurring' && <img src={recurringIcon} />
              }
              {broadcast.type}
            </span>
          </div>
          <div className="when">
            <p className="day">{dayInfo}</p>
            <p className="time">{broadcast.when_schedule_time}</p>
          </div>
        </div>*/}

        <div className="content">
          <div className="nameBox">
            <div className="name">
              {broadcast.name}
            </div>
            <div className="right">
              {
                props.isReadOnly
                ?
                <span>Active</span>
                :
                <div className="gToggleWrap">
                  { broadcast.editInProgress && <span className="status"><i className="fa fa-spinner fa-spin" /></span> }
                  <div className="gToggleBig">
                    <input type="checkbox" id={`bBox_${broadcast._id}_enabled`} checked={isEnabled} onChange={onIsEnabledChange}/><label htmlFor={`bBox_${broadcast._id}_enabled`}>Toggle</label>
                  </div>
                </div>
              }
              {
                !props.grid &&
                renderContextMenu()
              }
            </div>
          </div>
          {
            props.grid &&
            <div className="lineBreak" />
          }
          <div className="targetBox">
            <div className="target">
              <p>{broadcastTargetToLabel[broadcast.target]}</p>
              {
                broadcast.target_check_in_days_ahead ? <p className="daysAhead">{broadcastTargetDaysAheadToLabel[broadcast.target_check_in_days_ahead]}</p> : null
              }
              {
                broadcast.target_check_out_days_ahead ? <p className="daysAhead">{broadcastTargetDaysAheadToLabel[broadcast.target_check_out_days_ahead]}</p> : null
              }
            </div>

            {
              props.grid &&
              renderContextMenu()
            }
          </div>
          {
            broadcast.target === 'checked-in' && broadcast.target_days_since_check_in &&
            <div className="message">
              Days since check-in: {broadcast.target_days_since_check_in.split('-day')[0]}
            </div>
          }
          <div className="message">
            {broadcast.message}
          </div>
          {
            broadcast.is_enabled && nextRun &&
            <div className="nextRun">
              <span>Next Run:</span> {nextRun}
            </div>
          }
          {
            showDeleteModal &&
            <GenericAlertModal
              modalType="deleteBroadcast"
              bodyRowOne={`Do you want to delete "${broadcast.name}" broadcast ?`}
              setModalVisible={onShowDeleteModal}
              onAffirmativeClick={onDeleteConfirmClick}
              inProgress={broadcast.editInProgress}
              showCancel={true}
            />
          }
        </div>
      </div>
    );
  };

  return renderContent();
}

export default SingleBroadcastBox;
