// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import { getDataAction } from '../../../../redux/actions/getDataActions';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { formatDate, formatDateTimeOrig, getSearchGuestTypeByTransactionType, broadcastTypeToLabel,
  broadcastTargetToLabel, broadcastTargetDaysAheadToLabel, } from '../../../../helpers';

import useDebounce from '../../../Misc/DebounceHook';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';

import './style.css';

function SentEntry(props) {
  const { data } = props;
  return (
    <div className="row">
      <div className="name">
        {data.guest_info ? data.guest_info.first_name || data.guest_info.name : ''}
      </div>
      <div className="contact">
        {data.message_data && data.message_data.to ? data.message_data.to : ''}
      </div>
      <div className="message">
        {data.message_data && data.message_data.msg ? data.message_data.msg : ''}
      </div>
      <div className="sentAt">
        {data.message_data ? formatDateTimeOrig(data.message_data.date_created) : ''}
      </div>
      <div className="status">
        {data.message_data ? data.message_data.status : ''}
      </div>
    </div>
  );
}

function BroadcastRunDetail(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    dispatch(getDataAction({
      op: 'get_broadcast_run_detail',
      params: {
        location__id: props.locationidentifier,
        broadcast_run_id: props.broadcastRunId,
      },
    }));
  }, []);

  const onCloseClick = (e) => {
    props.onCloseClick(e);
  };

  const renderListHeader = () => {
    return (
      <div className="row header">
        <div className="name">
          Name
        </div>
        <div className="contact">
          Contact
        </div>
        <div className="message">
          Message
        </div>
        <div className="sentAt">
          Sent at
        </div>
        <div className="status">
          Status
        </div>
      </div>
    );
  }
  const renderContent = (history) => {
    const { broadcastRunId } = props;
    if (!history || !history.keyValue || !history.keyValue[broadcastRunId]) {
      return null;
    }
    const broadcastInfo = history.keyValue[broadcastRunId];
    if (process.env.NODE_ENV !== 'production') {
      console.log('broadcastInfo: ', broadcastInfo);
    }
    let whenDays = broadcastInfo.broadcast_data.when_days
      ? Object.keys(broadcastInfo.broadcast_data.when_days).
        reduce((accumulator, currentValue) => { return `${accumulator}${currentValue}, `; }, '') : '';
    let labelsStr = '';
    if (broadcastInfo.broadcast_data.target_labels && broadcastInfo.broadcast_data.target_labels.length) {
      broadcastInfo.broadcast_data.target_labels.forEach((item) => {
        if (item.label) {
          if (labelsStr) {
            labelsStr = `${labelsStr}, ${item.label}`;
          } else {
            labelsStr = `${item.label}`;
          }
        }
      });
    }
    return (
      <>
        {  broadcastInfo.getDetailInProgress &&
          <div className="divLoader">
            <i className="fa fa-spinner fa-spin" />
          </div>
        }
        <div className="gPageWrap broadcastRunDetail">
          <div className="basicInfo">
            <div className="row">
              <div className="left">
                Name
              </div>
              <div className="right">
                {broadcastInfo.broadcast_data.name || ''}
              </div>
            </div>
            <div className="row">
              <div className="left">
                Type
              </div>
              <div className="right">
                {broadcastTypeToLabel[broadcastInfo.broadcast_data.type]}
              </div>
            </div>
            <div className="row">
              <div className="left">
                Target
              </div>
              <div className="right">
                {
                  broadcastInfo.broadcast_data.target_check_in_date
                  ? `${broadcastTargetToLabel[broadcastInfo.broadcast_data.target]} - ${moment(broadcastInfo.broadcast_data.target_check_in_date).format('Do MMM YYYY')}`
                  : ''
                }
                {
                  broadcastInfo.broadcast_data.target_check_in_days_ahead
                  ? `${broadcastTargetToLabel[broadcastInfo.broadcast_data.target]} - ${broadcastTargetDaysAheadToLabel[broadcastInfo.broadcast_data.target_check_in_days_ahead]}`
                  : ''
                }
                {
                  broadcastInfo.broadcast_data.target_check_out_days_ahead
                  ? `${broadcastTargetToLabel[broadcastInfo.broadcast_data.target]} - ${broadcastTargetDaysAheadToLabel[broadcastInfo.broadcast_data.target_check_out_days_ahead]}`
                  : ''
                }
                {
                  !broadcastInfo.broadcast_data.target_check_in_date &&
                  !broadcastInfo.broadcast_data.target_check_in_days_ahead &&
                  !broadcastInfo.broadcast_data.target_check_out_days_ahead
                  ? `${broadcastTargetToLabel[broadcastInfo.broadcast_data.target]}`
                  : ''
                }
              </div>
            </div>
            {
              labelsStr
              ?
                <div className="row">
                  <div className="left">
                    Target Labels
                  </div>
                  <div className="right">
                    { labelsStr }
                  </div>
                </div>
              : null
            }
            <div className="row">
              <div className="left">
                Scheduled at
              </div>
              <div className="right">
                {
                  broadcastInfo.broadcast_data.type === 'recurring' && broadcastInfo.broadcast_data.when_all_days
                  ? `Everyday - ${moment(broadcastInfo.broadcast_data.when_schedule_time, 'HH:mm').format('hh:mm A')}` : ''
                }
                {
                  broadcastInfo.broadcast_data.type === 'recurring' && !broadcastInfo.broadcast_data.when_all_days
                  ? `${whenDays} - ${moment(broadcastInfo.broadcast_data.when_schedule_time, 'HH:mm').format('hh:mm A')}` : ''
                }
                {
                  broadcastInfo.broadcast_data.type === 'one-time' && broadcastInfo.broadcast_data.when === 'later'
                  ? `${broadcastInfo.broadcast_data.when_schedule_date} - ${moment(broadcastInfo.broadcast_data.when_schedule_time, 'HH:mm').format('hh:mm A')}` : ''
                }
                {
                  broadcastInfo.broadcast_data.type === 'one-time' && broadcastInfo.broadcast_data.when === 'now'
                  ? `${broadcastInfo.date_created_tz}` : ''
                }
              </div>
            </div>
            <div className="row">
              <div className="left">
                Message
              </div>
              <div className="right">
                {broadcastInfo.broadcast_data.message}
              </div>
            </div>
          </div>
          <div className="recipientsInfo">
            <div className="title">Recipents</div>
            { renderListHeader() }
            {
              !broadcastInfo.getDetailInProgress && broadcastInfo.run_status && broadcastInfo.run_status.sent && broadcastInfo.run_status.sent.length
              ?
                broadcastInfo.run_status.sent.map((item) => {
                  return <SentEntry data={item} />
                })
              : null
            }
          </div>
        </div>
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('BroadcastHistory props: ', props);
  }

  if (!props.broadcastRunId) return null;

  const { history } = props.engage.broadcast;
  if (!history) return null;


  return (
    <div className="gPWrp">
      <div className="gPTle title">
        Run Detail
        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
      </div>
      {/*
        broadcastInfo && broadcastInfo.name &&
        <div className="gPSubTle title">
          {broadcastInfo.name}
        </div>
      */}
      <div className="gBCtnt">
        { renderContent(history) }
      </div>
    </div>
  );
}

export default BroadcastRunDetail;
