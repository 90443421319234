import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import { isLocationAdminAndAbove } from '../../../../../helpers';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import '../style.css';

function BookingSourceRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  return (
    <div className="gBCtRw bkgSrcRw" id={`rc_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.label}
              />
            : template.label
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
                <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
              </ToolTipWrapper>
              {
                isLocationAdminAndAbove(props.accessLevel) &&
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
                  <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
                </ToolTipWrapper>
              }
            </>
          }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          {
            props.keyword
            ?
              <span>Value:
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.keyword]}
                  autoEscape={true}
                  textToHighlight={template.value}
                />
              </span>
            : <span>Value: {template.value}</span>
          }
        </div>
      </div>
    </div>
  )

}

export default BookingSourceRow;
