import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

function PromptRow(props) {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    setToggleOn(props.template.is_enabled || false);
  }, []);

  useEffect(() => {
    if (props.template && !props.template.editInProgress && props.template.editStatus && props.template.editStatus.error) {
      setToggleOn(props.template.is_enabled || false);
    }
  }, [props.template]);

  const onDefaultClick = (e) => {
    props.onDefaultClick(props.template);
  };
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };
  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    setToggleOn(checked);
    props.onEnableDisableClick({
      ...props.template,
      is_enabled: checked,
    });
  };


  const { template } = props;
  let infoStr = 'Default follow up template';
  if (!template.is_readonly) infoStr = 'Custom follow up template';

  return (
    <div className="gBCtRw respTplRw" id={`mt_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.title}
              />
            : template.title
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            {
              props.promptType === 'regular' && template.is_enabled &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={template.is_default ? 'Clear default status' : 'Mark as default'}>
                <span className={(template.is_default ? 'is_def' : '')} onClick={onDefaultClick}><img className={'def'} src={tickIcon} /></span>
              </ToolTipWrapper>
            }
            {
              !template.is_readonly &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
                <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
              </ToolTipWrapper>
            }
            {
              !template.is_readonly &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
                <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
              </ToolTipWrapper>
            }
            {
              (props.promptType === 'regular' || props.promptType === 'followup') &&
              <div className="gToggleWrap">
                <div className="gToggle">
                  <input type="checkbox" id={`toggleOn_${template._id}`} checked={toggleOn} onChange={onToggleChange}/><label htmlFor={`toggleOn_${template._id}`}>Toggle</label>
                </div>
              </div>
            }
            {
              props.promptType === 'followup' &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
                overlay={infoStr}
              >
                <i className="fas fa-info-circle" />
              </ToolTipWrapper>
            }
            </>
          }
        </div>
      </div>
      <div className="bt">
        {
          props.templateType === 'responseTemplates' &&
          <>
            {
              props.keyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.keyword]}
                  autoEscape={true}
                  textToHighlight={template.response}
                />
              : template.response
            }
          </>
        }
        {
          props.templateType === 'promptTemplates' &&
          <>
            {
              props.keyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.keyword]}
                  autoEscape={true}
                  textToHighlight={template.prompt}
                />
              : template.prompt
            }
          </>
        }
      </div>
    </div>
  )

}

export default PromptRow;
