import moment from 'moment';
import { getGuestTypeKey, guestTypeKeyByTransactionStatus } from '../../helpers';

import * as groupHelper from './groupHelper';

const initialState = {
  groups: {},
  shared_groups: {},
};

const groupReducer = (state = initialState, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('groupReducer action: ', action);
  }
  switch (action.type) {

    case 'USER_LOGOUT': {
      return initialState;
    }

    // case "INIT_REPUTATION": {
    //   return reputationHelper.initReputation(state, action);
    // }


    /* Get Groups */

    case "GET_GROUPS_CLEAR": {
      return groupHelper.getGroupsClear(state, action);
    }
    case "GET_GROUPS": {
      return groupHelper.getGroups(state, action);
    }
    case "GET_GROUPS_SUCCESS": {
      return groupHelper.getGroupsSuccess(state, action);
    }
    case "GET_GROUPS_ERROR": {
      return groupHelper.getGroupsError(state, action);
    }


    /* Get Groups Summary Data */

    case "GET_GROUPS_SUMMARY_DATA": {
      return groupHelper.getGroupsSummaryData(state, action);
    }
    case "GET_GROUPS_SUMMARY_DATA_SUCCESS": {
      return groupHelper.getGroupsSummaryDataSuccess(state, action);
    }
    case "GET_GROUPS_SUMMARY_DATA_ERROR": {
      return groupHelper.getGroupsSummaryDataError(state, action);
    }


    /* Get Group Dashboard Data */

    case "GET_GROUP_DASHBOARD_DATA": {
      return groupHelper.getGroupDashboardData(state, action);
    }
    case "GET_GROUP_DASHBOARD_DATA_SUCCESS": {
      return groupHelper.getGroupDashboardDataSuccess(state, action);
    }
    case "GET_GROUP_DASHBOARD_DATA_ERROR": {
      return groupHelper.getGroupDashboardDataError(state, action);
    }

    /* Get Group Reviews Data */

    case "GET_GROUP_REVIEWS_DATA": {
      return groupHelper.getGroupReviewsData(state, action);
    }
    case "GET_GROUP_REVIEWS_DATA_SUCCESS": {
      return groupHelper.getGroupReviewsDataSuccess(state, action);
    }
    case "GET_GROUP_REVIEWS_DATA_ERROR": {
      return groupHelper.getGroupReviewsDataError(state, action);
    }

    /* Get Group Sentiments Data */

    case "GET_GROUP_SENTIMENTS_DATA": {
      return groupHelper.getGroupSentimentsData(state, action);
    }
    case "GET_GROUP_SENTIMENTS_DATA_SUCCESS": {
      return groupHelper.getGroupSentimentsDataSuccess(state, action);
    }
    case "GET_GROUP_SENTIMENTS_DATA_ERROR": {
      return groupHelper.getGroupSentimentsDataError(state, action);
    }


    /* Group Edit Review  */

    case "EDIT_GROUP_REVIEW_CLEAR": {
      return groupHelper.editGroupReviewClear(state, action);
    }
    case "EDIT_GROUP_REVIEW": {
      return groupHelper.editGroupReview(state, action);
    }
    case "EDIT_GROUP_REVIEW_SUCCESS": {
      return groupHelper.editGroupReviewSuccess(state, action);
    }
    case "EDIT_GROUP_REVIEW_ERROR": {
      return groupHelper.editGroupReviewError(state, action);
    }


    /* Group Update Review Translation */

    case "UPDATE_GROUP_REVIEW_TRANSLATION_CLEAR": {
      return groupHelper.updateGroupReviewTranslationClear(state, action);
    }
    case "UPDATE_GROUP_REVIEW_TRANSLATION": {
      return groupHelper.updateGroupReviewTranslation(state, action);
    }
    case "UPDATE_GROUP_REVIEW_TRANSLATION_SUCCESS": {
      return groupHelper.updateGroupReviewTranslationSuccess(state, action);
    }
    case "UPDATE_GROUP_REVIEW_TRANSLATION_ERROR": {
      return groupHelper.updateGroupReviewTranslationError(state, action);
    }


    /* Get Group Response */

    case "GET_GROUP_RESPONSE_CLEAR": {
      return groupHelper.getGroupResponseClear(state, action);
    }
    case "GET_GROUP_RESPONSE": {
      return groupHelper.getGroupResponse(state, action);
    }
    case "GET_GROUP_RESPONSE_SUCCESS": {
      return groupHelper.getGroupResponseSuccess(state, action);
    }
    case "GET_GROUP_RESPONSE_ERROR": {
      return groupHelper.getGroupResponseError(state, action);
    }

    /* Post group response on google */

    case 'POST_GROUP_RESPONSE_ON_GOOGLE_CLEAR': {
      return groupHelper.postGroupResponseOnGoogleClear(state, action);
    }
    case 'POST_GROUP_RESPONSE_ON_GOOGLE': {
      return groupHelper.postGroupResponseOnGoogle(state, action);
    }
    case 'POST_GROUP_RESPONSE_ON_GOOGLE_SUCCESS': {
      return groupHelper.postGroupResponseOnGoogleSuccess(state, action);
    }
    case 'POST_GROUP_RESPONSE_ON_GOOGLE_ERROR': {
      return groupHelper.postGroupResponseOnGoogleError(state, action);
    }




    /* Edit Group */

    case "EDIT_GROUP_CLEAR": {
      return groupHelper.editGroupClear(state, action);
    }
    case "EDIT_GROUP": {
      return groupHelper.editGroup(state, action);
    }
    case "EDIT_GROUP_SUCCESS": {
      return groupHelper.editGroupSuccess(state, action);
    }
    case "EDIT_GROUP_ERROR": {
      return groupHelper.editGroupError(state, action);
    }


    /* Share Group */

    case "SHARE_GROUP_CLEAR": {
      return groupHelper.shareGroupClear(state, action);
    }
    case "SHARE_GROUP": {
      return groupHelper.shareGroup(state, action);
    }
    case "SHARE_GROUP_SUCCESS": {
      return groupHelper.shareGroupSuccess(state, action);
    }
    case "SHARE_GROUP_ERROR": {
      return groupHelper.shareGroupError(state, action);
    }


    /* Remove Shared Group */

    case "REMOVE_SHARED_GROUP_CLEAR": {
      return groupHelper.removeSharedGroupClear(state, action);
    }
    case "REMOVE_SHARED_GROUP": {
      return groupHelper.removeSharedGroup(state, action);
    }
    case "REMOVE_SHARED_GROUP_SUCCESS": {
      return groupHelper.removeSharedGroupSuccess(state, action);
    }
    case "REMOVE_SHARED_GROUP_ERROR": {
      return groupHelper.removeSharedGroupError(state, action);
    }



  }
  return state;
};
export default groupReducer;
