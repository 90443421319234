// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { Interweave } from 'interweave';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';

import { validateUrl, renderOTALogo, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP} from '../../../../../../helpers';
import { STRS, getUserGAId } from '../../../../../../helpers';

import './style.css';

import starIcon from '../../../../../../img/icons/star.png';

const gtconfig = require('../../../../../../gtconfig');

function GenericHotelDataSingle(props) {
  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={props.onMEnter} onMouseLeave={props.onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          <label>{props.desc}</label>
          <div className="gIpt">
            {
              props.type === "textarea"
              ?
                <textarea
                  className="gInput"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
              :
                <input
                  className="gInput"
                  type="text"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
            }
          </div>
          {
            props.error &&
            <span className="gErr">{props.error}</span>
          }
        </div>
        <div className="contentBoxNA">
        </div>
      </div>
    </div>
  );
}


function Question(props) {
  const [collectTextInput, setCollectTextInput] = useState(props.collectTextInput);
  const [collectStarRating, setCollectStarRating] = useState(props.collectStarRating);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    props.onError(props._id, inputError);
  }, [inputError]);

  const onChange = (e) => {
    props.onChange(props._id, e.target.value);
  };
  const onEnabledChange = (e) => {
    props.onEnabledChange(props._id, e.target.checked);
  };
  const onMEnter = (e) => {
    props.onMEnter(props._id, e);
  };
  const onMLeave = (e) => {
    props.onMLeave(props._id, e);
  };
  const onCollectTextInputCheckBoxChange = (e) => {
    if (collectTextInput) {
      if (!collectStarRating) {
        setInputError('Atleast one input is required');
      } else {
        setInputError('');
      }
    } else {
      setInputError('');
    }
    setCollectTextInput(collectTextInput => !collectTextInput);
    props.onCollectTextInputChange(props._id, e.target.checked);
  };
  const onCollectStarRatingCheckBoxChange = (e) => {
    if (collectStarRating) {
      if (!collectTextInput) {
        setInputError('Atleast one input is required');
      } else {
        setInputError('');
      }
    } else {
      setInputError('');
    }
    setCollectStarRating(collectStarRating => !collectStarRating);
    props.onCollectStarRatingChange(props._id, e.target.checked);
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Question props: ', props);
  }

  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={onMEnter} onMouseLeave={onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
          <div className="right">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id={`aq_${props._id}`} checked={props.isEnabled} onChange={onEnabledChange}/><label htmlFor={`aq_${props._id}`}>Toggle</label>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          { props.desc && <label>{props.desc}</label> }
          <div className="gIpt">
          <textarea
            className="gInput"
            value={props.value}
            onChange={onChange}
            readOnly={props.readOnly}
          />
          </div>

        </div>
        <div className="inputTypeBox">
          <div className="rw">
            <input type="checkbox" id={`aq_ti_${props._id}`} name="collectTextInput" onChange={onCollectTextInputCheckBoxChange} value={props.collectTextInput ? 'on' : 'off'} checked={props.collectTextInput} />
            <label htmlFor={`aq_ti_${props._id}`}>Collect text answer</label>
          </div>
          <div className="rw">
            <input type="checkbox" id={`aq_sr_${props._id}`} name="collectStarRating" onChange={onCollectStarRatingCheckBoxChange} value={props.collectStarRating ? 'on' : 'off'} checked={props.collectStarRating} />
            <label htmlFor={`aq_sr_${props._id}`}>Collect star rating out of 5</label>
          </div>
        </div>
        {
          props.error &&
          <span className="inputErrorBox">{props.error}</span>
        }
        {/*
          inputError &&
          <div className="inputErrorBox">
            {inputError}
          </div>
        */}
      </div>
    </div>
  );
}

function AdditionalQuestions(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  const location = useLocation();

  const [aQEnabled, setAQEnabled] = useState(false);
  const [aQEnabledChanged, setAQEnabledChanged] = useState(false);

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [highlightTitle, setHighlightTitle] = useState(false);

  const [primaryMessage, setPrimaryMessage] = useState('');
  const [primaryMessageError, setPrimaryMessageError] = useState('');
  const [highlightPrimaryMessage, setHighlightPrimaryMessage] = useState(false);

  const [hotelMessage, setHotelMessage] = useState('');
  const [hotelMessageError, setHotelMessageError] = useState('');
  const [highlightHotelMessage, setHighlightHotelMessage] = useState(false);

  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [additionalQuestionsChanged, setAdditionalQuestionsChanged] = useState(false);

  const [additionalQuestionsError, setadditionalQuestionsError] = useState({});

  const [additionalQuestionsInputError, setAdditionalQuestionsInputError] = useState('');

  const [showAddQuestion, setShowAddQuestion] = useState(false);

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Additional Questions - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'edit_feedback_settings',
      params,
    }));
  };

  useEffect(() => {
    clearAction();
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    if (reputation && reputation.feedback_settings) {
      const { feedback_settings } = reputation;
      if (feedback_settings) {
        if (!feedback_settings.editInProgress && feedback_settings.editStatus && feedback_settings.editStatus.success) {
          setUpdatedValues(updatedValues => ({
          }));
          setAdditionalQuestionsChanged(false);
          clearAction();
        }
      }
    }
  }, [props.settings])

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    const { reputation } = props.settings;
    if (!reputation || !reputation.feedback_settings) return null;
    const { feedback_settings } = reputation;

    setAQEnabled(feedback_settings[`${props.type}_questions_enabled`] || false);
    setTitle(feedback_settings[`${props.type}_questions_title`] || '');
    setPrimaryMessage(feedback_settings[`${props.type}_questions_primary_message`] || '');
    setHotelMessage(feedback_settings[`${props.type}_questions_hotel_message`] || '');
    setAdditionalQuestions(feedback_settings[`${props.type}_questions`] || []);

    setFormChanged(false);
  };

  const onAQEnabledChange = (e) => {
    setAQEnabled(e.target.checked);
    setAQEnabledChanged(true);
    setFormChanged(true);
  }

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      title: value,
    }));
    // setTitleError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onTitleMouseEnter = (e) => {
    setHighlightTitle(true);
  };
  const onTitleMouseLeave = (e) => {
    setHighlightTitle(false);
  };

  const onPrimaryMessageChange = (e) => {
    const { name, value } = e.target;
    setPrimaryMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      [`${props.type}_questions_primary_message`]: value,
      // additional_primary_message: value,
    }));
    // setNegativeTitleError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onPrimaryMessageMouseEnter = (e) => {
    setHighlightPrimaryMessage(true);
  };
  const onPrimaryMessageMouseLeave = (e) => {
    setHighlightPrimaryMessage(false);
  };

  const onHotelMessageChange = (e) => {
    const { name, value } = e.target;
    setHotelMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      [`${props.type}_questions_hotel_message`]: value,
      // additional_hotel_message: value,
    }));
    // setHotelMessageError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelMessageMouseEnter = (e) => {
    setHighlightHotelMessage(true);
  };
  const onHotelMessageMouseLeave = (e) => {
    setHighlightHotelMessage(false);
  };


  const onAdditionalQuestionMouseEnter = (_id, e) => {
    let tAdditionalQuestions = [ ...additionalQuestions ];
    tAdditionalQuestions.forEach((item) => {
      if (item._id === _id) item.highlight = true;
    });
    setAdditionalQuestions(tAdditionalQuestions);
  };
  const onAdditionalQuestionMouseLeave = (_id, e) => {
    let tAdditionalQuestions = [ ...additionalQuestions ];
    tAdditionalQuestions.forEach((item) => {
      if (item._id === _id) item.highlight = false;
    });
    setAdditionalQuestions(tAdditionalQuestions);
  };

  const onAdditionalQuestionChange = (_id, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAdditionalQuestionChange _id: ', _id, ' value: ', value);
    }
    let tAdditionalQuestions = [ ...additionalQuestions ];
    tAdditionalQuestions.forEach((item) => {
      if (item._id === _id) item.question = value;
    });
    setAdditionalQuestions(tAdditionalQuestions);
    setAdditionalQuestionsChanged(true);

    // const { name, value } = e.target;
    // setAdditionalQuestion(value);
    // setUpdatedValues(updatedValues => ({
    //   ...updatedValues,
    //   additional_question: value,
    // }));
    // setAdditionalQuestionError(value ? '' : 'Required');
    setFormChanged(true);
  };

  const onAdditionalQuestionEnabledChange = (_id, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAdditionalQuestionEnabledChange _id: ', _id, ' value: ', value);
    }
    let tAdditionalQuestions = [ ...additionalQuestions ];
    tAdditionalQuestions.forEach((item) => {
      if (item._id === _id) item.is_enabled = value;
    });
    setAdditionalQuestions(tAdditionalQuestions);
    setAdditionalQuestionsChanged(true);

    // const { name, value } = e.target;
    // setAdditionalQuestion(value);
    // setUpdatedValues(updatedValues => ({
    //   ...updatedValues,
    //   additional_question: value,
    // }));
    // setAdditionalQuestionError(value ? '' : 'Required');
    setFormChanged(true);
  };

  const onAdditonalQuestionCollectTextInputChange = (_id, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAdditonalQuestionCollectTextInputChange _id: ', _id, ' value: ', value);
    }
    let tAdditionalQuestions = [ ...additionalQuestions ];
    tAdditionalQuestions.forEach((item) => {
      if (item._id === _id) item.collect_text_input = value;
    });
    setAdditionalQuestions(tAdditionalQuestions);
    setAdditionalQuestionsChanged(true);

    setFormChanged(true);
  };

  const onAdditonalQuestionCollectStarRatingChange = (_id, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAdditonalQuestionCollectStarRatingChange _id: ', _id, ' value: ', value);
    }
    let tAdditionalQuestions = [ ...additionalQuestions ];
    tAdditionalQuestions.forEach((item) => {
      if (item._id === _id) item.collect_star_rating = value;
    });
    setAdditionalQuestions(tAdditionalQuestions);
    setAdditionalQuestionsChanged(true);
    setFormChanged(true);
  };

  const onAdditionalQuestionError = (_id, error) => {
    let tAdditionalQuestionsError = { ...additionalQuestionsError };
    if (!tAdditionalQuestionsError[_id]) {
      tAdditionalQuestionsError[_id] = {};
    }
    tAdditionalQuestionsError[_id].error = error;
    setadditionalQuestionsError(tAdditionalQuestionsError);

    let tempError = '';
    Object.keys(tAdditionalQuestionsError).forEach((q) => {
      if (tAdditionalQuestionsError[q._id] && tAdditionalQuestionsError[q._id].error) {
        tempError = 'Please correct above error';
      }
    });
    setAdditionalQuestionsInputError(tempError);
  };

  const onAddQuestion = (e) => {
    if (props.isReadOnly) return;

    let tempId = (new Date()).getTime();
    setAdditionalQuestions(
      [...additionalQuestions, { _id: tempId, question: '', id: tempId }]
    );
    setAdditionalQuestionsChanged(true);
    setShowAddQuestion(true);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    // setTitleError(title ? '' : 'Required');
    // if (!title) hasError = true;

    let tempQuestions = [];
    if (additionalQuestions.length) {
      additionalQuestions.forEach((item) => {
        if (item.question) {
          tempQuestions.push(item);
        }
      });
      setAdditionalQuestions(tempQuestions);
      let tempError = '';
      let tAdditionalQuestionsError = { ...additionalQuestionsError };
      tempQuestions.forEach((q) => {
        if (additionalQuestionsError[q._id] && additionalQuestionsError[q._id].error) {
          tempError = 'Please correct above error';
        }
        if (!q.collect_text_input && !q.collect_star_rating) {
          if (!tAdditionalQuestionsError[q._id]) {
            tAdditionalQuestionsError[q._id] = {};
          }
          tAdditionalQuestionsError[q._id].error = 'Atleast one is required';
          tempError = 'Please correct above error';
          hasError = true;
        }
      });
      setadditionalQuestionsError(tAdditionalQuestionsError);
      if (tempError) {
        setAdditionalQuestionsInputError(tempError);
        hasError = true;
      } else {
        setAdditionalQuestionsInputError('');
      }
    }

    if (hasError) return;

    const params = {};
    params.location__id = urlParams.locationidentifier;
    if (additionalQuestionsChanged) {
      if (tempQuestions.length) params[`${props.type}_questions`] = tempQuestions;
    }
    if (aQEnabledChanged) params[`${props.type}_questions_enabled`] = aQEnabled;
    if ('title' in updatedValues) params[`${props.type}_questions_title`] = updatedValues.title;
    if (`${props.type}_questions_primary_message` in updatedValues) params[`${props.type}_questions_primary_message`] = updatedValues[`${props.type}_questions_primary_message`];
    if (`${props.type}_questions_hotel_message` in updatedValues) params[`${props.type}_questions_hotel_message`] = updatedValues[`${props.type}_questions_hotel_message`];

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_feedback_settings',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
      show_alert_timeout: 2000,
      show_error_alert: true,
      error_alert_msg: 'Error in updating',
    }));
    setFormChanged(false);
  };

  const renderAdditionalQuestionsEnabled = (feedback_settings) => {
    return (
      <div className="enabledRow">
        <div className="left">
          Show additional questions ?
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={`aq_enabled`} checked={aQEnabled} onChange={onAQEnabledChange}/><label htmlFor={`aq_enabled`}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="title"
        title="Title"
        desc=""
        type="textarea"
        value={title}
        onChange={onTitleChange}
        error={titleError}
        onMEnter={onTitleMouseEnter}
        onMLeave={onTitleMouseLeave}
      />
    );
  };

  const renderPrimaryMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="primaryMessage"
        title="Primary Message"
        desc=""
        type="textarea"
        value={primaryMessage}
        onChange={onPrimaryMessageChange}
        error={primaryMessageError}
        onMEnter={onPrimaryMessageMouseEnter}
        onMLeave={onPrimaryMessageMouseLeave}
      />
    );
  };

  const renderHotelMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelMessage"
        title="Secondary Message"
        desc=""
        type="textarea"
        value={hotelMessage}
        onChange={onHotelMessageChange}
        error={hotelMessageError}
        onMEnter={onHotelMessageMouseEnter}
        onMLeave={onHotelMessageMouseLeave}
      />
    );
  };

  const renderAdditionalQuestions = (feedback_settings) => {
    return (
      additionalQuestions.map((item, index) => {
        return (
          <Question
            dataTypeCode="additionalQuestion"
            title={`Additional Question #${index+1}`}
            desc=""
            key={`q_${item._id}`}
            _id={item._id}
            value={item.question}
            isEnabled={item.is_enabled}
            collectTextInput={item.collect_text_input}
            collectStarRating={item.collect_star_rating}
            onChange={onAdditionalQuestionChange}
            onEnabledChange={onAdditionalQuestionEnabledChange}
            onCollectTextInputChange={onAdditonalQuestionCollectTextInputChange}
            onCollectStarRatingChange={onAdditonalQuestionCollectStarRatingChange}
            onError={onAdditionalQuestionError}
            error={additionalQuestionsError[item._id] ? additionalQuestionsError[item._id].error : ''}
            onMEnter={onAdditionalQuestionMouseEnter}
            onMLeave={onAdditionalQuestionMouseLeave}
          />
        );
      })
    );
  };

  const renderSave = (feedback_settings) => {
    if (props.isReadOnly) {
      return (
        <div className="gBCtRw rdOlyRw stndAln">
          <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
        </div>
      );
    }
    return (
      <>
        {
          additionalQuestionsInputError &&
          <div className="gSettingsRow gErrRw">
            {additionalQuestionsInputError}
          </div>
        }
        <div className="gSettingsRow gSaveButton">
          <button
            type="submit"
            className={'gBtn' + (formChanged ? '' : ' disabled')}
          >
            Save Changes
            { feedback_settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        </div>
      </>
    );
  };

  const renderSettings = (feedback_settings) => {
    return (
      <>
        <form onSubmit={onSubmit}>
      {/* renderAdditionalQuestionsEnabled(feedback_settings) */}
          { renderTitle(feedback_settings) }
          { renderPrimaryMessage(feedback_settings) }
          { renderAdditionalQuestions(feedback_settings) }
          <div className="adQuesBtn"><span onClick={onAddQuestion}>Add question</span></div>
          { renderHotelMessage(feedback_settings) }
          { renderSave(feedback_settings) }
        </form>
      </>
    );
  };

  const renderPreview = (feedback_settings, engage_settings, location_info) => {
    return (
      <div className="emailPreviewWrap positiveFeedback">
        <p>Questions Screen Preview</p>
        {/*<div className="devicesBox">
          <span>Desktop</span>
          <span>Mobile</span>
        </div>*/}
        <div className="emailPreviewBox">
          <div className="emailBodyBox">
            <div className="headerBox">
              { engage_settings.hotel_logo_url && <img src={engage_settings.hotel_logo_url} /> }
              <p>{engage_settings.hotel_name || ''}</p>
            </div>
              <div className="middleBox">
                {
                  props.type === 'positive' &&
                  <p className={'positiveTitle'}><Interweave content={feedback_settings.positive_title} /></p>
                }
                <p className={'title' + (highlightTitle ? ' highlight' : '')}><Interweave content={title} /></p>
                <p className={'primaryMessage' + (highlightPrimaryMessage ? ' highlight' : '')}><Interweave content={primaryMessage} /></p>

                {
                  additionalQuestions.length
                  ?
                    additionalQuestions.map((item) => {
                      if (!item.is_enabled) return null;
                      return (
                        <div className={'additionalQuestion' + (item.highlight ? ' highlight' : '')}>
                          <p className={'question'}><Interweave content={item.question} /></p>
                          {
                            item.collect_star_rating &&
                            <div className="starBox">
                              <div className="stars">
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                              </div>
                            </div>
                          }
                          {
                            item.collect_text_input &&
                            <textarea />
                          }
                        </div>
                      )
                    })
                  :
                    null
                }
              </div>
            <div className="footerBox">
              <p className={'hotelMessage' + (highlightHotelMessage ? ' highlight' : '')}><Interweave content={hotelMessage} /></p>
              <p className="address">{engage_settings.hotel_address || ''}</p>
              <p className="contact">
                <span className="link">{engage_settings.hotel_number || ''}</span>
                <span className="link">{engage_settings.hotel_website || ''}</span>
              </p>
              {
                gtconfig.IS_GT &&
                <p className="poweredBy">
                  Powered by <span className="link">{gtconfig.APP_NAME_CONTACT}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = (feedback_settings, engage_settings, location_info) => {
    return (
        <div className="innerBox">
          <div className="topBox">
            {
              props.type === 'positive' &&
              <div className="tabBox">
                <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/feedback-positive`}>Feedback Positive</Link>
                <Link className="active" to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/positive-questions`}>Questions</Link>
                <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/positive-otas`}>OTAs</Link>
              </div>
            }
            {
              props.type === 'negative' &&
              <div className="tabBox">
              <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/feedback-negative`}>Feedback Negative</Link>
              <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/negative-single`}>Single Feedback</Link>
              <Link className="active" to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/negative-questions`}>Questions</Link>
              </div>
            }
            <div className="titleBox">
              <h4>Questions{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
            {/*<p>Enter additional questions</p>*/}
            </div>
          </div>
          <div className="contentBox">
            <div className="left">
              { renderSettings(feedback_settings) }
            </div>
            <div className="right">
              { renderPreview(feedback_settings, engage_settings, location_info) }
            </div>
          </div>
        </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('AdditionalQuestions props: ', props);
    console.log('AdditionalQuestions additionalQuestions: ', additionalQuestions);
    console.log('AdditionalQuestions additionalQuestionsError: ', additionalQuestionsError);
    console.log('AdditionalQuestions updatedValues: ', updatedValues);
  }

  const { locationInfo } = props;
  const { reputation, engage } = props.settings;
  if (!reputation || !reputation.feedback_settings) return null;
  const { feedback_settings } = reputation;

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbPosPg fdbAdnlQsPg">
        { renderContent(feedback_settings, engage || {}, locationInfo) }
      </div>
    </div>
  );

}

export default AdditionalQuestions;
