import { useState, useEffect } from "react";
import moment from 'moment';
import Select from 'react-select';

import './style.css';

const gDtHourOptions = [];
Array.from(Array(12).keys()).forEach((i) => {
  gDtHourOptions.push({ label: `${i+1}`, value: `${i+1}` });
});

const dtMinOptions = [];
Array.from(Array(60).keys()).forEach((i) => {
  const v = i <= 9 ? `0${i}` : `${i}`;
  dtMinOptions.push({ label: `${v}`, value: `${v}` });
});
const dtMinEvery15Options = [];
Array.from(Array(4).keys()).forEach((i) => {
  const v = i <= 0 ? `0${i*15}` : `${i*15}`;
  dtMinEvery15Options.push({ label: `${v}`, value: `${v}` });
});

const dtAmPmOptions = [];
dtAmPmOptions.push({ label: 'AM', value: 'AM' });
dtAmPmOptions.push({ label: 'PM', value: 'PM' });

const yesterday = moment().subtract(1, 'day');

function CustomTimePicker(props) {

  const [dtHour, setDtHour] = useState('');
  const [dtMin, setDtMin] = useState('');
  const [dtAmPm, setDtAmPm] = useState('');

  const [dtHourOptions, setDtHourOptions] = useState(gDtHourOptions);

  useEffect(() => {
    if (props.minAM && props.maxPM) {
      if (props.value) {
        let tList = props.value.split(' ');
        if (tList.length === 2) {
          if (tList[1] === 'AM') {
            let tempOptions = [];
            Array.from(Array(12).keys()).forEach((i) => {
              if (i >= props.minAM-1 ) { // minAM = 8
                tempOptions.push({ label: `${i+1}`, value: `${i+1}` });
              }
            });
            setDtHourOptions(tempOptions);
          }
          if (tList[1] === 'PM') {
            let tempOptions = [];
            Array.from(Array(12).keys()).forEach((i) => {
              if (i <= props.maxPM-1 ) {
                tempOptions.push({ label: `${i+1}`, value: `${i+1}` });
              }
            });
            setDtHourOptions(tempOptions);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    let tList = props.value.split(' ');
    if (tList.length === 2) {
      setDtAmPm({ label: tList[1], value: tList[1] });
      tList = tList[0].split(':');
      if (tList.length === 2) {
        setDtHour({ label: tList[0], value: tList[0] });
        if (parseInt(tList[1]) <= 9) {
          setDtMin({ label: `0${parseInt(tList[1])}`, value: parseInt(tList[1]) });
        } else {
          setDtMin({ label: tList[1], value: tList[1] });
        }
      }
    }
  }, [props.value]);

  const onDtHourChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDtHourChange value: ', value);
    }
    setDtHour(value);
    props.onChange(`${value.value}:${dtMin.value} ${dtAmPm.value}`);
  };
  const onDtMinChange = (value) => {
    setDtMin(value);
    props.onChange(`${dtHour.value}:${value.value} ${dtAmPm.value}`);
  };
  const onDtAmPmChange = (value) => {
    if (props.minAM && props.maxPM) {
      setDtAmPm(value);
      if (value.value === 'AM') {
        let tempOptions = [];
        Array.from(Array(12).keys()).forEach((i) => {
          if (i >= props.minAM-1 ) { // minAM = 8
            tempOptions.push({ label: `${i+1}`, value: `${i+1}` });
          }
        });
        setDtHourOptions(tempOptions);
        setDtHour({ label: `${props.minAM}` , value: `${props.minAM}` });
        props.onChange(`${props.minAM}:${dtMin.value} ${value.value}`);
      }
      if (value.value === 'PM') {
        let tempOptions = [];
        Array.from(Array(12).keys()).forEach((i) => {
          if (i <= props.maxPM-1 ) {
            tempOptions.push({ label: `${i+1}`, value: `${i+1}` });
          }
        });
        setDtHourOptions(tempOptions);
        setDtHour({ label: `${props.maxPM}` , value: `${props.maxPM}` });
        props.onChange(`${props.maxPM}:${dtMin.value} ${value.value}`);
      }
    } else {
      setDtAmPm(value);
      props.onChange(`${dtHour.value}:${dtMin.value} ${value.value}`);
    }
  };



  if (process.env.NODE_ENV !== 'production') {
    console.log('CustomTimePicker props: ', props);
    console.log('CustomTimePicker dtHour: ', dtHour);
    console.log('CustomTimePicker dtMin: ', dtMin);
    console.log('CustomTimePicker dtAmPm: ', dtAmPm);
  }

  // if (!props.value) return null;
  // if (!dtHour || !dtMin || !dtAmPm) return null;

  return (
    <div className="dtTimeCtm">
      <div className="dtTimePrt">
        <Select
          options={dtHourOptions}
          onChange={onDtHourChange}
          value={dtHour}
        />
      </div>
      <div className="dtTimeSepPrt">
        :
      </div>
      <div className="dtTimePrt">
        <Select
          options={props.every15Min ? dtMinEvery15Options : dtMinOptions}
          onChange={onDtMinChange}
          value={dtMin}
        />
      </div>
      <div className="dtTimeSepPrt" />
      <div className="dtTimePrt">
        <Select
          options={dtAmPmOptions}
          onChange={onDtAmPmChange}
          value={dtAmPm}
        />
      </div>
    </div>
  );
}

export default CustomTimePicker;
