// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../Misc/OutsideClickHook';
import useDebounce from '../../../Misc/DebounceHook';

import { formatDate, formatDateTime, getSearchGuestTypeByTransactionType, broadcastWeekDayToLabel, 
  broadcastTargetToLabel, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';
import oneTimeIcon from '../../../../img/icons/broadcast-onetime.svg';
import recurringIcon from '../../../../img/icons/broadcast-recurring.svg';

import './style.css';


function SingleBroadcastHistoryBox(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();

  const [isEnabled, setIsEnabled] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const cMenuRef = useRef();
  useOnClickOutside(cMenuRef, useCallback(() => closeMenu()));

  const [sendInfo, setSendInfo] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Broadcast - History - Single - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { broadcastHistory } = props;
    let text = 'Send';
    // if (broadcast.when === 'now') text = `${text} Now`;
    // if (broadcast.when === 'later') text = `${text} at ${broadcast.when_schedule_time}`;
    // if (broadcast.when_all_days) text = `${text} Everyday`;
    // if (broadcast.when_days && Object.keys(broadcast.when_days).length) {
    //   // text = `${text} on ${Object.keys(broadcast.when_days).join(', ')}`;
    //   const numOfDays = Object.keys(broadcast.when_days).length;
    //   text = `${text} on `;
    //   Object.keys(broadcast.when_days).forEach((item, index) => {
    //     text = `${text} ${broadcastWeekDayToLabel[item]}${index < numOfDays-1 ? ',' : ''}`;
    //   });
    // }
    setSendInfo(text);
  }, [props.broadcastHistory]);


  const closeMenu = (e) => {
    setMenuOpen(false);
  };
  const onOpenMenuClick = (e) => {
    setMenuOpen(true);
  };
  const onDetailClick = (e) => {
    navigate(`/engage/locations/${props.locationidentifier}/broadcast/history/${props.broadcastHistory._id}`);
  }

  const renderOverflowMenu = () => {
    return (
      <div className="oMenu">
        <div className="dropdown" ref={cMenuRef}>
          <button className="dropbtn" onClick={onOpenMenuClick} ><i className="fa fa-caret-down" /></button>
          <div className={'dropdown-content ' + (menuOpen ? 'open ' : '')}>
            <p onClick={onDetailClick}>Details</p>
          </div>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    const { broadcastHistory } = props;
    return (
      <div className="broadcastBox" id={`bh_${broadcastHistory._id}`}>
        <div className="partOne">
          <div className="type">
            <span>
              {
                broadcastHistory.broadcast_data.type === 'one-time' && <img src={oneTimeIcon} />
              }
              {
                broadcastHistory.broadcast_data.type === 'recurring' && <img src={recurringIcon} />
              }
              {broadcastHistory.broadcast_data.type}
            </span>
          </div>
          <ToolTipWrapper placement="bottom" overlayClassName={'addTooltip'}
            overlay={broadcastHistory.broadcast_data.target}
          >
            <div className="target">
              {broadcastHistory.broadcast_data.target && broadcastTargetToLabel[broadcastHistory.broadcast_data.target]}
            </div>
          </ToolTipWrapper>
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={broadcastHistory.broadcast_data.name}>
            <div className="name">
              {broadcastHistory.broadcast_data.name}
            </div>
          </ToolTipWrapper>
        </div>
        <div className="partTwo">
          <div className="message">
            <ToolTipWrapper placement="bottom" overlayClassName={'addTooltip'}
              overlay={broadcastHistory.broadcast_data.message}
            >
              <span>{broadcastHistory.broadcast_data.message}</span>
            </ToolTipWrapper>
          </div>
        </div>
        <div className="partThree">
          <div className="when">
            <i className="far fa-clock"></i>
            {
              broadcastHistory.date_completed_tz
              ? broadcastHistory.date_completed_tz
              : ''
            }
          </div>
          <div className="sentStats">
            {
              broadcastHistory.run_status && broadcastHistory.run_status.sent
              ? `${broadcastHistory.run_status.sent.length}`
              : null
            }
          </div>

          {
            renderOverflowMenu()
          }
        </div>
      </div>
    );
  };


  return renderContent();
}

export default SingleBroadcastHistoryBox;
