// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import SentEmailPreviewModal from '../../../Misc/SentEmailPreviewModal';
import TransactionEmailTaskDetailModal from './TransactionEmailTaskDetailModal';

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../Misc/OutsideClickHook';

import { ENGAGE_FEATURES, hasAccessToFeature } from '../../../../helpers';
import { formatDate, formatDateStrToStr, getSearchGuestTypeByTransactionType,
  TRANSACTION_EMAIL_TYPE_TO_LABEL, TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP,
  COUNTRY_LIST_KEYS, DEVICE_TYPE_CODE_TO_LABEL } from '../../../../helpers';

import moreIcon from '../../../../img/icons/more-horizontal.svg';

import './style.css';


const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SingleTransactionEmailHistoryBox(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  let location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const cMenuRef = useRef();
  useOnClickOutside(cMenuRef, useCallback(() => closeMenu()));

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [showTaskDetailModal, setShowTaskDetailModal] = useState(false);

  const [showResendModal, setShowResendModal] = useState(false);



  useEffect(() => {
    const { history } = props.transactionEmails;
    if (history && history.keyValue && props.transactionEmailData &&
      props.transactionEmailData._id &&
      history.keyValue[props.transactionEmailData._id] &&
      !history.keyValue[props.transactionEmailData._id].resendInProgress &&
      history.keyValue[props.transactionEmailData._id].resendStatus &&
      history.keyValue[props.transactionEmailData._id].resendStatus.success
    ) {
      setShowResendModal(false);
    }
  }, [props.transactionEmails.history]);


  const closeMenu = (e) => {
    setMenuOpen(false);
  };
  const onOpenMenuClick = (e) => {
    setMenuOpen(true);
  };
  const onDetailClick = (e) => {
    // props.history.push(`/marketing/transaction-emails/history/${props.transactionEmailData._id}`);
    setShowTaskDetailModal(true);
  };

  const onPreviewOpenClick = (e) => {
    setShowEmailPreviewModal(true);
    // analytics reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Marketing - Transaction Emails - History - Single - Preview - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onEmailPreviewCloseModal = (e) => {
    setShowEmailPreviewModal(false);
  };
  const onEmailPreviewConfirmClick = (data) => {
    setShowEmailPreviewModal(false);
  };

  const onTaskDetailModalCloseClick = (e) => {
    setShowTaskDetailModal(false);
  }

  const onResendClick = (e) => {
    if (props.isReadOnly) return;
    dispatch(editDataClearAction({
      op: 'resend_transaction_email',
      params: {
        location__id: urlParams.locationidentifier,
        transaction_email_task_id: props.transactionEmailData._id,
      },
    }));
    setShowResendModal(true);
  };
  const onResendConfirmClick = (e) => {
    dispatch(editDataAction({
      op: 'resend_transaction_email',
      params: {
        location__id: urlParams.locationidentifier,
        transaction_email_task_id: props.transactionEmailData._id,
      },
      show_alert: true,
      alert_msg: 'Sent successfully',
    }));
  };

  const getEmailStatus = (transactionEmailData) => {
    let result = {};
    result.status = 'sent';
    result.statusLabel = 'Sent';
    const { tracking } = transactionEmailData;
    if (tracking && tracking.date_email_status_bounce) {
      result.status = 'bounce';
      result.statusLabel = 'Bounced';
      return result;
    }
    if (transactionEmailData.date_email_status_click_tz) {
      result.date = transactionEmailData.date_email_status_click_tz;
      result.status = 'click';
      result.statusLabel = 'Clicked';
      if (tracking && tracking.event_data_click) {
        if (tracking.event_data_click.ip_country) result.ipCountry = tracking.event_data_click.ip_country;
        if (tracking.event_data_click.is_desktop) result.device = 'desktop';
        if (tracking.event_data_click.is_mobile) result.device = 'mobile';
        if (tracking.event_data_click.is_tablet) result.device = 'tablet';
      }
      return result;
    }
    if (transactionEmailData.date_email_status_open_tz) {
      result.date = transactionEmailData.date_email_status_open_tz;
      result.status = 'open';
      result.statusLabel = 'Opened';
      if (tracking && tracking.event_data_open) {
        if (tracking.event_data_open.ip_country) result.ipCountry = tracking.event_data_open.ip_country;
        if (tracking.event_data_open.is_desktop) result.device = 'desktop';
        if (tracking.event_data_open.is_mobile) result.device = 'mobile';
        if (tracking.event_data_open.is_tablet) result.device = 'tablet';
      }
      return result;
    }
    if (transactionEmailData.date_email_status_delivery_tz) {
      result.date = transactionEmailData.date_email_status_delivery_tz;
      result.status = 'delivery';
      result.statusLabel = 'Delivered';
      return result;
    }
    return result;
  }

  const renderTaskDetailModal = () => {
    const info = props.transactionEmailData;
    return (
      <Modal>
        <div id="cModelId" className="cModal mtModal">
          <div className="content">
            <TransactionEmailTaskDetailModal onCancelClick={onTaskDetailModalCloseClick} taskDetail={info}
              type="taskDetail"
              locationMId={props.locationMId}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderOverflowMenu = () => {
    return (
      <div className="oMenu">
        <div className="dropdown" ref={cMenuRef}>
          <button className="dropbtn" onClick={onOpenMenuClick} ><img src={moreIcon}/></button>
          <div className={'dropdown-content ' + (menuOpen ? 'open ' : '')}>
            <p onClick={onDetailClick}>Details</p>
            <p onClick={onPreviewOpenClick}>Preview</p>
            { !props.isReadOnly && <p onClick={onResendClick}>Resend</p> }
          </div>
        </div>
      </div>
    );
  };

  const renderEmailStatus = (transactionEmailData) => {
    const emailStatus = getEmailStatus(transactionEmailData);
    return (
      <div className={'sentStats ' + (emailStatus.status)}>
        <div className="line1">
          <div className="emailStatusIndicator"></div><span>{emailStatus.statusLabel}</span>
        </div>
        <div className="line2">
          { emailStatus.date && <div className="dateStr">{emailStatus.date}</div> }
        </div>
        <div className="line3">
          { emailStatus.ipCountry &&
            <ToolTipWrapper placement="left" overlayClassName={'sbTooltip'}
              overlay={COUNTRY_LIST_KEYS[emailStatus.ipCountry]}
            >
              <div className="ipCountry">{emailStatus.ipCountry}</div>
            </ToolTipWrapper>
          }
          { emailStatus.device &&
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={DEVICE_TYPE_CODE_TO_LABEL[emailStatus.device]}
            >
              <div className="device"><span><i className={`fas fa-${emailStatus.device}-alt`}></i></span></div>
            </ToolTipWrapper>
          }
        </div>
      </div>
    );
  }

  const renderContent = () => {
    const { transactionEmailData } = props;
    const checkInDateStr = formatDateStrToStr(transactionEmailData.guest_info.check_in_date_local_str) || formatDate(transactionEmailData.guest_info.check_in_date);
    const checkOutDateStr = formatDateStrToStr(transactionEmailData.guest_info.check_out_date_local_str) || formatDate(transactionEmailData.guest_info.check_out_date);
    let customLabel = 'Default';
    if (transactionEmailData.data.is_custom && transactionEmailData.data.custom_for) {
      customLabel = `Custom - ${TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP[transactionEmailData.data.custom_for]}`;
      if (transactionEmailData.data.rate_code_info && transactionEmailData.data.rate_code_info.label) {
        customLabel = `${customLabel} - ${transactionEmailData.data.rate_code_info.label}`;
      }
      if (transactionEmailData.data.custom_for === 'booking-source' && transactionEmailData.data.custom_value && transactionEmailData.data.custom_value.length) {
        customLabel = `${customLabel} - ${transactionEmailData.data.custom_value.reduce((prev, cur, curIndex) => { if (curIndex === transactionEmailData.data.custom_value.length-1) { return prev + cur.label; } else { return prev + cur.label  + ' / '; }}, '')}`;
      }
    }

    let tooltipStr = `Subject: ${transactionEmailData.sent_email && transactionEmailData.sent_email.subject ? transactionEmailData.sent_email.subject : ''}`;
    if (!transactionEmailData.sent_email) {
      tooltipStr = `Subject: ${transactionEmailData.data && transactionEmailData.data.subject ? transactionEmailData.data.subject : ''}`;
    }

    return (
      <div className="singleBox" id={`bh_${transactionEmailData._id}`}>
        <div className="partOne">
          <ToolTipWrapper placement="left" overlayClassName={'sbTooltip maxWidth'}
            overlay={tooltipStr}
          >
            <div className={'type '+ (transactionEmailData.data.type)}>
              <span>
                {TRANSACTION_EMAIL_TYPE_TO_LABEL[transactionEmailData.data.type]}
              </span>
            </div>
          </ToolTipWrapper>
          <div className="name">
            {transactionEmailData.guest_info ? transactionEmailData.guest_info.first_name || '' : ''}
            {transactionEmailData.guest_info ? ` ${transactionEmailData.guest_info.last_name || ''}` : ''}
            <br/>
            <span className="email">{transactionEmailData.sent_email ? transactionEmailData.sent_email.to_email || '' : ''}</span>
          </div>
          <div className="stay">
            {transactionEmailData.guest_info ? `${checkInDateStr} - ${checkOutDateStr}` : ''}
          </div>
        </div>
        {/*<div className="partTwo">
          <div className="emailTemplate">
            {transactionEmailData.sent_email ? transactionEmailData.sent_email.email_template_name || '' : ''}
          </div>
          <div className="custom">
            {customLabel}
          </div>
        </div>*/}
        <div className="partThree">
          <div className="when">
            <div className="tp">
              {
                transactionEmailData.date_email_sent_at_tz
                ? <span>{transactionEmailData.date_email_sent_at_tz}</span>
                : ''
              }
              {
                transactionEmailData.manual
                ? <span className="md">(Manual)</span>
                : <span className="md">(Automated)</span>
              }
            </div>
            <div className="bt">
            {
              transactionEmailData.added_by
              ? <span className="sBy">by {transactionEmailData.added_by || transactionEmailData.data.added_by}</span>
              : null
            }
            </div>
          </div>
          { renderEmailStatus(transactionEmailData) }

          {
            renderOverflowMenu()
          }
        </div>
        { showEmailPreviewModal &&
          <SentEmailPreviewModal
            modalType="templateSelector"
            title="Email Preview"
            setModalVisible={onEmailPreviewCloseModal}
            onAffirmativeClick={onEmailPreviewConfirmClick}
            showCancel={true}
            affirmativeButtonLabel={'Close'}
            disableOutsideClick={true}
            // emailData={sentEmailData}
            // inProgress={getPreviewInProgress}
            transactionEmailIdentifier={transactionEmailData._id}
            locationMId={props.locationMId}
          />
        }
        {
          showTaskDetailModal &&
          renderTaskDetailModal()
        }
        { showResendModal &&
          <GenericAlertModal
            modalType="Resend Email"
            bodyRowOne={`Do you want to resend this ${transactionEmailData.data.type} email ?`}
            bodyRowNote=""
            keyName={transactionEmailData.label}
            setModalVisible={setShowResendModal}
            onAffirmativeClick={onResendConfirmClick}
            inProgress={transactionEmailData.resendInProgress || false}
            showCancel={true}
          />
        }
      </div>
    );
  };

  // if (!hasAccessToFeature(props.userprops.user, ENGAGE_FEATURES.ADD_EDIT_GUESTS)) {
  //   // nkcheck - log this
  //   return <NoAccess />;
  // }

  return renderContent();
}

export default SingleTransactionEmailHistoryBox;
