import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";
// import { changeUsersName } from "../../redux/actions/changeUsersName";
import { changeUsersAge } from "../../redux/actions/changeUsersAge";

import closeIcon from '../../img/icons/close.svg';

import './Notifications.css';

function Notifications(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const onCloseClick = (e) => {
    props.onCancelClick(e);
  };

  let params = useParams();
  return (
    <div className="ntWrap">
      <div className="hdr">
        <h1>Notifications</h1>
        <img src={closeIcon} onClick={onCloseClick} />
      </div>
      <div className="bd">
      </div>
    </div>
  );
}

export default Notifications;
