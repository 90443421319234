import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

function LocationHome() {
  let urlParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const { locationidentifier } = urlParams;
    navigate(`/marketing/locations/${locationidentifier}/overview`);
  }, []);

  return (
    <>
    </>
  );
}

export default LocationHome;
