
/* *************** Start - Edit Settings Review Channels **************** */

export const editReputationAutoResponsesClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            auto_responses: {
              editInProgress: false,
              editStatus: {},
            },
          },
        },
      },
    },
  };
}

export const editReputationAutoResponses = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            auto_responses: {
              editInProgress: true,
              editStatus: {},
            },
          },
        },
      },
    },
  };
}

export const editReputationAutoResponsesSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { location_info } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            auto_responses: {
              editInProgress: false,
              editStatus: {
                success: true,
              },
            },
          },
        },
      },
    },
  };
}

export const editReputationAutoResponsesError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id } = params;
  if (!location__id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          info: {
            ...state.locations[location__id].settings.info,
            auto_responses: {
              editInProgress: false,
              editStatus: {
                error: true,
              },
            },
          },
        },
      },
    },
  };
}
