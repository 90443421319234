// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import { getDataAction } from '../../../../redux/actions/getDataActions';

import SingleBroadcastBox from './SingleBroadcastBox';
import BroadcastSingle from '../BroadcastSingle';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { formatDate, STRS, getUserGAId } from '../../../../helpers';

import gridIcon from '../../../../img/icons/grid.svg';
import listIcon from '../../../../img/icons/list.svg';

import './style.css';

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function BroadcastAll(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();

  const [grid, setGrid] = useState(false);
  const [oList, setOList] = useState([]);

  const [selectedBroadcastId, setSelectedBroadcastId] = useState(null);
  const [showBroadcastSingle, setShowBroadcastSingle] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Broadcast - All - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        containerId: 'broadcastWrapId',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  }, []);

  useEffect(() => {
    const { broadcastidentifier } = urlParams;
    if (broadcastidentifier) {
      setSelectedBroadcastId(broadcastidentifier);
      setShowBroadcastSingle(true);
    }
  }, [urlParams.broadcastidentifier]);

  useEffect(() => {
    if (props.new) {
      setSelectedBroadcastId(null);
      setShowBroadcastSingle(true);
    }
  }, [props.new]);

  const getTimeTillNextRun = (broadcast) => {
    let timeToRun = 0;
    if (broadcast.type === 'recurring') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('when_schedule_time_utc: ', moment.utc(broadcast.when_schedule_time_utc, 'HH:mm').format(), ' # ', moment().utc().format(), ' # ', broadcast._id);
      }
      if (broadcast.when_all_days) {
        if (moment.utc(broadcast.when_schedule_time_utc, 'HH:mm') > moment().utc()) {
          timeToRun = moment.utc(broadcast.when_schedule_time_utc, 'HH:mm').diff(moment().utc(), 'minutes');
        } else {
          timeToRun = moment.utc(broadcast.when_schedule_time_utc, 'HH:mm').add(1, 'day').diff(moment().utc(), 'minutes');
        }
      } else {
        if (broadcast.when_days_utc && Object.keys(broadcast.when_days_utc).length) {
          // same as today
          let findNextDay = false;
          if (broadcast.when_days_utc[moment().utc().format('dddd').toLowerCase()]) {
            if (moment.utc(broadcast.when_schedule_time_utc, 'HH:mm') > moment().utc()) {
              timeToRun = moment.utc(broadcast.when_schedule_time_utc, 'HH:mm').diff(moment().utc(), 'minutes');
            } else {
              findNextDay = true;
            }
          } else {
            // some day in future
            findNextDay = true;
          }
          if (findNextDay) {
            let i = 1, foundDay = false;
            while (i < 7 && !foundDay) {
              if (broadcast.when_days_utc[moment().utc().add(i, 'day').format('dddd').toLowerCase()]) {
                foundDay = true;
                timeToRun = moment.utc(broadcast.when_schedule_time_utc, 'HH:mm').add(i, 'day').diff(moment().utc(), 'minutes');
              }
              i++;
            }
          }
        }
      }
    }
    return timeToRun;
  };

  useEffect(() => {
    if (props.engage.broadcast) {
      const { list, keyValue } = props.engage.broadcast;
      if (list) {
        const results = list.sort((a, b) => {
          if (keyValue[a].is_enabled && !keyValue[b].is_enabled) return -1;
          if (!keyValue[a].is_enabled && keyValue[b].is_enabled) return 1;
          if (!keyValue[a].when_schedule_time_utc || !keyValue[b].when_schedule_time_utc) return -1;
          let timeToRunA = getTimeTillNextRun(keyValue[a]);
          if (process.env.NODE_ENV !== 'production') {
            console.log('timeToRunA: ', timeToRunA, ' # ', keyValue[a]._id);
          }
          let timeToRunB = getTimeTillNextRun(keyValue[b]);
          // let timeToRunB = 0;
          // if (keyValue[b].type === 'recurring') {
          //   if (keyValue[b].when_all_days) {
          //     if (moment().utc() > moment(keyValue[b].when_schedule_time_utc, 'HH:mm').utc()) {
          //       timeToRunB = moment(keyValue[b].when_schedule_time_utc, 'HH:mm').utc().diff(moment().utc(), 'minutes');
          //     } else {
          //       timeToRunB = moment(keyValue[b].when_schedule_time_utc, 'HH:mm').add(1, 'day').utc().diff(moment().utc(), 'minutes');
          //     }
          //   }
          // }
          if (process.env.NODE_ENV !== 'production') {
            console.log('timeToRunB: ', timeToRunB, ' # ', keyValue[b]._id);
          }
          return timeToRunA - timeToRunB;
          // if (keyValue[a].unread_count && !keyValue[b].unread_count) return -1;
          // if (keyValue[a].unread_count && keyValue[b].unread_count) return a.unread_count - b.unread_count;
          // if (!keyValue[a].unread_count && keyValue[b].unread_count) return 1;
          // if (!keyValue[a].unread_count && !keyValue[b].unread_count) {
          //   let date_a = keyValue[a].date_last_message_received || keyValue[a].date_last_message_sent || keyValue[a].date_last_message;
          //   let date_b = keyValue[b].date_last_message_received || keyValue[b].date_last_message_sent || keyValue[b].date_last_message;
          //   if (!date_a && date_b) { return 1; }
          //   if (date_a && !date_b) { return -1; }
          //   // if (!date_a && !date_b) { return 0; }
          //   if (!date_a && !date_b) {
          //     date_a = keyValue[a].check_in_date;
          //     date_b = keyValue[b].check_in_date;
          //   }
          //   return new Date(date_b).getTime() - new Date(date_a).getTime();
          // }
          return 0;
        });
        setOList(results);
      }
    }
  }, [props.engage.broadcast]);

  const onAddClick = (e) => {
    navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/new`);
    // setSelectedBroadcastId(selectedData._id);
    // setShowBroadcastSingle(true);
  };

  useEffect(() => {
    dispatch(getDataAction({
      op: 'get_broadcasts',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);

  const onDisplayGridClick = (e) => { setGrid(true); };
  const onDisplayListClick = (e) => { setGrid(false); };

  const onEditClick = (selectedData) => {
    navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/edit/${selectedData._id}`);
    // setSelectedBroadcastId(selectedData._id);
    // setShowBroadcastSingle(true);
  };
  const onBroadcastSingleCloseClick = (e) => {
    setShowBroadcastSingle(false);
    navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/all`);
  };

  const renderBroadcastSingleModal = () => {
    return (
      <Modal>
          <div id="cModelId" className="sdMdl bdcstSnglMdl">
            <div className="ctnt">
              <BroadcastSingle
                onCloseClick={onBroadcastSingleCloseClick}
                mode={selectedBroadcastId ? 'edit' : 'add'}
                broadcastId={selectedBroadcastId}
                settings={props.settings}
                engage={props.engage}
                windowState={windowState}
                isReadOnly={props.isReadOnly}
              />
            </div>
          </div>
        </Modal>
    );
  };

  const renderContent = (broadcast) => {
    const { list, keyValue } = broadcast;
    return (
      <>
        <h4 className="gPageWrapOuterTitle title">
          All Scheduled Broadcasts{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
          {
            !props.isReadOnly &&
            <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
          }
        </h4>
        {
          oList.length > 0
          ?
            <div className="gPageWrap allBroadcasts">
              <div className="allHeader">
                <div className="filter">
                </div>
                <div className="display">
                  <span className={(grid ? 'active' : '')} onClick={onDisplayGridClick}><i className="fa fa-th" /></span>
                  <span className={(grid ? '' : 'active')} onClick={onDisplayListClick}><i className="fa fa-list" /></span>
                </div>
              </div>
              <div className={'allWrap ' + (grid ? 'grid ' : 'list ')}>
                {
                  oList.map((item) => {
                    if (keyValue[item] && !keyValue[item].is_removed) {
                      return <SingleBroadcastBox broadcast={keyValue[item]} grid={grid} style={props.style}
                        key={`sbb_${item}`}
                        onEditClick={onEditClick}
                        isReadOnly={props.isReadOnly}
                      />
                    }
                    return null;
                  })
                }
              </div>
            </div>
          :
            <div className="gPageWrap allBroadcasts">
              {
                !broadcast.getListInProgress &&
                <Link to={`/engage/locations/${urlParams.locationidentifier}/broadcast/new`}>
                  <p>Create your first broadcast!</p>
                </Link>
              }
            </div>
        }
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('BroadcastAll props: ', props);
  }

  // if (!hasAccessToFeature(props.userprops.user, ENGAGE_FEATURES.ADD_EDIT_GUESTS)) {
  //   // nkcheck - log this
  //   return <NoAccess />;
  // }

  const { broadcast } = props.engage;
  if (!broadcast) return null;


  return (
    <>
      {
        broadcast.getListInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg">
          { renderContent(broadcast) }
        </div>
      </div>
      { showBroadcastSingle && renderBroadcastSingleModal() }
    </>
  );
}

export default BroadcastAll;
