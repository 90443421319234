import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../Misc/GenericSideModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericList from '../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { STRS } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';

function MessageTemplateRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  return (
    <div className="gBCtRw msgTplRw" id={`mt_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.title}
              />
            : template.title
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
              <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
            </>
          }
        </div>
      </div>
      <div className="bt">
        {
          props.keyword
          ?
            <Highlighter
              highlightClassName="highlightClass"
              searchWords={[props.keyword]}
              autoEscape={true}
              textToHighlight={template.msg}
            />
          : template.msg
        }
      </div>
    </div>
  )
}

function MessageTemplates(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Templates - Generic - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.locationprops.users.keyValue change');
    }
    if (selectedTemplate && selectedTemplate._id &&
      props.settings.engage.message_templates &&
      props.settings.engage.message_templates.keyValue &&
      props.settings.engage.message_templates.keyValue[selectedTemplate._id] &&
      props.settings.engage.message_templates.keyValue[selectedTemplate._id].is_removed !== selectedTemplate.is_removed
    ) {
      setShowDeleteModal(false);
    }
  }, [props.settings.engage]); // props.settings.engage.message_templates

  const onAddClick = (e) => {
    dispatch(editDataClearAction({
      op: 'edit_message_template',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
      },
    }));
    setSelectedTemplate({});
    setModalType('add');
    setShowModal(true);
  };
  const onRowEditClick = (templateData) => {
    setSelectedTemplate(templateData);
    setModalType('edit');
    setShowModal(true);
  };
  const onRowDeleteClick = (templateData) => {
    setSelectedTemplate(templateData);
    setShowModal(false);
    setShowDeleteModal(true);
  };

  const onDeleteConfirmClick = (e) => {
    if (props.settings.engage.message_templates &&
      props.settings.engage.message_templates.keyValue &&
      props.settings.engage.message_templates.keyValue[selectedTemplate._id] &&
      props.settings.engage.message_templates.keyValue[selectedTemplate._id].editInProgress
    ) {
      return;
    }
    dispatch(editDataAction({
      op: 'edit_message_template',
      params: {
        location__id: urlParams.locationidentifier,
        message_template_id: selectedTemplate._id,
        mode: 'remove',
      },
    }));
  };

  const onCloseModal = (e) => {
    if (props.settings.engage.message_templates &&
      props.settings.engage.message_templates.keyValue &&
      props.settings.engage.message_templates.keyValue[selectedTemplate._id] &&
      props.settings.engage.message_templates.keyValue[selectedTemplate._id].editInProgress
    ) {
      return;
    }
    setShowModal(false);
    setSelectedTemplate({});
  };

  const onSarchInputChange = (e) => {
    setKeyword(e.target.value);
  };
  const onSearchTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
    }
  };

  const renderModal = () => {
    return (
      <GenericSideModal
        modalType="messageTemplateGeneric"
        className="mtGen"
        data={{
          ...selectedTemplate,
          opName: modalType,
        }}
        extData={{
          title: '',
          settings: props.settings
        }}
        setModalVisible={onCloseModal}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  };

  if (!props.locationInfo.has_engage) return null;

  const { settings: { engage } } = props;
  const { message_templates } = engage;

  return (
    <GenericList
      type="messageTemplates"
      title={`Message Templates${props.isReadOnly ? ` [${STRS.READ_ONLY_LABEL}]` : ''}`}
      location__id={urlParams.locationidentifier}
      generic_list={message_templates}
      settings={props.settings}
    />
  );

  // return (
  //   <div className="gPScrlWrp engSetGen flHt">
  //     <div className="gPWrp">
  //       <div className="gPTle title add">
  //         Message Templates
  //         <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
  //       </div>
  //       <div className="gBCtnt">
  //         <div className="gBCtRw srchRw">
  //           <div className="lt">
  //             <div className="gIpt">
  //               <input type="text" value={keyword} onChange={onSarchInputChange} placeholder="Search"
  //                 onKeyDown={onSearchTermKeyDown}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         {
  //           message_templates && message_templates.keyValue && message_templates.list && message_templates.list.length
  //           ?
  //             message_templates.list.map((item) => {
  //               if (!message_templates.keyValue[item]) return null;
  //               if (keyword) {
  //                 if (
  //                   message_templates.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
  //                   message_templates.keyValue[item].msg.toLowerCase().indexOf(keyword.toLowerCase()) > -1
  //                 ) {
  //                   return (
  //                     <MessageTemplateRow
  //                       template={message_templates.keyValue[item]}
  //                       onEditClick={onRowEditClick}
  //                       onDeleteClick={onRowDeleteClick}
  //                       keyword={keyword}
  //                     />
  //                   );
  //                 }
  //                 return null;
  //               }
  //               return (
  //                 <MessageTemplateRow
  //                   template={message_templates.keyValue[item]}
  //                   onEditClick={onRowEditClick}
  //                   onDeleteClick={onRowDeleteClick}
  //                   keyword={keyword}
  //                 />
  //               );
  //             })
  //           : null
  //         }
  //       </div>
  //     </div>
  //     { showModal && renderModal() }
  //     { showDeleteModal &&
  //         <GenericAlertModal
  //           modalType="Delete Template"
  //           bodyRowOne={`Do you want to delete this templete (${selectedTemplate.title}) ? This action cannot be undone!`}
  //           bodyRowNote={'You’ll not be able to retrieve the contents later.'}
  //           keyName={selectedTemplate.title}
  //           setModalVisible={setShowDeleteModal}
  //           onAffirmativeClick={onDeleteConfirmClick}
  //           inProgress={
  //             props.settings.engage.message_templates &&
  //             props.settings.engage.message_templates.keyValue &&
  //             props.settings.engage.message_templates.keyValue[selectedTemplate._id]
  //             ? props.settings.engage.message_templates.keyValue[selectedTemplate._id].editInProgress
  //             : false
  //           }
  //           showCancel={true}
  //         />
  //       }
  //   </div>
  // );
}

export default MessageTemplates;
