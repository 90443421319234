// @flow

import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import copy from 'copy-to-clipboard';

import { receiveSnack, removeSnack } from '../../../../../redux/actions/snacksActions';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import { mapReviewStateToString } from '../../../../../helpers';

import './style.css';

function SingleTemplate(props) {
  const dispatch = useDispatch();

  const onCopyClick = (e) => {
    copy(props.data.response);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
  };

  return (
    <div className="singleTemplate">
      <div className="title">
        <p>{ props.data.title }</p>
        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
          overlay={'Click to copy'}
        >
        <span onClick={onCopyClick}><i className="far fa-copy" /></span>
        </ToolTipWrapper>
      </div>
      <div className="msg">
        { props.data.response }
      </div>
    </div>
  );
}


export default SingleTemplate;
