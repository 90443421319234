import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

const personalizeOptionsG = [];
// personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'Name', value: '{{name}}' });
personalizeOptionsG.push({ label: 'Hotel Name', value: '{{hotelname}}' });
personalizeOptionsG.push({ label: 'Review Link', value: '{{feedback_text_link}}' });

function FeedbackTextTemplateSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [collectionTypeOptions, setCollectionTypeOptions] = useState([]);
  const [collectionTypeValue, setCollectionTypeValue] = useState({});
  const [collectionTypeError, setCollectionTypeError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const [pickerStyle, setPickerStyle] = useState({
    height: 0,
  });
  const emojiSelectorRef = useRef(null);
  // useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));


  const [personalizeOptions, setPersonalizeOptions] = useState([
    // { label: 'Personalize', value: 'personalize' },
    { label: 'First Name', value: '{{firstname}}' },
    { label: 'Last Name', value: '{{lastname}}' },
    { label: 'Wifi', value: '{{wifi}}' },
  ]);
  const [personalizeValues, setPersonalizeValues] = useState([
    { label: 'Personalize', value: 'personalize' }
  ]);

  const messageEl = useRef(null);
  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      let page_title = '';
      if (props.templateType === 'feedbackTextTemplates') page_title = `Settings - Reputation - Feedback Text Template - Single - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    const { templateType } = props;

    let tOptions = [ ...personalizeOptionsG ];
    setPersonalizeOptions([
      ...tOptions,
      // ...personalizeOptionsG,
      // { text: 'Wifi', value: '{{wifi}}' },
    ]);

    if (reputation) {
      // let template_categories = {};
      // if (templateType === 'feedbackTextTemplates') template_categories = { ...reputation.response_template_categories };
      // if (templateType === 'promptTemplate') template_categories = { ...reputation.prompt_template_categories };
      // if (template_categories && template_categories.list && template_categories.list.length) {
      //   let temp = [];
      //   template_categories.list.forEach((item) => {
      //     temp.push({ label: template_categories.keyValue[item].name, value: item });
      //   });
      //   setCategoryOptions(temp);
      // }
      let temp = [];
      temp.push({ label: 'Selector', value: 'selector' });
      temp.push({ label: 'Tripadvisor', value: 'tripadvisor' });
      temp.push({ label: 'Google', value: 'google' });
      // if (props.settings.info.location_id === 483) {
      //   temp.push({ label: 'HolidayCheck', value: 'holidaycheck' });
      // }
      setCollectionTypeOptions(temp);
      if (props.template && props.template._id) {
        setTitle(props.template.title);
        if (templateType === 'feedbackTextTemplate') setMessage(props.template.msg);
        if (props.template.type) {
          setCollectionTypeValue({
            label: props.template.type, // nkcheck
            value: props.template.type,
          });
        }
      }
      // if (props.template && props.template._id) {
      //   setTitle(props.template.title);
      //   setMessage(props.template.response);
      //   if (props.template.category_id &&
      //     reputation && reputation.response_template_categories && reputation.response_template_categories.list &&
      //     reputation.response_template_categories.list.length
      //   ) {
      //     setCategoryValue({
      //       label: reputation.response_template_categories.keyValue[props.template.category_id].name,
      //       value: props.template.category_id,
      //     });
      //   }
      // }
    }
    setSomeChange(false);

    // const params = {
    //   location__id: urlParams.locationidentifier,
    //   mode: props.type,
    // };
    // if (templateType === 'responseTemplate') params.response_template_id = props.template._id;
    // if (templateType === 'promptTemplate') params.prompt_template_id = props.template._id;
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template',
    //   params,
    // }));

    clearData();

    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.templateType === 'feedbackTextTemplate') {
      if (props.type === 'edit') {
        if (props.template._id &&
          props.settings.reputation.feedback_text_templates && props.settings.reputation.feedback_text_templates.keyValue &&
          props.settings.reputation.feedback_text_templates.keyValue[props.template._id] &&
          !props.settings.reputation.feedback_text_templates.keyValue[props.template._id].editInProgress &&
          props.settings.reputation.feedback_text_templates.keyValue[props.template._id].editStatus &&
          props.settings.reputation.feedback_text_templates.keyValue[props.template._id].editStatus.success
        ) {
          setSomeChange(false);
        }
      }
      if (props.type === 'add') {
        if (props.settings.reputation.feedback_text_templates &&
          !props.settings.reputation.feedback_text_templates.addInProgress &&
          props.settings.reputation.feedback_text_templates.addStatus &&
          props.settings.reputation.feedback_text_templates.addStatus.success
        ) {
          setTitle('');
          setMessage('');
          setCollectionTypeValue({});
          setSomeChange(false);
        }
      }
    }
  }, [props.settings.reputation.feedback_text_templates]);

  // useEffect(() => {
  //   if (props.templateType === 'responseTemplate') {
  //     if (props.settings.reputation.response_template_categories &&
  //       !props.settings.reputation.response_template_categories.addInProgress &&
  //       props.settings.reputation.response_template_categories.addStatus &&
  //       props.settings.reputation.response_template_categories.addStatus.success
  //     ) {
  //       const { reputation } = props.settings;
  //       if (reputation && reputation.response_template_categories && reputation.response_template_categories.list &&
  //         reputation.response_template_categories.list.length
  //       ) {
  //         let temp = [];
  //         reputation.response_template_categories.list.forEach((item) => {
  //           temp.push({ label: reputation.response_template_categories.keyValue[item].name, value: item });
  //           // auto select the newly added one
  //           if (newCategoryName) {
  //             if (newCategoryName === reputation.response_template_categories.keyValue[item].name) {
  //               setCategoryValue({
  //                 label: newCategoryName,
  //                 value: item,
  //               });
  //             }
  //           }
  //         });
  //         setCategoryOptions(temp);
  //       }
  //     }
  //   }
  // }, [props.settings.reputation.response_template_categories]);


  const clearData = () => {
    const { templateType } = props;
    if (props.type === 'edit') {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
      };
      let op = '';
      if (templateType === 'feedbackTextTemplate') {
        op = 'edit_feedback_text_template';
        params.feedback_text_template_id = props.template._id;
      }
      dispatch(editDataClearAction({
        op,
        params,
      }));
    }

    if (props.type === 'add') {
      let op = '';
      if (templateType === 'feedbackTextTemplate') {
        op = 'edit_feedback_text_template';
      }
      dispatch(editDataClearAction({
        op,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true);
    // props.onAddEditClear(props.template);

    // const params = {
    //   location__id: urlParams.locationidentifier,
    //   mode: props.type,
    // };
    // let op = '';
    // if (templateType === 'responseTemplate') {
    //   op = 'edit_response_template';
    //   params.response_template_id = props.template._id;
    // }
    // if (templateType === 'promptTemplate') {
    //   op = 'edit_prompt_template';
    //   params.response_template_id = props.template._id;
    // }
    // dispatch(editDataClearAction({
    //   op,
    //   params,
    // }));
    //
    // op = '';
    // if (templateType === 'responseTemplate') {
    //   op = 'edit_response_template_category';
    // }
    // if (templateType === 'promptTemplate') {
    //   op = 'edit_prompt_template_category';
    // }
    // dispatch(editDataClearAction({
    //   op,
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    clearData();

    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
  }
  const onMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    setSomeChange(true);
    // props.onAddEditClear(props.template);

    // const params = {
    //   location__id: urlParams.locationidentifier,
    //   mode: props.type,
    // };
    // let op = '';
    // if (templateType === 'responseTemplate') {
    //   op = 'edit_response_template';
    //   params.response_template_id = props.template._id;
    // }
    // if (templateType === 'promptTemplate') {
    //   op = 'edit_prompt_template';
    //   params.response_template_id = props.template._id;
    // }
    // dispatch(editDataClearAction({
    //   op,
    //   params,
    // }));
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template_category',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    clearData();

    if (!value) {
      setMessageError('error');
    } else {
      setMessageError('');
    }
  };

  const onOpenEmojiSelectorClick = (e) => {
    setPickerStyle(pickerStyle => ({
      ...pickerStyle,
      height: emojiSelectorOpen ? 0 : 200,
    }));
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      setSomeChange(true);
      if (message) {
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${emoji.native}`);
      }
    }
  };

  const onCollectionTypeChange = (value) => {
    setCollectionTypeValue(value);
    setSomeChange(true);
    setCollectionTypeError('');
  };

  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
      console.log('onPersonalizeChange: ', value);
    }
    if (value && value.value !== 'personalize') {
      // setMessage(message => `${message} ${values[0].text}`);
      setSomeChange(true);
      if (message) {
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${value.value}`);
      }
      // gtag('event', 'gt_click', {
      //   'event_category': 'Settings - Manage Templates',
      //   'event_label': `Selected Personalize - ${value.value}`,
      //   // 'value': 1,
      //   user_id: props.userprops.user.uid,
      // });
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    if (props.addEditInProgress) return;
    const { templateType } = props;
    // props.onAddEditClear(props.template);
    clearData();
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: props.type,
    //     response_template_id: props.template._id,
    //   },
    // }));
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template_category',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddEditClick title: ', title);
      console.log('onAddEditClick message: ', message);
      console.log('onAddEditClick collectionTypeValue: ', collectionTypeValue);
    }
    setTitleError(title ? '' : 'error');
    setMessageError(message ? '' : 'error');
    setCollectionTypeError(collectionTypeValue && collectionTypeValue.value ? '' : 'error');
    if (someChange) {
      if (props.type === 'add') {
        if (title && message && collectionTypeValue && collectionTypeValue.value) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            title,
            type: collectionTypeValue.value,
          };
          let op = '';
          if (templateType === 'feedbackTextTemplate') {
            op = 'edit_feedback_text_template';
            params.msg = message;
          }

          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
      if (props.type === 'edit') {
        if (title && message && collectionTypeValue && collectionTypeValue.value) {
          let op = '';
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            title,
            type: collectionTypeValue.value,
          };
          if (templateType === 'feedbackTextTemplate') {
            op = 'edit_feedback_text_template';
            params.feedback_text_template_id = props.template._id;
            params.msg = message;
          }
          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
    }
  };


  const renderContent = () => {
    const { locationidentifier } = urlParams;
    const { templateType } = props;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.reputation) return null;

    let tData = {};

    if (props.type === 'edit') {
      if (templateType === 'feedbackTextTemplate') {
        if (!settings.reputation.feedback_text_templates || !settings.reputation.feedback_text_templates.keyValue) return null;
        tData = settings.reputation.feedback_text_templates.keyValue[props.template._id] || {};
      }
    }

    let pageTitle = '';
    if (props.templateType === 'feedbackTextTemplate') {
      if (props.type === 'add') pageTitle = `Add Feedback Text Template`;
      if (props.type === 'edit') pageTitle = 'Edit Feedback Text Template';
    }
    if (props.title) pageTitle = props.title;


    let addEditInProgress = false;
    let addEditStatus = {};

    if (props.templateType === 'feedbackTextTemplate') {
      addEditInProgress = settings.reputation.feedback_text_templates ? settings.reputation.feedback_text_templates.addInProgress : false;
      addEditStatus = settings.reputation.feedback_text_templates ? settings.reputation.feedback_text_templates.addStatus : {};
    }

    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    if (process.env.NODE_ENV !== 'production') {
      console.log('ResponseTemplateSingle addEditStatus: ', addEditStatus);
    }


    return (
      <div className="gPWrp fttSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Title
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" ref={titleRef} />
                { titleError && <p className="gErr">Required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw categoryRow">
            <div className={'gRwBxLt ' + (collectionTypeError ? 'categoryError' : '')}>
              <div className="gLbl">
                Review Collection Type
              </div>
              <Select options={collectionTypeOptions}
                onChange={onCollectionTypeChange}
                value={collectionTypeValue}
              />
              { collectionTypeError && <p className="error">Required</p> }
            </div>
            <div className="gRwBxRt">
            </div>
          </div>
          <div className="gBCtRw messageRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Message
              </div>
              <div className={'gIpt ' + (messageError ? 'error' : '')}>
                <textarea rows="4" placeholder="Message"
                  onChange={onMessageChange}
                  placeholder="Message..."
                  value={message}
                  ref={messageEl}
                />
                { messageError && <p className="gErr">Message is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw contentRow emojiRow">
            <div className={'gRwBxLt emojiBox ' + (messageError ? 'messageError' : '')}>
              <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                {
                  emojiSelectorOpen
                  ? <img className="closeIcon" src={closeIcon} />
                  : <img src={emojiIcon} />
                }

              </div>
            </div>
          </div>
          { emojiSelectorOpen &&
            <div className="emojiBox" ref={emojiSelectorRef}>
              <Picker
                data={emojiData}
                theme="light"
                onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
              />
            </div>
          }
          <div className="gBCtRw contentRow personalizeRow">
            <div className="gRwBxLt personalizeBox">
              <label>Choose dynamic label to insert</label>
              <Select options={personalizeOptions}
                onChange={onPersonalizeChange}
              />
            </div>
            <div className="personalizeBoxNA" />
          </div>

          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('FeedbackTextTemplateSingle props: ', props);
  }

  return renderContent();
}

export default FeedbackTextTemplateSingle;
