import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

// import SideBar from './SideBar';

import './style.css';

const gtconfig = require('../../../gtconfig');

function EnableEngage() {

  return (
    <div className="enblPrdWrap">
      <p>Contact {gtconfig.APP_NAME_CONTACT} to enable Engage</p>
    </div>
  );
}

export default EnableEngage;
