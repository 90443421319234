import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';
import refreshIcon from '../../../../../img/icons/refresh-active.svg';
import verifyIcon from '../../../../../img/icons/required.svg';

import '../style.css';

function SenderEmailRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onRefreshClick = (e) => {
    props.onRefreshClick(props.template);
  };
  const onVerifyClick = (e) => {
    props.onVerifyClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('SenderEmailRow props: ', props);
  }

  const { template } = props;
  let cStyle = {};
  cStyle['backgroundColor'] = template.color;
  return (
    <div className="gBCtRw sndrEmRw" id={`lbl_${template._id}`}>
      <div className="lt">
        {
          props.keyword
          ?
            <Highlighter
              highlightClassName="highlightClass"
              searchWords={[props.keyword]}
              autoEscape={true}
              textToHighlight={template.value}
            />
          : template.value
        }
      </div>
      <div className="rt">
        {
          !props.nonEditable &&
          <>
          {
            template.verified_for_sending
            ?
              <ToolTipWrapper placement="top" overlayClassName="sbTooltip" overlay={'Email can be used as a sender now.'}>
                <div className="verified">Verified</div>
              </ToolTipWrapper>
            :
              <ToolTipWrapper placement="top" overlayClassName="sbTooltip" overlay={'Click to verify.'}>
                <span className="verify"><img className="edit verify" src={verifyIcon} onClick={onVerifyClick} /></span>
              </ToolTipWrapper>
          }
          {
            !template.verified_for_sending &&
            <ToolTipWrapper placement="top" overlayClassName="sbTooltip" overlay={'Get verification status'}>
              <span className="refresh"><img className={'edit refresh' + (template.getInProgress ? ' rotate' : '')} src={refreshIcon} onClick={onRefreshClick} /></span>
            </ToolTipWrapper>
          }
          {
            !template.verified_for_sending &&
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
          }
          {
            !template.verified_for_sending &&
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
              <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
          }
          </>
        }
      </div>
    </div>
  )

}

export default SenderEmailRow;
