import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import MessageBox from '../../../../../Misc/MessageBox';
import WCPreview from '../../WCPreview';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, STRS } from '../../../../../../helpers';

import '../../style.css';


function WCConfigureSms(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [successTitle, setSuccessTitle] = useState('');
  const [successTitleError, setSuccessTitleError] = useState('');

  const [successDesc, setSuccessDesc] = useState('');
  const [successDescError, setSuccessDescError] = useState('');

  const [businessHourMessage, setBusinessHourMessage] = useState('');
  const [businessHourMessageError, setBusinessHourMessageError] = useState('');

  const [nonBusinessHourMessage, setNonBusinessHourMessage] = useState('');
  const [nonBusinessHourMessageError, setNonBusinessHourMessageError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const setInitialValues = () => {
    const { settings: { webchat } } = props;
    if (webchat) {
      if (webchat.channels && webchat.channels.sms) {
        setSuccessTitle(webchat.channels.sms.success_title || '');
        setSuccessDesc(webchat.channels.sms.success_desc || '');
        setBusinessHourMessage(webchat.channels.sms.ack_msg_business_hours || '');
        setNonBusinessHourMessage(webchat.channels.sms.ack_msg_non_business_hours || '');
      }
    }
    setSomeChange(false);
  };

  const clearAction = () => {
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'sms',
    };
    dispatch(editDataClearAction({
      op: 'edit_webchat_settings',
      params,
    }));
  };

  useEffect(() => {
    setInitialValues();
    clearAction();
  }, []);

  useEffect(() => {
    if (!props.settings.webchat.editInProgress &&
      props.settings.webchat.editStatus &&
      props.settings.webchat.editStatus.success
    ) {
      setSomeChange(false);
    }
  }, [props.settings.webchat]);

  const onSuccessTitleChange = (value) => {
    setSuccessTitle(value);
    setSomeChange(true);
    if (!value) {
      setSuccessTitleError('error');
    } else {
      setSuccessTitleError('');
    }
    clearAction();
  };

  const onSuccessDescChange = (value) => {
    setSuccessDesc(value);
    setSomeChange(true);
    if (!value) {
      setSuccessDescError('error');
    } else {
      setSuccessDescError('');
    }
    clearAction();
  };

  const onBusinessHourMessageChange = (value) => {
    setBusinessHourMessage(value);
    setSomeChange(true);
    if (!value) {
      setBusinessHourMessageError('error');
    } else {
      setBusinessHourMessageError('');
    }
    clearAction();
  };

  const onNonBusinessHourMessageChange = (value) => {
    setNonBusinessHourMessage(value);
    setSomeChange(true);
    if (!value) {
      setNonBusinessHourMessageError('error');
    } else {
      setNonBusinessHourMessageError('');
    }
    clearAction();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (someChange) performSave();
  };

  const performSave = () => {
    const { webchat } = props.settings;
    if (webchat && webchat.channels && webchat.channels.sms && webchat.channels.sms.editInProgress) return;

    let hasError = false;

    setSuccessTitleError(successTitle ? '' : 'error');
    setSuccessDescError(successDesc ? '' : 'error');
    setBusinessHourMessageError(businessHourMessage ? '' : 'error');
    setNonBusinessHourMessageError(nonBusinessHourMessage ? '' : 'error');

    if (!successTitle || !successDesc) return;

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'sms',
      channel_data: {
        success_title: successTitle,
        success_desc: successDesc,
        ack_msg_business_hours: businessHourMessage,
        ack_msg_non_business_hours: nonBusinessHourMessage,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
    setSomeChange(false);
  };




  if (process.env.NODE_ENV !== 'production') {
    console.log('WCConfigureSms: ', props, ' # ', props);
  }

  const { webchat } = props.settings

  return (
    <div className="gPScrlWrp hDGen flHt wCBasic">
      <div className="gPWrp hDGen">
        <div className="gPTle allChnls">
          <Link to={`/settings/locations/${urlParams.locationidentifier}/webchat/channels`}>All Channels</Link> > Sms
          {props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          <MessageBox
            title={"Success line 1"}
            subTitle={"Success message line 1, to be shown to the guest."}
            error={successTitleError}
            onTextChange={onSuccessTitleChange}
            message={successTitle}
            maxLength={60}
          />
          <MessageBox
            title={"Success line 2"}
            subTitle={"Success message line 2, to be shown to the guest."}
            error={successDescError}
            onTextChange={onSuccessDescChange}
            message={successDesc}
            maxLength={160}
          />
          <MessageBox
            title={"Acknowledgement message - During business hours"}
            subTitle={"Acknowledgement message that will be sent over SMS to guest during busines hours upon receiving the query from guest."}
            error={businessHourMessageError}
            onTextChange={onBusinessHourMessageChange}
            message={businessHourMessage}
            maxLength={160}
          />
          <MessageBox
            title={"Acknowledgement message - During out of business hours"}
            subTitle={"Acknowledgement message that will be sent over SMS to guest during out of busines hours upon receiving the query from guest."}
            error={nonBusinessHourMessageError}
            onTextChange={onNonBusinessHourMessageChange}
            message={nonBusinessHourMessage}
            maxLength={160}
          />
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  webchat && webchat.channels && webchat.channels.sms && webchat.channels.sms.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
      <div className="webChatPreviewWrap">
        <h4 className="gPageTitle title">
          Preview
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay="A display only preview of WebChat"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </h4>
        <div className="previewBox">
          <WCPreview
            screen={"channels-sms"}
            settings={props.settings}
            data={{
              a: 1,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WCConfigureSms;
