
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';


import ToolTipWrapper from '../../Misc/ToolTipWrapper';

import { getDataAction } from '../../../redux/actions/getDataActions';
import { initMarketingAction } from '../../../redux/actions/initActions';

import { isGTUser, getProductAccess, getUserGAId } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';
import downloadIcon from '../../../img/icons/download.svg';

import './style.css';

const download = require('downloadjs');

function LocationRow(props) {
  return (
    <Link to={`/reputation/locations/${props.data._id}/dashboard`}>
      <div className="lRow">
        <div className="left">
          {
            props.data.welcome_screen_image_url &&
            <img src={props.data.welcome_screen_image_url} />
          }
        </div>
        <div className="right">
          <p className="name">{props.data.name}
            {
              isGTUser(props.user) &&
              ` ${props.data.location_id} - ${props.data.serverid}`
            }
          </p>
          <p className="address">{props.data.address}</p>
        </div>
      </div>
    </Link>
  )
}

function Locations(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  // analytics reporting
  useEffect(() => {
    document.title = `Reputation - All Locations`;
    try {
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { locations_list, locations } = userState.user.user_info;
    if (locations_list && locations) {
      const tList = locations_list.filter((locationId) => {
        let { hasAccessToReputation } = getProductAccess(userState.user.user_info, locationId);
        if (!hasAccessToReputation) return false;
        if (locations[locationId] && locations[locationId].has_engage) {
          return true;
        }
        return false;
      });
      setFilteredList(tList);
    }
  }, [userState.user.user_info]);

  // useEffect(() => {
  //   const { user_info } = userState.user;
  //   if (user_info) {
  //     const { locations_list, locations } = user_info;
  //     if (searchKeyword && searchKeyword.length > 2) {
  //       const tList = locations_list.filter((locationId) => {
  //         let { hasAccessToReputation } = getProductAccess(userState.user.user_info, locationId);
  //         if (!hasAccessToReputation) return false;
  //         if (new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
  //           new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
  //           new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
  //           new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
  //         ) {
  //           return true;
  //         }
  //         return false;
  //       });
  //       setFilteredList(tList);
  //     } else {
  //       setFilteredList(locations_list);
  //     }
  //   }
  // }, [searchKeyword]);

  useEffect(() => {
    const { user_info } = userState.user;
    if (user_info) {
      const { locations_list, locations } = user_info;
      if (searchKeyword && searchKeyword.length > 2) {
        const tList = locations_list.filter((locationId) => {
          let { hasAccessToReputation } = getProductAccess(user_info, locationId);
          if (!hasAccessToReputation) return false;
          if (locations[locationId].has_reputation) {
            if (new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
              new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
              new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
              new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
            ) {
              return true;
            }
          }
          return false;
        });
        setFilteredList(tList);
      } else {
        const tList = locations_list.filter((locationId) => {
          let { hasAccessToReputation } = getProductAccess(user_info, locationId);
          if (!hasAccessToReputation) return false;
          if (locations[locationId].has_reputation) {
            return true;
          }
          return false;
        });
        setFilteredList(tList);
      }
    }
  }, [searchKeyword]);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchClearClick = (e) => { setSearchKeyword(''); };
  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  const onDownloadClick = (e) => {
    const { user_info } = userState.user;
    if (!user_info) return;
    const { locations_list, locations } = user_info;
    let isLmPM = false;
    if (locations_list.length) {
      for(let i = 0;i < locations_list.length; i++) {
        if (locations[locations_list[i]].lmpm_location_id) {
          isLmPM = true;
          break;
        }
      }
    }
    if (locations_list.length) {
      let csv_headers = [ 'Name' ];
      if (isLmPM) {
        csv_headers = [ 'Name', 'LMPM Id', 'Airbnb', 'Vrbo' ];
      }
      let csvData = '';
      csvData = csv_headers.join(',') + '\r\n';
      locations_list.forEach((locationMId) => {
        let locInfo = locations[locationMId];
        if (locInfo) {
          let entry = [
                locInfo.name,
          ];
          if (isLmPM) {
            entry = [
                  locInfo.name,
                  locInfo.lmpm_location_id || '',
                  locInfo.airbnb_url || '',
                  locInfo.vrbo_url || '',
            ];
          }
          if (entry) { csvData += entry.join(',') + '\r\n'; }
        }
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log('csvData: ', csvData);
      }
      download(new Blob([csvData]), 'all_locations.csv', 'text/plain');
    }
  };

  const { user_info } = userState.user;
  if (!user_info) return null;

  const { locations_list, locations } = user_info;

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPWrp allLocsPg">
        <div className="gPTle">
          Locations
          {
            filteredList && filteredList.length
            ?
              <div className="dwnldCsv" onClick={onDownloadClick}>
                <img src={downloadIcon} />
                <p className="download">Download CSV</p>
              </div>
            : null
          }
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw">
            <div className="gRwBxLt">
              <div className="gIpt">
                <input type="text" onChange={onSearchChange} placeholder="Search..." value={searchKeyword} onKeyDown={onSearchInputKeyDown}/>
                {
                  searchKeyword &&
                  <img className="clear" src={closeIcon} onClick={onSearchClearClick} />
                }
              </div>
            </div>
          </div>
          <div className="gBCtRw">{filteredList.length} Found.</div>
          {
            filteredList && filteredList.length
            ?
              filteredList.map((locationId) => {
                return (
                  <LocationRow key={`lr_${locationId}`} data={locations[locationId]} user={userState.user} />
                )
              })
            : null
          }
          {/*
            locations_list && locations && locations_list.length
            ?
              locations_list.map((locationId) => {
                if (locations[locationId]) {
                  if (searchKeyword && searchKeyword.length > 2) {
                    if (new RegExp(searchKeyword, 'i').test(locations[locationId].name) ||
                      new RegExp(searchKeyword, 'i').test(locations[locationId].address) ||
                      new RegExp(searchKeyword, 'i').test(locations[locationId].location_id) ||
                      new RegExp(searchKeyword, 'i').test(locations[locationId].serverid)
                    ) {
                      return (
                        <LocationRow key={`lr_${locationId}`} data={locations[locationId]} user={userState.user} />
                      )
                    }
                    return null;
                  }
                  return (
                    <LocationRow key={`lr_${locationId}`} data={locations[locationId]} user={userState.user} />
                  )
                }
                return null;
              })
            : null
          */}
        </div>
      </div>
    </div>
  );
}

export default Locations;
