// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';

// import useOnClickOutside from '../Misc/OutsideClickHook';
import ToolTipWrapper from '../ToolTipWrapper';

// import { formatDate } from '../../../../../helpers';

import './style.css';


function ActionCard(props) {
  const onCtaClickLocal = (e) => {
    props.onCtaClick();
  };

  const { title, titleToolTip, value, noValueLabel, ctaLabel } = props.data;

  return (
    <div className="gCrd gActionCard">
      <div className="top">
        <p>
          {title}
          {
            titleToolTip &&
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={titleToolTip}
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          }
        </p>
      </div>
      <div className="middle">
        {
          value ? <p>{value}</p> : <span>{noValueLabel}</span>
        }
      </div>
      <div className="footer">
        {
          value && ctaLabel && props.onCtaClick
          ? <p onClick={onCtaClickLocal}>{ctaLabel}</p>
          : null
        }
      </div>
    </div>
  );
}

export default ActionCard;
