import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';

import { ruleTypeToLabel, RULE_TYPES } from '../../../../../../helpers';

import editIcon from '../../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../../img/icons/delete.svg';

import '../style.css';

function DGPageRow(props) {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    setToggleOn(props.template.is_enabled || false);
  }, []);

  useEffect(() => {
    if (props.template && !props.template.editInProgress && props.template.editStatus && props.template.editStatus.error) {
      setToggleOn(props.template.is_enabled || false);
    }
  }, [props.template]);

  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };
  const onDuplicateClick = (e) => {
    props.onDuplicateClick(props.template);
  };
  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    setToggleOn(checked);
    props.onEnableDisableClick({
      ...props.template,
      is_enabled: checked,
    });
  };

  const { template } = props;
  if (!template) return null;
  let rValue = template.type;
  if (template.type) {
    if (template.type === 'appear' && template.delay) {
      rValue = RULE_TYPES.SHOW_AFTER_DELAY;
    }
    if (template.type === 'appear' && template.scroll) {
      rValue = RULE_TYPES.SHOW_AFTER_SCROLL;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('rValue: ', rValue);
    }
  }

  return (
    <div className="gBCtRw ruleRw" id={`rm_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.name}
              />
            : template.name
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id={template._id} checked={toggleOn} onChange={onToggleChange}
                  name={template._id} for={template._id}
                /><label htmlFor={template._id}>Toggle</label>
              </div>
            </div>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Duplicate">
              <span onClick={onDuplicateClick}><i className="fa fa-copy"></i></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
              <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
            </>
          }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          <p>Web Address: {template.url}</p>
        </div>
      </div>
    </div>
  )

}

export default DGPageRow;
