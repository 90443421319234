// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
// import Select from 'react-dropdown-select';
import Select from 'react-select'
import moment from 'moment';
import DatePicker from "react-datepicker";

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import CustomTimePicker from '../../../../../Misc/CustomTimePicker';

import { formatDateTime } from '../../../../../../helpers';
import './style.css';


function CheckInModal(props) {

  const [roomNumber, setRoomNumber] = useState('');
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [checkOutTime, setCheckOutTime] = useState(new Date());
  const [dateRangeValid, setDateRangeValid] = useState(true);

  const [roomNumberError, setRoomNumberError] = useState('');

  const modalRef = useRef();

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - CheckIn - ${props.guest._id}`,
    //   user_id: props.userprops.user.uid,
    // });
  }, []);

  useEffect(() => {
    setRoomNumber(props.guest.room_number);
    setCheckOutDate(new Date(moment(props.guest.check_out_date)));
    setCheckOutTime(new Date(moment(props.guest.check_out_date)));
  }, []);

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onConfirmClick = (e) => {
    setRoomNumberError(roomNumber ? '' : 'Room number is required.');
    if (!dateRangeValid) return;
    if (!roomNumber) {
      return;
    }
    const guest_check_out_date_utc_str = moment(props.guest.check_out_date).utc().format('YYYY-MM-DD HH:mm');
    if (process.env.NODE_ENV !== 'production') {
      console.log('guest_check_out_date_utc_str: ', guest_check_out_date_utc_str);
    }
    const check_out_date_str = moment(checkOutDate).format('YYYY-MM-DD');
    const check_out_time_str = moment(checkOutTime).format('HH:mm');
    const check_out_date_utc_str = moment(`${check_out_date_str} ${check_out_time_str}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm');
    if (process.env.NODE_ENV !== 'production') {
      console.log('check_out_date_utc_str: ', check_out_date_utc_str);
    }

    const params = {};
    if (guest_check_out_date_utc_str !== check_out_date_utc_str) {
      params['check_out_date'] = check_out_date_utc_str;
    }
    params['room_number'] = roomNumber;

    props.onConfirmClick({
      ...params,
    });
    // if (props.type === 'add') {
    //   props.onAddEditConfirmClick({
    //     title,
    //     msg: message,
    //   });
    // }
    // if (props.type === 'edit') {
    //   props.onAddEditConfirmClick({
    //     _id: props.template._id,
    //     title,
    //     msg: message,
    //   });
    // }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setRoomNumber(value);
    setRoomNumberError(value ? '' : 'Room number is required.');
  };

  const validateDateRange = (mCheckInDate, mCheckInTime, mCheckOutDate, mCheckOutTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(mCheckInDate, ' # ', mCheckInTime, ' # ', mCheckOutDate, ' # ', mCheckOutTime);
    }
    const checkInDateStr = `${mCheckInDate.format('YYYY-MM-DD')} ${mCheckInTime.format('HH:mm')}`;
    const checkOutDateStr = `${mCheckOutDate.format('YYYY-MM-DD')} ${mCheckOutTime.format('HH:mm')}`;
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInDateStr: ', checkInDateStr);
      console.log('checkOutDateStr: ', checkOutDateStr);
    }
    if (moment(checkInDateStr, 'YYYY-MM-DD HH:mm') < moment(checkOutDateStr, 'YYYY-MM-DD HH:mm')) {
      return true;
    }

    return false;
  };

  const onCheckOutDateChange = (selectedDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckOutDateChange: ', selectedDate);
    }
    if (moment(selectedDate) < moment()) return;
    setCheckOutDate(new Date(moment(selectedDate)));
    setDateRangeValid(validateDateRange(moment(), moment(), moment(selectedDate), moment(checkOutTime)));
  };
  const onCheckOutTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckOutTimeChange: ', selectedTime);
    }
    // setCheckOutTime(new Date(selectedTime));
    setCheckOutTime(new Date(moment(selectedTime, 'hh:mm A')));
    setDateRangeValid(validateDateRange(moment(), moment(), moment(checkOutDate), moment(selectedTime, 'hh:mm A')));
  };

  const inValidCheckOutDate = (current) => {
    return current.isAfter(moment().subtract(1, 'day'));
  };


  const renderContent = () => {
    return (
      <div className="gPWrp checkInNow" ref={modalRef}>
        <div className="gPTle title">
          Do you want to check-in this guest now ?
        </div>
        <div className="gPTle subTitle">
          Guest will be checking in to the hotel right now
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                {formatDateTime(moment())}
              </div>
            </div>
          </div>
          <div className="gBCtRw rmNumRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Room Number
              </div>
              <div className="gIpt">
                <input type="text" placeholder="Please enter room number" name="roomNumber" value={roomNumber} onChange={onChange} />
                { roomNumberError && <p className="error">{roomNumberError}</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw chkOutDtRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Check-Out Date
              </div>
              <div className="gIpt">
                <DatePicker selected={checkOutDate} onChange={onCheckOutDateChange}
                  dateFormat="dd MMM yyyy"
                  minDate={inValidCheckOutDate}
                />
              </div>
            </div>
            <div className="gRwBxRt">
              <div className="gLbl">
                Check-Out Time
              </div>
              <div className="gIpt">
                <CustomTimePicker
                  value={moment(checkOutTime).format('hh:mm A')}
                  onChange={onCheckOutTimeChange}
                />
              </div>
            </div>
          </div>
          {
            !dateRangeValid &&
            <div className="gBCtRw curSts">
              <p className="error">Check-Out Date cannot be prior to Check-In Date</p>
            </div>
          }
          {
            props.checkInStatus && (props.checkInStatus.success || props.checkInStatus.error) &&
            <div className="gBCtRw curSts">
              {
                props.checkInStatus.error && <p className="error">{props.checkInStatus.message}</p>
              }
            </div>
          }
          {
            props.guest.edit_remote_lock_error &&
            <div className="gBCtRw curSts">
              {
                <p className="error">{props.guest.edit_remote_lock_error}</p>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            <button className="gBtn cancel" onClick={onCancelClick}>Cancel</button>
            <button type="submit" className="gBtn ok" onClick={onConfirmClick} >
              Yes, Check-In
              { props.checkInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  };

  return renderContent();

}

export default CheckInModal;
