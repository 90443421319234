// @flow

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import Mousetrap from 'mousetrap';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

// import { getEmailTemplatesAction } from '../../../actions/emailTemplateActions';
import CopyTemplateModal from './CopyTemplateModal';
import GenericAlertModal from '../../../Misc/GenericAlertModal';
import useOnClickOutside from '../../../Misc/OutsideClickHook';

import moreIcon from '../../../../img/icons/more-horizontal.svg';

import './style.css';

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SingleTemplate(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const cMenuRef = useRef();
  useOnClickOutside(cMenuRef, useCallback(() => closeMenu()));

  const closeMenu = (e) => {
    setMenuOpen(false);
  };
  const onOpenMenuClick = (e) => {
    setMenuOpen(true);
  };

  const onChooseClick = (e) => {
    props.onChooseClick(props.data);
  };
  const onOptionCopyClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOptionCopyClick: ', props.data);
    }
    props.onCopyClick(props.data);
  };
  const onOptionDeleteClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOptionDeleteClick: ', props.data);
    }
    props.onDeleteClick(props.data)
  };

  const renderOverflowMenu = () => {
    return (
      <div className="oMenu">
        <div className="dropdown" ref={cMenuRef}>
          <button className="dropbtn" onClick={onOpenMenuClick} ><img src={moreIcon}/></button>
          <div className={'dropdown-content ' + (menuOpen ? 'open ' : '')}>
            <p onClick={onOptionCopyClick}>Make a copy</p>
            { !props.data.is_default && <p onClick={onOptionDeleteClick}>Delete</p> }
          </div>
        </div>
      </div>
    );
  };
  if (props.data.new) {
    return (
      <div className={'singleTemplate new' + (props.selectedTemplate.new === props.data.new ? ' selected' : '')} id={`st_new`} onClick={onChooseClick}>
        <div className="name">Add New</div>
      </div>
    );
  }
  return (
    <div className={'singleTemplate' + (props.selectedTemplate._id === props.data._id ? ' selected' : '')} id={`st_${props.data._id}`}>
      <div className="top">
        { props.data.is_default && <span className="default">Default</span> }
        { renderOverflowMenu() }
      </div>
      <div className={'innerBox'} onClick={onChooseClick}>
        <div className="previewBox"><span>View & Use Template</span></div>
        <div className="name">{props.data.name}</div>
        <div className="desc">{props.data.desc || 'some sample description, for awesome check-in exp.'}</div>
      </div>
    </div>
  );
}

function TemplateSelectorModal(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  const [selectedTemplate, setSelectedTemplate] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteTemplate, setSelectedDeleteTemplate] = useState(null);

  const [showCopyTemplateModal, setShowCopyTemplateModal] = useState(false);
  const [selectedCopyTemplate, setSelectedCopyTemplate] = useState(null);

  const [showDeleteNotAllowedModal, setShowDeleteNotAllowedModal] = useState(false);
  const [deleteInUseBy, setDeleteInUseBy] = useState('');

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - Alert - ${props.modalType}`,
    //   user_id: props.userprops.user.uid,
    // });
    Mousetrap.bind('esc', () => props.setModalVisible(false));
    return () => {
      Mousetrap.unbind('esc');
    };
  }, []);

  useEffect(() => {
    const { email_templates } = props.transactionEmails;
    if (email_templates && email_templates.keyValue &&
      selectedDeleteTemplate && selectedDeleteTemplate._id &&
      email_templates.keyValue[selectedDeleteTemplate._id] &&
      !email_templates.keyValue[selectedDeleteTemplate._id].removeInProgress &&
      email_templates.keyValue[selectedDeleteTemplate._id].is_removed
    ) {
      setShowDeleteModal(false);
    }
    if (email_templates &&
      !email_templates.copyInProgress &&
      email_templates.copyStatus &&
      email_templates.copyStatus.success
    ) {
      setShowCopyTemplateModal(false);
    }
  }, [props.transactionEmails.email_templates]);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCancelClick = (e) => {
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    props.onAffirmativeClick(selectedTemplate);
  };

  const onAffirmativeSecondaryClick = (e) => {
    props.onAffirmativeSecondaryClick();
  };

  const onChooseClick = (data) => {
    setSelectedTemplate(data);
  };

  const onDeleteClick = (selectedData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDeleteClick: ', selectedData);
    }
    // check if email template is in use
    let inUse = false; let inUseBy = '';
    const { transaction_emails } = props.transactionEmails;
    if (transaction_emails && transaction_emails.list && transaction_emails.keyValue) {
      transaction_emails.list.forEach((item) => {
        if (transaction_emails.keyValue[item] &&
          transaction_emails.keyValue[item].email_template_id === selectedData._id
        ) {
          inUse = true;
          inUseBy = transaction_emails.keyValue[item].type;
        }
      });
    }
    if (inUse) {
      if (inUseBy) setDeleteInUseBy(inUseBy);
      setShowDeleteNotAllowedModal(true);
      return;
    }
    const params = {
      location__id: props.locationInfo._id,
      mode: 'remove',
      email_template_id: selectedData._id,
    };
    dispatch(editDataClearAction({
      op: 'edit_email_template',
      params,
    }));
    setSelectedDeleteTemplate(selectedData);
    setShowDeleteModal(true);
  };
  const onDeleteConfirmClick = (e) => {
    const params = {
      location__id: props.locationInfo._id,
      mode: 'remove',
      email_template_id: selectedDeleteTemplate._id,
    };
    dispatch(editDataAction({
      op: 'edit_email_template',
      params,
    }));
  };
  const onShowDeleteNotAllowedCloseModal = (e) => {
    setShowDeleteNotAllowedModal(false);
  };

  const onCopyClick = (selectedData) => {
    const params = {
      location__id: props.locationInfo._id,
      mode: 'copy',
      email_template_id: selectedData._id,
    };
    dispatch(editDataClearAction({
      op: 'edit_email_template',
      params,
    }));
    setSelectedCopyTemplate(selectedData);
    setShowCopyTemplateModal(true);
  };
  const onCopyConfirmClick = (name) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCopyConfirmClick: ', selectedCopyTemplate);
    }
    const params = {
      location__id: props.locationInfo._id,
      email_template_id: selectedCopyTemplate._id,
      mode: 'copy',
      template_data: {
        name,
      },
    };
    dispatch(editDataAction({
      op: 'edit_email_template',
      params,
    }));
  };

  const renderCopyTemplateModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal cpEtModal">
          <div className="content">
            <CopyTemplateModal onCancelClick={setShowCopyTemplateModal}
              onConfirmClick={onCopyConfirmClick}
              copyInProgress={props.transactionEmails.email_templates ? props.transactionEmails.email_templates.copyInProgress : false}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderModal = () => {
    const { email_templates } = props.transactionEmails;
    const cStyle = {};
    cStyle.height = windowState.window.windowHeight/2 - 100;
    return (
      <>
      <Modal>
        <div id="cModelId" className="templateSelectorModal" onClick={onModalClick}>
          <div className="contentWrap">
            <div className="content">
              <div className="title">
                {
                  props.title || 'Select Template'
                }
              </div>
              <div className="body">
                <div className="rowOne">
                  {props.bodyRowOne}
                </div>
                {
                  props.bodyRowNote &&
                  <div className="rowNote">
                    <b>Note: </b>{props.bodyRowNote}
                  </div>
                }
                <div className="templateList" style={cStyle}>
                  {
                    email_templates && email_templates.list &&
                    email_templates.list.map((item) => {
                      if (!email_templates.keyValue[item]) return null;
                      if (email_templates.keyValue[item].is_removed) return null;
                      return (
                        <SingleTemplate
                          key={`st_${item}`}
                          data={email_templates.keyValue[item]}
                          onChooseClick={onChooseClick}
                          selectedTemplate={selectedTemplate}
                          onCopyClick={onCopyClick}
                          onDeleteClick={onDeleteClick}
                        />
                      );
                    })
                  }
                  <SingleTemplate
                    key={`st_new`}
                    data={{
                      new: true,
                    }}
                    onChooseClick={onChooseClick}
                    selectedTemplate={selectedTemplate}
                  />
                </div>
                {
                  props.currentStatus && (props.currentStatus.success || props.currentStatus.error) &&
                  <div className="currentStatus">
                    {
                      props.currentStatus.error && <p className="error">{props.currentStatus.message}</p>
                    }
                    {
                      props.currentStatus.success && <p className="success">Updated successfully</p>
                    }
                  </div>
                }
                <div className="actions">
                  {
                    props.showCancel &&
                    <button className="cancel" onClick={onCancelClick}>Cancel</button>
                  }
                  <button className="ok" onClick={onAffirmativeClick}>
                    { props.affirmativeButtonLabel || 'Confirm' }
                    { props.inProgress && <i className="fa fa-spinner fa-spin" /> }
                  </button>
                  {
                    props.onAffirmativeSecondaryClick &&
                    <button className="ok secondary" onClick={onAffirmativeSecondaryClick}>
                      { props.affirmativeSecondaryButtonLabel || 'Confirm' }
                      { props.inProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {
        showCopyTemplateModal && renderCopyTemplateModal()
      }
      { showDeleteModal &&
        <GenericAlertModal
          modalType="Delete Template"
          bodyRowOne={`Do you want to delete ${selectedDeleteTemplate.name} email template ? This action cannot be undone!`}
          bodyRowNote={'You’ll not be able to retrieve the contents later.'}
          keyName={selectedTemplate.label}
          setModalVisible={setShowDeleteModal}
          onAffirmativeClick={onDeleteConfirmClick}
          inProgress={props.transactionEmails.email_templates.keyValue[selectedDeleteTemplate._id] ? props.transactionEmails.email_templates.keyValue[selectedDeleteTemplate._id].removeInProgress : false}
          showCancel={true}
        />
      }
      { showDeleteNotAllowedModal &&
        <GenericAlertModal
          modalType="deleteModal"
          title={`Email template in use, deletion not allowed.`}
          bodyRowOne={`It is being used in Guest Journey Emails(${deleteInUseBy}). It can only be deleted if it is not in use.`}
          bodyRowNote=""
          setModalVisible={onShowDeleteNotAllowedCloseModal}
          onAffirmativeClick={() => onShowDeleteNotAllowedCloseModal()}
          inProgress={false}
          // currentStatus={guest.checkOutStatus}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      }
      </>
    );
  };


  const renderContent = () => {
    if (showModal) return renderModal();
    return null;
  };

  return renderContent();
}

export default TemplateSelectorModal;
