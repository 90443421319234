import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

const personalizeOptionsG = [];
// personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{firstname}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{lastname}}' });
personalizeOptionsG.push({ label: 'Wifi', value: '{{wifi}}' });

function ResponseTemplateSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState({});
  const [categoryError, setCategoryError] = useState('');

  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryNameError, setNewCategoryNameError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const [pickerStyle, setPickerStyle] = useState({
    height: 0,
  });
  const emojiSelectorRef = useRef(null);
  // useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));

  const messageEl = useRef(null);
  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      let page_title = '';
      if (props.templateType === 'responseTemplate') page_title = `Settings - Reputation - Response Template - Single - ${locationName}`;
      if (props.templateType === 'promptTemplate') page_title = `Settings - Reputation - Prompt Template - Single - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    const { templateType } = props;
    if (reputation) {
      let template_categories = {};
      if (templateType === 'responseTemplate') template_categories = { ...reputation.response_template_categories };
      if (templateType === 'promptTemplate') template_categories = { ...reputation.prompt_template_categories };
      if (template_categories && template_categories.list && template_categories.list.length) {
        let temp = [];
        template_categories.list.forEach((item) => {
          temp.push({ label: template_categories.keyValue[item].name, value: item });
        });
        setCategoryOptions(temp);
      }
      // if (reputation && reputation.response_template_categories && reputation.response_template_categories.list &&
      //   reputation.response_template_categories.list.length
      // ) {
      //   let temp = [];
      //   reputation.response_template_categories.list.forEach((item) => {
      //     temp.push({ label: reputation.response_template_categories.keyValue[item].name, value: item });
      //   });
      //   setCategoryOptions(temp);
      // }
      if (props.template && props.template._id) {
        setTitle(props.template.title);
        if (templateType === 'responseTemplate') setMessage(props.template.response);
        if (templateType === 'promptTemplate') setMessage(props.template.prompt);
        if (props.template.category_id &&
          template_categories && template_categories.list && template_categories.list.length) {
          setCategoryValue({
            label: template_categories.keyValue[props.template.category_id].name,
            value: props.template.category_id,
          });
        }
      }
      // if (props.template && props.template._id) {
      //   setTitle(props.template.title);
      //   setMessage(props.template.response);
      //   if (props.template.category_id &&
      //     reputation && reputation.response_template_categories && reputation.response_template_categories.list &&
      //     reputation.response_template_categories.list.length
      //   ) {
      //     setCategoryValue({
      //       label: reputation.response_template_categories.keyValue[props.template.category_id].name,
      //       value: props.template.category_id,
      //     });
      //   }
      // }
    }
    setSomeChange(false);

    // const params = {
    //   location__id: urlParams.locationidentifier,
    //   mode: props.type,
    // };
    // if (templateType === 'responseTemplate') params.response_template_id = props.template._id;
    // if (templateType === 'promptTemplate') params.prompt_template_id = props.template._id;
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template',
    //   params,
    // }));

    clearData();

    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.templateType === 'responseTemplate') {
      if (props.type === 'edit') {
        if (props.template._id &&
          props.settings.reputation.response_templates && props.settings.reputation.response_templates.keyValue &&
          props.settings.reputation.response_templates.keyValue[props.template._id] &&
          !props.settings.reputation.response_templates.keyValue[props.template._id].editInProgress &&
          props.settings.reputation.response_templates.keyValue[props.template._id].editStatus &&
          props.settings.reputation.response_templates.keyValue[props.template._id].editStatus.success
        ) {
          setSomeChange(false);
        }
      }
      if (props.type === 'add') {
        if (props.settings.reputation.response_templates &&
          !props.settings.reputation.response_templates.addInProgress &&
          props.settings.reputation.response_templates.addStatus &&
          props.settings.reputation.response_templates.addStatus.success
        ) {
          setTitle('');
          setMessage('');
          setCategoryValue({});
          setSomeChange(false);
        }
      }
      // if (props.settings.reputation.response_template_categories &&
      //   !props.settings.reputation.response_template_categories.addInProgress &&
      //   props.settings.reputation.response_template_categories.addStatus &&
      //   props.settings.reputation.response_template_categories.addStatus.success
      // ) {
      //   const { reputation } = props.settings;
      //   if (reputation && reputation.response_template_categories && reputation.response_template_categories.list &&
      //     reputation.response_template_categories.list.length
      //   ) {
      //     let temp = [];
      //     reputation.response_template_categories.list.forEach((item) => {
      //       temp.push({ label: reputation.response_template_categories.keyValue[item].name, value: item });
      //     });
      //     setCategoryOptions(temp);
      //   }
      // }
    }
  }, [props.settings.reputation.response_templates]);

  useEffect(() => {
    if (props.templateType === 'responseTemplate') {
      if (props.settings.reputation.response_template_categories &&
        !props.settings.reputation.response_template_categories.addInProgress &&
        props.settings.reputation.response_template_categories.addStatus &&
        props.settings.reputation.response_template_categories.addStatus.success
      ) {
        const { reputation } = props.settings;
        if (reputation && reputation.response_template_categories && reputation.response_template_categories.list &&
          reputation.response_template_categories.list.length
        ) {
          let temp = [];
          reputation.response_template_categories.list.forEach((item) => {
            temp.push({ label: reputation.response_template_categories.keyValue[item].name, value: item });
            // auto select the newly added one
            if (newCategoryName) {
              if (newCategoryName === reputation.response_template_categories.keyValue[item].name) {
                setCategoryValue({
                  label: newCategoryName,
                  value: item,
                });
              }
            }
          });
          setCategoryOptions(temp);
        }
      }
    }
  }, [props.settings.reputation.response_template_categories]);

  useEffect(() => {
    if (props.templateType === 'promptTemplate') {
      if (props.type === 'edit') {
        if (props.template._id &&
          props.settings.reputation.prompt_templates && props.settings.reputation.prompt_templates.keyValue &&
          props.settings.reputation.prompt_templates.keyValue[props.template._id] &&
          !props.settings.reputation.prompt_templates.keyValue[props.template._id].editInProgress &&
          props.settings.reputation.prompt_templates.keyValue[props.template._id].editStatus &&
          props.settings.reputation.prompt_templates.keyValue[props.template._id].editStatus.success
        ) {
          setSomeChange(false);
        }
      }
      if (props.type === 'add') {
        if (props.settings.reputation.prompt_templates &&
          !props.settings.reputation.prompt_templates.addInProgress &&
          props.settings.reputation.prompt_templates.addStatus &&
          props.settings.reputation.prompt_templates.addStatus.success
        ) {
          setTitle('');
          setMessage('');
          setCategoryValue({});
          setSomeChange(false);
        }
      }
    }
  }, [props.settings.reputation.prompt_templates]);

  useEffect(() => {
    if (props.templateType === 'promptTemplate') {
      if (props.settings.reputation.prompt_template_categories &&
        !props.settings.reputation.prompt_template_categories.addInProgress &&
        props.settings.reputation.prompt_template_categories.addStatus &&
        props.settings.reputation.prompt_template_categories.addStatus.success
      ) {
        const { reputation } = props.settings;
        if (reputation && reputation.prompt_template_categories && reputation.prompt_template_categories.list &&
          reputation.prompt_template_categories.list.length
        ) {
          let temp = [];
          reputation.prompt_template_categories.list.forEach((item) => {
            temp.push({ label: reputation.prompt_template_categories.keyValue[item].name, value: item });
            // auto select the newly added one
            if (newCategoryName) {
              if (newCategoryName === reputation.prompt_template_categories.keyValue[item].name) {
                setCategoryValue({
                  label: newCategoryName,
                  value: item,
                });
              }
            }
          });
          setCategoryOptions(temp);
        }
      }
    }
  }, [props.settings.reputation.prompt_template_categories]);

  const clearData = () => {
    const { templateType } = props;
    if (props.type === 'edit') {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
      };
      let op = '';
      if (templateType === 'responseTemplate') {
        op = 'edit_response_template';
        params.response_template_id = props.template._id;
      }
      if (templateType === 'promptTemplate') {
        op = 'edit_prompt_template';
        params.response_template_id = props.template._id;
      }
      dispatch(editDataClearAction({
        op,
        params,
      }));
    }

    if (props.type === 'add') {
      let op = '';
      if (templateType === 'responseTemplate') {
        op = 'edit_response_template_category';
      }
      if (templateType === 'promptTemplate') {
        op = 'edit_prompt_template_category';
      }
      dispatch(editDataClearAction({
        op,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true);
    // props.onAddEditClear(props.template);

    // const params = {
    //   location__id: urlParams.locationidentifier,
    //   mode: props.type,
    // };
    // let op = '';
    // if (templateType === 'responseTemplate') {
    //   op = 'edit_response_template';
    //   params.response_template_id = props.template._id;
    // }
    // if (templateType === 'promptTemplate') {
    //   op = 'edit_prompt_template';
    //   params.response_template_id = props.template._id;
    // }
    // dispatch(editDataClearAction({
    //   op,
    //   params,
    // }));
    //
    // op = '';
    // if (templateType === 'responseTemplate') {
    //   op = 'edit_response_template_category';
    // }
    // if (templateType === 'promptTemplate') {
    //   op = 'edit_prompt_template_category';
    // }
    // dispatch(editDataClearAction({
    //   op,
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    clearData();

    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
  }
  const onMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    setSomeChange(true);
    // props.onAddEditClear(props.template);

    // const params = {
    //   location__id: urlParams.locationidentifier,
    //   mode: props.type,
    // };
    // let op = '';
    // if (templateType === 'responseTemplate') {
    //   op = 'edit_response_template';
    //   params.response_template_id = props.template._id;
    // }
    // if (templateType === 'promptTemplate') {
    //   op = 'edit_prompt_template';
    //   params.response_template_id = props.template._id;
    // }
    // dispatch(editDataClearAction({
    //   op,
    //   params,
    // }));
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template_category',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    clearData();

    if (!value) {
      setMessageError('error');
    } else {
      setMessageError('');
    }
  };

  const onOpenEmojiSelectorClick = (e) => {
    setPickerStyle(pickerStyle => ({
      ...pickerStyle,
      height: emojiSelectorOpen ? 0 : 200,
    }));
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      setSomeChange(true);
      if (message) {
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${emoji.native}`);
      }
    }
  };

  const onCategoryChange = (value) => {
    setCategoryValue(value);
    setSomeChange(true);
    setCategoryError('');
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    if (props.addEditInProgress) return;
    const { templateType } = props;
    // props.onAddEditClear(props.template);
    clearData();
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: props.type,
    //     response_template_id: props.template._id,
    //   },
    // }));
    // dispatch(editDataClearAction({
    //   op: 'edit_response_template_category',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    setTitleError(title ? '' : 'error');
    setMessageError(message ? '' : 'error');
    // setCategoryError(categoryValue && categoryValue.value ? '' : 'error');
    if (someChange) {
      if (props.type === 'add') {
        if (title && message) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            title,
            category_id: categoryValue.value,
          };
          let op = '';
          if (templateType === 'responseTemplate') {
            op = 'edit_response_template';
            params.response = message;
          }
          if (templateType === 'promptTemplate') {
            op = 'edit_prompt_template';
            params.prompt = message;
          }

          dispatch(editDataAction({
            op,
            params,
          }));
          // props.onAddEditConfirmClick({
          //   title,
          //   msg: message,
          // });
        }
      }
      if (props.type === 'edit') {
        if (title && message) {
          let op = '';
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            title,
            category_id: categoryValue.value,
          };
          if (templateType === 'responseTemplate') {
            op = 'edit_response_template';
            params.response_template_id = props.template._id;
            params.response = message;
          }
          if (templateType === 'promptTemplate') {
            op = 'edit_prompt_template';
            params.prompt_template_id = props.template._id;
            params.prompt = message;
          }
          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
    }
  };

  const onOpenAddCategory = (e) => {
    setAddCategoryOpen(true);
    const { templateType } = props;
    let op = '';
    if (templateType === 'responseTemplate') op = 'edit_response_template_category';
    if (templateType === 'promptTemplate') op = 'edit_prompt_template_category';
    dispatch(editDataClearAction({
      op,
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
      },
    }));
  }
  const onNewCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategoryName(value);
    const { templateType } = props;
    let op = '';
    if (templateType === 'responseTemplate') op = 'edit_response_template_category';
    if (templateType === 'promptTemplate') op = 'edit_prompt_template_category';
    dispatch(editDataClearAction({
      op,
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
      },
    }));
    setNewCategoryNameError(value ? '' : 'error');
  };
  const onAddCategorySaveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddCategoySaveClick: ', newCategoryName);
    }
    if (!newCategoryName) {
      setNewCategoryNameError('error');
      return;
    }
    setNewCategoryNameError('');
    // props.onAddCategoryConfirmClick({
    //   name: newCategoryName,
    // });
    const { templateType } = props;
    let op = '';
    if (templateType === 'responseTemplate') op = 'edit_response_template_category';
    if (templateType === 'promptTemplate') op = 'edit_prompt_template_category';
    dispatch(editDataAction({
      op,
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
        name: newCategoryName,
      },
    }));
  };
  const onAddCategoryCancelClick = (e) => {
    setNewCategoryName('');
    setAddCategoryOpen(false);
  };


  const renderContent = () => {
    const { locationidentifier } = urlParams;
    const { templateType } = props;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.reputation) return null;

    let tData = {};

    if (props.type === 'edit') {
      if (templateType === 'responseTemplate') {
        if (!settings.reputation.response_templates || !settings.reputation.response_templates.keyValue) return null;
        tData = settings.reputation.response_templates.keyValue[props.template._id] || {};
      }
      if (templateType === 'promptTemplate') {
        if (!settings.reputation.prompt_templates || !settings.reputation.prompt_templates.keyValue) return null;
        tData = settings.reputation.prompt_templates.keyValue[props.template._id] || {};
      }
    }

    let pageTitle = '';
    if (props.templateType === 'responseTemplate') {
      if (props.type === 'add') pageTitle = `Add Response Template`;
      if (props.type === 'edit') pageTitle = 'Edit Response Template';
    }
    if (props.templateType === 'promptTemplate') {
      if (props.type === 'add') pageTitle = `Add guestAI™ Template`;
      if (props.type === 'edit') pageTitle = 'Edit guestAI™ Template';
    }
    if (props.title) pageTitle = props.title;


    let addEditInProgress = false;
    let addEditStatus = {};

    if (props.templateType === 'responseTemplate') {
      addEditInProgress = settings.reputation.response_templates ? settings.reputation.response_templates.addInProgress : false;
      addEditStatus = settings.reputation.response_templates ? settings.reputation.response_templates.addStatus : {};
    }
    if (props.templateType === 'promptTemplate') {
      addEditInProgress = settings.reputation.prompt_templates ? settings.reputation.prompt_templates.addInProgress : false;
      addEditStatus = settings.reputation.prompt_templates ? settings.reputation.prompt_templates.addStatus : {};
    }

    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    if (process.env.NODE_ENV !== 'production') {
      console.log('ResponseTemplateSingle addEditStatus: ', addEditStatus);
    }

    let addEditCategoryInProgress = false;
    let addEditCategoryStatus = {};
    if (props.templateType === 'responseTemplate') {
      addEditCategoryInProgress = settings.reputation.response_template_categories ? settings.reputation.response_template_categories.addInProgress : false;
      addEditCategoryStatus = settings.reputation.response_template_categories ? settings.reputation.response_template_categories.addStatus : {};
    }
    if (props.templateType === 'promptTemplate') {
      addEditCategoryInProgress = settings.reputation.prompt_template_categories ? settings.reputation.prompt_template_categories.addInProgress : false;
      addEditCategoryStatus = settings.reputation.prompt_template_categories ? settings.reputation.prompt_template_categories.addStatus : {};
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('ResponseTemplateSingle addEditCategoryStatus: ', addEditCategoryStatus);
    }

    return (
      <div className="gPWrp">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Title
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" ref={titleRef} />
                { titleError && <p className="gErr">Title is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw messageRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Message
              </div>
              <div className={'gIpt ' + (messageError ? 'error' : '')}>
                <textarea rows="4" placeholder="Message"
                  onChange={onMessageChange}
                  placeholder="Message..."
                  value={message}
                  ref={messageEl}
                />
                { messageError && <p className="gErr">Message is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw contentRow emojiRow">
            <div className={'gRwBxLt emojiBox ' + (messageError ? 'messageError' : '')}>
              <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                {
                  emojiSelectorOpen
                  ? <img className="closeIcon" src={closeIcon} />
                  : <img src={emojiIcon} />
                }

              </div>
            </div>
          </div>
          { emojiSelectorOpen &&
            <div className="emojiBox" ref={emojiSelectorRef}>
              <Picker
                data={emojiData}
                theme="light"
                onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
              />
            </div>
          }
          <div className="gBCtRw categoryRow">
            <div className={'gRwBxLt ' + (categoryError ? 'categoryError' : '')}>
              <div className="gLbl">
                Category
              </div>
              <Select options={categoryOptions}
                onChange={onCategoryChange}
                value={categoryValue}
              />
              { categoryError && <p className="error">Category is required</p> }
            </div>
            <div className="gRwBxRt">
              {
                addCategoryOpen
                ?
                  <div className="addCategoryBox">
                    <div className={'gIpt' + (newCategoryNameError ? ' error' : '')}>
                      <input type="text" value={newCategoryName} onChange={onNewCategoryChange} placeholder="New Category..."/>
                    </div>
                    {
                      addEditCategoryInProgress
                      ? <span><i className="fa fa-spinner fa-spin" /></span>
                      :
                        <>
                          <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Add">
                            <span><img className="save" onClick={onAddCategorySaveClick} src={tickIcon}/></span>
                          </ToolTipWrapper>
                          <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Cancel">
                            <span><img className="cancel" onClick={onAddCategoryCancelClick} src={closeIcon}/></span>
                          </ToolTipWrapper>
                        </>
                    }
                  </div>
                : <div className="addCtgry" onClick={onOpenAddCategory}>+ Add New Category</div>
              }
            </div>
          </div>
          {
            addEditCategoryStatus && (addEditCategoryStatus.success || addEditCategoryStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditCategoryStatus.error && <span className="gErr">{addEditCategoryStatus.message || 'Error in adding category!'}</span>
              }
              {/*
                addEditCategoryStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              */}
              {
                addEditCategoryStatus.success && <span className="success">Category added successfully</span>
              }
            </div>
          }
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('ResponseTemplateSingle props: ', props);
  }

  return renderContent();
}

export default ResponseTemplateSingle;
