import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

// import SideBar from './SideBar';

import './style.css';

const gtconfig = require('../../../gtconfig');

function EnableMarketing() {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let urlParams = useParams();
  const location = useLocation();

  const onContactClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
      && locationState.locations[urlParams.locationidentifier].settings
      && locationState.locations[urlParams.locationidentifier].settings.info
      ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
      : '';
    window.FreshworksWidget('open');
    window.FreshworksWidget('identify', 'ticketForm', {
      name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
      email: userState.user.user_info.uid,
      subject: `Enable marketing for ${locationName}`,
    });
  }

  return (
    <div className="enblPrdWrap">
      <p><span className="cstFdbkContact" onClick={onContactClick}>Contact {gtconfig.APP_NAME_CONTACT} to enable Marketing</span></p>
    </div>
  );
}

export default EnableMarketing;
