import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";

import { logout } from "../../redux/actions/miscActions";

import './style.css';

function Logout() {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let params = useParams();


  useEffect(() => {
    try { window.Intercom('shutdown'); } catch(e) {}
    dispatch(logout({
      params: {
        uid: userState.user.uid,
      },
    }));
  }, []);

  useEffect(() => {
    if (!userState.user || !Object.keys(userState.user).length) {
      navigate(`/login`);
    }
  }, [userState.user]);

  return null;
}

export default Logout;
