// @flow

import ReactDOM from 'react-dom';
import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import {useDropzone} from 'react-dropzone';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import { validateEmail, validateContact, STRS, getUserGAId } from '../../../../../helpers';


import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../Misc/GenericAlertModal';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import './style.css';

import uploadImg from '../../../../../img/icons/upload.png';
import searchIcon from '../../../../../img/icons/search.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import resetIcon from '../../../../../img/icons/reset-active.svg';
import tipIcon from '../../../../../img/icons/bulb.png';
import requiredIcon from '../../../../../img/icons/required.svg';


const SPLIT_ON_NEWLINE = 'split-on-newline';
const SPLIT_ON_COMMA = 'split-on-comma';
const SPLIT_FOR_CSV = 'split-for-csv';

function TemplateButton(props) {
  const onButtonClick = (e) => {
    props.onButtonClick(props.data);
  };

  const { data, selected } = props;
  return (
    <div className={'entry' + (selected ? ' selected' : '')} onClick={onButtonClick}>
      {data.title}
    </div>
  );
}

function EmailRow(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  useEffect(() => {
    setName(props.data.name);
    setEmail(props.data.email);
    setContact(props.data.contact);
  }, [props.data]);
  const onNameChange = (e) => { setName(e.target.value); props.onNameChange(props.data.id, e.target.value); };
  const onEmailChange = (e) => { setEmail(e.target.value); props.onEmailChange(props.data.id, e.target.value); };
  const onContactChange = (e) => { setContact(e.target.value); props.onContactChange(props.data.id, e.target.value); };
  const onRemove = (e) => { props.onRemove(props.data.id); };
  return (
    <>
      <div className="emailRow">
        {
          props.selectedMedium === 'sms'
          ?
          <div className={'contact ' + (props.data.error ? 'error' : '')}>
            <input type="text" value={contact} onChange={onContactChange} />
            {
              props.data.error &&
              <p className="gErr">{props.data.error}</p>
            }
          </div>
          :
          <div className={'email ' + (props.data.error ? 'error' : '')}>
            <input type="text" value={email} onChange={onEmailChange} />
            {
              props.data.error &&
              <p className="gErr">{props.data.error}</p>
            }
          </div>
        }
        <div className="name"><input type="text" value={name} onChange={onNameChange} /></div>
        <div className="remove"><img src={closeIcon} onClick={onRemove} /></div>
      </div>
    </>
  );
}

function RunCampaign(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [selectedMedium, setSelectedMedium] = useState('email'); // default email
  const [mediumError, setMediumError] = useState(''); // default email
  const mediumRef = useRef('')

  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [templateError, setTemplateError] = useState({});


  const [previewMsg, setPreviewMsg] = useState('');

  const [mode, setMode] = useState('');
  const [fileName, setFileName] = useState('');
  const [manualInput, setManualInput] = useState('');

  const [parseResult, setParseResult] = useState({});
  const [list, setList] = useState([]);
  const [validCount, setValidCount] = useState(0);

  const [showUploadConfirmModal, setShowUploadConfirmModal] = useState(false);

  const [sendImmediately, setSendImmediately] = useState(false);
  const [sendOptimize, setSendOptimize] = useState(true);

  // moment(engage.pre_arrival_message_send_at_time, 'HH:mm')
  const [sendAt, setSendAt] = useState('');
  const [sendAtError, setSendAtError] = useState(false);

  const [someChange, setSomeChange] = useState(false);

  const [hasError, setHasError] = useState(false);

  const clearAction = () => {
    const params = {
      location__id: urlParams.locationidentifier,
    };
    dispatch(editDataClearAction({
      op: 'upload_feedback_emails',
      params,
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Run Campaign - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    setSendAt(moment('09:00', 'HH:mm'));
  }, []);

  useEffect(() => {
    const { upload } = props.reputation.feedbacks;
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect feedbacks upload: ', upload);
    }
    if (upload && upload.uploadStatus && upload.uploadStatus.success) {
      setShowUploadConfirmModal(false);
      setMode('');
      setFileName('');
      setManualInput('');
      setParseResult({});
      setList([]);
      setSelectedMedium('');
      setMediumError('error');
      setSelectedTemplate({});
    }
    if (upload && upload.uploadStatus && upload.uploadStatus.error) {
      setShowUploadConfirmModal(false);
    }
  }, [props.reputation.feedbacks]);

  useEffect(() => {
    let msg = selectedTemplate && selectedTemplate._id ? selectedTemplate.msg || '' : '';
    // if (msg.indexOf('{{name}}') > -1 && form.name) msg = replaceAll(msg, '{{name}}', form.name);
    setPreviewMsg(msg);
  }, [selectedTemplate]);

  const onMediumButtonClick = (data) => {
    setSelectedMedium(data.value);
    mediumRef.current = data.value;
    setMediumError('');
    setSomeChange(true);

    setMode('');
    setFileName('');
    setManualInput('');
    setParseResult({});
    setList([]);
  }

  const onTemplateButtonClick = (templateData) => {
    setSelectedTemplate(templateData);
    setTemplateError('');
    setSomeChange(true);
  };

  const onSendImmediatelyCheckBoxChange = (e) => {
    if (sendImmediately) return;
    if (!sendImmediately) setSendOptimize(false);
    setSendImmediately(sendImmediately => !sendImmediately);
  };
  const onSendImmediatelyLabelClick = (e) => {
    if (sendImmediately) return;
    if (!sendImmediately) setSendOptimize(false);
    setSendImmediately(sendImmediately => !sendImmediately);
  };

  const onSendOptimizeCheckBoxChange = (e) => {
    if (sendOptimize) return;
    if (!sendOptimize) setSendImmediately(false);
    setSendOptimize(sendOptimize => !sendOptimize);
  };
  const onSendOptimizeLabelClick = (e) => {
    if (sendOptimize) return;
    if (!sendOptimize) setSendImmediately(false);
    setSendOptimize(sendOptimize => !sendOptimize);
  };

  const onDrop = useCallback((acceptedFiles) => {
    clearAction();
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDrop acceptedFiles: ',acceptedFiles);
      console.log('onDrop selectedMedium: ',mediumRef.current);
      // console.log('rejectedFiles: ',rejectedFiles);
    }
    if (!mediumRef.current) return;
    // Do something with the files
    if (acceptedFiles.length===0) {
      // swal('Oops', 'The file is not valid.', 'error');
      return;
    }
    if (acceptedFiles.length === 1) {
      setMode('dnd');
      setManualInput('');
      // setParseResult({});
      // gtag('event', 'gt_click', {
      //   'event_category': `Feedbacks - Run Campaign - DND File`,
      //   'event_label': `DND File`,
      //   'value': acceptedFiles.length,
      //   user_id: props.userprops.user.uid,
      // });
      acceptedFiles.forEach((file) => {
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            console.log('fileAsBinaryString: ',fileAsBinaryString);
          }
          if (fileAsBinaryString) {
            if (fileAsBinaryString.indexOf(',') === -1) {
              // No commas found
              // swal('Oops', 'Not a CSV ( Comma Seperated File ).', 'warning');

              return;
            }

            let pResult = parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            if (process.env.NODE_ENV !== 'production') {
              console.log('parseResult: ', parseResult);
            }
            setParseResult(pResult);
            // this.parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // this.setState(prevState => ({ dragAndDropActive: true }));

          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsBinaryString(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const parseTextWithEmailAndName = (emailtextarea, splitOn) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('parseTextWithEmailAndName: ',emailtextarea,' # ',splitOn);
    }
    let tlist = [];
    let emailNameHash = {}, missingNamesCount = 0;
    let rowList = [];
    if (splitOn===SPLIT_ON_NEWLINE || splitOn===SPLIT_FOR_CSV) {
      tlist = emailtextarea.split('\n');
    }
    if (splitOn===SPLIT_ON_COMMA) {
      tlist = emailtextarea.split(',');
    }
    tlist.forEach((item, index) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('parseTextWithEmailAndName tlist item: ',item);
      }
      let rowInfo = {};
      //collapse multiple spaces to single space or in case of split on commas, remove the newlines as well if any
      item = item.replace(/\n/g, ' ');
      item = item.replace(/\s\s+/g, ' ');
      //console.log('item: ',item);
      //split on space for name and email
      let tsplit = [];
      if (splitOn===SPLIT_FOR_CSV) {
        tsplit = item.split(',');
      } else {
        tsplit = item.split(' ');
      }
      let email = null, name = null, contact = null;
      tsplit.forEach((titem, tindex) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('parseTextWithEmailAndName titem: ', titem);
        }
        if (titem) { titem = titem.trim(); }
        //check if item is email
        if (titem.indexOf('@')!==-1) {
          //stop at the first one
          if (!email) {
            //check email validity
            let valid = validateEmail(titem);
            //console.log(titem,' # ',valid);
            if (valid) {
              email = titem;
            }
          }
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log('parseTextWithEmailAndName has_feedback_by_sms_upload: ',props.locationInfo.has_feedback_by_sms_upload);
            console.log('parseTextWithEmailAndName selectedMedium::: ',selectedMedium);
            console.log('parseTextWithEmailAndName mediumRef::: ',mediumRef.current);
          }
          if (props.locationInfo.has_feedback_by_sms_upload && mediumRef.current === 'sms') { // only if sms is enabled
            // check for contact number
            let tempStr = titem;
            tempStr = tempStr.replace(/[^\d]/g, ''); // remove all non numbers
            // tempStr = tempStr.replace(/^0+/, ''); // remove leading zeros
            // if (titem[0] === '+') { tempStr = `+${tempStr}`};
            if (process.env.NODE_ENV !== 'production') {
              console.log('parseTextWithEmailAndName tempStr: ',tempStr);
            }
            if (tempStr.length > 0 && // is contact
              (
                tempStr.length === titem.length ||
                tempStr.length >= 9
              )
            ) {
              if (!contact) {
                contact = tempStr;
                // if (contact[0] !== '+') contact = `+${contact}`;
                if (contact.length <= 10) {
                  if (props.locationInfo.isd_code) {
                    contact = `${props.locationInfo.isd_code}${contact}`;
                  } else {
                    contact = `+${contact}`;
                  }
                } else {
                  contact = `+${contact}`;
                }
              }
            } else {
              //If not empty, then it is name, take only the first one
              if (!name && titem) {
                name = titem;
              }
            }
          } else {
            //If not empty, then it is name, take only the first one
            if (!name && titem) {
              name = titem;
            }
          }
        }
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log('parseTextWithEmailAndName name: ',name);
        console.log('parseTextWithEmailAndName email: ',email);
        console.log('parseTextWithEmailAndName contact: ',contact);
      }
      //console.log('name: ',name,' email: ',email);
      //console.log('emailNameHash: ',emailNameHash);
      if (name) name = name.replace('"', '');
      if (email && !(email in emailNameHash)) {
        emailNameHash[email] = name || '';
        if (!name) { missingNamesCount++; }
      } else {
      }
      // if (contact && !(contact in emailNameHash)) {
      //   emailNameHash[contact] = contact || '';
      //   // if (!name) { missingNamesCount++; }
      // }
      if (name) rowInfo['name'] = name;
      if (email) rowInfo['email'] = email;
      if (props.locationInfo.has_feedback_by_sms_upload && mediumRef.current === 'sms') { if (contact) rowInfo['contact'] = contact; }

      if (process.env.NODE_ENV !== 'production') {
        console.log('parseTextWithEmailAndName rowInfo: ',rowInfo);
      }

      if (Object.keys(rowInfo).length) {
        // Object.keys(rowInfo).forEach((item, index) => {
        let temp = {'id': index, 'name': rowInfo['name'] };
        if (mediumRef.current === 'sms') {
          temp['contact'] = rowInfo['contact'];
        } else {
          temp['email'] = rowInfo['email'];
        }
        // temp['email'] = rowInfo['email'];
        // if (props.locationInfo.has_feedback_by_sms_upload) temp['contact'] = rowInfo['contact'];
          rowList.push(temp);
        // });
      }

    });
    // if (Object.keys(emailNameHash).length) {
    //   let tList = [];
    //   Object.keys(emailNameHash).forEach((item, index) => {
    //     tList.push({'id': index, 'name': emailNameHash[item], 'email': item, 'contact': });
    //   });
    //   setList(tList);
    // }
    // return { emailNameHash, emailsProcessed: true, missingNamesCount };
    // if (Object.keys(rowInfo).length) {
    //   let tList = [];
    //   Object.keys(rowInfo).forEach((item, index) => {
    //     tList.push({'id': index, 'name': rowInfo['name'], 'email': rowInfo['email'], 'contact': rowInfo['contact']});
    //   });
    //   setList(tList);
    // }
    if (process.env.NODE_ENV !== 'production') {
      console.log('parseTextWithEmailAndName rowList: ',rowList);
    }
    setList(rowList);
    return { emailNameHash, emailsProcessed: true, missingNamesCount };
  };

  const onManualInputChange = (e) => {
    const { name, value } = e.target;
    setManualInput(value);
    setParseResult({});
    if (!mode || mode === 'dnd') { setMode('manual'); setFileName(''); setParseResult({}); }
    clearAction();
  };
  const onManualProcess = (e) => {
    if (props.isReadOnly) return;
    if (!selectedMedium) {
      // setHasError(true);
      return;
    }

    if (!manualInput) return;
    setMode('manual');
    let pResult = {};
    if (manualInput.indexOf(',')===-1) {
      //No commas, split the newlines
      pResult = parseTextWithEmailAndName(manualInput, SPLIT_ON_NEWLINE);
    } else {
      //split on commas
      pResult = parseTextWithEmailAndName(manualInput, SPLIT_ON_COMMA);
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onManualProcess pResult: ', pResult);
      console.log('onManualProcess parseResult: ', parseResult);
    }
    setParseResult(pResult);
    // gtag('event', 'gt_click', {
    //   'event_category': `Feedbacks - Run Campaign - Manual Process`,
    //   'event_label': `Manual Process`,
    //   'value': '',
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onNameChange = (id, value) => {
    const tList = list.map((item) => {
      if (item.id === id) item.name = value;
      return item;
    });
    setList(tList);
  };
  const onEmailChange = (id, value) => {
    const tList = list.map((item) => {
      if (item.id === id) item.email = value;
      return item;
    });
    setList(tList);
  };
  const onContactChange = (id, value) => {
    const tList = list.map((item) => {
      if (item.id === id) item.contact = value;
      return item;
    });
    setList(tList);
  };
  const onRemoveRowClick = (id) => {
    const tList = list.filter(item => item.id !== id);
    setList(tList);
  };
  const onAddRowClick = (e) => {
    const tList = [ ...list, { id: list.length+1 }];
    setList(tList);
    clearAction();
  };

  const onReset = (e) => {
    setMode('');
    setFileName('');
    setManualInput('');
    setParseResult({});
    setList([]);
    // gtag('event', 'gt_click', {
    //   'event_category': `Feedbacks - Run Campaign - Manual Reset`,
    //   'event_label': `Manual Reset`,
    //   'value': '',
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onUploadClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onUploadClick list: ', list);
    }
    let tempHasError = false;
    if (!selectedMedium) {
      tempHasError = true;
    }
    if (selectedMedium === 'sms') {
      if (!selectedTemplate || !selectedTemplate._id) {
        tempHasError = true;
      }
    }

    let tList = [ ...list ];
    let tCount = 0;

    tList.forEach((item, index) => {
      if (selectedMedium === 'email') {
        if (validateEmail(item.email)) {
          delete item.error;
          tCount++;
        } else {
            item.error = 'Invalid email';
            tempHasError = true;
        }
        if (index > 0) {
          if (tList.slice(0,index).find(el => el.email === item.email)) {
            if (!item.error) {
              item.error = 'Duplicate email';
              tempHasError = true;
            }
          }
        }
      }
      if (selectedMedium === 'sms') {
        if (validateContact(item.contact)) {
          delete item.error;
          tCount++;
        } else {
            item.error = 'Invalid contact';
            tempHasError = true;
        }
        if (index > 0) {
          if (tList.slice(0,index).find(el => el.contact === item.contact)) {
            if (!item.error) {
              item.error = 'Duplicate contact';
              tempHasError = true;
            }
          }
        }
      }
    });
    setHasError(tempHasError);
    setValidCount(tCount);
    setList(tList);
    if (tList.find(el => el.error)) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('onUploadClick error');
        console.log('onUploadClick tList: ', tList);
      }
      return;
    }
    if (tempHasError) return;
    setShowUploadConfirmModal(true);
  };

  const onUploadConfirmCloseModal = (value) => {
    setShowUploadConfirmModal(false);
  };
  const onUploadConfirmClick = (e) => {
    clearAction();
    const params = {
      location__id: urlParams.locationidentifier,
      feedback_emails: list,
    };
    // if (locationidentifier === 'wZYeknJ4QgyGXj7Jm') {
      if (sendImmediately) params.send_immediately = true;
      if (sendOptimize) {
        params.send_optimized = true;
        if (!sendAt) return;
        params.send_at = moment(sendAt).format('HH:mm');
      }
    // }
    if (selectedMedium === 'sms') {
      params.medium = 'sms';
      params.template_id = selectedTemplate._id;
    }
    dispatch(editDataAction({
      op: 'upload_feedback_emails',
      params,
      show_alert: true,
      alert_msg: 'Campaign created successfully',
      show_error_alert: true,
      error_alert_msg: 'Error in creating campaign'
    }));
    // gtag('event', 'gt_click', {
    //   'event_category': `Feedbacks - Run Campaign - Upload`,
    //   'event_label': `Upload`,
    //   'value': list.length,
    //   user_id: props.userprops.user.uid,
    // });
    // setShowUploadConfirmModal(false);
  }

  const onSendAtTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange: ', selectedTime);
      // console.log('onSendAtTimeChange checkInTime: ', props.checkInTime);
    }
    // const checkInTime = moment(props.checkInTime, 'H:mm A');
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('checkInTime: ', checkInTime.format());
    //   console.log('sendBeforeValue: ', sendBeforeValue);
    // }
    // if (sendBeforeValue.value === 'Same Day') {
    //   if (moment(selectedTime, 'hh:mm A').isSameOrBefore(checkInTime)) {
    //     setSendAtError(false);
    //     props.onSendAtErrorChange('');
    //   } else {
    //     setSendAtError(true);
    //     props.onSendAtErrorChange('error');
    //   }
    // }
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('onSendAtTimeChange:: ', new Date(moment(selectedTime, 'hh:mm A')));
    // }
    setSendAt(selectedTime ? new Date(moment(selectedTime, 'hh:mm A')) : null);
    // props.onSendAtTimeChange(moment(selectedTime, 'hh:mm A'));
  };


  // const onDrop = (acceptedFiles, rejectedFiles) => {
  //   // do stuff with files...
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('acceptedFiles: ',acceptedFiles);
  //     console.log('rejectedFiles: ',rejectedFiles);
  //   }
  //
  //   // onDrop
  //
  //   if (acceptedFiles.length===0) {
  //     // swal('Oops', 'The file is not valid.', 'error');
  //     return;
  //   }
  //   if (acceptedFiles.length === 1) {
  //     acceptedFiles.forEach((file) => {
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         const fileAsBinaryString = reader.result;
  //         // do whatever you want with the file content
  //         if (process.env.NODE_ENV !== 'production') {
  //           console.log('fileAsBinaryString: ',fileAsBinaryString);
  //         }
  //         if (fileAsBinaryString) {
  //           if (fileAsBinaryString.indexOf(',') === -1) {
  //             // No commas found
  //             // swal('Oops', 'Not a CSV ( Comma Seperated File ).', 'warning');
  //
  //             return;
  //           }
  //
  //           // this.parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
  //           // this.setState(prevState => ({ dragAndDropActive: true }));
  //
  //         }
  //       };
  //       reader.onabort = () => console.log('file reading was aborted');
  //       reader.onerror = () => console.log('file reading has failed');
  //
  //       reader.readAsBinaryString(file);
  //     });
  //   } else {
  //     // swal('One at a time.', 'Please upload one file at a time.', 'warning');
  //
  //   }
  // }

  // const getFeedbackOverviewData = (startDate, endDate, keyword, positiveNegativeFilter, sourceFilter, pageNumber) => {
  //   if (locationidentifier && props.userprops.user.locations && props.userprops.user.locations.keyValue &&
  //     props.userprops.user.locations.keyValue[locationidentifier]
  //   ) {
  //     props.dispatch(getFeedbackOverviewDataAction({
  //       params: {
  //         location__id: locationidentifier,
  //         start_date: moment(startDate).format('YYYY-MM-DD'),
  //         end_date: moment(endDate).format('YYYY-MM-DD'),
  //         keyword,
  //         positive_negative_filter: positiveNegativeFilter,
  //         source_filter: sourceFilter,
  //         skip: pageSize * (pageNumber - 1) >= 0 ? pageSize * (pageNumber - 1) : 0,
  //         limit: pageSize,
  //       }
  //     }));
  //   }
  // };

  const renderInputForm = (feedbackTextTemplates) => {
    if (!props.locationInfo.has_feedback_by_sms_upload) return;
    const { list, keyValue } = feedbackTextTemplates;
    return (
      <div className="inpFrm">
        <div className="bcRow tmplsRow mediumRow">
          <div className="gLbl">
            Choose medium
          </div>
          <div className="tmplTypes">
            {/* <TemplateButton data={{title: 'SMS', value: 'sms'}} onButtonClick={onMediumButtonClick} selected={selectedMedium === 'sms'} /> */}
            <TemplateButton data={{title: 'Email', value: 'email'}} onButtonClick={onMediumButtonClick} selected={selectedMedium === 'email'} />
          </div>
          {
            mediumError &&
            <div className="gErr">
              <img src={requiredIcon} /> Required
            </div>
          }
        </div>
        {
          selectedMedium === 'sms' &&
          <>
            <div className="bcRow tmplsRow">
              <div className="gLbl">
                Choose review collection type
              </div>
              <div className="tmplTypes">
                {
                  list && list.length && keyValue
                  ? list.map((item) => {
                    if (!keyValue[item]) return null;
                    return (
                      <TemplateButton data={keyValue[item]} onButtonClick={onTemplateButtonClick} selected={selectedTemplate._id === item} />
                    )
                  })
                  : null
                }
              </div>
              {
                templateError &&
                <div className="gErr">
                  <img src={requiredIcon} /> Required
                </div>
              }
            </div>
            <div className="bcRow prvRow">
              <div className="gLbl">
                Message Preview
              </div>
              <div className="prv">
              {
                previewMsg
                ?
                  <>
                    <div className="msg">
                      {previewMsg}
                    </div>
                    <div className="hlp">
                      {"{{feedback_text_link}}"} will be replaced before sending with a review link of the format https://revew.us/XXXXXXXXX
                    </div>
                  </>
                : null
              }
              </div>
              {
                !previewMsg &&
                <div className="gErr">
                  <img src={requiredIcon} /> Type selection required
                </div>
              }
            </div>
          </>
        }
      </div>
    )
  };

  const renderProcessBox = () => {
    // if (!parseResult || !parseResult.emailsProcessed || !parseResult.emailNameHash || !Object.keys(parseResult.emailNameHash).length) return null;
    if (!list || !list.length) return null;

    return (
      <div className="processBox">
        <div className="emailRow header">
          {
            selectedMedium === 'sms'
            ?
            <div className="contact">Contact</div>
            :
            <div className="email">Email</div>
          }
          <div className="name">Name</div>
        </div>
        {
          list.map((item) => {
            return (
              <EmailRow data={item}
                onNameChange={onNameChange}
                onEmailChange={onEmailChange}
                onContactChange={onContactChange}
                onRemove={onRemoveRowClick}
                hasFeedbackBySmsUpload={props.locationInfo.has_feedback_by_sms_upload}
                selectedMedium={selectedMedium}
              />
            );
          })
        }
        <div className="addRow">
          <span onClick={onAddRowClick}>Add Row <span><i className="fa fa-plus" /></span></span>
        </div>
        {
          urlParams.locationidentifier === 'wZYeknJ4QgyGXj7Jm' &&
          <div className="sendWhenBox">
            <div className="entry sendNow">
              <input type="checkbox" name={sendImmediately} onChange={onSendImmediatelyCheckBoxChange} value={sendImmediately ? 'on' : 'off'} checked={sendImmediately} />
              <label onClick={onSendImmediatelyLabelClick}>Send Immediately</label>
            </div>
            <div className="entry sendNow">
              <input type="checkbox" name={sendOptimize} onChange={onSendOptimizeCheckBoxChange} value={sendOptimize ? 'on' : 'off'} checked={sendOptimize} />
              <label onClick={onSendOptimizeLabelClick}>Send at specific time</label>
            </div>
            {
              sendOptimize &&
              <div className="rt">
              {/*<label className="gLbl">Send At</label>*/}
                <CustomTimePicker
                  value={moment(sendAt).format('hh:mm A')}
                  onChange={onSendAtTimeChange}
                  every15Min={true}
                  minAM={8}
                  maxPM={6}
                />
              </div>
            }
          </div>
        }

        {
          hasError &&
          <p className="gErr">Please check above errors!</p>
        }

      {/*<p className="title">Found {list.length} valid emails.</p>*/}
        <div className="buttonBox">
          <button className="gBtn" onClick={onUploadClick}>Upload</button>
          <button className="gBtn cancel" onClick={onReset}><img src={resetIcon} />Reset</button>
        </div>
      </div>
    );
  }


  const renderContent = (feedbacks) => {
    const { reputation } = props.settings;
    if (!reputation) return null;
    const { feedback_text_templates } = reputation;

    let confirmLineTwo = `You want to run feedback campaign with ${list.length} ${list.length > 1 ? 'emails' : 'email'}.`
    if (selectedMedium === 'sms') confirmLineTwo = `You want to run feedback campaign with ${list.length} ${list.length > 1 ? 'contacts' : 'contact'}.`

    return (
      <div className="fbdUplBd">
        <div className="lt">
          <div className="innerBox">
            <div className="topBox">
              <div className="titleBox">
                <h4>Run Campaign{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
              </div>
              {
                props.locationInfo.has_instant_invite &&
                <div className="subTitle">
                  To send instant review request to single guest, use <Link to={`/reputation/locations/${urlParams.locationidentifier}/instant-invite`}>Instant Invite</Link>
                </div>
              }
            </div>
            <div className="contentBox">
              { renderInputForm(feedback_text_templates || {}) }
              <div className="dndBox">
                <p className="title">Drag or Upload</p>
                {
                  props.isReadOnly
                  ?
                  <div className='innerBox'>
                    <img src={uploadImg} />
                    <p>Drag your .CSV file here to upload your list.</p>
                    {
                      fileName && <p><b>Processing {fileName}...</b></p>
                    }
                    <p>Or</p>
                    <p>
                      <div className="gButton"><span>Upload CSV</span></div>
                    </p>
                  </div>
                  :
                  <div {...getRootProps({className: 'innerBox'})}>
                    <input {...getInputProps()} />
                    <img src={uploadImg} />
                    <p>Drag your .CSV file here to upload your list.</p>
                    {
                      fileName && <p><b>Processing {fileName}...</b></p>
                    }
                    <p>Or</p>
                    <p>
                      <div className="gButton"><span>Upload CSV</span></div>
                    </p>
                  </div>
                }
                <p className="sampleCsv"><a href="https://d3hz2dasjf76zw.cloudfront.net/misc/guest-touch-feedback-upload-emails-sample.csv" target="_blank">Sample CSV File</a></p>
                {/*<Dropzone className="dropzone2" onDrop={onDrop}>
                  <div className="innerBox">
                    <p>Drag your .CSV file here to upload your list.  Check sample .CSV file</p>
                    <p>Or</p>
                    <p>
                      <div className="gButton"><span>Upload</span></div>
                    </p>
                  </div>
                </Dropzone>*/}
              </div>
              {
                mode === 'dnd' && renderProcessBox()
              }
              <div className="orBox">
                OR
              </div>
              <div className="manualBox">
                <div className="left">
                  <p className="title">Enter guest details manually</p>
                  <div className="gIpt">
                  <textarea className="gInput" value={manualInput} onChange={onManualInputChange}
                    rows="5"
                  />
                  </div>
                  {
                    mode === 'manual' && renderProcessBox()
                  }
                  {
                    (mode !== 'manual' || (!list || !list.length))
                    ?
                      <div className="buttonBox">
                        <button className="gBtn" onClick={onManualProcess}>Process</button>
                        <button className="gBtn cancel" onClick={onReset}><img src={resetIcon} />Reset</button>
                      </div>
                    : null
                  }
                </div>
                <div className="right">
                  <div className="tipColumn">
                    <img src={tipIcon} />
                  </div>
                  <div className="dataColumn">
                    <p className="dark mb15">Tip:</p>
                    <p className="light mb10">Enter every name value pair on newline:</p>
                    {
                      !props.locationInfo.has_feedback_by_sms_upload
                      ?
                      <>
                        <p>Mark mark@abc.com</p>
                        <p className="mb15">Stacy stacy123@xyz.com</p>
                        <p className="light mb15">OR</p>
                      </>
                      :
                      <>
                        <p>Mark +1XXX-XXX-XXXX</p>
                        <p className="mb15">Stacy +1XXX-XXX-XXXX</p>
                        <p className="light mb15">OR</p>
                      </>
                    }
                    <p className="light mb10">Enter every name value pair separated by comma</p>
                    {
                      !props.locationInfo.has_feedback_by_sms_upload
                      ?
                      <>
                        <p>Mark mark@abc.com, Stacy stacy123@xyz.com</p>
                      </>
                      :
                      <>
                        <p>Mark +1XXX-XXX-XXXX, Stacy +1XXX-XXX-XXXX</p>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
            { showUploadConfirmModal &&
              <GenericAlertModal
                modalType="UploadConfirm"
                title={`Are you sure?`}
                bodyRowOne={confirmLineTwo}
                bodyRowNote=""
                setModalVisible={onUploadConfirmCloseModal}
                onAffirmativeClick={onUploadConfirmClick}
                inProgress={feedbacks && feedbacks.upload ? feedbacks.upload.uploadInProgress : false}
                // currentStatus={guest.checkOutStatus}
                showCancel={true}
              />
            }
          </div>
        </div>
      </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RunCampaign props: ', props);
    console.log('RunCampaign selectedMedium: ', selectedMedium);
  }

  const { feedbacks } = props.reputation;
  if (!feedbacks) return null;


  return (
    <>
      <div className="gPScrlWrp flHt mk">
        <div className="gPg fdbUplPg">
          { renderContent(feedbacks) }
        </div>
      </div>
    </>
  );
}

export default RunCampaign;
