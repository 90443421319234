import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBarInner from '../../SideBarInner';
import WCGeneral from './WCGeneral';
import WCDisplay from './WCDisplay';
import WCHelpGuide from './WCHelpGuide';
import WCChatChannels from './WCChatChannels';
import WCChatChannelsRouter from './WCChatChannels/WCChatChannelsRouter';
import WCRules from './WCRules';
import WCDeploy from './WCDeploy';

import { getDataAction } from '../../../../redux/actions/getDataActions';

function WebChat(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - WebChat - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    console.log('WebChat urlParams: ', urlParams);
    console.log('WebChat locationidentifier: ', urlParams.locationidentifier);
    dispatch(getDataAction({
      op: 'get_webchat_settings',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);

  const onContactUsClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
    try { window.Intercom('showNewMessage', `Enable WebChat for ${locationName}`); } catch(e) {}
  };

  if (!props.locationInfo.has_engage || !props.locationInfo.has_webtext) {
    return (
      <div className="sc">
        <div className="gInf">
          {/* Boost trust, credibility, and conversions with our Reviews Widget! Easily showcase the best guest reviews from trusted sources like Tripadvisor, Google, Expedia, Booking.com, and more. Contact us to enable and effortlessly embed this powerful tool on your website, enhancing your online presence and attracting more bookings.
          <br/>
          <p><a href="https://www.guesttouch.com/reviews-widget-for-website" target="_blank">Learn more</a></p> */}
        </div>
        <div className="gInf">To enable, <span className="gLnk gCurDflt" onClick={onContactUsClick}><b>contact us</b></span>.</div>
      </div>
    );
  }

  const { webchat } = props.settings;

  return (
    <>
      {
        webchat && webchat.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <SideBarInner />
      <div className="sc">
        <Routes>
          <Route path="general" element={<WCGeneral { ...props } />} />
          <Route path="display" element={<WCDisplay { ...props } />} />
          <Route path="help-guide" element={<WCHelpGuide { ...props } />} />
          <Route path="channels/*" element={<WCChatChannelsRouter { ...props } />} />
          <Route path="rules" element={<WCRules { ...props } />} />
          <Route path="deploy" element={<WCDeploy { ...props } />} />
        </Routes>
      </div>
    </>
  );
}

export default WebChat;
