
export const initMarketing = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;

  let marketing = state.locations[location__id] && state.locations[location__id].marketing ? { ...state.locations[location__id].marketing } : {};
  // for transactionEmails
  marketing = {
    ...marketing,
    transactionEmails: marketing.transactionEmails
    ?
      {
        ...marketing.transactionEmails,
        stats: {},
      }
    :
     {
       stats: {},
     },
  };
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: state.locations[location__id]
      ?
        {
          ...state.locations[location__id],
          marketing: {
            ...marketing,
          },
        }
      :
        {
          marketing: {
            ...marketing,
          }
        },
    },
  };
  return state;
};
