import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Group from '../Group';
import GroupDashboard from '../Groups/GroupDashboard';
import Reviews from '../Location/Reviews';
import Sentiments from '../Location/Sentiments';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';

function GroupWrapper() {
  const userState = useSelector((state) => state.userReducer);
  const groupState = useSelector((state) => state.groupReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GroupWrapper urlParams: ', urlParams);
    }
    if (!groupState.groups || !groupState.shared_groups ||
      (groupState.groups && !groupState.groups.getStatus)
    ) {
      dispatch(getDataAction({
        op: 'get_groups',
        params: {},
      }));
    }
  }, []);

  const { groupidentifier } = urlParams;
  if (!groupidentifier) return null;

  if (
    (!groupState.groups || !groupState.groups.keyValue || !groupState.groups.keyValue[groupidentifier]) &&
    (!groupState.shared_groups || !groupState.shared_groups.keyValue || !groupState.shared_groups.keyValue[groupidentifier])
  ) return null;

  let reviews = {};
  let sentiments = {};

  let groupName = '';
  if (groupState.groups.keyValue && groupState.groups.keyValue[groupidentifier]) {
    reviews = groupState.groups.keyValue[groupidentifier].reviews || {};
    sentiments = groupState.groups.keyValue[groupidentifier].sentiments || {};
    groupName = groupState.groups.keyValue[groupidentifier].name || '';
  }
  if (groupState.shared_groups.keyValue && groupState.shared_groups.keyValue[groupidentifier]) {
    reviews = groupState.shared_groups.keyValue[groupidentifier].reviews || {};
    sentiments = groupState.shared_groups.keyValue[groupidentifier].sentiments || {};
    groupName = groupState.shared_groups.keyValue[groupidentifier].name || '';
  }
  if (groupidentifier === 'default') groupName = 'Default';
  // const { sentiments } = groupState.groups.keyValue[groupidentifier];

  // let groupName = groupState.groups && groupState.groups.keyValue && groupState.groups.keyValue[groupidentifier]
  //   ? groupState.groups.keyValue[groupidentifier].name || '' : '';

  return (
    <div className="gCw">
      <Routes>
        <Route path="*" element={<Group />}>
          <Route path="dashboard" element={<GroupDashboard />} />
          {/*<Route path="reviews" element={<Reviews
            locationInfo={{has_reputation_translate: true}}
            reputation={{ reviews: reviews }}
            settings={{}}
            isGroup={true}
            groupName={groupName}
            />}
          />*/}
          <Route path="reviews" element={<Outlet />}>
            <Route path=":reviewstate/*" element={<Reviews
              locationInfo={{has_reputation_translate: true}}
              reputation={{ reviews: reviews }}
              settings={{}}
              isGroup={true}
              groupName={groupName}
              />}
            />
            <Route index element={<Reviews
              locationInfo={{has_reputation_translate: true}}
              reputation={{ reviews: reviews }}
              settings={{}}
              isGroup={true}
              groupName={groupName}
              />}
            />
          </Route>
          <Route path="sentiments" element={<Sentiments
            locationInfo={{has_reputation_translate: true}}
            reputation={{ sentiments: sentiments }}
            settings={{}}
            isGroup={true}
            groupName={groupName}
            />}
          />
          <Route index element={<GroupDashboard />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </div>
  );
}

export default GroupWrapper;
