// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select'
import { Picker } from 'emoji-mart';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import dragIcon from '../../../../../../img/icons/drag.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import './style.css';

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `5px 0 ${grid}px 0`,
  border: '1px solid #e6e6e7',
  borderRadius: 5,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  width: 330
});

function SingleTopic(props) {
  const { topic_id, title, icon, icon_selected, questions_count } = props.data;
  return (
    <div id={`st_${props.data._id}`} className={'roEntry ' + (props.selected ? 'selected' : '')}>
      <div className="left">
        <img className="dragIcon" src={dragIcon} />
        <img src={icon} /> {title}
      </div>
      {/*<div className="right">
        ({questions_count})
      </div>*/}
    </div>
  );
}

function WebChatManageTopicsModal(props) {

  const [items, setItems] = useState(getItems(10));
  const [allItems, setAllItems] = useState(getItems(10));

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [someChange, setSomeChange] = useState(false);


  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const [pickerStyle, setPickerStyle] = useState({
    height: 0,
  });
  const emojiSelectorRef = useRef(null);
  // useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));

  const messageEl = useRef(null);
  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  const setInitialValues = () => {
    const { webchat } = props.settings;
    if (webchat && webchat.help_guide && webchat.help_guide.topics) {
      setItems(webchat.help_guide.topics);
    }
    if (webchat && webchat.help_guide && webchat.help_guide.all_topics) {
      let tItems = [];
      if (webchat.help_guide.all_topics.length) {
        if (webchat && webchat.help_guide && webchat.help_guide.topics && webchat.help_guide.topics.length) {
          webchat.help_guide.all_topics.forEach((item) => {
            let tFound = webchat.help_guide.topics.find(el => el.topic_id === item.topic_id);
            if (!tFound) {
              tItems.push(item);
            }
          });
        } else {
          webchat.help_guide.all_topics.forEach((item) => {
            // let tFound = webchat.help_guide.topics.find(el => el.topic_id === item.topic_id);
            // if (!tFound) {
              tItems.push(item);
            // }
          });
        }
      }
      // setAllItems(webchatSettings.helpGuide.all_topics);
      setAllItems(tItems);
    }
  };

  useEffect(() => {
    // let page_title = `${props.locationprops.location.name} - Settings - Manage Templates`;
    // if (props.template && props.template._id) {
    //   page_title = `${page_title} - Edit - ${props.template.title || ''}`;
    // } else {
    //   page_title = `${page_title} - Add`;
    // }
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title,
    //   user_id: props.userprops.user.uid,
    // });
    // Mousetrap.bind('esc', () => props.onCancelClick(''));
    // return () => {
    //   Mousetrap.unbind('esc');
    // };
  }, []);

  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.editInProgress && props.editStatus && props.editStatus.success) {
      setInitialValues();
      setSomeChange(false);
    }

  }, [props.editInProgress, props.editStatus]);

  const getList = (id) => {
    if (id === 'droppable') return items;
    if (id === 'droppable2') return allItems;
    return [];
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
        return;
    }

    if (source.droppableId !== destination.droppableId) {
      const result = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
      );


      setItems(result.droppable);
      setAllItems(result.droppable2);
    }
    setSomeChange(true);
    props.editClear();
  }


  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onEditClick = (e) => {
    // if (props.editInProgress) return;
    if (someChange) {
      props.onEditConfirmClick(items);
      setSomeChange(false);
    }
  };

  const renderContent = () => {
    const { webchat } = props.settings;
    const cStyle= {};
    cStyle.maxHeight = props.windowHeight*0.9 - 200;

    return (
      <div className="sdMdl wCTpcDnd wCMngTpcDnd" ref={modalRef}>
        <div className="ctnt">
          <div className="gPWrp">
            <div className="gPTle title">
              Manage Topics
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
            </div>
            <div className="gPSubTle title">
              Drag the topics between "Active" and "All" lists, to activate / deactivate them. Only active topics are displayed in Help Guide.
            </div>
            <div className="gBCtnt">
              <div className="dndBox" style={cStyle}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="left">
                    <p>Active topics</p>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {
                            items.map((item, index) => {
                              if (!item) return null;
                              return (
                                <Draggable key={item._id} draggableId={item._id} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <SingleTopic data={item} />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })
                          }
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className="right">
                    <p>All topics</p>
                      <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {
                              allItems.map((item, index) => {
                                if (!item) return null;
                                return (
                                  <Draggable key={item._id} draggableId={item._id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <SingleTopic data={item}/>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })
                            }
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                  </div>
                </DragDropContext>
              </div>
              {
                props.editStatus && (props.editStatus.success || props.editStatus.error) &&
                <div className="gBCtRw curSts">
                  {
                    props.editStatus.error && <span className="gErr">{props.editStatus.message || 'Error in updating'}</span>
                  }
                  {
                    props.editStatus.success && <span className="success">Updated successfully</span>
                  }
                </div>
              }
              <div className="gBCtRw btnRw">
                {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
                <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onEditClick} >
                  Save Changes
                  { props.editInProgress && <i className="fa fa-spinner fa-spin" /> }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('WebChatManageTopicsModal items: ', items);
    console.log('WebChatManageTopicsModal allItems: ', allItems);
  }

  return renderContent();

}

export default WebChatManageTopicsModal;
