import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import JMList from './JMList';
import JMSms from './JMSms';
import JMWhatsapp from './JMWhatsapp';
import JMEmail from './JMEmail';
import JMRuleBased from './JMRuleBased';

import { getUserGAId } from '../../../helpers';

import './style.css';
import JMExpedia from "./JMExpedia";

function JourneyMessages(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Journey Messages - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  if (!userState.user.user_info || !userState.user.user_info.locations || !userState.user.user_info.locations[locationidentifier]) return null;
  const locationInfo = { ...userState.user.user_info.locations[locationidentifier] };

  if (!locationState.locations[locationidentifier]) return null;
  const { settings } = locationState.locations[locationidentifier];
  if (!settings) return null;

  return (
    <>
      <JMList locationInfo={settings.info} settings={settings}/>
      <Routes>
        <Route path="/sms" element={<JMSms locationInfo={locationInfo} settings={settings} {...props} />} />
        <Route path="/whatsapp" element={<JMWhatsapp locationInfo={locationInfo} settings={settings} {...props} />} />
        <Route path="/email" element={<JMEmail locationInfo={locationInfo} settings={settings} {...props} />} />
        <Route path="/expedia" element={<JMExpedia locationInfo={locationInfo} settings={settings} {...props} />} />
        <Route path="/rule-based" element={<JMRuleBased locationInfo={locationInfo} settings={settings} {...props} />} />
      </Routes>
    </>
  );
}

export default JourneyMessages;
