import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import ReactGA from 'react-ga';
import Pusher from 'pusher-js';
import queryString from 'query-string';
import { auth } from '../../firebase';

import WrapperApp from './WrapperApp';
import About from '../About';
import Home from '../Home';
import IntgConfigure from '../IntgConfigure';
import TopBar from '../TopBar';
import Reputation from '../Reputation';
import Engage from '../Engage';
import Marketing from '../Marketing';
import ResponseGenerator from '../ResponseGenerator';
import Settings from '../Settings';
import Profile from '../Settings/Profile';
import Snack from '../Snack';

import setWindowAttributes from '../../redux/actions/windowActions';
import { getDataAction, getDataClearAction } from '../../redux/actions/getDataActions';
import { initEngageAction, initReputationAction, initMarketingAction } from '../../redux/actions/initActions';
import { handleEngagePusherMessage, handleEngageActPusherMessage } from '../../redux/actions/miscEngageActions';
import { setLoggedIn, logout } from '../../redux/actions/miscActions';
import { onSideBarToggle } from '../../redux/actions/appActions';


import { sendEmailAlert, isRGUserOnly, getCategoryIdByGuestKey, hasProductAccess, Encrypt, getUserGAId } from '../../helpers';

import GTLogo from '../../img/gtlogo-white-small.png';

import './Wrapper.css';

const gtconfig = require('../../gtconfig');

// Create pusher
let pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
  cluster: gtconfig.PUSHER_APP_CLUSTER,
  encrypted: true,
});
let channelLocation = null;
let channelAccount = null;
let channelUser = null;

function Wrapper() {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const snackState = useSelector((state) => state.snackReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const [curLocationId, setCurLocationId] = useState('');
  const [curLocationMId, setCurLocationMId] = useState('');

  const [authUserChecking, setAuthUserChecking] = useState(true);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const waitForLogoutTimeoutRef = useRef(false);

  useEffect(() => {
    document.title = `${gtconfig.APP_NAME_CONTACT}`;
    grantNotificationPermission();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper location changed: ', location.pathname);
    }
    // ReactGA.send({ hitType: "pageview", page: location.pathname});
    // gtag('event', 'page_view', {
    //   page_path: `${location.pathname}`,
    //   // page_title,
    //   // user_id: props.userprops.user.uid,
    // });
    window.gtag('config', 'G-VQMKH3VBCL', {
      'user_id': userState.user.uid,
      'gt_user_id': getUserGAId(userState.user),
    });
  }, [location]);


  const onAuthStateChange = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper onAuthStateChange');
    }
    setAuthUserChecking(true);
    return auth.onAuthStateChanged((authUser) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper onAuthStateChanged: ', authUser, ' # ', new Date().getTime()); // comment
      }
      if (authUser) {
        if (waitForLogoutTimeoutRef && waitForLogoutTimeoutRef.current) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('clearTimeout: ', waitForLogoutTimeoutRef.current);
          }
          clearTimeout(waitForLogoutTimeoutRef.current);
        }
        dispatch(setLoggedIn({
          uid: authUser.uid,
          loggedIn: true,
        }));
        setUserLoggedIn(true);
        setTimeout(() => {
          setAuthUserChecking(false);
        }, 500);
      } else {
        setAuthUserChecking(false);
        let waitForTime = 2000;
        // if (props.userprops.user.waitForLogout) {
        //   waitForTime = 3000;
        // }
        if (process.env.NODE_ENV !== 'production') {
          console.log('waitForTime: ', waitForTime);
        }
        waitForLogoutTimeoutRef.current = setTimeout(() => {
          // if (!waitForLogoutRef.current) {
          const next = location.pathname;
          const qparams = location.search;
          let formattedQParams = qparams
          if (qparams) {
            formattedQParams = formattedQParams.replace('?', '');
            formattedQParams = formattedQParams.replaceAll('&', '_-_');
          }
          setUserLoggedIn(false);
          dispatch(logout({}));
          let nextPath = `/login?next=${next}`;
          if (qparams) {
            nextPath = `${nextPath}&qparams=${formattedQParams}`;
          }
          navigate(nextPath);
          dispatch(setLoggedIn({
            uid: '',
            loggedIn: false,
          }));

          // }
        }, waitForTime);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!userState.user || !Object.keys(userState.user).length) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper userState.user missing');
      }
      if (userState.not_allowed) {
        navigate(`/not-allowed`);
      } else {
        navigate(`/login`);
      }
    }
  }, [userState.user]);

  useEffect(() => {
    if (userLoggedIn && userState.user.loggedIn && userState.user.uid) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper location: ', location);
      }
      // if (location.pathname === '/reputation') {
      //   navigate("/abc");
      // }
      dispatch(getDataAction({
        op: 'get_user_info',
        params: {
          uid: userState.user.uid, // : 'neeraj.2197@gmail.com', // nkcheck
        },
      }));
    }
  }, [userLoggedIn, userState.user.loggedIn]);

  useEffect(() => {
    function handleResize() {
      dispatch(setWindowAttributes({
        windowWidth: document.body.clientWidth,
        windowHeight: window.innerHeight,
      }));
      if (!('sideBarOpen' in appState)) {
        dispatch(onSideBarToggle({ open: document.body.clientWidth >= 1024 ? true : false }));
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    try {
      if (userState.user.user_info && userState.user.user_info.uid) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "mydf4dtr",
          name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
          email: userState.user.uid, // the email for your user
          // created_at: user.createdAt, // Signup date as a Unix timestamp
        });
      }
    } catch (e) {}
  }, [userState.user.user_info]);

  useEffect(() => {
    const { pathname } = location;
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper pathname: ', pathname);
      console.log('Wrapper pathname userState: ', userState);
    }
    
    const { customToken } = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper customToken: ', customToken);
    }
    if (customToken && userState.user.user_info && userState.user.user_info.uid) {
      let tstr = '';
      if (userState.user.user_info && userState.user.user_info.uid) {
        tstr = userState.user.user_info.uid;
        tstr = userState.user.user_info.profile ? `${tstr}#${userState.user.user_info.profile.first_name}` || '' : '';
      }
      let eResult = Encrypt("somesalt", tstr);
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper customToken tstr: ', tstr);
        console.log('Wrapper customToken eResult: ', eResult);
      }
      navigate(`/?customTokenNew=${eResult}`); // nkcheck
    }
    // if no pathname, then nudge it to default home
    if (pathname === '/') {

      // check for RG
      if (isRGUserOnly(userState.user)) {
        // navigate(`/response-generator`);
        navigate(`/response-generator/generate`);
        return;
      }


      // navigate(`/?customToken=AAAAA`); // nkcheck
      // check if user has access to single location
      if (userState.user.user_info && userState.user.user_info.locations &&
        Object.keys(userState.user.user_info.locations).length === 1
      ) {
        // check for product access
        let locInfo = {};
        Object.keys(userState.user.user_info.locations).forEach((item) => {
          locInfo = userState.user.user_info.locations[item];
        })
        const hasReputation = locInfo.has_reputation;
        const hasEngage = locInfo.has_engage;
        const hasMarketing = locInfo.has_transaction_emails;
        let tCount = 0;
        if (hasReputation && hasProductAccess(userState.user, locInfo._id, 'reputation')) tCount++;
        if (hasEngage && hasProductAccess(userState.user, locInfo._id, 'engage')) tCount++;
        if (hasMarketing && hasProductAccess(userState.user, locInfo._id, 'marketing')) tCount++;
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper tCount: ', tCount);
        }
        // tCount = 1;
        if (!tCount) { dispatch(logout({ params: { not_allowed: true }})); }
        if (tCount === 1) {
          if (hasReputation) {
            if (hasProductAccess(userState.user, locInfo._id, 'reputation')) {
              navigate(`/reputation/locations/${locInfo._id}/dashboard`);
            }
          }
          if (hasEngage) {
            if (hasProductAccess(userState.user, locInfo._id, 'engage')) {
              navigate(`/engage/locations/${locInfo._id}/messages`);
            }
          }
          if (hasMarketing) {
            if (hasProductAccess(userState.user, locInfo._id, 'marketing')) {
              navigate(`/marketing/locations/${locInfo._id}/overview`);
            }
          }
        }
        if (tCount > 1) {
          navigate(`/home`);
        }
      }
      // if access to many locations - go to /home
      if (userState.user.user_info && userState.user.user_info.locations &&
        Object.keys(userState.user.user_info.locations).length > 1
      ) {
        navigate(`/home`);
      }
    }
    if (pathname && pathname.indexOf('/locations/') > -1) {
      let temp = pathname.split('/locations/')[1];
      if (temp.indexOf('/') > -1) {
        temp = temp.substring(0, temp.indexOf('/'));
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper temp: ', temp);
      }
      if (temp) {
        setCurLocationMId(temp);
        if (userState.user.user_info && userState.user.user_info.locations &&
          userState.user.user_info.locations[temp] && userState.user.user_info.locations[temp].location_id
        )
        setCurLocationId(userState.user.user_info.locations[temp].location_id);
      }
    }
  }, [location.pathname, userState.user.user_info]);

  // initialize products based on access for a location
  // useEffect(() => {
  //   if (curLocationMId) {
  //     if (userState.user.user_info && userState.user.user_info.locations &&
  //       userState.user.user_info.locations[curLocationMId] &&
  //       userState.user.user_info.locations[curLocationMId].location_id
  //     ) {
  //       const { has_engage } = userState.user.user_info.locations[curLocationMId];
  //       if (has_engage) {
  //         dispatch(initEngageAction({
  //           params: {
  //             location__id: curLocationMId,
  //           },
  //         }));
  //       }
  //     }
  //   } else {
  //     // choose a location based on user_info.locations
  //     if (userState.user.user_info && userState.user.user_info.locations &&
  //       Object.keys(userState.user.user_info.locations).length
  //     ) {
  //       if (process.env.NODE_ENV !== 'production') {
  //         console.log('Wrapper curLocationMId not set: ', Object.keys(userState.user.user_info.locations).length);
  //       }
  //       let sLocation = null;
  //       // pick the first one
  //       for(let i = 0; i < Object.keys(userState.user.user_info.locations).length; i++) {
  //         if (userState.user.user_info.locations[Object.keys(userState.user.user_info.locations)[i]]) {
  //           sLocation = userState.user.user_info.locations[Object.keys(userState.user.user_info.locations)[i]];
  //           break;
  //         }
  //       }
  //       if (process.env.NODE_ENV !== 'production') {
  //         console.log('Wrapper sLocation: ', sLocation);
  //       }
  //       // sLocation = null; // nkcheck
  //       if (sLocation) {
  //         if (sLocation.has_reputation) {
  //           dispatch(initReputationAction({
  //             params: {
  //               location__id: sLocation._id,
  //             },
  //           }));
  //           navigate(`/reputation/locations/${sLocation._id}`);
  //         }
  //       } else {
  //         navigate(`/home`);
  //       }
  //     }
  //   }
  // }, [curLocationMId, userState.user.user_info]);


  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect userState.user.getInProgress: ', userState.user.getInProgress);
      console.log('useEffect getUserInfoStatus: ', userState.user.getStatus);
    }
    if (!userState.user.getInProgress && (userState.user.getStatus && userState.user.getStatus.error)) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('error in getUserInfo, get out...');
      }
      dispatch(logout({}));
      // props.history.push('/login');
      // props.dispatch(setLoggedIn({
      //   uid: '',
      //   loggedIn: false,
      // }));
      // setUserLoggedIn(false);
    }
    if (!userState.user.getInProgress &&
      (userState.user.getStatus && userState.user.getStatus.success) // &&
      // props.userprops.appVersion
      // props.userprops.user.user_info
    ) {
      dispatch(getDataClearAction({
        op: 'get_user_info',
        params: {}
      }));
    }
  }, [userState.user.getInProgress]);


  const handlePusherForLocation = (curLocationMId) => {
    // const channelName = `engage-loc-${curLocationId}`; // nkcheck
    const channelName = `gt-unified-loc-${curLocationMId}`; // nkcheck
    // const channelName = `engage-loc-1`; // nkcheck
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper pusher channelName: ', channelName);
    }
    // const channelName = `engage-loc-common`;
    channelLocation = pusher.subscribe(channelName);
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper pusher channelLocation: ', channelLocation);
    }
    pusher.connection.bind('connected', () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher connected');
      }
    });
    pusher.connection.bind('error', (error) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher connection error: ', error);
      }
      setTimeout(() => {
        if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper attempt reconnection');
          }
          pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
            cluster: gtconfig.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
        }
      }, 5000);
    });
    pusher.connection.bind('unavailable', () => {
      setTimeout(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher.connection.state: ', pusher.connection.state);
        }
        if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper attempt reconnection');
          }
          pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
            cluster: gtconfig.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
        }
      }, 5000);
    });
    pusher.connection.bind('failed', () => {
      setTimeout(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher.connection.state: ', pusher.connection.state);
        }
        if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper attempt reconnection');
          }
          pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
            cluster: gtconfig.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
        }
      }, 5000);
    });
    pusher.connection.bind('disconnected', () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher disconnected');
      }
    });

    channelLocation.bind('pusher:subscription_succeeded', () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher:subscription_succeeded: ', curLocationMId);
      }
      // get unread count data if is stale
      // if (process.env.NODE_ENV !== 'production') {
      //   console.log('Wrapper pusher:subscription_succeeded');
      // }
    });
    channelLocation.bind('pusher:subscription_error', (status) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher:subscription_error: ', status);
      }
      // sendEmailAlert({
      //   app: gtconfig.APP_NAME,
      //   subject: `${gtconfig.APP_NAME} - pusher subscription_error error - ${props.userprops.user.uid}`,
      //   message: JSON.stringify({ message: 'pusher subscription_error error' }),
      //   error: JSON.stringify({ status }),
      //   store: JSON.stringify({ userprops: props.userprops, locationprops: props.locationprops }),
      // });
    });
    channelLocation.bind('message', (data) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher data received: ', data);
      }
      const { prd, act } = data;
      if (prd === 'engage') {
        if (!act) {
          processEngagePusherData(data);
        }
      }
    });
    return () => {
      // unsubscribe();
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher pusherChannel unsubscribe / unbind: ', channelLocation);
      }
      // channelLocation.unbind();
      // pusher.unsubscribe();

    };
  };

  // pusher
  useEffect(() => {
    if (curLocationMId) {
      try {
        const profileLocations = userState.user.user_info && userState.user.user_info.profile
            ? userState.user.user_info.profile.locations || {} : {};
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher user profileLocations: ', profileLocations);
        }
        const allLocations = userState.user.user_info ? userState.user.user_info.locations || {} : {};
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher user allLocations: ', allLocations);
        }
        if (Object.keys(profileLocations).length && Object.keys(allLocations).length) {
          Object.keys(profileLocations).forEach((el) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Wrapper pusher user profileLocation el: ', el);
            }
            if (allLocations[el] && allLocations[el].has_engage) {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`Wrapper pusher user profileLocation ${el} has_engage`);
              }
              if (el !== curLocationMId) {
                handlePusherForLocation(curLocationMId);
              }
            }
          });
        }
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher user profileLocations e: ', e);
        }
      }
      handlePusherForLocation(curLocationMId)
    }
  }, [curLocationMId]);


  // pusher - act
  useEffect(() => {
    // const channelName = `engage-loc-${curLocationId}`; // nkcheck
    const channelName = `gt-unified-act-nkact`; // nkcheck
    // const channelName = `engage-loc-1`; // nkcheck
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper pusher channelName: ', channelName);
    }
    // const channelName = `engage-loc-common`;
    channelAccount = pusher.subscribe(channelName);
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper pusher channelAccount: ', channelAccount);
    }
    pusher.connection.bind('connected', () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher connected');
      }
    });
    pusher.connection.bind('error', (error) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher connection error: ', error);
      }
      setTimeout(() => {
        if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper attempt reconnection');
          }
          pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
            cluster: gtconfig.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
        }
      }, 5000);
    });
    pusher.connection.bind('unavailable', () => {
      setTimeout(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher.connection.state: ', pusher.connection.state);
        }
        if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper attempt reconnection');
          }
          pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
            cluster: gtconfig.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
        }
      }, 5000);
    });
    pusher.connection.bind('failed', () => {
      setTimeout(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper pusher.connection.state: ', pusher.connection.state);
        }
        if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper attempt reconnection');
          }
          pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
            cluster: gtconfig.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
        }
      }, 5000);
    });
    pusher.connection.bind('disconnected', () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher disconnected');
      }
    });

    channelAccount.bind('pusher:subscription_succeeded', () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper channelAccount pusher:subscription_succeeded');
      }
      // get unread count data if is stale
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper channelAccount pusher:subscription_succeeded');
      }
    });
    channelAccount.bind('pusher:subscription_error', (status) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper channelAccount pusher:subscription_error: ', status);
      }
      // sendEmailAlert({
      //   app: gtconfig.APP_NAME,
      //   subject: `${gtconfig.APP_NAME} - pusher subscription_error error - ${props.userprops.user.uid}`,
      //   message: JSON.stringify({ message: 'pusher subscription_error error' }),
      //   error: JSON.stringify({ status }),
      //   store: JSON.stringify({ userprops: props.userprops, locationprops: props.locationprops }),
      // });
    });
    channelAccount.bind('message', (data) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper channelAccount pusher data received: ', data);
      }
      const { prd, act } = data;
      if (prd === 'engage') {
        if (act) {
          processActEngagePusherData(data);
        }
      }
    });
    return () => {
      // unsubscribe();
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher pusherChannel channelAccount unsubscribe / unbind: ', channelAccount);
      }
      channelAccount.unbind();
      pusher.unsubscribe();

    };
  }, []);


  const grantNotificationPermission = () => {
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications');
      return;
    }

    if (Notification.permission === 'granted') {
      // new Notification('You are already subscribed to message notifications');
      return;
    }

    if (
      Notification.permission !== 'denied' ||
      Notification.permission === 'default'
    ) {
      try {
        Notification.requestPermission().then(result => {
          if (result === 'granted') {
            new Notification('Awesome! You will start receiving engage notifications shortly');
          }
        });
      } catch (e) {
        Notification.requestPermission((result) => {
          if (result === 'granted') {
            new Notification('Awesome! You will start receiving engage notifications shortly');
          }
        });
      }
    }
  };

  const getUrlForGuest = (message, outside_guest, type) => {
    let notificationClickUrl = null;
    try {
      if (!curLocationMId || !locationState.locations[curLocationMId]) return notificationClickUrl;
      const { engage } = locationState.locations[curLocationMId];
      if (!engage || !engage.guests || !engage.guests.keyValue) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper engage not initialized - getUrlForGuest');
        }
        return notificationClickUrl;
      }

      if (message && type) {
        const { guest_id, outside_guest_id } = message;
        let guestTypeKey = null;
        if (type === 'guest-message-create' && guest_id) {
          if (engage.guests.keyValue[guest_id]) { guestTypeKey = 'guests'; }
          else if (engage.pastGuests.keyValue[guest_id]) { guestTypeKey = 'pastGuests'; }
          else if (engage.upcomingGuests.keyValue[guest_id]) { guestTypeKey = 'upcomingGuests'; }
          if (guestTypeKey && getCategoryIdByGuestKey[guestTypeKey]) {
            notificationClickUrl = `/engage/locations/${curLocationMId}/messages/${getCategoryIdByGuestKey[guestTypeKey]}/${guest_id}`;
          }
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper guest-message-create guestTypeKey: ', guestTypeKey);
        }
        if ((type === 'outside-guest-create' || type === 'outside-guest-message-create') && outside_guest_id) {
          guestTypeKey = 'outsideGuests'; // default
          if (engage.outsideGuests.keyValue[outside_guest_id]) { guestTypeKey = 'outsideGuests'; }
          else if (engage.staff.keyValue[outside_guest_id]) { guestTypeKey = 'staff'; }
          else if (engage.webtext.keyValue[outside_guest_id]) { guestTypeKey = 'webtext'; }
          // check for guestTypeKey based on source
          if (type === 'outside-guest-create') {
            if (outside_guest && outside_guest.is_staff) {
              guestTypeKey = 'staff';
            }
            if (outside_guest && outside_guest.source === 'webtext') {
              guestTypeKey = 'webtext';
            }
          }
          // check for guestTypeKey based on source
          if (type === 'outside-guest-message-create') {
            if (message && message.source === 'webtext') {
              guestTypeKey = 'webtext';
            }
          }
          notificationClickUrl = `/engage/locations/${curLocationMId}/messages/${getCategoryIdByGuestKey[guestTypeKey]}/${outside_guest_id}`;
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper outside-guest-message-create guestTypeKey: ', guestTypeKey);
          console.log('Wrapper notificationClickUrl: ', notificationClickUrl);
        }
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper getUrlForGuest e: ', e);
      }
    }
    return notificationClickUrl;
  }

  const notifyMe = (notificationData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Wrapper notifyMe: ', notificationData);
    }
    const { title, options } = notificationData;
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications');
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper notifyMe Notification: ', Notification);
        console.log('Wrapper notifyMe Notification.permission: ', Notification.permission);
      }
      // Let's check whether notification permissions have already been granted
      if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        // options = { body: msg, tag: id, icon: '/img/gtlogo.png', data: data }
        try {
          var notification = new Notification(title, options);
          notification.onclick = function() {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Wrapper this.data: ',this.data);
            }
            const { message, outside_guest, type } = this.data;
            let notificationClickUrl = getUrlForGuest(message, outside_guest, type);
            if(notificationClickUrl){
              navigate(notificationClickUrl);
            }
            notification.close();
          };
        } catch (e) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Wrapper Notification err: ',e);
          }
        }
      } else {
        // Otherwise, we need to ask the user for permission
        if (Notification.permission !== 'denied') {
          Notification.requestPermission((permission) => {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              try {
                let notification = new Notification(title, options);
                notification.onclick = function() {
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('Wrapper this.data: ',this.data);
                  }
                  const { message, outside_guest, type } = this.data;
                  let notificationClickUrl = getUrlForGuest(message, outside_guest, type);
                  if(notificationClickUrl){
                    navigate(notificationClickUrl);
                  }
                  notification.close();
                };
              } catch (e) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('Wrapper Notification err: ',e);
                }
              }
            }
          });
        }
      }
    }
  };


  const processActEngagePusherData = (data) => {
    try {
      const { type } = data;
      const innerData = data.data;
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper processActEngagePusherData pusher data innerData: ', innerData);
      }
      let rLocationid = '';
      if (innerData) {
        if (innerData.location_id) {
          rLocationid = innerData.location_id;
        } else {
          if (innerData.message && innerData.message.location_id) {
            rLocationid = innerData.message.location_id;
          } else {
            if (innerData.messages && innerData.messages.length && innerData.messages[0].location_id) {
              rLocationid = innerData.messages[0].location_id;
            }
          }
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper processActEngagePusherData rLocationid: ', rLocationid);
        }
        if (rLocationid) {
          if (userState.user.user_info && userState.user.user_info.locations &&
            Object.keys(userState.user.user_info.locations).length > 0
          ) {
            const matchedLoc = Object.keys(userState.user.user_info.locations).find(el => userState.user.user_info.locations[el].location_id === rLocationid);
            if (process.env.NODE_ENV !== 'production') {
              console.log('Wrapper processActEngagePusherData matchedLoc: ', matchedLoc);
            }
            if (matchedLoc) {
              dispatch(handleEngageActPusherMessage({
                ...data,
                location_id: rLocationid,
                location__id: matchedLoc,
              }));
            }
          }
        } else {
          // nkcheck
          // log error
          // console.log('innerData location_id missing');
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleActMessage Error - ${userState.user.uid}`,
            message: JSON.stringify({ data }),
            error: 'innerData location_id missing',
            // store: JSON.stringify({ userprops: userState, locationprops: locationState }),
          });
        }
      } else {
        // console.log('innerData missing');
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleActMessage Error - ${userState.user.uid}`,
          message: JSON.stringify({ data }),
          error: 'innerData missing',
          // store: JSON.stringify({ userprops: userState, locationprops: locationState }),
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper processActEngagePusherData e: ', e);
      }
    }
  };


  const processEngagePusherData = (data) => {
    if (!curLocationId || !curLocationMId) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper curLocationId not set');
      }
      return;
    }
    try {
      if (!locationState.locations[curLocationMId]) return null;
      const { engage } = locationState.locations[curLocationMId];
      if (!engage || !engage.guests || !engage.guests.keyValue) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Wrapper engage not initialized');
        }
        return null;
      }

      const { type } = data;
      const innerData = data.data;
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper pusher data innerData: ', innerData);
      }
      if (innerData) {
        if (innerData &&
          (innerData.location_id || (innerData.message && innerData.message.location_id) ||
            (innerData.messages && innerData.messages.length && innerData.messages[0].location_id)
          )
        ) {
          if (innerData.location_id === curLocationId ||
            (innerData.message && innerData.message.location_id === curLocationId) ||
            (innerData.messages && innerData.messages.length && innerData.messages[0].location_id === curLocationId)
          ) {
            // show notifications only in below cases
            if (type === 'outside-guest-create' ||
              type === 'guest-message-create' ||
              type === 'outside-guest-message-create'
            ) {
              const { message, outside_guest } = innerData;
              if (message) {
                const { msg, name } = message;
                const { _id, location_id, guest_id, outside_guest_id } = message;
                if (process.env.NODE_ENV !== 'production') {
                  console.log('Wrapper guest_id: ', guest_id);
                  console.log('Wrapper outside_guest_id: ', outside_guest_id);
                }
                let notificationClickUrl = null;
                if (message.type === 1 && _id && location_id && (guest_id || outside_guest_id)) {
                  let guestTypeKey = null;
                  if (type === 'guest-message-create' && guest_id) {
                    if (engage.guests.keyValue[guest_id]) { guestTypeKey = 'guests'; }
                    else if (engage.pastGuests.keyValue[guest_id]) { guestTypeKey = 'pastGuests'; }
                    else if (engage.upcomingGuests.keyValue[guest_id]) { guestTypeKey = 'upcomingGuests'; }
                    notificationClickUrl = `/engage/locations/${curLocationMId}/messages/${getCategoryIdByGuestKey[guestTypeKey]}/${guest_id}`;
                  }
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('Wrapper guest-message-create guestTypeKey: ', guestTypeKey);
                  }
                  if ((type === 'outside-guest-create' || type === 'outside-guest-message-create') && outside_guest_id) {
                    guestTypeKey = 'outsideGuests'; // default
                    if (engage.outsideGuests.keyValue[outside_guest_id]) { guestTypeKey = 'outsideGuests'; }
                    else if (engage.staff.keyValue[outside_guest_id]) { guestTypeKey = 'staff'; }
                    else if (engage.webtext.keyValue[outside_guest_id]) { guestTypeKey = 'webtext'; }
                    // check for guestTypeKey based on source
                    if (type === 'outside-guest-create') {
                      if (outside_guest && outside_guest.is_staff) {
                        guestTypeKey = 'staff';
                      }
                      if (outside_guest && outside_guest.source === 'webtext') {
                        guestTypeKey = 'webtext';
                      }
                    }
                    // check for guestTypeKey based on source
                    if (type === 'outside-guest-message-create') {
                      if (message && message.source === 'webtext') {
                        guestTypeKey = 'webtext';
                      }
                    }
                    notificationClickUrl = `/engage/locations/${curLocationMId}/messages/${getCategoryIdByGuestKey[guestTypeKey]}/${outside_guest_id}`;
                  }
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('Wrapper outside-guest-message-create guestTypeKey: ', guestTypeKey);
                    console.log('Wrapper notificationClickUrl: ', notificationClickUrl);
                  }
                  // if (guestTypeKey && getCategoryIdByGuestKey[guestTypeKey]) {
                    const notificationData = {
                      title: name ? `New mesage from ${name}` : 'New Message',
                      options: {
                        body: msg,
                        tag: 'msg_' + message._id,
                        icon: GTLogo,
                        data: {
                          message,
                          outside_guest,
                          type,
                          actionUrl: notificationClickUrl,
                        },
                      },
                    };
                    if (message.medium === 'bm') {
                      notificationData.title = name ? `[Google] New mesage from ${name}` : '[Google] New Message';
                    }
                    notifyMe(notificationData);
                  // }
                }
              } // end of if message
            }

            dispatch(handleEngagePusherMessage({
              ...data,
              locationidentifier: curLocationMId,
            }));
          } else {
            // nkcheck
            // log error
            // console.log('innerData location_id mismatch');
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleMessage Error - ${userState.user.uid}`,
              message: JSON.stringify({ data }),
              error: 'innerData location_id mismatch',
              // store: JSON.stringify({ userprops: userState, locationprops: locationState }),
            });
          }
        } else {
          // nkcheck
          // log error
          // console.log('innerData location_id missing');
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleMessage Error - ${userState.user.uid}`,
            message: JSON.stringify({ data }),
            error: 'innerData location_id missing',
            // store: JSON.stringify({ userprops: userState, locationprops: locationState }),
          });
        }
      } else {
        // console.log('innerData missing');
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleMessage Error - ${userState.user.uid}`,
          message: JSON.stringify({ data }),
          error: 'innerData missing',
          // store: JSON.stringify({ userprops: userState, locationprops: locationState }),
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Wrapper processEngagePusherData e: ', e);
      }
    }
  };


  const renderSnacks = () => {
    if (!snackState.snacks_index) {
      return null;
    }
    const { snacks, snacks_index } = snackState;
    return (
      snacks_index.map((item, index) => <Snack key={item} message={snacks[item]} index={index} snackId={item} />)
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('Wrapper curLocationMId: ', curLocationMId);
    console.log('Wrapper authUserChecking: ', authUserChecking);
    console.log('Wrapper getInProgress: ', userState.user.getInProgress);
  }

  if (authUserChecking || !userLoggedIn) {
    return (
      <div className="gPnlLdng">
        <i className="fa fa-spinner fa-spin" />
      </div>
    );
  }

  // if (userState.user.getInProgress) {
  //   return (
  //     <div className="gPnlLdng">
  //       <i className="fa fa-spinner fa-spin" />
  //     </div>
  //   );
  // }
  if (userState.user.getInProgress) {
    return (
      <div className="gPnlSplLdng">
        <div className="innerWrap">
          <i className="fa fa-spinner fa-spin" />
          <img src={gtconfig.REPUTATION_LOADER_URL} />
        </div>
      </div>
    );
  }

  const { reputation_enabled, engage_enabled, marketing_enabled, response_generator_enabled } = appState.appInfo || {};

  return (
    <div className={'wrp' + (userState.sideBarOpen ? '' : ' sbClsd')}>
      <Routes>
        <Route>
          <Route path="home" element={<TopBar />} />
          { reputation_enabled && <Route path="reputation/*" element={<TopBar />} /> }
          { engage_enabled && <Route path="engage/*" element={<TopBar />} /> }
          { marketing_enabled && <Route path="marketing/*" element={<TopBar />} /> }
          { response_generator_enabled && <Route path="response-generator/*" element={<TopBar />} /> }
          <Route path="settings/*" element={<TopBar />} />
          <Route path="profile/*" element={<TopBar />} />
          <Route path="intg-configure/*" element={<TopBar src={"IntgConfigure"} />} />
        </Route>
      </Routes>
      <Outlet />
      <Routes>
        <Route element={<Outlet />}>
          <Route path="home" element={<Home />} />
          { reputation_enabled && <Route path="reputation/*" element={<Reputation />} /> }
          { engage_enabled && <Route path="engage/*" element={<Engage />} /> }
          { marketing_enabled && <Route path="marketing/*" element={<Marketing />} /> }
          { response_generator_enabled && <Route path="response-generator/*" element={<ResponseGenerator />} /> }
          <Route path="settings/*" element={<Settings />} />
          <Route path="profile/*" element={<Profile />} />
          {/*<Route path="reputation" element={<Outlet />}>
            <Route path="*" element={<Reputation />} />
            <Route index element={<ReputationWrap />} />
          </Route>*/}
          {/*<Route path="abc" element={<Home />} />*/}
          <Route path="intg-configure/*" element={<IntgConfigure />} />
        </Route>
      </Routes>
      { renderSnacks() }
    </div>
  );
}

export default Wrapper;
