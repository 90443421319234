// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import queryString from 'query-string';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';

import { getUserGAId } from '../../../../../../helpers';

import './style.css';

import uploadImg from '../../../../../../img/icons/upload.png';
import searchIcon from '../../../../../../img/icons/search.svg';
import closeIcon from '../../../../../../img/icons/close.svg';
import resetIcon from '../../../../../../img/icons/reset-active.svg';
import tipIcon from '../../../../../../img/icons/bulb.png';
import starIcon from '../../../../../../img/icons/star.png';

const gtconfig = require('../../../../../../gtconfig');


function Home(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const { has_custom_feedback } = props.locationInfo;
    if (has_custom_feedback) {
      navigate(`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/type`);
    }
  }, []);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Home - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const onContactClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
      && locationState.locations[urlParams.locationidentifier].settings
      && locationState.locations[urlParams.locationidentifier].settings.info
      ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
      : '';
    // window.FreshworksWidget('open');
    // window.FreshworksWidget('identify', 'ticketForm', {
    //   name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //   email: userState.user.user_info.uid,
    //   subject: `Enable custom feedback for ${locationName}`,
    // });
    try { window.Intercom('showNewMessage', `Enable custom feedback for ${locationName}`); } catch(e) {}
  }


  const renderContent = () => {
    const { has_custom_feedback } = props.locationInfo;
    const { reputation } = props.settings;
    // if (!reputation || !reputation.feedback_settings) return null;
    // const { feedback_settings } = reputation;

    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="titleBox">
              {
                !has_custom_feedback &&
                <h4>Custom feedback is not enabled. <span className="cstFdbkContact" onClick={onContactClick}>Please contact {gtconfig.APP_NAME_CONTACT} to enable it</span>.</h4>

              }
            </div>
          </div>
          <div className="contentBox">
          </div>
        </div>
    );
  }

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbCustTypPg">
        { renderContent() }
      </div>
    </div>
  );
}

export default Home;
