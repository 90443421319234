export const onSideBarToggle = (payload) => {
  return {
    type: "ON_SIDEBAR_TOGGLE",
    payload,
  };
};
export const setLocationIdentifier = (payload) => {
  return {
    type: "SET_LOCATION_IDENTIFIER",
    payload,
  };
};
export const setGroupIdentifier = (payload) => {
  return {
    type: "SET_GROUP_IDENTIFIER",
    payload,
  };
};
export const setCommonParams = (payload) => {
  return {
    type: "SET_COMMON_PARAMS",
    payload,
  };
};
