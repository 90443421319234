import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import '../style.css';

function RemoteLockRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  let name = template.attributes ? template.attributes.name || '' : '';
  let rooms = [];
  if (props.settings && props.settings.rooms && props.settings.rooms.list &&
    props.settings.rooms.keyValue && props.settings.rooms.list.length) {
    props.settings.rooms.list.forEach((item) => {
      if (template._id === props.settings.rooms.keyValue[item].remote_lock_id) rooms.push(props.settings.rooms.keyValue[item]);
    });
  }
  console.log('rooms: ', rooms);
  return (
    <div className="gBCtRw roomRw" id={`rm_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={name}
              />
            : name
          }
        </div>
        <div className="rt">
          {/* {
            !props.nonEditable &&
            <>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
              <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
            </>
          } */}
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          <span>Mapped to Room: {rooms.map((el) => el.label).join(',')}</span>
        </div>
      </div>
    </div>
  )

}

export default RemoteLockRow;
