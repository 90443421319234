import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function PromptSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const messageEl = useRef(null);
  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  // analytics reporting
  useEffect(() => {
    try {
      let page_title = `Review Response AI - Configure - Prompt Template - Single`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title,
        gt_user_id: userState.user.uid,
        // gt_location_id: urlParams.locationidentifier,
        // gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (props.template && props.template._id) {
      setTitle(props.template.title);
      setMessage(props.template.prompt);
    }
    setSomeChange(false);

    clearData();

    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 100);
  }, []);


  useEffect(() => {
    const { prompt_templates, followup_prompt_templates } = props.rgLocationInfo;
    if (props.type === 'edit') {
      if (props.template._id &&
        prompt_templates && prompt_templates.keyValue &&
        prompt_templates.keyValue[props.template._id] &&
        !prompt_templates.keyValue[props.template._id].editInProgress &&
        prompt_templates.keyValue[props.template._id].editStatus &&
        prompt_templates.keyValue[props.template._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (prompt_templates &&
        !prompt_templates.addInProgress &&
        prompt_templates.addStatus &&
        prompt_templates.addStatus.success
      ) {
        setTitle('');
        setMessage('');
        setSomeChange(false);
      }
    }
  }, [props.rgLocationInfo.prompt_templates]);

  useEffect(() => {
    const { followup_prompt_templates } = props.rgLocationInfo;
    if (props.type === 'edit') {
      if (props.template._id &&
        followup_prompt_templates && followup_prompt_templates.keyValue &&
        followup_prompt_templates.keyValue[props.template._id] &&
        !followup_prompt_templates.keyValue[props.template._id].editInProgress &&
        followup_prompt_templates.keyValue[props.template._id].editStatus &&
        followup_prompt_templates.keyValue[props.template._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (followup_prompt_templates &&
        !followup_prompt_templates.addInProgress &&
        followup_prompt_templates.addStatus &&
        followup_prompt_templates.addStatus.success
      ) {
        setTitle('');
        setMessage('');
        setSomeChange(false);
      }
    }
  }, [props.rgLocationInfo.followup_prompt_templates]);


  const clearData = () => {
    const { prompt_templates, followup_prompt_templates } = props.rgLocationInfo;
    const c_prompt_templates = props.promptType === 'followup' ? followup_prompt_templates : prompt_templates;
    const { templateType } = props;
    if (props.type === 'edit') {
      if (!c_prompt_templates ||
        (c_prompt_templates.keyValue && c_prompt_templates.keyValue[props.template._id] &&
          c_prompt_templates.keyValue[props.template._id].editStatus &&
        !c_prompt_templates.keyValue[props.template._id].editStatus.success)
      ) return;
      const params = {
        mode: props.type,
        rg_location__id: props.rgLocationInfo._id,
      };
      if (props.promptType) params.prompt_type = props.promptType;
      if (props.isRgLocationDefault) params.loc_is_default = true;
      let  op = 'edit_rg_prompt_template';
      params.prompt_template_id = props.template._id;
      dispatch(editDataClearAction({
        op,
        params,
      }));
    }
    if (props.type === 'add') {
      if (!c_prompt_templates || (c_prompt_templates.addStatus && !c_prompt_templates.addStatus.success)) return;
      const params = {
        mode: props.type,
        rg_location__id: props.rgLocationInfo._id,
      };
      if (props.promptType) params.prompt_type = props.promptType;
      if (props.isRgLocationDefault) params.loc_is_default = true;
      let  op = 'edit_rg_prompt_template';
      dispatch(editDataClearAction({
        op,
        params,
      }));
    }
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true);

    clearData();

    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
  }
  const onMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    setSomeChange(true);

    clearData();

    if (!value) {
      setMessageError('error');
    } else {
      setMessageError('');
    }
  };

  const onCloseClick = (e) => {
    props.setModalVisible(false);
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {

    let addEditInProgress = false;

    const { prompt_templates, followup_prompt_templates } = props.rgLocationInfo;
    const c_prompt_templates = props.promptType === 'followup' ? followup_prompt_templates : prompt_templates;

    if (props.type === 'edit') {
      addEditInProgress = c_prompt_templates && c_prompt_templates.keyValue && props.template._id &&
        c_prompt_templates.keyValue[props.template._id]
        ? c_prompt_templates.keyValue[props.template._id].editInProgress || false
        : false;
    }
    if (props.type === 'add') {
      addEditInProgress = c_prompt_templates ? c_prompt_templates.addInProgress || false : false;
    }

    if (addEditInProgress) return;

    clearData();

    setTitleError(title ? '' : 'error');
    setMessageError(message ? '' : 'error');

    if (someChange) {
      if (props.type === 'add') {
        if (title && message) {
          const params = {
            rg_location__id: props.rgLocationInfo._id,
            mode: props.type,
            title,
          };
          if (props.promptType) params.prompt_type = props.promptType;
          if (props.isRgLocationDefault) params.loc_is_default = true;
          let op = 'edit_rg_prompt_template';
          params.prompt = message;

          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
      if (props.type === 'edit') {
        if (title && message) {
          const params = {
            rg_location__id: props.rgLocationInfo._id,
            mode: props.type,
            title,
          };
          if (props.promptType) params.prompt_type = props.promptType;
          if (props.isRgLocationDefault) params.loc_is_default = true;
          let op = 'edit_rg_prompt_template';
          params.prompt_template_id = props.template._id;
          params.prompt = message;
          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
    }
  };

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      // if (props.disableOutsideClick) {
      //   // props.setModalVisible(false);
      // } else {
      //   props.setModalVisible(false);
      // }
    }
  };


  const renderContent = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('PromptSingle props.promptType: ', props.promptType);
    }
    let tData = {};
    const { prompt_templates, followup_prompt_templates } = props.rgLocationInfo;
    const c_prompt_templates = props.promptType === 'followup' ? followup_prompt_templates : prompt_templates;
    if (props.type === 'edit') {
      if (!c_prompt_templates || !c_prompt_templates.keyValue) return null;
      tData = c_prompt_templates.keyValue[props.template._id] || {};
    }

    let pageTitle = '';
    if (props.type === 'add') {
      pageTitle = `Add guestAI™ Template`;
      if (props.promptType === 'followup') pageTitle = `Add Follow Up Template`;
    }
    if (props.type === 'edit') {
      pageTitle = 'Edit guestAI™ Template';
      if (props.promptType === 'followup') pageTitle = `Edit Follow Up Template`;
    }
    if (props.title) pageTitle = props.title;


    let addEditInProgress = false;
    let addEditStatus = {};

    addEditInProgress = c_prompt_templates ? c_prompt_templates.addInProgress : false;
    addEditStatus = c_prompt_templates ? c_prompt_templates.addStatus : {};

    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    if (process.env.NODE_ENV !== 'production') {
      console.log('PromptSingle c_prompt_templates: ', c_prompt_templates);
      console.log('PromptSingle addEditStatus: ', addEditStatus);
    }


    return (
      <div className="gPWrp">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Title
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" ref={titleRef} />
                { titleError && <p className="gErr">Title is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw messageRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Message
              </div>
              <div className={'gIpt ' + (messageError ? 'error' : '')}>
                <textarea rows="4" placeholder="Message"
                  onChange={onMessageChange}
                  placeholder="Message..."
                  value={message}
                  ref={messageEl}
                />
                { messageError && <p className="gErr">Message is required</p> }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('PromptSingle props: ', props);
  }

  return (
    <Modal>
      <div id="cModelId" className="cModal" onClick={onModalClick}>
        <div className="content">
          <div className={'sdMdl ' + (props.className)}>
            <div className="ctnt">
            { renderContent() }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  // return renderContent();
}

export default PromptSingle;
