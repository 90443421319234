import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';

import ToolTipWrapper from '../../Misc/ToolTipWrapper';
import NumberCard from '../../Misc/NumberCard';
import MixedCard from '../../Misc/MixedCard';
import EngageUsageChart from '../../Reputation/Charts/EngageUsageChart';

import { setCommonParams } from '../../../redux/actions/appActions';
import { getDataAction } from '../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS_MAP, OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  renderOTALogo, getUserGAId } from '../../../helpers';

import './style.css';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

function EngageUsage(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const getData = (startDate, endDate) => {
    dispatch(getDataAction({
      op: 'get_engage_usage',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      },
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Usage - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Usage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    if (process.env.NODE_ENV !== 'production') {
      console.log('EngageUsage commonParams: ', commonParams);
    }
    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
    getData(startDate, endDate);

  }, []);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
        },
      }));
      getData(new Date(moment().subtract(period, 'days')), new Date());
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
        },
      }));
      getData(new Date(moment().subtract(period, 'month')), new Date());
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Usage - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Usage - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier,
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { usage } = props.engage;
    if (!usage || usage.getInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      },
    }));
    getData(dates[0], dates[1]);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Usage - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Usage - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier,
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };


  const renderDateFilterRow = (statsObj) => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Usage
          </div>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSplLoader = () => <span><i className="fa fa-circle-notch fa-spin" /></span>;

  const renderStatsBox = (usage) => {
    if (!usage) return null;
    const { locationidentifier } = urlParams;
    return (
      <>
        <div className="dshStatBx">
          <NumberCard
            data={{
              label: 'Total Messages',
              value: usage.getInProgress ? renderSplLoader() : usage.total_messages,
              labelToolTip: 'Total Messages',
              // ctaLabel: 'See All',
              // ctaLink: `/reputation/locations/${locationidentifier}/reviews`,
            }}
          />
          <NumberCard
            data={{
              label: 'Total Messages Sent',
              value: usage.getInProgress ? renderSplLoader() : usage.total_sent_messages,
              labelToolTip: 'Total Messages Sent for the selected period',
            }}
          />
          <NumberCard
            data={{
              label: 'Total Messages Received',
              value: usage.getInProgress ? renderSplLoader() : usage.total_received_messages,
              labelToolTip: 'Total messages received for the selected period',
            }}
          />
        </div>
        <div className="dshStatBx">
        <NumberCard
          data={{
            adClass: 'max-50',
            label: 'Total Conversations',
            value: usage.getInProgress ? renderSplLoader() : usage.total_conversations,
            labelToolTip: 'Total conversations for the selected period',
          }}
        />
        </div>
      </>
    );
  };

  const renderStatsSecondaryBox = (usage) => {
    if (!usage) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="dshStatSecBx">
        <MixedCard
          data={{
            adClass: 'max-50',
            label: 'Average number of segments per sent message',
            value: usage.getInProgress ? renderSplLoader() : usage.avg_segments_per_message,
            labelToolTip: 'Average number of segments per sent message for the selected period',
            warningToolTip: usage.avg_segments_per_message >= 3 ? 'Too many segments per sent message, messaging is charged based on number of segments' : '',
            // ctaLabel: 'See All',
            // ctaLink: `/reputation/locations/${locationidentifier}/feedbacks/overview?positive_negative=negative`,
          }}
        />
        {/*<MixedCard
          data={{
            label: 'Review widget impressions',
            value: usage.getInProgress ? renderSplLoader() : usage.review_widget_impressions,
            labelToolTip: 'Review widget impressions',
          }}
        />*/}
      </div>
    );
  };

  const renderLargeMessages = (usage) => {
    if (!usage || !usage.large_messages || !usage.large_messages.length) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="engUsgLgMsgBx">
        <div className="ttl">Messages with many segments
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay="Messages with many segments"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </div>
        <div className="subTle">Message with fewer segmants have better Deliverability. For best results, keep number of segments under 3.</div>
        {
          usage.large_messages.map((item) => {
            return (
              <div className="entry">
                <p className="numSegs">Number of segments: {item.number_of_segments}</p>
                <p>{item.msg}</p>
              </div>
            )
          })
        }
      </div>
    );
  };

  const renderChart = (usage) => {
    if (!usage || !usage.trend_data) return null;
    return (
      <div className="chartBox">
        <EngageUsageChart
          windowState={windowState}
          width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
          height={160}
          data={usage.trend_data}
          commChannels={props.commChannels}
          // hideRating={!ratingFilterValues['all']}
          // showOnlyDaily={dateRangeLastMonthValue['value'] === 'last-7-days' || dateRangeLastMonthValue['value'] === 'last-15-days'}
        />
      </div>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('Usage props: ', props);
  }

  const { usage } = props.engage;
  if (!usage) return null;


  return (
    <>
      {
        usage.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg">
          { renderDateFilterRow() }
          { renderChart(usage) }
          { renderStatsBox(usage) }
          { renderStatsSecondaryBox(usage) }
          { renderLargeMessages(usage) }
        </div>
      </div>
    </>
  );
}

export default EngageUsage;
