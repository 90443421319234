import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBarInner from '../../../SideBarInner';
import ResponseTemplates from '../../ResponseTemplates';
import ResponseTemplateCategories from '../../ResponseTemplateCategories';

import { getDataAction } from '../../../../../redux/actions/getDataActions';

function ResponseTemplatesHome(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Response Templates - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  return (
    <>
      <>
        <SideBarInner />
        <div className="sc">
          <Routes>
            <Route path="templates" element={<ResponseTemplates { ...props } />} />
            <Route path="categories" element={<ResponseTemplateCategories { ...props } />} />
          </Routes>
        </div>
      </>
    </>
  );
}

export default ResponseTemplatesHome;
