
import React, { useState, useEffect, useRef, useCallback } from 'react';

import './style.css';

function CopyTemplateModal(props) {

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const modalRef = useRef();

  useEffect(() => {
    setName(props.name);
  }, []);

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onConfirmClick = (e) => {
    setNameError(name.trim() ? '' : 'error');
    if (!name.trim()) {
      return;
    }
    props.onConfirmClick(name.trim());
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    let tValue = value.trimLeft().replace(/[^\w\s]/gi, '');
    setName(tValue);
    setNameError(tValue ? '' : 'error');
  };


  const renderContent = () => {
    return (
      <div className="cTModal copyTemplate" ref={modalRef}>
        <div className="title">
          Enter name for new template
        </div>
        <div className="content">
          <div className="nameRow">
            <div className="itemBox">
              <div className="gLbl">
                Name
              </div>
              <div className="gIpt">
                <input type="text" placeholder="Please enter new name" name="name" value={name} onChange={onChange} />
              </div>
            </div>
          </div>
          {
            nameError &&
            <div className="byOneRow error">Required</div>
          }
          {/*
            props.checkInStatus && (props.checkInStatus.success || props.checkInStatus.error) &&
            <div className="currentStatus">
              {
                props.checkInStatus.error && <p className="error">{props.checkInStatus.message}</p>
              }
            </div>
          */}
          <div className="saveRow">
            <button className="cancel" onClick={onCancelClick}>Cancel</button>
            <button type="submit" className="ok" onClick={onConfirmClick} >
              Make a copy
              { props.copyInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  };

  return renderContent();

}

export default CopyTemplateModal;
