import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { isGTUser, hasProductAccess } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';

import '../style.css';


function HostfullyInitiate(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  let aLink = `#`;

  return (
    <div className="gPScrlWrp flHt allHme">
      <div className="gPWrp homeAllLocsPg">
        <div className="gPTle">
          Initiate Hostfully Integration
        </div>
        <div className="gBCtnt">
          <a href={aLink}>Initiate</a>
        </div>
      </div>
    </div>
  );

}

export default HostfullyInitiate;
