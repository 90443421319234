// @flow

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';

import './style.css';

const initialValue = {};
initialValue['Sunday'] = {
  isActive: true,
  startTime: '8:00 AM',
  endTime: '6:00 PM',
};
initialValue['Monday'] = {
  isActive: false,
  startTime: '8:00 AM',
  endTime: '6:00 PM',
};

const allDays = [];
allDays.push('Sunday');
allDays.push('Monday');
allDays.push('Tuesday');
allDays.push('Wednesday');
allDays.push('Thursday');
allDays.push('Friday');
allDays.push('Saturday');

const startTimeOptions = [];
startTimeOptions.push({ label: 'Select', value: 'select' });
startTimeOptions.push({ label: '24 hours', value: '24 hours' });
startTimeOptions.push({ label: '12:00 AM', value: '12:00 AM' });
startTimeOptions.push({ label: '12:30 AM', value: '12:30 AM' });
startTimeOptions.push({ label: '1:00 AM', value: '1:00 AM' });
startTimeOptions.push({ label: '1:30 AM', value: '1:30 AM' });
startTimeOptions.push({ label: '2:00 AM', value: '2:00 AM' });
startTimeOptions.push({ label: '2:30 AM', value: '2:30 AM' });
startTimeOptions.push({ label: '3:00 AM', value: '3:00 AM' });
startTimeOptions.push({ label: '3:30 AM', value: '3:30 AM' });
startTimeOptions.push({ label: '4:00 AM', value: '4:00 AM' });
startTimeOptions.push({ label: '4:30 AM', value: '4:30 AM' });
startTimeOptions.push({ label: '5:00 AM', value: '5:00 AM' });
startTimeOptions.push({ label: '5:30 AM', value: '5:30 AM' });
startTimeOptions.push({ label: '6:00 AM', value: '6:00 AM' });
startTimeOptions.push({ label: '6:30 AM', value: '6:30 AM' });
startTimeOptions.push({ label: '7:00 AM', value: '7:00 AM' });
startTimeOptions.push({ label: '7:30 AM', value: '7:30 AM' });
startTimeOptions.push({ label: '8:00 AM', value: '8:00 AM' });
startTimeOptions.push({ label: '8:30 AM', value: '8:30 AM' });
startTimeOptions.push({ label: '9:00 AM', value: '9:00 AM' });
startTimeOptions.push({ label: '9:30 AM', value: '9:30 AM' });
startTimeOptions.push({ label: '10:00 AM', value: '10:00 AM' });
startTimeOptions.push({ label: '10:30 AM', value: '10:30 AM' });
startTimeOptions.push({ label: '11:00 AM', value: '11:00 AM' });
startTimeOptions.push({ label: '11:30 AM', value: '11:30 AM' });
startTimeOptions.push({ label: '12 Noon', value: '12 Noon' });
startTimeOptions.push({ label: '12:30 PM', value: '12:30 PM' });
startTimeOptions.push({ label: '1:00 PM', value: '1:00 PM' });
startTimeOptions.push({ label: '1:30 PM', value: '1:30 PM' });
startTimeOptions.push({ label: '2:00 PM', value: '2:00 PM' });
startTimeOptions.push({ label: '2:30 PM', value: '2:30 PM' });
startTimeOptions.push({ label: '3:00 PM', value: '3:00 PM' });
startTimeOptions.push({ label: '3:30 PM', value: '3:30 PM' });
startTimeOptions.push({ label: '4:00 PM', value: '4:00 PM' });
startTimeOptions.push({ label: '4:30 PM', value: '4:30 PM' });
startTimeOptions.push({ label: '5:00 PM', value: '5:00 PM' });
startTimeOptions.push({ label: '5:30 PM', value: '5:30 PM' });
startTimeOptions.push({ label: '6:00 PM', value: '6:00 PM' });
startTimeOptions.push({ label: '6:30 PM', value: '6:30 PM' });
startTimeOptions.push({ label: '7:00 PM', value: '7:00 PM' });
startTimeOptions.push({ label: '7:30 PM', value: '7:30 PM' });
startTimeOptions.push({ label: '8:00 PM', value: '8:00 PM' });
startTimeOptions.push({ label: '8:30 PM', value: '8:30 PM' });
startTimeOptions.push({ label: '9:00 PM', value: '9:00 PM' });
startTimeOptions.push({ label: '9:30 PM', value: '9:30 PM' });
startTimeOptions.push({ label: '10:00 PM', value: '10:00 PM' });
startTimeOptions.push({ label: '10:30 PM', value: '10:30 PM' });
startTimeOptions.push({ label: '11:00 PM', value: '11:00 PM' });
startTimeOptions.push({ label: '11:30 PM', value: '11:30 PM' });

const endTimeOptions = startTimeOptions.slice(2);

const checkOutTimes = [];
checkOutTimes.push({ label: '10:00 AM', value: '10:00 AM' });
checkOutTimes.push({ label: '10:30 AM', value: '10:30 AM' });
checkOutTimes.push({ label: '11:00 AM', value: '11:00 AM' });
checkOutTimes.push({ label: '11:30 AM', value: '11:30 AM' });
checkOutTimes.push({ label: '12 Noon', value: '12 Noon' });
checkOutTimes.push({ label: '12:30 PM', value: '12:30 PM' });
checkOutTimes.push({ label: '1:00 PM', value: '1:00 PM' });

function BHRow(props) {
  const [dayActive, setDayActive] = useState(false);
  const [startTimeRange, setStartTimeRange] = useState([]);
  const [startTimeValue, setStartTimeValue] = useState({});
  const [endTimeRange, setEndTimeRange] = useState([]);
  const [endTimeValue, setEndTimeValue] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    // console.log('props.data: ', props.data);
    if (props.data && props.data.isActive) {
      setDayActive(true);
      let tempStartTime = null;
      if (props.data.startTime) {
        tempStartTime = { label: props.data.startTime, value: props.data.startTime };
      } else {
        tempStartTime = { label: 'Select', value: 'select' };
      }
      setStartTimeValue(tempStartTime);

      let tList = [];
      startTimeOptions.forEach((item) => {
        if (item.value !== '24 hours' && item.value !== 'select') {
          let a = moment(item.value, 'h:mm A');
          let b = moment(tempStartTime.value, 'h:mm A');
          if (moment(item.value, 'h:mm A') > moment(tempStartTime.value, 'h:mm A')) {
            tList.push(item);
          }
        }
      });
      setEndTimeRange(tList);

      if (props.data.endTime) {
        setEndTimeValue({ label: props.data.endTime, value: props.data.endTime });
      } else {
        setEndTimeValue({ label: 'select', value: 'select' });
      }
    }
  }, [props.data]);

  // useEffect(() => {
  //   let tList = [];
  //   startTimeOptions.forEach((item) => {
  //     if (item.value !== '24 hours') {
  //       let a = moment(item.value, 'h:mm A');
  //       let b = moment(startTimeValue.value, 'h:mm A');
  //       console.log('item: ', item);
  //       console.log('a: ', a);
  //       console.log('b: ', b);
  //       if (moment(item.value, 'h:mm A') > moment(startTimeValue.value, 'h:mm A')) {
  //         tList.push(item);
  //         console.log('pushed: ', item);
  //       }
  //     }
  //   });
  //   console.log('pushed tList: ', tList);
  //   setEndTimeRange(tList);
  //   if (tList.length && !props.endTime) setEndTimeValue(tList[0]);
  // }, [startTimeValue]);

  const dayToggle = () => {
    if (!dayActive) {
      if (!startTimeValue.value || startTimeValue.value === 'select') {
        setError('Hours are required.');
      }
    } else {
      setError('');
    }
    props.onActiveChange(props.name, !dayActive);
    setDayActive(dayActive => !dayActive);
  }

  const onDayActiveChange = (e) => {
    dayToggle();
  };
  const onDayActiveLabelClick = (e) => {
    dayToggle();
  };
  const onStartTimeChange = (value) => {
    props.onStartTimeChange(props.name, value.value);
    setStartTimeValue(value);
    let tList = [];
    startTimeOptions.forEach((item) => {
      if (item.value !== '24 hours') {
        if (moment(item.value, 'h:mm A') > moment(value.value, 'h:mm A')) {
          tList.push(item);
        }
      }
    });
    setEndTimeRange(tList);
    if (value.value === '24 hours') {
      setError(''); return;
    }
    if (value.value === 'select' || !endTimeValue.value || endTimeValue.value === 'select') {
      setError('Closing hours are required.'); return;
    }
    if (value.value !== 'select' && endTimeValue.value !== 'select') {
      setError('');
    }
  };
  const onEndTimeChange = (value) => {
    props.onEndTimeChange(props.name, value.value);
    setEndTimeValue(value);
    if (value.value === 'select') {
      setError('Closing hours are required.'); return;
    } else {
      setError('');
    }
  };

  return (
    <div className="bhRow">
      <div className="top">
        <div className="dayBox">
          <input type="checkbox" name="dayActive" onChange={onDayActiveChange} value={dayActive ? 'on' : 'off'} checked={dayActive} />
          <div className="gLabel" onClick={onDayActiveLabelClick}>
            {props.name}
          </div>
        </div>
        {
          !dayActive &&
          <div className="closed">Closed</div>
        }
        {
          dayActive &&
          <div className="startTimeBox">
            <Select
              options={startTimeOptions}
              onChange={onStartTimeChange}
              value={startTimeValue}
            />
          </div>
        }
        {
          dayActive && startTimeValue.value !== '24 hours' &&
          <div className="endTimeBox">
            <Select
              options={endTimeRange}
              onChange={onEndTimeChange}
              value={endTimeValue}
            />
          </div>
        }
      </div>
      {
        error &&
        <div className="gErr">
          {error}
        </div>
      }
    </div>
  );
}

function BusinessHours(props) {

  const [checkInTimeValue, setCheckInTimeValue] = useState([
    { label: '1:00 PM', value: '1:00 PM' }
  ]);
  const [checkOutTimeValue, setCheckOutTimeValue] = useState([
    { label: '11:00 AM', value: '11:00 AM' }
  ]);

  const [allDaysValue, setAllDaysValue] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - Settings - Hotel Details`,
    //   user_id: props.userprops.user.uid,
    // });
    setAllDaysValue(props.businessHours);
  }, [props.businessHours]);

  const onActiveChange = (day, value) => {
    const temp = { ...allDaysValue };
    if (!temp[day]) {
      temp[day] = {
        isActive: true,
      };
    } else {
      temp[day].isActive = value;
    }
    setAllDaysValue(temp);
    props.onBusinessHoursChange(temp);
  };
  const onStartTimeChange = (day, value) => {
    const temp = { ...allDaysValue };
    if (!temp[day]) {
      temp[day] = {
        isActive: true,
        startTime: value,
      };
    } else {
      temp[day].startTime = value;
    }
    setAllDaysValue(temp);
    props.onBusinessHoursChange(temp);
  };
  const onEndTimeChange = (day, value) => {
    const temp = { ...allDaysValue };
    if (!temp[day]) {
      temp[day] = {
        isActive: true,
        endTime: value,
      };
    } else {
      temp[day].endTime = value;
    }
    setAllDaysValue(temp);
    props.onBusinessHoursChange(temp);
  };


  const renderContent = () => {
    return (
      <div className="businessHours">
        {/* renderCheckInOutTimes() */}
        {
          allDays.map((day) => {
            return <BHRow
              key={`bhrow_${day}`}
              name={day}
              data={allDaysValue[day]}
              onActiveChange={onActiveChange}
              onStartTimeChange={onStartTimeChange}
              onEndTimeChange={onEndTimeChange}
            />;
          })
        }
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('allDaysValue: ', allDaysValue);
  }

  return renderContent();

}

export default BusinessHours;
