import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Pagination from 'rc-pagination';

import ToolTipWrapper from '../../Misc/ToolTipWrapper';
import TransactionEmailTypeStatusCard from '../../Misc/TransactionEmailTypeStatusCard';
import EmailSendingStatDetailCard from '../../Misc/EmailSendingStatDetailCard';
import useDebounce from '../../Misc/DebounceHook';
import SingleHistoryBox from './SingleHistoryBox';

import { setCommonParams } from '../../../redux/actions/appActions';
import { getDataAction } from '../../../redux/actions/getDataActions';
import { initMarketingAction } from '../../../redux/actions/initActions';

import { DATE_DURATION_OPTIONS_MAP } from '../../../helpers';

import refreshIcon from '../../../img/icons/refresh-active.svg';

import './style.css';
import 'antd/dist/antd.css';
import "rc-pagination/assets/index.css";

const { RangePicker } = DatePicker;

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

const perPageSelectOptions = [];
perPageSelectOptions.push({ label: '10', value: 10 });
perPageSelectOptions.push({ label: '25', value: 25 });
perPageSelectOptions.push({ label: '50', value: 50 });
perPageSelectOptions.push({ label: '100', value: 100 });
perPageSelectOptions.push({ label: '200', value: 200 });

function History(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [keyword, setKeyword] = useState('');
  const debouncedSearchTerm = useDebounce(keyword, 500);

  const [filteredList, setFilteredList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [emailTypeOptions, setEmailTypeOptions] = useState([]);
  const [emailTypeValue, setEmailTypeValue] = useState(
    { label: 'All', value: 'all' }
  );

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [perPageSelectValue, setPerPageSelectValue] = useState(
    { label: '10', value: 10 }
  );

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Marketing - Transaction Emails - History - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const geHistoryData = (emailType, startDate, endDate, searchKeyword, pSize, pageNum = 1) => {
    dispatch(getDataAction({
      op: 'get_response_generator_history',
      params: {
        // location__id: urlParams.locationidentifier,
        keyword: searchKeyword,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        skip: pSize * (pageNum - 1),
        limit: pSize,
      },
    }));
  };

  useEffect(() => {
    const tOptions = [];
      tOptions.push({ label: 'All', value: 'all' });
      tOptions.push({ label: 'Booking', value: 'booking' });
      tOptions.push({ label: 'Pre-Arrival', value: 'pre-arrival' });
      tOptions.push({ label: 'Check-In', value: 'check-in' });
      tOptions.push({ label: 'Check-Out', value: 'check-out' });
      setEmailTypeOptions(tOptions);

      const { commonParams } = appState;
      let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
      let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
      let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
      setDateFilterStartDate(startDate);
      setDateFilterEndDate(endDate);
      setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
      geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize);
  }, []);

  useEffect(() => {
    const { history } = responseGeneratorState;
    if (history &&
      !history.getInProgress
    ) {
      setFilteredList(history.list);
      setTotalCount(history.total_count || 0);
    }
  }, [responseGeneratorState.history]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, debouncedSearchTerm, pageSize, 1);
    }
  }, [debouncedSearchTerm]);


  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
        },
      }));
      geHistoryData(emailTypeValue.value || 'all', new Date(moment().subtract(period, 'days')), new Date(), keyword, pageSize);
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
        },
      }));
      geHistoryData(emailTypeValue.value || 'all', new Date(moment().subtract(period, 'month')), new Date(), keyword, pageSize);
    }
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { transactionemailprops } = props;
    if (!transactionemailprops || transactionemailprops.getStatsInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      },
    }));
    geHistoryData(emailTypeValue.value || 'all', dates[0], dates[1], keyword, pageSize);
  };

  const onEmailTypeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmailTypeChange: ', value);
    }
    setPageNumber(1);
    setEmailTypeValue(value);
    geHistoryData(value.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize);
  };

  const onPerPageSelectChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPerPageSelectChange: ', value);
    }
    setPageNumber(1);
    setPerPageSelectValue(value);
    setPageSize(value.value);
    geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, value.value, 1);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
    if (!value) {
      geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, '', pageSize, 1);
    }
  };

  const onFilterTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
      geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, '', pageSize, 1);
    }
  };

  const onPageChange = (page) => {
    setPageNumber(page);
    geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize, page);
  };
  const onPaginationShowSizeChange = (current, pageSize) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPaginationShowSizeChange: ', current, ' # ', pageSize);
    }
  };

  const onRefreshClick = () => {
    geHistoryData(emailTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize, 1);
  };

  const renderPagination = (keyValue) => {
    if (!filteredList || !filteredList.length) return null;
    // let totalCount = 0;
    // totalCount = filteredList.length || 0;
    if (process.env.NODE_ENV !== 'production') {
      console.log('totalCount final: ', totalCount);
    }
    if (!totalCount) return null;
    return (
      <>
        <div className="rpPagination">
          <Pagination
            onChange={onPageChange}
            current={pageNumber}
            total={totalCount}
            pageSize={pageSize}
            hideOnSinglePage={true}
            showSizeChanger
            onShowSizeChange={onPaginationShowSizeChange}
          />
        </div>
        <div className="perPageCtrl">
          <Select
            className="perPageSelect"
            options={perPageSelectOptions}
            onChange={onPerPageSelectChange}
            value={perPageSelectValue}
          />
        </div>
      </>
    );
  };

  const renderListHeader = () => {
    return (
      <div className="singleBox header">
        <div className="partOne">
          <div className="rvLoc">
            Location
          </div>
          <div className="rvOta">
            OTA
          </div>
          <div className="rvRtng">
            Rating
          </div>
          <div className="rvTxt">
            Review Text
          </div>
          <div className="genTxt">
            Generated Response
          </div>
        </div>
        <div className="partTwo">
          <div className="when">
            <i className="far fa-clock"></i>
            On
          </div>
          <div className="oMenu">
          </div>
        </div>
      </div>
    );
  };


  const renderDateFilterRow = (statsObj) => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            History
            <span onClick={onRefreshClick}><img src={refreshIcon} /></span>
          </div>
          <p className="count">Found {totalCount} record/s</p>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const { history } = responseGeneratorState;
  if (!history) return null;


  return (
    <>
      {
        history && history.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg trscEmHstyPg">
          { renderDateFilterRow() }
          <div className="resGenHsty">
            {
              filteredList && filteredList.length
              ?
                <div className="allWrap">
                  { renderListHeader() }
                  {
                    filteredList && filteredList.length
                    ?
                      filteredList.map((item) => {
                        if (history.keyValue[item] && !history.keyValue[item].is_removed) {
                          return <SingleHistoryBox
                            key={`stehb_${item}`}
                            itemData={history.keyValue[item]}
                            isReadOnly={props.isReadOnly}
                          />
                        }
                        return null;
                      })
                    : <p>No Data</p>
                  }
                </div>
              : <p>No Data</p>
            }
          </div>
          <div className="footer">
            { renderPagination() }
          </div>
        </div>
      </div>
    </>
  );
}

export default History;
