// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Pagination from 'rc-pagination';

import { setCommonParams } from '../../../../../redux/actions/appActions';
import { getDataAction } from '../../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS, formatDate, PN_TO_ID, FEEDBACK_SOURCE_TO_LABEL, getUserGAId } from '../../../../../helpers';

import UploadHistoryRow from './UploadHistoryRow';


import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import './style.css';
import 'antd/dist/antd.css';
import "rc-pagination/assets/index.css";

import searchIcon from '../../../../../img/icons/search.svg';
import closeIcon from '../../../../../img/icons/close.svg';

const { RangePicker } = DatePicker;

function UploadHistory(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );

  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());
  const [dateRangeActive, setDateRangeActive] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const getData = (startDate, endDate, pageNumber) => {
    dispatch(getDataAction({
      op: 'get_feedback_upload_history_data',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        skip: pageSize * (pageNumber - 1) >= 0 ? pageSize * (pageNumber - 1) : 0,
        limit: pageSize,
      }
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks Campaign History - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('FeedbackOverview parsed: ', parsed);
    }

    let tempPageNumber = '';
    if (parsed['page_number']) {
      tempPageNumber = parsed['page_number'];
      if (tempPageNumber && Number.isInteger(parseInt(tempPageNumber))) {
        setPageNumber(tempPageNumber);
      }
    }
    if (!tempPageNumber) tempPageNumber = 1;

    getData(dateFilterStartDate, dateFilterEndDate, tempPageNumber);

  }, []);

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { feedbacks } = props.reputation;
    if (!feedbacks || !feedbacks.upload_history_data || feedbacks.upload_history_data.getInProgress) return;
    setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setPageNumber(1);
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    getData(dates[0], dates[1], 1);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Feedbacks Campaign History - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Feedbacks Campaign History - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDateRangeLastMonthChange = (value) => {
    const { feedbacks } = props.reputation;
    if (!feedbacks || !feedbacks.upload_history_data || feedbacks.upload_history_data.getInProgress) return;
    setPageNumber(1);
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    setDateRangeActive(false);
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      getData(new Date(moment().subtract(period, 'days')), new Date(), 1);
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      getData(new Date(moment().subtract(period, 'month')), new Date(), 1);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Feedbacks Campaign History - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Feedbacks Campaign History - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };


  const onPageChange = (page) => {
    setPageNumber(page);
    getData(dateFilterStartDate, dateFilterEndDate, page);
    const parsed = queryString.parse(location.search);
    parsed['page_number'] = page;
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onPaginationShowSizeChange = (current, pageSize) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPaginationShowSizeChange: ', current, ' # ', pageSize);
    }
  };

  const renderDateFilterRow = (feedbacks) => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Campaign History
          </div>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={DATE_DURATION_OPTIONS}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPagination = (feedbacks, overallCount) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderPagination pageNumber: ', pageNumber, ' # overallCount: ', overallCount);
    }
    if (!overallCount) return null;
    return (
      <div className="rpPagination">
        <Pagination
          onChange={onPageChange}
          // defaultCurrent={pageNumber}
          current={pageNumber}
          total={overallCount}
          pageSize={pageSize}
          hideOnSinglePage={true}
          showSizeChanger
          onShowSizeChange={onPaginationShowSizeChange}
        />
      </div>
    );
  };

  const renderUploadRowHeader = () => {
    return (
      <div className="uhRow header">
        <div className="dateCreated">
          Date
        </div>
        <div className="total">
          Medium
        </div>
        <div className="total">
          Upload Count
        </div>
        <div className="added">
          New
        </div>
        <div className="updated">
          Existing
        </div>
        <div className="errorCount">
          Error
        </div>
        <div className="sent">
          Sent
        </div>
        <div className="sentBy">
          Uploaded By
        </div>
        <div className="more">

        </div>
      </div>
    );
  };

  const renderContent = (feedbacks) => {

    return (
      <div className="fdbUplHstBd">
        <div className="lt">
          <div className="innerBox">
            <div className="contentBox">
              <div className="listBox">
                {
                  feedbacks && feedbacks.upload_history_data && feedbacks.upload_history_data.list
                  ? renderUploadRowHeader()
                  : null
                }
                {
                  feedbacks && feedbacks.upload_history_data && feedbacks.upload_history_data.list
                  ?
                    feedbacks.upload_history_data.list.map((item) => {
                      return <UploadHistoryRow data={item} />
                    })
                  : null
                }
              </div>
              { renderPagination(feedbacks, feedbacks && feedbacks.upload_history_data && feedbacks.upload_history_data.total_count ? feedbacks.upload_history_data.total_count : 0) }
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('UploadHistory props: ', props);
  }

  const { feedbacks } = props.reputation;
  if (!feedbacks || !feedbacks.upload_history_data) return null;
  const { upload_history_data } = feedbacks;

  return (
    <>
      {
        feedbacks.upload_history_data.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg fdbUplHstPg">
          { renderDateFilterRow() }
          { renderContent(feedbacks) }
        </div>
      </div>
    </>
  );
}

export default UploadHistory;
