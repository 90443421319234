// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { OTA_ID_TO_NAME_FORMAL_MAP, renderOTALogo, OTA_NAME_TO_SOURCE_MAP,
  REVIEW_STATE_TO_CTA_LABEL, REVIEW_STATE_TO_CTA_PENDING_LABEL, replaceAll } from '../../../../helpers';

import moreIcon from '../../../../img/icons/more.svg';
import searchIcon from '../../../../img/icons/search.svg';
import closeIcon from '../../../../img/icons/close.svg';

import './style.css';

function GenericLocationList(props) {
  const [searchKeyword, setSearchKeyword] = useState('');

  const onCloseClick = (e) => {
    props.onCancelClick();
  };
  const onCtaClick = (e) => {
    props.onCtaClick();
  };


  const onKeywordChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('GenericLocationList props.data: ', props.data);
  }
  let ctaLabel = '';
  ctaLabel = REVIEW_STATE_TO_CTA_LABEL[props.data.type];
  let countLabel = '';
  countLabel = REVIEW_STATE_TO_CTA_PENDING_LABEL[props.data.type];

  let qParam = '';
  if (props.data.type === 'approvalPending') qParam = 'approval';
  if (props.data.type === 'needInfo') qParam = 'need-info';
  if (props.data.type === 'responsePending') qParam = 'pending';

  return (
    <div className="gPWrp genLocLst">
      <div className="gPTle title">
        { props.data.title || '-' }
        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
      </div>
      <div className="gBCtnt">
        <div className="gBCtRw srchRow">
          <div className="gRwBxLt">
            <div className="gIpt">
              <img src={searchIcon} />
              <input type="text" name="keyword" value={searchKeyword} onChange={onKeywordChange}
                placeholder="Search location"
                onKeyDown={onSearchInputKeyDown}
              />
            </div>
          </div>
        </div>
        {
          props.data && props.data.list && props.data.list.length
          ?
            props.data.list.filter((item) => {
              if (searchKeyword) {
                if (new RegExp(searchKeyword, 'i').test(item.name) ||
                  new RegExp(searchKeyword, 'i').test(item.address) ||
                  new RegExp(searchKeyword, 'i').test(item.location_id) ||
                  new RegExp(searchKeyword, 'i').test(item.serverid)
                ) {
                  return true
                }
                return false;
              }
              return true;
            }).map((item) => {
              return (
                <div className="gBCtRw locRow">
                  <div className="left">
                    {
                      item.logo &&
                      <img src={item.logo} />
                    }
                  </div>
                  <div className="right">
                    <p className="name">{item.name}
                    </p>
                    <p className="address">{item.address}</p>
                    <div className="bottom">
                      <div className="left">
                        {countLabel}: <span>{item.count}</span>
                      </div>
                      <div className="right">
                        <Link to={`/reputation/locations/${item._id}/reviews/${qParam}`} target="_blank">{ctaLabel}
                          <span><i className="fas fa-external-link-alt"></i></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null
        }
      </div>
    </div>
  );
}

export default GenericLocationList;
