// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../Misc/CustomTimePicker';

import { formatDate, formatDateTime, broadcastTargetToLabel, broadcastTargetDaysAheadToLabel,
  broadcastTargetDaysSinceCheckInToLabel, broadcastWeekDayToLabel, STRS } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';
import oneTimeIcon from '../../../../img/icons/broadcast-onetime.svg';
import oneTimeActiveIcon from '../../../../img/icons/broadcast-onetime-active.svg';
import recurringIcon from '../../../../img/icons/broadcast-recurring.svg';
import recurringActiveIcon from '../../../../img/icons/broadcast-recurring-active.svg';

import './style.css';

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const timeConstraintsScheduleTime = { minutes: { step: 15 }};
const yesterday = moment().subtract(1, 'day');

const targetOptions = [];
targetOptions.push({ label: 'Yet to arrive', value: 'upcoming' });
targetOptions.push({ label: 'In-House', value: 'checked-in' });
targetOptions.push({ label: 'Departing Soon', value: 'departing-soon' });
targetOptions.push({ label: 'Staff', value: 'staff' });

const checkInDateSelectOptions = [];
checkInDateSelectOptions.push({ label: 'Same Day', value: 'same-day' });
checkInDateSelectOptions.push({ label: '1 day later', value: '1-day-later' });
checkInDateSelectOptions.push({ label: '2 days later', value: '2-days-later' });
checkInDateSelectOptions.push({ label: '3 days later', value: '3-days-later' });
checkInDateSelectOptions.push({ label: '7 days later', value: '7-days-later' });
checkInDateSelectOptions.push({ label: '10 days later', value: '10-days-later' });
checkInDateSelectOptions.push({ label: '30 days later', value: '30-days-later' });

const daysSinceCheckInSelectOptions = [];
daysSinceCheckInSelectOptions.push({ label: '1 Day', value: '1-day' });
daysSinceCheckInSelectOptions.push({ label: '2 Days', value: '2-day' });
daysSinceCheckInSelectOptions.push({ label: '3 Days', value: '3-day' });

const departingDateSelectOptions = [];
departingDateSelectOptions.push({ label: 'Same Day', value: 'same-day' });
departingDateSelectOptions.push({ label: 'Next Day', value: 'next-day' });

const daysArr = [];
daysArr.push({ label: 'Mon', 'value': 'monday' });
daysArr.push({ label: 'Tue', 'value': 'tuesday' });
daysArr.push({ label: 'Web', 'value': 'wednesday' });
daysArr.push({ label: 'Thu', 'value': 'thursday' });
daysArr.push({ label: 'Fri', 'value': 'friday' });
daysArr.push({ label: 'Sat', 'value': 'saturday' });
daysArr.push({ label: 'Sun', 'value': 'sunday' });

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{firstname}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{lastname}}' });

const MessageTemplateButton = (props) => {
  const onButtonClick = (e) => { props.onButtonClick(props.template); };

  return (
    <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
      overlay={props.template.msg}
    >
    <div className="lEntry" onClick={onButtonClick} data-tip data-for={`tt_mt_${props.template._id}`}>
      {props.template.title}
      {/*<ReactTooltip id={`tt_mt_${props.template._id}`} data-html={true}
            insecure={true}
            multiline={true} className="messageTemplateTT">
        <span>{props.template.msg}</span>
      </ReactTooltip>*/}
    </div>
    </ToolTipWrapper>
  )
};

function SingleDay(props) {
  const onClick = (e) => {
    props.onSingleDayClick(props.day);
  }
  return (
    <div className={'singleDay ' + (props.selected ? 'selected' : '')} onClick={onClick}>
      {props.day.label}
    </div>
  );
}

function SingleTag(props) {
  const onRemoveClick = (e) => {
    props.onRemoveTagClick(props.tag);
  };
  if (!props.tag) return null;
  return (
    <div className="singleTag">
      {props.tag.label}
      <span onClick={onRemoveClick}>
        {
          props.tag.editInProgress
          ? <i className="fa fa-spinner fa-spin" />
          : <i className="fa fa-times"></i>
        }
      </span>
    </div>
  );
}

function SingleBroadcastPreview(props) {
  const [showMore, setShowMore] = useState(false);
  const [sendInfo, setSendInfo] = useState('');
  useEffect(() => {
    let text = 'Send';
    if (broadcast.when === 'now') text = `${text} Now`;
    if (broadcast.when === 'later') text = `${text} at ${broadcast.when_schedule_time}`;
    if (broadcast.when_all_days) text = `${text} Everyday`;
    if (broadcast.when_days && Object.keys(broadcast.when_days).length) {
      // text = `${text} on ${Object.keys(broadcast.when_days).join(', ')}`;
      const numOfDays = Object.keys(broadcast.when_days).length;
      text = `${text} on `;
      Object.keys(broadcast.when_days).forEach((item, index) => {
        text = `${text} ${broadcastWeekDayToLabel[item]}${index < numOfDays-1 ? ',' : ''}`;
      });
    }
    setSendInfo(text);
  }, [props.broadcast]);
  const onMoreClick = (e) => {
    setShowMore(!showMore);
  };

  const { broadcast } = props;
  return (
    <div className={'singleBroadcast ' + (showMore ? 'showMore' : 'showLess')}>
      <div className="top">
        <div className="type">
          <span>
            {
              broadcast.type === 'one-time' && <img src={oneTimeIcon} />
            }
            {
              broadcast.type === 'recurring' && <img src={recurringIcon} />
            }
            {broadcast.type}
          </span>
        </div>
        <div className="when">
          <p className="day">Everyday</p>
          <p className="time">9AM</p>
        </div>
      </div>
      <div className="name">
        {broadcast.name}
      </div>
      <div className="lineBreak" />
      <span className="target">{broadcastTargetToLabel[broadcast.target]}</span>
      <div className="message">
        {broadcast.message}
      </div>
      <div className="more">
        <span onClick={onMoreClick}><i className={'fas ' + (showMore ? 'fa-angle-up' : 'fa-angle-down')} /></span>
      </div>
    </div>
  );
}

function BroadcastSingle(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const location = useLocation();

  const [broadcastType, setBroadcastType] = useState('');

  const [targetValue, setTargetValue] = useState(
    // { label: 'Currently Staying', value: 'checked-in' }
  );
  const [targetError, setTargetError] = useState('');
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkInDateSelectValue, setCheckInDateSelectValue] = useState(
    { label: '1 day later', value: '1-day-later' }
  );
  const [departingDateSelectValue, setDepartingDateSelectValue] = useState(
    { label: 'Same Day', value: 'same-day' }
  );
  const [daysSinceCheckInSelectValue, setDaysSinceCheckInSelectValue] = useState({});
    // { label: '1 day ', value: '1-day' }

  const [tagOptions, setTagOptions] = useState([]);
  const [tagSelectValue, setTagSelectValue] = useState([]);
  const [allSelectedTags, setAllSelectedTags] = useState([]);

  const [mewsProductOptions, setMewsProductOptions] = useState([]);
  const [mewsProductSelectValue, setMewsProductSelectValue] = useState([]);
  const [allSelectedMewsProducts, setAllSelectedMewsProducts] = useState([]);

  const [when, setWhen] = useState('');
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [scheduleTime, setScheduleTime] = useState(new Date(moment('10:00 AM', 'h:mm A')));
  const [whenAllDays, setWhenAllDays] = useState(false);
  const [whenWeekDays, setWhenWeekDays] = useState({
  });
  const [whenError, setWhenError] = useState('');
  const [whenDaysError, setWhenDaysError] = useState('');

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');

  const [personalizeOptions, setPersonalizeOptions] = useState([]);
  const [personalizeValue, setPersonalizeValue] = useState([
    { label: 'Personalize', value: 'personalize' }
  ]);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const messageEl = useRef(null);

  useEffect(() => {
    dispatch(editDataAction({
      op: 'edit_broadcast',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
      },
    }));
    if (props.mode === 'add' || props.mode === 'edit') {
      let tempTagOptions = [];
      if (props.settings && props.settings.engage && props.settings.engage.labels &&
        props.settings.engage.labels.list && props.settings.engage.labels.list.length) {
        props.settings.engage.labels.list.forEach((item) => {
          if (props.settings.engage.labels.keyValue[item]) {
            tempTagOptions.push({ label: props.settings.engage.labels.keyValue[item].label, value: props.settings.engage.labels.keyValue[item]._id });
          }
        });
      }
      setTagOptions(tempTagOptions);
      setPersonalizeOptions([
        ...personalizeOptionsG,
        // ...newOptions,
      ]);

      tempTagOptions = [];
      if (props.settings && props.settings.engage && props.settings.engage.mews_products &&
        props.settings.engage.mews_products.list && props.settings.engage.mews_products.list.length) {
        props.settings.engage.mews_products.list.forEach((item) => {
          if (props.settings.engage.mews_products.keyValue[item]) {
            tempTagOptions.push({ label: props.settings.engage.mews_products.keyValue[item].name, value: props.settings.engage.mews_products.keyValue[item]._id });
          }
        });
      }
      setMewsProductOptions(tempTagOptions);

    }
    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        containerId: 'broadcastWrapId',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  }, []);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('broadcast location.search: ', location.search);
  //   }
  //   const { success } = queryString.parse(location.search);
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('broadcast success: ', success);
  //   }
  //   if (!success) {
  //     dispatch(editDataClearAction({
  //       op: 'edit_broadcast',
  //       params: {
  //         location__id: urlParams.locationidentifier,
  //         mode: 'add',
  //       },
  //     }));
  //   }
  // }, [location.search]);

  useEffect(() => {
    if (props.mode === 'edit' && props.broadcastId) {
      const broadcast = props.engage.broadcast.keyValue[props.broadcastId];
      if (process.env.NODE_ENV !== 'production') {
        console.log('broadcast: ', broadcast);
      }
      if (broadcast) {
        setBroadcastType(broadcast.type);
        if (broadcast.target) {
          let temp = { label: broadcastTargetToLabel[broadcast.target], value: broadcast.target };
          setTargetValue(temp);
          let newOptions = [];
          if (broadcast.target === 'upcoming') {
            newOptions.push({ label: 'Self Check In Link', value: '{{selfcheckinlink}}' });
          }
          if (broadcast.target === 'checked-in' || broadcast.target === 'departing-soon') {
            newOptions.push({ label: 'Self Check Out Link', value: '{{selfcheckoutlink}}' });
          }
          if (props.settings && props.settings.info && props.settings.info.has_stayhq) {
            newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
          }
          setPersonalizeOptions([
            ...personalizeOptionsG,
            ...newOptions,
          ]);
          if (broadcast.target_check_in_date) setCheckInDate(new Date(moment(broadcast.target_check_in_date, 'YYYY-MM-DD')));
          if (broadcast.target_check_in_days_ahead) {
            temp = { label: broadcastTargetDaysAheadToLabel[broadcast.target_check_in_days_ahead], value: broadcast.target_check_in_days_ahead };
            setCheckInDateSelectValue(temp)
          }
          if (broadcast.target_days_since_check_in) {
            temp = { label: broadcastTargetDaysSinceCheckInToLabel[broadcast.target_days_since_check_in], value: broadcast.target_days_since_check_in };
            setDaysSinceCheckInSelectValue(temp)
          }
        }
        let tempTagOptions = [];
        if (props.settings && props.settings.engage && props.settings.engage.labels &&
          props.settings.engage.labels.list && props.settings.engage.labels.list.length
        ) {
          props.settings.engage.labels.list.forEach((item) => {
            if (props.settings.engage.labels.keyValue[item]) {
              if (broadcast.target_labels && broadcast.target_labels.length) {
                if (!broadcast.target_labels.find(el => el._id === item)) {
                  tempTagOptions.push({ label: props.settings.engage.labels.keyValue[item].label, value: item });
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('added: ', props.settings.engage.labels.keyValue[item]);
                  }
                }
              } else {
                tempTagOptions.push({ label: props.settings.engage.labels.keyValue[item].label, value: item });
              }
            }
          });
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('tempTagOptions: ', tempTagOptions);
        }
        setTagOptions(tempTagOptions);
        if (broadcast.target_labels && broadcast.target_labels.length) {
          let tempAllSelectedTags = [];
          broadcast.target_labels.forEach((item) => {
            tempAllSelectedTags.push(item);
          });
          setAllSelectedTags(tempAllSelectedTags);
        }
        // mews products
        tempTagOptions = [];
        if (props.settings && props.settings.engage && props.settings.engage.mews_products &&
          props.settings.engage.mews_products.list && props.settings.engage.mews_products.list.length
        ) {
          props.settings.engage.mews_products.list.forEach((item) => {
            if (props.settings.engage.mews_products.keyValue[item]) {
              if (broadcast.target_mews_products && broadcast.target_mews_products.length) {
                if (!broadcast.target_mews_products.find(el => el._id === item)) {
                  tempTagOptions.push({ label: props.settings.engage.mews_products.keyValue[item].name, value: item });
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('added: ', props.settings.engage.mews_products.keyValue[item]);
                  }
                }
              } else {
                tempTagOptions.push({ label: props.settings.engage.mews_products.keyValue[item].name, value: item });
              }
            }
          });
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('tempTagOptions: ', tempTagOptions);
        }
        setMewsProductOptions(tempTagOptions);
        if (broadcast.target_mews_products && broadcast.target_mews_products.length) {
          let tempAllSelectedTags = [];
          broadcast.target_mews_products.forEach((item) => {
            tempAllSelectedTags.push(item);
          });
          setAllSelectedMewsProducts(tempAllSelectedTags);
        } // end of mews products

        if (broadcast.when) setWhen(broadcast.when);
        if (broadcast.when_all_days) setWhenAllDays(true);
        if (broadcast.when_days) setWhenWeekDays(broadcast.when_days);
        if (broadcast.when_schedule_date) setScheduleDate(new Date(moment(broadcast.when_schedule_date, 'YYYY-MM-DD')));
        if (broadcast.when_schedule_time) setScheduleTime(moment(broadcast.when_schedule_time, 'k:mm'));
        setMessage(broadcast.message);
        setName(broadcast.name);
      }
    }
  }, [props.broadcastId])


  useEffect(() => {
    const { broadcast } = props.engage;
    if (props.mode === 'add' && !broadcast.addInProgress && broadcast.addStatus && broadcast.addStatus.success) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('add success');
        // setSomeChange(false);
        // setBroadcastType('');
        // setMessage('');
        // setName('');
        // let tempTagOptions = [];
        // if (props.locationprops.tags && props.locationprops.tags.list && props.locationprops.tags.list.length) {
        //   props.locationprops.tags.list.forEach((item) => {
        //     if (props.locationprops.tags.keyValue[item]) {
        //       tempTagOptions.push({ label: props.locationprops.tags.keyValue[item].label, value: props.locationprops.tags.keyValue[item]._id });
        //     }
        //   });
        // }
        // setTagOptions(tempTagOptions);
        // setTagSelectValue([]);
        // setAllSelectedTags([]);
      }
      navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/new?success=1`);
      resetData();
    }
  }, [props.engage.broadcast.addInProgress]);

  useEffect(() => {
    if (props.mode === 'add') {
      resetData();
    }
  }, [props.mode]);

  useEffect(() => {
    if (someChange && !props.engage.broadcast.addInProgress &&
      props.engage.broadcast.addStatus && props.engage.broadcast.addStatus.success) {
      dispatch(editDataClearAction({
        op: 'edit_broadcast',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
    if (someChange && props.broadcastId && props.engage.broadcast.keyValue &&
      props.engage.broadcast.keyValue[props.broadcastId] &&
      !props.engage.broadcast.keyValue[props.broadcastId].editInProgress &&
      props.engage.broadcast.keyValue[props.broadcastId].editStatus &&
      props.engage.broadcast.keyValue[props.broadcastId].editStatus.success) {
      dispatch(editDataClearAction({
        op: 'edit_broadcast',
        params: {
          location__id: urlParams.locationidentifier,
          broadcast_id: props.broadcastId,
          mode: 'edit',
        },
      }));
    }
  }, [someChange]);

  const resetData = () => {
    setSomeChange(false);
    setBroadcastType('');
    setWhen('');
    setWhenAllDays(false);
    setWhenWeekDays({});
    setMessage('');
    setName('');
    const { engage } = props.settings;
    let tempTagOptions = [];
    if (engage.labels && engage.labels.list && engage.labels.list.length) {
      engage.labels.list.forEach((item) => {
        if (engage.labels.keyValue[item]) {
          tempTagOptions.push({ label: engage.labels.keyValue[item].label, value: engage.labels.keyValue[item]._id });
        }
      });
    }
    setTagOptions(tempTagOptions);
    setTagSelectValue([]);
    setAllSelectedTags([]);
    tempTagOptions = [];
    if (engage.mews_products && engage.mews_products.list && engage.mews_products.list.length) {
      engage.mews_products.list.forEach((item) => {
        if (engage.mews_products.keyValue[item]) {
          tempTagOptions.push({ label: engage.mews_products.keyValue[item].name, value: engage.mews_products.keyValue[item]._id });
        }
      });
    }
    setMewsProductOptions(tempTagOptions);
    setMewsProductSelectValue([]);
    setAllSelectedMewsProducts([]);
  };

  const onCloseClick = (e) => {
    props.onCloseClick(e);
  };

  const onTypeOneTimeClick = (e) => {
    setBroadcastType('one-time');
    setWhen(''); // setWhen('now');
    setSomeChange(true);
    setWhenError('');
  };
  const onTypeRecurringClick = (e) => {
    setBroadcastType('recurring');
    setWhenAllDays(false);
    setWhenWeekDays({});
    setSomeChange(true);
    setWhen('later'); // setWhen('later');
    setWhenError('');
  };

  const onTargetChange = (value) => {
    setTargetValue(value);
    if (value.value === 'upcoming' || value.value === 'departing-soon') {
      if (broadcastType === 'recurring') setWhen('later');
      if (broadcastType === 'one-time') setWhen('');
    }
    if (value.value === 'checked-in' || value.value === 'staff') {
      setWhen('later');
    }
    let newOptions = [];
    if (value.value === 'upcoming') {
      newOptions.push({ label: 'Self Check In Link', value: '{{selfcheckinlink}}' });
    }
    if (value.value === 'checked-in' || value.value === 'departing-soon') {
      newOptions.push({ label: 'Self Check Out Link', value: '{{selfcheckoutlink}}' });
    }
    setPersonalizeOptions([
      ...personalizeOptionsG,
      ...newOptions,
    ]);
    setMessage('');
    setTargetError('');
    setSomeChange(true);
  };
  const onCheckInDateChange = (selectedDate) => {
    setCheckInDate(new Date(selectedDate));
    setSomeChange(true);
  };
  const onCheckInDateSelectChange = (value) => {
    setCheckInDateSelectValue(value);
    setSomeChange(true);
  };
  const onDaysSinceCheckInSelectChange = (value) => {
    setDaysSinceCheckInSelectValue(value);
    setSomeChange(true);
  };
  const onDepartingDateSelectChange = (value) => {
    setDepartingDateSelectValue(value);
    setSomeChange(true);
  };

  const inValidCheckInDate = (current) => {
    return current.isAfter(yesterday);
  };
  const inValidScheduleDate = (current) => {
    return current.isAfter(yesterday);
  };

  const onTagSelectChange = (value) => {
    setTagSelectValue(value);
    setAllSelectedTags([ ...allSelectedTags, { _id: value.value, label: value.label } ]);
    setTagOptions(tagOptions.filter(el => el.value !== value.value));
    setTagSelectValue([]);
    setSomeChange(true);
  };
  const onRemoveTagClick = (tag) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveTagClick tag: ', tag);
      console.log('onRemoveTagClick allSelectedTags: ', allSelectedTags);
    }
    setAllSelectedTags(allSelectedTags.filter(el => el._id !== tag._id));
    setTagOptions([ ...tagOptions, tag]);
    setSomeChange(true);
  }


  const onMewsProductSelectChange = (value) => {
    setMewsProductSelectValue(value);
    setAllSelectedMewsProducts([ ...allSelectedMewsProducts, { _id: value.value, label: value.label } ]);
    setMewsProductOptions(mewsProductOptions.filter(el => el.value !== value.value));
    setMewsProductSelectValue([]);
    setSomeChange(true);
  };
  const onRemoveMewsProductClick = (tag) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveMewsProductClick tag: ', tag);
      console.log('onRemoveMewsProductClick allSelectedMewsProducts: ', allSelectedMewsProducts);
    }
    setAllSelectedMewsProducts(allSelectedMewsProducts.filter(el => el._id !== tag._id));
    setMewsProductOptions([ ...mewsProductOptions, tag]);
    setSomeChange(true);
  }


  const onWhenNowClick = (e) => {
    setWhen('now');
    setWhenError('');
    setSomeChange(true);
  }
  const onWhenLaterClick = (e) => {
    setWhen('later');
    setWhenError('');
    setSomeChange(true);
  }
  const onWhenAllDaysClick = (e) => {
    if (!whenAllDays) setWhenWeekDays({});
    setWhenAllDays(!whenAllDays);
    setSomeChange(true);
    setWhenDaysError('');
  }
  const onSingleDayClick = (day) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSingleDayClick day; ', day);
      console.log('onSingleDayClick whenWeekDays; ', whenWeekDays);
    }
    let temp = { ...whenWeekDays };
    if (!temp[day.value]) { temp[day.value] = true; }
    else {
      temp[day.value] = false;
      delete temp[day.value];
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSingleDayClick temp; ', temp);
    }
    setWhenWeekDays(temp);
    setWhenAllDays(Object.keys(temp).length ? false : true);
    setWhenDaysError('');
    setSomeChange(true);
  }

  const onScheduleDateChange = (selectedDate) => {
    setScheduleDate(selectedDate ? new Date(selectedDate) : null);
    setWhenError(selectedDate ? '' : 'error');
    setSomeChange(true);
  };
  const onScheduleTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onScheduleTimeChange selectedTime; ', selectedTime);
    }
    // new Date(moment('10:00 AM', 'h:mm A')
    // setScheduleTime(selectedTime ? new Date(selectedTime) : null);
    setScheduleTime(selectedTime ? new Date(moment(selectedTime, 'h:mm A')) : null);
    setWhenError(selectedTime ? '' : 'error');
    setSomeChange(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    setMessageError(value ? '' : 'error');
    setSomeChange(true);
  };

  const onNameChange = (e) => {
    const { name, value } = e.target;
    setName(value);
    setNameError(value ? '' : 'error');
    setSomeChange(true);
  };

  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
    }
    if (value && value.value !== 'personalize') {
      // setMessage(message => `${message} ${values[0].label}`);
      setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
      // props.onMessageChange(`${message} ${values[0].value}`);
      props.onMessageChange(`${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
      // setTimeout(() => {
      //   setPersonalizeValues([{ label: 'Personalize', value: 'personalize' }]);
      // }, 100);
    }
  };

  const onTemplateClick = (template) => {
    setMessage(template.msg);
    setMessageError('');
    setSomeChange(true);
  };

  const onCreateClick = (e) => {
    if (props.engage.broadcast.addInProgress) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onCreateClick mode: ', props.mode);
      console.log('onCreateClick broadcastType: ', broadcastType);
      console.log('onCreateClick targetValue: ', targetValue);
      console.log('onCreateClick checkInDate: ', checkInDate);
      console.log('onCreateClick checkInDateSelectValue: ', checkInDateSelectValue);
      console.log('onCreateClick daysSinceCheckInSelectValue: ', daysSinceCheckInSelectValue);
      console.log('onCreateClick when: ', when);
      console.log('onCreateClick scheduleDate: ', scheduleDate);
      console.log('onCreateClick scheduleTime: ', scheduleTime);
    }

    performSave();

  };

  const onEditClick = (e) => {
    const { broadcast } = props.engage;
    if (broadcast && broadcast.keyValue && broadcast.keyValue[props.broadcastId] &&
      broadcast.keyValue[props.broadcastId].editInProgress) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditClick mode: ', props.mode);
      console.log('onEditClick broadcastType: ', broadcastType);
      console.log('onEditClick targetValue: ', targetValue);
      console.log('onEditClick checkInDate: ', checkInDate);
      console.log('onEditClick checkInDateSelectValue: ', checkInDateSelectValue);
      console.log('onEditClick daysSinceCheckInSelectValue: ', daysSinceCheckInSelectValue);
      console.log('onEditClick when: ', when);
      console.log('onEditClick scheduleDate: ', scheduleDate);
      console.log('onEditClick scheduleTime: ', scheduleTime);
    }

    performSave();
  };

  const performSave = () => {
    const { broadcast } = props.engage;
    if (props.mode === 'edit' && props.broadcastId) {
      if (broadcast.keyValue && broadcast.keyValue[props.broadcastId] && broadcast.keyValue[props.broadcastId].editInProgress) return;
    }
    if (props.mode === 'add') {
      if (broadcast.addInProgress) return;
    }

    setTargetError(targetValue && targetValue.value ? '' : 'error');
    setMessageError(message ? '' : 'error');
    setNameError(name ? '' : 'error');
    // setWhenError(when ? '' : 'error');

    if (!when) {
      setWhenError('error');
    }
    if (broadcastType === 'recurring') {
      if (!whenAllDays && !Object.keys(whenWeekDays).length) {
        setWhenDaysError('error');
      }
    }

    if (broadcastType === 'one-time') {
      if (targetValue.value === 'upcoming' && (!checkInDate)) return;
      if (targetValue.value === 'departing-soon' && (!departingDateSelectValue || !departingDateSelectValue.value)) return;
      if (when === 'later' && (!scheduleDate || !scheduleTime)) {
        setWhenError('error');
        return;
      }
    }
    if (broadcastType === 'recurring') {
      if (targetValue.value === 'upcoming' && (!checkInDateSelectValue || !checkInDateSelectValue.value)) return;
      if (targetValue.value === 'departing-soon' && (!departingDateSelectValue || !departingDateSelectValue.value)) return;
      if (targetValue.value === 'staff' && !scheduleTime) {
        setWhenError('error');
        return;
      }
    }
    if (broadcastType === 'recurring') {
      if (!whenAllDays && !Object.keys(whenWeekDays).length) {
        setWhenDaysError('error');
        return;
      }
    }

    if (!broadcastType || !targetValue || !targetValue.value || !when || !message || !name) return;


    if (broadcastType && targetValue && targetValue.value && when && message) {
      const params = {
        mode: 'add',
        location__id: urlParams.locationidentifier,
        name,
      };
      if (props.mode === 'edit') {
        params.mode = 'edit';
        params.broadcast_id = props.broadcastId;
      }
      params.type = broadcastType;
      params.target = targetValue.value;
      params.target_labels = allSelectedTags;
      if (props.settings.engage && props.settings.engage.has_mews_products) {
        if (allSelectedMewsProducts.length) params.target_mews_products = allSelectedMewsProducts;
      }
      if (targetValue.value === 'checked-in') {
        if (daysSinceCheckInSelectValue.value) { // optional
          params.target_days_since_check_in = daysSinceCheckInSelectValue.value;
        }
      }
      if (targetValue.value === 'upcoming') {
        if (broadcastType === 'one-time') {
          params.target_check_in_date = moment(checkInDate).format('YYYY-MM-DD');;
        }
        if (broadcastType === 'recurring') {
          params.target_check_in_days_ahead = checkInDateSelectValue.value;
        }
      }
      if (targetValue.value === 'departing-soon') {
        params.target_check_out_days_ahead = departingDateSelectValue.value;
      }
      params.when = when;
      if (broadcastType === 'recurring') {
        if (whenAllDays) {
          params.when_all_days = true;
          params.when_days = {};
        }
        else {
          params.when_days = whenWeekDays;
        }
      }
      if (broadcastType === 'one-time') {
        if (when === 'later') {
          params.when_schedule_date = moment(scheduleDate).format('YYYY-MM-DD');
          params.when_schedule_time = moment(scheduleTime).format('k:mm');
        }
      }
      if (broadcastType === 'recurring') {
        if (when === 'later') {
          if (targetValue.value === 'checked-in' || targetValue.value === 'staff') {
            params.when_schedule_time = moment(scheduleTime).format('k:mm');
          }
          if (targetValue.value === 'upcoming' || targetValue.value === 'departing-soon') {
            params.when_schedule_time = moment(scheduleTime).format('k:mm');
          }
        }
      }
      params.message = message;
      if (process.env.NODE_ENV !== 'production') {
        console.log('performSave: ', params);
      }
      dispatch(editDataAction({
        op: 'edit_broadcast',
        params,
      }));
      setSomeChange(false);
    }
  };


  const renderTarget = () => {
    if (!broadcastType) return null;
    return (
      <div className="cardWrap">
        <div className="titleRow">
          <p className="title">Select Audience</p>
          <p className="info">Choose guests to whom message will be sent.</p>
        </div>
        <div className="targetRow">
          <div className="targetBox">
            <Select
              options={targetOptions}
              onChange={onTargetChange}
              value={targetValue}
            />
          </div>
        </div>
        {
          targetError &&
          <div className="errorRow">
            <div className="gError">
              <img src={requiredIcon} /> Audience is required
            </div>
          </div>
        }
        {
          targetValue && targetValue.value === 'checked-in' &&
          (broadcastType === 'recurring' || broadcastType === 'one-time') &&
          <div className="targetDateRow">
            <div className="itemBox scheduleDateBox">
              <div className="gLabel">
                Days since Check-In (Optional)
              </div>
              <div className="gLabelInfo">
                Select days since the check-in took place.
              </div>
              <div className="gInput">
                <Select
                  options={daysSinceCheckInSelectOptions}
                  onChange={onDaysSinceCheckInSelectChange}
                  value={daysSinceCheckInSelectValue}
                />
              </div>
            </div>
          </div>
        }
        {
          targetValue && targetValue.value === 'upcoming' && broadcastType === 'one-time' &&
          <div className="targetDateRow">
            <div className="itemBox scheduleDateBox">
              <div className="gLabel">
                Checking-In on
              </div>
              <div className="gLabelInfo">
                Select date on which guests will be checking-in for this one time message.
              </div>
              <div className="gInput">
                {/*<DateTime
                  value={checkInDate}
                  dateFormat="DD MMM YYYY"
                  timeFormat=""
                  onChange={onCheckInDateChange}
                  isValidDate={inValidCheckInDate}
                  closeOnSelect={true}
                />*/}
                <div className="gIpt">
                  <DatePicker selected={checkInDate} onChange={onCheckInDateChange}
                    dateFormat="dd MMM yyyy"
                  />
                </div>
              </div>
            </div>
          </div>
        }
        {
          targetValue && targetValue.value === 'upcoming' && broadcastType === 'recurring' &&
          <div className="targetDateRow">
            <div className="itemBox scheduleDateBox">
              <div className="gLabel">
                Check-In Date
              </div>
              <div className="gLabelInfo">
                Choose which set of upcoming guests will receive the message based on their expected check-in.
              </div>
              <div className="gInput">
                <Select
                  options={checkInDateSelectOptions}
                  onChange={onCheckInDateSelectChange}
                  value={checkInDateSelectValue}
                />
              </div>
            </div>
          </div>
        }
        {
          targetValue && targetValue.value === 'departing-soon' && // broadcastType === 'recurring' &&
          <div className="targetDateRow">
            <div className="itemBox scheduleDateBox">
              <div className="gLabel">
                Departing when
              </div>
              <div className="gLabelInfo">
                Choose which set of departing guests will recive the message based on their expected check-out.
              </div>
              <div className="gInput">
                <Select
                  options={departingDateSelectOptions}
                  onChange={onDepartingDateSelectChange}
                  value={departingDateSelectValue}
                />
              </div>
            </div>
          </div>
        }
        <div className="targetTagRow">
          <div className="itemBox tagBox">
            <div className="gLabel">
              Select Labels (Optional)
            </div>
            <div className="gLabelInfo">
              Choose labels to select a group of guests within eligible guests. More than one can be selected, message will be sent to all the selected tags.
            </div>
            <div className="gInput">
              <Select
                options={tagOptions}
                onChange={onTagSelectChange}
                value={tagSelectValue}
                isMulti={false}
                placeholder="Select Labels"
              />
            </div>
          </div>
        </div>
        {
          allSelectedTags && allSelectedTags.length
          ?
            <div className="targetSelectedTagsRow">
              <div className="itemBox tagBox">
                {
                  allSelectedTags.map((item) => {
                    return (
                      <SingleTag tag={item} onRemoveTagClick={onRemoveTagClick} />
                    )
                  })
                }
              </div>
            </div>
          : null
        }
        {
          props.settings.engage && props.settings.engage.has_mews_products
          ?
          <>
            <div className="targetTagRow">
              <div className="itemBox tagBox">
                <div className="gLabel">
                  Select Products (Optional)
                </div>
                <div className="gLabelInfo">
                  Choose products to select a group of guests within eligible guests. More than one can be selected, message will be sent to all the guests with subscribed products.
                </div>
                <div className="gInput">
                  <Select
                    options={mewsProductOptions}
                    onChange={onMewsProductSelectChange}
                    value={mewsProductSelectValue}
                    isMulti={false}
                    placeholder="Select Products"
                  />
                </div>
              </div>
            </div>
            {
              allSelectedMewsProducts && allSelectedMewsProducts.length
              ?
                <div className="targetSelectedTagsRow">
                  <div className="itemBox tagBox">
                    {
                      allSelectedMewsProducts.map((item) => {
                        return (
                          <SingleTag tag={item} onRemoveTagClick={onRemoveMewsProductClick} />
                        )
                      })
                    }
                  </div>
                </div>
              : null
            }
          </>
          : null
        }
      </div>
    );
  }
  const renderWhenTimeOnly = () => {
    return (
      <div className="cardWrap">
        <div className="titleRow">
          <p className="title">When to send message</p>
          <p className="info">Choose days and times at which message will be sent to guests. Choose everyday to send the message on all days. Select specific days of the week to send message on only specific days.</p>
        </div>
        <div className="tabRow">
          <div className={'itemBox ' + (whenAllDays ? 'selected' : '')} onClick={onWhenAllDaysClick}>
            Every Day
          </div>
        </div>
        <div className={'tabRow daysRow ' + (whenAllDays ? 'whenAllDays ' : '')}>
          {
            daysArr.map((entry) => {
              return <SingleDay key={`single_day__${entry.value}`} day={entry} selected={whenWeekDays[entry.value]} onSingleDayClick={onSingleDayClick} />;
            })
          }
        </div>
        {
          whenDaysError &&
          <div className="errorRow">
            <div className="gError">
              <img src={requiredIcon} /> Every Day or atleast one day is required
            </div>
          </div>
        }

        <div className="scheduleRow">
          <div className="itemBox timeBox">
            <div className="gLabel">
              Time of day
            </div>
            <div className="gLabelInfo">
              Choose time of the day at which message will be sent.
            </div>
            <div className="gInput">
            {/*<DateTime
              value={scheduleTime}
              dateFormat=""
              timeFormat="h:mm A"
              onChange={onScheduleTimeChange}
              closeOnSelect={true}
              timeConstraints={timeConstraintsScheduleTime}
            />*/}
            <CustomTimePicker
              value={moment(scheduleTime).format('h:mm A')}
              onChange={onScheduleTimeChange}
            />
            </div>
          </div>
        </div>
        {
          whenError &&
          <div className="errorRow">
            <div className="gError">
              <img src={requiredIcon} /> When to send is required
            </div>
          </div>
        }
      </div>
    );
  }
  const renderWhen = () => {
    if (!broadcastType) return null;
    if (broadcastType === 'recurring') {
      return renderWhenTimeOnly();
    }
    return (
      <div className="cardWrap">
        <div className="titleRow">
          <p className="title">When to send message</p>
          <p className="info">Choose the time at which message will be sent to guests.</p>
        </div>
        <div className="tabRow whenRow">
          <div className={'itemBox ' + (when === 'now' ? 'selected' : '')} onClick={onWhenNowClick}>
            Now
          </div>
          <div className={'itemBox ' + (when === 'later' ? 'selected' : '')} onClick={onWhenLaterClick}>
            Later
          </div>
        </div>
        {
          when === 'later' &&
          <div className="scheduleRow">
            <div className="itemBox scheduleDateBox">
              <div className="gLabel">
                Date
              </div>
              <div className="gInput">
                {/*<DateTime
                  value={scheduleDate}
                  dateFormat="DD MMM YYYY"
                  timeFormat=""
                  onChange={onScheduleDateChange}
                  isValidDate={inValidScheduleDate}
                  closeOnSelect={true}
                />*/}
                <div className="gIpt">
                  <DatePicker selected={scheduleDate} onChange={onScheduleDateChange}
                    dateFormat="dd MMM yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="itemBox">
              <div className="gLabel">
                Time
              </div>
              <div className="gInput">
              {/*<DateTime
                value={scheduleTime}
                dateFormat=""
                timeFormat="h:mm A"
                onChange={onScheduleTimeChange}
                closeOnSelect={true}
                timeConstraints={timeConstraintsScheduleTime}
              />*/}
              <CustomTimePicker
                value={moment(scheduleTime).format('h:mm A')}
                onChange={onScheduleTimeChange}
              />
              </div>
            </div>
          </div>
        }
        {
          whenError &&
          <div className="errorRow">
            <div className="gError">
              <img src={requiredIcon} /> When to send is required
            </div>
          </div>
        }
      </div>
    );
  };

  const renderMessage = () => {
    if (!broadcastType) return null;
    if (broadcastType === 'one-time' && !when) return null;
    return (
      <div className="cardWrap noBorder">
        <div className="titleRow">
          <p className="title">Message to broadcast</p>
          <p className="info">Compose the message which will be sent to guests. Choose the message templates or enter your own message.</p>
        </div>
        <div className="messageRow">
          <div className="itemBox">
            <div className="gIpt gTextarea">
              <textarea placeholder="Message" name="message" onChange={onChange} value={message} ref={messageEl} rows="4"></textarea>
            </div>
            {
              messageError &&
              <div className="gError">
                <img src={requiredIcon} /> Message is required
              </div>
            }
          </div>
        </div>
        <div className="templatesRow">
          {
            props.settings.engage && props.settings.engage.message_templates &&
            props.settings.engage.message_templates.list && props.settings.engage.message_templates.list.length > 0 &&
            props.settings.engage.message_templates.list.filter(el => !props.settings.engage.message_templates.keyValue[el].is_removed)
            .map((item) => {
              const mt = props.settings.engage.message_templates.keyValue[item];
              if (!mt) return null
              return <MessageTemplateButton template={mt} onButtonClick={onTemplateClick}/>
            })
          }
        </div>
        <div className="personalizeRow">
          <div className="itemBox personalizeBox">
            <div className="gLabel">Choose dynamic label to insert (Optional)</div>
            <div className="gLabelInfo">
              Choose dynamic labels to customize the message to use guest's first name and last name in messages.
            </div>
            <div className="gInput">
              <Select
                options={personalizeOptions}
                value={personalizeValue}
                onChange={onPersonalizeChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderName = () => {
    if (!broadcastType) return null;
    if (broadcastType === 'one-time' && !when) return null;
    return (
      <div className="cardWrap">
        <div className="titleRow">
          <p className="title">Internal Broadcast name ( for your reference)</p>
          <p className="info">Enter a friendly name to easily remember the broadcast.</p>
        </div>
        <div className="nameRow">
          <div className="itemBox">
            <div className="gIpt">
              <input placeholder="Name" name="name" onChange={onNameChange} value={name} />
            </div>
            {
              nameError &&
              <div className="gError">
                <img src={requiredIcon} /> Name is required
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  const renderStatus = (broadcast, broadcastInfo) => {
    if (broadcast.addStatus && broadcast.addStatus.error) {
      return (
        <div className="statusRow">
          <p>{ props.mode === 'add' ? 'Error in creating!' : 'Error in updating!'}</p>
        </div>
      );
    }
    if (broadcast.addStatus && broadcast.addStatus.success) {
      return (
        <div className="statusRow">
          <p>Created successfully.</p>
        </div>
      );
    }
    if (broadcastInfo && broadcastInfo.editStatus && broadcastInfo.editStatus.error) {
      return (
        <div className="statusRow">
          <p>Error in updating!</p>
        </div>
      );
    }
    if (broadcastInfo && broadcastInfo.editStatus && broadcastInfo.editStatus.success) {
      return (
        <div className="statusRow">
          <p>Updated successfully.</p>
        </div>
      );
    }
    return null;
  }

  const renderButtons = (broadcast) => {
    if (!broadcastType) return null;
    let buttonText = 'Create';
    if (props.mode === 'edit') buttonText = 'Update';
    if (when === 'now') {
      buttonText = `Send Now`;
      if (props.mode === 'edit') { buttonText = `Update - ${buttonText}`; }
    }
    if (when === 'later') {
      buttonText = `Schedule Broadcast`;
      if (props.mode === 'edit') { buttonText = `Update - Scheduled Broadcast`; }
    }
    return (
      <div className="buttonsRow">
        {
          props.mode === 'add' &&
          <button className={'create ' + (someChange ? '' : 'disabled')} onClick={onCreateClick}>
            {buttonText} { props.engage.broadcast.addInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        }
        {
          props.mode === 'edit' &&
          <button className={'create edit ' + (someChange ? '' : 'disabled')} onClick={onEditClick}>
            {buttonText} { broadcast.editInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        }
      </div>
    );
  }

  const renderAllBroadcasts = () => {
    const { list, keyValue } = props.engage.broadcast;
    const cStyle = {
      height: props.windowState.window.windowHeight - 120,
    };
    if (!Object.keys(list).length) return null;
    return (
      <div className="allBroadcastsWrap">
        <div className="title"><p>Similar Broadcasts</p></div>
        <div className="list" style={{}}>
          {
            list.map((item) => {
              if (keyValue[item]) {
                if (broadcastType) {
                  if (keyValue[item].type !== broadcastType) return null;
                }
                if (targetValue && targetValue.value) {
                  if (keyValue[item].target !== targetValue.value) return null;
                }
                return <SingleBroadcastPreview broadcast={keyValue[item]} />;
              }
              return null;
            })
          }
        </div>
      </div>
    );
  }

  const renderContent = (broadcast, broadcastInfo) => {
    return (
      <>
        <div className="addEditBroadcast">
          <div className="gPageWrap newBroadcast">
            {/*<h4 className="gPageTitle title">
              <Link className="all" to={'/engage/broadcast/all'}>All</Link> >>
              { props.mode === 'add' ? ' New Broadcast' : ` Edit Broadcast - ${broadcast.name}` }
            </h4>*/}
            <div className="cardWrap">
              <div className="titleRow">
                <p className="title">Select Broadcast Frequency</p>
                <p className="info">Choose the type of broadcast, recurring is sent repeatedly and one time is sent just once.</p>
              </div>
              <div className="tabRow">
                <div className={'itemBox ' + (broadcastType === 'one-time' ? 'selected' : '')} onClick={onTypeOneTimeClick}>
                  { broadcastType === 'one-time' ? <img src={oneTimeActiveIcon}/> : <img src={oneTimeIcon}/> }One Time
                </div>
                <div className={'itemBox ' + (broadcastType === 'recurring' ? 'selected' : '')} onClick={onTypeRecurringClick}>
                  { broadcastType === 'recurring' ? <img src={recurringActiveIcon}/> : <img src={recurringIcon}/> }Recurring
                </div>
              </div>
            </div>
            { renderTarget() }
            { renderWhen() }
            { renderMessage() }
            { renderName() }
            { renderStatus(broadcast, broadcastInfo) }
            { !props.isReadOnly && renderButtons(broadcastInfo) }
            {
              props.isReadOnly &&
              <div className="gBCtRw rdOlyRw">
                <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
              </div>
            }
          </div>
          { renderAllBroadcasts() }
        </div>
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('BroadcastSingle props: ', props);
  }

  const { broadcast } = props.engage;
  if (!broadcast) return null;

  const broadcastInfo = props.broadcastId && broadcast && broadcast.keyValue &&
    broadcast.keyValue[props.broadcastId];

  return (
    <div className="gPWrp">
      <div className="gPTle title">
        Edit Broadcast{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
      </div>
      {
        broadcastInfo && broadcastInfo.name &&
        <div className="gPSubTle title">
          {broadcastInfo.name}
        </div>
      }
      <div className="gBCtnt">
        { renderContent(broadcast, broadcastInfo) }
      </div>
    </div>
  );
}

export default BroadcastSingle;
