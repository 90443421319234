import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import RouteLeavingGuard from '../../../Misc/RouteLeavingGuard';
import BusinessHours from '../../../Misc/BusinessHours';
import ToggleAndData from '../../../Misc/ToggleAndData';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../redux/actions/snacksActions';
import { ENGAGE_MEDIUM_TO_LABEL, EMAIL_FOR_SMS_OPTIONS, STRS } from '../../../../helpers';

import whatsappIcon from '../../../../img/icons/whatsapp.png';
import smsIcon from '../../../../img/icons/sms.svg';
import emailIcon from '../../../../img/icons/email.svg';
import googleIcon from '../../../../img/icons/google-logo.png';

import './style.css';


const mediumToIcon = (medium) => {
  switch (medium) {
    case 'sms':
      return smsIcon;
    case 'whatsapp':
      return whatsappIcon;
    case 'email':
      return emailIcon;
    case 'bm':
      return googleIcon;
    default:
      return null;
  }
};

function ListItem(props) {
  const onChange = (e) => {
    props.onChange(props.data.value);
  };
  const { data, selectedValue } = props;
  return (
    <>
      <div className="gRwBxLt">
        <div className="gLbl">
          <label>
            <input
              type="radio"
              name="maxEntries"
              value={data.value}
              checked={selectedValue === data.value}
              onChange={onChange}
            />
            {data.label}
          </label>
        </div>
      </div>
    </>
  );
}

function MessagingChannels(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [channels, setChannels] = useState({});
  const [defaultMessagingChannel, setDefaultMessagingChannel] = useState('');

  const [emailSettingForSmsJourney, setEmailSettingForSmsJourney] = useState('');

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Messaging Channels - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { engage } = props.settings;
    if (engage) {
      const { channels, default_messaging_channel, email_setting_for_sms_journey } = engage;
      if (channels) setChannels(channels);
      setDefaultMessagingChannel(default_messaging_channel || '');
      setEmailSettingForSmsJourney(email_setting_for_sms_journey || '');
      setFormChanged(false);
    }
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (props.settings.engage &&
      !props.settings.engage.editInProgress &&
      props.settings.engage.editStatus &&
      props.settings.engage.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.engage]);

  const onChange = (e) => {
    const { name, checked } = e.target;
    if (name !== 'whatsapp' && name !== 'sms' && name !== 'email') return;

    // show error if default channel is being turned off
    if (!checked && name === defaultMessagingChannel) {
      const id = new Date().getTime();
      dispatch(receiveSnack({
        snack: {
          id,
          msg: 'Default channel cannot be turned off. Please choose a different default channel first.',
        },
      }));
      // remove the snack
      setTimeout(() => {
        dispatch(removeSnack({
          id,
        }));
      }, 2000);
      return;
    }

    setChannels(channel => ({
      ...channel,
      [name]: {
        ...channel[name],
        is_active: checked,
      },
    }));
    if (name === 'email' && !checked) {
      setEmailSettingForSmsJourney('do-not-send');
    }
    setFormChanged(true);
  };

  const makeDefault = (value) => {
    setDefaultMessagingChannel(value);
    setFormChanged(true);
  };

  const onEmailSettingForSmsJourneyChange = (value) => {
    setEmailSettingForSmsJourney(value);
    setFormChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
    };
    params['channels'] = channels;
    params['default_messaging_channel'] = defaultMessagingChannel;
    params['email_setting_for_sms_journey'] = emailSettingForSmsJourney;
    // if (channels && channels.email && !channels.email.is_active) {
    //   params['email_setting_for_sms_journey'] = 'do-not-send';
    // }
    params.source = 'engage';

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }

    dispatch(editDataAction({
      op: 'edit_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };

  const onSmsShowArticleClick = (e) => {
    try { window.Intercom('showArticle', 8738544); } catch(e) {}
  };
  const onSmsEnableClick = (e) => {
    try { window.Intercom('showNewMessage', 'Enable SMS messaging for us'); } catch(e) {}
  };

  const onWhatsappShowArticleClick = (e) => {
    try { window.Intercom('showArticle', 8992398); } catch(e) {}
  };
  const onWhatsappEnableClick = (e) => {
    try { window.Intercom('showNewMessage', 'Enable Whatsapp messaging for us'); } catch(e) {}
  };

  const onEmailShowArticleClick = (e) => {
    try { window.Intercom('showArticle', 9234212); } catch(e) {}
  };
  const onEmailEnableClick = (e) => {
    try { window.Intercom('showNewMessage', 'Enable Email messaging for us'); } catch(e) {}
  };

  const onWhatsappKnowMoreClick = (e) => {
    try { window.Intercom('showArticle', 8992398); } catch(e) {}
  }

  const renderChannel = (title, name, isActive, isAvailable, emailChannel) => {
    let smsNumber = '';
    let whatsappNumber = '';
    if (props.locationInfo &&
      props.locationInfo.location_id
    ) {
      smsNumber = props.locationInfo.sms_number ?
        formatPhoneNumberIntl(props.locationInfo.sms_number) : '';
      whatsappNumber = props.locationInfo.whatsapp_number ?
        formatPhoneNumberIntl(props.locationInfo.whatsapp_number) : '';
    }
    return (
      <>
      <div className={'gBCtRw hasTgl ' + (name) + (isActive && emailChannel && emailChannel.is_active ? ' noMrgBtm ' : '')}
        key={`e_mc_${name}`}
      >
        {
          isAvailable &&
          <>
            <div className="lt">
              <p className="title">
                {ENGAGE_MEDIUM_TO_LABEL[name]}
                { name === 'whatsapp' && 
                  <span className="whatsappKnwMor" onClick={onWhatsappKnowMoreClick}>
                    Learn More <i className="fas fa-circle-info" />
                  </span>
                }
              </p>
              { name === 'sms' && <p className="desc">{smsNumber}</p> }
              { name === 'whatsapp' && 
                <p className="desc">
                  {whatsappNumber}  
                </p>
              }
              { name === 'email' && <p className="desc">{props.locationInfo.engage_email || ''}</p> }
              {
                isActive &&
                <>
                  {
                    name === defaultMessagingChannel
                    ? <p className="default active">Default</p>
                    : <p className="default" onClick={() => makeDefault(name)}>Set as Default</p>
                  }
                </>
              }
            </div>
            <div className="rt">
              <div className="gToggleWrap">
                <div className="gToggle">
                  <input type="checkbox" id={name} name={name} checked={isActive} onChange={onChange}/><label htmlFor={name}>Toggle</label>
                </div>
              </div>
            </div>
          </>
        }
        {
          !isAvailable &&
          <>
            <div className="lt">
              <p className="title">{ENGAGE_MEDIUM_TO_LABEL[name]}</p>
            </div>
            <div className="rt">
              <span>Request Access</span>
            </div>
          </>
        }
      </div>
      {
        name === 'sms' && isActive && emailChannel && emailChannel.is_active && defaultMessagingChannel !== 'email' &&
        <>
        <div className="gBCtRw emailSmsOpts">
          <div className="gRwBxLt">
            <div className="gLbl">
              Should journey messages be sent on email ?
            </div>
          </div>
          {
            EMAIL_FOR_SMS_OPTIONS.map((item) => {
              return (
                <ListItem data={item} onChange={onEmailSettingForSmsJourneyChange} selectedValue={emailSettingForSmsJourney} key={`gldp_${item.value}`} />
              );
            })
          }
        </div>
        </>
      }
      </>
    );
  };

  const renderDefaultChannel = () => {
    if (!defaultMessagingChannel) return null;
    return (
      <div className="gBCtRw defChnl">
        <div className="lt">
          <p className="title">Your default communication channel</p>
          <p className="desc">This channel will be used to send guest journey and automated messages.</p>
          <p className="defChnl">
            <img src={mediumToIcon(defaultMessagingChannel)} title={ENGAGE_MEDIUM_TO_LABEL[defaultMessagingChannel]} />
            <span>{ENGAGE_MEDIUM_TO_LABEL[defaultMessagingChannel]}</span>
          </p>
        </div>
      </div>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('MessagingChannels: ', props, ' # ', props);
  }

  if (!props.locationInfo.has_engage) return null;

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen msgChnls">
        <div className="gPTle title">
          Messaging Channels{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderDefaultChannel() }
          {
            channels
            ? Object.keys(channels).map((channel) => {
                return renderChannel(channel, channel, channels[channel].is_active, channels[channel].is_available, channels['email'])
              })
            : null
          }
          {
            !channels.sms &&
            <div className="gBCtRw actv">
              <div className="lt">
                <p className="title">{ENGAGE_MEDIUM_TO_LABEL['sms']}</p>
                {/* <p className="actv"><span onClick={onSmsShowArticleClick}>Learn More</span></p> */}
              </div>
              <div className="rt">
              <p className="actv"><span onClick={onSmsEnableClick}>Request to activate</span></p>
              </div>
            </div>
          }
          {
            !channels.whatsapp &&
            <div className="gBCtRw actv">
              <div className="lt">
                <p className="title">{ENGAGE_MEDIUM_TO_LABEL['whatsapp']}</p>
                <p className="actv"><span onClick={onWhatsappShowArticleClick}>Learn More</span></p>
              </div>
              <div className="rt">
              <p className="actv"><span onClick={onWhatsappEnableClick}>Request to activate</span></p>
              </div>
            </div>
          }
          {
            !channels.email &&
            <div className="gBCtRw actv">
              <div className="lt">
                <p className="title">{ENGAGE_MEDIUM_TO_LABEL['email']}</p>
                <p className="actv"><span onClick={onEmailShowArticleClick}>Learn More</span></p>
              </div>
              <div className="rt">
              <p className="actv"><span onClick={onEmailEnableClick}>Request to activate</span></p>
              </div>
            </div>
          }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.settings.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default MessagingChannels;
