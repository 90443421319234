import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";


import closeIcon from '../../../../../img/webchat/close.svg';
import searchIcon from '../../../../../img/webchat/search.svg';
import connectNowIcon from '../../../../../img/webchat/connect-now.svg';
import moreIcon from '../../../../../img/webchat/more.svg';
// import wifiIcon from '../../../../../img/webchat/wifi.svg';
import backIcon from '../../../../../img/webchat/back.svg';

import smsIcon from '../../../../../img/webchat/sms.svg';
import whatsappIcon from '../../../../../img/webchat/whatsapp.png';
import gbmIcon from '../../../../../img/webchat/gbm.png';

import './style.css';


function TopicRow(props) {
  return (
    <div className="topicRow">
      <div className="left">
        <div className="icon">
          <img src={props.data.icon} />
        </div>
        <div className="label">
          {props.data.title}
        </div>
      </div>
      <div className="right">
        <div className="more">
          <img src={moreIcon} />
        </div>
      </div>
    </div>
  );
}

function ChannelRow(props) {
  return (
    <div className="channelRow">
      <div className="left">
        <div className="icon">
          <img src={props.data.icon} />
        </div>
        <div className="label">
          <p>{props.data.title}</p>
          {
            props.data.subTitle &&
            <span>{props.data.subTitle}</span>
          }
        </div>
      </div>
    </div>
  );
}

function WCPreview(props) {

  const renderConnectWithUsBox = (general) => {
    let cStyle = {};
    if (general.custom_theme) {
      if (general.custom_theme.color) cStyle.backgroundColor = general.custom_theme.color;
    }
    return (
      <div className="wcGBox connectBox">
        <p className="title">{general.connect.title}</p>
        <p className="subTitle">{general.connect.desc}</p>
        <div className="connectNowBtn" style={cStyle}>
          <img src={connectNowIcon} /> {general.connect.button_label}
        </div>
      </div>
    );
  };

  const renderChooseChannelBox = (general, channels) => {
    return (
      <div className="wcGBox">
        <p className="title">{general.channels.title}</p>
        <p className="subTitle">{general.channels.desc}</p>
        <div className="channelsBox">
          {
            channels && channels.sms && channels.sms.is_enabled &&
            <ChannelRow
              data={{
                title: 'SMS',
                subTitle: '*International Tariffs applicable',
                icon: smsIcon,
              }}
            />
          }
          {
            channels && channels.whatsapp && channels.whatsapp.is_enabled &&
            <ChannelRow
              data={{
                title: 'WhatsApp',
                icon: whatsappIcon,
              }}
            />
          }
          {
            channels && channels.gbm && channels.gbm.is_enabled &&
            <ChannelRow
              data={{
                title: 'Google Business Messages',
                icon: gbmIcon,
              }}
            />
          }
        </div>
      </div>
    );
  };

  const renderConfigureChannelSmsBox = (channels) => {
    return (
      <div className="wcGBox">
        <p className="title">
          <div className="icon">
            <img src={smsIcon} />
          </div>
        </p>
        <p className="title">{channels.sms.success_title}</p>
        <p className="subTitle">{channels.sms.success_desc}</p>
      </div>
    );
  };

  const renderConfigureChannelWhatsappBox = (channels) => {
    return (
      <div className="wcGBox">
        <p className="title">
          <div className="icon">
            <img src={whatsappIcon} />
          </div>
        </p>
        <p className="title">{channels.whatsapp.success_title}</p>
        <p className="subTitle">{channels.whatsapp.success_desc}</p>
      </div>
    );
  };

  const renderTopicsListBox = (general, helpGuide) => {
    return(
      <div className="wcGBox queriesBox">
        <p className="title">{general.guide.title}</p>
        <p className="subTitle">{general.guide.desc}</p>
        <div className="topicsBox">
          {
            helpGuide && helpGuide.topics &&
            helpGuide.topics.map((item) => {
              if (item.is_hidden) return null;
              return <TopicRow data={item} />
            })
          }
        </div>
      </div>
    );
  };

  const renderHeader = (general) => {
    const { screen } = props;
    const showBack = screen === 'channels' || screen === 'channels-sms' || screen === 'channels-whatsapp';
    let promptBoxClass = '';
    let msg = general.intro.title;
    if (screen === 'channels' || screen === 'channels-sms' || screen === 'channels-whatsapp') {
      msg = 'Connect with us';
      promptBoxClass = 'addMargin';
    }
    let cStyle = {};
    if (general.custom_theme) {
      if (general.custom_theme.color) cStyle.backgroundColor = general.custom_theme.color;
    }
    return (
      <div className="header" style={cStyle}>
        <div className="top">
          <div className="left">
            {
              showBack &&
              <div className="backIcon">
                <img src={backIcon} />
              </div>
            }
            <div className="avataarIcon">
              <img src={general.avataar_logo_url} />
            </div>
          </div>
          <div className="right">
            <div className="imgBox closeBox">
              <img src={closeIcon} />
            </div>
          </div>
        </div>
        <div className={'promptBox ' + (promptBoxClass || '')}>
          {msg}
        </div>
        {
          screen === 'home' &&
          <div className="searchBox">
            <img src={searchIcon} />
            <input type="text" placeholder="Search for queries…." />
          </div>
        }
      </div>
    );
  };

  const renderBody = (webchatSettings) => {
    const { screen } = props;
    const { general, helpGuide, channels } = webchatSettings;
    return (
      <div className="body">
        { screen === 'home' && renderConnectWithUsBox(general) }
        { screen === 'channels-sms' && renderConfigureChannelSmsBox(channels) }
        { screen === 'channels-whatsapp' && renderConfigureChannelWhatsappBox(channels) }
        { screen === 'channels' && renderChooseChannelBox(general, channels) }
        { screen === 'home' && renderTopicsListBox(general, helpGuide) }
      </div>
    );
  };

  const renderContent = (webchatSettings) => {
    const { screen } = props;
    const { general, helpGuide } = webchatSettings;
    if (!general) return null;
    const theme = props.data.theme || general.theme;
    return (
      <div className={'wcPreview ' + (theme ? `${theme}` : '')}>
        { renderHeader(general) }
        { renderBody(webchatSettings) }
      </div>
    );
  };

  const { webchat } = props.settings;
  return renderContent(webchat);

};

export default WCPreview;
