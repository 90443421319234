// @flow

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

// import YesNoToggle from '../../../Misc/YesNoToggle';

import closeIcon from '../../../img/icons/close.svg';

// import { MSGTYPE_TO_LABEL } from '../../../../helpers';

import './style.css';


function ImagePreviewModal(props) {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');


  const onCloseClick = (e) => {
    props.onCancelClick('');
  };
  const onConfirmClick = (e) => {
    props.onConfirmClick('');
  };
  const onAddClick = (e) => {

  };

  const renderMessage = () => {
    return (
      <div className="imgPrv">
        <h4 className="title">
          {
            props.onCancelClick && <span className="close" onClick={onCloseClick}><i className="fa-solid fa-xmark"></i></span>
          }
        </h4>
        <div className="imgBx">
          <img src={props.image} />
        </div>
      </div>
    );
  };


  const renderContent = () => {
    return renderMessage();
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('ImagePreviewModal props: ', props);
  }

  return renderContent();

}

export default ImagePreviewModal;
