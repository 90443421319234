import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../Misc/OutsideClickHook';

import { MSGTYPE_TO_LABEL } from '../../../../helpers';

import emojiIcon from '../../../../img/icons/emoji.svg';
import closeIcon from '../../../../img/icons/close.svg';

import '../style.css';

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{firstname}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{lastname}}' });
// personalizeOptionsG.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
personalizeOptionsG.push({ label: 'Room Type', value: '{{roomtype}}' });
personalizeOptionsG.push({ label: 'Room Number', value: '{{roomnumber}}' });

function AddWhatsappTemplate(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const [personalizeOptions, setPersonalizeOptions] = useState([
    // { label: 'Personalize', value: 'personalize' },
    { label: 'First Name', value: '{{firstname}}' },
    { label: 'Last Name', value: '{{lastname}}' },
    { label: 'Wifi', value: '{{wifi}}' },
  ]);
  const [personalizeValues, setPersonalizeValues] = useState([
    { label: 'Personalize', value: 'personalize' }
  ]);

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const [pickerStyle, setPickerStyle] = useState({
    height: 0,
  });
  const emojiSelectorRef = useRef(null);
  // useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));

  const messageEl = useRef(null);
  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Settings - Engage - Templates - Whatsapp - Add - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    // let page_title = `${props.locationprops.location.name} - Settings - Manage Templates`;
    // if (props.template && props.template._id) {
    //   page_title = `${page_title} - Edit - ${props.template.title || ''}`;
    // } else {
    //   page_title = `${page_title} - Add`;
    // }
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title,
    //   user_id: props.userprops.user.uid,
    // });
  }, []);

  useEffect(() => {
    if (props.mode === 'view') {
      setTitle(props.template.title);
      setMessage(props.template.msg);
    } else {
      // props.onAddClear();

      let tOptions = [ ...personalizeOptionsG ];

      const { locationidentifier } = urlParams;
      if (locationState.locations[locationidentifier]) {
        const { settings } = locationState.locations[locationidentifier];
        if (settings && settings.engage) {
          if (settings.engage.default_review_link) {
            tOptions.push({ label: 'Review Link', value: '{{reviewlink}}' });
          }
          if (settings.engage.self_service_link_enabled) {
            tOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
          }
        }
      }
      setPersonalizeOptions([
        ...tOptions,
        // ...personalizeOptionsG,
        // { text: 'Wifi', value: '{{wifi}}' },
      ]);
      if (props.template && props.template._id) {
        setTitle(props.template.title);
        setMessage(props.template.msg);
      }
      setSomeChange(false);
      dispatch(editDataClearAction({
        op: 'add_whatsapp_message_template',
        params: {
          location__id: urlParams.locationidentifier
        },
      }));
      // dispatch(editDataClearAction({
      //   op: 'edit_message_template',
      //   params: {
      //     location__id: locationidentifier, // 'wZYeknJ4QgyGXj7Jm',
      //     mode: props.type,
      //     message_template_id: props.template._id,
      //   },
      // }));
      // setTimeout(() => {
      //   if (titleRef && titleRef.current) {
      //     titleRef.current.focus();
      //   }
      // }, 100);
    }
  }, []);

  useEffect(() => {
    if (props.settings.engage.added_whatsapp_templates &&
      !props.settings.engage.added_whatsapp_templates.addInProgress &&
      props.settings.engage.added_whatsapp_templates.addStatus &&
      props.settings.engage.added_whatsapp_templates.addStatus.success
    ) {
      setTitle('');
      setMessage('');
      setSomeChange(false);
    }
  }, [props.settings.engage.added_whatsapp_templates]);


  const onTitleChange = (e) => {
    const { name, value } = e.target;
    if (value.indexOf('[') > -1 || value.indexOf(']') > -1) return;
    setTitle(value); setSomeChange(true);
    // props.onAddClear();
    dispatch(editDataClearAction({
      op: 'add_whatsapp_message_template',
      params: {
        location__id: urlParams.locationidentifier
      },
    }));
    // props.onAddEditClear(props.template);
    // dispatch(editDataClearAction({
    //   op: 'edit_message_template',
    //   params: {
    //     location__id: locationidentifier, // 'wZYeknJ4QgyGXj7Jm',
    //     mode: props.type,
    //     message_template_id: props.template._id,
    //   },
    // }));
    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
  }
  const onMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    setSomeChange(true);
    dispatch(editDataClearAction({
      op: 'add_whatsapp_message_template',
      params: {
        location__id: urlParams.locationidentifier
      },
    }));
    // props.onAddEditClear(props.template);
    // dispatch(editDataClearAction({
    //   op: 'edit_message_template',
    //   params: {
    //     location__id: locationidentifier, // 'wZYeknJ4QgyGXj7Jm',
    //     mode: props.type,
    //     message_template_id: props.template._id,
    //   },
    // }));
    if (!value) {
      setMessageError('error');
    } else {
      setMessageError('');
    }
  };

  const onOpenEmojiSelectorClick = (e) => {
    if (props.mode === 'view') return;
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      setSomeChange(true);
      if (message) {
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${emoji.native}`);
      }
      // gtag('event', 'gt_click', {
      //   'event_category': 'Settings - Manage Templates',
      //   'event_label': 'Selected Emoji',
      //   // 'value': 1,
      //   user_id: props.userprops.user.uid,
      // });
    }
  }

  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
      console.log('onPersonalizeChange: ', value);
    }
    if (value && value.value !== 'personalize') {
      // setMessage(message => `${message} ${values[0].text}`);
      setSomeChange(true);
      if (message) {
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${value.value}`);
      }
      // gtag('event', 'gt_click', {
      //   'event_category': 'Settings - Manage Templates',
      //   'event_label': `Selected Personalize - ${value.value}`,
      //   // 'value': 1,
      //   user_id: props.userprops.user.uid,
      // });
    }
  };

  const onCloseClick = (e) => {
    dispatch(editDataClearAction({
      op: 'add_whatsapp_message_template',
      params: {
        location__id: urlParams.locationidentifier
      },
    }));
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    dispatch(editDataClearAction({
      op: 'add_whatsapp_message_template',
      params: {
        location__id: urlParams.locationidentifier
      },
    }));
    props.onCancelClick(false);
  };

  const onAddClick = (e) => {
    if (props.addInProgress) return;

    dispatch(editDataClearAction({
      op: 'add_whatsapp_message_template',
      params: {
        location__id: urlParams.locationidentifier
      },
    }));

    if (!title) setTitleError('error');
    if (!message) setMessageError('error');
    if (!message || !title) return;

    if (someChange) {
      if (title && message) {
        const params = {
          title,
          msg: message,
        };
        if (props.messageType === 'preArrivalMessage') params.type = 'pre_arrival_msg';
        if (props.messageType === 'welcomeMessage') params.type = 'welcome_msg';
        if (props.messageType === 'repeatWelcomeMessage') params.type = 'repeat_welcome_msg';
        if (props.messageType === 'departureMessage') params.type = 'departure_msg';
        if (props.messageType === 'automatedNonBusinessHoursMessage') params.type = 'starter';
        if (props.messageType === 'starter') params.type = 'starter';
        if (props.messageType === 'webchatWhatsappStarterMessage') params.type = 'webchat_starter_msg';
        if (props.messageType === 'webchatWhatsappNbhStarterMessage') params.type = 'webchat_non_business_hours_starter_msg';
        dispatch(editDataAction({
          op: 'add_whatsapp_message_template',
          show_error_alert: false,
          params: {
            location__id: urlParams.locationidentifier,
            ...params,
          },
        }));
      }
    }
  };

  const renderContent = () => {
    const { locationidentifier } = urlParams;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.engage) return null;

    const addInProgress = settings.engage.added_whatsapp_templates
      ? settings.engage.added_whatsapp_templates.addInProgress || false : false;
    const addStatus = settings.engage.added_whatsapp_templates
      ? settings.engage.added_whatsapp_templates.addStatus || {} : {};

    let pageTitle = '';
    if (props.mode === 'view') {
      pageTitle = `Added template for ${MSGTYPE_TO_LABEL[props.messageType]}`;
    } else {
      pageTitle = `Add template for ${MSGTYPE_TO_LABEL[props.messageType]}`;
    }
    if (props.title) pageTitle = props.title;


    return (
      <div className="gPWrp">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Title
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" ref={titleRef}
                  readOnly={props.mode === 'view'}
                />
                { titleError && <p className="gErr">Title is required</p> }
              </div>
            </div>
          </div>
          <div className={'gBCtRw messageRow' + (messageError ? ' error' : '')}>
            <div className="gRwBxLt">
              <div className="gLbl">
                Message
              </div>
              <div className={'gIpt ' + (messageError ? 'error' : '')}>
                <textarea rows="4" placeholder="Message"
                  onChange={onMessageChange}
                  placeholder="Message..."
                  value={message}
                  ref={messageEl}
                  readOnly={props.mode === 'view'}
                />
                { messageError && <p className="gErr">Message is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw contentRow emojiRow">
            <div className={'gRwBxLt emojiBox ' + (messageError ? 'messageError' : '')}>
              <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                {
                  emojiSelectorOpen
                  ? <img className="closeIcon" src={closeIcon} />
                  : <img src={emojiIcon} />
                }

              </div>
            </div>
          </div>
          { emojiSelectorOpen &&
            <div className="emojiBox" ref={emojiSelectorRef}>
              <Picker
                data={emojiData}
                theme="light"
                onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
              />
            </div>
          }
          {
            props.mode !== 'view' &&
            (props.messageType === 'starter' ||
              props.type === 'preArrivalMessage' || props.type === 'welcomeMessage' ||
              props.type === 'repeatWelcomeMessage' || props.type === 'departureMessage'
            ) &&
            <div className="gBCtRw contentRow personalizeRow">
              <div className="gRwBxLt personalizeBox">
                <label>Choose dynamic label to insert</label>
                {/*<Select
                  options={personalizeOptions}
                  labelField="text"
                  valueField="value"
                  values={personalizeValues}
                  onChange={onPersonalizeChange}
                />*/}
                <Select options={personalizeOptions}
                  onChange={onPersonalizeChange}
                  value={personalizeValues}
                />
              </div>
              <div className="personalizeBoxNA" />
            </div>
          }
          <div className="gBCtRw helpText">
            <p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests for personalized messages. You can add wifi code using {"{{wifi}}"}. You can also add review link using {"{{reviewlink}}"}.</p>
          </div>
          {
            addStatus && (addStatus.success || addStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addStatus.error && <span className="gErr">{addStatus.message || 'Error in adding!'}</span>
              }
              {
                addStatus.success && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {
              props.mode !== 'view' &&
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddClick} >
                Add
                { addInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            }
            {
              props.mode === 'view' &&
              <button className={'gBtn '} onClick={onCloseClick}>
                Close
              </button>
            }
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('AddWhatsappTemplate props: ', props);
  }

  return renderContent();
}

export default AddWhatsappTemplate;
