import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

function ResponseTemplateRow(props) {
  const onDefaultClick = (e) => {
    props.onDefaultClick(props.template);
  };
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };


  const { template, settings } = props;
  const { reputation } = settings;
  if (!reputation) return;
  const { response_template_categories } = reputation;
  let categoryName = '';
  if (props.template.category_id &&
    response_template_categories && response_template_categories.keyValue &&
    response_template_categories.keyValue[props.template.category_id]
  ) {
    categoryName = response_template_categories.keyValue[props.template.category_id].name;
  }

  return (
    <div className="gBCtRw respTplRw" id={`mt_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.title}
              />
            : template.title
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Category">
              <div className="catNm">
                {
                  props.keyword
                  ?
                    <Highlighter
                      highlightClassName="highlightClass"
                      searchWords={[props.keyword]}
                      autoEscape={true}
                      textToHighlight={categoryName}
                    />
                  : categoryName
                }
              </div>
            </ToolTipWrapper>
            {
              props.templateType === 'promptTemplates' &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={template.is_default ? 'Clear default status' : 'Mark as default'}>
                <span className={(template.is_default ? 'is_def' : '')} onClick={onDefaultClick}><img className={'def'} src={tickIcon} /></span>
              </ToolTipWrapper>
            }
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
              <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
            </>
          }
        </div>
      </div>
      <div className="bt">
        {
          props.templateType === 'responseTemplates' &&
          <>
            {
              props.keyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.keyword]}
                  autoEscape={true}
                  textToHighlight={template.response}
                />
              : template.response
            }
          </>
        }
        {
          props.templateType === 'promptTemplates' &&
          <>
            {
              props.keyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.keyword]}
                  autoEscape={true}
                  textToHighlight={template.prompt}
                />
              : template.prompt
            }
          </>
        }
      </div>
    </div>
  )

}

export default ResponseTemplateRow;
