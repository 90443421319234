import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Pagination from 'rc-pagination';

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import BarGroupChart from '../../../Charts/BarGroupChart';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import TransactionEmailTypeStatusCard from '../../../Misc/TransactionEmailTypeStatusCard';
import EmailSendingStatDetailCard from '../../../Misc/EmailSendingStatDetailCard';
import useDebounce from '../../../Misc/DebounceHook';
import SingleTransactionEmailHistoryBox from './SingleTransactionEmailHistoryBox';
import SelectTransactionEmailModal from './SelectTransactionEmailModal';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { initMarketingAction } from '../../../../redux/actions/initActions';

import { DATE_DURATION_NEXT_OPTIONS_MAP, TRANSACTION_EMAIL_TYPE_TO_LABEL } from '../../../../helpers';

import refreshIcon from '../../../../img/icons/refresh-active.svg';

import './style.css';
import 'antd/dist/antd.css';
import "rc-pagination/assets/index.css";

const { RangePicker } = DatePicker;

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Next 7 days', value: 'next-7-days' });
lastMonthOptions.push({ label: 'Next 15 days', value: 'next-15-days' });
lastMonthOptions.push({ label: 'Next 1 Month', value: 'next-1-month' });
// lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
// lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
// lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

const perPageSelectOptions = [];
perPageSelectOptions.push({ label: '10', value: 10 });
perPageSelectOptions.push({ label: '25', value: 25 });
perPageSelectOptions.push({ label: '50', value: 50 });
perPageSelectOptions.push({ label: '100', value: 100 });
perPageSelectOptions.push({ label: '200', value: 200 });

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function Reservations(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [keyword, setKeyword] = useState('');
  const debouncedSearchTerm = useDebounce(keyword, 500);

  const [filteredList, setFilteredList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [reservationTypeOptions, setReservationTypeOptions] = useState([]);
  const [reservationTypeValue, setReservationTypeValue] = useState(
    { label: 'All', value: 'all' }
  );

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Next 7 Days', value: 'next-7-days' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date());
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date(moment().add(7, 'days')));

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [perPageSelectValue, setPerPageSelectValue] = useState(
    { label: '10', value: 10 }
  );

  const [selectedRows, setSelectedRows] = useState({});

  const [transactionEmailOptions, setTransactionEmailOptions] = useState([]);
  const [selectedTransactionEmailValue, setSelectedTransactionEmailValue] = useState(
    { label: 'Select email', value: 'select' }
  );
  const [transactionEmailError, setTransactionEmailError] = useState('');

  const [showSendTransactionEmailModal, setShowSendTransactionEmailModal] = useState(false);

  const [rowSendIsActive, setRowSendIsActive] = useState(false);

  const [showSelectTransactionEmailModal, setShowSelectTransactionEmailModal] = useState(false);

  const [selectedGuestsType, setSelectedGuestsType] = useState({});

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Marketing - Transaction Emails - Reservations - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { reservations } = props.marketing.transactionEmails;
    if (reservations && !reservations.sendCustomMultipleInProgress &&
      reservations.sendCustomMultipleStatus &&
      reservations.sendCustomMultipleStatus.success
    ) {
      setShowSendTransactionEmailModal(false);
      setSelectedRows({});
      setSelectedTransactionEmailValue({ label: 'Select email', value: 'select' });
    }
  }, [props.marketing.transactionEmails.reservations]);

  useEffect(() => {
    const { reservations } = props.marketing.transactionEmails;
    if (reservations &&
      !reservations.sendCustomMultipleInProgress &&
      reservations.sendCustomMultipleStatus &&
      reservations.sendCustomMultipleStatus.success
    ) {
      setShowSelectTransactionEmailModal(false);
    }
  }, [props.marketing.transactionEmails.reservations]);

  useEffect(() => {
    const { reservations } = props.marketing.transactionEmails;
    if (reservations && reservations.keyValue) {
      let tempData = {};
      Object.keys(selectedRows).forEach((item) => {
        const transaction_type = reservations.keyValue[item].transaction_type;
        if (!tempData[transaction_type]) {
          tempData[transaction_type] = true;
        }
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log('selectedRows change tempData: ', tempData);
      }
      setSelectedGuestsType(tempData);
    }
  }, [selectedRows]);

  const getData = (reservationType, startDate, endDate, searchKeyword, pSize, pageNum = 1) => {
    dispatch(getDataAction({
      op: 'get_transaction_emails_reservations',
      params: {
        location__id: urlParams.locationidentifier,
        reservation_type: reservationType,
        keyword: searchKeyword,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        skip: pSize * (pageNum - 1),
        limit: pSize,
      },
    }));
  };

  useEffect(() => {
    let tOptions = [];
      tOptions.push({ label: 'All', value: 'all' });
      tOptions.push({ label: 'Upcoming', value: 'upcoming' });
      tOptions.push({ label: 'Checked-In', value: 'check-in' });
      tOptions.push({ label: 'Checked-Out', value: 'check-out' });
      setReservationTypeOptions(tOptions);

      const { transaction_emails } = props.marketing.transactionEmails;
      if (transaction_emails && transaction_emails.list && transaction_emails.keyValue &&
        transaction_emails.list.length
      ) {
        tOptions = [];
        tOptions.push({ label: 'Select email', value: 'select' });
        transaction_emails.list.forEach((item) => {
          if (transaction_emails.keyValue[item]) {
            let ln1 = `${TRANSACTION_EMAIL_TYPE_TO_LABEL[transaction_emails.keyValue[item].type]} Email`;
            let ln2 = `Subject: ${transaction_emails.keyValue[item].subject}`;
            tOptions.push({ label: <div className="trEmLbl"><span className="ln1">{ln1}</span><span className="ln2">{ln2}</span></div>, value: item });
          }
        });
      }
      setTransactionEmailOptions(tOptions);

      // const { commonParams } = appState;
      // let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
      // let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
      // let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
      let startDate = new Date();
      let endDate = new Date(moment().add(7, 'days'));
      let rangeKeyword = 'next-7-days';
      setDateFilterStartDate(startDate);
      setDateFilterEndDate(endDate);
      setDateRangeLastMonthValue({ label: DATE_DURATION_NEXT_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
      getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize);
  }, []);

  useEffect(() => {
    const { reservations } = props.marketing.transactionEmails;
    if (props.marketing.transactionEmails && reservations &&
      !reservations.getListInProgress
    ) {
      setFilteredList(reservations.list || []);
      setTotalCount(reservations.total_count || 0);
    }
  }, [props.marketing.transactionEmails.reservations]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, debouncedSearchTerm, pageSize, 1);
    }
  }, [debouncedSearchTerm]);


  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('next-') > -1) {
        period = period.split('next-')[1];
        setDateFilterStartDate(new Date());
        setDateFilterEndDate(new Date(moment().add(period, 'days')));
        getData(reservationTypeValue.value || 'all', new Date(), new Date(moment().add(period, 'days')),  keyword, pageSize);
      }
      if (period.indexOf('last-') > -1) {
        period = period.split('last-')[1];
        setDateFilterStartDate(new Date(moment().subtract(period, 'days')));
        setDateFilterEndDate(new Date());
        getData(reservationTypeValue.value || 'all', new Date(moment().subtract(period, 'days')), new Date(),  keyword, pageSize);
      }
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('next-') > -1) {
        period = period.split('next-')[1];
        setDateFilterStartDate(new Date())
        setDateFilterEndDate(new Date(moment().add(period, 'month')));
        getData(reservationTypeValue.value || 'all', new Date(), new Date(moment().add(period, 'month')),  keyword, pageSize);
      }
      if (period.indexOf('last-') > -1) {
        period = period.split('last-')[1];
        setDateFilterStartDate(new Date(moment().subtract(period, 'month')));
        setDateFilterEndDate(new Date())
        getData(reservationTypeValue.value || 'all', new Date(moment().subtract(period, 'month')), new Date(),  keyword, pageSize);
      }
    }
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { transactionEmails } = props.marketing;
    if (transactionEmails && transactionEmails.reservations && transactionEmails.reservations.getInProgress) return;
    // setDateRangeActive(true);
    // DD MMM YYYY
    // setDateFilterStartDate(new Date(dates[0]));
    // setDateFilterEndDate(new Date(dates[1]));
    try {
      setDateFilterStartDate(new Date(moment(dateStrings[0], 'DD MMM YYYY')));
      setDateFilterEndDate(new Date(moment(dateStrings[1], 'DD MMM YYYY')));
    } catch (e) {
    }
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    // dispatch(setCommonParams({
    //   params: {
    //     rangeKeyword: 'select',
    //     startDate: new Date(dates[0]),
    //     endDate: new Date(dates[1]),
    //   },
    // }));
    getData(reservationTypeValue.value || 'all', dates[0], dates[1], keyword, pageSize);
  };

  const onReservationTypeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReservationTypeChange: ', value);
    }
    setPageNumber(1);
    setReservationTypeValue(value);
    getData(value.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize);
  };

  const onPerPageSelectChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPerPageSelectChange: ', value);
    }
    setPageNumber(1);
    setPerPageSelectValue(value);
    setPageSize(value.value);
    getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, value.value, 1);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
    if (!value) {
      getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, '', pageSize, 1);
    }
  };

  const onFilterTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
      getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, '', pageSize, 1);
    }
  };

  const onPageChange = (page) => {
    setPageNumber(page);
    getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize, page);
  };
  const onPaginationShowSizeChange = (current, pageSize) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPaginationShowSizeChange: ', current, ' # ', pageSize);
    }
  };

  const onRefreshClick = () => {
    getData(reservationTypeValue.value || 'all', dateFilterStartDate, dateFilterEndDate, keyword, pageSize, 1);
  };

  const onRowSelect = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowSelect: ', data);
    }
    let temp = selectedRows;
    temp[data._id] = temp[data._id] ? false : true;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowSelect temp: ', temp);
    }
    setSelectedRows({ ...temp });
  };

  const onTransactionEmailChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTransactionEmailChange: ', value);
    }
    setSelectedTransactionEmailValue(value);
    setTransactionEmailError(value.value === 'select' ? 'error' : '');
  };

  const onSendTransactionEmailClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendTransactionEmailClick selectedRows: ', selectedRows);
      console.log('onSendTransactionEmailClick selectedRows true: ', Object.keys(selectedRows).filter(el => selectedRows[el]));
      console.log('onSendTransactionEmailClick selectedTransactionEmailValue: ', selectedTransactionEmailValue);
    }
    if (props.isReadOnly) return;
    const guestList = Object.keys(selectedRows).filter(el => selectedRows[el]);
    if (!guestList.length) return;
    // if (!selectedTransactionEmailValue || !selectedTransactionEmailValue.value) return;
    // if (selectedTransactionEmailValue.value === 'select') {
    //   setTransactionEmailError('error');
    //   return;
    // }
    // setTransactionEmailError('');

    const { transactionEmails } = props.marketing;
    if (!transactionEmails) return;
    const { reservations } = transactionEmails;
    if (!reservations) return;
    if (reservations.sendCustomMultipleInProgress) return false;

    // setShowSendTransactionEmailModal(true);
    setShowSelectTransactionEmailModal(true);

  };

  const onClearSelectionClick = (e) => {
    setSelectedRows({});
  };

  const onSendTransactionEmailConfirmClick = (e) => {
    const guestList = Object.keys(selectedRows).filter(el => selectedRows[el]);
    dispatch(editDataAction({
      op: 'send_custom_transaction_email',
      params: {
        location__id: urlParams.locationidentifier,
        transaction_email_id: selectedTransactionEmailValue.value,
        guest_list: guestList,
      },
      show_alert: true,
      alert_msg: 'Sent successfully',
    }));
  };

  const onRowSendIsActive = (value) => {
    setRowSendIsActive(value);
  };

  const onSelectTransactionEmailCloseModal = (e) => {
    setShowSelectTransactionEmailModal(false);
  };
  const onSelectTransactionEmailConfirmClick = (transactionEmail) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSelectTransactionEmailConfirmClick transactionEmail: ', transactionEmail);
    }
    if (props.isReadOnly) return;
    if (props.marketing.transactionEmails.reservations &&
      props.marketing.transactionEmails.reservations.sendCustomMultipleInProgress
    ) return;
    if (!transactionEmail || !transactionEmail._id) return;

    const guestList = Object.keys(selectedRows).filter(el => selectedRows[el]);
    if (!guestList.length) return;

    dispatch(editDataAction({
      op: 'send_custom_transaction_email',
      params: {
        location__id: urlParams.locationidentifier,
        transaction_email_id: transactionEmail._id,
        guest_list: guestList,
      },
      show_alert: true,
      alert_msg: 'Sent successfully',
    }));
  };

  const renderPagination = (keyValue) => {
    if (!filteredList || !filteredList.length) return null;
    // let totalCount = 0;
    // totalCount = filteredList.length || 0;
    if (process.env.NODE_ENV !== 'production') {
      console.log('totalCount final: ', totalCount);
    }
    if (!totalCount) return null;
    return (
      <>
        <div className="rpPagination">
          <Pagination
            onChange={onPageChange}
            current={pageNumber}
            total={totalCount}
            pageSize={pageSize}
            hideOnSinglePage={true}
            showSizeChanger
            onShowSizeChange={onPaginationShowSizeChange}
          />
        </div>
        <div className="perPageCtrl">
          <Select
            className="perPageSelect"
            options={perPageSelectOptions}
            onChange={onPerPageSelectChange}
            value={perPageSelectValue}
          />
        </div>
      </>
    );
  };

  const renderListHeader = () => {
    return (
      <div className="singleBox header">
        <div className="partOne">
          <div className="type">
            Type
          </div>
          <div className="name">
            Name
          </div>
          <div className="stay">
            Stay Dates
          </div>
        </div>
        {/*<div className="partTwo">
          <div className="emailTemplate">
            Email Template
          </div>
          <div className="custom">
            Default / Custom
          </div>
        </div>*/}
        <div className="partThree">
          <div className="when">
            <i className="far fa-clock"></i>
            Transaction Emails Sent
          </div>
          <div className="sentStats">
            Booking Source
          </div>
          <div className="oMenu">
          </div>
        </div>
      </div>
    );
  };


  const renderDateFilterRow = (statsObj) => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Reservations
            <span onClick={onRefreshClick}><img src={refreshIcon} /></span>
          </div>
          <p className="count">Found {totalCount} record/s</p>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('selectedRows: ', selectedRows);
  }

  const { transactionEmails } = props.marketing;
  if (!transactionEmails) return null;

  const { reservations } = transactionEmails;

  return (
    <>
      {
        ((reservations && reservations.getInProgress) ||
          rowSendIsActive
        ) &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg trscEmHstyPg">
          { renderDateFilterRow() }
          <div className="tnscEmHsty tnscEmRsrvs">
            <div className="actionsBox">
              <div className="left">
                <Select
                  className="emailTypeSelect"
                  options={reservationTypeOptions}
                  onChange={onReservationTypeChange}
                  value={reservationTypeValue}
                />
                <div className="searchBox">
                  <div className="gIpt">
                    <input type="text" onChange={onChange} placeholder="Search..." value={keyword} onKeyDown={onFilterTermKeyDown}/>
                  </div>
                </div>
              </div>
            </div>
            {
              selectedRows && Object.keys(selectedRows).filter(el => selectedRows[el]).length > 0
              ?
                <div className="actionsBox trEml">
                  <div className="left">
                    {/*<Select
                      className="trEmlSelect"
                      options={transactionEmailOptions}
                      onChange={onTransactionEmailChange}
                      value={selectedTransactionEmailValue}
                    />*/}
                    <button className="gBtn" onClick={onSendTransactionEmailClick}>
                      Send Email
                      {/*
                        reservations.sendCustomMultipleInProgress &&
                        <span><i className="fa fa-spinner fa-spin" /></span>
                      */}
                    </button>
                    <button className="gBtn cancel" onClick={onClearSelectionClick}>
                      Clear selection
                    </button>
                  </div>
                  {/*
                    transactionEmailError &&
                    <div className="bt">
                      <div className="gErr">Required</div>
                    </div>
                  */}
                </div>
              : null
            }
            {
              filteredList && filteredList.length
              ?
                <div className="allWrap">
                  { renderListHeader() }
                  {
                    filteredList && filteredList.length
                    ?
                      filteredList.map((item) => {
                        if (reservations && reservations.keyValue && reservations.keyValue[item] &&
                          !reservations.keyValue[item].is_removed
                        ) {
                          return <SingleTransactionEmailHistoryBox
                            key={`stehb_${item}`}
                            reservationData={reservations.keyValue[item]}
                            transactionEmails={transactionEmails}
                            locationMId={props.locationInfo._id}
                            onRowSelect={onRowSelect}
                            rowIsSelected={selectedRows[item]}
                            onRowSendIsActive={onRowSendIsActive}
                            isReadOnly={props.isReadOnly}
                          />
                        }
                        return null;
                      })
                    : <p>No Data</p>
                  }
                </div>
              : <p>No Data</p>
            }
          </div>
          <div className="footer">
            { renderPagination() }
          </div>
        </div>
        { showSelectTransactionEmailModal &&
          <SelectTransactionEmailModal
            modalType="templateSelector"
            title="Select Email"
            setModalVisible={onSelectTransactionEmailCloseModal}
            onAffirmativeClick={onSelectTransactionEmailConfirmClick}
            showCancel={true}
            affirmativeButtonLabel={'Send'}
            disableOutsideClick={true}
            // emailData={sentEmailData}
            inProgress={reservations.sendCustomMultipleInProgress}
            transactionEmails={transactionEmails}
            reservationData={
              Object.keys(selectedRows).filter(el => selectedRows[el]).length === 1
              ? reservations.keyValue[Object.keys(selectedRows).filter(el => selectedRows[el])[0]]
              : null
            }
            selectedGuestsType={selectedGuestsType}
          />
        }
        { showSendTransactionEmailModal &&
          <GenericAlertModal
            modalType="Resend Email"
            bodyRowOne={`Do you want to send email to the selected ${Object.keys(selectedRows).filter(el => selectedRows[el]).length} guest/s ?`}
            bodyRowNote=""
            // keyName={reservationData.label}
            setModalVisible={setShowSendTransactionEmailModal}
            onAffirmativeClick={onSendTransactionEmailConfirmClick}
            inProgress={reservations.sendCustomMultipleInProgress || false}
            showCancel={true}
          />
        }
      </div>
    </>
  );
}

export default Reservations;
