import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation, Outlet, } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import moment from 'moment';
// import DateTime from 'react-datetime';
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import { getDataAction, getDataClearAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import useDebounce from '../../../Misc/DebounceHook';
import CustomTimePicker from '../../../Misc/CustomTimePicker';

import { isContactValid, validateEmail, guestTransactionStatusToStayLabel, formatDate, getGuestTypeKey,
  getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';

import './style.css';

const yesterday = moment().subtract(1, 'day');

function AddGuestSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [contact, setContact] = useState('+1');
  const contactRef = useRef(null);

  const [contactChanged, setContactChanged] = useState(false);
  const debouncedSearchTerm = useDebounce(contact, 500);
  const [contactSelectedFromSearchResult, setContactSelectedFromSearchResult] = useState(false);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roomNumber: '',
    remarks: '',
  });

  const [checkInNow, setCheckInNow] = useState(false);
  const [checkInNowChanged, setCheckInNowChanged] = useState(false);

  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkInTime, setCheckInTime] = useState(new Date());

  const [checkOutDate, setCheckOutDate] = useState(new Date(moment().add('days', 1)));
  const [checkOutTime, setCheckOutTime] = useState(new Date());

  const [dateRangeValid, setDateRangeValid] = useState(true);

  const [someChange, setSomeChange] = useState(false);

  const [addButtonType, setAddButtonType] = useState('');
  const [remarksStyle, setRemarksStyle] = useState({});


  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Add/Edit Guest - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('AddGuestSingle props: ', props);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('AddGuestSingle props.guest: ', props.guest);
      // console.log('AddGuestSingle passedGuest: ', passedGuest);
      console.log('AddGuestSingle props.mode: ', props.mode);
    }
    if (props.mode === "edit" && props.guest && props.guest._id) {
      const { guest } = props;
      if (process.env.NODE_ENV !== 'production') {
        console.log('AddGuestSingle guest: ', guest);
      }
      if (guest.contact && guest.contact.trim()) {
        let temp = guest.contact.replace(/\D/g,'');
        setContact(`+${temp}`);
        validateContact(`+${temp}`);
      }
      // setForm(form => ({
      //   ...form,
      //   firstName: guest.first_name,
      //   lastName: guest.last_name,
      //   email: guest.email,
      //   roomNumber: guest.room_number,
      //   remarks: guest.remarks,
      // }));
      setForm({
        firstName: guest.first_name,
        lastName: guest.last_name,
        email: guest.email,
        roomNumber: guest.room_number,
        remarks: guest.remarks,
      });
      if (props.guest.transaction_type === 'CheckIn') { setCheckInNow(true); }
      if (props.guest.transaction_type === 'Reservation') { setCheckInNow(false); }
      setCheckInDate(new Date(moment(guest.check_in_date)));
      setCheckInTime(new Date(moment(guest.check_in_date)));
      setCheckOutDate(new Date(moment(guest.check_out_date)));
      setCheckOutTime(new Date(moment(guest.check_out_date)));
      setSomeChange(false);


      let params = {};
      params.mode = props.mode;
      params.location__id = urlParams.locationidentifier;
      params.guest_type = props.guestType;
      params['guest_id'] = props.guest._id;
      if (process.env.NODE_ENV !== 'production') {
        console.log('props.mode params: ', params);
      }
      dispatch(editDataClearAction({
        op: 'edit_guest',
        params,
      }));

      params = {};
      params.mode = props.mode;
      params.location__id = urlParams.locationidentifier;
      params.guest_type = 'guests';
      if (process.env.NODE_ENV !== 'production') {
        console.log('props.mode params: ', params);
      }
      dispatch(editDataClearAction({
        op: 'add_guest',
        params,
      }));
    }
    if (props.mode === "add") {
      setForm(form => ({
        ...form,
        firstName: '',
        lastName: '',
        email: '',
        roomNumber: '',
        remarks: '',
      }));
      setCheckInNow(false); // default it to false
      // set check out time
      if (props.settings.engage.check_out_time) {
        const hotelCheckOutTime = props.settings.engage.check_out_time;
        let tSplit = hotelCheckOutTime.split(':');
        if (hotelCheckOutTime.indexOf('Noon') > -1) {
          setCheckOutTime(new Date(moment(checkOutDate).set({ hour: 12, minute: 0 })));
        }
        if (tSplit.length == 2) {
          if (hotelCheckOutTime.indexOf('AM') > -1) {
            setCheckOutTime(new Date(moment(checkOutDate).set({ hour: tSplit[0], minute: tSplit[1].split(' ')[0] })));
          }
          if (hotelCheckOutTime.indexOf('PM') > -1) {
            setCheckOutTime(new Date(moment(checkOutDate).set({ hour: parseInt(tSplit[0]) + 12, minute: tSplit[1].split(' ')[0] })));
          }
        }
      }
      setSomeChange(false);
      clearCurrentStatus();
      setTimeout(() => {
        if (contactRef && contactRef.current) contactRef.current.focus();
      }, 100);

      const params = {};
      params.mode = props.mode;
      params.location__id = urlParams.locationidentifier;
      params.guest_type = props.guestType;
      if (process.env.NODE_ENV !== 'production') {
        console.log('props.mode params: ', params);
      }
      dispatch(editDataClearAction({
        op: 'add_guest',
        params,
      }));

    }
    dispatch(getDataClearAction({
      op: 'get_guest_search_result',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'guest',
      }
    }));
    setRemarksStyle({
      resize: 'none',
    });
  }, [props.mode]);

  // [debouncedSearchTerm]
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('debouncedSearchTerm: ', debouncedSearchTerm);
    }
    if (!contactSelectedFromSearchResult) {
      if (debouncedSearchTerm && debouncedSearchTerm != '+1') {
        if (props.mode === "edit") {
          const { guest } = props;
          if (guest && guest.contact && guest.contact.trim()) {
            let temp = `+${guest.contact.replace(/\D/g,'')}`;
            if (process.env.NODE_ENV !== 'production') {
              console.log('debouncedSearchTerm temp: ', temp);
            }
            if (temp !== contact) {
              dispatch(getDataAction({
                op: 'get_guest_search_result',
                params: {
                  location__id: urlParams.locationidentifier,
                  keyword: debouncedSearchTerm,
                  type: 'guest',
                }
              }));
            }
          }
        } else {
          dispatch(getDataAction({
            op: 'get_guest_search_result',
            params: {
              location__id: urlParams.locationidentifier,
              keyword: debouncedSearchTerm,
              type: 'guest',
            }
          }));
        }
      }
    }
  }, [debouncedSearchTerm]);


  // add successful - nkcheck - pending
  useEffect(() => {
    if (props.engage.guests &&
      !props.engage.guests.addGuestInProgress && props.engage.guests.addStatus &&
      props.engage.guests.addStatus.success && props.engage.guests.addStatus.newId
    ) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('AddGuestSingle addButtonType: ', addButtonType);
        console.log('AddGuestSingle checkInNow: ', checkInNow);
      }
      setContact('+1');
      setForm(form => ({
        ...form,
        firstName: '',
        lastName: '',
        email: '',
        roomNumber: '',
        remarks: '',
      }));
      setCheckInDate(new Date());
      setCheckInTime(new Date());
      setCheckOutDate(new Date(moment().add('days', 1)));
      setCheckOutTime(new Date());
      if (props.settings.check_out_time) {
        const hotelCheckOutTime = props.settings.check_out_time;
        let tSplit = hotelCheckOutTime.split(':');
        if (hotelCheckOutTime.indexOf('Noon') > -1) {
          setCheckOutTime(new Date(moment(checkOutDate).set({ hour: 12, minute: 0 })));
        }
        if (tSplit.length == 2) {
          if (hotelCheckOutTime.indexOf('AM') > -1) {
            setCheckOutTime(new Date(moment(checkOutDate).set({ hour: tSplit[0], minute: tSplit[1].split(' ')[0] })));
          }
          if (hotelCheckOutTime.indexOf('PM') > -1) {
            setCheckOutTime(new Date(moment(checkOutDate).set({ hour: parseInt(tSplit[0]) + 12, minute: tSplit[1].split(' ')[0] })));
          }
        }
      }
      setSomeChange(false);
      setCheckInNow(false);
      if (addButtonType === 'addAndMessage') {
        let category = 'guests';
        if (checkInNow) { category = 'guests'; }
        else { category = 'upcoming-guests'; }
        if (process.env.NODE_ENV !== 'production') {
          console.log('pushing ', `/engage/locations/${urlParams.locationidentifier}/messages/${category}/${props.engage.guests.addStatus.newId}`);
        }
        // navigate(`/settings/locations/${urlParams.locationidentifier}/journey-messages`,
        //   { state: { screenBeforeSettingsName: 'Guests', screenBeforeSettingsPath: pathname }}
        // );
        // props.history.push(`/categories/${category}/${props.locationprops.guests.addStatus.newId}`);
        // props.onCancelClick(`/categories/${category}/${props.locationprops.guests.addStatus.newId}`);
        props.onCancelClick(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${props.engage.guests.addStatus.newId}`);
        if (process.env.NODE_ENV !== 'production') {
          console.log('called onCancelClick');
        }
      }
    }
  }, [props.engage.guests]);

  // edit successful -  nkcheck - pending
  useEffect(() => {
    if (props.mode === "edit") {
      if (!props.guest.editInProgress && props.guest.editStatus &&
        props.guest.editStatus.success
      ) {
        setSomeChange(false);
        if (process.env.NODE_ENV !== 'production') {
          console.log('edit success: ', props.guest.transaction_type);
        }
        if (props.guest.transaction_type === 'Reservation') {
          let category = 'upcoming-guests';
          // props.onCancelClick(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${props.guest._id}`);
        } else if (props.guest.transaction_type === 'CheckIn') {
          let category = 'guests';
          // props.onCancelClick(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${props.guest._id}`);
        } else {
          // props.onCancelClick('');
        }

      }
    }
  }, [props.guest]);

  const clearCurrentStatus = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('clearCurrentStatus: ', props);
    }
    // for edit guest
    if (props.guest && props.guest._id) {
      const guestTypeKey = getGuestTypeKey(props.guestType);
      if (process.env.NODE_ENV !== 'production') {
        console.log('clearCurrentStatus guestTypeKey: ', guestTypeKey);
      }
      if (props.engage[guestTypeKey] && props.engage[guestTypeKey].keyValue && props.engage[guestTypeKey].keyValue[props.guest._id] &&
        props.engage[guestTypeKey].keyValue[props.guest._id].editStatus && Object.keys(props.engage[guestTypeKey].keyValue[props.guest._id].editStatus).length
      ) {
        const params = {};
        params.location__id = urlParams.locationidentifier;
        params.mode = props.mode;
        params.guest_type = props.guestType;
        if (props.mode === 'edit' && props.guest) {
          params['guest_id'] = props.guest._id;
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('clearCurrentStatus params: ', params);
        }
        dispatch(editDataClearAction({
          op: 'edit_guest',
          params,
        }));
      }
    }
    if (props.engage.guests &&
      props.engage.guests.addStatus && Object.keys(props.engage.guests.addStatus).length) {
      const params = {};
      params.mode = props.mode;
      params.guest_type = props.guestType;
      if (props.mode === 'edit' && props.guest) {
        params['guest_id'] = props.guest._id;
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('clearCurrentStatus params: ', params);
      }
      dispatch(editDataClearAction({
        op: 'edit_guest',
        params,
      }));
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  };

  const validateContact = (value) => {
    try {
      if (value) {
        const pContactNumber = parsePhoneNumber(value);
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact pContactNumber: ', pContactNumber);
        }
        setContact(value);
        setForm(form => ({
          ...form,
          contactError: pContactNumber && isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber) ? '' : 'error',
        }));
      } else {
        setContact('');
        setForm(form => ({
          ...form,
          // contactError: 'error',
          contactError: '',
        }));
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('contact error: ', e);
      }
    }
  };

  const onContactChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onContactChange: ', value);
    }
    setContactSelectedFromSearchResult(false);
    if (value) {
      validateContact(value);
      setSomeChange(true);
      setContactChanged(true);
      clearCurrentStatus();
    } else {
      setContact('');
    }
  };

  const onSearchResultCloseClick = (e) => {
    setContactChanged(false);
  };
  const onRowClick = (row) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowClick: ', row);
    }
    if (row && row._id) {
      setContactSelectedFromSearchResult(true);
      setContact(row.contact);
      validateContact(row.contact);
      setForm(form => ({
        ...form,
        firstName: row.first_name,
        lastName: row.last_name,
        email: row.email,
      }));
      setContactChanged(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
    }));
    if (name === 'firstName') {
      setForm(form => ({
        ...form,
        [`${name}Error`]: value ? '' : 'error',
      }));
    }
    if (name === 'email') {
      setForm(form => ({
        ...form,
        emailError: !validateEmail(value) ? 'error' : '',
      }));
    }
    if (name === 'roomNumber') {
      if (checkInNow) {
        setForm(form => ({
          ...form,
          roomNumberError: value ? '' : 'Room number is required.',
        }));
      } else {
        setForm(form => ({
          ...form,
          roomNumberError: '',
        }));
      }
    }
    setSomeChange(true);
    clearCurrentStatus();
  };

  const onCheckInNowChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckInNowChange: ', value);
    }
    if (!checkInNow) {
      setForm(form => ({
        ...form,
        roomNumberError: form.roomNumber ? '' : 'Room number is required.',
      }));
    } else {
      setForm(form => ({
        ...form,
        roomNumberError: '',
      }));
    }
    setCheckInNow(checkInNow => !checkInNow);
    if (value === 'off') {
      setCheckInDate(new Date());
      setCheckInTime(new Date());
    }
    setCheckInNowChanged(true);
    setSomeChange(true);
    clearCurrentStatus();
  };

  const onCheckInNowLabelClick = (e) => {
    if (!checkInNow) {
      setForm(form => ({
        ...form,
        roomNumberError: form.roomNumber ? '' : 'Room number is required.',
      }));
    } else {
      setForm(form => ({
        ...form,
        roomNumberError: '',
      }));
    }
    if (!checkInNow) {
      setCheckInDate(new Date());
      setCheckInTime(new Date());
    }
    setCheckInNow(checkInNow => !checkInNow);
    setSomeChange(true);
    clearCurrentStatus();
  };

  const validateDateRange = (mCheckInDate, mCheckInTime, mCheckOutDate, mCheckOutTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(mCheckInDate, ' # ', mCheckInTime, ' # mCheckOutDate ', mCheckOutDate, ' # ', mCheckOutTime);
    }
    const checkInDateStr = `${mCheckInDate.format('YYYY-MM-DD')} ${mCheckInTime.format('HH:mm')}`;
    const checkOutDateStr = `${mCheckOutDate.format('YYYY-MM-DD')} ${mCheckOutTime.format('HH:mm')}`;
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInDateStr: ', checkInDateStr);
      console.log('checkOutDateStr: ', checkOutDateStr);
    }
    if (moment(checkInDateStr, 'YYYY-MM-DD HH:mm') < moment(checkOutDateStr, 'YYYY-MM-DD HH:mm')) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('returning true');
      }
      return true;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('returning false');
    }
    return false;
  };

  const inValidCheckInDate = (current) => {
    return current.isAfter(yesterday);
  };
  const inValidCheckOutDate = (current) => {
    return current.isAfter(moment(checkInDate).subtract(1, 'day'));
  };

  const onCheckInDateChange = (selectedDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckInDateChange: ', selectedDate);
    }
    if (selectedDate < moment().startOf('day')) return;
    setCheckInDate(new Date(moment(selectedDate)));
    let tempCheckOutDate = checkOutDate;
    if (moment(selectedDate) > moment(checkInDate) && moment(selectedDate) > checkOutDate) {
      setCheckOutDate(new Date(moment(selectedDate).add('days', 1)));
      tempCheckOutDate = new Date(moment(selectedDate).add('days', 1));
    }
    setCheckInNow(false);
    setSomeChange(true);
    clearCurrentStatus();
    setDateRangeValid(validateDateRange(moment(selectedDate), moment(checkInTime), moment(tempCheckOutDate), moment(checkOutTime)));
  };
  const onCheckInTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckInTimeChange: ', selectedTime);
    }
    setCheckInTime(new Date(moment(selectedTime, 'hh:mm A')));
    setCheckInNow(false);
    setSomeChange(true);
    clearCurrentStatus();
    setDateRangeValid(validateDateRange(moment(checkInDate), moment(selectedTime, 'hh:mm A'), moment(checkOutDate), moment(checkOutTime)));
  };

  const onCheckOutDateChange = (selectedDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckOutDateChange: ', selectedDate);
    }
    setCheckOutDate(new Date(moment(selectedDate)));
    setSomeChange(true);
    clearCurrentStatus();
    setDateRangeValid(validateDateRange(moment(checkInDate), moment(checkInTime), moment(selectedDate), moment(checkOutTime)));
  };
  const onCheckOutTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckOutTimeChange: ', selectedTime);
    }
    setCheckOutTime(new Date(moment(selectedTime, 'hh:mm A')));
    setSomeChange(true);
    clearCurrentStatus();
    setDateRangeValid(validateDateRange(moment(checkInDate), moment(checkInTime), moment(checkOutDate), moment(selectedTime, 'hh:mm A')));
  };



  const onEditClick = (e) => {
    if (!someChange) return;

    validateContact(contact);
    const isDateRangeValid = validateDateRange(moment(checkInDate), moment(checkInTime), moment(checkOutDate), moment(checkOutTime));
    setDateRangeValid(isDateRangeValid);
    setForm(form => ({
      ...form,
      firstNameError: form.firstName ? '' : 'error',
    }));
    if (checkInNow) {
      setForm(form => ({
        ...form,
        roomNumberError: form.roomNumber ? '' : 'Room number is required.',
      }));
    }

    try {
      const pContactNumber = parsePhoneNumber(contact);
      if (process.env.NODE_ENV !== 'production') {
        console.log('validateContact pContactNumber: ', pContactNumber);
      }
      if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
        return;
      }
    } catch (e) {}

    if (!form.firstName || form.firstNameError || !contact || form.contactError || form.emailError || form.roomNumberError ||
      !checkInDate || form.checkInDateError || !checkInTime || form.checkInTimeError ||
      !checkOutDate || form.checkOutDateError || !checkOutTime || form.checkOutTimeError
    ) {
      return;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('isDateRangeValid: ', isDateRangeValid);
    }
    if (!isDateRangeValid) return;

    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.guest_id = props.guest._id;
    if (form.firstName !== props.guest.first_name) {
      params['first_name'] = form.firstName;
    }
    if (form.lastName !== props.guest.last_name) {
      params['last_name'] = form.lastName;
    }
    if (form.email !== props.guest.email) {
      params['email'] = form.email;
    }
    if (form.roomNumber !== props.guest.room_number) {
      params['room_number'] = form.roomNumber;
    }
    if (form.contact !== props.guest.contact) {
      params['contact'] = contact;
      if (contactChanged) {
        params['contact_changed'] = true;
      }
    }
    if (form.remarks !== props.guest.remarks) {
      params['remarks'] = form.remarks;
    }
    if (props.guestType === 'guests' || props.guestType === 'upcoming-guests') {
      if (checkInNow) {
        if (props.guest.transaction_type !== 'CheckIn') {
          params['transaction_type'] = "CheckIn";
        }
      } else {
        if (props.guest.transaction_type !== 'Reservation' && props.guest.transaction_type !== 'Booking') {
          params['transaction_type'] = "Reservation";
        }
      }
    }

    const guest_check_in_date_utc_str = moment(props.guest.check_in_date).utc().format('YYYY-MM-DD HH:mm');
    const guest_check_out_date_utc_str = moment(props.guest.check_out_date).utc().format('YYYY-MM-DD HH:mm');
    if (process.env.NODE_ENV !== 'production') {
      console.log('guest_check_in_date_utc_str: ', guest_check_in_date_utc_str);
      console.log('guest_check_out_date_utc_str: ', guest_check_out_date_utc_str);
    }

    const check_in_date_str = moment(checkInDate).format('YYYY-MM-DD');
    const check_in_time_str = moment(checkInTime).format('HH:mm');
    const check_out_date_str = moment(checkOutDate).format('YYYY-MM-DD');
    const check_out_time_str = moment(checkOutTime).format('HH:mm');
    const check_in_date_utc_str = moment(`${check_in_date_str} ${check_in_time_str}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm');
    const check_out_date_utc_str = moment(`${check_out_date_str} ${check_out_time_str}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm');
    if (process.env.NODE_ENV !== 'production') {
      console.log('check_in_date_utc_str: ', check_in_date_utc_str);
      console.log('check_out_date_utc_str: ', check_out_date_utc_str);
    }

    if (guest_check_in_date_utc_str !== check_in_date_utc_str) {
      params['check_in_date'] = check_in_date_utc_str;
    }
    if (guest_check_out_date_utc_str !== check_out_date_utc_str) {
      params['check_out_date'] = check_out_date_utc_str;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('check_in_date: ', check_in_date_str, ' # ', check_in_time_str);
      console.log('check_out_date: ', check_out_date_str, ' # ', check_out_time_str);
      console.log('check_in_date: ', new Date(moment(`${check_in_date_str} ${check_in_time_str}`, 'YYYY-MM-DD HH:mm')));
      console.log('check_in_date utc: ', moment(`${check_in_date_str} ${check_in_time_str}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm'));

      console.log('onEditClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_guest',
      params,
    }));
  };

  const performAdd = (addType) => {
    if (!someChange) return;
    if (props.engage.guests && props.engage.guests.addGuestInProgress) return;

    if (contactChanged) {
      validateContact(contact);
    }
    setForm(form => ({
      ...form,
      firstNameError: form.firstName ? '' : 'error',
    }));
    if (checkInNow) {
      setForm(form => ({
        ...form,
        roomNumberError: form.roomNumber ? '' : 'Room number is required.',
      }));
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('performAdd form: ', form);
    }

    if (contactChanged && contact) {
      try {
        const pContactNumber = parsePhoneNumber(contact);
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact pContactNumber: ', pContactNumber);
        }
        if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('validateContact error');
          }
          return;
        }
      } catch (e) {}
    }

    if (contact && form.contactError) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('some contact error');
      }
      return;
    }

    if (!form.firstName || form.firstNameError || form.roomNumberError ||
      !checkInDate || form.checkInDateError || !checkInTime || form.checkInTimeError ||
      !checkOutDate || form.checkOutDateError || !checkOutTime || form.checkOutTimeError
    ) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('some error');
      }
      return;
    }
    if (!dateRangeValid) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('dateRangeValid error');
      }
      return;
    }

    if (!contactChanged && contact === '+1') {
      setContact('');
    }

    const params = {};
    params.add_type = addType;
    params.mode = 'add';
    params.location__id = urlParams.locationidentifier;
    params.add_mode = 'manual';
    params.guest_type = props.guestType;
    params['first_name'] = form.firstName || '';
    params['last_name'] = form.lastName || '';
    params['email'] = form.email || '';
    params['room_number'] = form.roomNumber || '';
    params['contact'] = contact || '';
    if (!contactChanged && contact === '+1') {
      params['contact'] = '';
    }
    const pContactNumber = contact ? parsePhoneNumber(contact) : null;
    if (pContactNumber && pContactNumber.countryCallingCode) {
      params['country_code'] = `+${pContactNumber.countryCallingCode}`;
    }
    if (pContactNumber && pContactNumber.nationalNumber) {
      params['contact_number'] = pContactNumber.nationalNumber;
    }
    params['remarks'] = form.remarks || '';
    if (checkInNow) {
      params['transaction_type'] = 'CheckIn';
    } else {
      params['transaction_type'] = 'Reservation';
    }

    const check_in_date_str = moment(checkInDate).format('YYYY-MM-DD');
    const check_in_time_str = moment(checkInTime).format('HH:mm');
    const check_out_date_str = moment(checkOutDate).format('YYYY-MM-DD');
    const check_out_time_str = moment(checkOutTime).format('HH:mm');
    const check_in_date_utc_str = moment(`${check_in_date_str} ${check_in_time_str}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm');
    const check_out_date_utc_str = moment(`${check_out_date_str} ${check_out_time_str}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm');
    if (process.env.NODE_ENV !== 'production') {
      console.log('check_in_date_utc_str: ', check_in_date_utc_str);
      console.log('check_out_date_utc_str: ', check_out_date_utc_str);
    }

    params['check_in_date'] = check_in_date_utc_str || '';
    params['check_out_date'] = check_out_date_utc_str || '';

    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'add_guest', // 'edit_guest',
      params,
    }));
  };

  const onAddClick = (e) => {
    setAddButtonType('add');
    performAdd('add');
  };

  const onAddAndMessageClick = (e) => {
    setAddButtonType('addAndMessage');
    performAdd('addAndMessage');
  };


  const renderResultRow = (row) => {

    return (
      <div className="rslRw" onClick={() => onRowClick(row)}>
        <div className="rwIt">
          <div className="lt">
            {guestTransactionStatusToStayLabel[row.transaction_type]}
          </div>
        </div>
        <div className="rwIt topRow">
          <div className="lt">
            {row.first_name}
          </div>
          <div className="rt">
            {row.contact}
          </div>
        </div>
        <div className="rwIt stayInfo">
          <div className="lt">
            {formatDate(row.check_in_date)} to {formatDate(row.check_out_date)}
          </div>
          <div className="rt">
            Room # {row.room_number}
          </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('AddGuestSingle form: ', form);
  }

  return (
    <div className="sdMdl addGst">
      <div className="ctnt">
        <div className="gPWrp">
          <h4 className="gPTle title">
            <span>
            { props.mode === "edit" ? "Edit Guest" : "Add Guest" }
            </span>
            {
              props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            }
          </h4>
          {
            props.mode === "add" &&
            <h5 className="gPTle subTitle">
              If your PMS is not connected, you can manually add guest right below
              </h5>
          }
          <div className="bc">
            <div className={'bcRow contactRow ' + (contact && contactChanged && props.engage.guests && props.engage.guests.searchResults && props.engage.guests.searchResults.contacts && props.engage.guests.searchResults.contacts.length ? 'searchResultsOpen' : '')}>
              <div className="bxLt">
                <div className="gLbl">
                  Cell Number
                </div>
                <div className={'gIpt ' + (form.contactError ? 'error' : '')}>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    displayInitialValueAsLocalNumber={false}
                    value={contact}
                    // onCountryChange={onCountryChange}
                    ref={contactRef}
                    onChange={onContactChange}/>
                  {/*<input type="text" placeholder="Cell Number" />*/}
                </div>
                {
                  props.engage.guests && props.engage.guests.searchResults &&
                  props.engage.guests.searchResults.searchInProgress &&
                  <span className="srchInPrg"><i className="fa fa-spinner fa-spin" /></span>
                }

                {
                  form.contactError &&
                  <div className="gErr">
                    <img src={requiredIcon} /> Invalid contact
                  </div>
                }

                <div className="gHelpMsg">
                  Choose country code and enter cell number OR Paste the contact number from PMS or Booking Email
                </div>
              </div>
              <div className="bxRt">
              </div>
            </div>
            {
              contact && contactChanged && props.engage.guests && props.engage.guests.searchResults.contacts && props.engage.guests.searchResults.contacts.length
              ?
                <div className="srchRCls">
                  <span onClick={onSearchResultCloseClick}>Close</span>
                </div>
              : null
            }
            {
              contact && contactChanged && props.engage.guests && props.engage.guests.searchResults.contacts && props.engage.guests.searchResults.contacts.length
              ?
                <>
                  <div className="srchRWrp">
                    <div className="srchRInfo">
                      <p>Selecting a guest row will only copy the contact, first name, last name and email into the guest form.</p>
                    </div>
                    {
                      props.engage.guests.searchResults.contacts &&
                      props.engage.guests.searchResults.contacts.map((item) => {
                        return renderResultRow(item);
                      })
                    }
                  </div>

                </>
              : null
            }
            <div className="gLnBkFl" />
            <div className="bcRow nameRow">
              <div className="bxLt firstNameBox">
                <div className="gLbl">
                  First Name
                </div>
                <div className={'gIpt ' + (form.firstNameError ? 'error' : '')}>
                  <input type="text" name="firstName" placeholder="First Name" value={form.firstName} onChange={onChange} />
                </div>
              </div>
              <div className="bxRt lastNameBox">
                <div className="gLbl">
                  Last Name
                </div>
                <div className="gIpt">
                  <input type="text" name="lastName" placeholder="Last Name" value={form.lastName} onChange={onChange} />
                </div>
              </div>
            </div>
            <div className="bcRow nameRow">
              <div className="bxLt emailBox">
                <div className="gLbl">
                  Email
                </div>
                <div className={'gIpt ' + (form.emailError ? 'error' : '')}>
                  <input type="text" name="email" placeholder="Email" value={form.email} onChange={onChange} />
                </div>
                {
                  form.emailError &&
                  <div className="gError">
                    <img src={requiredIcon} /> Invalid email address
                  </div>
                }
              </div>
              <div className="bxRt roomNumberBox">
                <div className="gLbl">
                  Room Number
                </div>
                <div className={'gIpt ' + (form.roomNumberError ? 'error' : '')}>
                  <input type="text" name="roomNumber" placeholder="Room Number" value={form.roomNumber} onChange={onChange} />
                </div>
              </div>
            </div>
            { (props.guestType === 'guests' || props.guestType === 'upcoming-guests') &&
              <div className="bcRow checkInNowRow">
                <div className="bxLt">
                  <input type="checkbox" name="checkInNow" onChange={onCheckInNowChange} value={checkInNow ? 'on' : 'off'} checked={checkInNow} />
                  <div className="gLbl" onClick={onCheckInNowLabelClick}>
                    Check-In the Guest Right Now.
                  </div>
                </div>
              </div>
            }
            <div className="bcRow checkInDateTimeRow">
              <div className="bxLt checkInDateBox">
                <div className="gLbl">
                  Check-In Date
                </div>
                <div className="gIpt">
                  <DatePicker selected={checkInDate} onChange={onCheckInDateChange}
                    dateFormat="dd MMM yyyy"
                  />
                </div>
              </div>
              <div className="bxRt checkInTimeBox">
                <div className="gLbl">
                  Check-In Time
                </div>
                <CustomTimePicker
                  value={moment(checkInTime).format('hh:mm A')}
                  onChange={onCheckInTimeChange}
                />
              </div>
            </div>
            <div className="bcRow checkOutDateTimeRow">
              <div className="bxLt checkOutDateBox">
                <div className="gLbl">
                  Check-Out Date
                </div>
                <div className="gIpt">
                  <DatePicker selected={checkOutDate} onChange={onCheckOutDateChange}
                    dateFormat="dd MMM yyyy"
                  />
                </div>
              </div>
              <div className="bxRt checkOutTimeBox">
                <div className="gLbl">
                  Check-Out Time
                </div>
                <CustomTimePicker
                  value={moment(checkOutTime).format('hh:mm A')}
                  onChange={onCheckOutTimeChange}
                />
              </div>
            </div>
            {
              !dateRangeValid &&
              <div className="bcRow dateRangeErrorRow">Check-Out Date cannot be prior to Check-In Date</div>
            }
            <div className="bcRow remarksRow">
              <div className="bxLt">
                <div className="gLbl">
                  Notes <span className="optional">(Optional)</span>
                </div>
                <div className="gIpt">
                  <textarea placeholder="Notes" name="remarks" onChange={onChange} value={form.remarks} rows="3" style={remarksStyle}></textarea>
                </div>
              </div>
            </div>
            {
              props.mode === 'edit' && props.guest.editStatus && (props.guest.editStatus.success || props.guest.editStatus.error) &&
              <div className="bcRow curSts">
                {
                  props.guest.editStatus.error && <p className="error">{props.guest.editStatus.message}</p>
                }
                {
                  props.guest.editStatus.success && <p className="success">Updated successfully</p>
                }
              </div>
            }
            {
              props.mode === 'add' && props.engage.guests && props.engage.guests.addStatus &&
              (props.engage.guests.addStatus.success || props.engage.guests.addStatus.error) &&
              <div className="bcRow curSts">
                {
                  props.engage.guests.addStatus.error && <p className="error">{props.engage.guests.addStatus.message}</p>
                }
                {
                  props.engage.guests.addStatus.success && <p className="success">Added successfully</p>
                }
              </div>
            }
            <div className="bcRow buttonsRow">
              <div className="lt">
                {
                  props.mode === 'edit' &&
                  <>
                    {/*<button className="cancel" onClick={onCancelClick}>
                      Cancel
                    </button>*/}
                    {/*<button className={'add ' + (someChange ? '' : 'disabled')} onClick={onEditClick}>
                      Save { props.guest.editInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>*/}
                    <button className={'gBtn add ' + (someChange ? '' : 'disabled')} onClick={onEditClick}>
                      {/*Save & Exit{ props.guest.editInProgress && <i className="fa fa-spinner fa-spin" /> }*/}
                      Save{ props.guest.editInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  </>
                }
                {
                  props.mode === 'add' &&
                  <>
                    <button className={'gBtn add ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onAddClick}>
                      Add{ addButtonType === 'add' && props.engage.guests && props.engage.guests.addGuestInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                    <button className={'gBtn addAndMessage ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onAddAndMessageClick}>
                      Add & Message { addButtonType === 'addAndMessage' && props.engage.guests && props.engage.guests.addGuestInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  </>
                }
              </div>
              <div className="rt">
              <button className={'gBtn add'} onClick={onCloseClick}>
                Cancel
              </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default AddGuestSingle;
