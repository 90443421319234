export function receiveSnack(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'RECEIVED_NEW_SNACK',
      payload,
    });
  };
}

export function removeSnack(payload) {
  return {
    type: 'REMOVE_SNACK',
    payload,
  };
}
export function removeAllSnack(payload) {
  return {
    type: 'REMOVE_ALL_SNACK',
    payload,
  };
}
