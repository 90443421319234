import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

// import SideBar from './SideBar';

import './SettingsHome.css';

function SettingsMarketingHome() {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();
  let urlParams = useParams();

  const [screenBeforeSettings, setScreenBeforeSettings] = useState({});

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Marketing - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { state } = location;
    setScreenBeforeSettings(state || {});
  }, [location]);

  const { locationidentifier } = urlParams;

  const LinkTransactionEmails = <Link to={`/settings/locations/${locationidentifier}/transaction-emails`} state={screenBeforeSettings}>Manage Transaction Emails</Link>

  const LinkSenderNames = <Link to={`/settings/locations/${locationidentifier}/sender-names`} state={screenBeforeSettings}>Manage Sender Names</Link>

  const LinkSenderEmails = <Link to={`/settings/locations/${locationidentifier}/sender-emails`} state={screenBeforeSettings}>Manage Sender Emails</Link>

  return (
    <div className="sCw sh">
      <div className="hdr">
        <h4>Marketing Settings</h4>
      </div>
      {
        locationidentifier &&
        <div className="bd">
          <div className="sbx">
            <ul>
              <li>{LinkTransactionEmails}</li>
              <li>{LinkSenderNames}</li>
              <li>{LinkSenderEmails}</li>
            </ul>
          </div>
        </div>
      }
    </div>
  );
}

export default SettingsMarketingHome;
