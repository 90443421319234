// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import DOMPurify from 'dompurify';
import { auth } from '../../../firebase';
import axios from 'axios';

import closeIcon from '../../../img/icons/close.svg';
import { sendEmailAlert } from '../../../helpers';

import './style.css';

const gtconfig = require('../../../gtconfig');

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}


function SentEmailPreviewModal(props) {
  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  const [getPreviewInProgress, setGetPreviewInProgress] = useState(false);
  const [sentEmailData, setSentEmailData] = useState({});

  useEffect(() => {
    const params = {
      location__id: props.locationMId,
      transaction_email_task_id: props.transactionEmailIdentifier
    };
    getEmailPreview(params);
  }, []);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCloseClick = (e) => {
    props.setModalVisible(false);
  };

  const onCancelClick = (e) => {
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    props.onAffirmativeClick();
  };

  const getEmailPreview = (params) => {
    try {
      // nkcheck
      auth.currentUser
        .getIdToken(false)
        .then((idToken) => {
          const url = gtconfig.DATA_BACKEND_URL;
          const opdata = {};
          // opdata.params = params;
          opdata.params = {
            ...params,
            relogin_identifier: window.gtCId,
          };
          const postData = {
            appId: gtconfig.APP_ID,
            idToken,
            op: 'get_sent_transaction_email_html',
            opdata,
          };
          if (process.env.NODE_ENV !== 'production') {
            console.log('getEmailPreview postData: ', postData);
          }

          const headers = {
            'Content-type': 'application/json',
            'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
          };
          const axiosData = {
            gtps: postData,
            M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
          };

          setGetPreviewInProgress(true);

          axios
            .post(url, axiosData, { headers })
            .then((response) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('getEmailPreview response: ', response.data);
              }
              setGetPreviewInProgress(false);
              const { status } = response.data;
              const { data } = response.data;
              if (status === 'success' && data) {
                if (data.app_info && data.app_info.origin === window.location.origin) {
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('getEmailPreview data: ', data);
                  }
                  setSentEmailData(data);
                }
              } else {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getEmailPreview error: ', response);
                }
                sendEmailAlert({
                  app: gtconfig.APP_NAME,
                  subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getEmailPreview Error - ${auth.currentUser.uid}`,
                  message: JSON.stringify({ postData, responseData: response.data }),
                  error: JSON.stringify(response.data),
                });
              }
            })
            .catch((err) => {
              // axios error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getEmailPreview err--------------------: ', err);
                console.log('getEmailPreview err.response --------------------: ', err.response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getEmailPreview Error - axios - ${auth.currentUser.uid}`,
                message: JSON.stringify({ url, postData }),
                error: JSON.stringify({ err , config: err.config }),
              });
            })
            .catch((err) => {
            // firebase error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getEmailPreview firebase err--------------------: ', err);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getEmailPreview Error - firebase`,
                message: 'Firebase error',
                error: err.toString(),
              });
            });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('getEmailPreview e: ', e);
      }
    }
  };

  const renderModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="sentEmailPreviewModal" onClick={onModalClick}>
          <div className="content">
            <div className="sdMdl">
              <div className="ctnt">
                <div className="gPWrp">
                  <div className="gPTle title">
                    {
                      props.title || 'Email Preview'
                    }
                    <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
                  </div>
                  <div className="gBCtnt">
                    {
                      props.subject &&
                      <p>Subject: {props.subject}</p>
                    }
                    { getPreviewInProgress
                      ?
                        <div className="divLoader">
                          <i className="fa fa-spinner fa-spin" />
                        </div>
                      :
                        <>
                          {
                            sentEmailData && sentEmailData.email_html &&
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sentEmailData.email_html) }} />
                          }
                        </>
                    }
                    <div className="gBCtRw btnRw">
                      <button className="gBtn ok" onClick={onAffirmativeClick}>
                        { props.affirmativeButtonLabel || 'Close' }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const renderContent = () => {
    if (showModal) return renderModal();
    return null;
  };

  return renderContent();
}

export default SentEmailPreviewModal;
