import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import './style.css';

function EmptyMessages() {

  const renderContent = () => {

    return (
      <div className="etyW">
        <div className="inrB">
          <h3 className="title">No conversation yet!</h3>
        </div>
      </div>
    )
  };

  return renderContent();
}

export default EmptyMessages;
