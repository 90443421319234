import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Locations from './Locations';
import LocationsWrapper from './LocationsWrapper';
import LocationWrapper from './LocationWrapper';
import SideBar from './SideBar';
import ReputationHome from './ReputationHome';
import Groups from './Groups';
import GroupWrapper from './GroupWrapper';
import GroupDashboard from './Groups/GroupDashboard';

import { initMarketingAction } from '../../redux/actions/initActions';

import { isLocationAdminAndAbove, ACCESS_LEVELS } from '../../helpers';

import './Reputation.css';

const gtconfig = require('../../gtconfig');

function Reputation() {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `Reputation - ${gtconfig.APP_NAME_CONTACT}`;
    try {
      window.Intercom('shutdown');
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "mydf4dtr",
        name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
          email: userState.user.uid, // the email for your user
        // created_at: user.createdAt, // Signup date as a Unix timestamp
      });
    } catch(e) {}
  }, []);

  let accessLevel = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level ? userState.user.user_info.profile.access_level : '';

  const isReadOnly = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;


  let hasAnyReputation = false;
  if (userState.user.user_info.profile.locations && userState.user.user_info.locations) {
    const locIdList = Object.keys(userState.user.user_info.profile.locations);
    for (let i = 0; i < locIdList.length; i++) {
      if (userState.user.user_info.locations[locIdList[i]] && userState.user.user_info.locations[locIdList[i]].has_reputation) {
        hasAnyReputation = true; break;
      }
    }
  }

  // !userState.user.user_info.profile.locations || !userState.user.user_info.profile.access_level
  // (!userState.user.user_info.profile.locations)
  if (!hasAnyReputation) {
    return (
      <div className="mb iframeBx">
        <iframe id="iframeId"
          title={gtconfig.APP_NAME_CONTACT}
          src="https://guesttouch.com/internal-reputation-management"
        >
        </iframe>
      </div>
    );
  }

  return (
    <div className="mb rp">
      <Routes>
        <Route element={<Outlet />}>
          <Route path="locations/*" element={<Outlet />}>
            <Route path=":locationidentifier/*" element={<SideBar isReadOnly={isReadOnly} />} />
            <Route index element={<SideBar isReadOnly={isReadOnly} />} />
          </Route>
          <Route path="groups/*" element={<Outlet />}>
            <Route path=":groupidentifier/*" element={<SideBar isReadOnly={isReadOnly} />} />
            <Route index element={<SideBar isReadOnly={isReadOnly} />} />
          </Route>
          <Route index element={<SideBar isReadOnly={isReadOnly} />} />
        </Route>
      </Routes>
      <div className="mc">
        <Routes>
          <Route element={<Outlet />}>
            <Route path="locations" element={<Outlet />}>
              <Route path=":locationidentifier/*" element={<LocationWrapper isReadOnly={isReadOnly} />} />
              {/*<Route path="locations" element={<Locations />} />*/}
              <Route index element={<Locations isReadOnly={isReadOnly} />} />
            </Route>
            {
              isLocationAdminAndAbove(accessLevel) &&
              <Route path="groups" element={<Outlet />}>
                {/*<Route path=":groupidentifier/*" element={<GroupDashboard />} />*/}
                <Route path=":groupidentifier/*" element={<GroupWrapper isReadOnly={isReadOnly} />} />
                {/*<Route path="locations" element={<Locations />} />*/}
                <Route index element={<Groups isReadOnly={isReadOnly} />} />
              </Route>
            }
            <Route index element={<Locations isReadOnly={isReadOnly} />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default Reputation;
