import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import WCPreview from '../WCPreview';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, STRS } from '../../../../../helpers';

import smsIcon from '../../../../../img/icons/sms.svg';
import whatsappIcon from '../../../../../img/icons/whatsapp.png';
import gbmIcon from '../../../../../img/icons/google-logo.png';

import '../style.css';

function Channel(props) {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    setToggleOn(props.toggleOn);
  }, [props.toggleOn]);
  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    setToggleOn(checked);
    props.onToggleChange(checked);
  };
  const onKnowMoreClick = (e) => {
    let channelName = '';
    if (props.name === 'sms') channelName = 'SMS';
    if (props.name === 'whatsapp') channelName = 'Whatsapp';
    if (props.name === 'gbm') channelName = 'Google Business Messages';
    // nkcheck
    // FreshworksWidget('open');
    // FreshworksWidget('identify', 'ticketForm', {
    //   name: props.user.user_info.gt_profile ? props.user.user_info.gt_profile.first_name : '',
    //   email: props.user.uid,
    //   subject: `WebChat - Channel - ${channelName} - Know more`,
    // });
  };

  return (
    <div className="gBCtRw hasTgl">
      <div className="lt">
        <div className="logo">
          <img src={props.icon} />
        </div>
        <p className="title">{props.title}</p>
        <p className="desc">{props.desc}</p>
        {
          props.hasChannel
          ? (props.name === 'sms' || props.name === 'whatsapp') &&
            <Link to={`/settings/locations/${props.locationidentifier}/webchat/channels/${props.name}`}>Configure</Link>
          : <p className="know-more" onClick={onKnowMoreClick}>Know More</p>
        }
      </div>
      <div className="rt">
        {
          props.isReadOnly
          ?
          <>
            {
              props.hasChannel &&
              toggleOn &&
              <span>Active</span>
            }
          </>
          :
          <>
            {
              props.hasChannel &&
              <div className="gToggleWrap">
                <div className="gToggle">
                  <input type="checkbox" id={props.name} checked={toggleOn} onChange={onToggleChange}/><label htmlFor={props.name}>Toggle</label>
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
  );
}

function WCChatChannels(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [channelSmsOn, setChannelSmsOn] = useState(false);
  const [channelWhatsappOn, setChannelWhatsappOn] = useState(false);
  const [channelGBMOn, setChannelGBMOn] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState('');
  const [channelEditInProgress, setChannelEditInProgress] = useState(false);

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - WebChat - Messaging Channels - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { webchat } } = props;
    if (webchat) {
      if (webchat.channels) {
        setChannelSmsOn(webchat.channels && webchat.channels.sms && webchat.channels.sms.is_enabled || false);
        setChannelWhatsappOn(webchat.channels && webchat.channels.whatsapp && webchat.channels.whatsapp.is_enabled || false);
        setChannelGBMOn(webchat.channels && webchat.channels.gbm && webchat.channels.gbm.is_enabled || false);
      }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.settings.webchat.editInProgress &&
      props.settings.webchat.editStatus &&
      props.settings.webchat.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.webchat]);

  const onChannelSmsToggleChange = (value) => {
    setChannelSmsOn(value);
    setFormChanged(true);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onChannelSmsToggleChange checked: ', value);
    }
    setChannelEditInProgress(true);
    setSelectedChannel('sms');
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'sms',
      channel_data: {
        is_enabled: value,
      },
    };
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
  };

  const onChannelWhatsappToggleChange = (value) => {
    setChannelWhatsappOn(value);
    setFormChanged(true);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onChannelSmsToggleChange checked: ', value);
    }
    setChannelEditInProgress(true);
    setSelectedChannel('whatsapp');
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'whatsapp',
      channel_data: {
        is_enabled: value,
      },
    };
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
  };

  const onChannelGBMToggleChange = (value) => {
    setChannelGBMOn(value);
    setFormChanged(true);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onChannelSmsToggleChange checked: ', value);
    }
    setChannelEditInProgress(true);
    setSelectedChannel('gbm');
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-channel',
      channel_id: 'gbm',
      channel_data: {
        is_enabled: value,
      },
    };
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
  };


  const renderChannelSms = () => {
    return <Channel
      toggleOn={channelSmsOn}
      onToggleChange={onChannelSmsToggleChange}
      name={"sms"}
      icon={smsIcon}
      title="SMS"
      desc="Text messages"
      hasChannel={props.locationInfo ? props.locationInfo.has_engage_sms : false}
      user={userState.user}
      locationidentifier={urlParams.locationidentifier}
      isReadOnly={props.isReadOnly}
    />
  };
  const renderChannelWhatsapp = () => {
    return <Channel
      toggleOn={channelWhatsappOn}
      onToggleChange={onChannelWhatsappToggleChange}
      name={"whatsapp"}
      icon={whatsappIcon}
      title="Whatsapp"
      desc="Whatsapp messages"
      hasChannel={props.locationInfo ? props.locationInfo.has_engage_whatsapp : false}
      user={userState.user}
      locationidentifier={urlParams.locationidentifier}
      isReadOnly={props.isReadOnly}
    />
  };
  const renderChannelGBM = () => {
    return <Channel
      toggleOn={channelGBMOn}
      onToggleChange={onChannelGBMToggleChange}
      name={"gbm"}
      icon={gbmIcon}
      title="Google Business Messages"
      desc="Google Business Messages"
      noBorder={true}
      hasChannel={props.locationInfo ? props.locationInfo.has_gbm : false}
      user={userState.user}
      locationidentifier={urlParams.locationidentifier}
      isReadOnly={props.isReadOnly}
    />
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('WCChatChannels: ', props, ' # ', props);
  }

  const { webchat } = props.settings;
  let editInProgress = false;
  if (webchat && webchat.channels) {
    if (
      (webchat.channels.sms && webchat.channels.sms.editInProgress) ||
      (webchat.channels.whatsapp && webchat.channels.whatsapp.editInProgress) ||
      (webchat.channels.gbm && webchat.channels.gbm.editInProgress)
    ) {
      editInProgress = true;
    }
  }

  return (
    <div className="gPScrlWrp hDGen flHt wCBasic">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          All Channels{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderChannelSms() }
          { renderChannelWhatsapp() }
          { renderChannelGBM() }
        </div>
      </div>
      <div className="webChatPreviewWrap">
        <h4 className="gPageTitle title">
          Preview
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay="A display only preview of WebChat"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </h4>
        <div className="previewBox">
          <WCPreview
            screen={"channels"}
            settings={props.settings}
            data={{
              a: 1,
            }}
          />
        </div>
      </div>
      {
        editInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
    </div>
  );
}

export default WCChatChannels;
