import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation, Outlet, } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, isValidPhoneNumber, getCountryCallingCode, formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
// import DateTime from 'react-datetime';
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import { getDataAction, getDataClearAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { addSearchResultGuestToStore, addOutsideGuestStatusClearAction } from '../../../../redux/actions/miscEngageActions';

import useDebounce from '../../../Misc/DebounceHook';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { isContactValid, validateEmail, guestTransactionStatusToStayLabel, formatDate, getGuestTypeKey,
  getGuestCategoryKeyByGuest, getSearchGuestLabelByGuest, getFullNameForDisplay,
  guestLabelByGuestType, categoryToGuestType, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';
import currentIcon from '../../../../img/icons/current.svg';
import pastIcon from '../../../../img/icons/past.svg';
import upcomingIcon from '../../../../img/icons/upcoming.svg';
import staffIcon from '../../../../img/icons/staff.svg';
import webtextIcon from '../../../../img/icons/webchat.svg';
import leadsIcon from '../../../../img/icons/leads.svg';
import directIcon from '../../../../img/icons/direct-messaging.svg';
import gbmIcon from '../../../../img/icons/google-logo.png';

import './style.css';

const guestTypeIcon = {
  'guest': currentIcon,
  'past-guest': pastIcon,
  'upcoming-guest': upcomingIcon,
  'outside-guest': directIcon,
  'gbm': gbmIcon,
  'webtext': webtextIcon,
  'staff': staffIcon,
};

const tomorrow = moment().add(1, 'day');

function Search(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermChanged, setSearchTermChanged] = useState(false);

  const [filterTerm, setFilterTerm] = useState('');

  const [showAdvSearch, setShowAdvSearch] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(tomorrow));

  const [someChange, setSomeChange] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [selectedResultType, setSelectedResultType] = useState('all');

  const searchRef = useRef(null);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Engage - Search - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    // clear previous results
    dispatch(getDataClearAction({
      op: 'get_new_message_search_result',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'all',
      }
    }));
    setTimeout(() => {
      if (searchRef && searchRef.current) {
        searchRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (!showAdvSearch) {
      if (debouncedSearchTerm && debouncedSearchTerm != '+1') {
        dispatch(getDataAction({
          op: 'get_new_message_search_result',
          params: {
            location__id: urlParams.locationidentifier,
            keyword: debouncedSearchTerm,
            type: 'all',
          },
        }));
      }
    }
  }, [debouncedSearchTerm]);


  const onCloseClick = (e) => {
    props.onCancelClick('');
  };

  const onSearchTermChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSearchTermChange: ', value);
    }
    setSearchTerm(value);
    setSearchTermChanged(true);
    setSomeChange(true);
  };
  const onSearchClearClick = (e) => {
    setSearchTerm('');
    setSomeChange(true);
  };
  const onSearchTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchTerm('');
      setSomeChange(true);
    }
  };

  const onFilterTermChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFilterTermChange: ', value);
    }
    setFilterTerm(value ? value.toLowerCase() : '');
  };
  const onFilterTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setFilterTerm('');
    }
  };

  const onOpenAdvPanelClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOpenAdvPanelClick');
    }
    setShowAdvSearch(showAdvSearch => !showAdvSearch);
    setSomeChange(true);
  };
  const onAdvSearchClick = (e) => {
    if (someChange) {
      dispatch(getDataAction({
        op: 'get_new_message_search_result',
        params: {
          location__id: urlParams.locationidentifier,
          keyword: searchTerm,
          type: 'all',
          start_date: startDate,
          end_date: endDate,
        },
      }));
    }
  };

  const onRowClick = (row) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('NewMessage onRowClick: ', row);
    }
    let category = 'outside-guests';
    if (row.transaction_type === 'CheckIn') category = 'guests';
    if (row.transaction_type === 'CheckOut') category = 'past-guests';
    if (row.transaction_type === 'Reservation' || row.transaction_type === 'Booking') category = 'upcoming-guests';
    if (row.transaction_type === 'Cancel') category = 'upcoming-guests'; // 'past-guests'
    if (row.is_staff) category = 'staff';
    if (row.source === 'webtext') category = 'webtext';
    if (row.source === 'bm') category = 'gbm';
    if (category) {
      dispatch(addSearchResultGuestToStore({
        params: {
          location__id: urlParams.locationidentifier,
          guest_type: category,
          guest: row,
        }
      }));
      if (process.env.NODE_ENV !== 'production') {
        console.log('navigate to ', `/engage/locations/${urlParams.locationidentifier}/messages/${category}/${row._id}`);
      }
      navigate(`/engage/locations/${urlParams.locationidentifier}/messages/${category}/${row._id}`);
      props.onCancelClick('skip');
    }
  };

  const onResultTypeClick = (resultType) => {
    setSelectedResultType(resultType);
  };

  const onStartDateChange = (selectedDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onStartDateChange: ', selectedDate);
    }
    setStartDate(new Date(moment(selectedDate)));
    if (selectedDate > moment(startDate)) {
      setEndDate(new Date(moment(selectedDate).add('days', 1)));
    }
    setSomeChange(true);
  };
  const onEndDateChange = (selectedDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEndDateChange: ', selectedDate);
    }
    setEndDate(new Date(moment(selectedDate)));
    setSomeChange(true);
  };
  const minEndDate = () => {
    return startDate;
    // return current.isAfter(moment(startDate).subtract(1, 'day'));
  };

  const renderResultRowWrap = (row) => {
    if (filterTerm) {
      if (
        (row.contact && row.contact.indexOf(filterTerm) > -1) ||
        (row.first_name && row.first_name.toLowerCase().indexOf(filterTerm) > -1) ||
        (row.last_name && row.last_name.toLowerCase().indexOf(filterTerm) > -1) ||
        (row.name && row.name.toLowerCase().indexOf(filterTerm) > -1)
      ) return renderResultRow(row);
      return null;
    }
    return renderResultRow(row);
  };

  const renderResultRow = (row) => {
    const category = getGuestCategoryKeyByGuest(row);
    let curIcon = category ? guestTypeIcon[categoryToGuestType[category]] : staffIcon;
    let curIconToolTip = category ? guestLabelByGuestType[categoryToGuestType[category]] : '';
    return (
      <div className="rslRw" key={`srr_${row._id}`} onClick={() => onRowClick(row)} id={`srr_${row._id}`}>
        <div className="rwIt typRw">
          <div className="lt">
            {getSearchGuestLabelByGuest(row)}
          </div>
          <div className="rt">
            <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={curIconToolTip} disable={curIconToolTip ? false : true}>
              <img src={curIcon} />
            </ToolTipWrapper>
          </div>
        </div>
        <div className="rwIt topRow">
          <div className="lt">
            {getFullNameForDisplay(row) || row.contact}
          </div>
          <div className="rt">
            {row.contact}
          </div>
        </div>
        <div className="rwIt stayInfo">
          {
            (category === 'guests' || category === 'past-guests' || category === 'upcoming-guests') &&
            <div className="lt">
              Stayed: {formatDate(row.check_in_date)} to {formatDate(row.check_out_date)}
            </div>
          }
          {
            (category === 'webtext' || category === 'gbm' || category === 'outside-guests') &&
            <div className="lt">
              Last message on: {formatDate(row.date_last_message)}
            </div>
          }
          <div className="rt">
            {
              row.check_in_date ? `Room # ${row.room_number}` : ''
            }
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="sdMdl addGst searchBx">
      <div className="ctnt">
        <div className="gPWrp">
          <h4 className="gPTle title">
            <span>
            Search
            </span>
            {
              props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            }
          </h4>
          <h5 className="gPTle subTitle">
            Search by name, contact or room number.
          </h5>
          <div className="bc">
            <div className={'bcRow srchIptRw'}>
              <div className="bxLt">
                <div className={'gIpt'}>
                  <input type="text"
                    ref={searchRef}
                    value={searchTerm}
                    onKeyDown={onSearchTermKeyDown}
                    onChange={onSearchTermChange}
                    placeholder="Search"
                  />
                </div>
                {
                  !showAdvSearch &&
                  props.engage.newMessage && props.engage.newMessage.searchResults &&
                  props.engage.newMessage.searchResults.searchInProgress &&
                  <span className="srchInPrg"><i className="fa fa-spinner fa-spin" /></span>
                }
                {/*
                  searchTerm && searchTerm !== '+1' &&
                  <span className="clear" onClick={onSearchClearClick}><img src={closeIcon} /></span>
                */}



              </div>
            </div>
            <div className={'bcRow advActRw'}>
              <div className="bxLt">
                <span onClick={onOpenAdvPanelClick}>
                  Advanced
                  {
                    showAdvSearch
                    ? <i className="fa fa-caret-up" />
                    : <i className="fa fa-caret-down" />
                  }
                </span>
              </div>
            </div>
            {
              showAdvSearch &&
              <>
                <div className="bcRow advRw">
                  <div className="bxLt stDtBx">
                    <div className="gLbl">
                      Start Date
                    </div>
                    <div className="gIpt">
                      <DatePicker selected={startDate} onChange={onStartDateChange}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                  </div>
                  <div className="bxRt enDtBx">
                    <div className="gLbl">
                      End Date
                    </div>
                    <div className="gIpt">
                      <DatePicker selected={endDate} onChange={onEndDateChange}
                        dateFormat="dd MMM yyyy"
                        minDate={startDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="bcRow advRw">
                  <div className="bxLt">
                    <button className={'gBtn ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onAdvSearchClick}>
                      Search{ props.engage.newMessage && props.engage.newMessage.searchResults &&
                      props.engage.newMessage.searchResults.searchInProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  </div>
                </div>
              </>
            }
            {
              someChange &&
              props.engage.newMessage && props.engage.newMessage.searchResults.contacts &&
              props.engage.newMessage.searchResults.contacts.length
              ?
                <div className="bcRow rslTypRw">
                  <p className={'entry ' + (selectedResultType === 'all' ? 'active' : '')} onClick={() => onResultTypeClick('all')}>All</p>
                  {
                    props.engage.newMessage.searchResults.resultTypes && Object.keys(props.engage.newMessage.searchResults.resultTypes).map((item) => {
                      if (item === 'guests' || item === 'past-guests' || item === 'staff' || item === 'upcoming-guests' || item === 'outside-guests' || item === 'webtext' || item === 'gbm') {
                        return <p className={'entry ' + (selectedResultType === item ? 'active' : '')} onClick={() => onResultTypeClick(item)}>{guestLabelByGuestType[categoryToGuestType[item]]}</p>
                      }
                      return null;
                    })
                  }
                </div>
              : null
            }
            {
              someChange && props.engage.newMessage && props.engage.newMessage.searchResults.contacts && props.engage.newMessage.searchResults.contacts.length
              ?
                <>
                  <div className="srchRWrp">
                    <div className="srchRInfo">
                      <input type="text"
                        placeholder="Filter further"
                        value={filterTerm}
                        onChange={onFilterTermChange}
                        onKeyDown={onFilterTermKeyDown}
                      />
                    </div>
                    {
                      props.engage.newMessage.searchResults.contacts &&
                      props.engage.newMessage.searchResults.contacts.map((item) => {
                        if (selectedResultType && selectedResultType !== 'all') {
                          if (item.search_result_type === selectedResultType) {
                            return renderResultRowWrap(item);
                          }
                          return null;
                        }
                        return renderResultRowWrap(item);
                      })
                    }
                  </div>

                </>
              : null
            }
            {/*<div className="gLnBkFl" />*/}


          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
