import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import ImagePreviewModal from '../../../Misc/ImagePreviewModal';
import GenericSideModal from '../../../Misc/GenericSideModal';
import GenericAlertModal from '../../../Misc/GenericAlertModal';

import JMRuleRow from './JMRuleRow';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { STRS, getUserGAId } from '../../../../helpers';

import './style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function JMRuleBased(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showWhatsappModal, setShowWhatsappModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [hasSms, setHasSms] = useState(false);
  const [hasEngageWhatsapp, setHasEngageWhatsapp] = useState(false);
  const [hasEngageEmail, setHasEngageEmail] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Journey Messages - Rule Based - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setEngageProps = () => {
    const { locationInfo } = props;
    setHasSms(locationInfo.has_engage_sms || false);
    setHasEngageWhatsapp(locationInfo.has_engage_whatsapp || false);
    setHasEngageEmail(locationInfo.has_engage_email || false);
  };

  useEffect(() => {
    setEngageProps();
  }, [
    props.locationInfo,
  ]);

  useEffect(() => {
    if (props.settings.engage.journey_rules) {
      if (showDeleteModal) {
        if (selectedTemplate && selectedTemplate._id &&
          props.settings.engage.journey_rules.keyValue &&
          props.settings.engage.journey_rules.keyValue[selectedTemplate._id] &&
          !props.settings.engage.journey_rules.keyValue[selectedTemplate._id].editInProgress &&
          props.settings.engage.journey_rules.keyValue[selectedTemplate._id].editStatus &&
          props.settings.engage.journey_rules.keyValue[selectedTemplate._id].editStatus.success
        ) {
          setShowDeleteModal(false);
        }
      }
    }
  }, [props.settings.engage.journey_rules]);


  const onAddClick = (e) => {
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier
      },
    }));
    // props.dispatch(clearEditGuestJourneyRuleAction({
    //   params: {
    //     mode: 'add',
    //   },
    // }));
    setSelectedTemplate({});
    setModalType('add');
    setShowModal(true);
  };

  const onRowEditClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('selectedTemplate: ', cTemplate);
    }
    setSelectedTemplate(cTemplate);
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        rule_id: cTemplate._id,
        mode: 'edit',
      },
    }));
    // props.dispatch(clearEditGuestJourneyRuleAction({
    //   params: {
    //     rule_id: cTemplate._id,
    //     mode: 'edit',
    //   },
    // }));
    setModalType('edit');
    setShowModal(true);
  };

  const onModalCloseClick = (pathParam) => {
    setShowModal(false);
  };
  const onSmsModalCloseClick = (pathParam) => {
    setShowSmsModal(false);
  };
  const onWhatsappModalCloseClick = (pathParam) => {
    setShowWhatsappModal(false);
  };
  const onEmailModalCloseClick = (pathParam) => {
    setShowEmailModal(false);
  };

  const onRowDeleteClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowDeleteClick: ', cTemplate);
    }
    setSelectedTemplate(cTemplate);
    setShowModal(false);
    setShowDeleteModal(true);
  };

  const onRowSmsEditClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowSmsEditClick selectedTemplate: ', cTemplate);
    }
    setSelectedTemplate(cTemplate);
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        rule_id: cTemplate._id,
        mode: 'edit',
      },
    }));
    // props.dispatch(clearEditGuestJourneyRuleAction({
    //   params: {
    //     rule_id: cTemplate._id,
    //     mode: 'edit',
    //   },
    // }));
    setModalType('edit');
    setShowSmsModal(true);
  };

  const onRowWhatsappEditClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowWhatsappEditClick selectedTemplate: ', cTemplate);
    }
    setSelectedTemplate(cTemplate);
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        rule_id: cTemplate._id,
        mode: 'edit',
      },
    }));
    // props.dispatch(clearEditGuestJourneyRuleAction({
    //   params: {
    //     rule_id: cTemplate._id,
    //     mode: 'edit',
    //   },
    // }));
    setModalType('edit');
    setShowWhatsappModal(true);
  };

  const onRowEmailEditClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowEmailEditClick selectedTemplate: ', cTemplate);
    }
    setSelectedTemplate(cTemplate);
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        rule_id: cTemplate._id,
        mode: 'edit',
      },
    }));
    // setSelectedTemplate(cTemplate);
    // dispatch(editDataClearAction({
    //   op: 'edit_guest_journey_rule',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     rule_id: cTemplate._id,
    //     mode: 'edit',
    //   },
    // }));
    // // props.dispatch(clearEditGuestJourneyRuleAction({
    // //   params: {
    // //     rule_id: cTemplate._id,
    // //     mode: 'edit',
    // //   },
    // // }));
    setModalType('edit');
    setShowEmailModal(true);
  };

  const onDeleteConfirmClick = (e) => {
    dispatch(editDataClearAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        rule_id: selectedTemplate._id,
        mode: 'remove',
      },
    }));
    dispatch(editDataAction({
      op: 'edit_guest_journey_rule',
      params: {
        location__id: urlParams.locationidentifier,
        rule_id: selectedTemplate._id,
        mode: 'remove',
      },
    }));
    // gtag('event', 'gt_click', {
    //   'event_category': 'Settings - Manage Guest Journey Rules',
    //   'event_label': 'Delete',
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };


  const renderJourneyMessageRuleModal = () => {
    return (
      <GenericSideModal
        modalType="journeyMessageRuleSingle"
        className="jMRS"
        data={{
          mode: selectedTemplate && selectedTemplate._id ? 'edit' : 'add',
          template: selectedTemplate ? { ...selectedTemplate } : {},
        }}
        extData={{
          title: '',
          settings: props.settings
        }}
        setModalVisible={onModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
        isReadOnly={props.isReadOnly}
      />
    );
  };

  const renderJourneyMessageRuleSmsModal = () => {
    let propSelectedTemplate = selectedTemplate ? { ...selectedTemplate } : {};
    if (propSelectedTemplate._id && props.settings.engage.journey_rules &&
      props.settings.engage.journey_rules.keyValue &&
      props.settings.engage.journey_rules.keyValue[propSelectedTemplate._id]
    ) {
      propSelectedTemplate = props.settings.engage.journey_rules.keyValue[propSelectedTemplate._id];
    }
    return (
      <GenericSideModal
        modalType="journeyMessageRuleSms"
        className="jMRSms"
        data={{
          mode: selectedTemplate && selectedTemplate._id ? 'edit' : 'add',
          template: propSelectedTemplate,
        }}
        extData={{
          title: '',
          settings: props.settings
        }}
        setModalVisible={onSmsModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
        isReadOnly={props.isReadOnly}
      />
    );
  };

  const renderJourneyMessageRuleWhatsappModal = () => {
    let propSelectedTemplate = selectedTemplate ? { ...selectedTemplate } : {};
    if (propSelectedTemplate._id && props.settings.engage.journey_rules &&
      props.settings.engage.journey_rules.keyValue &&
      props.settings.engage.journey_rules.keyValue[propSelectedTemplate._id]
    ) {
      propSelectedTemplate = props.settings.engage.journey_rules.keyValue[propSelectedTemplate._id];
    }
    return (
      <GenericSideModal
        modalType="journeyMessageRuleWhatsapp"
        className="jMRWhatsapp"
        data={{
          mode: selectedTemplate && selectedTemplate._id ? 'edit' : 'add',
          template: propSelectedTemplate,
        }}
        extData={{
          title: '',
          settings: props.settings
        }}
        setModalVisible={onWhatsappModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
        isReadOnly={props.isReadOnly}
      />
    );
  };

  const renderJourneyMessageRuleEmailModal = () => {
    let propSelectedTemplate = selectedTemplate ? { ...selectedTemplate } : {};
    if (propSelectedTemplate._id && props.settings.engage.journey_rules &&
      props.settings.engage.journey_rules.keyValue &&
      props.settings.engage.journey_rules.keyValue[propSelectedTemplate._id]
    ) {
      propSelectedTemplate = props.settings.engage.journey_rules.keyValue[propSelectedTemplate._id];
    }
    return (
      <GenericSideModal
        modalType="journeyMessageRuleEmail"
        className="jMREmail"
        data={{
          mode: selectedTemplate && selectedTemplate._id ? 'edit' : 'add',
          template: propSelectedTemplate,
        }}
        extData={{
          title: '',
          settings: props.settings
        }}
        setModalVisible={onEmailModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
        isReadOnly={props.isReadOnly}
      />
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('JMRuleBased: ', props, ' # ', props);
  }

  const { rooms } = props.settings;
  const { journey_rules } = props.settings.engage;

  return (
    <div className="gPScrlWrp jMsg">
      <div className="gPWrp jMSms">
        <div className="gPTle title add">
          Manage Guest Journey Rules{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
          {
            !props.isReadOnly &&
            <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
          }
        </div>
        <div className="gPSubTle">
          Add/Edit rules to customize guest journey messages based on room number / types.
        </div>
        <div className="gBCtnt">
          {
            journey_rules && journey_rules.list && journey_rules.list.map((item, index) => {
              if (!journey_rules.keyValue[item] || journey_rules.keyValue[item].is_removed) return null;
              if (!rooms || !rooms.keyValue || !rooms.keyValue[journey_rules.keyValue[item].room_id]) return null;
              return <JMRuleRow
                key={`jmrr_${item}`}
                template={journey_rules.keyValue[item]}
                room={rooms.keyValue[journey_rules.keyValue[item].room_id]}
                onEditClick={onRowEditClick} onDeleteClick={onRowDeleteClick}
                onWhatsappEditClick={onRowWhatsappEditClick}
                onSmsEditClick={onRowSmsEditClick}
                onEmailEditClick={onRowEmailEditClick}
                isLast={journey_rules.list.length === index+1}
                hasSms={hasSms}
                hasEngageWhatsapp={hasEngageWhatsapp}
                hasEngageEmail={hasEngageEmail}
                isReadOnly={props.isReadOnly}
              />
              // return renderRow(messageTemplates.keyValue[item]);
            })
          }
        </div>
      </div>
      { showModal && renderJourneyMessageRuleModal() }
      { showSmsModal && renderJourneyMessageRuleSmsModal() }
      { showWhatsappModal && renderJourneyMessageRuleWhatsappModal() }
      { showEmailModal && renderJourneyMessageRuleEmailModal() }
      { showDeleteModal &&
          <GenericAlertModal
            modalType="Delete Template"
            bodyRowOne={`Do you want to delete this rule (${selectedTemplate.label}) ? This action cannot be undone!`}
            bodyRowNote={'You’ll not be able to retrieve the contents later.'}
            keyName={selectedTemplate._id}
            setModalVisible={setShowDeleteModal}
            onAffirmativeClick={onDeleteConfirmClick}
            inProgress={journey_rules.keyValue[selectedTemplate._id] ? journey_rules.keyValue[selectedTemplate._id].editInProgress : false}
            showCancel={true}
          />
        }
    </div>
  );
}

export default JMRuleBased;
