// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';

import './style.css';

import closeIcon from '../../../img/icons/close.svg';

function IFrame(props) {
  const [showLoader, setShowLoader] = useState(true);
  const onCtaClick = (e) => {
    props.onCtaClick();
  };
  const onCloseClick = (e) => {
    props.onCancelClick();
  };
  const hideLoader = (e) => {
    setShowLoader(false);
  };

  const renderLoader = () => {
    if (!showLoader) return null;
    return(
      <div className="pageLoader">
        <span>
          <i className="fa fa-spinner fa-spin" />
        </span>
      </div>
    );
  }

  const { label, value, labelToolTip, ctaLabel, ctaLink } = props.data;

  return (
    <div className="gIFrame">
      <div className="closeRow">
        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
        <div className="getStarted">
          <button className="gBtn" onClick={onCtaClick}>
            Get Started
          </button>
        </div>
      </div>
      <div className="middle">
        { renderLoader() }
        <iframe
            width="100%"
            height="100%"
            src={props.data.src}
            onLoad={hideLoader}
        />
      </div>
      <div className="footer">
      </div>
    </div>
  );
}

export default IFrame;
