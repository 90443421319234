import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBarInner from '../../SideBarInner';
import RWGeneral from './RWGeneral';
import RWRules from './RWRules';

import { getDataAction } from '../../../../redux/actions/getDataActions';

function ReviewsWidget(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Reviews Widget - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('ReviewsWidget urlParams: ', urlParams);
      console.log('ReviewsWidget locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(getDataAction({
      op: 'get_reviews_widget_settings',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, [urlParams.locationidentifier]);

  const onContactUsClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
    try { window.Intercom('showNewMessage', `Enable Reviews Widget for ${locationName}`); } catch(e) {}
  };

  const { reviews_widget } = props.settings;

  return (
    <>
      {
        reviews_widget && reviews_widget.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      {
        reviews_widget && reviews_widget.widget_id
        ?
          <>
            <SideBarInner />
            <div className="sc">
              <Routes>
                <Route path="general" element={<RWGeneral { ...props } />} />
                <Route path="rules" element={<RWRules { ...props } />} />
              </Routes>
            </div>
          </>
        : <div className="sc">
            <div className="gInf">
              Boost trust, credibility, and conversions with our Reviews Widget! Easily showcase the best guest reviews from trusted sources like Tripadvisor, Google, Expedia, Booking.com, and more. Contact us to enable and effortlessly embed this powerful tool on your website, enhancing your online presence and attracting more bookings.
              <br/>
              <p><a href="https://www.guesttouch.com/reviews-widget-for-website" target="_blank">Learn more</a></p>
            </div>
            <div className="gInf">To enable, <span className="gLnk gCurDflt" onClick={onContactUsClick}><b>contact us</b></span>.</div>
          </div>
      }
    </>
  );
}

export default ReviewsWidget;
