import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SettingsSideBar from './SettingsSideBar';
import SettingsHome from './SettingsHome';
import SettingsWrapper from './SettingsWrapper';

import './Settings.css';

const gtconfig = require('../../gtconfig');

function Settings() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `Settings - ${gtconfig.APP_NAME_CONTACT}`;
    // window.Intercom('shutdown');
  }, []);

  return (
    <div className="mb st">
      <Routes>
        <Route element={<Outlet />}>
          <Route path="locations/*" element={<Outlet />}>
            <Route path=":locationidentifier/*" element={<SettingsSideBar />} />
            <Route index element={<SettingsSideBar />} />
          </Route>
        </Route>
      </Routes>
      <div className="mc">
        <Routes>
          <Route element={<Outlet />}>
            <Route path="locations" element={<Outlet />}>
              <Route path=":locationidentifier/*" element={<SettingsWrapper />} />
              {/*<Route index element={<Locations />} />*/}
            </Route>
            <Route index element={<SettingsHome />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default Settings;
