import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../Misc/GenericSideModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericList from '../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { STRS } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';

import './style.css';


function SenderEmails(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const [showDeleteNotAllowedModal, setShowDeleteNotAllowedModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Marketing - Sender Emails - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { sender_emails } = props.settings.marketing;
    if (selectedTemplate &&
      selectedTemplate._id &&
      sender_emails &&
      sender_emails.keyValue &&
      sender_emails.keyValue[selectedTemplate._id] &&
      !sender_emails.keyValue[selectedTemplate._id].sendInProgress &&
      sender_emails.keyValue[selectedTemplate._id].sendStatus &&
      sender_emails.keyValue[selectedTemplate._id].sendStatus.success
    ) {
      setShowVerifyModal(false);
    }
  }, [props.settings.marketing.sender_emails]);


  const onRowDeleteClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowDeleteClick: ', cTemplate);
    }
    if (cTemplate._id === props.settings.engage.te_sender_email__id) {
      setShowDeleteNotAllowedModal(true);
      return;
    }
    setSelectedTemplate(cTemplate);
    setShowModal(false);
    setShowDeleteModal(true);
  };
  const onRowVerifyClick = (cTemplate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRowVerifyClick selectedTemplate: ', cTemplate);
    }
    dispatch(editDataClearAction({
      op: 'send_sender_email_verification_email',
      params: {
        location__id: urlParams.locationidentifier,
        sender_email_id: cTemplate._id,
      },
    }));
    setSelectedTemplate(cTemplate);
    setShowVerifyModal(true);
  };
  const onVerifyConfirmClick = (e) => {
    dispatch(editDataAction({
      op: 'send_sender_email_verification_email',
      show_alert: true,
      alert_msg: 'Verification email sent',
      params: {
        location__id: urlParams.locationidentifier,
        sender_email_id: selectedTemplate._id,
      },
    }));
  };

  const onRowRefreshClick = (templateData) => {
    dispatch(getDataAction({
      op: 'get_sender_email_verification_status',
      // show_alert: true,
      // alert_msg: 'Updated verification status',
      show_error_alert: true,
      error_alert_msg: 'Error in getting verification status!',
      params: {
        location__id: urlParams.locationidentifier,
        sender_email_id: templateData._id,
      },
    }));
  };

  const onDeleteConfirmClick = (e) => {
    dispatch(editDataAction({
      op: 'edit_sender_email',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'remove',
        sender_email_id: selectedTemplate._id,
        name_or_email: 'email',
      },
    }));
  };

  const onShowDeleteNotAllowedCloseModal = (e) => {
    setShowDeleteNotAllowedModal(false);
  };


  const { settings: { marketing } } = props;
  const { sender_emails } = marketing;

  return (
    <>
      <GenericList
        type="sender_emails"
        title={`Sender Emails${props.isReadOnly ? ` [${STRS.READ_ONLY_LABEL}]` : ''}`}
        sub_title="Add sender emails. They will be used while sending marketing emails to guests."
        location__id={urlParams.locationidentifier}
        generic_list={sender_emails}
        settings={props.settings}
        onDeleteClick={onRowDeleteClick}
        onVerifyClick={onRowVerifyClick}
        onRefreshClick={onRowRefreshClick}
      />
      { showDeleteModal &&
        <GenericAlertModal
          modalType="Delete Template"
          bodyRowOne={`Do you want to delete this sender email (${selectedTemplate.value}) ? This action cannot be undone!`}
          bodyRowNote={'You’ll not be able to retrieve the contents later.'}
          keyName={selectedTemplate.label}
          setModalVisible={setShowDeleteModal}
          onAffirmativeClick={onDeleteConfirmClick}
          inProgress={
            sender_emails &&
            sender_emails.keyValue &&
            sender_emails.keyValue[selectedTemplate._id]
            ? sender_emails.keyValue[selectedTemplate._id].editInProgress
            : false
          }
          showCancel={true}
        />
      }
      { showVerifyModal &&
        <GenericAlertModal
          modalType="Verify Email"
          title="Verify Email"
          bodyRowOne={`An email will be sent to (${selectedTemplate.value}).`}
          bodyRowNote={'After verification, this email can be used to send marketing email to guests'}
          affirmativeButtonLabel="Send verification email"
          keyName={selectedTemplate.value}
          setModalVisible={setShowVerifyModal}
          onAffirmativeClick={onVerifyConfirmClick}
          inProgress={
            sender_emails &&
            sender_emails.keyValue &&
            sender_emails.keyValue[selectedTemplate._id]
            ? sender_emails.keyValue[selectedTemplate._id].sendInProgress
            : false
          }
          currentStatus={
            sender_emails &&
            sender_emails.keyValue &&
            sender_emails.keyValue[selectedTemplate._id] &&
            sender_emails.keyValue[selectedTemplate._id].sendStatus &&
            sender_emails.keyValue[selectedTemplate._id].sendStatus.error
            ? sender_emails.keyValue[selectedTemplate._id].sendStatus
            : {}
          }
          showCancel={true}
        />
      }
      { showDeleteNotAllowedModal &&
        <GenericAlertModal
          modalType="deleteModal"
          title={`Sender email in use, deletion not allowed.`}
          bodyRowOne="It is being used as sender email for Guest Journey Emails. It can only be deleted if it is not in use."
          bodyRowNote=""
          setModalVisible={onShowDeleteNotAllowedCloseModal}
          onAffirmativeClick={() => onShowDeleteNotAllowedCloseModal()}
          inProgress={false}
          // currentStatus={guest.checkOutStatus}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      }
    </>
  );
}

export default SenderEmails;
