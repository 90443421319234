const gtconfig = require('../../gtconfig');

const initialState = {};
// sideBarOpen: true
// locationIdentifier: ''
// groupIdentifier: ''
// screenBeforeSettingsPath: ''
// screenBeforeSettingsName: ''

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT': {
      return initialState;
    }
    case "ON_SIDEBAR_TOGGLE": {
      const { open } = action.payload;
      return {
        ...state,
        sideBarOpen: open
      };
    }
    // case "SET_LOCATION_IDENTIFIER": {
    //   const { locationIdentifier } = action.payload;
    //   return {
    //     ...state,
    //     locationIdentifier,
    //   };
    // }
    case "SET_GROUP_IDENTIFIER": {
      const { groupIdentifier } = action.payload;
      return {
        ...state,
        groupIdentifier,
      };
    }
    case 'SET_COMMON_PARAMS': {
      const { params } = action.payload;
      return {
        ...state,
        commonParams: state.commonParams
          ? {
            ...state.commonParams,
            ...params,
          } : {
            ...params,
          },
      };
    }
    case 'SET_APP_INFO': {
      const { params, data } = action.payload;
      const { app_info } = data;
      if (app_info.primary_color) {
        const curPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--gte-primary-color');
        if (process.env.NODE_ENV !== 'production') {
          console.log('curPrimaryColor: ', curPrimaryColor);
          console.log('app_info.primary_color: ', app_info.primary_color);
        }
        if (curPrimaryColor !== app_info.primary_color) {
          document.documentElement.style.setProperty('--gte-primary-color', app_info.primary_color);
        }
      } else {
        document.documentElement.style.setProperty('--gte-primary-color', '#0d43aa');
      }
      if (app_info.primary_color_700) {
        document.documentElement.style.setProperty('--gte-primary-color-700', app_info.primary_color_700);
      }
      if (app_info.primary_color_900) {
        document.documentElement.style.setProperty('--gte-primary-color-900', app_info.primary_color_900);
      }
      if (app_info.font_family) {
        document.documentElement.style.setProperty('--gte-font-family', app_info.font_family);
      }

      if (!('name' in app_info)) app_info.name = gtconfig.APP_NAME_CONTACT;

      return {
        ...state,
        appInfo: state.appInfo
          ? {
            ...state.appInfo,
            ...app_info,
          } : {
            ...app_info,
          },
      };
    }
    case "CHANGEAGE": {
      return { ...state, age: action.payload };
    }
  }
  return state;
};
export default appReducer;
