import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Home from './Home';
import ListPanel from './ListPanel';
import Overview from './Overview';
import RequestSent from './RequestSent';
import UploadHistory from './UploadHistory';
import RunCampaign from './RunCampaign';
import Customize from './Customize';

function Feedbacks(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { hasAccessToRunFeedbackCampaign } = props.repAccess;
  
  return (
    <>
      <ListPanel {...props} />
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path="overview" element={<Overview {...props} />} />
          <Route path="requests-sent" element={<RequestSent {...props} />} />
          <Route path="campaign-history" element={<UploadHistory {...props} />} />
          { hasAccessToRunFeedbackCampaign && <Route path="run-campaign" element={<RunCampaign {...props} />} /> }
          { hasAccessToRunFeedbackCampaign && <Route path="customize-feedbacks/*" element={<Customize {...props} />} /> }
          <Route index element={<Home {...props} />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </>
  );

  /*return (
    <div className="App">
      <h1>Feedbacks</h1>
      <Link to="/reputation/locations/abc/feedbacks/run-campaign">Run Campaign</Link>
      <Outlet />
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path="run-campaign" element={<RunCampaign />} />
          <Route index element={<Overview />} />
        </Route>
      </Routes>
    </div>
  );*/

}

export default Feedbacks;
