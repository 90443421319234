import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Rnd } from "react-rnd";

import GuestListRow from './GuestListRow';
import DisplayPreferences from './DisplayPreferences';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import AddStaff from '../../../AddGuest/AddStaff';
import NewMessage from '../../NewMessage';
import AddGuestSingle from '../../../AddGuest/AddGuestSingle';

import { getDataAction } from '../../../../../redux/actions/getDataActions';

import { formatDate, sortOptionLabels, categoryToGuestType, categoryToStoreGuestLabel, 
  guestListLabelByCategory, getUserGAId } from '../../../../../helpers';

import infoIcon from '../../../../../img/icons/info.svg';
import searchIcon from '../../../../../img/icons/search.svg';
import filterIcon from '../../../../../img/icons/filter.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import settingsIcon from '../../../../../img/icons/settings.svg';
import moreIcon from '../../../../../img/icons/more.svg';

import './style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function GuestList(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [filteredList, setFilteredList] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [selectedSort, setSelectedSort] = useState('default');

  const [showActionsPanel, setShowActionsPanel] = useState(false);
  const actionsPanelRef = useRef();
  useOnClickOutside(actionsPanelRef, useCallback(() => setShowActionsPanel(false)));

  const [showSortPanel, setShowSortPanel] = useState(false);
  const sortPanelRef = useRef();
  useOnClickOutside(sortPanelRef, useCallback(() => setShowSortPanel(false)));

  const [showEditStaffModal, setShowEditStaffModal] = useState(false);
  const editStaffModalRef = useRef();
  useOnClickOutside(editStaffModalRef, useCallback(() => onEditStaffModalCloseClick()));

  const [showNewMessageModal, setShowNewMessageModal] = useState(false);
  const newMessageModalRef = useRef();
  useOnClickOutside(newMessageModalRef, useCallback(() => onNewMessageModalCloseClick()));

  // const [boxWidth, setBoxWidth] = useState(350);
  const [boxWidth, setBoxWidth] = useState(props.guestListBoxWidth);

  const [showDisplayPreference, setShowDisplayPreferences] = useState(false);

  const [showEditGuestModal, setShowEditGuestModal] = useState(false);
  const editGuestModalRef = useRef();
  // useOnClickOutside(editGuestModalRef, useCallback(() => {
  //     onEditGuestModalCloseClick();
  // }));

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GuestList urlParams: ', urlParams);
      console.log('GuestList urlParams categoryidentifier: ', urlParams.categoryidentifier);
      console.log('GuestList guestListBoxWidth: ', props.guestListBoxWidth);
    }
  }, []);

  useEffect(() => {
    const { categoryidentifier } = urlParams;
    if (process.env.NODE_ENV !== 'production') {
      console.log('GuestList urlParams.categoryidentifier: ', categoryidentifier);
    }
    dispatch(getDataAction({
      op: 'get_guest_list',
      params: {
        location__id: urlParams.locationidentifier, // 'wZYeknJ4QgyGXj7Jm',
        type: categoryToGuestType[categoryidentifier],
      },
    }));
  }, [urlParams.locationidentifier, urlParams.categoryidentifier]);

  useEffect(() => {
    setBoxWidth(props.guestListBoxWidth);
  }, [props.guestListBoxWidth]);

  useEffect(() => {
    props.onBoxWidthChange(boxWidth);
  }, [boxWidth]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('keyword or props.gData changed: ', keyword);
    }
    const getFilteredListResult = getFilteredList();
    if (selectedSort === 'default') {
      // sortFilteredListByDefault();
      sortFilteredListByUnread(getFilteredListResult);
    }
    if (selectedSort === 'unread') {
      sortFilteredListByUnread(getFilteredListResult);
    }
    if (selectedSort === 'incoming') {
      sortFilteredListByIncoming(getFilteredListResult);
    }
    if (selectedSort === 'check-in-date') {
      sortFilteredListByCheckInDate();
    }
  }, [keyword, props.gData]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('selectedSort changed: ', selectedSort);
    }
    if (selectedSort === 'default') {
      sortFilteredListByDefault();
    }
    if (selectedSort === 'unread') {
      sortFilteredListByUnread();
    }
    if (selectedSort === 'incoming') {
      sortFilteredListByIncoming();
    }
    if (selectedSort === 'check-in-date') {
      sortFilteredListByCheckInDate();
    }
  }, [selectedSort])

  const getFilteredList = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('getFilteredList');
    }
    const { list, keyValue } = props.gData;
    if (list && keyValue) {
      let activeGuestId = urlParams['*'];

      let filteredListLocal = list.filter((el) => {
        if (keyword) {
            const guest = keyValue[el];
            if (!guest || guest.is_removed) return false;
            if (guest.is_staff && urlParams.categoryidentifier === 'outside-guests') return false;
            const keywordList = keyword.split(' ');
            if (process.env.NODE_ENV !== 'production') {
              console.log('keywordList: ', keywordList);
            }
            let reg = new RegExp(keyword, "i");
            if (keywordList.length > 1) {
              const reg1 = new RegExp(keywordList[0], "i");
              const reg2 = new RegExp(keywordList[1], "i");
              if (
                (guest.first_name && guest.first_name.search(reg1) > -1) ||
                (guest.last_name && guest.first_name.search(reg2) > -1) ||
                (guest.room_number && guest.room_number.search(reg1) > -1) ||
                (guest.room_number && guest.room_number.search(reg2) > -1)
              ) {
                return true;
              } else {
                if (
                  (guest.first_name && guest.last_name && `${guest.first_name} ${guest.last_name}`.search(reg) > -1) ||
                  (guest.first_name && guest.first_name.search(reg) > -1) ||
                  (guest.last_name && guest.last_name.search(reg) > -1) ||
                  (guest.name && guest.name.search(reg) > -1) ||
                  (guest.room_number && guest.room_number.search(reg) > -1) ||
                  (guest.contact && guest.contact.search(reg) > -1) ||
                  (guest.remarks && guest.remarks.search(reg) > -1) ||
                  (guest.date_last_message && formatDate(guest.date_last_message).search(reg) > -1) ||
                  (guest.latest_msg && guest.latest_msg.search(reg) > -1) ||
                  (guest.messages && guest.messages.length && guest.messages[0].msg && guest.messages[0].msg.search(reg) > -1) ||
                  (guest.labels && guest.labels.length && guest.labels.find(el => el.label.search(reg) > -1))
                ) {
                  return true;
                }
                return false;
              }
            } else {
              if (
                (guest.first_name && guest.last_name && `${guest.first_name} ${guest.last_name}`.search(reg) > -1) ||
                (guest.first_name && guest.first_name.search(reg) > -1) ||
                (guest.last_name && guest.last_name.search(reg) > -1) ||
                (guest.name && guest.name.search(reg) > -1) ||
                (guest.room_number && guest.room_number.search(reg) > -1) ||
                (guest.contact && guest.contact.search(reg) > -1) ||
                (guest.remarks && guest.remarks.search(reg) > -1) ||
                (guest.date_last_message && formatDate(guest.date_last_message).search(reg) > -1) ||
                (guest.latest_msg && guest.latest_msg.search(reg) > -1) ||
                (guest.messages && guest.messages.length && guest.messages[0].msg && guest.messages[0].msg.search(reg) > -1) ||
                (guest.labels && guest.labels.length && guest.labels.find(el => el.label.search(reg) > -1)) ||
                (guest.transaction_type === 'Cancel' && 'cancel'.search(reg) > -1)
              ) {
                return true;
              }
              return false;
            }
        } else {
          return true;
        }
      });

      if (process.env.NODE_ENV !== 'production') {
        console.log('getFilteredList filteredListLocal: ', filteredListLocal);
      }

      let tt = filteredListLocal.map((entry) => {
        const guest = keyValue[entry];
        if (!guest || guest.is_removed) return null;
        return guest;
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log('getFilteredList filteredListLocal tt: ', tt);
      }

      // setTimeout(() => {
      //   setFilteredList(tt);
      // }, 100);
      return tt;
    }
    return [];
  };

  const sortFilteredListByDefault = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sortFilteredListByDefault filteredList: ', filteredList);
    }
    const { list, keyValue } = props.gData;
    if (list && keyValue) {
      // setFilteredList(list.map((entry) => {
      //   const guest = keyValue[entry];
      //   if (!guest || guest.is_removed) return null;
      //   return guest;
      // }));
      setFilteredList(filteredList.map((entry) => {
        if (!entry) return null;
        const guest = keyValue[entry._id];
        if (!guest || guest.is_removed) return null;
        return guest;
      }));
    }
    setShowSortPanel(false);
  };

  const sortFilteredListByUnread = (inputList) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sortFilteredListByUnread');
    }
    const { categoryidentifier } = urlParams;
    let tempList = inputList || filteredList;
    const results = tempList.sort((a, b) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sortFilteredListByUnread tempList a: ', a);
        console.log('sortFilteredListByUnread tempList b: ', b);
      }
      const guestA = a;
      const guestB = b;
      if (!guestA || !guestB) return 0;
      if (guestA.unread_count && !guestB.unread_count) return -1;
      // if (guestA.unread_count && guestB.unread_count) return a.unread_count - b.unread_count;
      if (guestA.unread_count && guestB.unread_count) {
        return b.unread_count - a.unread_count;
        // let date_a = guestA.date_last_message_received || guestA.date_last_message_sent || guestA.date_last_message;
        // let date_b = guestB.date_last_message_received || guestB.date_last_message_sent || guestB.date_last_message;
        // if (!date_a && date_b) { return 1; }
        // if (date_a && !date_b) { return -1; }
        // // if (!date_a && !date_b) { return 0; }
        // if (!date_a && !date_b) {
        //   date_a = guestA.check_in_date;
        //   date_b = guestB.check_in_date;
        // }
        // return new Date(date_b).getTime() - new Date(date_a).getTime();
      }
      if (!guestA.unread_count && guestB.unread_count) return 1;
      if (!guestA.unread_count && !guestB.unread_count) {
        // fallback to last message
        // // let date_a = guestA.date_last_message_received || guestA.date_last_message_sent || guestA.date_last_message;
        // // let date_b = guestB.date_last_message_received || guestB.date_last_message_sent || guestB.date_last_message;
        // let date_a = guestA.date_last_message || guestA.date_last_message_sent || guestA.date_last_message_received;
        // let date_b = guestB.date_last_message || guestB.date_last_message_sent || guestB.date_last_message_received;
        // if (!date_a && date_b) { return 1; }
        // if (date_a && !date_b) { return -1; }
        // // if (!date_a && !date_b) { return 0; }
        // if (!date_a && !date_b) {
        //   date_a = guestA.check_in_date;
        //   date_b = guestB.check_in_date;
        // }
        // if (categoryidentifier === 'upcoming-guests') {
        //   return new Date(date_a).getTime() - new Date(date_b).getTime();
        // }
        // return new Date(date_b).getTime() - new Date(date_a).getTime();

        if (categoryidentifier === 'guests' || categoryidentifier === 'past-guests') { // nkcheck
          let date_a = guestA.date_last_message || guestA.date_last_message_sent || guestA.date_last_message_received;
          let date_b = guestB.date_last_message || guestB.date_last_message_sent || guestB.date_last_message_received;
          if (date_a && !date_b) return -1;
          if (date_a && date_b) return new Date(date_b).getTime() - new Date(date_a).getTime();
          if (!date_a && date_b) return 1;
          if (!date_a && !date_b) {
            // let date_a = guestA.date_last_message || guestA.date_last_message_sent || guestA.date_last_message_received;
            // let date_b = guestB.date_last_message || guestB.date_last_message_sent || guestB.date_last_message_received;
            // if (!date_a && date_b) { return 1; }
            // if (date_a && !date_b) { return -1; }
            // if (!date_a && !date_b) {
              date_a = guestA.check_in_date;
              date_b = guestB.check_in_date;
            // }
            return new Date(date_b).getTime() - new Date(date_a).getTime();
          }
        }

        // if (categoryidentifier === 'past-guests') { // nkcheck
        //   if (guestA.date_last_message_received && !guestB.date_last_message_received) return -1;
        //   if (guestA.date_last_message_received && guestB.date_last_message_received) return new Date(guestB.date_last_message_received).getTime() - new Date(guestA.date_last_message_received).getTime();
        //   if (!guestA.date_last_message_received && guestB.date_last_message_received) return 1;
        //   if (!guestA.date_last_message_received && !guestB.date_last_message_received) {
        //     let date_a = guestA.date_last_message || guestA.date_last_message_sent || guestA.date_last_message_received;
        //     let date_b = guestB.date_last_message || guestB.date_last_message_sent || guestB.date_last_message_received;
        //     if (!date_a && date_b) { return 1; }
        //     if (date_a && !date_b) { return -1; }
        //     if (!date_a && !date_b) {
        //       date_a = guestA.check_in_date;
        //       date_b = guestB.check_in_date;
        //     }
        //     return new Date(date_b).getTime() - new Date(date_a).getTime();
        //   }
        // }

        // fallback to check in date
        if (guestA.check_in_date && !guestB.check_in_date) return 1;
        if (guestA.check_in_date && guestB.check_in_date) return new Date(guestA.check_in_date).getTime() - new Date(guestB.check_in_date).getTime();
        if (!guestA.check_in_date && guestB.check_in_date) return -1;
        return 0;

      }
      return 0;
    });
    setFilteredList(results);
    setShowSortPanel(false);
  };

  const sortFilteredListByIncoming = (inputList) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sortFilteredListByIncoming');
    }
    const { categoryidentifier } = urlParams;
    let tempList = inputList || filteredList;
    const results = tempList.sort((a, b) => {
      const guestA = a;
      const guestB = b;
      if (guestA.date_last_message_received && !guestB.date_last_message_received) return -1;
      if (guestA.date_last_message_received && guestB.date_last_message_received) return new Date(guestB.date_last_message_received).getTime() - new Date(guestA.date_last_message_received).getTime();
      if (!guestA.date_last_message_received && guestB.date_last_message_received) return 1;
      if (!guestA.date_last_message_received && !guestB.date_last_message_received) {
        // let date_a = guestA.date_last_message_received || guestA.date_last_message_sent || guestA.date_last_message;
        // let date_b = guestB.date_last_message_received || guestB.date_last_message_sent || guestB.date_last_message;
        let date_a = guestA.date_last_message || guestA.date_last_message_sent || guestA.date_last_message_received;
        let date_b = guestB.date_last_message || guestB.date_last_message_sent || guestB.date_last_message_received;
        if (!date_a && date_b) { return 1; }
        if (date_a && !date_b) { return -1; }
        // if (!date_a && !date_b) { return 0; }
        if (!date_a && !date_b) {
          date_a = guestA.check_in_date;
          date_b = guestB.check_in_date;
        }
        if (categoryidentifier === 'upcoming-guests') {
          return new Date(date_a).getTime() - new Date(date_b).getTime();
        }
        return new Date(date_b).getTime() - new Date(date_a).getTime();
        
      }
      return 0;
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('sortFilteredListByIncoming results: ', results);
    }
    setFilteredList(results);
    setShowSortPanel(false);
  };


  const sortFilteredListByCheckInDate = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sortFilteredListByCheckInDate');
    }
    const results = filteredList.sort((a, b) => {
      const guestA = a;
      const guestB = b;
      if (guestA.check_in_date && !guestB.check_in_date) return 1;
      if (guestA.check_in_date && guestB.check_in_date) return new Date(guestA.check_in_date).getTime() - new Date(guestB.check_in_date).getTime();
      if (!guestA.check_in_date && guestB.check_in_date) return -1;
      return 0;
    });
    setFilteredList(results);
    setShowSortPanel(false);
  };



  const onChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
  };
  const onClearKeyword = (e) => {
    setKeyword('');
  };
  const onKeywordKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
    }
  };

  const onActionsIconClick = (e) => {
    setShowActionsPanel(showActionsPanel => !showActionsPanel);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - GuestList Settings`,
        'event_label': `GuestList Settings`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onActionDisplayPrefClick = (e) => {
    setShowDisplayPreferences(true);
  };
  const onActionJourneyMessagesClick = (e) => {
    const { pathname } = location;
    navigate(`/settings/locations/${locationidentifier}/journey-messages`,
      { state: { screenBeforeSettingsName: 'Guests', screenBeforeSettingsPath: pathname }}
    );
  };
  const onActionManageRoomsClick = (e) => {
    const { pathname } = location;
    navigate(`/settings/locations/${locationidentifier}/hotel-details/rooms`,
      { state: { screenBeforeSettingsName: 'Guests', screenBeforeSettingsPath: pathname }}
    );
  };
  const onActionMessageTemplatesClick = (e) => {
    const { pathname } = location;
    navigate(`/settings/locations/${locationidentifier}/templates`,
      { state: { screenBeforeSettingsName: 'Guests', screenBeforeSettingsPath: pathname }}
    );
  };

  const onSortClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSortClick selectedSort: ', selectedSort);
    }
    setShowSortPanel(showSortPanel => !showSortPanel);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - GuestList Sort`,
        'event_label': `GuestList Sort`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onSortDefaultClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSortDefaultClick selectedSort: ', selectedSort);
    }
    e.stopPropagation();
    setSelectedSort('default');
    // setShowSortPanel(false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - GuestList Sort Recent`,
        'event_label': `GuestList Sort Recent`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onSortUnreadClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSortUnreadClick selectedSort: ', selectedSort);
    }
    e.stopPropagation();
    setSelectedSort('unread');
    // setShowSortPanel(false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - GuestList Sort Unread`,
        'event_label': `GuestList Sort Unread`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onSortIncomingClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSortIncomingClick selectedSort: ', selectedSort);
    }
    e.stopPropagation();
    setSelectedSort('incoming');
    // setShowSortPanel(false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - GuestList Sort Incoming`,
        'event_label': `GuestList Sort Incoming`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onSortCheckInDateClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSortCheckInDateClick selectedSort: ', selectedSort);
    }
    e.stopPropagation();
    setSelectedSort('check-in-date');
    // setShowSortPanel(false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - GuestList Sort Check-In Date`,
        'event_label': `GuestList Sort Check-In Date`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDisplayPreferencesModalCloseClick = (e) => {
    setShowDisplayPreferences(false);
  };

  const onDisplayPreferenceUpdate = (e) => {
    // refresh the list
    dispatch(getDataAction({
      op: 'get_guest_list',
      params: {
        location__id: urlParams.locationidentifier, // 'wZYeknJ4QgyGXj7Jm',
        type: categoryToGuestType[categoryidentifier],
      },
    }));
  }

  const onAddStaffClick = (e) => {
    if (props.isReadOnly) return;
    setShowEditStaffModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Add Staff`,
        'event_label': `Add Staff`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onEditStaffModalCloseClick = (pathParam) => {
    setShowEditStaffModal(false);
    // clearGuestEditStatus(); // nkcheck
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditStaffModalCloseClick pathParam: ', pathParam);
    }
    if (pathParam) {
      navigate(pathParam);
    }
  };


  const onNewMessageClick = (e) => {
    if (props.isReadOnly) return;
    setShowNewMessageModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - New Message`,
        'event_label': `New Message`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onNewMessageModalCloseClick = (pathParam) => {
    setShowNewMessageModal(false);
  };


  const onAddGuestClick = (e) => {
    if (props.isReadOnly) return;
    setShowEditGuestModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - GuestList - Add Guest`,
        'event_label': `Add Guest`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onEditGuestModalCloseClick = (pathParam) => {
    setShowEditGuestModal(false);
    // clearGuestEditStatus(); // nkcheck
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditGuestModalCloseClick pathParam: ', pathParam);
    }
    if (pathParam) {
      navigate(pathParam);
    }
  };


  const renderGuestList_Old = (list, keyValue) => {
    if (!list || !keyValue) return null;
    let activeGuestId = urlParams['*'];

    let filteredList = list.filter((el) => {
      if (keyword) {
          const guest = keyValue[el];
          if (!guest || guest.is_removed) return false;
          if (guest.is_staff && categoryidentifier === 'outside-guests') return false;
          const keywordList = keyword.split(' ');
          let reg = new RegExp(keyword, "i");
          if (keywordList.length > 1) {
            const reg1 = new RegExp(keywordList[0], "i");
            const reg2 = new RegExp(keywordList[1], "i");
            if (
              (guest.first_name && guest.first_name.search(reg1) > -1) ||
              (guest.last_name && guest.first_name.search(reg2) > -1)
            ) {
              return true;
            } else {
              if (
                (guest.first_name && guest.last_name && `${guest.first_name} ${guest.last_name}`.search(reg) > -1) ||
                (guest.first_name && guest.first_name.search(reg) > -1) ||
                (guest.last_name && guest.last_name.search(reg) > -1) ||
                (guest.name && guest.name.search(reg) > -1) ||
                (guest.contact && guest.contact.search(reg) > -1) ||
                (guest.remarks && guest.remarks.search(reg) > -1) ||
                (guest.date_last_message && formatDate(guest.date_last_message).search(reg) > -1) ||
                (guest.latest_msg && guest.latest_msg.search(reg) > -1) ||
                (guest.messages && guest.messages.length && guest.messages[0].msg && guest.messages[0].msg.search(reg) > -1) ||
                (guest.labels && guest.labels.length && guest.labels.find(el => el.label.search(reg) > -1))
              ) {
                return true;
              }
              return false;
            }
          } else {
            if (
              (guest.first_name && guest.last_name && `${guest.first_name} ${guest.last_name}`.search(reg) > -1) ||
              (guest.first_name && guest.first_name.search(reg) > -1) ||
              (guest.last_name && guest.last_name.search(reg) > -1) ||
              (guest.name && guest.name.search(reg) > -1) ||
              (guest.contact && guest.contact.search(reg) > -1) ||
              (guest.remarks && guest.remarks.search(reg) > -1) ||
              (guest.date_last_message && formatDate(guest.date_last_message).search(reg) > -1) ||
              (guest.latest_msg && guest.latest_msg.search(reg) > -1) ||
              (guest.messages && guest.messages.length && guest.messages[0].msg && guest.messages[0].msg.search(reg) > -1) ||
              (guest.labels && guest.labels.length && guest.labels.find(el => el.label.search(reg) > -1)) ||
              (guest.transaction_type === 'Cancel' && 'cancel'.search(reg) > -1)
            ) {
              return true;
            }
            return false;
          }
      } else {
        return true;
      }
    });

    return filteredList.map((entry) => {
      const guest = keyValue[entry];
      if (!guest || guest.is_removed) return null;
      return (
        <GuestListRow key={`glr_${guest._id}`} guest={guest} active={guest._id === activeGuestId}
          settings={props.settings}
        />
      )
    })
  };

  const renderGuestList = (list, keyValue) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderGuestList filteredList: ', filteredList);
    }
    // if (!list || !keyValue) return null;
    // let activeGuestId = urlParams['*'];
    //
    // let filteredList = list.filter((el) => {
    //   if (keyword) {
    //       const guest = keyValue[el];
    //       if (!guest || guest.is_removed) return false;
    //       if (guest.is_staff && categoryidentifier === 'outside-guests') return false;
    //       const keywordList = keyword.split(' ');
    //       let reg = new RegExp(keyword, "i");
    //       if (keywordList.length > 1) {
    //         const reg1 = new RegExp(keywordList[0], "i");
    //         const reg2 = new RegExp(keywordList[1], "i");
    //         if (
    //           (guest.first_name && guest.first_name.search(reg1) > -1) ||
    //           (guest.last_name && guest.first_name.search(reg2) > -1)
    //         ) {
    //           return true;
    //         } else {
    //           if (
    //             (guest.first_name && guest.last_name && `${guest.first_name} ${guest.last_name}`.search(reg) > -1) ||
    //             (guest.first_name && guest.first_name.search(reg) > -1) ||
    //             (guest.last_name && guest.last_name.search(reg) > -1) ||
    //             (guest.name && guest.name.search(reg) > -1) ||
    //             (guest.contact && guest.contact.search(reg) > -1) ||
    //             (guest.remarks && guest.remarks.search(reg) > -1) ||
    //             (guest.date_last_message && formatDate(guest.date_last_message).search(reg) > -1) ||
    //             (guest.latest_msg && guest.latest_msg.search(reg) > -1) ||
    //             (guest.messages && guest.messages.length && guest.messages[0].msg && guest.messages[0].msg.search(reg) > -1) ||
    //             (guest.labels && guest.labels.length && guest.labels.find(el => el.label.search(reg) > -1))
    //           ) {
    //             return true;
    //           }
    //           return false;
    //         }
    //       } else {
    //         if (
    //           (guest.first_name && guest.last_name && `${guest.first_name} ${guest.last_name}`.search(reg) > -1) ||
    //           (guest.first_name && guest.first_name.search(reg) > -1) ||
    //           (guest.last_name && guest.last_name.search(reg) > -1) ||
    //           (guest.name && guest.name.search(reg) > -1) ||
    //           (guest.contact && guest.contact.search(reg) > -1) ||
    //           (guest.remarks && guest.remarks.search(reg) > -1) ||
    //           (guest.date_last_message && formatDate(guest.date_last_message).search(reg) > -1) ||
    //           (guest.latest_msg && guest.latest_msg.search(reg) > -1) ||
    //           (guest.messages && guest.messages.length && guest.messages[0].msg && guest.messages[0].msg.search(reg) > -1) ||
    //           (guest.labels && guest.labels.length && guest.labels.find(el => el.label.search(reg) > -1)) ||
    //           (guest.transaction_type === 'Cancel' && 'cancel'.search(reg) > -1)
    //         ) {
    //           return true;
    //         }
    //         return false;
    //       }
    //   } else {
    //     return true;
    //   }
    // });

    if (categoryidentifier === 'guests' || categoryidentifier === 'upcoming-guests') {
      const hasEntry = filteredList.find(item => item && !item.is_removed);
      if (!hasEntry) {
        return (
          <div className="noGst">
            <p>No guests yet!</p>
            <button type="submit" className="gBtn" onClick={onAddGuestClick}>
              Add Guest
            </button>
          </div>
        );
      }
    }

    let activeGuestId = urlParams['*'];
    return filteredList.map((guest) => {
    // return [].map((guest) => {
      // const guest = keyValue[entry];
      if (!guest || guest.is_removed) return null;
      return (
        <GuestListRow key={`glr_${guest._id}`} guest={guest} active={guest._id === activeGuestId}
          settings={props.settings}
        />
      )
    })
  };

  const renderDisplayPreferences = () => {
    const { locationidentifier } = urlParams;
    const guestType = categoryToGuestType[categoryidentifier]
    let locationProfile = {};
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile.locations && userState.user.user_info.profile.locations[locationidentifier] &&
      userState.user.user_info.profile.locations[locationidentifier].engage
    ) {
      locationProfile = userState.user.user_info.profile.locations[locationidentifier].engage;
    }
    return (
      <Modal>
        <div id="cModelId" className="sdMdl gLDispPref">
          <div className="ctnt">
            <DisplayPreferences
              onCancelClick={onDisplayPreferencesModalCloseClick}
              locationidentifier={urlParams.locationidentifier}
              guestType={guestType}
              onDisplayPreferenceUpdate={onDisplayPreferenceUpdate}
              locationProfile={locationProfile}
            />
          </div>
        </div>
      </Modal>
    );
  };


  const renderEditStaffModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={editStaffModalRef}>
            <AddStaff mode="add"
              settings={settings}
              engage={engage}
              onCancelClick={onEditStaffModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderNewMessageModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={newMessageModalRef}>
            <NewMessage
              settings={settings}
              engage={engage}
              onCancelClick={onNewMessageModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderEditGuestModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={editGuestModalRef}>
            <AddGuestSingle mode="add" guestType={'guests'}
              settings={settings}
              engage={engage}
              onCancelClick={onEditGuestModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };


  const { categoryidentifier, locationidentifier } = urlParams;
  if (!locationidentifier) return null;
  if (!categoryidentifier) return null;

  if (process.env.NODE_ENV !== 'production') {
    console.log('GuestList locationidentifier: ', locationidentifier);
    console.log('GuestList categoryidentifier: ', categoryidentifier);
  }

  const storeGuestTypeLabel = categoryToStoreGuestLabel[categoryidentifier];
  if (!storeGuestTypeLabel) return null;

  if (process.env.NODE_ENV !== 'production') {
    console.log('GuestList storeGuestTypeLabel: ', storeGuestTypeLabel);
  }

  let gData = locationState.locations[locationidentifier] && locationState.locations[locationidentifier].engage
    && locationState.locations[locationidentifier].engage[storeGuestTypeLabel]
    ? locationState.locations[locationidentifier].engage[storeGuestTypeLabel]
    : {};
  const { list, keyValue } = gData;
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('GuestList gData: ', gData);
  // }
  //
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('GuestList list: ', list);
  // }

  // let tooltipInfo = <p>Checked-In guests. <Link to={'?add_staff=1'}>Add your team here</Link></p>;
  // let tooltipInfo = <p>Checked-In guests</p>;
  // let tooltipInfo = <p>Direct messages can be from non-registered guests/customers/vendors or anyone with whom the conversations are initiated. WhatsApp and other channel messages appear here as well.<br/><br/><b>Questions about the direct messages? <a href="https://newaccount1606477656776.freshdesk.com/support/solutions/articles/82000225766-what-are-direct-messages-" target="_blank">Learn more</a></b></p>;
  let tooltipInfo = '';
  if (categoryidentifier === 'guests') {
    tooltipInfo = <p>Currently staying guests</p>;
  }
  if (categoryidentifier === 'past-guests') {
    tooltipInfo = <p>Checked-Out guests</p>;
  }
  if (categoryidentifier === 'upcoming-guests') {
    tooltipInfo = <p>Future arrivals</p>;
  }
  if (categoryidentifier === 'outside-guests') {
    tooltipInfo = <p>Direct messages are conversations that are initiated with anyone: non-registered guests/customers/vendors etc. Direct WhatsApp, Google business message from google maps/search and other channel messages appear here as well. <br/><br/>Initiate a New message <span className="nwMsg" onClick={onNewMessageClick}>here</span></p>;
  }
  if (categoryidentifier === 'webtext') {
    tooltipInfo = <p>Increase your direct sales: Connect with website visitors and respond to their inquiries. You can configure your WebChat in <Link to={`/settings/locations/${urlParams.locationidentifier}/webchat`} state={{ screenBeforeSettingsName: 'Engage', screenBeforeSettingsPath: location.pathname }}>settings</Link><br/><br/>Learn more about <a href="https://newaccount1606477656776.freshdesk.com/support/solutions/articles/82000225765-how-to-setup-configure-webchat-" target="_blank">webchat</a></p>;
  }
  if (categoryidentifier === 'staff') {
    tooltipInfo = <p>Interact with your staff</p>;
  }
  if (categoryidentifier === 'gbm') {
    tooltipInfo = <p>Messages from Google</p>;
  }

  const loading = gData.getListInProgress || false;

  const hasGuestEntry = filteredList.find(item => item && !item.is_removed);

  return (
    <Rnd
      className={'glW'}
      size={{width: boxWidth}}
      style={{}}
      disableDragging={true}
      enableResizing={{
        right: true,
      }}
      maxWidth={460}
      minWidth={280}
      onResize={(e, direction, ref, delta, position) => {
        setBoxWidth(ref.style.width);
      }}
    >
      { loading && <div className="gPnlLdng"><span><i className="fas fa-spinner fa-spin"></i></span></div> }
      <div className="rszIndc">
        <span><i className="fas fa-grip-vertical"></i></span>
        <span><i className="fas fa-grip-vertical"></i></span>
        <span><i className="fas fa-grip-vertical"></i></span>
      </div>
      <div className="hdr">
        <div className="ttl">
          <h3>{guestListLabelByCategory[categoryidentifier]}</h3>
          <ToolTipWrapper placement="bottom" overlayClassName={'infoTooltip'}
            overlay={tooltipInfo}
          >
            <img src={infoIcon} />
          </ToolTipWrapper>
          {
            categoryidentifier === 'staff'
            &&
            <ToolTipWrapper placement="bottom" overlayClassName={'infoTooltip'}
              overlay="Add Staff"
            >
              <div className="addStfBx">
              <span onClick={onAddStaffClick}><i className="fa fa-plus-circle" /></span>
              </div>
            </ToolTipWrapper>
          }
        </div>
        <div className="opt">
          <div className="sts">
            <div ref={actionsPanelRef} className={'dropdown ' + (showActionsPanel ? 'open' : '')}
              onClick={onActionsIconClick}
            >
              <div className="dropbtn">
                <img className="sIcon" src={settingsIcon} />
              </div>
              <div className="dropdown-content">
                {
                  (categoryidentifier === 'guests' ||
                  categoryidentifier === 'past-guests' ||
                  categoryidentifier === 'upcoming-guests' ||
                  categoryidentifier === 'outside-guests' ||
                  categoryidentifier === 'webtext' ||
                  categoryidentifier === 'gbm') &&
                  <p onClick={onActionDisplayPrefClick}>Display Preferences</p>
                }
                {
                  (categoryidentifier === 'guests' ||
                  categoryidentifier === 'past-guests' ||
                  categoryidentifier === 'upcoming-guests') &&
                  <p onClick={onActionJourneyMessagesClick}>Edit Journey Messages</p>
                }
                {
                  (categoryidentifier === 'guests' ||
                  categoryidentifier === 'past-guests' ||
                  categoryidentifier === 'upcoming-guests') &&
                  <p onClick={onActionManageRoomsClick}>Manage Rooms</p>
                }
                <p onClick={onActionMessageTemplatesClick}>Message Templates</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (hasGuestEntry || keyword) &&
        <div className="optB">
          <div className="filter">
            <img className="fIcon" src={searchIcon} />
            <input type="text" value={keyword} onChange={onChange} onKeyDown={onKeywordKeyDown} />
            {
              keyword &&
              <img className="cIcon" src={closeIcon} onClick={onClearKeyword} />
            }
          </div>
          <div className="sort">
            <div ref={sortPanelRef} className={'dropdown ' + (showSortPanel ? 'open' : '')}
              onClick={onSortClick}
            >
              <div className="dropbtn">
                <span>{sortOptionLabels[selectedSort]}</span><img className="sIcon" src={filterIcon} />
              </div>
              <div className="dropdown-content">
                <p onClick={onSortDefaultClick}>Default</p>
                <p onClick={onSortUnreadClick}>Unread</p>
                <p onClick={onSortIncomingClick}>Incoming</p>
                { categoryidentifier === 'upcoming-guests' && <p onClick={onSortCheckInDateClick}>Check-In Date</p> }
              </div>
            </div>
          </div>
        </div>
      }
      <div className="glB">
        {
          renderGuestList(list, keyValue)
        }
      </div>
      { showDisplayPreference && renderDisplayPreferences() }
      { showEditStaffModal && renderEditStaffModal() }
      { showNewMessageModal && renderNewMessageModal() }
      { showEditGuestModal && renderEditGuestModal() }
      {/*<Routes>
        <Route path="*" element={<Location />}>
          <Route path="run-campaign" element={<Reviews />} />
          <Route index element={<Overview />} />
        </Route>
      </Routes>*/}
    </Rnd>
  );
}

export default GuestList;
