import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import '../style.css';

function ResponseTemplateCategoryRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (inUseCount) => {
    if (inUseCount) return;
    props.onDeleteClick(props.template);
  };


  const { template, settings } = props;
  const { reputation } = settings;
  if (!reputation) return;
  const { response_templates, prompt_templates } = reputation;
  const { response_template_categories, prompt_template_categories } = reputation;
  let inUseCount = 0;
  let categoryName = '';
  if (props.categoryType === 'responseTemplateCategory') {
    if (props.template._id &&
      response_template_categories && response_template_categories.keyValue &&
      response_template_categories.keyValue[props.template._id]
    ) {
      if (response_templates && response_templates.list && response_templates.list.length &&
        response_templates.keyValue
      ) {
        response_templates.list.forEach((item) => {
          if (response_templates.keyValue[item] && response_templates.keyValue[item].category_id === props.template._id) {
            inUseCount++;
          }
        });
      }
    }
  }
  if (props.categoryType === 'promptTemplateCategory') {
    if (props.template._id &&
      prompt_template_categories && prompt_template_categories.keyValue &&
      prompt_template_categories.keyValue[props.template._id]
    ) {
      if (prompt_templates && prompt_templates.list && prompt_templates.list.length &&
        prompt_templates.keyValue
      ) {
        prompt_templates.list.forEach((item) => {
          if (prompt_templates.keyValue[item] && prompt_templates.keyValue[item].category_id === props.template._id) {
            inUseCount++;
          }
        });
      }
    }
  }

  return (
    <div className="gBCtRw respTplRw" id={`mt_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.name}
              />
            : template.name
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={inUseCount ? "In use, cannot delete" : "Delete"}>
              <span onClick={() => onDeleteClick(inUseCount)}><img className="delete" src={deleteIcon} /></span>
            </ToolTipWrapper>
            </>
          }
        </div>
      </div>
      <div className="bt">
        {
          inUseCount > 0 &&
          <span>In use by {inUseCount} template/s</span>
        }
      </div>
    </div>
  )

}

export default ResponseTemplateCategoryRow;
