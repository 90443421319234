import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

// import SideBar from './SideBar';

function Home() {
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    navigate(`/engage/locations/${urlParams.locationidentifier}/messages/guests`);
  }, []);

  return (
    <>
      <p>Choose a category</p>
    </>
  );
}

export default Home;
