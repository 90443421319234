import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import GuestList from './GuestList';
import GuestPanelWrap from './GuestPanel/GuestPanelWrap';

import { guestListLabelByCategory, categoryToStoreGuestLabel, getUserGAId } from '../../../../helpers';

import './Category.css';

function SelectConversation(props) {
  return (
    <div className="selConv">
      <p>Select Conversation</p>
    </div>
  )
}

function Category(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [guestListBoxWidth, setGuestListBoxWidth] = useState(350);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Messages - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Category urlParams: ', urlParams);
      console.log('Category props: ', props);
      console.log('Category urlParams categoryidentifier: ', urlParams.categoryidentifier);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Category windowWidth: ', windowState.window.windowWidth);
    }
    if (windowState.window.windowWidth <= 768) {
      setGuestListBoxWidth(200);
    }
  }, [windowState.window.windowWidth]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Category urlParams: ', urlParams);
      console.log('Category urlParams categoryidentifier: ', urlParams.categoryidentifier);
    }
  }, [urlParams.locationidentifier]);

  const onGuestListBoxWidthChange = (value) => {
    setGuestListBoxWidth(value);
  };

  const onContactUsWebChatClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
    try { window.Intercom('showNewMessage', `Enable WebChat for ${locationName}`); } catch(e) {}
  };

  const onContactUsGBMClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
    try { window.Intercom('showNewMessage', `Enable Google Business Messages for ${locationName}`); } catch(e) {}
  };

  const { settings } = props;
  if (!settings || !settings.info) return null;

  const { locationidentifier, categoryidentifier } = urlParams;
  if (!locationidentifier) return null;
  if (!categoryidentifier) return null;

  if (categoryidentifier === 'webtext' && !props.settings.info.has_webtext) {
    return (
      <div className="reqActivation">
        <div className="gInf">
        <h3>WebChat</h3>
        Elevante your website's conversion rates with our custom chat widget made for modern hoteliers. Give visitors seamless access to connect with you, helping them effortlessly turn inquiries into bookings through convenient on-the-go messaging.
        <p className="gCurDflt"><a href="https://www.guesttouch.com/web-chat" target="_blank">Learn more</a></p>
        </div>
        <p className="pAll20 gLnk gCurDflt"><b onClick={onContactUsWebChatClick}>Request Setup and Activation</b></p>
      </div>
    );
  }
  if (categoryidentifier === 'gbm' && !props.settings.info.has_gbm) {
    return (
      <div className="reqActivation">
        <div className="gInf">
        <h3>Google Business Messages</h3>
        Connect effortlessly with your guests in the moments that matter through the GuestTouch and Google Business Messages partnership. You'll be able to seamlessly engage with potential customers across Google Search and Maps, whether they're browsing on desktop or mobile devices. Make it simple for them to initiate valuable conversations.
        <p className="gCurDflt"><a href="https://www.guesttouch.com/google-business-messages-for-hotels" target="_blank">Learn more</a></p>
        </div>
        
        <p className="pAll20 pt0 gLnk gCurDflt"><b onClick={onContactUsGBMClick}>Request Setup and Activation</b></p>
      </div>
    );
  }

  const { hasAccessToWebChatReply } = props.engAccess;
  if (categoryidentifier === 'webtext' && !hasAccessToWebChatReply) return null;


  const storeGuestTypeLabel = categoryToStoreGuestLabel[categoryidentifier];
  if (!storeGuestTypeLabel) return null;

  if (process.env.NODE_ENV !== 'production') {
    console.log('Category storeGuestTypeLabel: ', storeGuestTypeLabel);
  }

  let gData = locationState.locations[locationidentifier] && locationState.locations[locationidentifier].engage
    && locationState.locations[locationidentifier].engage[storeGuestTypeLabel]
    ? locationState.locations[locationidentifier].engage[storeGuestTypeLabel]
    : {};

  return (
    <>
      <GuestList {...props} gData={gData} guestListBoxWidth={guestListBoxWidth} onBoxWidthChange={onGuestListBoxWidthChange} />
      <Routes>
        <Route path=":guestidentifier" element={<GuestPanelWrap {...props} guestListBoxWidth={guestListBoxWidth} />} />
        <Route index element={<SelectConversation />} />
      </Routes>
      {/*}<Routes>
        <Route path="*" element={<Outlet />}>
          <Route path=":categoryidentifier" element={<Category />} />
          <Route index element={<Home />} />
        </Route>
      </Routes>*/}
      {/*<Routes>
        <Route path="*" element={<Location />}>
          <Route path="run-campaign" element={<Reviews />} />
          <Route index element={<Overview />} />
        </Route>
      </Routes>*/}
    </>
  );
}

export default Category;
