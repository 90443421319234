import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBar from '../Reputation/SideBar';
import Home from './Home';
import Locations from './Locations';
import LocationWrapper from './LocationWrapper';

import { ACCESS_LEVELS } from '../../helpers';

import './Marketing.css';

const gtconfig = require('../../gtconfig');

function Marketing() {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `Marketing - ${gtconfig.APP_NAME_CONTACT}`;
    // window.Intercom('shutdown');
    // window.Intercom("boot", {
    //   api_base: "https://api-iam.intercom.io",
    //   app_id: "mydf4dtr",
    //   name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
    //     email: userState.user.uid, // the email for your user
    //   // created_at: user.createdAt, // Signup date as a Unix timestamp
    // });
  }, []);

  const isReadOnly = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;

  let hasAnyMarketing = false;
  if (userState.user.user_info.profile.locations && userState.user.user_info.locations) {
    const locIdList = Object.keys(userState.user.user_info.profile.locations);
    for (let i = 0; i < locIdList.length; i++) {
      if (userState.user.user_info.locations[locIdList[i]] && userState.user.user_info.locations[locIdList[i]].has_transaction_emails) {
        hasAnyMarketing = true; break;
      }
    }
  }

  // (!userState.user.user_info.profile.access_level || !userState.user.user_info.profile.locations) 
  if (!hasAnyMarketing) {
    return (
      <div className="mb iframeBx">
        <iframe id="iframeId"
          title={gtconfig.APP_NAME_CONTACT}
          src="https://guesttouch.com/internal-transaction-guest-journey-email"
        >
        </iframe>
      </div>
    );
  }

  return (
    <div className={'mb mk' + (' sbClsd1')}>
      <Routes>
        <Route element={<Outlet />}>
          <Route path="locations/*" element={<Outlet />}>
            <Route path=":locationidentifier/*" element={<SideBar isReadOnly={isReadOnly} />} />
            <Route index element={<SideBar isReadOnly={isReadOnly} />} />
          </Route>
          <Route index element={<SideBar isReadOnly={isReadOnly} />} />
        </Route>
      </Routes>
      <div className="mc">
        <Routes>
          <Route element={<Outlet />}>
            <Route path="locations" element={<Outlet />}>
              <Route path=":locationidentifier/*" element={<LocationWrapper isReadOnly={isReadOnly} />} />
              <Route index element={<Locations isReadOnly={isReadOnly} />} />
            </Route>
            <Route index element={<Locations isReadOnly={isReadOnly} />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default Marketing;
