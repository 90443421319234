import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import { isLocationAdminAndAbove } from '../../../../../helpers';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import '../style.css';

function RoomRow(props) {
  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };

  const { template } = props;
  return (
    <div className="gBCtRw roomRw" id={`rm_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.label}
              />
            : template.label
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
                <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
              </ToolTipWrapper>
              {
                isLocationAdminAndAbove(props.accessLevel) &&
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
                  <span onClick={onDeleteClick}><img className="delete" src={deleteIcon} /></span>
                </ToolTipWrapper>
              }
            </>
          }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
        {
          template.attr && template.attr['room-number']
          ? <span>Room Number: {template.attr['room-number']}</span>
          : null
        }
        {
          template.attr && template.attr['room-type']
          ? <span>Room Type: {template.attr['room-type']}</span>
          : null
        }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          <span>Keycode: {template.keycode}</span>
        </div>
      </div>
      {
        props.settings && props.settings.info && props.settings.info.has_remote_lock &&
        <div className="bt">
          <div className="lt">
            <span>Assigned Remote Lock: {template.remote_lock_id &&
              props.settings.remote_locks &&
              props.settings.remote_locks.keyValue &&
              props.settings.remote_locks.keyValue[template.remote_lock_id] &&
              props.settings.remote_locks.keyValue[template.remote_lock_id].attributes ?
              props.settings.remote_locks.keyValue[template.remote_lock_id].attributes.name || '' : ''
              }
            </span>
          </div>
        </div>
      }
    </div>
  )

}

export default RoomRow;
