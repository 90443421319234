import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import WCChatChannels from '../WCChatChannels';
import WCConfigureSms from './WCConfigureSms';
import WCConfigureWhatsapp from './WCConfigureWhatsapp';

function WCChatChannelsRouter(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  return (
    <Routes>
      <Route path="*" element={<Outlet />}>

        <Route path="sms" element={<WCConfigureSms { ...props } />} />
        <Route path="whatsapp" element={<WCConfigureWhatsapp { ...props } />} />

        <Route index element={<WCChatChannels { ...props } />} />
      </Route>
    </Routes>
  );
}

export default WCChatChannelsRouter;
