// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';

import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import HistoryDetailModal from './HistoryDetailModal';

import GenericAlertModal from '../../Misc/GenericAlertModal';
import ToolTipWrapper from '../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../Misc/OutsideClickHook';

import { ENGAGE_FEATURES, hasAccessToFeature } from '../../../helpers';
import { formatDate, formatDateStrToStr, getSearchGuestTypeByTransactionType,
  TRANSACTION_EMAIL_TYPE_TO_LABEL, TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP,
  COUNTRY_LIST_KEYS, DEVICE_TYPE_CODE_TO_LABEL,
  OTA_NAME_TO_SOURCE_MAP, renderOTALogo } from '../../../helpers';

import moreIcon from '../../../img/icons/more-horizontal.svg';
import webIcon from '../../../img/icons/website.png';

import './style.css';


const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SingleTransactionEmailHistoryBox(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  let location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const cMenuRef = useRef();
  useOnClickOutside(cMenuRef, useCallback(() => closeMenu()));

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [showTaskDetailModal, setShowTaskDetailModal] = useState(false);


  const closeMenu = (e) => {
    setMenuOpen(false);
  };
  const onOpenMenuClick = (e) => {
    setMenuOpen(true);
  };
  const onDetailClick = (e) => {
    // props.history.push(`/marketing/transaction-emails/history/${props.transactionEmailData._id}`);
    setShowTaskDetailModal(true);
  };


  const onTaskDetailModalCloseClick = (e) => {
    setShowTaskDetailModal(false);
  }

  const renderTaskDetailModal = () => {
    const info = props.itemData;
    return (
      <Modal>
        <div id="cModelId" className="cModal mtModal">
          <div className="content">
            <HistoryDetailModal onCancelClick={onTaskDetailModalCloseClick} itemData={info}
              type="taskDetail"
              locationMId={props.locationMId}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderOverflowMenu = () => {
    return (
      <div className="oMenu">
        <div className="dropdown" ref={cMenuRef}>
          <button className="dropbtn" onClick={onOpenMenuClick} ><img src={moreIcon}/></button>
          <div className={'dropdown-content ' + (menuOpen ? 'open ' : '')}>
            <p onClick={onDetailClick}>Details</p>
          </div>
        </div>
      </div>
    );
  };


  const renderContent = () => {
    const { itemData } = props;
    const dateCreatedStr = formatDate(itemData.date_created);
    let customLabel = 'Default';

    let tooltipStr = `Subject: `;

    let otaSource = itemData.ota ? OTA_NAME_TO_SOURCE_MAP[itemData.ota] : '';

    let rgLocName = '';
    const { locations } = responseGeneratorState;
    if (itemData.rg_location__id && locations && locations.keyValue && locations.keyValue[itemData.rg_location__id]) {
      rgLocName = locations.keyValue[itemData.rg_location__id].name || '';
    }
    if (!rgLocName) rgLocName = itemData.rg_location_name || '';

    return (
      <div className="singleBox" id={`bh_${itemData._id}`}>
        <div className="partOne">
          <div className="rvLoc">
            {rgLocName}
          </div>
          <ToolTipWrapper placement="left" overlayClassName={'sbTooltip maxWidth'}
            overlay={itemData.webpage || itemData.ota || ''}
            disable={!itemData.webpage && !itemData.ota}
          >
            <div className={'rvOta'}>
              <span>
                {otaSource ? renderOTALogo(otaSource) : <img className="logo web" src={webIcon} />}
              </span>
            </div>
          </ToolTipWrapper>
          <div className="rvRtng">
            {itemData.rating || '-'}
          </div>
          <div className="rvTxt">
            {itemData.input_text}
          </div>
          <div className="genTxt">
            {itemData.generated_response}
          </div>
        </div>
        <div className="partTwo">
          <div className="when">
            {dateCreatedStr}
          </div>
          {
            renderOverflowMenu()
          }
        </div>
        {
          showTaskDetailModal &&
          renderTaskDetailModal()
        }
      </div>
    );
  };

  // if (!hasAccessToFeature(props.userprops.user, ENGAGE_FEATURES.ADD_EDIT_GUESTS)) {
  //   // nkcheck - log this
  //   return <NoAccess />;
  // }

  return renderContent();
}

export default SingleTransactionEmailHistoryBox;
