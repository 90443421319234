/* *************** Start - Edit Profile **************** */

export const editProfileClear = (state, action) => {
  //const { params: { first_name, last_name } } = action.payload;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editInProgress: false,
        editStatus: {},
      },
    },
  };
}

export const editProfile = (state, action) => {
  const { params: { first_name, last_name } } = action.payload;
  if (!first_name || !last_name) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editInProgress: true,
        editStatus: {},
      },
    },
  };
}

export const editProfileSuccess = (state, action) => {
  const { data, params: { first_name, last_name } } = action.payload;
  // if (!first_name || !last_name) return state;
  const { profile } = data;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          // first_name,
          // last_name,
          ...profile,
        },
        editInProgress: false,
        editStatus: { success: true },
      },
    },
  };
}

export const editProfileError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { first_name, last_name } = params;
  if (!first_name || !last_name) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editInProgress: false,
        editStatus: { error: true },
        error,
        errorMessage,
      },
    },
  };
}

/* *************** End - Edit Profile **************** */

/* *************** Start - Edit Settings Lang **************** */

export const editSettingsSuccess = (state, action) => {
  const { data, params: { location__id, conversation_lang, channels } } = action.payload;
  // if (!first_name || !last_name) return state;
  const { profile } = data;

  if (process.env.NODE_ENV !== 'production') {
    console.log('editSettingsSuccess payload: ', action.payload);
    console.log('editSettingsSuccess channels: ', channels);
  }

  if (conversation_lang) {
    return {
      ...state,
      user: {
        ...state.user,
        user_info: {
          ...state.user.user_info,
          profile: {
            ...state.user.user_info.profile,
            locations: {
              ...state.user.user_info.profile.locations,
              [location__id]: state.user.user_info.profile.locations[location__id]
              ?
              {
                ...state.user.user_info.profile.locations[location__id],
                conversation_lang,
              }
              : {
                conversation_lang,
              }
            },
          },
        },
      },
    };
  }
  if (channels) {
    return {
      ...state,
      user: {
        ...state.user,
        user_info: {
          ...state.user.user_info,
          locations: {
            ...state.user.user_info.locations,
            [location__id]: state.user.user_info.locations[location__id]
            ?
            {
              ...state.user.user_info.locations[location__id],
              has_engage_sms: channels.sms && channels.sms.is_active ? true : false,
              has_engage_whatsapp: channels.whatsapp && channels.whatsapp.is_active ? true : false,
              has_engage_email: channels.email && channels.email.is_active ? true : false,
            }
            : {
              has_engage_sms: channels.sms && channels.sms.is_active ? true : false,
              has_engage_whatsapp: channels.whatsapp && channels.whatsapp.is_active ? true : false,
              has_engage_email: channels.email && channels.email.is_active ? true : false,
            }
          },
        },
      },
    };
  }
  return state;
}

/* *************** End - Edit Settings - Lang **************** */


/* *************** Start - Edit Self Pass **************** */

export const editSelfPassClear = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editPassInProgress: false,
        editPassStatus: {},
      },
    },
  };
}

export const editSelfPass = (state, action) => {
  const { params: { password, identifier } } = action.payload;
  if (!password || !identifier) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editPassInProgress: true,
        editPassStatus: {},
      },
    },
  };
}

export const editSelfPassSuccess = (state, action) => {
  const { data, params: { password, identifier } } = action.payload;
  if (!password || !identifier) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editPassInProgress: false,
        editPassStatus: { success: true },
      },
    },
  };
}

export const editSelfPassError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { password, identifier } = params;
  if (!password || !identifier) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        editPassInProgress: false,
        editPassStatus: { error: true },
        error,
        errorMessage,
      },
    },
  };
}

export const editSelfPassChangeIdentifier = (state, action) => {
  const { data, params: { identifier } } = action.payload;
  if (!identifier) return state;
  return {
    ...state,
    user: {
      ...state.user,
      changePasswordIdentifier: identifier,
    },
  };
}

/* *************** End - Edit Self Pass **************** */


/* *************** Start - Edit Engage Guest List Display Preferences **************** */

export const editGuestListDisplayPreferencesClear = (state, action) => {
  const { params: { location__id, guest_list_display_pref } } = action.payload;
  if (!location__id || !guest_list_display_pref || !guest_list_display_pref.guest_type) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: state.user.user_info.profile.locations[location__id]
            ?
            {
              ...state.user.user_info.profile.locations[location__id],
              engage: {
                ...state.user.user_info.profile.locations[location__id].engage,
                display_prefs: state.user.user_info.profile.locations[location__id].engage.display_prefs
                ?
                  {
                    ...state.user.user_info.profile.locations[location__id].engage.display_prefs,
                    [guest_list_display_pref.guest_type]: state.user.user_info.profile.locations[location__id].engage.display_prefs[guest_list_display_pref.guest_type]
                      ?
                        {
                          ...state.user.user_info.profile.locations[location__id].engage.display_prefs[guest_list_display_pref.guest_type],
                          updateDisplayPrefInProgress: false,
                          updateDisplayPrefStatus: {},
                        }
                      : {},
                  }
                : {
                    [guest_list_display_pref.guest_type]: {},
                  },
              },
            }
            : {
              engage: {
                display_prefs: {
                  [guest_list_display_pref.guest_type]: {},
                }
              }
            },
          },
        },
      },
    },
  };
}

export const editGuestListDisplayPreferences = (state, action) => {
  const { params: { location__id, guest_list_display_pref } } = action.payload;
  if (!location__id || !guest_list_display_pref || !guest_list_display_pref.guest_type || !guest_list_display_pref.list_size) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              engage: {
                ...state.user.user_info.profile.locations[location__id].engage,
                display_prefs: state.user.user_info.profile.locations[location__id].engage.display_prefs
                ?
                  {
                    ...state.user.user_info.profile.locations[location__id].engage.display_prefs,
                    [guest_list_display_pref.guest_type]: state.user.user_info.profile.locations[location__id].engage.display_prefs[guest_list_display_pref.guest_type]
                      ?
                        {
                          ...state.user.user_info.profile.locations[location__id].engage.display_prefs[guest_list_display_pref.guest_type],
                          updateDisplayPrefInProgress: true,
                          updateDisplayPrefStatus: {},
                        }
                      : {},
                  }
                : {
                    [guest_list_display_pref.guest_type]: {
                      updateDisplayPrefInProgress: true,
                      updateDisplayPrefStatus: {},
                    },
                  },
              },
            },
          },
        },
      },
    },
  };
}

export const editGuestListDisplayPreferencesSuccess = (state, action) => {
  const { data, params: { location__id, guest_list_display_pref } } = action.payload;
  if (!location__id || !guest_list_display_pref || !guest_list_display_pref.guest_type || !guest_list_display_pref.list_size) return state;
  // const { location_info } = data;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              engage: {
                ...state.user.user_info.profile.locations[location__id].engage,
                display_prefs: {
                  ...state.user.user_info.profile.locations[location__id].engage.display_prefs,
                  [guest_list_display_pref.guest_type]: {
                    ...state.user.user_info.profile.locations[location__id].engage.display_prefs[guest_list_display_pref.guest_type],
                    list_size: guest_list_display_pref.list_size,
                    updateDisplayPrefInProgress: false,
                    updateDisplayPrefStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

export const editGuestListDisplayPreferencesError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, guest_list_display_pref } = params;
  if (!location__id || !guest_list_display_pref || !guest_list_display_pref.guest_type || !guest_list_display_pref.list_size) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              engage: {
                ...state.user.user_info.profile.locations[location__id].engage,
                display_prefs: {
                  ...state.user.user_info.profile.locations[location__id].engage.display_prefs,
                  [guest_list_display_pref.guest_type]: {
                    ...state.user.user_info.profile.locations[location__id].engage.display_prefs[guest_list_display_pref.guest_type],
                    updateDisplayPrefInProgress: false,
                    updateDisplayPrefStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Edit Engage Guest List Display Preferences **************** */


/* *************** Start - Edit Reputation Alerts **************** */

export const editReputationAlertsClear = (state, action) => {
  const { params: { location__id, guest_feedback_alert } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: {
                ...state.user.user_info.profile.locations[location__id].reputation,
                alerts: state.user.user_info.profile.locations[location__id].reputation.alerts
                ?
                  {
                    ...state.user.user_info.profile.locations[location__id].reputation.alerts,
                    guest_feedback_alert: state.user.user_info.profile.locations[location__id].reputation.alerts.guest_feedback_alert
                      ?
                        {
                          ...state.user.user_info.profile.locations[location__id].reputation.alerts.guest_feedback_alert,
                          editInProgress: false,
                          editStatus: {},
                        }
                      : {},
                  }
                : {
                    guest_feedback_alert: {},
                  },
              },
            },
          },
        },
      },
    },
  };
}

export const editReputationAlerts = (state, action) => {
  const { params: { location__id, guest_feedback_alert } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: state.user.user_info.profile.locations[location__id].reputation
              ?
                {
                  ...state.user.user_info.profile.locations[location__id].reputation,
                  alerts: state.user.user_info.profile.locations[location__id].reputation.alerts
                  ?
                    {
                      ...state.user.user_info.profile.locations[location__id].reputation.alerts,
                      guest_feedback_alert: state.user.user_info.profile.locations[location__id].reputation.alerts.guest_feedback_alert
                        ?
                          {
                            ...state.user.user_info.profile.locations[location__id].reputation.alerts.guest_feedback_alert,
                            editInProgress: true,
                            editStatus: {},
                          }
                        : {},
                    }
                  : {
                      guest_feedback_alert: {
                        editInProgress: true,
                        editStatus: {},
                      },
                    },
                }
              : {
                alerts: {
                  guest_feedback_alert: {
                    editInProgress: true,
                    editStatus: {},
                  }
                }
              }
            },
          },
        },
      },
    },
  };
}

export const editReputationAlertsSuccess = (state, action) => {
  const { data, params: { location__id, guest_feedback_alert } } = action.payload;
  if (!location__id) return state;
  const { location_info } = data;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: {
                ...state.user.user_info.profile.locations[location__id].reputation,
                alerts: {
                  ...state.user.user_info.profile.locations[location__id].reputation.alerts,
                  guest_feedback_alert: {
                    ...state.user.user_info.profile.locations[location__id].reputation.alerts.guest_feedback_alert,
                    ...guest_feedback_alert,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

export const editReputationAlertsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, guest_feedback_alert } = params;
  if (!location__id) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: {
                ...state.user.user_info.profile.locations[location__id].reputation,
                alerts: {
                  ...state.user.user_info.profile.locations[location__id].reputation.alerts,
                  guest_feedback_alert: {
                    ...state.user.user_info.profile.locations[location__id].reputation.alerts.guest_feedback_alert,
                    ...guest_feedback_alert,
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Edit Reputation Alerts **************** */



/* *************** Start - Edit Reputation Reports **************** */

export const editReputationReportsClear = (state, action) => {
  const { params: { location__id, reports } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: {
                ...state.user.user_info.profile.locations[location__id].reputation,
                reports: state.user.user_info.profile.locations[location__id].reputation.reports
                ?
                  {
                    ...state.user.user_info.profile.locations[location__id].reputation.reports,
                    editInProgress: false,
                  }
                : {}
              },
            },
          },
        },
      },
    },
  };
}

export const editReputationReports = (state, action) => {
  const { params: { location__id, reports } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: state.user.user_info.profile.locations[location__id].reputation
              ?
                {
                  ...state.user.user_info.profile.locations[location__id].reputation,
                  reports: state.user.user_info.profile.locations[location__id].reputation.reports
                    ?
                    {
                      ...state.user.user_info.profile.locations[location__id].reputation.reports,
                      editInProgress: true,
                      editStatus: {},
                    }
                    : {
                      editInProgress: true,
                      editStatus: {},
                    }
                  }
                : {
                  reports: {
                    editInProgress: true,
                    editStatus: {},
                  }
                }
            },
          },
        },
      },
    },
  };
}

export const editReputationReportsSuccess = (state, action) => {
  const { data, params: { location__id, reports } } = action.payload;
  if (!location__id) return state;
  const { location_info } = data;

  let newReports = { ...reports };
  delete newReports.group_overview;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: {
                ...state.user.user_info.profile.locations[location__id].reputation,
                reports: {
                  ...state.user.user_info.profile.locations[location__id].reputation.reports,
                  ...newReports,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
          reports: {
            ...state.user.user_info.profile.reports,
            ...reports,
          },
        },
      },
    },
  };
}

export const editReputationReportsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, reports } = params;
  if (!location__id) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          locations: {
            ...state.user.user_info.profile.locations,
            [location__id]: {
              ...state.user.user_info.profile.locations[location__id],
              reputation: {
                ...state.user.user_info.profile.locations[location__id].reputation,
                reports: {
                  ...state.user.user_info.profile.locations[location__id].reputation.reports,
                  editInProgress: false,
                  editStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Edit Reputation Reports **************** */



/* *************** Start - Send Reputation Report **************** */

export const sendReputationReportClear = (state, action) => {
  const { params: { location__id, group_id, type } } = action.payload;
  if (!location__id && !group_id) return state;
  if (!type) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          [`send_${type}_InProgress`]: false,
          [`send_${type}_Status`]: {},
        },
      },
    },
  };
}

export const sendReputationReport = (state, action) => {
  const { params: { location__id, group_id, type } } = action.payload;
  if (!location__id && !group_id) return state;
  if (!type) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          [`send_${type}_InProgress`]: true,
          [`send_${type}_Status`]: {},
        },
      },
    },
  };
}

export const sendReputationReportSuccess = (state, action) => {
  const { data, params: { location__id, group_id, type } } = action.payload;
  if (!location__id && !group_id) return state;
  if (!type) return state;
  const { location_info } = data;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          [`send_${type}_InProgress`]: false,
          [`send_${type}_Status`]: {
            success: true,
          },
        },
      },
    },
  };
}

export const sendReputationReportError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, group_id, type } = params;
  if (!location__id && !group_id) return state;
  if (!type) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          [`send_${type}_InProgress`]: false,
          [`send_${type}_Status`]: {
            error: true,
          },
          error,
          errorMessage,
        },
      },
    },
  };
}

/* *************** End - Send Reputation Report **************** */



/* *************** Start - Edit Bookmarks **************** */

export const editBookmarksClear = (state, action) => {
  const { params: { location__id, bookmark } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          bookmarkEditInProgress: false,
          bookmarkEditStatus: {},
        },
      },
    },
  };
}

export const editBookmarks = (state, action) => {
  const { params: { location__id, bookmark } } = action.payload;
  if (!location__id) return state;
  let tempBookmarks = state.user.user_info && state.user.user_info.profile
    ? state.user.user_info.profile.bookmarks || [] : [];
  if (bookmark.add) { tempBookmarks.push(bookmark); }
  else {
    tempBookmarks = tempBookmarks.filter(el => el.link !== bookmark.link);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('tempBookmarks: ', tempBookmarks);
  }
  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          bookmarks: tempBookmarks,
          bookmarkEditInProgress: true,
          bookmarkEditStatus: {},
        },
      },
    },
  };
}

export const editBookmarksSuccess = (state, action) => {
  const { data, params: { location__id, bookmark } } = action.payload;
  if (!location__id) return state;
  
  // let tempBookmarks = state.user.user_info && state.user.user_info.profile
  //   ? state.user.user_info.profile.bookmarks || [] : [];
  // if (bookmark.add) { tempBookmarks.push(bookmark); }
  // else {
  //   tempBookmarks = tempBookmarks.filter(el => el.link !== bookmark.link);
  // }

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          // bookmarks: tempBookmarks,
          bookmarkEditInProgress: false,
          bookmarkEditStatus: {
            success: true,
          },
        },
      },
    },
  };
}

export const editBookmarksError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, bookmark } = params;
  if (!location__id) return state;

  return {
    ...state,
    user: {
      ...state.user,
      user_info: {
        ...state.user.user_info,
        profile: {
          ...state.user.user_info.profile,
          bookmarkEditInProgress: false,
          bookmarkEditStatus: {
            error: true,
          },
          error,
          errorMessage,
        },
      },
    },
  };
}

/* *************** End - Edit Bookmarks **************** */


/* *************** Start - handle enagge act message **************** */

export const handleEngageActMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleEngageActMessage: ', action.payload); // comment
  }
  const { data, location_id, location__id } = action.payload;
  if (!location__id) return state;

  if (state.user.user_info && state.user.user_info.locations &&
    state.user.user_info.locations[location__id]
  ) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngageActMessage locations: ', state.user.user_info.locations[location__id]); // comment
    }
    return {
      ...state,
      user: {
        ...state.user,
        user_info: {
          ...state.user.user_info,
          locations: {
            ...state.user.user_info.locations,
            [location__id]: {
              ...state.user.user_info.locations[location__id],
              unreadCount: state.user.user_info.locations[location__id].unreadCount
                ? state.user.user_info.locations[location__id].unreadCount + 1
                : 1,
            },
          }
        },
      },
    };
  }

  return state;

  // return {
  //   ...state,
  //   locations: {
  //     ...state.locations,
  //     [locationidentifier]: {
  //       ...state.locations[locationidentifier],
  //       engage: {
  //         ...state.locations[locationidentifier].engage,
  //         [guestTypeKey]: {
  //           ...state.locations[locationidentifier].engage[guestTypeKey],
  //           keyValue: {
  //             ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
  //             [guest_data._id]: {
  //               ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[guest_data._id],
  //               ...guest_data,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // };
}

/* *************** End - handle enagge act message **************** */


/* *************** Start - Get unread count **************** */

export const getUnreadCount = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('getUnreadCount: ', action.payload); // comment
  }
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;

  if (state.user.user_info && state.user.user_info.locations &&
    state.user.user_info.locations[location__id]
  ) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('getUnreadCount locations: ', state.user.user_info.locations[location__id]); // comment
    }
    return {
      ...state,
      user: {
        ...state.user,
        user_info: {
          ...state.user.user_info,
          locations: {
            ...state.user.user_info.locations,
            [location__id]: {
              ...state.user.user_info.locations[location__id],
              unreadCount: undefined,
            },
          }
        },
      },
    };
  }

  return state;
}

/* *************** End - Get unread count **************** */
