import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { getHighestAccessLevels, isLocationAdminAndAbove } from '../../../helpers';

// import SideBar from './SideBar';

import './SettingsHome.css';

function SettingsEngageHome(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  let location = useLocation();
  let urlParams = useParams();

  const [screenBeforeSettings, setScreenBeforeSettings] = useState({});

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { state } = location;
    setScreenBeforeSettings(state || {});
  }, [location]);

  const { locationidentifier } = urlParams;

  const LinkMessagingChannels = <Link to={`/settings/locations/${locationidentifier}/messaging-channels`} state={screenBeforeSettings}>Manage Messaging Channels</Link>

  const LinkManageTemplates = <Link to={`/settings/locations/${locationidentifier}/templates`} state={screenBeforeSettings}>Manage Templates</Link>
  const LinkGenericTemplates = <Link to={`/settings/locations/${locationidentifier}/generic-templates`} state={screenBeforeSettings}>Generic Templates</Link>
  const LinkWhatsappStarterTemplates = <Link to={`/settings/locations/${locationidentifier}/whatsapp-starter-templates`} state={screenBeforeSettings}>WhatsApp Starter Templates</Link>

  const LinkJourneyMessages = <Link to={`/settings/locations/${locationidentifier}/journey-messages`} state={screenBeforeSettings}>Journey Messages</Link>
  const LinkJourneyMessagesSms = <Link to={`/settings/locations/${locationidentifier}/journey-messages/sms`} state={screenBeforeSettings}>SMS</Link>
  const LinkJourneyMessagesWhatsapp = <Link to={`/settings/locations/${locationidentifier}/journey-messages/whatsapp`} state={screenBeforeSettings}>WhatsApp</Link>
  const LinkJourneyMessagesEmail = <Link to={`/settings/locations/${locationidentifier}/journey-messages/email`} state={screenBeforeSettings}>Email</Link>
  const LinkJourneyMessagesRuleBased = <Link to={`/settings/locations/${locationidentifier}/journey-messages/rule-based`} state={screenBeforeSettings}>Rule Based Journey Messages</Link>

  const LinkGbm = <Link to={`/settings/locations/${locationidentifier}/google-business-messages`} state={screenBeforeSettings}>Google Business Messages</Link>

  const { locationInfo } = props;

  const { accessLevel } = getHighestAccessLevels(userState.user.user_info, locationidentifier);

  return (
    <div className="sCw sh en">
      <div className="hdr">
        <h4>Engage Settings</h4>
        {/*
          locationInfo.has_engage_sms &&
          <p>Sms Number: {locationInfo.sms_number || ''}</p>
        }
        {
          locationInfo.has_engage_whatsapp &&
          <p>Whatsapp Number: {locationInfo.whatsapp_number || ''}</p>
        }
        {
          locationInfo.has_engage_email &&
          <p>Email: {locationInfo.engage_email || ''}</p>
        */}
      </div>
      {
        locationidentifier &&
        <div className="bd">
          <div className="sbx">
            <ul>
              { isLocationAdminAndAbove(accessLevel) && <li>{LinkMessagingChannels}</li> }
              <li>{LinkManageTemplates} { locationInfo.has_engage_whatsapp ? <span>including {LinkWhatsappStarterTemplates}</span> : null }</li>
              { isLocationAdminAndAbove(accessLevel) && <li><Link to={`/settings/locations/${locationidentifier}/webchat`}>Configure WebChat</Link></li> }
              <li>
                Configure {LinkJourneyMessages} for {LinkJourneyMessagesSms}, {LinkJourneyMessagesWhatsapp}, {LinkJourneyMessagesEmail} and {LinkJourneyMessagesRuleBased}
              </li>
              { isLocationAdminAndAbove(accessLevel) && <li>Configure {LinkGbm}</li> }
            </ul>
          </div>
        </div>
      }
    </div>
  );
}

export default SettingsEngageHome;
