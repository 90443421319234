import moment from 'moment';
import { getGuestTypeKey, guestTypeKeyByTransactionStatus } from '../../helpers';

import * as responseGeneratorHelper from './responseGeneratorHelper';

const initialState = {
  generator: {},
  overview: {},
  history: {},
  general: {},
  locations: {},
  users: {},
};

const responseGeneratorReducer = (state = initialState, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('responseGeneratorReducer action: ', action);
  }
  switch (action.type) {

    case 'USER_LOGOUT': {
      return initialState;
    }

    /* Get RG Response */

    case "GET_RESPONSE_GENERATOR_RESPONSE_RESET": {
      return responseGeneratorHelper.getResponseGeneratorResponseReset(state, action);
    }

    case "GET_RESPONSE_GENERATOR_RESPONSE_CLEAR": {
      return responseGeneratorHelper.getResponseGeneratorResponseClear(state, action);
    }
    case "GET_RESPONSE_GENERATOR_RESPONSE": {
      return responseGeneratorHelper.getResponseGeneratorResponse(state, action);
    }
    case "GET_RESPONSE_GENERATOR_RESPONSE_SUCCESS": {
      return responseGeneratorHelper.getResponseGeneratorResponseSuccess(state, action);
    }
    case "GET_RESPONSE_GENERATOR_RESPONSE_ERROR": {
      return responseGeneratorHelper.getResponseGeneratorResponseError(state, action);
    }


    /* Get RG Locations */

    case "GET_RESPONSE_GENERATOR_LOCATIONS_CLEAR": {
      return responseGeneratorHelper.getResponseGeneratorLocationsClear(state, action);
    }
    case "GET_RESPONSE_GENERATOR_LOCATIONS": {
      return responseGeneratorHelper.getResponseGeneratorLocations(state, action);
    }
    case "GET_RESPONSE_GENERATOR_LOCATIONS_SUCCESS": {
      return responseGeneratorHelper.getResponseGeneratorLocationsSuccess(state, action);
    }
    case "GET_RESPONSE_GENERATOR_LOCATIONS_ERROR": {
      return responseGeneratorHelper.getResponseGeneratorLocationsError(state, action);
    }


    /* Get Overview */

    case "GET_RESPONSE_GENERATOR_OVERVIEW_CLEAR": {
      return responseGeneratorHelper.getResponseGeneratorOverviewClear(state, action);
    }
    case "GET_RESPONSE_GENERATOR_OVERVIEW": {
      return responseGeneratorHelper.getResponseGeneratorOverview(state, action);
    }
    case "GET_RESPONSE_GENERATOR_OVERVIEW_SUCCESS": {
      return responseGeneratorHelper.getResponseGeneratorOverviewSuccess(state, action);
    }
    case "GET_RESPONSE_GENERATOR_OVERVIEW_ERROR": {
      return responseGeneratorHelper.getResponseGeneratorOverviewError(state, action);
    }


    /* Get History */

    case "GET_RESPONSE_GENERATOR_HISTORY_CLEAR": {
      return responseGeneratorHelper.getResponseGeneratorHistoryClear(state, action);
    }
    case "GET_RESPONSE_GENERATOR_HISTORY": {
      return responseGeneratorHelper.getResponseGeneratorHistory(state, action);
    }
    case "GET_RESPONSE_GENERATOR_HISTORY_SUCCESS": {
      return responseGeneratorHelper.getResponseGeneratorHistorySuccess(state, action);
    }
    case "GET_RESPONSE_GENERATOR_HISTORY_ERROR": {
      return responseGeneratorHelper.getResponseGeneratorHistoryError(state, action);
    }


    /* Get users for rg location */

    case "GET_USERS_FOR_RG_CLEAR": {
      return responseGeneratorHelper.getUsersForRgClear(state, action);
    }
    case "GET_USERS_FOR_RG": {
      return responseGeneratorHelper.getUsersForRg(state, action);
    }
    case "GET_USERS_FOR_RG_SUCCESS": {
      return responseGeneratorHelper.getUsersForRgSuccess(state, action);
    }
    case "GET_USERS_FOR_RG_ERROR": {
      return responseGeneratorHelper.getUsersForRgError(state, action);
    }


    /* Add RG Location */

    case "ADD_RG_LOCATION_CLEAR": {
      return responseGeneratorHelper.addRGLocationClear(state, action);
    }
    case "ADD_RG_LOCATION": {
      return responseGeneratorHelper.addRGLocation(state, action);
    }
    case "ADD_RG_LOCATION_SUCCESS": {
      return responseGeneratorHelper.addRGLocationSuccess(state, action);
    }
    case "ADD_RG_LOCATION_ERROR": {
      return responseGeneratorHelper.addRGLocationError(state, action);
    }

    /* Edit RG Location */

    case "EDIT_RG_LOCATION_CLEAR": {
      return responseGeneratorHelper.editRGLocationClear(state, action);
    }
    case "EDIT_RG_LOCATION": {
      return responseGeneratorHelper.editRGLocation(state, action);
    }
    case "EDIT_RG_LOCATION_SUCCESS": {
      return responseGeneratorHelper.editRGLocationSuccess(state, action);
    }
    case "EDIT_RG_LOCATION_ERROR": {
      return responseGeneratorHelper.editRGLocationError(state, action);
    }


    /* Edit Prompt Templates */

    case "EDIT_RG_PROMPT_TEMPLATE_CLEAR": {
      return responseGeneratorHelper.editRGPromptTemplateClear(state, action);
    }
    case "EDIT_RG_PROMPT_TEMPLATE": {
      return responseGeneratorHelper.editRGPromptTemplate(state, action);
    }
    case "EDIT_RG_PROMPT_TEMPLATE_SUCCESS": {
      return responseGeneratorHelper.editRGPromptTemplateSuccess(state, action);
    }
    case "EDIT_RG_PROMPT_TEMPLATE_ERROR": {
      return responseGeneratorHelper.editRGPromptTemplateError(state, action);
    }


    /* Edit RG User */

    case "EDIT_RG_USER_CLEAR": {
      return responseGeneratorHelper.editRgUserClear(state, action);
    }
    case "EDIT_RG_USER": {
      return responseGeneratorHelper.editRgUser(state, action);
    }
    case "EDIT_RG_USER_SUCCESS": {
      return responseGeneratorHelper.editRgUserSuccess(state, action);
    }
    case "EDIT_RG_USER_ERROR": {
      return responseGeneratorHelper.editRgUserError(state, action);
    }

    /* Edit RG User Reset Password */

    case "EDIT_RG_USER_RESET_PASSWORD_CLEAR": {
      return responseGeneratorHelper.editRgUserResetPasswordClear(state, action);
    }
    case "EDIT_RG_USER_RESET_PASSWORD": {
      return responseGeneratorHelper.editRgUserResetPassword(state, action);
    }
    case "EDIT_RG_USER_RESET_PASSWORD_SUCCESS": {
      return responseGeneratorHelper.editRgUserResetPasswordSuccess(state, action);
    }
    case "EDIT_RG_USER_RESET_PASSWORD_ERROR": {
      return responseGeneratorHelper.editRgUserResetPasswordError(state, action);
    }




  }
  return state;
};
export default responseGeneratorReducer;
