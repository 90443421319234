import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Configure from './Configure';
import Stats from './Stats';
import History from './History';
import Reservations from './Reservations';

function TransactionEmails(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Marketing - Transaction Emails - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const { hasAccessToManageTransactionEmails } = props.mrkAccess;
  return (
      <Routes>
        <Route path="*" element={<Outlet />}>
          { hasAccessToManageTransactionEmails && <Route path="configure" element={<Configure {...props} />} /> }
          { hasAccessToManageTransactionEmails && <Route path="configure/:stage" element={<Configure {...props} />} /> }
          <Route path="stats" element={<Stats {...props} />} />
          <Route path="history" element={<History {...props} />} />
          <Route path="reservations" element={<Reservations {...props} />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
  );
}

export default TransactionEmails;
