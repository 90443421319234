import { genericItemTypeToKeyName } from '../../helpers';

/***************************************** Get Reviews Widget Settings ****************************/

export const getReviewsWidgetSettings = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: state.locations[location__id].settings.reviews_widget
          ?
            {
              ...state.locations[location__id].settings.reviews_widget,
              getInProgress: true, getStatus: {},
            }
          : {
              getInProgress: true, getStatus: {},
            }
        },
      },
    },
  };
};

export const getReviewsWidgetSettingsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { reviews_widget_settings } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            ...reviews_widget_settings,
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getReviewsWidgetSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};



/* ********************** Start - edit Reviews Widget settings ******************* */

export const editReviewsWidgetSettingsClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            editInProgress: false,
            editStatus: {},
          },
        },
      },
    },
  };
};

export const editReviewsWidgetSettings = (state, action) => {
  const { params: { location__id, number_of_reviews } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            editInProgress: true,
            editStatus: {},
          },
        },
      },
    },
  };
};

export const editReviewsWidgetSettingsSuccess = (state, action) => {
  const { params: { location__id, number_of_reviews } } = action.payload;
  if (!location__id) return state;
  const { data } = action.payload;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            reviews_widget_number_of_reviews: number_of_reviews,
            editInProgress: false,
            editStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const editReviewsWidgetSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            editInProgress: false,
            editStatus: {
              error: true,
              message: 'Error in updating!'
            },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};

/* ********************** End - edit Reviews Widget settings ******************* */


/***************************************** Get Reviews Widget Rules ****************************/

export const getReviewsWidgetRules = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: state.locations[location__id].settings.reviews_widget
          ?
            {
              ...state.locations[location__id].settings.reviews_widget,
              rules: state.locations[location__id].settings.reviews_widget.rules
              ?
                {
                  ...state.locations[location__id].settings.reviews_widget.rules,
                  getInProgress: true, getStatus: {},
                }
              : {
                  getInProgress: true, getStatus: {},
                }  ,
            }
          : {
              rules: {
                getInProgress: true, getStatus: {},
              },
            },
        },
      },
    },
  };
};

export const getReviewsWidgetRulesSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { reviews_widget_rules, reviews_widget_all_locations } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            rules: {
              ...reviews_widget_rules,
              getInProgress: false, getStatus: {
                success: true,
              },
            },
            reviews_widget_all_locations,
          },
        },
      },
    },
  };
};

export const getReviewsWidgetRulesError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          reviews_widget: {
            ...state.locations[location__id].settings.reviews_widget,
            rules: {
              ...state.locations[location__id].settings.reviews_widget.rules,
              error,
              errorMessage,
              getInProgress: false, getStatus: {
                error: true,
              },
            }
          },
        },
      },
    },
  };
};



/***************** Generic Engage List Item - Reviews Widget **********************/

export const editGenericReviewsWidgetListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, rule_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'rw_rule') {
      itemId = rule_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.reviews_widget[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reviews_widget[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericReviewsWidgetListItem = (state, action, itemType) => {
  const { params: { location__id, mode, rule_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'rw_rule') {
      itemId = rule_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericReviewsWidgetListItem itemId: `, itemId);
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.reviews_widget[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reviews_widget[keyName].keyValue[itemId],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericReviewsWidgetListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, rule_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { new_rule, rule_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'rw_rule') {
      itemId = rule_id;
      return_data = rule_data;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].settings.reviews_widget[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].settings.reviews_widget[keyName].list ],
                keyValue: {
                  ...state.locations[location__id].settings.reviews_widget[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reviews_widget[keyName].keyValue[itemId],
                    ...return_data,
                    is_removed: mode === 'remove' ? true : undefined,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    let return_data = null;
    if (itemType === 'rw_rule') {
      return_data = new_rule;
    }
    if (!return_data || !return_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                list: [return_data._id, ...state.locations[location__id].settings.reviews_widget[keyName].list],
                keyValue: {
                  ...state.locations[location__id].settings.reviews_widget[keyName].keyValue,
                  [return_data._id]: {
                    ...return_data,
                  },
                },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericReviewsWidgetListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, rule_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'rw_rule') {
      itemId = rule_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.reviews_widget[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reviews_widget[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reviews_widget: {
              ...state.locations[location__id].settings.reviews_widget,
              [keyName]: {
                ...state.locations[location__id].settings.reviews_widget[keyName],
                addInProgress: false,
                addStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic List Item - Reviews Widget *******************************/
