// @flow

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';

import SentEmailPreviewModal from '../../../Misc/SentEmailPreviewModal';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { validateEmail, isContactValid } from '../../../../helpers';
import { formatDate, formatDateStrToStr, TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP,
  TRANSACTION_EMAIL_TYPE_TO_LABEL, sendEmailAlert,
  DEVICE_TYPE_CODE_TO_LABEL, COUNTRY_LIST_KEYS,
  OTA_SOURCE_TO_NAME_FORMAL_MAP, OTA_NAME_TO_SOURCE_MAP } from '../../../../helpers';

import useDebounce from '../../../Misc/DebounceHook';

import closeIcon from '../../../../img/icons/close.svg';
import requiredIcon from '../../../../img/icons/required.svg';

import './style.css';

const gtconfig = require('../../../../gtconfig');
const ROW_TYPE_TO_LABEL = {
  'input': 'Review Text',
  'follow_up': 'Follow Up Text',
  'generated': 'Generated Response',
};

function HistoryDetailModal(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  let location = useLocation();

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [getPreviewInProgress, setGetPreviewInProgress] = useState(false);
  const [sentEmailData, setSentEmailData] = useState({});

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - History - Single - Detail - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const onPreviewOpenClick = (e) => {
    setShowEmailPreviewModal(true);
  };

  const onEmailPreviewCloseModal = (e) => {
    setShowEmailPreviewModal(false);
  };
  const onEmailPreviewConfirmClick = (data) => {
    setShowEmailPreviewModal(false);
  };
  const onCloseClick = (e) => {
    props.onCancelClick();
  }

  const renderRow = (rowData) => {

    return (
      <>
        <div className="gBCtRw title">
          <div className="gRwBxLt">
            {ROW_TYPE_TO_LABEL[rowData.type]}
          </div>
          <div className="gRwBxRt">
            {
              rowData.use_hotel_name &&
              <span>Property name used | </span>
            }
            {
              rowData.tone &&
              <span>Tone: {rowData.tone.join(',')}</span>
            }
          </div>
        </div>
        <div className="gBCtRw kVRw">
          {rowData.msg || rowData.follow_up_prompt_title}
          {
            rowData.msg_translated &&
            <span>[Translated: {rowData.msg_translated}]</span>
          }
          {
            rowData.prompt_title &&
            <span>[Additional Instructions: {rowData.prompt_title}]</span>
          }
          {
            rowData.prompt_msg &&
            <span>[Additional Instructions: {rowData.prompt_msg}]</span>
          }
        </div>
      </>
    );
  };


  const renderContent = () => {
    const info = props.itemData;
    if (process.env.NODE_ENV !== 'production') {
      console.log('info: ', info);
    }
    if (!info) return null;
    const dateCreatedStr = formatDate(info.date_created);
    let customLabel = 'Default';

    return (
      <div className="sdMdl rgHstDtl">
        <div className="ctnt">
          <div className="gPWrp">
            <div className="gPTle title">
              Details
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            </div>

            <div className="gBCtnt">
              {
                info.webpage &&
                <div className="gBCtRw title">
                  <div className="gRwBxLt web">
                    Webpage
                  </div>
                  <div className="gRwBxRt web">
                    {
                      info.webpage
                    }
                  </div>
                </div>
              }
              {
                info.ota &&
                <div className="gBCtRw title">
                  <div className="gRwBxLt web">
                    OTA
                  </div>
                  <div className="gRwBxRt web">
                    {
                      OTA_SOURCE_TO_NAME_FORMAL_MAP[OTA_NAME_TO_SOURCE_MAP[info.ota]]
                    }
                  </div>
                </div>
              }
              {
                info.rating &&
                <div className="gBCtRw title">
                  <div className="gRwBxLt web">
                    Rating
                  </div>
                  <div className="gRwBxRt web">
                    {
                      info.rating
                    }
                  </div>
                </div>
              }
              <div className="gBCtRw title seq">
                <div className="gRwBxLt">
                  Sequence of steps
                </div>
              </div>
              {
                info.display && info.display.length
                ? info.display.map((item) => {
                    return renderRow(item);
                  })
                : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  // if (!hasAccessToFeature(props.userprops.user, ENGAGE_FEATURES.ADD_EDIT_GUESTS)) {
  //   // nkcheck - log this
  //   return <NoAccess />;
  // }

  return renderContent();
}

export default HistoryDetailModal;
