// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';

// import useOnClickOutside from '../Misc/OutsideClickHook';

import { formatDate } from '../../../../../helpers';

import './style.css';


function SingleGuestRow(props) {
  const onShowRepeatVisitsClick = (e) => {
    props.onShowRepeatVisitsClick(props.data);
  }

  const { first_name, last_name, contact, email, room_number, check_in_date, check_out_date, repeats } = props.data;

  return (
    <div className="gCrd glSingleCard">
      <div className="innerBox">
        <div className="nameBox">
          <p className="name">{first_name} {last_name}</p>
          <p className="email">{email}</p>
          <p className="contact">{contact}</p>
        </div>
        <div className="detailsBox">
          <div className="row row1">
            <div className="col">
              <div className="label">
                Check-In Date
              </div>
              <div className="value">
                {formatDate(check_in_date)}
              </div>
            </div>
            <div className="col">
              <div className="label">
                Check-Out Date
              </div>
              <div className="value">
                {formatDate(check_out_date)}
              </div>
            </div>
            <div className="col">
              <div className="label">
                Room Number
              </div>
              <div className="value">
                {room_number}
              </div>
            </div>
          </div>
          <div className="row row2">
            <div className="col">
              <div className="label">
                Repeat Visits
              </div>
              <div className="value">
                {
                  repeats && repeats.length
                  ? <span className="repeats" onClick={onShowRepeatVisitsClick}>{repeats.length-1}</span>
                  : '-'
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleGuestRow;
