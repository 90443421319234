
import React, { Component, useState, useEffect, useRef, useCallback } from 'react';

import { formatDate, formatDateTime } from '../../../../../helpers';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import moreIcon from '../../../../../img/icons/more.svg';

import './style.css';


function UploadHistoryRow(props) {
  const [showMore, setShowMore] = useState(false);

  const onShowMoreClick = (e) => {
    setShowMore(showMore => !showMore);
  };

  const renderMoreDetailsHeader = () => {
    return (
      <div className="header">
        <div className="name">
          Name
        </div>
        <div className="email">
          Email/Contact
        </div>
        <div className="sentAt">
          Sent at
        </div>
      </div>
    );
  };

  let { medium, date_created, total_count, added_count, modified_count, error_count, sent_count,
    uploaded_by, data,
  } = props.data;
  return (
    <div className={'uhRowWrap ' + (showMore ? 'more' : '')}>
      <div className="uhRow" id={`uhr_${props.data._id}`}>
        <div className="dateCreated">
          {formatDate(date_created)}
        </div>
        <div className="total">
          {medium || ''}
        </div>
        <div className="total">
          {total_count}
        </div>
        <div className="added">
          {added_count}
        </div>
        <div className="updated">
          {modified_count}
        </div>
        <div className="errorCount">
          {error_count}
        </div>
        <div className="sent">
          {sent_count ? (sent_count <= added_count ? sent_count : added_count) : '-'}
        </div>
        <div className="sentBy">
          {uploaded_by}
        </div>
        <div className="more">
          <span onClick={onShowMoreClick}>
            {
              showMore
              ? <><i className="fa fa-minus" /> Less</>
              : <><i className="fa fa-plus" /> More</>
            }

          </span>
        </div>
      </div>
      {
        showMore &&
        <div className="uhRowMore">
          { renderMoreDetailsHeader() }
          {
            data.map((item) => {
              return (
                <div className="entry">
                  <div className="name">
                    {item.name}
                  </div>
                  <div className="email">
                    {item.email || item.contact}
                  </div>
                  <div className="sent">
                    {item.date_email_sent_at ? formatDateTime(item.date_email_sent_at) : ''}
                    {item.date_msg_sent_at ? formatDateTime(item.date_msg_sent_at) : ''}
                  </div>
                </div>
              )
            })
          }
        </div>
      }
    </div>
  );
}


export default UploadHistoryRow;
