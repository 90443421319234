import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import ListPanel from './ListPanel';
import General from './General';

function Configure(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return (
    <div className="gCw">
      <ListPanel {...props} />
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path=":rglocationidentifier" element={<General { ...props } />} />
          {/*<Route path="location/*" element={<General { ...props } />} />*/}
          {/*<Route index element={<h2>Default Home</h2>} />*/}
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </div>
  );

  /*return (
    <div className="App">
      <h1>Feedbacks</h1>
      <Link to="/reputation/locations/abc/feedbacks/run-campaign">Run Campaign</Link>
      <Outlet />
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path="run-campaign" element={<RunCampaign />} />
          <Route index element={<Overview />} />
        </Route>
      </Routes>
    </div>
  );*/

}

export default Configure;
