import { useSelector, useDispatch } from "react-redux";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import DOMPurify from 'dompurify';

// import {Editor, EditorState} from 'draft-js';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
// import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { editDataClearAction, editDataAction } from '../../../../../../redux/actions/editDataActions';

import ImageSection from './ImageSection';
import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import RichTextEditor from '../../../../../Misc/RichTextEditor';

import { RULE_TYPES, RULE_TYPE_LABELS, ruleTypeToLabel, validateUrl } from '../../../../../../helpers';

import emojiIcon from '../../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import '../style.css';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 2;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `5px 0 ${grid}px 0`,
  border: '1px solid #e6e6e7',
  borderRadius: 5,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  width: 330
});

function InputBox(props) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('props.error: ', props.props);
  }
  const onTextChange = (e) => { props.onTextChange(e.target.value); };
  return (
    <div className="titleRow">
      <div className="itemBox">
        <div className="gLabel">
          {props.title}
        </div>
        {
          props.subTitle &&
          <div className="gSubLabel">
            {props.subTitle}
          </div>
        }
        <div className={'gInput ' + (props.error ? 'error' : '')}>
          <input type="text" value={props.value} onChange={onTextChange} placeholder={props.placeholder} />
          { props.error && <p className="error">{props.error}</p> }
        </div>
      </div>
    </div>
  );
}

function DGRoomSingle(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  const [showAddSection, setShowAddSection] = useState(true);
  const [sectionType, setSectionType] = useState('');
  const [sections, setSections] = useState([]);

  const [someChange, setSomeChange] = useState(false);

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  const clearAction = () => {
    if (props.type === 'edit') {
      dispatch(editDataClearAction({
        op: 'edit_digital_guide_room',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          room_id: props.room._id,
        },
      }));
    }
    if (props.type === 'add') {
      dispatch(editDataClearAction({
        op: 'edit_digital_guide_room',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
          room_id: props.room._id,
        },
      }));
    }
  };

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    if (props.page && props.page._id) {
      if (props.page.sections) {
        setSections(props.page.sections);
      }
    }
    setSomeChange(false);
    clearAction();
  }, []);


  useEffect(() => {
    const { digital_guide } = props.settings;
    if (props.type === 'edit') {
      if (props.page && props.page._id &&
        digital_guide && digital_guide.rooms &&
        digital_guide.rooms.keyValue &&
        digital_guide.rooms.keyValue[props.page._id] &&
        !digital_guide.rooms.keyValue[props.page._id].editInProgress &&
        digital_guide.rooms.keyValue[props.page._id].editStatus &&
        digital_guide.rooms.keyValue[props.page._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (digital_guide && digital_guide.pages &&
        !digital_guide.rooms.addInProgress &&
        digital_guide.rooms.addStatus &&
        digital_guide.rooms.addStatus.success
      ) {
        setTitle('');
        setSomeChange(false);
      }
    }
  }, [props.settings.digital_guide]);


  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true); clearAction();
    if (!value) {
      setTitleError('Title is required');
    } else {
      setTitleError('');
    }
  };

  const onAddSectionClick = (e) => {
    setShowAddSection(true);
  };
  const onSelectTextClick = (e) => {
    clearAction();
    setSectionType('text');
    let temp = [ ...sections ];
    temp.push({ 'type': 'text', sectionId: (new Date()).getTime(), order: temp.length });
    setSections(temp);
  };
  const onSelectImageClick = (e) => {
    clearAction();
    setSectionType('image');
    let temp = [ ...sections ];
    temp.push({ 'type': 'image', sectionId: (new Date()).getTime(), order: temp.length });
    setSections(temp);
  };
  const onSectionRemoveClick = (sectionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionRemoveClick sectionId: ', sectionId);
    }
    let temp = sections.filter(el => el.sectionId !== sectionId);
    setSections(temp);
  };


  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    const { digital_guide } = props.settings;
    let addEditInProgress = digital_guide && digital_guide.rooms && digital_guide.rooms.addInProgress
    if (props.type === 'edit') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('onAddEditClick props.page: ', props.page);
      }
      if (props.page && props.page._id &&
        digital_guide &&
        digital_guide.rooms && digital_guide.rooms.keyValue &&
        digital_guide.rooms.keyValue[props.page._id]
      ) {
        addEditInProgress = digital_guide.rooms.keyValue[props.page._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    clearAction();


    let hasError = false;

    if (hasError) return;

    if (someChange) {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        room_id: props.room._id,
        page_data: {
          sections,
        }
      }
      if (props.page && props.page._id) {
        params.page_id = props.page._id;
      }
      params.mode = 'edit';
      dispatch(editDataAction({
        op: 'edit_digital_guide_room',
        params,
      }));
      setSomeChange(false);
      // if (props.type === 'add') {
      //   params.page_data.is_enabled = true;
      //   dispatch(editDataAction({
      //     op: 'edit_digital_guide_room',
      //     params,
      //   }));
      //   setSomeChange(false);
      // }
      // if (props.type === 'edit') {
      //   params.page_id = props.page._id;
      //   dispatch(editDataAction({
      //     op: 'edit_digital_guide_room',
      //     params,
      //   }));
      //   setSomeChange(false);
      // }
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDragEnd result: ', result);
    }

    // dropped outside the list
    if (!destination || destination.droppableId !== source.droppableId) {
        return;
    }

    const tempItems = reorder(
      sections,
      result.source.index,
      result.destination.index
    );

    setSections(tempItems);
    setSomeChange(true);
    // props.editClear();
  }

  const onTextSectionChange = (sectionId, data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTextSectionChange sectionId: ', sectionId);
      console.log('onTextSectionChange data: ', data);
    }
    let tempSections = [ ...sections ];
    tempSections.forEach((item) => {
      if (item.sectionId === sectionId) {
        item.html = data;
      }
    });
    setSections(tempSections);
    setSomeChange(true);
    clearAction();
  };

  const onImageSectionChange = (sectionId, imageUrl) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onImageSectionChange sectionId: ', sectionId);
      console.log('onImageSectionChange image url: ', imageUrl);
    }
    let tempSections = [ ...sections ];
    tempSections.forEach((item) => {
      if (item.sectionId === sectionId) {
        item.url = imageUrl;
      }
    });
    setSections(tempSections);
    setSomeChange(true);
    clearAction();
  };


  const renderEditor = () => {
    return (
      <RichTextEditor />
    );
  }

  const renderContent = () => {
    const { digital_guide } = props.settings;

    let pageTitle = `Edit Room Info - ${props.room.label}`;
    if (props.title) pageTitle = props.title;

    // let addEditInProgress = digital_guide && digital_guide.pages ? digital_guide.pages.addInProgress : false;
    // let addEditStatus = digital_guide && digital_guide.pages ? digital_guide.pages.addStatus || {} : {};
    // if (props.type === 'edit') {
    //   if (props.page && props.page._id) {
    //     addEditInProgress = props.page.editInProgress;
    //     addEditStatus = props.page.editStatus;
    //   }
    // }
    let addEditInProgress = digital_guide && digital_guide.rooms && props.room && props.room._id &&
      digital_guide.rooms.keyValue && digital_guide.rooms.keyValue[props.room._id]
      ? digital_guide.rooms.keyValue[props.room._id].editInProgress : false;
    let addEditStatus = digital_guide && digital_guide.rooms && props.room && props.room._id &&
      digital_guide.rooms.keyValue && digital_guide.rooms.keyValue[props.room._id]
      ? digital_guide.rooms.keyValue[props.room._id].editStatus : {};

    return (
      <div className="gPWrp dgRmPgSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="lt">
            <div className="dgPgSecs">
            {
              sections.length ?
              <DragDropContext onDragEnd={onDragEnd} >
              <Droppable droppableId="droppable" className="dgPgSecsDrg">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                  {
                    sections.map((item, index) => {
                      if (process.env.NODE_ENV !== 'production') {
                        console.log('DGRoomSingle section: ', index, ' # ', item);
                      }
                      if (item.type === 'text') {
                        return (
                          <Draggable key={item.sectionId} draggableId={item.sectionId.toString()} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <RichTextEditor data={item.data} order={item.order} sectionId={item.sectionId}
                                  html={item.html}
                                  onDataChange={onTextSectionChange}
                                  onRemoveClick={onSectionRemoveClick}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      if (item.type === 'image') {
                        return (
                          <Draggable key={item.sectionId} draggableId={item.sectionId.toString()} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <ImageSection data={item.data} order={item.order} sectionId={item.sectionId}
                                  url={item.url}
                                  onDataChange={onImageSectionChange}
                                  onRemoveClick={onSectionRemoveClick}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      return null;
                    })
                  }
                  {provided.placeholder}
                </div>
              )}
              </Droppable>
              </DragDropContext>
              : null
            }
            </div>
            <div className="gBCtRw chsSectionRw">
              <div className="gRwBxLt">
                {/*<div className="etry lbl" onClick={onAddSectionClick}>
                  Add Section
                </div>*/}
                {
                  showAddSection &&
                  <>
                    <button className="gBtn cancel etry" onClick={onSelectTextClick}>Add Text</button>
                    <button className="gBtn cancel etry" onClick={onSelectImageClick}>Add Image</button>
                  </>
                }
              </div>
            </div>
            {/* renderEditor() */}
            {
              addEditStatus && (addEditStatus.success || addEditStatus.error) &&
              <div className="gBCtRw curSts">
                {
                  addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
                }
                {
                  addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
                }
                {
                  addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
                }
              </div>
            }
            <div className="gBCtRw btnRw">
              {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
                { props.type === 'add' && 'Add' }
                { props.type === 'edit' && 'Save Changes' }
                { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            </div>
          </div>
          <div className="rt">
            <div className="dgPgPrvBx">
              <div className="ttl">
                {title}
              </div>
              {
                sections.map((item) => {
                  if (item.type === 'text') {
                    if (item.html) {
                      return (
                        <div className="txtBx" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.html) }} />
                      );
                    }
                  }
                  if (item.type === 'image') {
                    if (item.url) {
                      return (
                        <div className="imgBx">
                          <img src={item.url} />
                        </div>
                      );
                    }
                  }
                  return null;
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('DGRoomSingle props: ', props);
    console.log('DGRoomSingle convertedContent: ', convertedContent);
    console.log('DGRoomSingle sections', sections);
  }

  return renderContent();
}

export default DGRoomSingle;
