import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import GenericAlertModal from '../../../Misc/GenericAlertModal';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { renderOTALogo, OTA_ID_TO_NAME_FORMAL_MAP, STRS } from '../../../../helpers';

import './style.css';


function OTABox(props) {
  const onCtaClick = (e) => {
    if (props.isReadOnly) return;
    if (props.editInProgress) return;
    props.onToggle(props.otaId);
  }
  return (
    <div className="gBCtRw rvOtaChlRw">
      <div className="lt">
        <p className="title">
          {renderOTALogo(props.otaSource)}
          {props.otaName}
        </p>
        {
          props.value.is_requested && !props.value.is_enabled &&
          <div className="bottom">
            <p className="note">
              Note: You’ve requested for this OTA. It may take upto 24 hours for this to be active.
            </p>
          </div>
        }
      </div>
      <div className="rt">
          {
            props.value.is_enabled
            ?
              <span className="cta disable" onClick={onCtaClick}>Disable</span>
            :
              <>
                {
                  props.value.is_requested
                  ? <span className="requested">Connect Requested</span>
                  : <span className="cta enable" onClick={onCtaClick}>Enable</span>
                }
              </>
          }
          {
            props.editInProgress && <i className="fa fa-spinner fa-spin" />
          }
        </div>
      </div>
  );
};


function ReviewChannels(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showOtaConfirmModal, setShowOtaConfirmModal] = useState(false);
  const [otaConfirmModal, setOtaConfirmModal] = useState('none'); // 'tripadvisor', 'booking', etc

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Review Channels - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    const { otas, review_channels } = props.settings.info;
    if (otas) {
      const { tripadvisor, booking, expedia, google, hotelscom, opentable } = otas;
      if (review_channels && review_channels[otaConfirmModal] && review_channels[otaConfirmModal].editStatus && review_channels[otaConfirmModal].editStatus.success) {
        setShowOtaConfirmModal(false);
      }
    }
  }, [props.settings.info]);

  const onOtaToggle = (otaId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOtaToggle: ', otaId);
    }
    if (otaId === 'tripadvisor' ||
      otaId === 'booking' ||
      otaId === 'expedia' ||
      otaId === 'google' ||
      otaId === 'hotelscom' ||
      otaId === 'opentable'
    ) {
      setOtaConfirmModal(otaId);
      setShowOtaConfirmModal(true);
    }
    setFormChanged(true);
  };

  const onOtaConfirmCloseModal = (value) => {
    setShowOtaConfirmModal(false);
  };

  const prepOtaParam = (otaValue) => {
    let params = {};
    if (!otaValue.is_enabled) {
      if (otaValue.date_ota_connected) {
        params['is_enabled'] = true;
      } else {
        params['is_requested'] = true;
      }
    } else {
      params['is_enabled'] = false;
    }
    return params;
  };

  const onOtaConfirmClick = (e) => {
    const { otas } = props.settings.info;
    const params = {};
    params['invoked_from'] = 'review-channels';
    params['location__id'] = urlParams.locationidentifier;
    params['ota'] = otaConfirmModal;
    params['review_channels'] = {};
    if (otaConfirmModal === 'tripadvisor') {
      params['review_channels']['tripadvisor'] = prepOtaParam(otas.tripadvisor || {});
    }
    if (otaConfirmModal === 'booking') {
      params['review_channels']['booking'] = prepOtaParam(otas.booking || {});
    }
    if (otaConfirmModal === 'expedia') {
      params['review_channels']['expedia'] = prepOtaParam(otas.expedia || {});
    }
    if (otaConfirmModal === 'google') {
      params['review_channels']['google'] = prepOtaParam(otas.google || {});
    }
    if (otaConfirmModal === 'hotelscom') {
      params['review_channels']['hotelscom'] = prepOtaParam(otas.hotelscom || {});
    }
    if (otaConfirmModal === 'opentable') {
      params['review_channels']['opentable'] = prepOtaParam(otas.opentable || {});
    }

    // dispatch(editSettingsAction({
    //   params,
    // }));
    params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_review_channels',
      show_alert: true,
      params,
    }));
  };







  if (process.env.NODE_ENV !== 'production') {
    console.log('ReviewChannels: ', props, ' # ', props);
  }

  const { otas, review_channels } = props.settings.info;

  let otaConfirmInfo = `You’ve requested for ${OTA_ID_TO_NAME_FORMAL_MAP[otaConfirmModal]}. Are you sure you want to add this OTA. It may take upto 24 hours for this to be active.`;
  if ((otaConfirmModal === 'tripadvisor' && otas.tripadvisor && otas.tripadvisor.is_enabled) ||
    (otaConfirmModal === 'booking' && otas.booking && otas.booking.is_enabled) ||
    (otaConfirmModal === 'expedia' && otas.expedia && otas.expedia.is_enabled) ||
    (otaConfirmModal === 'google' && otas.google && otas.google.is_enabled) ||
    (otaConfirmModal === 'hotelscom' && otas.hotelscom && otas.hotelscom.is_enabled) ||
    (otaConfirmModal === 'opentable' && otas.opentable && otas.opentable.is_enabled)
  ) {
    otaConfirmInfo = `Do you want to disable ${OTA_ID_TO_NAME_FORMAL_MAP[otaConfirmModal]} channel ?`;
  }


  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Review Channels{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          <OTABox
            isReadOnly={props.isReadOnly}
            otaName={'Tripadvisor'}
            otaId={'tripadvisor'}
            otaSource={2}
            value={otas ? otas.tripadvisor || {} : {}}
            onToggle={onOtaToggle}
            // editInProgress={review_channels.tripadvisor && review_channels.tripadvisor.editInProgress ? review_channels.tripadvisor.editInProgress : false}
          />
          <OTABox
            isReadOnly={props.isReadOnly}
            otaName={'Booking'}
            otaId={'booking'}
            otaSource={3}
            value={otas ? otas.booking || {} : {}}
            onToggle={onOtaToggle}
          />
          <OTABox
            isReadOnly={props.isReadOnly}
            otaName={'Expedia'}
            otaId={'expedia'}
            otaSource={4}
            value={otas ? otas.expedia || {} : {}}
            onToggle={onOtaToggle}
          />
          <OTABox
            isReadOnly={props.isReadOnly}
            otaName={'Google'}
            otaId={'google'}
            otaSource={5}
            value={otas ? otas.google || {} : {}}
            onToggle={onOtaToggle}
          />
          <OTABox
            isReadOnly={props.isReadOnly}
            otaName={'Hotels.com'}
            otaId={'hotelscom'}
            otaSource={6}
            value={otas ? otas.hotelscom || {} : {}}
            onToggle={onOtaToggle}
          />
          <OTABox
            isReadOnly={props.isReadOnly}
            otaName={'OpenTable'}
            otaId={'opentable'}
            otaSource={19}
            value={otas ? otas.opentable || {} : {}}
            onToggle={onOtaToggle}
          />
        </div>
      </div>
      { showOtaConfirmModal &&
        <GenericAlertModal
          modalType="ApproveConfirm"
          title={`Are you sure?`}
          bodyRowOne={otaConfirmInfo}
          bodyRowNote=""
          setModalVisible={onOtaConfirmCloseModal}
          onAffirmativeClick={onOtaConfirmClick}
          // inProgress={otas && otas[otaConfirmModal] ? otas[otaConfirmModal].editInProgress : false}
          inProgress={review_channels && review_channels[otaConfirmModal] && review_channels[otaConfirmModal].editInProgress ? review_channels[otaConfirmModal].editInProgress : false}
          // currentStatus={guest.checkOutStatus}
          showCancel={true}
        />
      }
    </div>
  );
}

export default ReviewChannels;
