import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from "redux";
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import allReducers from "./reducers";

const enhancers = [];
// if (process.env.NODE_ENV === 'development') {
//   const { devToolsExtension } = window;

//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension());
//   }
// }

let composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware), ...enhancers);
if (process.env.NODE_ENV !== 'production') {
  composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware, logger), ...enhancers);
}
// let composedEnhancer = compose(applyMiddleware(thunkMiddleware), ...enhancers);

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, allReducers);

export default function configureStore(preloadedState) {

  const store = createStore(
    persistedReducer, // allReducers,
    composedEnhancer,
    
  );
  // store.getState();
  const persistor = persistStore(store);
  return { persistor, store };
}

// export default store;
