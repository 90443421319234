// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import ToolTipWrapper from '../ToolTipWrapper';

import './style.css';

function TransactionEmailTypeStatusCard(props) {
  const { label, icon, emailType, activeCount, ctaLabel, ctaLink } = props.data;

  return (
    <div className={'gCrd gTETypeStatusCard' + (activeCount ? ' active' : '')}>
      <div className="status">
        <div className="indicator" />
      </div>
      <div className="top">
        <div className="left">
          <div className="line1">
            {label}
          </div>
          <div className="line2">
            {`${activeCount} active`}
          </div>
        </div>
        {
          icon &&
          <div className="right">
            <img src={icon} />
          </div>
        }
      </div>
      <div className="footer">
        {
          ctaLink && ctaLabel &&
          <Link to={ctaLink}>{ctaLabel}</Link>
        }
      </div>
    </div>
  );
}

export default TransactionEmailTypeStatusCard;
