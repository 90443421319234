import React from 'react'
import {Prompt} from 'react-router-dom'
// import {CustomModal} from './CustomModal'
// import NavigationPrompt from "react-router-navigation-prompt";

import GenericAlertModal from './GenericAlertModal';

export class RouteLeavingGuard extends React.Component {
 state = {
   modalVisible: false,
   lastLocation: null,
   confirmedNavigation: false,
 }
 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 // closeModal = (callback) => this.setState({
 //   modalVisible: false
 // }, callback)
 closeModal = () => {
   this.setState({
     modalVisible: false,
  });
  // this.handleConfirmNavigationClick();
}
 handleBlockedNavigation = (nextLocation) => {
   if (process.env.NODE_ENV !== 'production') {
     console.log('handleBlockedNavigation nextLocation: ', nextLocation);
     console.log('handleBlockedNavigation confirmedNavigation: ', this.state.confirmedNavigation);
   }
   const {confirmedNavigation} = this.state
   const {shouldBlockNavigation} = this.props
   if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
       this.showModal(nextLocation)
       return false
   }

   return true
 }
 handleConfirmNavigationClick = () => {
   if (process.env.NODE_ENV !== 'production') {
     console.log('handleConfirmNavigationClick');
   }
   const {navigate} = this.props
   const {lastLocation} = this.state
   if (process.env.NODE_ENV !== 'production') {
     console.log('handleConfirmNavigationClick lastLocation: ', lastLocation);
   }
   if (lastLocation) {
      this.setState({
         confirmedNavigation: true,
         modalVisible: false,
      }, () => {
         // Navigate to the previous blocked location with your navigate function
         this.setState({
            confirmedNavigation: false,
            modalVisible: false,
         })
         navigate(`${lastLocation.pathname}${lastLocation.search}`)
      })
   }
 }
 render() {
   const {when} = this.props
   const {modalVisible, lastLocation} = this.state;
   if (process.env.NODE_ENV !== 'production') {
     console.log('RouteLeavingGuard props: ', this.props);
   }
   return null;
   // return (
   //   <>
   //      <NavigationPrompt when={when}>
   //      {({ onConfirm, onCancel }) => (
   //        <GenericAlertModal
   //          modalType="Unsaved Changes"
   //          title={"Unsaved Changes"}
   //          bodyRowOne={`Do you want to move away without saving these ?`}
   //         // bodyRowNote={'Do you want to move away without saving these ?'}
   //          setModalVisible={this.closeModal}
   //          onAffirmativeClick={this.handleConfirmNavigationClick}
   //          inProgress={false}
   //          showCancel={true}
   //        />
   //      )}
   //      </NavigationPrompt>
   //   </>
   // )
   // return (
   //   <>
   //      <Prompt
   //         when={when}
   //         message={this.handleBlockedNavigation}/>
   //         {
   //           modalVisible &&
   //           <GenericAlertModal
   //             modalType="Unsaved Changes"
   //             title={"Unsaved Changes"}
   //             bodyRowOne={`Do you want to move away without saving these ?`}
   //            // bodyRowNote={'Do you want to move away without saving these ?'}
   //             setModalVisible={this.closeModal}
   //             onAffirmativeClick={this.handleConfirmNavigationClick}
   //             inProgress={false}
   //             showCancel={true}
   //           />
   //         }
   //      {/*<CustomModal
   //         visible={modalVisible}
   //         onCancel={this.closeModal}
   //         onConfirm={this.handleConfirmNavigationClick}/>*/}
   //   </>
   // )
 }

}
export default RouteLeavingGuard
