// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import editIcon from '../../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../../img/icons/delete.svg';

import './style.css';

function AiModelDataRow(props) {

  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };

  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  }

  const renderContent = (template) => {
    if (!template) return null;
    return (
      <div id={`hg_q_${template._id}`} className={'wcqRow ' + (props.isLast ? 'noBorder ' : '')}>
        <div className="title">
          {template.question}
          <div className="actions">
            {
              !props.isReadOnly &&
              <>
                <span><img className="edit" src={editIcon} onClick={onEditClick} /></span>
                <span><img className="delete" src={deleteIcon} onClick={onDeleteClick} /></span>
              </>
            }
          </div>
        </div>
        <div className="msg">
          {template.answer}
        </div>
      </div>
    );
  };

  return renderContent(props.template);

}

export default AiModelDataRow;
