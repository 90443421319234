// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Mousetrap from 'mousetrap';

import MessageTemplateSingle from '../../Settings/Templates/MessageTemplates/MessageTemplateSingle';
import AddWhatsappTemplate from '../../Settings/Templates/AddWhatsappTemplate';
import LabelSingle from '../../Settings/Engage/Labels/LabelSingle';
import RoomSingle from '../../Settings/HotelDetails/Rooms/RoomSingle';
// import RemoteLockSingle from '../../Settings/HotelDetails/RemoteLocks/RemoteLockSingle';
import RateCodeSingle from '../../Settings/HotelDetails/RateCodes/RateCodeSingle';
import BookingSourceSingle from '../../Settings/HotelDetails/BookingSources/BookingSourceSingle';
import SenderNameSingle from '../../Settings/Marketing/SenderNames/SenderNameSingle';
import SenderEmailSingle from '../../Settings/Marketing/SenderEmails/SenderEmailSingle';
import WCQuerySingle from '../../Settings/Engage/WebChat/WCHelpGuide/WCQuerySingle';
import WCRuleSingle from '../../Settings/Engage/WebChat/WCRules/WCRuleSingle';
import DGPageSingle from '../../Settings/Engage/DigitalGuide/DGPages/DGPageSingle';
import DGRoomSingle from '../../Settings/Engage/DigitalGuide/DGRooms/DGRoomSingle';
import UserSingle from '../../Settings/Account/Users/UserSingle';
import RgUserSingle from '../../ResponseGenerator/RgUsers/RgUserSingle';
import AiModelDataSingle from '../../Settings/Reputation/PromptTemplates/WCHelpGuide/AiModelDataSingle';

import ResponseTemplateSingle from '../../Settings/Reputation/ResponseTemplates/ResponseTemplateSingle';
import ResponseTemplateCategorySingle from '../../Settings/Reputation/ResponseTemplateCategories/ResponseTemplateCategorySingle';
import RWRuleSingle from '../../Settings/Reputation/ReviewsWidget/RWRules/RWRuleSingle';
import FeedbackTextTemplateSingle from '../../Settings/Reputation/FeedbackTextTemplates/FeedbackTextTemplateSingle';
import CompSetSingle from '../../Settings/Reputation/CompSet/CompSetSingle';

import JMRuleSingle from '../../Engage/JourneyMessages/JMRuleBased/JMRuleSingle';
import JMSms from '../../Engage/JourneyMessages/JMSms';
import JMWhatsapp from '../../Engage/JourneyMessages/JMWhatsapp';
import JMEmail from '../../Engage/JourneyMessages/JMEmail';

import RGLocationSingle from '../../ResponseGenerator/Configure/General/RGLocationSingle';

import './style.css';


const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function GenericSideModal(props) {

  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - Alert - ${props.modalType}`,
    //   user_id: props.userprops.user.uid,
    // });
    Mousetrap.bind('esc', () => props.setModalVisible(false));
    return () => {
      Mousetrap.unbind('esc');
    };
  }, []);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCancelClick = (e) => {
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    props.onAffirmativeClick();
  };

  const onAffirmativeSecondaryClick = (e) => {
    props.onAffirmativeSecondaryClick();
  };

  const renderModal = (data) => {
    const { opName, messageType, mode, template, reputationTemplateType, reputationCategoryType } = data;
    let lProps = {
      id: data._id,
    };
    const { settings, googlePlaceId } = props.extData;

    if (props.modalType === "messageTemplateGeneric") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <MessageTemplateSingle
                    type={opName}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "addWhatsappMessageTemplate") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <AddWhatsappTemplate
                    template={template}
                    messageType={messageType}
                    onCancelClick={onCancelClick}
                    mode={mode}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                  {/*<MessageTemplateSingle
                    type={opName}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                  />*/}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "label") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <LabelSingle
                    type={opName}
                    label={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "room") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <RoomSingle
                    type={opName}
                    room={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "remote_lock") {
      return null;
    //   return (
    //     <Modal>
    //       <div id="cModelId" className="cModal" onClick={onModalClick}>
    //         <div className="content">
    //           <div className={'sdMdl ' + (props.className)}>
    //             <div className="ctnt">
    //               <RemoteLockSingle
    //                 type={opName}
    //                 room={data}
    //                 onCancelClick={onCancelClick}
    //                 title={props.extData ? props.extData.title || '' : ''}
    //                 settings={settings}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </Modal>
    //   );
    }

    if (props.modalType === "rate_code") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <RateCodeSingle
                    type={opName}
                    rate_code={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "booking_source") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <BookingSourceSingle
                    type={opName}
                    booking_source={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "sender_name") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <SenderNameSingle
                    type={opName}
                    sender_name={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "sender_email") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <SenderEmailSingle
                    type={opName}
                    sender_email={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "journeyMessageRuleSingle") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <JMRuleSingle
                    rule={template}
                    onCancelClick={onCancelClick}
                    mode={mode}
                    settings={settings}
                  />
                  {/*<MessageTemplateSingle
                    type={opName}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                  />*/}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "journeyMessageRuleSms") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <JMSms
                    rule={template}
                    onCancelClick={onCancelClick}
                    mode={mode}
                    settings={settings}
                    isReadOnly={props.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "journeyMessageRuleWhatsapp") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <JMWhatsapp
                    rule={template}
                    onCancelClick={onCancelClick}
                    mode={mode}
                    settings={settings}
                    isReadOnly={props.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "journeyMessageRuleEmail") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <JMEmail
                    rule={template}
                    onCancelClick={onCancelClick}
                    mode={mode}
                    settings={settings}
                    locationInfo={settings.info}
                    isReadOnly={props.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "webChatQuery") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <WCQuerySingle
                    type={opName}
                    query={template}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    topic_id={props.extData.topicId}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "wc_rule") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <WCRuleSingle
                    type={opName}
                    rule={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "dg_page") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <DGPageSingle
                    type={opName}
                    page={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "dg_room") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <DGRoomSingle
                    type={opName}
                    room={data}
                    page={data.page}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "user") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <UserSingle
                    type={opName}
                    user={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "rg_user") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <RgUserSingle
                    type={opName}
                    user={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    users={props.extData.users}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "aiModelData") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <AiModelDataSingle
                    type={opName}
                    query={template}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    topic_id={props.extData.topicId}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "responseTemplate") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <ResponseTemplateSingle
                    type={opName}
                    templateType={reputationTemplateType}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "promptTemplate") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <ResponseTemplateSingle
                    type={opName}
                    templateType={reputationTemplateType}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "responseTemplateCategory") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <ResponseTemplateCategorySingle
                    type={opName}
                    categoryType={reputationCategoryType}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "promptTemplateCategory") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <ResponseTemplateCategorySingle
                    type={opName}
                    categoryType={reputationCategoryType}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "rw_rule") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <RWRuleSingle
                    type={opName}
                    rule={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "feedbackTextTemplate") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <FeedbackTextTemplateSingle
                    type={opName}
                    templateType={reputationTemplateType}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "compSet") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <CompSetSingle
                    type={opName}
                    templateType={reputationTemplateType}
                    template={data}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                    googlePlaceId={googlePlaceId}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (props.modalType === "rgLocation") {
      return (
        <Modal>
          <div id="cModelId" className="cModal" onClick={onModalClick}>
            <div className="content">
              <div className={'sdMdl ' + (props.className)}>
                <div className="ctnt">
                  <RGLocationSingle
                    type={opName}
                    data={template}
                    onCancelClick={onCancelClick}
                    title={props.extData ? props.extData.title || '' : ''}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    return null;
  };


  const renderContent = () => {
    const { data, extData } = props;
    if (!data) return null;
    if (showModal) return renderModal(data);
    return null;
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('GenericSideModal props: ', props);
  }

  return renderContent();
}

export default GenericSideModal;
