import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import ToolTipWrapper from '../../Misc/ToolTipWrapper';

import { onSideBarToggle, setLocationIdentifier, setGroupIdentifier } from '../../../redux/actions/appActions';

import { getHighestAccessLevels, isLocationAdminAndAbove, hasAccessToUserManagment } from '../../../helpers';

import collapseIcon from '../../../img/icons/collapse.svg';
import feedbacksIcon from '../../../img/icons/feedback.svg';
import feedbacksIconActive from '../../../img/icons/feedback-active.svg';
import hotelDetailsIcon from '../../../img/icons/settings/settings-hotel-details.svg';
import hotelDetailsActiveIcon from '../../../img/icons/settings/settings-hotel-details-active.svg';
import templatesIcon from '../../../img/icons/settings/settings-templates.svg';
// import templatesIcon from '../../../img/icons/settings/templates.png';
import templatesActiveIcon from '../../../img/icons/settings/settings-templates-active.svg';
import labelIcon from '../../../img/icons/settings/settings-label.svg';
import labelActiveIcon from '../../../img/icons/settings/settings-label-active.svg';
import usersIcon from '../../../img/icons/settings/settings-users.svg';
import userActiveIcon from '../../../img/icons/settings/settings-users-active.svg';

import reportsIcon from '../../../img/icons/settings/reports.png';
import reportsActiveIcon from '../../../img/icons/settings/reports-active.png';
import alertsIcon from '../../../img/icons/settings/alerts.png';
import alertsActiveIcon from '../../../img/icons/settings/alerts-active.png';

import reviewsWidgetIcon from '../../../img/icons/settings/reviews-widget.png';
import reviewsWidgetActiveIcon from '../../../img/icons/settings/reviews-widget-active.png';
import approvalIcon from '../../../img/icons/settings/approval.png';
import approvalActiveIcon from '../../../img/icons/settings/approval-active.png';
import guestAIlIcon from '../../../img/icons/settings/guestAI.png';
import guestAIActiveIcon from '../../../img/icons/settings/guestAI-active.png';
import responseTemplatesIcon from '../../../img/icons/settings/response-templates.png';
import responseTemplatesActiveIcon from '../../../img/icons/settings/response-templates-active.png';

import transactionEmailsIcon from '../../../img/icons/settings/transaction-emails.png';
import transactionEmailsActiveIcon from '../../../img/icons/settings/transaction-emails-active.png';

import channelsIcon from '../../../img/icons/settings/channels.png';
import channelsActiveIcon from '../../../img/icons/settings/channels-active.png';
import gbmIcon from '../../../img/icons/gbm.png';
import gbmActiveIcon from '../../../img/icons/gbm-active.png';

import essentialsIcon from '../../../img/icons/settings/essentials.png';
import essentialsActiveIcon from '../../../img/icons/settings/essentials-active.png';

import senderNamesIcon from '../../../img/icons/settings/sender-names.svg';
import senderNamesActiveIcon from '../../../img/icons/settings/sender-names-active.svg';
import senderEmailsIcon from '../../../img/icons/settings/sender-emails.svg';
import senderEmailsActiveIcon from '../../../img/icons/settings/sender-emails-active.svg';

import webtextIcon from '../../../img/icons/webchat.svg';
import webtextIconActive from '../../../img/icons/webchat-active.svg';

import './SideBar.css';

var Scroll = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

/* Account Management */

let ACCOUNT_MENU_OPTIONS = [];
ACCOUNT_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Account Management',
});
ACCOUNT_MENU_OPTIONS.push({
  label: 'Users',
  id: 'users',
  icon: usersIcon,
  iconActive: userActiveIcon,
  ttText: 'Users',
});

/* Reputation */

let REP_MENU_OPTIONS = [];
REP_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Reputation',
  id: 'reputation',
  ttText: 'Reputation',
});
REP_MENU_OPTIONS.push({
  label: 'Reports',
  id: 'reports',
  icon: reportsIcon,
  iconActive: reportsActiveIcon,
  ttText: 'Reports',
});
// REP_MENU_OPTIONS.push({
//   label: 'Review Channels',
//   id: 'review-channels',
//   icon: templatesIcon,
//   iconActive: templatesActiveIcon,
//   ttText: 'Configure review channels',
// });
REP_MENU_OPTIONS.push({
  label: 'Alerts',
  id: 'alerts',
  icon: alertsIcon,
  iconActive: alertsActiveIcon,
  ttText: 'Alerts',
});
REP_MENU_OPTIONS.push({
  label: 'guestAI™',
  id: 'guestai-templates/templates',
  icon: guestAIlIcon,
  iconActive: guestAIActiveIcon,
  ttText: 'guestAI™ Templates',
});
REP_MENU_OPTIONS.push({
  label: 'Response Templates',
  id: 'response-templates/templates',
  icon: responseTemplatesIcon,
  iconActive: responseTemplatesActiveIcon,
  ttText: 'Response Templates',
});
REP_MENU_OPTIONS.push({
  label: 'Feedback Text Templates',
  id: 'feedback-text-templates',
  icon: templatesIcon,
  iconActive: templatesActiveIcon,
  ttText: 'Feedback Text Templates',
});
REP_MENU_OPTIONS.push({
  label: 'Response Approval',
  id: 'response-approval', // 'auto-responses',
  icon: approvalIcon,
  iconActive: approvalActiveIcon,
  ttText: 'Auto Responses',
});
REP_MENU_OPTIONS.push({
  label: 'Reviews Widget',
  id: 'reviews-widget',
  icon: reviewsWidgetIcon,
  iconActive: reviewsWidgetActiveIcon,
  ttText: 'Reviews Widget',
});
REP_MENU_OPTIONS.push({
  label: 'Comp Set',
  id: 'comp-set',
  icon: templatesIcon,
  iconActive: templatesActiveIcon,
  ttText: 'Comp Set',
});


/* Engage */

let ENGAGE_MENU_OPTIONS = [];
ENGAGE_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Engage',
  id: 'engage',
  ttText: 'Engage',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Essentials',
  id: 'essentials',
  icon: essentialsIcon,
  iconActive: essentialsActiveIcon,
  ttText: 'Essentials',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Journey Messages',
  id: 'journey-messages',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Journey Messages',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Templates',
  id: 'templates',
  icon: templatesIcon,
  iconActive: templatesActiveIcon,
  ttText: 'Templates',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Labels',
  id: 'labels',
  icon: labelIcon,
  iconActive: labelActiveIcon,
  ttText: 'Labels',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Messaging Channels',
  id: 'messaging-channels',
  icon: channelsIcon,
  iconActive: channelsActiveIcon,
  ttText: 'Messaging Channels',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'WebChat',
  id: 'webchat',
  icon: webtextIcon,
  iconActive: webtextIconActive,
  ttText: 'WebChat',
});
// ENGAGE_MENU_OPTIONS.push({
//   label: 'Digital Guide',
//   id: 'digital-guide',
//   icon: webtextIcon,
//   iconActive: webtextIconActive,
//   ttText: 'Digital Guide',
// });
ENGAGE_MENU_OPTIONS.push({
  label: 'Google Business Messages',
  id: 'google-business-messages',
  icon: gbmIcon,
  iconActive: gbmActiveIcon,
  ttText: 'Google Business Messages',
});

/* Marketing */

let MARKETING_MENU_OPTIONS = [];
MARKETING_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Email Marketing',
  id: 'marketing',
  ttText: 'Email Marketing',
});
MARKETING_MENU_OPTIONS.push({
  label: 'Transaction Emails',
  id: 'transaction-emails',
  icon: transactionEmailsIcon,
  iconActive: transactionEmailsActiveIcon,
  ttText: 'Transaction Emails',
});
MARKETING_MENU_OPTIONS.push({
  label: 'Sender Names',
  id: 'sender-names',
  icon: senderNamesIcon,
  iconActive: senderNamesActiveIcon,
  ttText: 'Sender Names',
});
MARKETING_MENU_OPTIONS.push({
  label: 'Sender Emails',
  id: 'sender-emails',
  icon: senderEmailsIcon,
  iconActive: senderEmailsActiveIcon,
  ttText: 'Sender Emails',
});


/* Misc */

let MISC_MENU_OPTIONS = [];
MISC_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Hotel Details',
  id: 'hotel-details',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Hotel Details',
});


/* Profile */

let PROFILE_MENU_OPTIONS = [];
PROFILE_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Profile',
  id: 'profile',
  ttText: 'Profile',
});



const MenuEntry = ({ id, className, pathname, type, label, link, linkState, icon, iconActive, ttText, menuOpen }) => {
  let active = pathname.indexOf(link) > -1;
  // if (link === '/reputation/locations') {
  //   if (pathname === '/reputation/locations' || pathname === '/reputation/locations/') { active = true; } else { active = false; }
  // }
  return (
    <li className={(className ? className : '') + (active ? ' active' : '') + (type === 'sectionHeader' ? ' sh' : '')}
      key={id}
      id={`mn_${id}`}
    >
      <ToolTipWrapper placement="right" overlayClassName={'sbTooltip ' + (menuOpen ? 'hide' : '')}
        overlay={ttText}
      >
      <Link to={link} state={linkState || {}}>
        { icon && <img src={active ? iconActive : icon} /> }
        <span>{label}</span>
      </Link>
      </ToolTipWrapper>
    </li>
  );
}

function SettingsSideBar() {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [product, setProduct] = useState('');
  const [screenBeforeSettings, setScreenBeforeSettings] = useState({});

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Settings SideBar urlParams: ', urlParams);
      console.log('Settings SideBar location: ', location);
    }
    const { pathname, state } = location;
    if (pathname.indexOf('/reputation') > -1) setProduct('reputation');
    if (pathname.indexOf('/engage') > -1) setProduct('engage');
    if (pathname.indexOf('/marketing') > -1) setProduct('marketing');
    const { locationidentifier, groupidentifier } = urlParams;
    // dispatch(setLocationIdentifier({ locationIdentifier: locationidentifier || '' }));
    dispatch(setGroupIdentifier({ groupIdentifier: groupidentifier || '' }));
    setScreenBeforeSettings(state || {});
    let menuFound = false;
    for(let i = 0; i < ACCOUNT_MENU_OPTIONS.length; i++) {
      const item = ACCOUNT_MENU_OPTIONS[i];
      if (location.pathname.indexOf(item.id) > -1) {
        scroller.scrollTo(`mn_ssb_me_${item.id}`,
          {
          duration: 100,
          delay: 100,
          smooth: true,
          containerId: 's_mn',
          // offset: 50, // Scrolls to element + 50 pixels down the page
        });
        menuFound = true;
        break;
      }
    }
    if (!menuFound) {
      for(let i = 0; i < REP_MENU_OPTIONS.length; i++) {
        const item = REP_MENU_OPTIONS[i];
        if (location.pathname.indexOf(item.id) > -1) {
          scroller.scrollTo(`mn_ssb_me_${item.id}`,
            {
            duration: 100,
            delay: 100,
            smooth: true,
            containerId: 's_mn',
            // offset: 50, // Scrolls to element + 50 pixels down the page
          });
          menuFound = true;
          break;
        }
      }
    }
    if (!menuFound) {
      for(let i = 0; i < ENGAGE_MENU_OPTIONS.length; i++) {
        const item = ENGAGE_MENU_OPTIONS[i];
        if (location.pathname.indexOf(item.id) > -1) {
          scroller.scrollTo(`mn_ssb_me_${item.id}`,
            {
            duration: 100,
            delay: 100,
            smooth: true,
            containerId: 's_mn',
            // offset: 50, // Scrolls to element + 50 pixels down the page
          });
          break;
        }
      }
    }
    if (!menuFound) {
      for(let i = 0; i < MARKETING_MENU_OPTIONS.length; i++) {
        const item = MARKETING_MENU_OPTIONS[i];
        if (location.pathname.indexOf(item.id) > -1) {
          scroller.scrollTo(`mn_ssb_me_${item.id}`,
            {
            duration: 100,
            delay: 100,
            smooth: true,
            containerId: 's_mn',
            // offset: 50, // Scrolls to element + 50 pixels down the page
          });
          break;
        }
      }
    }
    if (!menuFound) {
      for(let i = 0; i < MISC_MENU_OPTIONS.length; i++) {
        const item = MISC_MENU_OPTIONS[i];
        if (location.pathname.indexOf(item.id) > -1) {
          scroller.scrollTo(item.type === 'sectionHeader' ? `mn_ssb_sh_${item.id}` : `mn_ssb_me_${item.id}`,
            {
            duration: 100,
            delay: 100,
            smooth: true,
            containerId: 's_mn',
            // offset: 50, // Scrolls to element + 50 pixels down the page
          });
          break;
        }
      }
    }
  }, [urlParams.groupidentifier, location.pathname]);

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  if (!location) return null;
  const { pathname } = location;
  const { locationidentifier } = urlParams;
  const { screenBeforeSettingsName, screenBeforeSettingsPath } = screenBeforeSettings;

  const hasScreenBeforeSettings = screenBeforeSettingsName && screenBeforeSettingsPath;

  if (!locationidentifier) return null;


  if (!userState.user.user_info || !userState.user.user_info.locations || !userState.user.user_info.locations[locationidentifier]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SettingsSideBar user_info locations missing: ', urlParams);
    }
    return null;
  }

  if (!locationState.locations[locationidentifier]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SettingsSideBar locations missing: ', urlParams);
    }
    return null;
  }

  const { settings } = locationState.locations[locationidentifier];
  if (!settings || !settings.info) return null;

  const { reputation_enabled, engage_enabled, marketing_enabled } = appState.appInfo;

  const { accessLevel, accessLevelReputation } = getHighestAccessLevels(userState.user.user_info, locationidentifier);
  // let accessLevel = '';
  // if (userState.user.user_info && userState.user.user_info.profile) {
  //   accessLevel = userState.user.user_info.profile.locations && userState.user.user_info.profile.locations[locationidentifier] &&
  //     userState.user.user_info.profile.locations[locationidentifier].access_level
  //     ? userState.user.user_info.profile.locations[locationidentifier].access_level
  //     : '';
  //   if (userState.user.user_info.profile.access_level) {
  //     accessLevel = userState.user.user_info.profile.access_level;
  //   }
  // }

  if (process.env.NODE_ENV !== 'production') {
    console.log('SettingsSideBar screenBeforeSettings: ', screenBeforeSettings);
  }

  if (pathname.indexOf(`/settings/locations/${locationidentifier}/profile`) > -1) {
    return null;
    return (
      <div className={'ssb' + (hasScreenBeforeSettings ? ' sbs' : '')}>
        <div className={'fxd top' }>
          <div className="prdRow">
            Profile
          </div>
          {
            hasScreenBeforeSettings &&
            <div className="backRow">
              Back to <Link to={screenBeforeSettingsPath}>{screenBeforeSettingsName}</Link>
            </div>
          }
        </div>
        <div className="mn">
          <ul className="menu" >
            {
              PROFILE_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  return (
                    <MenuEntry className={entry.className ? entry.className : ''}
                      key={`ssb_me_${entry.id}`}
                      id={`ssb_me_${entry.id}`}
                      type={entry.type}
                      label={entry.label} pathname={pathname}
                      link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                      ttText={entry.ttText}
                    />
                  )
                }
                return (
                  <MenuEntry className={entry.className ? entry.className : ''}
                    key={`ssb_me_${entry.id}`}
                    id={`ssb_me_${entry.id}`}
                    label={entry.label} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
          </div>
        </div>
      );
  }

  return (
    <div className={'ssb' + (hasScreenBeforeSettings ? ' sbs' : '')}>
      <div className={'fxd top' }>
        <div className="prdRow">
          Settings
        </div>
        {
          hasScreenBeforeSettings &&
          <div className="backRow">
            Back to <Link to={screenBeforeSettingsPath}>{screenBeforeSettingsName}</Link>
          </div>
        }
      </div>
      <div className="mn" id="s_mn">
        {
          hasAccessToUserManagment(accessLevel) &&
          <ul className="menu" >
            {
              ACCOUNT_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  return (
                    <li className="sh" key={`ssb_sh_${entry.id}`}>
                      <p>{entry.label}</p>
                    </li>
                  )
                }
                if (entry.id === 'users') {
                  if (hasAccessToUserManagment(accessLevel)) {
                    return (
                      <MenuEntry className={entry.className ? entry.className : ''}
                        key={`ssb_me_${entry.id}`}
                        id={`ssb_me_${entry.id}`}
                        label={entry.label} pathname={pathname}
                        link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                        icon={entry.icon} iconActive={entry.iconActive}
                        ttText={entry.ttText}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <MenuEntry className={entry.className ? entry.className : ''}
                    key={`ssb_me_${entry.id}`}
                    id={`ssb_me_${entry.id}`}
                    label={entry.label} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
        }
        {
          reputation_enabled && settings.info.has_reputation &&
          <ul className="menu" >
            {
              REP_MENU_OPTIONS.map((entry) => {
                if (entry.id === 'guestai-templates/templates' && (!settings.info.has_generated_response && !settings.info.has_guest_ai)) return null;
                if (entry.id === 'comp-set' && !settings.info.has_comp_analysis) return null;
                if (entry.type === 'sectionHeader') {
                  return (
                    <MenuEntry className={entry.className ? entry.className : ''}
                      key={`ssb_me_${entry.id}`}
                      id={`ssb_me_${entry.id}`}
                      type={entry.type}
                      label={entry.label} pathname={pathname}
                      link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                      ttText={entry.ttText}
                    />
                  )
                }
                if (entry.id === 'reports' ||
                  entry.id === 'reviews-widget'
                ) {
                  if (isLocationAdminAndAbove(accessLevel || accessLevelReputation)) {
                    return (
                      <MenuEntry className={entry.className ? entry.className : ''}
                        key={`ssb_me_${entry.id}`}
                        id={`ssb_me_${entry.id}`}
                        label={entry.label} pathname={pathname}
                        link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                        icon={entry.icon} iconActive={entry.iconActive}
                        ttText={entry.ttText}
                      />
                    );
                  }
                  return null;
                }
                if (entry.id === 'response-approval') {
                  if (isLocationAdminAndAbove(accessLevel || accessLevelReputation) && settings.info.has_gt_response) {
                    return (
                      <MenuEntry className={entry.className ? entry.className : ''}
                        key={`ssb_me_${entry.id}`}
                        id={`ssb_me_${entry.id}`}
                        label={entry.label} pathname={pathname}
                        link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                        icon={entry.icon} iconActive={entry.iconActive}
                        ttText={entry.ttText}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <MenuEntry className={entry.className ? entry.className : ''}
                    key={`ssb_me_${entry.id}`}
                    id={`ssb_me_${entry.id}`}
                    label={entry.label} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                );
              })
            }
          </ul>
        }
        {
          engage_enabled && settings.info.has_engage &&
          <ul className="menu" >
            {
              ENGAGE_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  return (
                    <MenuEntry className={entry.className ? entry.className : ''}
                      key={`ssb_me_${entry.id}`}
                      id={`ssb_me_${entry.id}`}
                      type={entry.type}
                      label={entry.label} pathname={pathname}
                      link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                      ttText={entry.ttText}
                    />
                  )
                  {/*return (
                    <li className="sh">
                      <p>{entry.label}</p>
                    </li>
                  )*/}
                }
                if (entry.id === 'webchat' && !settings.info.has_webtext) {
                  return null;
                }
                if (entry.id === 'google-business-messages' && !settings.info.has_gbm) {
                  return null;
                }
                if (entry.id === 'messaging-channels' || entry.id === 'webchat' || entry.id === 'google-business-messages') {
                  if (isLocationAdminAndAbove(accessLevel)) {
                    return (
                      <MenuEntry className={entry.className ? entry.className : ''}
                        key={`ssb_me_${entry.id}`}
                        id={`ssb_me_${entry.id}`}
                        label={entry.label} pathname={pathname}
                        link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                        icon={entry.icon} iconActive={entry.iconActive}
                        ttText={entry.ttText}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <MenuEntry className={entry.className ? entry.className : ''}
                    key={`ssb_me_${entry.id}`}
                    id={`ssb_me_${entry.id}`}
                    label={entry.label} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
        }
        {
          marketing_enabled && settings.info.has_transaction_emails &&
          isLocationAdminAndAbove(accessLevel) &&
          <ul className="menu" >
            {
              MARKETING_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  return (
                    <MenuEntry className={entry.className ? entry.className : ''}
                      key={`ssb_me_${entry.id}`}
                      id={`ssb_me_${entry.id}`}
                      type={entry.type}
                      label={entry.label} pathname={pathname}
                      link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                      ttText={entry.ttText}
                    />
                  )
                }
                if (entry.id === 'transaction-emails' || entry.id === 'sender-names' || entry.id === 'sender-emails') {
                  if (isLocationAdminAndAbove(accessLevel)) {
                    return (
                      <MenuEntry className={entry.className ? entry.className : ''}
                        key={`ssb_me_${entry.id}`}
                        id={`ssb_me_${entry.id}`}
                        label={entry.label} pathname={pathname}
                        link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                        icon={entry.icon} iconActive={entry.iconActive}
                        ttText={entry.ttText}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <MenuEntry className={entry.className ? entry.className : ''}
                    key={`ssb_me_${entry.id}`}
                    id={`ssb_me_${entry.id}`}
                    label={entry.label} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
        }
        <ul className="menu" >
          {
            MISC_MENU_OPTIONS.map((entry) => {
              if (entry.type === 'sectionHeader') {
                return (
                  <MenuEntry className={entry.className ? entry.className : ''}
                    key={`ssb_sh_${entry.id}`}
                    id={`ssb_sh_${entry.id}`}
                    type={entry.type}
                    label={entry.label} pathname={pathname}
                    link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                    ttText={entry.ttText}
                  />
                )
                // return (
                //   <li className="sh" key={`ssb_sh_${entry.id}`}>
                //     <p>{entry.label}</p>
                //   </li>
                // )
              }
              return (
                <MenuEntry className={entry.className ? entry.className : ''}
                  key={`ssb_me_${entry.id}`}
                  id={`ssb_me_${entry.id}`}
                  label={entry.label} pathname={pathname}
                  link={`/settings/locations/${locationidentifier}/${entry.id}`} linkState={screenBeforeSettings}
                  icon={entry.icon} iconActive={entry.iconActive}
                  ttText={entry.ttText}
                />
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default SettingsSideBar;
