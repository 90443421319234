
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import ListPanel from './ListPanel';
import BroadcastAll from './BroadcastAll';
import BroadcastHistory from './BroadcastHistory';

import { getUserGAId } from '../../../helpers';

function Broadcast(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Broadcast - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const onContactUsClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
    try { window.Intercom('showNewMessage', `Enable Broadcast for ${locationName}`); } catch(e) {}
  };

  const { settings } = props;
  if (!settings || !settings.info || !settings.info.has_engage_broadcast) {
    return (
      <>
      <div className="sc">
        <div className="gInf">
          The Broadcast is designed to enhance guest communication. It allows you to send tailored messages either as a one-time notification or on a recurring basis to a specific segment of guests and staff.
          <br/>
        </div>
        <div className="gInf"><span className="gLnk gCurDflt" onClick={onContactUsClick}><b>Request Setup and Activation</b></span></div>
      </div>
      </>
    );
  }

  return (
    <>
      <ListPanel />
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path="all" element={<BroadcastAll {...props} />} />
          <Route path="new" element={<BroadcastAll new={true} {...props} />} />
          <Route path="edit/:broadcastidentifier" element={<BroadcastAll {...props} />} />
          <Route path="history" element={<BroadcastHistory {...props} />} />
          <Route path="history/:broadcasthistoryidentifier" element={<BroadcastHistory {...props} />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </>
  );

}

export default Broadcast;
