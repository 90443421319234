import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';

import { ruleTypeToLabel, RULE_TYPES } from '../../../../../../helpers';

import editIcon from '../../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../../img/icons/delete.svg';

import '../style.css';

function DGRoomRow(props) {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    setToggleOn(props.template && props.template.page && props.template.page.is_enabled || false);
  }, []);

  useEffect(() => {
    if (props.template && !props.template.editInProgress && props.template.editStatus && props.template.editStatus.error) {
      setToggleOn(props.template && props.template.page && props.template.page.is_enabled || false);
    }
  }, [props.template]);

  const onEditClick = (e) => {
    props.onEditClick(props.template);
  };
  const onDeleteClick = (e) => {
    props.onDeleteClick(props.template);
  };
  const onDuplicateClick = (e) => {
    props.onDuplicateClick(props.template);
  };
  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    setToggleOn(checked);
    props.onEnableDisableClick({
      ...props.template,
      is_enabled: checked,
    });
  };

  const { template } = props;
  if (!template) return null;
  let rValue = template.type;
  if (template.type) {
    if (template.type === 'appear' && template.delay) {
      rValue = RULE_TYPES.SHOW_AFTER_DELAY;
    }
    if (template.type === 'appear' && template.scroll) {
      rValue = RULE_TYPES.SHOW_AFTER_SCROLL;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('rValue: ', rValue);
    }
  }

  return (
    <div className="gBCtRw ruleRw" id={`rm_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.name}
              />
            : template.label
          }
        </div>
        <div className="rt">
          {
            !props.nonEditable &&
            <>
            {
              props.template && props.template.page && props.template.page._id &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={`${props.template.page.is_enabled ? 'Disable' : 'Enable'} display of room specific information`}>
              <div className="gToggleWrap">
                <div className="gToggle">
                  <input type="checkbox" id={template._id} checked={toggleOn} onChange={onToggleChange}
                    name={template._id} for={template._id}
                  /><label htmlFor={template._id}>Toggle</label>
                </div>
              </div>
              </ToolTipWrapper>
            }
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Edit">
              <span onClick={onEditClick}><img className="edit" src={editIcon} /></span>
            </ToolTipWrapper>
            </>
          }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
        {
          template.attr && template.attr['room-number']
          ? <span>Room Number: {template.attr['room-number']}</span>
          : null
        }
        {
          template.attr && template.attr['room-type']
          ? <span>Room Type: {template.attr['room-type']}</span>
          : null
        }
        </div>
      </div>
      <div className="bt">
        <div className="lt">
          <p>Html info: {template.page ? 'Yes' : 'No'}</p>
        </div>
      </div>
    </div>
  )

}

export default DGRoomRow;
