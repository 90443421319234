import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { Rnd } from "react-rnd";

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import { ACCESS_LEVELS, RG_SUBS, RG_PLAN } from '../../../../helpers';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericSideModal from '../../../Misc/GenericSideModal';

import overviewIcon from '../../../../img/icons/feedback.svg';
import overviewIconActive from '../../../../img/icons/feedback-active.svg';
import uploadCSVIcon from '../../../../img/icons/feedback.svg';
import uploadCSVIconActive from '../../../../img/icons/feedback.svg';
import uploadHistoryIcon from '../../../../img/icons/feedback.svg';
import uploadHistoryIconActive from '../../../../img/icons/feedback.svg';

import './style.css';

function Stage(props) {
  const { stage, locationidentifier, active, link, enabled } = props;
  return (
    <Link to={link}
      className={(active ? 'active' : '')}
    >
      <div className="lt">
        {/*<img src={active ? stage.iconActive || overviewIconActive : stage.icon || overviewIcon} />*/}
        <ToolTipWrapper placement="left" overlayClassName={'sbToolTip'}
          overlay={enabled ? 'Enabled' : 'Disabled'} disable={false}
        >
          <div className={'mDot' + (enabled ? ' active' : '')} />
        </ToolTipWrapper>
      </div>
      <div className="rt">
        <p className="title">{stage.name}</p>
        { stage.desc && <p className="desc">{stage.desc}</p> }
      </div>
    </Link>
  );
}

function ListPanel(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [boxWidth, setBoxWidth] = useState(240);
  const [list, setList] = useState([]);
  const [customizeFeedbackList, setCustomizeFeedbackList] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState(''); // 'default'

  const [showLocationModal, setShowLocationModal] = useState(false);

  useEffect(() => {
    let listLocal = [];
    // listLocal.push({ 'id': 'default', 'name': 'Default', 'desc': '', 'icon': overviewIcon, 'iconActive': overviewIconActive });
    setList(listLocal);
  }, []);

  useEffect(() => {
    const firstLocation = locations.list.find((rgLocId) => {
      let stage = locations.keyValue[rgLocId] || {};
      if (!stage._id || !stage.name || stage.is_removed) return false;
      return true;
      // let active = false;
      // active = location.pathname.indexOf(`/response-generator/configure/${rgLocId}`) > -1;
      // return (
      //   <Stage key={`te_stg_${rgLocId}`} stage={stage} locationidentifier={locationidentifier} active={active}
      //     link={`/response-generator/configure/${rgLocId}`}
      //     enabled={stage.is_enabled}
      //   />
      // )
    })
    if (process.env.NODE_ENV !== 'production') {
      console.log('ListPanel firstLocation: ', firstLocation);
    }
    // if (firstLocation) {
    //   navigate(`/response-generator/configure/${firstLocation}`);
    // }
    if (firstLocation &&
      (location.pathname === '/response-generator/configure' || location.pathname === '/response-generator/configure/')
    ) {
      navigate(`/response-generator/configure/${firstLocation}`);
    }
  }, []);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('location.pathname: ', location.pathname);
  //   }
  //   if (location.pathname.indexOf('/feedbacks/customize-feedbacks') > -1) {
  //     setSelectedMenu('customize-feedbacks');
  //   } else {
  //     setSelectedMenu('default');
  //   }
  // }, [location.pathname]);

  const onLocationAddClick = (e) => {
    if (props.isReadOnly) return;
    setShowLocationModal(true);
  };
  const onLocationCancelClick = (value) => {
    setShowLocationModal(value);
  };

  const onLocationAddLockClick = (e) => {
    try { window.Intercom('showNewMessage', `Upgrade Review Response AI to Pro`); } catch(e) {}
  };


  const renderLocationModal = () => {
    const { locations } = responseGeneratorState;
    return (
      <GenericSideModal
        modalType="rgLocation"
        className="mtGen"
        data={{
          mode: 'add',
          opName: 'add',
          template: {}, // selectedQuery ? { ...selectedQuery } : {},
        }}
        extData={{
          title: 'Add Location' ,
        }}
        setModalVisible={onLocationCancelClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  };


  const { locationidentifier, stage } = urlParams;
  const { locations } = responseGeneratorState;

  return (
    <>
      <Rnd
        className={'fdbMenuLst'}
        size={{width: boxWidth}}
        style={{}}
        disableDragging={true}
        enableResizing={{
          right: true,
        }}
        maxWidth={300}
        minWidth={180}
        onResize={(e, direction, ref, delta, position) => {
          setBoxWidth(ref.style.width);
        }}
      >
        <div className="rszIndc">
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
        </div>
        {
          selectedMenu === ''
          ?
            <>
              <div className="hdr">
                Configure
                {
                  userState.user.user_info.profile && userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN &&
                  userState.user.user_info.profile.rg_plan === RG_PLAN.PRO &&
                  <ToolTipWrapper placement="left" overlayClassName={'sbToolTip'}
                    overlay={'Add new location'} disable={false}
                  >
                  <div onClick={onLocationAddClick}>Add <span><i className="fa fa-plus-circle" /></span></div>
                  </ToolTipWrapper>
                }
                {
                  userState.user.user_info.profile && userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN &&
                  userState.user.user_info.profile.rg_plan === RG_PLAN.BASIC &&
                  <ToolTipWrapper placement="left" overlayClassName={'sbToolTip'}
                    overlay={'Upgrade to Pro Plan to add new locations'} disable={false}
                  >
                  <div className="lck" onClick={onLocationAddLockClick}>Add <span><i className="fa-solid fa-lock" /></span></div>
                  </ToolTipWrapper>
                }
              </div>
              <div className="mnuLst">
                {/*
                  list.map((stage) => {
                    let active = false;
                    if (stage.id === 'default') {
                      active = location.pathname.indexOf(`/response-generator/configure/${stage.id}`) > -1;
                    } else {
                      active = location.pathname.indexOf(`/response-generator/locations/${locationidentifier}/feedbacks/${stage.id}`) > -1;
                    }
                    if (stage.id === 'wZYeknJ4QgyGXj7Jm') {
                      return (
                        <Stage key={`te_stg_${stage.id}`} stage={stage} locationidentifier={locationidentifier} active={active}
                          link={`/settings/locations/wZYeknJ4QgyGXj7Jm/guestai-templates/templates`}
                        />
                      )
                    }
                    return (
                      <Stage key={`te_stg_${stage.id}`} stage={stage} locationidentifier={locationidentifier} active={active}
                        link={`/response-generator/configure/${stage.id}`}
                      />
                    )
                  })
                */}
                {
                  locations && locations.keyValue && locations.list && locations.list.length
                  ?
                    locations.list.map((rgLocId) => {
                      let stage = locations.keyValue[rgLocId] || {};
                      if (!stage._id || !stage.name || stage.is_removed) return null;
                      let active = false;
                      active = location.pathname.indexOf(`/response-generator/configure/${rgLocId}`) > -1;
                      return (
                        <Stage key={`te_stg_${rgLocId}`} stage={stage} locationidentifier={locationidentifier} active={active}
                          link={`/response-generator/configure/${rgLocId}`}
                          enabled={stage.is_enabled}
                        />
                      )
                    })
                  : null
                }
              </div>
            </>
          : null
        }
        { showLocationModal && renderLocationModal() }
      </Rnd>
    </>
  );
}

export default ListPanel;
