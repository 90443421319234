import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import GenericAlertModal from '../../../Misc/GenericAlertModal';
import GenericList from '../../../Misc/GenericList';
import PromptSingle from './PromptSingle';
import PromptRow from './PromptRow';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../redux/actions/snacksActions';
import { STRS, ACCESS_LEVELS } from '../../../../helpers';

import avataarIcon from '../../../../img/icons/avataar.svg';
import deleteIcon from '../../../../img/icons/delete.svg';

import './style.css';

const gtconfig = require('../../../../gtconfig');


function General(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [nameChanged, setNameChanged] = useState(false);

  const [useTypeOfBusiness, setUseTypeOfBusiness] = useState(false);
  const [useTypeOfBusinessChanged, setUseTypeOfBusinessChanged] = useState(false);
  const [typeOfBusiness, setTypeOfBusiness] = useState('');
  const [typeOfBusinessError, setTypeOfBusinessError] = useState('');
  const [typeOfBusinessChanged, setTypeOfBusinessChanged] = useState(false);

  const [useHotelName, setUseHotelName] = useState(false);
  const [useHotelNameChanged, setUseHotelNameChanged] = useState(false);
  const [hotelName, setHotelName] = useState('');
  const [hotelNameError, setHotelNameError] = useState('');
  const [hotelNameChanged, setHotelNameChanged] = useState(false);

  const [useHotelAddress, setUseHotelAddress] = useState(false);
  const [useHotelAddressChanged, setUseHotelAddressChanged] = useState(false);
  const [hotelAddress, setHotelAddress] = useState('');
  const [hotelAddressError, setHotelAddressError] = useState('');
  const [hotelAddressChanged, setHotelAddressChanged] = useState(false);

  const [useSalutation, setUseSalutation] = useState(false);
  const [useSalutationChanged, setUseSalutationChanged] = useState(false);

  const [salutationMsg, setSalutationMsg] = useState('');
  const [salutationMsgError, setSalutationMsgError] = useState('');
  const [salutationMsgChanged, setSalutationMsgChanged] = useState(false);

  const [useSignature, setUseSignature] = useState(false);
  const [useSignatureChanged, setUseSignatureChanged] = useState(false);

  const [signatureMsg, setSignatureMsg] = useState('');
  const [signatureMsgError, setSignatureMsgError] = useState('');
  const [signatureMsgChanged, setSignatureMsgChanged] = useState(false);

  const [useTone, setUseTone] = useState(false);
  const [useToneChanged, setUseToneChanged] = useState(false);
  const [toneSelectValues, setToneSelectValues] = useState([]);
  const [toneSelectOptions, setToneSelectOptions] = useState('');
  const [toneChanged, setToneChanged] = useState('');
  const [customTone, setCustomTone] = useState('');
  const [customToneError, setCustomToneError] = useState('');


  const [useBrandTone, setUseBrandTone] = useState(false);
  const [useBrandToneChanged, setUseBrandToneChanged] = useState(false);

  const [brandTone, setBrandTone] = useState('');
  const [brandToneError, setBrandToneError] = useState('');
  const [brandToneChanged, setBrandToneChanged] = useState(false);


  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDefaultModal, setShowDefaultModal] = useState(false);


  const [selectedFollowUpTemplate, setSelectedFollowUpTemplate] = useState({});
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [followUpModalType, setFollowUpModalType] = useState('');
  const [showFollowUpDeleteModal, setShowFollowUpDeleteModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [showLocDeleteModal, setShowLocDeleteModal] = useState(false);
  const [showLocCantDeleteModal, setShowLocCantDeleteModal] = useState(false);
  const [toggleOn, setToggleOn] = useState(false);
  const [showLocCantDisableModal, setShowLocCantDisableModal] = useState(false);


  const [formChanged, setFormChanged] = useState(false);

  const clearAction = () => {
    const params = {
    };
    dispatch(editDataClearAction({
      op: 'edit_rg_location',
      params,
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      document.title = `Review Response AI - Configure - General`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        // gt_location_id: urlParams.locationidentifier,
        // gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('General setInitialValues');
    }
    const { locations } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
    if (process.env.NODE_ENV !== 'production') {
      console.log('General setInitialValues rgLocationInfo: ', rgLocationInfo);
    }
    if (!rgLocationInfo || !rgLocationInfo._id) return;
    if (!rgLocationInfo.is_default) setName(rgLocationInfo.name ? rgLocationInfo.name || '' : '');
    if (!rgLocationInfo.is_default) setToggleOn(rgLocationInfo.is_enabled || false);
    setUseTypeOfBusiness(rgLocationInfo.use_type_of_business || false);
    setTypeOfBusiness(rgLocationInfo.type_of_business ? rgLocationInfo.type_of_business || '' : '');
    setTypeOfBusinessError('');
    setUseHotelName(rgLocationInfo.use_hotel_name || false);
    setHotelName(rgLocationInfo.hotel_name ? rgLocationInfo.hotel_name || '' : '');
    setHotelNameError('');
    setUseHotelAddress(rgLocationInfo.use_hotel_address || false);
    setHotelAddress(rgLocationInfo.hotel_address ? rgLocationInfo.hotel_address || '' : '');
    setHotelAddressError('');
    setSalutationMsg(rgLocationInfo.salutation_text ? rgLocationInfo.salutation_text || '' : '');
    setUseSalutation(rgLocationInfo.use_salutation || false);
    setSalutationMsgError('');
    setSignatureMsg(rgLocationInfo.signature_text ? rgLocationInfo.signature_text || '' : '');
    setUseSignature(rgLocationInfo.use_signature || false);
    setSignatureMsgError('');

    let tOptions = [];
    tOptions.push({ label: 'Friendly', value: 'Friendly' });
    tOptions.push({ label: 'Warm', value: 'Warm' });
    tOptions.push({ label: 'Empathetic', value: 'Empathetic' });
    tOptions.push({ label: 'Personable & conversational', value: 'Personable & conversational' });
    tOptions.push({ label: 'Confident', value: 'Confident' });
    tOptions.push({ label: 'Engaging', value: 'Engaging' });
    tOptions.push({ label: 'Witty', value: 'Witty' });
    tOptions.push({ label: 'Direct', value: 'Direct' });
    tOptions.push({ label: 'Custom', value: 'custom' });
    setToneSelectOptions(tOptions);

    setUseBrandTone(rgLocationInfo.use_brand_tone || false);
    setBrandTone(rgLocationInfo.brand_tone ? rgLocationInfo.brand_tone || '' : '');
    setBrandToneError('');

    setUseTone(rgLocationInfo.use_tone || false);
    if (rgLocationInfo.tone_values && Array.isArray(rgLocationInfo.tone_values) && rgLocationInfo.tone_values.length) {
      setToneSelectValues(rgLocationInfo.tone_values);
    }
    setCustomTone(rgLocationInfo.tone_custom ? rgLocationInfo.tone_custom || '' : '');

    setFormChanged(false);
  };


  useEffect(() => {
    clearAction();
    setInitialValues();
  }, [urlParams.rglocationidentifier]);

  useEffect(() => {
    if (urlParams.rglocationidentifier) {
      const { locations } = responseGeneratorState;
      const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
      const { prompt_templates } = rgLocationInfo;
      if (selectedTemplate &&
        selectedTemplate._id &&
        prompt_templates && prompt_templates.keyValue &&
        prompt_templates.keyValue[selectedTemplate._id] &&
        prompt_templates.keyValue[selectedTemplate._id].is_removed &&
        prompt_templates.keyValue[selectedTemplate._id].editStatus &&
        prompt_templates.keyValue[selectedTemplate._id].editStatus.success
      ) {
        setShowDeleteModal(false);
      }
      if (selectedTemplate &&
        selectedTemplate._id &&
        prompt_templates && prompt_templates.keyValue &&
        prompt_templates.keyValue[selectedTemplate._id] &&
        !prompt_templates.keyValue[selectedTemplate._id].is_removed &&
        prompt_templates.keyValue[selectedTemplate._id].editStatus &&
        prompt_templates.keyValue[selectedTemplate._id].editStatus.success
      ) {
        setShowDefaultModal(false);
      }
      if (rgLocationInfo &&
        rgLocationInfo._id &&
        rgLocationInfo.is_removed &&
        rgLocationInfo.editStatus &&
        rgLocationInfo.editStatus.success
      ) {
        setShowLocDeleteModal(false);
        navigate(`/response-generator/configure`);
      }
    }
  }, [responseGeneratorState.locations]);

  const onUseHotelNameChanged = (e) => {
    const { name, checked } = e.target;
    setUseHotelName(checked);
    setUseHotelNameChanged(true);
    setFormChanged(true);
  };

  const onNameChange = (e) => {
    const { name, value } = e.target;
    // if (value.length > 20) return;
    setName(value);
    setNameError(value ? '' : 'error');
    setFormChanged(true);
    setNameChanged(true);
  };

  const onUseTypeOfBusinessChanged = (e) => {
    const { name, checked } = e.target;
    setUseTypeOfBusiness(checked);
    setUseTypeOfBusinessChanged(true);
    setFormChanged(true);
  };

  const onTypeOfBusinessChange = (e) => {
    const { name, value } = e.target;
    setTypeOfBusiness(value);
    setTypeOfBusinessError(value ? '' : 'error');
    setFormChanged(true);
    setTypeOfBusinessChanged(true);
  };

  const onHotelNameChange = (e) => {
    const { name, value } = e.target;
    setHotelName(value);
    setHotelNameError(value ? '' : 'error');
    setFormChanged(true);
    setHotelNameChanged(true);
  };

  const onUseHotelAddressChanged = (e) => {
    const { name, checked } = e.target;
    setUseHotelAddress(checked);
    setUseHotelAddressChanged(true);
    setFormChanged(true);
  };

  const onHotelAddressChange = (e) => {
    const { name, value } = e.target;
    setHotelAddress(value);
    setHotelAddressError(value ? '' : 'error');
    setFormChanged(true);
    setHotelAddressChanged(true);
  };

  const onSalutationMsgChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 30) return;
    setSalutationMsg(value);
    setSalutationMsgError(value ? '' : 'error');
    setFormChanged(true);
    setSalutationMsgChanged(true);
  };

  const onUseSalutionChanged = (e) => {
    const { name, checked } = e.target;
    setUseSalutation(checked);
    setUseSalutationChanged(true);
    setFormChanged(true);
  };

  const onSignatureMsgChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 100) return;
    setSignatureMsg(value);
    setSignatureMsgError(value ? '' : 'error');
    setFormChanged(true);
    setSignatureMsgChanged(true);
  };

  const onUseSignatureChanged = (e) => {
    const { name, checked } = e.target;
    setUseSignature(checked);
    setUseSignatureChanged(true);
    setFormChanged(true);
  };

  const onUseBrandToneChanged = (e) => {
    const { name, checked } = e.target;
    setUseBrandTone(checked);
    setUseBrandToneChanged(true);
    setFormChanged(true);
  };
  const onBrandToneChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 30) return;
    setBrandTone(value);
    setBrandToneError(value ? '' : 'error');
    setFormChanged(true);
    setBrandToneChanged(true);
  };

  const onUseToneChanged = (e) => {
    const { name, checked } = e.target;
    setUseTone(checked);
    setUseToneChanged(true);
    setFormChanged(true);
  };
  const onToneSelectChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onToneSelectChange values: ', values);
    }

    const hasCustomIndex = values.findIndex(el => el.value === 'custom');
    if (hasCustomIndex > -1) {
      if (hasCustomIndex === 0) { // new ones are non custom
        setToneSelectValues(values.splice(1));
        setCustomToneError('');
        setToneChanged(true);
        setFormChanged(true);
        return;
      }
      if (hasCustomIndex === values.length - 1 ) { // custom is last
        setToneSelectValues([{ label: 'Custom', value: 'custom' }]);
        setCustomToneError('');
        setToneChanged(true);
        setFormChanged(true);
        return;
      }
    }

    setCustomToneError(values.length > 3 ? 'maxError' : '');
    if (values.length > 3) return;

    setToneSelectValues(values);
    setToneChanged(true);
  };
  const onCustomToneChange = (e) => {
    const { name, value } = e.target;
    setCustomTone(value);
    setCustomToneError(value ? '' : 'error');
    setFormChanged(true);
    setToneChanged(true);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { locations } = responseGeneratorState;
    const rgLocationInfo = locations && locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
    if (!rgLocationInfo || !rgLocationInfo._id) return;
    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave rgLocationInfo: ', rgLocationInfo);
      console.log('performSave name: ', name);
      console.log('performSave typeOfBusiness: ', typeOfBusiness);
      console.log('performSave hotelName: ', hotelName);
      console.log('performSave hotelAddress: ', hotelAddress);
      console.log('performSave useSalutation: ', useSalutation);
      console.log('performSave salutationMsg: ', salutationMsg);
      console.log('performSave useSignature: ', useSignature);
      console.log('performSave signatureMsg: ', signatureMsg);
      console.log('performSave useBrandTone: ', useBrandTone);
      console.log('performSave brandTone: ', brandTone);
      console.log('performSave useTone: ', useTone);
      console.log('performSave toneSelectValues: ', toneSelectValues);
      console.log('performSave customTone: ', customTone);
    }

    if (rgLocationInfo && rgLocationInfo.editInProgress) return;

    let hasError = false;

    if (!rgLocationInfo.is_default) {
      setNameError(name ? '' : 'error'); hasError = !name;
    }
    if (useTypeOfBusiness) {
      if (!typeOfBusiness) { setTypeOfBusinessError('error'); hasError = true; }
    }
    if (useHotelName) {
      if (!hotelName) { setHotelNameError('error'); hasError = true; }
    }
    if (useHotelAddress) {
      if (!hotelAddress) { setHotelAddressError('error'); hasError = true; }
    }
    if (useSalutation) {
      if (!salutationMsg) { setSalutationMsgError('error'); hasError = true; }
    }
    if (useSignature) {
      if (!signatureMsg) { setSignatureMsgError('error'); hasError = true; }
    }
    if (useBrandTone) {
      if (!brandTone) { setBrandToneError('error'); hasError = true; }
    }
    if (useTone) {
      if (toneSelectValues && toneSelectValues.length === 1 &&
        toneSelectValues[0].value === 'custom' && !customTone
      ) { setCustomToneError('maxError'); hasError = true; }

      if (!toneSelectValues || !toneSelectValues.length) {
        setCustomToneError('error'); hasError = true;
      }
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave hasError: ', hasError);
    }
    if (hasError) return;

    const params = {
      mode: 'edit',
      rg_location__id: rgLocationInfo._id,
    };
    if (rgLocationInfo.is_default) params.loc_is_default = true;
    if (nameChanged) {
      params.name = name;
    }
    if (useTypeOfBusinessChanged) { params.use_type_of_business = useTypeOfBusiness; }
    if (typeOfBusinessChanged) {
      params.type_of_business = typeOfBusiness;
    }
    if (useHotelNameChanged) { params.use_hotel_name = useHotelName; }
    if (hotelNameChanged) {
      params.hotel_name = hotelName;
    }
    if (useHotelAddressChanged) { params.use_hotel_address = useHotelAddress; }
    if (hotelAddressChanged) {
      params.hotel_address = hotelAddress;
    }
    if (salutationMsgChanged) {
      params.salutation_text = salutationMsg;
    }
    if (useSalutationChanged) {
      params.use_salutation = useSalutation;
    }
    if (signatureMsgChanged) {
      params.signature_text = signatureMsg;
    }
    if (useSignatureChanged) {
      params.use_signature = useSignature;
    }
    if (useBrandToneChanged) {
      params.use_brand_tone = useBrandTone;
    }
    if (brandToneChanged) { params.brand_tone = brandTone; }
    if (useToneChanged) {
      params.use_tone = useTone;
    }
    if (toneChanged) { params.tone_values = toneSelectValues; }
    if (customTone) { params.tone_custom = customTone; }

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_rg_location',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };

  const onAddClick = (e) => {
    // dispatch(editDataClearAction({
    //   op, // : 'edit_message_template',
    //   params: {
    //     location__id: urlParams.locationidentifier,
    //     mode: 'add',
    //   },
    // }));
    setSelectedTemplate({});
    setModalType('add');
    setShowModal(true);
  };

  const onCloseModal = (e) => {
    const { locations } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
    const { prompt_templates } = rgLocationInfo;
    if (modalType === 'add') {
      if (prompt_templates && prompt_templates.addInProgress) {
        return;
      }
    }
    if (modalType === 'edit') {
      if (selectedTemplate &&
        selectedTemplate._id &&
        prompt_templates && prompt_templates.keyValue &&
        prompt_templates.keyValue[selectedTemplate._id] &&
        prompt_templates.keyValue[selectedTemplate._id].editInProgress
      ) {
        return;
      }
    }


    setShowModal(false);
    setSelectedTemplate({});
  };

  const onRowEditClick = (templateData) => {
    setSelectedTemplate(templateData);
    setModalType('edit');
    setShowModal(true);
    setShowDeleteModal(false);
  };
  const onRowDeleteClick = (templateData) => {
    if (templateData.is_default) {
      const id = new Date().getTime();
      dispatch(receiveSnack({
        snack: {
          id,
          msg: 'Default template cannot be deleted.',
        },
      }));
      // remove the snack
      setTimeout(() => {
        dispatch(removeSnack({
          id,
        }));
      }, 2000);
    } else {
      setSelectedTemplate(templateData);
      setShowModal(false);
      setShowDeleteModal(true);
    }
  };
  const onRowDefaultClick = (templateData) => {
    setSelectedTemplate(templateData);
    setShowModal(false);
    setShowDeleteModal(false);
    setShowDefaultModal(true);
  };

  const onDeleteConfirmClick = (e) => {
    if (selectedTemplate && selectedTemplate._id) {
      if (selectedTemplate.editInProgress) return;
      const { locations } = responseGeneratorState;
      if (!locations) return;
      if (!urlParams.rglocationidentifier) return;
      const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};


      const { prompt_templates, followup_prompt_templates } = rgLocationInfo;
      if (selectedTemplate.type === 'regular') {
        if (prompt_templates && prompt_templates.keyValue && selectedTemplate._id &&
          prompt_templates.keyValue[selectedTemplate._id]
          && prompt_templates.keyValue[selectedTemplate._id].editInProgress
        ) return;
      }
      if (selectedTemplate.type === 'followup') {
        if (followup_prompt_templates && followup_prompt_templates.keyValue && selectedTemplate._id &&
          followup_prompt_templates.keyValue[selectedTemplate._id]
          && followup_prompt_templates.keyValue[selectedTemplate._id].editInProgress
        ) return;
      }

      const params = {
        rg_location__id: rgLocationInfo._id,
        mode: 'remove',
      };
      if (selectedTemplate.type) params.prompt_type = selectedTemplate.type;
      if (urlParams.rglocationidentifier === "default") params.loc_is_default = true;
      let op = 'edit_rg_prompt_template';
      params.prompt_template_id = selectedTemplate._id;
      dispatch(editDataAction({
        op,
        params,
        show_alert: true,
        alert_msg: 'Deleted successfully',
      }));
    }
  };

  const onDefaultConfirmClick = (e) => {
    if (selectedTemplate && selectedTemplate._id) {
      if (selectedTemplate.editInProgress) return;
      const { locations } = responseGeneratorState;
      if (!locations) return;
      if (!urlParams.rglocationidentifier) return;
      const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};

      const { prompt_templates } = rgLocationInfo;
      if (prompt_templates && prompt_templates.keyValue && selectedTemplate._id &&
        prompt_templates.keyValue[selectedTemplate._id]
        && prompt_templates.keyValue[selectedTemplate._id].editInProgress
      ) return;

      const params = {
        rg_location__id: rgLocationInfo._id,
        mode: 'edit',
      };
      if (selectedTemplate.type) params.prompt_type = selectedTemplate.type;
      if (urlParams.rglocationidentifier === "default") params.loc_is_default = true;
      let op = 'edit_rg_prompt_template';
      params.prompt_template_id = selectedTemplate._id;
      if (selectedTemplate.is_default) {
        params.is_default = false;
      } else {
        params.is_default = true;
      }
      dispatch(editDataAction({
        op,
        params,
        show_alert: true,
        alert_msg: 'Updated successfully',
      }));
    }
  };

  const onEnableDisableClick = (templateData) => {
    if (templateData && templateData._id) {
      if (templateData.editInProgress) return;
      const { locations } = responseGeneratorState;
      if (!locations) return;
      if (!urlParams.rglocationidentifier) return;
      const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};

      const { followup_prompt_templates } = rgLocationInfo;
      if (followup_prompt_templates && followup_prompt_templates.keyValue && templateData._id &&
        followup_prompt_templates.keyValue[templateData._id]
        && followup_prompt_templates.keyValue[templateData._id].editInProgress
      ) return;

      const params = {
        rg_location__id: rgLocationInfo._id,
        mode: 'edit',
        is_enabled: templateData.is_enabled,
      };
      if (templateData.type) params.prompt_type = templateData.type;
      if (urlParams.rglocationidentifier === "default") params.loc_is_default = true;
      let op = 'edit_rg_prompt_template';
      params.prompt_template_id = templateData._id;
      dispatch(editDataAction({
        op,
        params,
        show_alert: true,
        alert_msg: 'Updated successfully',
      }));
    }
  }


  const onFollowUpAddClick = (e) => {
    setSelectedFollowUpTemplate({});
    setFollowUpModalType('add');
    setShowFollowUpModal(true);
  };
  const onFollowUpCloseModal = (e) => {
    const { locations } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
    const { followup_prompt_templates } = rgLocationInfo;
    if (modalType === 'add') {
      if (followup_prompt_templates && followup_prompt_templates.addInProgress) {
        return;
      }
    }
    if (modalType === 'edit') {
      if (selectedFollowUpTemplate &&
        selectedFollowUpTemplate._id &&
        followup_prompt_templates && followup_prompt_templates.keyValue &&
        followup_prompt_templates.keyValue[selectedFollowUpTemplate._id] &&
        followup_prompt_templates.keyValue[selectedFollowUpTemplate._id].editInProgress
      ) {
        return;
      }
    }


    setShowFollowUpModal(false);
    setSelectedFollowUpTemplate({});
  };


  const onLocDeleteClick = (e) => {
    setSelectedTemplate({});
    setShowModal(false);
    setShowDeleteModal(false);
    const { locations } = responseGeneratorState;
    const hasRgLocation = locations && locations.list && locations.list.length === 1;
    if (hasRgLocation) {
      setShowLocCantDeleteModal(true);
    } else {
      setShowLocDeleteModal(true);
    }
  };
  const onLocDeleteConfirmClick = (e) => {
    const { locations } = responseGeneratorState;
    if (!locations) return;
    if (!urlParams.rglocationidentifier) return;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
    if (!rgLocationInfo._id) return;

    const params = {
      rg_location__id: rgLocationInfo._id,
      mode: 'remove',
    };
    let op = 'edit_rg_location';
    dispatch(editDataAction({
      op,
      params,
    }));
  };
  const onLocCantDeleteConfirmClick = (e) => {
    setShowLocCantDeleteModal(false);
  }

  const onToggleChange = (e) => {
    const { name, checked } = e.target;

    const { locations } = responseGeneratorState;
    if (!locations) return;
    if (!urlParams.rglocationidentifier) return;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};
    if (!rgLocationInfo._id) return;

    let isLastActiveLocation = true;
    if (locations.list && locations.list.length) {
      if (locations.list.filter(el => locations.keyValue[el] && locations.keyValue[el].is_enabled).length > 1) isLastActiveLocation = false;
    }

    if (!checked && isLastActiveLocation) {
      setShowLocCantDisableModal(true);
    } else {

      setToggleOn(checked);

      const params = {
        rg_location__id: rgLocationInfo._id,
        mode: 'edit',
        is_enabled: checked,
      };
      let op = 'edit_rg_location';
      dispatch(editDataAction({
        op,
        params,
        show_alert: true,
        alert_msg: 'Updated successfully',
        // show_alert_timeout: 2000,
        show_error_alert: true,
        error_alert_msg: 'Error in updating',
      }));
    }
  };
  const onLocCantDisableConfirmClick = (e) => {
    setShowLocCantDisableModal(false);
  }


  const renderOnOff = (label, name, isOn, isOnMsg, isOffMsg, onChanged) => {
    return (
      <div className={'gBCtRw hasTgl' + (isOn ? ' noBtmMrgn' : '')}>
        <div className="lt">
          <p className="title">{label}</p>
          <p className="desc">{ isOn ? isOnMsg : isOffMsg}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={name} checked={isOn} onChange={onChanged}/><label htmlFor={name}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderName = () => {
    return (
      <div className="gBCtRw astntNm">
        <div className="lt">
          <p className="title">Location Name</p>
          <p className="desc">Set name for the location</p>
          {/*<p className="maxLen">Max length: 20</p>*/}
          <div className="gIpt">
            <input
              type="text"
              value={name}
              onChange={onNameChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTypeOfBusiness = () => {
    return (
      <>
        { renderOnOff("Use Type of business", "useTypeOfBusiness", useTypeOfBusiness, "Type of business info will be used to the generated response", "Type of business info will not be used to the generated response", onUseTypeOfBusinessChanged) }
        {
          useTypeOfBusiness &&
          <div className="gBCtRw astntNm">
            <div className="lt">
              <p className="title">Type of business</p>
              <p className="desc">Enter type of business such as Hotel, Bar, Restaurant, etc. It will assist ReviewResponseAI in generating appropriate responses</p>
              <div className="gIpt">
                <input
                  type="text"
                  value={typeOfBusiness}
                  onChange={onTypeOfBusinessChange}
                />
              </div>
              {
                typeOfBusinessError &&
                <p className="gErr">Required</p>
              }
            </div>
          </div>
        }
      </>
    );
  };

  const renderHotelName = () => {
    return (
      <>
        { renderOnOff("Use Business Name", "useHotelName", useHotelName, "Name of business will be added to the generated response", "Name of business will not be added to the generated response", onUseHotelNameChanged) }
        {
          useHotelName &&
          <div className="gBCtRw astntNm">
            <div className="lt">
              <p className="title">Name of Business</p>
              <p className="desc">Set name of the business that ReviewResponseAI will use while generating responses</p>
              <div className="gIpt">
                <input
                  type="text"
                  value={hotelName}
                  onChange={onHotelNameChange}
                />
              </div>
              {
                hotelNameError &&
                <p className="gErr">Required</p>
              }
            </div>
          </div>
        }
      </>
    );
  };

  const renderHotelAddress = () => {
    return (
      <>
        { renderOnOff("Use Business Address", "useHotelAddress", useHotelAddress, "Address of business will be added to the generated response", "Address of business will not be added to the generated response", onUseHotelAddressChanged) }
        {
          useHotelAddress &&
          <div className="gBCtRw astntNm">
            <div className="lt">
              <p className="title">Hotel Address</p>
              <p className="desc">Enter address of the hotel that will give more context to ReviewResponseAI while generating responses</p>
              <div className="gIpt">
                <input
                  type="text"
                  value={hotelAddress}
                  onChange={onHotelAddressChange}
                />
              </div>
              {
                hotelAddressError &&
                <p className="gErr">Required</p>
              }
            </div>
          </div>
        }
      </>
    );
  };

  const renderSalution = () => {
    if (!useSalutation) return null;
    return (
      <div className="gBCtRw prmptMsg">
        <div className="lt">
          <p className="title">Salutation
            <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
              overlay="This salutation will be added to the generated response"
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </p>
          <p className="desc">Set salutation</p>
          <p className="maxLen">Max length: 30</p>
          <div className="gIpt">
            <textarea
              value={salutationMsg}
              onChange={onSalutationMsgChange}
              placeholder="Greetings..."
            />
          </div>
          {
            salutationMsgError &&
            <p className="gErr">Required</p>
          }
        </div>
      </div>
    );
  };

  const renderSalutationOnOff = () => {
    return (
      <div className="gBCtRw hasTgl noBtmMrgn">
        <div className="lt">
          <p className="title">Use salutation</p>
          <p className="desc">{ useSalutation ? "Salution will be added to the generated response" : "Salution will not be added to the generated response"}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="useSalutation" checked={useSalutation} onChange={onUseSalutionChanged}/><label htmlFor="useSalutation">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSignature = () => {
    if (!useSignature) return null;
    return (
      <div className="gBCtRw prmptMsg">
        <div className="lt">
          <p className="title">Signature
            <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
              overlay="This signature will be added to the generated response"
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </p>
          <p className="desc">Set signature</p>
          <p className="maxLen">Max length: 100</p>
          <div className="gIpt">
            <textarea
              value={signatureMsg}
              onChange={onSignatureMsgChange}
              placeholder="Regards..."
            />
          </div>
          {
            signatureMsgError &&
            <p className="gErr">Required</p>
          }
        </div>
      </div>
    );
  };

  const renderSignatureOnOff = () => {
    return (
      <div className="gBCtRw hasTgl noBtmMrgn usTpMrgn">
        <div className="lt">
          <p className="title">Use signature</p>
          <p className="desc">{ useSignature ? "Signature will be added to the generated response" : "Signature will not be added to the generated response"}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="useSignature" checked={useSignature} onChange={onUseSignatureChanged}/><label htmlFor="useSignature">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBrandToneOnOff = () => {
    return (
      <div className="gBCtRw hasTgl noBtmMrgn usTpMrgn">
        <div className="lt">
          <p className="title">Use Brand Tone</p>
          <p className="desc">{ useBrandTone ? "Brand tone will be used to generated response" : "Brand tone will not be used to generated response"}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="useBrandTone" checked={useBrandTone} onChange={onUseBrandToneChanged}/><label htmlFor="useBrandTone">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBrandTone = () => {
    if (!useBrandTone) return null;
    return (
      <div className="gBCtRw prmptMsg">
        <div className="lt">
          <p className="title">Brand Tone
            <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
              overlay="This brand tone will be applied to all generated responses"
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </p>
          <p className="desc">Set brand tone</p>
          <div className="gIpt">
            <textarea
              value={brandTone}
              onChange={onBrandToneChange}
              placeholder=""
            />
          </div>
        </div>
      </div>
    );
  };

  const renderToneOnOff = () => {
    return (
      <div className="gBCtRw hasTgl noBtmMrgn usTpMrgn">
        <div className="lt">
          <p className="title">Use Brand tone</p>
          <p className="desc">{ useTone ? "Brand tone will be used to generated response" : "Brand tone will not be used to generated response"}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="useTone" checked={useTone} onChange={onUseToneChanged}/><label htmlFor="useTone">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderTone = () => {
    if (!useTone) return null;
    return (
      <div className="gBCtRw toneSel">
        <div className="lt">
          <p className="title">Brand tone
            <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
              overlay="This tone will be applied to all generated responses"
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </p>
          <p className="desc">Set brand tone, select a maximum of 3 or set you own by selecting custom</p>
          {/*<div className="gIpt">
            <textarea
              value={brandTone}
              onChange={onBrandToneChange}
              placeholder=""
            />
          </div>*/}
        </div>
        <div className="rt">
          <Select
            className="dateFilterLastMonthSelect"
            isMulti
            options={toneSelectOptions}
            onChange={onToneSelectChange}
            value={toneSelectValues}
          />
          {
            toneSelectValues[0] && toneSelectValues[0].value === 'custom' &&
            <div className="gIpt">
              <input
                type="text"
                value={customTone}
                onChange={onCustomToneChange}
              />
            </div>
          }
          {
            customToneError &&
            <>
            { customToneError === 'error' &&
              <p className="gErr">Required</p>
            }
            { customToneError === 'maxError' &&
              <p className="gErr">Maximum of 3 allowed</p>
            }
            </>
          }
        </div>
      </div>
    );
  };


  const renderPromptTemplates = (rgLocationInfo) => {
    const prompt_templates = rgLocationInfo ? rgLocationInfo.prompt_templates || {} : {};
    let editAccess = true;
    return (
      <div className="prmptBx">
        <div className="ttl">
          <p className="title">Prompt Templates</p>
          <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
        </div>
        <div className="pmptList">
          {
            prompt_templates && prompt_templates.keyValue && prompt_templates.list && prompt_templates.list.length
            ?
              prompt_templates.list.map((item) => {
                if (!prompt_templates.keyValue[item] || prompt_templates.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    prompt_templates.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <PromptRow
                        key={`rg_p_${item}`}
                        promptType="regular"
                        template={prompt_templates.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <PromptRow
                    key={`rg_p_${item}`}
                    promptType="regular"
                    template={prompt_templates.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onDefaultClick={onRowDefaultClick}
                    onEnableDisableClick={onEnableDisableClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
        </div>

      {/*<GenericList
        type="promptTemplates"
        title="guestAI™ Templates"
        // location__id={urlParams.locationidentifier}
        generic_list={prompt_templates}
        response_template_categories={prompt_template_categories}
        settings={props.settings}
        hide_categories={true}
      />*/}
      </div>
    );
  };


  const renderFollowUpPromptTemplates = (rgLocationInfo) => {
    const followup_prompt_templates = rgLocationInfo ? rgLocationInfo.followup_prompt_templates || {} : {};
    let editAccess = true;
    return (
      <div className="prmptBx">
        <div className="ttl">
          <p className="title">Follow Up Templates</p>
          <span className="add" onClick={onFollowUpAddClick}><i className="fa fa-plus-circle" /></span>
        </div>
        <div className="pmptList">
          {
            followup_prompt_templates && followup_prompt_templates.keyValue && followup_prompt_templates.list && followup_prompt_templates.list.length
            ?
              followup_prompt_templates.list.map((item) => {
                if (!followup_prompt_templates.keyValue[item] || followup_prompt_templates.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    followup_prompt_templates.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <PromptRow
                        key={`rg_p_${item}`}
                        promptType="followup"
                        template={followup_prompt_templates.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onEnableDisableClick={onEnableDisableClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <PromptRow
                    key={`rg_p_${item}`}
                    promptType="followup"
                    template={followup_prompt_templates.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onEnableDisableClick={onEnableDisableClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
        </div>
      </div>
    );
  };


  const renderModal = (rgLocationInfo) => {
    return (
      <PromptSingle
        modalType="messageTemplateGeneric"
        className="mtGen"
        promptType="regular"
        rgLocationInfo={rgLocationInfo}
        isRgLocationDefault={urlParams.rglocationidentifier === "default"}
        template={{
          ...selectedTemplate,
          // opName: modalType,
        }}
        type={modalType}
        setModalVisible={onCloseModal}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  }

  const renderFollowUpModal = (rgLocationInfo) => {
    return (
      <PromptSingle
        modalType="messageTemplateGeneric"
        className="mtGen"
        promptType="followup"
        rgLocationInfo={rgLocationInfo}
        isRgLocationDefault={urlParams.rglocationidentifier === "default"}
        template={{
          ...selectedFollowUpTemplate,
          // opName: modalType,
        }}
        type={followUpModalType}
        setModalVisible={onFollowUpCloseModal}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  }


  if (process.env.NODE_ENV !== 'production') {
    console.log('General rglocationidentifier: ', urlParams.rglocationidentifier);
    console.log('General: ', props, ' # ', props);
    console.log('General toneSelectValues: ', toneSelectValues);
  }

  const { locations } = responseGeneratorState;
  if (process.env.NODE_ENV !== 'production') {
    console.log('General locations: ', locations);
  }
  if (!locations) return null;
  if (!urlParams.rglocationidentifier) return null;
  const rgLocationInfo = locations.keyValue ? locations.keyValue[urlParams.rglocationidentifier] || {} : {};

  const promptTemplateEditInProgress = rgLocationInfo.prompt_templates &&
    rgLocationInfo.prompt_templates.keyValue &&
    rgLocationInfo.prompt_templates.keyValue[selectedTemplate._id]
    ? rgLocationInfo.prompt_templates.keyValue[selectedTemplate._id].editInProgress
    : false
  const followUpPromptTemplateEditInProgress = rgLocationInfo.followup_prompt_templates &&
    rgLocationInfo.followup_prompt_templates.keyValue &&
    rgLocationInfo.followup_prompt_templates.keyValue[selectedTemplate._id]
    ? rgLocationInfo.followup_prompt_templates.keyValue[selectedTemplate._id].editInProgress
    : false

  let locatioName = rgLocationInfo.name || '';
  if (rgLocationInfo.is_default) locatioName = 'Default';

  return (
    <>
      {
        rgLocationInfo && rgLocationInfo.editInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp hDGen flHt wCBasic wCGen">
        <div className="gPWrp hDGen rgGen">
          <div className="gPTle title">
            {
              (props.isReadOnly || rgLocationInfo.is_default)
              ? locatioName
              :
              <input type="text" value={name} onChange={onNameChange}
                readOnly={userState.user.user_info.profile &&
                  userState.user.user_info.profile.rg_access_level !== ACCESS_LEVELS.RG_ADMIN
                }
              />
            }
            {props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
            <div className="rt">
              {
                !rgLocationInfo.is_default && userState.user.user_info.profile &&
                userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN &&
                <div className="gToggleWrap">
                  <div className="gToggle">
                    <input type="checkbox" id={`toggleOn_${rgLocationInfo._id}`} checked={toggleOn} onChange={onToggleChange}/><label htmlFor={`toggleOn_${rgLocationInfo._id}`}>Toggle</label>
                  </div>
                </div>
              }
              {
                !rgLocationInfo.is_default && userState.user.user_info.profile &&
                userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN &&
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay="Delete">
                  <span onClick={onLocDeleteClick}><img className="delete" src={deleteIcon} /></span>
                </ToolTipWrapper>
              }
            </div>
          </div>
          <div className="gBCtnt">
            {/* !rgLocationInfo.is_default && renderName() */}
            { renderTypeOfBusiness() }
            { renderHotelName() }
            { renderHotelAddress() }
            { renderSalutationOnOff() }
            { renderSalution() }
            { renderSignatureOnOff() }
            { renderSignature() }
            {/* renderBrandToneOnOff() }
            { renderBrandTone() */}
            { renderToneOnOff() }
            { renderTone() }
            { renderPromptTemplates(rgLocationInfo) }
            { renderFollowUpPromptTemplates(rgLocationInfo) }
            { (hotelNameError || hotelAddressError || salutationMsgError || signatureMsgError) &&
              <p className="gErr">Check errors above</p>
            }
            {
              !props.isReadOnly &&
              <div className="gBCtRw btnRw">
                <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                  Save Changes
                  {
                    rgLocationInfo && rgLocationInfo.editInProgress && <i className="fa fa-spinner fa-spin" />
                  }
                </button>
              </div>
            }
            {
              props.isReadOnly &&
              <div className="gBCtRw rdOlyRw">
                <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
              </div>
            }
          </div>
        </div>
        { showModal && renderModal(rgLocationInfo) }
        { showDeleteModal &&
          <GenericAlertModal
            modalType="Delete Template"
            bodyRowOne={`Do you want to delete this template (${selectedTemplate.title}) ? This action cannot be undone!`}
            bodyRowNote={'You’ll not be able to retrieve the contents later.'}
            keyName={selectedTemplate.title}
            setModalVisible={setShowDeleteModal}
            onAffirmativeClick={onDeleteConfirmClick}
            inProgress={selectedTemplate.type === 'followup' ? followUpPromptTemplateEditInProgress : promptTemplateEditInProgress}
            showCancel={true}
          />
        }
        { showDefaultModal &&
          <GenericAlertModal
            modalType="Default Template"
            bodyRowOne={`Do you want to ${selectedTemplate.is_default ? 'unmark' : 'mark'} this template (${selectedTemplate.title}) as default ?`}
            bodyRowNote={selectedTemplate.is_default ? 'You can undo this action later.' : 'Only one template can be marked as Default. You can undo this action later.'}
            keyName={selectedTemplate.title}
            setModalVisible={setShowDefaultModal}
            onAffirmativeClick={onDefaultConfirmClick}
            inProgress={promptTemplateEditInProgress}
            showCancel={true}
          />
        }
        { showFollowUpModal && renderFollowUpModal(rgLocationInfo) }
        { rgLocationInfo._id && showLocDeleteModal &&
          <GenericAlertModal
            modalType="Delete Location"
            bodyRowOne={`Do you want to delete this location (${rgLocationInfo.name}) ? This action cannot be undone!`}
            bodyRowNote={'You’ll not be able to retrieve the contents later.'}
            keyName={rgLocationInfo.name}
            setModalVisible={setShowLocDeleteModal}
            onAffirmativeClick={onLocDeleteConfirmClick}
            inProgress={rgLocationInfo.editInProgress}
            showCancel={true}
          />
        }
        { rgLocationInfo._id && showLocCantDeleteModal &&
          <GenericAlertModal
            modalType="Delete Location"
            title={'Cannot delete!'}
            bodyRowOne={`Cannot delete the last location (${rgLocationInfo.name}).`}
            bodyRowNote={'Atleast one location should be present'}
            keyName={rgLocationInfo.name}
            affirmativeButtonLabel="Ok"
            setModalVisible={setShowLocCantDeleteModal}
            onAffirmativeClick={onLocCantDeleteConfirmClick}
            inProgress={rgLocationInfo.editInProgress}
            showCancel={false}
          />
        }
        { rgLocationInfo._id && showLocCantDisableModal &&
          <GenericAlertModal
            modalType="Delete Location"
            title={'Cannot disable!'}
            bodyRowOne={`Cannot disable the last location (${rgLocationInfo.name}).`}
            bodyRowNote={'Atleast one location should be enabled'}
            keyName={rgLocationInfo.name}
            affirmativeButtonLabel="Ok"
            setModalVisible={setShowLocCantDisableModal}
            onAffirmativeClick={onLocCantDisableConfirmClick}
            inProgress={rgLocationInfo.editInProgress}
            showCancel={false}
          />
        }
      </div>
    </>
  );
}

export default General;
