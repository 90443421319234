import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Interweave } from 'interweave';
import copy from 'copy-to-clipboard';
import linkifyStr from 'linkify-string';
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import ImagePreview from '../../../../../Misc/ImagePreview';

import { receiveSnack, removeSnack } from '../../../../../../redux/actions/snacksActions';

import { formatDateChange, formatMessageDate, isEmojiOnlyMessage, isBMImageUrl, LANGUAGE_LIST_KEYS } from '../../../../../../helpers';

import './style.css';

import whatsappIcon from '../../../../../../img/icons/whatsapp.png';
import expediaIcon from '../../../../../../img/icons/expedia.png';
import smsIcon from '../../../../../../img/icons/sms.svg';
import emailIcon from '../../../../../../img/icons/email.svg';
import googleIcon from '../../../../../../img/icons/google-logo.png';
import IconDelivered from '../../../../../../img/icons/iconDelivered.png';

const SHOW_MORE_LESS_THRESHOLD = 10;

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function MessageReceived(props) {
  // const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  // let urlParams = useParams();

  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const imagePreviewModalRef = useRef();


  useEffect(() => {
    let newlineCount = 0;
    if (props.message.medium === 'email') {
      if (props.message.msg) newlineCount = props.message.msg.split('\n').length;
      // if (!props.message.msg && props.message.msg_text_html) newlineCount = props.message.msg_text_html.split('\n').length;
    }
    if (newlineCount > SHOW_MORE_LESS_THRESHOLD) setIsMoreOpen(false);
    if (props.message.medium === 'email') {
      if (!props.message.msg && props.message.msg_text_html) setIsMoreOpen(true);
    }
  }, []);

  const onShowMoreClick = (e) => {
    setIsMoreOpen(true);
  };
  const onShowLessClick = (e) => {
    setIsMoreOpen(false);
  };

  const onTranslateMessageClick = (e) => {
    // props.dispatch(translateMessageAction({
    //   user: props.userprops.user,
    //   params: {
    //     message_id: props.message._id,
    //     guest_id: props.message.guest_id || undefined,
    //     outside_guest_id: props.message.outside_guest_id || undefined,
    //     guest_type: props.guestType,
    //     text: props.message.msg,
    //     to_lang: props.locationprops.langSettings.conversation_lang, // 'fr',
    //   },
    // }));
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Translate - Received Message`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };


  const onCopyToClipboard = (e) => {
    copy(props.message.msg);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Copy - Sent Message`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onShowImagePreviewClick = (url) => {
    setImagePreviewUrl(url);
    setShowImagePreviewModal(true);
  };
  const onImagePreviewModalCloseClick = (e) => {
    setImagePreviewUrl('')
    setShowImagePreviewModal(false);
  };



  const renderBMMessage = (message) => {
    if (message.msg && isBMImageUrl(message.msg)) {
      return <img src={message.msg} />;
    }
    return message.msg;
  };

  const renderImagePreviewModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal imgPrv">
          <div className="ctnt" ref={imagePreviewModalRef}>
            <ImagePreview
              image={imagePreviewUrl}
              onConfirmClick={onImagePreviewModalCloseClick}
              hideConfirm={{}}
              onCancelClick={onImagePreviewModalCloseClick}
              inProgress={false}
              uploadError={''}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderContent = () => {
    const { message, dateChange, dateChangePosition, isLast, avataarName } = props;
    if (!message) return null;

    const isEmojiOnly = message.emoji_only_processed ? message.emoji_only : isEmojiOnlyMessage(message.msg);
    let isMenuSingle = isEmojiOnly;
    // if (props.locationprops.langSettings.conversation_lang === message.lang) {
    //   isMenuSingle = true;
    // } // nkcheck
    const isImage = message.bm_message && isBMImageUrl(message.msg);
    const hasIncomingMedia = message.media && message.media.length;
    let newlineCount = 0;
    if (message.medium === 'email') {
      if (message.msg) newlineCount = message.msg.split('\n').length;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('newlineCount: ', newlineCount);
    }

    let msgTextHtml = '';
    try {
      if (!message.msg && message.msg_text_html) {
        msgTextHtml = message.msg_text_html;
        if (msgTextHtml.indexOf('<body') > -1) {
          msgTextHtml = msgTextHtml.split('<body')[1];
          msgTextHtml = msgTextHtml.substring(msgTextHtml.indexOf('>')+1);
        }
        if (msgTextHtml.indexOf('</body>') > -1) {
          msgTextHtml = msgTextHtml.split('</body>')[0];
        }
      }
    } catch (e) {

    }

    return (
      <>
        {
          dateChange && dateChangePosition === 'bottom' &&
          <div className="dtCh">
            <div className="line" /><p>{formatDateChange(dateChange)}</p><div className="line" />
          </div>
        }
        <div className={'rcMB ' + (isLast ? 'latest' : '') + (isMenuSingle ? 'menuSingleOption ' : '')} key={message._id}>
          <div className="avtB">
            {avataarName || <i className="fa fa-user" /> }
          </div>
          <div className="msB">
            <div className="msg">
              <div className={'inner'}>
                {
                  message.medium === 'email'
                  ?
                    <p className={'orig email' + (isMoreOpen ? '' : ' showLess')} id={`msg__${message._id}`}>
                      {message.msg_subject && <span className="subject">Sub: {message.msg_subject}</span> }
                      {
                        message.msg &&
                        <Interweave content={linkifyStr(message.msg, { nl2br: true })} />
                      }
                      {
                        !message.msg && message.msg_text_html && msgTextHtml &&
                        <Interweave content={msgTextHtml} />
                      }
                    </p>
                  :
                    <p className="orig" id={`msg__${message._id}`}>
                      {
                        message.bm_message
                        ? renderBMMessage(message)
                        : message.msg && <Interweave content={message.msg} />
                      }
                    </p>
                }
                {/*
                  message.lang !== 'en' &&
                  <>
                    <p className="by">Translated by Google to English</p>
                    <p className="translated" id={`msg__tr_${message._id}`}>{message.msg__en}</p>
                  </>
                */}
                {
                  Object.keys(message).map((el) => {
                    if (el.indexOf('msg__') === 0) {
                      let lang = el.split('msg__')[1];
                      return (
                        <>
                          <p className="by">Translated by Google from {LANGUAGE_LIST_KEYS[message.lang]} to {LANGUAGE_LIST_KEYS[lang]} </p>
                          <p className="translated" id={`msg__tr_${message._id}`}>{message[el]}</p>
                        </>
                      )
                    }
                    return null;
                  })
                }
                {
                  message.media && Array.isArray(message.media) && message.media.length
                  ?
                    message.media.map((m, idx) => {
                      return (
                        <div className="imgEntry">
                          <img key={`e_ms_img_${idx}`} className="incomingMedia" src={m.url} onClick={() => onShowImagePreviewClick(m.url)} />
                        </div>
                      );
                    })
                  : null
                }
                {/*
                  message.media && message.media.length
                  ?
                    message.media.map((m) => {
                      return (
                        <SimpleReactLightbox>
                        <SRLWrapper
                          options={{
                            thumbnails: {showThumbnails:false},
                            buttons: {
                              showAutoplayButton: false,
                              showDownloadButton: false,
                              showFullscreenButton: false,
                              showThumbnailsButton: false,
                              showNextButton: false,
                              showPrevButton: false,
                            },
                          }}
                        >
                          <img className="incomingMedia" src={m.url} />
                        </SRLWrapper>
                        </SimpleReactLightbox>
                      );
                    })
                  : null
                */}
                {
                  newlineCount > SHOW_MORE_LESS_THRESHOLD &&
                  <>
                    {
                      !isMoreOpen
                      ? <p className="showMoreLess" onClick={onShowMoreClick}>Show More</p>
                      : <p className="showMoreLess" onClick={onShowLessClick}>Show Less</p>
                    }
                  </>
                }
              </div>
              {
                (!isImage && !hasIncomingMedia) &&
                <div className="dd">
                  <button className="dbtn"><i className="fa fa-caret-down" /></button>
                  <div className="ddc">
                    {/*
                      !isEmojiOnly && props.locationprops.langSettings.conversation_lang !== message.lang &&
                      !message[`msg__${props.locationprops.langSettings.conversation_lang}`] &&
                      <p onClick={onTranslateMessageClick}>Translate to {LANGUAGE_LIST_KEYS[props.locationprops.langSettings.conversation_lang]}</p>
                    // nkcheck */}
                    <p onClick={onCopyToClipboard}>Copy</p>
                  </div>
                </div>
              }
            </div>
            <div className="mstB">
              <div className="dateMessage">
                {formatMessageDate(message.date_created)}
                {
                  message.medium && message.medium === 'whatsapp' && <img src={whatsappIcon} title="WhatsApp" />
                }
                {
                  message.medium && message.medium === 'expedia' && <img src={expediaIcon} title="Expedia" />
                }
                {
                  message.medium && message.medium === 'email' && <span><i className="fa-solid fa-envelope"></i></span>
                }
                {/*
                  message.medium && message.medium === 'bm' && <img src={googleIcon} className="gbm" title="Google Business Message" />
                */}
                {
                  !message.medium && !message.from_webapp && <img src={smsIcon} title="SMS" />
                }
                {
                  message.from_webapp &&

                    <div className="mdm"><i className="fa-solid fa-mobile-screen-button"></i><span>Digital Guide</span></div>
                }
              </div>
            </div>
          </div>
          <div className="leftOverBox">
          </div>
        </div>
        {
          props.dateChange && props.dateChangePosition === 'top' &&
          <div className="dtCh">
            <div className="line" /><p>{formatDateChange(props.dateChange)}</p><div className="line" />
          </div>
        }
        { showImagePreviewModal && renderImagePreviewModal() }
      </>
    );
  };

  return renderContent();
}

export default MessageReceived;
