import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import emojiIcon from '../../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import './style.css';

function WCQuerySingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  useEffect(() => {
    if (props.query && props.query._id) {
      setTitle(props.query.question);
      setMessage(props.query.answer);
    }
    setSomeChange(false);
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'add-question',
    };
    if (props.type === 'edit') {
      params.op = 'edit-question';
      params.question_id = props.query._id;
    }
    dispatch(editDataClearAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  }, []);

  useEffect(() => {
    const { webchat } = props.settings;
    if (props.type === 'edit') {
      if (props.query && props.query._id &&
        webchat && webchat.help_guide && webchat.help_guide.questions &&
        webchat.help_guide.questions.keyValue &&
        webchat.help_guide.questions.keyValue[props.query._id] &&
        !webchat.help_guide.questions.keyValue[props.query._id].editInProgress &&
        webchat.help_guide.questions.keyValue[props.query._id].editStatus &&
        webchat.help_guide.questions.keyValue[props.query._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (webchat && webchat.help_guide && webchat.help_guide.questions &&
        !webchat.help_guide.questions.addInProgress &&
        webchat.help_guide.questions.addStatus &&
        webchat.help_guide.questions.addStatus.success
      ) {
        setTitle('');
        setMessage('');
        setSomeChange(false);
      }
    }
  }, [props.settings.webchat]);

  const clearAction = () => {
    const params = {
      location__id: urlParams.locationidentifier,
      op: 'add-question',
    };
    if (props.type === 'edit') {
      params.op = 'edit-question';
      params.question_id = props.query ? props.query._id : '';
    }
    dispatch(editDataClearAction({
      op: 'edit_webchat_helpguide',
      params,
    }));
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    clearAction();
    setTitle(value);
    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
    setSomeChange(true);
  };

  const onMessageChange = (e) => {
    const { name, value } = e.target;
    clearAction();
    setMessage(value);
    if (!value) {
      setMessageError('error');
    } else {
      setMessageError('');
    }
    setSomeChange(true);
  };


  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    const { webchat } = props.settings;
    let addEditInProgress = webchat && webchat.help_guide && webchat.help_guide.questions ? webchat.help_guide.questions.addInProgress : false;
    if (props.type === 'edit') {
      if (props.query && props.query._id) {
        addEditInProgress = props.query.editInProgress;
      }
    }
    if (addEditInProgress) return;

    clearAction();

    setTitleError(title ? '' : 'error');
    setMessageError(message ? '' : 'error');


    if (someChange) {
      if (props.type === 'add') {
        if (title && message) {
          const params = {
            location__id: urlParams.locationidentifier,
            op: 'add-question',
            question_data: {
              topic_id: props.topic_id,
              question: title,
              answer: message,
            },
          };
          dispatch(editDataAction({
            op: 'edit_webchat_helpguide',
            params,
          }));
        }
        setSomeChange(false);
      }
      if (props.type === 'edit') {
        if (title && message) {
          const params = {
            location__id: urlParams.locationidentifier,
            op: 'edit-question',
            question_id: props.query._id,
            question_data: {
              question: title,
              answer: message,
            },
          };
          dispatch(editDataAction({
            op: 'edit_webchat_helpguide',
            params,
          }));
          setSomeChange(false);
        }
      }
    }
  };

  const renderContent = () => {
    const { webchat } = props.settings;

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Query`;
    if (props.type === 'edit') pageTitle = 'Edit Query';
    if (props.title) pageTitle = props.title;


    let addEditInProgress = webchat && webchat.help_guide && webchat.help_guide.questions ? webchat.help_guide.questions.addInProgress : false;
    let addEditStatus = webchat && webchat.help_guide && webchat.help_guide.questions ? webchat.help_guide.questions.addStatus || {} : {};
    if (props.type === 'edit') {
      if (props.query && props.query._id) {
        addEditInProgress = props.query.editInProgress;
        addEditStatus = props.query.editStatus;
      }
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('WCQuerySingle addEditInProgress: ', addEditInProgress);
      console.log('WCQuerySingle addEditStatus: ', addEditStatus);
    }

    return (
      <div className="gPWrp roomSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Question
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Question" />
                { titleError && <p className="gErr">Question is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Answer
              </div>
              <div className={'gIpt ' + (messageError ? 'error' : '')}>
                <textarea value={message} onChange={onMessageChange} placeholder="Answer" rows="4" />
                { messageError && <p className="gErr">Answer is required</p> }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('WCQuerySingle props: ', props);
  }

  return renderContent();
}

export default WCQuerySingle;
