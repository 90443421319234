import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import NumberCard from '../../../Misc/NumberCard';
import MixedCard from '../../../Misc/MixedCard';
import RecentReviewCard from './RecentReviewCard';
import ConnectPMSModal from './ConnectPMSModal';
import ConnectReviewsWidgetModal from './ConnectReviewsWidgetModal';
import SingleReview from '../Reviews/SingleReview';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { addReviewToReviewsAction } from '../../../../redux/actions/miscReputationActions';

import { DATE_DURATION_OPTIONS_MAP, OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  renderOTALogo, getUserGAId, changeInValue } from '../../../../helpers';

import './style.css';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

function Dashboard(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [showConnectPMSModal, setShowConnectPMSModal] = useState(false);
  const [showConnectReviewsWidgetModal, setShowConnectReviewsWidgetModal] = useState(false);

  const [showSingleReviewModal, setShowSingleReviewModal] = useState(false);
  const [singleReviewData, setSingleReviewData] = useState({});
  const singleReviewModalRef = useRef();

  const [compareNow, setCompareNow] = useState(false);
  const [compareDateFilterStartDate, setCompareDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [compareDateFilterEndDate, setCompareDateFilterEndDate] = useState(new Date());

  const getDashboardData = (startDate, endDate, compareStartDate, compareEndDate) => {
    const params = {
      location__id: urlParams.locationidentifier,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };
    if (compareStartDate && compareEndDate) {
      params.compare_start_date = moment(compareStartDate).format('YYYY-MM-DD');
      params.compare_end_date = moment(compareEndDate).format('YYYY-MM-DD');
    }
    dispatch(getDataAction({
      op: 'get_dashboard_data',
      params,
      // params: {
      //   location__id: urlParams.locationidentifier,
      //   start_date: moment(startDate).format('YYYY-MM-DD'),
      //   end_date: moment(endDate).format('YYYY-MM-DD'),
      //   compare_start_date: moment(compareDateFilterStartDate).format('YYYY-MM-DD'),
      //   compare_end_date: moment(compareDateFilterEndDate).format('YYYY-MM-DD'),
      // },
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Dashboard - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Reputation - Dashboard - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    let dateUpdated = commonParams ? commonParams.dateUpdated || new Date(moment().subtract(3, 'days')) : new Date(moment().subtract(3, 'days'));
    if (moment().diff(dateUpdated, 'hours') > 24) {
      startDate = new Date(moment().subtract(1, 'month'));
      endDate = new Date();
    }
    
    if (process.env.NODE_ENV !== 'production') {
      console.log('Dashboard commonParams: ', commonParams);
    }
    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
    getDashboardData(startDate, endDate, null, null);

  }, []);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      if (compareNow) {
        const cmpDtStart = new Date(moment(moment().subtract(period, 'days')).subtract(period, 'days'));
        const cmpDtEnd = new Date(moment().subtract(period, 'days'));
        setCompareDateFilterStartDate(cmpDtStart)
        setCompareDateFilterEndDate(cmpDtEnd)
        getDashboardData(new Date(moment().subtract(period, 'days')), new Date(), cmpDtStart, cmpDtEnd);
      } else {
        getDashboardData(new Date(moment().subtract(period, 'days')), new Date(), null, null);
      }
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      if (compareNow) {
        const cmpDtStart = new Date(moment(moment().subtract(period, 'month')).subtract(period, 'month'));
        const cmpDtEnd = new Date(moment().subtract(period, 'month'));
        setCompareDateFilterStartDate(cmpDtStart)
        setCompareDateFilterEndDate(cmpDtEnd)
        getDashboardData(new Date(moment().subtract(period, 'month')), new Date(), cmpDtStart, cmpDtEnd);
      } else {
        getDashboardData(new Date(moment().subtract(period, 'month')), new Date(), null, null);
      }
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Dashboard - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Dashboard - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier,
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { dashboard } = props.reputation;
    if (!dashboard || dashboard.getInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
        dateUpdated: new Date(),
      },
    }));
    getDashboardData(dates[0], dates[1], null, null);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Dashboard - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Dashboard - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier,
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onCompareDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCompareDateRangeChange dates: ', dates);
      console.log('onCompareDateRangeChange dateStrings: ', dateStrings);
    }
    const { dashboard } = props.reputation;
    if (!dashboard || dashboard.getInProgress) return;
    // setDateRangeActive(true);
    setCompareDateFilterStartDate(new Date(dates[0]));
    setCompareDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    getDashboardData(dateFilterStartDate, dateFilterEndDate, dates[0], dates[1]);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Dashboard - Compare Date Change Range`,
        'event_label': `Compare Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Dashboard - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier,
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onKnowMoreConnectPmsClick = (e) => {
    setShowConnectPMSModal(true);
  };
  const onConnectPmsCloseModalClick = (e) => {
    setShowConnectPMSModal(false);
  };
  const onConnectPmsCtaClick = (e) => {

  };
  const onKnowMoreConnectReviewsWidgetClick = (e) => {
    setShowConnectReviewsWidgetModal(true);
  };
  const onConnectReviewsWidgetCloseModalClick = (e) => {
    setShowConnectReviewsWidgetModal(false);
  };
  const onConnectReviewsWidgetCtaClick = (e) => {

  };

  const onReviewCardMoreClick = (review) => {
    dispatch(addReviewToReviewsAction({
      params: {
        location__id: urlParams.locationidentifier,
        review,
      },
    }));
    setShowSingleReviewModal(true);
    setSingleReviewData(review);
  };
  const onSingleReviewModalCloseClick = (e) => {
    setShowSingleReviewModal(false);
    setSingleReviewData({});
  };

  const handleCompareClick = (compareNowValue) => {
    if (compareNowValue) { // value is reversed
      // setCompareDateFilterStartDate(moment(dateFilterStartDate).subtract(1, 'month'));
      // setCompareDateFilterEndDate(moment(dateFilterEndDate).subtract(1, 'month'));
      // getDashboardData(dateFilterStartDate, dateFilterEndDate, moment(dateFilterStartDate).subtract(1, 'month'), moment(dateFilterEndDate).subtract(1, 'month'));

      const rangeValue = dateRangeLastMonthValue.value;
      if (rangeValue.indexOf('-day') > -1) {
        let period = rangeValue.split('-day')[0];
        if (period.indexOf('last-') > -1) period = period.split('last-')[1];
        setCompareDateFilterStartDate(new Date(moment(dateFilterStartDate).subtract(period, 'days')))
        setCompareDateFilterEndDate(new Date(moment(dateFilterEndDate).subtract(period, 'days')))
        getDashboardData(dateFilterStartDate, dateFilterEndDate, new Date(moment(dateFilterStartDate).subtract(period, 'days')), new Date(moment(dateFilterEndDate).subtract(period, 'days')));
      }
      if (rangeValue.indexOf('-month') > -1) {
        let period = rangeValue.split('-month')[0];
        if (period.indexOf('last-') > -1) period = period.split('last-')[1];
        setCompareDateFilterStartDate(new Date(moment(dateFilterStartDate).subtract(period, 'month')))
        setCompareDateFilterEndDate(new Date(moment(dateFilterEndDate).subtract(period, 'month')))
        getDashboardData(dateFilterStartDate, dateFilterEndDate, new Date(moment(dateFilterStartDate).subtract(period, 'month')), new Date(moment(dateFilterEndDate).subtract(period, 'month')));
      }
    }
  }

  const onCompareTextClick = (e) => {
    setCompareNow(compareNow => !compareNow);
    handleCompareClick(!compareNow);
  };

  const onCompareNowChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCompareNowChange: ', value);
      console.log('onCompareNowChange dateRangeLastMonthValue: ', dateRangeLastMonthValue);
    }
    setCompareNow(compareNow => !compareNow);
    handleCompareClick(!compareNow);
    // if (value === 'off') { // value is reversed
    //   // setCompareDateFilterStartDate(moment(dateFilterStartDate).subtract(1, 'month'));
    //   // setCompareDateFilterEndDate(moment(dateFilterEndDate).subtract(1, 'month'));
    //   // getDashboardData(dateFilterStartDate, dateFilterEndDate, moment(dateFilterStartDate).subtract(1, 'month'), moment(dateFilterEndDate).subtract(1, 'month'));

    //   const rangeValue = dateRangeLastMonthValue.value;
    //   if (rangeValue.indexOf('-day') > -1) {
    //     let period = rangeValue.split('-day')[0];
    //     if (period.indexOf('last-') > -1) period = period.split('last-')[1];
    //     setCompareDateFilterStartDate(new Date(moment(dateFilterStartDate).subtract(period, 'days')))
    //     setCompareDateFilterEndDate(new Date(moment(dateFilterEndDate).subtract(period, 'days')))
    //     getDashboardData(dateFilterStartDate, dateFilterEndDate, new Date(moment(dateFilterStartDate).subtract(period, 'days')), new Date(moment(dateFilterEndDate).subtract(period, 'days')));
    //   }
    //   if (rangeValue.indexOf('-month') > -1) {
    //     let period = rangeValue.split('-month')[0];
    //     if (period.indexOf('last-') > -1) period = period.split('last-')[1];
    //     setCompareDateFilterStartDate(new Date(moment(dateFilterStartDate).subtract(period, 'month')))
    //     setCompareDateFilterEndDate(new Date(moment(dateFilterEndDate).subtract(period, 'month')))
    //     getDashboardData(dateFilterStartDate, dateFilterEndDate, new Date(moment(dateFilterStartDate).subtract(period, 'month')), new Date(moment(dateFilterEndDate).subtract(period, 'month')));
    //   }
    // }
    
  };


  const renderCurrentRatingBox = (dashboard) => {
    if (!dashboard || !dashboard.current_ratings) return null;
    const { otas } = props.locationInfo;
    return (
      <div className="curRtnBx">
        <div className="inrCrd">
          <div className="tp">
            <p>
              Current Rating
              <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
                overlay={'Current Rating across OTAs'}
              >
                <i className="fas fa-info-circle" />
              </ToolTipWrapper>
            </p>
          </div>
          <div className="middle">
            <div className="lt">
              <p>{dashboard.getInProgress ? <span><i className="fa fa-circle-notch fa-spin" /></span> : dashboard.current_ratings.overall}</p>
              {
                compareNow && !dashboard.getInProgress && dashboard.compare_current_ratings && dashboard.compare_current_ratings.overall
                ?
                <>
                  <p className="cmpVal"><span>Vs</span> {dashboard.compare_current_ratings.overall}</p>
                  {
                    !dashboard.getInProgress && dashboard.current_ratings.overall && dashboard.compare_current_ratings.overall
                    ?
                    <p className="cmpChng">{changeInValue(dashboard.current_ratings.overall, dashboard.compare_current_ratings.overall)}</p>
                    : null
                  }
                </>
                : null
              }
            </div>
            <div className="rt">
              {
                !dashboard.getInProgress && dashboard.current_ratings && dashboard.current_ratings.otas && Object.keys(dashboard.current_ratings.otas).length
                ?
                  OTA_ORDER.map((ota) => {
                  // Object.keys(otas).map((ota) => {
                    if (dashboard.current_ratings.otas[ota] && OTA_NAME_TO_SOURCE_MAP[ota]) { // && otas[ota].is_enabled
                      return (
                        <div className="otaEntry" key={`d_cr_${ota}`}>
                          { renderOTALogo(OTA_NAME_TO_SOURCE_MAP[ota]) }
                          <p>{dashboard.current_ratings.otas[ota] || '-'}</p>
                          {
                            compareNow && dashboard.compare_current_ratings && dashboard.compare_current_ratings.otas && dashboard.compare_current_ratings.otas[ota]
                            ?
                            <>
                              <p className="cmpVal"><span>vs</span> {dashboard.compare_current_ratings.otas[ota]}</p>
                              {
                                dashboard.current_ratings.otas[ota] &&
                                dashboard.compare_current_ratings.otas[ota] && dashboard.current_ratings.otas[ota]
                                ?
                                <p className="cmpChng">{changeInValue(dashboard.current_ratings.otas[ota], dashboard.compare_current_ratings.otas[ota])}</p>
                                : null
                              }
                            </>
                            : null
                          }
                          <p>{OTA_ID_TO_NAME_FORMAL_MAP[ota]}</p>
                        </div>
                      );
                    }
                    return null;
                  })
                : null
              }
            </div>
          </div>
          {/*<div className="footer">
            <p>See All</p>
          </div>*/}
        </div>
      </div>
    );
  };

  const renderDateFilterRow = (statsObj) => {
    return (
      <div className="dtFltBx repDash">
        <div className="left">
          <div className="title">
            Dashboard
          </div>
        </div>
        <div className="dateFilter">
          <div className="dtCmpr">
            <span onClick={onCompareTextClick}>Compare</span>
            <input type="checkbox" name="compareNow" onChange={onCompareNowChange} value={compareNow ? 'on' : 'off'} checked={compareNow} />
          </div>
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCompareDateFilterRow = (statsObj) => {
    if (!compareNow) return null;
    return (
      <div className="dtFltBx dtCmp">
        <div className="dateFilter">
          <div className="cmpTxt">
            Compare to
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(compareDateFilterStartDate), moment(compareDateFilterEndDate)]}
              onChange={onCompareDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSplLoader = () => <span><i className="fa fa-circle-notch fa-spin" /></span>;

  const renderStatsBox = (dashboard) => {
    if (!dashboard || !dashboard.stats) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="dshStatBx">
        <NumberCard
          data={{
            label: 'Total Reviews',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.review_count,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats ? dashboard.compare_stats.review_count || '' : '',
            labelToolTip: 'Total Reviews',
            ctaLabel: 'See All',
            ctaLink: `/reputation/locations/${locationidentifier}/reviews`,
            getInProgress: dashboard.getInProgress,
          }}
          // onCtaClick={onCheckinsClick}
        />
        {
          dashboard.stats.reviews_per_room &&
          <NumberCard
            data={{
              label: 'Reviews per room',
              value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.reviews_per_room,
              compareNow,
              cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats ? dashboard.compare_stats.reviews_per_room || '' : '',
              labelToolTip: 'Reviews per room',
              ctaLabel: 'See All',
              ctaLink: `/reputation/locations/${locationidentifier}/reviews`,
              getInProgress: dashboard.getInProgress,
            }}
            // onCtaClick={onCheckinsClick}
          />
        }
        <NumberCard
          data={{
            label: 'Overall Rating',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.average_rating,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats ? dashboard.compare_stats.average_rating || '' : '',
            labelToolTip: 'Overall rating across all OTAs for the selected period',
            getInProgress: dashboard.getInProgress,
          }}
        />
        <NumberCard
          data={{
            label: 'Sentiment',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.sentiment_score,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats ? dashboard.compare_stats.sentiment_score || '' : '',
            labelToolTip: 'Sentiment score for the selected period',
            ctaLabel: 'See All',
            ctaLink: `/reputation/locations/${locationidentifier}/sentiments`,
            getInProgress: dashboard.getInProgress,
          }}
          // onCtaClick={onRepeatsClick}
        />
        <NumberCard
          data={{
            label: 'Response Rate',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.response_rate,
            compareNow,
            labelToolTip: 'Percentage of reviews which have been responded',
            // ctaLabel: 'See All',
            getInProgress: dashboard.getInProgress,
          }}
        />
      </div>
    );
  };

  const renderStatsSecondaryBox = (dashboard) => {
    if (!dashboard || !dashboard.stats) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="dshStatSecBx">
        <MixedCard
          data={{
            label: 'Negative feedbacks prevented',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.negative_feedback_prevented,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats ? dashboard.compare_stats.negative_feedback_prevented || '' : '',
            labelToolTip: 'Number of negative reviews prevented on OTAs',
            ctaLabel: 'See All',
            ctaLink: `/reputation/locations/${locationidentifier}/feedbacks/overview?positive_negative=negative`,
            getInProgress: dashboard.getInProgress,
          }}
          // onCtaClick={onNegativeFeedbacksClick}
        />
        <MixedCard
          data={{
            label: 'Review widget impressions',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.review_widget_impressions,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats ? dashboard.compare_stats.review_widget_impressions || '' : '',
            labelToolTip: 'Review widget impressions',
            getInProgress: dashboard.getInProgress,
          }}
        />
      </div>
    );
  };

  const renderApprovalPendingReviews = (dashboard) => {
    const { has_gt_response } = props.locationInfo;
    if (!has_gt_response) return null;
    if (!dashboard || !dashboard.approval_pending_reviews || !dashboard.approval_pending_reviews.length) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="rcntRevBx">
        <div className="hdr">
          <div className="left">
            Approve Pending Reviews
            <ToolTipWrapper placement="bottom" overlayClassName={'infoTooltip'}
              overlay={'For the selected duration, Approval is required for below reviews. Once approved, they will be posted on OTAs.'}
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
            <span className="count">({dashboard.approval_pending_reviews.length})</span>
          </div>
          <div className="right">
            <Link to={`/reputation/locations/${locationidentifier}/reviews/approval`}>
              See All >
            </Link>
          </div>
        </div>
        <div className="body">
          {
            dashboard.approval_pending_reviews &&
            dashboard.approval_pending_reviews.length
            ?
              dashboard.approval_pending_reviews.slice(0,2)
              .map((review) => {
                // const review = reviewsObj.keyValue[reviewId];
                // if (!review) return null;
                return <RecentReviewCard data={review} locationId={locationidentifier}
                  showActionCTA={true}
                  showReviewState={true}
                />
              })
            : null
          }
        </div>
      </div>
    );
  };

  const renderNeedInfoReviews = (dashboard) => {
    const { has_gt_response } = props.locationInfo;
    if (!has_gt_response) return null;
    if (!dashboard || !dashboard.need_info_reviews || !dashboard.need_info_reviews.length) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="rcntRevBx">
        <div className="hdr">
          <div className="left">
            Need Info Reviews
            <ToolTipWrapper placement="bottom" overlayClassName={'infoTooltip'}
              overlay={'For the selected duration, Approval is required for below reviews. Once approved, they will be posted on OTAs.'}
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
            <span className="count">({dashboard.need_info_reviews.length})</span>
          </div>
          <div className="right">
            <Link to={`/reputation/locations/${locationidentifier}/reviews/need-info`}>
              See All >
            </Link>
          </div>
        </div>
        <div className="body">
          {
            dashboard.need_info_reviews &&
            dashboard.need_info_reviews.length
            ?
              dashboard.need_info_reviews.slice(0,2)
              .map((review) => {
                // const review = reviewsObj.keyValue[reviewId];
                // if (!review) return null;
                return <RecentReviewCard data={review} locationId={locationidentifier}
                  showActionCTA={true}
                  showReviewState={true}
                />
              })
            : null
          }
        </div>
      </div>
    );
  };

  const renderResponsePendingReviews = (dashboard) => {
    const { has_self_response } = props.locationInfo;
    if (!has_self_response) return null;
    if (!dashboard || !dashboard.response_pending_reviews || !dashboard.response_pending_reviews.length) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="rcntRevBx">
        <div className="hdr">
          <div className="left">
            Response Pending Reviews
            <ToolTipWrapper placement="bottom" overlayClassName={'infoTooltip'}
              overlay={'For the selected duration, Response is pending for below reviews.'}
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
            <span className="count">({dashboard.response_pending_reviews.length})</span>
          </div>
          <div className="right">
            <Link to={`/reputation/locations/${locationidentifier}/reviews/response-required`}>
              See All >
            </Link>
          </div>
        </div>
        <div className="body">
          {
            dashboard.response_pending_reviews &&
            dashboard.response_pending_reviews.length
            ?
              dashboard.response_pending_reviews.slice(0,2)
              .map((review) => {
                // const review = reviewsObj.keyValue[reviewId];
                // if (!review) return null;
                return <RecentReviewCard data={review} locationId={locationidentifier}
                  showActionCTA={true}
                  showReviewState={true}
                  hasSelfResponse={has_self_response}
                  onRespondClick={onReviewCardMoreClick}
                />
              })
            : null
          }
        </div>
      </div>
    );
  };

  const renderRecentReviews = (dashboard) => {
    const { has_self_response } = props.locationInfo;
    if (!dashboard || !dashboard.recent_reviews) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="rcntRevBx">
        <div className="hdr">
          <div className="left">
            Recent Reviews
          </div>
          <div className="right">
            <Link to={`/reputation/locations/${locationidentifier}/reviews`}>
              See All >
            </Link>
          </div>
        </div>
        <div className="body">
          {
            dashboard.recent_reviews &&
            dashboard.recent_reviews.length
            ?
              dashboard.recent_reviews.slice(0,2)
              .map((review) => {
                // const review = reviewsObj.keyValue[reviewId];
                // if (!review) return null;
                return <RecentReviewCard data={review} locationId={locationidentifier} hasSelfResponse={has_self_response}
                  showActionCTA={false}
                  showReviewState={false}
                  showMore={true}
                  onMoreClick={onReviewCardMoreClick}
                />
              })
            : null
          }
        </div>
      </div>
    );
  };

  const renderDoMore = (dashboard) => {
    if (!gtconfig.IS_GT) return null;
    if (!dashboard || !dashboard.recent_reviews) return null;
    const { has_pms_integration, has_reviews_widget } = props.locationInfo;
    if (has_pms_integration && has_reviews_widget) return null;
    return (
      <div className="doMoreBx">
        <div className="header">
          <div className="left">
            Do more with {gtconfig.APP_NAME_CONTACT}
          </div>
        </div>
        <div className="body">
          {
            !has_pms_integration &&
            <div className="gCrd connectPms">
              <div className="innerBox">
                <div className="headerBox">
                  Connect PMS
                </div>
                <div className="bodyBox">
                  Supercharge feedback collection by connecting your PMS. Collect feedback in an automated manner.
                </div>
                <div className="footerBox">
                  <div className="left">
                    <img src={gtconfig.CONNECT_PMS_URL} />
                  </div>
                  <div className="right">
                    <span onClick={onKnowMoreConnectPmsClick}>Know More</span>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            !has_reviews_widget &&
            <div className="gCrd connectReviewsWidget">
              <div className="innerBox">
                <div className="headerBox">
                  Connect Reviews Widget
                </div>
                <div className="bodyBox">
                  Showcase your reviews on your website and convert more customers from your website to direct booking customers.
                </div>
                <div className="footerBox">
                  <div className="left">
                    <img src={gtconfig.CONNECT_REVIEWS_WIDGET_URL} />
                  </div>
                  <div className="right">
                    <span onClick={onKnowMoreConnectReviewsWidgetClick}>Know More</span>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        { showConnectPMSModal &&
          <ConnectPMSModal onCloseClick={onConnectPmsCloseModalClick} onCtaClick={onConnectPmsCtaClick} />
        }
        { showConnectReviewsWidgetModal &&
          <ConnectReviewsWidgetModal onCloseClick={onConnectReviewsWidgetCloseModalClick} onCtaClick={onConnectReviewsWidgetCtaClick} />
        }
      </div>
    );
  };

  const renderSingleReviewModal = () => {
    if (!singleReviewData || !singleReviewData._id) return null;
    return (
      <Modal>
        <div id="cModelId" className="sdMdl singleReviewModal">
          <div className="ctnt" ref={singleReviewModalRef}>
            <SingleReview reviewId={singleReviewData._id} review={singleReviewData}
              locationId={urlParams.locationidentifier}
              hasSelfResponse={props.locationInfo.has_self_response}
              filterKeyword={""}
              userState={userState}
              locationInfo={props.locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              repAccess={props.repAccess}
              mode="embed"
              onCancelClick={onSingleReviewModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('Dashboard props: ', props);
    console.log('Dashboard compareNow: ', compareNow);
  }

  const { dashboard } = props.reputation;
  if (!dashboard) return null;


  return (
    <>
      {/*
        dashboard.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      {
        dashboard.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg">
          { renderCurrentRatingBox(dashboard) }
          { renderDateFilterRow() }
          { renderCompareDateFilterRow() }
          { renderStatsBox(dashboard) }
          { renderStatsSecondaryBox(dashboard) }
          { renderApprovalPendingReviews(dashboard) }
          { renderNeedInfoReviews(dashboard) }
          { renderResponsePendingReviews(dashboard) }
          { renderRecentReviews(dashboard) }
          { renderDoMore(dashboard) }
          { showSingleReviewModal && renderSingleReviewModal() }
        </div>
      </div>
    </>
  );
}

export default Dashboard;
