
/***************************************** Get users for location ****************************/

export const getUsersForLocation = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: state.locations[location__id].settings.users
          ?
            {
              ...state.locations[location__id].settings.users,
              getInProgress: true, getStatus: {},
            }
          : {
              getInProgress: true, getStatus: {},
            }
        },
      },
    },
  };
};

export const getUsersForLocationSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { users } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: {
            ...state.locations[location__id].settings.users,
            ...users,
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getUsersForLocationError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: {
            ...state.locations[location__id].settings.users,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};



/* ********************** Start - edit users for location ******************* */

export const editUserForLocationClear = (state, action) => {
  const { params: { location__id, mode, user_id } } = action.payload;
  if (!location__id || !mode) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              keyValue: {
                ...state.locations[location__id].settings.users.keyValue,
                [user_id]: {
                  ...state.locations[location__id].settings.users.keyValue[user_id],
                  editInProgress: false,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: state.locations[location__id].settings.users
            ? {
                ...state.locations[location__id].settings.users,
                addInProgress: false,
                addStatus: {},
              }
            : {
              addInProgress: false,
              addStatus: {},
              },
          },
        },
      },
    };
  }
  return state;
};

export const editUserForLocation = (state, action) => {
  const { params: { location__id, mode, user_id } } = action.payload;
  if (!location__id || !mode) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              keyValue: {
                ...state.locations[location__id].settings.users.keyValue,
                [user_id]: {
                  ...state.locations[location__id].settings.users.keyValue[user_id],
                  editInProgress: true,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              addInProgress: true,
              addStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editUserForLocationSuccess = (state, action) => {
  const { data, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, user_id, ...restParams } = params;
  if (!location__id || !mode) return state;
  const { gt_user } = data;
  if (!gt_user || !gt_user._id) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              keyValue: {
                ...state.locations[location__id].settings.users.keyValue,
                [user_id]: {
                  ...state.locations[location__id].settings.users.keyValue[user_id],
                  ...gt_user,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              keyValue: state.locations[location__id].settings.users.keyValue
              ?
                {
                  ...state.locations[location__id].settings.users.keyValue,
                  [gt_user._id]: {
                    ...gt_user,
                  },
                }
              : {
                  [gt_user._id]: {
                    ...gt_user,
                  },
                },
              list: state.locations[location__id].settings.users.list
              ?
                [gt_user._id, ...state.locations[location__id].settings.users.list]
              :
                [gt_user._id],
              addInProgress: false,
              addStatus: {
                success: true,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editUserForLocationError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, user_id } = params;
  if (!location__id || !mode) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              keyValue: {
                ...state.locations[location__id].settings.users.keyValue,
                [user_id]: {
                  ...state.locations[location__id].settings.users.keyValue[user_id],
                  editInProgress: false,
                  editStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            users: {
              ...state.locations[location__id].settings.users,
              addInProgress: false,
              addStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    };
  }
  return state;
};

/* ********************** End - edit user for location ******************* */


/* ********************** Start - edit user reset password ******************* */

export const editUserResetPasswordClear = (state, action) => {
  const { params: { location__id, user_id } } = action.payload;
  if (!location__id || !user_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: {
            ...state.locations[location__id].settings.users,
            keyValue: {
              ...state.locations[location__id].settings.users.keyValue,
              [user_id]: {
                ...state.locations[location__id].settings.users.keyValue[user_id],
                resetInProgress: false,
                resetStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const editUserResetPassword = (state, action) => {
  const { params: { location__id, user_id } } = action.payload;
  if (!location__id || !user_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: {
            ...state.locations[location__id].settings.users,
            keyValue: {
              ...state.locations[location__id].settings.users.keyValue,
              [user_id]: {
                ...state.locations[location__id].settings.users.keyValue[user_id],
                resetInProgress: true,
                resetStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const editUserResetPasswordSuccess = (state, action) => {
  const { data, params } = action.payload;
  if (!params) return state;
  const { location__id, user_id } = params;
  if (!location__id || !user_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: {
            ...state.locations[location__id].settings.users,
            keyValue: {
              ...state.locations[location__id].settings.users.keyValue,
              [user_id]: {
                ...state.locations[location__id].settings.users.keyValue[user_id],
                resetInProgress: false,
                resetStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
};

export const editUserResetPasswordError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, user_id } = params;
  if (!location__id || !user_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          users: {
            ...state.locations[location__id].settings.users,
            keyValue: {
              ...state.locations[location__id].settings.users.keyValue,
              [user_id]: {
                ...state.locations[location__id].settings.users.keyValue[user_id],
                resetInProgress: false,
                resetStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};

/* ********************** End - edit user reset password ******************* */
