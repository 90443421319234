// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';

// import useOnClickOutside from '../Misc/OutsideClickHook';
import ToolTipWrapper from '../ToolTipWrapper';

import { OTA_ID_TO_NAME_FORMAL_MAP, renderOTALogo, OTA_NAME_TO_SOURCE_MAP,
  changeInValuePercent, changeInValue } from '../../../helpers';

import './style.css';

const { RangePicker } = DatePicker;



function OtaReviewSplitCard(props) {
  const onCtaClick = (e) => {
    props.onCtaClick();
  };

  const { label, labelToolTip, ctaLabel, ctaLink } = props.data;
  const { cmpTotal, cmpSplit, compareNow, cmpPercent } = props;

  return (
    <div className="gCrd gOtaSplitCard">
      <div className="top">
        <p>
          {label}
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay={labelToolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </p>
      </div>
      <div className="total">
        {
          props.total ? <p>{props.total}</p> : <span>-</span>
        }
        { compareNow && cmpTotal ? <p className='cmpVal'>vs {cmpTotal}</p> : null }
        { compareNow && cmpTotal ? <p className='cmpChng'>{changeInValuePercent(props.total, cmpTotal)}</p> : null }
      </div>
      <div className="middle">
        {
          props.split && Object.keys(props.split).length
          ?
            Object.keys(props.split).map((item) => {
              return (
                <div className="entry" key={`orsc_${item}`}>
                  <div className="logo">{ renderOTALogo(OTA_NAME_TO_SOURCE_MAP[item]) }</div>
                  <span className="label">{OTA_ID_TO_NAME_FORMAL_MAP[item]}</span>
                  <div className="value">
                    {props.split[item]}
                    { compareNow && cmpSplit && cmpSplit[item] ? <p className='cmpVal'>vs {cmpSplit[item]}</p> : null }
                    { compareNow && cmpTotal && cmpSplit[item] ? <p className='cmpChng'>{cmpPercent ? changeInValuePercent(props.split[item], cmpSplit[item]) : changeInValue(props.split[item], cmpSplit[item])}</p> : null }
                  </div>
                </div>
              );
            })
          : null
        }
      </div>
      <div className="footer">

      </div>
    </div>
  );
}

export default OtaReviewSplitCard;
