// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
// import Select from 'react-dropdown-select';
import Select from 'react-select'
import moment from 'moment';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import { LABEL_COLORS, LABEL_MAX_LENGTH } from '../../../../../../helpers';
import './style.css';


function ColorDot(props) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(props.isSelected);
  }, [props.isSelected]);
  const onColorDotClick = (e) => {
    setSelected(selected => !selected);
    props.onClick(props.color);
  };
  let cStyle = {};
  cStyle['borderColor'] = selected ? props.color : '#FFFFFF';
  let innerStyle = {};
  innerStyle['backgroundColor'] = props.color;
  return (
    <div className={'colorDot ' + (selected ? 'selected' : '')} style={cStyle} onClick={onColorDotClick}>
      <div className="inner" style={innerStyle} />
    </div>
  );
}

function AddNewLabelModal(props) {

  const [label, setLabel] = useState('');
  const [color, setColor] = useState(LABEL_COLORS[0]);

  const [labelNameError, setLabelNameError] = useState('');

  const modalRef = useRef();

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - CheckIn - ${props.guest._id}`,
    //   user_id: props.userprops.user.uid,
    // });
  }, []);

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const processConfirm = () => {
    setLabelNameError(label ? '' : 'Name is required.');


    if (!label || !color) return;
    const params = {};
    params['label'] = label.trim();
    params['color'] = color;


    props.onConfirmClick({
      ...params,
    });
  };

  const onConfirmClick = (e) => {
    processConfirm();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      processConfirm();
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (!value.length) { setLabel(''); return; }
    let tValue = value;
    if (tValue.length > LABEL_MAX_LENGTH) tValue = tValue.slice(0, LABEL_MAX_LENGTH);
    setLabel(tValue);
    setLabelNameError(tValue ? '' : 'Name is required.');
  };

  const onLabelColorClick = (color) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLabelColorClick: ', color);
    }
    setColor(color);
  };

  const renderContent = () => {
    return (
      <div className="gPWrp addNewLabel" ref={modalRef}>
        <div className="gPTle title">
          Add New Label
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw colorRow">
            <div className="itemBox">
              <div className="gLabel">
                Label Color
              </div>
              <div className="labelColors">
                {
                  LABEL_COLORS.map((item) => {
                    return (
                      <ColorDot color={item} isSelected={color === item} onClick={onLabelColorClick} />
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className="gBCtRw rmNumRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Label Name <span>(Character Limit - {LABEL_MAX_LENGTH})</span>
              </div>
              <div className="gIpt">
                <input type="text" placeholder="Label name" name="label" value={label} onChange={onChange} />
                { labelNameError && <p className="gErr">{labelNameError}</p> }
              </div>
            </div>
          </div>
          {
            props.addStatus && (props.addStatus.success || props.addStatus.error) &&
            <div className="gBCtRw curSts">
              {
                props.addStatus.error && <p className="error">{props.addStatus.message}</p>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            <button className="gBtn cancel" onClick={onCancelClick}>Cancel</button>
            <button type="submit" className="gBtn ok" onClick={onConfirmClick} >
              Add
              { props.addInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  };

  return renderContent();

}

export default AddNewLabelModal;
