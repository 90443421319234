import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import { editDataClearAction, editDataAction } from '../../../../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../../../../Misc/ToolTipWrapper';
import ImagePreviewModal from '../../../../../../Misc/ImagePreviewModal';

import { RULE_TYPES, RULE_TYPE_LABELS, ruleTypeToLabel, validateUrl } from '../../../../../../../helpers';

import closeIcon from '../../../../../../../img/icons/close.svg';
import deleteIcon from '../../../../../../../img/icons/delete.svg';

import '../../style.css';

const modalRoot = document.getElementById('modal-img-prv');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function ImageSection(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [showUploadedPreview, setShowUploadedPreview] = useState(false);
  const [uploadedPath, setUploadedPath] = useState('');
  const [uploadedFileType, setUploadedFileType] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const imagePreviewModalRef = useRef();
  const [imagePreviewModalHideConfirm, setImagePreviewModalHideConfirm] = useState(false);

  useEffect(() => {
    if (props.url) {
      setUploadedPath(props.url);
    }
  }, [props.url]);

  const onAttachMediaClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAttachMediaClick');
    }
  };

  const onDrop = useCallback((acceptedFiles) => {

    // Do something with the files
    if (process.env.NODE_ENV !== 'production') {
      console.log('acceptedFiles: ',acceptedFiles);
    }
    if (acceptedFiles.length===0) {
      return;
    }
    setUploadedPath('');
    setUploadedFileType('');
    setUploadedFileName('');
    setImagePreviewModalHideConfirm(false);

    if (acceptedFiles.length === 1) {
      acceptedFiles.forEach((file) => {
        // check file size

        let fileType = '';
        if ( /\.(jpe?g|png|gif)$/i.test(file.name) === false ) {
          setUploadError(`Supported image types: JPG, JPEG, PNG and GIF. Please try with a different image.`);
          setUploadInProgress(false);
          // setShowUploadErrorModal(true);
          return false;
        }
        if ( /\.(jpe?g|png|gif)$/i.test(file.name)) {
          fileType = 'image';
        }

        if ( /\.(pdf)$/i.test(file.name)) {
          fileType = 'pdf';
        }
        setUploadedFileType(fileType);
        setUploadedFileName(file.name);
        setUploadError('');

        if (file.size > 2 * 1024 * 1024) {
          setUploadError('Size exceeds 2MB! Please try with a file with a size smaller than 2MB.');
          setUploadInProgress(false);
          setUploadedFileType('');
          setUploadedFileName('');
          return false;
        }
        setUploadError('');

        const reader = new FileReader();
        reader.onload = (e) => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            // console.log('fileAsBinaryString: ',fileAsBinaryString);
            console.log('fileType: ',fileType);
            console.log('e.target.result: ',e.target.result);
          }
          if (fileType === 'image') {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.


            //Validate the File Height and Width.
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (process.env.NODE_ENV !== 'production') {
                console.log('height: ', height);
                console.log('width: ', width);
              }
              // if (height !== 1024 || width != 1024) {
              //   setUploadError(`Image must be 1024px X 1024px. Tried one is ${height}px X ${width}px`);
              //   setShowUploadErrorModal(true);
              //   return false;
              // }
              uploadFile(file);
              return true;
            };
            image.src = e.target.result;
          }
          if (fileType === 'pdf') {
            uploadFile(file);
            return true;
          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsDataURL(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);

  const uploadFile = (file) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile: ', file);
    }
    setShowUploadedPreview(true);
    setUploadedPath('');
    setUploadInProgress(true);
    setShowImagePreviewModal(true);

    let filename = file.name.replace(/\s/g,'');
    let fileExtension = 'jpg';
    let tArr = filename.split('.');
    if (tArr.length >= 2) {
      fileExtension = tArr[tArr.length-1];
    }
    const onlyFileName = filename.split(`.${fileExtension}`)[0];

    if (process.env.NODE_ENV !== 'production') {
      console.log('fileExtension: ', fileExtension);
      console.log('onlyFileName: ', onlyFileName);
    }

    let postData = new FormData();
    postData.append('file', file, file.name);
    postData.append('name', filename);
    postData.append('new_name', `${onlyFileName}.${fileExtension}`);
    // postData.append('uid', props.userprops.user.uid);
    postData.append('location_id', userState.user.user_info.locations[urlParams.locationidentifier].location_id);
    postData.append('identifier', file.name);
    postData.append('source', 'gtengage-web');

    // //get data from database
    let url = "https://xooz4w4zi5.guesttouch.com/dapi/pubg8dh9i6ncy4c73awy";
    const header = { 'gt-access-token' : "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa", 'Content-Type': 'multipart/form-data' };
    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   'x-api-key': "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa",
    // };
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile called api');
      console.log('uploadFile postData: ', postData);
    }
    axios.post(url, postData, { headers: header }).then((response) => {
      // setUploadInProgress(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('uploadFile response: ', response['data']);
      }
      const { status } = response.data;
      const { data } = response.data;
      if (status === 'success' && data && data.name && data.path) {
        setShowUploadedPreview(true);
        setUploadedPath(data.path);
        setShowImagePreviewModal(true);
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [data.identifier]: data.name, //feedbackEmailHotelLogoUrl
        //   [`${data.identifier}Error`]: 'success',
        //   [`${data.identifier}UploadInProgress`]: false,
        // }));
        try {
          const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
            && locationState.locations[urlParams.locationidentifier].settings
            && locationState.locations[urlParams.locationidentifier].settings.info
            ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
            : ''
          window.gtag('event', 'gt_click', {
            'event_category': `Engage - Digital Guide - Image Upload`,
            'event_label': `Upload Image`,
            page_path: `${location.pathname}`,
            page_title: `Engage - Digital Guide - ${locationName}`,
            gt_user_id: userState.user.uid,
            gt_location_id: urlParams.locationidentifier,
            gt_location: locationName,
          });
        } catch (e) {}
      } else {
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [name]: '',
        //   [`${name}Error`]: 'Error is uploading.',
        //   [`${name}UploadInProgress`]: false,
        // }));
      }
      setUploadInProgress(false);
    })
      .catch((err) => {
        console.log('uploadFile err--------------------: ',err);
      });
  };

  const onUploadedImageClick = (e) => {
    setShowImagePreviewModal(true);
  };
  const onRemoveAttachMediaClick = (e) => {
    setUploadedPath('');
    setUploadedFileType('');
    setUploadedFileName('');
    setImagePreviewModalHideConfirm(false);
  };
  const onImagePreviewModalCloseClick = (e) => {
    setShowImagePreviewModal(false);
    setUploadError('');
    if (!imagePreviewModalHideConfirm) {
      setUploadedPath('');
    }
  };
  const onImagePreviewModalConfirmClick = (e) => {
    setShowImagePreviewModal(false);
    setUploadError('');
    setImagePreviewModalHideConfirm(true);
    props.onDataChange(props.sectionId, uploadedPath);
  };

  const onRemoveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RichTextEditor onRemoveClick props: ', props);
    }
    props.onRemoveClick(props.sectionId);
  }

  const renderImagePreviewModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal imgPrvMdl">
          <div className="ctnt" ref={imagePreviewModalRef}>
            <ImagePreviewModal
              image={uploadedPath}
              onConfirmClick={onImagePreviewModalConfirmClick}
              hideConfirm={imagePreviewModalHideConfirm}
              onCancelClick={onImagePreviewModalCloseClick}
              inProgress={uploadInProgress}
              uploadError={uploadError}
              replaceFile={
                <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} multiple={false}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({className: 'dndBox1'})}>
                    <input {...getInputProps()} />
                    <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Choose a different file'}>
                      <span className="replace">{uploadError ? 'Retry' : 'Replace Image' }</span>
                      {/*<button className="reverse" onClick={onAttachMediaClick}>
                        Replace Image
                      </button>*/}
                    </ToolTipWrapper>
                  </div>
                  )}
                </Dropzone>
              }
            />
          </div>
        </div>
      </Modal>
    );
  };



  return (
    <>
      <div className={'gBCtRw imgRw'}>

        {
          uploadedPath &&
          <div className="lt">
            <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={uploadedFileName}>
              <img src={uploadedPath} onClick={onUploadedImageClick} />
            </ToolTipWrapper>
            {/*<ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={"Remove media"}>
              <div className="close" onClick={onRemoveAttachMediaClick}><i className="far fa-times-circle"></i></div>
            </ToolTipWrapper>*/}
          </div>
        }
        <div className="rt">
          <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} multiple={false}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps({className: 'dndBox'})}>
                <input {...getInputProps()} />
                <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Attach media'}>
                <span onClick={onAttachMediaClick}>
                  <div className="info">
                    <div className="top">Drag your image here or <div className="gButton"><span>Click to Upload/Change</span></div></div>
                    <p className="supportInfo">
                      Supported formats: PNG, JPG. Size must be less than 2MB.
                    </p>
                  </div>
                </span>
                </ToolTipWrapper>
              </div>
              )}
          </Dropzone>
        </div>
        <div className="delCtrl">
          <span onClick={onRemoveClick}><img src={deleteIcon} /></span>
        </div>
      </div>
      {/*<div className="opIt img">
        <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} multiple={false}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: 'dndBox1'})}>
              <input {...getInputProps()} />
              <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Attach media'}>
              <span onClick={onAttachMediaClick}>
                <i className="fas fa-image"></i>
              </span>
              </ToolTipWrapper>
            </div>
            )}
        </Dropzone>
      </div>*/}
      { (showImagePreviewModal || uploadError) && renderImagePreviewModal() }
    </>
  );
}

export default ImageSection;
