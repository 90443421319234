import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import { RULE_TYPES, RULE_TYPE_LABELS, ruleTypeToLabel, validateUrl } from '../../../../../../helpers';

import emojiIcon from '../../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import '../style.css';

const selectOptions = [];
selectOptions.push({ label: 'Select', value: 'select' });
selectOptions.push({ label: RULE_TYPE_LABELS.CUSTOM_PROMPT, value: RULE_TYPES.CUSTOM_PROMPT });
selectOptions.push({ label: RULE_TYPE_LABELS.SHOW_AFTER_DELAY, value: RULE_TYPES.SHOW_AFTER_DELAY });
selectOptions.push({ label: RULE_TYPE_LABELS.SHOW_AFTER_SCROLL, value: RULE_TYPES.SHOW_AFTER_SCROLL });
selectOptions.push({ label: RULE_TYPE_LABELS.HIDE, value: RULE_TYPES.HIDE });

function InputBox(props) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('props.error: ', props.props);
  }
  const onTextChange = (e) => { props.onTextChange(e.target.value); };
  return (
    <div className="titleRow">
      <div className="itemBox">
        <div className="gLabel">
          {props.title}
        </div>
        {
          props.subTitle &&
          <div className="gSubLabel">
            {props.subTitle}
          </div>
        }
        <div className={'gInput ' + (props.error ? 'error' : '')}>
          <input type="text" value={props.value} onChange={onTextChange} placeholder={props.placeholder} />
          { props.error && <p className="error">{props.error}</p> }
        </div>
      </div>
    </div>
  );
}

function WCRuleSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  const [url, setUrl] = useState('');
  const [urlError, seturlError] = useState('');

  const [prompt, setPrompt] = useState('');
  const [promptError, setPromptError] = useState('');

  const [delay, setDelay] = useState('');
  const [delayError, setDelayError] = useState('');

  const [scroll, setScroll] = useState('');
  const [scrollError, setScrollError] = useState('');

  const [ruleTypeOptions, setRuleTypeOptions] = useState(selectOptions);
  const [ruleTypeValue, setRuleTypeValue] = useState(
    { label: 'Select', value: 'select' },
  );
  const [ruleTypeError, setRuleTypeError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const clearAction = () => {
    if (props.type === 'edit') {
      dispatch(editDataClearAction({
        op: 'edit_webchat_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
        },
      }));
    }
    if (props.type === 'add') {
      dispatch(editDataClearAction({
        op: 'edit_webchat_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
  };

  useEffect(() => {
    if (props.rule && props.rule._id) {
      setTitle(props.rule.name);
      setUrl(props.rule.url || '');
      if (props.rule.type) {
        let rValue = props.rule.type;
        if (rValue === 'appear' && props.rule.delay) {
          rValue = RULE_TYPES.SHOW_AFTER_DELAY;
        }
        if (rValue === 'appear' && props.rule.scroll) {
          rValue = RULE_TYPES.SHOW_AFTER_SCROLL;
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('rValue: ', rValue);
        }
        setRuleTypeValue({
          label: ruleTypeToLabel[rValue],
          value: rValue,
        });
      }
      setPrompt(props.rule.prompt || '');
      setDelay(props.rule.delay || '');
    }
    setSomeChange(false);
    clearAction();
  }, []);

  useEffect(() => {
    const { webchat } = props.settings;
    if (props.type === 'edit') {
      if (props.rule && props.rule._id &&
        webchat && webchat.rules &&
        webchat.rules.keyValue &&
        webchat.rules.keyValue[props.rule._id] &&
        !webchat.rules.keyValue[props.rule._id].editInProgress &&
        webchat.rules.keyValue[props.rule._id].editStatus &&
        webchat.rules.keyValue[props.rule._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (webchat && webchat.rules &&
        !webchat.rules.addInProgress &&
        webchat.rules.addStatus &&
        webchat.rules.addStatus.success
      ) {
        setTitle('');
        setRuleTypeValue({ label: 'Select', value: 'select' });
        setUrl('');
        setPrompt('');
        setDelay('');
        setSomeChange(false);
      }
    }
  }, [props.settings.rooms]);

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true); clearAction();
    if (!value) {
      setTitleError('Name is required');
    } else {
      setTitleError('');
    }
  };

  const validateUrlValue = (value) => {
    if (!value) {
      seturlError('Url is required'); return false;
    } else {
      if (validateUrl(value)) {
        seturlError(''); return true;
      } else {
        seturlError('Invalid value'); return false;
      }
    }
  };

  const onUrlChange = (e) => {
    const { name, value } = e.target;
    setUrl(value); setSomeChange(true); clearAction()
    validateUrlValue(value);
  };

  const onPromptChange = (e) => {
    const { name, value } = e.target;
    setPrompt(value); setSomeChange(true); clearAction();
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPromptChange: ', value);
    }
    if (!value) {
      setPromptError('Prompt is required');
    } else {
      setPromptError('');
    }
  };

  const validateDelay = (value) => {
    if (!value) {
      setDelayError('Delay is required'); return false;
    } else {
      if (Number.isInteger(parseInt(value)) && parseInt(value) > 0) {
        setDelayError(''); return true;
      } else {
        setDelayError('Invalid value'); return false;
      }
    }
  };

  const onDelayChange = (e) => {
    const { name, value } = e.target;
    setDelay(value); setSomeChange(true); clearAction();
    validateDelay(value);
  };

  const onScrollChange = (e) => {
    const { name, value } = e.target;
    setScroll(value); setSomeChange(true); clearAction();
    validateDelay(value);
  };

  const onRuleTypeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRuleTypeChange: ', value);
    }
    if (value && value.value !== 'select') {
      setRuleTypeValue(value);
      setSomeChange(true);
      setRuleTypeError('');
    } else {
      setRuleTypeError('Type is required');
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    const { webchat } = props.settings;
    let addEditInProgress = webchat.rules.addInProgress
    if (props.type === 'edit') {
      if (props.rule && props.rule._id &&
        webchat.rules && webchat.rules.keyValue &&
        webchat.rules.keyValue[props.rule._id]
      ) {
        addEditInProgress = webchat.rules.keyValue[props.rule._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    clearAction();

    let hasError = false;
    if (!title) hasError = true;
    setTitleError(title ? '' : 'Name is required');

    if (ruleTypeValue.value === 'select') hasError = true;
    setRuleTypeError(ruleTypeValue.value !== 'select' ? '' : 'Type is required');

    if (!validateUrlValue(url)) hasError = true;

    if (ruleTypeValue.value === RULE_TYPES.CUSTOM_PROMPT) {
      if (!prompt) hasError = true;
      setPromptError(prompt ? '' : 'Prompt is required');
    }
    if (ruleTypeValue.value === RULE_TYPES.SHOW_AFTER_DELAY) {
      if (!validateDelay(delay)) hasError = true;
    }
    if (ruleTypeValue.value === RULE_TYPES.SHOW_AFTER_SCROLL) {
      if (!validateDelay(scroll)) hasError = true;
    }
    if (hasError) return;

    if (someChange) {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        rule_data: {
          name: title,
          type: ruleTypeValue.value,
          url,
        }
      }
      if (ruleTypeValue.value === RULE_TYPES.CUSTOM_PROMPT) {
        params.rule_data.prompt = prompt;
      }
      if (ruleTypeValue.value === RULE_TYPES.SHOW_AFTER_DELAY) {
        params.rule_data.type = 'appear';
        params.rule_data.delay = delay;
      }
      if (ruleTypeValue.value === RULE_TYPES.SHOW_AFTER_SCROLL) {
        params.rule_data.type = 'appear';
        params.rule_data.scroll = scroll;
      }
      if (props.type === 'add') {
        params.rule_data.is_enabled = true;
        dispatch(editDataAction({
          op: 'edit_webchat_rule',
          params,
        }));
        setSomeChange(false);
      }
      if (props.type === 'edit') {
        params.rule_id = props.rule._id;
        dispatch(editDataAction({
          op: 'edit_webchat_rule',
          params,
        }));
        setSomeChange(false);
      }
    }
  };

  const renderContent = () => {
    const { webchat } = props.settings;

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Rule`;
    if (props.type === 'edit') pageTitle = 'Edit Rule';
    if (props.title) pageTitle = props.title;

    let addEditInProgress = webchat && webchat.rules ? webchat.rules.addInProgress : false;
    let addEditStatus = webchat && webchat.rules ? webchat.rules.addStatus || {} : {};
    if (props.type === 'edit') {
      if (props.rule && props.rule._id) {
        addEditInProgress = props.rule.editInProgress;
        addEditStatus = props.rule.editStatus;
      }
    }

    return (
      <div className="gPWrp ruleSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Name
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" />
                { titleError && <p className="gErr">Name is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw">
            <div className="gRwBxLt">
              <div className="gLbl">Type of rule</div>
              <div className="gSLbl">
                Choose appropriate rule type based on your need to customize the behaviour on specific web addresses.
              </div>
              <Select options={ruleTypeOptions}
                value={ruleTypeValue}
                onChange={onRuleTypeChange}
              />
              { ruleTypeError && <p className="gErr">{ruleTypeError}</p> }
            </div>
          </div>
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Web Address
              </div>
              <div className="gSLbl">
                Web address on which you want to apply this rule.
              </div>
              <div className={'gIpt ' + (urlError ? 'error' : '')}>
                <input type="text" value={url} onChange={onUrlChange} placeholder="Web Address" />
                { urlError && <p className="gErr">Web address is required</p> }
              </div>
            </div>
          </div>
          {
            ruleTypeValue.value === RULE_TYPES.CUSTOM_PROMPT &&
            <div className="gBCtRw titleRow">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Custom Prompt
                </div>
                <div className="gSLbl">
                  Custom prompt which will be shown only on the above url, if accessed directly
                </div>
                <div className={'gIpt ' + (promptError ? 'error' : '')}>
                  <input type="text" value={prompt} onChange={onPromptChange} placeholder="Custom Prompt" />
                  { promptError && <p className="gErr">Prompt is required</p> }
                </div>
              </div>
            </div>
          }
          {
            ruleTypeValue.value === RULE_TYPES.SHOW_AFTER_DELAY &&
            <div className="gBCtRw titleRow">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Show after delay ( in seconds )
                </div>
                <div className="gSLbl">
                  Number of seconds after which webchat will be shown.
                </div>
                <div className={'gIpt ' + (delayError ? 'error' : '')}>
                  <input type="text" value={delay} onChange={onDelayChange} placeholder="Delay in seconds" />
                  { delayError && <p className="gErr">Delay is required</p> }
                </div>
              </div>
            </div>
          }
          {
            ruleTypeValue.value === RULE_TYPES.SHOW_AFTER_SCROLL &&
            <div className="gBCtRw titleRow">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Show after some scroll ( in % of page )
                </div>
                <div className="gSLbl">
                  Percentage of page scrolled after which webchat will be shown.
                </div>
                <div className={'gIpt ' + (scrollError ? 'error' : '')}>
                  <input type="text" value={scroll} onChange={onScrollChange} placeholder="Percentage of scroll" />
                  { scrollError && <p className="gErr">Scroll percentage is required</p> }
                </div>
              </div>
            </div>
          }
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('WCRuleSingle props: ', props);
  }

  return renderContent();
}

export default WCRuleSingle;
