import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import GuestPanel from '../GuestPanel';

import { getDataAction } from '../../../../../redux/actions/getDataActions';

import { categoryToStoreGuestLabel, categoryToGuestType } from '../../../../../helpers';

import './style.css';

function GuestPanelWrap(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();




  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GuestPanelWrap props: ', props);
      console.log('GuestPanel urlParams guestidentifier: ', urlParams.guestidentifier);
    }

    const { user } = userState;

    const { locationidentifier } = urlParams;

    const locInfo = user.user_info && user.user_info.locations && user.user_info.locations[locationidentifier]
      ? { ...user.user_info.locations[locationidentifier] } : null;

    if (locInfo) {
      const { categoryidentifier, guestidentifier } = urlParams;

      const type = categoryToGuestType[categoryidentifier];
      const params = {
        location__id: locationidentifier,
        location_id: locInfo.location_id,
        type,
      };
      if (type === 'guest' || type === 'past-guest' || type === 'upcoming-guest') {
        params.guest_id = guestidentifier;
      }
      if (type === 'outside-guest' || type === 'staff' || type === 'webtext' || type === 'gbm') {
        params.outside_guest_id = guestidentifier;
      }
      dispatch(getDataAction({
        op: 'get_guest',
        params,
      }));
    }

  }, [urlParams.categoryidentifier, urlParams.guestidentifier]);


  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  const { categoryidentifier, guestidentifier } = urlParams;
  if (!categoryidentifier || !guestidentifier) return null;

  const storeGuestTypeLabel = categoryToStoreGuestLabel[categoryidentifier];
  if (!storeGuestTypeLabel) return null;

  let gData = locationState.locations[locationidentifier] && locationState.locations[locationidentifier].engage
    && locationState.locations[locationidentifier].engage[storeGuestTypeLabel]
    && locationState.locations[locationidentifier].engage[storeGuestTypeLabel].keyValue &&
    locationState.locations[locationidentifier].engage[storeGuestTypeLabel].keyValue[guestidentifier]
    ? locationState.locations[locationidentifier].engage[storeGuestTypeLabel].keyValue[guestidentifier]
    : null;
  if (process.env.NODE_ENV !== 'production') {
    console.log('GuestPanelWrap gData: ', gData);
  }
  if (!gData) return null;

  const type = categoryToGuestType[categoryidentifier];

  return (
    <GuestPanel guest={gData} guestType={type} {...props} />
  );
}

export default GuestPanelWrap;
