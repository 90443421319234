
import * as userProfileHelper from './userProfileHelper';

const initialState = {
  user: {},
};
// user: {}

const addUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT': {
      const { params } = action.payload;
      if (params) {
        const { not_allowed } = params;
        if (not_allowed) {
          return {
            ...initialState,
            not_allowed: true,
          };
        }
      }
      return initialState;
    }

    case "ON_SIDEBAR_TOGGLE": {
      const { open } = action.payload;
      return {
        ...state,
        sideBarOpen: open
      };
    }

    case 'SET_LOGGED_IN': {
      const { uid, loggedIn } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          uid,
          loggedIn,
        }
      };
    }

    case "GET_USER_INFO_CLEAR": {
      return {
        ...state,
        user: {
          ...state.user,
          getInProgress: false,
          getStatus: {},
        },
      };
    }
    case "GET_USER_INFO": {
      return {
        ...state,
        user: {
          ...state.user,
          getInProgress: true,
          getStatus: {},
        },
      };
    }
    case "GET_USER_INFO_SUCCESS": {
      const { data } = action.payload;
      const { user_info, app_version } = data;
      if (!user_info || !user_info._id || !user_info.locations) return state;
      // const keyValue = {};
      // user_info.location_list.forEach(el => keyValue[el._id] = el);

      // if (user_info.uid === 'neeraj.2197@gmail.com') { // nkcheck
      //   let t1 = '63a5f4fd3fb1ae00082181f8';
      //   // localStorage.setItem('nktest', JSON.stringify(t1));
      //   localStorage.setItem('nktest', t1); // nkcheck

      // window.intercomSettings = {
      //   api_base: "https://api-iam.intercom.io",
      //   app_id: "mydf4dtr",
      //   name: user_info.profile ? user_info.profile.first_name || user_info.uid : user_info.uid, // Full name
      //   user_id: user_info._id, // a UUID for your user
      //   email: user_info.uid, // the email for your user
      //   created_at: new Date(), // Signup date as a Unix timestamp
      // };

      // }
      return {
        ...state,
        user: {
          ...state.user,
          user_id: user_info._id,
          user_info,
          getInProgress: false,
          getStatus: { success: true },
        },
      };
    }
    case "GET_USER_INFO_ERROR": {
      return {
        ...state,
        user: {
          ...state.user,
          user_id: '',
          getInProgress: false,
          getStatus: { error: true },
        },
      };
    }

    case "UPDATE_CURRENT_USER_RECENT_LANG": {
      const { params } = action.payload;
      const { recent_lang } = params;
      return {
        ...state,
        user: {
          ...state.user,
          user_info: {
            ...state.user.user_info,
            profile: {
              ...state.user.user_info.profile,
              recent_lang,
            },
          },
        },
      };
    }

    case "UPDATE_REVIEW_TRANSLATION_SUCCESS": {
      const { params } = action.payload;
      if (params.translation_lang) {
        return {
          ...state,
          user: {
            ...state.user,
            user_info: {
              ...state.user.user_info,
              profile: {
                ...state.user.user_info.profile,
                translation_lang: params.translation_lang,
              },
            },
          },
        };
      }
      return state;
    }


    case "SET_UPDATED_USER_PROFILE": {
      const { data } = action.payload;
      const { updated_user_profile } = data;
      if (!updated_user_profile) return state;
      return {
        ...state,
        user: {
          ...state.user,
          user_info: {
            ...state.user.user_info,
            profile: {
              ...state.user.user_info.profile,
              ...updated_user_profile
            },
          },
        },
      };
    }

    /* Edit Profile */

    case "EDIT_PROFILE_CLEAR": {
      return userProfileHelper.editProfileClear(state, action);
    }
    case "EDIT_PROFILE": {
      return userProfileHelper.editProfile(state, action);
    }
    case "EDIT_PROFILE_SUCCESS": {
      return userProfileHelper.editProfileSuccess(state, action);
    }
    case "EDIT_PROFILE_ERROR": {
      return userProfileHelper.editProfileError(state, action);
    }

    case "EDIT_SETTINGS_SUCCESS": {
      return userProfileHelper.editSettingsSuccess(state, action);
    }


    /* Edit Self Pass */

    case "EDIT_SELF_PASSWORD_CLEAR": {
      return userProfileHelper.editSelfPassClear(state, action);
    }
    case "EDIT_SELF_PASSWORD": {
      return userProfileHelper.editSelfPass(state, action);
    }
    case "EDIT_SELF_PASSWORD_SUCCESS": {
      return userProfileHelper.editSelfPassSuccess(state, action);
    }
    case "EDIT_SELF_PASSWORD_ERROR": {
      return userProfileHelper.editSelfPassError(state, action);
    }
    case "SET_SELF_PASS_CHANGE_IDENTIFIER": {
      return userProfileHelper.editSelfPassChangeIdentifier(state, action);
    }


    /* Edit Engage Guest List Display Preferences */

    case "EDIT_GUEST_LIST_DISPLAY_PREFERENCES_CLEAR": {
      return userProfileHelper.editGuestListDisplayPreferencesClear(state, action);
    }
    case "EDIT_GUEST_LIST_DISPLAY_PREFERENCES": {
      return userProfileHelper.editGuestListDisplayPreferences(state, action);
    }
    case "EDIT_GUEST_LIST_DISPLAY_PREFERENCES_SUCCESS": {
      return userProfileHelper.editGuestListDisplayPreferencesSuccess(state, action);
    }
    case "EDIT_GUEST_LIST_DISPLAY_PREFERENCES_ERROR": {
      return userProfileHelper.editGuestListDisplayPreferencesError(state, action);
    }


    /* Edit Reputation Alerts */

    case "EDIT_REPUTATION_ALERTS_CLEAR": {
      return userProfileHelper.editReputationAlertsClear(state, action);
    }
    case "EDIT_REPUTATION_ALERTS": {
      return userProfileHelper.editReputationAlerts(state, action);
    }
    case "EDIT_REPUTATION_ALERTS_SUCCESS": {
      return userProfileHelper.editReputationAlertsSuccess(state, action);
    }
    case "EDIT_REPUTATION_ALERTS_ERROR": {
      return userProfileHelper.editReputationAlertsError(state, action);
    }


    /* Edit Reputation Reports */

    case "EDIT_REPUTATION_REPORTS_CLEAR": {
      return userProfileHelper.editReputationReportsClear(state, action);
    }
    case "EDIT_REPUTATION_REPORTS": {
      return userProfileHelper.editReputationReports(state, action);
    }
    case "EDIT_REPUTATION_REPORTS_SUCCESS": {
      return userProfileHelper.editReputationReportsSuccess(state, action);
    }
    case "EDIT_REPUTATION_REPORTS_ERROR": {
      return userProfileHelper.editReputationReportsError(state, action);
    }


    /* Send Reputation Report */

    case "SEND_REPUTATION_REPORT_CLEAR": {
      return userProfileHelper.sendReputationReportClear(state, action);
    }
    case "SEND_REPUTATION_REPORT": {
      return userProfileHelper.sendReputationReport(state, action);
    }
    case "SEND_REPUTATION_REPORT_SUCCESS": {
      return userProfileHelper.sendReputationReportSuccess(state, action);
    }
    case "SEND_REPUTATION_REPORT_ERROR": {
      return userProfileHelper.sendReputationReportError(state, action);
    }

    /* Edit Bookmarks */

    case "EDIT_USER_BOOKMARKS_CLEAR": {
      return userProfileHelper.editBookmarksClear(state, action);
    }
    case "EDIT_USER_BOOKMARKS": {
      return userProfileHelper.editBookmarks(state, action);
    }
    case "EDIT_USER_BOOKMARKS_SUCCESS": {
      return userProfileHelper.editBookmarksSuccess(state, action);
    }
    case "EDIT_USER_BOOKMARKS_ERROR": {
      return userProfileHelper.editBookmarksError(state, action);
    }


    /* Add Response Generator Location */

    case "ADD_RG_LOCATION_SUCCESS": {
      const { data } = action.payload;
      const { rg_location_data } = data;
      if (!rg_location_data._id) return state;
      return {
        ...state,
        user: {
          ...state.user,
          user_info: {
            ...state.user.user_info,
            profile: {
              ...state.user.user_info.profile,
              rg_locations: state.user.user_info.profile.rg_locations
                ?
                  {
                    ...state.user.user_info.profile.rg_locations,
                    [rg_location_data._id]: {},
                  }
                :
                  {
                    [rg_location_data._id]: {},
                  }
            },
            rg_locations: state.user.user_info.rg_locations
              ?
                {
                  ...state.user.user_info.rg_locations,
                  [rg_location_data._id]: { ...rg_location_data },
                }
              :
                {
                  [rg_location_data._id]: { ...rg_location_data },
                },
            rg_locations_list: state.user.user_info.rg_locations_list
              ? [ ...state.user.user_info.rg_locations_list, rg_location_data._id ]
              : [ rg_location_data._id ]
          },
        },
      };
    }


    /* Handle Pusher act message */

    case "HANDLE_ENGAGE_ACT_MESSAGE": {
      return userProfileHelper.handleEngageActMessage(state, action);
    }

    case "GET_UNREAD_COUNT": {
      return userProfileHelper.getUnreadCount(state, action);
    }


  }
  return state;
};
export default addUserReducer;
