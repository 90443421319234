// @flow

import React, { Component, useEffect, useState } from 'react';
import { OTA_SOURCE_TO_NAME_FORMAL_MAP } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';

import './style.css';

const gtconfig = require('../../../../../gtconfig');

function RespondedOnOta(props) {
  const onCloseClick = (e) => { props.onCancelClick(); };
  const onConfirmClick = (e) => {
    props.onConfirmClick();
  }

  const renderContent = () => {
    return (
      <div className="innerBox">
        <div className="header">
          <div className="title">
            <i className="far fa-question-circle" />
            Have you responded to the review on {OTA_SOURCE_TO_NAME_FORMAL_MAP[props.ota]} ?
          </div>
          {
            props.mode === 'modal' &&
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
          }
        </div>
        <div className="body">
          <div className="infoMsg">
            By confirming, you will help {gtconfig.APP_NAME_CONTACT} to calculate your Response Rate accurately.
          </div>
          <div className="note">
            <p><b>Note:</b> Please confirm, yes, only if you have responded to the review on {OTA_SOURCE_TO_NAME_FORMAL_MAP[props.ota]} successfully.</p>
          </div>
        </div>
        <div className="submitBox">
          <div className="buttonWrap">
            <button className={'gBtn cancel'} onClick={onCloseClick}>
              <span>Cancel</span>
            </button>
            <button className={'gBtn'} onClick={onConfirmClick}>
              {
                props.inProgress
                ? <span>Updating... <span><i className="fa fa-spinner fa-spin" /></span></span>
                : <span>Yes, I have responded</span>
              }
            </button>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="gBoxWrap rtoBoxWrap">
        { renderContent() }
    </div>
  );
}

export default RespondedOnOta;
