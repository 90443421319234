import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../GenericAlertModal';
import GenericSideModal from '../GenericSideModal';
import ToolTipWrapper from '../ToolTipWrapper';
import MessageTemplateRow from '../../Settings/Templates/MessageTemplates/MessageTemplateRow';
import WhatsappTemplateRow from '../../Settings/Templates/WhatsappTemplates/WhatsappTemplateRow';
import LabelRow from '../../Settings/Engage/Labels/LabelRow';
import RoomRow from '../../Settings/HotelDetails/Rooms/RoomRow';
import RemoteLockRow from '../../Settings/HotelDetails/RemoteLocks/RemoteLockRow';
import RateCodeRow from '../../Settings/HotelDetails/RateCodes/RateCodeRow';
import BookingSourceRow from '../../Settings/HotelDetails/BookingSources/BookingSourceRow';
import SenderNameRow from '../../Settings/Marketing/SenderNames/SenderNameRow';
import SenderEmailRow from '../../Settings/Marketing/SenderEmails/SenderEmailRow';
import WCRuleRow from '../../Settings/Engage/WebChat/WCRules/WCRuleRow';
import DGPageRow from '../../Settings/Engage/DigitalGuide/DGPages/DGPageRow';
import DGRoomRow from '../../Settings/Engage/DigitalGuide/DGRooms/DGRoomRow';
import UserRow from '../../Settings/Account/Users/UserRow';
import RgUserRow from '../../ResponseGenerator/RgUsers/RgUserRow';
import ResetPasswordModal from '../../Settings/Account/Users/ResetPasswordModal';
import ResetRgPasswordModal from '../../ResponseGenerator/RgUsers/ResetRgPasswordModal';

import ResponseTemplateRow from '../../Settings/Reputation/ResponseTemplates/ResponseTemplateRow';
import ResponseTemplateCategoryRow from '../../Settings/Reputation/ResponseTemplateCategories/ResponseTemplateCategoryRow';
import RWRuleRow from '../../Settings/Reputation/ReviewsWidget/RWRules/RWRuleRow';

import FeedbackTextTemplateRow from '../../Settings/Reputation/FeedbackTextTemplates/FeedbackTextTemplateRow';
import CompSetRow from "../../Settings/Reputation/CompSet/CompSetRow";

import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../redux/actions/snacksActions';

import { ACCESS_LEVELS, hasAccessToUserManagment, isLocationAdminAndAbove } from '../../../helpers';

import editIcon from '../../../img/icons/edit.svg';
import deleteIcon from '../../../img/icons/delete.svg';


const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}


function CategoryButton(props) {
  const onCategoryClick = (e) => {
    props.onCategoryClick(props.data);
  }
  return (
    <div className={'entry ' + (props.selectedCategory === props.data._id ? 'active' : '')} onClick={onCategoryClick}>
      {props.data.name}
    </div>
  );
}

function GenericList(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const [showDefaultModal, setShowDefaultModal] = useState(false);

  const [showDisableModal, setShowDisableModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('all');

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.generic_list change: ', props.generic_list);
      console.log('selectedTemplate: ', selectedTemplate);
    }
    if (selectedTemplate && selectedTemplate._id &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].is_removed !== selectedTemplate.is_removed
    ) {
      setShowDeleteModal(false);
    }
    if (selectedTemplate && selectedTemplate._id &&
      props.generic_list &&
      !props.generic_list.addInProgress &&
      props.generic_list.addStatus && props.generic_list.addStatus.success
    ) {
      setShowDuplicateModal(false);
    }
    if (selectedTemplate && selectedTemplate._id &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].is_disabled !== selectedTemplate.is_disabled
    ) {
      setShowDisableModal(false);
    }
    if (selectedTemplate && selectedTemplate._id &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].is_default !== selectedTemplate.is_default
    ) {
      setShowDefaultModal(false);
    }
  }, [props.generic_list]);

  const onRefreshClick = (e) => {
    if (props.onRefreshClick) {
      props.onRefreshClick(e);
    }
  };

  const onAddClick = (e) => {
    let op = '';
    if (props.type === 'messageTemplates') op = 'edit_message_template';
    if (props.type === 'whatsappTemplates') op = 'edit_whatsapp_template';
    if (props.type === 'labels') op = 'edit_engage_guest_label';
    if (props.type === 'rooms') op = 'edit_room';
    if (props.type === 'remote_lock') op = 'edit_remote_lock';
    if (props.type === 'rate_codes') op = 'edit_rate_code';
    if (props.type === 'booking_sources') op = 'edit_booking_source';
    if (props.type === 'sender_names') op = 'edit_sender_name';
    if (props.type === 'sender_emails') op = 'edit_sender_email';
    if (props.type === 'wc_rules') op = 'edit_webchat_rule';
    if (props.type === 'dg_pages') op = 'edit_digital_guide_page';
    if (props.type === 'dg_rooms') op = 'edit_digital_guide_page';
    if (props.type === 'users') op = 'edit_user_for_location';
    if (props.type === 'rg_users') op = 'edit_rg_user';
    if (props.type === 'responseTemplates') op = 'edit_response_template';
    if (props.type === 'promptTemplates') op = 'edit_prompt_template';
    if (props.type === 'responseTemplateCategory') op = 'edit_response_template_category';
    if (props.type === 'promptTemplateCategory') op = 'edit_prompt_template_category';
    if (props.type === 'rw_rules') op = 'edit_reviews_widget_rule';
    if (props.type === 'feedbackTextTemplates') op = 'edit_feedback_text_template';
    if (props.type === 'compSet') op = 'edit_comp_set_location';
    dispatch(editDataClearAction({
      op, // : 'edit_message_template',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
      },
    }));
    setSelectedTemplate({});
    setModalType('add');
    setShowModal(true);
  };
  const onRowEditClick = (templateData) => {
    setSelectedTemplate(templateData);
    setModalType('edit');
    if (props.type === 'dg_rooms') {
      if (!templateData.page) setModalType('add');
    }
    setShowModal(true);
  };
  const onRowDeleteClick = (templateData) => {
    if (props.type === 'promptTemplates' || props.type === 'feedbackTextTemplates') {
      if (templateData.is_default) {
        const id = new Date().getTime();
        dispatch(receiveSnack({
          snack: {
            id,
            msg: 'Default template cannot be deleted.',
          },
        }));
        // remove the snack
        setTimeout(() => {
          dispatch(removeSnack({
            id,
          }));
        }, 2000);
      } else {
        setSelectedTemplate(templateData);
        setShowModal(false);
        setShowDeleteModal(true);
        setShowDuplicateModal(false);
      }
    } else {
      setSelectedTemplate(templateData);
      setShowModal(false);
      setShowDeleteModal(true);
      setShowDuplicateModal(false);
    }

  };
  const onRowDefaultClick = (templateData) => {
    setSelectedTemplate(templateData);
    setShowModal(false);
    setShowDeleteModal(false);
    setShowDefaultModal(true);
    setShowDuplicateModal(false);
  };

  const onRowDuplicateClick = (templateData) => {
    if (props.type === 'dg_pages') {
      setSelectedTemplate(templateData);
      setShowModal(false);
      setShowDeleteModal(false);
      setShowDefaultModal(false);
      setShowDuplicateModal(true);
    }
  };

  const onRowResetPasswordClick = (templateData) => {
    setSelectedTemplate(templateData);
    setShowResetPasswordModal(true);
  };
  const onResetPasswordModalCancelClick = (templateData) => {
    setShowResetPasswordModal(false);
  };

  const onRowResetRgPasswordClick = (templateData) => {
    setSelectedTemplate(templateData);
    setShowResetPasswordModal(true);
  };
  const onResetRgPasswordModalCancelClick = (templateData) => {
    setShowResetPasswordModal(false);
  };

  const onRowUserDisableClick = (templateData) => {
    setSelectedTemplate(templateData);
    setShowModal(false);
    setShowDisableModal(true);
  };

  const onEnableDisableClick = (templateData) => {
    if (props.type === 'wc_rules') {
      const params = {
        location__id: props.location__id,
        mode: 'edit',
        rule_id: templateData._id,
        rule_data: {
          is_enabled: templateData.is_enabled,
        },
      };
      let op = 'edit_webchat_rule';
      dispatch(editDataAction({
        op, //: 'edit_message_template',
        params,
      }));
    }
    if (props.type === 'rw_rules') {
      const params = {
        location__id: props.location__id,
        mode: 'edit',
        rule_id: templateData._id,
        rule_data: {
          is_enabled: templateData.is_enabled,
        },
      };
      let op = 'edit_reviews_widget_rule';
      dispatch(editDataAction({
        op, //: 'edit_message_template',
        show_alert: true,
        alert_msg: 'Updated successfully',
        params,
      }));
    }
    if (props.type === 'dg_pages') {
      const params = {
        location__id: props.location__id,
        mode: 'edit',
        page_id: templateData._id,
        page_data: {
          is_enabled: templateData.is_enabled,
        },
      };
      let op = 'edit_digital_guide_page';
      dispatch(editDataAction({
        op, //: 'edit_message_template',
        show_alert: true,
        alert_msg: 'Updated successfully',
        params,
      }));
    }
    if (props.type === 'dg_rooms') {
      if (templateData.page && templateData.page._id) {
        const params = {
          location__id: props.location__id,
          mode: 'edit',
          room_id: templateData._id,
          page_id: templateData.page._id,
          page_data: {
            is_enabled: !templateData.page.is_enabled,
          },
        };
        let op = 'edit_digital_guide_room';
        dispatch(editDataAction({
          op, //: 'edit_message_template',
          show_alert: true,
          alert_msg: 'Updated successfully',
          params,
        }));
      }
    }

  }

  const onDeleteConfirmClick = (e) => {
    if (props.generic_list &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].editInProgress
    ) {
      return;
    }

    const params = {
      location__id: props.location__id,
      mode: 'remove',
    };
    let op = '';
    if (props.type === 'messageTemplates') {
      op = 'edit_message_template';
      params.message_template_id = selectedTemplate._id;
    }
    if (props.type === 'whatsappTemplates') {
      op = 'edit_message_template';
      params.message_template_id = selectedTemplate._id;
    }
    if (props.type === 'labels') {
      op = 'edit_engage_guest_label';
      params.label_id = selectedTemplate._id;
    }
    if (props.type === 'rooms') {
      op = 'edit_room';
      params.room_id = selectedTemplate._id;
    }
    if (props.type === 'remote_locks') {
      op = 'edit_remote_lock';
      params.remote_lock_id = selectedTemplate._id;
    }
    if (props.type === 'rate_codes') {
      op = 'edit_rate_code';
      params.rate_code_id = selectedTemplate._id;
    }
    if (props.type === 'booking_sources') {
      op = 'edit_booking_source';
      params.booking_source_id = selectedTemplate._id;
    }
    if (props.type === 'sender_names') {
      op = 'edit_sender_name';
      params.sender_name_id = selectedTemplate._id;
      params.name_or_email = 'name';
    }
    if (props.type === 'sender_emails') {
      op = 'edit_sender_email';
      params.sender_email_id = selectedTemplate._id;
      params.name_or_email = 'email';
    }
    if (props.type === 'wc_rules') {
      op = 'edit_webchat_rule';
      params.rule_id = selectedTemplate._id;
    }
    if (props.type === 'dg_pages' || props.type === 'dg_rooms') {
      op = 'edit_digital_guide_page';
      params.page_id = selectedTemplate._id;
    }
    if (props.type === 'users') {
      op = 'edit_user_for_location';
      params.user_id = selectedTemplate._id;
      params.username = selectedTemplate.username;
    }
    if (props.type === 'rg_users') {
      op = 'edit_rg_user';
      params.user_id = selectedTemplate._id;
      params.username = selectedTemplate.username;
    }
    if (props.type === 'responseTemplates') {
      op = 'edit_response_template';
      params.response_template_id = selectedTemplate._id;
    }
    if (props.type === 'promptTemplates') {
      op = 'edit_prompt_template';
      params.prompt_template_id = selectedTemplate._id;
    }
    if (props.type === 'responseTemplateCategory') {
      op = 'edit_response_template_category';
      params.response_template_category_id = selectedTemplate._id;
    }
    if (props.type === 'promptTemplateCategory') {
      op = 'edit_prompt_template_category';
      params.prompt_template_category_id = selectedTemplate._id;
    }
    if (props.type === 'rw_rules') {
      op = 'edit_reviews_widget_rule';
      params.rule_id = selectedTemplate._id;
    }
    if (props.type === 'feedbackTextTemplates') {
      op = 'edit_feedback_text_template';
      params.feedback_text_template_id = selectedTemplate._id;
    }
    if (props.type === 'compSet') {
      op = 'edit_comp_set_location';
      params.comp_set_location_id = selectedTemplate._id;
    }

    dispatch(editDataAction({
      op, //: 'edit_message_template',
      params,
    }));
  };


  const onDuplicateConfirmClick = (e) => {
    if (props.generic_list &&
      props.generic_list &&
      props.generic_list.addInProgress
    ) {
      return;
    }

    const params = {
      location__id: props.location__id,
      mode: 'add',
    };
    let op = '';
    if (props.type === 'dg_pages') {
      op = 'edit_digital_guide_page';
      params.page_data = {
        name: `Copy - ${props.generic_list.keyValue[selectedTemplate._id].name}`,
        sections: props.generic_list.keyValue[selectedTemplate._id].sections,
        copied_from: selectedTemplate._id,
      };
      dispatch(editDataAction({
        op, //: 'edit_message_template',
        params,
        show_alert: true,
        alert_msg: `Added successfully`,
      }));
    }
  };


  const onDefaultConfirmClick = (e) => {
    if (props.generic_list &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].editInProgress
    ) {
      return;
    }

    const params = {
      location__id: props.location__id,
      mode: 'edit',
    };
    let op = '';
    if (props.type === 'promptTemplates') {
      op = 'edit_prompt_template';
      params.prompt_template_id = selectedTemplate._id;
      if (selectedTemplate.is_default) {
        params.is_default = false;
      } else {
        params.is_default = true;
      }
    }
    if (props.type === 'feedbackTextTemplates') {
      op = 'edit_feedback_text_template';
      params.feedback_text_template_id = selectedTemplate._id;
      if (selectedTemplate.is_default) {
        params.is_default = false;
      } else {
        params.is_default = true;
      }
    }
    if (props.type === 'compSet') {
      op = 'edit_comp_set_location';
      params.comp_set_location_id = selectedTemplate._id;
      if (selectedTemplate.is_default) {
        params.is_default = false;
      } else {
        params.is_default = true;
      }
    }
    dispatch(editDataAction({
      op,
      params,
    }));
  };


  const onDisableConfirmClick = (e) => {
    if (props.generic_list &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].editInProgress
    ) {
      return;
    }

    const params = {
      location__id: props.location__id,
      mode: 'disable',
    };
    let op = '';
    if (props.type === 'users') {
      op = 'edit_user_for_location';
      params.user_id = selectedTemplate._id;
      params.username = selectedTemplate.username;
      params.is_disabled = selectedTemplate.is_disabled ? false : true;
    }
    if (props.type === 'rg_users') {
      op = 'edit_rg_user';
      params.user_id = selectedTemplate._id;
      params.username = selectedTemplate.username;
      params.is_disabled = selectedTemplate.is_disabled ? false : true;
    }

    dispatch(editDataAction({
      op, //: 'edit_message_template',
      params,
      show_alert: true,
      alert_msg: `${selectedTemplate.is_disabled ? 'Enabled' : 'Disabled'} successfully`,
    }));
  };

  const onCloseModal = (e) => {
    if (props.generic_list &&
      props.generic_list.keyValue &&
      props.generic_list.keyValue[selectedTemplate._id] &&
      props.generic_list.keyValue[selectedTemplate._id].editInProgress
    ) {
      return;
    }
    setShowModal(false);
    setSelectedTemplate({});
  };

  const onSearchInputChange = (e) => {
    setKeyword(e.target.value);
    setSelectedCategory('all');
  };
  const onSearchTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
    }
  };

  const onCategoryClick = (category) => {
    setSelectedCategory(category._id);
  };

  const renderResetPasswordModal = (template) => {
    if (props.type === 'rg_users') {
      return (
        <Modal>
          <div id="cModelId" className="cModal">
            <div className="content">
              <ResetRgPasswordModal
                onCancelClick={onResetRgPasswordModalCancelClick}
                user={template}
                settings={props.settings}
              />
            </div>
          </div>
        </Modal>
      );
    }
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <ResetPasswordModal
              onCancelClick={onResetPasswordModalCancelClick}
              user={template}
              settings={props.settings}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderModal = () => {
    if (props.type === 'messageTemplates') {
      return (
        <GenericSideModal
          modalType="messageTemplateGeneric"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'whatsappTemplates') {
      return (
        <GenericSideModal
          modalType="addWhatsappMessageTemplate"
          className="mtGen"
          data={{
            mode: '',
            messageType: "starter",
            template: {},
          }}
          extData={{
            title: 'Add WhatsApp Starter Template',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'labels') {
      return (
        <GenericSideModal
          modalType="label"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'rooms') {
      return (
        <GenericSideModal
          modalType="room"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'remote_locks') {
      return (
        <GenericSideModal
          modalType="room"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'rate_codes') {
      return (
        <GenericSideModal
          modalType="rate_code"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'booking_sources') {
      return (
        <GenericSideModal
          modalType="booking_source"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'sender_names') {
      return (
        <GenericSideModal
          modalType="sender_name"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'sender_emails') {
      return (
        <GenericSideModal
          modalType="sender_email"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'wc_rules') {
      let tempSelectedTemplate = { ...selectedTemplate };
      if (selectedTemplate && selectedTemplate._id && props.generic_list && props.generic_list.keyValue && props.generic_list.keyValue[selectedTemplate._id]) {
        tempSelectedTemplate = { ...props.generic_list.keyValue[selectedTemplate._id] };
      }
      return (
        <GenericSideModal
          modalType="wc_rule"
          className="mtGen"
          data={{
            ...tempSelectedTemplate, // ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'dg_pages') {
      let tempSelectedTemplate = { ...selectedTemplate };
      if (selectedTemplate && selectedTemplate._id && props.generic_list && props.generic_list.keyValue && props.generic_list.keyValue[selectedTemplate._id]) {
        tempSelectedTemplate = { ...props.generic_list.keyValue[selectedTemplate._id] };
      }
      return (
        <GenericSideModal
          modalType="dg_page"
          className="mtGen dgPg"
          data={{
            ...tempSelectedTemplate, // ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'dg_rooms') {
      let tempSelectedTemplate = { ...selectedTemplate };
      if (selectedTemplate && selectedTemplate._id && props.generic_list && props.generic_list.keyValue && props.generic_list.keyValue[selectedTemplate._id]) {
        tempSelectedTemplate = { ...props.generic_list.keyValue[selectedTemplate._id] };
      }
      return (
        <GenericSideModal
          modalType="dg_room"
          className="mtGen dgPg"
          data={{
            ...tempSelectedTemplate, // ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'users') {
      let tempSelectedTemplate = { ...selectedTemplate };
      if (selectedTemplate && selectedTemplate._id && props.generic_list && props.generic_list.keyValue && props.generic_list.keyValue[selectedTemplate._id]) {
        tempSelectedTemplate = { ...props.generic_list.keyValue[selectedTemplate._id] };
      }
      return (
        <GenericSideModal
          modalType="user"
          className="usrSingle"
          data={{
            ...tempSelectedTemplate, // ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'rg_users') {
      let tempSelectedTemplate = { ...selectedTemplate };
      if (selectedTemplate && selectedTemplate._id && props.generic_list && props.generic_list.keyValue && props.generic_list.keyValue[selectedTemplate._id]) {
        tempSelectedTemplate = { ...props.generic_list.keyValue[selectedTemplate._id] };
      }
      return (
        <GenericSideModal
          modalType="rg_user"
          className="usrSingle"
          data={{
            ...tempSelectedTemplate, // ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            users: props.users
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'responseTemplates') {
      return (
        <GenericSideModal
          modalType="responseTemplate"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
            reputationTemplateType: 'responseTemplate',
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'promptTemplates') {
      return (
        <GenericSideModal
          modalType="promptTemplate"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
            reputationTemplateType: 'promptTemplate',
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'responseTemplateCategory') {
      return (
        <GenericSideModal
          modalType="responseTemplateCategory"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
            reputationCategoryType: 'responseTemplateCategory',
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'promptTemplateCategory') {
      return (
        <GenericSideModal
          modalType="promptTemplateCategory"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
            reputationCategoryType: 'promptTemplateCategory',
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'rw_rules') {
      let tempSelectedTemplate = { ...selectedTemplate };
      if (selectedTemplate && selectedTemplate._id && props.generic_list && props.generic_list.keyValue && props.generic_list.keyValue[selectedTemplate._id]) {
        tempSelectedTemplate = { ...props.generic_list.keyValue[selectedTemplate._id] };
      }
      return (
        <GenericSideModal
          modalType="rw_rule"
          className="mtGen"
          data={{
            ...tempSelectedTemplate, // ...selectedTemplate,
            opName: modalType,
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'feedbackTextTemplates') {
      return (
        <GenericSideModal
          modalType="feedbackTextTemplate"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
            reputationTemplateType: 'feedbackTextTemplate',
          }}
          extData={{
            title: '',
            settings: props.settings
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    if (props.type === 'compSet') {
      return (
        <GenericSideModal
          modalType="compSet"
          className="mtGen"
          data={{
            ...selectedTemplate,
            opName: modalType,
            // reputationTemplateType: 'feedbackTextTemplate',
          }}
          extData={{
            title: '',
            settings: props.settings,
            googlePlaceId: props.googlePlaceId,
          }}
          setModalVisible={onCloseModal}
          inProgress={false}
          showCancel={false}
          affirmativeButtonLabel={'OK'}
          disableOutsideClick={true}
        />
      );
    }
    return null;
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('GenericList props: ', props);
  }

  if (
    props.type !== 'messageTemplates' &&
    props.type !== 'whatsappTemplates' &&
    props.type !== 'labels' &&
    props.type !== 'rooms' &&
    props.type !== 'remote_locks' &&
    props.type !== 'rate_codes' &&
    props.type !== 'booking_sources' &&
    props.type !== 'sender_names' &&
    props.type !== 'sender_emails' &&
    props.type !== 'wc_rules' &&
    props.type !== 'dg_pages' &&
    props.type !== 'dg_rooms' &&
    props.type !== 'users' &&
    props.type !== 'rg_users' &&
    props.type !== 'responseTemplates' &&
    props.type !== 'promptTemplates' &&
    props.type !== 'responseTemplateCategory' &&
    props.type !== 'promptTemplateCategory' &&
    props.type !== 'rw_rules' &&
    props.type !== 'feedbackTextTemplates' &&
    props.type !== 'compSet'
  ) {
    return null;
  }
  const { generic_list, added_whatsapp_templates, booking_sources_all, rate_codes_all, room_numbers_all, room_types_all,
    response_template_categories, prompt_template_categories
  } = props;

  let deleteModalRowOne = '';
  if (props.type === 'messageTemplates') {
    deleteModalRowOne = `Do you want to delete this templete (${selectedTemplate.title}) ? This action cannot be undone!`
  }
  if (props.type === 'labels') {
    deleteModalRowOne = `Do you want to delete this label (${selectedTemplate.label}) ? This action cannot be undone!`;
  }
  if (props.type === 'rooms') {
    deleteModalRowOne = `Do you want to delete this room (${selectedTemplate.label}) ? This action cannot be undone!`;
  }
  if (props.type === 'remote_locks') {
    deleteModalRowOne = `Do you want to delete this remote lock (${selectedTemplate.label}) ? This action cannot be undone!`;
  }
  if (props.type === 'rate_codes') {
    deleteModalRowOne = `Do you want to delete this rate code (${selectedTemplate.label}) ? This action cannot be undone!`;
  }
  if (props.type === 'booking_sources') {
    deleteModalRowOne = `Do you want to delete this booking source (${selectedTemplate.label}) ? This action cannot be undone!`;
  }
  if (props.type === 'sender_names') {
    deleteModalRowOne = `Do you want to delete this sender name (${selectedTemplate.value}) ? This action cannot be undone!`;
  }
  if (props.type === 'sender_emails') {
    deleteModalRowOne = `Do you want to delete this sender email (${selectedTemplate.value}) ? This action cannot be undone!`;
  }
  if (props.type === 'wc_rules') {
    deleteModalRowOne = `Do you want to delete this rule (${selectedTemplate.name}) ? This action cannot be undone!`;
  }
  if (props.type === 'dg_pages') {
    deleteModalRowOne = `Do you want to delete this page (${selectedTemplate.name}) ? This action cannot be undone!`;
  }
  if (props.type === 'users') {
    deleteModalRowOne = `Do you want to delete this user (${selectedTemplate.username}) ? This action cannot be undone!`;
  }
  if (props.type === 'rg_users') {
    deleteModalRowOne = `Do you want to delete this user (${selectedTemplate.username}) ? This action cannot be undone!`;
  }
  if (props.type === 'responseTemplates') {
    deleteModalRowOne = `Do you want to delete this response template (${selectedTemplate.title}) ? This action cannot be undone!`;
  }
  if (props.type === 'promptTemplates') {
    deleteModalRowOne = `Do you want to delete this guestAI™ template (${selectedTemplate.title}) ? This action cannot be undone!`;
  }
  if (props.type === 'responseTemplateCategory') {
    deleteModalRowOne = `Do you want to delete this response template category (${selectedTemplate.name}) ? This action cannot be undone!`;
  }
  if (props.type === 'promptTemplateCategory') {
    deleteModalRowOne = `Do you want to delete this guestAI™ template category (${selectedTemplate.name}) ? This action cannot be undone!`;
  }
  if (props.type === 'rw_rules') {
    deleteModalRowOne = `Do you want to delete this rule (${selectedTemplate.name}) ? This action cannot be undone!`;
  }
  if (props.type === 'feedbackTextTemplates') {
    deleteModalRowOne = `Do you want to delete this feedback text template (${selectedTemplate.title}) ? This action cannot be undone!`;
  }
  if (props.type === 'compSet') {
    deleteModalRowOne = `Do you want to delete this comp set entry (${selectedTemplate.name}) ? This action cannot be undone!`;
  }

  let defaultModalRowOne = '';
  if (props.type === 'promptTemplates') {
    defaultModalRowOne = `Do you want to ${selectedTemplate.is_default ? 'unmark' : 'mark'} this template (${selectedTemplate.title}) as default ?`
  }

  let disableModalRowOne = '';
  if (props.type === 'users') {
    disableModalRowOne = `Do you want to ${selectedTemplate.is_disabled ? 'enable' : 'disable'} this user (${selectedTemplate.username}) ?`
  }
  if (props.type === 'rg_users') {
    disableModalRowOne = `Do you want to ${selectedTemplate.is_disabled ? 'enable' : 'disable'} this user (${selectedTemplate.username}) ?`
  }
  let disableModalRowTwo = '';
  if (props.type === 'users') {
    disableModalRowTwo = 'You’ll be able to revert this later.'
  }
  if (props.type === 'rg_users') {
    disableModalRowTwo = 'You’ll be able to revert this later.'
  }

  let duplicateModalRowOne = '';
  if (props.type === 'dg_pages') {
    duplicateModalRowOne = `Do you want to create a duplicate of this guide page (${selectedTemplate.name}) ?`
  }

  let userMgmtAccess = hasAccessToUserManagment(userState.user.user_info.profile.access_level) &&
    userState.user.user_info.profile.access_level !== ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;
  if (userState.user.user_info.profile && userState.user.user_info.profile.locations &&
    userState.user.user_info.profile.locations[props.location__id] &&
    userState.user.user_info.profile.locations[props.location__id].access_level &&
    hasAccessToUserManagment(userState.user.user_info.profile.locations[props.location__id].access_level)
  ) {
    userMgmtAccess = true;
  }
  let rgUserMgmtAccess = userState.user.user_info.profile.rg_access_level === ACCESS_LEVELS.RG_ADMIN;

  let editAccess = isLocationAdminAndAbove(userState.user.user_info.profile.access_level) &&
      userState.user.user_info.profile.access_level !== ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;
  if (process.env.NODE_ENV !== 'production') {
    console.log('GenericList userMgmtAccess: ', userMgmtAccess);
    console.log('GenericList editAccess: ', editAccess);
  }

  return (
    <div className={'gPScrlWrp engSetGen flHt ' + (props.className ? props.className : '')}>
      <div className="gPWrp">
        <div className="gPTle title add">
          {props.title}
          {
            props.type === 'wc_rules' && props.generic_list.list ? ` (${props.generic_list.list.length})` : ''
          }
          {
            props.type === 'dg_pages' && props.generic_list.list ? ` (${props.generic_list.list.length})` : ''
          }
          {
            props.type === 'dg_rooms' && props.generic_list.list ? ` (${props.generic_list.list.length})` : ''
          }
          {
            (
              props.type === 'messageTemplates' ||
              props.type === 'whatsappTemplates' ||
              props.type === 'labels' ||
              props.type === 'rooms' ||
              props.type === 'rate_codes' ||
              props.type === 'booking_sources' ||
              props.type === 'sender_names' ||
              props.type === 'sender_emails' ||
              props.type === 'wc_rules' ||
              props.type === 'dg_pages' ||
              props.type === 'responseTemplates' ||
              props.type === 'promptTemplates' ||
              props.type === 'responseTemplateCategory' ||
              props.type === 'promptTemplateCategory' ||
              props.type === 'rw_rules' ||
              props.type === 'feedbackTextTemplates' ||
              props.type === 'compSet'
            ) && editAccess &&
            <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
          }
          {
            props.type === 'users' && userMgmtAccess &&
            <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
          }
          {
            props.type === 'rg_users' && rgUserMgmtAccess &&
            <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
          }
          {
            props.show_refresh &&
            <span className="add" onClick={onRefreshClick}><i className="fa fa-refresh" /></span>
          }
        </div>
        {
          props.sub_title &&
          <div className={'gPSubTle'}>
            {props.sub_title}
          </div>
        }
        {
          props.type === "booking_sources"
          ?
            <div className="gPSubTle">
              All possible booking sources from all guests:
              {
                booking_sources_all && booking_sources_all.length > 0
                && <span>{booking_sources_all.join(', ')}</span>
              }
            </div>
          : null
        }
        {
          props.type === "rate_codes"
          ?
            <div className="gPSubTle">
              All possible rate codes from all guests:
              {
                rate_codes_all && rate_codes_all.length > 0
                && <span>{rate_codes_all.join(', ')}</span>
              }
            </div>
          : null
        }
        {
          props.type === "rooms"
          ?
            <div className="gPSubTle">
              All possible room types from all guests:
              {
                room_types_all && room_types_all.length > 0
                && <span>{room_types_all.join(', ')}</span>
              }
            </div>
          : null
        }
        {
          props.type === "rooms"
          ?
            <div className="gPSubTle">
              All possible room numbers from all guests:
              {
                room_numbers_all && room_numbers_all.length > 0
                && <span>{room_numbers_all.join(', ')}</span>
              }
            </div>
          : null
        }
        <div className="gBCtnt">
          <div className="gBCtRw srchRw">
            <div className="lt">
              <div className="gIpt">
                <input type="text" value={keyword} onChange={onSearchInputChange} placeholder="Search"
                  onKeyDown={onSearchTermKeyDown}
                />
              </div>
            </div>
          </div>
          {
            props.type === "responseTemplates" &&
            <div className="gBCtRw respTmplCatListRw">
              <div className="lt">
                <CategoryButton data={{ _id: 'all', name: 'All' }} selectedCategory={selectedCategory}
                  onCategoryClick={onCategoryClick}
                />
                {
                  response_template_categories && response_template_categories.list && response_template_categories.list.length &&
                  response_template_categories.keyValue
                  ? response_template_categories.list.map((item) => {
                      return <CategoryButton data={response_template_categories.keyValue[item]} selectedCategory={selectedCategory}
                        onCategoryClick={onCategoryClick}
                      />
                    })
                  : null
                }
              </div>
            </div>
          }
          {
            props.type === "promptTemplates" && !props.hide_categories &&
            <div className="gBCtRw respTmplCatListRw">
              <div className="lt">
                <CategoryButton data={{ _id: 'all', name: 'All' }} selectedCategory={selectedCategory}
                  onCategoryClick={onCategoryClick}
                />
                {
                  response_template_categories && response_template_categories.list && response_template_categories.list.length &&
                  response_template_categories.keyValue
                  ? response_template_categories.list.map((item) => {
                      return <CategoryButton data={response_template_categories.keyValue[item]} selectedCategory={selectedCategory}
                        onCategoryClick={onCategoryClick}
                      />
                    })
                  : null
                }
              </div>
            </div>
          }
          {
            props.type === "messageTemplates" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    generic_list.keyValue[item].msg.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <MessageTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                return (
                  <MessageTemplateRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "whatsappTemplates" && generic_list && generic_list.length
            ?
              generic_list.filter(el => el.type === 'starter').map((item) => {
                if (keyword) {
                  if (
                    item.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    item.msg.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <WhatsappTemplateRow
                        key={`glr_${props.type}_${item._id}`}
                        template={item}
                        keyword={keyword}
                      />
                    );

                  }
                  return null;
                }
                return (
                  <WhatsappTemplateRow
                    key={`glr_${props.type}_${item._id}`}
                    template={item}
                    keyword={keyword}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "whatsappTemplates" && added_whatsapp_templates && added_whatsapp_templates.keyValue &&
            added_whatsapp_templates.list && added_whatsapp_templates.list.length
            ?
              added_whatsapp_templates.list.map((item) => {
                if (!added_whatsapp_templates.keyValue[item] || added_whatsapp_templates.keyValue[item].is_removed) return null;
                if (added_whatsapp_templates.keyValue[item].type !== 'starter') return null;
                if (keyword) {
                  if (
                    added_whatsapp_templates.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    added_whatsapp_templates.keyValue[item].msg.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <WhatsappTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={added_whatsapp_templates.keyValue[item]}
                        keyword={keyword}
                      />
                    );

                  }
                  return null;
                }
                return (
                  <WhatsappTemplateRow
                    key={`glr_${props.type}_${item}`}
                    template={added_whatsapp_templates.keyValue[item]}
                    keyword={keyword}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "labels" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].label.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <LabelRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <LabelRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "rooms" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].label.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <RoomRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        settings={props.settings}
                        accessLevel={props.accessLevel}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <RoomRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    settings={props.settings}
                    accessLevel={props.accessLevel}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "remote_locks" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    (
                      generic_list.keyValue[item] &&
                      generic_list.keyValue[item].attributes &&
                      generic_list.keyValue[item].attributes.name &&
                      generic_list.keyValue[item].attributes.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                    )
                  ) {
                    return (
                      <RemoteLockRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        nonEditable={true}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <RemoteLockRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    nonEditable={true}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "rate_codes" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].label.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <RateCodeRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        accessLevel={props.accessLevel}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <RateCodeRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    accessLevel={props.accessLevel}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "booking_sources" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].label.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <BookingSourceRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        accessLevel={props.accessLevel}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <BookingSourceRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    accessLevel={props.accessLevel}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "sender_names" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].value.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <SenderNameRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <SenderNameRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "sender_emails" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].value.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <SenderEmailRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={() => { props.onDeleteClick(generic_list.keyValue[item]) }}
                        onVerifyClick={() => { props.onVerifyClick(generic_list.keyValue[item]) }}
                        onRefreshClick={() => { props.onRefreshClick(generic_list.keyValue[item]) }}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <SenderEmailRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={() => { props.onDeleteClick(generic_list.keyValue[item]) }}
                    onVerifyClick={() => { props.onVerifyClick(generic_list.keyValue[item]) }}
                    onRefreshClick={() => { props.onRefreshClick(generic_list.keyValue[item]) }}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "wc_rules" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <WCRuleRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onEnableDisableClick={onEnableDisableClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <WCRuleRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onEnableDisableClick={onEnableDisableClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "dg_pages" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <DGPageRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDuplicateClick={onRowDuplicateClick}
                        onDeleteClick={onRowDeleteClick}
                        onEnableDisableClick={onEnableDisableClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <DGPageRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDuplicateClick={onRowDuplicateClick}
                    onDeleteClick={onRowDeleteClick}
                    onEnableDisableClick={onEnableDisableClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "dg_rooms" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <DGRoomRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onEnableDisableClick={onEnableDisableClick}
                        keyword={keyword}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <DGRoomRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onEnableDisableClick={onEnableDisableClick}
                    keyword={keyword}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "users" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].username.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <UserRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onResetPasswordClick={onRowResetPasswordClick}
                        onDisableClick={onRowUserDisableClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        userState={userState}
                        nonEditable={!userMgmtAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <UserRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onResetPasswordClick={onRowResetPasswordClick}
                    onDisableClick={onRowUserDisableClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    userState={userState}
                    nonEditable={!userMgmtAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "rg_users" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].username.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <RgUserRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onResetPasswordClick={onRowResetRgPasswordClick}
                        onDisableClick={onRowUserDisableClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        userState={userState}
                        nonEditable={!rgUserMgmtAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <RgUserRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onResetPasswordClick={onRowResetRgPasswordClick}
                    onDisableClick={onRowUserDisableClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    userState={userState}
                    nonEditable={!rgUserMgmtAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "responseTemplates" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                const categoryId = generic_list.keyValue[item].category_id || '';
                const categoryName = categoryId && response_template_categories.keyValue[categoryId]
                  ? response_template_categories.keyValue[categoryId].name || '' : '';
                if (keyword) {
                  if (
                    generic_list.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    generic_list.keyValue[item].response.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    (categoryName && categoryName.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
                  ) {
                    return (
                      <ResponseTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        templateType={props.type}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                if (selectedCategory !== 'all') {
                  if (selectedCategory === categoryId) {
                    return (
                      <ResponseTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        templateType={props.type}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    )
                  }
                  return null;
                }
                return (
                  <ResponseTemplateRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    templateType={props.type}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onDefaultClick={onRowDefaultClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "promptTemplates" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                const categoryId = generic_list.keyValue[item].category_id || '';
                const categoryName = categoryId && response_template_categories.keyValue[categoryId]
                  ? response_template_categories.keyValue[categoryId].name || '' : '';
                if (keyword) {
                  if (
                    generic_list.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    generic_list.keyValue[item].prompt.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    (categoryName && categoryName.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
                  ) {
                    return (
                      <ResponseTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        templateType={props.type}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                if (selectedCategory !== 'all') {
                  if (selectedCategory === categoryId) {
                    return (
                      <ResponseTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        templateType={props.type}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    )
                  }
                  return null;
                }
                return (
                  <ResponseTemplateRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    templateType={props.type}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onDefaultClick={onRowDefaultClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "responseTemplateCategory" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <ResponseTemplateCategoryRow
                        key={`glr_${props.type}_${item}`}
                        categoryType={props.type}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                return (
                  <ResponseTemplateCategoryRow
                    key={`glr_${props.type}_${item}`}
                    categoryType={props.type}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "promptTemplateCategory" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <ResponseTemplateCategoryRow
                        key={`glr_${props.type}_${item}`}
                        categoryType={props.type}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                return (
                  <ResponseTemplateCategoryRow
                    key={`glr_${props.type}_${item}`}
                    categoryType={props.type}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "rw_rules" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <RWRuleRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onEnableDisableClick={onEnableDisableClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <RWRuleRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onEnableDisableClick={onEnableDisableClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "feedbackTextTemplates" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    generic_list.keyValue[item].msg.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    generic_list.keyValue[item].type.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <FeedbackTextTemplateRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        templateType={props.type}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                // if (selectedCategory !== 'all') {
                //   if (selectedCategory === categoryId) {
                //     return (
                //       <ResponseTemplateRow
                //         key={`glr_${props.type}_${item}`}
                //         template={generic_list.keyValue[item]}
                //         templateType={props.type}
                //         onEditClick={onRowEditClick}
                //         onDeleteClick={onRowDeleteClick}
                //         onDefaultClick={onRowDefaultClick}
                //         keyword={keyword}
                //         settings={props.settings}
                //         nonEditable={!editAccess}
                //       />
                //     )
                //   }
                //   return null;
                // }
                return (
                  <FeedbackTextTemplateRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    templateType={props.type}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onDefaultClick={onRowDefaultClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
          {
            props.type === "compSet" && generic_list && generic_list.keyValue && generic_list.list && generic_list.list.length
            ?
              generic_list.list.map((item) => {
                if (!generic_list.keyValue[item] || generic_list.keyValue[item].is_removed) return null;
                if (keyword) {
                  if (
                    generic_list.keyValue[item].name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                  ) {
                    return (
                      <CompSetRow
                        key={`glr_${props.type}_${item}`}
                        template={generic_list.keyValue[item]}
                        templateType={props.type}
                        onEditClick={onRowEditClick}
                        onDeleteClick={onRowDeleteClick}
                        onDefaultClick={onRowDefaultClick}
                        keyword={keyword}
                        settings={props.settings}
                        nonEditable={!editAccess}
                      />
                    );

                  }
                  return null;
                }
                return (
                  <CompSetRow
                    key={`glr_${props.type}_${item}`}
                    template={generic_list.keyValue[item]}
                    templateType={props.type}
                    onEditClick={onRowEditClick}
                    onDeleteClick={onRowDeleteClick}
                    onDefaultClick={onRowDefaultClick}
                    keyword={keyword}
                    settings={props.settings}
                    nonEditable={!editAccess}
                  />
                );
                return null;
              })
            : null
          }
        </div>
      </div>
      { showModal && renderModal() }
      { showDeleteModal &&
          <GenericAlertModal
            modalType="Delete Template"
            bodyRowOne={deleteModalRowOne}
            bodyRowNote={'You’ll not be able to retrieve the contents later.'}
            keyName={selectedTemplate.title}
            setModalVisible={setShowDeleteModal}
            onAffirmativeClick={onDeleteConfirmClick}
            inProgress={
              props.generic_list &&
              props.generic_list &&
              props.generic_list.keyValue[selectedTemplate._id]
              ? props.generic_list.keyValue[selectedTemplate._id].editInProgress
              : false
            }
            showCancel={true}
          />
        }
        { showDuplicateModal &&
            <GenericAlertModal
              modalType="Duplicate Template"
              bodyRowOne={duplicateModalRowOne}
              bodyRowNote={''}
              keyName={selectedTemplate.title}
              setModalVisible={setShowDuplicateModal}
              onAffirmativeClick={onDuplicateConfirmClick}
              inProgress={
                props.generic_list
                ? props.generic_list.addInProgress
                : false
              }
              showCancel={true}
            />
          }
        { showDefaultModal &&
            <GenericAlertModal
              modalType="Default Template"
              bodyRowOne={defaultModalRowOne}
              bodyRowNote={selectedTemplate.is_default ? 'You can undo this action later.' : 'Only one template can be marked as Default. You can undo this action later.'}
              keyName={selectedTemplate.title}
              setModalVisible={setShowDefaultModal}
              onAffirmativeClick={onDefaultConfirmClick}
              inProgress={
                props.generic_list &&
                props.generic_list &&
                props.generic_list.keyValue[selectedTemplate._id]
                ? props.generic_list.keyValue[selectedTemplate._id].editInProgress
                : false
              }
              showCancel={true}
            />
          }
        { showDisableModal &&
            <GenericAlertModal
              modalType="Disable Template"
              bodyRowOne={disableModalRowOne}
              bodyRowNote={disableModalRowTwo}
              keyName={selectedTemplate.username}
              setModalVisible={setShowDisableModal}
              onAffirmativeClick={onDisableConfirmClick}
              inProgress={
                props.generic_list &&
                props.generic_list &&
                props.generic_list.keyValue[selectedTemplate._id]
                ? props.generic_list.keyValue[selectedTemplate._id].editInProgress
                : false
              }
              showCancel={true}
            />
          }
        { showResetPasswordModal && renderResetPasswordModal(props.generic_list.keyValue[selectedTemplate._id]) }
    </div>
  );
}

export default GenericList;
