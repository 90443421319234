import React, {Component, useEffect, useRef, useState} from 'react';
import {withFauxDOM} from 'react-faux-dom'

import * as d3 from "d3";
import './style.css';

const MIN_BAR_WIDTH = 24;

function ChartTypeButton(props) {
  const onButtonClick = (e) => { props.onButtonClick(props.value); }
  return (
    <div className={'entry ' + (props.className ? props.className : '') + (props.selected ? ' active' : '')} onClick={onButtonClick}>
      <span>{props.label}</span>
    </div>
  );
}

function TimePeriodButton(props) {
  const onButtonClick = (e) => { props.onButtonClick(props.value); }
  return (
    <div className={'entry ' + (props.selected ? 'active' : '')} onClick={onButtonClick}>
      <span>{props.label}</span>
    </div>
  );
}

function ReviewRatingChart(props) {
  const svgRef = useRef();
  const [margin, setMargin] = useState({ top: 60, right: 60, bottom: 60, left: 60 });
  const [barWidth, setBarWidth] = useState(40);
  const [data, setData] = useState([]);
  const [listData, setListData] = useState([]);
  const [canvasHeight, setCanvasHeight] = useState(360);
  const [canvasWidth, setCanvasWidth] = useState(360);

  const [chartUpdating, setChartUpdating] = useState(false);

  const [selectedChartType, setSelectedChartType] = useState('ratings');
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('weekly');

  const [selectedLocIndex, setSelectedLocIndex] = useState('all');
 

  useEffect(() => {
    setCanvasHeight(props.height);
    // setCanvasWidth(props.windowprops.window.windowWidth/2 - 100);
    setCanvasWidth(props.width - 120 - 20);
    setData([
      { period: 'jan', value: 2, rating: 4 },
      { period: 'feb', value: 14, rating: 5},
      { period: 'mar', value: 6, rating: 1 },
      { period: 'apr', value: 8, rating: 2 },
      { period: 'may', value: 10, rating: 5 },
    ]);
    setListData(props.listData);
    drawChart();
  }, []);

  useEffect(() => {
    if (props.data) {
      // console.log('props.data changed: ', props.data);
      let tempCanvasWidth = props.width - 120 - 20;
      if (selectedTimePeriod === 'daily' && props.data.daily) {
        if (tempCanvasWidth/props.data.daily.length < MIN_BAR_WIDTH) {
          // change it to monthly
          if (tempCanvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
            setData(props.data.monthly);
            setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
            setSelectedTimePeriod('monthly');
          } else {
            // change it to weekly
            setData(props.data.weekly);
            setBarWidth((tempCanvasWidth/props.data.weekly.length)/2);
            setSelectedTimePeriod('weekly');
          }
        } else {
          setData(props.data.daily);
          setBarWidth((tempCanvasWidth/props.data.daily.length)/2);
        }
      }
      if (selectedTimePeriod === 'weekly' && props.data.weekly) {
        // change it to monthly
        if (tempCanvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
          setData(props.data.monthly);
          setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
          setSelectedTimePeriod('monthly');
        } else {
          // change it to weekly
          setData(props.data.weekly);
          setBarWidth((tempCanvasWidth/props.data.weekly.length)/2);
          setSelectedTimePeriod('weekly');
        }
      }
      if (selectedTimePeriod === 'monthly' && props.data.monthly) {
        setData(props.data.monthly);
        setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
      }
    }
    if (props.hideRating) {
      setSelectedChartType('reviews');
    }
    // console.log('showOnlyDaily: ', props.showOnlyDaily);
    if (props.showOnlyDaily) {
      setSelectedTimePeriod('daily');
    }
  }, [props.data, props.hideRating, props.showOnlyDaily]);

  useEffect(() => {
    // console.log('data changed');
    setChartUpdating(true);
    setTimeout(() => {
      updateChart(selectedChartType, selectedTimePeriod, selectedLocIndex);
    }, 50);
  }, [data, canvasWidth, canvasHeight]);

  useEffect(() => {
    setCanvasHeight(props.height);
    setCanvasWidth(props.width - 120 - 20);
    setTimeout(() => {
      updateChart(selectedChartType, selectedTimePeriod, selectedLocIndex);
  }, 50);
  }, [props.windowState.window.windowHeight]);

  const onChartTypeButtonClick = (chartType) => {
    setSelectedChartType(chartType);
    setTimeout(() => {
      updateChart(chartType, selectedTimePeriod, selectedLocIndex);
    }, 50);
  }

  const onChartPrimaryLocClick = () => {
    setSelectedLocIndex('');
    setTimeout(() => {
      updateChart(selectedChartType, selectedTimePeriod, '');
    }, 50);
  }
  const onChartLocButtonClick = (locIndex) => {
    setSelectedLocIndex(locIndex);
    setTimeout(() => {
      updateChart(selectedChartType, selectedTimePeriod, locIndex);
    }, 50);
  }

  const onTimePeriodButtonClick = (timePeriod) => {
    // console.log('onTimePeriodButtonClick: ', timePeriod);
    if (selectedTimePeriod === timePeriod) return;
    setSelectedTimePeriod(timePeriod);
    if (timePeriod === 'monthly') {
      if (props.data.monthly) {
        setData(props.data.monthly);
        setBarWidth((canvasWidth/props.data.monthly.length)/2);
        setChartUpdating(true);
      }
    }
    if (timePeriod === 'weekly') {
      // change it to monthly
      if (canvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
        setData(props.data.monthly);
        setBarWidth((canvasWidth/props.data.monthly.length)/2);
        setSelectedTimePeriod('monthly');
        setChartUpdating(true);
      } else {
        // change it to weekly
        setData(props.data.weekly);
        setBarWidth((canvasWidth/props.data.weekly.length)/2);
        setSelectedTimePeriod('weekly');
        setChartUpdating(true);
      }
    }
    if (timePeriod === 'daily') {
      if (canvasWidth/props.data.daily.length < MIN_BAR_WIDTH) {
        // change it to monthly
        if (canvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
          setData(props.data.monthly);
          setBarWidth((canvasWidth/props.data.monthly.length)/2);
          setSelectedTimePeriod('monthly');
          setChartUpdating(true);
        } else {
          // change it to weekly
          setData(props.data.weekly);
          setBarWidth((canvasWidth/props.data.weekly.length)/2);
          setSelectedTimePeriod('weekly');
          setChartUpdating(true);
        }
      } else {
        setData(props.data.daily);
        setBarWidth((canvasWidth/props.data.daily.length)/2);
        setChartUpdating(true);
      }
    }
  }

  const onToggleData = (e) => {
    let newData = [ ...data ];
    setData([ ...data, { period: 'jun', value: 50 }]);
  };

  const onMonthlyClick = (e) => {
    let newData = [
      { period: 'jan', value: 2, rating: 2 },
      { period: 'feb', value: 14, rating: 4 },
      { period: 'mar', value: 6, rating: 5 },
      { period: 'apr', value: 8, rating: 1 },
      { period: 'may', value: 10, rating: 4 },
      { period: 'jun', value: 20, rating: 2 },
      { period: 'jul', value: 1, rating: 5 },
    ];
    setData([ ...newData ]);
  };

  const onWeeklyClick = (e) => {
    let newData = [
      { period: 'W1', value: 20, rating: 2 },
      { period: 'W2', value: 14, rating: 3 },
      { period: 'W3', value: 60, rating: 4 },
      { period: 'W4', value: 80, rating: 2 },
      { period: 'W5', value: 10, rating: 5 },
      { period: 'W6', value: 20, rating: 1 },
      { period: 'W7', value: 10, rating: 2 },
    ];
    setData([ ...newData ]);
  };

  const onDailyClick = (e) => {
    let newData = [
      { period: '1st Jan', value: 2, rating: 2 },
      { period: '2nd Jan', value: 14, rating: 3 },
      { period: '3rd Jan', value: 6, rating: 4 },
      { period: '4th Jan', value: 8, rating: 2 },
      { period: '5th Jan', value: 10, rating: 5 },
      { period: '6th Jan', value: 20, rating: 3 },
    ];
    setData([ ...newData ]);
  };




  const scaleValueXAxis = d3.scaleBand()
    // .domain([0,1,2,3,4])
    .domain(d3.range(0, data.length))
    .range([0,canvasWidth]);

  const scaleValueYAxis = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return d.value; })])
    .range([canvasHeight,0]);

  const scaleValueYAxisRight = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return d.rating; })])
    .range([canvasHeight,0]);


  const scaleValueXAxisOrdinal = d3.scaleBand()
  // .domain(["jan","feb","mar","apr","may"])
  .domain(data.map(el => el.period))
  .range([0, canvasWidth]);


  const drawChart = () => {
    // const margin = 30;

    const faux = props.connectFauxDOM('div', 'chart');

    const scale = 20;
    // const data = [ 2, 4, 2, 6, 8 ];
    // const svgCanvas = d3.select(svgRef.current)
    const svgCanvas = d3.select(faux)
      .append('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)
      // .style('border', '1px solid #EEE')


    // Add scale to Y axis
    const y_axis = d3.axisLeft()
                   .scale(scaleValueYAxis);

    // Add Y axis
    svgCanvas.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(y_axis);

    // Add scale to Y axis right
    const y_axis_right = d3.axisRight()
      .scale(scaleValueYAxisRight);

    // Add Y axis right
    svgCanvas.append("g")
      .attr("class", "y axis_right")
      .attr("transform", "translate("  + (canvasWidth + margin.left) + "," + margin.top + ")")
      .call(y_axis_right);

    // Add scale to X axis
    const x_axis = d3.axisBottom()
                   .scale(scaleValueXAxisOrdinal);

    // Add X axis
    svgCanvas.append("g")
    .attr("class", "x axis")
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.bottom) + ")")
      .call(x_axis);

    // Add review bars
    svgCanvas.append("g")
      .attr('id', 'barChart')
      .attr("transform","translate(" + margin.left + "," + margin.top + ")")
      .selectAll('rect')
      .data(data)
      // .enter()
      // .append('rect')
      .join("rect")
      .attr('width', 40)
      .attr('height', (datapoint) => `${canvasHeight - scaleValueYAxis(datapoint.value)}px`)
      .attr('x', (datapoint, iteration) => scaleValueXAxis(iteration) + 17)
      .attr('y', (datapoint) => (scaleValueYAxis(datapoint.value)))
      // .on('mouseover', function (d, i) {
      //   d3.select(this).transition()
      //    .duration('50')
      //    .attr('opacity', '.85')
      // })

    // Add g tag for text on top of bars
    svgCanvas.append("g")
      .attr('id', 'barText');

    // Add g tag for line chart
    svgCanvas.append("g")
      .attr('id', 'lineChart');

    // Define the div for the tooltip
    const div = d3.select("body").append("div")
      .attr("class", "lineChartCompTooltip")
      .style("opacity", 0);

  }

  const updateChart = (chartType, timePeriod, locIndex) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateChart: ', (new Date()).getTime());
      console.log('updateChart selectedTimePeriod: ', selectedTimePeriod);
      console.log('updateChart selectedLocIndex: ', selectedLocIndex);
      console.log('updateChart locIndex: ', locIndex);
    }

    setChartUpdating(true);
    d3.select('#chartLoader').style('display', 'block');
    // console.log('updateChart after setChartUpdating: ', (new Date()).getTime());

    // showLoader();
    // d3.select("#loader_container")
    // .attr('class', 'show')

    const faux = props.connectFauxDOM('div', 'chart')

    // const svgCanvas = d3.select(svgRef.current)
    const svgCanvas = d3.select(faux)
      .select('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)

    // console.log('updateChart svgCanvas: ', (new Date()).getTime(), ' # ', svgCanvas);

    scaleValueYAxis.domain([0, d3.max(data, function(d) { return d.value; })]);

    scaleValueYAxisRight.domain([0, d3.max(data, function(d) { return d.rating; })]);

    scaleValueXAxis.domain(d3.range(0, data.length));

    scaleValueXAxisOrdinal.domain(data.map(el => el.period));

    // clear barChart abd barText
    svgCanvas
      .select('#barChart')
      .selectAll('rect')
      .remove();

    svgCanvas
      .select('#barText')
      .selectAll('text')
      .remove();

    // const entryOffset = (canvasWidth - barWidth * data.length)/(data.length*2);
    const entryOffset = (canvasWidth - barWidth * data.length)/(data.length*2);

    if (chartType === 'reviews' || chartType === 'ratings-and-reviews') {
      // review bars
      svgCanvas
        .select('#barChart')
        .selectAll('rect')
        .data(data)
        .join("rect")
        .attr('width', barWidth)
        .attr('height', (datapoint) => `${canvasHeight - scaleValueYAxis(datapoint.value)}px`)
        .attr('x', (datapoint, iteration) => scaleValueXAxis(iteration) + parseInt(entryOffset))
        .attr('y', (datapoint) => (scaleValueYAxis(datapoint.value)))

      // text on top of bar
      svgCanvas
        .select('#barText')
        .selectAll('text')
        .data(data).enter()
        .append('text')
        .attr('x', (datapoint, iteration) => scaleValueXAxis(iteration) + parseInt(entryOffset) + margin.left + barWidth/2 - 5)
        .attr('y', (datapoint) => (scaleValueYAxis(datapoint.value) + margin.top - 4))
        .text((dataPoint) => dataPoint.value || '')
    }

    // Remove Y axis and Y axis label
    svgCanvas
      .select('.y.axis')
      .remove();

    svgCanvas
      .select('#ylabel')
      .remove();

    if (chartType === 'reviews' || chartType === 'ratings-and-reviews') {
      // Add y axis
      svgCanvas.append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        // .attr("transform", "translate("  + (canvasWidth + margin.left) + "," + margin.top + ")")
        .call(d3.axisLeft()
          .ticks(5)
          .scale(scaleValueYAxis));

      // Add y axis label
      svgCanvas.append("text")
        .attr("id", "ylabel")
        .attr("y",  30)
        .attr("x", margin.left - 40 )
        .text("Number of reviews")
    }

    // Remove Y axis right and Y axis right label
    svgCanvas
      .select('.y.axis_right')
      .remove();

    svgCanvas
      .select('#ylabelRight')
      .remove();

    if (chartType === 'ratings' || chartType === 'ratings-and-reviews') {
      // Add y axis right
      svgCanvas.append("g")
        .attr("class", "y axis_right")
        // .transition()
        // .duration(750)
        .attr("transform", "translate("  + (canvasWidth + margin.left) + "," + margin.top + ")")
        .call(d3.axisRight()
          .ticks(5)
          .scale(scaleValueYAxisRight));

      // Add y axis right label
      svgCanvas.append("text")
        .attr("id", "ylabelRight")
        .attr("text-anchor", "end")
        // .attr("transform", "rotate(-90)")
        // .attr("y", canvasWidth + margin.left + 40)
        // .attr("x", -margin.top )
        .attr("y",  30)
        .attr("x", canvasWidth + margin.left + 40 )
        .text("Rating")
    }

    let xAxisTransform = "translate("  + (-40) + "," + (50) + "), rotate(-60)";
    if (selectedTimePeriod === 'weekly') {
      xAxisTransform = "translate("  + (-80) + "," + (45) + "), rotate(-25)";
    }
    if (selectedTimePeriod === 'monthly') {
      xAxisTransform = "translate("  + (-30) + "," + (20) + "), rotate(-30)";
    }
    // Add X axis
    svgCanvas.select(".x.axis") // change the x axis
      // .duration(750)
      // .transition()
      // .duration(750)
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.top) + ")")
      .call(d3.axisBottom()
              .scale(scaleValueXAxisOrdinal)
      )
      .selectAll("text")
      .attr("y", 10)
      .attr("x", 0)
      // .attr("transform", "rotate(-60)")
      .attr("transform", xAxisTransform)
      .style("text-anchor", "start")
      .style("font-size", "10px")

    // setTimeout(() => {
    //   svgCanvas.select(".x.axis") // change the x axis
    //     // .duration(750)
    //     .selectAll(".tick text")
    //     .call(function(t){
    //       console.log('text each');
    //       t.each(function(d){ // for each one
    //         var self = d3.select(this);
    //         console.log('text each t self: ', self);
    //         var s = self.text().split('-');  // get the text and split it
    //         console.log('text each t s: ', s);
    //         self.text(''); // clear it out
    //         self.append("tspan") // insert two tspans
    //           .attr("x", 0)
    //           .attr("dy",".8em")
    //           .text(`${s[0]}-`);
    //         self.append("tspan")
    //           .attr("x", 0)
    //           .attr("dy",".8em")
    //           .text(s[1]);
    //       })
    //     });
    // }, 100);

    // Add X axis label:
    // svgCanvas
    //   .select('#xlabel')
    //   .remove();
    //
    // svgCanvas.append("text")
    //   .attr("id", "xlabel")
    //     .attr("text-anchor", "end")
    //     .attr("x", canvasWidth)
    //     .attr("y", canvasHeight + margin.top + 44)
    //     .text("Time period");

    // Remove line chart, dots and dots text
    svgCanvas
      .select('#lineChart')
      .selectAll('path')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dot')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dotText')
      .remove();

    if (chartType === 'ratings' || chartType === 'ratings-and-reviews') {
      // Define the line
      const valueline = d3.line()
        .x(function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
        .y(function(d, i) { return scaleValueYAxisRight(d.rating) + margin.top });

      // Add the valueline path.


      svgCanvas
        .select('#lineChart')
        .append('path')
        .data([data])
        .attr("class", "line prmry")
        .attr("d", valueline)
        .on("mouseover", (e, d, i) => {
          d3.select(".line")
          .attr('class', 'line prmry hglt')
          // d3.select(".line")
          // //   .style("stroke", "red");
          // .style("opacity", "0.5")
          // .attr("stroke-width", 5)
          // d3.select(this)
          // .style("stroke", "red");
            // .attr('class', 'line4')
        })
        .on("mouseout", (d) => {
          d3.select(".line.prmry.hglt")
          .attr('class', 'line prmry')
        });



        if (selectedTimePeriod) {
          listData.forEach((item, idx) => {

            if (locIndex === 'all' || locIndex === idx+1) {
            // Define the line
          const valueline1 = d3.line()
          .x(function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
          .y(function(d, i) { return scaleValueYAxisRight(d.rating) + margin.top });

          // Add the valueline path.
          svgCanvas
            .select('#lineChart')
            .append('path')
            .data([listData[idx].data.trend_data[selectedTimePeriod]])
            .attr("class", `line list${idx}`)
            .attr("d", valueline1)
            .on("mouseover", (e, d, i) => {
              d3.select(`.line.list${idx}`)
              .attr('class', `line list${idx} hglt`);

              div.transition()
            .duration(200)
            .style("opacity", .9);

              div.html(`${listData[idx].data.location_name}`)
            .style("left", (e.pageX) + "px")
            .style("top", (e.pageY - 28) + "px");
            })
            .on("mouseout", (d) => {
              d3.select(`.line.list${idx}.hglt`)
              .attr('class', `line list${idx}`);

              div.transition()
            .duration(500)
            .style("opacity", 0);
            });

          }

          })
        }

      // // Define the line
      // const valueline1 = d3.line()
      // .x(function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
      // .y(function(d, i) { return scaleValueYAxisRight(d.rating) + margin.top });

      // // Add the valueline path.
      // svgCanvas
      //   .select('#lineChart')
      //   .append('path')
      //   .data([listData[0].data.trend_data.weekly])
      //   .attr("class", "line")
      //   .attr("d", valueline1);



      var div = d3.select(".lineChartCompTooltip");

      // Appends a circle for each datapoint
      svgCanvas
        .select('#lineChart')
        .selectAll(".dot")
        .data(data).enter()
        .append("circle") // Uses the enter().append() method
        .attr("class", "dot") // Assign a class for styling
        // .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + 15; })
        .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2})
        .attr("cy", function(d) { return scaleValueYAxisRight(d.rating) + margin.top + 5; })
        .attr("r", 8)
        .on("mouseover", (e, d, i) => {
          d3.select(this).transition()
            .duration('50')
            .attr('opacity', '.5')
          div.transition()
            .duration(200)
            .style("opacity", .9);
          // console.log('mouseover d: ', d);
          // div.html(`Reviews: ${d.value}` + "<br/>"  + `Rating: ${d.rating}`)
          let tstr = `<div class="rw">${d.period}</div><br/>`;
          tstr = `${tstr}<div class="rw prmy"><span class="rtng">${d.rating}</span> <span class="locNm">${props.locationInfo.name}</span></div>`;
          listData.forEach((item, indx) => {
            if (item.data && item.data.trend_data && selectedTimePeriod && item.data.trend_data[selectedTimePeriod] &&
              item.data.trend_data[selectedTimePeriod][d.tIndex]
            ) {
              tstr = `${tstr}<div class="rw list${indx}"><span class="rtng">${item.data.trend_data[selectedTimePeriod][d.tIndex].rating}</span> <span class="locNm">${item.data.location_name}</span></div>`;
            }
          });

          div.html(tstr)
            .style("left", (e.pageX + 2) + "px")
            .style("top", (e.pageY + 2) + "px");
        })
        .on("mouseout", (d) => {
          d3.select(this).transition()
            .duration('50')
            .attr('opacity', '1')
          div.transition()
            .duration(500)
            .style("opacity", 0);
        });

      // add text on top of dot
      svgCanvas
        .select('#lineChart')
        .selectAll('text')
        .data(data).enter()
        .append('text')
        .attr('class', 'dotText')
        .attr('x', (d, i) => scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2 - 10)
        // .attr('x', (dataPoint, i) => i * 45 + 15)
        .attr('y', (d) => (scaleValueYAxisRight(d.rating) + margin.top - 5))
        // .attr('y', (dataPoint, i) => canvasHeight - scaleValueYAxis(dataPoint.value) - 10)
        .text(dataPoint => dataPoint.rating)
    }
    // console.log('updateChart end: ', (new Date()).getTime());

    // d3.select("#loader_container")
    // .attr('class', 'hide')
    // hideLoader();

    props.animateFauxDOM(800)

    d3.select('#chartLoader').style('display', 'none');

    setTimeout(() => {
      setChartUpdating(false);
    }, 50);
  };


  /* ************ Loader **************** */

  function degToRad (degrees) {
    return degrees * Math.PI / 180;
  }

  // Returns a tween for a transition’s "d" attribute, transitioning any selected
  // arcs from their current angle to the specified new angle.
  function arcTween(newAngle, angle) {
    return function(d) {
      var interpolate = d3.interpolate(d[angle], newAngle);
      return function(t) {
        d[angle] = interpolate(t);
        return arc(d);
      };
    };
  }

  const animationTime = 1200;
  const loaderRadius = 40;
  const loaderColor = '#ccc';

  const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(loaderRadius);

  const hideLoader = () => {
    d3.select("#loader_container").selectAll('g').remove();
  }
  const showLoader = () => {

    var svg = d3.select("#loader_container"),
        width = 900,
        height = 600,
        g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      // console.log('loader_container svg: ', svg);
    var loader = g.append("path")
        .datum({endAngle: 0, startAngle: 0})
        .style("fill", loaderColor)
        .attr("d", arc);

    d3.interval(function() {
      loader.datum({endAngle: 0, startAngle: 0})

      loader.transition()
          .duration(animationTime)
          .attrTween("d", arcTween(degToRad(360), 'endAngle'));

       loader.transition()
          .delay(animationTime)
          .duration(animationTime)
          .attrTween("d", arcTween(degToRad(360), 'startAngle'));
    }, animationTime * 2);
  }

  /* ************ Loader **************** */

  // console.log('selectedChartType: ', selectedChartType);
  // console.log('selectedTimePeriod: ', selectedTimePeriod);
  // console.log('chartUpdating time: ', (new Date()).getTime());
  // console.log('chartUpdating: ', chartUpdating);

  if (process.env.NODE_ENV !== 'production') {
    console.log('selectedLocIndex: ', selectedLocIndex);
  }

  const cStyle = {};
  cStyle['width'] = props.width;

  return (
    <div className={'compChartWrap ' + (props.className || '' )} style={cStyle}>
      {/*<svg id="loader_container" width="900" height="600"></svg>*/}
      <div id="chartLoader" className="chartLoader">
        <i className="fa fa-spinner fa-spin" />
      </div>
      <div className="topRow">
        <div className="chartInfoBox">
          <div className='rtng'>Ratings Trend</div>
          <div className='locNm' onClick={onChartPrimaryLocClick}>{props.locationInfo.name}</div>
        </div>
        {/* <div className="chartTypeBox">
          {
            !props.hideRating &&
            <ChartTypeButton label={'Ratings'} value={'ratings'} onButtonClick={onChartTypeButtonClick} selected={selectedChartType === 'ratings'} />
          }
          <ChartTypeButton label={'Reviews'} value={'reviews'} onButtonClick={onChartTypeButtonClick} selected={selectedChartType === 'reviews'} />
          {
            !props.hideRating &&
            <ChartTypeButton label={'Ratings & Reviews'} value={'ratings-and-reviews'} onButtonClick={onChartTypeButtonClick} selected={selectedChartType === 'ratings-and-reviews'} />
          }
        </div> */}
        {
          listData && listData.length
          ?
          <div className="locBox">
            <ChartTypeButton 
              className={`list0`}
              label={'All'} value={'all'} onButtonClick={onChartLocButtonClick} selected={selectedLocIndex === 'all'} 
            />
            {
              listData.map((item, indx) => {
                if (!item.data || !item.data.location_name) return null;
                return (
                  <ChartTypeButton 
                    className={`list${indx+1}`}
                    label={item.data.location_name} value={indx+1} onButtonClick={onChartLocButtonClick} selected={selectedLocIndex === indx+1} 
                  />
                )
              })
            }
          </div>
          : null
        }
        {/*<div className="timePeriodBox">
          <TimePeriodButton label={'Monthly'} value={'monthly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'monthly'} />
          <TimePeriodButton label={'Weekly'} value={'weekly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'weekly'} />
          <TimePeriodButton label={'Daily'} value={'daily'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'daily'} />
        </div>*/}
      </div>
      {props.chart}
      <div className="bottomRow">
        <div className="timePeriodBox">
          {
            !props.showOnlyDaily &&
            <TimePeriodButton label={'Monthly'} value={'monthly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'monthly'} />
          }
          {
            (canvasWidth/props.data.weekly.length > MIN_BAR_WIDTH && !props.showOnlyDaily) &&
            <TimePeriodButton label={'Weekly'} value={'weekly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'weekly'} />
          }
          {
            (canvasWidth/props.data.daily.length > MIN_BAR_WIDTH) &&
            <TimePeriodButton label={'Daily'} value={'daily'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'daily'} />
          }
        </div>
      </div>
    </div>
  )
}

export default withFauxDOM(ReviewRatingChart);
