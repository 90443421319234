import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import { getDataAction, getDataClearAction } from '../../../../../../redux/actions/getDataActions';

import dragIcon from '../../../../../../img/icons/drag.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import './style.css';


function AiModelLiveModal(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [convInput, setConvInput] = useState('');
  const [convInputError, setConvInputError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const convInputEl = useRef(null);

  const modalRef = useRef();
  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  const setInitialValues = () => {
    const { ai_model } = props.settings;
  };

  const clearData = () => {
    dispatch(getDataClearAction({
      op: 'get_ai_model_result',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  };

  useEffect(() => {
    // let page_title = `${props.locationprops.location.name} - Settings - Manage Templates`;
    // if (props.template && props.template._id) {
    //   page_title = `${page_title} - Edit - ${props.template.title || ''}`;
    // } else {
    //   page_title = `${page_title} - Add`;
    // }
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title,
    //   user_id: props.userprops.user.uid,
    // });
    // Mousetrap.bind('esc', () => props.onCancelClick(''));
    // return () => {
    //   Mousetrap.unbind('esc');
    // };
  }, []);

  useEffect(() => {
    setInitialValues();
    clearData();
  }, []);

  useEffect(() => {
    if (!props.getInProgress && props.getStatus && props.getStatus.success) {
      setInitialValues();
      setSomeChange(false);
      setConvInput('');
    }

  }, [props.getInProgress, props.getStatus]);

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onConvInputChange = (e) => {
    const { name, value } = e.target;
    setConvInput(value);
    setConvInputError(convInput ? '' : 'error');
    setSomeChange(true);
  };

  const onClearConvClick = (e) => {
    clearData();
    setConvInput('');
  };

  const onEditClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('AiModelLiveModal onEditClick: ', convInput);
    }
    if (props.getInProgress) return;
    if (!someChange) return;

    setConvInputError(convInput ? '' : 'error');
    if (!convInput) return;

    const { ai_model } = props.settings;

    let messages = [];
    if (ai_model && ai_model.result && ai_model.result.messages && ai_model.result.messages.length) {
      messages = [ ...ai_model.result.messages ];
    }
    messages.push({ 'role': 'user', 'content': convInput });
    dispatch(getDataAction({
      op: 'get_ai_model_result',
      params: {
        location__id: urlParams.locationidentifier,
        messages,
      },
    }));

  };

  const renderContent = () => {
    const { ai_model } = props.settings;
    const cStyle= {};
    cStyle.maxHeight = props.windowHeight*0.9 - 200;

    return (
      <div className="sdMdl aiMdlLv" ref={modalRef}>
        <div className="ctnt">
          <div className="gPWrp">
            <div className="gPTle title">
              Test and Ask Questions to AI Model
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
            </div>
            <div className="gPSubTle title">
              Ask anything, and guestAI will try to answer using the information entered in the AI Model.
            </div>
            <div className="gPSubTle clrConv">
              <span onClick={onClearConvClick}>Clear Conversation</span>
            </div>
            <div className="gBCtnt">
              {
                ai_model && ai_model.result && ai_model.result.messages &&
                ai_model.result.messages.length > 0 &&
                ai_model.result.messages.map((item) => {
                  return (
                    <div className={'gBCtRw convRw ' + (item.role)}>
                      <div className="gRwBxLt">
                        <div className="gLbl">
                          {item.role === 'user' && <span>You</span>}
                          {item.role === 'assistant' && <span>Ai Model</span>}
                        </div>
                      </div>
                      <div className="gRwBxRt">
                        <span>{item.content}</span>
                      </div>
                    </div>
                  )
                })
              }
              <div className="gBCtRw convRw convIpt">
                <div className="gRwBxLt">
                  <div className={'gIpt ' + (convInputError ? 'error' : '')}>
                    <textarea rows="4" placeholder="Message"
                      onChange={onConvInputChange}
                      placeholder="Ask anything..."
                      value={convInput}
                      ref={convInputEl}
                    />
                    { convInputError && <p className="gErr">Input is required</p> }
                  </div>
                </div>
              </div>

              {
                props.getStatus && (props.getStatus.success || props.getStatus.error) &&
                <div className="gBCtRw curSts">
                  {
                    props.getStatus.error && <span className="gErr">{props.getStatus.message || 'Error!'}</span>
                  }
                </div>
              }
              <div className="gBCtRw btnRw">
                {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
                <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onEditClick} >
                  Get Answer
                  { props.getInProgress && <i className="fa fa-spinner fa-spin" /> }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('AiModelLiveModal props: ', props);
  }

  return renderContent();

}

export default AiModelLiveModal;
