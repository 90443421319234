import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

// import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import './style.css';

const gtconfig = require('../../../../gtconfig');


function Notifications(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [desktopNotificationsStatus, setDesktopNotificationsStatus] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      document.title = `Profile - Notifications`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    if (('Notification' in window)) {
      setDesktopNotificationsStatus(Notification.permission);
      if (process.env.NODE_ENV !== 'production') {
        console.log('Notification.permission: ', Notification.permission);
      }
    }
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  const onEnableDesktopNotificationsClick = (e) => {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (process.env.NODE_ENV !== 'production') {
        console.log('permission: ', permission);
      }
      setDesktopNotificationsStatus(permission);
      if (permission === 'granted') {
        new Notification(`Awesome! You will start receiving ${gtconfig.APP_NAME_CONTACT} notifications shortly`);
        const params = {
          desktop_notification_enabled: true,
        };
        dispatch(editDataAction({
          op: 'edit_profile',
          params,
        }));
      }
    });
  };


  const renderDesktopNotificationsInfo = () => {
    const { uid, contact } = props.userInfo
    const { first_name, last_name } = props.userInfo.profile;
    if (desktopNotificationsStatus === 'granted') {
      return (
        <div className="gBCtRw">
          <div className="lt">
            <p className="gLbl">Desktop notifications are enabled</p>
          </div>
        </div>
      );
    }
    return (
      <div className="gBCtRw prfNtfRw">
        <div className="lt">
          <p className="gLbl">Enable desktop notifications ?</p>
        </div>
        <div className="rt">
          <button className={'gBtn ok'} onClick={onEnableDesktopNotificationsClick}>
            Enable
          </button>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Notifications: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen prfInf">
        <div className="gPTle title">
          Notifications
        </div>
        <div className="gBCtnt">
          { renderDesktopNotificationsInfo() }
        </div>
      </div>
    </div>
  );
}

export default Notifications;
