import { promptKeyByType } from '../../helpers';


/***************************************** Get RG Response ****************************/

export const getResponseGeneratorResponseReset = (state, action) => {
  const { params: { session_id } } = action.payload;
  if (!session_id) return state;
  const tempGenerator = { ...state.generator };
  delete tempGenerator[session_id];
  return {
    ...state,
    generator: {
      ...tempGenerator,
    },
  };
};


export const getResponseGeneratorResponseClear = (state, action) => {
  const { params: { session_id } } = action.payload;
  if (!session_id) return state;
  return {
    ...state,
    generator: state.generator
    ?
      {
        ...state.generator,
        [session_id]: state.generator[session_id]
        ?
          {
            ...state.generator[session_id],
            getInProgress: false,
            getStatus: {},
          }
        :
          {
            getInProgress: false,
            getStatus: {},
          }
      }
    : {
        [session_id]: {
          getInProgress: false,
          getStatus: {},
        }
      }
  };
};

export const getResponseGeneratorResponse = (state, action) => {
  const { params: { session_id } } = action.payload;
  if (!session_id) return state;
  return {
    ...state,
    generator: {
      ...state.generator,
      [session_id]: {
        ...state.generator[session_id],
        params: { ...action.payload.params },
        getInProgress: true,
        getStatus: {},
      }
    },
  };
};

export const getResponseGeneratorResponseSuccess = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('getResponseGeneratorResponseSuccess action: ', action);
  }
  const { data, params: { session_id } } = action.payload;
  if (!session_id) return state;
  const { response_data } = data;
  if (process.env.NODE_ENV !== 'production') {
    console.log('getResponseGeneratorResponseSuccess response_data: ', response_data);
  }
  if (!response_data) return state;
  let tempAllResponse = state.generator[session_id] ? state.generator[session_id].all_responses || [] : [];
  if (state.generator[session_id].current_response) {
    tempAllResponse.push(state.generator[session_id].current_response);
  }

  return {
    ...state,
    generator: {
      ...state.generator,
      [session_id]: {
        ...state.generator[session_id],
        current_response: response_data,
        all_responses: [ ...tempAllResponse ],
        getInProgress: false,
        getStatus: {
          success: true,
        },
      },
    },
  };
};

export const getResponseGeneratorResponseError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { session_id } = params;
  return {
    ...state,
    generator: {
      ...state.generator,
      [session_id]: {
        ...state.generator[session_id],
        getInProgress: false,
        getStatus: {
          error: true,
        },
        error,
        errorMessage,
      },
    },
  };
};




/***************************************** Get RG Locations ****************************/

export const getResponseGeneratorLocationsClear = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    locations: {
      ...state.locations,
      getInProgress: false, getStatus: {},
    },
  };
};

export const getResponseGeneratorLocations = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    locations: {
      ...state.locations,
      getInProgress: true, getStatus: {},
    },
  };
};

export const getResponseGeneratorLocationsSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { locations } = data;
  return {
    ...state,
    locations: {
      // ...state.locations,
      ...locations,
      getInProgress: false,
      getStatus: {
        success: true,
      },
    },
  };
};

export const getResponseGeneratorLocationsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      getInProgress: false,
      getStatus: {
        error: true,
      },
      error,
      errorMessage,
    },
  };
};



/***************************************** Get Overview ****************************/

export const getResponseGeneratorOverviewClear = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    overview: {
      ...state.overview,
      getInProgress: false, getStatus: {},
    },
  };
};

export const getResponseGeneratorOverview = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    overview: {
      ...state.overview,
      getInProgress: true, getStatus: {},
    },
  };
};

export const getResponseGeneratorOverviewSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { stats } = data;
  return {
    ...state,
    overview: {
      ...state.overview,
      stats,
      getInProgress: false,
      getStatus: {
        success: true,
      },
    },
  };
};

export const getResponseGeneratorOverviewError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  return {
    ...state,
    overview: {
      ...state.overview,
      getInProgress: false,
      getStatus: {
        error: true,
      },
      error,
      errorMessage,
    },
  };
};


/***************************************** Get History ****************************/

export const getResponseGeneratorHistoryClear = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    history: state.history ?
      {
        ...state.history,
        getInProgress: false, getStatus: {},
      }
    : {
      getInProgress: false, getStatus: {},
    }
  };
};

export const getResponseGeneratorHistory = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    history: {
      ...state.history,
      getInProgress: true, getStatus: {},
    },
  };
};

export const getResponseGeneratorHistorySuccess = (state, action) => {
  const { data, params } = action.payload;
  const { history, total_count } = data;
  if (!history) return state;
  return {
    ...state,
    history: {
      // ...state.history,
      ...history,
      total_count,
      getInProgress: false,
      getStatus: {
        success: true,
      },
    },
  };
};

export const getResponseGeneratorHistoryError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  return {
    ...state,
    history: {
      ...state.history,
      getInProgress: false,
      getStatus: {
        error: true,
      },
      error,
      errorMessage,
    },
  };
};


/***************************************** Get users for rg login ****************************/

export const getUsersForRgClear = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    users: state.users
    ?
      {
        ...state.users,
        getInProgress: false, getStatus: {},
      }
    : {
        getInProgress: false, getStatus: {},
      }
  };
};

export const getUsersForRg = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    users: {
      ...state.users,
      getInProgress: true, getStatus: {},
    },
  };
};

export const getUsersForRgSuccess = (state, action) => {
  const { data, params } = action.payload;
  let { users } = data;
  return {
    ...state,
    users: {
      ...state.users,
      ...users,
      getInProgress: false, getStatus: {
        success: true,
      },
    },
  };
};

export const getUsersForRgError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  return {
    ...state,
    users: {
      ...state.users,
      error,
      errorMessage,
      getInProgress: false, getStatus: {
        error: true,
      },
    },
  };
};



/***************************************** Add Location ****************************/

export const addRGLocationClear = (state, action) => {
  const { params: { name } } = action.payload;
  return {
    ...state,
    locations: {
      ...state.locations,
      addInProgress: false,
      addStatus: {},
    }
  };
};

export const addRGLocation = (state, action) => {
  const { params: { name } } = action.payload;
  if (!name) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      addInProgress: true,
      addStatus: {},
    },
  };
};

export const addRGLocationSuccess = (state, action) => {
  const { data, params: { name } } = action.payload;
  if (!name) return state;
  const { rg_location_data } = data;
  if (!rg_location_data || !rg_location_data._id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      keyValue: {
        ...state.locations.keyValue,
        [rg_location_data._id]: {
          ...rg_location_data,
        },
      },
      list: state.locations.list
      ? [ rg_location_data._id, ...state.locations.list]
      : [ rg_location_data._id],
      addInProgress: false,
      addStatus: {
        success: true,
      },
    },
  };
};

export const addRGLocationError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { name } = params;
  if (!name) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      addInProgress: false,
      addStatus: {
        error: true,
      },
    },
  };
};





/***************************************** Edit Location ****************************/

export const editRGLocationClear = (state, action) => {
  const { params: { rg_location__id, loc_is_default } } = action.payload;
  if (!rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  return {
    ...state,
    locations: {
      ...state.locations,
      keyValue: state.locations.keyValue
      ?
        {
          ...state.locations.keyValue,
          [location__id]: state.locations.keyValue[location__id]
          ?
            {
              ...state.locations.keyValue[location__id],
              editInProgress: false, editStatus: {},
            }
          : {
              editInProgress: false, editStatus: {},
            },
        }
      : {
          [location__id]: {
            editInProgress: false, editStatus: {},
          }
        },
    }
  };
};

export const editRGLocation = (state, action) => {
  const { params: { rg_location__id, loc_is_default } } = action.payload;
  if (!rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  return {
    ...state,
    locations: {
      ...state.locations,
      keyValue: {
        ...state.locations.keyValue,
        [location__id]: {
          ...state.locations.keyValue[location__id],
          editInProgress: true, editStatus: {},
        },
      },
    },
  };
};

export const editRGLocationSuccess = (state, action) => {
  const { data, params: { rg_location__id, loc_is_default } } = action.payload;
  if (!rg_location__id) return state;
  // const location__id = loc_is_default ? 'default' : rg_location__id;
  const location__id = rg_location__id;
  const { rg_location_data } = data;
  if (!rg_location_data) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      keyValue: {
        ...state.locations.keyValue,
        [location__id]: {
          ...state.locations.keyValue[location__id],
          ...rg_location_data,
          editInProgress: false,
          editStatus: {
            success: true
          },
        },
      },
    },
  };
};

export const editRGLocationError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { rg_location__id, loc_is_default } = params;
  if (!rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  return {
    ...state,
    locations: {
      ...state.locations,
      keyValue: {
        ...state.locations.keyValue,
        [location__id]: {
          ...state.locations.keyValue[location__id],
          editInProgress: false,
          editStatus: {
            error: true
          },
          error,
          errorMessage,
        },
      },
    },
  };
};


/***************************************** Edit General Prompt Template ****************************/

export const editRGPromptTemplateClear = (state, action) => {
  const { params: { mode, prompt_type, rg_location__id, loc_is_default, prompt_template_id } } = action.payload;
  if (!mode || !prompt_type || !rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  let promptKey = promptKeyByType[prompt_type]; // prompt_templates, followup_prompt_templates
  if (!promptKey) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!prompt_template_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              keyValue: {
                ...state.locations.keyValue[location__id][promptKey].keyValue,
                [prompt_template_id]: {
                  ...state.locations.keyValue[location__id][promptKey].keyValue[prompt_template_id],
                  editInProgress: false,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: state.locations.keyValue[location__id][promptKey]
            ?
              {
                ...state.locations.keyValue[location__id][promptKey],
                addInProgress: false,
                addStatus: {},
              }
            : {
                addInProgress: false,
                addStatus: {},
              },
          },
        },
      },
    };
  }
  return state;
};

export const editRGPromptTemplate = (state, action) => {
  const { params: { mode, prompt_type, rg_location__id, loc_is_default, prompt_template_id, is_default } } = action.payload;
  if (!mode || !prompt_type || !rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  let promptKey = promptKeyByType[prompt_type]; // prompt_templates, followup_prompt_templates
  if (!promptKey) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!prompt_template_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              keyValue: {
                ...state.locations.keyValue[location__id][promptKey].keyValue,
                [prompt_template_id]: {
                  ...state.locations.keyValue[location__id][promptKey].keyValue[prompt_template_id],
                  editInProgress: true,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              addInProgress: true,
              addStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editRGPromptTemplateSuccess = (state, action) => {
  const { data, params: { mode, prompt_type, rg_location__id, loc_is_default, prompt_template_id, is_default } } = action.payload;
  if (!mode || !prompt_type || !rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  const { prompt_template_data } = data;
  if (!prompt_template_data) return state;
  let promptKey = promptKeyByType[prompt_type]; // prompt_templates, followup_prompt_templates
  if (!promptKey) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!prompt_template_id) return state;
    let tKeyValue = { ...state.locations.keyValue[location__id][promptKey].keyValue };
    if (is_default) {
      Object.keys(tKeyValue).forEach((item) => {
        tKeyValue[item].is_default = false;
      })
    }
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              keyValue: {
                // ...state.locations.keyValue[location__id][promptKey].keyValue,
                ...tKeyValue,
                [prompt_template_id]: {
                  // ...tKeyValue,
                  // ...state.locations.keyValue[location__id][promptKey].keyValue[prompt_template_id],
                  ...prompt_template_data,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    if (!prompt_template_data._id) return;
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              list: state.locations.keyValue[location__id][promptKey].list
              ?
                [
                  prompt_template_data._id, ...state.locations.keyValue[location__id][promptKey].list,
                ]
              : [prompt_template_data._id],
              keyValue: state.locations.keyValue[location__id][promptKey].keyValue
              ?
                {
                  ...state.locations.keyValue[location__id][promptKey].keyValue,
                  [prompt_template_data._id]: {
                    ...prompt_template_data,
                  },
                }
              : {
                  [prompt_template_data._id]: {
                    ...prompt_template_data,
                  },
                },
              addInProgress: false,
              addStatus: {
                success: true,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editRGPromptTemplateError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { mode, prompt_type, rg_location__id, loc_is_default, prompt_template_id } = params;
  if (!mode || !prompt_type || !rg_location__id) return state;
  const location__id = loc_is_default ? 'default' : rg_location__id;
  let promptKey = promptKeyByType[prompt_type]; // prompt_templates, followup_prompt_templates
  if (!promptKey) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!prompt_template_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              keyValue: {
                ...state.locations.keyValue[location__id][promptKey].keyValue,
                [prompt_template_id]: {
                  ...state.locations.keyValue[location__id][promptKey].keyValue[prompt_template_id],
                  editInProgress: false,
                  editStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        keyValue: {
          ...state.locations.keyValue,
          [location__id]: {
            ...state.locations.keyValue[location__id],
            [promptKey]: {
              ...state.locations.keyValue[location__id][promptKey],
              addInProgress: false,
              addStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    };
  }
  return state;
};


/* ********************** Start - edit rg user ******************* */

export const editRgUserClear = (state, action) => {
  const { params: { mode, user_id } } = action.payload;
  if (!mode) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      users: {
        ...state.users,
        keyValue: {
          ...state.users.keyValue,
          [user_id]: {
            ...state.users.keyValue[user_id],
            editInProgress: false,
            editStatus: {},
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      users: state.users
      ? {
          ...state.users,
          addInProgress: false,
          addStatus: {},
        }
      : {
        addInProgress: false,
        addStatus: {},
        },
    };
  }
  return state;
};

export const editRgUser = (state, action) => {
  const { params: { mode, user_id } } = action.payload;
  if (!mode) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      users: {
        ...state.users,
        keyValue: {
          ...state.users.keyValue,
          [user_id]: {
            ...state.users.keyValue[user_id],
            editInProgress: true,
            editStatus: {},
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      users: {
        ...state.users,
        addInProgress: true,
        addStatus: {},
      },
    };
  }
  return state;
};

export const editRgUserSuccess = (state, action) => {
  const { data, params } = action.payload;
  if (!params) return state;
  const { mode, user_id, ...restParams } = params;
  if (!mode) return state;
  const { gt_user } = data;
  if (!gt_user || !gt_user._id) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      users: {
        ...state.users,
        keyValue: {
          ...state.users.keyValue,
          [user_id]: {
            ...state.users.keyValue[user_id],
            ...gt_user,
            editInProgress: false,
            editStatus: {
              success: true,
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      users: {
        ...state.users,
        keyValue: state.users.keyValue
        ?
          {
            ...state.users.keyValue,
            [gt_user._id]: {
              ...gt_user,
            },
          }
        : {
            [gt_user._id]: {
              ...gt_user,
            },
          },
        list: state.users.list
        ?
          [gt_user._id, ...state.users.list]
        :
          [gt_user._id],
        addInProgress: false,
        addStatus: {
          success: true,
        },
      },
    };
  }
  return state;
};

export const editRgUserError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { mode, user_id } = params;
  if (!mode) return state;
  if (mode === 'edit' || mode === 'remove' || mode === 'disable') {
    return {
      ...state,
      users: {
        ...state.users,
        keyValue: {
          ...state.users.keyValue,
          [user_id]: {
            ...state.users.keyValue[user_id],
            editInProgress: false,
            editStatus: {
              error: true,
            },
            error,
            errorMessage,
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      users: {
        ...state.users,
        addInProgress: false,
        addStatus: {
          error: true,
          message: 'Error in adding',
        },
        error,
        errorMessage,
      },
    };
  }
  return state;
};

/* ********************** End - edit rg user ******************* */

/* ********************** Start - edit rg user reset password ******************* */

export const editRgUserResetPasswordClear = (state, action) => {
  const { params: { user_id } } = action.payload;
  if (!user_id) return state;
  return {
    ...state,
    users: {
      ...state.users,
      keyValue: {
        ...state.users.keyValue,
        [user_id]: {
          ...state.users.keyValue[user_id],
          resetInProgress: false,
          resetStatus: {},
        },
      },
    },
  };
};

export const editRgUserResetPassword = (state, action) => {
  const { params: { user_id } } = action.payload;
  if (!user_id) return state;
  return {
    ...state,
    users: {
      ...state.users,
      keyValue: {
        ...state.users.keyValue,
        [user_id]: {
          ...state.users.keyValue[user_id],
          resetInProgress: true,
          resetStatus: {},
        },
      },
    },
  };
};

export const editRgUserResetPasswordSuccess = (state, action) => {
  const { data, params } = action.payload;
  if (!params) return state;
  const { user_id } = params;
  if (!user_id) return state;
  return {
    ...state,
    users: {
      ...state.users,
      keyValue: {
        ...state.users.keyValue,
        [user_id]: {
          ...state.users.keyValue[user_id],
          resetInProgress: false,
          resetStatus: {
            success: true,
          },
        },
      },
    },
  };
};

export const editRgUserResetPasswordError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { user_id } = params;
  if (!user_id) return state;
  return {
    ...state,
    users: {
      ...state.users,
      keyValue: {
        ...state.users.keyValue,
        [user_id]: {
          ...state.users.keyValue[user_id],
          resetInProgress: false,
          resetStatus: {
            error: true,
          },
          error,
          errorMessage,
        },
      },
    },
  };
};

/* ********************** End - edit rg user reset password ******************* */
