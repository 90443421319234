import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { SegmentedMessage } from 'sms-segments-calculator';
import moment from 'moment';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Select from 'react-select';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import { ISD_CODES, MSGTYPE_TO_STR } from '../../../../../helpers';

import emojiIcon from '../../../../../img/icons/emoji.svg';

import './style.css';

const sendAfterTimes = [];
sendAfterTimes.push({ label: '30 Secs', value: '30 Secs' });
sendAfterTimes.push({ label: '2 Mins', value: '2 Mins' });
sendAfterTimes.push({ label: '10 Mins', value: '10 Mins' });
sendAfterTimes.push({ label: '30 Mins', value: '30 Mins' });
sendAfterTimes.push({ label: '60 Mins', value: '60 Mins' });
sendAfterTimes.push({ label: '24 Hrs', value: '24 Hrs' });

const sendBeforeDays = [];
sendBeforeDays.push({ label: 'Same Day', value: 'Same Day' });
sendBeforeDays.push({ label: '1 Day', value: '1 Day' });
sendBeforeDays.push({ label: '2 Days', value: '2 Days' });
sendBeforeDays.push({ label: '3 Days', value: '3 Days' });
sendBeforeDays.push({ label: '4 Days', value: '4 Days' });
sendBeforeDays.push({ label: '5 Days', value: '5 Days' });
sendBeforeDays.push({ label: '10 Days', value: '10 Days' });
sendBeforeDays.push({ label: '30 Days', value: '30 Days' });

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{firstname}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{lastname}}' });
// personalizeOptionsG.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
personalizeOptionsG.push({ label: 'Room Type', value: '{{roomtype}}' });
personalizeOptionsG.push({ label: 'Room Number', value: '{{roomnumber}}' });
// personalizeOptionsG.push({ label: 'Wifi', value: '{{wifi}}' });

const sendEmailOptionsG = [];
const sendEmailOptions_remaining = { label: 'Send email to the remaining cases', value: 'remaining' };
const sendEmailOptions_all = { label: 'Send email to all', value: 'all' };
sendEmailOptionsG.push(sendEmailOptions_remaining);
sendEmailOptionsG.push(sendEmailOptions_all);

function PreDefinedMessage(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [hasMessage, setHasMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [sendAfterValue, setSendAfterValue] = useState([
    { label: '30 Secs', value: '30 Secs' }
  ]);
  const [sendBeforeValue, setSendBeforeValue] = useState([
    { label: '1 Day', value: '1 Day' }
  ]);
  const [sendAt, setSendAt] = useState('');
  const [timeConstraintsScheduleTime, setTimeConstraintsScheduleTime] = useState({ minutes: { step: 15 }});
  const [sendAtError, setSendAtError] = useState(false);

  const [personalizeOptions, setPersonalizeOptions] = useState([]);
  const [personalizeValue, setPersonalizeValue] = useState([
    { label: 'Personalize', value: 'personalize' }
  ]);
  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);

  const [segmentInfo, setSegmentInfo] = useState(null);

  const messageEl = useRef(null);


  useEffect(() => {
    if (props.type === 'bookingMessage') {
      let newOptions = [];
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'preArrivalMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      newOptions.push({ label: 'Keycode', value: '{{keycode}}' });
      newOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'welcomeMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Keycode', value: '{{keycode}}' });
      newOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      // setPersonalizeOptions([
      //   ...personalizeOptionsG,
      //   { label: 'Wifi', value: '{{wifi}}' },
      // ]);
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'repeatWelcomeMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Keycode', value: '{{keycode}}' });
      newOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'departureMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Review Link', value: '{{reviewlink}}' });
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }    
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.hasMessage: ', props.hasMessage, ' # ', props.type);
    }
    setHasMessage(props.hasMessage)
  }, [props.hasMessage]);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);
  
  useEffect(() => {
    setSendAfterValue({ label: props.sendAfter, value: props.sendAfter });
    // if (props.sendAfter) {
    //   setSendAfterValue({ label: props.sendAfter, value: props.sendAfter });
    // } else {
    //   setSendAfterValue({ label: '30 Secs', value: '30 Secs' });
    // }
  }, [props.sendAfter]);

  useEffect(() => {
    if (props.sendBefore === '0 Day') {
      setSendBeforeValue({ label: 'Same Day', value: 'Same Day' });
    } else {
      setSendBeforeValue({ label: props.sendBefore, value: props.sendBefore });
    }
  }, [props.sendBefore]);
  useEffect(() => {
    setSendAt(props.sendAt ? new Date(moment(props.sendAt)) : '');
  }, [props.sendAt]);




  const onHasMessageChange = (e) => {
    const { name, checked } = e.target;
    setHasMessage(checked);
    props.onHasMessageChange(checked);
  }

  const onSendAfterChange = (value) => {
    setSendAfterValue(value);
    props.onSendAfterChange(value);
  };
  const onSendBeforeChange = (value) => {
    setSendBeforeValue(value);
    if (value.value === 'Same Day') {
      props.onSendBeforeChange({ label: 'Same Day', value: '0 Day' });
      const checkInTime = moment(props.checkInTime, 'H:mm A');
      if (moment(sendAt).isSameOrBefore(checkInTime)) {
        setSendAtError(false);
        props.onSendAtErrorChange('');
      } else {
        setSendAtError(true);
        props.onSendAtErrorChange('error');
      }
    } else {
      props.onSendBeforeChange(value);
      setSendAtError(false);
      props.onSendAtErrorChange('');
    }
    // props.onSendBeforeChange(value);
  };
  const onSendAtTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange: ', selectedTime);
      console.log('onSendAtTimeChange checkInTime: ', props.checkInTime);
    }
    const checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
      console.log('sendBeforeValue: ', sendBeforeValue);
    }
    if (sendBeforeValue.value === 'Same Day') {
      if (moment(selectedTime, 'hh:mm A').isSameOrBefore(checkInTime)) {
        setSendAtError(false);
        props.onSendAtErrorChange('');
      } else {
        setSendAtError(true);
        props.onSendAtErrorChange('error');
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange:: ', new Date(moment(selectedTime, 'hh:mm A')));
    }
    setSendAt(selectedTime ? new Date(moment(selectedTime, 'hh:mm A')) : null);
    props.onSendAtTimeChange(moment(selectedTime, 'hh:mm A'));
  };

  const isValidSendAtDate = (current) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('isValidSendAtDate: ', props.checkInTime);
    }
    let checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
    }
    return current.isBefore(moment(checkInTime));
  };


  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
    }
    if (value && value.value !== 'personalize') {
      if (message) {
        // setMessage(message => `${message} ${values[0].label}`);
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
        // props.onMessageChange(`${message} ${values[0].value}`);
        props.onMessageChange(`${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
        // setTimeout(() => {
        //   setPersonalizeValues([{ label: 'Personalize', value: 'personalize' }]);
        // }, 100);
      } else {
        setMessage(message => `${value.value}`);
        props.onMessageChange(`${value.value}`);
      }
    }
  };
  const onMessageChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
    }
    setMessage(e.target.value);
    props.onMessageChange(e.target.value);
  };

  const onOpenEmojiSelectorClick = (e) => {
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      if (message) {
      // setMessage(message => `${message} ${emoji.native}`);
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
        props.onMessageChange(`${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${emoji.native}`);
        props.onMessageChange(`${emoji.native}`);
      }
    }
  };

  const onAttachMediaClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAttachMediaClick');
    }
    props.onMediaAttachClick(props.type);
  };

  const onUploadedImageClick = (e) => {
    props.onShowUploadedImageClick();
  };
  const onRemoveAttachMediaClick = (e) => {
    props.onRemoveMediaClick();
  };



  if (process.env.NODE_ENV !== 'production') {
    console.log('PreDefinedMessage sendAt: ', sendAt);
    console.log('PreDefinedMessage sendAtError: ', sendAtError);
  }

  return (
    <div className="pDefMsg pDefSms">
      <div className="pDRw hdr">
        {props.heading}
      </div>
      <div className="pDRw hasTgl">
        <div className="lt">
          <div className="title">
            {props.title}
          </div>
          <div className="desc">
            {props.desc}
          </div>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'hasMessage_'+props.type} checked={hasMessage} onChange={onHasMessageChange}/><label htmlFor={'hasMessage_'+props.type}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
      {
        hasMessage &&
        <>
          <div className="pDRw msgRw">
            <label className="gLbl">Message</label>
            <div className="gIpt">
              <textarea
                className={'gIpt ' + (hasMessage && !message ? 'error' : '')}
                onChange={onMessageChange}
                placeholder="Message..."
                value={message}
                rows="4"
                ref={messageEl}
              />
            </div>
            <div className="emojiRw">
              <div className="emojiBox">
                <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                  {
                    emojiSelectorOpen
                    ? <i className="fa fa-times-circle" />
                    : <img src={emojiIcon} />
                  }

                </div>
              </div>
            </div>
            { emojiSelectorOpen &&
              <div className="emojiBox">
                <Picker data={emojiData}
                  onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                />
              </div>
            }
            {
              props.hasMms &&
              <div className="atchRw">
                <div className="attachIcon">
                  <Dropzone onDrop={acceptedFiles => props.onDrop(acceptedFiles)} multiple={false}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({className: 'dndBox1'})}>
                      <input {...getInputProps()} />
                      <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Attach media'}>
                      <span onClick={onAttachMediaClick}>
                        <i className="fas fa-image"></i>
                      </span>
                      </ToolTipWrapper>
                    </div>
                    )}
                  </Dropzone>
                </div>
                {
                  props.mediaPath &&
                  <div className="uplThmb">
                    <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={props.mediaName}>
                      <img src={props.mediaPath} onClick={onUploadedImageClick} />
                    </ToolTipWrapper>
                    <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={"Remove media"}>
                      <div className="close" onClick={onRemoveAttachMediaClick}><i className="far fa-times-circle"></i></div>
                    </ToolTipWrapper>
                  </div>
                }
              </div>
            }
          </div>
          <div className="pDRw prslRw">
            <div className="tp">
              <label className="gLbl">Choose dynamic label to insert</label>
              <Select
                options={personalizeOptions}
                value={personalizeValue}
                onChange={onPersonalizeChange}
              />
            </div>
            <div className="hlpMsg">
              {props.dynamicLabel}
            </div>
          </div>
          {
            props.type === 'preArrivalMessage'
            ?
              <div className="pDRw sndAtRw">
                <div className="tp">
                  <div className="lt">
                    <label className="gLbl">Send Before</label>
                    <Select
                      options={sendBeforeDays}
                      value={sendBeforeValue}
                      onChange={onSendBeforeChange}
                      className={hasMessage && !sendBeforeValue.value ? 'hasError' : ''}
                    />
                  </div>
                  <div className="rt">
                    <label className="gLbl">Send At</label>
                    <CustomTimePicker
                      value={moment(sendAt).format('hh:mm A')}
                      onChange={onSendAtTimeChange}
                      every15Min={true}
                    />
                  </div>
                </div>
                {/* <div className="hlpMsg">
                  {props.dynamicLabel}
                </div> */}
              </div>
            : null
          }
          {
            props.type === 'bookingMessage' || props.type === 'welcomeMessage' || props.type === 'repeatWelcomeMessage' || props.type === 'departureMessage'
            ?
              <div className="pDRw sndAftRw">
                <div className="tp">
                  <div className="lt">
                    <label className="gLbl">Send After</label>
                    <Select
                      options={sendAfterTimes}
                      value={sendAfterValue}
                      onChange={onSendAfterChange}
                      className={hasMessage && !sendAfterValue.value ? 'hasError' : ''}
                    />
                  </div>
                </div>
                {/* <div className="hlpMsg">
                  {props.dynamicLabel}
                </div> */}
              </div>
            : null
          }
        </>
      }
    </div>
  );
}

export default PreDefinedMessage;
