import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { auth } from '../../../firebase';

import BarChart from '../../Charts/BarChart';
import ToolTipWrapper from '../../Misc/ToolTipWrapper';

import { setCommonParams } from '../../../redux/actions/appActions';
import { receiveSnack, removeSnack } from '../../../redux/actions/snacksActions';
import { getDataAction, getDataClearAction, getDataResetAction } from '../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS_MAP, LANGUAGE_LIST_KEYS, OTA_ID_TO_NAME_FORMAL_MAP, 
  sendEmailAlert, getUserGAId } from '../../../helpers';

import translateIcon from '../../../img/icons/translate.svg';
import editIcon from '../../../img/icons/edit.svg';

import './style.css';
import 'antd/dist/antd.css';

const gtconfig = require('../../../gtconfig');

const { RangePicker } = DatePicker;

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

function ResponseTabButton(props) {
  const onButtonClick = (e) => {
    props.onButtonClick(props.data, props.index);
  };
  let tLabel = 'Latest';
  if (!props.isCurrent) tLabel = `Resp #${props.index+1}`; // (props.index+1).toString();
  let tTip = 'Latest response';
  if (!props.isCurrent) tTip = `Response #${props.index+1}`;

  return (
    <ToolTipWrapper placement="top" overlayClassName={'sbToolTip'}
      overlay={tTip} disable={false}
    >
      <button className={'gBtn' + (props.selected ? ' selected' : '')} onClick={onButtonClick}>{tLabel}</button>
    </ToolTipWrapper>
  );
}

function PromptBottom(props) {
  const { title, prompt, is_readonly } = props.data;
  const onButtonClick = (e) => {
    props.onButtonClick(props.data);
  };
  const Button = () => <button className={'gBtn' + (props.selected ? ' selected' : '')} onClick={onButtonClick}>{title}</button>;

  return (
    <ToolTipWrapper placement="top" overlayClassName={'sbToolTip'}
      overlay={`Custom: ${prompt}`} disable={is_readonly}
    >
      <button className={'gBtn' + (props.selected ? ' selected' : '')} onClick={onButtonClick}>{title}</button>
    </ToolTipWrapper>
  );
}

function ResponseTab(props) {
  const [followUpOptions, setFollowUpOptions] = useState([]);
  const [followUpValue, setFollowUpValue] = useState(
    { label: 'Select', value: 'select' }
  );
  const [selectedFollowUpPrompt, setSelectedFollowUpPrompt] = useState({});
  const [followUpText, setFollowUpText] = useState('');
  const [selectedPromptButton, setSelectedPromptButton] = useState('');

  const [genResponseText, setGenResponseText] = useState('');
  const [genResponseTextTranslated, setGenResponseTextTranslated] = useState('');

  const [showOriginalGenerated, setShowOriginalGenerated] = useState(true);

  useEffect(() => {
    const { rgLocationInfo } = props;
    if (rgLocationInfo) {
      const { followup_prompt_templates } = rgLocationInfo;
      if (followup_prompt_templates && followup_prompt_templates.keyValue &&
        followup_prompt_templates.list && followup_prompt_templates.list.length
      ) {
        const tOptions = [{ label: 'Select', value: 'select' }];
        followup_prompt_templates.list.forEach((item) => {
          if (followup_prompt_templates.keyValue[item]) {
            // tOptions.push({ label: followup_prompt_templates.keyValue[item].title, value: item });
            if (followup_prompt_templates.keyValue[item].is_readonly) {
              tOptions.push({ label: `${followup_prompt_templates.keyValue[item].title}`, value: item });
            } else {
              tOptions.push({ label: `[custom]  ${followup_prompt_templates.keyValue[item].title}`, value: item });
            }
          }
        });
        setFollowUpOptions(tOptions);
      }
      if (props.response) {
        if (!props.isCurrent) {
          setFollowUpText(props.response.follow_up_text || '');
          setSelectedPromptButton(props.response.follow_up_prompt_id || '');
        } else {
          setFollowUpText('');
          setSelectedPromptButton('');
        }
      }
    }
  }, []);

  useEffect(() => {
    setGenResponseText(props.generated_response);
    setGenResponseTextTranslated(props.generated_response_translated || '');
  }, [props.generated_response]);

  const onGenResponseChange = (e) => {
    const { name, value } = e.target;
    if (!genResponseTextTranslated) {
      setGenResponseText(value); return;
    }
    if (!showOriginalGenerated) {
      setGenResponseText(value);
    } else {
      setGenResponseTextTranslated(value);
    }
  };

  const onCopyClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCopyClick showOriginalGenerated: ', showOriginalGenerated);
      console.log('onCopyClick genResponseTextTranslated: ', genResponseTextTranslated);
      console.log('onCopyClick genResponseText: ', genResponseText);
    }
    if (showOriginalGenerated) {
      props.onCopyClick(genResponseTextTranslated || genResponseText);
    } else {
      props.onCopyClick(genResponseText);
    }
  };
  const onPromptButtonClick = (promptData) => {
    if (!props.isCurrent) return;
    setSelectedPromptButton(promptData._id);
    if (!promptData.is_readonly) {
      setFollowUpText(promptData.prompt);
    }
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Prompt`,
        page_path: `${props.location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(props.userState.user),
      });
    } catch (e) {}
  };
  const onFollowUpChange = (value) => {
    setFollowUpValue(value);
    if (value.value) {
      const { rgLocationInfo } = props;
      if (rgLocationInfo) {
        const { followup_prompt_templates } = rgLocationInfo;
        if (followup_prompt_templates && followup_prompt_templates.keyValue &&
          followup_prompt_templates.keyValue[value.value]
        ) {
          setSelectedFollowUpPrompt(followup_prompt_templates.keyValue[value.value]);
          if (!followup_prompt_templates.keyValue[value.value].is_readonly) {
            setFollowUpText(followup_prompt_templates.keyValue[value.value].prompt);
          } else {
            setFollowUpText('');
          }
        }
      }
    }
  };
  const onFollowUpTextChange = (e) => {
    setFollowUpText(e.target.value);
  };

  const onRegenerateResponseClick = (e) => {
    const params = {
      logId: props.logId,
    };
    // if (selectedPromptButton) params.follow_up_prompt_id = selectedPromptButton;
    if (setSelectedFollowUpPrompt) params.follow_up_prompt_id = selectedFollowUpPrompt._id;
    if (followUpText) params.follow_up_text = followUpText;
    props.onRegenerateClick(params);
  };

  const onShowOriginalGeneratedClick = (e) => {
    setShowOriginalGenerated(true);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Show Original Generated`,
        page_path: `${props.location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(props.userState.user),
      });
    } catch (e) {}
  };
  const onShowEnglishGeneratedClick = (e) => {
    setShowOriginalGenerated(false);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Show English Generated`,
        page_path: `${props.location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(props.userState.user),
      });
    } catch (e) {}
  };

  const { rgLocationInfo, getInProgress } = props;
  if (!rgLocationInfo) return null;
  const { followup_prompt_templates } = rgLocationInfo;

  return (
    <div className={'tbBox' + (props.isCurrent ? ' current' : '')}>
      <div className="genRBx">
        <div className="ttlBx">
          Generated Response
          <span onClick={onCopyClick}><i class="fa-regular fa-copy"></i>Copy</span>
        </div>
        {
          genResponseTextTranslated &&
          <div className="tbs">
            <span className={(showOriginalGenerated ? 'selected' : '')} onClick={onShowOriginalGeneratedClick}>{LANGUAGE_LIST_KEYS[props.response.input_text_detected_lang]}</span>
            <span className={(!showOriginalGenerated ? 'selected' : '')} onClick={onShowEnglishGeneratedClick}>English</span>
          </div>
        }
        <div className="respBx gIpt">
          <textarea
            value={showOriginalGenerated ? genResponseTextTranslated || genResponseText : genResponseText}
            onChange={onGenResponseChange}
          />
          {/*props.generated_response*/}
        </div>
        {
          (props.response.follow_up_prompt_title || props.response.follow_up_text) &&
          <div className="usdPrmptBx">
            {
              props.response.follow_up_prompt_title &&
              <p><b>Used:</b> {props.response.follow_up_prompt_title}</p>
            }
            {
              props.response.follow_up_text &&
              <p><b>Used further instructions:</b> {props.response.follow_up_text}</p>
            }
          </div>
        }
      </div>
      {
        props.isCurrent &&
        <div className="folupBx">
          {
            props.isCurrent &&
            <div className="folupSep" />
          }
          {
            props.isCurrent &&
            <div className="furthrInstrBtnBx">
              <div className="gLbl">Further instructions (Optional)</div>
              <div className="prmptBtns">
                <Select
                  className="promptSelect"
                  options={followUpOptions}
                  onChange={onFollowUpChange}
                  value={followUpValue}
                />
                {/*
                  followup_prompt_templates && followup_prompt_templates.keyValue && followup_prompt_templates.list &&
                  followup_prompt_templates.list.length
                  ?
                  followup_prompt_templates.list.map((item) => {
                    if (!followup_prompt_templates.keyValue[item] || !followup_prompt_templates.keyValue[item].title) return null;
                    return (
                      <PromptBottom
                        data={followup_prompt_templates.keyValue[item]}
                        selected={selectedPromptButton === item}
                        onButtonClick={onPromptButtonClick}
                      />
                    )
                  })
                  : null
                */}
              </div>
            </div>
          }
          {
            props.isCurrent &&
            <div className="prmptSel">
              {/*}<div className="tp">
                <div className="gLbl">Further instructions (Optional)</div>
                {/*}<Select
                  className="promptSelect"
                  options={followUpOptions}
                  onChange={onFollowUpChange}
                  value={followUpValue}
                />
              </div>*/}
              {
                (followUpValue.value === 'select' || !selectedFollowUpPrompt.is_readonly) &&
                <div className="gIpt">
                  <textarea
                    onChange={onFollowUpTextChange}
                    value={followUpText}
                    placeholder="Follow up text..."
                    readOnly={!props.isCurrent}
                  />
                </div>
              }
            </div>
          }
          {
            props.isCurrent &&
            <div className="btnBx">
              <button className="gBtn" onClick={onRegenerateResponseClick}>
                Regenerate Response
                { getInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            </div>
          }
        </div>
      }
    </div>
  );
}

function StarClickable(props) {
  const onMEnter = (e) => { props.onMEnter(props.starValue); }
  const onMLeave = (e) => { props.onMLeave(props.starValue); }
  const onStarClick = (e) => { props.onStarClick(props.starValue); }
  if (process.env.NODE_ENV !== 'production') {
    console.log('StarClickable props: ', props);
  }
  return (
    <div className={'star' + (props.currentHoverStarValue >= parseInt(props.starValue) ? ' active' : '') + (props.currentSelectedStarValue >= parseInt(props.starValue) ? ' selected' : '')}
      onClick={onStarClick}
      onMouseEnter={onMEnter} onMouseLeave={onMLeave}
    ></div>
  );
}

function Generate(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();


  const [rgLocOptions, setRgLocOptions] = useState([]);
  const [rgLocValue, setRgLocValue] = useState(
    { label: 'Select', value: 'select' }
  );
  const [rgLocError, setRgLocError] = useState('');

  const [otaOptions, setOtaOptions] = useState([]);
  const [otaValue, setOtaValue] = useState(
    { label: 'Review channel (optional)', value: 'select' }
  );

  const [currentSelectedStarValue, setCurrentSelectedStarValue] = useState(0);
  const [currentHoverStarValue, setCurrentHoverStarValue] = useState(0);

  const [reviewerName, setReviewerName] = useState('');

  const [reviewText, setReviewText] = useState('');
  const [reviewTextError, setReviewTextError] = useState('');

  const [promptOptions, setPromptOptions] = useState([]);
  const [promptValue, setPromptValue] = useState(
    { label: 'Select', value: 'select' }
  );

  const [additionalInstructionText, setAdditionalInstructionText] = useState('');

  const [selectedTabButtonIndex, setSelectedTabButtonIndex] = useState(0);

  const [reviewTextLang, setReviewTextLang] = useState('');
  const [reviewTextTranslated, setReviewTextTranslated] = useState('');
  const [translateInProgress, setTranslateInProgress] = useState(false);
  const [showOriginal, setShowOriginal] = useState(true);

  useEffect(() => {
    if (!urlParams.generateidentifier) {
      const temp = (new Date()).getTime().toString();
      navigate(`/response-generator/generate/${temp}`);
    } else {
      dispatch(getDataClearAction({
        op: 'get_response_generator_response',
        params: {
          session_id: urlParams.generateidentifier,
        },
      }));
      updateLocationOptions();
      const { generator, locations } = responseGeneratorState;
      if (generator[urlParams.generateidentifier] && generator[urlParams.generateidentifier].current_response) {
        const { reviewer_name, input_text, additional_input_text, rg_location__id, loc_is_default, ota, rating, prompt_id } = generator[urlParams.generateidentifier].params || {};
        setReviewerName(reviewer_name || '');
        setReviewText(input_text || '');
        if (loc_is_default) { setRgLocValue({ label: 'Default', value: 'default' }); }
        else {
          if (process.env.NODE_ENV !== 'production') {
            console.log('useEffect locations.keyValue: ', locations.keyValue);
            console.log('useEffect rg_location__id: ', rg_location__id);
            console.log('useEffect locations.keyValue[rg_location__id]: ', locations.keyValue[rg_location__id]);
          }
          if (locations && locations.keyValue && rg_location__id && locations.keyValue[rg_location__id]) {
           setRgLocValue({ label: locations.keyValue[rg_location__id].name, value: rg_location__id });
           updatePromptOptions(rg_location__id);
          }
        }
        setCurrentSelectedStarValue(rating || 0);
        if (ota) { setOtaValue({ label: OTA_ID_TO_NAME_FORMAL_MAP[ota], value: ota }); }
        else {
          setOtaValue({ label: 'Select OTA (optional)', value: 'select' });
        }
        if (!prompt_id) { setPromptValue({ label: 'Select', value: 'select' }); }
        else {
          if (locations && locations.keyValue && rg_location__id && locations.keyValue[rg_location__id] &&
            locations.keyValue[rg_location__id].prompt_templates && locations.keyValue[rg_location__id].prompt_templates.keyValue &&
            locations.keyValue[rg_location__id].prompt_templates.keyValue[prompt_id]
          ) {
           setPromptValue({ label: locations.keyValue[rg_location__id].prompt_templates.keyValue[prompt_id].title, value: prompt_id });
          }
        }
        setAdditionalInstructionText(additional_input_text || '');
        setSelectedTabButtonIndex(0);
      } else {
        setReviewerName('');
        setReviewText('');
        setReviewTextError('');
        setReviewTextLang('');
        setReviewTextTranslated('');
        setShowOriginal(true);
        // setRgLocValue({ label: 'Default', value: 'default' });
        setCurrentSelectedStarValue(0);
        // setOtaValue({ label: 'Select OTA (optional)', value: 'select' });
        setPromptValue({ label: 'Select', value: 'select' });
        setAdditionalInstructionText('');
        setSelectedTabButtonIndex(0);
      }
    }
  }, [urlParams.generateidentifier]);

  // analytics reporting
  useEffect(() => {
    try {
      document.title = `Review Response AI - Generate`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {

    const tOptions = [{ label: 'Select OTA (optional)', value: 'select' }];
    tOptions.push({ label: 'Tripadvisor', value: 'tripadvisor' });
    tOptions.push({ label: 'Booking', value: 'booking' });
    tOptions.push({ label: 'Expedia', value: 'expedia' });
    tOptions.push({ label: 'Google', value: 'google' });
    setOtaOptions(tOptions);
  }, []);

  useEffect(() => {
    const { generator } = responseGeneratorState;
    if (urlParams.generateidentifier && generator[urlParams.generateidentifier] &&
      generator[urlParams.generateidentifier].all_responses &&
      !generator[urlParams.generateidentifier].getInProgress &&
      generator[urlParams.generateidentifier].getStatus &&
      generator[urlParams.generateidentifier].getStatus.success
    ) {
      setSelectedTabButtonIndex(generator[urlParams.generateidentifier].all_responses.length);
    }
  }, [responseGeneratorState.generator])

  // useEffect(() => {
  //   const { commonParams } = props.locationprops;
  //   let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
  //   let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
  //   let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
  //   setDateFilterStartDate(startDate);
  //   setDateFilterEndDate(endDate);
  //   setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
  //   getStatsData(dateFilterStartDate, dateFilterEndDate);
  // }, []);

  const onCopyClick = (data) => {
    copy(data);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Copy - Generated`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onNewClick = (e) => {
    dispatch(getDataResetAction({
      op: 'get_response_generator_response',
      params: {
        session_id: urlParams.generateidentifier,
      },
    }));
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `New`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
    const temp = (new Date()).getTime().toString();
    navigate(`/response-generator/generate/${temp}`);
  };

  const updateLocationOptions = () => {
    const { locations } = responseGeneratorState;
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateLocationOptions locations: ', locations);
    }
    if (locations && locations.keyValue && locations.list && locations.list.length) {
      const tOptions = [{ label: 'Select', value: 'select' }];
      locations.list.forEach((item) => {
        if (locations.keyValue[item] && locations.keyValue[item].is_enabled &&
          !locations.keyValue[item].is_removed
        ) {
          tOptions.push({ label: locations.keyValue[item].name, value: item });
        }
      });
      setRgLocOptions(tOptions);
      if (tOptions.length === 2) {
        setRgLocValue({ label: tOptions[1].label, value: tOptions[1].value });
        updatePromptOptions(tOptions[1].value);
      }
      // setRgLocValue({ label: 'Default', value: 'default' });
      // updatePromptOptions('default');
    }
  };

  const updatePromptOptions = (rgLocId) => {
    const tOptions = [{ label: 'Select', value: 'select' }];
    const { locations } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[rgLocId] || {} : {};
    if (rgLocationInfo.prompt_templates && rgLocationInfo.prompt_templates.keyValue &&
      rgLocationInfo.prompt_templates.list &&
      rgLocationInfo.prompt_templates.list.length
    ) {

      rgLocationInfo.prompt_templates.list.forEach((item) => {
        if (rgLocationInfo.prompt_templates.keyValue[item]) {
          if (rgLocationInfo.prompt_templates.keyValue[item].is_enabled) {
            tOptions.push({ label: rgLocationInfo.prompt_templates.keyValue[item].title, value: item });
          }
        }
      });
    }
    setPromptOptions(tOptions);
    setPromptValue({ label: 'Select', value: 'select' });
  }

  const setDefaultPromptValue = (rgLocId) => {
    let defaultValue = { label: 'Select', value: 'select' };
    let defaultPrompt = {};
    const { locations } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[rgLocId] || {} : {};
    if (rgLocationInfo.prompt_templates && rgLocationInfo.prompt_templates.keyValue &&
      rgLocationInfo.prompt_templates.list &&
      rgLocationInfo.prompt_templates.list.length
    ) {
      rgLocationInfo.prompt_templates.list.forEach((item) => {
        if (rgLocationInfo.prompt_templates.keyValue[item]) {
          if (rgLocationInfo.prompt_templates.keyValue[item].is_default) {
            defaultValue = { label: rgLocationInfo.prompt_templates.keyValue[item].title, value: item };
            defaultPrompt = { ...rgLocationInfo.prompt_templates.keyValue[item] };
          }
        }
      });
    }
    setPromptValue(defaultValue);
    if (defaultPrompt.prompt) {
      setAdditionalInstructionText(defaultPrompt.prompt || '');
    }
  }

  const onRgLocChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRgLocChange value: ', value);
    }

    const { generator } = responseGeneratorState;
    if (generator[urlParams.generateidentifier].current_response) return;

    setRgLocValue(value);
    if (value.value) {
      updatePromptOptions(value.value);
      setDefaultPromptValue(value.value);
      setRgLocError(value.value === 'select' ? 'error' : '');
    }
  };

  const onStarClickableClick = (starValue) => {
    const { generator } = responseGeneratorState;
    if (generator[urlParams.generateidentifier].current_response) return;

    setCurrentSelectedStarValue(starValue);
    props.onStarClick(props.data._id, starValue);
  };
  const onStarClickableMEnter = (starValue) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onStarClickableMEnter: ', starValue);
    }
    setCurrentHoverStarValue(parseInt(starValue));
  };
  const onStarClickableMLeave = (starValue) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onStarClickableMLeave: ', starValue);
    }
    setCurrentHoverStarValue(0);
  };

  const onOtaChange = (value) => {
    const { generator } = responseGeneratorState;
    if (generator[urlParams.generateidentifier].current_response) return;
    setOtaValue(value);
  }

  const onReviewerNameChange = (e) => {
    const { name, value } = e.target;
    setReviewerName(value);
  };

  const onReviewTextChange = (e) => {
    const { name, value } = e.target;
    if (showOriginal) {
      setReviewText(value);
    }
  };
  const onReviewTextCopyClick = (e) => {
    if (showOriginal) {
      copy(reviewText);
    } else {
      copy(reviewTextTranslated);
    }
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Copy - Review Text`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onPromptChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPromptChange value: ', value);
    }

    const { generator } = responseGeneratorState;
    if (generator[urlParams.generateidentifier].current_response) return;

    setPromptValue(value);
    if (value.value) {
      const { locations } = responseGeneratorState;
      const rgLocationInfo = locations.keyValue ? locations.keyValue[rgLocValue.value] || {} : {};
      if (rgLocationInfo.prompt_templates && rgLocationInfo.prompt_templates.keyValue &&
        rgLocationInfo.prompt_templates.keyValue[value.value]
      ) {
        setAdditionalInstructionText(rgLocationInfo.prompt_templates.keyValue[value.value].prompt || '');
      } else {
        setAdditionalInstructionText('');
      }
    }
  };

  const onAdditionalInstrctionsTextChange = (e) => {
    const { name, value } = e.target;
    setAdditionalInstructionText(value);
  };

  const onGenerateResponseClick = (e) => {
    let hasError = false;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onGenerateResponseClick rgLocValue: ', rgLocValue);
    }

    if (!rgLocValue.value) {
      hasError = true;
    }
    if (rgLocValue.value === 'select') {
      setRgLocError('error');
      hasError = true;
    }

    const { locations, generator } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[rgLocValue.value] || {} : {};
    if (!rgLocationInfo._id) hasError = true;

    if (generator[urlParams.generateidentifier].current_response) return;
    if (generator[urlParams.generateidentifier].getInProgress) return;

    if (!reviewText && !currentSelectedStarValue) {
      setReviewTextError('Either review text or rating is required.'); hasError = true;
    } else {
      setReviewTextError('');
    }

    if (hasError) return false;

    const params = {};
    params.rg_location__id = rgLocationInfo._id;
    params.session_id = urlParams.generateidentifier;
    if (reviewerName) params.reviewer_name = reviewerName;
    params.input_text = reviewText;
    if (currentSelectedStarValue) params.rating = currentSelectedStarValue;
    if (otaValue && otaValue.value !== 'select' && otaValue.value) params.ota = otaValue.value;
    if (promptValue && promptValue.value !== 'select' && promptValue.value) params.prompt_id = promptValue.value;
    if (additionalInstructionText) params.additional_input_text = additionalInstructionText;
    if (rgLocationInfo.is_default) params.loc_is_default = true;
    dispatch(getDataAction({
      op: 'get_response_generator_response',
      params,
    }));
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Generate Response`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onRegenerateClick = (data) => {
    let hasError = false;

    if (!rgLocValue.value) {
      hasError = true;
    }

    const { locations, generator } = responseGeneratorState;
    const rgLocationInfo = locations.keyValue ? locations.keyValue[rgLocValue.value] || {} : {};
    if (!rgLocationInfo._id) hasError = true;

    if (generator[urlParams.generateidentifier].getInProgress) return;

    if (!reviewText && !currentSelectedStarValue) {
      // setReviewTextError('error'); hasError= true;
      setReviewTextError('Either review text or rating is required.'); hasError = true;
    } else {
      setReviewTextError('');
    }

    if (hasError) return false;

    const params = { ...data };
    params.rg_location__id = rgLocationInfo._id;
    params.session_id = urlParams.generateidentifier;
    if (reviewerName) params.reviewer_name = reviewerName;
    params.input_text = reviewText;
    if (currentSelectedStarValue) params.rating = currentSelectedStarValue;
    if (rgLocationInfo.is_default) params.loc_is_default = true;
    dispatch(getDataAction({
      op: 'get_response_generator_response',
      params,
    }));
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Regenerate Response`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onTabButtonClick = (data, index) => {
    setSelectedTabButtonIndex(index);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Tab - ${index}`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onReviewTextTranslateClick = (e) => {
    if (!reviewText) return;

    const { locations, generator } = responseGeneratorState;
    const gData = generator[urlParams.generateidentifier] || {};
    if (gData.current_response) return;
    
    const rgLocationInfo = locations.keyValue ? locations.keyValue[rgLocValue.value] || {} : {};
    if (!rgLocationInfo._id) return;
    getTextTranslation({
      rg_location_id: rgLocationInfo._id,
      // location__id: locationidentifier || '',
      // group_id: urlParams.groupidentifier || '',
      textArray: [reviewText],
      destLang: 'en',
    });
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Translate - Review`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const getTextTranslation = (params) => {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.log('start getTextTranslation params: ', params);
      }
      auth.currentUser
        .getIdToken(false)
        .then((idToken) => {
          const url = gtconfig.DATA_BACKEND_URL;
          const opdata = {};
          // opdata.params = params;
          opdata.params = {
            ...params,
            relogin_identifier: window.gtCId,
          };
          const postData = {
            idToken,
            appId: gtconfig.APP_ID,
            op: 'get_text_translation',
            opdata,
          };
          if (process.env.NODE_ENV !== 'production') {
            console.log('getTextTranslation postData: ', postData);
          }

          const headers = {
            'Content-type': 'application/json',
            'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
          };
          const axiosData = {
            gtps: postData,
            M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
          };

          setTranslateInProgress(true);

          axios
            .post(url, axiosData, { headers })
            .then((response) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation response: ', response.data);
              }
              setTranslateInProgress(false);
              const { status } = response.data;
              const { data } = response.data;
              if (status === 'success' && data) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getTextTranslation data: ', data);
                }
                if (data.translated_text_array && data.translated_text_array.length) {
                  if (data.translated_text_array[0].detected_lang === 'en') {
                    setReviewTextTranslated('');
                  } else {
                    setReviewTextTranslated(data.translated_text_array[0].text || '');
                    setShowOriginal(false);
                  }
                  setReviewTextLang(data.translated_text_array[0].detected_lang || '')
                }
              } else {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getTextTranslation error: ', response);
                }
                sendEmailAlert({
                  app: gtconfig.APP_NAME,
                  subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Sentiments Error - ${auth.currentUser.uid}`, // nkcheck
                  message: JSON.stringify({ postData, responseData: response.data }),
                  error: JSON.stringify(response.data),
                });
              }
            })
            .catch((err) => {
              // axios error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation err--------------------: ', err);
                console.log('getTextTranslation err.response --------------------: ', err.response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Sentiments Error - axios - ${auth.currentUser.uid}`, // nkcheck
                message: JSON.stringify({ url, postData }),
                error: JSON.stringify({ err , config: err.config }),
              });
            })
            .catch((err) => {
            // firebase error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation firebase err--------------------: ', err);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Sentiments Error - firebase`,
                message: 'Firebase error',
                error: err.toString(),
              });
            });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('getTextTranslation e: ', e);
      }
    }
  };

  const onShowOriginalClick = (e) => {
    setShowOriginal(true);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Show Original`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };
  const onShowEnglishClick = (e) => {
    setShowOriginal(false);
    try {
      window.gtag('event', 'gt_click', {
        'event_category': `Review Response AI - Generate`,
        'event_label': `Show English`,
        page_path: `${location.pathname}`,
        page_title: `Review Response AI - Generate`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onConfigureClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onConfigureClick rgLocValue: ', rgLocValue);
    }
    if (rgLocValue && rgLocValue.value && rgLocValue.value !== 'select') {
      navigate(`/response-generator/configure/${rgLocValue.value}`)
    }
  };

  const renderDateFilterRow = (statsObj) => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Generate Response
            <button className="gBtn" onClick={onNewClick}>New</button>
          </div>
        </div>
        {/*<div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>*/}
      </div>
    );
  };

  const renderResponseTab = (gData) => {
    const { all_responses, current_response } = gData;
    if (!all_responses || !all_responses.length) return null;

    return (
      <div className="tbCtrl">
        {
          all_responses.map((item, index) => {
            return (
              <ResponseTabButton
                data={item} index={index}
                selected={selectedTabButtonIndex === index}
                onButtonClick={onTabButtonClick}
                userState={userState}
                location={location}
              />
            )
          })
        }
        <ResponseTabButton
        isCurrent={true}
          data={current_response} index={all_responses.length}
          selected={selectedTabButtonIndex === all_responses.length}
          onButtonClick={onTabButtonClick}
          userState={userState}
          location={location}
        />
      </div>
    );
  };

  const renderCurrentResponse = (gData, rgLocationInfo, getInProgress) => {
    const { current_response, all_responses } = gData;
    if (!current_response) return null;

    if (selectedTabButtonIndex === all_responses.length) {
      return (
        <ResponseTab
          key={`rtb_${selectedTabButtonIndex}`}
          rgLocationInfo={rgLocationInfo}
          response={current_response}
          generated_response={current_response.generated_response || ''}
          generated_response_translated={current_response.generated_response_translated || ''}
          logId={current_response.log_id}
          getInProgress={getInProgress}
          onCopyClick={onCopyClick}
          onRegenerateClick={onRegenerateClick}
          isCurrent={true}
          userState={userState}
          location={location}
        />
      );
    }
    if (all_responses.length && selectedTabButtonIndex <= all_responses.length) {
      return (
        <ResponseTab
          rgLocationInfo={rgLocationInfo}
          response={all_responses[selectedTabButtonIndex] || {}}
          generated_response={all_responses[selectedTabButtonIndex].generated_response || ''}
          generated_response_translated={all_responses[selectedTabButtonIndex].generated_response_translated || ''}
          logId={all_responses[selectedTabButtonIndex].log_id}
          getInProgress={getInProgress}
          onCopyClick={onCopyClick}
          onRegenerateClick={onRegenerateClick}
          userState={userState}
          location={location}
        />
      );
    }
    return null;
  };

  const renderGenerator = (generator, rgLocationInfo) => {
    const gData = generator[urlParams.generateidentifier] || {};
    const getInProgress = gData.getInProgress || false;
    return (
      <div className={'rgBx' + (gData.current_response ? ' hasResp' : '')}>
        <div className="lt">
          <div className="locSel">
            <div className="gLbl">Select Location (optional)
              <ToolTipWrapper placement="right" overlayClassName={'sbToolTip maxWidth'}
                overlay="Choose a location profile"
              >
                <i className="fas fa-info-circle" />
              </ToolTipWrapper>
            </div>
            <div className="lSel">
              <Select
                className="locPrfSelect"
                options={rgLocOptions}
                onChange={onRgLocChange}
                value={rgLocValue}
              />
              {
                rgLocValue && rgLocValue.value !== 'select' &&
                <ToolTipWrapper placement="right" overlayClassName={'sbToolTip maxWidth'}
                  overlay="Configure location"
                >
                  {/* <span onClick={onConfigureClick}><img src={editIcon} /></span> */}
                  <Link to={`/response-generator/configure/${rgLocValue.value}`}><img src={editIcon} /></Link>
                </ToolTipWrapper>
              }
            </div>
            { rgLocError && <div className="gErr">Required</div> }
          </div>
          <div className="strSel">
            <div className="stars">
              {
                Array.from(Array(5).keys()).map((item) => {
                  return (
                    <StarClickable starValue={item+1}
                      currentSelectedStarValue={currentSelectedStarValue}
                      currentHoverStarValue={currentHoverStarValue}
                      onStarClick={onStarClickableClick}
                      onMEnter={onStarClickableMEnter} onMLeave={onStarClickableMLeave}
                      key={`str_${item+1}`}
                    />
                  );
                })
              }
            </div>
            <Select
              className="otaSelect"
              options={otaOptions}
              onChange={onOtaChange}
              value={otaValue}
              isDisabled={gData.current_response}
            />
          </div>
          <div className="gIpt rvrNm">
            <div className="lt">
              <div className="gLbl">Reviewer's Name (optional)</div>
            </div>
            <div className="gIpt rt">
              <input type="text"
                onChange={onReviewerNameChange}
                value={reviewerName}
                placeholder="Reviewer's name..."
                readOnly={gData.current_response ? true : false}
              />
            </div>
          </div>

          <div className={'gIpt rvTxt' + (reviewTextTranslated ? ' hasTrnsl' : '')}>
          {
            translateInProgress &&
            <div className="gPnlLdng">
              <div className="innerWrap">
                <i className="fa fa-spinner fa-spin" />
              </div>
            </div>
          }
            <div className="lt">
              <div className="lt">
                <div className="gLbl">Review Text</div>
              </div>
              <div className="rt">
                {
                  reviewText &&
                  <span onClick={onReviewTextCopyClick}><i class="fa-regular fa-copy"></i>Copy</span>
                }
                {
                  reviewText && reviewTextLang !== 'en' &&
                  <ToolTipWrapper placement="top" overlayClassName={'sbToolTip'}
                    overlay={'Translate to English'} disable={false}
                  >
                    <span onClick={onReviewTextTranslateClick}>
                      <img src={translateIcon} />
                    </span>
                  </ToolTipWrapper>
                }
              </div>
            </div>
            {
              reviewTextTranslated &&
              <div className="tbs">
                <span className={(showOriginal ? 'selected' : '')} onClick={onShowOriginalClick}>Original{ reviewTextLang ? ` - ${LANGUAGE_LIST_KEYS[reviewTextLang]}` : ''}</span>
                <span className={(!showOriginal ? 'selected' : '')} onClick={onShowEnglishClick}>English</span>
              </div>
            }
            <textarea
              onChange={onReviewTextChange}
              value={showOriginal ? reviewText : reviewTextTranslated}
              placeholder="Add review text here..."
              readOnly={gData.current_response || !showOriginal ? true : false}
            />
            { reviewTextError && <div className="gErr">{reviewTextError}</div> }
          </div>
          <div className="prmptSel">
            <div className="tp">
              <div className="gLbl">Add additional instructions (optional)</div>
              <Select
                className="promptSelect"
                options={promptOptions}
                onChange={onPromptChange}
                value={promptValue}
                isDisabled={gData.current_response}
              />
            </div>
            <div className="gIpt">
              <textarea
                onChange={onAdditionalInstrctionsTextChange}
                value={additionalInstructionText}
                placeholder="Additional instructions..."
                readOnly={gData.current_response ? true : false}
              />
            </div>
          </div>
          {
            !gData.current_response &&
            <div className="btnBx">
              <button className="gBtn" onClick={onGenerateResponseClick}>
                Generate Response
                { getInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            </div>
          }
        </div>
        {
          gData.current_response &&
          <div className={'rt' + (gData.current_response ? ' hasResp' : '')}>
            { renderResponseTab(gData) }
            { renderCurrentResponse(gData, rgLocationInfo, getInProgress) }
          </div>
        }
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Generate generateidentifier: ', urlParams.generateidentifier);
  }

  const { generator, locations } = responseGeneratorState;
  if (!generator) return null;
  const rgLocationInfo = rgLocValue.value && locations && locations.keyValue
    ? locations.keyValue[rgLocValue.value] || {} : {};

  if (process.env.NODE_ENV !== 'production') {
    console.log('Generate rgLocationInfo: ', rgLocationInfo);
    console.log('Generate showOriginal: ', showOriginal);
  }

  return (
    <>
      {/*
        overview.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      */}
      <div className="gPScrlWrp flHt mk">
        <div className="gPg rgGnrt">
          { renderDateFilterRow() }
          { renderGenerator(generator, rgLocationInfo) }
        </div>
      </div>
    </>
  );
}

export default Generate;
