// @flow

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Mousetrap from 'mousetrap';

import './style.css';

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function GenericAlertModal(props) {

  const [showModal, setShowModal] = useState(true);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    // gtag('event', 'page_view', {
    //   page_path: `${props.location.pathname}`,
    //   page_title: `${props.locationprops.location.name} - Alert - ${props.modalType}`,
    //   user_id: props.userprops.user.uid,
    // });
    Mousetrap.bind('esc', () => props.setModalVisible(false));
    return () => {
      Mousetrap.unbind('esc');
    };
  }, []);

  const onModalClick = (e) => {
    if (e.target.id === 'cModelId') {
      if (props.disableOutsideClick) {
        // props.setModalVisible(false);
      } else {
        props.setModalVisible(false);
      }
    }
  };

  const onCancelClick = (e) => {
    if (props.inProgress) return;
    if (props.onCancelClick) props.onCancelClick();
    props.setModalVisible(false);
  };

  const onAffirmativeClick = (e) => {
    if (props.inProgress) return;
    props.onAffirmativeClick();
  };

  const onAffirmativeSecondaryClick = (e) => {
    props.onAffirmativeSecondaryClick();
  };

  const renderModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="alertModal" onClick={onModalClick}>
          <div className="contentWrap">
            <div className="content">
              <div className="title">
                {
                  props.title || 'Are you sure ?'
                }

              </div>
              <div className="body">
                <div className="rowOne">
                  {props.bodyRowOne}
                </div>
                {
                  props.bodyRowNote &&
                  <div className="rowNote">
                    <b>Note: </b>{props.bodyRowNote}
                  </div>
                }
                {
                  props.currentStatus && (props.currentStatus.success || props.currentStatus.error) &&
                  <div className="currentStatus">
                    {
                      props.currentStatus.error && <p className="error">{props.currentStatus.message}</p>
                    }
                    {
                      props.currentStatus.success && <p className="success">Updated successfully</p>
                    }
                  </div>
                }
                <div className="actions">
                  {
                    props.showCancel &&
                    <button className="cancel" onClick={onCancelClick}>Cancel</button>
                  }
                  <button className="ok" onClick={onAffirmativeClick}>
                    { props.affirmativeButtonLabel || 'Confirm' }
                    { props.inProgress && <i className="fa fa-spinner fa-spin" /> }
                  </button>
                  {
                    props.onAffirmativeSecondaryClick &&
                    <button className="ok secondary" onClick={onAffirmativeSecondaryClick}>
                      { props.affirmativeSecondaryButtonLabel || 'Confirm' }
                      { props.inProgress && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const renderContent = () => {
    if (showModal) return renderModal();
    return null;
  };

  return renderContent();
}

export default GenericAlertModal;
