import React, { useState, useEffect } from "react";

import { CompositeDecorator, Editor, EditorState, RichUtils, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import "draft-js/dist/Draft.css";

import './style.css';

import deleteIcon from '../../../img/icons/delete.svg';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}


const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  if (process.env.NODE_ENV !== 'production') {
    console.log('RichTextEditor Link url: ', url);
  }
  return (
    <a href={url} target="_blank" style={styles.link}>
      {props.children}
    </a>
  );
};

function RichTextEditor(props) {

  const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);


  // const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
  const [editorState, setEditorState] = useState(
    props.html
    ?
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.html).contentBlocks,
          convertFromHTML(props.html).entityMap,
        ),
        decorator
      )
    : EditorState.createEmpty(decorator)
  );

  const editor = React.useRef(null);

  const [convertedContent, setConvertedContent] = useState(null);

  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  function focusEditor() {
    editor.current.focus();
  }

  // const c1onvertFromHtmlToDraftContent = () => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('RichTextEditor convertFromHtmlToDraftContent');
  //   }
  //   const contentState = convertFromHTML({
  //     htmlToStyle: (nodeName, node, currentStyle) => {
  //         if (nodeName === 'span' && node.style.color === 'blue') {
  //             return currentStyle.add('BLUE');
  //         } else {
  //             return currentStyle;
  //         }
  //     },
  //     htmlToEntity: (nodeName, node, createEntity) => {
  //       if (process.env.NODE_ENV !== 'production') {
  //         console.log('RichTextEditor htmlToEntity nodeName: ', nodeName);
  //         console.log('RichTextEditor htmlToEntity node: ', node);
  //         console.log('RichTextEditor htmlToEntity createEntity: ', createEntity);
  //       }
  //         if (nodeName === 'a') {
  //             return createEntity(
  //                 'LINK',
  //                 'MUTABLE',
  //                 {url: node.href}
  //             )
  //         }
  //     },
  //     textToEntity: (text, createEntity) => {
  //         const result = [];
  //         text.replace(/\@(\w+)/g, (match, name, offset) => {
  //             const entityKey = createEntity(
  //                 'AT-MENTION',
  //                 'IMMUTABLE',
  //                 {name}
  //             );
  //             result.push({
  //                 entity: entityKey,
  //                 offset,
  //                 length: match.length,
  //                 result: match
  //             });
  //         });
  //         return result;
  //     },
  //     htmlToBlock: (nodeName, node) => {
  //       if (process.env.NODE_ENV !== 'production') {
  //         console.log('RichTextEditor htmlToBlock nodeName: ', nodeName);
  //         console.log('RichTextEditor htmlToBlock node: ', node);
  //       }
  //         if (nodeName === 'blockquote') {
  //             return {
  //                 type: 'blockquote',
  //                 data: {}
  //             };
  //         }
  //     }
  //   })(props.html);
  //   setEditorState(EditorState.createWithContent(contentState));
  // };

  // const convertFromHtmlToDraftContent = () => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('RichTextEditor convertFromHtmlToDraftContent props.html: ', props.html);
  //   }
  //   const blocksFromHTML = convertFromHTML(props.html);
  //   const state = ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap,
  //   );
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('RichTextEditor convertFromHtmlToDraftContent blocksFromHTML.contentBlocks: ', blocksFromHTML.contentBlocks);
  //     console.log('RichTextEditor convertFromHtmlToDraftContent blocksFromHTML.entityMap: ', blocksFromHTML.entityMap);
  //   }
  //
  //   // setEditorState(EditorState.createWithContent(state));
  //   setEditorState(EditorState.createWithContent(
  //             state,
  //             // decorator,
  //   ));
  // };

  useEffect(() => {
    focusEditor();
  }, []);

  useEffect(() => {
    // if (props.html) convertFromHtmlToDraftContent();
  }, []);

  const convertToHTMLLocal = () => {
    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style === 'BOLD') {
          // return <span style={{color: 'blue'}} />;
          return <b />;
        }
      },
      blockToHTML: (block) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('RichTextEditor blockToHTML: ', block);
        }
        if (block.type === 'PARAGRAPH') {
          return <p />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('RichTextEditor entityToHTML: ', entity);
        }
        if (entity.type === 'LINK') {
          return <a href={entity.data.url} target="_blank">{originalText}</a>;
        }
        return originalText;
      }
    })(editorState.getCurrentContent());
    setConvertedContent(html);
    props.onDataChange(props.sectionId, html);
  };


//   useEffect(() => {
//     // let html = convertToHTML(editorState.getCurrentContent());
//     const html = convertToHTML({
//   styleToHTML: (style) => {
//     if (style === 'BOLD') {
//       // return <span style={{color: 'blue'}} />;
//       return <b />;
//     }
//   },
//   blockToHTML: (block) => {
//     if (process.env.NODE_ENV !== 'production') {
//       console.log('RichTextEditor blockToHTML: ', block);
//     }
//     if (block.type === 'PARAGRAPH') {
//       return <p />;
//     }
//   },
//   entityToHTML: (entity, originalText) => {
//     if (process.env.NODE_ENV !== 'production') {
//       console.log('RichTextEditor entityToHTML: ', entity);
//     }
//     if (entity.type === 'LINK') {
//       return <a href={entity.data.url} target="_self">{originalText}</a>;
//     }
//     return originalText;
//   }
// })(editorState.getCurrentContent());
//     setConvertedContent(html);
//     props.onDataChange(props.sectionId, html);
//   }, [editorState]);


  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    convertToHTMLLocal();
  }

  const StyleButton = (props) => {
    let onClickButton = (e) => {
      e.preventDefault();
      props.onToggle(props.style);
    };
    return <button onMouseDown={onClickButton}>{props.label}</button>;
  };

  const BLOCK_TYPES = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ];

  const BlockStyleControls = (props) => {
    return (
      <>
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </>
    );
  };

  const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ];
  const InlineStyleControls = (props) => {
    return (
      <>
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </>
    );
  };

  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  const promptForLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setShowURLInput(true);
      setUrlValue(url);
      // this.setState({
      //   showURLInput: true,
      //   urlValue: url,
      // }, () => {
      //   setTimeout(() => this.refs.url.focus(), 0);
      // });
    }
  };

  const removeLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  const onURLChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RichTextEditor onURLChange: ', e.target.value);
    }
    setUrlValue(e.target.value);
  }

  const confirmLink = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: urlValue}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    if (process.env.NODE_ENV !== 'production') {
      console.log('RichTextEditor confirmLink entityKey: ', entityKey);
    }

    // Apply entity
    let nextEditorState = EditorState.set(editorState,
      { currentContent: contentStateWithEntity }
    );

    // Apply selection
    nextEditorState = RichUtils.toggleLink( nextEditorState,
      nextEditorState.getSelection(), entityKey
    );

    setEditorState(nextEditorState);
    setShowURLInput(false);
    setUrlValue('');
    // this.setState({
    //   editorState: nextEditorState,
    //   showURLInput: false,
    //   urlValue: '',
    // }, () => {
    //   setTimeout(() => this.refs.editor.focus(), 0);
    // });
  };

  const onLinkInputKeyDown = (e) => { if (e.which === 13) { this.confirmLink(e); } }

  const linkCancelClick = (e) => {
    setShowURLInput(false);
    setUrlValue('');
  };

  const onRemoveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('RichTextEditor onRemoveClick props: ', props);
    }
    if (props.onRemoveClick) {
      props.onRemoveClick(props.sectionId);
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RichTextEditor convertedContent: ', convertedContent);
    console.log('RichTextEditor props html: ', props.html);
  }


  let urlInput;
    if (showURLInput) {
      urlInput =
        <div>
          <input
            onChange={onURLChange}
            // ref="url"
            style={{}}
            type="text"
            value={urlValue}
            onKeyDown={onLinkInputKeyDown}
          />
          <button onMouseDown={confirmLink}> Confirm </button>
        </div>;
    }


  return (
    <div className="rte-bx">
      <div className="lt">
        <div className="rte-edit-area">
          <Editor
            ref={editor}
            editorState={editorState}
            // onChange={(editorState) => setEditorState(editorState)}
            onChange={onEditorStateChange}
          />
        </div>
        <div className="rte-controls">
          <BlockStyleControls onToggle={onBlockClick} />
          <InlineStyleControls onToggle={onInlineClick} />
        </div>
        <div className="rte-controls-link">
          <div className="btns">
            <button
              onMouseDown={promptForLink}
              style={{marginRight: 10}}>
              Add Link
            </button>
            <button onMouseDown={removeLink}>
              Remove Link
            </button>
          </div>
          {
            showURLInput &&
            <div className="ipt">
            <input
              onChange={onURLChange}
              // ref="url"
              style={{}}
              type="text"
              value={urlValue}
              onKeyDown={onLinkInputKeyDown}
            />
            <button onMouseDown={confirmLink}> Confirm </button>
            <button onMouseDown={linkCancelClick}> Cancel </button>
            </div>
          }
        </div>
      </div>
      {
        props.onRemoveClick &&
        <div className="rt">
          <div className="delCtrl">
            <span onClick={onRemoveClick}><img src={deleteIcon} /></span>
          </div>
        </div>
      }
    </div>
  );
}

const styles = {
  root: {
    fontFamily: '\'Georgia\', serif',
    padding: 20,
    width: 400,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: '\'Georgia\', serif',
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};

export default RichTextEditor;
