import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import ReviewChannels from '../Reputation/ReviewChannels';
import Alerts from '../Reputation/Alerts';
import Reports from '../Reputation/Reports';
import AutoResponses from '../Reputation/AutoResponses';
import ResponseTemplatesHome from '../Reputation/ResponseTemplates/Home';
import ResponseTemplates from '../Reputation/ResponseTemplates';
import PromptTemplates from '../Reputation/PromptTemplates';
import ReviewsWidget from '../Reputation/ReviewsWidget';
import FeedbackTextTemplates from '../Reputation/FeedbackTextTemplates';
import CompSet from "../Reputation/CompSet";

import SettingsHome from '../SettingsHome';
import SettingsEngageHome from '../SettingsHome/SettingsEngageHome';
import SettingsReputationHome from '../SettingsHome/SettingsReputationHome';
import SettingsMarketingHome from '../SettingsHome/SettingsMarketingHome';
import Profile from '../Profile';
import HotelDetails from '../HotelDetails';
import Templates from '../Templates';
import JourneyMessages from '../../Engage/JourneyMessages';
import Essentials from '../Engage/Essentials';
import MessagingChannels from '../Engage/MessagingChannels';
import Labels from '../Engage/Labels';
import WebChat from '../Engage/WebChat';
import DigitalGuide from '../Engage/DigitalGuide';
import GBM from '../Engage/GBM';

import SenderNames from '../Marketing/SenderNames';
import SenderEmails from '../Marketing/SenderEmails';
import TransactionEmailEssentials from '../Marketing/TransactionEmailEssentials';

import Users from '../Account/Users';

import { initEngageAction, initSettingsAction } from '../../../redux/actions/initActions';
import { getDataAction } from '../../../redux/actions/getDataActions';

import { ACCESS_LEVELS, getHighestAccessLevels, isLocationAdminAndAbove, hasAccessToUserManagment } from '../../../helpers';

function SettingsWrapper() {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SettingsWrapper urlParams: ', urlParams);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('LocationWrapper urlParams: ', urlParams);
      console.log('LocationWrapperurlParams locationidentifier: ', urlParams.locationidentifier);
    }
    dispatch(initSettingsAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
    dispatch(getDataAction({
      op: 'get_location_settings',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'all',
      },
    }));
  }, [urlParams.locationidentifier]);


  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  if (!userState.user.user_info || !userState.user.user_info.locations || !userState.user.user_info.locations[locationidentifier]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SettingsWrapper user_info locations missing: ', urlParams);
    }
    return null;
  }
  const locationInfo = { ...userState.user.user_info.locations[locationidentifier] };

  if (!locationState.locations[locationidentifier]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SettingsWrapper locations missing: ', urlParams);
    }
    return null;
  }

  const { settings } = locationState.locations[locationidentifier];
  if (!settings || !settings.info) return null;

  if (process.env.NODE_ENV !== 'production') {
    console.log('SettingsWrapper settings.info: ', settings.info);
  }

  // show loader while settings are being retrieved
  if (settings.getInProgress && settings.type_all) {
    return (
      <div className="gPnlLdng">
        <div className="inWrp">
          <i className="fa fa-spinner fa-spin" />
        </div>
      </div>
    );
  }

  let locationProfile = {};
  if (userState.user.user_info.profile && userState.user.user_info.profile.locations && userState.user.user_info.profile.locations[locationidentifier]) {
    locationProfile = { ...userState.user.user_info.profile.locations[locationidentifier] };
  }

  const { reputation_enabled, engage_enabled, marketing_enabled } = appState.appInfo;

  const { accessLevel, accessLevelReputation, accessLevelEngage } = getHighestAccessLevels(userState.user.user_info, locationidentifier);
  // let accessLevel = '';
  // if (userState.user.user_info && userState.user.user_info.profile) {
  //   accessLevel = userState.user.user_info.profile.locations && userState.user.user_info.profile.locations[locationidentifier] &&
  //     userState.user.user_info.profile.locations[locationidentifier].access_level
  //     ? userState.user.user_info.profile.locations[locationidentifier].access_level
  //     : '';
  //   if (userState.user.user_info.profile.access_level) {
  //     accessLevel = userState.user.user_info.profile.access_level;
  //   }
  // }
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('SettingsWrapper accessLevel: ', accessLevel);
  // }

  const isReadOnly = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY;

  return (
    <Routes>
      <Route path="*" element={<Outlet />}>


        { hasAccessToUserManagment(accessLevel) &&
          <Route path="users" element={<Users locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
        }

        {
          reputation_enabled && settings.info.has_reputation &&
          <>
            <Route path="reputation" element={<SettingsReputationHome locationInfo={settings.info} settings={settings} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            <Route path="alerts" element={<Alerts locationInfo={settings.info} settings={settings} locationProfile={locationProfile} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            <Route path="response-templates/*" element={<ResponseTemplatesHome type="response-templates" locationInfo={settings.info} settings={settings} locationProfile={locationProfile} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            <Route path="feedback-text-templates" element={<FeedbackTextTemplates type="feedback-text-templates" locationInfo={settings.info} settings={settings} locationProfile={locationProfile} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            {
              settings.info.has_comp_analysis &&
              <Route path="comp-set" element={<CompSet type="compSet" locationInfo={settings.info} settings={settings} locationProfile={locationProfile} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            }
            {/* settings.info.has_generated_response &&*/}

            <Route path="guestai-templates/*" element={<PromptTemplates type="prompt-templates" locationInfo={settings.info} settings={settings} locationProfile={locationProfile} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            { isLocationAdminAndAbove(accessLevel || accessLevelReputation) &&
              <>
                <Route path="reports" element={<Reports locationInfo={settings.info} settings={settings} locationProfile={locationProfile} isReadOnly={isReadOnly} />} />
                <Route path="s" element={<ReviewChannels locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
              </>
            }
            { isLocationAdminAndAbove(accessLevel || accessLevelReputation) && settings.info.has_gt_response &&
              <>
                <Route path="response-approval" element={<AutoResponses locationInfo={settings.info} settings={settings} locationProfile={locationProfile} isReadOnly={isReadOnly} />} />
              </>
            }
            { isLocationAdminAndAbove(accessLevel || accessLevelReputation) && <Route path="reviews-widget/*" element={<ReviewsWidget locationInfo={settings.info} settings={settings} locationProfile={locationProfile} accessLevel={accessLevel} isReadOnly={isReadOnly} />} /> }
          </>
        }

        {
          engage_enabled && settings.info.has_engage &&
          <>
            <Route path="engage" element={<SettingsEngageHome locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
            <Route path="essentials" element={<Essentials locationInfo={settings.info} settings={settings} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            <Route path="labels" element={<Labels locationInfo={settings.info} settings={settings} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            { isLocationAdminAndAbove(accessLevel) &&
              <Route path="messaging-channels" element={<MessagingChannels locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
            }
            <Route path="templates/*" element={<Templates locationInfo={settings.info} settings={settings} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />
            { isLocationAdminAndAbove(accessLevel) &&
              <>
                {
                  settings.info.has_webtext &&
                  <Route path="webchat/*" element={<WebChat locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
                }
              </>
            }
            { isLocationAdminAndAbove(accessLevel) &&
              <>
                {
                  settings.info.has_digital_guide &&
                  <Route path="digital-guide/*" element={<DigitalGuide locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
                }
              </>
            }
            <Route path="journey-messages/*" element={<JourneyMessages isReadOnly={isReadOnly} />} />
            { isLocationAdminAndAbove(accessLevel) &&
              <>
                {
                  settings.info.has_gbm &&
                  <Route path="google-business-messages" element={<GBM locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
                }
              </>
            }
          </>
        }

        {
          marketing_enabled && settings.info.has_transaction_emails &&
          <>
            <Route path="marketing" element={<SettingsMarketingHome locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
            { isLocationAdminAndAbove(accessLevel) &&
              <>
                <Route path="transaction-emails" element={<TransactionEmailEssentials locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
                <Route path="sender-names" element={<SenderNames locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
                <Route path="sender-emails" element={<SenderEmails locationInfo={settings.info} settings={settings} isReadOnly={isReadOnly} />} />
              </>
            }
          </>
        }

        <Route path="hotel-details/*" element={<HotelDetails locationInfo={settings.info} settings={settings} accessLevel={accessLevel} isReadOnly={isReadOnly} />} />

        {/*<Route path="feedbacks/*" element={<Feedbacks />} />*/}
        <Route index element={<SettingsHome isReadOnly={isReadOnly} />} />
      </Route>
      {/*<Route>
        <Route path=":locationidentifier/*" element={<LocationWrapper />} />
      </Route>*/}
    </Routes>
  );
}

export default SettingsWrapper;
