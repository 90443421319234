import axios from 'axios';
import { auth } from '../../firebase';

const gtconfig = require('../../gtconfig');



/* ***************************  Start - set logged in ****************************** */

export function setLoggedIn(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'SET_LOGGED_IN',
      payload,
    });
  };
}

/* ***************************  End - set logged in  ****************************** */


/* ***************************  Start - logout ****************************** */

export function logout(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'USER_LOGOUT',
      payload,
    });
    const { params } = payload;

    auth.signOut().then(
      () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Signed Out'); // comment
        }
        // if (params && params.uid)  logoutUpdateBackend(params.uid);
        dispatch({
          type: 'USER_LOGOUT',
          payload,
        });
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Sign Out Error', error); // comment
        }
      },
    );
  };
}

/* ***************************  End - logout  ****************************** */



/* ***************************  Start - Updated current user's recent_lang ****************************** */

export function updateCurrentUserRecentLang(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'UPDATE_CURRENT_USER_RECENT_LANG',
      payload,
    });
  };
}

/* ***************************  End - Updated current user's recent_lang  ****************************** */


/* ***************************  Start - Update self change pass identifier ****************************** */

export function setSelfPassChangeIdentifier(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'SET_SELF_PASS_CHANGE_IDENTIFIER',
      payload,
    });
  };
}

/* ***************************  End - Update self change pass identifier  ****************************** */
