import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import PreDefinedMessage from './PreDefinedMessage';
// import LocationWrapper from './LocationWrapper';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import ImagePreviewModal from '../../../Misc/ImagePreviewModal';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { getRoomLabel, STRS, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';

import './style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function JMSms(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [hasBookingMessage, setHasBookingMessage] = useState(false);
  const [bookingMessage, setBookingMessage] = useState({
    message: '',
    sendAfter: '',
    mediaPath: '',
    mediaName: '',
    smsCodesList: [],
  });

  const [hasPreArrivalMessage, setHasPreArrivalMessage] = useState(false);
  const [preArrivalMessage, setPreArrivalMessage] = useState({
    message: '',
    sendBefore: '',
    sendAt: '',
    sendAtError: '',
    mediaPath: '',
    mediaName: '',
    // whatsappMessage: '',
    // whatsappMessageId: '',
    smsCodesList: [],
    whatsappCodesList: [],
    sendEmail: '',
  });

  const [hasWelcomeMessage, setHasWelcomeMessage] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState({
    message: '',
    sendAfter: '',
    mediaPath: '',
    mediaName: '',
    smsCodesList: [],
  });

  const [hasRepeatWelcomeMessage, setHasRepeatWelcomeMessage] = useState(false);
  const [repeatWelcomeMessage, setRepeatWelcomeMessage] = useState({
    message: '',
    sendAfter: '',
    mediaPath: '',
    mediaName: '',
    smsCodesList: [],
  });

  const [hasDepartureMessage, setHasDepartureMessage] = useState(false);
  const [departureMessage, setDepartureMessage] = useState({
    message: '',
    sendAfter: '',
    mediaPath: '',
    mediaName: '',
    smsCodesList: [],
  });

  const [updatedValues, setUpdatedValues] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');

  const [activeMediaAttachForMessageType, setActiveMediaAttachForMessageType] = useState('');
  const [uploadedPath, setUploadedPath] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const imagePreviewModalRef = useRef();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Journey Messages - Sms - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    let { settings: { engage }, rule } = props;
    if (rule) {
      engage = rule.attr ? { ...rule.attr } : {};
    }

    setHasBookingMessage(engage.has_booking_message || false);
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      message: engage.booking_message_text,
      sendAfter: engage.booking_message_send_after,
      mediaPath: engage.booking_media_path,
      mediaName: engage.booking_media_name,
      smsCodesList: engage.booking_message_sms_isd_list || [],
    }));

    setHasPreArrivalMessage(engage.has_pre_arrival_message || false);
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      message: engage.pre_arrival_message_text,
      sendBefore: engage.pre_arrival_message_send_before,
      sendAt: engage.pre_arrival_message_send_at_time ? moment(engage.pre_arrival_message_send_at_time, 'HH:mm') : '',
      sendAtError: '',
      mediaPath: engage.pre_arrival_media_path,
      mediaName: engage.pre_arrival_media_name,
      smsCodesList: engage.pre_arrival_message_sms_isd_list || [],
      whatsappCodesList: engage.pre_arrival_message_whatsapp_isd_list || [],
      sendEmail: engage.pre_arrival_message_send_email || '',
    }));

    setHasWelcomeMessage(engage.has_welcome_message || false);
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      message: engage.welcome_message_text,
      sendAfter: engage.welcome_message_send_after,
      mediaPath: engage.welcome_media_path,
      mediaName: engage.welcome_media_name,
      smsCodesList: engage.welcome_message_sms_isd_list || [],
    }));

    setHasRepeatWelcomeMessage(engage.has_repeat_visitor_message || false);
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      message: engage.repeat_visitor_message_text,
      sendAfter: engage.repeat_visitor_message_send_after,
      mediaPath: engage.repeat_visitor_media_path,
      mediaName: engage.repeat_visitor_media_name,
      smsCodesList: engage.repeat_visitor_message_sms_isd_list || [],
    }));

    setHasDepartureMessage(engage.has_departure_message || false);
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      message: engage.departure_message_text,
      sendAfter: engage.departure_message_send_after,
      mediaPath: engage.departure_media_path,
      mediaName: engage.departure_media_name,
      smsCodesList: engage.departure_message_sms_isd_list || [],
    }));

    setFormChanged(false);
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    setInitialValues();
  }, [
    props.settings.engage.has_booking_message,
    props.settings.engage.booking_message_text,
    props.settings.engage.booking_message_send_after,
    props.settings.engage.has_pre_arrival_message,
    props.settings.engage.pre_arrival_message_text,
    props.settings.engage.pre_arrival_message_send_before,
    props.settings.engage.pre_arrival_message_send_at_time,
    props.settings.engage.has_welcome_message,
    props.settings.engage.welcome_message_text,
    props.settings.engage.welcome_message_send_after,
    props.settings.engage.has_repeat_visitor_message,
    props.settings.engage.repeat_visitor_message_text,
    props.settings.engage.repeat_visitor_message_send_after,
    props.settings.engage.has_departure_message,
    props.settings.engage.departure_message_text,
    props.settings.engage.departure_message_send_after,
    // props.rule,
  ]);

  // useEffect(() => {
  //   clearEditRuleData();
  // }, [formChanged]);


  /******** Booking Message *************/

  const onHasBookingMessageChange = (value) => {
    setHasBookingMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_booking_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingMessageSendAfterChange = (value) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingMessageChange = (message) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onBookingRemoveMediaClick = () => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      mediaPath: '',
      mediaName: '',
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_media_path: '',
      booking_media_name: '',
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingShowUploadedImageClick = () => {
    setShowImagePreviewModal(true);
  };

  const onBookingSmsIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onBookingSmsIsdCodesChange: ', values);
    }
    values.forEach((el) => {
      delete el.data;
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_sms_isd_list: values,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Pre-Arrival Message *************/

  const onHasPreArrivalMessageChange = (value) => {
    setHasPreArrivalMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_pre_arrival_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendBeforeChange = (value) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendBefore: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_before: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendAtTimeChange = (value) => {
    // console.log('onPreArrivalMessageSendAtTimeChange: ', moment(value).format('HH:mm'));
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendAt: new Date(moment(value)),
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_at_time: moment(value).format('HH:mm'),
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendAtErrorChange = (error) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendAtError: error,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageChange = (message) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onPreArrivalRemoveMediaClick = () => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      mediaPath: '',
      mediaName: '',
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_media_path: '',
      pre_arrival_media_name: '',
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalShowUploadedImageClick = () => {
    setShowImagePreviewModal(true);
  };

  const onPreArrivalSmsIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPreArrivalSmsIsdCodesChange: ', values);
    }
    values.forEach((el) => {
      delete el.data;
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_sms_isd_list: values,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  const onPreArrivalWhatsappIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPreArrivalWhatsappIsdCodesChange: ', values);
    }
    values.forEach((el) => {
      delete el.data;
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_whatsapp_isd_list: values,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  const onPreArrivalSendEmailChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPreArrivalSendEmailChange: ', value);
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_email: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  const onPreArrivalWhatsappMessageIdChange = (messageId) => {
    const { settings } = props;
    if (settings.engage.approved_whatsapp_templates && settings.engage.approved_whatsapp_templates.length) {
      const foundMsg = settings.engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        setPreArrivalMessage(preArrivalMessage => ({
          ...preArrivalMessage,
          whatsappMessageId: messageId,
          whatsappMessage: foundMsg.msg,
        }));
      }
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_whatsapp_message_id: messageId,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /******** Welcome Message *************/

  const onHasWelcomeMessageChange = (value) => {
    setHasWelcomeMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_welcome_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeMessageSendAfterChange = (value) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeMessageChange = (message) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onWelcomeRemoveMediaClick = () => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      mediaPath: '',
      mediaName: '',
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_media_path: '',
      welcome_media_name: '',
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeShowUploadedImageClick = () => {
    setShowImagePreviewModal(true);
  };

  const onWelcomeSmsIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onWelcomeSmsIsdCodesChange: ', values);
    }
    values.forEach((el) => {
      delete el.data;
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_sms_isd_list: values,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Repeat Welcome Message *************/

  const onHasRepeatWelcomeMessageChange = (value) => {
    setHasRepeatWelcomeMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_repeat_visitor_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeMessageSendAfterChange = (value) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeMessageChange = (message) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onRepeatWelcomeRemoveMediaClick = () => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      mediaPath: '',
      mediaName: '',
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_media_path: '',
      repeat_visitor_media_name: '',
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeShowUploadedImageClick = () => {
    setShowImagePreviewModal(true);
  };

  const onRepeatWelcomeSmsIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRepeatWelcomeSmsIsdCodesChange: ', values);
    }
    values.forEach((el) => {
      delete el.data;
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_sms_isd_list: values,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /************* Departure Message *******************/

  const onHasDepartureMessageChange = (value) => {
    setHasDepartureMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_departure_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureMessageSendAfterChange = (value) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureMessageChange = (message) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onDepartureRemoveMediaClick = () => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      mediaPath: '',
      mediaName: '',
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_media_path: '',
      departure_media_name: '',
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureShowUploadedImageClick = () => {
    setShowImagePreviewModal(true);
  };

  const onDepartureSmsIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDepartureSmsIsdCodesChange: ', values);
    }
    values.forEach((el) => {
      delete el.data;
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_sms_isd_list: values,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /************* End - Departure Message *******************/

  const onMediaAttachClick = (messageType) => {
    setActiveMediaAttachForMessageType(messageType);
  };
  const onImagePreviewModalCloseClick = (e) => {
    setShowImagePreviewModal(false);
    setUploadError('');
    if (activeMediaAttachForMessageType === 'bookingMessage') {
      if (bookingMessage.mediaPath) {
        setUploadedPath(bookingMessage.mediaPath);
      } else {
        setUploadedPath('');
      }
    }
    if (activeMediaAttachForMessageType === 'preArrivalMessage') {
      if (preArrivalMessage.mediaPath) {
        setUploadedPath(preArrivalMessage.mediaPath);
      } else {
        setUploadedPath('');
      }
    }
    if (activeMediaAttachForMessageType === 'welcomeMessage') {
      if (welcomeMessage.mediaPath) {
        setUploadedPath(welcomeMessage.mediaPath);
      } else {
        setUploadedPath('');
      }
    }
    if (activeMediaAttachForMessageType === 'repeatWelcomeMessage') {
      if (repeatWelcomeMessage.mediaPath) {
        setUploadedPath(repeatWelcomeMessage.mediaPath);
      } else {
        setUploadedPath('');
      }
    }
    if (activeMediaAttachForMessageType === 'departureMessage') {
      if (departureMessage.mediaPath) {
        setUploadedPath(departureMessage.mediaPath);
      } else {
        setUploadedPath('');
      }
    }
  };
  const onImagePreviewModalConfirmClick = (e) => {
    setShowImagePreviewModal(false);
    setUploadError('');
    if (activeMediaAttachForMessageType) {
      if (activeMediaAttachForMessageType === 'bookingMessage') {
        setBookingMessage(bookingMessage => ({
          ...bookingMessage,
          mediaPath: uploadedPath,
          mediaName: uploadedFileName,
        }));
        setUpdatedValues(updatedValues => ({
          ...updatedValues,
          booking_media_path: uploadedPath,
          booking_media_name: uploadedFileName,
        }));
        setFormChanged(true);
      }
      if (activeMediaAttachForMessageType === 'preArrivalMessage') {
        setPreArrivalMessage(preArrivalMessage => ({
          ...preArrivalMessage,
          mediaPath: uploadedPath,
          mediaName: uploadedFileName,
        }));
        setUpdatedValues(updatedValues => ({
          ...updatedValues,
          pre_arrival_media_path: uploadedPath,
          pre_arrival_media_name: uploadedFileName,
        }));
        setFormChanged(true);
      }
      if (activeMediaAttachForMessageType === 'welcomeMessage') {
        setWelcomeMessage(welcomeMessage => ({
          ...welcomeMessage,
          mediaPath: uploadedPath,
          mediaName: uploadedFileName,
        }));
        setUpdatedValues(updatedValues => ({
          ...updatedValues,
          welcome_media_path: uploadedPath,
          welcome_media_name: uploadedFileName,
        }));
        setFormChanged(true);
      }
      if (activeMediaAttachForMessageType === 'repeatWelcomeMessage') {
        setRepeatWelcomeMessage(repeatWelcomeMessage => ({
          ...repeatWelcomeMessage,
          mediaPath: uploadedPath,
          mediaName: uploadedFileName,
        }));
        setUpdatedValues(updatedValues => ({
          ...updatedValues,
          repeat_visitor_media_path: uploadedPath,
          repeat_visitor_media_name: uploadedFileName,
        }));
        setFormChanged(true);
      }
      if (activeMediaAttachForMessageType === 'departureMessage') {
        setDepartureMessage(departureMessage => ({
          ...departureMessage,
          mediaPath: uploadedPath,
          mediaName: uploadedFileName,
        }));
        setUpdatedValues(updatedValues => ({
          ...updatedValues,
          departure_media_path: uploadedPath,
          departure_media_name: uploadedFileName,
        }));
        setFormChanged(true);
      }
    }
  };


  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (process.env.NODE_ENV !== 'production') {
      console.log('acceptedFiles: ',acceptedFiles);
    }
    if (acceptedFiles.length===0) {
      return;
    }
    setUploadedPath('');
    setUploadedFileName('');
    if (acceptedFiles.length === 1) {
      acceptedFiles.forEach((file) => {
        // check file size

        let fileType = '';
        if ( /\.(jpe?g|png|gif)$/i.test(file.name) === false ) {
          setUploadError(`Supported image types: JPG, JPEG, PNG and GIF. Please try with a different image.`);
          setUploadInProgress(false);
          // setShowUploadErrorModal(true);
          return false;
        }
        if ( /\.(jpe?g|png|gif)$/i.test(file.name)) {
          fileType = 'image';
        }
        if ( /\.(pdf)$/i.test(file.name)) {
          fileType = 'pdf';
        }
        setUploadedFileName(file.name);
        setUploadError('');

        if (file.size > 1024 * 1024) {
          setUploadError('Size exceeds 1MB! Please try with a file with a size smaller than 1MB.');
          setUploadInProgress(false);
          setUploadedFileName('');
          return false;
        }
        setUploadError('');

        const reader = new FileReader();
        reader.onload = (e) => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            // console.log('fileAsBinaryString: ',fileAsBinaryString);
            console.log('e.target.result: ',e.target.result);
          }
          if (fileType === 'image') {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.


            //Validate the File Height and Width.
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (process.env.NODE_ENV !== 'production') {
                console.log('height: ', height);
                console.log('width: ', width);
              }
              // if (height !== 1024 || width != 1024) {
              //   setUploadError(`Image must be 1024px X 1024px. Tried one is ${height}px X ${width}px`);
              //   setShowUploadErrorModal(true);
              //   return false;
              // }
              uploadFile(file);
              return true;
            };
            image.src = e.target.result;
          }
          if (fileType === 'pdf') {
            uploadFile(file);
            return true;
          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsDataURL(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);

  const uploadFile = (file) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile: ', file);
    }
    setUploadedPath('');
    setUploadInProgress(true);
    setShowImagePreviewModal(true);

    let filename = file.name.replace(/\s/g,'');
    let fileExtension = 'jpg';
    let tArr = filename.split('.');
    if (tArr.length >= 2) {
      fileExtension = tArr[tArr.length-1];
    }
    const onlyFileName = filename.split(`.${fileExtension}`)[0];

    if (process.env.NODE_ENV !== 'production') {
      console.log('fileExtension: ', fileExtension);
      console.log('onlyFileName: ', onlyFileName);
    }

    let postData = new FormData();
    postData.append('file', file, file.name);
    postData.append('name', filename);
    postData.append('new_name', `${onlyFileName}.${fileExtension}`);
    // postData.append('uid', props.userprops.user.uid);
    postData.append('location_id', props.locationInfo.location_id);
    postData.append('identifier', file.name);
    postData.append('source', 'gtengage-web');

    // //get data from database
    let url = "https://xooz4w4zi5.guesttouch.com/dapi/pubg8dh9i6ncy4c73awy";
    const header = { 'gt-access-token' : "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa", 'Content-Type': 'multipart/form-data' };
    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   'x-api-key': "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa",
    // };
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile called api');
      console.log('uploadFile postData: ', postData);
    }
    axios.post(url, postData, { headers: header }).then((response) => {
      // setUploadInProgress(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('uploadFile response: ', response['data']);
      }
      const { status } = response.data;
      const { data } = response.data;
      if (status === 'success' && data && data.name && data.path) {
        setUploadedPath(data.path);
        setShowImagePreviewModal(true);
      } else {
      }
      setUploadInProgress(false);
    })
      .catch((err) => {
        console.log('uploadFile err--------------------: ',err);
      });
  };

  const clearEditRuleData = () => {
    if (props.rule) {
      dispatch(editDataClearAction({
        op: 'edit_guest_journey_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
        },
      }));
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    clearEditRuleData();
    if (formChanged) performSave();
  };

  const performSave = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave bookingMessage: ', bookingMessage);
    }

    if (hasBookingMessage && (!bookingMessage.message || !bookingMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasPreArrivalMessage && (!preArrivalMessage.message || !preArrivalMessage.sendBefore || !preArrivalMessage.sendAt || preArrivalMessage.sendAtError)) { setCurrentStatus('error'); return; }
    if (hasWelcomeMessage && (!welcomeMessage.message || !welcomeMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasRepeatWelcomeMessage && (!repeatWelcomeMessage.message || !repeatWelcomeMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasDepartureMessage && (!departureMessage.message || !departureMessage.sendAfter)) { setCurrentStatus('error'); return; }

    const params = {
      // location_id: props.locationInfo.location_id,
    };
    params.location__id = urlParams.locationidentifier;

    if ('has_booking_message' in updatedValues) params['has_booking_message'] = updatedValues.has_booking_message;
    if ('booking_message_text' in updatedValues) params['booking_message_text'] = updatedValues.booking_message_text;
    if ('booking_message_send_after' in updatedValues) params['booking_message_send_after'] = updatedValues.booking_message_send_after;
    if (props.settings.engage.has_mms) {
      if ('booking_media_path' in updatedValues) params['booking_media_path'] = updatedValues.booking_media_path;
      if ('booking_media_name' in updatedValues) params['booking_media_name'] = updatedValues.booking_media_name;
    }
    if ('booking_message_sms_isd_list' in updatedValues) params['booking_message_sms_isd_list'] = updatedValues.booking_message_sms_isd_list;

    if ('has_pre_arrival_message' in updatedValues) params['has_pre_arrival_message'] = updatedValues.has_pre_arrival_message;
    if ('pre_arrival_message_text' in updatedValues) params['pre_arrival_message_text'] = updatedValues.pre_arrival_message_text;
    if ('pre_arrival_message_send_before' in updatedValues) params['pre_arrival_message_send_before'] = updatedValues.pre_arrival_message_send_before;
    if ('pre_arrival_message_send_at_time' in updatedValues) params['pre_arrival_message_send_at_time'] = updatedValues.pre_arrival_message_send_at_time;
    if (props.settings.engage.has_mms) {
      if ('pre_arrival_media_path' in updatedValues) params['pre_arrival_media_path'] = updatedValues.pre_arrival_media_path;
      if ('pre_arrival_media_name' in updatedValues) params['pre_arrival_media_name'] = updatedValues.pre_arrival_media_name;
    }
    if ('pre_arrival_message_sms_isd_list' in updatedValues) params['pre_arrival_message_sms_isd_list'] = updatedValues.pre_arrival_message_sms_isd_list;
    if ('pre_arrival_message_whatsapp_isd_list' in updatedValues) params['pre_arrival_message_whatsapp_isd_list'] = updatedValues.pre_arrival_message_whatsapp_isd_list;
    if ('pre_arrival_message_send_email' in updatedValues) params['pre_arrival_message_send_email'] = updatedValues.pre_arrival_message_send_email;
    
    if ('has_welcome_message' in updatedValues) params['has_welcome_message'] = updatedValues.has_welcome_message;
    if ('welcome_message_text' in updatedValues) params['welcome_message_text'] = updatedValues.welcome_message_text;
    if ('welcome_message_send_after' in updatedValues) params['welcome_message_send_after'] = updatedValues.welcome_message_send_after;
    if (props.settings.engage.has_mms) {
      if ('welcome_media_path' in updatedValues) params['welcome_media_path'] = updatedValues.welcome_media_path;
      if ('welcome_media_name' in updatedValues) params['welcome_media_name'] = updatedValues.welcome_media_name;
    }
    if ('welcome_message_sms_isd_list' in updatedValues) params['welcome_message_sms_isd_list'] = updatedValues.welcome_message_sms_isd_list;

    if ('has_repeat_visitor_message' in updatedValues) params['has_repeat_visitor_message'] = updatedValues.has_repeat_visitor_message;
    if ('repeat_visitor_message_text' in updatedValues) params['repeat_visitor_message_text'] = updatedValues.repeat_visitor_message_text;
    if ('repeat_visitor_message_send_after' in updatedValues) params['repeat_visitor_message_send_after'] = updatedValues.repeat_visitor_message_send_after;
    if (props.settings.engage.has_mms) {
      if ('repeat_visitor_media_path' in updatedValues) params['repeat_visitor_media_path'] = updatedValues.repeat_visitor_media_path;
      if ('repeat_visitor_media_name' in updatedValues) params['repeat_visitor_media_name'] = updatedValues.repeat_visitor_media_name;
    }
    if ('repeat_visitor_message_sms_isd_list' in updatedValues) params['repeat_visitor_message_sms_isd_list'] = updatedValues.repeat_visitor_message_sms_isd_list;

    if ('has_departure_message' in updatedValues) params['has_departure_message'] = updatedValues.has_departure_message;
    if ('departure_message_text' in updatedValues) params['departure_message_text'] = updatedValues.departure_message_text;
    if ('departure_message_send_after' in updatedValues) params['departure_message_send_after'] = updatedValues.departure_message_send_after;
    if (props.settings.engage.has_mms) {
      if ('departure_media_path' in updatedValues) params['departure_media_path'] = updatedValues.departure_media_path;
      if ('departure_media_name' in updatedValues) params['departure_media_name'] = updatedValues.departure_media_name;
    }
    if ('departure_message_sms_isd_list' in updatedValues) params['departure_message_sms_isd_list'] = updatedValues.departure_message_sms_isd_list;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }

    setCurrentStatus('');

    if (props.rule) {
      delete params.location__id;
      dispatch(editDataAction({
        op: 'edit_guest_journey_rule',
        show_alert: false,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
          room_id: props.rule.room_id,
          attr: params,
        }
      }));
    } else {
      params.source = 'engage';
      dispatch(editDataAction({
        op: 'edit_settings',
        show_alert: true,
        params,
      }));
    }

    // dispatch(editSettingsAction({
    //   params,
    // }));
    setFormChanged(false);
    // gtag('event', 'gt_click', {
    //   'event_category': 'Settings - Chat',
    //   'event_label': 'Save',
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const renderImagePreviewModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal imagePreviewModal">
          <div className="content" ref={imagePreviewModalRef}>
            <ImagePreviewModal
              image={uploadedPath}
              onConfirmClick={onImagePreviewModalConfirmClick}
              onCancelClick={onImagePreviewModalCloseClick}
              inProgress={uploadInProgress}
              uploadError={uploadError}
              replaceFile={
                <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} multiple={false}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({className: 'dndBox1'})}>
                    <input {...getInputProps()} />
                    <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Choose a different file'}>
                      <span className="replace">{uploadError ? 'Retry' : 'Replace Image' }</span>
                      {/*<button className="reverse" onClick={onAttachMediaClick}>
                        Replace Image
                      </button>*/}
                    </ToolTipWrapper>
                  </div>
                  )}
                </Dropzone>
              }
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderStatus = () => {
    if (!currentStatus) return null;
    return (
      <div className="gBCtRw curSts">
        <p className="error">
          {
            currentStatus === 'error'
            ? 'Check above errors!'
            : currentStatus
          }
        </p>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('JMSms: ', props, ' # ', props);
    console.log('JMSms preArrivalMessage: ', preArrivalMessage);
    
  }

  return (
    <div className="gPScrlWrp jMsg">
      <div className="gPWrp jMSms">
        <div className="gPTle title">
          Guest Journey messages - SMS{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
          {
            props.rule &&
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
          }
        </div>
        {
          props.rule
          ?
            <>
              <div className="gPSubTle">
                Rule will be applied on guests whose room number / type matches with this rule and below guest journey messages will be sent.
              </div>
              <div className="gPSubTle">
                <p>Rule Name: {props.rule.label}</p>
                {
                  props.settings.rooms && props.settings.rooms.keyValue && props.settings.rooms.keyValue[props.rule.room_id] &&
                  <p>For Room: {getRoomLabel(props.settings.rooms.keyValue[props.rule.room_id])}</p>
                }
              </div>
            </>
          : null
        }
        <div className="gBCtnt">
        {
          props.settings.engage && props.settings.engage.booking_message_enabled &&
          <div className="gBCtRw jmEntRw">
              <PreDefinedMessage
                type="bookingMessage"
                heading="Booking Message"
                title="Send a booking message to the guest?"
                desc="Set an automated personalized booking message to guests."
                messageLabel="Booking Message"
                hasMessage={hasBookingMessage}
                message={bookingMessage.message}
                sendAfter={bookingMessage.sendAfter}
                onHasMessageChange={onHasBookingMessageChange}
                onMessageChange={onBookingMessageChange}
                onSendAfterChange={onBookingMessageSendAfterChange}
                locationInfo={props.locationInfo}
                settings={props.settings}
                dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the booking message.</p>}
                hasMms={props.settings.engage.has_mms}
                mediaPath={bookingMessage.mediaPath}
                mediaName={bookingMessage.mediaName}
                onDrop={onDrop}
                onMediaAttachClick={onMediaAttachClick}
                onRemoveMediaClick={onBookingRemoveMediaClick}
                onShowUploadedImageClick={onBookingShowUploadedImageClick}

                smsCodesList={bookingMessage.smsCodesList}
                onSmsIsdCodesChange={onBookingSmsIsdCodesChange}
              />
            </div>
          }
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="preArrivalMessage"
              heading="Pre-Arrival Message"
              title="Send a pre-arrival message to the guest?"
              desc="Set an automated personalized pre-arrival message to guests."
              messageLabel="Pre-Arrival Message"
              hasMessage={hasPreArrivalMessage}
              message={preArrivalMessage.message}
              sendBefore={preArrivalMessage.sendBefore}
              sendAt={preArrivalMessage.sendAt}
              
              onHasMessageChange={onHasPreArrivalMessageChange}
              onMessageChange={onPreArrivalMessageChange}
              onSendBeforeChange={onPreArrivalMessageSendBeforeChange}
              onSendAtTimeChange={onPreArrivalMessageSendAtTimeChange}
              onSendAtErrorChange={onPreArrivalMessageSendAtErrorChange}
              // checkInTime={'10:00 AM'}
              checkInTime={props.settings.engage.check_in_time || '10:00 AM'}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the pre-arrival message.</p>}
              smsIsdHelp={<p>Message set above will be sent via SMS</p>}
              whatsappIsdHelp={'Whatsapp message set under "Journey Messages -> Whatsapp" will be sent'}
              whatsappIsdHelpTt={'Selected template under respective whatsapp journey message will be used here. Whatsapp journey message need not be enabled.'}
              sendEmailHelp={'Email set under "Journey Messages -> Email" will be sent'}
              sendEmailHelpTt={'Selected template under respective email journey message will be used here. Email journey message need not be enabled.'}
              hasMms={props.settings.engage.has_mms}
              mediaPath={preArrivalMessage.mediaPath}
              mediaName={preArrivalMessage.mediaName}
              onDrop={onDrop}
              onMediaAttachClick={onMediaAttachClick}
              onRemoveMediaClick={onPreArrivalRemoveMediaClick}
              onShowUploadedImageClick={onPreArrivalShowUploadedImageClick}

              smsCodesList={preArrivalMessage.smsCodesList}
              whatsappCodesList={preArrivalMessage.whatsappCodesList}
              sendEmail={preArrivalMessage.sendEmail}
              onSmsIsdCodesChange={onPreArrivalSmsIsdCodesChange}
              onWhatsappIsdCodesChange={onPreArrivalWhatsappIsdCodesChange}
              onSendEmailChange={onPreArrivalSendEmailChange}

              // whatsappMessageId={preArrivalMessage.whatsappMessageId}
              // whatsappMessage={preArrivalMessage.whatsappMessage}
              // onWhatsappMessageIdChange={onPreArrivalWhatsappMessageIdChange}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="welcomeMessage"
              heading="Welcome Message"
              title="Send a check-in welcome message to the guest?"
              desc="Set an automated personalized welcome message to guests."
              messageLabel="Welcome Message"
              hasMessage={hasWelcomeMessage}
              message={welcomeMessage.message}
              sendAfter={welcomeMessage.sendAfter}
              onHasMessageChange={onHasWelcomeMessageChange}
              onMessageChange={onWelcomeMessageChange}
              onSendAfterChange={onWelcomeMessageSendAfterChange}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{wifi}}"} code.</p>}
              hasMms={props.settings.engage.has_mms}
              mediaPath={welcomeMessage.mediaPath}
              mediaName={welcomeMessage.mediaName}
              onDrop={onDrop}
              onMediaAttachClick={onMediaAttachClick}
              onRemoveMediaClick={onWelcomeRemoveMediaClick}
              onShowUploadedImageClick={onWelcomeShowUploadedImageClick}

              smsCodesList={welcomeMessage.smsCodesList}
              onSmsIsdCodesChange={onWelcomeSmsIsdCodesChange}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="repeatWelcomeMessage"
              heading="Repeat Welcome Message"
              messageLabel="Welcome Message"
              title="Send check-in welcome message for returning guests?"
              desc="Set an automated personalized welcome message to returning guests."
              hasMessage={hasRepeatWelcomeMessage}
              message={repeatWelcomeMessage.message}
              sendAfter={repeatWelcomeMessage.sendAfter}
              onHasMessageChange={onHasRepeatWelcomeMessageChange}
              onMessageChange={onRepeatWelcomeMessageChange}
              onSendAfterChange={onRepeatWelcomeMessageSendAfterChange}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{wifi}}"} code.</p>}
              hasMms={props.settings.engage.has_mms}
              mediaPath={repeatWelcomeMessage.mediaPath}
              mediaName={repeatWelcomeMessage.mediaName}
              onDrop={onDrop}
              onMediaAttachClick={onMediaAttachClick}
              onRemoveMediaClick={onRepeatWelcomeRemoveMediaClick}
              onShowUploadedImageClick={onRepeatWelcomeShowUploadedImageClick}

              smsCodesList={repeatWelcomeMessage.smsCodesList}
              onSmsIsdCodesChange={onRepeatWelcomeSmsIsdCodesChange}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="departureMessage"
              heading="Departure Message"
              messageLabel="Departure Message"
              title="Send a check-out message to guests?"
              desc="Set a personalized check-out message to guests."
              hasMessage={hasDepartureMessage}
              message={departureMessage.message}
              sendAfter={departureMessage.sendAfter}
              onHasMessageChange={onHasDepartureMessageChange}
              onMessageChange={onDepartureMessageChange}
              onSendAfterChange={onDepartureMessageSendAfterChange}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{reviewlink}}"} to collect guest feedback and reviews.</p>}
              hasMms={props.settings.engage.has_mms}
              mediaPath={departureMessage.mediaPath}
              mediaName={departureMessage.mediaName}
              onDrop={onDrop}
              onMediaAttachClick={onMediaAttachClick}
              onRemoveMediaClick={onDepartureRemoveMediaClick}
              onShowUploadedImageClick={onDepartureShowUploadedImageClick}

              smsCodesList={departureMessage.smsCodesList}
              onSmsIsdCodesChange={onDepartureSmsIsdCodesChange}
            />
          </div>
          {
            props.rule
            ?
              <>
                {
                  props.rule.editStatus && (props.rule.editStatus.success || props.rule.editStatus.error) &&
                  <div className="gBCtRw curSts">
                    {
                      props.rule.editStatus.error && <p className="gErr">{props.rule.editStatus.message || 'Error!'}</p>
                    }
                    {
                      props.rule.editStatus.success && <p className="success">Updated successfully</p>
                    }
                  </div>
                }
              </>
            : null
          }
          { renderStatus() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.rule
                  ?
                    <>
                      {
                        props.settings.engage.journey_rules &&
                        props.settings.engage.journey_rules.keyValue &&
                        props.settings.engage.journey_rules.keyValue[props.rule._id] &&
                        props.settings.engage.journey_rules.keyValue[props.rule._id].editInProgress
                        ? <i className="fa fa-spinner fa-spin" />
                        : null
                      }
                    </>
                  :
                    <>{ props.settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }</>
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
      { (showImagePreviewModal) && renderImagePreviewModal() }
    </div>
  );
}

export default JMSms;
