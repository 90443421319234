import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import MessageSent from './MessageSent';
import MessageReceived from './MessageReceived';
import EmptyMessages from '../../../../../Empty/EmptyMessages';
import GenericSideModal from '../../../../../Misc/GenericSideModal';

import { editDataAction } from '../../../../../../redux/actions/editDataActions';

import { isDateChanged, getAvataarName } from '../../../../../../helpers';

import './style.css';

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;

function GuestConversationBox(props) {
  // const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  // let urlParams = useParams();

  const [showEmptyState, setShowEmptyState] = useState(false);

  const [showSideModal, setShowSideModal] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [props.guest._id]);

  useEffect(() => {
    if (!props.guest.getInProgress && props.guest.getStatus && props.guest.getStatus.success) {
      const notReadFound = props.guest.messages ? props.guest.messages.find(el => el.type === 1 && !el.read) : false;
      if (process.env.NODE_ENV !== 'production') {
        console.log('notReadFound: ', notReadFound);
      }
      if (notReadFound) {
        const params = {
          location__id: props.locationidentifier,
        };
        if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
          params.guest_id = props.guest._id;
        }
        if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
          params.outside_guest_id = props.guest._id;
        }
        params.guest_type = props.guestType;
        params.all = true;
        dispatch(editDataAction({
          op: 'mark_message_as_read',
          params,
        }));
      }
    }
  }, [props.guest._id, props.guest.getInProgress]);

  useEffect(() => {
    setTimeout(() => {
        scrollToBottom();
      }, 100);
  }, [props.guest.getInProgress]);

  useEffect(() => {
    if (props.guest.messages && props.guest.messages.length) {
      setShowEmptyState(false);
    } else {
      setShowEmptyState(true)
    }
  }, [props.guest.messages]);

  const scrollToBottom = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('scrollToBottom');
    }
    scroll.scrollToBottom({
      duration: 100,
      delay: 100,
      smooth: true,
      containerId: 'gcBId',
      // offset: 50, // Scrolls to element + 50 pixels down the page
    });
  };

  const onShowSideModal = (mt) => {
    setShowSideModal(true);
  };
  const onSideModalClose = (e) => {
    setShowSideModal(false);
  };

  const renderSideModal = () => {
    if (!showSideModal) return;
    return (
      <GenericSideModal
        modalType="messageTemplateGeneric"
        className="mtGen"
        data={{
          opName: 'add',
        }}
        extData={{
          title: 'Add Message Template',
          settings: props.settings
        }}
        setModalVisible={onSideModalClose}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    )
  };

  return (
    <div id="gcBId" className="gcB">
      <div className="inrB">
        {
          showEmptyState
          ? <EmptyMessages />
          :
            props.guest.messages && props.guest.messages.map((item, index) => {
              let dateChange = null;
              let dateChangePosition = 'bottom';
              if (props.guest.messages.length > 1) {
                if (index === props.guest.messages.length - 1) {
                  dateChange = item.date_created;
                } else {
                  dateChange = isDateChanged(item.date_created, props.guest.messages[index+1].date_created) ? item.date_created : null;
                }
                dateChangePosition = 'top';
              } else {
                dateChange = item.date_created;
                dateChangePosition = 'top';
              }
              if (item.type === 1) {
                return <MessageReceived message={item} isLast={index === 0 ? 'last' : ''} guestType={props.guestType} avataarName={getAvataarName(props.guest)}
                  key={`mr_${item._id}`}
                  dateChange={dateChange}
                  dateChangePosition={dateChangePosition}
                  isReadOnly={props.isReadOnly}
                />
                // return renderReceivedMessageRow(item, index === 0 ? 'last' : '');
              }
              if (item.type === 2 || item.sent_email) {
                return <MessageSent
                  key={`ms_${item._id}`}
                  message={item} isLast={index === 0 ? 'last' : ''} guestType={props.guestType}
                  dateChange={dateChange}
                  dateChangePosition={dateChangePosition}
                  onAddAsMessageTemplateClick={onShowSideModal}
                  isReadOnly={props.isReadOnly}
                  locationidentifier={props.locationidentifier}
                />
                // return renderSentMessageRow(item, index === 0 ? 'last' : '');
              }
              return null;
            })
        }
      </div>
      { renderSideModal() }
    </div>
  );
}

export default GuestConversationBox;
