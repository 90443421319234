import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import RouteLeavingGuard from '../../../Misc/RouteLeavingGuard';
import BusinessHours from '../../../Misc/BusinessHours';
import ToggleAndData from '../../../Misc/ToggleAndData';
import GenericSideModal from '../../../Misc/GenericSideModal';
import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { hasAccessToManagerAndAbove, validateEmail, STRS, LANGUAGE_LIST_KEYS } from '../../../../helpers';

import './style.css';

const DEFAULT_AUTO_REPLY = 'Hi this is an automated message, We are currently off business hours for text messaging. You may receive a reply later or during our business hours. Please reach out to front desk or our staff if we can be any help. Thank you.';

const checkInTimes = [];
checkInTimes.push({ label: '12 Noon', value: '12 Noon' });
checkInTimes.push({ label: '12:30 PM', value: '12:30 PM' });
checkInTimes.push({ label: '1:00 PM', value: '1:00 PM' });
checkInTimes.push({ label: '1:30 PM', value: '1:30 PM' });
checkInTimes.push({ label: '2:00 PM', value: '2:00 PM' });
checkInTimes.push({ label: '2:30 PM', value: '2:30 PM' });
checkInTimes.push({ label: '3:00 PM', value: '3:00 PM' });
checkInTimes.push({ label: '3:30 PM', value: '3:30 PM' });
checkInTimes.push({ label: '4:00 PM', value: '4:00 PM' });
checkInTimes.push({ label: '4:30 PM', value: '4:30 PM' });
checkInTimes.push({ label: '5:00 PM', value: '5:00 PM' });
checkInTimes.push({ label: '5:30 PM', value: '5:30 PM' });
checkInTimes.push({ label: '6:00 PM', value: '6:00 PM' });

const checkOutTimes = [];
checkOutTimes.push({ label: '10:00 AM', value: '10:00 AM' });
checkOutTimes.push({ label: '10:30 AM', value: '10:30 AM' });
checkOutTimes.push({ label: '11:00 AM', value: '11:00 AM' });
checkOutTimes.push({ label: '11:30 AM', value: '11:30 AM' });
checkOutTimes.push({ label: '12 Noon', value: '12 Noon' });
checkOutTimes.push({ label: '12:30 PM', value: '12:30 PM' });
checkOutTimes.push({ label: '1:00 PM', value: '1:00 PM' });

const breakfastStartTimes = [];
breakfastStartTimes.push({ label: '6:00 AM', value: '6:00 AM' });
breakfastStartTimes.push({ label: '6:30 AM', value: '6:30 AM' });
breakfastStartTimes.push({ label: '7:00 AM', value: '7:00 AM' });
breakfastStartTimes.push({ label: '7:30 AM', value: '7:30 AM' });
breakfastStartTimes.push({ label: '8:00 AM', value: '8:00 AM' });
breakfastStartTimes.push({ label: '8:30 AM', value: '8:30 AM' });
breakfastStartTimes.push({ label: '9:00 AM', value: '9:00 AM' });

const breakfastEndTimes = [];
breakfastEndTimes.push({ label: '9:00 AM', value: '9:00 AM' });
breakfastEndTimes.push({ label: '9:30 AM', value: '9:30 AM' });
breakfastEndTimes.push({ label: '10:00 AM', value: '10:00 AM' });
breakfastEndTimes.push({ label: '10:30 AM', value: '10:30 AM' });
breakfastEndTimes.push({ label: '11:00 AM', value: '11:00 AM' });
breakfastEndTimes.push({ label: '11:30 AM', value: '11:30 AM' });
breakfastEndTimes.push({ label: '12 Noon', value: '12 Noon' });

const escalationAfterTimes = [];
escalationAfterTimes.push({ label: '3 Mins', value: '3 Mins' });
escalationAfterTimes.push({ label: '5 Mins', value: '5 Mins' });
escalationAfterTimes.push({ label: '10 Mins', value: '10 Mins' });
escalationAfterTimes.push({ label: '15 Mins', value: '15 Mins' });
escalationAfterTimes.push({ label: '20 Mins', value: '20 Mins' });
escalationAfterTimes.push({ label: '30 Mins', value: '30 Mins' });

const displayLangOptions = [];
displayLangOptions.push({ label: 'English', value: 'en' });



function Essentials(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [hideContact, setHideContact] = useState(false);

  const [checkInTimeValue, setCheckInTimeValue] = useState([
    { label: '1:00 PM', value: '1:00 PM' }
  ]);
  const [checkOutTimeValue, setCheckOutTimeValue] = useState([
    { label: '11:00 AM', value: '11:00 AM' }
  ]);

  const [hasBreakfast, setHasBreakfast] = useState(false);
  const [breakfastStartTimeValue, setBreakfastStartTimeValue] = useState([
    { label: '8:00 AM', value: '8:00 AM' }
  ]);
  const [breakfastEndTimeValue, setBreakfastEndTimeValue] = useState([
    { label: '10:00 AM', value: '10:00 AM' }
  ]);
  const [wifiCode, setWifiCode] = useState('');
  const [defaultReviewLink, setDefaultReviewLink] = useState('');
  const [callForwardingNumber, setCallForwardingNumber] = useState('');

  const [businessHours, setBusinessHours] = useState({});
  const [businessHoursChanged, setBusinessHoursChanged] = useState(false);

  const [automatedMsgNonBusinessHours, setAutomatedMsgNonBusinessHours] = useState(false);
  const [automatedMsgNonBusinessHoursText, setAutomatedMsgNonBusinessHoursText] = useState('');
  const [automatedMsgNonBusinessHoursError, setAutomatedMsgNonBusinessHoursError] = useState('');
  const [automatedMsgChanged, setAutomatedMsgChanged] = useState(false);

  const [hasEscalationAlert, setHasEscalationAlert] = useState(false);
  const [escalationAfterValue, setEscalationAfterValue] = useState(
    { label: '3 Mins', value: '3 Mins' }
  );
  const [escalationAfterValueError, setEscalationAfterValueError] = useState('');
  const [escalationEmail, setEscalationEmail] = useState('');
  const [escalationEmailError, setEscalationEmailError] = useState('');

  const [displayLangValue, setDisplayLangValue] = useState(
    { label: 'English', value: 'en' }
  );
  const [displayLangValueError, setDisplayLangValueError] = useState('');

  const [conversationLangOptions, setConversationLangOptions] = useState([]);
  const [conversationLangValue, setConversationLangValue] = useState(
    { label: 'English', value: 'en' }
  );
  const [conversationLangValueError, setConversationLangValueError] = useState('');

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  const [automatedWhatsappMessage, setAutomatedWhatsappMessage] = useState({
    message: '',
    messageId: '',
    sendAfter: '',
  });
  const [selectedAddedWhatsappTemplate, setSelectedAddedWhatsappTemplate] = useState(null);
  const [showAddWhatsappTemplateModal, setShowAddWhatsappTemplateModal] = useState(false);
  const addTemplateModalRef = useRef();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Essentials - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { engage } } = props;
    if (engage) {
      setHideContact(engage.hide_contact || false);
      setDefaultReviewLink(engage.default_review_link || '');
      setCallForwardingNumber(engage.call_forwarding_number);
      setBusinessHours(engage.business_hours || {});
      setAutomatedMsgNonBusinessHours(engage.has_automated_msg_non_business_hours || false);
      setAutomatedMsgNonBusinessHoursText(engage.automated_msg_non_business_hours || '');
      if (engage.automated_msg_non_business_hours_whatsapp_id && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
        const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === engage.automated_msg_non_business_hours_whatsapp_id);
        if (foundMsg) {
          setAutomatedWhatsappMessage(automatedWhatsappMessage => ({
            ...automatedWhatsappMessage,
            message: foundMsg.msg,
            messageId: engage.automated_msg_non_business_hours_whatsapp_id,
          }));
        }
      }
      setHasEscalationAlert(engage.has_escalation_alert || false);
      if (engage.escalation_after) {
        setEscalationAfterValue({ label: engage.escalation_after, value: engage.escalation_after });
      }
      setEscalationEmail(engage.escalation_email || '');
      // lang settings
      let tempOptions = [];
      Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
        const lang = LANGUAGE_LIST_KEYS[item];
        tempOptions.push({ 'label': lang, 'value': item });
      })
      setConversationLangOptions(tempOptions);
      if (userState.user.user_info && userState.user.user_info.profile &&
        userState.user.user_info.profile.locations &&
        userState.user.user_info.profile.locations[urlParams.locationidentifier] &&
        userState.user.user_info.profile.locations[urlParams.locationidentifier].conversation_lang
      ) {
        setConversationLangValue({
          label: LANGUAGE_LIST_KEYS[userState.user.user_info.profile.locations[urlParams.locationidentifier].conversation_lang],
          value: userState.user.user_info.profile.locations[urlParams.locationidentifier].conversation_lang,
        })
      }

      setFormChanged(false);
    }
  };


  useEffect(() => {
    setInitialValues();
  }, [props.settings.engage]);

  useEffect(() => {
    if (props.settings.engage &&
      !props.settings.engage.editInProgress &&
      props.settings.engage.editStatus &&
      props.settings.engage.editStatus.success
    ) {
      setFormChanged(false);
      setUpdatedValues({});
    }
  }, [props.settings.engage]);

  const onHideContactChange = (e) => {
    const { name, checked } = e.target;
    setHideContact(checked);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      hide_contact: checked,
    }));
    setFormChanged(true);
  };

  const onDefaultReviewLinkChange = (e) => {
    const { name, value } = e.target;
    setDefaultReviewLink(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      default_review_link: value,
    }));
    setFormChanged(true);
  };

  const onCallForwardingChange = (e) => {
    const { name, value } = e.target;
    setCallForwardingNumber(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      call_forwarding_number: value,
    }));
    setFormChanged(true);
  };

  const onBusinessHoursChange = (values) => {
    setBusinessHours(values);
    setFormChanged(true);
    setBusinessHoursChanged(true);
  };

  const onAutomatedMsgNonBusinessHoursChange = (value) => {
    setAutomatedMsgNonBusinessHours(value);
    setFormChanged(true);
    setAutomatedMsgChanged(true);
    if (!value) {
      setAutomatedMsgNonBusinessHoursError('');
    } else {
      if (!automatedMsgNonBusinessHoursText) {
        setAutomatedMsgNonBusinessHoursText(DEFAULT_AUTO_REPLY);
        setAutomatedMsgNonBusinessHoursError('');
      } else {
        setAutomatedMsgNonBusinessHoursError(automatedMsgNonBusinessHoursText ? '' : 'error');
      }
    }
  };
  const onAutomatedMsgNonBusinessHoursTextChange = (value) => {
    setAutomatedMsgNonBusinessHoursText(value);
    setFormChanged(true);
    setAutomatedMsgChanged(true);
    if (automatedMsgNonBusinessHours && !value) {
      setAutomatedMsgNonBusinessHoursError('error');
    } else {
      setAutomatedMsgNonBusinessHoursError('');
    }
  };
  const onAutomatedWhatsappMessageIdChange = (messageId) => {
    const { settings: { engage } } = props;
    if (engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        setAutomatedWhatsappMessage(automatedWhatsappMessage => ({
          ...automatedWhatsappMessage,
          messageId,
          message: foundMsg.msg,
        }));
        setFormChanged(true);
        setAutomatedMsgChanged(true);
      }
    }
    // setUpdatedValues(updatedValues => ({
    //   ...updatedValues,
    //   departure_message_id: messageId,
    // }));
    // setFormChanged(true);
  };


  const onAddNewWhatsappTemplateClick = (msgType) => {
    setSelectedAddedWhatsappTemplate(null);
    setShowAddWhatsappTemplateModal(true);
  };
  const onAddWhatsappTemplateModalCloseClick = (pathParam) => {
    setShowAddWhatsappTemplateModal(false);
  };
  const onShowAddedWhatsappTemplateClick = (templateData) => {
    setShowAddWhatsappTemplateModal(true);
    setSelectedAddedWhatsappTemplate(templateData);
  };


  const onHasEscalationAlertChange = (e) => {
    if (!hasAccessToManagerAndAbove(userState.user)) return;
    const { name, checked } = e.target;
    setHasEscalationAlert(checked);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_escalation_alert: checked,
    }));
    setFormChanged(true);
  }
  const onEscalationAfterChange = (value) => {
    setEscalationAfterValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      escalation_after: value.value,
    }));
    setEscalationAfterValueError(false);
    setFormChanged(true);
  };
  const onEscalationEmailChange = (e) => {
    const { name, value } = e.target;
    setEscalationEmail(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      escalation_email: value,
    }));
    setTimeout(() => {
      setEscalationEmailError(!validateEmail(value));
    }, 100);
    setFormChanged(true);
  };

  const onDisplayLangChange = (value) => {
    setDisplayLangValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      display_lang: value.value,
    }));
    setDisplayLangValueError(false);
    // setFormChanged(false); // nkcheck
  };
  const onConversationLangChange = (value) => {
    setConversationLangValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      conversation_lang: value.value,
    }));
    setConversationLangValueError(false);
    setFormChanged(true);
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;
    if (Object.keys(businessHours).length) {
      Object.keys(businessHours).forEach((day) => {
        if (!hasError && businessHours[day] && businessHours[day].isActive) {
          if (!businessHours[day].startTime || businessHours[day].startTime === 'select') {
            hasError = true;
          }
          if (businessHours[day].startTime !== '24 hours' && (!businessHours[day].endTime || businessHours[day].endTime === 'select')) {
            hasError = true;
          }
        }
      });
    }
    if (automatedMsgNonBusinessHours && !automatedMsgNonBusinessHoursText) hasError = true;

    if (hasEscalationAlert) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('performSave escalationAfterValue: ', escalationAfterValue);
      }
      if (escalationAfterValue && escalationAfterValue.value) {
        setEscalationAfterValueError(false);
      } else {
        setEscalationAfterValueError(true);
        hasError = true;
      }
      const emailValidated = validateEmail(escalationEmail);
      setEscalationEmailError(!emailValidated);
      hasError = !emailValidated;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave conversationLangValue: ', conversationLangValue);
    }
    if (conversationLangValue && conversationLangValue.value) {
      setConversationLangValueError(false);
    } else {
      setConversationLangValueError(true);
      hasError = true;
    }

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
    };
    if ('hide_contact' in updatedValues) params['hide_contact'] = updatedValues.hide_contact;
    if ('check_in_time' in updatedValues) params['check_in_time'] = updatedValues.check_in_time;
    if ('check_out_time' in updatedValues) params['check_out_time'] = updatedValues.check_out_time;
    if ('has_breakfast' in updatedValues) params['has_breakfast'] = updatedValues.has_breakfast;
    if ('breakfast_start_time' in updatedValues) params['breakfast_start_time'] = updatedValues.breakfast_start_time;
    if ('breakfast_end_time' in updatedValues) params['breakfast_end_time'] = updatedValues.breakfast_end_time;
    if ('wifi_code' in updatedValues) params['wifi_code'] = updatedValues.wifi_code;
    if ('default_review_link' in updatedValues) params['default_review_link'] = updatedValues.default_review_link;
    if ('call_forwarding_number' in updatedValues) params['call_forwarding_number'] = updatedValues.call_forwarding_number;
    if (businessHoursChanged) params.business_hours = businessHours;
    if (automatedMsgChanged) {
      params.has_automated_msg_non_business_hours = automatedMsgNonBusinessHours;
      params.automated_msg_non_business_hours = automatedMsgNonBusinessHoursText;
      params.automated_msg_non_business_hours_whatsapp_id = automatedWhatsappMessage.messageId;
    }
    if ('has_escalation_alert' in updatedValues) params['has_escalation_alert'] = updatedValues.has_escalation_alert;
    if ('escalation_after' in updatedValues) params['escalation_after'] = updatedValues.escalation_after;
    if ('escalation_email' in updatedValues) params['escalation_email'] = updatedValues.escalation_email;

    if ('conversation_lang' in updatedValues) params['conversation_lang'] = updatedValues.conversation_lang;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };


  const renderHideContactNumber = () => {
    return (
      <div className="gBCtRw hasTgl">
        <div className="lt">
          <p className="title">Hide Contact Number?</p>
          <p className="desc">Use the toggle to display or hide guest contact number for frontdesk user.</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="hideContact" checked={hideContact} onChange={onHideContactChange}/><label htmlFor="hideContact">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDefaultReviewLink = () => {
    return (
      <div className="gBCtRw revLnk">
        <div className="lt">
          <p className="title">Default review link</p>
          <p className="desc">Add your default review link.</p>
          <div className="gIpt">
            <input
              type="text"
              value={defaultReviewLink}
              onChange={onDefaultReviewLinkChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCallForwarding = () => {
    return (
      <div className="gBCtRw revLnk">
        <div className="lt">
          <p className="title">Call forwarding</p>
          <p className="desc">Set the number that you would like calls to be forwarded to if the guests try calling your texting number. Tip: most hotels use their hotel front desk number.</p>
          <div className="gIpt">
            <input
              type="text"
              value={callForwardingNumber}
              onChange={onCallForwardingChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderBusinessHours = () => {
    return (
      <div className="gBCtRw businessHours">
        <div className="lt">
          <p className="title">Set business hours</p>
          <p className="desc">Hours during which staff can reply to incoming messages</p>
          <BusinessHours
            businessHours={businessHours}
            onBusinessHoursChange={onBusinessHoursChange}
          />
        </div>
      </div>
    );
  };

  const renderAutomatedMsgs = () => {
    const { locationInfo } = props;
    return (
      <>
        <ToggleAndData
          title="Automated message during non business hours?"
          subTitle="What message should go to customer when they reach out to you during non-business hours. This will be sent only once to a guest."
          name={"automatedMsgNonBusinessHours"}
          hasTextArea={locationInfo.has_engage_sms}
          textareaTitle="Sms message"
          toggleOn={automatedMsgNonBusinessHours}
          onToggleChange={onAutomatedMsgNonBusinessHoursChange}
          textValue={automatedMsgNonBusinessHoursText}
          onTextChange={onAutomatedMsgNonBusinessHoursTextChange}
          error={automatedMsgNonBusinessHoursError}
        />
        {
          locationInfo.has_engage_whatsapp && automatedMsgNonBusinessHours &&
          <>
            <div className="gBCtRw whtsapMsgTtl">
              <div className="lt">
                <p className="title">WhatsApp message</p>
              </div>
            </div>
            <PreDefinedMessage
              className="automatedNonBusinessHoursMessage"
              type="automatedNonBusinessHoursMessage"
              messageLabel="Automated Non Business Hours Message"
              title="Send a check-out message to guests?"
              description="Set a personalized check-out message to guests."
              dropdownLabel="Select approved WhatsApp template"
              hasMessage={true}
              messageId={automatedWhatsappMessage.messageId}
              message={automatedWhatsappMessage.message}
              sendAfter={null}
              onHasMessageChange={null}
              onMessageIdChange={onAutomatedWhatsappMessageIdChange}
              onSendAfterChange={null}
              locationInfo={locationInfo}
              settings={props.settings}
              dynamicLabel={null}
              onAddNewTemplateClick={onAddNewWhatsappTemplateClick}
              onShowAddedTemplateClick={onShowAddedWhatsappTemplateClick}
            />
          </>
        }
        { showAddWhatsappTemplateModal && renderAddTemplateModal() }
      </>
    );
  };

  const renderAddTemplateModal = () => {
    return (
      <GenericSideModal
        modalType="addWhatsappMessageTemplate"
        className="mtGen"
        data={{
          mode: selectedAddedWhatsappTemplate ? 'view' : '',
          messageType: "automatedNonBusinessHoursMessage",
          template: selectedAddedWhatsappTemplate ? { ...selectedAddedWhatsappTemplate } : {},
        }}
        extData={{
          title: 'Add WhatsApp Message Template',
          settings: props.settings
        }}
        setModalVisible={onAddWhatsappTemplateModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
  };

  const renderHasEscalationAlert = () => {
    return (
      <>
        <div className={'gBCtRw esclAlrt hasTgl' + (hasEscalationAlert ? ' withValue' : '')}>
          <div className="lt">
            <p className="title">Enable escalation alert for pending reply to messages ?</p>
            <p className="desc">One time alert will be sent after specific time to below email in case the reply is not sent to incoming message. Use the toggle to enable / disable escalation alert.</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="hasEscalationAlert" checked={hasEscalationAlert} onChange={onHasEscalationAlertChange}/><label htmlFor="hasEscalationAlert">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        { hasEscalationAlert &&
          <div className="gBCtRw esclAlrtTime">
            <div className="lt">
              <label className="gLbl">Send after</label>
              <Select
                className="esclAlrtSelect"
                options={escalationAfterTimes}
                value={escalationAfterValue}
                onChange={onEscalationAfterChange}
                isDisabled={!hasAccessToManagerAndAbove(userState.user)}
              />
              {
                escalationAfterValueError &&
                <span className="gError">Send after value is required!</span>
              }
            </div>
          </div>
        }
        { hasEscalationAlert &&
          <div className="gBCtRw esclAlrtEmail">
            <div className="lt">
              <label className="gLbl">To email</label>
              <div className="gIpt">
                <input
                  className={'gInput ' + (escalationEmailError ? 'error' : '')}
                  type="text"
                  value={escalationEmail}
                  onChange={onEscalationEmailChange}
                  readOnly={!hasAccessToManagerAndAbove(userState.user)}
                />
              </div>
              {
                escalationEmailError &&
                <span className="gErr">Invalid email!</span>
              }
            </div>
          </div>
        }
      </>
    );
  };

  const renderLanguageSettings = () => {
    return (
      <>
        <div className={'gBCtRw esclAlrt hasTgl' + (hasEscalationAlert ? ' withValue' : '')}>
          <div className="lt">
            <p className="title">Manage language preferences</p>
          </div>
        </div>
        <div className="gBCtRw esclAlrtTime">
          <div className="lt">
            <label className="gLbl">Display Language</label>
            <Select
              className="esclAlrtSelect"
              options={displayLangOptions}
              value={displayLangValue}
              onChange={onDisplayLangChange}
              isDisabled={!hasAccessToManagerAndAbove(userState.user)}
            />
            {
              displayLangValueError &&
              <span className="gError">Display language is required!</span>
            }
          </div>
        </div>
        <div className="gBCtRw esclAlrtTime">
          <div className="lt">
            <label className="gLbl">Conversation Language</label>
            <Select
              className="esclAlrtSelect"
              options={conversationLangOptions}
              value={conversationLangValue}
              onChange={onConversationLangChange}
              isDisabled={!hasAccessToManagerAndAbove(userState.user)}
            />
            {
              conversationLangValueError &&
              <span className="gError">Conversation language is required!</span>
            }
          </div>
        </div>
      </>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('General: ', props, ' # ', props);
  }

  if (!props.locationInfo.has_engage) return null;

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Engage Essentials{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderHideContactNumber() }
          { renderDefaultReviewLink() }
          { renderCallForwarding() }
          { renderBusinessHours() }
          { renderAutomatedMsgs() }
          { renderHasEscalationAlert() }
          { renderLanguageSettings() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.settings.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Essentials;
