import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Tooltip from 'rc-tooltip';
import {
  isBrowser,
  isTablet,
  isMobile
} from "react-device-detect";

import 'rc-tooltip/assets/bootstrap.css';

function ToolTipWrapper(props) {
  if (props.disable) return props.children;
  if (isTablet) return props.children;
  return (
    <Tooltip placement={props.placement} overlayClassName={props.overlayClassName}
      overlay={props.overlay}
    >
      { props.children}
    </Tooltip>
  );
}

export default ToolTipWrapper;
