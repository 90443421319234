import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Home from './Home';
import FeedbackType from './FeedbackType';
import CustomizeEmail from './CustomizeEmail';
import FeedbackPositive from './FeedbackPositive';
import FeedbackPositiveOTAs from './FeedbackPositiveOTAs';
import FeedbackNegative from './FeedbackNegative';
import FeedbackNegativeSingle from './FeedbackNegativeSingle';
import AdditionalQuestions from './AdditionalQuestions';

import './style.css';

function Customize(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route path="type" element={<FeedbackType {...props} />} />
          <Route path="customize-email" element={<CustomizeEmail {...props} />} />
          <Route path="feedback-positive" element={<FeedbackPositive {...props} />} />
          <Route path="positive-questions" element={<AdditionalQuestions type="positive" {...props} />} />
          <Route path="positive-otas" element={<FeedbackPositiveOTAs {...props} />} />
          <Route path="feedback-negative" element={<FeedbackNegative {...props} />} />
          <Route path="negative-single" element={<FeedbackNegativeSingle {...props} />} />
        {/*<Route path="additional-questions" element={<AdditionalQuestions {...props} />} />*/}
          <Route path="negative-questions" element={<AdditionalQuestions type="negative" {...props} />} />
          <Route index element={<Home {...props} />} />
        </Route>
      </Routes>
    </>
  );
}

export default Customize;
