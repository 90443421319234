import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Location from '../Location';
import Reviews from '../Location/Reviews';
import CompAnalysis from '../Location/CompAnalysis';
import Sentiments from '../Location/Sentiments';
import Dashboard from '../Location/Dashboard';
import Feedbacks from '../Location/Feedbacks';
import GuestList from '../Location/GuestList';
import InstantInvite from '../Location/InstantInvite';
import EnableReputation from '../EnableReputation';

import { initSettingsAction, initReputationAction } from '../../../redux/actions/initActions';
import { getDataAction } from '../../../redux/actions/getDataActions';

import { getProductAccess, getReputationAccess, isLocationInUserInfo } from '../../../helpers';

function LocationWrapper(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    dispatch(initReputationAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
    dispatch(initSettingsAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
    dispatch(getDataAction({
      op: 'get_location_settings',
      params: {
        location__id: urlParams.locationidentifier,
        type: 'reputation',
      },
    }));
  }, [urlParams.locationidentifier]);

  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationWrapper urlParams: ', urlParams);
  }

  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  // if (!userState.user.user_info || !userState.user.user_info.locations || !userState.user.user_info.locations[locationidentifier]) return null;
  // const locationInfo = { ...userState.user.user_info.locations[locationidentifier] };

  if (!locationState.locations[locationidentifier]) return null;
  const { reputation } = locationState.locations[locationidentifier];
  if (!reputation) return null;

  const { settings } = locationState.locations[locationidentifier];
  if (!settings) return null;


  // show loader while settings are being retrieved
  if (settings.getInProgress && settings.type_reputation) {
    return (
      <div className="gPnlLdng">
        <div className="inWrp">
          <i className="fa fa-spinner fa-spin" />
        </div>
      </div>
    );
  }

  // for
  if (!settings.info) return null;

  const { hasLocation } = isLocationInUserInfo(userState.user.user_info, locationidentifier);
  if (!hasLocation) return null;
  

  const { hasAccessToReputation } = getProductAccess(userState.user.user_info, locationidentifier);
  // if (!hasAccessToReputation) return null;
  if (!hasAccessToReputation) return <EnableReputation />;

  const { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews } = getReputationAccess(userState.user.user_info, urlParams.locationidentifier);
  const repAccess = { hasAccessToRespondToReviews, hasAccessToRunFeedbackCampaign };

  const { isReadOnly } = props;
  // nkcheck locationInfo={settings.info}
  // check user has access to location

  let accessLevel = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.locations &&
    userState.user.user_info.profile.locations[locationidentifier] &&
    userState.user.user_info.profile.locations[locationidentifier].access_level
    ? userState.user.user_info.profile.locations[locationidentifier].access_level : '';

  // let accessLevel = userState.user.user_info && userState.user.user_info.profile &&
  //   userState.user.user_info.profile.access_level ? userState.user.user_info.profile.access_level : '';
  if (userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level) {
      accessLevel = userState.user.user_info.profile.access_level;
    }

  const hasInstantInvite = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_instant_invite
    : false;
  const hasCompAnalysis = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[urlParams.locationidentifier]
    ? userState.user.user_info.locations[urlParams.locationidentifier].has_comp_analysis
    : false;


  return (
    <div className="gCw">
      <Routes>
        <Route path="*" element={<Location isReadOnly={isReadOnly} />}>
          <Route path="dashboard" element={<Dashboard locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} />} />
          <Route path="reviews" element={<Outlet />}>
            <Route path=":reviewstate/*" element={<Reviews locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} accessLevel={accessLevel} />} />
            <Route index element={<Reviews locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} accessLevel={accessLevel} />} />
          </Route>
          {
            hasCompAnalysis &&
            <Route path="comp-analysis" element={<Outlet />}>
              <Route path=":reviewstate/*" element={<CompAnalysis locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} accessLevel={accessLevel} />} />
              <Route index element={<CompAnalysis locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} accessLevel={accessLevel} />} />
            </Route>
}
          <Route path="sentiments" element={<Sentiments locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} />} />
          <Route path="feedbacks/*" element={<Feedbacks locationInfo={{...settings.info, has_feedback_by_sms_upload: true}} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} />} />
          <Route path="guestlist/*" element={<GuestList locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} />} />
          { hasInstantInvite &&
            <Route path="instant-invite" element={<InstantInvite locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} />} />
          }
          <Route index element={<Dashboard locationInfo={settings.info} reputation={reputation} settings={settings} repAccess={repAccess} isReadOnly={isReadOnly} />} />
        </Route>
        {/*<Route>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
        </Route>*/}
      </Routes>
    </div>
  );
}

export default LocationWrapper;
