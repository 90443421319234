import { useSelector, useDispatch } from "react-redux";
// import { changeUsersName } from "./redux/actions/changeUsersName";
import { changeUsersAge } from "./redux/actions/changeUsersAge";

import { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import { ErrorBoundary } from 'react-error-boundary'

import routes from './routes';
import { sendEmailAlert } from './helpers';

import "./App.css";

const gtconfig = require('./gtconfig');

const TRACKING_ID = "G-VQMKH3VBCL";

function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div className="errorBoundary" role="alert">
      <p>Oops! Something went wrong.</p>
      <p>We are on it.</p>
      <p>Please refresh the page and try again or give it a try after some time.</p>
    </div>
  )
}



function App() {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   ReactGA.initialize(TRACKING_ID, { debug: true });
  // }, []);

  function logErrorToService(error, info) {
    // Use your preferred error logging service
    if (process.env.NODE_ENV !== 'production') {
      console.error("Caught an error:", error);
      console.error("Caught an error string:", error.stack);
      console.error("Caught an info:", info);
      console.error("Caught an info string:", info.componentStack);
    }
    let uid = '';
    if (userState.user && userState.user.user_info && userState.user.user_info.uid) uid = userState.user.user_info.uid;
    sendEmailAlert({
      app: gtconfig.APP_NAME,
      subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - ErrorBoundary - ${uid}`,
      message: JSON.stringify({ error: error.stack, info: info.componentStack }),
      // error: JSON.stringify(response.data),
      error: error.stack,
      // store: JSON.stringify(getState()),
    });
  }


  return (
    <ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onError={logErrorToService}
      onReset={() => {
        // reset the state of your app here
      }}
      resetKeys={['someKey']}
    >
      <>
          { routes }
      </>
    </ErrorBoundary>
  );
}

export default App;
