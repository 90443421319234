import { genericItemTypeToKeyName } from '../../helpers';

/***************************************** Get Digital Guide Settings ****************************/

export const getDigitalGuideSettings = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          digital_guide: state.locations[location__id].settings.digital_guide
          ?
            {
              ...state.locations[location__id].settings.digital_guide,
              getInProgress: true, getStatus: {},
            }
          : {
              getInProgress: true, getStatus: {},
            }
        },
      },
    },
  };
};

export const getDigitalGuideSettingsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { digital_guide_settings, hotel_information, rooms } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          digital_guide: {
            ...state.locations[location__id].settings.digital_guide,
            ...digital_guide_settings,
            pages: hotel_information,
            rooms,
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getDigitalGuideSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          digital_guide: {
            ...state.locations[location__id].settings.digital_guide,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};



/* ********************** Start - edit webchat settings ******************* */

export const editWebChatSettingsClear = (state, action) => {
  const { params: { location__id, op, channel_id } } = action.payload;
  if (!location__id || !op) return state;
  if (op === 'edit-channel') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              channels: state.locations[location__id].settings.webchat.channels
              ? {
                ...state.locations[location__id].settings.webchat.channels,
                [channel_id]: state.locations[location__id].settings.webchat.channels[channel_id]
                  ? {
                    ...state.locations[location__id].settings.webchat.channels[channel_id],
                    editInProgress: false,
                    editStatus: {},
                  }
                  : {
                    editInProgress: false,
                    editStatus: {},
                  },
              }
              : {
                [channel_id]: {
                  editInProgress: false,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-general') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              general: state.locations[location__id].settings.webchat.general
                ? {
                  ...state.locations[location__id].settings.webchat.general,
                  editInProgress: false,
                  editStatus: {},
                }
                : {
                  editInProgress: false,
                  editStatus: {},
                },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-display') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              display: state.locations[location__id].settings.webchat.display
                ? {
                  ...state.locations[location__id].settings.webchat.display,
                  editInProgress: false,
                  editStatus: {},
                }
                : {
                  editInProgress: false,
                  editStatus: {},
                },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-deploy') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              deploy: state.locations[location__id].settings.webchat.deploy
                ? {
                  ...state.locations[location__id].settings.webchat.deploy,
                  editInProgress: false,
                  editStatus: {},
                }
                : {
                  editInProgress: false,
                  editStatus: {},
                },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editWebChatSettings = (state, action) => {
  const { params: { location__id, op, channel_id } } = action.payload;
  if (!location__id || !op) return state;
  if (op === 'edit-channel') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              channels: {
                ...state.locations[location__id].settings.webchat.channels,
                [channel_id]: {
                  ...state.locations[location__id].settings.webchat.channels[channel_id],
                  editInProgress: true,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-general') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              general: {
                ...state.locations[location__id].settings.webchat.general,
                editInProgress: true,
                editStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-display') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              display: {
                ...state.locations[location__id].settings.webchat.display,
                editInProgress: true,
                editStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-deploy') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              deploy: {
                ...state.locations[location__id].settings.webchat.deploy,
                editInProgress: true,
                editStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editWebChatSettingsSuccess = (state, action) => {
  const { params: { location__id, op, channel_id, channel_data, general, display, deploy } } = action.payload;
  if (!location__id || !op) return state;
  const { data } = action.payload;
  if (op === 'edit-channel') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              channels: {
                ...state.locations[location__id].settings.webchat.channels,
                [channel_id]: {
                  ...state.locations[location__id].settings.webchat.channels[channel_id],
                  ...channel_data,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-general') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              general: {
                ...state.locations[location__id].settings.webchat.general,
                ...general,
                editInProgress: false,
                editStatus: {
                  success: true,
                },
              },
              is_help_guide_enabled: 'is_help_guide_enabled' in general
                ? general.is_help_guide_enabled
                : state.locations[location__id].settings.webchat.is_help_guide_enabled,
            },
          },
        },
      },
    };
  }
  if (op === 'edit-display') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              display: {
                ...state.locations[location__id].settings.webchat.display,
                ...display,
                editInProgress: false,
                editStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-deploy') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              deploy: {
                ...state.locations[location__id].settings.webchat.deploy,
                ...deploy,
                editInProgress: false,
                editStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editWebChatSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, op, channel_id, display, general } = params;
  if (!location__id || !op) return state;
  if (op === 'edit-channel' && channel_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              channels: {
                ...state.locations[location__id].settings.webchat.channels,
                [channel_id]: {
                  ...state.locations[location__id].settings.webchat.channels[channel_id],
                  editInProgress: false,
                  editStatus: {
                    error: true,
                    message: 'Error in updating!'
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-general') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              general: {
                ...state.locations[location__id].settings.webchat.general,
                editInProgress: false,
                editStatus: {
                  error: true,
                  message: 'Error in updating!',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-display') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              display: {
                ...state.locations[location__id].settings.webchat.display,
                editInProgress: false,
                editStatus: {
                  error: true,
                  message: 'Error in updating!',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  if (op === 'edit-deploy') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              deploy: {
                ...state.locations[location__id].settings.webchat.deploy,
                editInProgress: false,
                editStatus: {
                  error: true,
                  message: 'Error in updating!',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/* ********************** End - edit webchat settings ******************* */


/***************************************** Get Webchat Help Guide ****************************/

export const getWebChatHelpGuide = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          webchat: state.locations[location__id].settings.webchat
          ?
            {
              ...state.locations[location__id].settings.webchat,
              help_guide: state.locations[location__id].settings.webchat.help_guide
              ?
                {
                  ...state.locations[location__id].settings.webchat.help_guide,
                  getInProgress: true, getStatus: {},
                }
              : {
                  getInProgress: true, getStatus: {},
                }  ,
            }
          : {
              help_guide: {
                getInProgress: true, getStatus: {},
              },
            },
        },
      },
    },
  };
};

export const getWebChatHelpGuideSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { webchat_topics, webchat_questions, webchat_all_topics } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          webchat: {
            ...state.locations[location__id].settings.webchat,
            help_guide: {
              topics: webchat_topics || [],
              all_topics: webchat_all_topics || [],
              questions: {
                ...webchat_questions,
              },
              getInProgress: false, getStatus: {
                success: true,
              },
            },
          },
        },
      },
    },
  };
};

export const getWebChatHelpGuideError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          webchat: {
            ...state.locations[location__id].settings.webchat,
            help_guide: {
              ...state.locations[location__id].settings.webchat.help_guide,
              error,
              errorMessage,
              getInProgress: false, getStatus: {
                error: true,
              },
            }
          },
        },
      },
    },
  };
};


/* ********************** Start - edit webchat help guide ******************* */

export const editWebChatHelpGuideClear = (state, action) => {
  const { params: { location__id, op, question_id, topic_id } } = action.payload;
  if (!location__id || !op) return state;
  if (op === 'edit-question') {
    if (!question_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  keyValue: {
                    ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue,
                    [question_id]: {
                      ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue[question_id],
                      editInProgress: false,
                      editStatus: {},
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-question') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  addInProgress: false,
                  addStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'reorder-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topicsReorderInProgress: false,
                topicsReorderStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topicsManageInProgress: false,
                topicsManageStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'toggle-topic') {
    if (!topic_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topics: state.locations[location__id].settings.webchat.help_guide.topics.map((item) => {
                  if (item.topic_id === topic_id) {
                    item.editInProgress = false;
                    item.editStatus = {};
                  }
                  return item;
                }),
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editWebChatHelpGuide = (state, action) => {
  const { params: { location__id, op, question_id, topic_id } } = action.payload;
  if (!location__id || !op) return state;
  if (op === 'edit-question') {
    if (!question_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  keyValue: {
                    ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue,
                    [question_id]: {
                      ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue[question_id],
                      editInProgress: true,
                      editStatus: {},
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-question') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  addInProgress: true,
                  addStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'reorder-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topicsReorderInProgress: true,
                topicsReorderStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topicsManageInProgress: true,
                topicsManageStatus: {},
              },
            },
          },
        },
      },
    };
  }
  if (op === 'toggle-topic') {
    if (!topic_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topics: state.locations[location__id].settings.webchat.help_guide.topics.map((item) => {
                  if (item.topic_id === topic_id) {
                    item.editInProgress = true;
                    item.editStatus = {};
                  }
                  return item;
                }),
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editWebChatHelpGuideSuccess = (state, action) => {
  const { params: { location__id, op, question_id, question_data, topics, topic_id, toggleValue } } = action.payload;
  if (!location__id || !op) return state;
  const { data } = action.payload;
  if (op === 'edit-question') {
    if (!question_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  keyValue: {
                    ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue,
                    [question_id]: {
                      ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue[question_id],
                      ...question_data,
                      editInProgress: false,
                      editStatus: {
                        success: true,
                      },
                    },
                  },
                },
                topics: state.locations[location__id].settings.webchat.help_guide.topics.map((item) => {
                  if (question_data.is_removed && item.topic_id === question_data.topic_id) {
                    if (item.questions_count) {
                      item.questions_count--;
                    } else {
                      item.questions_count = 0;
                    }
                  }
                  return item;
                }),
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-question') {
    if (!data.new_question || !data.new_question._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  list: state.locations[location__id].settings.webchat.help_guide.questions.list
                    ? [ data.new_question._id, ...state.locations[location__id].settings.webchat.help_guide.questions.list]
                    : [ data.new_question._id ],
                  keyValue: {
                    ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue,
                    [data.new_question._id]: {
                      ...data.new_question,
                    },
                  },
                  addInProgress: false,
                  addStatus: {
                    success: true,
                  },
                },
                topics: state.locations[location__id].settings.webchat.help_guide.topics.map((item) => {
                  if (item.topic_id === data.new_question.topic_id) {
                    if (!item.questions_count) item.questions_count = 0;
                    item.questions_count++;
                  }
                  return item;
                }),
              },
            },
          },
        },
      },
    };
  }
  if (op === 'reorder-topics') {
    if (!data.webchat_topics) return state;
    const { webchat_topics } = data;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topics: webchat_topics,
                topicsReorderInProgress: false,
                topicsReorderStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    if (!data.webchat_topics) return state;
    const { webchat_topics } = data;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topics: webchat_topics,
                topicsManageInProgress: false,
                topicsManageStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'toggle-topic') {
    if (!topic_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topics: state.locations[location__id].settings.webchat.help_guide.topics.map((item) => {
                  if (item.topic_id === topic_id) {
                    item.is_hidden = toggleValue;
                    item.editInProgress = false;
                    item.editStatus = {
                      success: true,
                    };
                  }
                  return item;
                }),
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editWebChatHelpGuideError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, op, question_id, topic_id } = params;
  if (!location__id || !op) return state;
  if (op === 'edit-question' && question_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  keyValue: {
                    ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue,
                    [question_id]: {
                      ...state.locations[location__id].settings.webchat.help_guide.questions.keyValue[question_id],
                      editInProgress: false,
                      editStatus: {
                        error: true,
                      },
                      error,
                      errorMessage,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'add-question') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                questions: {
                  ...state.locations[location__id].settings.webchat.help_guide.questions,
                  addInProgress: false,
                  addStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (op === 'reorder-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topicsReorderInProgress: false,
                topicsReorderStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  if (op === 'manage-topics') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topicsManageInProgress: false,
                topicsManageStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  if (op === 'toggle-topic') {
    if (!topic_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            webchat: {
              ...state.locations[location__id].settings.webchat,
              help_guide: {
                ...state.locations[location__id].settings.webchat.help_guide,
                topics: state.locations[location__id].settings.webchat.help_guide.topics.map((item) => {
                  if (item.topic_id === topic_id) {
                    item.editInProgress = false;
                    item.editStatus = {
                      error: true,
                    };
                    item.error = error;
                    item.errorMessage = errorMessage;
                  }
                  return item;
                }),
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/* ********************** End - edit webchat help guide ******************* */


/***************** Generic Engage List Item - Digital Guide **********************/

export const editGenericDigitalGuideListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, page_id, room_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // dg_page
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'dg_page') {
      itemId = page_id;
    }
    if (itemType === 'dg_room') {
      itemId = room_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.digital_guide[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.digital_guide[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericDigitalGuideListItem = (state, action, itemType) => {
  const { params: { location__id, mode, page_id, room_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'dg_page') {
      itemId = page_id;
    }
    if (itemType === 'dg_room') {
      itemId = room_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericDigitalGuideListItem itemId: `, itemId);
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.digital_guide[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.digital_guide[keyName].keyValue[itemId],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericDigitalGuideListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, page_id, room_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { new_page, page_data, room_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'dg_page') {
      itemId = page_id;
      return_data = page_data;
    }
    if (itemType === 'dg_room') {
      itemId = room_id;
      return_data = room_data;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].settings.digital_guide[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].settings.digital_guide[keyName].list ],
                keyValue: {
                  ...state.locations[location__id].settings.digital_guide[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.digital_guide[keyName].keyValue[itemId],
                    ...return_data,
                    is_removed: mode === 'remove' ? true : undefined,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    let return_data = null;
    if (itemType === 'dg_page') {
      return_data = new_page;
    }
    if (itemType === 'dg_room') {
      return_data = room_data;
    }
    if (!return_data || !return_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                list: state.locations[location__id].settings.digital_guide[keyName].list
                ? [return_data._id, ...state.locations[location__id].settings.digital_guide[keyName].list]
                : [return_data._id],
                keyValue: state.locations[location__id].settings.digital_guide[keyName].keyValue
                ?
                  {
                    ...state.locations[location__id].settings.digital_guide[keyName].keyValue,
                    [return_data._id]: {
                      ...return_data,
                    },
                  }
                : {
                  [return_data._id]: {
                    ...return_data,
                  },
                },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericDigitalGuideListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, page_id, room_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'dg_page') {
      itemId = page_id;
    }
    if (itemType === 'dg_room') {
      itemId = room_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.digital_guide[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.digital_guide[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            digital_guide: {
              ...state.locations[location__id].settings.digital_guide,
              [keyName]: {
                ...state.locations[location__id].settings.digital_guide[keyName],
                addInProgress: false,
                addStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic List Item - Digital Guide *******************************/


/***************************************** Get Webchat Help Guide ****************************/

export const getWebChatRules = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          webchat: state.locations[location__id].settings.webchat
          ?
            {
              ...state.locations[location__id].settings.webchat,
              rules: state.locations[location__id].settings.webchat.rules
              ?
                {
                  ...state.locations[location__id].settings.webchat.rules,
                  getInProgress: true, getStatus: {},
                }
              : {
                  getInProgress: true, getStatus: {},
                }  ,
            }
          : {
              rules: {
                getInProgress: true, getStatus: {},
              },
            },
        },
      },
    },
  };
};

export const getWebChatRulesSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { webchat_rules } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          webchat: {
            ...state.locations[location__id].settings.webchat,
            rules: {
              ...webchat_rules,
              getInProgress: false, getStatus: {
                success: true,
              },
            },
          },
        },
      },
    },
  };
};

export const getWebChatRulesError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          webchat: {
            ...state.locations[location__id].settings.webchat,
            rules: {
              ...state.locations[location__id].settings.webchat.rules,
              error,
              errorMessage,
              getInProgress: false, getStatus: {
                error: true,
              },
            }
          },
        },
      },
    },
  };
};
