// @flow

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';

import ToolTipWrapper from '../ToolTipWrapper';

import { ENGAGE_FEATURES, hasAccessToFeature } from '../../../helpers';

import './style.css';

import deleteIcon from '../../../img/icons/delete-box.svg';


function GBMChip(props) {

  const [isActive, setIsActive] = useState(false);
  const [title, setTitle] = useState('');
  const [shortTitle, setShortTitle] = useState('');
  const [hasMessage, setHasMessage] = useState(false);
  const [message, setMessage] = useState('');

  const [titleError, setTitleError] = useState('');
  const [shortTitleError, setShortTitleError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    setIsActive(props.data.isActive || false);
    setTitle(props.data.title || '');
    setShortTitle(props.data.shortTitle || '');
    setHasMessage(props.data.hasMessage || false);
    setMessage(props.data.message || '');
    if (props.data.title) {
      if (!props.data.shortTitle) setShortTitleError('Shortened question is required.')
    }
  }, [props.data]);

  const onActiveChange = (e) => {
    if (!isActive) {
      setTitleError(title ? '' : 'Question is required.');
    } else { setTitleError(''); }
    props.onActiveChange(props.id, !isActive);
    setIsActive(isActive => !isActive);
  };
  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value.slice(0,35));
    props.onTitleChange(props.id, value.slice(0,35));
    if (isActive) {
      setTitleError(value ? '' : 'Question is required.');
    }
  };
  const onShortTitleChange = (e) => {
    const { name, value } = e.target;
    setShortTitle(value.slice(0,25));
    props.onShortTitleChange(props.id, value.slice(0,25));
    if (isActive) {
      setShortTitleError(value ? '' : 'Shortened question is required.');
    }
  };

  const onHasMessageChange = (e) => {
    if (!hasMessage) {
      setMessageError(message ? '' : 'Reply is required.');
    } else { setMessageError(''); }
    props.onHasMessageChange(props.id, !hasMessage);
    setHasMessage(hasMessage => !hasMessage);
  };
  const onMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    props.onMessageChange(props.id, value);
    if (hasMessage) {
      setMessageError(value ? '' : 'Reply is required.');
    }
  };
  const onRemoveClick = (e) => {
    props.onRemoveClick(props.id);
  };

  const renderContent = () => {
    return (
      <div className={'gbmChip ' + (isActive ? '' : 'disabled')}>
        <div className="dataRow titleRow">
          <div className="top">
            <p>Suggested Question (Max 35 characters)
              <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
                overlay="Suggested questions are sent to guests interacting with Agent after welcome message to kick start the conversation."
              >
                <i className="fas fa-info-circle" />
              </ToolTipWrapper>
            </p>
          </div>
          <div className="bottom">
            <input type="checkbox" className="checkbox" name="isActive" onChange={onActiveChange} value={isActive ? 'on' : 'off'} checked={isActive} />
            <div className="gIpt">
              <input type="text" className="input" onChange={onTitleChange} value={title} placeholder="Question" />
            </div>
          </div>
          {
            titleError &&
            <div className="error">
              {titleError}
            </div>
          }
        </div>
        {
          isActive &&
          <div className="dataRow shortTitleRow">
            <div className="top">
              <p>Shortened Question (Max 25 characters)
                <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
                  overlay="This shortened question will be shown to guests if they want to see suggested replies again."
                >
                  <i className="fas fa-info-circle" />
                </ToolTipWrapper>
              </p>
            </div>
            <div className="bottom">
              <div className="gIpt">
                <input type="text" className="input" onChange={onShortTitleChange} value={shortTitle} placeholder="Shortened question" />
              </div>
            </div>
            {
              shortTitleError &&
              <div className="error">
                {shortTitleError}
              </div>
            }
          </div>
        }
        {
          isActive &&
          <div className="dataRow messageRow">
            <div className="top">
              <p>Auto Reply
                <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
                  overlay="This reply will be sent back to guest upon tapping on the above question."
                >
                  <i className="fas fa-info-circle" />
                </ToolTipWrapper>
              </p>
            </div>
            <div className="bottom">
              <input type="checkbox" className="checkbox" name="hasMessage" onChange={onHasMessageChange} value={hasMessage ? 'on' : 'off'} checked={hasMessage} />
              <div className="gIpt">
                <input type="text" className="input" onChange={onMessageChange} value={message} placeholder="Reply" />
              </div>
            </div>
            {
              messageError &&
              <div className="error">
                {messageError}
              </div>
            }
          </div>
        }
        <div className="dataRow removeRow">
          <div className="gButton" onClick={onRemoveClick}>Remove <img src={deleteIcon} /></div>
        </div>
      </div>
    );
  }

  return renderContent();

}

export default GBMChip;
