import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import RouteLeavingGuard from '../../../Misc/RouteLeavingGuard';
import BusinessHours from '../../../Misc/BusinessHours';
import ToggleAndData from '../../../Misc/ToggleAndData';
import GenericSideModal from '../../../Misc/GenericSideModal';
import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { validateEmail, STRS } from '../../../../helpers';

import './style.css';


function Alerts(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [feedbackAlert, setFeedbackAlert] = useState(false);

  const [emailAlert, setEmailAlert] = useState(true);
  const [whatsappAlert, setWhatsappAlert] = useState(false);
  const [feedbackAlertMediumError, setFeedbackAlertMediumError] = useState(false);

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Alerts - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { locationProfile } = props;
    if (locationProfile.reputation) {
      const { alerts } = locationProfile.reputation;
      if (alerts) {
        const { guest_feedback_alert } = alerts;
        if (guest_feedback_alert) {
          setFeedbackAlert(guest_feedback_alert.is_enabled || false);
          setEmailAlert(guest_feedback_alert.medium && guest_feedback_alert.medium.email || false);
          setWhatsappAlert(guest_feedback_alert.medium && guest_feedback_alert.medium.whatsapp || false);
        }
      }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  // useEffect(() => {
  //   if (props.settings.reputation &&
  //     !props.settings.reputation.editInProgress &&
  //     props.settings.reputation.editStatus &&
  //     props.settings.reputation.editStatus.success
  //   ) {
  //     setFormChanged(false);
  //   }
  // }, [props.settings.reputation]);

  const onFeedbackAlertChange = (e) => {
    const { name, checked } = e.target;
    setFeedbackAlert(checked);
    setFormChanged(true);
  };

  const onEmailCheckBoxChange = (e) => {
    if (emailAlert) {
      if (!whatsappAlert) return; // both cannot be false
    }
    setEmailAlert(emailAlert => !emailAlert);
    setFeedbackAlertMediumError('');
    setFormChanged(true);
  };

  const onWhatsappCheckBoxChange = (e) => {
    if (!userState.user.user_info.contact) return;
    if (whatsappAlert) {
      if (!emailAlert) return; // both cannot be false
    }
    setWhatsappAlert(whatsappAlert => !whatsappAlert);
    setFeedbackAlertMediumError('');
    setFormChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    if (feedbackAlert && (!emailAlert && !whatsappAlert)) {
      setFeedbackAlertMediumError('error');
      return;
    }
    setFeedbackAlertMediumError('');

    if (hasError) return;

    const { locationProfile } = props;
    if (locationProfile && locationProfile.reputation && locationProfile.reputation.alerts &&
      locationProfile.reputation.alerts.guest_feedback_alert &&
      locationProfile.reputation.alerts.guest_feedback_alert.editInProgress
    ) {
      return;
    }

    const params = {
      location__id: urlParams.locationidentifier,
    };
    // params['invoked_from'] = 'alerts';
    // params['profile_location__id'] = urlParams.locationidentifier;
    params['guest_feedback_alert'] = {};
    params['guest_feedback_alert']['is_enabled'] = feedbackAlert;
    params['guest_feedback_alert']['medium'] = {};
    if (emailAlert) params['guest_feedback_alert']['medium']['email'] = true;
    if (whatsappAlert) params['guest_feedback_alert']['medium']['whatsapp'] = true;


    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_reputation_alerts',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };


  const renderFeedbackAlertBox = () => {
    return (
      <>
        <div className="gBCtRw hasTgl">
          <div className="lt">
            <p className="title">Direct Guest Feedback Alert</p>
            <p className="desc">Feedback Alert</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="feedbackAlert" checked={feedbackAlert} onChange={onFeedbackAlertChange}/><label htmlFor="feedbackAlert">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        <div className={'gBCtRw alrtOpts' + (!feedbackAlert ? ' inactive' : '')}>
          <div className="lt">
            <p className="title">
              How would you like to receive alerts ?
              {
                feedbackAlert ? ' (Atleast one is required.)' : ''
              }
            </p>
            <div className="alertOptions">
              <div className={'entry ' + (emailAlert ? 'active' : '')}>
                <input type="checkbox" name="emailAlert" onChange={onEmailCheckBoxChange} value={emailAlert ? 'on' : 'off'} checked={emailAlert} />
                <div className="label">
                  <span className="name" onClick={onEmailCheckBoxChange}>Email Alert</span><span>( On {userState.user.user_info.uid} )</span>
                </div>
              </div>
              <div className={'entry ' + (whatsappAlert ? 'active ' : '')}>
                <input type="checkbox" name="whatsappAlert" onChange={onWhatsappCheckBoxChange} value={whatsappAlert ? 'on' : 'off'} checked={whatsappAlert} />
                <div className="label">
                  <span className="name" onClick={onWhatsappCheckBoxChange}>WhatsApp Alert</span>
                  {
                    userState.user.user_info.contact
                    ? <span>( On {userState.user.user_info.contact} )</span>
                    : <span>( Cannot be enabled, updated your contact in your <Link to={'/profile/info'}>profile</Link>. )</span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Alerts: ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen setRepAlrt">
        <div className="gPTle title">
          Alerts{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderFeedbackAlertBox() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.locationProfile && props.locationProfile.reputation &&
                  props.locationProfile.reputation.alerts &&
                  props.locationProfile.reputation.alerts.guest_feedback_alert &&
                  props.locationProfile.reputation.alerts.guest_feedback_alert.editInProgress
                  && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Alerts;
