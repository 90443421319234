import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import '../style.css';

function RateCodeSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState('');

  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const labelRef = useRef(null);


  useEffect(() => {
    if (props.rate_code && props.rate_code._id) {
      setLabel(props.rate_code.label);
      setValue(props.rate_code.value);
    }
    setSomeChange(false);
    dispatch(editDataClearAction({
      op: 'edit_rate_code',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        rate_code_id: props.rate_code._id,
      },
    }));
    setTimeout(() => {
      if (labelRef && labelRef.current) {
        labelRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.rate_code && props.rate_code._id &&
        props.settings.rate_codes && props.settings.rate_codes.keyValue &&
        props.settings.rate_codes.keyValue[props.rate_code._id] &&
        !props.settings.rate_codes.keyValue[props.rate_code._id].editInProgress &&
        props.settings.rate_codes.keyValue[props.rate_code._id].editStatus &&
        props.settings.rate_codes.keyValue[props.rate_code._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.settings.rate_codes &&
        !props.settings.rate_codes.addInProgress &&
        props.settings.rate_codes.addStatus &&
        props.settings.rate_codes.addStatus.success
      ) {
        setLabel('');
        setValue('');
        setSomeChange(false);
      }
    }
  }, [props.settings.rate_codes]);

  const onLabelChange = (e) => {
    const { name, value } = e.target;
    dispatch(editDataClearAction({
      op: 'edit_rate_code',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        rate_code_id: props.rate_code._id,
      },
    }));
    setLabel(value);
    if (!value) {
      setLabelError('error');
    } else {
      setLabelError('');
    }
    setSomeChange(true);
  };
  const onValueChange = (e) => {
    setValue(e.target.value);
    setValueError(e.target.value ? '' : 'error');
    setSomeChange(true);
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    let addEditInProgress = props.settings.rate_codes.addInProgress
    if (props.type === 'edit') {
      if (props.rate_code && props.rate_code._id &&
        props.settings.rate_codes && props.settings.rate_codes.keyValue &&
        props.settings.rate_codes.keyValue[props.rate_code._id]
      ) {
        addEditInProgress = props.settings.rate_codes.keyValue[props.rate_code._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    dispatch(editDataClearAction({
      op: 'edit_rate_code',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        rate_code_id: props.rate_code._id,
      },
    }));
    setLabelError(label ? '' : 'error');
    setValueError(value ? '' : 'Value is required');
    if (someChange) {
      if (props.type === 'add') {
        if (label && value) {
          dispatch(editDataAction({
            op: 'edit_rate_code',
            params: {
              location__id: urlParams.locationidentifier,
              mode: props.type,
              label,
              value,
            },
          }));
        }
      }
      if (props.type === 'edit') {
        if (label && value) {
          dispatch(editDataAction({
            op: 'edit_rate_code',
            params: {
              location__id: urlParams.locationidentifier,
              mode: props.type,
              rate_code_id: props.rate_code._id,
              label,
              value,
            },
          }));
        }
      }
    }
  };

  const renderContent = () => {
    const { locationidentifier } = urlParams;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings ||
      !settings.rate_codes || !settings.rate_codes.keyValue
    ) return null;
    const tData = settings.rate_codes.keyValue[props.rate_code._id] || {};

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Rate Code`;
    if (props.type === 'edit') pageTitle = 'Edit Rate Code';
    if (props.title) pageTitle = props.title;



    let addEditInProgress = settings.rate_codes.addInProgress
    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    let addEditStatus = settings.rate_codes.addStatus;
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    return (
      <div className="gPWrp rateCodeSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Label
              </div>
              <div className={'gIpt ' + (labelError ? 'error' : '')}>
                <input type="text" value={label} onChange={onLabelChange} placeholder="Title" ref={labelRef} />
                { labelError && <p className="gErr">Label is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw attrRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Value
              </div>
              <div className={'gIpt ' + (valueError ? 'error' : '')}>
                <input type="text" value={value} onChange={onValueChange} placeholder="Value" />
                { valueError && <p className="gErr">Value is required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw helpText">
            <p>Value will be used to match the rate code recived from PMS. Case-insensitive match will be used.</p>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RateCodeSingle props: ', props);
  }

  return renderContent();
}

export default RateCodeSingle;
