import { genericItemTypeToKeyName } from '../../helpers';

/***************************************** Get Broadcasts ****************************/

export const getBroadcasts = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            getListInProgress: true, getListStatus: {},
          },
        },
      },
    },
  };
};

export const getBroadcastsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { broadcasts } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            ...broadcasts,
            getListInProgress: false, getListStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getBroadcastsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            getListInProgress: false, getListStatus: {
              error: true,
            },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};


/***************** Edit Broadcast **********************/

export const editBroadcastClear = (state, action, itemType) => {
  const { params: { location__id, mode, broadcast_id } } = action.payload;
  if (!location__id) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!broadcast_id) return state;
    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: {} }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: {} }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              keyValue: {
                ...state.locations[location__id].engage.broadcast.keyValue,
                [broadcast_id]: {
                  ...state.locations[location__id].engage.broadcast.keyValue[broadcast_id],
                  ...newStatus,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              ...newStatus,
            },
          },
        },
      },
    };
  }
  return state;
};

export const editBroadcast = (state, action, itemType) => {
  const { params: { location__id, mode, broadcast_id } } = action.payload;
  if (!location__id) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!broadcast_id) return state;
    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: true, editStatus: {} }; }
    if (mode === 'remove') { newStatus = { removeInProgress: true, removeStatus: {} }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              keyValue: {
                ...state.locations[location__id].engage.broadcast.keyValue,
                [broadcast_id]: {
                  ...state.locations[location__id].engage.broadcast.keyValue[broadcast_id],
                  ...newStatus,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: true, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: true, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              ...newStatus,
            },
          },
        },
      },
    };
  }
  return state;
};

export const editBroadcastSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, broadcast_id } } = action.payload;
  if (!location__id) return state;
  const { broadcast_data } = data;

  if (mode === 'edit' || mode === 'remove') {
    if (!broadcast_id || !broadcast_data || !broadcast_data._id) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: { success : true } }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: { success: true } }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              keyValue: {
                ...state.locations[location__id].engage.broadcast.keyValue,
                [broadcast_id]: {
                  ...state.locations[location__id].engage.broadcast.keyValue[broadcast_id],
                  ...broadcast_data,
                  ...newStatus,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: { success: true } }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: { success: true } }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              list: [broadcast_data._id, ...state.locations[location__id].engage.broadcast.list],
              keyValue: {
                ...state.locations[location__id].engage.broadcast.keyValue,
                [broadcast_data._id]: {
                  ...broadcast_data,
                },
              },
              ...newStatus,
            },
          },
        },
      },
    };
  }
  return state;
};

export const editBroadcastError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;

  const { location__id, mode, broadcast_id } = params;
  if (!location__id) return state;

  if (mode === 'edit' || mode === 'remove') {
    if (!broadcast_id) return state;
    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: { error: true } }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: { error: true } }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              keyValue: {
                ...state.locations[location__id].engage.broadcast.keyValue,
                [broadcast_id]: {
                  ...state.locations[location__id].engage.broadcast.keyValue[broadcast_id],
                  ...newStatus,
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            broadcast: {
              ...state.locations[location__id].engage.broadcast,
              ...newStatus,
              error,
              errorMessage,
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Edit Broadcast *******************************/


/***************************************** Get Broadcasts History ****************************/

export const getBroadcastsHistory = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            history: {
              ...state.locations[location__id].engage.broadcast.history,
              getListInProgress: true, getListStatus: {},
            },
          },
        },
      },
    },
  };
};

export const getBroadcastsHistorySuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { history } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            history: {
              ...state.locations[location__id].engage.broadcast.history,
              ...history,
              getListInProgress: false, getListStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const getBroadcastsHistoryError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            history: {
              ...state.locations[location__id].engage.broadcast.history,
              getListInProgress: false, getListStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};



/***************************************** Get Broadcast Run Detail History ****************************/

export const getBroadcastRunDetail = (state, action) => {
  const { params: { location__id, broadcast_run_id } } = action.payload;
  if (!location__id || !broadcast_run_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            history: {
              ...state.locations[location__id].engage.broadcast.history,
              keyValue: {
                ...state.locations[location__id].engage.broadcast.history.keyValue,
                [broadcast_run_id]: {
                  ...state.locations[location__id].engage.broadcast.history.keyValue[broadcast_run_id],
                  getDetailInProgress: true,
                  getDetailStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
};

export const getBroadcastRunDetailSuccess = (state, action) => {
  const { data, params: { location__id, broadcast_run_id } } = action.payload;
  if (!location__id || !broadcast_run_id) return state;
  const { broadcast_run_detail } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          broadcast: {
            ...state.locations[location__id].engage.broadcast,
            history: {
              ...state.locations[location__id].engage.broadcast.history,
              keyValue: {
                ...state.locations[location__id].engage.broadcast.history.keyValue,
                [broadcast_run_id]: {
                  ...state.locations[location__id].engage.broadcast.history.keyValue[broadcast_run_id],
                  ...broadcast_run_detail,
                  getDetailInProgress: false,
                  getDetailStatus: { success: true },
                },
              },
            },
          },
        },
      },
    },
  };
};

export const getBroadcastRunDetailError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, broadcast_run_id } = params;
  if (!location__id || !broadcast_run_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          history: {
            ...state.locations[location__id].engage.broadcast.history,
            keyValue: {
              ...state.locations[location__id].engage.broadcast.history.keyValue,
              [broadcast_run_id]: {
                ...state.locations[location__id].engage.broadcast.history.keyValue[broadcast_run_id],
                getDetailInProgress: false,
                getDetailStatus: { error: true },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};
