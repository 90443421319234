// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// import { getReviewsDataAction } from '../../actions/reviewsActions';
import { formatDate } from '../../../../../../helpers';

import moreIcon from '../../../../../../img/icons/more.svg';
import closeIcon from '../../../../../../img/icons/close.svg';

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../../../Misc/OutsideClickHook';

import './style.css';


function SingleRow(props) {
  const { data } = props;
  return (
    <div className="gCrd glSingleCard" id={`rv_${data._id}`}>
      <div className="innerBox">
        <div className="nameBox">
          <p className="name">{data.first_name} {data.last_name}</p>
          <p className="email">{data.email}</p>
          <p className="contact">{data.contact}</p>
        </div>
        <div className="detailsBox">
          <div className="row row1">
            <div className="col">
              <div className="label">
                Check-In Date
              </div>
              <div className="value">
                {formatDate(data.check_in_date)}
              </div>
            </div>
            <div className="col">
              <div className="label">
                Check-Out Date
              </div>
              <div className="value">
                {formatDate(data.check_out_date)}
              </div>
            </div>
            <div className="col">
              <div className="label">
                Room Number
              </div>
              <div className="value">
                {data.room_number}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RepeatVisits(props) {
  const onCloseClick = (e) => { props.onCancelClick(); };

  const renderContent = () => {
    const guest = props.data;
    return (
      <div className="gPWrp">
        <div className="gPTle title">
          <div className="lt">
            All Visits
            {
              guest && guest.repeats
              ? <span>({guest.repeats.length})</span>
              : null
            }
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
              overlay={'Repeat visits are found by matching phone number or email'}
            >
              <i className="fas fa-info-circle" />
            </ToolTipWrapper>
          </div>
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
        </div>
        <div className="gBCtnt">
          <div className="listBox">
            {
              guest && guest.repeats
              ?
                guest.repeats.map((item) => {
                  return (
                    <SingleRow
                      data={item}
                      key={`rv_${item._id}`}
                    />
                  )
                })
              : null
            }
          </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('RepeatVisits data: ', props.data);
  }

  return (
    <div className="gCard repeatVisitsWrap">
        { renderContent() }
    </div>
  );
}

export default RepeatVisits;
