import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../../Misc/GenericSideModal';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import GenericList from '../../../../Misc/GenericList';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { getDataAction } from '../../../../../redux/actions/getDataActions';
import { STRS } from '../../../../../helpers';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

import './style.css';

function DGRooms(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [keyword, setKeyword] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - Digital Guide - Rooms - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  // useEffect(() => {
  //   console.log('WebChat urlParams: ', urlParams);
  //   console.log('WebChat locationidentifier: ', urlParams.locationidentifier);
  //   dispatch(getDataAction({
  //     op: 'get_webchat_rules',
  //     params: {
  //       location__id: urlParams.locationidentifier,
  //     },
  //   }));
  // }, [urlParams.locationidentifier]);


  const { settings: { digital_guide } } = props;

  if (process.env.NODE_ENV !== 'production') {
    console.log('DGRooms props: ', props);
  }

  return (
    <>
      {
        digital_guide && digital_guide.rooms && digital_guide.rooms.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <GenericList
        className="wc_rules"
        type="dg_rooms"
        title={`Rooms${props.isReadOnly ? ` [${STRS.READ_ONLY_LABEL}]` : ''}`}
        sub_title="Add/Edit room specific information sections"
        location__id={urlParams.locationidentifier}
        generic_list={digital_guide && digital_guide.rooms ? digital_guide.rooms : []}
        settings={props.settings}
      />
    </>
  );
}

export default DGRooms;
