import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import { guestLabelByGuestType, GUEST_LIST_CHECKED_IN_DISPLAY_SIZE_OPTIONS,
  GUEST_LIST_CHECKED_OUT_DISPLAY_SIZE_OPTIONS, GUEST_LIST_UPCOMING_DISPLAY_SIZE_OPTIONS } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';

function ListItem(props) {
  const onChange = (e) => {
    props.onChange(props.data.value);
  };
  const { data, selectedValue } = props;
  return (
    <div className="gBCtRw valRw">
      <div className="gRwBxLt">
        <div className="gLbl">
          <label>
            <input
              type="radio"
              name="maxEntries"
              value={data.value}
              checked={selectedValue === data.value}
              onChange={onChange}
            />
            {data.label}
          </label>
        </div>
      </div>
    </div>
  );
}

function DisplayPreferences(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [maxEntries, setMaxEntries] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const clearAction = () => {
    const params = {
      location__id: props.locationidentifier,
    };
    params.guest_list_display_pref = {
      guest_type: props.guestType,
    };
    dispatch(editDataClearAction({
      op: 'edit_guest_list_display_preferences',
      params,
    }));
  };

  const setInitialValues = () => {
    const { locationidentifier, guestType } = props;
    let locationProfile = {};
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile.locations && userState.user.user_info.profile.locations[locationidentifier] &&
      userState.user.user_info.profile.locations[locationidentifier].engage
    ) {
      locationProfile = userState.user.user_info.profile.locations[locationidentifier].engage;
    }
    if (locationProfile && locationProfile.display_prefs && locationProfile.display_prefs[guestType] &&
      locationProfile.display_prefs[guestType].list_size
    ) {
      setMaxEntries(locationProfile.display_prefs[guestType].list_size);
    }
  };

  useEffect(() => {
    setInitialValues();
    clearAction();
  }, []);

  useEffect(() => {
    const { locationProfile, guestType } = props;
    if (guestType && locationProfile.display_prefs && locationProfile.display_prefs[guestType] &&
      !locationProfile.display_prefs[guestType].updateDisplayPrefInProgress &&
      locationProfile.display_prefs[guestType].updateDisplayPrefStatus &&
      locationProfile.display_prefs[guestType].updateDisplayPrefStatus.success
    ) {
      props.onDisplayPreferenceUpdate();
    }
  }, [props.locationProfile, props.guestType])

  const onCloseClick = (e) => {
    clearAction();
    props.onCancelClick('');
  };

  const onMaxEntriesChange = (value) => {
    setMaxEntries(value);
    setSomeChange(true);
    clearAction();
  };

  const onSubmit = (e) => {
    if (!someChange) return;
    const { locationidentifier, guestType } = props;
    const { locationProfile } = props;
    if (locationProfile && locationProfile.display_prefs && locationProfile.display_prefs[props.guestType] &&
      locationProfile.display_prefs[props.guestType].updateDisplayPrefInProgress
    ) {
      return;
    }

    const params = {
      location__id: locationidentifier,
    };
    params.guest_list_display_pref = {
      guest_type: guestType,
      list_size: maxEntries,
    };
    dispatch(editDataAction({
      op: 'edit_guest_list_display_preferences',
      params,
    }));
    setSomeChange(false);
  };

  // const { categoryidentifier, locationidentifier } = urlParams;
  // const { guest, settings } = props;
  // if (!settings || !settings.engage) return null;
  // const { engage } = settings;

  const { locationidentifier, guestType } = props;
  if (!locationidentifier || !guestType) return null;

  const { locationProfile } = props;


  if (process.env.NODE_ENV !== 'production') {
    console.log('DisplayPreferences props: ', props);
    console.log('DisplayPreferences locationProfile: ', locationProfile);
  }

  let displayOptions = [];
  if (guestType === 'guest') displayOptions = GUEST_LIST_CHECKED_IN_DISPLAY_SIZE_OPTIONS;
  if (guestType === 'past-guest') displayOptions = GUEST_LIST_CHECKED_OUT_DISPLAY_SIZE_OPTIONS;
  if (guestType === 'upcoming-guest') displayOptions = GUEST_LIST_UPCOMING_DISPLAY_SIZE_OPTIONS;
  if (guestType === 'webtext') displayOptions = GUEST_LIST_CHECKED_OUT_DISPLAY_SIZE_OPTIONS;
  if (guestType === 'gbm') displayOptions = GUEST_LIST_CHECKED_OUT_DISPLAY_SIZE_OPTIONS;
  if (guestType === 'outside-guest') displayOptions = GUEST_LIST_CHECKED_OUT_DISPLAY_SIZE_OPTIONS;

  return (
    <div className="gPWrp">
      <h4 className="gPTle title">
        <span>
        {guestLabelByGuestType[guestType]} - Display Preferences
        </span>
        {
          props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
        }
      </h4>
      <h5 className="gPTle subTitle">
        Update display preferences
      </h5>
      <div className="gBCtnt">
        <div className="gBCtRw titleRow">
          <div className="gRwBxLt">
            <div className="gLbl">
              List size
            </div>
          </div>
        </div>
        {
          displayOptions.map((item) => {
            return (
              <ListItem data={item} onChange={onMaxEntriesChange} selectedValue={maxEntries} key={`gldp_${item.value}`} />
            );
          })
        }
        {
          locationProfile.display_prefs &&
          locationProfile.display_prefs[props.guestType] &&
          locationProfile.display_prefs[props.guestType].updateDisplayPrefStatus &&
          (locationProfile.display_prefs[props.guestType].updateDisplayPrefStatus.success || locationProfile.display_prefs[props.guestType].updateDisplayPrefStatus.error) &&
          <div className="gBCtRw curSts">
            {
              locationProfile.display_prefs[props.guestType].updateDisplayPrefStatus.error && <span className="gErr">{locationProfile.display_prefs[props.guestType].updateDisplayPrefStatus.message || 'Error in updating'}</span>
            }
            {
              locationProfile.display_prefs[props.guestType].updateDisplayPrefStatus.success && <span className="success">Updated successfully</span>
            }
          </div>
        }
        <div className="gBCtRw btnRw">
          {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
          <button type="submit" className={'gBtn ok' + (someChange ? '' : ' disabled ')} onClick={onSubmit} >
            Update
            { locationProfile.display_prefs && locationProfile.display_prefs[props.guestType] &&
              locationProfile.display_prefs[props.guestType].updateDisplayPrefInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        </div>

      </div>
    </div>
  );
}

export default DisplayPreferences;
