import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import PreDefinedMessage from './PreDefinedMessage';
// import LocationWrapper from './LocationWrapper';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { getRoomLabel, STRS, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';

import './style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function JMEmail(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [hasBookingMessage, setHasBookingMessage] = useState(false);
  const [bookingMessage, setBookingMessage] = useState({
    message: '',
    subject: '',
    previewText: '',
    sendAfter: '',
  });

  const [hasPreArrivalMessage, setHasPreArrivalMessage] = useState(false);
  const [preArrivalMessage, setPreArrivalMessage] = useState({
    message: '',
    subject: '',
    previewText: '',
    sendBefore: '',
    sendAt: '',
    sendAtError: '',
  });

  const [hasWelcomeMessage, setHasWelcomeMessage] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState({
    message: '',
    subject: '',
    previewText: '',
    sendAfter: '',
  });

  const [hasRepeatWelcomeMessage, setHasRepeatWelcomeMessage] = useState(false);
  const [repeatWelcomeMessage, setRepeatWelcomeMessage] = useState({
    message: '',
    subject: '',
    previewText: '',
    sendAfter: '',
  });

  const [hasDepartureMessage, setHasDepartureMessage] = useState(false);
  const [departureMessage, setDepartureMessage] = useState({
    message: '',
    subject: '',
    previewText: '',
    sendAfter: '',
  });

  const [updatedValues, setUpdatedValues] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Journey Messages - Email - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    let { settings: { engage }, rule } = props;
    if (!engage.email) return;
    let engageEmail = { ...engage.email };
    if (rule) {
      engageEmail = rule.attr && rule.attr.email ? { ...rule.attr.email } : {};
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('setInitialValues engageEmail: ', engageEmail);
    }

    setHasBookingMessage(engageEmail.has_booking_message || false);
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      message: engageEmail.booking_message_text,
      subject: engageEmail.booking_email_subject,
      previewText: engageEmail.booking_email_preview_text,
      sendAfter: engageEmail.booking_message_send_after,
    }));

    setHasPreArrivalMessage(engageEmail.has_pre_arrival_message || false);
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      message: engageEmail.pre_arrival_message_text,
      subject: engageEmail.pre_arrival_email_subject,
      previewText: engageEmail.pre_arrival_email_preview_text,
      sendBefore: engageEmail.pre_arrival_message_send_before,
      sendAt: engageEmail.pre_arrival_message_send_at_time ? moment(engageEmail.pre_arrival_message_send_at_time, 'HH:mm') : '',
      sendAtError: '',
    }));

    setHasWelcomeMessage(engageEmail.has_welcome_message || false);
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      message: engageEmail.welcome_message_text,
      subject: engageEmail.welcome_email_subject,
      previewText: engageEmail.welcome_email_preview_text,
      sendAfter: engageEmail.welcome_message_send_after,
    }));

    setHasRepeatWelcomeMessage(engageEmail.has_repeat_visitor_message || false);
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      message: engageEmail.repeat_visitor_message_text,
      subject: engageEmail.repeat_visitor_email_subject,
      previewText: engageEmail.repeat_visitor_email_preview_text,
      sendAfter: engageEmail.repeat_visitor_message_send_after,
    }));

    setHasDepartureMessage(engageEmail.has_departure_message || false);
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      message: engageEmail.departure_message_text,
      subject: engageEmail.departure_email_subject,
      previewText: engageEmail.departure_email_preview_text,
      sendAfter: engageEmail.departure_message_send_after,
    }));

    setFormChanged(false);
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  // useEffect(() => {
  //   setInitialValues();
  // }, [
  //   props.settings.engage.email,
  // ]);

  useEffect(() => {
    if (props.rule) {
      if (!props.rule.editInProgress && props.rule.editStatus && props.rule.editStatus.success) {
        setUpdatedValues({});
      }
    }
  }, [props.rule]);


  /******** Booking Message *************/

  const onHasBookingMessageChange = (value) => {
    setHasBookingMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_booking_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingMessageSendAfterChange = (value) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingMessageChange = (message) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onBookingSubjectChange = (subject) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      subject,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_email_subject: subject,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingPreviewTextChange = (previewText) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      previewText,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_email_preview_text: previewText,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Pre-Arrival Message *************/

  const onHasPreArrivalMessageChange = (value) => {
    setHasPreArrivalMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_pre_arrival_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendBeforeChange = (value) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendBefore: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_before: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendAtTimeChange = (value) => {
    // console.log('onPreArrivalMessageSendAtTimeChange: ', moment(value).format('HH:mm'));
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendAt: new Date(moment(value)),
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_at_time: moment(value).format('HH:mm'),
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendAtErrorChange = (error) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendAtError: error,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageChange = (message) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onPreArrivalSubjectChange = (subject) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      subject,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_email_subject: subject,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalPreviewTextChange = (previewText) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      previewText,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_email_preview_text: previewText,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Welcome Message *************/

  const onHasWelcomeMessageChange = (value) => {
    setHasWelcomeMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_welcome_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeMessageSendAfterChange = (value) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeMessageChange = (message) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onWelcomeSubjectChange = (subject) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      subject,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_email_subject: subject,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomePreviewTextChange = (previewText) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      previewText,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_email_preview_text: previewText,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Repeat Welcome Message *************/

  const onHasRepeatWelcomeMessageChange = (value) => {
    setHasRepeatWelcomeMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_repeat_visitor_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeMessageSendAfterChange = (value) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeMessageChange = (message) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onRepeatWelcomeSubjectChange = (subject) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      subject,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_email_subject: subject,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomePreviewTextChange = (previewText) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      previewText,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_email_preview_text: previewText,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /************* Departure Message *******************/

  const onHasDepartureMessageChange = (value) => {
    setHasDepartureMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_departure_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureMessageSendAfterChange = (value) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureMessageChange = (message) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      message,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_text: message,
    }));
    setFormChanged(true);
    clearEditRuleData();
    setCurrentStatus('');
  };
  const onDepartureSubjectChange = (subject) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      subject,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_email_subject: subject,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDeparturePreviewTextChange = (previewText) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      previewText,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_email_preview_text: previewText,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /************* End - Departure Message *******************/


  const clearEditRuleData = () => {
    if (props.rule) {
      dispatch(editDataClearAction({
        op: 'edit_guest_journey_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
        },
      }));
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    clearEditRuleData();
    if (formChanged) performSave();
  };

  const performSave = () => {
    if (hasBookingMessage && (!bookingMessage.message || !bookingMessage.subject || !bookingMessage.previewText || !bookingMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasPreArrivalMessage && (!preArrivalMessage.message || !preArrivalMessage.subject || !preArrivalMessage.previewText || !preArrivalMessage.sendBefore || !preArrivalMessage.sendAt || preArrivalMessage.sendAtError)) { setCurrentStatus('error'); return; }
    if (hasWelcomeMessage && (!welcomeMessage.message || !welcomeMessage.subject || !welcomeMessage.previewText || !welcomeMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasRepeatWelcomeMessage && (!repeatWelcomeMessage.message || !repeatWelcomeMessage.subject || !repeatWelcomeMessage.previewText || !repeatWelcomeMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasDepartureMessage && (!departureMessage.message || !departureMessage.subject || !departureMessage.previewText || !departureMessage.sendAfter)) { setCurrentStatus('error'); return; }

    const params = {
      // location_id: props.locationInfo.location_id,
    };
    // params.location__id = urlParams.locationidentifier;

    if ('has_booking_message' in updatedValues) params['has_booking_message'] = updatedValues.has_booking_message;
    if ('booking_message_text' in updatedValues) params['booking_message_text'] = updatedValues.booking_message_text;
    if ('booking_email_subject' in updatedValues) params['booking_email_subject'] = updatedValues.booking_email_subject;
    if ('booking_email_preview_text' in updatedValues) params['booking_email_preview_text'] = updatedValues.booking_email_preview_text;
    if ('booking_message_send_after' in updatedValues) params['booking_message_send_after'] = updatedValues.booking_message_send_after;

    if ('has_pre_arrival_message' in updatedValues) params['has_pre_arrival_message'] = updatedValues.has_pre_arrival_message;
    if ('pre_arrival_message_text' in updatedValues) params['pre_arrival_message_text'] = updatedValues.pre_arrival_message_text;
    if ('pre_arrival_email_subject' in updatedValues) params['pre_arrival_email_subject'] = updatedValues.pre_arrival_email_subject;
    if ('pre_arrival_email_preview_text' in updatedValues) params['pre_arrival_email_preview_text'] = updatedValues.pre_arrival_email_preview_text;
    if ('pre_arrival_message_send_before' in updatedValues) params['pre_arrival_message_send_before'] = updatedValues.pre_arrival_message_send_before;
    if ('pre_arrival_message_send_at_time' in updatedValues) params['pre_arrival_message_send_at_time'] = updatedValues.pre_arrival_message_send_at_time;

    if ('has_welcome_message' in updatedValues) params['has_welcome_message'] = updatedValues.has_welcome_message;
    if ('welcome_message_text' in updatedValues) params['welcome_message_text'] = updatedValues.welcome_message_text;
    if ('welcome_email_subject' in updatedValues) params['welcome_email_subject'] = updatedValues.welcome_email_subject;
    if ('welcome_email_preview_text' in updatedValues) params['welcome_email_preview_text'] = updatedValues.welcome_email_preview_text;
    if ('welcome_message_send_after' in updatedValues) params['welcome_message_send_after'] = updatedValues.welcome_message_send_after;

    if ('has_repeat_visitor_message' in updatedValues) params['has_repeat_visitor_message'] = updatedValues.has_repeat_visitor_message;
    if ('repeat_visitor_message_text' in updatedValues) params['repeat_visitor_message_text'] = updatedValues.repeat_visitor_message_text;
    if ('repeat_visitor_email_subject' in updatedValues) params['repeat_visitor_email_subject'] = updatedValues.repeat_visitor_email_subject;
    if ('repeat_visitor_email_preview_text' in updatedValues) params['repeat_visitor_email_preview_text'] = updatedValues.repeat_visitor_email_preview_text;
    if ('repeat_visitor_message_send_after' in updatedValues) params['repeat_visitor_message_send_after'] = updatedValues.repeat_visitor_message_send_after;

    if ('has_departure_message' in updatedValues) params['has_departure_message'] = updatedValues.has_departure_message;
    if ('departure_message_text' in updatedValues) params['departure_message_text'] = updatedValues.departure_message_text;
    if ('departure_email_subject' in updatedValues) params['departure_email_subject'] = updatedValues.departure_email_subject;
    if ('departure_email_preview_text' in updatedValues) params['departure_email_preview_text'] = updatedValues.departure_email_preview_text;
    if ('departure_message_send_after' in updatedValues) params['departure_message_send_after'] = updatedValues.departure_message_send_after;


    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }

    setCurrentStatus('');

    if (props.rule) {
      delete params.location__id;
      dispatch(editDataAction({
        op: 'edit_guest_journey_rule',
        show_alert: false,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
          room_id: props.rule.room_id,
          attr: {
            email: params,
          },
        }
      }));
    } else {
      params.source = 'engage';
      dispatch(editDataAction({
        op: 'edit_settings',
        show_alert: true,
        params: {
          location__id: urlParams.locationidentifier,
          source: 'engage',
          email: params,
        }
      }));
    }

    // dispatch(editSettingsAction({
    //   params,
    // }));
    setFormChanged(false);
    // gtag('event', 'gt_click', {
    //   'event_category': 'Settings - Chat',
    //   'event_label': 'Save',
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const renderStatus = () => {
    if (!currentStatus) return null;
    return (
      <div className="gBCtRw curSts">
        <p className="error">
          {
            currentStatus === 'error'
            ? 'Check above errors!'
            : currentStatus
          }
        </p>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('JMEmail: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp jMsg">
      <div className="gPWrp jMSms">
        <div className="gPTle title">
          Guest Journey messages - Email{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
          {
            props.rule &&
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
          }
        </div>
        {
          props.rule
          ?
            <>
              <div className="gPSubTle">
                Rule will be applied on guests whose room number / type matches with this rule and below guest journey messages will be sent.
              </div>
              <div className="gPSubTle">
                <p>Rule Name: {props.rule.label}</p>
                {
                  props.settings.rooms && props.settings.rooms.keyValue && props.settings.rooms.keyValue[props.rule.room_id] &&
                  <p>For Room: {getRoomLabel(props.settings.rooms.keyValue[props.rule.room_id])}</p>
                }
              </div>
            </>
          : null
        }
        <div className="gBCtnt">
          {
            props.settings.engage && props.settings.engage.booking_message_enabled &&
            <div className="gBCtRw jmEntRw">
              <PreDefinedMessage
                type="bookingMessage"
                heading="Booking Message"
                title="Send a booking message to the guest?"
                desc="Set an automated personalized booking message to guests."
                messageLabel="Booking Message"
                hasMessage={hasBookingMessage}
                message={bookingMessage.message}
                subject={bookingMessage.subject}
                previewText={bookingMessage.previewText}
                sendAfter={bookingMessage.sendAfter}
                onHasMessageChange={onHasBookingMessageChange}
                onMessageChange={onBookingMessageChange}
                onSubjectChange={onBookingSubjectChange}
                onPreviewTextChange={onBookingPreviewTextChange}
                onSendAfterChange={onBookingMessageSendAfterChange}
                locationInfo={props.locationInfo}
                dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the booking message.</p>}
              />
            </div>
          }
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="preArrivalMessage"
              heading="Pre-Arrival Message"
              title="Send a pre-arrival email to the guest?"
              desc="Set an automated personalized pre-arrival email to guests."
              messageLabel="Pre-Arrival Email"
              hasMessage={hasPreArrivalMessage}
              message={preArrivalMessage.message}
              subject={preArrivalMessage.subject}
              previewText={preArrivalMessage.previewText}
              sendBefore={preArrivalMessage.sendBefore}
              sendAt={preArrivalMessage.sendAt}

              onHasMessageChange={onHasPreArrivalMessageChange}
              onMessageChange={onPreArrivalMessageChange}
              onSubjectChange={onPreArrivalSubjectChange}
              onPreviewTextChange={onPreArrivalPreviewTextChange}
              onSendBeforeChange={onPreArrivalMessageSendBeforeChange}
              onSendAtTimeChange={onPreArrivalMessageSendAtTimeChange}
              onSendAtErrorChange={onPreArrivalMessageSendAtErrorChange}
              checkInTime={'10:00 AM'}
              locationInfo={props.locationInfo}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the pre-arrival message.</p>}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="welcomeMessage"
              heading="Welcome Message"
              title="Send a check-in welcome message to the guest?"
              desc="Set an automated personalized welcome message to guests."
              messageLabel="Welcome Message"
              hasMessage={hasWelcomeMessage}
              message={welcomeMessage.message}
              subject={welcomeMessage.subject}
              previewText={welcomeMessage.previewText}
              sendAfter={welcomeMessage.sendAfter}
              onHasMessageChange={onHasWelcomeMessageChange}
              onMessageChange={onWelcomeMessageChange}
              onSubjectChange={onWelcomeSubjectChange}
              onPreviewTextChange={onWelcomePreviewTextChange}
              onSendAfterChange={onWelcomeMessageSendAfterChange}
              locationInfo={props.locationInfo}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{wifi}}"} code.</p>}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="repeatWelcomeMessage"
              heading="Repeat Welcome Message"
              messageLabel="Welcome Message"
              title="Send check-in welcome message for returning guests?"
              desc="Set an automated personalized welcome message to returning guests."
              hasMessage={hasRepeatWelcomeMessage}
              message={repeatWelcomeMessage.message}
              subject={repeatWelcomeMessage.subject}
              previewText={repeatWelcomeMessage.previewText}
              sendAfter={repeatWelcomeMessage.sendAfter}
              onHasMessageChange={onHasRepeatWelcomeMessageChange}
              onMessageChange={onRepeatWelcomeMessageChange}
              onSubjectChange={onRepeatWelcomeSubjectChange}
              onPreviewTextChange={onRepeatWelcomePreviewTextChange}
              onSendAfterChange={onRepeatWelcomeMessageSendAfterChange}
              locationInfo={props.locationInfo}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{wifi}}"} code.</p>}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="departureMessage"
              heading="Departure Message"
              messageLabel="Departure Message"
              title="Send a check-out message to guests?"
              desc="Set a personalized check-out message to guests."
              hasMessage={hasDepartureMessage}
              message={departureMessage.message}
              subject={departureMessage.subject}
              previewText={departureMessage.previewText}
              sendAfter={departureMessage.sendAfter}
              onHasMessageChange={onHasDepartureMessageChange}
              onMessageChange={onDepartureMessageChange}
              onSubjectChange={onDepartureSubjectChange}
              onPreviewTextChange={onDeparturePreviewTextChange}
              onSendAfterChange={onDepartureMessageSendAfterChange}
              locationInfo={props.locationInfo}
            />
          </div>
          {
            props.rule
            ?
              <>
                {
                  props.rule.editStatus && (props.rule.editStatus.success || props.rule.editStatus.error) &&
                  <div className="gBCtRw curSts">
                    {
                      props.rule.editStatus.error && <p className="gErr">{props.rule.editStatus.message || 'Error!'}</p>
                    }
                    {
                      props.rule.editStatus.success && <p className="success">Updated successfully</p>
                    }
                  </div>
                }
              </>
            : null
          }
          { renderStatus() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.rule
                  ?
                    <>
                      {
                        props.settings.engage.journey_rules &&
                        props.settings.engage.journey_rules.keyValue &&
                        props.settings.engage.journey_rules.keyValue[props.rule._id] &&
                        props.settings.engage.journey_rules.keyValue[props.rule._id].editInProgress
                        ? <i className="fa fa-spinner fa-spin" />
                        : null
                      }
                    </>
                  :
                    <>{ props.settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }</>
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default JMEmail;
