import axios from 'axios';

import { auth } from '../../firebase';
import { sendEmailAlert } from '../../helpers';

const gtconfig = require('../../gtconfig');



/* ***************************  Start - Add Dashboard Review to Reviews ****************************** */

export function addReviewToReviewsAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'ADD_REVIEW_TO_REVIEWS',
      payload,
    });
  };
}

/* ***************************  End - Add Dashboard Review to Reviews  ****************************** */
