// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import ToolTipWrapper from '../ToolTipWrapper';

import './style.css';

function EmailSendingStatDetailCard(props) {
  const { label, icon, value, labelToolTip, labelValue1, labelValue2, labelValue3, labelValue4 } = props.data;

  return (
    <div className={'gCrd gEmailSendingStatDetailCard' + (props.cardType === 'small' ? ' small' : '')}>
      <div className="top">
        <div className="left">
          {label}
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay={labelToolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </div>
        {
          icon &&
          <div className="right">
            <img src={icon} />
          </div>
        }
      </div>
      <div className={'middle'}>
        <p className="value">
          {
            value ? value : '-'
          }
        </p>
        {
          props.cardType === 'detail' &&
          <div className="bar">
            <div className="filled" style={{width: value != '-' ? value : 0}}/>
          </div>
        }
        {
          labelValue1 &&
          <div className="labelValue">
            <span className="label">{labelValue1.label}</span>
            <span className="value">{labelValue1.value}</span>
          </div>
        }
        {
          labelValue2 &&
          <div className="labelValue">
            <span className="label">{labelValue2.label}</span>
            <span className="value">{labelValue2.value}</span>
          </div>
        }
      </div>
      {
        props.cardType === 'detail' && props.separator &&
        <div className="separator" />
      }
      {
        (labelValue3 || labelValue4) &&
        <div className="footer">
          {
            labelValue3 &&
            <div className="labelValue">
              <span className="label">{labelValue3.label}</span>
              <span className="value">{labelValue3.value}</span>
            </div>
          }
          {
            labelValue4 &&
            <div className="labelValue">
              <span className="label">{labelValue4.label}</span>
              <span className="value">{labelValue4.value}</span>
            </div>
          }
        </div>
      }
    </div>
  );
}

export default EmailSendingStatDetailCard;
