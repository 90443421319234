import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
// import 'firebase/auth';
import { auth } from '../../../../firebase';
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

// var generator = require('generate-password');

// import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { setSelfPassChangeIdentifier } from '../../../../redux/actions/miscActions';

import { isPasswordStrong } from '../../../../helpers';

import './style.css';


function ChangePassword(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const [repeatPassword, setRepeatPassword] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const [inProgress, setInProgress] = useState(false);
  const [userReAuthError, setUserReAuthError] = useState('');
  const [identifier, setIdentifier] = useState('');

  // analytics reporting
  useEffect(() => {
    try {
      document.title = `Profile - Change Password`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    const params = {};
    dispatch(editDataClearAction({
      op: 'edit_self_password',
    }));
  };

  useEffect(() => {
    clearAction();
    // var identifierLocal = generator.generate({
    //     length: 10,
    //     numbers: true
    // });
    var identifierLocal = (new Date()).getTime();
    setIdentifier(identifierLocal);
    dispatch(setSelfPassChangeIdentifier({
      params: {
        identifier: identifierLocal,
      },
    }));
  }, []);

  useEffect(() => {
    if (!userState.user.user_info.editPassInProgress &&
      userState.user.user_info.editPassStatus &&
      userState.user.user_info.editPassStatus.success
    ) {
      setSomeChange(false);
    }
  }, [userState.user.user_info]);


  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'oldPassword') {
      setOldPassword(value);
      setOldPasswordError(value ? '' : 'error');
    }
    if (name === 'newPassword') {
      setNewPassword(value);
    }
    if (name === 'repeatPassword') {
      setRepeatPassword(value);
    }
    setUserReAuthError('');
    setSomeChange(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (inProgress) return;
    if (someChange) performSave();
  };

  const performSave = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave');
    }
    let hasError = false;

    setOldPasswordError(oldPassword ? '' : 'error');
    setNewPasswordError(newPassword ? '' : 'error');
    setRepeatPasswordError(repeatPassword ? '' : 'error');

    if (!oldPassword || !newPassword || !repeatPassword) {
      hasError = true;
    }
    if (newPassword) {
      let isPassStrong = isPasswordStrong(newPassword);
      setNewPasswordError(isPassStrong ? '' : 'weak');
      hasError = !isPassStrong;
    }

    if (newPassword !== repeatPassword) {
      setRepeatPasswordError('mismatch');
      hasError = true;
    }


    if (hasError) return;

    const credential = EmailAuthProvider.credential(
      userState.user.uid,
      oldPassword,
    );
    setInProgress(true);
    reauthenticateWithCredential(auth.currentUser, credential).then(function() {
      // User re-authenticated.
      if (process.env.NODE_ENV !== 'production') {
        console.log('User re-authenticated.');
      }
      window.gtCId = identifier;
      const params = {
        password: newPassword,
        identifier,
      };

      if (process.env.NODE_ENV !== 'production') {
        console.log('performSave params: ', params);
      }
      dispatch(editDataAction({
        op: 'edit_self_change_password',
        show_alert: true,
        params,
      }));
      setInProgress(false);
    }).catch(function(error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('reauthenticateWithCredential error: ', error);
      }
      setInProgress(false);
      setUserReAuthError('error');
      // An error happened.
    });



  };


  const renderEdit = () => {
    const { uid, contact } = userState.user.user_info;
    return (
      <>
        <div className="gBCtRw prfEdtRw">
          <div className="lt">
            <div className="gLbl">
              Current Password
            </div>
            <div className="gIpt">
              <input type="password" name="oldPassword" placeholder="Current Password" value={oldPassword} onChange={onChange} />
            </div>
            {
              oldPasswordError &&
              <div className="gErr">Required</div>
            }
          </div>
        </div>
        <div className="gBCtRw prfEdtRw">
          <div className="lt">
            <div className="gLbl">
              New Password
            </div>
            <div className="gIpt">
              <input type="password" name="newPassword" placeholder="New Password" value={newPassword} onChange={onChange} />
            </div>
            {
              newPasswordError === 'error' &&
              <div className="gErr">Required</div>
            }
            {
              newPasswordError === 'weak' &&
              <div className="gErr">Minimum 8 characters required</div>
            }
          </div>
        </div>
        <div className="gBCtRw prfEdtRw">
          <div className="lt">
            <div className="gLbl">
              Repeat Password
            </div>
            <div className="gIpt">
              <input type="password" name="repeatPassword" placeholder="Repeat Password" value={repeatPassword} onChange={onChange} />
            </div>
            {
              repeatPasswordError === 'error' &&
              <div className="gErr">Required</div>
            }
            {
              repeatPasswordError === 'mismatch' &&
              <div className="gErr">Password mismatch</div>
            }
          </div>
        </div>
      </>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('ChangePassword: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen prfInf">
        <div className="gPTle title">
          Profile Info
        </div>
        <div className="gBCtnt">
          { renderEdit() }
          {
            userReAuthError &&
            <div className="gBCtRw curSts">
              <span className="gErr">Wrong current password!</span>
            </div>
          }
          <div className="gBCtRw btnRw prfEdtSvRw">
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSubmit} >
              Save Changes
              {
                (inProgress || userState.user.user_info.editPassInProgress) && <i className="fa fa-spinner fa-spin" />
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
