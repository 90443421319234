// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Pagination from 'rc-pagination';

import { setCommonParams } from '../../../../../redux/actions/appActions';
import { getDataAction } from '../../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS, GUEST_LIST_SORT_OPTIONS, GUEST_LIST_SORT_OPTIONS_MAP,
  PAGE_SIZE_OPTIONS, DATE_DURATION_OPTIONS_MAP,
  formatDate, PN_TO_ID, FEEDBACK_SOURCE_TO_LABEL, getUserGAId } from '../../../../../helpers';

import SingleGuestRow from './SingleGuestRow';
import RepeatVisits from './RepeatVisits';
import NumberCard from '../../../../Misc/NumberCard';


import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import './style.css';
import 'antd/dist/antd.css';
import "rc-pagination/assets/index.css";

import searchIcon from '../../../../../img/icons/search.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import downloadIcon from '../../../../../img/icons/download.svg';
import sortIcon from '../../../../../img/icons/sort.svg';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function Overview(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );

  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());
  const [dateRangeActive, setDateRangeActive] = useState(false);

  const [filterKeyword, setFilterKeyword] = useState('');
  const [guestListSortValue, setGuestListSortValue] = useState(
    { label: 'Newest First', value: 'newest' }
  );

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [pageSizeValue, setPageSizeValue] = useState(
    { label: '10', value: 10 }
  );

  const [category, setCategory] = useState('');

  const [showRepeatVisitsModal, setShowRepeatVisitsModal] = useState(false);
  const repeatVisitsModalRef = useRef();
  useOnClickOutside(repeatVisitsModalRef, useCallback(() => onRepeatVistsModalCloseClick()));
  const [repeatVisitsModalData, setRepeatVisitsModalData] = useState({});


  const getData = (startDate, endDate, keyword, category, orderBy, pageNumber, pageSize) => {
    dispatch(getDataAction({
      op: 'get_guestlist_overview_data',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        keyword,
        category,
        order_by: orderBy,
        skip: pageSize * (pageNumber - 1) >= 0 ? pageSize * (pageNumber - 1) : 0,
        limit: pageSize,
      }
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - GuestList - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('GuestListOverview parsed: ', parsed);
    }

    if (parsed['keyword']) {
      const keyword = parsed['keyword'];
      if (keyword) {
        setFilterKeyword(keyword);
      }
    }

    if (parsed['category']) {
      const category = parsed['category'];
      if (category) {
        setCategory(category);
      }
    }

    if (parsed['order_by']) {
      const orderBy = parsed['order_by'];
      if (orderBy && GUEST_LIST_SORT_OPTIONS_MAP[orderBy]) {
        setGuestListSortValue({ label: GUEST_LIST_SORT_OPTIONS_MAP[orderBy], value: orderBy });
      }
    }


    let tempPageNumber = '';
    if (parsed['page_number']) {
      tempPageNumber = parsed['page_number'];
      if (tempPageNumber && Number.isInteger(parseInt(tempPageNumber))) {
        setPageNumber(tempPageNumber);
      }
    }
    if (!tempPageNumber) tempPageNumber = 1;

    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    let dateUpdated = commonParams ? commonParams.dateUpdated || new Date(moment().subtract(3, 'days')) : new Date(moment().subtract(3, 'days'));
    if (moment().diff(dateUpdated, 'hours') > 24) {
      startDate = new Date(moment().subtract(1, 'month'));
      endDate = new Date();
    }
    
    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });

    getData(startDate, endDate, parsed['keyword'], parsed['category'], parsed['order_by'], tempPageNumber, pageSize);
  }, [urlParams.locationidentifier]);

  useEffect(() => {
    const { category } = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('category: ', category);
    }
    if (category) {
      getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, category, null, 1, pageSize);
    }
  }, [location.search]);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setFilterKeyword(value);
  };
  const onSearchClick = (e) => {
    if (filterKeyword) {
      setPageNumber(1);
      const parsed = queryString.parse(location.search);
      parsed['keyword'] = filterKeyword;
      if (parsed['page_number']) delete parsed['page_number'];
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, category, guestListSortValue.value, 1, pageSize);
      // gtag('event', 'search', {
      //   'search_term': filterKeyword,
      // });
      // gtag('event', 'gt_click', {
      //   'event_category': `GuestList - Search - Keyword - Button`,
      //   'event_label': `Keyword`,
      //   'value': filterKeyword,
      //   user_id: props.userprops.user.uid,
      // });
    }
  };
  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (filterKeyword) {
        setPageNumber(1);
        const parsed = queryString.parse(location.search);
        parsed['keyword'] = filterKeyword;
        if (parsed['page_number']) delete parsed['page_number'];
        const stringified = queryString.stringify(parsed);
        navigate(`${location.pathname}?${stringified}`);
        getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, category, guestListSortValue.value, 1, pageSize);
        // gtag('event', 'search', {
        //   'search_term': filterKeyword,
        // });
        // gtag('event', 'gt_click', {
        //   'event_category': `GuestList - Search - Keyword - Enter`,
        //   'event_label': `Keyword`,
        //   'value': filterKeyword,
        //   user_id: props.userprops.user.uid,
        // });
      }
    }
    if (e.keyCode === 27) {
      setFilterKeyword('');
      setPageNumber(1);
      setGuestListSortValue({ label: GUEST_LIST_SORT_OPTIONS_MAP['newest'], value: 'newest' });
      const parsed = queryString.parse(location.search);
      if (parsed['keyword']) delete parsed['keyword'];
      if (parsed['page_number']) delete parsed['page_number'];
      if (parsed['order_by']) delete parsed['order_by'];
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      getData(dateFilterStartDate, dateFilterEndDate, '', category, null, 1, pageSize);
    }
  };
  const onSearchClearClick = (e) => {
    setFilterKeyword('');
    setPageNumber(1);
    setGuestListSortValue({ label: GUEST_LIST_SORT_OPTIONS_MAP['newest'], value: 'newest' });
    const parsed = queryString.parse(location.search);
    if (parsed['keyword']) delete parsed['keyword'];
    if (parsed['page_number']) delete parsed['page_number'];
    if (parsed['order_by']) delete parsed['order_by'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    getData(dateFilterStartDate, dateFilterEndDate, '', category, null, 1, pageSize);
  };


  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { guestlist } = props.reputation;
    if (!guestlist || !guestlist.overview_data || guestlist.overview_data.getInProgress) return;
    setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setPageNumber(1);
    setGuestListSortValue({ label: GUEST_LIST_SORT_OPTIONS_MAP['newest'], value: 'newest' });
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    dispatch(setCommonParams({
      params: {
        rangeKeyword: '',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
        dateUpdated: new Date(),
      },
    }));
    getData(dates[0], dates[1], filterKeyword, category, null, 1, pageSize);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - GuestList - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - GuestList - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDateRangeLastMonthChange = (value) => {
    const { guestlist } = props.reputation;
    if (!guestlist || !guestlist.overview_data || guestlist.overview_data.getInProgress) return;
    setPageNumber(1);
    setGuestListSortValue({ label: GUEST_LIST_SORT_OPTIONS_MAP['newest'], value: 'newest' });
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    setDateRangeActive(false);
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      getData(new Date(moment().subtract(period, 'days')), new Date(), filterKeyword, category, null, 1, pageSize);
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      props.dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      getData(new Date(moment().subtract(period, 'month')), new Date(), filterKeyword, category, null, 1, pageSize);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - GuestList - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - GuestList - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onPageChange = (page) => {
    setPageNumber(page);
    getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, category, guestListSortValue.value, page, pageSize);
    const parsed = queryString.parse(location.search);
    parsed['page_number'] = page;
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onPaginationShowSizeChange = (current, pageSize) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPaginationShowSizeChange: ', current, ' # ', pageSize);
    }
  };

  const onPageSizeChange = (value) => {
    setPageSizeValue(value);
    setPageSize(value.value);
    setPageNumber(1);
    getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, category, guestListSortValue.value, 1, value.value);
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onGuestListSortChange = (value) => {
    setPageNumber(1);
    setGuestListSortValue(value);
    getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, category, value.value, pageSize);
    const parsed = queryString.parse(location.search);
    parsed['order_by'] = value.value;
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onRepeatVistsModalCloseClick = () => {
    setRepeatVisitsModalData({});
    setShowRepeatVisitsModal(false);
    document.body.style.overflow = 'auto';
  };

  const onShowRepeatVisitsClick = (guest) => {
    setRepeatVisitsModalData(guest);
    setShowRepeatVisitsModal(true);
    document.body.style.overflow = 'hidden';
  };

  const onCheckinsClick = (e) => {
    setPageNumber(1);
    setCategory('');
    getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, '', guestListSortValue.value, 1, pageSize);
    const parsed = queryString.parse(location.search);
    if (parsed['category']) delete parsed['category'];
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onRepeatsClick = (e) => {
    setPageNumber(1);
    setCategory('repeat');
    getData(dateFilterStartDate, dateFilterEndDate, filterKeyword, 'repeat', guestListSortValue.value, 1, pageSize);
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    parsed['category'] = 'repeat';
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onDownloadClick = (e) => {
    const { guestlist } = props.reputation;
    if (!guestlist || !guestlist.data_dump || guestlist.data_dump.getInProgress) return;
    let filename = "GuestList";
    if (props.locationInfo.name) { filename += "-" + props.locationInfo.name.replace(/ /g, ''); }
    if (dateFilterStartDate) { filename += "-" + moment(dateFilterStartDate).format('YYYY-MM-DD') }
    if (dateFilterEndDate) { filename += "-to-" + moment(dateFilterEndDate).format('YYYY-MM-DD') }
    filename += ".csv";
    dispatch(getDataAction({
      op: 'get_guestlist_dump',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(dateFilterStartDate).format('YYYY-MM-DD'),
        end_date: moment(dateFilterEndDate).format('YYYY-MM-DD'),
        keyword: filterKeyword,
        filename,
      },
    }));
    // gtag('event', 'gt_click', {
    //   'event_category': `GuestList - Download CSV`,
    //   'event_label': `Download CSV`,
    //   'value': '',
    //   user_id: props.userprops.user.uid,
    // });
  };

  const renderDateFilterRow = () => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Overview
          </div>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={DATE_DURATION_OPTIONS}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderRepeatVistsModal = () => {
    return (
      <Modal>
        <div id="cModelId1" className="sdMdl repeatVisitsModal">
          <div className="ctnt" ref={repeatVisitsModalRef}>
            <RepeatVisits
              data={repeatVisitsModalData}
              onCancelClick={onRepeatVistsModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };


  const renderPagination = (keyValue, overallCount) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderPagination pageNumber: ', pageNumber);
    }
    // if (!keyValue || !keyValue.filter_count) return null;
    // let totalCount = 0;
    // totalCount = keyValue.filter_count || 0;
    // console.log('totalCount final: ', totalCount);
    if (!overallCount) return null;
    return (
      <div className="rpPagination">
        <Pagination
          onChange={onPageChange}
          // defaultCurrent={pageNumber}
          current={pageNumber}
          total={overallCount}
          pageSize={pageSize}
          hideOnSinglePage={true}
          showSizeChanger
          onShowSizeChange={onPaginationShowSizeChange}
        />
      </div>
    );
  };

  const renderStatBox = (keyValue) => {
    if (!keyValue || !keyValue.overall || !keyValue.overall.stats) return null;
    return (
      <div className="statBox">
        <NumberCard
          data={{
            label: 'Guests',
            value: keyValue.overall.stats.checkins,
            labelToolTip: 'Total guests',
            ctaLabel: category === 'repeat' ? 'View all guests' : '',
          }}
          onCtaClick={onCheckinsClick}
        />
        <NumberCard
          data={{
            label: 'Nights',
            value: keyValue.overall.stats.nights,
            labelToolTip: 'Total numbers of nights',
          }}
        />
        <NumberCard
          data={{
            label: 'Repeat',
            value: keyValue.overall.stats.repeats,
            labelToolTip: 'Total repeat guests',
            ctaLabel: category === '' ? 'View repeat guests' : '',
          }}
          onCtaClick={onRepeatsClick}
        />
      </div>
    );
  }

  const renderContent = (overview_data, data_dump) => {
    const { locationidentifier } = urlParams;

    return (
      <div className="gLOvrBd">
        <div className="lt">
          <div className="innerBox">
            <div className="contentBox">
              <div className="dataBox">
                { renderStatBox(overview_data) }
                <div className="guestsTitle">
                  <div className="left">
                    <p>All Guests
                      {
                        category === 'repeat'
                        ? ' -> Repeat Guests'
                        : ''
                      }
                      <span>({ overview_data && overview_data.overall && overview_data.overall.stats ? overview_data.overall.stats.guest_count : 0})</span>
                    </p>
                  </div>
                  <div className="right" onClick={onDownloadClick}>
                    <img src={downloadIcon} />
                    <p className="download">Download CSV</p>
                    {
                      data_dump && data_dump.getInProgress &&
                      <span>
                        <i className="fa fa-spinner fa-spin" />
                      </span>
                    }
                  </div>
                </div>
                <div className="guestsSearch">
                  <div className="left">
                    <div className="searchInput">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        value={filterKeyword}
                        onChange={onSearchChange}
                        onKeyDown={onSearchInputKeyDown}
                        placeholder="Search for guests"
                      />
                    </div>
                    <div className="searchBtn" >
                      { filterKeyword && <img src={closeIcon} onClick={onSearchClearClick} /> }
                      <span onClick={onSearchClick}>Search</span>
                    </div>
                  </div>
                  <div className="right">
                    <img src={sortIcon} />
                    <div className="guestListSort">
                      <Select
                        className="guestListSortSelect"
                        options={GUEST_LIST_SORT_OPTIONS}
                        onChange={onGuestListSortChange}
                        value={guestListSortValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="listBox">
                  {
                    overview_data && overview_data.overall && overview_data.overall.list
                    ?
                      overview_data.overall.list.map((item) => {
                        return <SingleGuestRow data={item} onShowRepeatVisitsClick={onShowRepeatVisitsClick} />
                      })
                    : null
                  }
                </div>
                <div className="pageOptions">
                  <div className="paginationBox">
                    {
                      overview_data && overview_data.overall && overview_data.overall.stats
                      ? renderPagination(overview_data, overview_data.overall.stats.guest_count)
                      : null
                    }
                  </div>
                  <div className="pageSizeBox">
                    <Select
                      className="pageSizeSelect"
                      options={PAGE_SIZE_OPTIONS}
                      onChange={onPageSizeChange}
                      value={pageSizeValue}
                      menuPlacement="top"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          { showRepeatVisitsModal && renderRepeatVistsModal() }
        </div>
      </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('Overview props: ', props);
  }

  const { guestlist } = props.reputation;
  if (!guestlist || !guestlist.overview_data || !guestlist.data_dump) return null;
  const { overview_data, data_dump } = guestlist;

  return (
    <>
      {/*
        guestlist.overview_data.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      {
        guestlist.overview_data.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg gLOvrPg">
          { renderDateFilterRow() }
          { renderContent(guestlist.overview_data, guestlist.data_dump) }
        </div>
      </div>
    </>
  );
}

export default Overview;
