import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";
import axios from 'axios';

import { setLoggedIn } from "../../redux/actions/miscActions";
import { sendEmailAlert, Encrypt } from '../../helpers';

import gtLogo from '../../img/gtlogo-color.svg';

import './style.css';

const gtconfig = require('../../gtconfig');

const loginFormFields = {
  username: '',
  code: '',
  password: '',
  repeatPassword: '',
  usernameError: '',
  passwordError: '',
  loginInProgress: false,
};
const RESET_PASSWORD_ERROR_MSG = 'Error in resetting password! Please contact support.';
const LOGIN_WITH_CODE_ERROR_MSG = 'Incorrect code!';

function Login() {
  const user = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const loginFormUsername = useRef(null);
  const loginFormCode = useRef(null);
  const loginFormPassword = useRef(null);
  const loginFormRepeatPassword = useRef(null);
  const [loginForm, setLoginForm] = useState(loginFormFields);
  const [loginError, setLoginError] = useState(false);

  const [loginCodeEnabled, setLoginCodeEnabled] = useState(false);

  const [forgotPasswordEnabled, setForgotPasswordEnabled] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [forgotPasswordSendCodeInProgress, setForgotPasswordSendCodeInProgress] = useState(false);
  const [forgotPasswordSendCodeStatus, setForgotPasswordSendCodeStatus] = useState('');

  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const [resetPasswordStatus, setResetPasswordStatus] = useState('');

  const [loginSendCodeInProgress, setLoginSendCodeInProgress] = useState(false);
  const [loginSendCodeStatus, setLoginSendCodeStatus] = useState('');
  const [loginWithCodeInProgress, setLoginWithCodeInProgress] = useState(false);
  const [loginWithCodeStatus, setLoginWithCodeStatus] = useState('');

  const lPathParams = useRef({});

  let params = useParams();

  useEffect(() => {
    document.title = `${gtconfig.APP_NAME_CONTACT} - Login`;
    // window.Intercom('shutdown');
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login location changed: ', location);
    }
    const queryJson = queryString.parse(lPathParams.current.search ||location.search);
    const { usr } = queryJson;
    if (usr) {
      setLoginForm({
        ...loginForm,
        username: usr,
      });
      let tStr = '';
      Object.keys(queryJson).forEach((item) => {
        if (item !== 'usr') {
          tStr = tStr ? `${tStr}&${item}=${queryJson[item]}` : `${item}=${queryJson[item]}`;
        }
      });
      navigate(`/login?${tStr}`);
    }
  }, [location]);


  const onAuthStateChange = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login onAuthStateChange');
    }
    return auth.onAuthStateChanged((authUser) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Login onAuthStateChanged: ', authUser, ' # ', new Date().getTime()); // comment
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('Login onAuthStateChanged location ', location);
        console.log('Login onAuthStateChanged lPathParams ', lPathParams);
      }
      if (authUser) {
        dispatch(setLoggedIn({
          uid: authUser.uid,
          loggedIn: true,
        }));

        let nextRoute = '/';
        // const queryJson = queryString.parse(location.search);
        // const queryJson = queryString.parse(lPathParams.current.search || {});
        const queryJson = queryString.parse(lPathParams.current.search ||location.search);
        const { next, qparams } = queryJson;
        if (process.env.NODE_ENV !== 'production') {
          console.log('Login location ', location);
          console.log('qparams ', qparams);
        }
        if (next) {
          if (next === 'customToken') {
            // let eResult = Encrypt("somesalt", `${authUser.uid}`);
            // if (process.env.NODE_ENV !== 'production') {
            //   console.log('Login eResult ', eResult);
            // }
            nextRoute = `${nextRoute}?customToken=ct`;
          } else {
            nextRoute = next;
            if (qparams) {
              nextRoute = `${nextRoute}?${qparams.replaceAll('_-_', '&')}`;
            }
          }
        }
        // nextRoute = '/intg-configure/remote-lock?code=1234'
        if (process.env.NODE_ENV !== 'production') {
          console.log('pushing nextRoute', nextRoute);
        }

        navigate(nextRoute);
      }
    });
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);


  function onChange(e) {
    const { name, value } = e.target;
    if (value) {
      switch (name) {
        case 'username':
          let tempError = value ? '' : 'Field is required';
          if (value && value.indexOf('@') === -1) tempError = 'Invalid Email';
          setLoginForm({
            ...loginForm,
            [name]: value,
            usernameError: tempError, // value ? false : true,
          });
          break;
        case 'password':
          setLoginForm({
            ...loginForm,
            [name]: value,
            passwordError: value ? '' : 'Field is required',
          });
          break;
        default:
          setLoginForm({
            ...loginForm,
            [name]: value,
            [`${name}Error`]: false,
          });
      }
      setResetPasswordStatus('');
      setLoginSendCodeStatus('');
      setLoginWithCodeStatus('');
    } else {
      setLoginForm({
        ...loginForm,
        [name]: value,
        [`${name}Error`]: true,
      });
    }
    setLoginError(false);
  };

  const onGetCodeClick = (e) => {
    const { username } = loginForm;
    let usernameError = username ? '' : 'Field is required';
    if (username && username.indexOf('@') === -1) usernameError = 'Invalid Email';
    setLoginForm({
      ...loginForm,
      usernameError,
      passwordError: '',
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login onGetCodeClick username: ', username);
    }
    if (username) {
      sendCode({
        username,
        purpose: 'login',
      });
    }
    setLoginWithCodeStatus('');
    setLoginSendCodeStatus('');
  };
  const onForgotPasswordClick = (e) => {
    const { username } = loginForm;
    let usernameError = username ? '' : 'Field is required';
    if (username && username.indexOf('@') === -1) usernameError = 'Invalid Email';
    setLoginForm({
      ...loginForm,
      usernameError,
      passwordError: '',
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login onForgotPasswordClick username: ', username);
    }
    if (username) {
      sendCode({
        username,
        purpose: 'forgot-password',
      });
    }
    setResetPasswordInProgress(false);
    setResetPasswordStatus('');
  };
  const onShowLoginClick = (e) => {
    setLoginCodeEnabled(false);
    setForgotPasswordEnabled(false);
    setLoginForm(loginFormFields);
  }

  function onSubmit(e) {
    e.preventDefault();

    if (forgotPasswordSendCodeInProgress) return null;
    if (loginSendCodeInProgress) return;

    if (loginForm.loginInProgress) return null;
    // if (props.userprops.get_user_info_in_progress) return null; // nkcheck

    const { username, password } = loginForm;
    let usernameError = username ? '' : 'Field is required';
    if (username && username.indexOf('@') === -1) usernameError = 'Invalid Email';
    let passwordError = password ? '' : 'Field is required';
    setLoginForm({
      ...loginForm,
      usernameError,
      passwordError,
    });
    if (passwordError) {
      if (loginFormPassword) { loginFormPassword.current.focus(); }
    }
    if (usernameError) {
      if (loginFormUsername) { loginFormUsername.current.focus(); }
    }
    if (!usernameError && !passwordError) {
      // props.history.push('/home');
      // props.dispatch(setNewUrl('/home'));
      performLogin(username, password);
    }
  };

  const performLogin = (username, password) => {
    setLoginForm(prevForm => ({
      ...prevForm,
      loginInProgress: true,
    }));
    setLoginError(false);
    // props.dispatch(setLoginWithCred({
    //   loginWithCred: true,
    // }));
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login performLogin location ', location);
    }
    lPathParams.current = { pathname: location.pathname, search: location.search };
    signInWithEmailAndPassword(auth, username, password)
      .then((authUser) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('signInWithEmailAndPassword authUser: ', authUser);
        }
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - Logged In - ${username}`,
          message: JSON.stringify({ username }),
          // error: JSON.stringify({ error }),
        });
        // gtag('event', 'login', {
        //   'method': 'Google'
        // });
        // // gtag('config', 'GA_MEASUREMENT_ID', {
        // //   'user_id': username,
        // // });
        // // props.history.push('/home');
        // let nextRoute = '/home';
        // const queryJson = queryString.parse(props.location.search);
        // const { next, intgkey } = queryJson;
        // if (next) {
        //   nextRoute = next;
        //   if (intgkey) nextRoute = `${nextRoute}?intgkey=${intgkey}`;
        // }
        // if (process.env.NODE_ENV !== 'production') {
        //   console.log('pushing ', nextRoute);
        // }
        // // props.history.push('/categories/guests');
        // props.history.push(nextRoute);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('auth error: ', error);
        }
        setLoginError(true);
        setLoginForm(prevForm => ({
          ...prevForm,
          loginInProgress: false,
        }));
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - auth Error - ${username}`,
          message: JSON.stringify({ username }),
          error: JSON.stringify({ error }),
        });
      });
  };


  const sendCode = (params) => {
    try {
      const url = gtconfig.DATA_BACKEND_URL;
      const opdata = {};
      // opdata.params = params;
      opdata.params = {
        ...params,
        relogin_identifier: window.gtCId,
      };
      const postData = {
        appId: gtconfig.APP_ID,
        op: 'send_code',
        opdata,
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendCode postData: ', postData);
      }

      const headers = {
        'Content-type': 'application/json',
        'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
      };
      const axiosData = {
        gtps: postData,
        M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
      };

      if (params.purpose === 'forgot-password') setForgotPasswordSendCodeInProgress(true);
      if (params.purpose === 'login') setLoginSendCodeInProgress(true);

      axios
        .post(url, axiosData, { headers })
        .then((response) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('sendCode response: ', response.data);
          }
          if (params.purpose === 'forgot-password') setForgotPasswordSendCodeInProgress(false);
          if (params.purpose === 'login') setLoginSendCodeInProgress(false);
          const { status, message } = response.data;
          const { data } = response.data;
          if (status === 'success' && data) {
            if (data.app_info && data.app_info.origin === window.location.origin) {
              if (process.env.NODE_ENV !== 'production') {
                console.log('sendCode data: ', data);
              }
              if (params.purpose === 'forgot-password') setForgotPasswordSendCodeStatus('success');
              if (params.purpose === 'login') setLoginSendCodeStatus('success');
              if (params.purpose === 'forgot-password') setForgotPasswordEnabled(true);
              if (params.purpose === 'login') setLoginCodeEnabled(true);
            }
          } else {
            if (process.env.NODE_ENV !== 'production') {
              console.log('sendCode error: ', response);
            }
            if (message && message.indexOf('err u') > -1) {
              if (params.purpose === 'forgot-password') setForgotPasswordSendCodeStatus('success');
              if (params.purpose === 'login') setLoginSendCodeStatus('success');
              if (params.purpose === 'forgot-password') setForgotPasswordEnabled(true);
              if (params.purpose === 'login') setLoginCodeEnabled(true);
            } else {
              if (params.purpose === 'forgot-password') setForgotPasswordSendCodeStatus('error');
              if (params.purpose === 'login') setLoginSendCodeStatus('error');
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - sendCode Error`,
              message: JSON.stringify({ postData, responseData: response.data }),
              error: JSON.stringify(response.data),
            });
          }
        })
        .catch((err) => {
          // axios error
          if (process.env.NODE_ENV !== 'production') {
            console.log('sendCode err--------------------: ', err);
            console.log('sendCode err.response --------------------: ', err.response);
          }
          if (params.purpose === 'forgot-password') setForgotPasswordSendCodeStatus('error');
          if (params.purpose === 'login') setLoginSendCodeStatus('error');
          if (params.purpose === 'forgot-password') setForgotPasswordSendCodeInProgress(false);
          if (params.purpose === 'login') setLoginSendCodeInProgress(false);
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - sendCode Error - axios`,
            message: JSON.stringify({ url, postData }),
            error: JSON.stringify({ err , config: err.config }),
          });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendCode e: ', e);
      }
      if (params.purpose === 'forgot-password') setForgotPasswordSendCodeStatus('error');
      if (params.purpose === 'login') setLoginSendCodeStatus('error');
      if (params.purpose === 'forgot-password') setForgotPasswordSendCodeInProgress(false);
      if (params.purpose === 'login') setLoginSendCodeInProgress(false);
    }
  };



  const resetPassword = (params) => {
    try {
      const url = gtconfig.DATA_BACKEND_URL;
      const opdata = {};
      // opdata.params = params;
      opdata.params = {
        ...params,
        relogin_identifier: window.gtCId,
      };
      const postData = {
        appId: gtconfig.APP_ID,
        op: 'reset_password',
        opdata,
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log('resetPassword postData: ', postData);
      }

      const headers = {
        'Content-type': 'application/json',
        'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
      };
      const axiosData = {
        gtps: postData,
        M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
      };

      setResetPasswordInProgress(true);
      setForgotPasswordSendCodeStatus('');

      axios
        .post(url, axiosData, { headers })
        .then((response) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('resetPassword response: ', response.data);
          }
          setResetPasswordInProgress(false);
          const { status, message } = response.data;
          const { data } = response.data;
          if (status === 'success' && data) {
            if (data.app_info && data.app_info.origin === window.location.origin) {
              if (process.env.NODE_ENV !== 'production') {
                console.log('resetPassword data: ', data);
              }
              setResetPasswordStatus('success');
              setLoginForm({
                ...loginForm,
                code: '',
                password: '',
                repeatPassword: '',
              })
            }
          } else {
            if (process.env.NODE_ENV !== 'production') {
              console.log('resetPassword error: ', response);
            }
            if (message) {
              if (message.indexOf('err o') > -1) setResetPasswordStatus('Code expired!');
              if (message.indexOf('err c') > -1 || message.indexOf('err u') > -1
              ) setResetPasswordStatus('Wrong code!');
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - resetPassword Error`,
              message: JSON.stringify({ postData, responseData: response.data }),
              error: JSON.stringify(response.data),
            });
          }
        })
        .catch((err) => {
          // axios error
          if (process.env.NODE_ENV !== 'production') {
            console.log('resetPassword err--------------------: ', err);
            console.log('resetPassword err.response --------------------: ', err.response);
          }
          setResetPasswordStatus(RESET_PASSWORD_ERROR_MSG);
          setResetPasswordInProgress(false);
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - resetPassword Error - axios`,
            message: JSON.stringify({ url, postData }),
            error: JSON.stringify({ err , config: err.config }),
          });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('resetPassword e: ', e);
      }
      setResetPasswordStatus(RESET_PASSWORD_ERROR_MSG);
      setResetPasswordInProgress(false);
    }
  };


  const loginWithCode = (params) => {
    try {
      const url = gtconfig.DATA_BACKEND_URL;
      const opdata = {};
      // opdata.params = params;
      opdata.params = {
        ...params,
        relogin_identifier: window.gtCId,
      };
      const postData = {
        appId: gtconfig.APP_ID,
        op: 'login_with_code',
        opdata,
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log('loginWithCode postData: ', postData);
      }

      const headers = {
        'Content-type': 'application/json',
        'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
      };
      const axiosData = {
        gtps: postData,
        M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
      };

      setLoginWithCodeInProgress(true);
      setLoginSendCodeStatus('');

      axios
        .post(url, axiosData, { headers })
        .then((response) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('loginWithCode response: ', response.data);
          }
          setLoginWithCodeInProgress(false);
          const { status, message } = response.data;
          const { data } = response.data;
          if (status === 'success' && data && data.tkn) {
            if (data.app_info && data.app_info.origin === window.location.origin) {
              if (process.env.NODE_ENV !== 'production') {
                console.log('loginWithCode data: ', data);
              }
              setLoginWithCodeStatus('success');
              setLoginForm({
                ...loginForm,
                code: '',
              })
              signInWithCustomToken(auth, data.tkn)
              .then((authUser) => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('signInWithCustomToken authUser: ', authUser);
                }
              })
              .catch((error) => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('signInWithCustomToken auth error: ', error);
                }
                setLoginWithCodeStatus('Error in logging in!')
              });
            }
          } else {
            if (process.env.NODE_ENV !== 'production') {
              console.log('loginWithCode error: ', response);
            }
            if (message) {
              if (message.indexOf('err o') > -1) setResetPasswordStatus('Code expired!');
              if (message.indexOf('err c') > -1 || message.indexOf('err u') > -1
              ) setLoginWithCodeStatus('Wrong code!');
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - loginWithCode Error`,
              message: JSON.stringify({ postData, responseData: response.data }),
              error: JSON.stringify(response.data),
            });
          }
        })
        .catch((err) => {
          // axios error
          if (process.env.NODE_ENV !== 'production') {
            console.log('loginWithCode err--------------------: ', err);
            console.log('loginWithCode err.response --------------------: ', err.response);
          }
          setLoginWithCodeStatus(LOGIN_WITH_CODE_ERROR_MSG);
          setLoginWithCodeInProgress(false);
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - loginWithCode Error - axios`,
            message: JSON.stringify({ url, postData }),
            error: JSON.stringify({ err , config: err.config }),
          });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('loginWithCode e: ', e);
      }
      setLoginWithCodeStatus(LOGIN_WITH_CODE_ERROR_MSG);
      setLoginWithCodeInProgress(false);
    }
  };


  function onLoginWithCodeSubmit(e) {
    e.preventDefault();

    if (loginWithCodeInProgress) return null;
    if (loginWithCodeStatus === 'success') return;

    const { username, code } = loginForm;
    let usernameError = username ? '' : 'Field is required';
    if (username && username.indexOf('@') === -1) usernameError = 'Invalid Email';
    let codeError = code ? '' : 'Field is required';
    setLoginForm({
      ...loginForm,
      usernameError,
      codeError,
    });

    if (codeError) {
      if (loginFormCode) { loginFormCode.current.focus(); }
    }
    if (usernameError) {
      if (loginFormUsername) { loginFormUsername.current.focus(); }
    }
    if (!usernameError && !codeError) {
      performLoginWithCode(username, code);
    }
  };

  const performLoginWithCode = (username, code) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login performLoginWithCode location ', location);
    }
    loginWithCode({
      username,
      code,
    });
  };


  function onForgotPasswordSubmit(e) {
    e.preventDefault();

    if (resetPasswordInProgress) return null;
    if (resetPasswordStatus === 'success') return;

    const { username, code, password, repeatPassword } = loginForm;
    let usernameError = username ? '' : 'Field is required';
    if (username && username.indexOf('@') === -1) usernameError = 'Invalid Email';
    let codeError = code ? '' : 'Field is required';
    let passwordError = password ? '' : 'Field is required';
    let repeatPasswordError = repeatPassword ? '' : 'Field is required';
    if (!repeatPasswordError) {
      repeatPasswordError = password !== repeatPassword ? 'Password mismatch' : ''
    }
    setLoginForm({
      ...loginForm,
      usernameError,
      codeError,
      passwordError,
      repeatPasswordError
    });

    if (passwordError) {
      if (loginFormPassword) { loginFormPassword.current.focus(); }
    }
    if (repeatPasswordError) {
      if (loginFormRepeatPassword) { loginFormRepeatPassword.current.focus(); }
    }
    if (codeError) {
      if (loginFormCode) { loginFormCode.current.focus(); }
    }
    if (usernameError) {
      if (loginFormUsername) { loginFormUsername.current.focus(); }
    }
    if (!usernameError && !codeError && !passwordError && !repeatPasswordError) {
      // props.history.push('/home');
      // props.dispatch(setNewUrl('/home'));
      performResetPassword(username, code, password);
    }
  };

  const performResetPassword = (username, code, password) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login performResetPassword location ', location);
    }
    resetPassword({
      username,
      code,
      password,
    });
  };

  const renderField = ({ label, name, refVar, readOnly, showGetCode, placeholder }) => {
    return (
      <div className="fr">
        <label>{label}</label>
        <input
          name={name}
          value={loginForm[name]}
          type={ name === "password" || name === "repeatPassword" ? "password" : "text" }
          placeholder={placeholder || label}
          onChange={onChange}
          autoComplete={ name === "password" ? "current-password" : "username" }
          ref={refVar}
          readOnly={readOnly}
        />
        {
          name === 'password' && showGetCode &&
          <div className="passExRw">
            <div className="lt">
              {
                loginSendCodeInProgress &&
                <span>Sending code <i className="fas fa-spinner fa-spin"></i></span>
              }
              {
                !loginSendCodeInProgress &&
                <>
                  {
                    loginCodeEnabled
                    ? <span>Code sent to above email</span>
                    : <span onClick={onGetCodeClick}>Get one-time login code</span>
                  }
                </>
              }
            </div>
            <div className="rt">
              {
                !forgotPasswordEnabled &&
                <>
                  {
                    forgotPasswordSendCodeInProgress &&
                    <span>Sending code <i className="fas fa-spinner fa-spin"></i></span>
                  }
                  {
                    !forgotPasswordSendCodeInProgress &&
                    <span onClick={onForgotPasswordClick}>Forgot Password</span>
                  }
                </>
              }
            </div>
          </div>
        }
        <div className={'hlp ' + (loginForm[`${name}Error`] ? 'err' : '')}>
          { loginForm[`${name}Error`] && <span className={'err'}>{loginForm[`${name}Error`]}</span> }
          {/* name === 'password' && <a className={'fp'} href="https://accounts.guesttouch.com/forgot-password?application=gtengage">Forgot Password ?</a> */}
        </div>
      </div>
    );
  };

  const renderLoginForm = () => {
    let actionInProgress = forgotPasswordSendCodeInProgress;
    return (
      <div className="lf">
        <h5>
          <img src={gtconfig.APP_LOGO} />
          {
            (gtconfig.APP_ID === 'gt-unified-rep-only-live' ||
            gtconfig.APP_ID === 'gt-unified-dev' ||
            gtconfig.APP_ID === 'gt-unified-app-test') &&
            <span>GuestTouch</span>
          }
        </h5>
        <p>Login</p>
        <form onSubmit={onSubmit}>
          { renderField({ name: 'username', label: 'Email', type: 'input', refVar: loginFormUsername, readOnly: actionInProgress }) }
          { renderField({ name: 'password', label: 'Password', type: 'input', refVar: loginFormPassword, readOnly: actionInProgress, showGetCode: true, }) }
          {
            loginError &&
            <div className="le">Incorrect username or password</div>
          }
          <div className={"btns"}>
            <button type="submit" className={"login"}>Login</button>
          </div>
          {
            loginForm.loginInProgress &&
            <div className="loader"><span><i className="fas fa-spinner fa-spin"></i></span></div>
          }
        </form>
        {
          (gtconfig.APP_ID === 'gt-unified-wl-dev' || gtconfig.APP_ID === 'gt-unified-prosperhotels' ||
          gtconfig.APP_ID === 'gt-unified-wl-test')
          ?
            <div className="pp">
              <p>By signing in you agree to following Terms of Service and Privacy Policy of Prosper Hotels</p>
            </div>
          :
            <div className="pp">
              <p>By signing in you agree to following Terms of Service and Privacy Policy</p>
              <p><a href="https://guesttouch.com/terms-conditions/" target="_blank">Terms of Service</a> | <a href="https://guesttouch.com/privacy-policy/" target="_blank">Privacy Policy</a></p>
            </div>
        }
      </div>
    );
  };

  const renderLoginWithCodeForm = () => {
    let actionInProgress = loginSendCodeInProgress;
    return (
      <div className="lf">
        <h5>
          <img src={gtconfig.APP_LOGO} />
          {
            (gtconfig.APP_ID === 'gt-unified-rep-only-live' ||
            gtconfig.APP_ID === 'gt-unified-dev' ||
            gtconfig.APP_ID === 'gt-unified-app-test') &&
            <span>GuestTouch</span>
          }
        </h5>
        <p>Login</p>
        <form onSubmit={onLoginWithCodeSubmit}>
          { renderField({ name: 'username', label: 'Email', type: 'input', refVar: loginFormUsername, readOnly: actionInProgress }) }
          { renderField({ name: 'code', label: 'One-time login code',
            placeholder: 'Add your code to continue',
            type: 'input', refVar: loginFormCode })
          }
          {
            loginWithCodeStatus && loginWithCodeStatus !== 'success' &&
            <div className="le">{loginWithCodeStatus}</div>
          }
          {
            loginWithCodeStatus === 'success' &&
            <div className="inf">Logging in!</div>
          }
          {
            loginSendCodeStatus === 'success' &&
            <div className="inf">Check your email for the verification code. If the email is registered as a user, use the code to log in.</div>
          }
          <div className={"btns lgnWCode"}>
            <button type="submit" className={"login"}>Login with code</button>
          </div>
          <div className={"btns bckToLgn"}>
            <button className="gBtn cancel" onClick={onShowLoginClick}>Login with password</button>
          </div>
          {
            loginWithCodeInProgress &&
            <div className="loader"><span><i className="fas fa-spinner fa-spin"></i></span></div>
          }
        </form>
        <div className="pp">
          <p>By signing in you agree to following Terms of Service and Privacy Policy</p>
          <p><a href="https://guesttouch.com/terms-conditions/" target="_blank">Terms of Service</a> | <a href="https://guesttouch.com/privacy-policy/" target="_blank">Privacy Policy</a></p>
        </div>
      </div>
    );
  };

  const renderForgotPasswordForm = () => {
    return (
      <div className="lf">
        <h5>
          <img src={gtconfig.APP_LOGO} />
          {
            (gtconfig.APP_ID === 'gt-unified-rep-only-live' ||
            gtconfig.APP_ID === 'gt-unified-dev' ||
            gtconfig.APP_ID === 'gt-unified-app-test') &&
            <span>GuestTouch</span>
          }
        </h5>
        <p>Reset Password</p>
        <form onSubmit={onForgotPasswordSubmit}>
          { renderField({ name: 'username', label: 'Email', type: 'input', refVar: loginFormUsername, readOnly: true, }) }
          { renderField({ name: 'code', label: 'One-time login code',
            placeholder: 'Add your code to continue',
            type: 'input', refVar: loginFormCode })
          }
          { renderField({ name: 'password', label: 'Password', type: 'input', refVar: loginFormPassword, showGetCode: false, }) }
          { renderField({ name: 'repeatPassword', label: 'Repeat Password', type: 'input', refVar: loginFormRepeatPassword }) }
          {
            resetPasswordStatus && resetPasswordStatus !== 'success' &&
            <div className="le">{resetPasswordStatus}</div>
          }
          {
            resetPasswordStatus === 'success' &&
            <div className="inf">Congratulations! Your password has been successfully updated. You can now Login from the <span onClick={onShowLoginClick}>Login</span> page.</div>
          }
          {
            forgotPasswordSendCodeStatus === 'success' &&
            <div className="inf">Check your email for the verification code. If the email is registered as a user, use the code to update your password.</div>
          }
          <div className={"btns"}>
            <button type="submit" className={"login"}>Reset Password</button>
          </div>
          <div className={"btns bckToLgn"}>
            <button className="gBtn cancel" onClick={onShowLoginClick}>Back to Login</button>
          </div>
          {
            resetPasswordInProgress &&
            <div className="loader"><span><i className="fas fa-spinner fa-spin"></i></span></div>
          }
        </form>
      </div>
    );
  };

  const cStyle = {};
  cStyle['height'] = window.innerHeight;

  const { APP_ID } = gtconfig;

  return (
    <div className="lw" style={cStyle}>
      <div className="lt">
        <iframe id="iframeId"
          title={gtconfig.APP_NAME_CONTACT}
          src="https://d1dis9b5p43h87.cloudfront.net/html/pages/reputation-banner.html">
        </iframe>
      </div>
      <div className="rt">
        {
          forgotPasswordEnabled
          ?
            renderForgotPasswordForm()
          :
            <>
              {
                loginCodeEnabled
                ? renderLoginWithCodeForm()
                : renderLoginForm()
              }
            </>
        }
        {/* !forgotPasswordEnabled ? renderLoginForm() : renderForgotPasswordForm() */}
      </div>
    </div>
  );
}

export default Login;
