import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { addReviewToReviewsAction } from '../../../../redux/actions/miscReputationActions';

import { OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  renderOTALogo, replaceAll, validateEmail, isContactValid, formatDateTime,
  INSTANT_INVITE_MEDIUM_TO_LABEL, INSTANT_INVITE_REQUEST_TYPE_TO_LABEL, getUserGAId } from '../../../../helpers';

import './style.css';

import requiredIcon from '../../../../img/icons/required.svg';

const gtconfig = require('../../../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function TemplateButton(props) {
  const onButtonClick = (e) => {
    props.onButtonClick(props.data);
  };

  const { data, selected } = props;
  return (
    <div className={'entry' + (selected ? ' selected' : '')} onClick={onButtonClick}>
      {data.title}
    </div>
  );
}

const initialForm = {
  name: '',
  contact: '+1',
  email: '',
  template_id: '',
  preview_msg: '',

  mediumError: '',
  contactError: '',
  emailError: '',
  templateError: '',

  contactChanged: false,
  emailChanged: false,
};

function InstantInvite(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [selectedMedium, setSelectedMedium] = useState('');

  const [form, setForm] = useState(initialForm);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [previewMsg, setPreviewMsg] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const contactRef = useRef(null);

  const getData = () => {
    dispatch(getDataAction({
      op: 'get_instant_invite_dashboard_data',
      params: {
        location__id: urlParams.locationidentifier,
        // start_date: moment(startDate).format('YYYY-MM-DD'),
        // end_date: moment(endDate).format('YYYY-MM-DD'),
      },
    }));
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Instant Invite - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Reputation - Instant Invite - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    clearStatus();
    getData();

    const { reputation } = props.settings;
    if (reputation) {
      const { feedback_text_templates } = reputation;
      if (feedback_text_templates) {
        const { list, keyValue } = feedback_text_templates;
        if (list && list.length && keyValue) {
          list.forEach((item) => {
            if (keyValue[item] && keyValue[item].is_default) {
              setSelectedTemplate(keyValue[item]);
            }
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (props.reputation.instant_invite && props.reputation.instant_invite.sendStatus &&
      props.reputation.instant_invite.sendStatus.success &&
      !props.reputation.instant_invite.sendInProgress
    ) {
      setForm(form => ({
        ...form,
        name: '',
        email: '',
        contact: '',
        template_id: '',
      }));
      // setSelectedTemplate({});
      // setSomeChange(false);
    }
  }, [props.reputation.instant_invite]);

  useEffect(() => {
    let msg = selectedTemplate && selectedTemplate._id ? selectedTemplate.msg || '' : '';
    if (msg.indexOf('{{name}}') > -1 && form.name) msg = replaceAll(msg, '{{name}}', form.name);
    setPreviewMsg(msg);
  }, [selectedTemplate, form.name, form.name]);

  // useEffect(() => {
  //   clearStatus();
  // }, [someChange]);

  const clearStatus = () => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'send_instant_invite_feedback_request',
      params
    }));
  };

  const onMediumButtonClick = (data) => {
    setSelectedMedium(data.value);
    if (data.value === 'sms' || data.value === 'email') {
      setForm(form => ({
        ...form,
        contactError: '',
        emailError: '',
        mediumError: '',
      }));
    }
    setSomeChange(true);
  }

  const onContactChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onContactChange: ', value);
    }
    if (value) {
      let contactError = '';
      try {
        const pContactNumber = parsePhoneNumber(value);
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact pContactNumber: ', pContactNumber);
        }
        if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
          contactError = 'invalid';
        }
      } catch (e) {}
      setForm(form => ({
        ...form,
        contact: value,
        contactError: contactError,
        contactChanged: true,
      }));

    } else {
      setForm(form => ({
        ...form,
        contact: value,
        contactError: 'error',
        contactChanged: true,
      }));
    }
    setSomeChange(true);
    clearStatus();
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
    }));
    if (name === 'name') {
      setForm(form => ({
        ...form,
        [`${name}Error`]: value ? '' : 'error',
      }));
    }
    if (name === 'email') {
      let emailError = !value ? 'error' : '';
      if (!emailError) {
        if (!validateEmail(value)) emailError = 'invalid';
      }
      setForm(form => ({
        ...form,
        emailError,
        // emailError: !validateEmail(value) ? 'error' : '',
      }));
    }
    setSomeChange(true);
    clearStatus();
  };

  const onTemplateButtonClick = (templateData) => {
    setSelectedTemplate(templateData);
    setForm(form => ({
      ...form,
      templateError: '',
    }));
    setSomeChange(true);
  };

  const onSendClick = (e) => {
    if (!selectedMedium) {
      setForm(form => ({
        ...form,
        mediumError: 'error',
      }));
    }
    if (!selectedTemplate._id) {
      setForm(form => ({
        ...form,
        templateError: 'error',
      }));
    }
    if (selectedMedium === 'sms') {
      let contactError = !form.contact ? 'error' : '';
      try {
        const pContactNumber = parsePhoneNumber(form.contact);
        if (process.env.NODE_ENV !== 'production') {
          console.log('onSendClick validateContact pContactNumber: ', pContactNumber);
        }
        // if (!contactError) {
          if (!pContactNumber || !isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
            contactError = 'invalid';
          }
        // }
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('onSendClick e: ', e);
        }
      }
      setForm(form => ({
        ...form,
        contactError,
      }));
      if (contactError) return;
    }
    if (selectedMedium === 'email') {
      let emailError = !form.email ? 'error' : '';
      if (!emailError) {
        if (!validateEmail(form.email)) emailError = 'invalid';
      }
      setForm(form => ({
        ...form,
        emailError: emailError,
      }));
      if (emailError) return;
    }
    if (selectedMedium && selectedTemplate._id && previewMsg) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('onSendClick all good');
      }
      // send_instant_invite_feedback_request
      const params = {};
      params.location__id = urlParams.locationidentifier;
      params.medium = selectedMedium;
      params.template_id = selectedTemplate._id;
      params.template_type = selectedTemplate.type;
      if (form.name) params.name = form.name;
      if (selectedMedium === 'sms') params.contact = form.contact;
      if (selectedMedium === 'email') params.email = form.email;
      params.msg = previewMsg;
      dispatch(editDataAction({
        op: 'send_instant_invite_feedback_request',
        params
      }));
    }
  };

  const onResetClick = (e) => {
    if (props.reputation.instant_invite && props.reputation.instant_invite.sendInProgress) return;
    setForm(initialForm);
    setSelectedMedium('');
    setSelectedTemplate({});
    setPreviewMsg('');
    setSomeChange(false);
    clearStatus();
  };

  const renderInputForm = (instantInviteData, feedbackTextTemplates) => {
    const { list, keyValue } = feedbackTextTemplates;
    return (
      <div className="inpFrm">
        <div className="bcRow tmplsRow mediumRow">
          <div className="gLbl">
            Choose medium
          </div>
          <div className="tmplTypes">
            <TemplateButton data={{title: 'SMS', value: 'sms'}} onButtonClick={onMediumButtonClick} selected={selectedMedium === 'sms'} />
            <TemplateButton data={{title: 'Email', value: 'email'}} onButtonClick={onMediumButtonClick} selected={selectedMedium === 'email'} />
          </div>
          {
            form.mediumError &&
            <div className="gErr">
              <img src={requiredIcon} /> Required
            </div>
          }
        </div>
        <div className="bcRow nameRow">
          <div className="gLbl">
            Name (Optional)
          </div>
          <div className={'gIpt ' + (form.firstNameError ? 'error' : '')}>
            <input type="text" name="name" placeholder="Name" value={form.name} onChange={onChange} />
          </div>
        </div>
        {
          selectedMedium === 'sms' &&
          <div className={'bcRow contactRow'}>
            <div className="gLbl">
              Cell Number
            </div>
            <div className={'gIpt ' + (form.contactError ? 'error' : '')}>
              <PhoneInput
                international
                defaultCountry="US"
                placeholder="Enter phone number"
                displayInitialValueAsLocalNumber={false}
                value={form.contact}
                // onCountryChange={onCountryChange}
                ref={contactRef}
                onChange={onContactChange}/>
              {/*<input type="text" placeholder="Cell Number" />*/}
            </div>
            {
              form.contactError &&
              <div className="gErr">
                <img src={requiredIcon} /> { form.contactError === 'invalid' ? <span>Invalid contact</span> : ''}
                { form.contactError === 'error' ? <span>Required</span> : ''}
              </div>
            }

            <div className="gHelpMsg">

            </div>
          </div>
        }
        {
          selectedMedium === 'email' &&
          <div className="bcRow nameRow">
            <div className="gLbl">
              Email
            </div>
            <div className={'gIpt ' + (form.emailError ? 'error' : '')}>
              <input type="text" name="email" placeholder="Email" value={form.email} onChange={onChange} />
            </div>
            {
              form.emailError &&
              <div className="gErr">
                <img src={requiredIcon} /> { form.emailError === 'invalid' ? <span>Invalid email</span> : ''}
                { form.emailError === 'error' ? <span>Required</span> : ''}
              </div>
            }
          </div>
        }
        <div className="bcRow tmplsRow">
          <div className="gLbl">
            Choose review collection type
          </div>
          <div className="tmplTypes">
            {
              list && list.length && keyValue
              ? list.map((item) => {
                if (!keyValue[item]) return null;
                return (
                  <TemplateButton data={keyValue[item]} onButtonClick={onTemplateButtonClick} selected={selectedTemplate._id === item} />
                )
              })
              : null
            }
          </div>
          {
            form.templateError &&
            <div className="gErr">
              <img src={requiredIcon} /> Required
            </div>
          }
        </div>
        <div className="bcRow prvRow">
          <div className="gLbl">
            Message Preview
          </div>
          <div className="prv">
          {
            previewMsg
            ?
              <>
                <div className="msg">
                  {previewMsg}
                </div>
                <div className="hlp">
                  {"{{feedback_text_link}}"} will be replaced before sending with a review link of the format https://revew.us/XXXXXXXXX
                </div>
              </>
            : null
          }
          </div>
          {
            !previewMsg &&
            <div className="gErr">
              <img src={requiredIcon} /> Type selection required
            </div>
          }
        </div>
        {
          instantInviteData.sendStatus &&
          (instantInviteData.sendStatus.success || instantInviteData.sendStatus.error) &&
          <div className="bcRow curSts">
            {
              instantInviteData.sendStatus.error && <p className="error">{instantInviteData.sendStatus.message}</p>
            }
            {
              instantInviteData.sendStatus.success && <p className="success">Sent successfully</p>
            }
          </div>
        }
        <div className="bcRow buttonsRow">
          <div className="lt">
            <>
              <button className={'gBtn send ' + (someChange ? '' : 'disabled')} disabled={!someChange} onClick={onSendClick}>
                Send{ instantInviteData.sendInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            </>
          </div>
          <div className="rt">
          <button className={'gBtn cancel'} onClick={onResetClick}>
            Reset
          </button>
          </div>
        </div>
      </div>
    )
  };

  const renderPreview = () => {
    return null;
  };

  const renderPreviousSent = (instantInviteData) => {
    const { sent } = instantInviteData;
    return (
      <div className="prvSnt">
        {
          sent && sent.length
          ?
            <>
              <div className="gLbl">Recently sent</div>
              {
                sent.map((item) => {
                  return (
                    <div className="entry">
                      <div className="rw tp">
                        <div className="lt">
                          {INSTANT_INVITE_MEDIUM_TO_LABEL[item.medium]}
                        </div>
                        <div className="rt">
                          {item.contact || item.email}
                        </div>
                      </div>
                      <div className="rw">
                        <div className="lt">
                          {
                            item.feedback_text_template_type
                            ?
                            INSTANT_INVITE_REQUEST_TYPE_TO_LABEL[item.feedback_text_template_type]
                            : null
                          }
                        </div>
                        <div className="rt">
                          Sent on {formatDateTime(item.date_created)}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </>
          : null
        }
      </div>
    );
  };

  const renderSplLoader = () => <span><i className="fa fa-circle-notch fa-spin" /></span>;



  if (process.env.NODE_ENV !== 'production') {
    console.log('InstantInvite props: ', props);
  }

  const { instant_invite } = props.reputation;
  if (!instant_invite) return null;

  const { reputation, info } = props.settings;
  if (!reputation || !info) return null;
  const { feedback_text_templates } = reputation;


  return (
    <>
      {
        instant_invite.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt ">
        <div className="gPg insIvt">
          <div className="title">
            Send Instant Review Invite Request
          </div>
          <div className="subTitle pb10">
            Your sending number: { info.sms_number || ''}
          </div>
          <div className="subTitle">
            To send review request to multiple guests using upload, use <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/run-campaign`}>Run Campaign</Link>
          </div>
          <div className="insIvt">
            <div className="lt">
              { renderInputForm(instant_invite, feedback_text_templates || {}) }
              { renderPreview() }
            </div>
            <div className="rt">
              { renderPreviousSent(instant_invite) }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstantInvite;
