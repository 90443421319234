// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import queryString from 'query-string';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import Sample from './Sample';
import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';
import { STRS, getUserGAId } from '../../../../../../helpers';

import './style.css';

import uploadImg from '../../../../../../img/icons/upload.png';
import searchIcon from '../../../../../../img/icons/search.svg';
import closeIcon from '../../../../../../img/icons/close.svg';
import resetIcon from '../../../../../../img/icons/reset-active.svg';
import tipIcon from '../../../../../../img/icons/bulb.png';
import starIcon from '../../../../../../img/icons/star.png';

const gtconfig = require('../../../../../../gtconfig');


const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function TypeBox(props) {
  const onSelectClick = (e) => {
    if (props.isReadOnly) return;
    props.onSelectClick(props.type);
  };
  const onSampleClick = (e) => { props.onSampleClick(props.type); }
  return (
    <>
      <div className={'typeBox ' + (props.type) + (props.selected ? ' selected' : '') + (props.active ? ' active' : '')}>
        <div className="sampleLink"><span onClick={onSampleClick}>View a sample</span></div>
        <div className="top">
          <p className="title">{props.title}</p>
          {
            props.type === 'smiley' &&
            <div className="iconRow">
              <img src={gtconfig.FEEDBACK_HAPPY} />
              <img src={gtconfig.FEEDBACK_SAD} />
            </div>
          }
          {
            props.type === 'stars' &&
            <div className="iconRow">
              <img src={starIcon} />
              <img src={starIcon} />
              <img src={starIcon} />
              <img src={starIcon} />
              <img src={starIcon} />
            </div>
          }
          {
            props.type === 'nps' &&
            <div className="iconRow nps">
              {
                Array(11).fill().map((el, idx) => {
                return (<div className="npsRtngBx">{idx}</div>)
                })
              }
            </div>
          }
        </div>
        <div className="body">
          <p className="desc">{props.desc}</p>
        </div>
        {
          !props.active
          ?
            <div className="select">
              <span onClick={onSelectClick}>
                Select
                {
                  props.inProgress ? <span><i className="fa fa-spinner fa-spin" /></span> : null
                }
              </span>
            </div>
          :
            <div className="select">
              <p>Currently is use</p>
            </div>
        }
      </div>
    </>
  );
}

function FeedbackType(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  const location = useLocation();

  const [selectedType, setSelectedType] = useState('');

  const [showSampleModal, setShowSampleModal] = useState(false);
  const [sampleType, setSampleType] = useState('');
  const sampleModalRef = useRef();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Type - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'edit_feedback_settings',
      params,
    }));
  }, []);

  const onTypeBoxSelect = (type) => {
    setSelectedType(type);
    const params = {};
    params.type = type;
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataAction({
      op: 'edit_feedback_settings',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
      show_alert_timeout: 2000,
      show_error_alert: true,
      error_alert_msg: 'Error in updating',
    }));
  };

  const onViewSampleClick = (type) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onViewSampleClick: ', type);
    }
    setShowSampleModal(true);
    setSampleType(type);
  };
  const onSampleModalCloseClick = (e) => {
    setShowSampleModal(false);
  };

  const renderSampleModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="sdMdl fdbCustSmplEml">
          <div className="ctnt" ref={sampleModalRef}>
            <Sample type={sampleType}
              onCancelClick={onSampleModalCloseClick}
              mode="embed"
              showNextPrev={false}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderContent = () => {
    const { reputation } = props.settings;
    if (!reputation || !reputation.feedback_settings) return null;
    const { feedback_settings } = reputation;

    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="titleBox">
              <h4>Run Campaign{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
            </div>
          </div>
          <div className="contentBox">
            <div className="typeBoxWrap">
              <TypeBox
                type="smiley"
                title="Smileys With Integrated Review Link"
                imgSrc={searchIcon}
                desc="An easy way to collect feedback using happy and sad smileys."
                onSelectClick={onTypeBoxSelect}
                onSampleClick={onViewSampleClick}
                active={feedback_settings.type === 'smiley'}
                selected={selectedType === 'smiley'}
                inProgress={selectedType === 'smiley' && feedback_settings.editInProgress}
                isReadOnly={props.isReadOnly}
              />
              <TypeBox
                type="stars"
                title="Stars With Integrated Review Link"
                imgSrc={searchIcon}
                desc="An easy way to collect feedback using stars."
                onSelectClick={onTypeBoxSelect}
                onSampleClick={onViewSampleClick}
                active={feedback_settings.type === 'stars'}
                selected={selectedType === 'stars'}
                inProgress={selectedType === 'stars' && feedback_settings.editInProgress}
                isReadOnly={props.isReadOnly}
              />
              <TypeBox
                type="nps"
                title="NPS"
                imgSrc={searchIcon}
                desc="Collect nps score without hassle."
                onSelectClick={onTypeBoxSelect}
                onSampleClick={onViewSampleClick}
                active={feedback_settings.type === 'nps'}
                selected={selectedType === 'nps'}
                inProgress={selectedType === 'nps' && feedback_settings.editInProgress}
              />
            </div>
          </div>
          { showSampleModal && renderSampleModal() }
        </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('FeedbackType selectedType: ', selectedType);
  }

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbCustTypPg">
        { renderContent() }
      </div>
    </div>
  );
}

export default FeedbackType;
