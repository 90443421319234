import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { validateUrl, validateEmail, STRS } from '../../../../helpers';

import avataarIcon from '../../../../img/icons/avataar.svg';

import './style.css';


function TransactionEmailEssentials(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [senderEmailOptions, setSenderEmailOptions] = useState([]);
  const [senderEmailValue, setSenderEmailValue] = useState([
    { label: 'Select', value: 'select' }
  ]);
  const [senderEmailError, setSenderEmailError] = useState('');

  const [senderNameOptions, setSenderNameOptions] = useState([]);
  const [senderNameValue, setSenderNameValue] = useState([
    { label: 'Select', value: 'select' }
  ]);
  const [senderNameError, setSenderNameError] = useState('');

  const [replyTo, setReplyTo] = useState('');
  const [replyToError, setReplyToError] = useState('');

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);


  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Marketing - Transaction Emails - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { engage, marketing } } = props;
    const { sender_emails, sender_names } = marketing;
    if (engage.te_sender_email__id && sender_emails && sender_emails.keyValue && sender_emails.keyValue[engage.te_sender_email__id]) {
      setSenderEmailValue({ label: sender_emails.keyValue[engage.te_sender_email__id].value, value: engage.te_sender_email__id });
      setSenderEmailError('');
    } else {
      setSenderEmailError('error');
    }
    if (engage.te_sender_name__id && sender_names && sender_names.keyValue && sender_names.keyValue[engage.te_sender_name__id]) {
      setSenderNameValue({ label: sender_names.keyValue[engage.te_sender_name__id].value, value: engage.te_sender_name__id });
      setSenderNameError('');
    } else {
      setSenderNameError('error');
    }
    if (sender_emails && sender_emails.list && sender_emails.list.length) {
      let tOptions = [];
      sender_emails.list.forEach((item) => {
        if (sender_emails.keyValue[item].verified_for_sending) {
          tOptions.push({
            label: sender_emails.keyValue[item].value,
            value: item,
          });
        }
      });
      setSenderEmailOptions(tOptions);
    }
    if (sender_names && sender_names.list && sender_names.list.length) {
      let tOptions = [];
      sender_names.list.forEach((item) => {
        tOptions.push({
          label: sender_names.keyValue[item].value,
          value: item,
        });
      });
      setSenderNameOptions(tOptions);
    }
    setReplyTo(engage.te_reply_to_email || '');
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.settings.engage.editInProgress &&
      props.settings.engage.editStatus &&
      props.settings.engage.editStatus.success
    ) {
      setFormChanged(false);
      setUpdatedValues({});
    }
  }, [props.settings.engage]);


  const onSenderEmailChange = (value) => {
    setSenderEmailValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      te_sender_email__id: value.value,
    }));
    setSenderEmailError(value.value === 'select' ? 'error' : '');
    setFormChanged(true);
  };
  const onSenderNameChange = (value) => {
    setSenderNameValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      te_sender_name__id: value.value,
    }));
    setSenderNameError(value.value === 'select' ? 'error' : '');
    setFormChanged(true);
  };

  const onReplyToChange = (e) => {
    const { name, value } = e.target;
    setReplyTo(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      te_reply_to_email: value,
    }));
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReplyToChange value: ', value);
    }
    if (value) {
      let tempArr = value.split(',');
      let tError = false;
      tempArr.forEach((item) => {
        if (!validateEmail(item.trim())) tError = true;
      });
      setReplyToError(!tError ? '' : 'Invalid email');
      // setReplyToError(validateEmail(value) ? '' : 'Invalid email');
    } else {
      setReplyToError('Required');
    }
    setFormChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    if (!senderEmailValue.value || senderEmailValue.value === 'select') {
      setSenderEmailError('error'); hasError = true;
    }
    if (!senderNameValue.value || senderNameValue.value === 'select') {
      setSenderNameError('error'); hasError = true;
    }
    if (replyTo) {
      let tempArr = replyTo.split(',');
      let tError = false;
      tempArr.forEach((item) => {
        if (!validateEmail(item.trim())) tError = true;
      });
      setReplyToError(!tError ? '' : 'Invalid email');
      // setReplyToError(validateEmail(replyTo) ? '' : 'Invalid email');
      if (tError) hasError = true;
    } else {
      setReplyToError('Required');
      hasError = true;
    }

    if (hasError) return;
    setSenderEmailError('');
    setSenderNameError('');

    const params = {
      location__id: urlParams.locationidentifier,
    };
    if ('te_sender_name__id' in updatedValues) params['te_sender_name__id'] = updatedValues.te_sender_name__id;
    if ('te_sender_email__id' in updatedValues) params['te_sender_email__id'] = updatedValues.te_sender_email__id;
    if ('te_reply_to_email' in updatedValues) params['te_reply_to_email'] = updatedValues.te_reply_to_email;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };

  const renderSenderEmail = () => {
    return (
      <>
        <div className="gBCtRw sndrEm">
          <div className="lt">
            <p className="title">Sender Email</p>
            <p className="desc">Select email to be used for sending guest journey emails. Sender emails can be added <Link to={`/settings/locations/${urlParams.locationidentifier}/sender-emails`}>here</Link>.</p>
            <Select
              options={senderEmailOptions}
              onChange={onSenderEmailChange}
              value={senderEmailValue}
              noOptionsMessage={() => 'No verified sender email available!'}
            />
            {
              senderEmailError &&
              <span className="error">Required</span>
            }
          </div>
        </div>
      </>
    );
  };

  const renderSenderName = () => {
    return (
      <>
        <div className="gBCtRw sndrNm">
          <div className="lt">
            <p className="title">Sender Name</p>
            <p className="desc">Select name to be used for sending guest journey emails. Sender names can be added <Link to={`/settings/locations/${urlParams.locationidentifier}/sender-names`}>here</Link>.</p>
            <Select
              options={senderNameOptions}
              onChange={onSenderNameChange}
              value={senderNameValue}
            />
            {
              senderNameError &&
              <span className="error">Required</span>
            }
          </div>
        </div>
      </>
    );
  };


  const renderReplyTo = () => {
    return (
      <div className="gBCtRw rplTo">
        <div className="lt">
          <p className="title">Reply To Email</p>
          <p className="desc">Enter reply to email here</p>
          <div className="gIpt">
            <input
              type="text"
              value={replyTo}
              onChange={onReplyToChange}
            />
          </div>
          {
            replyToError &&
            <span className="error">{replyToError}</span>
          }
        </div>
      </div>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('TransactionEmailEssentials: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Transaction Email Essentials{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gPSubTle">
          Set default sending settings.
        </div>
        <div className="gBCtnt">
          { renderSenderEmail() }
          { renderSenderName() }
          { renderReplyTo() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.settings.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default TransactionEmailEssentials;
