import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import tickIcon from '../../../../../img/icons/tick.svg';

import '../style.css';

function ResponseTemplateCategorySingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [title, setTitle] = useState('');

  const [titleError, setTitleError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const modalRef = useRef();
  const titleRef = useRef(null);

  useOnClickOutside(modalRef, useCallback(() => props.onCancelClick(false)));

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      let page_title = '';
      if (props.categoryType === 'responseTemplateCategory') page_title = `Settings - Reputation - Response Template Category - Single - ${locationName}`;
      if (props.templateType === 'promptTemplateCategory') page_title = `Settings - Reputation - Prompt Template Category - Single - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    const { categoryType } = props;
    if (reputation) {
      if (props.template && props.template._id) {
        setTitle(props.template.name);
      }
    }
    setSomeChange(false);
    const params = {
      location__id: urlParams.locationidentifier,
      mode: props.type,
    };
    let op = '';
    if (categoryType === 'responseTemplateCategory') {
      params.response_template_category_id = props.template._id;
      op = 'edit_response_template_category';
    }
    if (categoryType === 'promptTemplateCategory') {
      params.prompt_template_category_id = props.template._id;
      op = 'edit_prompt_template_category';
    }
    dispatch(editDataClearAction({
      op,
      params,
    }));
    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (props.categoryType === 'responseTemplateCategory') {
      if (props.type === 'edit') {
        if (props.template._id &&
          props.settings.reputation.response_template_categories &&
          props.settings.reputation.response_template_categories.keyValue &&
          props.settings.reputation.response_template_categories.keyValue[props.template._id] &&
          !props.settings.reputation.response_template_categories.keyValue[props.template._id].editInProgress &&
          props.settings.reputation.response_template_categories.keyValue[props.template._id].editStatus &&
          props.settings.reputation.response_template_categories.keyValue[props.template._id].editStatus.success
        ) {
          setSomeChange(false);
        }
      }
      if (props.type === 'add') {
        if (props.settings.reputation.response_template_categories &&
          !props.settings.reputation.response_template_categories.addInProgress &&
          props.settings.reputation.response_template_categories.addStatus &&
          props.settings.reputation.response_template_categories.addStatus.success
        ) {
          setTitle('');
          setSomeChange(false);
        }
      }
    }
  }, [props.settings.reputation.response_template_categories]);

  useEffect(() => {
    if (props.categoryType === 'promptTemplateCategory') {
      if (props.type === 'edit') {
        if (props.template._id &&
          props.settings.reputation.prompt_template_categories &&
          props.settings.reputation.prompt_template_categories.keyValue &&
          props.settings.reputation.prompt_template_categories.keyValue[props.template._id] &&
          !props.settings.reputation.prompt_template_categories.keyValue[props.template._id].editInProgress &&
          props.settings.reputation.prompt_template_categories.keyValue[props.template._id].editStatus &&
          props.settings.reputation.prompt_template_categories.keyValue[props.template._id].editStatus.success
        ) {
          setSomeChange(false);
        }
      }
      if (props.type === 'add') {
        if (props.settings.reputation.prompt_template_categories &&
          !props.settings.reputation.prompt_template_categories.addInProgress &&
          props.settings.reputation.prompt_template_categories.addStatus &&
          props.settings.reputation.prompt_template_categories.addStatus.success
        ) {
          setTitle('');
          setSomeChange(false);
        }
      }
    }
  }, [props.settings.reputation.prompt_template_categories]);

  const clearData = () => {
    const { categoryType } = props;
    if (props.type === 'edit') {
      const params = {
        location__id: urlParams.locationidentifier,
        mode: props.type,
      };
      let op = '';
      if (categoryType === 'responseTemplateCategory') {
        op = 'edit_response_template_category';
        params.response_template_category_id = props.template._id;
      }
      if (categoryType === 'promptTemplateCategory') {
        op = 'edit_prompt_template_category';
        params.prompt_template_category_id = props.template._id;
      }
      dispatch(editDataClearAction({
        op,
        params,
      }));
    }

    if (props.type === 'add') {
      let op = '';
      if (categoryType === 'responseTemplateCategory') {
        op = 'edit_response_template_category';
      }
      if (categoryType === 'promptTemplateCategory') {
        op = 'edit_prompt_template_category';
      }
      dispatch(editDataClearAction({
        op,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value); setSomeChange(true);
    clearData();

    if (!value) {
      setTitleError('error');
    } else {
      setTitleError('');
    }
  }

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    if (props.addEditInProgress) return;
    const { categoryType } = props;
    clearData();
    setTitleError(title ? '' : 'error');
    if (someChange) {
      if (props.type === 'add') {
        if (title) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            name: title,
          };
          let op = '';
          if (categoryType === 'responseTemplateCategory') {
            op = 'edit_response_template_category';
          }
          if (categoryType === 'promptTemplateCategory') {
            op = 'edit_prompt_template_category';
          }

          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
      if (props.type === 'edit') {
        if (title) {
          let op = '';
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            name: title,
          };
          if (categoryType === 'responseTemplateCategory') {
            op = 'edit_response_template_category';
            params.response_template_category_id = props.template._id;
          }
          if (categoryType === 'promptTemplateCategory') {
            op = 'edit_prompt_template_category';
            params.prompt_template_category_id = props.template._id;
          }
          dispatch(editDataAction({
            op,
            params,
          }));
        }
      }
    }
  };


  const renderContent = () => {
    const { locationidentifier } = urlParams;
    const { categoryType } = props;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.reputation) return null;

    let tData = {};

    if (props.type === 'edit') {
      if (categoryType === 'responseTemplateCategory') {
        if (!settings.reputation.response_template_categories || !settings.reputation.response_template_categories.keyValue) return null;
        tData = settings.reputation.response_template_categories.keyValue[props.template._id] || {};
      }
      if (categoryType === 'promptTemplateCategory') {
        if (!settings.reputation.prompt_template_categories || !settings.reputation.prompt_template_categories.keyValue) return null;
        tData = settings.reputation.prompt_template_categories.keyValue[props.template._id] || {};
      }
    }

    let pageTitle = '';
    if (categoryType === 'responseTemplateCategory') {
      if (props.type === 'add') pageTitle = `Add Response Template Category`;
      if (props.type === 'edit') pageTitle = 'Edit Response Template Category';
    }
    if (categoryType === 'promptTemplateCategory') {
      if (props.type === 'add') pageTitle = `Add guestAI™ Template Category`;
      if (props.type === 'edit') pageTitle = 'Edit guestAI™ Template Category';
    }
    if (props.title) pageTitle = props.title;


    let addEditInProgress = false;
    let addEditStatus = {};

    if (categoryType === 'responseTemplateCategory') {
      addEditInProgress = settings.reputation.response_template_categories ? settings.reputation.response_template_categories.addInProgress : false;
      addEditStatus = settings.reputation.response_template_categories ? settings.reputation.response_template_categories.addStatus : {};
    }
    if (categoryType === 'promptTemplateCategory') {
      addEditInProgress = settings.reputation.prompt_template_categories ? settings.reputation.prompt_template_categories.addInProgress : false;
      addEditStatus = settings.reputation.prompt_template_categories ? settings.reputation.prompt_template_categories.addStatus : {};
    }

    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }


    return (
      <div className="gPWrp">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Name
              </div>
              <div className={'gIpt ' + (titleError ? 'error' : '')}>
                <input type="text" value={title} onChange={onTitleChange} placeholder="Title" ref={titleRef} />
                { titleError && <p className="gErr">Title is required</p> }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('ResponseTemplateCategorySingle props: ', props);
  }

  return renderContent();
}

export default ResponseTemplateCategorySingle;
