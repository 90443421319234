// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import { OTA_ID_TO_NAME_FORMAL_MAP, renderOTALogo, OTA_NAME_TO_SOURCE_MAP, 
  replaceAll, changeInValuePercent } from '../../../../../helpers';

import moreIcon from '../../../../../img/icons/more.svg';
import searchIcon from '../../../../../img/icons/search.svg';
import downloadIcon from '../../../../../img/icons/download.svg';

import './style.css';

const download = require('downloadjs');


function SortIndicator(props) {
  return (
    <div className="sortIndicator">
      <div className={'arrow-up' + (props.sortOrder === 'decreasing' ? ' active' : '')}></div>
      <div className={'arrow-down' + (props.sortOrder === 'increasing' ? ' active' : '')}></div>
    </div>
  );
}

function LocationListCard(props) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentSort, setCurrentSort] = useState('');
  const [sortOrderName, setSortOrderName] = useState('');
  const [sortOrderReviewCount, setSortOrderReviewCount] = useState('');
  const [sortOrderAverageRating, setSortOrderAverageRating] = useState('');
  const [sortOrderResponseRate, setSortOrderResponseRate] = useState('');
  const [sortOrderSentimentScore, setSortOrderSentimentScore] = useState('');

  const onCtaClick = (e) => {
    props.onCtaClick();
  };

  const onNameSortClick = (e) => {
    if (!sortOrderName) { setSortOrderName('decreasing'); }
    else { setSortOrderName(sortOrderName === 'decreasing' ? 'increasing' : 'decreasing'); }
    setCurrentSort('name');
  };
  const onReviewCountSortClick = (e) => {
    if (!sortOrderReviewCount) { setSortOrderReviewCount('decreasing'); }
    else { setSortOrderReviewCount(sortOrderReviewCount === 'decreasing' ? 'increasing' : 'decreasing'); }
    setCurrentSort('reviewCount');
  };
  const onAverageRatingSortClick = (e) => {
    if (!sortOrderAverageRating) { setSortOrderAverageRating('decreasing'); }
    else { setSortOrderAverageRating(sortOrderAverageRating === 'decreasing' ? 'increasing' : 'decreasing'); }
    setCurrentSort('averageRating');
  };
  const onResponseRateSortClick = (e) => {
    if (!sortOrderResponseRate) { setSortOrderResponseRate('decreasing'); }
    else { setSortOrderResponseRate(sortOrderResponseRate === 'decreasing' ? 'increasing' : 'decreasing'); }
    setCurrentSort('responseRate');
  };
  const onSentimentScoreSortClick = (e) => {
    if (!sortOrderSentimentScore) { setSortOrderSentimentScore('decreasing'); }
    else { setSortOrderSentimentScore(sortOrderSentimentScore === 'decreasing' ? 'increasing' : 'decreasing'); }
    setCurrentSort('sentimentScore');
  };

  const onKeywordChange = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };
  const onSearchInputKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSearchKeyword('');
    }
  };

  const onDownloadClick = (e) => {
    const { locations } = props;
    if (!locations) return;
    if (locations.length) {
      let csv_headers = [ 'Name', 'Total Reviews', 'Average Rating', 'Response Rate', 'Sentiment Score' ];
      let csvData = '';
      csvData = csv_headers.join(',') + '\r\n';
      locations.forEach((locInfo) => {
        if (locInfo) {
          let totalReviews = props.stats && props.stats[locInfo._id] ? props.stats[locInfo._id].review_count || '-' : '-';
          let averageRating = props.stats && props.stats[locInfo._id] ? props.stats[locInfo._id].average_rating || '-' : '-';
          let responseRate = props.stats && props.stats[locInfo._id] ? props.stats[locInfo._id].response_rate || '-' : '-';
          let sentimentScore = props.stats && props.stats[locInfo._id] ? props.stats[locInfo._id].sentiment_score || '-' : '-';
          let entry = [
                locInfo.name,
                totalReviews,
                averageRating,
                responseRate,
                sentimentScore
          ];
          if (entry) { csvData += entry.join(',') + '\r\n'; }
        }
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log('csvData: ', csvData);
      }
      download(new Blob([csvData]), props.csvFileName, 'text/plain');
    }
  };

  const { label, labelToolTip, ctaLabel, ctaLink } = props.data;
  const { compareStats, compareNow } = props;

  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationListCard currentSort: ', currentSort);
    console.log('LocationListCard sortOrderName: ', sortOrderName);
    console.log('LocationListCard sortOrderReviewCount: ', sortOrderReviewCount);
  }

  return (
    <div className="gLocationListCard">
      <div className="top">
        <p>
          {label}
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay={labelToolTip}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </p>
        <div className="dwnldCsv" onClick={onDownloadClick}>
          <img src={downloadIcon} />
          <p className="download">Download CSV</p>
          {/*
            reviews && reviews.getReviewsDumpInProgress &&
            <span>
              <i className="fa fa-spinner fa-spin" />
            </span>
          */}
        </div>
      </div>
      <div className="searchBox">
        <div className="inputBox">
          <img src={searchIcon} />
          <input type="text" name="keyword" value={searchKeyword} onChange={onKeywordChange}
            placeholder="Search location"
            onKeyDown={onSearchInputKeyDown}
          />
        </div>
      </div>
      <div className="middle">
        <div className="entry header">
          <div className="name" onClick={onNameSortClick}>Location<SortIndicator sortOrder={sortOrderName}/></div>
          <div className="value totalReviews" onClick={onReviewCountSortClick}>Total Reviews<SortIndicator sortOrder={sortOrderReviewCount}/></div>
          <div className="value averageRating" onClick={onAverageRatingSortClick}>Average Rating<SortIndicator sortOrder={sortOrderAverageRating}/></div>
          <div className="value responseRate" onClick={onResponseRateSortClick}>Response Rate<SortIndicator sortOrder={sortOrderResponseRate}/></div>
          <div className="value sentimentScore"onClick={onSentimentScoreSortClick}>Sentiment Score<SortIndicator sortOrder={sortOrderSentimentScore}/></div>
        </div>
        {
          props.locations && props.locations.length
          ?
            props.locations.filter((item) => {
              if (searchKeyword) {
                if (new RegExp(searchKeyword, 'i').test(item.name) ||
                  new RegExp(searchKeyword, 'i').test(item.address) ||
                  new RegExp(searchKeyword, 'i').test(item.location_id) ||
                  new RegExp(searchKeyword, 'i').test(item.serverid)
                ) {
                  return true
                }
                return false;
              }
              return true;
            }).sort((a,b) => {
              if (!currentSort) return 0;
              let aValue = '', bValue = '';
              let sortOrderValue = '';
              if (currentSort === 'name') {
                aValue = a.name; bValue = b.name;
                sortOrderValue = sortOrderName;
              }
              if (currentSort === 'reviewCount') {
                aValue = props.stats && props.stats[a._id] ? props.stats[a._id].review_count || 0 : 0;
                bValue = props.stats && props.stats[b._id] ? props.stats[b._id].review_count || 0 : 0;
                sortOrderValue = sortOrderReviewCount;
              }
              if (currentSort === 'averageRating') {
                aValue = props.stats && props.stats[a._id] ? props.stats[a._id].average_rating || 0 : 0;
                bValue = props.stats && props.stats[b._id] ? props.stats[b._id].average_rating || 0 : 0;
                sortOrderValue = sortOrderAverageRating;
              }
              if (currentSort === 'responseRate') {
                aValue = props.stats && props.stats[a._id] && props.stats[a._id].response_rate ? parseFloat(replaceAll(props.stats[a._id].response_rate, '%', '')) || 0 : 0;
                bValue = props.stats && props.stats[b._id] && props.stats[b._id].response_rate ? parseFloat(replaceAll(props.stats[b._id].response_rate, '%', '')) || 0 : 0;
                sortOrderValue = sortOrderResponseRate;
              }
              if (currentSort === 'sentimentScore') {
                aValue = props.stats && props.stats[a._id] ? props.stats[a._id].sentiment_score || 0 : 0;
                bValue = props.stats && props.stats[b._id] ? props.stats[b._id].sentiment_score || 0 : 0;
                sortOrderValue = sortOrderSentimentScore;
              }
              if (process.env.NODE_ENV !== 'production') {
                console.log('aValue: ', aValue, ' # a: ', a);
                console.log('bValue: ', bValue, ' # b: ', b);
              }
              if (sortOrderValue === 'increasing') {
                if (aValue > bValue) return -1;
                if (bValue > aValue) return 1;
              }
              if (sortOrderValue === 'decreasing') {
                if (aValue > bValue) return 1;
                if (bValue > aValue) return -1;
              }
              return 0;
            }).map((item) => {
              return (
                <div className="entry" key={`llc_${item._id}`}>
                  <div className="openNewTab"><Link to={`/reputation/locations/${item._id}/dashboard`} target="_blank"><span><i className="fas fa-external-link-alt"></i></span></Link></div>
                  <div className="name">{ item.name }</div>
                  <div className="value totalReviews">
                    {props.stats && props.stats[item._id] ? props.stats[item._id].review_count || '-' : '-'}
                    {
                      props.stats && props.stats[item._id] && props.stats[item._id].review_count
                      ?
                      <>
                        { compareNow && compareStats && compareStats[item._id] && compareStats[item._id].review_count ? <p className='cmpVal'>vs {compareStats[item._id].review_count}</p> : null }
                        { compareNow && compareStats && compareStats[item._id] && compareStats[item._id].review_count ? <p className='cmpChng'>{changeInValuePercent(props.stats[item._id].review_count, compareStats[item._id].review_count)}</p> : null }
                      </>
                      : null
                    }
                  </div>
                  <div className="value averageRating">
                    {props.stats && props.stats[item._id] ? props.stats[item._id].average_rating || '-' : '-'}
                    {
                      props.stats && props.stats[item._id] && props.stats[item._id].average_rating
                      ?
                      <>
                        { compareNow && compareStats && compareStats[item._id] && compareStats[item._id].average_rating ? <p className='cmpVal'>vs {compareStats[item._id].average_rating}</p> : null }
                        { compareNow && compareStats && compareStats[item._id] && compareStats[item._id].average_rating ? <p className='cmpChng'>{changeInValuePercent(props.stats[item._id].average_rating, compareStats[item._id].average_rating)}</p> : null }
                      </>
                      : null
                    }
                  </div>
                  <div className="value responseRate">{props.stats && props.stats[item._id] ? props.stats[item._id].response_rate || '-' : '-'}</div>
                  <div className="value sentimentScore">
                    {props.stats && props.stats[item._id] ? props.stats[item._id].sentiment_score || '-' : '-'}
                    {
                      props.stats && props.stats[item._id] && props.stats[item._id].sentiment_score
                      ?
                      <>
                        { compareNow && compareStats && compareStats[item._id] && compareStats[item._id].sentiment_score ? <p className='cmpVal'>vs {compareStats[item._id].sentiment_score}</p> : null }
                        { compareNow && compareStats && compareStats[item._id] && compareStats[item._id].sentiment_score ? <p className='cmpChng'>{changeInValuePercent(props.stats[item._id].sentiment_score, compareStats[item._id].sentiment_score)}</p> : null }
                      </>
                      : null
                    }
                  </div>
                </div>
              );
            })
          : null
        }
      </div>
      <div className="footer">

      </div>
    </div>
  );
}

export default LocationListCard;
