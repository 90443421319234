import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import copy from 'copy-to-clipboard';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import WCPreview from '../WCPreview';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../../redux/actions/snacksActions';
import { LAUNCHER_POSITIONS, LAUNCHER_POSITION_LABELS, LAUNCHER_POSITION_KEY_TO_LABELS, STRS } from '../../../../../helpers';

import copyIcon from '../../../../../img/icons/copy.svg';

import '../style.css';

const positionOptions = [];
positionOptions.push({ label: LAUNCHER_POSITION_LABELS.BOTTOM_RIGHT, value: LAUNCHER_POSITIONS.BOTTOM_RIGHT });
positionOptions.push({ label: LAUNCHER_POSITION_LABELS.BOTTOM_LEFT, value: LAUNCHER_POSITIONS.BOTTOM_LEFT });

function ThemeOption(props) {
  const onThemeClick = (e) => { props.onThemeClick(props.theme); };
  let cStyle = {};
  cStyle['borderColor'] = props.selected ? props.color : '#FFFFFF';
  let innerStyle = {};
  innerStyle['backgroundColor'] = props.color;
  return (
    <div className={'themeOption ' + (props.selected ? 'selected' : '')} style={cStyle} onClick={onThemeClick}>
      <div className={'inner ' + (props.theme)} style={innerStyle} >
      </div>
    </div>
  );
}

function WCDeploy(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [isOn, setIsOn] = useState(false);
  const [isOnChanged, setIsOnChanged] = useState(false);

  const [scriptTag, setScriptTag] = useState('');
  const scriptTagRef = useRef(null);

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Engage - WebChat - Deploy - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { webchat } } = props;
    if (webchat) {
      if (webchat.deploy) {
        setIsOn(webchat.deploy.is_active ? true : false);
        setScriptTag(webchat.deploy.script_tag ? webchat.deploy.script_tag || '' : '');
      }
    }
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (!props.settings.webchat.editInProgress &&
      props.settings.webchat.editStatus &&
      props.settings.webchat.editStatus.success
    ) {
      setFormChanged(false);
    }
  }, [props.settings.webchat]);

  const onOnOffToggleChange = (e) => {
    const { name, checked } = e.target;
    setIsOn(checked);
    setIsOnChanged(true);
    setFormChanged(true);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOnOffToggleChange checked: ', checked);
    }
  };

  const onCopyScriptClick = (e) => {
    scriptTagRef.current.select();
    copy(scriptTag);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { webchat } = props.settings;
    if (webchat && webchat.deploy && webchat.deploy.editInProgress) return;

    let hasError = false;

    if (!isOnChanged) return;

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
      op: 'edit-deploy',
      deploy: {},
    };
    if (isOnChanged) {
      params.deploy.is_active = isOn;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_webchat_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };

  const renderWebChatOnOff = () => {
    return (
      <div className="gBCtRw hasTgl">
        <div className="lt">
          <p className="title">WebChat</p>
          <p className="desc">{ isOn ? "Switch the webchat off that is deployed on your website" : "Switch the webchat on that is deployed on your website"}</p>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id="isOn" checked={isOn} onChange={onOnOffToggleChange}/><label htmlFor="isOn">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCopyScript = () => {
    return (
      <div className="gBCtRw dplyScrpt">
        <div className="lt">
          <p className="title">Webchat Script
          </p>
          <p className="desc">Copy the WebChat script and insert it in the head section of your website.</p>
          <div className="copyScript">
            <div className="inner" onClick={onCopyScriptClick}>
              <img src={copyIcon} />Copy Script
            </div>
          </div>
          <div className="gIpt">
            <textarea
              ref={scriptTagRef}
              readOnly
              value={scriptTag}
              rows="3"
            />
          </div>
        </div>
      </div>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('WCDeploy: ', props, ' # ', props);
  }

  const { webchat } = props.settings

  return (
    <div className="gPScrlWrp hDGen flHt wCBasic">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Deploy{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderWebChatOnOff() }
          { renderCopyScript() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  webchat && webchat.deploy && webchat.deploy.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
      <div className="webChatPreviewWrap">
        <h4 className="gPageTitle title">
          Preview
          <ToolTipWrapper placement="bottom" overlayClassName={'sbToolTip'}
            overlay="A display only preview of WebChat"
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </h4>
        <div className="previewBox">
          <WCPreview
            screen={"home"}
            settings={props.settings}
            data={{
              theme: 'theme_4',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WCDeploy;
