// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { Interweave } from 'interweave';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';

import { renderOTALogo, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP} from '../../../../../../helpers';
import { STRS, getUserGAId } from '../../../../../../helpers';

import './style.css';

const gtconfig = require('../../../../../../gtconfig');

function GenericHotelDataSingle(props) {
  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={props.onMEnter} onMouseLeave={props.onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          { props.desc && <label>{props.desc}</label> }
          <div className="gIpt">
            {
              props.type === "textarea"
              ?
                <textarea
                  className="gInput"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
              :
                <input
                  className="gInput"
                  type="text"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
            }
          </div>
          {
            props.error &&
            <span className="gErr">{props.error}</span>
          }
        </div>
      </div>
    </div>
  );
}

function FeedbackNegativeSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const urlParams = useParams();
  const location = useLocation();

  const [negativeTitle, setNegativeTitle] = useState('');
  const [negativeTitleError, setNegativeTitleError] = useState('');
  const [highlightNegativeTitle, setHighlightNegativeTitle] = useState(false);

  const [negativePrimaryMessage, setNegativePrimaryMessage] = useState('');
  const [negativePrimaryMessageError, setNegativePrimaryMessageError] = useState('');
  const [highlightNegativePrimaryMessage, setHighlightNegativePrimaryMessage] = useState(false);

  const [hotelMessage, setHotelMessage] = useState('');
  const [hotelMessageError, setHotelMessageError] = useState('');
  const [highlightHotelMessage, setHighlightHotelMessage] = useState(false);

  const [aQEnabled, setAQEnabled] = useState(false);
  const [aQEnabledChanged, setAQEnabledChanged] = useState(false);

  const [sFEnabled, setSFEnabled] = useState(false);
  const [sFEnabledChanged, setSFEnabledChanged] = useState(false);

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Negative - Single - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'edit_feedback_settings',
      params,
    }));
  };

  useEffect(() => {
    clearAction();
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    if (reputation && reputation.feedback_settings) {
      const { feedback_settings } = reputation;
      if (feedback_settings) {
        if (!feedback_settings.editInProgress && feedback_settings.editStatus && feedback_settings.editStatus.success) {
          setUpdatedValues(updatedValues => ({
          }));
          setSFEnabledChanged(false);
          setAQEnabledChanged(false);
          clearAction();
        }
      }
    }
  }, [props.settings])

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    const { reputation } = props.settings;
    if (!reputation || !reputation.feedback_settings) return null;
    const { feedback_settings } = reputation;

    setNegativeTitle(feedback_settings.negative_single_feedback_title || '');
    setNegativePrimaryMessage(feedback_settings.negative_single_feedback_primary_message || '');
    setHotelMessage(feedback_settings.negative_single_feedback_hotel_message || '');

    setSFEnabled(feedback_settings.negative_single_feedback_enabled || false);
    setAQEnabled(feedback_settings.negative_questions_enabled || false);

    setFormChanged(false);
  };

  const onSFEnabledChange = (e) => {
    setSFEnabled(e.target.checked);
    setSFEnabledChanged(true);
    setFormChanged(true);
  };

  const onAQEnabledChange = (e) => {
    setAQEnabled(e.target.checked);
    setAQEnabledChanged(true);
    setFormChanged(true);
  };

  const onNegativeTitleChange = (e) => {
    const { name, value } = e.target;
    setNegativeTitle(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      negative_single_feedback_title: value,
    }));
    // setNegativeTitleError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onNegativeTitleMouseEnter = (e) => {
    setHighlightNegativeTitle(true);
  };
  const onNegativeTitleMouseLeave = (e) => {
    setHighlightNegativeTitle(false);
  };

  const onNegativePrimaryMessageChange = (e) => {
    const { name, value } = e.target;
    setNegativePrimaryMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      negative_single_feedback_primary_message: value,
    }));
    // setNegativeTitleError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onNegativePrimaryMessageMouseEnter = (e) => {
    setHighlightNegativePrimaryMessage(true);
  };
  const onNegativePrimaryMessageMouseLeave = (e) => {
    setHighlightNegativePrimaryMessage(false);
  };

  const onHotelMessageChange = (e) => {
    const { name, value } = e.target;
    setHotelMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      negative_single_feedback_hotel_message: value,
    }));
    // setHotelMessageError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelMessageMouseEnter = (e) => {
    setHighlightHotelMessage(true);
  };
  const onHotelMessageMouseLeave = (e) => {
    setHighlightHotelMessage(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    // setNegativeTitleError(negativeTitle ? '' : 'Required');
    // if (!negativeTitle) hasError = true;

    // setHotelMessageError(hotelMessage ? '' : 'Required');
    // if (!hotelMessage) hasError = true;

    if (hasError) return;

    const params = {};
    params.location__id = urlParams.locationidentifier;

    if ('negative_single_feedback_title' in updatedValues) params['negative_single_feedback_title'] = updatedValues.negative_single_feedback_title;
    if ('negative_single_feedback_primary_message' in updatedValues) params['negative_single_feedback_primary_message'] = updatedValues.negative_single_feedback_primary_message;
    if ('negative_single_feedback_hotel_message' in updatedValues) params['negative_single_feedback_hotel_message'] = updatedValues.negative_single_feedback_hotel_message;

    if (sFEnabledChanged) params.negative_single_feedback_enabled = sFEnabled;
    if (aQEnabledChanged) params.negative_questions_enabled = aQEnabled;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_feedback_settings',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
      show_alert_timeout: 2000,
      show_error_alert: true,
      error_alert_msg: 'Error in updating',
    }));
    setFormChanged(false);
  };

  const renderNegativeTitle = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="negativeTitle"
        title="Title"
        desc=""
        type="textarea"
        value={negativeTitle}
        onChange={onNegativeTitleChange}
        error={negativeTitleError}
        onMEnter={onNegativeTitleMouseEnter}
        onMLeave={onNegativeTitleMouseLeave}
      />
    );
  };

  const renderNegativePrimaryMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="negativePrimaryMessage"
        title="Primary Message"
        desc=""
        type="textarea"
        value={negativePrimaryMessage}
        onChange={onNegativePrimaryMessageChange}
        error={negativeTitleError}
        onMEnter={onNegativePrimaryMessageMouseEnter}
        onMLeave={onNegativePrimaryMessageMouseLeave}
      />
    );
  };

  const renderHotelMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelMessage"
        title="Secondary Message"
        desc=""
        type="textarea"
        value={hotelMessage}
        onChange={onHotelMessageChange}
        error={hotelMessageError}
        onMEnter={onHotelMessageMouseEnter}
        onMLeave={onHotelMessageMouseLeave}
      />
    );
  };

  const renderCollectSingleFeedbackEnabled = (feedback_settings) => {
    return (
      <div className="enabledRow">
        <div className="left">
          Collect single feedback ?
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={`sf_enabled`} checked={sFEnabled} onChange={onSFEnabledChange}/><label htmlFor={`sf_enabled`}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAdditionalQuestionsEnabled = (feedback_settings) => {
    return (
      <div className="enabledRow">
        <div className="left">
          Show questions ?
        </div>
        <div className="right">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={`aq_enabled`} checked={aQEnabled} onChange={onAQEnabledChange}/><label htmlFor={`aq_enabled`}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const renderSave = (feedback_settings) => {
    if (props.isReadOnly) {
      return (
        <div className="gBCtRw rdOlyRw stndAln">
          <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
        </div>
      );
    }
    return (
      <div className="gSettingsRow gSaveButton">
        <button
          type="submit"
          className={'gBtn' + (formChanged ? '' : ' disabled')}
        >
          Save Changes
          { feedback_settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }
        </button>
      </div>
    );
  };

  const renderSettings = (feedback_settings) => {
    return (
      <>
        <form onSubmit={onSubmit}>
          { renderNegativeTitle(feedback_settings) }
          { renderNegativePrimaryMessage(feedback_settings) }
          { renderHotelMessage(feedback_settings) }
          { renderSave(feedback_settings) }
        </form>
      </>
    );
  };

  const renderPreview = (feedback_settings, engage_settings, location_info) => {
    return (
      <div className="emailPreviewWrap positiveFeedback">
        <p>Negative Feedback Screen Preview</p>
        {/*<div className="devicesBox">
          <span>Desktop</span>
          <span>Mobile</span>
        </div>*/}
        <div className="emailPreviewBox">
          <div className="emailBodyBox">
            <div className="headerBox">
              { engage_settings.hotel_logo_url && <img src={engage_settings.hotel_logo_url} /> }
              <p>{engage_settings.hotel_name || ''}</p>
            </div>
            <div className="middleBox">
              <p className={'negativeTitle' + (highlightNegativeTitle ? ' highlight' : '')}><Interweave content={negativeTitle} /></p>
              <p className={'primaryMessage' + (highlightNegativePrimaryMessage ? ' highlight' : '')}><Interweave content={negativePrimaryMessage} /></p>
              <textarea
                rows="4"
              />
            </div>
            <div className="footerBox">
              <p className={'hotelMessage' + (highlightHotelMessage ? ' highlight' : '')}><Interweave content={hotelMessage} /></p>
              <p className="address">{engage_settings.hotel_address || ''}</p>
              <p className="contact">
                <span className="link">{engage_settings.hotel_number || ''}</span>
                <span className="link">{engage_settings.hotel_website || ''}</span>
              </p>
              {
                gtconfig.IS_GT &&
                <p className="poweredBy">
                  Powered by <span className="link">{gtconfig.APP_NAME_CONTACT}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = (feedback_settings, engage_settings, location_info) => {
    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="tabBox">
              <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/feedback-negative`}>Feedback Negative</Link>
              <Link className="active" to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/negative-single`}>Single Feedback</Link>
              <Link to={`/reputation/locations/${urlParams.locationidentifier}/feedbacks/customize-feedbacks/negative-questions`}>Questions</Link>
            </div>
            <div className="titleBox">
              <h4>Single Feedback{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
            </div>
          </div>
          <div className="contentBox">
            <div className="left">
              { renderSettings(feedback_settings) }
            </div>
            <div className="right">
              { renderPreview(feedback_settings, engage_settings, location_info) }
            </div>
          </div>
        </div>
    )
  }


  if (process.env.NODE_ENV !== 'production') {
    console.log('FeedbackNegativeSingle props: ', props);
  }

  const { locationInfo } = props;
  const { reputation, engage } = props.settings;
  if (!reputation || !reputation.feedback_settings) return null;
  const { feedback_settings } = reputation;

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbPosPg">
        { renderContent(feedback_settings, engage || {}, locationInfo) }
      </div>
    </div>
  );

}

export default FeedbackNegativeSingle;
