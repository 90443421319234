import { genericItemTypeToKeyName } from '../../helpers';

// export const initSettings = (state, action) => {
//   const { params: { location__id } } = action.payload;
//   if (!location__id) return state;
//
//   let settings = state.locations[location__id] && state.locations[location__id].settings
//     ? { ...state.locations[location__id].settings }
//     : {
//         reputation: {},
//         engage: {},
//         marketing: {},
//       };
//   return {
//     ...state,
//     locations: {
//       ...state.locations,
//       [location__id]: state.locations[location__id]
//       ?
//         {
//           ...state.locations[location__id],
//           settings: {
//             ...settings,
//           },
//         }
//       :
//         {
//           settings: {
//             ...settings,
//           }
//         },
//     },
//   };
//   return state;
// };

/***************************************** Get Groups ****************************/

export const getGroupsClear = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    groups: {
      ...state.groups,
      getInProgress: false, getStatus: {},
    },
  };
};

export const getGroups = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    groups: {
      ...state.groups,
      getInProgress: true, getStatus: {},
    },
  };
};

export const getGroupsSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { groups, shared_groups } = data;
  if (!Object.keys(groups).length) {
    return {
      ...state,
      groups: {
        ...state.groups,
        list: [],
        keyValue: {},
        users: [],
        getInProgress: false,
        getStatus: {
          success: true,
        },
      },
      shared_groups,
    };
  }
  return {
    ...state,
    groups: {
      ...state.groups,
      ...groups,
      getInProgress: false,
      getStatus: {
        success: true,
      },
    },
    shared_groups,
  };
};

export const getGroupsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      getInProgress: false,
      getStatus: {
        error: true,
      },
      error,
      errorMessage,
    },
  };
};


/***************************************** Get Groups Summary Data ****************************/

export const getGroupsSummaryData = (state, action) => {
  const { params } = action.payload;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: state.groups.keyValue
        ? { ...state.groups.keyValue }
        : {
            default: {},
          },
      getSummaryDataInProgress: true, getSummaryDataStatus: {},
    },
  };
};

export const getGroupsSummaryDataSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { groups_summary, shared_groups_summary } = data;
  let tempKeyValue = state.groups.keyValue ? { ...state.groups.keyValue } : {};
  if (Object.keys(groups_summary).length) {
    Object.keys(groups_summary).forEach((item) => {
      if (!tempKeyValue[item]) tempKeyValue[item] = {};
      tempKeyValue[item].summary = { ...groups_summary[item] };
    });
  }
  let sharedGroupKeyValue = state.shared_groups.keyValue ? { ...state.shared_groups.keyValue } : {};
  if (Object.keys(shared_groups_summary).length) {
    Object.keys(shared_groups_summary).forEach((item) => {
      if (!sharedGroupKeyValue[item]) sharedGroupKeyValue[item] = {};
      sharedGroupKeyValue[item].summary = { ...shared_groups_summary[item] };
    });
  }
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...tempKeyValue,
      },
      getSummaryDataInProgress: false,
      getSummaryDataStatus: {
        success: true,
      },
    },
    shared_groups: {
      ...state.shared_groups,
      keyValue: {
        ...sharedGroupKeyValue,
      },
    },
  };
};

export const getGroupsSummaryDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      getSummaryDataInProgress: false,
      getSummaryDataStatus: {
        error: true,
      },
      error,
      errorMessage,
    },
  };
};


/***************************************** Get Groups Dashboard Data ****************************/

export const getGroupDashboardData = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          getDashboardDataInProgress: true,
          getDashboardDataStatus: {},
        }
      },
    },
  };
};

export const getGroupDashboardDataSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { group_id } = params;
  if (!group_id) return state;
  const { dashboard } = data;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          dashboard,
          getDashboardDataInProgress: false,
          getDashboardDataStatus: {
            success: true,
          },
        }
      },
    },
  };
};

export const getGroupDashboardDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id } = params;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          getDashboardDataInProgress: false,
          getDashboardDataStatus: {
            error: true,
          },
          error,
          errorMessage,
        }
      },
    },
  };
};


/***************************************** Get Groups Reviews Data ****************************/

export const getGroupReviewsData = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: state.groups.keyValue[group_id].reviews
            ?
              {
                ...state.groups.keyValue[group_id].reviews,
                getInProgress: true, getStatus: {},
              }
            : {
              getInProgress: true, getStatus: {},
             },
        },
      },
    },
  };
};

export const getGroupReviewsDataSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { group_id } = params;
  if (!group_id) return state;
  let { reviews_data } = data;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            ...reviews_data,
            getInProgress: false,
            getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getGroupReviewsDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id } = params;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            getInProgress: false,
            getStatus: {
              error: true,
            },
            error,
            errorMessage,
          },
        }
      },
    },
  };
};



/***************************************** Get Groups Sentiments Data ****************************/

export const getGroupSentimentsData = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          sentiments: state.groups.keyValue[group_id].sentiments
            ?
              {
                ...state.groups.keyValue[group_id].sentiments,
                getInProgress: true, getStatus: {},
              }
            : {
              getInProgress: true, getStatus: {},
             },
        },
      },
    },
  };
};

export const getGroupSentimentsDataSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { group_id } = params;
  if (!group_id) return state;
  let { sentiments_data } = data;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          sentiments: {
            ...state.groups.keyValue[group_id].sentiments,
            ...sentiments_data,
            getInProgress: false,
            getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getGroupSentimentsDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id } = params;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          sentiments: {
            ...state.groups.keyValue[group_id].sentiments,
            getInProgress: false,
            getStatus: {
              error: true,
            },
            error,
            errorMessage,
          },
        }
      },
    },
  };
};


/* *************** Start - Edit Group Review **************** */

export const editGroupReviewClear = (state, action) => {
  const { params: { group_id, location__id, review__id, edit_type } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                editInProgress: false,
                editStatus: {},
                editApproveInProgress: edit_type === 'approve' ? false : undefined,
                editMoreInfoInProgress: edit_type === 'more-info' ? false : undefined,
                editSaveInProgress: edit_type === 'save' ? false : undefined,
                editSaveStatus: {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? false : undefined,
                editSaveAndRespondStatus: {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? false : undefined,
                editRedirectToOtaStatus: {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? false : undefined,
                editRespondedOnOtaStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const editGroupReview = (state, action) => {
  const { params: { group_id, location__id, review__id, edit_type } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                editInProgress: true,
                editStatus: {},
                editApproveInProgress: edit_type === 'approve' ? true : undefined,
                editMoreInfoInProgress: edit_type === 'more-info' ? true : undefined,
                editSaveInProgress: edit_type === 'save' ? true : undefined,
                editSaveStatus: {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? true : undefined,
                editSaveAndRespondStatus: {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? true : undefined,
                editRedirectToOtaStatus: {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? true : undefined,
                editRespondedOnOtaStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const editGroupReviewSuccess = (state, action) => {
  const { data, params: { group_id, location__id, review__id, edit_type } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  const { updated_review } = data;

  let reviewStateSplit = state.groups.keyValue[group_id] && state.groups.keyValue[group_id].reviews
    && state.groups.keyValue[group_id].reviews.review_state_split
    ? state.groups.keyValue[group_id].reviews.review_state_split
    : {};
  if (edit_type === 'more-info') {
    if (reviewStateSplit.need_more_info) {
      reviewStateSplit = {
        ...reviewStateSplit,
        need_more_info: reviewStateSplit.need_more_info - 1,
      };
    }
  }
  if (edit_type === 'responded-on-ota') {
    if (reviewStateSplit.response_required) {
      reviewStateSplit = {
        ...reviewStateSplit,
        response_required: reviewStateSplit.response_required ? reviewStateSplit.response_required - 1 : reviewStateSplit.response_required,
        response_posted: reviewStateSplit.response_posted ? reviewStateSplit.response_posted + 1 : 1,
      };
    }
  }

  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                ...updated_review,
                editApproveInProgress: edit_type === 'approve' ? false : undefined,
                editApproveStatus: edit_type === 'approve' ? {
                  success: true,
                } : {},
                editMoreInfoInProgress: edit_type === 'more-info' ? false : undefined,
                editMoreInfoStatus: edit_type === 'more-info' ? {
                  success: true,
                } : {},
                editSaveInProgress: edit_type === 'save' ? false : undefined,
                editSaveStatus: edit_type === 'save' ? {
                  success: true,
                } : {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? false : undefined,
                editSaveAndRespondStatus: edit_type === 'save-and-respond' ? {
                  success: true,
                } : {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? false : undefined,
                editRedirectToOtaStatus: edit_type === 'redirected-to-ota' ? {
                  success: true,
                } : {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? false : undefined,
                editRespondedOnOtaStatus: edit_type === 'responded-on-ota' ? {
                  success: true,
                } : {},
                self_response_posted: edit_type === 'responded-on-ota'
                  ? false : state.locations[location__id].reputation.reviews.keyValue[review__id].self_response_posted,
              },
            },
            review_state_split: reviewStateSplit,
          },
        },
      },
    },
  };
}

export const editGroupReviewError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id, location__id, review__id, edit_type } = params;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                editApproveInProgress: edit_type === 'approve' ? false : undefined,
                editApproveStatus: edit_type === 'approve' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editMoreInfoInProgress: edit_type === 'more-info' ? false : undefined,
                editMoreInfoStatus: edit_type === 'more-info' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editSaveInProgress: edit_type === 'save' ? false : undefined,
                editSaveStatus: edit_type === 'save' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? false : undefined,
                editSaveAndRespondStatus: edit_type === 'save-and-respond' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? false : undefined,
                editRedirectToOtaStatus: edit_type === 'redirected-to-ota' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? false : undefined,
                editRespondedOnOtaStatus: edit_type === 'responded-on-ota' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Edit Group Review **************** */


/* *************** Start - Group Update Review Translation **************** */

export const updateGroupReviewTranslationClear = (state, action) => {
  const { params: { group_id, location__id, review__id } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                translateInProgress: false,
                translateStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const updateGroupReviewTranslation = (state, action) => {
  const { params: { group_id, location__id, review__id, type, text } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                translateInProgress: true,
                translateStatus: {},
                self_response: type === 'text' ? text : state.groups.keyValue[group_id].reviews.keyValue[review__id].self_response,
              },
            },
          },
        },
      },
    },
  };
}

export const updateGroupReviewTranslationSuccess = (state, action) => {
  const { data, params: { group_id, location__id, review__id, type } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  const { updated_review, self_response } = data;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                ...updated_review,
                translateInProgress: false,
                translateStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
}

export const updateGroupReviewTranslationError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id , location__id, review__id, type } = params;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                translateInProgress: false,
                translateStatus: {
                  error: true,
                  message: 'Error in translation',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Group Update Review Translation **************** */


/* *************** Start - Get Group Response **************** */

export const getGroupResponseClear = (state, action) => {
  const { params: { group_id, location__id, review__id } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                getResponseInProgress: false,
                getResponseStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const getGroupResponse = (state, action) => {
  const { params: { group_id, location__id, review__id } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                getResponseInProgress: true,
                getResponseStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const getGroupResponseSuccess = (state, action) => {
  const { data, params: { group_id, location__id, review__id } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  const { generated_response } = data;

  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                generated_response,
                getResponseInProgress: false,
                getResponseStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
}

export const getGroupResponseError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id, location__id, review__id } = params;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                getResponseInProgress: false,
                getResponseStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Get Group Response **************** */


/* *************** Start - Post group response on google **************** */

export const postGroupResponseOnGoogleClear = (state, action) => {
  const { params: { group_id, location__id, review__id } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                postResponseOnGoogleInProgress: false,
                postResponseOnGoogleStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const postGroupResponseOnGoogle = (state, action) => {
  const { params: { group_id, location__id, review__id } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                postResponseOnGoogleInProgress: true,
                postResponseOnGoogleStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const postGroupResponseOnGoogleSuccess = (state, action) => {
  const { data, params: { group_id, location__id, review__id, edit_type } } = action.payload;
  if (!group_id || !location__id || !review__id) return state;
  const { updated_review } = data;

  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                ...updated_review,
                postResponseOnGoogleInProgress: false,
                postResponseOnGoogleStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
}

export const postGroupResponseOnGoogleError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id, location__id, review__id } = params;
  if (!group_id || !location__id || !review__id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          reviews: {
            ...state.groups.keyValue[group_id].reviews,
            keyValue: {
              ...state.groups.keyValue[group_id].reviews.keyValue,
              [review__id]: {
                ...state.groups.keyValue[group_id].reviews.keyValue[review__id],
                postResponseOnGoogleInProgress: false,
                postResponseOnGoogleStatus: {
                  error: true,
                  message: 'Error in updating',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Post group response on google **************** */



/***************************************** Edit Group ****************************/

export const editGroupClear = (state, action) => {
  const { params: { group_id, mode } } = action.payload;
  if (!mode) return state;
  if ((mode === 'edit' || mode === 'remove') && !group_id) return state;
  if (mode === 'add') {
    return {
      ...state,
      groups: {
        ...state.groups,
        addInProgress: false,
        addStatus: {},
      },
    };
  }
  if (mode === 'edit') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            editInProgress: false,
            editStatus: {},
          },
        },
      },
    };
  }
  if (mode === 'remove') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            removeInProgress: false,
          },
        },
      },
    };
  }
  return state;
};

export const editGroup = (state, action) => {
  const { params: { group_id, mode } } = action.payload;
  if (!mode) return state;
  if ((mode === 'edit' || mode === 'remove') && !group_id) return state;
  if (mode === 'add') {
    return {
      ...state,
      groups: {
        ...state.groups,
        addInProgress: true,
        addStatus: {},
      },
    };
  }
  if (mode === 'edit') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            editInProgress: true,
            editStatus: {},
          },
        },
      },
    };
  }
  if (mode === 'remove') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            removeInProgress: true,
          },
        },
      },
    };
  }
  return state;
};

export const editGroupSuccess = (state, action) => {
  const { data, params: { group_id, mode, name, list } } = action.payload;
  if (!mode) return state;
  if ((mode === 'edit' || mode === 'remove') && !group_id) return state;
  const { group_data } = data;
  if (mode === 'add') {
    return {
      ...state,
      groups: {
        ...state.groups,
        list: [
          ...state.groups.list,
          group_data._id,
        ],
        keyValue: {
          ...state.groups.keyValue,
          [group_data._id]: {
            ...group_data,
          },
        },
        addInProgress: false,
        addStatus: {
          success: true,
        },
      },
    };
  }
  if (mode === 'edit') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            ...group_data,
            editInProgress: false,
            editStatus: {
              success: true,
            },
          },
        },
      },
    };
  }
  if (mode === 'remove') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            is_removed: true,
            removeInProgress: false,
          },
        },
      },
    };
  }
  return state;
};

export const editGroupError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id, mode } = params;
  if (!mode) return state;
  if ((mode === 'edit' || mode === 'remove') && !group_id) return state;
  if (mode === 'add') {
    return {
      ...state,
      groups: {
        ...state.groups,
        addInProgress: false,
        error,
        errorMessage,
        addStatus: {
          error: true,
        },
      },
    };
  }
  if (mode === 'edit') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            error,
            errorMessage,
            editStatus: {
              error: true,
              message: 'Error in updating'
            },
            editInProgress: false,
          },
        },
      },
    };
  }
  if (mode === 'remove') {
    return {
      ...state,
      groups: {
        ...state.groups,
        keyValue: {
          ...state.groups.keyValue,
          [group_id]: {
            ...state.groups.keyValue[group_id],
            error,
            errorMessage,
            removeInProgress: false,
          },
        },
      },
    };
  }
  return state;
};



/***************************************** Share Group ****************************/

export const shareGroupClear = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          editShareInProgress: false,
          editShareStatus: {},
        },
      },
    },
  };
};

export const shareGroup = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          editShareInProgress: true,
          editShareStatus: {},
        },
      },
    },
  };
};

export const shareGroupSuccess = (state, action) => {
  const { data, params: { group_id, list } } = action.payload;
  if (!group_id || !list) return state;
  const { share_data } = data;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          share_data,
          editShareInProgress: false,
          editShareStatus: {
            success: true,
          },
        },
      },
    },
  };
};

export const shareGroupError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id } = params;
  if (!group_id) return state;
  return {
    ...state,
    groups: {
      ...state.groups,
      keyValue: {
        ...state.groups.keyValue,
        [group_id]: {
          ...state.groups.keyValue[group_id],
          error,
          errorMessage,
          editShareStatus: {
            error: true,
            message: 'Error in updating'
          },
          editShareInProgress: false,
        },
      },
    },
  };
};


/***************************************** Remove Shared Group ****************************/

export const removeSharedGroupClear = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    shared_groups: {
      ...state.shared_groups,
      keyValue: {
        ...state.shared_groups.keyValue,
        [group_id]: {
          ...state.shared_groups.keyValue[group_id],
          removeInProgress: false,
          removeStatus: {},
        },
      },
    },
  };
};

export const removeSharedGroup = (state, action) => {
  const { params: { group_id } } = action.payload;
  if (!group_id) return state;
  return {
    ...state,
    shared_groups: {
      ...state.shared_groups,
      keyValue: {
        ...state.shared_groups.keyValue,
        [group_id]: {
          ...state.shared_groups.keyValue[group_id],
          removeInProgress: true,
          removeStatus: {},
        },
      },
    },
  };
};

export const removeSharedGroupSuccess = (state, action) => {
  const { data, params: { group_id } } = action.payload;
  if (!group_id) return state;
  // const { share_data } = data;
  return {
    ...state,
    shared_groups: {
      ...state.shared_groups,
      keyValue: {
        ...state.shared_groups.keyValue,
        [group_id]: {
          ...state.shared_groups.keyValue[group_id],
          is_removed: true,
          removeInProgress: false,
          removeStatus: {
            success: true,
          },
        },
      },
    },
  };
};

export const removeSharedGroupError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { group_id } = params;
  if (!group_id) return state;
  return {
    ...state,
    shared_groups: {
      ...state.shared_groups,
      keyValue: {
        ...state.shared_groups.keyValue,
        [group_id]: {
          ...state.shared_groups.keyValue[group_id],
          error,
          errorMessage,
          removeStatus: {
            error: true,
            message: 'Error in removing'
          },
          removeInProgress: false,
        },
      },
    },
  };
};
