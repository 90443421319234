import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import SideBarInner from '../../SideBarInner';
import ResponseTemplates from '../ResponseTemplates';
import ResponseTemplateCategories from '../ResponseTemplateCategories';
import WCHelpGuide from './WCHelpGuide';

import { getDataAction } from '../../../../redux/actions/getDataActions';

const gtconfig = require('../../../../gtconfig');

function PromptTemplates(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Prompt Templates - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const onContactClick = (e) => {
    const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
      && locationState.locations[urlParams.locationidentifier].settings
      && locationState.locations[urlParams.locationidentifier].settings.info
      ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
      : '';
    window.FreshworksWidget('open');
    window.FreshworksWidget('identify', 'ticketForm', {
      name: userState.user.user_info.profile ? userState.user.user_info.profile.first_name : '',
      email: userState.user.user_info.uid,
      subject: `Enable guestAI for ${locationName}`,
    });
  }

  const { has_guest_ai } = props.locationInfo;
  if (process.env.NODE_ENV !== 'production') {
    console.log('PromptTemplates has_guest_ai: ', has_guest_ai);
  }

  // if (!has_guest_ai) {
  //   return (
  //       <div className="ntEnblBx">
  //         <div className="titleBox">
  //           <h4>guestAI™ is not enabled. <span className="cstFdbkContact" onClick={onContactClick}>Please contact {gtconfig.APP_NAME_CONTACT} to enable it</span>.</h4>
  //         </div>
  //       </div>
  //   );
  // }

  return (
    <>
      <>
        <SideBarInner />
        <div className="sc">
          <Routes>
            <Route path="templates" element={<ResponseTemplates { ...props } />} />
            <Route path="categories" element={<ResponseTemplateCategories { ...props } />} />
            { has_guest_ai && <Route path="guest-messaging-model" element={<WCHelpGuide { ...props } />} /> }
          </Routes>
        </div>
      </>
    </>
  );
}

export default PromptTemplates;
