import { combineReducers } from "redux";
import windowReducer from "./windowReducer";
import appReducer from "./appReducer";
import snackReducer from "./snackReducer";
import userReducer from "./userReducer";
import groupReducer from "./groupReducer";
import locationReducer from "./locationReducer";
import responseGeneratorReducer from "./responseGeneratorReducer";
import intgReducer from "./intgReducer";

const allReducers = combineReducers({
  windowReducer: windowReducer,
  appReducer: appReducer,
  snackReducer: snackReducer,
  userReducer: userReducer,
  groupReducer: groupReducer,
  locationReducer: locationReducer,
  responseGeneratorReducer: responseGeneratorReducer,
  intgReducer: intgReducer,
});

export default allReducers;
