import { getGuestTypeKey, guestTypeKeyByTransactionStatus, sortList, categoryToGuestType } from '../../helpers';


export const initEngage = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;

  let engage = state.locations[location__id] && state.locations[location__id].engage ? { ...state.locations[location__id].engage } : {};
  Object.keys(categoryToGuestType).forEach((guestType) => {
    const guestTypeKey = getGuestTypeKey(guestType);
    engage = {
      ...engage,
      [guestTypeKey]: engage[guestTypeKey]
      ?
        {
          ...engage[guestTypeKey],
          list: engage[guestTypeKey].list
          ? [ ...engage[guestTypeKey].list ]
          : [],
          keyValue: engage[guestTypeKey].keyValue
          ? { ...engage[guestTypeKey].keyValue }
          : {},
        }
      :
       {
         list: [],
         keyValue: {},
       },
    }
  });
  // for newMessage
  engage = {
    ...engage,
    newMessage: engage.newMessage
    ?
      {
        ...engage.newMessage,
        list: engage.newMessage.list
        ? [ ...engage.newMessage.list ]
        : [],
        keyValue: engage.newMessage.keyValue
        ? { ...engage.newMessage.keyValue }
        : {},
      }
    :
     {
       list: [],
       keyValue: {},
     },
  };
  // for activityLog
  engage = {
    ...engage,
    activityLog: engage.activityLog
    ?
      {
        ...engage.activityLog,
        list: engage.activityLog.list
        ? [ ...engage.activityLog.list ]
        : [],
        keyValue: engage.activityLog.keyValue
        ? { ...engage.activityLog.keyValue }
        : {},
      }
    :
     {
       list: [],
       keyValue: {},
     },
  };
  // for broadcast
  engage = {
    ...engage,
    broadcast: engage.broadcast
    ?
      {
        ...engage.broadcast,
        list: engage.broadcast.list
        ? [ ...engage.broadcast.list ]
        : [],
        keyValue: engage.broadcast.keyValue
        ? { ...engage.broadcast.keyValue }
        : {},
        history: engage.broadcast.history
        ?
          {
            ...engage.broadcast.history,
          }
        :
          {}
      }
    :
     {
       list: [],
       keyValue: {},
       history: {},
     },
  };
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: state.locations[location__id]
      ?
        {
          ...state.locations[location__id],
          engage: {
            ...engage,
          },
        }
      :
        {
          engage: {
            ...engage,
          }
        },
    },
  };
  return state;
};



/*************************** Start - Get unread count ******************************/

export const getUnreadCountClear = (state, action) => {
  const { params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          getUnreadCountInProgress: false,
          getUnreadCountStatus: {},
        },
      },
    },
  };
}

export const getUnreadCount = (state, action) => {
  const { params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          getUnreadCountInProgress: true,
          getUnreadCountStatus: {},
        },
      },
    },
  };
}

export const getUnreadCountSuccess = (state, action) => {
  const { data, params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  const { unread_count_guest, unread_count_past, unread_count_upcoming, unread_count_outside, unread_count_staff, unread_count_webtext, unread_count_gbm } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          guests: {
            ...state.locations[location__id].engage.guests,
            unreadCount: 'unread_count_guest' in data ? unread_count_guest || 0 : state.locations[location__id].engage.guests.unreadCount || 0,
          },
          pastGuests: {
            ...state.locations[location__id].engage.pastGuests,
            unreadCount: 'unread_count_past' in data ? unread_count_past || 0 : state.locations[location__id].engage.pastGuests.unreadCount || 0,
          },
          upcomingGuests: {
            ...state.locations[location__id].engage.upcomingGuests,
            unreadCount: 'unread_count_upcoming' in data ? unread_count_upcoming || 0 : state.locations[location__id].engage.upcomingGuests.unreadCount || 0,
          },
          outsideGuests: {
            ...state.locations[location__id].engage.outsideGuests,
            unreadCount: 'unread_count_outside' in data ? unread_count_outside || 0 : state.locations[location__id].engage.outsideGuests.unreadCount || 0,
          },
          staff: {
            ...state.locations[location__id].engage.staff,
            unreadCount: 'unread_count_staff' in data ? unread_count_staff || 0 : state.locations[location__id].engage.staff.unreadCount || 0,
          },
          webtext: {
            ...state.locations[location__id].engage.webtext,
            unreadCount: 'unread_count_webtext' in data ? unread_count_webtext || 0 : state.locations[location__id].engage.webtext.unreadCount || 0,
          },
          gbm: {
            ...state.locations[location__id].engage.gbm,
            unreadCount: 'unread_count_gbm' in data ? unread_count_gbm || 0 : state.locations[location__id].engage.gbm.unreadCount || 0,
          },
          getUnreadCountInProgress: false,
          getUnreadCountStatus: { success: true },
        },
      },
    },
  };
}

export const getUnreadCountError = (state, action) => {
  const { params, error, errorMessage } = action.payload;
  if (!params) return state;
  const { location__id, type } = params;
  if (!location__id || !type) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          getUnreadCountInProgress: false,
          getUnreadCountStatus: { error: true },
        },
      },
    },
  };
}

/*************************** End - Get unread count ******************************/




/* ***************** Start - Add Search Result Guest to Store **************** */

export const addSearchResultGuestToStore = (state, action) => {
  const { params: { location__id, guest_type, guest } } = action.payload;
  if (!location__id || !guest_type) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  // already exists in store
  // if (state.locations[location__id].engage[guestTypeKey].keyValue[guest._id]) {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('addSearchResultGuestToStore already exists');
  //   }
  //   return state;
  // }
  let tempList = [guest._id, ...state.locations[location__id].engage[guestTypeKey].list];
  tempList = [...new Set(tempList)];
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            list: tempList,
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest._id]: {
                ...guest,
              },
            },
          },
        },
      },
    },
  };
}

/* ***************** End - Add Search Result Guest to Store **************** */


/************************ Start Add Guest ********************/

export const addGuestClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          guests: {
            ...state.locations[location__id].engage.guests,
            addGuestInProgress: false,
            addStatus: {},
          },
        },
      },
    },
  };
};

export const addGuest = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          guests: {
            ...state.locations[location__id].engage.guests,
            addGuestInProgress: true,
            addStatus: {},
          },
        },
      },
    },
  };
};

export const addGuestSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { guest_data } = data;
  if (!guest_data || !guest_data._id) return state;

  let guestTypeKey = 'guests';
  if (guest_data.transaction_type === 'CheckIn') { guestTypeKey = 'guests'; }
  if (guest_data.transaction_type === 'Reservation') { guestTypeKey = 'upcomingGuests'; }

  // let tempGuests = { ...state.locations[location__id].engage.guests };
  if (guestTypeKey === 'guests') {
    let tempList = [guest_data._id, ...state.locations[location__id].engage.guests.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.guests.keyValue, [guest_data._id]: guest_data });
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            guests: {
              ...state.locations[location__id].engage.guests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.guests.keyValue,
                [guest_data._id]: {
                  ...guest_data,
                },
              },
              addGuestInProgress: false,
              addStatus: {
                success: true,
                newId: guest_data._id,
              },
            },
          },
        },
      },
    };
  } // end of guests

  // tempGuests = { ...state.locations[location__id].engage.guests };
  if (guestTypeKey === 'upcomingGuests') {
    let tempList = [guest_data._id];
    if (state.locations[location__id].engage.upcomingGuests && state.locations[location__id].engage.upcomingGuests.list) {
      tempList = [...tempList, ...state.locations[location__id].engage.upcomingGuests.list];
    }
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.upcomingGuests.keyValue, [guest_data._id]: guest_data });
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            guests: {
              ...state.locations[location__id].engage.guests,
              addGuestInProgress: false,
              addStatus: {
                success: true,
                newId: guest_data._id,
              },
            },
            upcomingGuests: {
              ...state.locations[location__id].engage.upcomingGuests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.upcomingGuests.keyValue,
                [guest_data._id]: {
                  ...guest_data,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const addGuestError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          guests: {
            ...state.locations[location__id].engage.guests,
            addGuestInProgress: false,
            addStatus: {
              error: true,
              message: 'Error in adding.'
            },
          },
        },
      },
    },
  };
};

/***************************End of Add Guest ******************************/



/************************ Edit Guest ********************/

export const editGuestClear = (state, action) => {
  const { params: { location__id, mode, guest_type, guest_id, edit_type } } = action.payload;
  if (!location__id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (!guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id],
                editInProgress: false,
                editCheckInInProgress: edit_type === 'check_in_now' ? false : undefined,
                editCheckInStatus: edit_type === 'check_in_now' ? '' : undefined,
                editCheckOutInProgress: edit_type === 'check_out_now' ? false : undefined,
                editCheckOutStatus: edit_type === 'check_out_now' ? '' : undefined,
                editCheckOutFollowGuest: undefined,
                editDisableCheckMsgInProgress: edit_type === 'checkout_msg_disabled' ? false : undefined,
                editOptOutInProgress: edit_type === 'opt_out' ? false : undefined,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? false : undefined,
                cancelReservationInProgress: edit_type === 'cancel_reservation' ? false : undefined,
                regenerateRemoteLockKeyInProgress: edit_type === 'regenerate_remote_lock_key' ? false : undefined,
                revokeRemoteLockKeyInProgress: edit_type === 'revoke_remote_lock_key' ? false : undefined,
                editStatus: {},
                editDisableCheckMsgStatus: {},
                editOptOutStatus: {},
                editReplyRequiredStatus: {},
                editMarkAsStaffStatus: {},
              },
            },
            addGuestInProgress: false,
            addStatus: {},
          },
        },
      },
    },
  };
};

export const editGuest = (state, action) => {
  const { params: { location__id, mode, guest_type, guest_id, edit_type } } = action.payload;
  if (!location__id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  // if (!guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id],
                editInProgress: true,
                editCheckInInProgress: edit_type === 'check_in_now' ? true : undefined,
                editCheckInStatus: edit_type === 'check_in_now' ? '' : undefined,
                editCheckOutInProgress: edit_type === 'check_out_now' ? true : undefined,
                editCheckOutStatus: edit_type === 'check_out_now' ? '' : undefined,
                editCheckOutFollowGuest: undefined,
                editDisableCheckMsgInProgress: edit_type === 'checkout_msg_disabled' ? true : undefined,
                editOptOutInProgress: edit_type === 'opt_out' ? true : undefined,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? true : undefined,
                cancelReservationInProgress: edit_type === 'cancel_reservation' ? true : undefined,
                regenerateRemoteLockKeyInProgress: edit_type === 'regenerate_remote_lock_key' ? true : undefined,
                revokeRemoteLockKeyInProgress: edit_type === 'revoke_remote_lock_key' ? true : undefined,
                editStatus: {},
                editDisableCheckMsgStatus: {},
                editOptOutStatus: {},
                editReplyRequiredStatus: {},
                editMarkAsStaffStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const editGuestSuccess = (state, action) => {
  const { data, params: { location__id, guest_type, guest_id, edit_type, follow_guest, transaction_type, ...restParams } } = action.payload;
  if (!location__id) return state;
  if (!guest_id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('editGuestSuccess guestTypeKey: ', guestTypeKey);
  }
  const { guest_data } = data;
  if (!guest_data) return state;

  if (edit_type === 'check_in_now') {
    let tempList = [guest_id, ...state.locations[location__id].engage.guests.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.guests.keyValue, [guest_id]: state.locations[location__id].engage.upcomingGuests.keyValue[guest_id] });
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            guests: {
              ...state.locations[location__id].engage.guests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.guests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.upcomingGuests.keyValue[guest_id],
                  ...guest_data,
                },
              },
            },
            upcomingGuests: {
              ...state.locations[location__id].engage.upcomingGuests,
              list: state.locations[location__id].engage.upcomingGuests.list.filter(el => el !== guest_id),
              keyValue: { // pending - guest_id could be removed later
                ...state.locations[location__id].engage.upcomingGuests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.upcomingGuests.keyValue[guest_id],
                  editInProgress: false,
                  editCheckInInProgress: edit_type === 'check_in_now' ? false : undefined,
                  editCheckInStatus: edit_type === 'check_in_now' ? { success : true } : undefined,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  } // end of edit_type === 'check_in_now'

  if (edit_type === 'check_out_now') {
    let tempList = [guest_id, ...state.locations[location__id].engage.pastGuests.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.pastGuests.keyValue, [guest_id]: state.locations[location__id].engage.guests.keyValue[guest_id] });
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            guests: {
              ...state.locations[location__id].engage.guests,
              list: state.locations[location__id].engage.guests.list.filter(el => el !== guest_id),
              keyValue: {
                ...state.locations[location__id].engage.guests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.guests.keyValue[guest_id],
                  ...guest_data,
                  editCheckOutInProgress: edit_type === 'check_out_now' ? false : undefined,
                  editCheckOutStatus: edit_type === 'check_out_now' ? { success : true } : undefined,
                  editCheckOutFollowGuest: follow_guest ? true : false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
            pastGuests: {
              ...state.locations[location__id].engage.pastGuests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.pastGuests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.guests.keyValue[guest_id],
                  ...guest_data,
                },
              },
            },
          },
        },
      },
    };
  } // end of edit_type === 'check_out_now'

  // if a guest is edited from check-in to Reservation
  if (transaction_type === 'Reservation') {
    let tempList = [guest_id, ...state.locations[location__id].engage.upcomingGuests.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.upcomingGuests.keyValue, [guest_id]: state.locations[location__id].engage.guests.keyValue[guest_id] });
    const { [guest_id]: _, ...restKeyValue } = state.locations[location__id].engage.guests.keyValue;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            guests: {
              ...state.locations[location__id].engage.guests,
              list: state.locations[location__id].engage.guests.list.filter(el => el !== guest_id),
              keyValue: {
                ...state.locations[location__id].engage.guests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.guests.keyValue[guest_id],
                  ...guest_data,
                  transaction_type,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
            upcomingGuests: {
              ...state.locations[location__id].engage.upcomingGuests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.upcomingGuests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.guests.keyValue[guest_id],
                  ...guest_data,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                  added_locally_on: new Date(),
                },
              },
            },
          },
        },
      },
    };
  } // end of transaction_type === 'Reservation'

  // if a guest is edited from Reservation to check-in
  if (transaction_type === 'CheckIn') {
    let tempList = [guest_id, ...state.locations[location__id].engage.guests.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.guests.keyValue, [guest_id]: state.locations[location__id].engage.upcomingGuests.keyValue[guest_id] });
    const { [guest_id]: _, ...restKeyValue } = state.locations[location__id].engage.upcomingGuests.keyValue;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            upcomingGuests: {
              ...state.locations[location__id].engage.upcomingGuests,
              list: state.locations[location__id].engage.upcomingGuests.list.filter(el => el !== guest_id),
              keyValue: {
                ...state.locations[location__id].engage.upcomingGuests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.upcomingGuests.keyValue[guest_id],
                  ...guest_data,
                  transaction_type,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
            guests: {
              ...state.locations[location__id].engage.guests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.guests.keyValue,
                [guest_id]: {
                  ...state.locations[location__id].engage.upcomingGuests.keyValue[guest_id],
                  ...guest_data,
                  added_locally_on: new Date(),
                },
              },
            },
          },
        },
      },
    };
  } // end of transaction_type === 'CheckIn'

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].settings.engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            // list: state.locations[location__id].engage[guestTypeKey].list.filter(el => el !== guest_id),
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id],
                ...guest_data,
                editInProgress: false,
                editDisableCheckMsgInProgress: edit_type === 'checkout_msg_disabled' ? false : undefined,
                editOptOutInProgress: edit_type === 'opt_out' ? false : undefined,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? false : undefined,
                editStatus: {
                  success: true,
                },
                editDisableCheckMsgStatus: edit_type === 'checkout_msg_disabled'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].editDisableCheckMsgStatus,
                editOptOutStatus: edit_type === 'opt_out'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].editOptOutStatus,
                editReplyRequiredStatus: edit_type === 'reply_required'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].editReplyRequiredStatus,
                // editMarkAsStaffStatus: edit_type === 'mark_as_staff'
                //   ? { success: true } : state[guestTypeKey].keyValue[guest_id].editMarkAsStaffStatus,
                cancelReservationInProgress: edit_type === 'cancel_reservation' ? false : undefined,
                cancelReservationStatus: edit_type === 'cancel_reservation'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].cancelReservationStatus,
                regenerateRemoteLockKeyInProgress: edit_type === 'regenerate_remote_lock_key' ? false : undefined,
                regenerateRemoteLockKeyStatus: edit_type === 'regenerate_remote_lock_key'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].regenerateRemoteLockKeyStatus,
                revokeRemoteLockKeyInProgress: edit_type === 'revoke_remote_lock_key' ? false : undefined,
                revokeRemoteLockKeyStatus: edit_type === 'revoke_remote_lock_key'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].revokeRemoteLockKeyStatus,
              },
            },
          },
        },
      },
    },
  };
};

export const editGuestError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, guest_type, guest_id, edit_type } = params;
  if (!location__id || !guest_id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id],
                editInProgress: false,
                editCheckInInProgress: edit_type === 'check_in_now' ? false : undefined,
                editCheckInStatus: edit_type === 'check_in_now' ? { error : true } : undefined,
                editCheckOutInProgress: edit_type === 'check_out_now' ? false : undefined,
                editCheckOutStatus: edit_type === 'check_out_now' ? { error : true } : undefined,
                editCheckOutFollowGuest: undefined,
                editDisableCheckMsgInProgress: edit_type === 'checkout_msg_disabled' ? false : undefined,
                editOptOutInProgress: edit_type === 'opt_out' ? false : undefined,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? false : undefined,
                editStatus: {
                  error: true,
                  message: 'Error',
                },
                editDisableCheckMsgStatus: edit_type === 'checkout_msg_disabled'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].editDisableCheckMsgStatus,
                editOptOutStatus: edit_type === 'opt_out'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].editOptOutStatus,
                editReplyRequiredStatus: edit_type === 'reply_required'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].editReplyRequiredStatus,
                // editMarkAsStaffStatus: edit_type === 'mark_as_staff'
                //   ? { error: true, message: 'Error', } : state[guestTypeKey].keyValue[guest_id].editMarkAsStaffStatus,
                cancelReservationInProgress: edit_type === 'cancel_reservation' ? false : undefined,
                cancelReservationStatus: edit_type === 'cancel_reservation'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].cancelReservationStatus,
                regenerateRemoteLockKeyInProgress: edit_type === 'regenerate_remote_lock_key' ? false : undefined,
                regenerateRemoteLockKeyStatus: edit_type === 'regenerate_remote_lock_key'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].regenerateRemoteLockKeyStatus,
                revokeRemoteLockKeyInProgress: edit_type === 'revoke_remote_lock_key' ? false : undefined,
                revokeRemoteLockKeyStatus: edit_type === 'revoke_remote_lock_key'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[guest_id].revokeRemoteLockKeyStatus,
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};

/***************************End of Edit Guest ******************************/

/* ***************** Start - Edit Guest - Remove entry from keyValue **************** */

export const editGuestRemoveEntry = (state, action) => {
  const { params: { location__id, guest_id, guest_type } } = action.payload;
  if (!location__id || !guest_id || !guest_type) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  const newList = state.locations[location__id].engage[guestTypeKey].list.filter(el => el !== guest_id);
  const newkeyValue = {};
  newList.forEach ((item) => {
    newkeyValue[item._id] = {
      ...state.locations[location__id].engage[guestTypeKey].keyValue[item._id],
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('newkeyValue: ', newkeyValue);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            list: newList,
            keyValue: {
              ...newkeyValue,
            },
          },
        },
      },
    },
  };
}

/* ***************** End - Edit Guest - Remove entry from keyValue **************** */



/************************ Edit Outside Guest ********************/

export const initOutsideGuest = (state, action) => {
  const { params: { location__id, guest_type, outside_guest_id, edit_type } } = action.payload;
  if (!location__id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (!outside_guest_id) return state;
  if (edit_type === 'mark_as_staff') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            staff: state.locations[location__id].engage.staff
            ?
              {
                ...state.locations[location__id].engage[guestTypeKey],
                list: state.locations[location__id].engage[guestTypeKey].list
                ? [ ...state.locations[location__id].engage[guestTypeKey].list ]
                : [],
                keyValue: state.locations[location__id].engage[guestTypeKey].keyValue
                ? { ...state.locations[location__id].engage[guestTypeKey].keyValue }
                : {},
              }
            :
              {
                list: [],
                keyValue: {},
              }
          },
        },
      },
    };
  }
  return state;
};

export const editOutsideGuestClear = (state, action) => {
  const { params: { location__id, guest_type, outside_guest_id, edit_type } } = action.payload;
  if (!location__id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (!outside_guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id],
                editInProgress: false,
                editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? false : undefined,
                editOptOutInProgress: edit_type === 'opt_out' ? false : undefined,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? false : undefined,
                editRemoveInProgress: edit_type === 'remove' ? false : undefined,
                editStatus: {},
                editMarkAsStaffStatus: {},
                editRemoveStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const editOutsideGuest = (state, action) => {
  const { params: { location__id, guest_type, outside_guest_id, edit_type } } = action.payload;
  if (!location__id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (!outside_guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id],
                editInProgress: true,
                editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? true : undefined,
                editOptOutInProgress: edit_type === 'opt_out' ? true : undefined,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? true : undefined,
                editRemoveInProgress: edit_type === 'remove' ? true : undefined,
                editStatus: {},
                editMarkAsStaffStatus: {},
                editRemoveStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const editOutsideGuestSuccess = (state, action) => {
  const { data, params: { location__id, guest_type, outside_guest_id, edit_type, is_staff, ...restParams } } = action.payload;
  if (!location__id) return state;
  if (!outside_guest_id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('editOutsideGuestSuccess guestTypeKey: ', guestTypeKey);
  }
  const { outside_guest_data } = data;
  if (!outside_guest_data) return state;

  if (edit_type === 'mark_as_staff' && is_staff) {
    let tempList = [outside_guest_id, ...state.locations[location__id].engage.staff.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.staff.keyValue, [outside_guest_id]: state.locations[location__id].engage.outsideGuests.keyValue[outside_guest_id] });
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            outsideGuests: {
              ...state.locations[location__id].engage.outsideGuests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.outsideGuests.keyValue,
                [outside_guest_id]: {
                  ...state.locations[location__id].engage.outsideGuests.keyValue[outside_guest_id],
                  ...outside_guest_data,
                  editInProgress: false,
                  editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? false : undefined,
                  editMarkAsStaffStatus: {
                    success: true,
                  },
                },
              },
            },
            staff: {
              ...state.locations[location__id].engage.staff,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.staff.keyValue,
                [outside_guest_id]: {
                  ...state.locations[location__id].engage.outsideGuests.keyValue[outside_guest_id],
                  ...outside_guest_data,
                  editInProgress: false,
                  editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? false : undefined,
                  editMarkAsStaffStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  } // end of edit_type === 'mark_as_staff' && is_staff

  if (edit_type === 'mark_as_staff' && !is_staff) {
    let tempList = [outside_guest_id, ...state.locations[location__id].engage.outsideGuests.list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...state.locations[location__id].engage.outsideGuests.keyValue, [outside_guest_id]: state.locations[location__id].engage.staff.keyValue[outside_guest_id] });
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          engage: {
            ...state.locations[location__id].engage,
            staff: {
              ...state.locations[location__id].engage.staff,
              list: state.locations[location__id].engage.staff.list.filter(el => el !== outside_guest_id),
              keyValue: {
                ...state.locations[location__id].engage.staff.keyValue,
                [outside_guest_id]: {
                  ...state.locations[location__id].engage.staff.keyValue[outside_guest_id],
                  ...outside_guest_data,
                  editInProgress: false,
                  editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? false : undefined,
                  editMarkAsStaffStatus: {
                    success: true,
                  },
                },
              },
            },
            outsideGuests: {
              ...state.locations[location__id].engage.outsideGuests,
              list: tempList,
              keyValue: {
                ...state.locations[location__id].engage.outsideGuests.keyValue,
                [outside_guest_id]: {
                  ...state.locations[location__id].engage.staff.keyValue[outside_guest_id],
                  ...outside_guest_data,
                  editInProgress: false,
                  editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? false : undefined,
                  editMarkAsStaffStatus: {
                    success: true,
                  },
                },
              },
            },

          },
        },
      },
    };
  } // end of edit_type === 'mark_as_staff' && !is_staff

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].settings.engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            // list: state.locations[location__id].engage[guestTypeKey].list.filter(el => el !== outside_guest_id),
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id],
                ...outside_guest_data,
                editInProgress: false,
                editRemoveInProgress: edit_type === 'remove' ? false : undefined,
                editStatus: {
                  success: true,
                },
                editRemoveStatus: edit_type === 'remove'
                  ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editRemoveStatus,
                editOptOutInProgress: edit_type === 'opt_out' ? false : undefined,
                editOptOutStatus: edit_type === 'opt_out'
                    ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editOptOutStatus,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? false : undefined,
                editReplyRequiredStatus: edit_type === 'reply_required'
                        ? { success: true } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editReplyRequiredStatus,
              },
            },
          },
        },
      },
    },
  };
};

export const editOutsideGuestError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, guest_type, outside_guest_id, edit_type } = params;
  if (!location__id || !outside_guest_id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id],
                editInProgress: false,
                editMarkAsStaffInProgress: edit_type === 'mark_as_staff' ? false : undefined,
                editRemoveInProgress: edit_type === 'remove' ? false : undefined,
                editStatus: {
                  error: true,
                  message: 'Error',
                },
                editMarkAsStaffStatus: edit_type === 'mark_as_staff'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editMarkAsStaffStatus,
                editRemoveStatus: edit_type === 'remove'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editRemoveStatus,
                editOptOutInProgress: edit_type === 'opt_out' ? false : undefined,
                editOptOutStatus: edit_type === 'opt_out'
                  ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editOptOutStatus,
                editReplyRequiredInProgress: edit_type === 'reply_required' ? false : undefined,
                editReplyRequiredStatus: edit_type === 'reply_required'
                    ? { error: true, message: 'Error', } : state.locations[location__id].engage[guestTypeKey].keyValue[outside_guest_id].editReplyRequiredStatus,
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};

/***************************End of Edit Outside Guest ******************************/



/************************ Add Outside Guest ********************/

export const addOutsideGuestClear = (state, action) => {
  const { params: { location__id, is_staff } } = action.payload;
  if (!location__id) return state;
  let guestTypeKey = 'outsideGuests';
  if (is_staff) guestTypeKey = 'staff';
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            addInProgress: false,
            addStatus: {},
          },
        },
      },
    },
  };
};

export const addOutsideGuest = (state, action) => {
  const { params: { location__id, is_staff } } = action.payload;
  if (!location__id) return state;
  let guestTypeKey = 'outsideGuests';
  if (is_staff) guestTypeKey = 'staff';
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            addInProgress: true,
            addStatus: {},
          },
        },
      },
    },
  };
};

export const addOutsideGuestSuccess = (state, action) => {
  const { data, params: { location__id, is_staff } } = action.payload;
  if (!location__id) return state;
  const { outside_guest_data, existing } = data;
  if (!outside_guest_data || !outside_guest_data._id) return state;
  let guestTypeKey = 'outsideGuests';
  if (is_staff) guestTypeKey = 'staff';

  let tempList = [outside_guest_data._id, ...state.locations[location__id].engage[guestTypeKey].list];
  tempList = [...new Set(tempList)];
  tempList = sortList(tempList, { ...state.locations[location__id].engage[guestTypeKey].keyValue, [outside_guest_data._id]: outside_guest_data });

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            list: tempList,
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [outside_guest_data._id]: {
                ...outside_guest_data
              },
            },
            addInProgress: false,
            addStatus: {
              success: true,
              newId: outside_guest_data._id,
              existing,
            },
          },
        },
      },
    },
  };
};

export const addOutsideGuestError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, is_staff } = params;
  let guestTypeKey = 'outsideGuests';
  if (is_staff) guestTypeKey = 'staff';
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            addInProgress: false,
            addStatus: {
              error: true,
              message: 'Error in adding.'
            },
          },
        },
      },
    },
  };
};

/***************************End of Add Outside Guest ******************************/


/******************** Start - Clear add outside guest status *********************/

export const addOutsideGuestStatusClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  let guestTypeKey = 'outsideGuests';
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            addInProgress: false,
            addStatus: {},
          },
        },
      },
    },
  };
};

/******************** End - Clear add outside guest status *********************/



/************************ Start - get Ai Model result for guest ********************/

export const getAiModelResultClear = (state, action) => {
  const { params: { location__id, guest_id, outside_guest_id, guest_type } } = action.payload;
  if (!location__id || (!guest_id && !outside_guest_id) || !guest_type) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                getSuggestedResponseInProgress: false,
                getSuggestedResponseStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const getAiModelResult = (state, action) => {
  const { params: { location__id, guest_type, guest_id, outside_guest_id } } = action.payload;
  if (!location__id || (!guest_id && !outside_guest_id) || !guest_type) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                getSuggestedResponseInProgress: true,
                getSuggestedResponseStatus: {},
              },
            },
          },
        },
      },
    },
  };
};

export const getAiModelResultSuccess = (state, action) => {
  const { data, params: { location__id, guest_type, guest_id, outside_guest_id } } = action.payload;
  if (!location__id || (!guest_id && !outside_guest_id) || !guest_type) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('getAiModelResultSuccess guestTypeKey: ', guestTypeKey);
  }
  const { generated_response } = data;
  // if (!suggested_response_data) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                generated_response: generated_response || '',
                getSuggestedResponseInProgress: false,
                getSuggestedResponseStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
};

export const getAiModelResultError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, guest_type, guest_id, outside_guest_id } = params;
  if (!location__id || (!guest_id && !outside_guest_id) || !guest_type) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                getSuggestedResponseInProgress: false,
                getSuggestedResponseStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};

/*************************** End of get Ai Model result for guest ******************************/

/********************* Start - Send Message *********************/

export const sendMessage = (state, action) => {
  const { params: { location__id, location_id, guest_id, outside_guest_id, type, msg, msg_subject, new_msg_id, medium, mediaUrl, sent_by } } = action.payload;
  if (!location__id || !location_id) return state;
  const guestTypeKey = getGuestTypeKey(type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('guestTypeKey: ', guestTypeKey);
  }
  if (!guest_id && !outside_guest_id) return state;
  const message = {
    _id: new_msg_id,
    location_id,
    msg,
    type: 2,
    medium,
    sent_by,
    date_created: new Date(),
    sendingInProgress: true,
  };
  if (medium === 'email' && msg_subject) message.msg_subject = msg_subject;
  if (medium === 'email' && msg_subject) message.msg_subject = msg_subject;
  if (mediaUrl && mediaUrl.length) message.mediaUrl = mediaUrl;
  if (type === 'guest' || type === 'guests' ||
    type === 'past-guest' || type === 'past-guests' ||
    type === 'upcoming-guest' || type === 'upcoming-guests'
  ) { message['guest_id'] = guest_id; }
  if (type === 'outside-guest' || type === 'outside-guests' ||
    type === 'staff' || type === 'webtext' || type === 'gbm'
  ) { message['outside_guest_id'] = outside_guest_id; }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                messages: state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages
                  ? [ message, ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages]
                  : [ message ],
                sendMessageInProgress: true,
                sendMessageStatus: {},
              },
            },
          },
        },
      },
    },
  };
};


export const sendMessageSuccess = (state, action) => {
  const { data, params: { location__id, location_id, guest_id, outside_guest_id, type, msg, new_msg_id, medium, selected_template_id, selected_template_type } } = action.payload;
  if (!location__id || !location_id) return state;
  const guestTypeKey = getGuestTypeKey(type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('guestTypeKey: ', guestTypeKey);
  }
  if (!guest_id && !outside_guest_id) return state;
  const { message } = data;
  if (!message) return state;

  let existingMessages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ].filter(el => el._id !== new_msg_id);
  let messageFound = false;
  existingMessages.forEach((item) => {
    if (item._id === message._id) {
      messageFound = true;
    }
  });
  if (!messageFound) {
    existingMessages = [message, ...existingMessages];
  }

  let newSettingsEngage = { ...state.locations[location__id].settings.engage };
  if (selected_template_id && selected_template_type === "whatsapp") {
    // let approvedWhatsappTemplates = newSettingsEngage.approved_whatsapp_templates ? { ...newSettingsEngage.approvedWhatsappTemplates } : {};
    // if (approvedWhatsappTemplates.keyValue && approvedWhatsappTemplates.list && approvedWhatsappTemplates.list.length) {
    //   approvedWhatsappTemplates.list.forEach((item) => {
    //     if (item === selected_template_id) {
    //       if (approvedWhatsappTemplates.keyValue[item]) {
    //         if (!approvedWhatsappTemplates.keyValue[item].count) approvedWhatsappTemplates.keyValue[item].count = 0;
    //         approvedWhatsappTemplates.keyValue[item].count++;
    //       }
    //     }
    //   });
    // }
    // newSettingsEngage = {
    //   ...newSettingsEngage,
    //   approved_whatsapp_templates: { ...approvedWhatsappTemplates },
    // }
    let approvedWhatsappTemplates = newSettingsEngage.approved_whatsapp_templates ? [ ...newSettingsEngage.approved_whatsapp_templates ] : [];
    if (approvedWhatsappTemplates.length) {
      approvedWhatsappTemplates.forEach((item) => {
        if (item._id === selected_template_id) {
          if (item) {
            if (!item.count) item.count = 0;
            item.count++;
          }
        }
      });
    }
    newSettingsEngage = {
      ...newSettingsEngage,
      approved_whatsapp_templates: [ ...approvedWhatsappTemplates ],
    }
  }

  let newMessageTemplates = { ...state.locations[location__id].settings.engage.message_templates };
  if (selected_template_id && selected_template_type === "generic" && newMessageTemplates &&
    newMessageTemplates.keyValue && newMessageTemplates.keyValue[selected_template_id]
  ) {
    newMessageTemplates = {
      ...newMessageTemplates,
      keyValue: {
        ...newMessageTemplates.keyValue,
        [selected_template_id]: {
          ...newMessageTemplates.keyValue[selected_template_id],
          count: newMessageTemplates.keyValue[selected_template_id].count ?
            newMessageTemplates.keyValue[selected_template_id].count+1
            : 1,
        }
      }
    }
  }

  newSettingsEngage = {
    ...newSettingsEngage,
    message_templates: { ...newMessageTemplates },
  };

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                messages: existingMessages,
                latest_msg: message.msg,
                date_last_message: message.date_created,
                date_last_message_sent: message.date_created,
                sendMessageInProgress: false,
                sendMessageStatus: {
                  success: true,
                },
              },
            },
          },
        },
        settings: {
          ...state.locations[location__id].settings,
          engage: {
            ...state.locations[location__id].settings.engage,
            ...newSettingsEngage,
          }
        }
      },
    },
  };
};

export const sendMessageError = (state, action) => {
  const { error, errorMessage } = action.payload;
  const { params } = action.payload;
  if (!params) return state;
  const { location__id, location_id, guest_id, outside_guest_id, type, msg, new_msg_id, medium, sent_by } = params;
  if (!location__id || !location_id) return state;
  const guestTypeKey = getGuestTypeKey(type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('guestTypeKey: ', guestTypeKey);
  }
  if (!guest_id && !outside_guest_id) return state;

  let existingMessages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ];
  existingMessages.forEach((item) => {
    if (item._id === new_msg_id) {
      item.sendingInProgress = false;
      item.sendMessageStatus = {
        error: true,
      };
    }
  });

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                sendMessageInProgress: false,
                sendMessageStatus: {
                  error: true,
                },
                messages: existingMessages,
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};

/********************* End - Send Message *********************/

/********************* Start - Send Message Retry *********************/

export const sendMessageRetry = (state, action) => {
  const { params: { location__id, location_id, guest_id, outside_guest_id, type, msg, new_msg_id, medium, sent_by } } = action.payload;
  if (!location__id || !location_id) return state;
  const guestTypeKey = getGuestTypeKey(type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('guestTypeKey: ', guestTypeKey);
  }
  if (!guest_id && !outside_guest_id) return state;

  let existingMessages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ];
  existingMessages.forEach((item) => {
    if (item._id === new_msg_id) {
      item.sendingInProgress = true;
      item.sendMessageStatus = {};
      item.retryCount = item.retryCount ? item.retryCount+1 : 1;
    }
  });

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                messages: existingMessages,
              },
            },
          },
        },
      },
    },
  };
};


export const sendMessageRetrySuccess = (state, action) => {
  const { data, params: { location__id, location_id, guest_id, outside_guest_id, type, msg, new_msg_id, medium, selected_template_id, selected_template_type } } = action.payload;
  if (!location__id || !location_id) return state;
  const guestTypeKey = getGuestTypeKey(type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('guestTypeKey: ', guestTypeKey);
  }
  if (!guest_id && !outside_guest_id) return state;
  const { message } = data;
  if (!message) return state;

  let existingMessages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ].filter(el => el._id !== new_msg_id);
  let messageFound = false;
  existingMessages.forEach((item) => {
    if (item._id === message._id) {
      messageFound = true;
    }
  });
  if (!messageFound) {
    existingMessages = [message, ...existingMessages];
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                messages: existingMessages,
                latest_msg: message.msg,
                date_last_message: message.date_created,
                date_last_message_sent: message.date_created,
                sendMessageInProgress: false,
                sendMessageStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
};

export const sendMessageRetryError = (state, action) => {
  const { error, errorMessage } = action.payload;
  const { params } = action.payload;
  if (!params) return state;
  const { location__id, location_id, guest_id, outside_guest_id, type, msg, new_msg_id, medium, sent_by } = params;
  if (!location__id || !location_id) return state;
  const guestTypeKey = getGuestTypeKey(type);
  if (process.env.NODE_ENV !== 'production') {
    console.log('guestTypeKey: ', guestTypeKey);
  }
  if (!guest_id && !outside_guest_id) return state;

  let existingMessages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ];
  existingMessages.forEach((item) => {
    if (item._id === new_msg_id) {
      item.sendingInProgress = false;
      item.sendMessageStatus = {
        error: true,
      };
    }
  });

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                sendMessageInProgress: false,
                sendMessageStatus: {
                  error: true,
                },
                messages: existingMessages,
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
};

/********************* End - Send Message Retry *********************/


/*********************************** Mark message as read ***********************************/


export const markMessageAsRead = (state, action) => {
  const { params: { location__id, guest_type, guest_id, outside_guest_id, all } } = action.payload;
  if (!location__id) return state;
  const guestTypeKey = getGuestTypeKey(guest_type);
  if (!guest_id && !outside_guest_id) return state;

  let existingMessages = [];
  if (state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id] &&
    state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages
  ) {
    existingMessages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ];
  }
  // let existingMessages = [ ...state[guestTypeKey].keyValue[guest_id || outside_guest_id].messages ];
  existingMessages.forEach((item) => {
    // if (item._id === msg_id) {
      item.read = true;
    // }
  });

  // re-calculate the unreadCount for guests
  let unreadCount = state.locations[location__id].engage[guestTypeKey].unreadCount;
  if (process.env.NODE_ENV !== 'production') {
    console.log('MARK_MESSAGE_AS_READ_SUCCESS original unreadCount: ', unreadCount);
  }
  if (state.locations[location__id].engage[guestTypeKey].list.length) unreadCount = 0;
  state.locations[location__id].engage[guestTypeKey].list.forEach((item) => {
    if (item === (guest_id || outside_guest_id)) {
      // if (data.unread_count) unreadCount++;
    } else {
      if (state.locations[location__id].engage[guestTypeKey].keyValue[item] &&
        state.locations[location__id].engage[guestTypeKey].keyValue[item].unread_count
      ) unreadCount++;
    }
  });
  if (!state.locations[location__id].engage[guestTypeKey].list || !state.locations[location__id].engage[guestTypeKey].list.length) {
    if (unreadCount) {
      unreadCount--;
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('MARK_MESSAGE_AS_READ_SUCCESS recalculated unreadCount: ', unreadCount);
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          [guestTypeKey]: {
            ...state.locations[location__id].engage[guestTypeKey],
            keyValue: {
              ...state.locations[location__id].engage[guestTypeKey].keyValue,
              [guest_id || outside_guest_id]: {
                ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                messages: [
                  ...existingMessages,
                ],
                unread_count: 0,
              },
            },
            unreadCount,
          },
        },
      },
    },
  };
};

/*********************************** end - mark message as read ***********************************/


/*************************** Start - Get new message guest search result ******************************/

export const getNewMessageSearchResultClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          newMessage: {
            ...state.locations[location__id].engage.newMessage,
            searchResults: {
              contacts: [],
              resultTypes: {},
              searchInProgress: false,
              searchStatus: {},
            },
          },
        },
      },
    },
  };
}

export const getNewMessageSearchResult = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          newMessage: {
            ...state.locations[location__id].engage.newMessage,
            searchResults: {
              contacts: [],
              resultTypes: {},
              searchInProgress: true,
              searchStatus: {},
            },
          },
        },
      },
    },
  };
}

export const getNewMessageSearchResultSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { search_list, search_result_types } = data;
  if (!search_list) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          newMessage: {
            ...state.locations[location__id].engage.newMessage,
            searchResults: {
              contacts: search_list,
              resultTypes: search_result_types,
              searchInProgress: false,
              searchStatus: {
                success: true,
              },
            },
          },
        },
      },
    },
  };
}

export const getNewMessageSearchResultError = (state, action) => {
  const { params, error, errorMessage } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          newMessage: {
            ...state.locations[location__id].engage.newMessage,
            searchResults: {
              searchInProgress: false,
              searchStatus: {
                error: true,
              },
            },
          },
        },
      },
    },
  };
}

/*************************** End - Get new message guest search result ******************************/



/*************************** Start - Get activity log ******************************/

export const getActivityLogClear = (state, action) => {
  const { params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  if (type !== 'engage') return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          activityLog: {
            ...state.locations[location__id].engage.activityLog,
            getInProgress: false, getStatus: {},
          },
        },
      },
    },
  };
}

export const getActivityLog = (state, action) => {
  const { params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  if (type !== 'engage') return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          activityLog: {
            ...state.locations[location__id].engage.activityLog,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
}

export const getActivityLogSuccess = (state, action) => {
  const { data, params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  if (type !== 'engage') return state;
  if (!data.engage) return state;
  const { list } = data.engage;
  const logList = list ? list.map(elem => elem._id) : [];
  const logListMap = {};
  if (list) {
    list.forEach((item) => { logListMap[item._id] = item; })
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          activityLog: {
            ...state.locations[location__id].engage.activityLog,
            list: logList,
            keyValue: logListMap,
            getInProgress: false,
            getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
}

export const getActivityLogError = (state, action) => {
  const { params, error, errorMessage } = action.payload;
  if (!params) return state;
  const { location__id, type } = params;
  if (!location__id || !type) return state;
  if (type !== 'engage') return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          activityLog: {
            ...state.locations[location__id].engage.activityLog,
            getInProgress: false,
            getStatus: {
              error: true,
            },
            error,
            errorMessage,
          },
        },
      },
    },
  };
}

/*************************** End - Get new message guest search result ******************************/


/*************************** Start - Get engage usage ******************************/

export const getEngageUsageClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          usage: state.locations[location__id].engage.usage
            ? {
                ...state.locations[location__id].engage.usage,
                getInProgress: false, getStatus: {},
              }
            : {
              getInProgress: false, getStatus: {},
            },
        },
      },
    },
  };
}

export const getEngageUsage = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          usage: {
            ...state.locations[location__id].engage.usage,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
}

export const getEngageUsageSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  if (!data.usage_data) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          usage: {
            ...state.locations[location__id].engage.usage,
            ...data.usage_data,
            getInProgress: false,
            getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
}

export const getEngageUsageError = (state, action) => {
  const { params, error, errorMessage } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        engage: {
          ...state.locations[location__id].engage,
          usage: {
            ...state.locations[location__id].engage.usage,
            getInProgress: false,
            getStatus: {
              error: true,
            },
            error,
            errorMessage,
          },
        },
      },
    },
  };
}

/*************************** End - Get engage usage ******************************/


/******************** Start - Add Guest to list ********************/

export const addGuestToList = (state, action) => {
  const { params: { guest_data, outside_guest_data, locationidentifier } } = action.payload;
  let guestTypeKey = null;
  let guestId = null;
  if (guest_data) {
    if (guest_data.transaction_type === 'CheckIn') { guestTypeKey = 'guests'; }
    if (guest_data.transaction_type === 'CheckOut') { guestTypeKey = 'pastGuests'; }
    if (guest_data.transaction_type === 'Reservation' || guest_data.transaction_type === 'Booking') { guestTypeKey = 'upcomingGuests'; }
    // if (guest_data.transaction_type === 'Cancel') { guestTypeKey = 'pastGuests'; }
    if (guest_data.transaction_type === 'Cancel') { guestTypeKey = 'upcomingGuests'; }
    guestId = guest_data._id;
  }
  if (outside_guest_data) {
    guestTypeKey = 'outsideGuests';
    if (outside_guest_data.source === 'webtext') { guestTypeKey = 'webtext'; }
    if (outside_guest_data.is_staff) { guestTypeKey = 'staff'; }
    guestId = outside_guest_data._id;
  }
  if (!guestTypeKey || !guestId) return state;

  let tempList = [...state.locations[locationidentifier].engage[guestTypeKey].list, guestId];
  if (guest_data) {
    let newUnreadCount = state.locations[locationidentifier].engage[guestTypeKey].unreadCount || 0;
    if (guest_data.unread_count) {
      newUnreadCount++;
    }
    tempList = sortList(tempList, { ...state.locations[locationidentifier].engage[guestTypeKey].keyValue, [guestId]: guest_data });
    tempList = [...new Set(tempList)];
    // get unread count from list and keyValue
    let calcUnreadCount = 0;
    tempList.forEach((item) => {
      if (state.locations[locationidentifier].engage[guestTypeKey].keyValue[item]) {
        if (state.locations[locationidentifier].engage[guestTypeKey].keyValue[item].unread_count) calcUnreadCount++;
      } else {
        if (item === guest_data._id) {
          if (guest_data.unread_count) calcUnreadCount++;
        }
      }
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('calcUnreadCount: ', calcUnreadCount);
    }
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestTypeKey]: {
              ...state.locations[locationidentifier].engage[guestTypeKey],
              list: tempList,
              keyValue: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                [guestId]: {
                  ...guest_data,
                }
              },
              unreadCount: calcUnreadCount,
            },
          },
        },
      },
    };
  }
  if (outside_guest_data) {
    let newUnreadCount = state.locations[locationidentifier].engage[guestTypeKey].unreadCount || 0;
    if (outside_guest_data.unread_count) {
      newUnreadCount++;
    }
    tempList = sortList(tempList, { ...state.locations[locationidentifier].engage[guestTypeKey].keyValue, [guestId]: outside_guest_data });
    tempList = [...new Set(tempList)];
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestTypeKey]: {
              ...state.locations[locationidentifier].engage[guestTypeKey],
              list: tempList,
              keyValue: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                [guestId]: {
                  ...outside_guest_data,
                }
              },
              unreadCount: newUnreadCount,
            },
          },
        },
      },
    };
  }
  return state;
};


/******************** End - Add Guest to list ********************/


/******************************* Start - handle engage pusher message *******************************/





export const handleEngageMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleEngageMessage: ', action.payload); // comment
  }
  const {
    type, data, loggedInUid, locationidentifier
  } = action.payload;
  if (
    !type ||
    (type !== 'guest-create' &&
      type !== 'guest-update' &&
      type !== 'outside-guest-create' &&
      type !== 'guest-message-create' &&
      type !== 'outside-guest-update' &&
      type !== 'outside-guest-update-only' &&
      type !== 'outside-guest-message-create' &&
      type !== 'message-update' &&
      type !== 'typing-update' &&
      type !== 'contact-typing-update' &&
      type !== 'guest-data-update'
    )
  ) { return state; }
  if (!data) return state;

  // for guest create / update
  if (type === 'guest-create' || type === 'guest-update') {
    return handleGuestCreateUpdateMessage(state, action);
  }

  // for outside guest create / update
  if (type === 'outside-guest-create' || type === 'outside-guest-update') {
    return handleOutsideGuestCreateUpdateMessage(state, action);
  }

  // for outside guest - update only
  if (type === 'outside-guest-update-only') {
    return handleOutsideGuestUpdateOnlyMessage(state, action);
  }

  // guest message create
  if (type === 'guest-message-create') {
    return handleGuestMessageCreateMessage(state, action);
  }

  // outside guest message create
  if (type === 'outside-guest-message-create') {
    return handleOutsideGuestMessageCreateMessage(state, action);
  }

  // message update
  if (type === 'message-update') {
    return handleMessageUpdateMessage(state, action);
  }

  // typing update
  if (type === 'typing-update') {
    return handleTypingUpdateMessage(state, action);
  }

  // guest typing update
  if (type === 'contact-typing-update') {
    return handleContactTypingUpdateMessage(state, action);
  }

  // guest data update
  if (type === 'guest-data-update') {
    return handleGuestDataUpdateMessage(state, action);
  }

  return state;
};

export const handleGuestCreateUpdateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleGuestCreateUpdateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;

  const { _id, location_id, transaction_type } = data;

  if (!_id || !location_id) return state;

  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;

  if (type === 'guest-create' || type === 'guest-update') {
    if (transaction_type === 'Reservation' || transaction_type === 'Booking' || transaction_type === 'Cancel') {
      // re-calculate the unreadCount for guests
      let unreadCount = engage.upcomingGuests.unreadCount;
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE original unreadCount: ', unreadCount);
      }
      if (engage.upcomingGuests.list.length) unreadCount = 0;
      engage.upcomingGuests.list.forEach((item) => {
        if (item === data._id) {
          if (data.unread_count) unreadCount++;
        } else {
          if (engage.upcomingGuests.keyValue[item] && engage.upcomingGuests.keyValue[item].unread_count) unreadCount++;
        }
      });
      if (!engage.upcomingGuests.list || !engage.upcomingGuests.list.length) {
        if (unreadCount) {
          unreadCount--;
        }
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE recalculated unreadCount: ', unreadCount);
      }
      const found = engage.upcomingGuests.list.find(elem => elem === data._id);
      if (!found) {
        let tempList = [data._id, ...engage.upcomingGuests.list];
        tempList = [...new Set(tempList)];
        tempList = sortList(tempList, { ...engage.upcomingGuests.keyValue, [data._id]: data });
        return {
          ...state,
          locations: {
            ...state.locations,
            [locationidentifier]: {
              ...state.locations[locationidentifier],
              engage: {
                ...state.locations[locationidentifier].engage,
                upcomingGuests: {
                  ...state.locations[locationidentifier].engage.upcomingGuests,
                  list: tempList,
                  keyValue: {
                    ...state.upcomingGuests.keyValue,
                    [data._id]: data,
                  },
                  unreadCount,
                },
              },
            },
          },
        };
      }
      // already exists
      return {
        ...state,
        locations: {
          ...state.locations,
          [locationidentifier]: {
            ...state.locations[locationidentifier],
            engage: {
              ...state.locations[locationidentifier].engage,
              upcomingGuests: {
                ...state.locations[locationidentifier].engage.upcomingGuests,
                keyValue: {
                  ...state.locations[locationidentifier].engage.upcomingGuests.keyValue,
                  [data._id]: {
                    ...state.locations[locationidentifier].engage.upcomingGuests.keyValue[data._id],
                    ...data,
                  },
                },
                unreadCount,
              },
            },
          },
        },
      };
    } // end of Reservation / Booking
    if (transaction_type === 'CheckOut') { // removed 'Cancel'
      let newState = { ...state };
      // re-calculate the unreadCount for pastGuests
      let unreadCount = engage.pastGuests.unreadCount;
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE original unreadCount: ', unreadCount);
      }
      if (engage.pastGuests.list.length) unreadCount = 0;
      engage.pastGuests.list.forEach((item) => {
        if (item === data._id) {
          if (data.unread_count) unreadCount++;
        } else {
          if (engage.pastGuests.keyValue[item] && engage.pastGuests.keyValue[item].unread_count) unreadCount++;
        }
      });
      if (!engage.pastGuests.list || !engage.pastGuests.list.length) {
        if (unreadCount) {
          unreadCount--;
        }
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE recalculated unreadCount: ', unreadCount);
      }
      // check in pastGuests
      let found = engage.pastGuests.list.find(elem => elem === data._id);
      if (!found) {
        let tempList = [data._id, ...engage.pastGuests.list];
        tempList = [...new Set(tempList)];
        tempList = sortList(tempList, { ...engage.pastGuests.keyValue, [data._id]: data });
        newState = {
          ...newState,
          locations: {
            ...newState.locations,
            [locationidentifier]: {
              ...newState.locations[locationidentifier],
              engage: {
                ...newState.locations[locationidentifier].engage,
                pastGuests: {
                  ...newState.locations[locationidentifier].engage.pastGuests,
                  list: tempList,
                  keyValue: {
                    ...newState.locations[locationidentifier].engage.pastGuests.keyValue,
                    [data._id]: data,
                  },
                  unreadCount,
                },
              },
            },
          },
        };
      } else {
        // already exists in past guests
        newState = {
          ...newState,
          locations: {
            ...newState.locations,
            [locationidentifier]: {
              ...newState.locations[locationidentifier],
              engage: {
                ...newState.locations[locationidentifier].engage,
                pastGuests: {
                  ...newState.locations[locationidentifier].engage.pastGuests,
                  keyValue: {
                    ...newState.locations[locationidentifier].engage.pastGuests.keyValue,
                    [data._id]: {
                      ...newState.locations[locationidentifier].engage.pastGuests.keyValue[data._id],
                      ...data,
                    },
                  },
                  unreadCount,
                },
              },
            },
          },
        };
      }
      // check in guests
      if (process.env.NODE_ENV !== 'production') {
        console.log('list: ', newState.guests.list);
      }
      found = engage.guests.list.find(elem => elem === data._id);
      if (process.env.NODE_ENV !== 'production') {
        console.log('found: ', found);
      }
      if (found) {
        let tempList = engage.guests.list.filter(elem => elem !== data._id);
        const { [data._id]: remove, ...tempKeyValue } = engage.guests.keyValue;
        if (process.env.NODE_ENV !== 'production') {
          console.log('tempKeyValue: ', tempKeyValue);
        }
        newState = {
          ...newState,
          locations: {
            ...newState.locations,
            [locationidentifier]: {
              ...newState.locations[locationidentifier],
              engage: {
                ...newState.locations[locationidentifier].engage,
                guests: {
                  ...newState.locations[locationidentifier].engage.guests,
                  list: tempList,
                  keyValue: tempKeyValue,
                },
              },
            },
          },
        };
      }
      return newState;
    } // end of CheckOut
    if (transaction_type === 'CheckIn') {
      // re-calculate the unreadCount for pastGuests
      let unreadCount = engage.guests.unreadCount;
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE original unreadCount: ', unreadCount);
      }
      if (engage.guests.list.length) unreadCount = 0;
      engage.guests.list.forEach((item) => {
        if (item === data._id) {
          if (data.unread_count) unreadCount++;
        } else {
          if (engage.guests.keyValue[item] && engage.guests.keyValue[item].unread_count) unreadCount++;
        }
      });
      if (!engage.guests.list || !engage.guests.list.length) {
        if (unreadCount) {
          unreadCount--;
        }
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE recalculated unreadCount: ', unreadCount);
      }
      const found = engage.guests.list.find(elem => elem === data._id);
      if (!found) {
        let tempList = [data._id, ...engage.guests.list];
        tempList = [...new Set(tempList)];
        tempList = sortList(tempList, { ...engage.guests.keyValue, [data._id]: data });
        return {
          ...state,
          locations: {
            ...state.locations,
            [locationidentifier]: {
              ...state.locations[locationidentifier],
              engage: {
                ...state.locations[locationidentifier].engage,
                guests: {
                  ...state.locations[locationidentifier].engage.guests,
                  list: tempList,
                  keyValue: {
                    ...state.locations[locationidentifier].engage.guests.keyValue,
                    [data._id]: data,
                  },
                  unreadCount,
                },
              },
            },
          },
        };
      }
      // already exists
      return {
        ...state,
        locations: {
          ...state.locations,
          [locationidentifier]: {
            ...state.locations[locationidentifier],
            engage: {
              ...state.locations[locationidentifier].engage,
              guests: {
                ...state.locations[locationidentifier].engage.guests,
                keyValue: {
                  ...state.locations[locationidentifier].engage.guests.keyValue,
                  [data._id]: {
                    ...state.locations[locationidentifier].engage.guests.keyValue[data._id],
                    ...data,
                  },
                },
                unreadCount,
              },
            },
          },
        },
      };
    } // end of CheckIn
  }
};

// outside guest - create / update message
export const handleOutsideGuestCreateUpdateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleOutsideGuestCreateUpdateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;

  let { outside_guest, message } = data;
  if (message && outside_guest) {
    let outside_guest_name = '';
    const { msg } = message;
    const { _id, location_id, outside_guest_id } = message;
    if (process.env.NODE_ENV !== 'production') {
      console.log('outside_guest_id: ', outside_guest_id);
    }
    if (!_id || !location_id || !outside_guest_id) return state;

    let engage = {};
    if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
      engage = { ...state.locations[locationidentifier].engage };
    }
    if (!engage || !Object.keys(engage).length) return state;

    let guestTypeKey = 'outsideGuests';
    if (outside_guest.source === 'webtext') { guestTypeKey = 'webtext'; }
    if (outside_guest.source === 'bm') { guestTypeKey = 'gbm'; }
    else if (outside_guest.is_staff) { guestTypeKey = 'staff'; }


    if (!engage[guestTypeKey].keyValue[outside_guest_id]) { // add
      let tempList = [outside_guest._id, ...engage[guestTypeKey].list];
      tempList = [...new Set(tempList)];
      tempList = sortList(tempList, { ...engage[guestTypeKey].keyValue, [outside_guest._id]: outside_guest });
      let newUnreadCount = engage[guestTypeKey].unreadCount || 0;
      if (message.type === 1) newUnreadCount++;
      return {
        ...state,
        locations: {
          ...state.locations,
          [locationidentifier]: {
            ...state.locations[locationidentifier],
            engage: {
              ...state.locations[locationidentifier].engage,
              [guestTypeKey]: {
                ...state.locations[locationidentifier].engage[guestTypeKey],
                list: tempList,
                keyValue: {
                  ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                  [outside_guest_id]: {
                    ...outside_guest, // ...state.outsideGuests.keyValue[outside_guest_id],
                    latest_msg: msg,
                    date_last_message: message.date_created,
                    date_last_message_sent: message.date_created,
                    messages: [message],
                    unread_count: message.type === 1 ? 1 : 0,
                  },
                },
                unreadCount: newUnreadCount,
              },
              alertSoundStatus: 'play',
            },
          },
        },
      };
    } else { // update
      // re-calculate the unreadCount for pastGuests
      let unreadCount = engage[guestTypeKey].unreadCount;
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE original unreadCount: ', unreadCount);
      }
      if (engage[guestTypeKey].list.length) unreadCount = 0;
      engage[guestTypeKey].list.forEach((item) => {
        if (item === data._id) {
          if (data.unread_count) unreadCount++;
        } else {
          if (engage[guestTypeKey].keyValue[item] && engage[guestTypeKey].keyValue[item].unread_count) unreadCount++;
        }
      });
      if (!engage[guestTypeKey].list || !engage[guestTypeKey].list.length) {
        if (unreadCount) {
          unreadCount--;
        }
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('HANDLE_MESSAGE recalculated unreadCount: ', unreadCount);
      }
      return {
        ...state,
        locations: {
          ...state.locations,
          [locationidentifier]: {
            ...state.locations[locationidentifier],
            engage: {
              ...state.locations[locationidentifier].engage,
              [guestTypeKey]: {
                ...state.locations[locationidentifier].engage[guestTypeKey],
                keyValue: {
                  ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                  [outside_guest_id]: {
                    ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[outside_guest_id],
                    ...outside_guest,
                  },
                },
                unreadCount,
              },
            },
          },
        },
      };
    }
  }
  return state;
}


// outside guest - update only
export const handleOutsideGuestUpdateOnlyMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleOutsideGuestUpdateOnlyMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;

  const { _id, location_id } = data;
  if (!_id || !location_id) return state;

  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;

  if (process.env.NODE_ENV !== 'production') {
    console.log('outside-guest-update-only outside_guest_id: ', _id);
  }
  let guestTypeKey = 'outsideGuests';
  if (data.source === 'webtext') { guestTypeKey = 'webtext'; }
  if (data.source === 'bm') { guestTypeKey = 'gbm'; }
  else if (data.is_staff) { guestTypeKey = 'staff'; }


  // re-calculate the unreadCount for guests
  let unreadCount = engage[guestTypeKey].unreadCount;
  if (process.env.NODE_ENV !== 'production') {
    console.log('HANDLE_MESSAGE original unreadCount: ', unreadCount);
  }
  if (engage[guestTypeKey].list.length) unreadCount = 0;
  engage[guestTypeKey].list.forEach((item) => {
    if (item === data._id) {
      if (data.unread_count) unreadCount++;
    } else {
      if (engage[guestTypeKey].keyValue[item] && engage[guestTypeKey].keyValue[item].unread_count) unreadCount++;
    }
  });
  if (!engage[guestTypeKey].list || !engage[guestTypeKey].list.length) {
    if (unreadCount) {
      unreadCount--;
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('HANDLE_MESSAGE recalculated unreadCount: ', unreadCount);
  }
  const found = engage[guestTypeKey].list.find(elem => elem === data._id);
  if (!found) {
    let tempList = [data._id, ...engage[guestTypeKey].list];
    tempList = [...new Set(tempList)];
    tempList = sortList(tempList, { ...engage[guestTypeKey].keyValue, [data._id]: data });
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestTypeKey]: {
              ...state.locations[locationidentifier].engage[guestTypeKey],
              list: tempList,
              keyValue: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                [data._id]: data,
              },
              unreadCount,
            },
          },
        },
      },
    };
  }
  // already exists
  return {
    ...state,
    locations: {
      ...state.locations,
      [locationidentifier]: {
        ...state.locations[locationidentifier],
        engage: {
          ...state.locations[locationidentifier].engage,
          [guestTypeKey]: {
            ...state.locations[locationidentifier].engage[guestTypeKey],
            keyValue: {
              ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
              [data._id]: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[data._id],
                ...data,
              },
            },
            unreadCount,
          },
        },
      },
    },
  };
  return state;
}


// guest message create
export const handleGuestMessageCreateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleGuestMessageCreateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;

  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;

  const { message } = data;
  const { msg, name } = message;
  // let guest_name = '';
  const {
    _id,
    location_id,
    guest_id,
    new_msg_id,
    // outside_guest_id,
  } = message;
  if (process.env.NODE_ENV !== 'production') {
    console.log('_id,  location_id,guest_id,: ', _id, location_id, guest_id);
  }
  if (!_id || !location_id || !guest_id) return state;

  let guestType = null;
  if (engage.guests.keyValue[guest_id] && engage.guests.keyValue[guest_id].transaction_type === 'CheckIn') { guestType = 'guests'; }
  else if (engage.pastGuests.keyValue[guest_id] && engage.pastGuests.keyValue[guest_id].transaction_type === 'CheckOut') { guestType = 'pastGuests'; }
  else if (engage.upcomingGuests.keyValue[guest_id]) { guestType = 'upcomingGuests'; }
  if (process.env.NODE_ENV !== 'production') {
    console.log('guest-message-create guestType: ', guestType);
  }
  if (!guestType) return state;

  const existingMessages = engage[guestType].keyValue[guest_id].messages;
  if (existingMessages && existingMessages.find(el => el._id === _id || el._id === new_msg_id)) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('message already exists');
    }
    return state;
  }

  let newUnreadCount = engage[guestType].unreadCount;
  if (message.type === 1) {
    if (!engage[guestType].keyValue[guest_id].unread_count) {
      newUnreadCount++;
    }
  }

  let newUnreadCountGuest = engage[guestType].keyValue[guest_id].unread_count || 0;
  if (message.type === 1) {
    newUnreadCountGuest++;
  }

  let optOutValue = engage[guestType].keyValue[guest_id].opt_out || false;
  if (message.is_stop_msg) { optOutValue = true; }
  if (message.is_start_msg) { optOutValue = false; }

  return {
    ...state,
    locations: {
      ...state.locations,
      [locationidentifier]: {
        ...state.locations[locationidentifier],
        engage: {
          ...state.locations[locationidentifier].engage,
          [guestType]: {
            ...state.locations[locationidentifier].engage[guestType],
            keyValue: {
              ...state.locations[locationidentifier].engage[guestType].keyValue,
              [guest_id]: {
                ...state.locations[locationidentifier].engage[guestType].keyValue[guest_id],
                opt_out: optOutValue,
                latest_msg: msg,
                date_last_message: message.date_created,
                date_last_message_sent: message.date_created,
                messages: state.locations[locationidentifier].engage[guestType].keyValue[guest_id].messages ? [message, ...state.locations[locationidentifier].engage[guestType].keyValue[guest_id].messages] : [message],
                unread_count: newUnreadCountGuest, // message.type === 1 ? state[guestType].keyValue[guest_id].unread_count+1 : state[guestType].keyValue[guest_id].unread_count,
              },
            },
            unreadCount: newUnreadCount, // message.type === 1 ? state[guestType].unreadCount+1 : state[guestType].unreadCount,
          },
          alertSoundStatus: message.type === 1 ? 'play' : 'played',
        },
      },
    },
  };
}


// outside guest message create
export const handleOutsideGuestMessageCreateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleOutsideGuestMessageCreateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;

  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;

  const { message, guest_type } = data;
  // let outside_guest_name = '';
  const { msg, name } = message;
  const { _id, location_id, outside_guest_id, new_msg_id } = message;
  if (process.env.NODE_ENV !== 'production') {
    console.log('outside_guest_id: ', outside_guest_id);
  }
  if (!_id || !location_id || !outside_guest_id) return state;

  let guestTypeKey = null;
  if (engage.outsideGuests.keyValue[outside_guest_id]) { guestTypeKey = 'outsideGuests'; }
  else if (engage.staff.keyValue[outside_guest_id]) { guestTypeKey = 'staff'; }
  else if (engage.webtext.keyValue[outside_guest_id]) { guestTypeKey = 'webtext'; }
  else if (engage.gbm.keyValue[outside_guest_id]) { guestTypeKey = 'gbm'; }

  if (guest_type === 'staff') {
    guestTypeKey = 'staff';
  }
  if (guest_type === 'webtext') {
    guestTypeKey = 'webtext';
  }
  if (guest_type === 'gbm') {
    guestTypeKey = 'gbm';
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('outside-guest-message-create guestTypeKey: ', guestTypeKey);
  }
  if (!guestTypeKey) return state;

  const existingMessages = engage[guestTypeKey].keyValue[outside_guest_id].messages;
  if (existingMessages && existingMessages.find(el => el._id === _id || el._id === new_msg_id)) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('message already exists');
    }
    return state;
  }

  let newUnreadCount = engage[guestTypeKey].unreadCount;
  if (message.type === 1) {
    if (!engage[guestTypeKey].keyValue[outside_guest_id].unread_count) {
      newUnreadCount++;
    }
  }

  let newUnreadCountOutsideGuest = engage[guestTypeKey].keyValue[outside_guest_id].unread_count || 0;
  if (message.type === 1) {
    newUnreadCountOutsideGuest++;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('message.is_stop_msg: ', message.is_stop_msg);
    console.log('message.is_start_msg: ', message.is_start_msg);
  }
  let optOutValue = engage[guestTypeKey].keyValue[outside_guest_id].opt_out || false;
  if (message.is_stop_msg) { optOutValue = true; }
  if (message.is_start_msg) { optOutValue = false; }
  if (process.env.NODE_ENV !== 'production') {
    console.log('optOutValue: ', optOutValue);
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [locationidentifier]: {
        ...state.locations[locationidentifier],
        engage: {
          ...state.locations[locationidentifier].engage,
          [guestTypeKey]: {
            ...state.locations[locationidentifier].engage[guestTypeKey],
            keyValue: {
              ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
              [outside_guest_id]: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[outside_guest_id],
                opt_out: optOutValue,
                editInProgress: false,
                editStatus: {},
                is_removed: false,
                editRemoveInProgress: false,
                editRemoveStatus: {},
                latest_msg: msg,
                date_last_message: message.date_created,
                date_last_message_received: message.date_created,
                messages: state.locations[locationidentifier].engage[guestTypeKey].keyValue[outside_guest_id].messages
                  ? [message, ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[outside_guest_id].messages]
                  : [message],
                unread_count: newUnreadCountOutsideGuest, // message.type === 1 ? state[guestTypeKey].keyValue[outside_guest_id].unread_count+1 : state[guestTypeKey].keyValue[outside_guest_id].unread_count,
              },
            },
            unreadCount: newUnreadCount, // message.type === 1 ? state[guestTypeKey].unreadCount+1 : state[guestTypeKey].unreadCount,
          },
          alertSoundStatus: message.type === 1 ? 'play' : 'played',
        },
      },
    },
  };
}


// message update
export const handleMessageUpdateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleMessageUpdateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;

  const { message, messages, session_id } = data;
  if (message) {

    let engage = {};
    if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
      engage = { ...state.locations[locationidentifier].engage };
    }
    if (!engage || !Object.keys(engage).length) return state;

    const {
      _id, guest_id, outside_guest_id, status, guest_type
    } = message;
    if (process.env.NODE_ENV !== 'production') {
      console.log('guest_id: ', guest_id);
      console.log('status: ', status);
    }

    let guestType = null;
    let guestId = null;
    if (guest_id) {
      if (engage.guests.keyValue[guest_id]) { guestType = 'guests'; guestId = guest_id; }
      else if (engage.pastGuests.keyValue[guest_id]) { guestType = 'pastGuests'; guestId = guest_id; }
      else if (engage.upcomingGuests.keyValue[guest_id]) { guestType = 'upcomingGuests'; guestId = guest_id; }
    }
    if (outside_guest_id) {
      if (engage.outsideGuests.keyValue[outside_guest_id]) { guestType = 'outsideGuests'; guestId = outside_guest_id; }
      else if (engage.webtext.keyValue[outside_guest_id]) { guestType = 'webtext'; guestId = outside_guest_id; }
      else if (engage.gbm.keyValue[outside_guest_id]) { guestType = 'gbm'; guestId = outside_guest_id; }
      else if (engage.staff.keyValue[outside_guest_id]) { guestType = 'staff'; guestId = outside_guest_id; }

      if (guest_type === 'webtext') { guestType = 'webtext'; }
      if (guest_type === 'staff') { guestType = 'staff'; }
      if (guest_type === 'gbm') { guestType = 'gbm'; }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('guestType: ', guestType);
      console.log('guestId: ', guestId);
    }
    if (!guestType || !guestId) return state;

    let cMessages = [];
    if (engage[guestType].keyValue[guestId] && engage[guestType].keyValue[guestId].messages) {
      cMessages = [ ...engage[guestType].keyValue[guestId].messages ];
    }
    // if received message update is found in messages
    if (cMessages.find(el => el._id === message._id)) {
      cMessages = cMessages.map((item) => {
        if (item._id === message._id) {
          return {
            ...item,
            ...message,
            status: item.status !== 'delivered' ? status : item.status,
          }
        }
        return item;
      });
    } else { // if not, then add it
      if (cMessages.length) {
        cMessages = [message, ...cMessages];
      } else {
        cMessages = [message];
      }
    }
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestType]: {
              ...state.locations[locationidentifier].engage[guestType],
              keyValue: {
                ...state.locations[locationidentifier].engage[guestType].keyValue,
                [guestId]: {
                  ...state.locations[locationidentifier].engage[guestType].keyValue[guestId],
                  messages: [ ...cMessages ],
                },
              },
            },
          },
        },
      },
    };
  }
  if (messages && messages.length) {

    let engage = {};
    if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
      engage = { ...state.locations[locationidentifier].engage };
    }
    if (!engage || !Object.keys(engage).length) return state;

    const {
      _id, guest_id, outside_guest_id, status, guest_type
    } = messages[0];
    if (process.env.NODE_ENV !== 'production') {
      console.log('guest_id: ', guest_id);
      console.log('status: ', status);
    }

    let guestType = null;
    let guestId = null;
    if (guest_id) {
      if (engage.guests.keyValue[guest_id]) { guestType = 'guests'; guestId = guest_id; }
      else if (engage.pastGuests.keyValue[guest_id]) { guestType = 'pastGuests'; guestId = guest_id; }
      else if (engage.upcomingGuests.keyValue[guest_id]) { guestType = 'upcomingGuests'; guestId = guest_id; }
    }
    if (outside_guest_id) {
      if (engage.outsideGuests.keyValue[outside_guest_id]) { guestType = 'outsideGuests'; guestId = outside_guest_id; }
      else if (engage.webtext.keyValue[outside_guest_id]) { guestType = 'webtext'; guestId = outside_guest_id; }
      else if (engage.gbm.keyValue[outside_guest_id]) { guestType = 'gbm'; guestId = outside_guest_id; }
      else if (engage.staff.keyValue[outside_guest_id]) { guestType = 'staff'; guestId = outside_guest_id; }

      if (guest_type === 'webtext') { guestType = 'webtext'; }
      if (guest_type === 'staff') { guestType = 'staff'; }
      if (guest_type === 'gbm') { guestType = 'gbm'; }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('guestType: ', guestType);
      console.log('guestId: ', guestId);
    }
    if (!guestType || !guestId) return state;
    let newUnreadCount = engage[guestType].keyValue[guestId].unread_count;
    let newGuestsUnreadCount = engage[guestType].unreadCount;
    let readCount = 0;
    messages.forEach((el) => {
      if (el.read) readCount++;
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('readCount: ', readCount);
      console.log('gtSessionId: ', window.gtSessionId);
    }
    // if (gtSessionId && session_id && gtSessionId === session_id) {
    //   if (process.env.NODE_ENV !== 'production') {
    //     console.log('skip updating unread count');
    //   }
    //   readCount = 0;
    // }
    if (readCount) {
      newUnreadCount = newUnreadCount - readCount; // messages.length;
      if (newUnreadCount < 0) newUnreadCount = 0;
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestType]: {
              ...state.locations[locationidentifier].engage[guestType],
              keyValue: {
                ...state.locations[locationidentifier].engage[guestType].keyValue,
                [guestId]: {
                  ...state.locations[locationidentifier].engage[guestType].keyValue[guestId],
                  unread_count: newUnreadCount,
                },
              },
              unreadCount: newGuestsUnreadCount,
            },
          },
        },
      },
    };
  }
  return state;
}



// typing update
export const handleTypingUpdateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleTypingUpdateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;


  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;


  const { location_id, guest_id, outside_guest_id, uid, name, typing_status, date_typing_status, guest_type } = data;
  if (loggedInUid === uid) return state;
  if (location_id && (guest_id || outside_guest_id) && uid && typing_status && guest_type) {
    const guestTypeKey = getGuestTypeKey(guest_type);
    if (process.env.NODE_ENV !== 'production') {
      console.log('guestTypeKey: ', guestTypeKey);
    }
    if (!guestTypeKey) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestTypeKey]: {
              ...state.locations[locationidentifier].engage[guestTypeKey],
              keyValue: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                [guest_id || outside_guest_id]: {
                  ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                  date_typing_status: new Date(date_typing_status),
                  typing_status: typing_status,
                  typing_by: uid,
                  typing_by_name: name,
                  typing_status_message: uid,
                },
              },
            },
          },
        },
      },
    };
  }
} // end of handleTypingUpdateMessage


// guest/contact typing update
export const handleContactTypingUpdateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleContactTypingUpdateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;


  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;

  const { location_id, outside_guest_id, name, is_typing, guest_type } = data;
  if (location_id && outside_guest_id && name && guest_type && 'is_typing' in data) {
    const guestTypeKey = getGuestTypeKey(guest_type);
    if (process.env.NODE_ENV !== 'production') {
      console.log('guestTypeKey: ', guestTypeKey);
    }
    if (!guestTypeKey) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestTypeKey]: {
              ...state.locations[locationidentifier].engage[guestTypeKey],
              keyValue: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                [outside_guest_id]: {
                  ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[outside_guest_id],
                  is_typing: is_typing,
                },
              },
            },
          },
        },
      },
    };
  }
} // end of handleContactTypingUpdateMessage



// guest/contact typing update
export const handleGuestDataUpdateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleGuestDataUpdateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;
  if (!locationidentifier) return state;


  let engage = {};
  if (state.locations && state.locations[locationidentifier] && state.locations[locationidentifier].engage) {
    engage = { ...state.locations[locationidentifier].engage };
  }
  if (!engage || !Object.keys(engage).length) return state;

  const { location_id, guest_data, guest_type } = data;
  if (location_id && guest_data && guest_data._id && guest_type) {
    const guestTypeKey = getGuestTypeKey(guest_type);
    if (process.env.NODE_ENV !== 'production') {
      console.log('guestTypeKey: ', guestTypeKey);
    }
    if (!guestTypeKey) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [locationidentifier]: {
          ...state.locations[locationidentifier],
          engage: {
            ...state.locations[locationidentifier].engage,
            [guestTypeKey]: {
              ...state.locations[locationidentifier].engage[guestTypeKey],
              keyValue: {
                ...state.locations[locationidentifier].engage[guestTypeKey].keyValue,
                [guest_data._id]: {
                  ...state.locations[locationidentifier].engage[guestTypeKey].keyValue[guest_data._id],
                  ...guest_data,
                },
              },
            },
          },
        },
      },
    };
  }
} // end of handleGuestDataUpdateMessage




/******************************* End - handle engage pusher message *******************************/
