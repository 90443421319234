import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import { ACCESS_LEVELS, validateEmail, PRODUCT_TO_LABELS, REPUTATION_FEATURES_TO_LABELS, ENGAGE_FEATURES_TO_LABELS, MARKETING_FEATURES_TO_LABELS,
  curUserHasAccessToOtherUserByAccessLevel, ACCESS_LEVELS_TO_LABEL, isPasswordStrong,
  getHighestAccessLevels, isOwnerAndAbove, isGroupAdminAndAbove, isLocationAdminAndAbove,
  isPartnerManagerAndAbove, isPartnerAdminAndAbove } from '../../../../../helpers';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import '../style.css';

const featureSetReputationOptions = [];
let featureId = 'respond-to-reviews';
featureSetReputationOptions.push({ label: REPUTATION_FEATURES_TO_LABELS[featureId], value: featureId });
featureId = 'run-feedback-campaign';
featureSetReputationOptions.push({ label: REPUTATION_FEATURES_TO_LABELS[featureId], value: featureId });

const featureSetEngageOptions = [];
let featureIdList = [
  'engage-basic',
  'reply-to-guest-messages',
  'reply-to-webchat-messages',
  'broadcast',
];
featureIdList.forEach((featureId) => {
  featureSetEngageOptions.push({ label: ENGAGE_FEATURES_TO_LABELS[featureId], value: featureId });
});

const featureSetMarketingOptions = [];
featureIdList = [
  'manage-transaction-emails',
  // 'manage-email-marketing',
];
featureIdList.forEach((featureId) => {
  featureSetMarketingOptions.push({ label: MARKETING_FEATURES_TO_LABELS[featureId], value: featureId });
});

function FeatureTab(props) {
  const onRemoveClick = (e) => {
    props.onRemoveClick(feature);
  }
  const { feature } = props;
  if (process.env.NODE_ENV !== 'production') {
    console.log('FeatureTab feature: ', feature);
  }
  return (
    <div className="fetrEntry">
      {feature.label || ''}
      <span onClick={onRemoveClick}><i className="fa fa-times" /></span>
    </div>
  );
}


function HlLocationRow(props) {
  const onRemoveClick = (e) => {
    props.onRemoveClick(props.locName, props.locMId);
  }
  const { locName, locMId } = props;
  if (process.env.NODE_ENV !== 'production') {
    console.log('HlLocationRow locMId: ', locMId);
  }
  return (
    <div className="fetrEntry">
      <span onClick={onRemoveClick}><i className="fa fa-times" /></span>
      {locName || ''}
    </div>
  );
}


function LocationProductRow(props) {
  const [accessLevel, setAccessLevel] = useState('');

  const [accessLevelOptions, setAccessLevelOptions] = useState([]);
  const [accessLevelValue, setAccessLevelValue] = useState(
    { label: 'Select access level', value: 'select-access-level' }
  );
  const [accessLevelChanged, setAccessLevelChanged] = useState(false);
  const [accessLevelError, setAccessLevelError] = useState(false);

  const [featureOptions, setFeatureOptions] = useState([]);
  const [featureValue, setFeatureValue] = useState(
    { label: 'Select feature', value: 'select-feature' },
  );
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [featuresChanged, setFeaturesChanged] = useState(false);
  const [selectedFeaturesError, setSelectedFeaturesError] = useState(false);

  const [productData, setProductData] = useState({});

  useEffect(() => {
    props.onChange(productData);
  }, [productData]);

  const updateAccessLevelOptions = () => {
    const { product, curUserProfile, LocData } = props;
    const { accessLevel } = getHighestAccessLevels(props.userState.user.user_info, props.locationInfo._id);
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateAccessLevelOptions curUserProfileLocData: ', curUserProfileLocData);
    }
    let tempAccessLevelOptions = [];
    if (accessLevel) {
      if (
        accessLevel === ACCESS_LEVELS.GT_ADMIN ||
        accessLevel === ACCESS_LEVELS.PARTNER_ADMIN ||
        accessLevel === ACCESS_LEVELS.PARTNER_MANAGER ||
        accessLevel === ACCESS_LEVELS.OWNER ||
        accessLevel === ACCESS_LEVELS.GROUP_ADMIN
      ){
        tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
        tempAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
        tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
      }
      // if (accessLevel === ACCESS_LEVELS.GROUP_ADMIN){
      //   tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
      //   tempAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
      //   tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
      // }
      if (accessLevel === ACCESS_LEVELS.LOCATION_ADMIN){
        tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
        tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
      }
    }
    /*if (curUserProfile.access_level) {
      if (curUserProfile.access_level === ACCESS_LEVELS.GT_ADMIN){
        tempAccessLevelOptions = [ ...accessLevelOptionsG ];
      }
      if (curUserProfile.access_level === ACCESS_LEVELS.GROUP_ADMIN){
        tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
        tempAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
        tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
      }
    } else {
      if (curUserProfileLocData.access_level) {
        if (curUserProfileLocData.access_level === ACCESS_LEVELS.GT_ADMIN){
          tempAccessLevelOptions = [ ...accessLevelOptionsG ];
        }
        if (curUserProfileLocData.access_level === ACCESS_LEVELS.GROUP_ADMIN){
          tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
          tempAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
          tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
        }
      } else {
        if (curUserProfileLocData[product]) {
          if (curUserProfileLocData[product].access_level === ACCESS_LEVELS.GT_ADMIN){
            tempAccessLevelOptions = [ ...accessLevelOptionsG ];
          }
          if (curUserProfileLocData[product].access_level === ACCESS_LEVELS.GROUP_ADMIN){
            tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
            tempAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
            tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
          }
          if (curUserProfileLocData[product].access_level === ACCESS_LEVELS.LOCATION_ADMIN){
            tempAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
            tempAccessLevelOptions.push({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
          }
        }
      }
    }*/
    setAccessLevelOptions([
      ...tempAccessLevelOptions,
    ]);
  };

  useEffect(() => {
    const { locData, curUserProfileLocData } = props;
    if (process.env.NODE_ENV !== 'production') {
      console.log('LocationRow curUserProfileLocData: ', curUserProfileLocData);
    }
    const { product } = props;
    if (locData && locData[product]) {
      setProductData({ ...locData[product] });
      setAccessLevel(locData[product].access_level || '')
      if (locData[product].access_level === ACCESS_LEVELS.GT_ADMIN) {
        setAccessLevelValue({ label: 'Gt Admin', value: ACCESS_LEVELS.GT_ADMIN });
      };
      if (locData[product].access_level === ACCESS_LEVELS.GT_VIEW_ADMIN) {
        setAccessLevelValue({ label: 'Gt View Admin', value: ACCESS_LEVELS.GT_VIEW_ADMIN });
      };
      if (locData[product].access_level === ACCESS_LEVELS.GROUP_ADMIN) {
        setAccessLevelValue({ label: 'Group Admin', value: ACCESS_LEVELS.GROUP_ADMIN });
      }
      if (locData[product].access_level === ACCESS_LEVELS.LOCATION_ADMIN) {
        setAccessLevelValue({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
      }
      if (locData[product].access_level === ACCESS_LEVELS.GENERIC_USER) {
        setAccessLevelValue({ label: 'Generic User', value: ACCESS_LEVELS.GENERIC_USER });
      }
    }
    updateAccessLevelOptions();

    // features
    let tempSelectedFeatures = [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('locData: ', locData);
    }
    if (locData && locData[product] && locData[product].features) {
      Object.keys(locData[product].features).forEach((featureId) => {
        if (product === 'reputation') {
          tempSelectedFeatures.push({ label: REPUTATION_FEATURES_TO_LABELS[featureId] || featureId, value: featureId })
        }
        if (product === 'engage') {
          tempSelectedFeatures.push({ label: ENGAGE_FEATURES_TO_LABELS[featureId] || featureId, value: featureId })
        }
        if (product === 'marketing') {
          tempSelectedFeatures.push({ label: MARKETING_FEATURES_TO_LABELS[featureId] || featureId, value: featureId })
        }
      });
    }
    setSelectedFeatures(tempSelectedFeatures);


    let tempFeatureSetOptions = [];
    if (product === 'reputation') {
      tempFeatureSetOptions = [ ...featureSetReputationOptions ];
    }
    if (product === 'engage') {
      tempFeatureSetOptions = [ ...featureSetEngageOptions ];
    }
    if (product === 'marketing') {
      tempFeatureSetOptions = [ ...featureSetMarketingOptions ];
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('tempFeatureSetOptions: ', tempFeatureSetOptions);
      console.log('tempSelectedFeatures: ', tempSelectedFeatures);
    }
    tempFeatureSetOptions = tempFeatureSetOptions.filter((item) => {
      if (tempSelectedFeatures.find(el => el.value === item.value)) {
        return false;
      }
      return true;
    });
    setFeatureOptions([
      { label: 'Select feature', value: 'select-feature' },
      ...tempFeatureSetOptions,
      // { label: 'Wifi', value: '{{wifi}}' },
    ]);


  }, []);

  const onAccessLevelChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAccessLevelChange value: ', value);
    }
    setAccessLevelValue(value);
    setAccessLevel(value.value);
    setProductData({
      ...productData,
      access_level: value.value === 'select-access-level' ? '' : value.value,
    })
    if (value.value === ACCESS_LEVELS.GENERIC_USER) {
      let tempSelectedFeatures = [ ...selectedFeatures ];

      // update feature options
      const { product } = props;
      let tempFeatureSetOptions = [];
      if (product === 'reputation') {
        tempFeatureSetOptions = [ ...featureSetReputationOptions ];
      }
      if (product === 'engage') {
        tempFeatureSetOptions = [ ...featureSetEngageOptions ];
      }
      if (product === 'marketing') {
        tempFeatureSetOptions = [ ...featureSetMarketingOptions ];
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('tempFeatureSetOptions: ', tempFeatureSetOptions);
        console.log('tempSelectedFeatures: ', tempSelectedFeatures);
      }
      tempFeatureSetOptions = tempFeatureSetOptions.filter((item) => {
        if (tempSelectedFeatures.find(el => el.value === item.value)) {
          return false;
        }
        return true;
      });
      setFeatureOptions([
        { label: 'Select feature', value: 'select-feature' },
        ...tempFeatureSetOptions,
        // { label: 'Wifi', value: '{{wifi}}' },
      ]);
    }
    props.onSomeChange();
  };

  const onFeatureRemoveClick = (feature) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFeatureRemoveClick feature: ', feature);
    }
    let tempSelectedFeatures = selectedFeatures.filter(el => el.value !== feature.value);
    setSelectedFeatures(tempSelectedFeatures);

    let tempFeatures = { ...productData.features };
    delete tempFeatures[feature.value];

    setProductData({
      ...productData,
      features: {
        // ...productData.features,
        // [feature.value]: false,
        ...tempFeatures,
      },
    });

    // update feature options
    const { product } = props;
    let tempFeatureSetOptions = [];
    if (product === 'reputation') {
      tempFeatureSetOptions = [ ...featureSetReputationOptions ];
    }
    if (product === 'engage') {
      tempFeatureSetOptions = [ ...featureSetEngageOptions ];
    }
    if (product === 'marketing') {
      tempFeatureSetOptions = [ ...featureSetMarketingOptions ];
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('tempFeatureSetOptions: ', tempFeatureSetOptions);
      console.log('tempSelectedFeatures: ', tempSelectedFeatures);
    }
    tempFeatureSetOptions = tempFeatureSetOptions.filter((item) => {
      if (tempSelectedFeatures.find(el => el.value === item.value)) {
        return false;
      }
      return true;
    });
    setFeatureOptions([
      { label: 'Select feature', value: 'select-feature' },
      ...tempFeatureSetOptions,
      // { label: 'Wifi', value: '{{wifi}}' },
    ]);
    props.onSomeChange();
  };

  const onFeatureSelectValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFeatureSelectValueChange value: ', value);
    }
    // setPersonalizeValue(value);
    if (value.value !== 'select-feature') {
      setSelectedFeatures(selectedFeatures => [...selectedFeatures, value]);
      setFeatureOptions(featureOptions => featureOptions.filter(el => el.value !== value.value));
      setFeatureValue({ label: 'Select feature', value: 'select-feature' });
      setSelectedFeaturesError(false);
      setProductData({
        ...productData,
        features: productData.features
        ?
          {
            ...productData.features,
            [value.value]: true,
          }
        : {
            [value.value]: true,
          }
      });
    }
    setFeaturesChanged(true);
    // if (Object.keys(props.addEditStatus).length) {
    //   props.onAddEditClear(props.template);
    // }
    props.onSomeChange();
  };

  const onEnableAccess = (e) => {
    setAccessLevel('select');
    updateAccessLevelOptions();
    props.onSomeChange();
  };
  const onRemoveAccess = (e) => {
    if (accessLevelValue.value === ACCESS_LEVELS.GT_VIEW_ADMIN || accessLevelValue.value === ACCESS_LEVELS.GT_ADMIN) return;
    setAccessLevel('');
    setProductData({
      ...productData,
      access_level: '',
    });
    props.onSomeChange();
  }

  const { locData, locName, product, curUserProfileLocData } = props;
  let accessLevelIsDisabled = false;
  // check access level based on produc level access of said user and logged in user
  const { accessLevel: accessLevelNew, accessLevelReputation, accessLevelEngage, accessLevelMarketing } = getHighestAccessLevels(props.userState.user.user_info, props.locationInfo._id);
  // if (!accessLevelIsDisabled) {
  //   if (accessLevelValue.value !== 'select-access-level' && curUserProfileLocData[product] && curUserProfileLocData[product].access_level) {
  //     accessLevelIsDisabled = !curUserHasAccessToOtherUserByAccessLevel(curUserProfileLocData[product].access_level, accessLevelValue.value);
  //   }
  // }
  if (!accessLevelIsDisabled) {
    if (accessLevelValue.value !== 'select-access-level' && accessLevelEngage) {
      accessLevelIsDisabled = !curUserHasAccessToOtherUserByAccessLevel(accessLevelEngage, accessLevelValue.value);
    }
  }
  // if logged in user is admin, they will always have access
  // if (curUserProfileLocData.access_level === ACCESS_LEVELS.GT_ADMIN || curUserProfileLocData.access_level === ACCESS_LEVELS.GROUP_ADMIN) {
  //   accessLevelIsDisabled = false;
  // }
  if (product === 'reputation') {
    if (accessLevelEngage === ACCESS_LEVELS.GT_ADMIN ||
      accessLevelEngage === ACCESS_LEVELS.PARTNER_ADMIN ||
      accessLevelEngage === ACCESS_LEVELS.PARTNER_MANAGER ||
      accessLevelEngage === ACCESS_LEVELS.OWNER ||
      accessLevelEngage === ACCESS_LEVELS.GROUP_ADMIN
    ) {
      accessLevelIsDisabled = false;
    }
    // if the said user is GT user, access will be disabled
    accessLevelIsDisabled = accessLevelReputation === ACCESS_LEVELS.GT_VIEW_ADMIN || accessLevelReputation === ACCESS_LEVELS.GT_ADMIN
      ? true : accessLevelIsDisabled;
  }
  if (product === 'engage') {
    if (accessLevelEngage === ACCESS_LEVELS.GT_ADMIN ||
      accessLevelEngage === ACCESS_LEVELS.PARTNER_ADMIN ||
      accessLevelEngage === ACCESS_LEVELS.PARTNER_MANAGER ||
      accessLevelEngage === ACCESS_LEVELS.OWNER ||
      accessLevelEngage === ACCESS_LEVELS.GROUP_ADMIN
    ) {
      accessLevelIsDisabled = false;
    }
    // if the said user is GT user, access will be disabled
    accessLevelIsDisabled = accessLevelEngage === ACCESS_LEVELS.GT_VIEW_ADMIN || accessLevelEngage === ACCESS_LEVELS.GT_ADMIN
      ? true : accessLevelIsDisabled;
  }
  if (product === 'marketing') {
    if (accessLevelEngage === ACCESS_LEVELS.GT_ADMIN ||
      accessLevelEngage === ACCESS_LEVELS.PARTNER_ADMIN ||
      accessLevelEngage === ACCESS_LEVELS.PARTNER_MANAGER ||
      accessLevelEngage === ACCESS_LEVELS.OWNER ||
      accessLevelEngage === ACCESS_LEVELS.GROUP_ADMIN
    ) {
      accessLevelIsDisabled = false;
    }
    // if the said user is GT user, access will be disabled
    accessLevelIsDisabled = accessLevelMarketing === ACCESS_LEVELS.GT_VIEW_ADMIN || accessLevelMarketing === ACCESS_LEVELS.GT_ADMIN
      ? true : accessLevelIsDisabled;
  }
  // if the said user is GT user, access will be disabled
  // accessLevelIsDisabled = accessLevelValue.value === ACCESS_LEVELS.GT_VIEW_ADMIN || accessLevelValue.value === ACCESS_LEVELS.GT_ADMIN
  //   ? true : accessLevelIsDisabled;

  if (accessLevelNew === ACCESS_LEVELS.GT_ADMIN) {
    accessLevelIsDisabled = false;
  }
  if (props.user.username === props.userState.user.user_info.uid) {
    accessLevelIsDisabled = true;
  }


  return (
    <div className="prdInfo">
      <div className="lt">
        {PRODUCT_TO_LABELS[product]}
      </div>
      <div className="md">
        {
          accessLevel
          ?
            <div className="aclvlBx">
              <Select
                className={'selectDropDown ' + (accessLevelError ? 'selectError' : '')}
                options={accessLevelOptions}
                value={accessLevelValue}
                onChange={onAccessLevelChange}
                isDisabled={accessLevelIsDisabled}
                // onMenuOpen={onFeatureMenuOpen}
              />
              {
                accessLevelValue.value !== ACCESS_LEVELS.GT_VIEW_ADMIN && accessLevelValue.value !== ACCESS_LEVELS.GT_ADMIN && !accessLevelIsDisabled &&
                <span onClick={onRemoveAccess}>Remove Accesss</span>
              }
            </div>
          : <div className="aclvlBx"><p>No Access</p>
              {
                props.user.username !== props.userState.user.user_info.uid &&
                <span onClick={onEnableAccess}>Add Accesss</span>
              }
            </div>
        }
      </div>
      <div className="rt">
        {
          accessLevel && accessLevelValue && accessLevelValue.value === ACCESS_LEVELS.GENERIC_USER &&
          <>
            <div className="curFetrBx">
              {
                selectedFeatures && Object.keys(selectedFeatures).length
                ?
                  Object.keys(selectedFeatures).map((feature) => {
                    return (
                      <FeatureTab feature={selectedFeatures[feature]} onRemoveClick={onFeatureRemoveClick} />
                    )
                  })
                : <span>Limited access to basic features</span>
              }
            </div>
            <div className="addFetrBx">
              <div className="gLbl">Add access to features</div>
              <Select
                className={'selectDropDown ' + (selectedFeaturesError ? 'selectError' : '')}
                options={featureOptions}
                value={featureValue}
                onChange={onFeatureSelectValueChange}
                // onMenuOpen={onFeatureMenuOpen}
              />
            </div>
          </>
        }
        {
          accessLevel && accessLevelValue && (accessLevelValue.value === ACCESS_LEVELS.GROUP_ADMIN || accessLevelValue.value === ACCESS_LEVELS.LOCATION_ADMIN ) &&
          <span>Access to all features</span>
        }
      </div>
    </div>
  );
}

function LocationProductRowNotActive(props) {
  const { product } = props;
  return (
    <div className="prdInfo">
      <div className="ntActv">{PRODUCT_TO_LABELS[product]} - Not Active</div>
    </div>
  )
}
function LocationProductRowNoAccess(props) {
  const { product } = props;
  return (
    <div className="prdInfo">
      <div className="ntActv">{PRODUCT_TO_LABELS[product]} - No Access to your id</div>
    </div>
  )
}

function LocationRow(props) {
  const [locationData, setLocationData] = useState({});

  const [locationAccessLevel, setLocationAccessLevel] = useState({});
  const [locationAccessLevelOptions, setLocationAccessLevelOptions] = useState([]);

  const [addLocationAccessLevel, setAddLocationAccessLevel] = useState(false);

  const [hasProductLevelAccess, setHasProductLevelAccess] = useState(false);

  const updateLocationAccessLevelOptions = () => {
    const { locData, curUserProfileLocData } = props;
    const { accessLevel } = getHighestAccessLevels(props.userState.user.user_info, props.locationInfo._id);
    let tempLocationAccessLevelOptions = [];
    if (locData) {
      if (
        accessLevel === ACCESS_LEVELS.GT_ADMIN ||
        accessLevel === ACCESS_LEVELS.PARTNER_ADMIN ||
        accessLevel === ACCESS_LEVELS.PARTNER_MANAGER ||
        accessLevel === ACCESS_LEVELS.OWNER ||
        accessLevel === ACCESS_LEVELS.GROUP_ADMIN
      ){
        tempLocationAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
        tempLocationAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
      }
      // if (accessLevel === ACCESS_LEVELS.GROUP_ADMIN){
      //   tempLocationAccessLevelOptions.push({ label: 'Select access level', value: 'select-access-level' });
      //   tempLocationAccessLevelOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
      // }
    }
    setLocationAccessLevelOptions([
      ...tempLocationAccessLevelOptions,
    ]);
    setLocationAccessLevel({ label: 'Select access level', value: 'select-access-level' });
  };

  useEffect(() => {
    updateLocationAccessLevelOptions();
    const { locData, curUserProfileLocData } = props;
    if (locData && locData.access_level) {
      setLocationAccessLevel({ label: ACCESS_LEVELS_TO_LABEL[locData.access_level], value: locData.access_level });
      setAddLocationAccessLevel(true);
    }

    setLocationData(locData);

    let tempHasProductLevelAccess = false;
    if (
      (locData && locData.reputation && locData.reputation.access_level) ||
      (locData && locData.engage && locData.engage.access_level) ||
      (locData && locData.marketing && locData.marketing.access_level)
    ) {
      tempHasProductLevelAccess = true;
    }
    setHasProductLevelAccess(tempHasProductLevelAccess);

  }, []);

  useEffect(() => {
    props.onChange(locationData, props.locationInfo._id, props.locationInfo.location_id);
  }, [locationData]);

  const onReputationChange = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReputationChange: ', data);
    }
    setLocationData({
      ...locationData,
      reputation: locationData.reputation
      ?
        {
          ...locationData.reputation,
          ...data,
        }
      :
        {
          ...data,
        }
    })
  };
  const onEngageChange = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEngageChange: ', data);
    }
    setLocationData({
      ...locationData,
      engage: locationData.engage
      ?
        {
          ...locationData.engage,
          ...data,
        }
      :
        {
          ...data,
        }
    })
  };
  const onMarketingChange = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onMarketingChange: ', data);
    }
    // if (!Object.keys(data).length) {
    //   let tempData = { ...locationData };
    //   delete tempData.marketing;
    //   setLocationData({
    //     ...tempData,
    //   });
    //   return;
    // }
    setLocationData({
      ...locationData,
      marketing: locationData.marketing
      ?
        {
          ...locationData.marketing,
          ...data,
        }
      :
        {
          ...data,
        }
    })
  };

  const onLocationRemoveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationRemoveClick');
    }
    setLocationData(null, props.locationInfo._id, props.locationInfo.location_id);
    props.onSomeChange(e);
  };

  const onAddLocationLevelAccessClick = (e) => {
    setAddLocationAccessLevel(true);
    updateLocationAccessLevelOptions();
  };
  const onLocationAccessLevelChange = (value) => {
    setLocationAccessLevel(value);
    setLocationData({
      ...locationData,
      access_level: value.value === 'select-access-level' ? '' : value.value,
    });
    props.onSomeChange();
  };
  const onRemoveLocationLevelAccess = (e) => {
    setAddLocationAccessLevel(false);
    setLocationAccessLevel({});
    setLocationData({
      ...locationData,
      access_level: '',
    });
    props.onSomeChange();
  };

  const onAddProductLevelAccessClick = (e) => {
    setHasProductLevelAccess(true);
    props.onSomeChange();
  };

  const { locData, locName, locationInfo, curUserProfile, curUserProfileLocData, formError } = props;
  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow formError: ', formError);
    console.log('LocationRow LocationRow locData: ', locData);
    console.log('LocationRow LocationRow curUserProfile: ', curUserProfile);
    console.log('LocationRow LocationRow curUserProfileLocData: ', curUserProfileLocData);
  }
  let maxAccessLevelAmongProducts = '';
  if (locData) {
    if (locData.reputation && locData.reputation.access_level) maxAccessLevelAmongProducts = locData.reputation.access_level;
    if (locData.engage && locData.engage.access_level &&
      curUserHasAccessToOtherUserByAccessLevel(locData.engage.access_level, maxAccessLevelAmongProducts)
    ) maxAccessLevelAmongProducts = locData.engage.access_level;
    if (locData.marketing && locData.marketing.access_level &&
      curUserHasAccessToOtherUserByAccessLevel(locData.marketing.access_level, maxAccessLevelAmongProducts)
    ) maxAccessLevelAmongProducts = locData.marketing.access_level;
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow maxAccessLevelAmongProducts: ', maxAccessLevelAmongProducts);
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow curUserProfileLocData: ', curUserProfileLocData);
  }

  let curUserMaxAccessLevelAmongProducts = '';
  if (curUserProfile.access_level) {
      curUserMaxAccessLevelAmongProducts = curUserProfile.access_level;
  } else {
    if (curUserProfileLocData) {
      if (curUserProfileLocData.reputation && curUserProfileLocData.reputation.access_level) curUserMaxAccessLevelAmongProducts = curUserProfileLocData.reputation.access_level;
      if (curUserProfileLocData.engage && curUserProfileLocData.engage.access_level &&
        curUserHasAccessToOtherUserByAccessLevel(curUserProfileLocData.engage.access_level, curUserMaxAccessLevelAmongProducts)
      ) curUserMaxAccessLevelAmongProducts = curUserProfileLocData.engage.access_level;
      if (curUserProfileLocData.marketing && curUserProfileLocData.marketing.access_level &&
        curUserHasAccessToOtherUserByAccessLevel(curUserProfileLocData.marketing.access_level, curUserMaxAccessLevelAmongProducts)
      ) curUserMaxAccessLevelAmongProducts = curUserProfileLocData.marketing.access_level;
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow curUserMaxAccessLevelAmongProducts: ', curUserMaxAccessLevelAmongProducts);
  }

  const { accessLevel, accessLevelReputation, accessLevelEngage, accessLevelMarketing } = getHighestAccessLevels(props.userState.user.user_info, locationInfo._id);


  const { reputation_enabled, engage_enabled, marketing_enabled } = props.appInfo;

  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow curUserMaxAccessLevelAmongProducts: ', curUserMaxAccessLevelAmongProducts);
    console.log('LocationRow maxAccessLevelAmongProducts: ', maxAccessLevelAmongProducts);
  }
  const ac1 = curUserHasAccessToOtherUserByAccessLevel(curUserMaxAccessLevelAmongProducts, maxAccessLevelAmongProducts);
  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationRow ac1: ', ac1);
  }

  return (
    <>
    <div className={'gBCtRw locRw' + (formError[locationInfo._id] ? ' hasErr' : '')}>
      <div className="gRwBxLt">
        <div className="gLbl">
          {locName}

          {
            (
              curUserHasAccessToOtherUserByAccessLevel(curUserMaxAccessLevelAmongProducts, maxAccessLevelAmongProducts) ||
              props.type === 'add'
            ) &&
            <span className="remove" onClick={onLocationRemoveClick}>Remove All Access to Location</span>
          }
        </div>
        {
          props.userState.user.user_id === '994dfb9b1b674a6d' &&
          <div className="gDbg">
            <p>Signed In user access level: {curUserProfile.access_level}</p>
            <p>Signed In user location access level: {curUserProfileLocData.access_level}</p>
            <p>Signed In user prd reputation access level: {curUserProfileLocData.reputation ? curUserProfileLocData.reputation.access_level : ''}</p>
            <p>Signed In user prd engage access level: {curUserProfileLocData.engage ? curUserProfileLocData.engage.access_level : ''}</p>
            <p>Signed In user prd marketing access level: {curUserProfileLocData.marketing ? curUserProfileLocData.marketing.access_level : ''}</p>
          </div>
        }
        {
          (props.user.username === props.userState.user.user_info.uid && accessLevel === ACCESS_LEVELS.LOCATION_ADMIN )
          ?
            <div className="locLvlAcBox">
              Location Admin Access
            </div>
          : null
        }
        {
          (
            accessLevel === ACCESS_LEVELS.GT_ADMIN ||
            accessLevel === ACCESS_LEVELS.PARTNER_ADMIN ||
            accessLevel === ACCESS_LEVELS.PARTNER_MANAGER ||
            accessLevel === ACCESS_LEVELS.OWNER ||
            accessLevel === ACCESS_LEVELS.GROUP_ADMIN
          )
          ?
            <>
              <div className="locLvlAcBox">
                {
                  addLocationAccessLevel
                  ?
                    <>
                      <div className="lt">
                        <span>Location Level Access</span>
                        <Select
                          className={'selectDropDown'}
                          options={locationAccessLevelOptions}
                          value={locationAccessLevel}
                          onChange={onLocationAccessLevelChange}
                          isDisabled={props.user.username === props.userState.user.user_info.uid}
                          // isDisabled={accessLevelIsDisabled}
                          // onMenuOpen={onFeatureMenuOpen}
                        />
                      </div>
                      {
                        props.user.username !== props.userState.user.user_info.uid &&
                        <div className="rt">
                          <span className="remove" onClick={onRemoveLocationLevelAccess}>Remove Location Level Access</span>
                        </div>
                      }
                    </>
                  :
                    <>
                    {
                      props.user.username !== props.userState.user.user_info.uid &&
                      <div className="lt">
                        <span className="add" onClick={onAddLocationLevelAccessClick}>Add Location Level access</span>
                      </div>
                    }
                    </>
                }
              </div>
              <div className="locLvlAcBoxHelp">
                Location level access will override all product level access settings. User will have access to all enabled products and settings.
              </div>
            </>
          : null
        }
        <div className={'prdLvlAcBox' + (locationAccessLevel.value && locationAccessLevel.value !== 'select-access-level' ? ' noChgAlw' : '')}>
          {
            hasProductLevelAccess
            ?
              <>
                <div className="gLbl">
                  Product Level access
                </div>
                <div className="gHelpMsg">
                  This access will be applicable for indivdual products. Use this for fine grained control.
                </div>
                <div className="prdInfo hdr">
                  <div className="lt">
                    Product
                  </div>
                  <div className="md">
                    Access Level
                  </div>
                  <div className="rt">
                    Features
                  </div>
                </div>
                {
                  reputation_enabled &&
                  <>
                  {
                    locationInfo.has_reputation
                    ?
                      <>
                        {
                          isLocationAdminAndAbove(accessLevelReputation)
                          ?
                            <LocationProductRow product="reputation" {...props} onChange={onReputationChange} onSomeChange={props.onSomeChange}/>
                          : <LocationProductRowNoAccess product="reputation" />
                        }
                      </>
                    : <LocationProductRowNotActive product="reputation" />
                  }
                  </>
                }
                {
                  engage_enabled &&
                  <>
                  {
                    locationInfo.has_engage
                    ?
                      <>
                        {
                          isLocationAdminAndAbove(accessLevelEngage)
                          ?
                            <LocationProductRow product="engage" {...props} onChange={onEngageChange} />
                          : <LocationProductRowNoAccess product="engage" />
                        }
                      </>
                    : <LocationProductRowNotActive product="engage" />
                  }
                  </>
                }
                {
                  marketing_enabled &&
                  <>
                  {
                    locationInfo.has_transaction_emails
                    ?
                      <>
                        {
                          isLocationAdminAndAbove(accessLevelMarketing)
                          ?
                            <LocationProductRow product="marketing" {...props} onChange={onMarketingChange} />
                          : <LocationProductRowNoAccess product="marketing" />
                        }
                      </>
                    : <LocationProductRowNotActive product="marketing" />
                  }
                  </>
                }
              </>
            :
              <>
                <div className="gLbl add" onClick={onAddProductLevelAccessClick}>Add Product Level access</div>
                <div className="gHelpMsg">
                  This access will be applicable for indivdual products. Use this for fine grained control.
                </div>
              </>
          }
        </div>

      </div>
    </div>
    {
      formError[locationInfo._id] &&
      <div className="gBCtRw locErrRw">
        {formError[locationInfo._id]}
      </div>
    }
    </>
  );
}

function UserSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    password: '',
    repeatPassword: '',
  });
  const [localError, setLocalError] = useState('');

  const [userSetupOptions, setUserSetupOptions] = useState([]);
  const [userSetupValue, setUserSetupValue] = useState(
    { label: 'Select access type', value: 'select' },
  );
  const [userSetupError, setUserSetupError] = useState('');


  const [hlAccessLevelOptions, setHlAccessLevelOptions] = useState([]);
  const [hlAccessLevelValue, setHlAccessLevelValue] = useState(
    { label: 'Select access level', value: 'select' },
  );
  const [hlAccessLevelError, setHlAccessLevelError] = useState('');


  const [grpAdmReadOnly, setGrpAdmReadOnly] = useState(false);
  const [grpAdmNoUsrMgmt, setGrpAdmNoUsrMgmt] = useState(false);


  const [hlLocationOptions, setHlLocationOptions] = useState([]);
  const [hlLocationValue, setHlLocationValue] = useState(
    { label: 'Select location', value: 'select' },
  );
  const [hlLocationError, setHlLocationError] = useState('');
  const [hlSelectedLocations, setHlSelectedLocations] = useState([]);


  const [locationOptions, setLocationOptions] = useState([]);
  const [locationValue, setLocationValue] = useState(
    { label: 'Select location', value: 'select-location' },
  );
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationsChanged, setLocationsChanged] = useState(false);
  const [selectedLocationsError, setSelectedLocationsError] = useState(false);

  const [someChange, setSomeChange] = useState(false);

  const [formError, setFormError] = useState({});
  const [noLocationError, setNoLocationError] = useState(false);

  const [allLocationsData, setAllLocationsData] = useState({});
  const [initialAllLocationsData, setInitialAllLocationsData] = useState({});

  const [sendEmailToUser, setSendEmailToUser] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Settings - Account - User - Single - ${locationName}`,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    if (props.type === 'edit') {
      if (props.user && props.user.editStatus && Object.keys(props.user.editStatus).length) {
        dispatch(editDataClearAction({
          op: 'edit_user_for_location',
          params: {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            user_id: props.user ? props.user._id : undefined,
          },
        }));
      }
    }
    if (props.type === 'add') {
      if (props.settings && props.settings.users && props.settings.users.addStatus && Object.keys(props.settings.users.addStatus).length) {
        dispatch(editDataClearAction({
          op: 'edit_user_for_location',
          params: {
            location__id: urlParams.locationidentifier,
            mode: props.type,
          },
        }));
      }
    }
  };

  useEffect(() => {
    let tempLocations = [];
    if (userState.user && userState.user.user_info && userState.user.user_info.locations &&
      Object.keys(userState.user.user_info.locations).length &&
      userState.user.user_info.profile
    ) {
      Object.keys(userState.user.user_info.locations).forEach((locMId) => {
        // if (!tempLocationOptions.find(ll => ll.value === locMId)) {
          let locInfo = { ...userState.user.user_info.locations[locMId] };
          // only if logged in user has location or above access to this location
          if (
              (
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.GT_ADMIN ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_ADMIN ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_MANAGER ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.OWNER ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.LOCATION_ADMIN
              ) ||
              (
                userState.user.user_info.profile.locations &&
                userState.user.user_info.profile.locations[locMId] &&
                (userState.user.user_info.profile.locations[locMId].access_level === 'GROUP_ADMIN' || userState.user.user_info.profile.locations[locMId].access_level === 'LOCATION_ADMIN')
              )
            ) {
              tempLocations.push({ label: locInfo.name, value: locMId });
            }
            // tempLocations.push({ label: locInfo.name, value: locMId })
        // }
      });
    }

    if (props.user && props.user._id) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('UserSingle props.user: ', props.user);
      }
      setAllLocationsData(props.user.profile.locations ? { ...props.user.profile.locations } : {});
      setForm(form => ({
        ...form,
        firstName: props.user.profile.first_name,
        lastName: props.user.profile.last_name,
        email: props.user.username,
        contact: props.user.contact,
      }));
      if (props.user.profile.locations && Object.keys(props.user.profile.locations).length) {
        let tempSelectedLocationOptions = [];
        Object.keys(props.user.profile.locations).forEach((locMId) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('locMId: ', locMId);
          }
          let locInfo = {};
          if (userState.user && userState.user.user_info && userState.user.user_info.locations && userState.user.user_info.locations[locMId]) {
            locInfo = { ...userState.user.user_info.locations[locMId] };
          }
          if (locInfo && locInfo._id) {
            tempSelectedLocationOptions.push({ label: locInfo.name, value: locInfo._id });
          }
        });
        setSelectedLocations(tempSelectedLocationOptions);
        if (process.env.NODE_ENV !== 'production') {
          console.log('tempSelectedLocationOptions: ', tempSelectedLocationOptions);
        }

        // let tempLocations = [];
        // if (userState.user && userState.user.user_info && userState.user.user_info.locations && Object.keys(userState.user.user_info.locations).length) {
        //   Object.keys(userState.user.user_info.locations).forEach((locMId) => {
        //     if (!tempSelectedLocationOptions.find(ll => ll.value === locMId)) {
        //       let locInfo = { ...userState.user.user_info.locations[locMId] };
        //       tempLocations.push({ label: locInfo.name, value: locMId })
        //     }
        //   });
        // }
        tempLocations = tempLocations.filter((el) => {
          if (tempSelectedLocationOptions.find(ll => ll.value === el.value)) {
            return false;
          }
          return true;
        });

        if (process.env.NODE_ENV !== 'production') {
          console.log('tempLocations: ', tempLocations);
        }
      }
      setLocationOptions([
        { label: 'Select location', value: 'select-location' },
        ...tempLocations,
      ]);
      if (props.user.profile.access_level) {
        setUserSetupValue({ label: 'High Level Access', value: 'high-level-access' });
        setHlAccessLevelValue({
          label: ACCESS_LEVELS_TO_LABEL[props.user.profile.access_level],
          value: props.user.profile.access_level
        });
        let tempOptions = getAccessLevelOptions();
        setHlAccessLevelOptions(tempOptions);
        if (props.user.profile.locations && Object.keys(props.user.profile.locations).length) {
          let tLocations = [];
          Object.keys(props.user.profile.locations).forEach((el) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('UserSingle loc: ', el);
            }
            if (props.user.profile.locations[el] && props.user.profile.locations[el].location_id) {
              tLocations.push({
                label: props.user.profile.locations[el].name,
                value: el,
              });
            }
          });
          if (process.env.NODE_ENV !== 'production') {
            console.log('UserSingle tLocations: ', tLocations);
          }
          setHlSelectedLocations(tLocations);
          updateHlLocationOptions(tLocations);
        }

      } else {
        setUserSetupValue({ label: 'Specific Access', value: 'specific-access' });
      }
    } else {
      setLocationOptions([
        { label: 'Select location', value: 'select-location' },
        ...tempLocations,
      ]);
      if (userState.user.user_info.profile.access_level === ACCESS_LEVELS.LOCATION_ADMIN) {
        setUserSetupValue({ label: 'Specific Access', value: 'specific-access' });
      }
      if (userState.user.user_info.profile.locations &&
        props.settings.info && props.settings.info._id &&
        userState.user.user_info.profile.locations[props.settings.info._id] &&
        userState.user.user_info.profile.locations[props.settings.info._id].access_level === ACCESS_LEVELS.LOCATION_ADMIN
      ) {
        setUserSetupValue({ label: 'Specific Access', value: 'specific-access' });
      }
    }
    let tempOptions = [];
    tempOptions.push({ label: 'High Level Access', value: 'high-level-access' });
    tempOptions.push({ label: 'Specific Access', value: 'specific-access' });
    setUserSetupOptions(tempOptions);



    setSomeChange(false);
    clearAction();
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.user && props.user._id &&
        props.settings.users && props.settings.users.keyValue &&
        props.settings.users.keyValue[props.user._id] &&
        !props.settings.users.keyValue[props.user._id].editInProgress &&
        props.settings.users.keyValue[props.user._id].editStatus &&
        props.settings.users.keyValue[props.user._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.settings.users &&
        !props.settings.users.addInProgress &&
        props.settings.users.addStatus &&
        props.settings.users.addStatus.success
      ) {
        setForm({
          firstName: '',
          lastName: '',
          email: '',
          contact: '',
          password: '',
          repeatPassword: '',
        });
        setSelectedLocations([]);
        setSomeChange(false);
      }
    }
  }, [props.settings.users]);

  const getAccessLevelOptions = () => {
    let tempOptions = [];
    if (userState.user.user_info.profile.access_level === ACCESS_LEVELS.GT_ADMIN) {
      tempOptions.push({ label: 'Partner Admin', value: ACCESS_LEVELS.PARTNER_ADMIN });
    }
    if (isPartnerAdminAndAbove(userState.user.user_info.profile.access_level)) {
      tempOptions.push({ label: 'Partner Manager', value: ACCESS_LEVELS.PARTNER_MANAGER });
    }
    if (isPartnerManagerAndAbove(userState.user.user_info.profile.access_level)) {
      tempOptions.push({ label: 'Owner', value: ACCESS_LEVELS.OWNER });
    }
    if (isOwnerAndAbove(userState.user.user_info.profile.access_level)) {
      tempOptions.push({ label: 'Group Admin - Read Only', value: ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY });
      tempOptions.push({ label: 'Group Admin - Without User Management', value: ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT });
      tempOptions.push({ label: 'Group Admin', value: ACCESS_LEVELS.GROUP_ADMIN });
    }
    if (isGroupAdminAndAbove(userState.user.user_info.profile.access_level)) {
      tempOptions.push({ label: 'Location Admin', value: ACCESS_LEVELS.LOCATION_ADMIN });
    }
    return tempOptions;
  };

  const updateHlLocationOptions = (alreadySelectedList = []) => {
    let tempLocations = [];
    if (userState.user && userState.user.user_info && userState.user.user_info.locations &&
      Object.keys(userState.user.user_info.locations).length &&
      userState.user.user_info.profile
    ) {
      Object.keys(userState.user.user_info.locations).forEach((locMId) => {
        // if (!tempLocationOptions.find(ll => ll.value === locMId)) {
          let locInfo = { ...userState.user.user_info.locations[locMId] };
          // only if logged in user has location or above access to this location
          if (
              (
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.GT_ADMIN ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_ADMIN ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_MANAGER ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.OWNER ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN ||
                userState.user.user_info.profile.access_level === ACCESS_LEVELS.LOCATION_ADMIN
              ) ||
              (
                userState.user.user_info.profile.locations &&
                userState.user.user_info.profile.locations[locMId] &&
                (userState.user.user_info.profile.locations[locMId].access_level === 'GROUP_ADMIN' || userState.user.user_info.profile.locations[locMId].access_level === 'LOCATION_ADMIN')
              )
            ) {
              tempLocations.push({ label: locInfo.name, value: locMId });
            }
      });
    }
    if (alreadySelectedList && alreadySelectedList.length) {
      tempLocations = tempLocations.filter((el) => {
        if (alreadySelectedList.find(x => x.value === el.value)) {
          return false;
        }
        return true;
      });
    }
    if (tempLocations.length) {
      tempLocations = [ { label: 'All Locations', value: 'all-locations' }, ...tempLocations ];
    }
    setHlLocationOptions(tempLocations);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
      [`${name}Error`]: value ? '' : 'error',
    }));
    if (name === 'email') {
      if (!validateEmail(value)) {
        let emailError = value ? '' : 'error';
        if (!emailError) emailError = validateEmail(value) ? '' : 'invalid';
        setForm(form => ({
          ...form,
          emailError,
        }));
      } else {
        if (props.settings.users && props.settings.users.keyValue && Object.keys(props.settings.users.keyValue).length) {
          const foundInUsers = Object.keys(props.settings.users.keyValue).find((el) => {
            if (props.settings.users.keyValue[el] && props.settings.users.keyValue[el].username === value) {
              return true;
            }
            return false;
          });
          if (process.env.NODE_ENV !== 'production') {
            console.log('foundInUsers: ', foundInUsers);
          }
          let emailError = '';
          if (foundInUsers) {
            emailError = 'in-use';
          }
          setLocalError(emailError === 'in-use' ? 'Email already in use!' : '');
          setForm(form => ({
            ...form,
            emailError,
          }));
        }
      }
    }
    if (name === 'password') {
      setForm(form => ({
        ...form,
        passwordError: !isPasswordStrong(value) ? 'weak' : '',
      }));
    }
    if (name === 'repeatPassword') {
      setForm(form => ({
        ...form,
        repeatPasswordError: value !== form.password ? 'mismatch' : '',
      }));
    }
    setSomeChange(true);
    clearAction();
  };

  const onUserSetupValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onUserSetupValueChange value: ', value);
    }
    setUserSetupValue(value);
    setUserSetupError(value.value === 'select' ? 'error' : '');

    if (value.value === 'high-level-access') {
      let tempOptions = getAccessLevelOptions();
      setHlAccessLevelOptions(tempOptions);
      setNoLocationError(true);
    } else {
      setHlAccessLevelOptions([]);
      setHlAccessLevelValue({ label: 'Select access level', value: 'select' });
      setHlLocationValue({ label: 'Select location', value: 'select' });
      setHlSelectedLocations([]);
      setNoLocationError(false);
    }
    setSomeChange(true);
    clearAction();
  };

  const onHlAccessLevelValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onHlAccessLevelValueChange value: ', value);
    }
    setHlAccessLevelValue(value);
    setHlAccessLevelError(value.value === 'select' ? 'error' : '');
    if (value.value !== 'select') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('onHlAccessLevelValueChange allLocationsData: ', allLocationsData);
      }
      let tLocations = [];
      if (allLocationsData && Object.keys(allLocationsData).length) {
        Object.keys(allLocationsData).forEach((el) => {
          tLocations.push({ label: allLocationsData[el].name, value: el });
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log('onHlAccessLevelValueChange tLocations: ', tLocations);
        }
      }
      setHlSelectedLocations(tLocations);
      updateHlLocationOptions(tLocations);
      if (tLocations.length) setNoLocationError(false);
    }
    setHlLocationValue({ label: 'Select location', value: 'select' });
    // setHlSelectedLocations([]);
    setSomeChange(true);
    clearAction();
  };
  // const onGrpAdmReadOnlyChange = (e) => {
  //   const { name, value } = e.target;
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('onGrpAdmReadOnlyChange: ', name, ' # ', value);
  //   }
  //   setGrpAdmReadOnly(grpAdmReadOnly => !grpAdmReadOnly);
  // };
  // const onGrpAdmReadOnlyLabelClick = (e) => {
  //   setGrpAdmReadOnly(grpAdmReadOnly => !grpAdmReadOnly);
  // };
  // const onGrpAdmNoUsrMgmtChange = (e) => {
  //   const { name, value } = e.target;
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('onGrpAdmNoUsrMgmtChange: ', name, ' # ', value);
  //   }
  //   setGrpAdmNoUsrMgmt(grpAdmNoUsrMgmt => !grpAdmNoUsrMgmt);
  // };
  // const onGrpAdmNoUsrMgmtLabelClick = (e) => {
  //   setGrpAdmNoUsrMgmt(grpAdmNoUsrMgmt => !grpAdmNoUsrMgmt);
  // };

  const onHlLocationValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onHlLocationValueChange value: ', value);
    }
    if (value.value !== 'select') {
      if (value.value === 'all-locations') {
        let tempSelectedLocations = [];
        hlLocationOptions.forEach((item) => {
          tempSelectedLocations.push(item);
        });
        setHlSelectedLocations(hlSelectedLocations => [...hlSelectedLocations, ...tempSelectedLocations]);
        setHlLocationOptions([ { label: 'Select location', value: 'select' } ]);
        setHlLocationValue({ label: 'Select location', value: 'select' });
        setNoLocationError(false);
      } else {
        setHlSelectedLocations(hlSelectedLocations => [...hlSelectedLocations, value]);
        setHlLocationOptions(hlLocationOptions => hlLocationOptions.filter(el => el.value !== value.value));
        setHlLocationValue({ label: 'Select location', value: 'select' });
        setHlLocationError(false);
        setNoLocationError(false);
      }
    }
    setSomeChange(true);
    // setLocationsChanged(true);
  };
  const onHlLocationRemoveClick = (locName, locMId) => {
    if (
      props.user.username === userState.user.user_info.uid ||
      (props.user.profile && props.user.profile.access_level === userState.user.user_info.profile.access_level)
    ) return;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onHlLocationRemoveClick: ', locName, ' # ', locMId);
      console.log('onHlLocationRemoveClick hlSelectedLocations: ', hlSelectedLocations.filter(el => el.value !== locMId));
    }
    if (!hlSelectedLocations.filter(el => el.value !== locMId).length) {
      setNoLocationError(true);
    }
    setHlSelectedLocations(hlSelectedLocations => hlSelectedLocations.filter(el => el.value !== locMId));
    setHlLocationOptions(hlLocationOptions => [...hlLocationOptions, { label: locName, value: locMId }]);
    setSomeChange(true);
  };


  const onLocationValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationValueChange value: ', value);
    }
    // setPersonalizeValue(value);
    if (value.value !== 'select-location') {
      setSelectedLocations(selectedLocations => [...selectedLocations, value]);
      setLocationOptions(locationOptions => locationOptions.filter(el => el.value !== value.value));
      setLocationValue({ label: 'Select location', value: 'select-location' });
      setSelectedLocationsError(false);
    }

    setLocationsChanged(true);
    // if (props.addEditStatus && Object.keys(props.addEditStatus).length) {
    //   props.onAddEditClear(props.template);
    // }
    // gtag('event', 'gt_click', {
    //   'event_category': 'Settings - Users',
    //   'event_label': 'Added Feature',
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onLocationDataChange = (locationData, locMId, locationId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocationDataChange locMId: ', locMId);
      console.log('onLocationDataChange locationId: ', locationId);
      console.log('onLocationDataChange locationData: ', locationData);
      console.log('onLocationDataChange allLocationsData: ', allLocationsData);
    }
    let tempLocationData = { location_id: locationId, ...locationData };
    if (tempLocationData.reputation && !tempLocationData.reputation.access_level) delete tempLocationData.reputation;
    if (tempLocationData.engage && !tempLocationData.engage.access_level) delete tempLocationData.engage;
    if (tempLocationData.marketing && !tempLocationData.marketing.access_level) delete tempLocationData.marketing;
    if (locationData) {
      // let tempAllLocationsData = {
      //   ...allLocationsData,
      //   [locMId]: allLocationsData[locMId]
      //   ?
      //     {
      //       ...allLocationsData[locMId],
      //       ...locationData,
      //     }
      //   :
      //     {
      //       location_id: locationId,
      //       ...locationData,
      //     }
      // };
      // over-write it
      let tempAllLocationsData = {
        ...allLocationsData,
        [locMId]: allLocationsData[locMId]
        ?
          {
            ...tempLocationData,
          }
        :
          {
            location_id: locationId,
            ...tempLocationData,
          }
      };
      setAllLocationsData(tempAllLocationsData);
      if (process.env.NODE_ENV !== 'production') {
        console.log('onLocationDataChange tempAllLocationsData: ', tempAllLocationsData);
      }

      let fError = {};
      Object.keys(tempAllLocationsData).forEach((item) => {
        if (
          (!tempAllLocationsData[item] || !tempAllLocationsData[item].access_level || tempAllLocationsData[item].access_level === 'select-access-level') &&
          (!tempAllLocationsData[item].reputation || !tempAllLocationsData[item].reputation.access_level || tempAllLocationsData[item].reputation.access_level === 'select-access-level') &&
          (!tempAllLocationsData[item].engage || !tempAllLocationsData[item].engage.access_level || tempAllLocationsData[item].engage.access_level === 'select-access-level') &&
          (!tempAllLocationsData[item].marketing || !tempAllLocationsData[item].marketing.access_level || tempAllLocationsData[item].marketing.access_level === 'select-access-level')
        ) {
          fError[item] = 'No Access level assigned. Please remove location if not assigned.'
        }
      });
      setFormError(fError);

      if (!Object.keys(tempAllLocationsData).length) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('UserSingle no locations assigned');
        }
        setNoLocationError(true);
      } else {
        setNoLocationError(false);
      }

    } else {
      // remove location
      let tempAllLocationsData = { ...allLocationsData };
      delete tempAllLocationsData[locMId];
      setAllLocationsData(tempAllLocationsData);

      let fError = {};
      Object.keys(tempAllLocationsData).forEach((item) => {
        if (
          (!tempAllLocationsData[item] || !tempAllLocationsData[item].access_level || tempAllLocationsData[item].access_level === 'select-access-level') &&
          (!tempAllLocationsData[item].reputation || !tempAllLocationsData[item].reputation.access_level || tempAllLocationsData[item].reputation.access_level === 'select-access-level') &&
          (!tempAllLocationsData[item].engage || !tempAllLocationsData[item].engage.access_level || tempAllLocationsData[item].engage.access_level === 'select-access-level') &&
          (!tempAllLocationsData[item].marketing || !tempAllLocationsData[item].marketing.access_level || tempAllLocationsData[item].marketing.access_level === 'select-access-level')
        ) {
          fError[item] = 'No Access level assigned. Please remove location if not assigned.'
        }
      });
      setFormError(fError);

      if (!Object.keys(tempAllLocationsData).length) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('UserSingle no locations assigned');
        }
        setNoLocationError(true);
      } else {
        setNoLocationError(false);
      }

      // update selected locations
      let newSelectedLocations = selectedLocations.filter(el => el.value !== locMId);
      setSelectedLocations(newSelectedLocations);

      // update location select options
      let tempLocations = [];
      if (userState.user && userState.user.user_info && userState.user.user_info.locations && Object.keys(userState.user.user_info.locations).length) {
        Object.keys(userState.user.user_info.locations).forEach((locMId) => {
          if (!newSelectedLocations.find(ll => ll.value === locMId)) {
            let locInfo = { ...userState.user.user_info.locations[locMId] };
            tempLocations.push({ label: locInfo.name, value: locMId })
          }
        });
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('tempLocations: ', tempLocations);
      }
      setLocationOptions([
        { label: 'Select location', value: 'select-location' },
        ...tempLocations,
      ]);
      setSomeChange(true);
    }
    clearAction();
    // setSomeChange(true);
  };

  const onSomeChange = (e) => {
    setSomeChange(true);
  }

  const onAddEditClick = (e) => {
    let addEditInProgress = props.settings.users.addInProgress
    if (props.type === 'edit') {
      if (props.user && props.user._id &&
        props.settings.users && props.settings.users.keyValue &&
        props.settings.users.keyValue[props.user._id]
      ) {
        addEditInProgress = props.settings.users.keyValue[props.user._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('UserSingle onAddEditClick allLocationsData: ', allLocationsData);
      console.log('UserSingle onAddEditClick form: ', form);
      console.log('UserSingle onAddEditClick someChange: ', someChange);
    }

    if (!userSetupValue || userSetupValue.value === 'select') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('onAddEditClick userSetupValue: ', userSetupValue);
      }
      return;
    }

    if (
      props.user.username === userState.user.user_info.uid ||
      (props.user.profile && props.user.profile.access_level === userState.user.user_info.profile.access_level)
    ) {
      return;
    }

    clearAction();
    setForm(form => ({
      ...form,
      firstNameError: form.firstName ? '' : 'error',
      lastNameError: form.lastName ? '' : 'error',
    }));
    let emailError = form.email ? '' : 'error';
    if (!emailError) emailError = validateEmail(form.email) ? '' : 'invalid';
    if (localError) emailError = 'in-use';
    setForm(form => ({
      ...form,
      emailError,
    }));
    if (props.type === 'add') {
      setForm(form => ({
        ...form,
        passwordError: form.password ? '' : 'error',
      }));
      setForm(form => ({
        ...form,
        repeatPasswordError: form.repeatPassword ? '' : 'error',
      }));
      if (form.password && form.repeatPassword && (form.password !== form.repeatPassword)) {
        setForm(form => ({
          ...form,
          // passwordError: 'error',
          repeatPasswordError: 'mismatch',
        }));
        return;
      }
      if (form.password && !isPasswordStrong(form.password)) {
        setForm(form => ({
          ...form,
          passwordError: 'weak',
        }));
        return;
      }
    }
    if (localError) return;

    let hlLocations = {};
    if (userSetupValue.value === 'high-level-access') {
      if (!hlAccessLevelValue || hlAccessLevelValue.value === 'select') {
        if (process.env.NODE_ENV !== 'production') {
          console.log('onAddEditClick hlAccessLevelValue missing');
        }
        return;
      }
      if (!hlSelectedLocations.length) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('onAddEditClick hlSelectedLocations missing: ', hlSelectedLocations);
        }
        return;
      } else {
        hlSelectedLocations.forEach((el) => {
          if (userState.user.user_info.locations[el.value] && userState.user.user_info.locations[el.value].location_id) {
            hlLocations[el.value] = {
              location_id: userState.user.user_info.locations[el.value].location_id
            };
          }
        });
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('onAddEditClick hlLocations: ', hlLocations);
      }
    }

    if (userSetupValue.value === 'specific-access') {
      let fError = {};
      Object.keys(allLocationsData).forEach((item) => {
        if (
          (!allLocationsData[item] || !allLocationsData[item].access_level || allLocationsData[item].access_level === 'select-access-level') &&
          (!allLocationsData[item].reputation || !allLocationsData[item].reputation.access_level || allLocationsData[item].reputation.access_level === 'select-access-level') &&
          (!allLocationsData[item].engage || !allLocationsData[item].engage.access_level || allLocationsData[item].engage.access_level === 'select-access-level') &&
          (!allLocationsData[item].marketing || !allLocationsData[item].marketing.access_level || allLocationsData[item].marketing.access_level === 'select-access-level')
        ) {
          fError[item] = 'No Access level assigned. Please remove location if not assigned.'
        }
      });
      setFormError(fError);
      if (Object.keys(fError).length) return;

      if (!Object.keys(allLocationsData).length) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('UserSingle no locations assigned');
        }
        setNoLocationError(true);
        return;
      }
      setNoLocationError(false);
    }

    // clear name
    Object.keys(allLocationsData).forEach((el) => {
      delete allLocationsData[el].name;
    });

    // return;

    if (someChange) {
      if (props.type === 'add') {
        if (form.firstName && form.lastName && form.email && form.password && form.repeatPassword) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            first_name: form.firstName,
            last_name: form.lastName,
            username: form.email,
            contact: form.contact,
            password: form.password,
            // locations: allLocationsData,
            send_email_to_user: sendEmailToUser,
          };
          if (userSetupValue.value === 'high-level-access') {
            params.access_level = hlAccessLevelValue.value;
            params.locations = hlLocations;
          }
          if (userSetupValue.value === 'specific-access') {
            params.locations = allLocationsData;
          }
          if (process.env.NODE_ENV !== 'production') {
            console.log('UserSingle onAdd params: ', params);
          }
          dispatch(editDataAction({
            op: 'edit_user_for_location',
            params
          }));
          setSomeChange(false);
        }
      }
      if (props.type === 'edit') {
        if (form.firstName) {
          const params = {
            location__id: urlParams.locationidentifier,
            mode: props.type,
            username: props.user.username,
            user_id: props.user._id,
            first_name: form.firstName,
            last_name: form.lastName,
            // locations: allLocationsData,
          };
          if (userSetupValue.value === 'high-level-access') {
            params.access_level = hlAccessLevelValue.value;
            params.locations = hlLocations;
          }
          if (userSetupValue.value === 'specific-access') {
            params.access_level = '';
            params.locations = allLocationsData;
          }
          if (process.env.NODE_ENV !== 'production') {
            console.log('UserSingle params: ', params);
          }
          dispatch(editDataAction({
            op: 'edit_user_for_location',
            params,
          }));
          setSomeChange(false);
        }
      }
    }
  };

  const renderContent = () => {
    const { users } = props.settings;

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add User`;
    if (props.type === 'edit') pageTitle = 'Edit User';
    if (props.title) pageTitle = props.title;

    const accessTypeEnabled = isGroupAdminAndAbove(userState.user.user_info.profile.access_level);

    let addEditInProgress = users.addInProgress
    if (props.type === 'edit') { addEditInProgress = props.user.editInProgress; }
    let addEditStatus = users.addStatus;
    if (props.type === 'edit') { addEditStatus = props.user.editStatus; }

    return (
      <div className="gPWrp usrSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw byTwRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                First Name
              </div>
              <div className={'gIpt ' + (form.firstNameError ? 'error' : '')}>
                <input type="text" value={form.firstName} onChange={onChange} name="firstName" placeholder="First Name" />
                { form.firstNameError && <p className="gErr">Required</p> }
              </div>
            </div>
            <div className="gRwBxRt">
              <div className="gLbl">
                Last Name
              </div>
              <div className={'gIpt ' + (form.lastNameError ? 'error' : '')}>
                <input type="text" value={form.lastName} onChange={onChange} name="lastName" placeholder="Last Name" />
                { form.lastNameError && <p className="gErr">Required</p> }
              </div>
            </div>
          </div>
          <div className="gBCtRw byTwRw">
            <div className="gRwBxLt">
              <div className="gLbl">
                Email
              </div>
              <div className={'gIpt ' + (form.emailError ? 'error' : '')}>
                <input type="text" value={form.email} onChange={onChange} name="email" placeholder="Email" readOnly={props.type === 'edit'} />
                {form.emailError === 'error' ? <div className="gErr">Required</div> : null}
                {form.emailError === 'invalid' ? <div className="gErr">Invalid email</div> : null}
                {form.emailError === 'in-use' ? <div className="gErr">{localError}</div> : null}
              </div>
            </div>
            <div className="gRwBxRt">
              <div className="gLbl">
                Mobile
              </div>
              <div className={'gIpt ' + (form.contactError ? 'error' : '')}>
                <input type="text" value={form.contact} onChange={onChange} name="contact" placeholder="Mobile" readOnly={props.type === 'edit'} />
                { form.contactError && <p className="gErr">Mobile is required</p> }
              </div>
            </div>
          </div>
          {
            props.type === 'add' &&
            <div className="gBCtRw byTwRw">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Password
                </div>
                <div className={'gIpt ' + (form.passwordError ? 'error' : '')}>
                  <input type="password" value={form.password} onChange={onChange} name="password" placeholder="Password" readOnly={props.type === 'edit'} />
                  {form.passwordError === 'error' ? <div className="gErr">Required</div> : null}
                { form.passwordError === 'weak' && <div className="gErr">Minimum 8 characters required.</div> }
                </div>
              </div>
              <div className="gRwBxRt">
                <div className="gLbl">
                  Repeat Password
                </div>
                <div className={'gIpt ' + (form.repeatPasswordError ? 'error' : '')}>
                  <input type="password" value={form.repeatPassword} onChange={onChange} name="repeatPassword" placeholder="Repeat Password" readOnly={props.type === 'edit'} />
                  {form.repeatPasswordError === 'error' ? <div className="gErr">Required</div> : null}
                { form.repeatPasswordError === 'mismatch' && <div className="gErr">Password mismatch</div> }
                </div>
              </div>
            </div>
          }
          {
            accessTypeEnabled &&
            <div className="gBCtRw">
              <div className="gRwBxLt">
                <div className="gLbl">
                  Access Type
                </div>
                <Select
                  className={'selectDropDown halfW' + (userSetupError ? 'selectError' : '')}
                  options={userSetupOptions}
                  value={userSetupValue}
                  onChange={onUserSetupValueChange}
                  isDisabled={
                    props.user.username === userState.user.user_info.uid ||
                    (props.user.profile && props.user.profile.access_level === userState.user.user_info.profile.access_level)
                  }
                  // onMenuOpen={onFeatureMenuOpen}
                />
                {
                  userSetupValue.value === 'high-level-access' &&
                  <div className="gHelpMsg">
                    Ideal for creating users with high level access to multiple locations
                  </div>
                }
                {
                  userSetupValue.value === 'specific-access' &&
                  <div className="gHelpMsg">
                    Ideal for creating users with specific access to specific products for specific locations
                  </div>
                }
                {userSetupError ? <div className="gErr">Required</div> : null}
              </div>
              <div className="gRwBxRt">
              </div>
            </div>
          }
          {
            userSetupValue.value === 'high-level-access' &&
            <>
              <div className="gBCtRw">
                <div className="gRwBxLt">
                  <div className="gLbl">
                    Access Level
                  </div>
                  <Select
                    className={'selectDropDown ' + (hlAccessLevelError ? 'selectError' : '')}
                    options={hlAccessLevelOptions}
                    value={hlAccessLevelValue}
                    onChange={onHlAccessLevelValueChange}
                    isDisabled={
                      props.user.username === userState.user.user_info.uid ||
                      (props.user.profile && props.user.profile.access_level === userState.user.user_info.profile.access_level)
                    }
                    // onMenuOpen={onFeatureMenuOpen}
                  />
                  {
                    hlAccessLevelValue.value === ACCESS_LEVELS.GROUP_ADMIN &&
                    <div className="gHelpMsg">
                      User will get full access (including edits) to all enabled products and settings - for multiple locations.
                    </div>
                  }
                  {
                    hlAccessLevelValue.value === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY &&
                    <div className="gHelpMsg">
                      User will get only read only access (no edits) to all enabled products and settings - for multiple locations.
                    </div>
                  }
                  {
                    hlAccessLevelValue.value === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT &&
                    <div className="gHelpMsg">
                      User will get full access (including edits) to all enabled products and settings except user management (creating/modifying other users) - for multiple locations.
                    </div>
                  }
                  {
                    hlAccessLevelValue.value === ACCESS_LEVELS.LOCATION_ADMIN &&
                    <div className="gHelpMsg">
                      User will get full access (including edits) to all enabled products and settings - for multiple locations.
                    </div>
                  }
                  {hlAccessLevelError ? <div className="gErr">Required</div> : null}
                </div>
                <div className="gRwBxRt">
                </div>
              </div>
              {/*
                hlAccessLevelValue.value === 'GROUP_ADMIN' &&
                <>
                  <div className="gBCtRw chkBxRw">
                    <div className="gRwBxLt">
                      <input type="checkbox" name="grpAdmReadOnly" onChange={onGrpAdmReadOnlyChange} value={grpAdmReadOnly ? 'on' : 'off'} checked={grpAdmReadOnly} />
                      <div className="gLbl" onClick={onGrpAdmReadOnlyLabelClick}>
                        Read Only
                      </div>
                    </div>
                  </div>
                  <div className="gBCtRw chkBxRw">
                    <div className="gRwBxLt">
                      <input type="checkbox" name="grpAdmNoUsrMgmt" onChange={onGrpAdmNoUsrMgmtChange} value={grpAdmNoUsrMgmt ? 'on' : 'off'} checked={grpAdmNoUsrMgmt} />
                      <div className="gLbl" onClick={onGrpAdmNoUsrMgmtLabelClick}>
                        No User Management
                      </div>
                    </div>
                  </div>
                </>
              */}
              {
                hlAccessLevelValue.value !== 'select' &&
                <>
                  <div className="gBCtRw locLbl">
                    <div className="gRwBxLt">
                      <div className="gLbl">
                        Locations
                      </div>
                    </div>
                  </div>
                  {
                    hlSelectedLocations && hlSelectedLocations.length
                    ?
                      <div className="gBCtRw curFetrBx">
                        {
                          hlSelectedLocations.map((locLabelValue) => {
                            // if (userState && userState.user && userState.user.user_info && userState.user.user_info.locations && userState.user.user_info.locations[locLabelValue.value]) {
                              return (
                                <HlLocationRow
                                  key={`usr_hllr_${locLabelValue.value}`}
                                  locMId={locLabelValue.value}
                                  locName={locLabelValue.label}
                                  onRemoveClick={onHlLocationRemoveClick}
                                />
                              )
                            // }
                            return null;
                          })
                        }
                      </div>
                    : null
                  }
                  <div className="gBCtRw byTwRw">
                    <div className="gRwBxLt">
                      <div className="gLbl">
                        Add Location
                      </div>
                      <Select
                        className={'selectDropDown ' + (hlLocationError ? 'selectError' : '')}
                        options={hlLocationOptions}
                        value={hlLocationValue}
                        onChange={onHlLocationValueChange}
                        isDisabled={
                          props.user.username === userState.user.user_info.uid ||
                          (props.user.profile && props.user.profile.access_level === userState.user.user_info.profile.access_level)
                        }
                        // onMenuOpen={onFeatureMenuOpen}
                      />
                      {hlLocationError ? <div className="gErr">Atleast one location is required</div> : null}
                    </div>
                    <div className="gRwBxRt">
                    </div>
                  </div>
                </>
              }
            </>
          }
          {
            userSetupValue.value === 'specific-access' &&
            <>
              <div className="gBCtRw locLbl">
                <div className="gRwBxLt">
                  <div className="gLbl">
                    Locations
                  </div>
                </div>
              </div>
              {
                selectedLocations && selectedLocations.length
                ?
                  selectedLocations.map((locLabelValue) => {
                    if (userState && userState.user && userState.user.user_info && userState.user.user_info.locations && userState.user.user_info.locations[locLabelValue.value]) {
                      let locationInfo = { ...userState.user.user_info.locations[locLabelValue.value] };
                      let curUserProfile = userState.user && userState.user.user_info && userState.user.user_info.profile
                        ? { ...userState.user.user_info.profile } : {};
                      let curUserProfileLocData = userState.user && userState.user.user_info && userState.user.user_info.profile &&
                        userState.user.user_info.profile.locations && userState.user.user_info.profile.locations[locLabelValue.value]
                        ? { ...userState.user.user_info.profile.locations[locLabelValue.value] }
                        : {};
                      return (
                        <LocationRow
                          key={`usr_lr_${locLabelValue.value}`}
                          locData={
                            props.user && props.user._id
                            ? { ...props.user.profile.locations[locLabelValue.value] }
                            : {}
                          }
                          type={props.type}
                          user={props.user}
                          locName={userState.user.user_info.locations[locLabelValue.value].name}
                          curUserProfile={curUserProfile}
                          curUserProfileLocData={curUserProfileLocData} locationInfo={locationInfo}
                          onChange={onLocationDataChange}
                          formError={formError}
                          onSomeChange={onSomeChange}
                          userState={userState}
                          appInfo={appState.appInfo}
                        />
                      )
                    }
                    return null;
                  })
                : null
              }
              <div className="gBCtRw byTwRw">
                <div className="gRwBxLt">
                  <div className="gLbl">
                    Add Location
                  </div>
                  <Select
                    className={'selectDropDown ' + (selectedLocationsError ? 'selectError' : '')}
                    options={locationOptions}
                    value={locationValue}
                    onChange={onLocationValueChange}
                    isDisabled={props.user.username === userState.user.user_info.uid}
                    // onMenuOpen={onFeatureMenuOpen}
                  />
                  {selectedLocationsError ? <div className="gErr">Atleast one location is required</div> : null}
                </div>
                <div className="gRwBxRt">
                </div>
              </div>
            </>
          }
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          {
            Object.keys(formError).length
            ?
              <div className="gBCtRw curSts">
                <span className="gErr">Please check errors above!</span>
              </div>
            : null
          }
          {
            noLocationError &&
            <div className="gBCtRw curSts">
              <span className="gErr">Atleast one location is required.</span>
            </div>
          }
          {
            props.user.username !== userState.user.user_info.uid &&
            <div className="gBCtRw btnRw">
              {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
                { props.type === 'add' && 'Add' }
                { props.type === 'edit' && 'Save Changes' }
                { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
              </button>
            </div>
          }
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('UserSingle props: ', props);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('UserSingle allLocationsData: ', allLocationsData);
    console.log('UserSingle noLocationError: ', noLocationError);
    console.log('UserSingle hlSelectedLocations: ', hlSelectedLocations);
  }

  return renderContent();
}

export default UserSingle;
