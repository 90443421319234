// @flow

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

// import YesNoToggle from '../../../Misc/YesNoToggle';

import closeIcon from '../../../img/icons/close.svg';

// import { MSGTYPE_TO_LABEL } from '../../../../helpers';

import './style.css';


function ImagePreviewModal(props) {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');


  const onCloseClick = (e) => {
    props.onCancelClick('');
  };
  const onConfirmClick = (e) => {
    props.onConfirmClick('');
  };
  const onAddClick = (e) => {

  };

  const renderMessage = () => {
    if (props.uploadError) {
      return (
        <div className="gPWrp imgPrv">
          <h4 className="gPTle title">
            <span>
              Error
            </span>
            {
              props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            }
          </h4>
          <div className="uploadError attachStatus">
            <p>{props.uploadError}</p>
            <div className="btnRow">
              {props.replaceFile}
              {/*<button className="confirm" onClick={onCloseClick}>
                Cancel
              </button>*/}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="gPWrp imgPrv">
        {
          props.inProgress &&
          <div className="attachInProgress attachStatus">
            <p>Processing...</p>
            <p><i className="fa fa-spinner fa-spin" /></p>
          </div>
        }
        {
          !props.inProgress &&
          <>
            <h4 className="gPTle title">
              <span>
                Image Preview
              </span>
              {
                props.onCancelClick && <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
              }
            </h4>
            <div className="contentRow">
              <div className="itemBox">
            {/*<i className="fa fa-spinner fa-spin" />*/}
                <img src={props.image} />
              </div>
            </div>
            <div className="byTwoRow buttonsRow">
              <button className="replace reverse" onClick={onAddClick}>
                {props.replaceFile}
              </button>
              {
                !props.hideConfirm &&
                <button className="confirm" onClick={onConfirmClick}>
                  Confirm
                </button>
              }
            </div>
          </>
        }
      </div>
    );
  };


  const renderContent = () => {
    return renderMessage();
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('ImagePreviewModal props: ', props);
  }

  return renderContent();

}

export default ImagePreviewModal;
