import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select'
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataClearAction, editDataAction } from '../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

// import { LABEL_COLORS, LABEL_MAX_LENGTH } from '../../../../../helpers';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import '../style.css';

function SenderNameSingle(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  useEffect(() => {
    if (props.sender_name && props.sender_name._id) {
      setValue(props.sender_name.value);
    }
    setSomeChange(false);
    dispatch(editDataClearAction({
      op: 'edit_sender_name',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        sender_name_id: props.sender_name._id,
      },
    }));
    // setTimeout(() => {
    //   if (labelRef && labelRef.current) {
    //     labelRef.current.focus();
    //   }
    // }, 100);
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      if (props.sender_name && props.sender_name._id &&
        props.settings.marketing.sender_names && props.settings.marketing.sender_names.keyValue &&
        props.settings.marketing.sender_names.keyValue[props.sender_name._id] &&
        !props.settings.marketing.sender_names.keyValue[props.sender_name._id].editInProgress &&
        props.settings.marketing.sender_names.keyValue[props.sender_name._id].editStatus &&
        props.settings.marketing.sender_names.keyValue[props.sender_name._id].editStatus.success
      ) {
        setSomeChange(false);
      }
    }
    if (props.type === 'add') {
      if (props.settings.marketing.sender_names &&
        !props.settings.marketing.sender_names.addInProgress &&
        props.settings.marketing.sender_names.addStatus &&
        props.settings.marketing.sender_names.addStatus.success
      ) {
        setValue('');
        setSomeChange(false);
      }
    }
  }, [props.settings.marketing.sender_names]);

  const onValueChange = (e) => {
    const { name, value } = e.target;
    dispatch(editDataClearAction({
      op: 'edit_sender_name',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        sender_name_id: props.sender_name._id,
      },
    }));
    setValue(e.target.value);
    let vError = e.target.value ? '' : 'Value is required';
    if (e.target.value) {
      if (props.settings.marketing.sender_names && props.settings.marketing.sender_names.list &&
        props.settings.marketing.sender_names.keyValue
      ) {
        props.settings.marketing.sender_names.list.forEach((item) => {
          if (props.settings.marketing.sender_names.keyValue[item] &&
            !props.settings.marketing.sender_names.keyValue[item].is_removed &&
            props.settings.marketing.sender_names.keyValue[item].value &&
            props.settings.marketing.sender_names.keyValue[item].value.toLowerCase() === e.target.value.toLowerCase()
          ) {
            vError = 'Already exists';
          }
        });
      }
    }
    setValueError(vError);
    setSomeChange(true);
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  }

  const onCancelClick = (e) => {
    props.onCancelClick(false);
  };

  const onAddEditClick = (e) => {
    if (!someChange) return;
    let addEditInProgress = props.settings.marketing.sender_names.addInProgress
    if (props.type === 'edit') {
      if (props.sender_name && props.sender_name._id &&
        props.settings.marketing.sender_names && props.settings.marketing.sender_names.keyValue &&
        props.settings.marketing.sender_names.keyValue[props.sender_name._id]
      ) {
        addEditInProgress = props.settings.marketing.sender_names.keyValue[props.sender_name._id].editInProgress;
      }
    }
    if (addEditInProgress) return;

    dispatch(editDataClearAction({
      op: 'edit_sender_name',
      params: {
        location__id: urlParams.locationidentifier,
        mode: props.type,
        sender_name_id: props.sender_name._id,
      },
    }));

    let vError = value ? '' : 'Value is required';
    if (value) {
      if (props.settings.marketing.sender_names && props.settings.marketing.sender_names.list &&
        props.settings.marketing.sender_names.keyValue
      ) {
        props.settings.marketing.sender_names.list.forEach((item) => {
          if (props.settings.marketing.sender_names.keyValue[item] &&
            !props.settings.marketing.sender_names.keyValue[item].is_removed &&
            props.settings.marketing.sender_names.keyValue[item].value &&
            props.settings.marketing.sender_names.keyValue[item].value.toLowerCase() === value.toLowerCase()
          ) {
            vError = 'Already exists';
          }
        });
      }
    }

    setValueError(vError);
    if (vError) return;

    if (someChange) {
      if (props.type === 'add') {
        if (value) {
          dispatch(editDataAction({
            op: 'edit_sender_name',
            params: {
              location__id: urlParams.locationidentifier,
              mode: props.type,
              name_or_email: 'name',
              value,
            },
          }));
        }
      }
      if (props.type === 'edit') {
        if (value) {
          dispatch(editDataAction({
            op: 'edit_sender_name',
            params: {
              location__id: urlParams.locationidentifier,
              mode: props.type,
              name_or_email: 'name',
              sender_name_id: props.sender_name._id,
              value,
            },
          }));
        }
      }
    }
  };

  const renderContent = () => {
    const { locationidentifier } = urlParams;
    if (!locationidentifier || !locationState.locations[locationidentifier]) return null;
    const { settings } = locationState.locations[locationidentifier];
    if (!settings || !settings.marketing ||
      !settings.marketing.sender_names || !settings.marketing.sender_names.keyValue
    ) return null;
    const tData = settings.marketing.sender_names.keyValue[props.sender_name._id] || {};

    let pageTitle = '';
    if (props.type === 'add') pageTitle = `Add Sender Name`;
    if (props.type === 'edit') pageTitle = 'Edit Sender Name';
    if (props.title) pageTitle = props.title;



    let addEditInProgress = settings.marketing.sender_names.addInProgress
    if (props.type === 'edit') { addEditInProgress = tData.editInProgress; }
    let addEditStatus = settings.marketing.sender_names.addStatus;
    if (props.type === 'edit') { addEditStatus = tData.editStatus; }

    return (
      <div className="gPWrp lblSingle">
        <div className="gPTle title">
          { pageTitle }
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        <div className="gBCtnt">
          <div className="gBCtRw titleRow">
            <div className="gRwBxLt">
              <div className="gLbl">
                Name
              </div>
              <div className={'gIpt ' + (valueError ? 'error' : '')}>
                <input type="text" value={value} onChange={onValueChange} placeholder="Sender Name" />
                { valueError && <p className="gErr">Name is required</p> }
              </div>
            </div>
          </div>
          {
            addEditStatus && (addEditStatus.success || addEditStatus.error) &&
            <div className="gBCtRw curSts">
              {
                addEditStatus.error && <span className="gErr">{addEditStatus.message || 'Error in updating'}</span>
              }
              {
                addEditStatus.success && props.type === 'edit' && <span className="success">Updated successfully</span>
              }
              {
                addEditStatus.success && props.type === 'add' && <span className="success">Added successfully</span>
              }
            </div>
          }
          <div className="gBCtRw btnRw">
            {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onAddEditClick} >
              { props.type === 'add' && 'Add' }
              { props.type === 'edit' && 'Save Changes' }
              { addEditInProgress && <i className="fa fa-spinner fa-spin" /> }
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('SenderNameSingle props: ', props);
  }

  return renderContent();
}

export default SenderNameSingle;
