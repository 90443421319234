export function initSettingsAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'INIT_SETTINGS',
      payload,
    });
  };
}

export function initEngageAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'INIT_ENGAGE',
      payload,
    });
  };
}

export function initMarketingAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'INIT_MARKETING',
      payload,
    });
  };
}

export function initReputationAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'INIT_REPUTATION',
      payload,
    });
  };
}
