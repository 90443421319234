import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Interweave } from 'interweave';

// import Reviews from './Reviews';
// import addGuestIcon from '../../../../../img/icons/add-guest.svg';

import whatsappIcon from '../../../../../img/icons/whatsapp.png';
import smsIcon from '../../../../../img/icons/sms.svg';
import expediaIcon from '../../../../../img/icons/expedia.png';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import { formatDateSpl, formatDateStrToStr, formatDate } from '../../../../../helpers';

function GuestListRow(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const user = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GuestListRow props: ', props);
      console.log('GuestListRow urlParams: ', urlParams);
      console.log('GuestListRow urlParams categoryidentifier: ', urlParams.categoryidentifier);
    }
  }, []);

  const { categoryidentifier, locationidentifier } = urlParams;
  const { guest, settings } = props;
  if (!settings || !settings.engage) return null;
  const { engage } = settings;

  let name = guest.first_name || guest.last_name || guest.name || guest.contact;

  const checkInDateStr = formatDateStrToStr(guest.check_in_date_local_str) || formatDate(guest.check_in_date);
  const checkOutDateStr = formatDateStrToStr(guest.check_out_date_local_str) || formatDate(guest.check_out_date);

  let allLabelsLength = 0, labelsToShow = 0, activeGuestLabels = 0;
  if (engage.labels && engage.labels.list && guest.labels) {
    let LABELS_LENGTH_THRESHOLD = 34;
    if (windowState.window.windowWidth > 1366) {
      LABELS_LENGTH_THRESHOLD = 44;
    } else if (windowState.window.windowWidth > 1023) {
      LABELS_LENGTH_THRESHOLD = 40;
    }
    guest.labels.forEach((item) => {
      if (engage.labels.keyValue[item._id] && !engage.labels.keyValue[item._id].is_removed) {
        activeGuestLabels++;
        // const labelString = labels.keyValue[item._id] ? labels.keyValue[item._id].label : item.label;
        const labelString = engage.labels.keyValue[item._id].label;
        allLabelsLength += labelString.length + 3;
        if (allLabelsLength < LABELS_LENGTH_THRESHOLD) {
          labelsToShow++;
        }
      } else {
        if (process.env.NODE_ENV !== 'production') {
          console.log('removed : ', item);
        }
      }
    });
  }

  const replyRequired = guest.hotel_reply_required;
  // const replyRequired = true;

  let replyRequiredTxt = guest.hotel_reply_required_txt || '';
  if (replyRequiredTxt) {
    replyRequiredTxt = `${replyRequiredTxt}\n\n Powered by guestAI`;
  }

  const conversationRating = guest.conversation_rating;
  let conversationRatingTxt = guest.conversation_rating_txt || '';
  if (conversationRatingTxt) {
    conversationRatingTxt = `${conversationRatingTxt}\n\n Powered by guestAI`;
  }

  let mediumIcon = '';
  if (guest.medium_last_message === 'sms') mediumIcon = <img src={smsIcon} />;
  if (guest.medium_last_message === 'whatsapp') mediumIcon = <img src={whatsappIcon} />;
  if (guest.medium_last_message === 'email') mediumIcon = <i className="fa-solid fa-envelope"></i>;
  if (guest.medium_last_message === 'webapp') mediumIcon = <i className="fa-solid fa-mobile-screen-button"></i>;
  if (guest.medium_last_message === 'webtext') mediumIcon = <i className="fa-solid fa-globe"></i>;
  if (guest.medium_last_message === 'expedia') mediumIcon = <img src={expediaIcon} />;
  // if (!mediumIcon) mediumIcon = <img src={smsIcon} />;

  return (
    <Link className={'glR' + (props.active ? ' active' : '') + (replyRequired ? ' rplReq' : '')} to={`/engage/locations/${locationidentifier}/messages/${categoryidentifier}/${guest._id}`}>
      {/*
        (
          (replyRequired && replyRequiredTxt) ||
          (conversationRating && conversationRatingTxt)
        ) &&
        <div className="rw aiAlrts">
          <div className="lt convRt">
            {
              (conversationRating && conversationRatingTxt) &&
              <>
                <ToolTipWrapper placement="left" overlayClassName="sbTooltip maxWidth" overlay={<Interweave content={conversationRatingTxt || ''} />}>
                  <span>{conversationRating}</span>
                </ToolTipWrapper>
              </>
            }
          </div>
          <div className="rt rplReq">
            {
              (replyRequired && replyRequiredTxt) &&
              <ToolTipWrapper placement="left" overlayClassName="sbTooltip maxWidth" overlay={<Interweave content={replyRequiredTxt || ''} />}>
                <span>Reply Required</span>
              </ToolTipWrapper>
            }
          </div>
        </div>
          */}
      {
        categoryidentifier === 'upcoming-guests' && guest.transaction_type === 'Cancel' &&
        <div className="rw cnclRw">
          <div className="cnclLbl">
            Cancelled
          </div>
        </div>
      }
      <div className="rw name">
        <div className="lt">
          {name}
        </div>
        <div className="rt">
          { guest.date_last_message && formatDateSpl(guest.date_last_message) }
        </div>
      </div>
      {
        engage.labels && engage.labels.list && engage.labels.list.length && engage.labels.keyValue && guest.labels && guest.labels.length
        ?
          <div className="rw lblsRw">
            <div className="lt">
            {
              guest.labels.filter(item => engage.labels.keyValue[item._id])
              .slice(0,labelsToShow).map((item) => {
                if (!engage.labels.keyValue || !engage.labels.keyValue[item._id] || !engage.labels.keyValue[item._id].label) return null;
                let cStyle = {};
                cStyle['backgroundColor'] = '#c8d3fc';
                if (engage.labels.keyValue[item._id]) { cStyle['backgroundColor'] = engage.labels.keyValue[item._id].color; }
                else { cStyle['backgroundColor'] = item.color; }
                return (
                  <div className="entry" style={cStyle} key={`gll_${item._id}`}>
                    {engage.labels.keyValue[item._id] ? engage.labels.keyValue[item._id].label : item.label}
                  </div>
                );
              })
            }
            {
              labelsToShow < activeGuestLabels &&
              <div className="entry">
                +{activeGuestLabels-labelsToShow}
              </div>
            }
            </div>
          </div>
        : null
      }
      <div className="rw msg">
        <div className={'lt' + (guest.unread_count ? ' uc' : '')}>
          {mediumIcon && <>{mediumIcon}</>}
          {guest.latest_msg}
        </div>
        {
          guest.unread_count
          ?
            <div className="rt">
              <span className="uc">{guest.unread_count}</span>
            </div>
          : null
        }
      </div>
      {
        (categoryidentifier === 'upcoming-guests' || categoryidentifier === 'guests') &&
        <div className="rw pg rm">
          <div className="lt">
            Room # {guest.room_number}{guest.room_type ? ` - ${guest.room_type}` : ''}
          </div>
        </div>
      }
      {
        categoryidentifier === 'past-guests' &&
        <div className="rw pg">
          <div className="lt">
            {checkInDateStr} to {checkOutDateStr}
          </div>
        </div>
      }
      {
        categoryidentifier === 'upcoming-guests' &&
        <div className="rw pg">
          <div className="lt">
            Check-In Date: { checkInDateStr }
          </div>
        </div>
      }
    </Link>
  );
}

export default GuestListRow;
