import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import GenericAlertModal from '../../Misc/GenericAlertModal';
import GenericSideModal from '../../Misc/GenericSideModal';
import ToolTipWrapper from '../../Misc/ToolTipWrapper';
import GenericList from '../../Misc/GenericList';

import { getDataAction } from '../../../redux/actions/getDataActions';
import { STRS } from '../../../helpers';

import editIcon from '../../../img/icons/edit.svg';
import deleteIcon from '../../../img/icons/delete.svg';

import './style.css';

function RgUsers(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const responseGeneratorState = useSelector((state) => state.responseGeneratorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  // analytics reporting
  useEffect(() => {
    try {
      document.title = `Review Response AI - Users`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        // gt_location_id: urlParams.locationidentifier,
        // gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    dispatch(getDataAction({
      op: 'get_users_for_rg',
      params: {},
    }));
  }, []);

  const { users } = responseGeneratorState;

  return (
    <>
      {
        users && users.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <GenericList
        type="rg_users"
        title={`Users${props.isReadOnly ? ` [${STRS.READ_ONLY_LABEL}]` : ''}`}
        // location__id={urlParams.locationidentifier}
        generic_list={users}
        users={users || {}}
      />
    </>
  );
}

export default RgUsers;
